/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import Box from "@material-ui/core/Box";
import { useI18n } from "hooks/i18n/i18n";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ButtonBase from "@material-ui/core/ButtonBase";

export const AccountTicketDigitalQrCodeSharingArea: React.FC<{
  isShared: boolean;
  ticketShareMessage: string;
  ticketShareButtonLabel: string;
  onClickTicketShareButton: () => void;
}> = ({
  isShared,
  ticketShareMessage,
  ticketShareButtonLabel,
  onClickTicketShareButton,
}) => {
  const { t } = useI18n();
  const [isOpenAccordion, setIsOpenAccordion] = React.useState<boolean>(false);

  const handleAccordion = () => {
    setIsOpenAccordion(!isOpenAccordion);
  };

  return (
    <Box component={"dl"} margin={0}>
      <Box
        component={"dt"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        padding={"12px"}
        marginTop={"20px"}
        color={isShared ? "white" : "black"}
        fontSize={"14px"}
        fontWeight={"bold"}
        bgcolor={isShared ? "#FF5F96" : "white"}
        style={{ gap: "4px", cursor: "pointer" }}
        onClick={handleAccordion}
      >
        <Box>{isShared ? "チケット共有済み" : t("ticketDetail.share")}</Box>
        <Box>{isOpenAccordion ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
      </Box>

      <Box
        component={"dd"}
        display={isOpenAccordion ? "block" : "none"}
        height={isOpenAccordion ? "auto" : "0px"}
        padding={"0 12px 12px"}
        margin={"0px"}
        color={isShared ? "white" : "black"}
        fontSize={"14px"}
        bgcolor={isShared ? "#FF5F96" : "white"}
        overflow={isOpenAccordion ? "visible" : "hidden"}
        style={{
          transitionDuration: "249ms",
        }}
      >
        <Box component={"p"} lineHeight={"1.3em"}>
          {ticketShareMessage}
        </Box>

        <Box component={"p"} lineHeight={"1.3em"}>
          営利目的の転売を禁ずる
        </Box>

        <ButtonBase
          style={{
            width: "100%",
            padding: "12px",
            marginTop: "12px",
            color: isShared ? "#FF5F96" : "white",
            fontWeight: "bold",
            letterSpacing: "1px",
            backgroundColor: isShared ? "white" : "#00C2AD",
          }}
          disabled={isShared}
          onClick={onClickTicketShareButton}
        >
          {ticketShareButtonLabel}
        </ButtonBase>
      </Box>
    </Box>
  );
};
