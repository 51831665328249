import React from "react";
import { useI18n } from "hooks/i18n/i18n";
import { Button } from "components/atoms/button/Button";

type Props = {
  isPurchased: boolean;
  isOnSale: boolean;
  isLotteryTicketSellingMode: boolean;
  handleButtonAction: () => void;
};
export const TicketPurchaseButton: React.FC<Props> = (props) => {
  const { t } = useI18n();
  const {
    isPurchased,
    isOnSale,
    isLotteryTicketSellingMode,
    handleButtonAction,
  } = props;

  // 購入済み
  if (isPurchased)
    return <Button text={t("event.archiveVideo.purchased")} disabled={true} />;
  // 販売期間外
  if (!isOnSale)
    return (
      <Button
        text={t("event.archiveVideo.outsideSalesPeriod")}
        disabled={true}
      />
    );
  if (isLotteryTicketSellingMode)
    return (
      <Button
        text={t("event.buttons.lotteryPurchase")}
        onClick={handleButtonAction}
      />
    );
  return (
    <Button text={t("event.buttons.purchase")} onClick={handleButtonAction} />
  );
};
