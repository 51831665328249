import React from "react";
import { useDispatch } from "react-redux";
import BorderLoader from "../atoms/loader/BorderLoader";

import { modalActions } from "../../modules/modal";
import { Paw } from "../../styles";

// @ts-expect-error TS7006
export const PawBalance = (props) => {
  const { pawValue, user } = props;
  return (
    <>
      {pawValue !== undefined ? (
        <Paw>{pawValue.toLocaleString()}</Paw>
      ) : user !== undefined &&
        user !== null &&
        user.emailVerified === false ? (
        <>email未認証</>
      ) : (
        <BorderLoader />
      )}
    </>
  );
};

interface Props {}
export const PawCharge: React.FC<Props> = (_props: Props) => {
  const dispatch = useDispatch();
  const togglePawChargeModal = () => {
    dispatch(modalActions.togglePawChargeModal({}));
  };

  return (
    <p className="charge">
      <span onClick={togglePawChargeModal}>+</span>
    </p>
  );
};
