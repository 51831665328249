/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Chip } from "@material-ui/core";
import { useI18n } from "hooks/i18n/i18n";

export const GoodsSearchResult: React.FC<{
  search: string;
  searchResultsNum: number;
  clearSearch: () => void;
}> = (props) => {
  const { t } = useI18n();
  if (!props.search) {
    return null;
  }
  const classes = styles();
  return (
    <p css={classes.searchResults}>
      <Chip label={props.search} onDelete={props.clearSearch} color="primary" />
      <span>{`${t("goods.search.result")} ${props.searchResultsNum} ${t(
        "goods.search.numberUnit"
      )}`}</span>
    </p>
  );
};

const styles = () => ({
  searchResults: css`
    width: 90%;
    margin: 0 auto 20px;
    font-size: 14px;
    span {
      margin-left: 10px;
    }
    @media screen and (min-width: 768px) {
      width: 100%;
    }
  `,
});
