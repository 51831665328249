/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { BreadcrumbArea } from "components/common/Link";
import VoucherCode from "components/voucher/VoucherCode";
import VoucherEvent, { SelectedEvent } from "components/voucher/VoucherEvent";
import VoucherComplete from "components/voucher/VoucherComplete";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "modules/modal";
import { Store } from "store";
import { useVoucherCodeQuery } from "hooks/QueryString";
import { useI18n } from "hooks/i18n/i18n";
import { useVoucherCode } from "../../hooks/voucher/VoucherCode";
import { PromptToLogin } from "./PromptToLogin";

export enum Status {
  CODE = 0,
  EVENT = 1,
  COMPLETE = 2,
}

const Voucher: React.FC = () => {
  // @ts-expect-error TS2345
  const [voucherCode, setVoucherCode] = useState<string>(null);
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent[]>([]);

  const user = useSelector((state: Store) => state.auth.user);
  const myTickets = useSelector((state: Store) => state.ticket.myTickets);
  // @ts-expect-error TS2345
  const [pageStatus, setPageStatus] = useState<string>(Status[0]);
  const { t } = useI18n();
  const design = styles();
  const dispatch = useDispatch();
  const { fetchVoucherCodeEvent, enableVoucherData, registerSelectVideo } =
    useVoucherCode({
      onReveal: () => {
        // @ts-expect-error TS2345
        setPageStatus(Status[1]);
      },
      onRegister: () => {
        // @ts-expect-error TS2345
        setPageStatus(Status[2]);
      },
    });
  /**
   * @description getパラメータを取得してハイフンで分割する。
   */
  const { code } = useVoucherCodeQuery();
  const queryParam = setQueryParam(code);

  /**
   * @deprecated
   * HOTFIX TODO: voucherOrderをチェックするようにする
   */
  const excludeEventIds = myTickets
    ? myTickets
        .filter((data) => {
          return (
            (data._id === "20100210-tif2020-day1" ||
              data._id === "20100310-tif2020-day2" ||
              data._id === "20100410-tif2020-day3") &&
            data.tickets.length > 0
          );
        })
        .map((el) => el._id)
    : [];

  /**
   * @function ログインしていなかったらログインを促すモーダルを表示する
   */
  useEffect(() => {
    if (!user.uid) {
      dispatch(modalActions.toggleLoginMethod());
    }
  }, [dispatch, user.uid]);

  /**
   * @function 入力されたバウチャーコードをバリデートしイベントを取得する。
   * @param values
   */
  const handleSubmitCode = async (values: string[]) => {
    // @ts-expect-error TS2345
    setVoucherCode(values[0]);
    // @ts-expect-error TS2345
    await fetchVoucherCodeEvent(values[0]);
  };

  /**
   * @function 選択したイベントとチケットをFirestoreに登録する。
   * @param selectedEvent
   */
  const handleSubmitEvent = async (selectedEvent: SelectedEvent[]) => {
    setSelectedEvent(selectedEvent);
    await registerSelectVideo(voucherCode, selectedEvent);
  };

  if (!user.uid) {
    return <PromptToLogin />;
  }

  return (
    <div id="contents" css={design.root}>
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, t("common.routes.voucherCode")],
        ]}
      />
      <div css={design.main}>
        <div css={design.inner}>
          {pageStatus === Status[0] && (
            // @ts-expect-error TS2322
            <VoucherCode queryParam={queryParam} onSubmit={handleSubmitCode} />
          )}

          {pageStatus === Status[1] && (
            <VoucherEvent
              user={user}
              enableVoucherData={enableVoucherData}
              // @ts-expect-error TS2322
              excludeEventIds={excludeEventIds}
              onSubmit={handleSubmitEvent}
            />
          )}

          {pageStatus === Status[2] && (
            <VoucherComplete selectedEvent={selectedEvent} />
          )}
        </div>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      min-height: calc(100vh - 256px);
      margin: 0 auto;
      background-color: #ffffff;
    `,
    main: css`
      background-color: #ffffff;
    `,
    inner: css`
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      padding: 45px 20px;
      box-sizing: border-box;
    `,
    title: css`
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    `,
    caution: css`
      text-align: center;
      div {
        margin-bottom: 21px;
      }
      p {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.8em;
      }
    `,
  };
};

/**
 * @function getパラメータで渡されたバウチャーコードをハイフンで分割する
 * @param code: string
 */
const setQueryParam = (code: string) => {
  if (!code) return null;

  const splitCode = code.split("-");
  if (splitCode.length !== 5) return null;
  if (
    // @ts-expect-error TS2532
    splitCode[0].length === 0 ||
    // @ts-expect-error TS2532
    splitCode[1].length === 0 ||
    // @ts-expect-error TS2532
    splitCode[2].length === 0 ||
    // @ts-expect-error TS2532
    splitCode[3].length === 0 ||
    // @ts-expect-error TS2532
    splitCode[4].length === 0
  ) {
    return null;
  }

  return splitCode;
};

export default Voucher;
