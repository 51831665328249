import { useGetCapsuleBox } from "features/useGetCapsuleBox";
import { useMemo, useState } from "react";
import { CapsuleSelectOption } from "./CapsuleSelectNumberOfRolls";

type Props = {
  eventId: string;
  capsuleId: number;
};

const FALLBACK_OPTION = { numOfRolls: 1, hasBonus: false } as const;

export const useCapsuleSelectOptions = (
  props: Props
): {
  options: CapsuleSelectOption[];
  numOfRolls: number;
  setNumOfRolls: (numOfRolls: number) => void;
} => {
  const [numOfRolls, setNumOfRolls] = useState<number>(1);

  const { capsuleBox, isLoading } = useGetCapsuleBox({
    eventId: props.eventId,
    capsuleId: props.capsuleId,
  });

  const selectOptions = useMemo(() => {
    if (!capsuleBox) return [FALLBACK_OPTION];

    const hasMultipleSelectOptions = Object.keys(capsuleBox).length > 0;
    if (!isLoading && hasMultipleSelectOptions) {
      return capsuleBox.selectOptions.map((selectOption) => {
        const hasBonus = selectOption.bonusGoodsList.length > 0;
        const numOfRolls = selectOption.count;
        return {
          numOfRolls,
          hasBonus,
        };
      });
    }
    return [FALLBACK_OPTION];
  }, [capsuleBox, isLoading]);

  return {
    options: selectOptions,
    numOfRolls,
    setNumOfRolls,
  };
};
