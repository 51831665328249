import { format } from "date-fns";

// TODO: 仮定義 & 必要最低限のフィールドのみ定義しているので、必要に応じて修正していく

export type Goods = {
  fulfillmentShipment: {
    title: string;
  };
  thumbnails: string[];
  name: string;
  price: number;
  id: string;
  tags: string[];
  variants: GoodsVariant[];
  // TODO tenantIdはtenantGoods DTOが持っていてもいい気がする
  tenantId: string;
  inventoryQuantity: number | null;
  purchaseLimit: number | null;
  isUnlimitedPurchase: boolean | null;
  salesPeriodType: SalesPeriodType;
  releasedAt: Date | null;
  closedAt: Date | null;
  description: string;
  salesStatus: "soldOut" | "beforeSale" | "onSale" | "afterSale";
};

export type GoodsVariant = {
  name: string;
  id: string;
  inventoryQuantity: number;
  purchaseLimit: number | null;
  isUnlimitedPurchase: boolean;
};

/**
 * 販売期間なし | 販売開始日のみあり | 販売開始日と終了日あり
 */
export type SalesPeriodType = "none" | "releaseOnly" | "releaseAndClose";

/**
 * 発送関連のテキストを取得する
 * Base is https://github.com/balus-co-ltd/spwn/blob/e8871fe3662f8c073463d2da61fdfb1f050dff76/packages/cms/src/features/goods/GoodsShippingDatePreviewNote.tsx#L77
 */
export const getShippingAtPreviewText = (
  shippingMethod: "forEachOrder" | "dateSpecified",
  shippingAtEpochMillis: number | null
): string => {
  // TODO i18n
  switch (shippingMethod) {
    case "forEachOrder":
      return "準備が出来次第発送します";
    case "dateSpecified":
      // @ts-expect-error TS2769
      return approximateConverter(new Date(shippingAtEpochMillis));
    default: {
      const _expectNever: never = shippingMethod;
      return "";
    }
  }
};

/**
 * 日付を上旬、中旬、下旬に変換する
 * Base is https://github.com/balus-co-ltd/spwn/blob/e8871fe3662f8c073463d2da61fdfb1f050dff76/packages/cms/src/features/goods/GoodsShippingDatePreviewNote.tsx#L90
 */
const approximateConverter = (date: Date): string => {
  const getDateText = (d: number): string => {
    if (d <= 10) {
      return "上旬";
    } else if (10 < d && d <= 20) {
      return "中旬";
    } else {
      return "下旬";
    }
  };
  return `${format(date, "yyyy年M月")}${getDateText(date.getDate())}`;
};
