import styled from "@emotion/styled";
import { fontDinMixin } from "../mixin";

const _Paw = styled.p`
  display: flex;
  align-items: center;
  font-size: 35px;
  @media screen and (min-width: 768px) {
    font-size: 55px;
  }
  &:after {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    background-image: url("/images/icon_paw.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    @media screen and (min-width: 768px) {
      width: 40px;
      height: 40px;
      margin-left: 10px;
    }
  }
`;

export const Paw = styled(_Paw)(fontDinMixin);

Paw.displayName = "Paw";
