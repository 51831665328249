/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

type Props = {
  shipment: string;
};

export const Shipment: React.FC<Props> = ({ shipment }) => (
  <React.Fragment>
    <p css={styles.heading}>発送時期</p>
    <p css={styles.content}>{shipment}</p>
  </React.Fragment>
);

const styles = {
  heading: css`
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 4px;
  `,
  content: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  `,
};
