/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import React, { FC, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "../store";
import { goBack } from "connected-react-router";

import { SPView, PCView } from "components/common/MediaQuery";
import { changeStyleWithHosting } from "utility/hosting";
import { useI18n } from "hooks/i18n/i18n";

import { menuActions } from "modules/menu";
import { cartActions, MyCartData } from "modules/cart";
import { modalActions } from "modules/modal";

import { HeaderPcDisplay } from "components/header/HeaderPcDisplay";
import { HeaderSpDisplay } from "components/header/HeaderSpDisplay";
import { useUserCart } from "features/useUserCart";
import { Cart } from "hooks/cart/Cart";
import { useQuery } from "utility/useQuery";
import { SpwnLogo } from "ui/SpwnLogo";
import { HeaderForCrew } from "features/HeaderForCrew";
import { useMediaQuery } from "react-responsive";

export const HeaderFetcher: FC = () => {
  const user = useSelector((state: Store) => state.auth.user);
  const pathname = useSelector(
    (state: Store) => state.router.location.pathname
  );
  const myCart = useSelector((state: Store) => state.cart.myCart);

  const { data } = useUserCart();
  const crewBrandSlug = useQuery().get("crewBrandSlug");

  return (
    <HeaderLogic
      userId={user.uid}
      pathname={pathname}
      myCart={myCart}
      // @ts-expect-error TS2322
      data={data}
      // @ts-expect-error TS2322
      crewBrandSlug={crewBrandSlug}
    />
  );
};

const HeaderLogic: FC<{
  userId: string;
  pathname: string;
  myCart: MyCartData[];
  data: Cart;
  crewBrandSlug: string | undefined;
}> = ({ userId, pathname, myCart, data, crewBrandSlug }) => {
  const dispatch = useDispatch();
  const isDesktop: boolean = useMediaQuery({ query: "(min-width: 768px)" });

  const isEmptyCart =
    myCart.length === 0 && (data === undefined || data.cartItemCount === 0);
  const isBackIconRoute =
    BackIconRouteList.find((item) => pathname.search(item) > 0) !== undefined;

  const isStreamingPage = !(
    /streaming/.exec(pathname) || // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
    /streams/.exec(pathname) || // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
    /\/videos\//.exec(pathname)
  );

  // MEMO: 本日のイベントを表示するボタンと合わせている
  const isStickyHeader =
    !pathname.includes("/streaming") && !pathname.includes("/account");

  const isDisplayLogo = (() => {
    if (isDesktop) {
      return changeStyleWithHosting().commonSettings.header.logo.isDisplay;
    } else {
      if (isStreamingPage) return true;
      return changeStyleWithHosting().commonSettings.header.logo.isDisplay;
    }
  })();

  const domainLogo = (() => {
    if (changeStyleWithHosting().commonSettings.header.logo.path)
      return (
        <img
          // @ts-expect-error TS2322
          src={changeStyleWithHosting().commonSettings.header.logo.path}
          alt=""
        />
      );
    return <SpwnLogo />;
  })();

  const toggleSideMenu = useMemo(
    () => () => {
      dispatch(menuActions.toggleSideMenu());
    },
    [dispatch]
  );

  const toggleMyCart = useMemo(
    () => () => {
      dispatch(cartActions.toggleMyCart({}));
    },
    [dispatch]
  );

  const toggleLoginMethod = useMemo(
    () => () => {
      dispatch(modalActions.toggleLoginMethod());
    },
    [dispatch]
  );

  const goBackPage = useMemo(
    () => () => {
      dispatch(goBack());
    },
    [dispatch]
  );

  return (
    <Header
      userId={userId}
      isEmptyCart={isEmptyCart}
      isBackIconRoute={isBackIconRoute}
      isStreamingPage={isStreamingPage}
      isDisplayLogo={isDisplayLogo}
      isStickyHeader={isStickyHeader}
      domainLogo={domainLogo}
      crewBrandSlug={crewBrandSlug}
      toggleSideMenu={toggleSideMenu}
      toggleMyCart={toggleMyCart}
      toggleLoginMethod={toggleLoginMethod}
      goBackPage={goBackPage}
    />
  );
};

const Header: FC<{
  userId: string;
  isEmptyCart: boolean;
  isBackIconRoute: boolean;
  isStreamingPage: boolean;
  isDisplayLogo: boolean;
  isStickyHeader: boolean;
  domainLogo: JSX.Element;
  crewBrandSlug: string | undefined;
  toggleSideMenu: () => void;
  toggleMyCart: () => void;
  toggleLoginMethod: () => void;
  goBackPage: () => void;
}> = ({
  userId,
  isEmptyCart,
  isBackIconRoute,
  isStreamingPage,
  isDisplayLogo,
  isStickyHeader,
  domainLogo,
  crewBrandSlug,
  toggleSideMenu,
  toggleMyCart,
  toggleLoginMethod,
  goBackPage,
}) => {
  const { t } = useI18n();
  const classes = styles(isStickyHeader);

  const HeaderMenuItems = [
    {
      name: t("common.header.nav.home"),
      link: changeStyleWithHosting().commonSettings.route.home,
      display: changeStyleWithHosting().commonSettings.menu.home.isDisplay,
    },
    {
      name: changeStyleWithHosting().commonSettings.menu.event.text,
      link: "/events",
      display: changeStyleWithHosting().commonSettings.menu.event.isDisplay,
    },
    {
      name: changeStyleWithHosting().commonSettings.menu.archive.text,
      link: "/vod-events",
      display: changeStyleWithHosting().commonSettings.menu.archive.isDisplay,
    },
    {
      name: t("common.header.nav.account"),
      link: "/account",
      display: changeStyleWithHosting().commonSettings.menu.account.isDisplay,
    },
  ];

  if (crewBrandSlug) {
    return <HeaderForCrew crewBrandSlug={crewBrandSlug} />;
  }

  return (
    <div id="header" css={classes.root}>
      <PCView>
        <HeaderPcDisplay
          uid={userId}
          isEmptyCart={isEmptyCart}
          isDisplayLogo={isDisplayLogo}
          domainLogo={domainLogo}
          HeaderMenuItems={HeaderMenuItems}
          toggleSideMenu={toggleSideMenu}
          toggleMyCart={toggleMyCart}
          toggleLoginMethod={toggleLoginMethod}
        />
      </PCView>

      {isStreamingPage && (
        <SPView>
          <HeaderSpDisplay
            uid={userId}
            isEmptyCart={isEmptyCart}
            isDisplayLogo={isDisplayLogo}
            domainLogo={domainLogo}
            isBackIconRoute={isBackIconRoute}
            HeaderMenuItems={HeaderMenuItems}
            toggleSideMenu={toggleSideMenu}
            toggleMyCart={toggleMyCart}
            toggleLoginMethod={toggleLoginMethod}
            goBack={goBackPage}
          />
        </SPView>
      )}
    </div>
  );
};
export default Header;

const BackIconRouteList = [
  new RegExp("account/ticket"),
  new RegExp("account/settlement/history"),
  new RegExp("account/lottery"),
  new RegExp("account/paw-charge"),
  new RegExp("account/paw-charge-history"),
  new RegExp("account/paw-use-history"),
  new RegExp("account/edit"),
  new RegExp("account/address"),
  new RegExp("account/edit/password"),
  new RegExp("account/credit"),
  new RegExp("events/\\d{6}-.*/ticket"),
  new RegExp("events/\\d{6}-.*/goods"),
  new RegExp("events/\\d{6}-.*/flower-stand"),
  new RegExp("account/notifications"),
];

const styles = (isStickyHeader: boolean) => {
  const headerColor = changeStyleWithHosting().baseStyles.mainColor;
  const borderColor = headerColor === "#fff" ? "#d2d2d2" : headerColor;
  const position = isStickyHeader ? "sticky" : "initial";
  return {
    root: css`
      position: ${position};
      top: 0;
      left: 0;
      width: 100%;
      background-color: ${headerColor};
      border-bottom: 1px solid ${borderColor};
      z-index: 1000;
      header {
        @media screen and (min-width: 768px) {
          background-color: initial;
        }
      }
    `,
  };
};
