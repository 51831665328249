import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../../store";
import { BreadcrumbArea } from "components/common/Link";
import { ContentsLayout, SubPageSection } from "../../styles";
import { TextLink } from "ui/TextLink";

interface Props {}

class PlicaPrivacyPolicy extends Component<Props> {
  render() {
    return (
      <SubPageSection id="contents">
        <BreadcrumbArea
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            [null, "プリカサービスのプライバシーポリシー"],
          ]}
        />
        <ContentsLayout>
          <h2>プリカサービスのプライバシーポリシー</h2>

          <article className="text_block">
            <h3>(一般条項)</h3>
            <h4>第1条</h4>
            <p className="indent_none">
              この「プリカサービスのプライバシーポリシー」(以下本ポリシーといいます。)は、お客様が本サービスを利用する際の、会員情報及び本サービスに関連して
              GMO ペイメントゲートウェイ株式会社(以下「当社」とい
              います。)が収集する情報(以下「個人情報等」と総称します。)の取り扱いについて定めるものです。本ポリ
              シーで使用する用語は、本ポリシーで別段の定めのない限り、プリカサービスに係る約款(プリカ基本約款及
              び関連する特約、規則等)における定義に従うものとします。
              2.お客様は、本ポリシーに記載のすべての事項に同意する必要があります。お客様は、本サービスの利用にあ
              たり、予め本ポリシーを精読し、理解した上で同意するものとします。
              3.お客様が本サービスを利用した場合、お客様は本ポリシーを読み、理解し、かつそれに従うことに同意した
              ものとみなされるものとします。
            </p>
          </article>

          <article className="text_block">
            <h3>(当社が収集する情報)</h3>
            <h4>第2条</h4>
            <p>
              当社は、本条第 2 項及び第 3
              項に定める通り、個人情報等を収集します。
            </p>
            <p>2.会員情報</p>
            <p className="indent_none">
              お客様は本サービスの利用のため、以下の情報を当社に提供し、会員登録をするものとします。
            </p>
            <ul>
              <li>
                (1) 個人情報
                お客様の氏名、フリガナ、電話番号。また、お客様は、本サービスの利用に関して、当社が要求する身元確認のための情報を提供するものとします。
              </li>
              <li>
                (2) 決済手段に関する情報
                お客様の決済手段に関連付けられるお客様のクレジットカード番号等。なお、当社は、お客様のカード有効期限及び請求書の送付先等の支払いに関連する情報を収集することがあります。
              </li>
            </ul>

            <p>3.本サービスを利用する際に生成される情報</p>
            <ul>
              <li>
                (1)
                お客様が本サービスを利用する際、当社は、お客様が使用しているモバイル機器のモデル、オペレーティング・システムのバージョン、固有の識別子、携帯電話ネットワーク情報、お客様の
                IP アド
                レス、ブラウザの種類、本サービスへのアクセス履歴といった標準的なログ情報等、モバイル機器
                に関連した情報を収集することがあります。
              </li>
              <li>
                (2)
                お客様が本サービスを利用して取引を行う際、当社は、取引日時、代金等、お客様の氏名及び電話
                番号、使用された決済手段、取引に関してお客様が入力した特記事項及び取引と関連した情報等の、
                お客様により各取引から生成される情報を収集することがあります。
              </li>
            </ul>
          </article>

          <article className="text_block">
            <h3>(当社が収集する個人情報等の使用目的)</h3>
            <p>第3条</p>
            <p className="indent_none">
              当社がお客様の個人情報等を収集する主な目的は、お客様が本サービスを利用する際、簡単、迅速かつ安全な取引を行うことができるようにすることです。当社は、以下の目的でお客様の個人情報等を使用することがあります。
            </p>
            <ul>
              <li>(1) 本サービスへの申込みの処理。</li>
              <li>(2) お客様への本サービスの提供。</li>
              <li>(3) 安全な方法による取引の実施及び確認。</li>
              <li>(4) 取引に関する通知。</li>
              <li>(5) 代金等の徴収。</li>
              <li>
                (6) 現在及び将来に参照することを目的とした取引履歴の管理。
              </li>
              <li>(7) 本サービスの利用促進を目的とした案内。</li>
              <li>
                (8) 紛争解決の支援、及び他の形式によるお客様サポートの提供。
              </li>
              <li>
                (9) 本サービス、コンテンツ及び宣伝の追跡、改善及びカスタマイズ。
              </li>
              <li>
                (10)
                正確性の確認のために収集された情報の比較、及び権限がある第三者によるその正確性の確認。
              </li>
              <li>
                (11)
                適用される法律、規定、規則、及び指針が定める開示義務の遵守及び実行。
              </li>
              <li>
                (12)
                本サービス以外の当社の商品又は当社の関連会社若しくは提携先の商品を当社から案内するため。
              </li>
              <li>
                (13)
                本サービスの利用状況の分析、本サービス利用に関する統計データの作成。
              </li>
            </ul>
          </article>

          <article className="text_block">
            <h3>(本ポリシーの修正)</h3>
            <p>第4条</p>
            <p className="indent_none">
              当社は、その単独かつ完全な裁量により、お客様に通知等をすることによって、いつでも本ポリシーを変更することができるものとします。変更は、掲載された時点で有効となり、お客様によって承認されたとみなされ、
              掲載後に行われた取引に関して継続的に適用されるものとします。お客様がかかる修正に同意しない場合、本
              サービスの利用を終了するものとします。
            </p>
            <p className="indent_none">
              2.前項にかかわらず、個人情報等の収集項目、使用目的その他重要な事項の変更に伴う本ポリシーの変更につ
              いては、当社は、お客様に通知等をし、かつお客様から事前の承認を求めるものとします。
            </p>
          </article>

          <article className="text_block">
            <h3>(個人情報等の開示等の請求について)</h3>
            <p>第5条</p>
            <p className="indent_none">
              当社は、お客様から、自身の個人情報等に関する利用目的の通知又は開示、訂正・追加・削除、利用の停止・消去及び第三者への提供の停止の申し出があったときは、誠実に対応します。その要求に対応するため、当社
              は下記窓口を設置します。
            </p>
          </article>

          <article className="text_block">
            <p>個人情報保護管理責任者 当社 システム本部 本部⻑</p>
            <p>
              連絡先 〒150-0043 東京都渋谷区道玄坂 1-14-6 ヒューマックス渋谷ビル
              7F
            </p>
            <p>電話 03-3464-2740</p>
          </article>

          <article className="text_block">
            <p>(問い合わせ)</p>
            <p>
              本サービスに関する当社への連絡は、下記までお問い合わせください。
            </p>
            <p>privacy@gmo-pg.com</p>
          </article>

          <article className="text_block">
            <p>(個人情報保護方針へのリンク)</p>
            <p>
              <TextLink
                href={"https://corp.gmo-pg.com/security/privacy-policy/"}
              >
                https://corp.gmo-pg.com/security/privacy-policy/
              </TextLink>
            </p>
          </article>
        </ContentsLayout>
      </SubPageSection>
    );
  }
}

const mapStateToProps = (_state: Store) => {
  return {};
};

const mapDispatchToProps = (_dispatch: Dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlicaPrivacyPolicy);
