import { useState, useEffect } from "react";
import { THEOPlayer } from "@spwn/types/external";

export const useTHEOplayerTimeupdate = (player: THEOPlayer) => {
  const [, _forceUpdate] = useState(false);
  const forceUpdate = () => _forceUpdate((prevState) => !prevState);

  useEffect(() => {
    // seekedもここに置きたかったがやり方分からず諦める
    // player.addEventListener("seeked", forceUpdate);
    player.addEventListener("timeupdate", forceUpdate);

    return () => {
      // player.removeEventListener("seeked", forceUpdate);
      player.removeEventListener("timeupdate", forceUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [Math.floor(player.currentTime)];
};
