/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx, Global } from "@emotion/core";
import React from "react";

import { changeStyleWithHosting } from "utility/hosting";

import { Button } from "components/atoms/button/Button";

import logo_spwn_black from "designs/images/logo_spwn_black.svg";

interface Props {
  eventStatus: {
    eventName: string;
    eventDate: string;
  };
  purchasedGoodsStatus: {
    subClassName: string;
    name: string;
    count: number;
    price_jpy: number;
    priority: number;
  }[];
  closePrint: () => void;
}
export const QrcodePrint: React.FC<Props> = (props) => {
  let _total = 0;
  const classes = styles();
  const logoSrcPath = changeStyleWithHosting().commonSettings.header.logo.path
    ? changeStyleWithHosting().commonSettings.header.logo.path
    : logo_spwn_black;

  // @ts-expect-error TS7006
  const zenkana2Hankana = (str) => {
    const kanaMap = {
      ガ: "ｶﾞ",
      ギ: "ｷﾞ",
      グ: "ｸﾞ",
      ゲ: "ｹﾞ",
      ゴ: "ｺﾞ",
      ザ: "ｻﾞ",
      ジ: "ｼﾞ",
      ズ: "ｽﾞ",
      ゼ: "ｾﾞ",
      ゾ: "ｿﾞ",
      ダ: "ﾀﾞ",
      ヂ: "ﾁﾞ",
      ヅ: "ﾂﾞ",
      デ: "ﾃﾞ",
      ド: "ﾄﾞ",
      バ: "ﾊﾞ",
      ビ: "ﾋﾞ",
      ブ: "ﾌﾞ",
      ベ: "ﾍﾞ",
      ボ: "ﾎﾞ",
      パ: "ﾊﾟ",
      ピ: "ﾋﾟ",
      プ: "ﾌﾟ",
      ペ: "ﾍﾟ",
      ポ: "ﾎﾟ",
      ヴ: "ｳﾞ",
      ヷ: "ﾜﾞ",
      ヺ: "ｦﾞ",
      ア: "ｱ",
      イ: "ｲ",
      ウ: "ｳ",
      エ: "ｴ",
      オ: "ｵ",
      カ: "ｶ",
      キ: "ｷ",
      ク: "ｸ",
      ケ: "ｹ",
      コ: "ｺ",
      サ: "ｻ",
      シ: "ｼ",
      ス: "ｽ",
      セ: "ｾ",
      ソ: "ｿ",
      タ: "ﾀ",
      チ: "ﾁ",
      ツ: "ﾂ",
      テ: "ﾃ",
      ト: "ﾄ",
      ナ: "ﾅ",
      ニ: "ﾆ",
      ヌ: "ﾇ",
      ネ: "ﾈ",
      ノ: "ﾉ",
      ハ: "ﾊ",
      ヒ: "ﾋ",
      フ: "ﾌ",
      ヘ: "ﾍ",
      ホ: "ﾎ",
      マ: "ﾏ",
      ミ: "ﾐ",
      ム: "ﾑ",
      メ: "ﾒ",
      モ: "ﾓ",
      ヤ: "ﾔ",
      ユ: "ﾕ",
      ヨ: "ﾖ",
      ラ: "ﾗ",
      リ: "ﾘ",
      ル: "ﾙ",
      レ: "ﾚ",
      ロ: "ﾛ",
      ワ: "ﾜ",
      ヲ: "ｦ",
      ン: "ﾝ",
      ァ: "ｧ",
      ィ: "ｨ",
      ゥ: "ｩ",
      ェ: "ｪ",
      ォ: "ｫ",
      ッ: "ｯ",
      ャ: "ｬ",
      ュ: "ｭ",
      ョ: "ｮ",
      "。": "｡",
      "、": "､",
      ー: "ｰ",
      "「": "｢",
      "」": "｣",
      "・": "･",
    };
    const reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
    return (
      str
        // @ts-expect-error TS7006
        .replace(reg, function (match) {
          // @ts-expect-error TS7053
          return kanaMap[match];
        })
        .replace(/゛/g, "ﾞ")
        .replace(/゜/g, "ﾟ")
    );
  };

  return (
    <div css={classes.root}>
      <Global styles={globalStyles} />
      <style>{`@media print {#closePrintButton{display: none;}}`}</style>

      <div css={classes.logo}>
        {/*
         // @ts-expect-error TS2322 */}
        <img src={logoSrcPath} alt="" />
      </div>

      <h2>オーダーシート</h2>

      <div css={classes.box}>
        {props.purchasedGoodsStatus.map((item, index) => {
          _total += item.price_jpy * item.count;
          return (
            <div css={classes.goods} key={index}>
              <div css={classes.goodsName}>
                <p>{zenkana2Hankana(item.name)}</p>
                {item.subClassName.length > 0 && (
                  <p css={classes.goodsSubName}>
                    （{zenkana2Hankana(item.subClassName)}）
                  </p>
                )}
              </div>
              <p css={classes.price}>
                {(item.price_jpy * item.count).toLocaleString()}円
              </p>
              <p css={classes.goodsCount}> x {item.count}</p>
            </div>
          );
        })}
      </div>

      <div css={classes.total}>
        <p className="headline">合計金額</p>
        <p className="total">{_total.toLocaleString()}円</p>
      </div>

      <div css={classes.caution}>
        こちらのオーダーシートは商品の取り置き・購入をお約束するものではありません。
        <br />
        また事前のお支払いはできません。
        <br />
        会場で売り切れ・欠品が出た場合はご購入いただけませんので、予めご了承ください。
      </div>

      <Button
        id={"closePrintButton"}
        text={"閉じる"}
        color={"#E84B0F"}
        onClick={props.closePrint}
      />
    </div>
  );
};

const globalStyles = css`
  header,
  footer,
  #alert-bar {
    display: none !important;
  }
`;

const styles = () => {
  return {
    root: css`
      width: 100%;
      max-width: 500px;
      min-height: 100vh;
      padding: 24px 5%;
      margin: 0 auto;
      background-color: #fff !important;
      h2 {
        margin-bottom: 16px;
        text-align: center;
      }
    `,
    logo: css`
      width: 90%;
      margin: 0 auto 8px;
      img {
        width: 100%;
      }
    `,
    box: css`
      width: 100%;
      max-width: 640px;
      margin: 0 auto;
      margin-bottom: 16px;
    `,
    day: css`
      margin-bottom: 8px;
      font-size: 12px;
      font-weight: 700;
    `,
    name: css`
      font-weight: 700;
      line-height: 1.3em;
    `,
    event: css`
      margin: 0 auto 14px;
      border-bottom: 1px solid #000;
      padding-bottom: 14px;
    `,
    goods: css`
      display: grid;
      grid-template-columns: 1fr auto auto;
      grid-column-gap: 8px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    `,
    goodsName: css`
      p {
        font-size: 12px;
        line-height: 1.5em;
        letter-spacing: -0.5px;
        word-wrap: break-word;
      }
    `,
    goodsSubName: css`
      font-size: 12px;
    `,
    price: css`
      font-size: 12px;
      text-align: right;
      line-height: 1.5em;
    `,
    goodsCount: css`
      font-size: 12px;
      text-align: right;
      line-height: 1.5em;
    `,
    total: css`
      margin-bottom: 32px;
      text-align: right;
      .headline {
        margin-bottom: 8px;
        font-weight: 700;
      }
      .total {
        font-weight: 700;
      }
    `,
    caution: css`
      margin-bottom: 40px;
      font-size: 12px;
      letter-spacing: 0px;
      line-height: 1.2em;
    `,
  };
};
