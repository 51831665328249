import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/react";

export const HeaderSpace = styled.div`
  height: 60px;
`;
export const Btn = styled.input`
  display: block;
  padding: 2.93vw 0;
  border-radius: 1.07vw;
  border-style: none;
  background-color: #151515;
  color: #ffffff;
  text-align: center;
  font-size: 3.2vw;
  font-weight: bold;
  margin: 3.23vw auto;
  box-sizing: border-box;
  width: 75vw;
`;

export const cssModalPopupBox = css`
  width: 90vw;
  padding: 32px;
  outline: none;
  position: absolute;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  box-sizing: content-box;
  background: rgba(255, 255, 255, 0.9);
  color: #333;
`;
export const cssOverlay = css`
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.97);
  padding: 4vw 0 6.13vw;
  color: #333;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  h1 {
    font-size: 4.8vw;
    margin: 0 auto 7.6vw;
    text-align: center;
  }
  h2 {
    font-size: 4vw;
    width: 75.73vw;
    margin: 0 auto;
  }
  h3 {
    font-size: 3.2vw;
    width: 75.73vw;
    margin: 7.73vw auto 1.6vw;
  }
  p {
    font-size: 2.67vw;
    width: 75.73vw;
    margin: 0 auto 2.1vw;
  }
`;
export const keyloadSicle = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;
export const LoaderSmall = styled.div`
  border-radius: 50%;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(11, 86, 113, 1);
  border-right: 1.1em solid rgba(11, 86, 113, 0.8);
  border-bottom: 1.1em solid rgba(11, 86, 113, 0.6);
  border-left: 1.1em solid rgba(11, 86, 113, 0.3);
  transform: translateZ(0);
  animation: ${keyloadSicle} 1.1s infinite linear;
  animation: ${keyloadSicle} 1.1s infinite linear;
  width: 4vw;
  height: 4vw;
  margin: 0;
  margin-left: 3vw;
  border-width: 1vw;
`;

/**
 * React 書式 css
 */
export const DialogStyles = {
  width: "100%",
  maxWidth: " 800px",
  margin: "0",
  overflowY: "auto",
};
