import { useState, useEffect } from "react";
import firebase from "firebase/app";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "../../modules/event";
import { streamingActions } from "../../modules/streaming";
import { Store } from "../../store";
import { firestoreActions } from "modules/firestore";
import { useHistory } from "react-router";
import { fetchFirestoreCollection } from "utility/firebase";
import { DocumentReference } from "@google-cloud/firestore";
import type { EventVideo } from "@spwn/types/firebase/firestore";

type Video = EventVideo & {};

export type VideoErrorType = "unknown" | "needTicket" | "notStreamPageOpen";

/**
 * fetch data to watch video.
 * 1. fetch target video from URL path
 * 2. change target video as needed
 *  - if fes event, target video is stage live video
 * 3. set target video as current video.
 * @param eventId
 * @returns
 */
export const useVideoAuthenticator = () => {
  const videoId = useSelector((state: Store) => {
    // このカスタムフックは /videos のURLでのみ機能させる想定
    if (state.router.location.pathname.indexOf("/videos") === -1) return null;
    return state.router.location.pathname.split("/").slice(-1)[0];
  });
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const eventVideoMap = useSelector(
    (state: Store) => state.streaming.eventVideoMap
  );
  const streamingKey = useSelector(
    (state: Store) => state.streaming.streamingKey
  );
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);
  // @ts-expect-error TS2345
  const [video, setVideo] = useState<Video>(null);
  // @ts-expect-error TS2345
  const [error, setError] = useState<VideoErrorType>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * fetch all video data, when videoId is changed.
   */
  useEffect(() => {
    (async () => {
      try {
        if (!videoId) return;
        // fetch target video
        const eventVideo = await fetchEventVideo(videoId);
        if (!eventVideo) return;
        const { eid } = eventVideo;
        dispatch(eventActions.watchOneEvent.started({ eventId: eid }));
        dispatch(streamingActions.watchEventVideos.started({ eventId: eid }));
        dispatch(streamingActions.getStreamingKey.started({ eid }));
      } catch (e) {
        console.error(e);
        setError("unknown");
      }
    })();
    return () => {
      // ページローディングが走らないようにonSnapshotのみclose。
      dispatch(firestoreActions.closeChannel({ channel: "oneEvent" }));
      dispatch(firestoreActions.closeChannel({ channel: "eventVideos" }));
    };
  }, [dispatch, videoId]);

  /**
   * displayEvent, eventVideoMap, streamingKeyの状態によって、視聴するvideoを選択する
   */
  useEffect(() => {
    if (!videoId || !displayEvent || !eventVideoMap || !streamingKey) return;

    // 開場していなければイベントページへリダイレクト（admin userの場合は開場しているかどうかは無視する）
    if (displayEvent && !displayEvent.isOpenStreamingPage && !isAdmin) {
      setError("notStreamPageOpen");
      return;
    }
    // 配信URLが取得できるか
    if (streamingKey?.isError) {
      if (streamingKey.msg && !streamingKey.hasTickets) {
        setError("needTicket");
        return;
      }
      // `isError` しか存在しない場合は意図しないエラー。
      setError("unknown");
      return;
    }

    (async () => {
      const currentVideo = eventVideoMap[videoId];

      // if current video is archived video, watch the video.
      // admin userであればライブ・アーカイブに関わらず、URLのvideoを設定する
      // @ts-expect-error TS18048
      if ((currentVideo.isOpen && currentVideo.hasVOD) || isAdmin) {
        // @ts-expect-error TS2345
        setVideo(currentVideo);
        return;
      }

      // if fes event, check if current video is live in the video stage.
      // if not live in current video stage, push to live video.
      if (displayEvent.isFesEvent) {
        // videoが所属するstageのvideosから、ライブ配信中のvideoを選択し
        // 現在選択中のvideoId出なかったら該当のvideoIdのURLに変更する。
        const stageVideoRefs = await fetchFirestoreCollection<{
          ref: DocumentReference;
          priority: number;
          // @ts-expect-error TS18048
        }>(`stages/${currentVideo.stageRef.id}/videos`);
        const stageVideos = await Promise.all(
          stageVideoRefs.map(async (v) => {
            const snapshot = await v.ref.get();
            return {
              ...(snapshot.data() as EventVideo),
              _id: snapshot.id,
              _refPath: snapshot.ref.path,
            };
          })
        );
        // stage videoの、最新（開場時間が遅い）で配信中のvideoを選択。
        const stageLiveVideo = stageVideos
          .sort((a, b) => b.startAt.seconds - a.startAt.seconds)
          .find((el) => el.isOpen);
        // @ts-expect-error TS18048
        const stageLiveVideoId = stageLiveVideo._id;
        if (stageLiveVideoId !== videoId) {
          history.replace(`/videos/${stageLiveVideoId}`);
        } else {
          // @ts-expect-error TS2345
          setVideo(currentVideo);
        }
        return;
      }

      // if current video is live video, watch the video.
      // @ts-expect-error TS18048
      if (currentVideo.isOpen) {
        // @ts-expect-error TS2345
        setVideo(currentVideo);
        return;
      }

      // cannot watch video.
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayEvent, eventVideoMap, streamingKey, videoId]);

  return {
    video,
    displayEvent,
    streamingKey,
    eventVideoMap,
    error,
  };
};

/**
 * fetch target video by videoId
 * @param videoId
 * @returns
 */
const fetchEventVideo = async (videoId: string): Promise<EventVideo> => {
  const snapshot = await firebase
    .firestore()
    .collectionGroup("videos")
    .where("id", "==", videoId)
    .orderBy("eid")
    .get();
  const [doc] = snapshot.docs;
  // @ts-expect-error TS2322
  if (!doc?.exists) return null;
  return {
    ...(doc.data() as EventVideo),
    _id: doc.id,
    _refPath: doc.ref.path,
  };
};
