/** msの単位変換用 */
const formatMilliseconds = (ms: number): string => {
  const msPerSecond = 1000;
  const msPerMinute = msPerSecond * 60;
  const msPerHour = msPerMinute * 60;

  let formattedTime = "";

  const hours = Math.floor(ms / msPerHour);
  if (hours > 0) {
    formattedTime += `${hours}h `;
    ms -= hours * msPerHour;
  }

  const minutes = Math.floor(ms / msPerMinute);
  if (minutes > 0) {
    formattedTime += `${minutes}m `;
    ms -= minutes * msPerMinute;
  }

  const seconds = Math.floor(ms / msPerSecond);
  if (seconds > 0) {
    formattedTime += `${seconds}s `;
    ms -= seconds * msPerSecond;
  }

  if (ms > 0) {
    formattedTime += `${Math.ceil(ms * 100) / 100}ms`;
  }

  return formattedTime.trim();
};

type Span = {
  start: (log?: boolean) => Span;
  end: () => Span;
};

export const span: (name: string) => Span = (name: string) => {
  const self: Span = {
    start: (log?: boolean) => {
      performance.mark(`${name}:start`);
      if (log) console.info(`${name} start`);
      return self;
    },
    end: () => {
      performance.mark(`${name}:end`);
      performance.measure(`${name}:measure`, `${name}:start`, `${name}:end`);
      console.info(
        `${name} time`,
        formatMilliseconds(
          performance.getEntriesByName(`${name}:measure`)[0]?.duration ?? 0
        )
      );
      return self;
    },
  };
  return self;
};
