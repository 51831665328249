/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect, ComponentProps } from "react";
import { useSelector } from "react-redux";

import type { Goods, ProductData } from "@spwn/types/firebase/firestore";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { useI18n } from "hooks/i18n/i18n";
import { useGetPlaceList } from "hooks/event/useGetPlaceList";
import { Store } from "store";
import {
  getLastDirNameFromHref,
  convertMapToValues,
  createListSetOfField,
  getNowTimestamp,
} from "utility";
import { changeStyleWithHosting } from "utility/hosting";
import { EventGoodsItemInfo } from "./EventGoodsItemInfo";
import { EventGoodsItemSlider } from "./EventGoodsItemSlider";
import { EventCapsuleItem } from "./EventCapsuleItem";

export const EventGoodsItem: React.FC = () => {
  const [repGoods, setRepGoods] = useState<ProductData | null>(null);
  const [classGoodsList, setClassGoodsList] = useState<ProductData[]>([]);
  const [goodsName, setGoodsName] = useState<string | null>(null);
  const [selectablePlaces, setSelectedPlaces] = useState<
    ComponentProps<typeof EventGoodsItemInfo>["selectablePlaces"]
  >([]);

  const pathname = useSelector(
    (state: Store) => state.router.location.pathname
  );
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const goodsMap = useSelector((state: Store) => state.ticket.eventGoodsMap);
  const { selectedPlaceCode } = useSelector(
    (state: Store) => state.event.goodsPageSettings
  );

  const placeCodes = Object.values(goodsMap).map((el) => el.place);
  const { data: places } = useGetPlaceList(placeCodes);

  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);

  const classesPageLayout = pageLayout();
  const breadcrumbText =
    changeStyleWithHosting().commonSettings.menu.event.text;
  const { t } = useI18n();

  const watchPathname = () => {
    setGoodsName(getLastDirNameFromHref(pathname, "goods"));
  };
  useEffect(watchPathname, [pathname]);

  const watchRepGoods = () => {
    // 配送商品 → 自分が購入している会場の商品 → デフォルトの会場の商品の優先順
    if (goodsMap && goodsName) {
      const goods = convertMapToValues(goodsMap);
      const classNameGoodsList = goods.filter(
        (el) =>
          ((el.display && el.releaseDateTime.seconds <= getNowTimestamp()) ||
            (el.display && isAdmin)) &&
          el.className === goodsName
      );
      const classGoodsList = classNameGoodsList
        .filter((goods) => goods.place === selectedPlaceCode)
        .sort((prev, cur) => prev.priority - cur.priority);
      const [repGoods] = classGoodsList;
      setClassGoodsList(classGoodsList);
      setRepGoods(repGoods ?? null);
      // 名前が同じでないと、同じ商品であっても場所選択候補として一緒に入らない仕様。
      setSelectedPlaces(
        createListSetOfField(classNameGoodsList, "place").map((el) => {
          const place = places?.find((place) => place.code === el.place);
          return {
            name: place?.name ?? "",
            placeCode: el.place,
          };
        })
      );
    }
  };
  useEffect(watchRepGoods, [
    goodsMap,
    goodsName,
    selectedPlaceCode,
    places,
    isAdmin,
  ]);

  if (!goodsMap || !repGoods) {
    return (
      <div id="contents">
        <LoaderLayout />
      </div>
    );
  }

  return (
    <div css={classesPageLayout.root}>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/events", breadcrumbText],
          ["/events/" + displayEvent.eventId, displayEvent.eventTitle],
          [
            "/events/" + displayEvent.eventId + "/goods",
            t("common.routes.goodsList"),
          ],
          [null, repGoods?.name],
        ]}
      />

      <div css={classesPageLayout.container}>
        <EventGoodsItemSlider repGoods={repGoods} />

        {displayEvent?.isCapsuleGoodsSellingEnabled ? (
          <EventCapsuleItem
            eventId={displayEvent.eventId}
            classGoodsList={classGoodsList as Goods[]}
            repGoods={repGoods as Goods}
            selectablePlaces={selectablePlaces}
            isAdmin={isAdmin}
          />
        ) : (
          <EventGoodsItemInfo
            eventId={displayEvent.eventId}
            classGoodsList={classGoodsList}
            repGoods={repGoods}
            selectablePlaces={selectablePlaces}
            isAdmin={isAdmin}
          />
        )}
      </div>
    </div>
  );
};

const pageLayout = () => {
  return {
    root: css`
      margin: 0 auto;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        max-width: 1800px;
        width: 100%;
      }
    `,
    container: css`
      max-width: 1120px;
      width: 100%;
      padding: 40px 0 80px;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        width: 95%;
      }
    `,
  };
};
