import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import firebase from "firebase/app";

import type { Event, TapPublicData } from "@spwn/types/firebase/firestore";

import { liveActions } from "modules/live";
import { AllChannel, firestoreActions } from "modules/firestore";
import { Store } from "store";
import { isSmartPhone, getEventDisplayInfo } from "utility";

import tap_able from "designs/images/live/tap_button_able.png";
import tap_disable from "designs/images/live/tap_button_disable.png";
import tap_normal from "designs/images/live/tap_button_normal.png";

interface Props {
  contextId: string;
  event: Event;
}

const Container = styled.div`
  padding-bottom: 35px;
  .tapEventThumbnail {
    width: 100%;
    margin-bottom: 30px;
    @media screen and (min-width: 768px) {
      display: block;
      width: 500px;
      margin: 0 auto 50px;
    }
  }
  .tapEventText {
    width: 90%;
    margin: 0 auto 20px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    line-height: 1.5em;
  }
  .tapButton {
    display: block;
    width: 150px;
    margin: 0 auto;
  }
`;

const TapEvent: React.FC<Props> = (props) => {
  const surveyData = useSelector(
    (state: Store) => state.live.surveyData
  ) as TapPublicData;
  const dispatch = useDispatch();
  const [isTouchStatus, setTouchStatus] = useState<boolean>(false);
  const [contextId, setContextId] = useState<string>(props.contextId);
  const [countRate, setCountRate] = useState<number>(0);

  const watchContextId = () => {
    dispatch(
      liveActions.watchSurveyData.started({
        nextContextId: props.contextId,
        currentContextId: contextId,
      })
    );
    setContextId(props.contextId);
    return () => {
      dispatch(
        firestoreActions.closeChannel({
          channel: props.contextId as AllChannel,
        })
      );
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(watchContextId, [props.contextId]);

  const pressedButton = () => {
    if (!props.event._id) {
      return;
    }
    setTouchStatus(true);
    countTapNum(props.event._id, props.contextId, surveyData);
  };

  const releasedButton = () => {
    setTouchStatus(false);
  };

  const countTapNum = (
    eventId: string,
    contextId: string,
    surveyData: TapPublicData
  ) => {
    if (countRate === surveyData.countRate - 1) {
      const shardId = Math.floor(Math.random() * surveyData.nShards);
      firebase
        .firestore()
        .doc(`/clicks/${eventId}/${contextId}/results/shards/${shardId}`)
        .set(
          {
            count: firebase.firestore.FieldValue.increment(
              surveyData.countRate
            ),
          },
          { merge: true }
        );
      setCountRate(0);
    } else {
      setCountRate(countRate + 1);
    }
  };

  if (!props.event) {
    return null;
  }
  const eventInfo = getEventDisplayInfo(props.event);

  let buttonImage = null;
  if (isTouchStatus) {
    buttonImage = tap_able;
  } else {
    buttonImage = tap_normal;
  }

  return (
    <Container>
      <img
        src={eventInfo.thumbnail}
        alt={eventInfo.title}
        className="tapEventThumbnail"
      />
      {surveyData && surveyData.isStart ? (
        <EventTapButton
          surveyData={surveyData}
          buttonImage={buttonImage}
          pressedButton={pressedButton}
          releasedButton={releasedButton}
        />
      ) : (
        <>
          <p className="tapEventText">
            イベント開始までしばらくお待ちください。
          </p>
          <img
            className="tapButton"
            id="tapButton"
            src={tap_disable}
            alt="TAP"
          />
        </>
      )}
    </Container>
  );
};
export default TapEvent;

// @ts-expect-error TS7006
const EventTapButton = (props) => {
  return (
    <>
      <p className="tapEventText">{props.surveyData.content}</p>
      {isSmartPhone() ? (
        <img
          id="tapButton"
          src={props.buttonImage}
          alt="TAP"
          onTouchStart={props.pressedButton}
          onTouchEnd={props.releasedButton}
        />
      ) : (
        <img
          id="tapButton"
          src={props.buttonImage}
          alt="TAP"
          onMouseDown={props.pressedButton}
          onMouseUp={props.releasedButton}
        />
      )}
    </>
  );
};
