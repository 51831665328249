/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

import { PortalTopSliderFetcher } from "./PortalTopSlider";
import { PortalTopInformationFetcher } from "./PortalTopInformation";
import { PortalTopFanclubFetcher } from "./PortalTopFanclub";
import { PortalTopArchiveEventsFetcher } from "./PortalTopArchiveEvents";
import { PortalTopUpcomingEventsFetcher } from "./PortalTopUpcomingEvents";
import { PortalTopCapsuleFetcher } from "./PortalTopCapsule";
import { PortalTopGoodsFetcher } from "./PortalTopGoods";

export const PortalTopFetcher: React.FC = () => {
  return <PortalTopLogic />;
};

const PortalTopLogic: React.FC = () => {
  return <PortalTop />;
};

const PortalTop: React.FC = () => {
  return (
    <React.Fragment>
      <main
        css={css`
          padding-bottom: 120px;
        `}
      >
        <PortalTopSliderFetcher />

        {/* CREW */}
        <section
          css={css`
            padding-top: 60px;
            @media screen and (min-width: 768px) {
              padding-top: 80px;
            }
          `}
        >
          <PortalTopFanclubFetcher />
        </section>

        {/* Upcoming Event */}
        <section
          css={css`
            padding-top: 60px;
            @media screen and (min-width: 768px) {
              padding-top: 80px;
            }
          `}
        >
          <PortalTopUpcomingEventsFetcher />
        </section>

        {/* Archive */}
        <section
          css={css`
            padding-top: 60px;
            @media screen and (min-width: 768px) {
              padding-top: 80px;
            }
          `}
        >
          <PortalTopArchiveEventsFetcher />
        </section>

        {/* GOODS */}
        <section
          css={css`
            padding-top: 60px;
            @media screen and (min-width: 768px) {
              padding-top: 80px;
            }
          `}
        >
          <PortalTopGoodsFetcher />
        </section>

        {/* CAPSULE */}
        <section
          css={css`
            padding-top: 60px;
            @media screen and (min-width: 768px) {
              padding-top: 80px;
            }
          `}
        >
          <PortalTopCapsuleFetcher />
        </section>

        {/* お知らせ */}
        <section
          css={css`
            padding-top: 60px;
            @media screen and (min-width: 768px) {
              padding-top: 80px;
            }
          `}
        >
          <PortalTopInformationFetcher />
        </section>
      </main>
    </React.Fragment>
  );
};

/**
 * @description
 * PortalTopの各セクションのレイアウトを共通化するために、別コンポーネントに切り出し
 */
export const PortalTopSectionLayout: React.FC<{
  children: JSX.Element;
  heading: {
    mainText: string;
    subText: string;
  };
}> = ({ children, heading }) => {
  return (
    <div
      css={css`
        width: 90%;
        max-width: 1050px;
        margin: 0 auto;
      `}
    >
      <div
        css={css`
          @media screen and (min-width: 768px) {
            display: flex;
            align-items: center;
          }
          h2 {
            font-family: "din-condensed", sans-serif;
            font-style: normal;
            padding: 4px 0 4px 16px;
            margin-bottom: 5px;
            color: #fff;
            font-size: 24px;
            letter-spacing: 3px;
            border-left: 5px solid #00c2ad;
            @media screen and (min-width: 768px) {
              padding: 0 0 0 16px;
              margin-bottom: 0;
              font-size: 32px;
            }
          }
          p {
            padding-left: 21px;
            color: #fff;
            font-size: 16px;
          }
        `}
      >
        <h2>{heading.mainText}</h2>
        <p>{heading.subText}</p>
      </div>

      <div
        css={css`
          margin-top: 20px;
        `}
      >
        {children}
      </div>
    </div>
  );
};
