import React, { Children, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../../store";
import { firestoreActions } from "../../../modules/firestore";
import { ticketActions } from "../../../modules/ticket";
import { purchaseActions } from "modules/purchase";

type Props = {};

const AccountTicketBase: React.FC<Props> = (props) => {
  const myTickets = useSelector((state: Store) => state.ticket.myTickets);
  const isLogined = useSelector((state: Store) => state.auth.user.uid);

  const dispatch = useDispatch();

  const didMount = () => {
    if (isLogined) {
      dispatch(ticketActions.fetchMyTickets.started({ callConfirmTran: true }));
      dispatch(purchaseActions.fetchUserActiveTransactions.started());
    }
  };
  useEffect(didMount, [isLogined, dispatch]);

  // onSnapshotを使ってチケットの状態を監視
  // FIXME: /account/ticket/:eventIdだけでいいかもしれない
  useEffect(() => {
    // @ts-expect-error TS7034
    const issuedTicketsChannelNames = [];
    // @ts-expect-error TS7034
    const sharedTicketsChannelNames = [];
    if (myTickets) {
      // openしているイベントだけでいい
      const watchEventIds = [];
      for (const ticketData of myTickets) {
        if (ticketData.event.isClosed) {
          continue;
        }
        const eventId = ticketData.event._id;
        watchEventIds.push(eventId);
        issuedTicketsChannelNames.push(`${eventId}_IssuedTickets`);
        sharedTicketsChannelNames.push(`${eventId}_SharedTickets`);
        dispatch(ticketActions.watchTicketStates.started({ eventId }));
        dispatch(ticketActions.watchSharedTicketStates.started({ eventId }));
      }
      // use for check if watching all event is done
      dispatch(
        ticketActions.startWatchingIssuedTicket({ eventIds: watchEventIds })
      );
    }
    return () => {
      // @ts-expect-error TS7005
      for (const channel of issuedTicketsChannelNames) {
        dispatch(firestoreActions.closeChannel({ channel }));
      }
      // @ts-expect-error TS7005
      for (const channel of sharedTicketsChannelNames) {
        dispatch(firestoreActions.closeChannel({ channel }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTickets]);

  const newProps = {
    parentProps: {},
  };

  const childrenWithProps = Children.map(
    // @ts-expect-error TS2345
    props.children,
    (child: string | ReactElement) => {
      switch (typeof child) {
        case "string":
          return child;
        case "object":
          return React.cloneElement(child, newProps);
        default:
          return null;
      }
    }
  );

  return <>{childrenWithProps}</>;
};

export default AccountTicketBase;
