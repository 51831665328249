import { useState, useCallback } from "react";
import firebase from "firebase/app";
import { useDispatch } from "react-redux";
import { modalActions } from "modules/modal";
import type { VoucherState } from "@spwn/types";
import { SelectedEvent } from "../../components/voucher/VoucherEvent";
import { loadingActions } from "../../modules/loading";
import { ticketActions } from "../../modules/ticket";

export enum Status {
  CODE = 0,
  EVENT = 1,
  COMPLETE = 2,
}

export type VoucherErrorType = "unknown";

export const useVoucherCode = ({
  onReveal,
  onRegister,
}: {
  onReveal: () => void;
  onRegister: () => void;
}) => {
  const dispatch = useDispatch();
  const [enableVoucherData, setEnableVoucherData] =
    // @ts-expect-error TS2345
    useState<VoucherState["enableVoucherData"]>(null);

  /**
   * @function バウチャーコードから閲覧可能なイベントとビデオを取得する
   * @param voucherCode: string
   */
  const fetchVoucherCodeEvent = useCallback(async (voucherCode: string) => {
    try {
      dispatch(loadingActions.toggleLoading({ msg: "イベント取得中" }));

      if (!voucherCode) {
        throw new Error("バウチャーコードを入力してください");
      }

      const { data } = await firebase
        .functions()
        .httpsCallable("revealVoucherCodeInfo")({
        voucherCode,
      });

      setEnableVoucherData(data);
      onReveal();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        modalActions.toggleError({
          caption: error.message,
        })
      );
    } finally {
      dispatch(loadingActions.toggleLoading({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @function 選択したイベントをfirestoreに登録する
   * @param voucherCode: string
   * @param selectedEvent: SelectedEvent[]
   */
  const registerSelectVideo = useCallback(
    async (voucherCode: string, selectedEvent: SelectedEvent[]) => {
      try {
        dispatch(
          loadingActions.toggleLoading({
            msg: "チケットを追加しています。処理が完了するまで少々お時間頂く場合がございます。",
          })
        );

        const tickets = [];
        for (let i = 0; i < selectedEvent.length; i++) {
          // @ts-expect-error TS2532
          for (let j = 0; j < selectedEvent[i].tickets.length; j++) {
            const ticket = {
              // @ts-expect-error TS2532
              eventId: selectedEvent[i].eventId,
              // @ts-expect-error TS2532
              ticketId: selectedEvent[i].tickets[j].id,
              // @ts-expect-error TS2532
              num: selectedEvent[i].tickets[j].num,
            };
            tickets.push(ticket);
          }
        }

        await firebase.functions().httpsCallable("redeemVoucherCode")({
          tickets,
          code: voucherCode,
        });

        onRegister();
        /**
         * @description to update myTickets
         */
        dispatch(ticketActions.fetchMyTickets.started({}));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        dispatch(modalActions.toggleError({ caption: error.message }));
      } finally {
        dispatch(loadingActions.toggleLoading({}));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    fetchVoucherCodeEvent,
    enableVoucherData,
    registerSelectVideo,
  };
};
