/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Breadcrumb } from "components/atoms/Breadcrumb";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { TenantGoodsSlider } from "./TenantGoodsSlider";
import { TenantGoodsInfo } from "./TenantGoodsInfo";
import { Redirect, useParams } from "react-router";
import { useGetGoods } from "./useGetGoods";
import type { Goods } from "./goodsModel";

type Props = {
  tenantId: string;
  brandId: string;
  shopId: string;
};

export const TenantGoodsContainer: React.FC<Props> = (props) => {
  const { goodsId } = useParams<{ goodsId: string }>();
  const { goods, loading, error } = useGetGoods({
    ...props,
    goodsId,
  });
  if (error) {
    console.log(error);
    return <Redirect to="/" />;
  }

  if (loading || !goods) {
    return (
      <div>
        <LoaderLayout />
      </div>
    );
  }
  return <TenantGoods {...props} goods={goods} />;
};

type TenantGoodsProps = {
  tenantId: string;
  brandId: string;
  shopId: string;
  goods: Goods;
};
export const TenantGoods: React.FC<TenantGoodsProps> = (props) => {
  const { goods } = props;
  const classesPageLayout = pageLayout();

  return (
    <div css={classesPageLayout.root}>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/shop", "ショップ"],
          [null, goods.name],
        ]}
      />

      <div css={classesPageLayout.container}>
        <TenantGoodsSlider {...{ goods }} />
        <TenantGoodsInfo {...{ goods }} />
      </div>
    </div>
  );
};

const pageLayout = () => {
  return {
    root: css`
      margin: 0 auto;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        max-width: 1800px;
        width: 100%;
      }
    `,
    container: css`
      max-width: 1120px;
      width: 100%;
      padding: 40px 0 80px;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        width: 95%;
      }
    `,
  };
};
