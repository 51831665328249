/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

interface Props {}
export const MainContents: React.FC<Props> = (props) => {
  const classesMainContentsStyles = mainContentsStyles();
  return (
    <main id="main-contents" css={classesMainContentsStyles.root}>
      {props.children}
    </main>
  );
};

const mainContentsStyles = () => {
  return {
    root: css`
      min-height: calc(100vh - 65px - 190px);
    `,
  };
};
