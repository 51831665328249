/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { TFunction, i18n } from "i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { Drawer } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import withStyles, { StyleRules } from "@material-ui/core/styles/withStyles";
import HomeIcon from "@material-ui/icons/Home";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import EventIcon from "@material-ui/icons/Event";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import TranslateIcon from "@material-ui/icons/Translate";

import { SPView } from "components/common/MediaQuery";
import { LanguageDropdownMenu } from "components/common/LanguageDropdownMenu";
import { SideMenuTodayEvent } from "features/SideMenuTodayEvent";
import { adminActions } from "modules/admin";
import { authActions } from "modules/auth";
import { menuActions } from "modules/menu";
import { modalActions } from "modules/modal";
import { Store } from "store";
import { changeStyleWithHosting } from "utility/hosting";
import playIcon from "designs/images/play_icon.png";

const styles: StyleRules = createStyles({
  // drawerRoot: {
  //   top: '65px',
  //   width: '400px',
  //   color: '#fff',
  //   backgroundColor: '#0f1213',
  // },
  // listIconRoot: {
  //   minWidth: '10vw',
  // },
  // listTextRoot: {
  //   fontSize: '14px',
  // },
});

const SideHeaderMenuNav = styled.nav`
  height: 100vh;
`;

const SideMenuUl = styled.ul`
  margin-bottom: 20px;
  background-color: #111314;
  li {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: #fff;
    font-size: 12px;
    border-bottom: 1px solid #181b1b;
    letter-spacing: 1px;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      padding: 10px 40px;
    }
    svg {
      margin-right: 7%;
    }
    a {
      color: #fff;
    }
  }
`;

interface Props extends WithTranslation {
  t: TFunction;
  i18n: i18n;
  classes: unknown;
  user: { uid: string };
  toggleSideMenu: () => void;
  toggleLoginMethod: () => void;
  logout: () => void;
  // @ts-expect-error TS7051
  changeRoute: (string) => void;
  setI18nLang: (str: string) => void;
  isOpenSideMenu: boolean;
}

interface States {}

class TransSideMenu extends Component<Props, States> {
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  login = () => {
    this.props.toggleLoginMethod();
    this.props.toggleSideMenu();
  };

  render() {
    const { isOpenSideMenu, t } = this.props;
    const hostingSidemenuStyleMap =
      changeStyleWithHosting().commonSettings.menu;

    return (
      <Drawer
        open={isOpenSideMenu}
        ModalProps={{ onBackdropClick: this.props.toggleSideMenu }}
        PaperProps={{ style: { backgroundColor: "#181b1b" } }}
      >
        <SideHeaderMenuNav>
          <SideMenuTodayEvent />

          <SideMenuUl>
            {hostingSidemenuStyleMap.home.isDisplay ? (
              <li
                onClick={() => {
                  this.clickMenuItem("/");
                }}
              >
                <HomeIcon />
                {t("common.sidebar.nav.home")}
              </li>
            ) : null}

            {hostingSidemenuStyleMap.event.isDisplay ? (
              <li
                onClick={() => {
                  this.clickMenuItem("/events");
                }}
              >
                <EventIcon />
                {hostingSidemenuStyleMap.event.text}
              </li>
            ) : null}
            {hostingSidemenuStyleMap.archive.isDisplay ? (
              <li
                onClick={() => {
                  this.clickMenuItem("/vod-events");
                }}
              >
                <VideoLibraryIcon />
                {hostingSidemenuStyleMap.archive.text}
              </li>
            ) : null}
          </SideMenuUl>

          <SideMenuUl>
            <li
              onClick={() => {
                this.clickMenuItem("/account");
              }}
            >
              <AccountBoxIcon />
              {t("common.sidebar.nav.account")}
            </li>
            <li
              onClick={() => {
                this.clickMenuItem("/account/ticket");
              }}
            >
              <ConfirmationNumberIcon />
              {t("common.sidebar.nav.mytickets")}
            </li>
            <li
              onClick={() => {
                this.clickMenuItem("/account/ticket/vod");
              }}
              css={css`
                img {
                  width: 24px;
                  height: auto;
                  margin-right: 7%;
                }
              `}
            >
              <img src={playIcon} alt="" />
              {t("common.sidebar.nav.mylive")}
            </li>
            <li
              onClick={() => {
                this.clickMenuItem("/voucher");
              }}
            >
              <DeveloperModeIcon />
              {t("common.sidebar.nav.voucherCode")}
            </li>
          </SideMenuUl>

          <SideMenuUl>
            {this.props.user.uid ? (
              <li onClick={() => this.props.logout()}>
                <ExitToAppIcon />
                {t("account.account.logout")}
              </li>
            ) : (
              <li onClick={() => this.login()}>
                <ExitToAppIcon />
                {t("login.buttons.login")}
              </li>
            )}
          </SideMenuUl>

          {
            <SideMenuUl>
              <SPView>
                <ul className="sideMenuList">
                  <li>
                    <TranslateIcon />
                    <LanguageDropdownMenu />
                  </li>
                </ul>
              </SPView>
            </SideMenuUl>
          }
        </SideHeaderMenuNav>
      </Drawer>
    );
  }

  // @ts-expect-error TS7006
  clickMenuItem = (link) => {
    if (this.checkLogin(link)) {
      this.props.changeRoute(link);
      this.props.toggleSideMenu();
    } else {
      this.props.toggleLoginMethod();
      localStorage.setItem("redirectUrl", link);
    }
  };

  //ログイン済みであればtrueを返す
  // @ts-expect-error TS7006
  checkLogin = (path) => {
    const { user } = this.props;
    return (
      CHECK_LOGIN_PATH.find((item) => item === path) === undefined || user.uid
    );
  };
}

const mapStateToProps = (state: Store) => {
  return {
    isOpenSideMenu: state.menu.isOpenSideMenu,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    // @ts-expect-error TS7006
    changeRoute: (link) => {
      dispatch(push(link));
    },
    toggleSideMenu: () => {
      dispatch(menuActions.toggleSideMenu());
    },
    toggleLoginMethod: () => {
      dispatch(modalActions.toggleLoginMethod());
    },
    logout: () => {
      dispatch(authActions.logout.started());
    },
    setI18nLang: (lang: string) => {
      dispatch(adminActions.setI18nLang(lang));
    },
  };
};

const SideMenu = withTranslation()(TransSideMenu);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SideMenu));

const CHECK_LOGIN_PATH = ["/account"];
