import useSWR from "swr";
import firebase from "firebase/app";
import {
  GetBrandBySlugRequest,
  GetBrandBySlugResponse,
} from "@spwn/types/functions";
import { Brand } from "./brandModel";
import { getSPWNSlug } from "utility/getSPWNSlug";

type GetBrandQuery = {
  slug: string;
};

const generateKey = (query: GetBrandQuery) => ({
  key: "useGetBrand",
  slug: query.slug,
});

export const useGetBrand = (): {
  brand: Brand | undefined;
  error: Error | undefined;
} => {
  // https://hoge.fuga.spwn.jp:443 の hoge.fuga.spwn.jp が `hostname`
  // const slug = getSPWNSlug(window.location.hostname);
  const { hostname } = new URL(window.location.href);
  const slug =
    hostname === "localhost"
      ? "dev-brand-a"
      : getSPWNSlug(window.location.hostname);

  const key = generateKey({ slug });
  const result = useSWR<Brand, Error, GetBrandQuery>(
    key,
    async ({ slug }: GetBrandQuery) => {
      if (slug === "") {
        throw new Error(
          "spwn.jp以外のサイトのためブランドを取得できませんでした"
        );
      }
      const { data } = await callGetBrandBySlug({ slug });
      if (data === null) {
        throw new Error(`slug: ${slug} のブランドが見つかりませんでした`);
      }
      // BrandDTO -> BrandModel への変換
      return {
        id: data.id,
        slug: data.slug,
        tenant: {
          id: data.tenant.id,
          slug: data.tenant.slug,
        },
        shops: data.shops.map((shop) => ({
          id: shop.id,
        })),
      };
    }
  );

  return {
    brand: result.data,
    error: result.error,
  };
};

const callGetBrandBySlug = async (
  request: GetBrandBySlugRequest
): Promise<{ data: GetBrandBySlugResponse }> => {
  return firebase.functions().httpsCallable("getBrandBySlug")(request);
};
