import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * @see https://v5.reactrouter.com/web/example/query-parameters
 */
export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};
