/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "../../../store";
import { push } from "connected-react-router";
import { useGoodsSerachQuery } from "hooks/QueryString";
import { createListSetOfField, getNowTimestamp } from "../../../utility";
import { ticketState } from "modules/ticket";
import { GoodsSearchResult } from "components/event/goods/GoodsSearchResult";
import { useI18n } from "hooks/i18n/i18n";
import tifTopBanner from "designs/images/capsule/spwn-capsule-tif.png";
import capsuleTopBanner from "designs/images/external/capsule/goods-top-banner.jpg";

import { GoodsListCapsuleItem } from "components/event/goods/GoodsListCapsuleItem";
import type { ProductData } from "@spwn/types/firebase/firestore";

type EventCapsuleListProps = {
  goods: ticketState["eventGoods"];
  eventId: string;
};
export const EventCapsuleList: React.FC<EventCapsuleListProps> = ({
  eventId,
  goods,
}: EventCapsuleListProps) => {
  const { selectedPlaceCode } = useSelector(
    (state: Store) => state.event.goodsPageSettings
  );

  const [searchResultsNum, setSearchResultsNum] = useState<number>(0);
  const [displayResult, setDisplayResult] = useState<ProductData[]>([]);

  const dispatch = useDispatch();
  const { search } = useGoodsSerachQuery();
  const classesCapsuleList = capsuleList();

  const { t } = useI18n();

  const setDisplayGoods = () => {
    // 配送商品、会場受け取り、タグでのソートを行う
    const filteredGoodsList = goods.filter((goods) => {
      // filter by place and sale date
      const isDisplay = goods.place === selectedPlaceCode;
      // filter by search query if present
      if (search) {
        return (
          isDisplay && goods.hashtags && goods.hashtags.indexOf(search) !== -1
        );
      }
      return isDisplay;
    });

    // make class remaining map
    const classGoodsRemainingMap: { [cid: string]: number[] } = {};
    filteredGoodsList.forEach((goods) => {
      // @ts-expect-error TS2538
      if (!classGoodsRemainingMap[goods.class])
        // @ts-expect-error TS2538
        classGoodsRemainingMap[goods.class] = [];
      // @ts-expect-error TS2538
      classGoodsRemainingMap[goods.class].push(goods.remaining);
    });

    // make class list
    const classGoodsList = createListSetOfField(filteredGoodsList, "class");

    // sort
    const currentDate = getNowTimestamp();
    const sortSoldGoodsList = classGoodsList
      .filter((goods) => goods.closeDateTime.seconds > currentDate)
      .sort(
        (prev, cur) =>
          // TODO@later 管理ツールで並び替えができるようになったら、priorityだけ見るようにした方がいいかも。
          cur.releaseDateTime.seconds - prev.releaseDateTime.seconds ||
          prev.priority - cur.priority
      );
    const sortCurrentGoodsList = classGoodsList
      .filter((goods) => goods.closeDateTime.seconds <= currentDate)
      .sort(
        (prev, cur) =>
          cur.releaseDateTime.seconds - prev.releaseDateTime.seconds ||
          prev.priority - cur.priority
      );

    const concatGoodsList = sortSoldGoodsList.concat(sortCurrentGoodsList);

    const displayGoodsList = concatGoodsList.map((goods) => {
      // バリエーションがすべて売り切れの場合に売り切れ状態にする
      // @ts-expect-error TS2538
      const isSoldOut = classGoodsRemainingMap[goods.class]
        ? // @ts-expect-error TS2538
          classGoodsRemainingMap[goods.class].every(
            // @ts-expect-error TS7006
            (remaining) => remaining === -1
          )
        : false;
      return {
        ...goods,
        remaining: isSoldOut ? -1 : 1,
      };
    });

    setDisplayResult(displayGoodsList);
    if (search) {
      setSearchResultsNum(displayGoodsList.length);
    } else {
      setSearchResultsNum(0);
    }
  };
  useEffect(setDisplayGoods, [goods, selectedPlaceCode, search]);

  const clearSearch = () => {
    dispatch(push("/events/" + eventId + "/goods/"));
  };

  const moveDetailPage = (goodsName: string | null = null) => {
    if (!goodsName) {
      return;
    }
    dispatch(
      push("/events/" + eventId + "/goods/" + encodeURIComponent(goodsName))
    );
  };

  const displaySalesState = (
    releaseDateTime: number,
    closeDateTime: number,
    isSoldOut = false
  ) => {
    const datetime = releaseDateTime;
    const FewDaysLaterDatetime = datetime + 7 * 24 * 60 * 60;

    const currentDate = new Date(getNowTimestamp() * 1000);
    const releaseDate = new Date(datetime * 1000);
    const FewDaysLaterDate = new Date(FewDaysLaterDatetime * 1000);
    const closeDate = new Date(closeDateTime * 1000);

    const isNewRelease =
      releaseDate <= currentDate && currentDate <= FewDaysLaterDate;
    const isEndOfSale = closeDate < currentDate;

    let stateColor: string;
    let stateText: string;

    if (isEndOfSale) {
      stateColor = "#ff9d00";
      stateText = "販売期間外";
    } else if (isSoldOut) {
      stateColor = "#da3d50";
      stateText = "SOLD OUT";
    } else if (isNewRelease) {
      stateColor = "#25c2af";
      stateText = "NEW";
    } else {
      stateColor = "inherit";
      stateText = "";
    }

    return (
      <div
        css={classesCapsuleList.capsuleItemState(stateColor).capsuleItemState}
      >
        <p>{stateText}</p>
      </div>
    );
  };

  const getTopBanner = (eventId: string) => {
    return eventId === "21100109-tif" ? tifTopBanner : capsuleTopBanner;
  };

  return (
    <React.Fragment>
      <div css={classesCapsuleList.root}>
        <GoodsSearchResult
          search={search}
          searchResultsNum={searchResultsNum}
          clearSearch={clearSearch}
        />

        {/* グッズ一覧を表示する */}
        {displayResult && displayResult.length > 0 ? (
          <div css={classesCapsuleList.capsuleList}>
            <img
              css={classesCapsuleList.topBanner}
              src={getTopBanner(eventId)}
              alt="SPWNカプセルトップ画像"
            />
            {displayResult.map((item, index) => {
              const { className } = item;
              const releaseDateTime = item.releaseDateTime.seconds;
              const closeDateTime = item.closeDateTime.seconds;
              // カプセルグッズの場合そもそもhasVariationは使用されなさそう
              const hasVariation =
                !!item.subClassName && item.subClassName.length > 0;
              const isSoldOut = item?.remaining === -1;
              return (
                <GoodsListCapsuleItem
                  key={index}
                  item={item}
                  index={index}
                  hasVariation={hasVariation}
                  moveDetailPage={() => moveDetailPage(className)}
                  displaySalesState={displaySalesState(
                    releaseDateTime,
                    closeDateTime,
                    isSoldOut
                  )}
                />
              );
            })}
          </div>
        ) : (
          <div css={classesCapsuleList.notApplicable}>
            <p>{t("goods.search.noResult")}</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const capsuleList = () => {
  return {
    root: css`
      width: 100%;
      @media screen and (min-width: 768px) {
        width: calc(100% - 240px);
      }
    `,
    topBanner: css`
      width: 100%;
      max-width: 100%;
      margin-bottom: 40px;
    `,
    changePlaceName: css`
      width: 90%;
      margin: 0 auto 20px;
      font-size: 14px;
      @media screen and (min-width: 768px) {
        width: 100%;
      }
      span {
        margin-left: 15px;
        color: #25c2af;
      }
    `,
    capsuleList: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 100%;
      }
      &::after {
        display: block;
        content: "";
        width: 48%;
        @media screen and (min-width: 768px) {
          width: 32%;
        }
      }
    `,
    capsuleItemState: (stateColor: string) => {
      return {
        capsuleItemState: css`
          min-height: 24px;
          margin-bottom: 10px;
          p {
            display: inline-block;
            padding: 4px 8px;
            color: #fff;
            font-size: 12px;
            background-color: ${stateColor};
            @media screen and (min-width: 768px) {
              font-size: 10px;
            }
          }
        `,
      };
    },
    notApplicable: css`
      width: 90%;
      margin: 0 auto;
      p {
        font-size: 14px;
      }
    `,
  };
};
