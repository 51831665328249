import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { select, put, take, call, takeEvery } from "redux-saga/effects";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import { eventChannel } from "redux-saga";
import { isRegisteredChannel, firestoreActions } from "./firestore";

const actionCreator = actionCreatorFactory("news");

export const newsActions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watchTopInfo: actionCreator.async<any, any>("watchTopInfo"),
};

export interface newsState {
  topInfo: {
    featureEventIds: string[] | undefined;
  };
}

const initialState: newsState = {
  // @ts-expect-error TS2322
  topInfo: null,
};

const newsReducer = reducerWithInitialState(initialState).case(
  newsActions.watchTopInfo.done,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (state, payload: any) => {
    return { ...state, topInfo: payload };
  }
);

export default newsReducer;

export function* newsSaga() {
  yield takeEvery(newsActions.watchTopInfo.started, watchTopInfo);
}

function topInfoChannel() {
  return eventChannel((emitter) => {
    const unsubscribe = firebase
      .firestore()
      .doc("/news/topInfo")
      .onSnapshot(
        (snapshot) => {
          const data = snapshot.data();
          console.log("top info changed");
          emitter(data);
        },
        (error) => {
          console.error(error);
        }
      );
    return unsubscribe;
  });
}

function* watchTopInfo() {
  const {
    firestore: { channels },
  } = yield select();

  //既にlisten済ならreturn
  if (isRegisteredChannel(channels, "topInfo")) return;

  try {
    // @ts-expect-error TS7057
    const channel = yield call(topInfoChannel);
    // ループ回して変更をwatchする
    while (true) {
      // @ts-expect-error TS7057
      const topInfo = yield take(channel);
      yield put(newsActions.watchTopInfo.done(topInfo));
      yield put(firestoreActions.addChannel({ ...channel, name: "topInfo" }));
    }
  } catch (e) {
    console.error(e);
  }
}
