/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import type { ProductData } from "@spwn/types/firebase/firestore";

interface Props {
  purchasedProduct: ProductData;
}
export const CommentItemPurchasedHiddenName: React.FC<Props> = (props) => {
  const classesPurchasedProductCommentStyles = purchasedProductCommentStyles();

  return (
    <div css={classesPurchasedProductCommentStyles.root}>
      <div css={classesPurchasedProductCommentStyles.thumbnails}>
        <img
          src={props.purchasedProduct?.thumbnail}
          alt={props.purchasedProduct?.name}
        />
      </div>

      <div css={classesPurchasedProductCommentStyles.info}>
        <div css={classesPurchasedProductCommentStyles.layout}>
          <p className="productName">{props.purchasedProduct?.name}</p>
          <p className="text">が購入されました！</p>
        </div>
      </div>
    </div>
  );
};

const purchasedProductCommentStyles = () => {
  return {
    root: css`
      display: flex;
      margin: 8px 0;
      border-radius: 10px;
      overflow: hidden;
      /* box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2); */
    `,
    thumbnails: css`
      width: 40%;
      img {
        width: 100%;
      }
    `,
    info: css`
      display: flex;
      align-items: center;
      width: 60%;
      padding: 10px;
      background-color: #d1f2ee;
      p {
        font-size: 12px;
        letter-spacing: 1px;
      }
      .productName {
        display: box;
        max-height: 3.8em;
        margin-bottom: 8px;
        font-weight: bold;
        line-height: 1.3em;
        /* stylelint-disable-next-line property-no-unknown */
        box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 3;
      }
    `,
    layout: css``,
  };
};
