/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "store";
import { Redirect } from "react-router";
import { ticketActions, ReqCollectTicket } from "modules/ticket";
import { eventActions } from "modules/event";
import { authActions } from "modules/auth";
import {
  getEventDisplayInfo,
  getEventIdFromHref,
  isTodayEvent,
  convertMapToValues,
} from "utility";
import { modalActions, ActionModalType, ActionData } from "modules/modal";
import { isVODTicket } from "utility/event";
import { I18nTrans, useI18n } from "hooks/i18n/i18n";

import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { firestoreActions } from "modules/firestore";
import { DigitalTicketHeader } from "./ticket/DigitalTicketHeader";
import { SurveyResult } from "./ticket/SurveyResult";
import { makeEncodedIssuedTicketQRCodeValue } from "utility/ticket";
import { GoodsTicket } from "components/account/TicketDigital/GoodsTicket";

import styled from "@emotion/styled";
import { fontDinMixin } from "../../styles";
import type { MyProductData } from "@spwn/types";
import type {
  IssuedTicket,
  ActiveTransaction,
  Place,
} from "@spwn/types/firebase/firestore";
import { useGetPlaceList } from "../../hooks/event/useGetPlaceList";
import { AccountTicketDigitalPlace } from "./AccountTicketDigitalPlace";
import { AccountTicketDigitalSpwnId } from "./AccountTicketDigitalSpwnId";
import { BreadcrumbNavigation } from "ui/BreadcrumbNavigation";
import { AccountTicketDigitalQrCode } from "./AccountTicketDigitalQrCode";
import { AccountTicketDigitalInfo } from "./AccountTicketDigitalInfo";
import { AccountTicketDigitalSeatNum } from "./AccountTicketDigitalSeatNum";
import Box from "@material-ui/core/Box";
import { ticketShareStatus } from "./useAccountTicketDigitalQrCode";
import { TextLink } from "ui/TextLink";

interface Props {}

const Container = styled.div`
  margin: 0 auto;
  .accountTicketLayout {
    margin: 0 auto;
    .liveStreamHeading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      ${fontDinMixin};
      color: #000;
      font-size: 30px;
      text-align: center;
      line-height: 1em;
      letter-spacing: 1px;
      white-space: nowrap;
      &:before,
      &:after {
        content: "";
        width: 30%;
        /* stylelint-disable-next-line */
        background: linear-gradient(
          transparent 40%,
          currentColor 40%,
          currentColor calc(40% + 2px),
          transparent calc(40% + 2px)
        );
        /* FIXME */
        /* stylelint-disable-next-line */
        background-clip: padding;
      }
      &:before {
        margin-right: 4%;
      }
      &:after {
        margin-left: 4%;
      }
    }
    .eventTicketLayout {
      margin-bottom: 40px;
      .headingDesign {
        margin-bottom: 20px;
        letter-spacing: 1px;
        @media screen and (min-width: 768px) {
          display: flex;
          align-items: center;
          margin-bottom: 40px;
        }
        h2 {
          ${fontDinMixin};
          padding: 5px 0 0 15px;
          margin-bottom: 5px;
          font-size: 35px;
          border-left: 5px solid #01ffe1;
          @media screen and (min-width: 768px) {
            margin-bottom: 0;
            letter-spacing: 3px;
          }
        }
        p {
          padding-left: 21px;
          font-size: 12px;
          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
        }
      }
      .considerationsAboutSeatNumber {
        width: 100%;
        margin: 0 auto 15px;
        font-size: 12px;
        font-weight: bold;
        text-align: right;
        @media screen and (min-width: 768px) {
          width: 740px;
          font-size: 14px;
        }
      }
      .eventTicket {
        max-width: 740px;
        width: 100%;
        margin: 0 auto;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
        .ticketInfoLayout {
          padding: 5%;
          @media screen and (min-width: 768px) {
            padding: 20px;
          }
          .ticketInfo {
            .place {
              margin-bottom: 6px;
              font-size: 14px;
              font-weight: bold;
            }
            .ticketName {
              margin-bottom: 6px;
              font-size: 24px;
              font-weight: bold;
            }
            .price {
              font-size: 14px;
              font-weight: bold;
            }
          }
          .seatNumber {
            margin-top: 20px;
            .heading {
              margin-bottom: 4px;
              font-size: 12px;
              font-weight: 700;
            }
            .num {
              font-size: 20px;
              line-height: 1.3em;
            }
          }
          .checkinText {
            margin-bottom: 10px;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.3em;
            @media screen and (min-width: 768px) {
              text-align: center;
            }
          }
          .buttonForLink {
            display: block;
            max-width: 460px;
            width: 100%;
            padding: 12px 0;
            margin: 0 auto;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            line-height: 1.2em;
            letter-spacing: 1px;
            background-color: #00c2ae;
            cursor: pointer;
            @media screen and (min-width: 768px) {
              padding: 15px 0;
              margin: 0 auto;
              font-size: 15px;
              letter-spacing: 2px;
            }
            &.checkin {
              color: #fff;
              background-color: #adaeae;
            }
          }
        }
        .benefitsLayout {
          padding: 5%;
          @media screen and (min-width: 768px) {
            padding: 20px;
          }
          .heading {
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: bold;
          }
          .benefitsList {
            margin-bottom: 20px;
            font-size: 12px;
            @media screen and (min-width: 768px) {
              font-size: 14px;
            }
            li {
              padding-left: 1em;
              text-indent: -1em;
            }
          }
          .checkinText {
            margin-bottom: 10px;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.3em;
            @media screen and (min-width: 768px) {
              text-align: center;
            }
          }
          .buttonForLink {
            display: block;
            max-width: 460px;
            width: 100%;
            padding: 12px 0;
            margin: 0 auto;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            line-height: 1.2em;
            letter-spacing: 1px;
            background-color: #00c2ae;
            cursor: pointer;
            @media screen and (min-width: 768px) {
              padding: 15px 0;
              margin: 0 auto;
              font-size: 15px;
              letter-spacing: 2px;
            }
            &.checkin {
              color: #fff;
              background-color: #adaeae;
            }
          }
        }
        .ticketInfoLayout + .benefitsLayout {
          border-top: 1px solid #fff;
        }
        .ticketShareLayout {
          padding: 5%;
          @media screen and (min-width: 768px) {
            padding: 20px;
          }
          .heading {
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: bold;
          }
          .caution {
            margin-bottom: 15px;
            font-size: 12px;
            line-height: 1.3em;
            @media screen and (min-width: 768px) {
              margin-bottom: 25px;
              font-size: 14px;
              line-height: 1.5em;
            }
          }
          .buttonForLink {
            display: block;
            max-width: 460px;
            width: 100%;
            padding: 12px 0;
            margin: 0 auto;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            line-height: 1.2em;
            letter-spacing: 1px;
            background-color: #4fb1ea;
            cursor: pointer;
            @media screen and (min-width: 768px) {
              padding: 15px 0;
              margin: 0 auto;
              font-size: 15px;
              letter-spacing: 2px;
            }
          }
          .streamingNotice {
            margin-bottom: 20px;
            line-height: 1.3em;
            a {
              font-weight: bold;
              font-size: 20px;
            }
          }
        }
      }
      .ticketSuperlative {
        border: 5px solid #8f87c4;
        .ticketInfoLayout,
        .benefitsLayout {
          color: #fff;
          background-color: #8f87c4;
          .buttonForLink {
            color: #8f87c4;
            background-color: #fff;
          }
        }
        .ticketShareLayout {
          .buttonForLink {
            background-color: #8f87c4;
          }
        }
      }
      .ticketWithPrivilege {
        border: 5px solid #4774c4;
        .ticketInfoLayout,
        .benefitsLayout {
          color: #fff;
          background-color: #4774c4;
          .buttonForLink {
            color: #4774c4;
            background-color: #fff;
          }
        }
        .ticketShareLayout {
          .buttonForLink {
            background-color: #4774c4;
          }
        }
      }
      .generalTicket {
        border: 5px solid #2ca721;
        .ticketInfoLayout,
        .benefitsLayout {
          color: #fff;
          background-color: #2ca721;
          .buttonForLink {
            color: #2ca721;
            background-color: #fff;
          }
        }
        .ticketShareLayout {
          .buttonForLink {
            background-color: #2ca721;
          }
        }
      }
    }
  }
`;

Container.displayName = "AccountTicketDigitalContainer";

export type TicketDigitalType = {
  relatedTicket: IssuedTicket;
  product: MyProductData;
  place: Place | undefined;
  name: string;
  price_jpy: number;
  seat: string | null;
  seatNumName: string;
  checkInQRCodeValue: string | null;
  isChecked: boolean;
  isRedeemed: boolean;
  ticketColorClass: string;
};

export const AccountTicketDigital: React.FC<Props> = (_props) => {
  const pathname = useSelector(
    (state: Store) => state.router.location.pathname
  );
  const user = useSelector((state: Store) => state.auth.user);
  const myTickets = useSelector((state: Store) => state.ticket.myTickets);
  const eventMap = useSelector(
    (state: Store) => state.event.userRelatedEventMap
  );
  const issuedTicketStates = useSelector(
    (state: Store) => state.ticket.issuedTicketStates
  );
  const doneWatchingIssuedTicket = useSelector(
    (state: Store) => state.ticket.doneWatchingIssuedTicket
  );
  const activeTransactionMap = useSelector(
    (state: Store) => state.purchase.activeTransactionMap
  );
  const eventGoodsMap = useSelector(
    (state: Store) => state.ticket.eventGoodsMap
  );
  const eventTicketMap = useSelector(
    (state: Store) => state.ticket.eventTicketMap
  );
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);

  const [activeTransaction, setActiveTransaction] =
    // @ts-expect-error TS2345
    useState<ActiveTransaction[]>(null);

  const { t } = useI18n();
  const dispatch = useDispatch();
  const eventId = getEventIdFromHref(pathname, "ticket");

  const ticketIdxCounter = {};

  // Placeを取得
  const placeIds = useMemo(
    () =>
      (myTickets ?? []).flatMap((el) => {
        if (el.event._id !== eventId) {
          return [];
        }

        const ticketPlaces = el.tickets.map((el) => el.place);
        const goodsPlaces = el.goods.map((el) => el.place);
        return [...ticketPlaces, ...goodsPlaces];
      }),
    [myTickets, eventId]
  );
  const { data: places } = useGetPlaceList(placeIds);

  const didMount = () => {
    dispatch(ticketActions.fetchEventGoods.started({ eventId }));
    dispatch(ticketActions.fetchEventTickets.started({ eventId }));
    dispatch(ticketActions.fetchRelatedTicketData.started({ eventId }));
    dispatch(
      authActions.addLoginAction({
        action: ticketActions.fetchRelatedTicketData.started,
        args: { eventId },
      })
    );
    // @ts-expect-error TS18048
    dispatch(eventActions.watchOneEvent.started({ eventId }));
    return () => {
      dispatch(firestoreActions.closeChannel({ channel: "oneEvent" }));
      dispatch(eventActions.clearStateByKey("displayEvent"));
      dispatch(ticketActions.clearStateByKey("eventGoodsMap"));
      dispatch(ticketActions.clearStateByKey("eventTicketMap"));
    };
  };
  useEffect(didMount, [dispatch, eventId]);

  const fetchActiveTransaction = () => {
    if (eventId && activeTransactionMap) {
      // const activeTransactionList = convertMapToValues(activeTransactionMap).filter(el => el.eventIds.includes(eventId))
      const activeTransactionList = convertMapToValues(activeTransactionMap);
      const filteredActiveTransaction = activeTransactionList.filter(
        (el) => !el.isCancelled && el?.eventIds && el.eventIds.includes(eventId)
      );
      setActiveTransaction(filteredActiveTransaction);
    }
  };
  useEffect(fetchActiveTransaction, [eventId, activeTransactionMap]);

  const checkIfSelfTicketNumberIssueIsAllowed = () => {
    if (!myTickets || !doneWatchingIssuedTicket || !activeTransaction) {
      return;
    }
    const goodsActiveTransactions = activeTransaction.filter(
      (el) => el.products !== null && el.status === "PAYSUCCESS"
    );

    const _eventTicketData = myTickets.filter((el) => el.event._id === eventId);
    if (_eventTicketData.length === 0) {
      return;
    }
    const [eventTicketData] = _eventTicketData;
    // @ts-expect-error TS18048
    if (!!eventTicketData.goods && eventTicketData.goods.length > 0) {
      for (const goodsActiveTran of goodsActiveTransactions) {
        const relatedTicket = getRelatedIssuedGoods(
          // @ts-expect-error TS18048
          eventId,
          goodsActiveTran.orderId
        );
        if (!relatedTicket) {
          dispatch(
            ticketActions.issueTicketNumIfPossible.started({
              eid: eventId as string,
              itemId: "",
              isGoods: true,
              orderId: goodsActiveTran.orderId,
            })
          );
        }
      }
    }
    // @ts-expect-error TS18048
    for (const ticket of eventTicketData.tickets) {
      // snapshotに保持されていない情報を補完する
      const eventTicket = eventTicketMap && eventTicketMap[ticket.productId];
      // 自動発行フラグがtrue以外の場合はスキップ
      if (!eventTicket?.allowSelfTnumGeneration) continue;

      for (let i = 0; i < ticket.count; i++) {
        const relatedTicket = getRelatedIssuedTicket(
          eventId,
          ticket.productId,
          // @ts-expect-error TS7053
          ticketIdxCounter[ticket.productId]
        );
        if (!relatedTicket) {
          dispatch(
            ticketActions.issueTicketNumIfPossible.started({
              eid: eventId as string,
              itemId: ticket.productId,
              isGoods: false,
            })
          );
        }
      }
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(checkIfSelfTicketNumberIssueIsAllowed, [
    myTickets,
    doneWatchingIssuedTicket,
    activeTransaction,
    eventTicketMap,
  ]);

  /**
   * modalTypeごとのactionとargsをActionModalに渡す
   */
  const openActionModal = (
    modalType: ActionModalType,
    ticketNum: string,
    issuedTicket: IssuedTicket | null
  ) => {
    if (
      ticketNum === null ||
      ticketNum === undefined ||
      ticketNum === "" ||
      issuedTicket === null
    ) {
      const msg =
        modalType === "goodsCheckIn"
          ? t("ticketDetail.cannotExchang")
          : t("ticketDetail.cannotAccept");
      dispatch(modalActions.toggleNotice({ msg }));
      return;
    }

    let args = null;
    let action = null;
    switch (modalType) {
      case "eventCheckIn": {
        // @ts-expect-error TS2322
        action = (payload) =>
          dispatch(ticketActions.collectTicket.started(payload));
        const eventCheckInArgs: ReqCollectTicket = {
          // @ts-expect-error TS18048
          eid: eventId,
          ticketNumber: ticketNum,
          authCode: issuedTicket.authCode,
        };
        args = eventCheckInArgs;
        break;
      }
      case "rewardCheckIn": {
        // @ts-expect-error TS2322
        action = (payload) =>
          dispatch(ticketActions.redeemTicket.started(payload));
        const rewardCheckInArgs: ReqCollectTicket = {
          // @ts-expect-error TS18048
          eid: eventId,
          ticketNumber: ticketNum,
          authCode: issuedTicket.authCode,
        };
        args = rewardCheckInArgs;
        break;
      }
      case "goodsCheckIn":
      case "goodsOrderSheetCheckIn": {
        // @ts-expect-error TS2322
        action = (payload) =>
          dispatch(ticketActions.redeemTicket.started(payload));
        const goodsCheckInArgs: ReqCollectTicket = {
          // @ts-expect-error TS18048
          eid: eventId,
          ticketNumber: ticketNum,
          authCode: issuedTicket.authCode,
          orderId: issuedTicket.orderId,
          isGoods: true,
        };
        args = goodsCheckInArgs;
        break;
      }
      case "shareTicket": {
        // @ts-expect-error TS2322
        action = (payload) =>
          dispatch(ticketActions.shareTicket.started(payload));
        const shareTicketArgs: ReqCollectTicket = {
          // @ts-expect-error TS18048
          eid: eventId,
          ticketNumber: ticketNum,
        };
        args = shareTicketArgs;
        break;
      }
      default: {
        break;
      }
    }
    if (!action) {
      return;
    }
    const actionData: ActionData = {
      trunk: { issuedTicket, event: displayEvent },
      actionModalType: modalType,
      // @ts-expect-error TS2322
      action,
      // @ts-expect-error TS2322
      args,
    };
    dispatch(modalActions.toggleActionModal(actionData));
  };

  // @ts-expect-error TS7006
  const getRelatedIssuedTicket = (eventId, ticketId, ticketIdx) => {
    if (
      issuedTicketStates.hasOwnProperty(eventId) &&
      ticketIdx !== null &&
      ticketIdx !== undefined
    ) {
      // @ts-expect-error TS2769
      const relatedTicket = Object.entries(issuedTicketStates[eventId])
        .filter(([_ticketNum, ticketState]) => {
          return ticketState.ticketId === ticketId && !ticketState.isGoods;
        })
        .sort(
          ([a_ticketNum, _a_ticketState], [b_ticketNum, _b_ticketState]) => {
            const a = a_ticketNum.toString().toLowerCase();
            const b = b_ticketNum.toString().toLowerCase();
            if (a < b) {
              return -1;
            } else if (a > b) {
              return 1;
            }
            return 0;
          }
        );
      if (relatedTicket.length === 0 || relatedTicket.length < ticketIdx + 1)
        return null;
      else {
        // @ts-expect-error TS2461
        const [ticketNum, ticketState] = relatedTicket[ticketIdx];
        ticketState["ticketNum"] = ticketNum;
        return ticketState;
      }
    } else {
      return null;
    }
  };

  const getRelatedIssuedGoods = (eventId: string, orderId: number) => {
    const issuedTicket = issuedTicketStates[eventId];
    if (!issuedTicket) {
      return null;
    }
    const relatedTicket = Object.entries(issuedTicket).filter(
      ([_ticketNum, ticketState]) => {
        return ticketState.orderId === undefined
          ? ticketState.isGoods
          : ticketState.orderId === orderId && ticketState.isGoods;
      }
    );
    if (relatedTicket.length === 0) {
      return null;
    }
    // グッズ引き換えはグッズの個数に関わらず一つ
    // @ts-expect-error TS2461
    const [[ticketNum, ticketState]] = relatedTicket;
    ticketState["ticketNum"] = ticketNum;
    return ticketState;
  };

  /**
   * render logic
   */

  if (
    !eventMap ||
    places?.length === 0 ||
    !myTickets ||
    myTickets.length === 0
  ) {
    return (
      <div id="contents-layout">
        <LoaderLayout />
      </div>
    );
  }

  // @ts-expect-error TS18048
  const event = eventMap[eventId];
  if (!event) {
    return <Redirect to="/" />;
  }

  const myEventTicketsData = myTickets.filter((el) => el.event._id === eventId);
  if (myEventTicketsData.length === 0) {
    return <Redirect to="/" />;
  }
  const [_myEventTicket] = myEventTicketsData;
  const myEventTicket = {
    ..._myEventTicket,
    tickets: _myEventTicket?.tickets ?? [],
    goods: _myEventTicket?.goods ?? [],
  };

  const userId = user.uid;
  const dispInfo = getEventDisplayInfo(event);

  // TODO@later ひとまず複数の会場を買っている場合は所有しているチケットに関わる会場を全て表示する
  const ticketPlaces = myEventTicket.tickets.map((el) => el.place);
  const goodsPlaces = myEventTicket.goods.map((el) => el.place);
  const placeCodes = Array.from(new Set(ticketPlaces.concat(goodsPlaces)));

  const eventTickets: TicketDigitalType[] = (() => {
    const tickets = [];
    for (const ticket of myEventTicket.tickets) {
      for (let i = 0; i < ticket.count; i++) {
        tickets.push(ticket);
      }
    }
    return tickets;
  })()
    .concat()
    .filter((product) => {
      return product.status !== "UNPROCESS";
    })
    .sort((a_product, b_product) => {
      return Number(a_product.priority) - Number(b_product.priority);
    })
    .map((product) => {
      const place = places?.find((el) => el.code === product.place);

      /**
       * @description ticketIdxCounterはチケットの発行枚数をカウントするための変数
       * @todo mapの中で変数に対して再代入する処理をやめる
       * @see https://github.com/balus-co-ltd/spwn/issues/7150
       */
      if (product.productId in ticketIdxCounter) {
        // @ts-expect-error TS7053
        ticketIdxCounter[product.productId] += 1;
      } else {
        // @ts-expect-error TS7053
        ticketIdxCounter[product.productId] = 0;
      }

      const relatedTicket = getRelatedIssuedTicket(
        eventId,
        product.productId,
        // @ts-expect-error TS7053
        ticketIdxCounter[product.productId]
      );

      const checkInQRCodeValue = relatedTicket
        ? makeEncodedIssuedTicketQRCodeValue(
            // @ts-expect-error TS18048
            eventId,
            relatedTicket["ticketNum"],
            relatedTicket?.["authCode"] || null,
            "isChecked"
          )
        : null;

      const seat =
        relatedTicket !== null && relatedTicket.seat !== undefined
          ? relatedTicket.seat
          : null;
      const seatNumName =
        relatedTicket !== null && relatedTicket.seatNumName !== undefined
          ? relatedTicket.seatNumName
          : "座席番号";
      const isChecked =
        relatedTicket !== null &&
        relatedTicket.isChecked !== undefined &&
        relatedTicket.isChecked;
      const isRedeemed =
        relatedTicket !== null &&
        relatedTicket.isRedeemed !== undefined &&
        relatedTicket.isRedeemed;

      const ticketColorClass: string = (() => {
        switch (product.class) {
          case 1:
            //推しチケット等の最上級のチケット時
            return "eventTicket ticketSuperlative";
          case 2:
            //特典付きチケットの時
            return "eventTicket ticketWithPrivilege";
          case 3:
          case 7:
          case 8:
            //一般チケット、配信チケットの時
            return "eventTicket generalTicket";
          default:
            return "";
        }
      })();

      return {
        // ...product,
        relatedTicket,
        product,
        place,
        price_jpy: product.price_jpy,
        name: product.name,
        seat,
        seatNumName,
        checkInQRCodeValue,
        isChecked,
        isRedeemed,
        ticketColorClass,
      };
    });

  const myEventTickets = eventTickets.filter((product) => {
    const shareStatus = ticketShareStatus(product.relatedTicket, userId);
    return shareStatus !== "shared";
  });

  const sharedEventTickets = eventTickets.filter((product) => {
    const shareStatus = ticketShareStatus(product.relatedTicket, userId);
    return shareStatus === "shared";
  });

  return (
    <Container id="contents">
      <div
        css={css`
          max-width: 1800px;
          width: 100%;
          margin: 0 auto;
          background-color: #fff;
        `}
      >
        <BreadcrumbNavigation
          breadcrumbs={[
            { label: "ホーム", link: "/" },
            { label: t("common.routes.account"), link: "/account" },
            { label: t("common.routes.ticket"), link: "/account/ticket" },
            { label: t("common.routes.ticketDetail"), link: null },
          ]}
        />

        <div
          css={css`
            max-width: 980px;
            width: 90%;
            padding: 40px 0;
            margin: 0 auto;
            @media screen and (min-width: 768px) {
              padding-bottom: 80px;
            }
          `}
          className="accountTicketLayout"
        >
          {isTodayEvent(event) && (
            <h2 className="liveStreamHeading">
              {t("ticketDetail.todayEvent")}
            </h2>
          )}

          <DigitalTicketHeader />

          <AccountTicketDigitalPlace placeCodes={placeCodes} places={places} />

          {/* アンケート結果枠 
            // @ts-expect-error TS18048 */}
          <SurveyResult eventId={eventId} />

          {myEventTickets.length !== 0 && (
            <div className="eventTicketLayout">
              <div className="headingDesign">
                <h2>{t("ticketDetail.ticket.title")}</h2>
                <p>{t("ticketDetail.ticket.subTitle")}</p>
              </div>

              {
                // TODO@later「※座席番号は公演日の前日までに公開されます。」今後使われなさそうな機能なので隠す。
                // isSeatRequired
                //   ? <p className="considerationsAboutSeatNumber">{t('ticketDetail.considerationsAboutSeatNumber')}</p>
                //   : null
              }

              {myEventTickets.map((product: TicketDigitalType, idx) => {
                return (
                  <Box key={idx} marginBottom={["20px", "36px"]}>
                    <AccountTicketDigitalCard
                      product={product}
                      userId={userId}
                      eventId={eventId}
                      isTicketSharable={event.isTicketSharable}
                      openActionModal={openActionModal}
                    />
                  </Box>
                );
              })}
            </div>
          )}

          {sharedEventTickets.length !== 0 && (
            <Box className="eventTicketLayout" marginBottom={"60px"}>
              <Box marginBottom={"28px"}>
                <Box
                  width={"100%"}
                  height={"1px"}
                  borderBottom={"1px solid #000"}
                />
                <Box component={"p"} marginTop={"-0.5em"} textAlign={"center"}>
                  <Box
                    component={"span"}
                    padding={"0 16px"}
                    fontSize={"16px"}
                    fontWeight={"bold"}
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    共有済みチケット
                  </Box>
                </Box>
              </Box>

              {sharedEventTickets.map((product: TicketDigitalType, idx) => {
                return (
                  <Box key={idx} marginBottom={["20px", "36px"]}>
                    <AccountTicketDigitalCard
                      product={product}
                      userId={userId}
                      eventId={eventId}
                      isTicketSharable={event.isTicketSharable}
                      openActionModal={openActionModal}
                    />
                  </Box>
                );
              })}
            </Box>
          )}

          <GoodsTicket
            // @ts-expect-error TS2322
            eventId={eventId}
            dispInfo={dispInfo}
            // @ts-expect-error TS2322
            eventTicketData={myEventTicket}
            activeTransaction={activeTransaction}
            eventGoodsMap={eventGoodsMap}
            getRelatedIssuedGoods={getRelatedIssuedGoods}
            openActionModal={openActionModal}
          />

          <AccountTicketDigitalSpwnId uid={user.uid} email={user.email} />
        </div>
      </div>
    </Container>
  );
};

const AccountTicketDigitalCard: React.FC<{
  product: TicketDigitalType;
  userId: string;
  eventId: string | null;
  isTicketSharable: boolean;
  openActionModal: (
    modalType: ActionModalType,
    ticketNum: string,
    issuedTicket: IssuedTicket | null
  ) => void;
}> = ({ product, userId, eventId, isTicketSharable, openActionModal }) => {
  return (
    <div className={product.ticketColorClass}>
      <div className="ticketInfoLayout">
        <AccountTicketDigitalInfo product={product} />

        {product.seat && (
          <Box marginTop={"20px"}>
            <AccountTicketDigitalSeatNum product={product} />
          </Box>
        )}

        <Box marginTop={"20px"}>
          {/* チケットと特典のQRコードを表示する */}
          {/* 
              isSharingRestricted
                - 共有可能かの判定値。isTicketSharableで共有エリアを表示するか決めているので使用しない。
              sharingRestrictionMsg
                - 共有不可の理由を表示するメッセージ。
                - isTicketSharableがfalseの時、共有エリアが表示されないので使用しない。
              isRequiredArApp
                - ARアプリが必要かの判定値(?)
                - isSharingRestrictedの判定値に使用されていたが、共有エリアが表示されないので使用しない。
            */}
          {product.checkInQRCodeValue && (
            <AccountTicketDigitalQrCode
              userId={userId}
              eventId={eventId}
              relatedTicket={product.relatedTicket}
              openActionModal={openActionModal}
              ticket={{
                isVodTicket: isVODTicket(product.product) ?? false,
                isChecked: product.isChecked,
                isTicketSharable,
              }}
              benefits={{
                productClass: product.product.class,
                isRedeemed: product.isRedeemed,
              }}
            />
          )}
          {/* チケットの種類がVODの場合、イベントページへのリンクを表示する */}
          {isVODTicket(product.product) && (
            <Box padding={"16px 12px"} bgcolor={"white"}>
              <Box
                component={"p"}
                color={"black"}
                fontSize={"14px"}
                fontWeight={"bold"}
                lineHeight={"1.5em"}
              >
                <I18nTrans i18nKey="ticketDetail.distributionLink">
                  配信は公演開始時間より、
                  <TextLink href={`/events/${eventId}`}>こちら</TextLink>
                  のページからご覧いただけます。
                </I18nTrans>
              </Box>
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};
