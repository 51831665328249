import useSWR from "swr";
import { PortalTopFanclubProps } from "./PortalTopFanclub";
import { useCallback } from "react";

export const useGetPortalTopFanclub = () => {
  const shuffleArray = useCallback(
    (array: PortalTopFanclubProps["brandList"]) => {
      const randomArray = array.slice().flatMap((_) => {
        const randomIndex = Math.floor(Math.random() * array.length);
        const item = array[randomIndex];
        array.splice(randomIndex, 1);

        if (!item) return [];

        return item;
      });

      return randomArray;
    },
    []
  );

  const { data: fanclub, error } = useSWR("useGetPortalTopFanclub", () => {
    return shuffleArray([
      {
        thumbnail:
          "https://public-web.devspwn.net/crew/juntaterashima/brandIcon.png",
        brandName: "寺島惇太 アーティストメンバーシップ",
        brandSlug: "juntaterashima",
      },
      {
        thumbnail:
          "https://public-web.spwn.jp/brandIcons/te_tMrGRMNttRW60rmyV6jd_br_R9Xu8T7niuLkUbmOPW8L_1702986850559_e7f63632-8eaf-48f0-9cfd-220ef791c8c9_500x500",
        brandName: "リーエ香澄",
        brandSlug: "kasumirie",
      },
      {
        thumbnail:
          "https://public-web.spwn.jp/brandIcons/te_fd7pLzQiCQDWMyfWaQoF_br_d21Zk76ODNUvBGS47tKo_1705601500869_b04602b5-1ace-47a5-9dbe-61c7ca80096f_500x500",
        brandName: "未確認動物うまぴ / VTuber",
        brandSlug: "umapi",
      },
      {
        thumbnail:
          "https://public-web.spwn.jp/brandIcons/te_GKNRJudMOWtQn0cbVVxO_br_pmQ7CiK8M9T5bMLQTWVa_1703703916388_500fa294-9466-4d31-ab83-343901f0059c_500x500",
        brandName: "七篠さよ",
        brandSlug: "7shi0sayo",
      },
      {
        thumbnail:
          "https://public-web.spwn.jp/brandIcons/te_NbUTAxM7zoatWVyt7CNc_br_t2CiKpRUwP4YCGLH4PUe_1705646721342_bc4bcc76-e09f-472a-b173-9c5cd31995a4_500x500",
        brandName: "銀河アリス Official Fan Club D.O.G.",
        brandSlug: "alice",
      },
      {
        thumbnail:
          "https://public-web.spwn.jp/brandIcons/te_W4entJQUsivS96476dsM_br_QVNtpcM00Ia6l9zb2A1O_1705300664251_dae08f5c-97d8-439c-818b-08ab5126cc97_500x500",
        brandName: "悪ガキカルテット",
        brandSlug: "warugaki-quartet",
      },
      {
        thumbnail:
          "https://public-web.spwn.jp/brandIcons/te_8dPbMMHGgaU2GdCJsw8D_br_wRoSzFFxzjj2qB1oCSlu_1706792940052_0bf3c32f-0390-408f-b1b2-a3b32ad6638d_500x500",
        brandName: "bananaamaou",
        brandSlug: "bananaamaou",
      },
      {
        thumbnail:
          "https://public-web.spwn.jp/brandIcons/gf2qdwksadscyi5p6l59rib4_bdqhudihubjtj8i0w6nkxv5w_1727340396002_d37c9561-4de7-41e4-b5ad-e8e2a3b4fa66_500x500",
        brandName: "GAKUTO-JAM",
        brandSlug: "gakuto-jam",
      },
    ]);
  });

  return {
    fanclub,
    error,
  };
};
