import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { put, takeEvery } from "redux-saga/effects";
import appConfig from "../constants/appConfig";
import { fetchFbToken } from "utility/firebase";
import { hostingMap } from "constants/admin";
import { setLocalStorage } from "utility/storage";
import type { HostingType } from "@spwn/types/firebase/firestore";

const actionCreator = actionCreatorFactory("admin");

export const adminActions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkMaintenanceUser: actionCreator.async<any, any>("checkMaintenanceUser"),
  checkBelongedHosting: actionCreator<void>("checkBelongedHosting"),
  setI18nLang: actionCreator<string>("setI18nLang"),
};

export interface adminState {
  isAdmin: boolean;
  belongedHosting: HostingType;
  i18nLang: string;
}

const initialState: adminState = {
  // @ts-expect-error TS2322
  isAdmin: null,
  // @ts-expect-error TS2322
  belongedHosting: null,
  // @ts-expect-error TS2322
  i18nLang: null,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const adminReducer = reducerWithInitialState(initialState)
  .case(adminActions.checkMaintenanceUser.done, (state, payload: any) => {
    return { ...state, isAdmin: payload };
  })
  // @ts-expect-error TS2345
  .case(adminActions.checkBelongedHosting, (state, _payload: any) => {
    const { hostname } = window.location;
    let belongedHosting;
    for (const hostingType of Object.keys(hostingMap)) {
      // why hostingType type is string...
      // @ts-expect-error TS7053
      const hostings = hostingMap[hostingType];
      if (hostings.includes(hostname)) {
        belongedHosting = hostingType;
        break;
      }
    }
    // @ts-expect-error TS2345
    setLocalStorage("belongedHosting", belongedHosting);
    return { ...state, belongedHosting };
  })
  .case(adminActions.setI18nLang, (state, payload: any) => {
    return { ...state, i18nLang: payload };
  });
/* eslint-enable @typescript-eslint/no-explicit-any */

export default adminReducer;

export function* adminSaga() {
  yield takeEvery(
    adminActions.checkMaintenanceUser.started,
    checkMaintenanceUser
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-expect-error TS7006
function* checkMaintenanceUser(action) {
  try {
    if (!action.payload || !action.payload.sdp) {
      yield put(adminActions.checkMaintenanceUser.done(false as any));
      return;
    }
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    if (!fbToken) {
      yield put(adminActions.checkMaintenanceUser.done(false as any));
      return;
    }
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.CloudFunctions.checkPermission, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify({ magic: action.payload.sdp }),
    });
    const isAdmin: any = response.ok === true;
    yield put(adminActions.checkMaintenanceUser.done(isAdmin));
    return;
  } catch (error) {
    const isAdmin: any = false;
    yield put(adminActions.checkMaintenanceUser.done(isAdmin));
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */
