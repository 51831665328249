/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useI18n } from "hooks/i18n/i18n";
import { changeStyleWithHosting } from "utility/hosting";

interface Props {
  upcomingEventType: string;
}
export const Headline: React.FC<Props> = (props) => {
  const { t } = useI18n();
  const classes = styles();
  const { mainText } =
    changeStyleWithHosting().pageSettings.topPage.upcomingHeadline;
  const { subText: _subText } =
    changeStyleWithHosting().pageSettings.topPage.upcomingHeadline;

  const subText = React.useCallback(() => {
    if (_subText === "" || _subText) return _subText;
    if (props.upcomingEventType === "UPCOMING EVENT")
      return t("event.top.upcomingEvent");
    return "";
  }, [props.upcomingEventType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div css={classes.heading}>
      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      <h2>{mainText || props.upcomingEventType}</h2>
      <p>{subText()}</p>
    </div>
  );
};

const styles = () => {
  const textColor = changeStyleWithHosting().baseStyles.fontPrimaryColor;
  const { accentColor } = changeStyleWithHosting().baseStyles;

  return {
    heading: css`
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }
      h2 {
        padding: 5px 0 5px 16px;
        margin-bottom: 5px;
        color: ${textColor};
        font-size: 24px;
        line-height: 1.3em;
        border-left: 5px solid ${accentColor};
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
          letter-spacing: 3px;
        }
      }
      p {
        padding-left: 21px;
        color: ${textColor};
        font-size: 14px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    `,
  };
};
