import type { HostingStyleMap } from "utility/hosting";
import logo from "designs/images/external/tvf/logo.png";

/**
 * spwn basic style
 */
export const baseStyles = (): HostingStyleMap => {
  return {
    baseStyles: {
      baseColor: "#fff",
      mainColor: "#6495ED",
      accentColor: "#00c2ae",
      fontPrimaryColor: "#000",
      fontSecondaryColor: "#000",
      backgroundImage: null,
    },

    commonSettings: {
      auth: {
        loginMethod: {
          twitter: true,
          facebook: false,
        },
      },
      route: {
        home: "/",
      },
      meta: {
        favicon: null,
        appleTouchIcon: null,
        title: "TOKYO VDOL FESTIVAL",
        description: null,
        copyright: "Powerd by SPWN",
      },
      header: {
        logo: {
          isDisplay: true,
          path: logo,
        },
        textColor: "#fff",
        svgColor: "#fff",
      },
      menu: {
        home: {
          isDisplay: true,
        },
        event: {
          isDisplay: true,
          text: "イベント一覧",
        },
        archive: {
          isDisplay: true,
          text: "配信中のイベント",
        },
        account: {
          isDisplay: true,
        },
        pastEvents: {
          isDisplay: true,
          text: "過去のイベント",
        },
        eventNotes: {
          isDisplay: true,
          path: null,
        },
        guide: {
          isDisplay: true,
        },
        contact: {
          isDisplay: true,
          path: null,
        },
      },
      isDisplayBreadcrumb: true,
      isDisplayEventDate: true,
      isDisplayEventTime: true,
    },

    pageSettings: {
      topPage: {
        upcomingHeadline: {
          isDisplay: true,
          mainText: null,
          subText: null,
        },
        isDisplayTodaysEvent: true,
        isDisplayInfoArea: false,
        isDisplayDeliveryDisplayArea: false,
        isDisplayCatchCopy: false,
      },
      eventListPage: {
        /** -- 基本的にtrueで設定する -- */
        isDisplayEventDatetimeLabel: true,
        /** ------------------------- */
      },
      eventPage: {
        /** -- 基本的にtrueで設定する -- */
        isDisplayEventState: true,
        isDisplayEventTitle: true,
        isDisplayArtist: true,
        isDisplayShareButton: true,
        /** ------------------------- */
        linkInPage: {
          color: "#fff",
          link01: {
            isDisplay: true,
            text: "トップ",
          },
          link02: {
            isDisplay: true,
            text: "出演",
          },
          link03: {
            isDisplay: true,
            text: "会場・チケット",
          },
          link04: {
            isDisplay: true,
            text: "グッズ",
          },
          link05: {
            isDisplay: true,
            text: "フラスタ",
          },
        },
        artistInfo: {
          isDisplayArtistStateLabel: true,
          headline: {
            isDisplay: true,
            text: "ACT",
            subText: "出演",
          },
        },
        purchaseButton: {
          path: null,
        },
      },
      goodsPage: {
        /** -- 基本的にtrueで設定する -- */
        isDisplayEventTitle: true,
        isDisplayArtist: true,
        isDisplayGoodsInfo: true,
        /** ------------------------- */
      },
      streamingPage: {
        isDisplaySuperChatArea: true,
      },
      accountTicketPage: {
        /** -- 基本的にtrueで設定する -- */
        isDisplayEventTitle: true,
        isDisplayArtist: true,
        isDisplayShareButton: true,
        /** ------------------------- */
      },
    },
  };
};
