/**
 * テナント商品専用決済ページコンポーネント
 * 現状はテナントグッズ専用で、一部UIコンポーネント・購入処理を現行決済コンポーネント（SettlementLegacy）をDIしている。
 * SettlementLegacyがメンテナブルではないため、徐々にコンポーネントや処理を引き剥がしていく
 */

/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../store";
import {
  SettlementWithConnect as SettlementLegacy,
  States as SettlementLegacyStates,
} from "../containers/settlement/Settlement";
import { PurchaseOrderDetails } from "./PurchaseOrderDetails";
import { usePurchase, PurchaseProps, PurchaseFunction } from "./usePurchase";
import { CardInfo } from "../modules/purchase";
import { getGMOToken } from "../utility/gmo";
import { useTenantIdQuery } from "./useTenantIdQuery";
import { useUserCart } from "./useUserCart";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { usePurchaseWithStripe } from "./usePurchaseWithStripe";
import { OrderCart } from "hooks/cart/Cart";
import { Redirect } from "react-router";
import { cartActions } from "modules/cart";

export const PurchaseContainer = () => {
  return <PurchaseFetcher />;
};

/**
 * カートを取得するまでLoader表示、取得したらPurchaseContent表示。
 */
const PurchaseFetcher = () => {
  const tenantId = useTenantIdQuery(); // TODO: tenantIdなかったとの表示どうするか、useCart()と一緒に考えそう
  const { data } = useUserCart();
  if (!data) {
    return <LoaderLayout />;
  }
  const orderCart = data.orderCarts.find((cart) => cart.groupId === tenantId);
  /**
   * FIXME 決済ページでカートの中身が空だったらリダイレクトする。
   * 既存仕様に合わせている。
   * @see https://github.com/balus-co-ltd/spwn/blob/51db1989dba4913ffd68276d7536a7500a4bf050/packages/portal/src/containers/settlement/InjectCartToSettlement.tsx#L67-L69
   */
  if (orderCart === undefined || orderCart.products.length <= 0) {
    return <Redirect to="/" />;
  }
  // @ts-expect-error TS2322
  return <PurchaseContent tenantId={tenantId} orderCart={orderCart} />;
};

/**
 * 決済画面を表示
 */
const PurchaseContent: React.VFC<{ tenantId: string; orderCart: OrderCart }> =
  ({ tenantId, orderCart }) => {
    const cardInfoList =
      useSelector((store: Store) => store.purchase.cardInfoList) ?? []; // MEMO: 元の型定義は `CarInfo[] | null`
    const dispatch = useDispatch();

    const { products, shippingFee, subTotalPrice } = orderCart;

    /**
     * @todo
     * settlementFeeは決済方法選択したら変更されるようにする必要がありそう。
     * stripeにしてコンビニ決済をなくすなら未実装にする可能性もあり。一旦保留
     */
    const settlementFee = 0;
    const totalAmount = subTotalPrice + shippingFee + settlementFee;

    const purchase = usePurchaseForSettlementLegacy(
      {
        tenantId,
        totalAmount,
      },
      {
        cardInfoList,
      }
    );

    const openCart = useCallback(() => {
      dispatch(cartActions.toggleMyCart({}));
    }, [dispatch]);

    const items = products.map((product) => {
      return {
        name: product.name,
        variantName: product.subClassName,
        price: product.price,
        count: product.count,
        shippingAtTitle: product.shippingAtTitle,
      };
    });

    return (
      <SettlementLegacy
        // テナントグッズの場合、myCartは使用しないが、何も渡さないことによる影響範囲が大きいので空配列を渡す
        myCart={[]}
        OrderDetails={
          <PurchaseOrderDetails
            // @ts-expect-error TS2322
            items={items}
            toggleMyCart={openCart}
            // totalShippingFee: number;
            shippingFee={shippingFee}
            // isAdditionalFee
            isAdditionalFee={false}
            // totalAmount: number;
            totalPrice={totalAmount}
            settlementFee={settlementFee}
            /**
             * デフォルトtrueでok
             */
            displayAddressCaution={true}
            /**
             * 発送日名
             */
            shippingAtTitle={null}
            /**
             * サービス手数料合計
             */
            serviceFee={0}
          />
        }
        // @ts-expect-error TS2322
        purchase={purchase}
      />
    );
  };

type PaymentMethod = "creditNew" | "creditMember" | "cvs" | "phone";

type FromReduxStore = {
  cardInfoList: CardInfo[];
};

type FromSettlementState = Pick<
  SettlementLegacyStates["onChangeField"],
  | "cardSecurityCode"
  | "cvsCode"
  | "customerName"
  | "customerKana"
  | "telNo"
  | "phoneCode"
  | "useCreditInfoIndex"
  | "cardNumber"
  | "cardExpireYear"
  | "cardExpireMonth"
  | "cardHolderName"
>;

/**
 * 以下のためにSettlementLegacyとusePurchaseのつなぎこみを行う
 *
 * - SettlementLegacyになるべく手を加えない (壊しやすくする)
 * - usePurchaseが直接SettlementLegacyに依存しない
 */
const usePurchaseForSettlementLegacy = (
  common: PurchaseProps["commonProps"],
  store: FromReduxStore
) => {
  const purchaseWithStripe = usePurchaseWithStripe();
  return useCallback<
    (
      paymentMethod: PaymentMethod,
      state: FromSettlementState
    ) => ReturnType<PurchaseFunction>
  >(
    async (_method, _state) => {
      const commonProps = common;
      return purchaseWithStripe({
        ...commonProps,
      });
    },
    [purchaseWithStripe, common, store] // eslint-disable-line react-hooks/exhaustive-deps
  );
  /**
   * FIXME stripeでやるなら以下削除
   */
  /* eslint-disable no-unreachable */
  const purchase = usePurchase();
  return useCallback<
    (
      paymentMethod: PaymentMethod,
      state: FromSettlementState
    ) => ReturnType<PurchaseFunction>
  >(
    async (method, state) => {
      const commonProps = common;
      switch (method) {
        case "creditNew": {
          // 参考: https://github.com/balus-co-ltd/spwn/blob/9a76585598d5ea88797d4c3ca09e0f66faf990ae/packages/portal/src/containers/settlement/Settlement.tsx#L1034-L1046
          const token = await getGMOToken({
            cardNo: state.cardNumber,
            expireYear: state.cardExpireYear,
            expireMonth: state.cardExpireMonth,
            securityCode: state.cardSecurityCode,
            holderName: state.cardHolderName,
          });
          if (token === undefined) {
            throw new Error(); // TODO: エラーの表示方法を考える
          }
          return purchase({
            commonProps,
            paymentProps: {
              method: "Card",
              token,
            },
          });
        }
        case "creditMember": {
          // 実装参考: https://github.com/balus-co-ltd/spwn/blob/561d6dba16827ef52098b099c9fb541cda9c56a7/packages/portal/src/containers/settlement/Settlement.tsx#L1053-L1055
          const card = store.cardInfoList[state.useCreditInfoIndex]; // MEMO: 本当は `CardInfo | undefined`
          if (card === undefined) {
            throw new Error(); // TODO: エラーの表示方法を考える
          }
          return purchase({
            commonProps,
            paymentProps: {
              method: "Member",
              cardSeq: card.cardSeq,
              securityCode: state.cardSecurityCode,
            },
          });
        }
        case "cvs":
          return purchase({
            commonProps,
            paymentProps: {
              method: "CVS",
              customerName: state.customerName,
              customerKana: state.customerKana,
              cvesCode: state.cvsCode,
              telNo: state.telNo,
            },
          });
        case "phone":
          return purchase({
            commonProps,
            paymentProps: {
              method: "Phone",
              phoneCode: state.phoneCode,
            },
          });
        default: {
          const _expectNever: never = method;
          return;
        }
      }
    },
    [purchase, common, store]
  );
};
