import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import { convertMapToValues, getNowTimestamp } from "../../utility";
import { push } from "connected-react-router";
import { streamingActions } from "../../modules/streaming";
import { firestoreActions } from "../../modules/firestore";
import { filterDisplayVideoTickets } from "../../utility/ticket";
import { modalActions } from "../../modules/modal";
import { isOpenEventStreamingPage } from "utility/event";

export const useEventDetailVideoList = () => {
  const pathname = useSelector(
    (state: Store) => state.router.location.pathname
  );
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);
  const isLogined = useSelector((state: Store) => state.auth.user.uid);
  const eventTicketMap = useSelector(
    (state: Store) => state.ticket.eventTicketMap
  );
  const eventVideoMap = useSelector(
    (state: Store) => state.streaming.eventVideoMap
  );
  const streamingKey = useSelector(
    (state: Store) => state.streaming.streamingKey
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      streamingActions.getStreamingKey.started({ eid: displayEvent.eventId })
    );
    dispatch(
      streamingActions.watchEventVideos.started({
        eventId: displayEvent.eventId,
      })
    );
    return () => {
      dispatch(streamingActions.clearStateByKey("streamingKey"));
      dispatch(streamingActions.clearStateByKey("eventVideoMap"));
      dispatch(firestoreActions.closeChannel({ channel: "eventVideos" }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isOpenStreamingPage, setIsOpenStreamingPage] = useState(
    displayEvent.isOpenStreamingPage
  );
  useEffect(() => {
    // hasStreamingPageOpenがtrueで、streamingPageOpenTimeが未来なら開場までの時間を計算してsetTimeout。
    const nowTimestamp = getNowTimestamp();
    if (
      displayEvent.hasStreamingPageOpen &&
      displayEvent.streamingPageOpenTime &&
      nowTimestamp < displayEvent.streamingPageOpenTime.seconds
    ) {
      const secondsUntilOpen =
        displayEvent.streamingPageOpenTime.seconds - nowTimestamp;
      window.setTimeout(() => {
        setIsOpenStreamingPage(true);
      }, secondsUntilOpen * 1000);
    }
    // displayEvent.isOpenStreamingPageを使わないのは、再計算されない場合があるため。
    // e.g. hasStreamingPageOpen: true, now < streamingPageOpenTime → の時に配信ページに行き戻ってきた場合
    setIsOpenStreamingPage(isOpenEventStreamingPage(displayEvent));
  }, [displayEvent]);
  const isOpenLiveStreamingPage = isOpenStreamingPage;

  const eventVideos = convertMapToValues(eventVideoMap ?? {}).sort(
    (prev, cur) => prev.priority - cur.priority
  );
  const videoTickets = useMemo(() => {
    const filteredTickets = filterDisplayVideoTickets(eventTicketMap, isAdmin);
    return filteredTickets;
  }, [eventTicketMap, isAdmin]);

  const handleButtonAction = useCallback(() => {
    if (!isLogined) {
      dispatch(modalActions.toggleLoginMethod());
      return;
    }
    if (displayEvent.eventSaleStatus === "ON_SALE") {
      if (displayEvent.ticketUrl) {
        // if ticket purchase is external site, open its site
        // @ts-expect-error TS2345
        window.open(displayEvent.ticketUrl, null, "noopener");
        return;
      }
      // go to purchase
      dispatch(push(`/events/${displayEvent.eventId}/ticket`));
      return;
    }
    return;
  }, [dispatch, displayEvent, isLogined]);

  const goLive = useCallback(
    (vid: string) => {
      dispatch(push(`/events/${displayEvent.eventId}/streaming`, { vid }));
    },
    [dispatch, displayEvent]
  );

  return {
    pathname,
    isAdmin,
    displayEvent,
    eventVideos,
    eventVideoMap,
    videoTickets,
    streamingKey,
    isOpenLiveStreamingPage,
    handleButtonAction,
    goLive,
  };
};
