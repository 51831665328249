import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";

const actionCreator = actionCreatorFactory("loading");

export const loadingActions = {
  toggleLoading: actionCreator<{ msg?: string }>("toggleLoading"),
};

export interface loadingState {
  isOpen: boolean;
  msg: string;
}

const initialState: loadingState = {
  isOpen: false,
  msg: "",
};

const loadingReducer = reducerWithInitialState(initialState).case(
  loadingActions.toggleLoading,
  // @ts-expect-error TS2345
  (state, payload) => {
    let isOpen = !state.isOpen;
    if (
      payload === undefined ||
      // @ts-expect-error TS2367
      payload === "" ||
      Object.keys(payload).length === 0
    ) {
      isOpen = false;
    }
    const msg = payload.hasOwnProperty("msg") ? payload.msg : "";
    return { ...initialState, isOpen, msg };
  }
);

export default loadingReducer;
