import React, { Children, useEffect, ReactElement } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "../../../store";
import { flowerStandActions } from "../../../modules/flowerStand";
import { purchaseActions } from "../../../modules/purchase";
import { authActions } from "../../../modules/auth";
import { getEventIdFromHref } from "../../../utility";
import { firestoreActions } from "../../../modules/firestore";

type Props = {};

const EventFlowerStandBase: React.FC<Props> = (props) => {
  const location = useSelector((state: Store) => state.router.location);

  const dispatch = useDispatch();

  const eventId = getEventIdFromHref(location.pathname, "events");

  useEffect(() => {
    // @ts-expect-error TS2322
    dispatch(flowerStandActions.fetchFlowerStandList.started({ eventId }));
    // @ts-expect-error TS2322
    dispatch(flowerStandActions.fetchPurchaseStatus.started({ eventId }));
    dispatch(purchaseActions.getEmoBalance.started());
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.getEmoBalance.started,
        args: null,
      })
    );
    return () => {
      dispatch(
        firestoreActions.closeChannel({ channel: "flowerStandPurchaseState" })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newProps = {
    parentProps: {},
  };

  const childrenWithProps = Children.map(
    // @ts-expect-error TS2345
    props.children,
    (child: ReactElement) => {
      switch (typeof child) {
        case "string":
          return child;
        case "object":
          return React.cloneElement(child, newProps);
        default:
          return null;
      }
    }
  );

  return <>{childrenWithProps}</>;
};

export default EventFlowerStandBase;
