import { Code, ConnectError } from "@bufbuild/connect";
import firebase from "firebase/app";
import useSWRMutation from "swr/mutation";
import {
  createConnectClient,
  PortalTicketBonusMedia,
} from "utility/connectWeb";

export type EventTicketBonusMedia = PortalTicketBonusMedia & {
  eventId: string;
};

export const useEventTicketsBonusMediaLazy = (
  eventId: string,
  ticketIds: string[]
) => {
  const auth = firebase.auth();
  const connectClient = createConnectClient(auth);

  return useSWRMutation(
    ["getTicketsBonusMedia", eventId, ticketIds],
    async ([_, eventId, ticketIds]) => {
      if (ticketIds.length === 0) {
        console.warn("チケットが存在しないイベントです");
        return [];
      }

      try {
        const response = await connectClient.getTicketsBonusMedia({
          eventId,
          ticketIds,
        });
        return (
          response.ticketBonusMedia?.map<EventTicketBonusMedia>((bonus) => ({
            ...bonus,
            eventId,
          })) ?? []
        );
      } catch (e) {
        if (e instanceof ConnectError && e.code === Code.NotFound) {
          // 共有チケットだとエラーになるはずなのでここで無視する
          // ※現状エラーにはなっていない
          return [];
        }
        throw e;
      }
    }
  );
};
