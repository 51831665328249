/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx, Global } from "@emotion/core";
import React, {
  useState,
  ComponentProps,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { isDisplayPeriod } from "../../../utility";
import { Store } from "store";
import { modalActions } from "modules/modal";
import { GoodsVenueSelector } from "components/event/goods/GoodsVenueSelector";
import { useI18n } from "hooks/i18n/i18n";
import { getDisplayPrice } from "utility/ticket";
import type { Goods, ProductData } from "@spwn/types/firebase/firestore";
import { StreamingLiveCommerceRegisterCredit } from "../../../components/streaming/liveCommerce/StreamingLiveCommerceRegisterCredit";
import { getCardTokenGMO } from "../../../utility/gmo";
import { purchaseActions } from "../../../modules/purchase";
import { useLiveCommerceShippingFee } from "../../../hooks/Settlement";
import { GoodsSelectCard } from "../../../components/goods/GoodsSelectCard";
import { usePurchaseCapsule } from "hooks/capsule/PurchaseCapsule";
import FileDocumentEditOutline from "mdi-material-ui/FileDocumentEditOutline";
import { Button, LinkButton } from "../../../ui/Button";
import { CapsuleSelectNumberOfRolls } from "../../../components/capsule/CapsuleSelectNumberOfRolls";
import { useCapsuleSelectOptions } from "../../../components/capsule/useCapsuleSelectOptions";
import { CapsuleTotalFeeDetails } from "../../../components/capsule/CapsuleTotalFeeDetails";
import { useCapsuleAnimationImages } from "../../../components/capsule/useCapsuleAnimationImages";
import { CapsuleResultMultiple } from "../../../components/capsule/CapsuleResultMultiple";
import { sanitizer } from "../../../utility/sanitize";
import { CapsuleResultSingle } from "components/capsule/CapsuleResultSingle";
import { SHIPPING_FEE_LABEL } from "constants/fulfillment";

type EventGoodsItemInfoProps = {
  eventId: string;
  classGoodsList: Goods[];
  repGoods: Goods;
  selectablePlaces: ComponentProps<typeof GoodsVenueSelector>["venues"];
  isAdmin: boolean;
};

type ReqRegisterCardInfo = ComponentProps<
  typeof StreamingLiveCommerceRegisterCredit
>["registerCardInfo"];

export const EventCapsuleItemInfo: React.FC<EventGoodsItemInfoProps> = (
  props
) => {
  const isLogined = useSelector((state: Store) => Boolean(state.auth.user.uid));
  const isAddressRegistered = useSelector(
    (state: Store) => state.auth.isAddressRegistered
  );
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const registeredCardList = useSelector(
    (state: Store) => state.purchase.cardInfoList
  );
  const { resultLotteryProducts, purchaseCapsule, error, clearResult } =
    usePurchaseCapsule();

  // カプセルまとめ買いの回数選択
  const { options, numOfRolls, setNumOfRolls } = useCapsuleSelectOptions({
    eventId: props.eventId,
    capsuleId: props.repGoods.class,
  });
  // まとめ買いアニメーション
  const { animationImages, refreshImages } = useCapsuleAnimationImages();

  const [isOpenSettlement, setIsOpenSettlement] = useState<boolean>(false);
  const [isOpenResult, setIsOpenResult] = useState<boolean>(false);
  const [registerCardInfo, setRegisterCardInfo] =
    // @ts-expect-error TS2345
    useState<ReqRegisterCardInfo>(null);
  const [selectCreditCardSeq, setCreditCardSeq] = useState(null);
  // @ts-expect-error TS2345
  const [securityCode, setSecurityCode] = useState<string>(null);
  const [isRegisterCard, setIsRegisterCard] = useState<boolean>(false);
  const [paymentType, setPaymentType] =
    useState<"credit" | "convenience" | "phone" | null>(null);

  const { t } = useI18n();
  const dispatch = useDispatch();
  const price = getDisplayPrice(props.repGoods as ProductData);
  const { isCalculatingShippingFee, shippingFee } = useLiveCommerceShippingFee(
    displayEvent,
    props.classGoodsList as ProductData[]
  );
  const isOnSale =
    (displayEvent.goodsSaleStatus === "ON_SALE" &&
      isDisplayPeriod(
        props.repGoods.display,
        props.repGoods.releaseDateTime,
        props.repGoods.closeDateTime
      )) ||
    props.isAdmin;
  const disablePurchase =
    !isLogined ||
    isCalculatingShippingFee ||
    !selectCreditCardSeq ||
    !securityCode ||
    !isAddressRegistered;
  const classes = styles();

  const initialize = () => {
    setCreditCardSeq(null);
    // @ts-expect-error TS2345
    setSecurityCode(null);
  };

  const openRegisterCard = () => {
    setIsRegisterCard(true);
    initialize();
  };
  const closeRegisterCard = () => {
    setIsRegisterCard(false);
  };

  const registerNewCard = () => {
    getCardTokenGMO({
      cardNo: registerCardInfo.creditNumber,
      expire:
        registerCardInfo.creditExpirationYear.toString() +
        registerCardInfo.creditExpirationMonth.toString(),
      securityCode: registerCardInfo.creditSecurityCode,
      holderName: registerCardInfo.creditName,
      tokenNumber: 1,
      // @ts-expect-error TS7008
    }).then((response: { isError: boolean; tokens }) => {
      if (
        response.tokens === undefined ||
        response.tokens === null ||
        response.tokens.length === 0
      ) {
        dispatch(modalActions.toggleError({ msg: "カード登録に失敗しました" }));
        return;
      }
      dispatch(
        purchaseActions.registerCardInfo.started({ token: response.tokens[0] })
      );
      closeRegisterCard();
      // @ts-expect-error TS2345
      setRegisterCardInfo(null);
      setIsOpenSettlement(true); //todo: 決済方法が追加されたら削除する
    });
  };

  const checkInputValue = () => {
    return Boolean(
      registerCardInfo &&
        registerCardInfo.creditNumber &&
        registerCardInfo.creditExpirationMonth &&
        registerCardInfo.creditExpirationYear &&
        registerCardInfo.creditName &&
        registerCardInfo.creditSecurityCode
    );
  };

  const startPurchaseProcess = async () => {
    // return if cannot exec purchase.
    if (disablePurchase) {
      return;
    }
    // open result popup
    setIsOpenResult(true);
    // exec purchase api
    // payTypeは現状固定
    const count = numOfRolls;
    const displayPrice = props.repGoods.price_jpy * count + shippingFee;
    const payType = "Member";
    const payInfo = {
      cardSeq: selectCreditCardSeq,
      securityCode,
    };
    await purchaseCapsule({
      eventId: displayEvent.eventId,
      capsuleId: props.repGoods.class,
      displayPrice,
      count,
      payType,
      payInfo,
    });
  };

  const closeResult = useCallback(() => {
    refreshImages();
    clearResult();
    setIsOpenResult(false);
  }, [clearResult, refreshImages]);

  useEffect(() => {
    if (!error) return;
    // close result and pop up error.
    setIsOpenResult(false);
    refreshImages();
    dispatch(modalActions.toggleError({ msg: error.msg }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const shippingFeeText = useMemo(() => {
    return `（1決済ごとに${SHIPPING_FEE_LABEL} ¥${shippingFee.toLocaleString()}）`;
  }, [shippingFee]);

  return (
    <div css={classes.root}>
      {isOpenResult && <Global styles={globalStyle} />}

      <h2>{props.repGoods?.name}</h2>
      <div css={classes.priceArea}>
        <p className={"price"}>{`1回 ¥${price.toLocaleString()}`}</p>
        {
          // Don't display shipping fee under the following conditions because can't calculate.
          // - user is not logged in
          // - user haven't registered address
          // - calculating shipping fee
          isLogined && isAddressRegistered && !isCalculatingShippingFee && (
            <p className={"shipping"}>{shippingFeeText}</p>
          )
        }
      </div>

      {isLogined && !isAddressRegistered && (
        <div css={classes.pleaseRegisterAddress}>
          <p>{t("goods.needAddress")}</p>
          <LinkButton
            href={"/account/address"}
            // FIXME: marginの打ち消しをやめる
            margin={css`
              margin-left: -8px;
              margin-top: 12px;
            `}
            variant={"ghost"}
            size={"sm"}
            prefix={<FileDocumentEditOutline />}
          >
            {t("goods.registerAddress")}
          </LinkButton>
        </div>
      )}

      {isOnSale && isLogined && (
        <div css={classes.capsuleSelectLayout}>
          <p css={classes.selectTitle}>{t("capsule.selectOptionsCount")}</p>
          <CapsuleSelectNumberOfRolls
            options={options}
            value={numOfRolls}
            onChange={setNumOfRolls}
          />
        </div>
      )}

      {!isOnSale ? (
        <Button
          size={"full"}
          margin={css`
            margin-bottom: 24px;
          `}
          disabled
        >
          {t("goods.periodError")}
        </Button>
      ) : (
        // Don't display capsule button under the following condition
        // - user haven't registered address
        (isAddressRegistered || !isLogined) &&
        !isOpenSettlement && (
          <Button
            size={"full"}
            margin={css`
              margin-bottom: 40px;
            `}
            onClick={() => {
              if (!isLogined) {
                dispatch(modalActions.toggleLoginMethod());
                return;
              }
              setIsOpenSettlement(true);
              setPaymentType("credit"); //todo: 決済方法が追加されたら削除する
            }}
          >
            {t("capsule.selectPaymentMethod")}
          </Button>
        )
      )}

      {isOpenSettlement && (
        <React.Fragment>
          <CapsuleTotalFeeDetails
            numOfRolls={numOfRolls}
            price={price}
            shippingFee={shippingFee}
          />
          <div
            css={css`
              margin-bottom: 40px;
            `}
          >
            <h3 css={classes.paymentHeading}>決済方法を選択</h3>

            {!paymentType && (
              <ul css={classes.paymentList}>
                <li
                  onClick={() => {
                    setPaymentType("credit");
                  }}
                >
                  クレジットカード
                </li>
              </ul>
            )}

            {/* 支払い方法リストの「クレジットカード」が選択された時、カードが登録されていない場合、カードを登録するフォームを表示する */}
            {paymentType === "credit" && isRegisterCard && (
              <StreamingLiveCommerceRegisterCredit
                isDarkModeEnabled={false}
                registerCardInfo={registerCardInfo}
                registerNewCard={registerNewCard}
                setRegisterCardInfo={setRegisterCardInfo}
                closeRegisterCard={closeRegisterCard}
                checkInputValue={checkInputValue}
              />
            )}

            {/* 支払い方法リストの「クレジットカード」が選択された時、カードが登録されている場合、カードを選択出来るようにする */}
            {paymentType === "credit" && !isRegisterCard && (
              <React.Fragment>
                <GoodsSelectCard
                  // @ts-expect-error TS2322
                  registeredCardList={registeredCardList}
                  setSecurityCode={setSecurityCode}
                  openRegisterCard={openRegisterCard}
                  // @ts-expect-error TS2322
                  setCreditCardSeq={setCreditCardSeq}
                />
                <Button
                  size={"full"}
                  margin={css`
                    margin-top: 24px;
                  `}
                  disabled={disablePurchase}
                  onClick={startPurchaseProcess}
                >
                  {t("capsule.rollCapsule")}
                </Button>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      )}
      {isOpenResult &&
        (resultLotteryProducts.length === 1 ? (
          <CapsuleResultSingle
            resultProduct={resultLotteryProducts[0]}
            onClickClose={closeResult}
            capsuleAnimationUrls={animationImages}
            refreshImages={refreshImages}
          />
        ) : (
          <CapsuleResultMultiple
            resultProducts={resultLotteryProducts}
            refreshImages={refreshImages}
            onClickClose={closeResult}
            capsuleAnimationUrls={animationImages}
          />
        ))}
      {/* description */}
      {props.repGoods?.specification ? (
        <div
          css={classes.description}
          dangerouslySetInnerHTML={{
            __html: sanitizer(props.repGoods.specification),
          }}
        />
      ) : null}
    </div>
  );
};

const globalStyle = css`
  body {
    height: 100vh;
    overflow-y: hidden;
  }
`;

const styles = () => {
  return {
    root: css`
      width: 90%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: calc(41% - 17px);
      }
      h2 {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 1.5em;
      }
    `,
    priceArea: css`
      margin-bottom: 24px;
      .price {
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: bold;
      }
      .shipping {
        font-size: 14px;
        font-weight: bold;
      }
    `,
    selectTitle: css`
      font-size: 16px;
      font-weight: bold;
    `,
    capsuleSelectLayout: css`
      display: flex;
      flex-direction: column;
      gap: 12px;
    `,
    salesPeriod: css`
      padding: 12px 20px;
      margin-bottom: 20px;
      color: #fc1f74;
      font-size: 14px;
      text-align: center;
      border: 1px solid #fc1f74;
    `,

    selectSize: css`
      margin-bottom: 32px;
    `,
    selectSizeForm: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      border: 2px solid #e5e5e5;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    `,
    selectSizeLabel: css`
      width: calc(100% - 90px);
    `,
    selectSizeButton: css`
      display: flex;
      width: 75px;
      margin-left: 15px;
      border: 1px solid #e5e5e5;
      border-radius: 3px;
      input {
        width: calc(100% / 3);
        padding: 0 4px;
        font-size: 14px;
        text-align: center;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 3);
        padding: 5px 0;
        border-left: 1px solid #e5e5e5;
        background-color: #f4f4f4;
        cursor: pointer;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    `,
    selectSoldOutLabel: css`
      display: flex;
      p {
        display: inline-block;
        padding: 4px 8px;
        color: #fff;
        font-size: 12px;
        background-color: #da3d50;
        @media screen and (min-width: 768px) {
          font-size: 10px;
        }
      }
    `,

    placeSelector: css`
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    `,
    pleaseRegisterAddress: css`
      margin-bottom: 24px;
      padding: 20px 0 80px;
      p {
        font-size: 14px;
        line-height: 1;
        color: #f00;
      }
      a {
        margin-top: 12px;
        font-size: 14px;
        line-height: 1;
        text-decoration: none;
        color: #00c2ae;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        > svg {
          width: 20px;
          height: 20px;
        }
      }
    `,
    paymentHeading: css`
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    `,
    paymentList: css`
      li {
        padding: 12px 0;
        color: #fff;
        font-size: 14px;
        text-align: center;
        background-color: #00c2ae;
        cursor: pointer;
      }
    `,

    methodOfPayment: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      p {
        font-size: 14px;
      }
      .change {
        color: #00c2ae;
        cursor: pointer;
      }
    `,
    description: css`
      h3 {
        margin: 15px 0 10px;
      }
      p {
        color: #3c3c3c;
        font-size: 14px;
        line-height: 1.5em;
      }
      ul {
        li {
          color: #3c3c3c;
          font-size: 14px;
          line-height: 1.5em;
        }
      }
    `,
  };
};
