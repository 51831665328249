/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../../store";
import firebase from "firebase/app";
import "firebase/firestore";

import "firebase/auth";
import "../../designs/css/ui.css";
import BorderLoader from "../../components/atoms/loader/BorderLoader";
import {
  domain_from_url,
  IsJsonString,
  splitBr,
  AutoLink,
} from "../../utility";

import { createPlayer } from "./StreaminngUtility";
import appConfig from "../../constants/appConfig";
import { modalActions } from "../../modules/modal";
import { BreadcrumbArea } from "components/common/Link";
import { changeStyleWithHosting } from "utility/hosting";
import { WithTranslation, withTranslation } from "react-i18next";
import { TFunction } from "i18next";
import type {
  StreamingTestPageInfo,
  THEOSetting,
} from "@spwn/types/firebase/firestore";

type Props = ValueProps &
  FuncProps &
  ParentProps & {
    cookies: unknown;
  };
interface ParentProps extends WithTranslation {
  t: TFunction;
}

interface ValueProps {}

interface FuncProps {
  // @ts-expect-error TS7051
  toggleError: (any) => void;
}

interface States {
  has_streaming_key_loaded: boolean;
  theaterModeState: boolean;
  description: StreamingTestPageInfo;
}

class StreamingViewTest extends Component<Props, States> {
  _player = null;
  _streamingUrl = null;
  _hostingStyleMap = changeStyleWithHosting();

  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    this.state = {
      has_streaming_key_loaded: false,
      theaterModeState: false,
      description: {
        // @ts-expect-error TS2322
        msg: null,
        // @ts-expect-error TS2322
        subTitle: null,
        // @ts-expect-error TS2322
        title: null,
      },
    };
    this.fetchDescription();
  }

  componentDidMount() {
    const poster = `https://public.spwn.jp/spwn-steaming/test_view_thumbnail.jpg`;
    const setting: THEOSetting = {
      abr: {
        strategy: "bandwidth",
        targetBuffer: 12,
      },
      playBackRates: [2, 1.5, 1, 0.75],
      allowNativeFullscreen: false,
      enablePiP: false,
      initialLiveOffset: 10,
    };
    this._player = createPlayer(poster, null, setting, "VOD");
    this.get_streaming_key();
  }

  fetchDescription = async () => {
    try {
      const ret = await firebase
        .firestore()
        .doc("news/streamingTestPage/")
        .get();
      const data = ret.data() as StreamingTestPageInfo; // !!
      console.log(data);
      if (!!data.msg && !!data.subTitle && !!data.title) {
        this.setState({ description: data });
      }
    } catch (e) {
      console.error(e);
    }
  };

  // @ts-expect-error TS7006
  updatePlayer = (streamingUrl) => {
    // @ts-expect-error TS2531
    this._player.source = {
      sources: [
        {
          src: streamingUrl,
          type: "application/x-mpegurl",
          lowLatency: true,
          liveOffset: 10,
          crossOrigin: "use-credentials",
        },
      ],
    };
    // @ts-expect-error TS2531
    this._player.play();
  };

  render() {
    const breadcrumbText = this._hostingStyleMap.commonSettings.menu.event.text;
    const classesStreamingViewTestDesign = streamingViewTestDesign();
    return (
      <div id="contents" css={classesStreamingViewTestDesign.root}>
        <BreadcrumbArea
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            ["/events", breadcrumbText],
            [null, "配信テスト"],
          ]}
        />

        <div css={classesStreamingViewTestDesign.video}>
          <div id="video">
            <div className="theoplayer-container video-js theoplayer-skin"></div>
          </div>
          <div hidden={this.state.has_streaming_key_loaded}>
            <BorderLoader />
          </div>
          <div css={classesStreamingViewTestDesign.info}>
            {this.description_render()}
          </div>
        </div>
      </div>
    );
  }

  description_render = () => {
    const { t } = this.props;
    const default_message = `${t("streaming.testPage.paragraph1")}<br/>${t(
      "streaming.testPage.paragraph2"
    )}<br/>${t("streaming.testPage.paragraph3")}`;
    const msg = this.state.description.msg
      ? this.state.description.msg
      : default_message;
    return (
      <React.Fragment>
        <p className="title">
          {this.state.description.title
            ? this.state.description.title
            : t("purchase.purchase.succsessPurchaseMsg")}
        </p>
        <p className="artist">
          {this.state.description.subTitle
            ? this.state.description.subTitle
            : t("streaming.testPage.subTitle")}
        </p>
        <p className="description" style={{ whiteSpace: "pre-line" }}>
          {splitBr(msg).map((el: string, i) => (
            <React.Fragment key={i}>
              {el}
              <br />
            </React.Fragment>
          ))}
          <br />
          <br />
          {AutoLink(
            "(c) copyright 2008, Blender Foundation / https://www.bigbuckbunny.org"
          )}
        </p>
      </React.Fragment>
    );
  };

  get_streaming_key = async () => {
    try {
      // const fbToken = await firebase.auth().currentUser.getIdToken(true);
      const response = await fetch(
        // @ts-expect-error TS2769
        appConfig.CloudFunctions.getTestPageStreamingKey,
        {
          // const response = await fetch("http://www.spwn.jp:8000/get_test_page_streaming_key", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'Authorization': `'Bearer ${fbToken}`
          },
          body: JSON.stringify({}),
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        if (json.hasOwnProperty("cookie")) {
          const domain = domain_from_url(json.url);
          Object.keys(json.cookie).forEach((key, _idx) => {
            document.cookie = `${key}=${json.cookie[key]};domain=${domain};path=/`;
          });
        }
        if (this._streamingUrl !== json.url) {
          this._streamingUrl = json.url;
          this.updatePlayer(this._streamingUrl);
          this.setState({ has_streaming_key_loaded: true });
        }
        return;
      }

      const text = await response.text();
      console.error(text);
      if (IsJsonString(text)) {
        this.props.toggleError({ msg: JSON.parse(text).msg });
      } else {
        console.error(text);
        this.props.toggleError({ msg: "テスト映像の再生に失敗しました" });
      }
    } catch (e) {
      console.error(e);
      this.props.toggleError({ msg: "テスト映像の再生に失敗しました。" });
    }
  };
}

const mapStateToProps = (_state: Store) => {
  const props: ValueProps = {};
  return props;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const func: FuncProps = {
    toggleError: (payload) => {
      dispatch(modalActions.toggleError(payload));
    },
  };
  return func;
};

const TransStreamingViewTest = withTranslation()(StreamingViewTest);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransStreamingViewTest);

const streamingViewTestDesign = () => {
  return {
    root: css`
      width: 100%;
      max-width: 1800px;
      min-height: calc(100vh - 255px);
      margin: 0 auto;
      background-color: #fff;
    `,
    video: css`
      width: 100%;
      max-width: 1000px;
      padding-bottom: 50px;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 90%;
      }
      #video {
        position: sticky;
        top: 0;
        margin-bottom: 20px;
        @media screen and (min-width: 768px) {
          position: relative;
        }
      }
    `,
    info: css`
      width: 90%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 100%;
      }
      p {
        line-height: 1.3em;
      }
      .title {
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: bold;
      }
      .artist {
        padding-bottom: 20px;
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #e0e0e0;
      }
      .description {
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 1.5em;
      }
    `,
  };
};
