import styled from "@emotion/styled";
import { myPageEditMixin } from "../../mixin";

const _MyPageEditProfile = styled.div`
  @media screen and (min-width: 768px) {
    margin: 0 auto;
  }
  .contents_layout {
    padding: 40px 0;
  }
  .profile_edit_img {
    position: relative;
    width: 45%;
    margin: 0 auto 40px;
    @media screen and (min-width: 768px) {
      width: 250px;
    }
    img {
      width: 100%;
      border-radius: 50%;
      border: 2px solid #dedede;
    }
    .profile_edit_img_btn {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 45px;
      height: 45px;
      text-align: center;
      background-color: #000;
      background-image: url("/images/icon_camera.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 50% auto;
      border-radius: 50%;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        width: 60px;
        height: 60px;
      }
      .touch_area {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  .prifle_edit_info {
    width: 100%;
    margin: 0 auto 80px;
    @media screen and (min-width: 768px) {
      width: 500px;
    }
  }
`;

export const MyPageEditProfile = styled(_MyPageEditProfile)(myPageEditMixin);

MyPageEditProfile.displayName = "MyPageEditProfile";
