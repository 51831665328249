import React from "react";
import { Event, Place } from "@spwn/types/firebase/firestore";
import { useI18n } from "hooks/i18n/i18n";
import Box from "@material-ui/core/Box";
import { sanitizer } from "utility/sanitize";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

export const AccountTicketItemTicketInfo: React.FC<{
  ticketStateImageUrl: string;
  place: Place | undefined;
  isFesEvent: boolean;
  productName: string;
  event: Event;
  vodExpiredAt: string;
  description: string;
  price: string;
  count: string;
}> = ({
  ticketStateImageUrl,
  place,
  isFesEvent,
  productName,
  event,
  vodExpiredAt,
  description,
  price,
  count,
}) => {
  const { t } = useI18n();
  const [isOpenAccordion, setIsOpenAccordion] = React.useState<boolean>(false);

  const handleAccordion = () => {
    setIsOpenAccordion(!isOpenAccordion);
  };

  return (
    <Box marginBottom={"15px"} borderBottom={"1px solid #d4d4d4"}>
      {ticketStateImageUrl && (
        <Box marginBottom={"30px"}>
          <img
            src={ticketStateImageUrl}
            alt=""
            width={"60px"}
            height={"auto"}
          />
        </Box>
      )}

      {place && (
        <Box component={"p"} fontSize={"14px"} fontWeight={"bold"}>
          {place.areaName} {place.venueName}
        </Box>
      )}

      {/* HOTFIX フェスチケットはマスターデータの名前・価格が変更されるので表示しない */}
      <Box
        component={"p"}
        marginTop={"8px"}
        fontSize={["18px", "20px"]}
        fontWeight={"bold"}
        lineHeight={"1.3em"}
      >
        {isFesEvent ? "チケット" : productName}
      </Box>

      {description.length > 0 && (
        <Box>
          <Box
            component={"p"}
            height={isOpenAccordion ? "auto" : "2.6em"}
            marginTop={"20px"}
            fontSize={"14px"}
            lineHeight={"1.3em"}
            overflow={"hidden"}
            dangerouslySetInnerHTML={{
              __html: sanitizer(description),
            }}
          />
          <Box
            display={"inline-flex"}
            alignItems={"center"}
            color="#00C2AD"
            fontSize={"12px"}
            onClick={handleAccordion}
          >
            <Box component={"span"}>
              {isOpenAccordion ? "折りたたむ" : "もっと見る"}
            </Box>
            <Box component={"span"}>
              {isOpenAccordion ? (
                <ExpandLessIcon fontSize="small" />
              ) : (
                <ExpandMoreIcon fontSize="small" />
              )}
            </Box>
          </Box>
        </Box>
      )}

      <Box
        display={"grid"}
        gridTemplateColumns={"1fr max-content"}
        marginTop={"20px"}
      >
        <Box>
          {!event.isHiddenVODExpiredAt && vodExpiredAt && (
            <Box
              component={"p"}
              marginBottom={"8px"}
              color={"red"}
              fontSize={"14px"}
            >
              {t("ticket.expirationDate", {
                date: vodExpiredAt,
              })}
            </Box>
          )}

          {!isFesEvent && (
            <Box
              component={"p"}
              fontSize={["18px", "20px"]}
              fontWeight={"bold"}
            >
              ¥{price}
            </Box>
          )}
        </Box>

        <Box
          fontWeight={"400"}
          fontStyle={"normal"}
          style={{
            /* stylelint-disable */
            fontFamily: `"din-condensed", "sans-serif"`,
          }}
        >
          <Box component={"span"} fontSize={"20px"}>
            ×
          </Box>
          <Box component={"span"} fontSize={"40px"}>
            {count}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
