import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

type Props = {
  height?: string;
  width?: string;
};

const stretchDelayKeyframe = keyframes`
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1.0);
  }
`;

const _Spinner = styled.div`
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  margin: 0 auto;
  & > div {
    background-color: #979797;
    height: 100%;
    width: 4px;
    margin-right: 3px;
    display: inline-block;
    animation: ${stretchDelayKeyframe} 1.2s infinite ease-in-out;
    @media screen and (min-width: 768px) {
      width: 5px;
    }
    &.rect2 {
      animation-delay: -1.1s;
    }
    &.rect3 {
      animation-delay: -1s;
    }
    &.rect4 {
      animation-delay: -0.9s;
    }
    &.rect5 {
      animation-delay: -0.8s;
    }
  }
`;

const Spinner = styled(_Spinner)<Props>`
  ${({ height, width }) => {
    return height && width ? `height: ${height}; width: ${width};` : "";
  }}
`;

const LoaderSmall = ({ height, width }: Props) => {
  return (
    <div className="spinner_layout">
      {" "}
      {/* NOTE: AccountCredit からのみ margin-bottom を注入 */}
      <Spinner height={height} width={width}>
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
      </Spinner>
    </div>
  );
};

export default LoaderSmall;
