import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import { authActions } from "../../modules/auth";
import BorderLoader from "../../components/atoms/loader/BorderLoader";
import "firebase/auth";
import { modalActions } from "../../modules/modal";
import { hasEmailProviderData } from "../../utility/auth";
import { BreadcrumbArea } from "components/common/Link";
import { useI18n } from "hooks/i18n/i18n";
import styled from "@emotion/styled";
import {
  EditForm,
  LinkButton,
  myPageEditMixin,
  ContentsLayout,
} from "../../styles";

const _Container = styled.div`
  @media screen and (min-width: 768px) {
    margin: 0 auto;
  }
  .caution {
    padding-top: 50px;
    text-align: center;
    font-weight: bold;
    @media screen and (min-width: 768px) {
      padding-top: 100px;
    }
  }
  .current_pass {
    width: 100%;
    margin: 0 auto 40px;
    @media screen and (min-width: 768px) {
      width: 740px;
    }
  }
  .new_pass {
    width: 100%;
    margin: 0 auto 80px;
    @media screen and (min-width: 768px) {
      width: 740px;
    }
  }
  .button {
    width: 100%;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 740px;
    }
  }
`;

const Container = styled(_Container)(myPageEditMixin);

export const AccountEditPassword: React.FC = () => {
  const { t } = useI18n();
  const user = useSelector((state: Store) => state.auth.user);

  const [statePW, setStatePW] = useState({
    PW: "",
    newPW1: "",
    newPW2: "",
  });
  const dispatch = useDispatch();

  const onClickEditPassword = () => {
    // eslint-disable-next-line eqeqeq
    if (statePW.newPW1 == "" || statePW.newPW2 == "") {
      return;
    } else if (statePW.newPW1 !== statePW.newPW2) {
      dispatch(
        modalActions.toggleError({ msg: t("account.password.noMatch") })
      );
      return;
    } else if (statePW.newPW1.length < 8) {
      dispatch(modalActions.toggleError({ msg: t("account.password.easyPW") }));
      return;
    }

    dispatch(
      authActions.reAuthWithEmail.started({
        mode: "updatePW",
        email: user.email,
        password: statePW.PW,
        newPassword: statePW.newPW1,
      })
    );

    setStatePW({
      PW: "",
      newPW1: "",
      newPW2: "",
    });
  };

  // @ts-expect-error TS7006
  const onUpdatePW = (event) => {
    const target = event.currentTarget.id;
    // this.setState({[target]: event.currentTarget.value})
    setStatePW({ ...statePW, [target]: event.currentTarget.value });
  };

  if (Object.keys(user).length === 0) {
    return <BorderLoader />;
  } else if (!hasEmailProviderData(user)) {
    return (
      <Container id="contents">
        <p className="caution">{t("account.password.updateSNS")}</p>
      </Container>
    );
  }

  return (
    <Container id="contents">
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/account", "アカウント情報"],
          [null, "パスワードの編集"],
        ]}
      />
      <ContentsLayout>
        <div className="current_pass">
          <EditForm>
            <p>{t("account.password.nowPW")}</p>
            <input
              type="password"
              name="current_pass"
              id={"PW"}
              onChange={onUpdatePW}
              value={statePW.PW}
              placeholder={t("account.password.enterNowPW")}
            />
          </EditForm>
        </div>

        <div className="new_pass">
          <EditForm>
            <p>{t("account.password.newPW")}</p>
            <input
              type="password"
              name="new_pass"
              id={"newPW1"}
              onChange={onUpdatePW}
              value={statePW.newPW1}
              placeholder={t("account.password.enterNewPW")}
            />
          </EditForm>
          <EditForm>
            <p>{t("account.password.confirmNewPW")}</p>
            <input
              type="password"
              name="new_pass_re"
              id={"newPW2"}
              onChange={onUpdatePW}
              value={statePW.newPW2}
              placeholder={t("account.password.reEnterNewPW")}
            />
          </EditForm>
        </div>

        <a href="#!" onClick={onClickEditPassword}>
          <LinkButton className="button">
            {t("account.password.changePW")}
          </LinkButton>
        </a>
      </ContentsLayout>
    </Container>
  );
};
