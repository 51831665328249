/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import type { Video } from "@spwn/types";

interface Props {
  status: Video["actionStatus"];
  onClick?: () => void;
  doButtonAction?: () => void;
}
export const EventTimetableLabel: React.FC<Props> = (props) => {
  const classes = styles();
  switch (props.status) {
    case "NotAvailableForPurchase":
    case "NotPurchased":
    case "InCart":
    case "NotPlayable":
      return null;

    case "Playable":
      return (
        <p css={classes.label} className="play">
          LIVE
        </p>
      );

    case "BeforePlay":
      return <p css={classes.label}>購入済</p>;

    default:
      return null;
  }
};

const styles = () => {
  return {
    label: css`
      position: absolute;
      top: 8%;
      right: 4%;
      padding: 4px 8px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 1px;
      background-color: #00c2ad;
      &.play {
        background-color: #ea3376;
      }
    `,
  };
};
