/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useState, useEffect, useMemo } from "react";
import { useI18n } from "hooks/i18n/i18n";
import { useSelector } from "react-redux";
import { Store } from "store";
import BorderLoader from "components/atoms/loader/BorderLoader";
import ConfirmCvsPayment from "components/ConfirmCvsPayment";
import type { UnProcessData } from "components/ConfirmCvsPayment";
import type { PayType } from "@spwn/types";
import type { CVSCode, PhoneCode } from "@spwn/types/gmo";
import type { ActiveTransaction } from "@spwn/types/firebase/firestore";
import { useGetPlaceList } from "../../hooks/event/useGetPlaceList";
import { BreadcrumbNavigation } from "ui/BreadcrumbNavigation";
import { AccountTicketCategoryTab } from "./AccountTicketCategoryTab";
import Box from "@material-ui/core/Box";
import { AccountTicketListInSession } from "./AccountTicketListInSession";
import { AccountTicketListArchiveEvent } from "./AccountTicketListArchiveEvent";
import { AccountTicketListBeforeEvent } from "./AccountTicketListBeforeEvent";

interface Props {}

export type TabState =
  | "beforeEvent"
  | "archiveEvent"
  | "waitingPayment"
  | "inSession";

interface CvsInfo {
  cvsCode: CVSCode;
  ReceiptNo: string;
  ConfNo: string;
  payType: PayType;
}

interface PhoneInfo {
  phoneCode: PhoneCode;
  phoneURL: string;
  payType: PayType;
}

export const AccountTicket: React.FC<Props> = (_props) => {
  const { t } = useI18n();

  // props value
  const _auth = useSelector((state: Store) => state.auth.user);
  const myTickets = useSelector((state: Store) => state.ticket.myTickets);
  const myUnprocessData = useSelector(
    (state: Store) => state.ticket.myUnprocessData
  );
  const eventMap = useSelector(
    (state: Store) => state.event.userRelatedEventMap
  );
  const activeTransactionMap = useSelector(
    (state: Store) => state.purchase.activeTransactionMap
  );

  // ページに必要な情報が取得できたかどうか
  const [isFetchedPossTickets, setIsFetchedPossTickets] =
    useState<boolean>(false); // check if pos ticket info
  const [isOpenConfirmCvsPayment, setIsOpenConfirmCvsPayment] =
    // @ts-expect-error TS2345
    useState<boolean>(null);
  // @ts-expect-error TS2345
  const [unprocessData, setUnprocessData] = useState<UnProcessData[]>(null);

  /**
   * @description タブの状態を管理する
   * Floating Buttonから遷移した場合は、queryにinSessionが渡されるので、それをtabStateを取得する
   *
   * @deprecated
   * Floating Buttonから遷移した場合は、queryにinSessionが渡されるので、それをtabStateに設定していた。
   * タブのデフォルトがinSessionになったので、この処理は不要になった。
   */
  // const query =
  //   location.query.tabState === "inSession" ? "inSession" : "inSession";
  const [tabState, setTabState] = useState<TabState>("inSession");

  const [cvsInfo, setCvsInfo] = useState<CvsInfo>({
    // @ts-expect-error TS2322
    cvsCode: null,
    // @ts-expect-error TS2322
    ReceiptNo: null,
    // @ts-expect-error TS2322
    ConfNo: null,
    // @ts-expect-error TS2322
    payType: null,
  });
  const [phoneInfo, setPhoneInfo] = useState<PhoneInfo>({
    // @ts-expect-error TS2322
    phoneCode: null,
    // @ts-expect-error TS2322
    phoneURL: null,
    // @ts-expect-error TS2322
    payType: null,
  });
  // @ts-expect-error TS2345
  const [selectedOrderId, setSelectedOrderId] = useState<number>(null);

  // Place情報を取得する
  const placeIds = useMemo(
    () =>
      (myTickets ?? []).flatMap((el) => {
        const ticketPlaces = el.tickets.map((el) => el.place);
        const goodsPlaces = el.goods.map((el) => el.place);
        return [...ticketPlaces, ...goodsPlaces];
      }),
    [myTickets]
  );
  const { data: places, isLoading: isLoadingPlaces } =
    useGetPlaceList(placeIds);

  // like componentDidUpdate
  useEffect(() => {
    if (myTickets && myUnprocessData) {
      setIsFetchedPossTickets(true);
    }
  }, [myTickets, myUnprocessData]);

  const toggleConfirmCvsPayment = (transactionData: ActiveTransaction) => {
    // 確認画面用のデータを作成する
    const { orderId } = transactionData;
    const unprocessDataList: UnProcessData[] = [];
    for (const { event, tickets, goods, unprocessOrderIds } of myTickets) {
      if (!unprocessOrderIds.includes(String(orderId))) continue;
      const ticketList = tickets.filter((el) => el.orderId === String(orderId));
      const goodsList = goods.filter((el) => el.orderId === String(orderId));
      const productList = ticketList.concat(goodsList);
      if (productList.length !== 0) {
        unprocessDataList.push({
          event,
          ticketList,
          goodsList,
        });
      }
    }

    if (transactionData.payType === "CVS") {
      setCvsInfo({
        // @ts-expect-error TS2322
        ConfNo: transactionData.confNo,
        // @ts-expect-error TS2322
        ReceiptNo: transactionData.receiptNo,
        cvsCode: transactionData.convenience as CVSCode,
        payType: transactionData.payType,
      });
      setPhoneInfo({
        // @ts-expect-error TS2322
        phoneCode: null,
        // @ts-expect-error TS2322
        phoneURL: null,
        // @ts-expect-error TS2322
        payType: null,
      });
    } else if (transactionData.payType === "Phone") {
      setPhoneInfo({
        // @ts-expect-error TS2322
        phoneCode: transactionData.phoneCode,
        // @ts-expect-error TS2322
        phoneURL: transactionData.phoneURL,
        payType: transactionData.payType,
      });
      setCvsInfo({
        // @ts-expect-error TS2322
        ConfNo: null,
        // @ts-expect-error TS2322
        ReceiptNo: null,
        // @ts-expect-error TS2322
        cvsCode: null,
        // @ts-expect-error TS2322
        payType: null,
      });
    }
    setUnprocessData(unprocessDataList);
    setIsOpenConfirmCvsPayment(!isOpenConfirmCvsPayment);
    setSelectedOrderId(Number(orderId));
  };

  const closeCvsConfirmModal = () => {
    setIsOpenConfirmCvsPayment(!isOpenConfirmCvsPayment);
  };

  /**
   * render logic
   */

  // MEMO: App.tsxでfetchしている場合があるため、state.ticket.fetchingMyTicketsは見ない
  // MEMO: 特典取得APIが重いので isLoadingBonus は無視して遅延読み込みする
  const isInitialLoading =
    !eventMap || isLoadingPlaces || !isFetchedPossTickets;

  if (isInitialLoading) {
    return (
      <Box
        width={"100%"}
        maxWidth={"1800px"}
        minHeight={["calc(100vh - 436px)", "calc(100vh - 280px)"]}
        paddingBottom={"80px"}
        margin={"0 auto"}
        bgcolor={"#fafafa"}
      >
        <BreadcrumbNavigation
          breadcrumbs={[
            { label: "ホーム", link: "/" },
            { label: t("common.routes.account"), link: "/account" },
            { label: t("common.routes.ticket"), link: null },
          ]}
        />

        <Box marginBottom={["20px", "40px"]}>
          <AccountTicketCategoryTab
            tabState={tabState}
            setTabState={setTabState}
          />
        </Box>

        <BorderLoader />
      </Box>
    );
  }

  return (
    <Box
      width={"100%"}
      maxWidth={"1800px"}
      minHeight={["calc(100vh - 436px)", "calc(100vh - 280px)"]}
      paddingBottom={"80px"}
      margin={"0 auto"}
      bgcolor={"#fafafa"}
    >
      <BreadcrumbNavigation
        breadcrumbs={[
          { label: "ホーム", link: "/" },
          { label: t("common.routes.account"), link: "/account" },
          { label: t("common.routes.ticket"), link: null },
        ]}
      />

      <Box marginBottom={["20px", "40px"]}>
        <AccountTicketCategoryTab
          tabState={tabState}
          setTabState={setTabState}
        />
      </Box>

      <ConfirmCvsPayment
        isOpen={isOpenConfirmCvsPayment}
        close={closeCvsConfirmModal}
        unprocessData={unprocessData}
        cvsCode={cvsInfo.cvsCode}
        ReceiptNo={cvsInfo.ReceiptNo}
        ConfNo={cvsInfo.ConfNo}
        phoneCode={phoneInfo.phoneCode}
        phoneURL={phoneInfo.phoneURL}
        orderId={selectedOrderId}
        activeTransactionMap={activeTransactionMap}
      />

      {tabState === "beforeEvent" && (
        <Box>
          <AccountTicketListBeforeEvent
            eventMap={eventMap}
            myTickets={myTickets}
            myUnprocessData={myUnprocessData}
            places={places}
            onClickToggleConfirmCvsPayment={toggleConfirmCvsPayment}
          />
        </Box>
      )}

      {tabState === "inSession" && (
        <Box>
          <AccountTicketListInSession
            eventMap={eventMap}
            myTickets={myTickets}
            myUnprocessData={myUnprocessData}
            places={places}
            onClickToggleConfirmCvsPayment={toggleConfirmCvsPayment}
          />
        </Box>
      )}

      {tabState === "archiveEvent" && (
        <Box>
          <AccountTicketListArchiveEvent
            eventMap={eventMap}
            myTickets={myTickets}
            myUnprocessData={myUnprocessData}
            places={places}
            onClickToggleConfirmCvsPayment={toggleConfirmCvsPayment}
          />
        </Box>
      )}
    </Box>
  );
};
