import React from "react";
import ReactMarkdown from "react-markdown";

type MarkdownElements =
  | "a"
  | "blockquote"
  | "code"
  | "em"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "hr"
  | "img"
  | "li"
  | "ol"
  | "p"
  | "pre"
  | "strong"
  | "ul"
  | "br";

type Props = {
  allowedMarkdownElements?: MarkdownElements[];
  children: string;
};

export const DisplayUserInputText: React.FC<Props> = (props) => {
  const { allowedMarkdownElements = undefined, children } = props;
  // @ts-expect-error TS7031
  const anchorLink = ({ node, ...props }) => {
    return (
      <a href={node.properties.href} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    );
  };
  return (
    <div style={{ whiteSpace: "pre-line" }}>
      <ReactMarkdown
        allowedElements={allowedMarkdownElements ?? ["a", "p"]}
        unwrapDisallowed={true}
        components={{
          a: anchorLink,
        }}
      >
        {children}
      </ReactMarkdown>
    </div>
  );
};
