/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState } from "react";
import { I18nTrans, useI18n } from "hooks/i18n/i18n";

import { useSelector, useDispatch } from "react-redux";
import { Store } from "../../store";
import { modalActions } from "../../modules/modal";
import { push } from "connected-react-router";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { FullScreenDialog } from "components/common/Modal";

interface Props {}
const ModalLoginMethod: React.FC<Props> = (_props) => {
  const dispatch = useDispatch();
  const { t } = useI18n();
  const isOpen = useSelector((state: Store) => state.modal.isOpenLoginMethod);
  const [checkboxState, setCheckboxState] = useState<boolean>(false);

  const classesLoginPopupDesign = loginPopupDesign();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxState(event.target.checked);
  };

  const closeModal = () => {
    dispatch(modalActions.toggleLoginMethod());
  };
  const openLoginModal = () => {
    dispatch(modalActions.toggleLogin({ isSignup: false }));
  };
  const openSignupModal = () => {
    dispatch(modalActions.toggleLogin({ isSignup: true }));
  };
  const clickMenuItem = (link: string) => {
    dispatch(push(link));
    dispatch(modalActions.toggleLoginMethod());
  };

  return (
    <FullScreenDialog
      open={isOpen}
      onBackdropClick={closeModal}
      maxWidth={"md"}
    >
      <div className="login-signup" css={classesLoginPopupDesign.root}>
        <h2>{t("login.title")}</h2>
        {/**
         * TODO@implementation 利用規約へのリンクを追加する
         */}
        <p css={classesLoginPopupDesign.membershipAgreementLink}>
          <I18nTrans i18nKey="login.notes">
            ログイン/新規登録をすると
            <span
              onClick={() => {
                clickMenuItem("/terms-of-service");
              }}
            >
              会員規約
            </span>
            に同意したものと見なされます
          </I18nTrans>
        </p>

        <div css={classesLoginPopupDesign.AgreementCheckbox}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxState}
                onChange={handleChange}
                name="checkedB"
                color="primary"
              />
            }
            label={t("login.agree")}
          />
        </div>

        <div css={classesLoginPopupDesign.buttonList}>
          {checkboxState ? (
            <React.Fragment>
              <button
                css={classesLoginPopupDesign.loginButton}
                onClick={openLoginModal}
              >
                {t("login.buttons.login")}
              </button>
              {/**
               * TODO@implementation 新規登録へのリンクを追加する
               */}
              <button
                css={classesLoginPopupDesign.loginButton}
                onClick={openSignupModal}
              >
                {t("login.buttons.registration")}
              </button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <button
                className="disable"
                css={classesLoginPopupDesign.loginButton}
              >
                {t("login.buttons.login")}
              </button>
              <button
                className="disable"
                css={classesLoginPopupDesign.loginButton}
              >
                {t("login.buttons.registration")}
              </button>
            </React.Fragment>
          )}
          <button
            css={classesLoginPopupDesign.canselButton}
            onClick={closeModal}
          >
            {t("login.buttons.cancel")}
          </button>
        </div>
      </div>
    </FullScreenDialog>
  );
};
export default ModalLoginMethod;

const loginPopupDesign = () => {
  return {
    root: css`
      width: 90%;
      padding: 50px 5% 50px;
      margin: 0 auto;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        max-width: 980px;
        width: 100%;
        padding: 50px 0 100px;
      }
      h2 {
        margin-bottom: 30px;
        font-size: 20px;
        text-align: center;
        @media screen and (min-width: 768px) {
          margin-bottom: 40px;
          font-size: 25px;
        }
      }
    `,
    membershipAgreementLink: css`
      margin-bottom: 20px;
      font-size: 14px;
      text-align: left;
      font-weight: bold;
      line-height: 1.5em;
      @media screen and (min-width: 768px) {
        font-size: 16px;
        text-align: center;
      }
      span {
        color: #11d4db;
        text-decoration: underline;
        cursor: pointer;
      }
    `,
    AgreementCheckbox: css`
      margin-bottom: 20px;
      text-align: center;
    `,
    buttonList: css`
      width: 100%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 500px;
      }
      button {
        display: block;
        width: 100%;
        padding: 12px 0;
        margin: 0 auto;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        line-height: 1.2em;
        letter-spacing: 1px;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    `,
    loginButton: css`
      color: #fff;
      background-color: #00c2ae;
      border: 2px solid #00c2ae;
      cursor: pointer;
      &.disable {
        background-color: #b3b3b3;
        border: 2px solid #b3b3b3;
        cursor: not-allowed;
      }
    `,
    canselButton: css`
      width: 100%;
      color: #00c2ae;
      background-color: #fff;
      border: 2px solid #00c2ae;
      cursor: pointer;
    `,
  };
};
