/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Link } from "react-router-dom";

import { changeStyleWithHosting } from "utility/hosting";

import { LanguageDropdownMenu } from "components/common/LanguageDropdownMenu";
import { BellNotification } from "containers/account/notification/HeaderNotification";

import DehazeIcon from "@material-ui/icons/Dehaze";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

interface Props {
  uid: string;
  // currentLang: string;
  isEmptyCart: boolean;
  isDisplayLogo: boolean;
  domainLogo: JSX.Element;
  HeaderMenuItems: { name: string; link: string; display: boolean }[];
  // handleChangeLang: (
  //   event: React.ChangeEvent<{ name?: string; value: string }>
  // ) => void;
  toggleSideMenu: () => void;
  toggleMyCart: () => void;
  toggleLoginMethod: () => void;
}
export const HeaderPcDisplay: React.FC<Props> = (props) => {
  const classesHeaderForPc = headerDesignForPc();

  return (
    <header css={classesHeaderForPc.header}>
      <div css={classesHeaderForPc.container}>
        <div css={classesHeaderForPc.flexLeftSideLayout}>
          <DehazeIcon
            htmlColor="#fff"
            fontSize="large"
            onClick={props.toggleSideMenu}
          />
          {props.isDisplayLogo && (
            <h1 className="spwnLogo">
              {/*
               // @ts-expect-error TS2532 */}
              <Link to={props.HeaderMenuItems[0].link}>{props.domainLogo}</Link>
            </h1>
          )}
        </div>

        <div css={classesHeaderForPc.flexRightSideLayout}>
          <ul>
            {props.HeaderMenuItems.filter((el) => el.display === true).map(
              (el, i) => {
                return (
                  <li key={i}>
                    <Link to={el.link}>{el.name}</Link>
                  </li>
                );
              }
            )}
            {
              <li>
                <LanguageDropdownMenu />
              </li>
            }
          </ul>

          {props.uid ? (
            <React.Fragment>
              <BellNotification />
              <div>
                <button
                  id="cart"
                  css={resetButtonStyles}
                  className={props.isEmptyCart ? "" : "putBadge"}
                  onClick={props.toggleMyCart}
                >
                  <ShoppingCartIcon aria-label="Cart" />
                  <span></span>
                </button>
              </div>
            </React.Fragment>
          ) : (
            <button
              id="login"
              css={resetButtonStyles}
              className={props.isEmptyCart ? "" : "putBadge"}
              onClick={props.toggleLoginMethod}
            >
              <ExitToAppIcon aria-label="Login" />
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

const headerDesignForPc = () => {
  const { textColor } = changeStyleWithHosting().commonSettings.header;
  const { svgColor } = changeStyleWithHosting().commonSettings.header;
  const { accentColor } = changeStyleWithHosting().baseStyles;

  return {
    header: css`
      position: relative;
      display: block;
      width: 100%;
      max-width: 1800px;
      margin: 0 auto;
      z-index: 900;
    `,
    container: css`
      display: flex;
      justify-content: space-between;
      width: 95%;
      padding: 15px 0px;
      margin: 0 auto;
    `,
    flexLeftSideLayout: css`
      display: flex;
      align-items: center;
      svg {
        display: block;
        margin-right: 30px;
        fill: ${textColor};
        cursor: pointer;
      }
      h1 {
        width: auto;
        height: 25px;
        img {
          height: 100%;
        }
      }
    `,
    flexRightSideLayout: css`
      display: flex;
      align-items: center;
      ul {
        display: grid;
        grid-template-columns: max-content max-content max-content max-content max-content;
        gap: 24px;
        margin-right: 24px;
        li {
          display: inline-flex;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          a {
            color: ${textColor};
            &:hover {
              color: ${accentColor};
            }
          }
        }
      }
      #badge {
        margin-right: 10px;
      }
      #cart {
        svg {
          fill: ${textColor};
          cursor: pointer;
        }
        &.putBadge {
          position: relative;
          span {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 10px;
            height: 10px;
            background-color: #f00;
            border-radius: 50%;
            border: 1px solid #fff;
          }
        }
      }
      #bell svg {
        fill: ${textColor};
        cursor: pointer;
      }
      #login svg {
        fill: ${svgColor};
        cursor: pointer;
      }
    `,
  };
};

const resetButtonStyles = css`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;
