/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import QRCode from "qrcode.react";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useI18n } from "hooks/i18n/i18n";
import { IssuedTicket } from "@spwn/types/firebase/firestore";
import { makeEncodedIssuedTicketQRCodeValue } from "utility/ticket";
import { ActionModalType } from "modules/modal";
import { AccountTicketDigitalQrCodeSharingArea } from "./AccountTicketDigitalQrCodeSharingArea";
import { useAccountTicketDigitalQrCode } from "./useAccountTicketDigitalQrCode";

export const AccountTicketDigitalQrCode: React.FC<{
  userId: string;
  eventId: string | null;
  relatedTicket: IssuedTicket;
  openActionModal: (
    modalType: ActionModalType,
    ticketNum: string,
    issuedTicket: IssuedTicket | null
  ) => void;
  ticket: {
    isVodTicket: boolean;
    isChecked: boolean;
    isTicketSharable: boolean;
  };
  benefits: {
    productClass: number | undefined;
    isRedeemed: boolean;
  };
}> = ({
  userId,
  eventId,
  relatedTicket,
  openActionModal,
  ticket,
  benefits,
}) => {
  const { t } = useI18n();
  const { shareStatus, ticketShareMessage, isShared, isBenefitGoods } =
    useAccountTicketDigitalQrCode({
      userId,
      relatedTicket,
      benefits,
    });

  const [selectedTabNumber, setSelectedTabNumber] = React.useState<number>(0);

  const handleChangeIndex = (index: number) => {
    setSelectedTabNumber(index);
  };

  if (!eventId) {
    throw new Error(`eventId is required`);
  }
  if (!relatedTicket) {
    throw new Error(`eventId: ${eventId}, relatedTicket is required`);
  }

  /**
   * @description チケット番号と認証コードを取得する
   * @todo 本来は型を使って取得するべきだが、受け取る関数との型が合わないためanyにキャストしている
   */
  const { ticketNum, authCode } = relatedTicket as {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    ticketNum: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    authCode: any;
  };

  /**
   * @description チケット使用・特典引き換え用のQRコードを作成する
   */
  const createQRCode = (type: "ticket" | "benefits") => {
    /**
     * @description
     * isChecked
     *  - チケットのチェックインしたかどうか
     * isRedeemed
     *  - 特典の引換をしたかどうか
     */
    const ticketType = type === "ticket" ? "isChecked" : "isRedeemed";

    return makeEncodedIssuedTicketQRCodeValue(
      eventId,
      ticketNum,
      authCode || null,
      ticketType
    );
  };

  const handleOnClickEventCheckInTicket = () => {
    openActionModal("eventCheckIn", ticketNum, relatedTicket);
  };

  const handleOnClickRewardCheckInTicket = () => {
    openActionModal("rewardCheckIn", ticketNum, relatedTicket);
  };

  const handleOnClickShareTicket = () => {
    if (shareStatus === "beforeSharing" || shareStatus === "createShareUrl") {
      openActionModal("shareTicket", ticketNum, relatedTicket);
    }
    return;
  };

  // args引き換え処理に渡すargs
  // const rewardCheckInArgs: ReqCollectTicket = {
  //   // @ts-expect-error TS2322
  //   eid: eventId,
  //   ticketNumber: ticketNum,
  //   authCode: issuedTicket.authCode,
  // };

  // 特典引き換え処理
  // dispatch(ticketActions.redeemTicket.started(rewardCheckInArgs))

  // チケット引換処理
  // dispatch(ticketActions.collectTicket.started(rewardCheckInArgs));

  const tabSelectedColor = "white";
  const tabNotSelectedAndNotRedeemedColor = "#00C2AD";
  const tabNotSelectedAndRedeemedColor = "#A1A1A1";

  const tabNotSelectedFontColor = "black";
  const tabSelectedAndNotRedeemedFontColor = "black";
  const tabSelectedAndRedeemedFontColor = "#A1A1A1";

  const changeTabColor = ({
    isSelected,
    isRedeemed,
  }: {
    isSelected: boolean;
    isRedeemed: boolean;
  }) => {
    if (isSelected) return tabSelectedColor;
    if (isRedeemed) return tabNotSelectedAndRedeemedColor;
    return tabNotSelectedAndNotRedeemedColor;
  };

  const changeTabFontColor = ({
    isSelected,
    isRedeemed,
  }: {
    isSelected: boolean;
    isRedeemed: boolean;
  }) => {
    if (!isSelected) return tabNotSelectedFontColor;
    if (isRedeemed) return tabSelectedAndRedeemedFontColor;
    return tabSelectedAndNotRedeemedFontColor;
  };

  return (
    <Box maxWidth={"500px"} margin={"0 auto"}>
      {/* チケットと特典のQRを切り替えるタブ */}
      {isBenefitGoods && (
        <Box
          component={"ul"}
          display={"grid"}
          gridTemplateColumns={"1fr 1fr"}
          gridGap={"8px"}
        >
          <Box
            component={"li"}
            padding={"14px"}
            color={changeTabFontColor({
              isSelected: selectedTabNumber === 0,
              isRedeemed: ticket.isChecked,
            })}
            fontWeight={"bold"}
            textAlign={"center"}
            bgcolor={changeTabColor({
              isSelected: selectedTabNumber === 0,
              isRedeemed: ticket.isChecked,
            })}
            style={{ cursor: "pointer" }}
            onClick={() => handleChangeIndex(0)}
          >
            チケット
          </Box>
          <Box
            component={"li"}
            padding={"14px"}
            color={changeTabFontColor({
              isSelected: selectedTabNumber === 1,
              isRedeemed: benefits.isRedeemed,
            })}
            fontWeight={"bold"}
            textAlign={"center"}
            bgcolor={changeTabColor({
              isSelected: selectedTabNumber === 1,
              isRedeemed: benefits.isRedeemed,
            })}
            style={{ cursor: "pointer" }}
            onClick={() => handleChangeIndex(1)}
          >
            特典
          </Box>
        </Box>
      )}

      {/* 選択したタブの項目に表示を切り替える */}
      {selectedTabNumber === 0 && (
        <Box>
          {!ticket.isVodTicket && (
            // チケットの種類がEvent Ticketの場合、QRコードを表示する
            <React.Fragment>
              <Box
                component={"div"}
                padding={"28px"}
                marginBottom={"20px"}
                textAlign={"center"}
                bgcolor={"white"}
              >
                <Box display={"inline-block"}>
                  <QRCode
                    value={createQRCode("ticket")}
                    size={224}
                    style={{
                      display: "block",
                      border: "12px solid #c9c9c9",
                    }}
                  />
                </Box>
              </Box>

              <AccountTicketDigitalButton
                isDisabled={ticket.isChecked}
                label={
                  ticket.isChecked
                    ? t("ticketDetail.checkedIn")
                    : t("ticketDetail.checkInOnlyStaff")
                }
                onClick={handleOnClickEventCheckInTicket}
              />

              {/* チケットの共有機能が有効の時に表示する */}
              {ticket.isTicketSharable && (
                <AccountTicketDigitalQrCodeSharingArea
                  isShared={isShared}
                  ticketShareMessage={ticketShareMessage}
                  ticketShareButtonLabel={
                    shareStatus === "beforeSharing"
                      ? "このチケットを共有"
                      : shareStatus === "createShareUrl"
                      ? "共有URLを表示"
                      : "共有済み"
                  }
                  onClickTicketShareButton={handleOnClickShareTicket}
                />
              )}
            </React.Fragment>
          )}
        </Box>
      )}

      {selectedTabNumber === 1 && isBenefitGoods && (
        <Box>
          <Box
            component={"div"}
            padding={"28px"}
            marginBottom={"20px"}
            textAlign={"center"}
            bgcolor={"white"}
          >
            <Box display={"inline-block"}>
              <QRCode
                value={createQRCode("benefits")}
                size={224}
                style={{
                  display: "block",
                  border: "12px solid #c9c9c9",
                }}
              />
            </Box>
          </Box>

          <AccountTicketDigitalButton
            isDisabled={benefits.isRedeemed}
            label={
              benefits.isRedeemed
                ? t("ticketDetail.benefitsRedeemed")
                : t("ticketDetail.benefitsRedeemOnlyStaff")
            }
            onClick={handleOnClickRewardCheckInTicket}
          />
        </Box>
      )}
    </Box>
  );
};

const AccountTicketDigitalButton: React.FC<{
  isDisabled: boolean;
  label: string;
  onClick: () => void;
}> = ({ isDisabled, label, onClick }) => {
  return (
    <ButtonBase
      style={{
        width: "100%",
        padding: "12px",
        fontWeight: "bold",
        letterSpacing: "1px",
        border: isDisabled ? "1px solid #ADAEAE" : "1px solid white",
        backgroundColor: isDisabled ? "#ADAEAE" : "inherit",
      }}
      disabled={isDisabled}
      onClick={onClick}
    >
      {label}
    </ButtonBase>
  );
};
