/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useI18n } from "../hooks/i18n/i18n";
import { Breadcrumb } from "../components/atoms/Breadcrumb";
import { ShopItem } from "../features/ShopItem";
import type { Shop as ShopType, ShopGoods } from "./shopModel";

type Props = {
  tenantId: string;
  brandId: string;
  shopId: string;
  shopInfo?: ShopType;
};

const salesStatusLabel: Record<
  ShopGoods["salesStatus"],
  { color: string; label: string }
> = {
  beforeSale: {
    color: "#ff9d00",
    label: "販売前",
  },
  onSale: {
    color: "#ff9d00",
    label: "販売中",
  },
  afterSale: {
    color: "#ff9d00",
    label: "販売終了",
  },
  soldOut: {
    color: "#da3d50",
    label: "SOLD OUT",
  },
};

export const Shop: React.FC<Props> = (props) => {
  const { shopInfo } = props;
  const { t } = useI18n();
  const classes = styles();
  const dispatch = useDispatch();

  // @ts-expect-error TS2322
  const moveDetailPage = (goodsId: string = null) => {
    dispatch(push("/shop/goods/" + encodeURIComponent(goodsId)));
  };

  return (
    <section css={classes.root}>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, "ショップ"],
        ]}
      />

      <div css={classes.thumbnail}>
        <div className="layout">
          {/*
           // @ts-expect-error TS18048 */}
          <img src={shopInfo.thumbnail} alt={shopInfo.name} />
        </div>
      </div>

      <section css={classes.main}>
        <div css={classes.heading}>
          <h2>GOODS LIST</h2>
          <p>グッズ一覧</p>
        </div>

        {/* グッズ一覧を表示する */}
        {/*
         // @ts-expect-error TS18048 */}
        {shopInfo.goodsList && shopInfo.goodsList.length > 0 ? (
          <div css={classes.goodsList}>
            {/*
             // @ts-expect-error TS18048 */}
            {shopInfo.goodsList.map((item) => {
              const { id, salesStatus, hasVariant } = item;

              return (
                <ShopItem
                  key={item.id}
                  item={item}
                  hasVariation={hasVariant}
                  moveDetailPage={() => moveDetailPage(id)}
                  displaySalesState={<DisplaySalesState {...{ salesStatus }} />}
                />
              );
            })}
          </div>
        ) : (
          <div css={classes.notApplicable}>
            <p>{t("goods.search.noResult")}</p>
          </div>
        )}
      </section>
    </section>
  );
};

type DisplaySalesStateProps = {
  salesStatus: ShopGoods["salesStatus"];
};
export const DisplaySalesState: React.FC<DisplaySalesStateProps> = ({
  salesStatus,
}) => {
  const classes = styles();

  return (
    <div
      css={
        classes.goodsItemState(salesStatusLabel[salesStatus].color)
          .goodsItemState
      }
    >
      <p>{salesStatusLabel[salesStatus].label}</p>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      background-color: #fff;
    `,
    thumbnail: css`
      background-color: #000;
      .layout {
        max-width: 980px;
        width: 100%;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
    `,
    main: css`
      max-width: 880px;
      width: 100%;
      padding: 40px 5%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        padding: 40px 0;
      }
    `,
    heading: css`
      margin-bottom: 24px;
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }
      h2 {
        font-family: "din-condensed", sans-serif;
        font-weight: 400;
        font-style: normal;
        padding: 5px 0 0 15px;
        margin-bottom: 5px;
        font-size: 35px;
        border-left: 5px solid #00c2ae;
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
          letter-spacing: 3px;
        }
      }
      p {
        padding-left: 21px;
        font-size: 12px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    `,
    goodsList: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media screen and (min-width: 768px) {
        width: 100%;
      }
      &::after {
        display: block;
        content: "";
        width: 48%;
        @media screen and (min-width: 768px) {
          width: 32%;
        }
      }
    `,
    // @ts-expect-error TS7006
    goodsItemState: (stateColor) => {
      return {
        goodsItemState: css`
          min-height: 24px;
          margin-bottom: 10px;
          p {
            display: inline-block;
            padding: 4px 8px;
            color: #fff;
            font-size: 12px;
            background-color: ${stateColor};
            @media screen and (min-width: 768px) {
              font-size: 10px;
            }
          }
        `,
      };
    },
    notApplicable: css`
      padding: 80px 0;
      p {
        font-size: 14px;
        text-align: center;
      }
    `,
  };
};
