/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useI18n } from "hooks/i18n/i18n";
import { PortalTopSectionLayout } from "./PortalTop";
import { PortalTopFanclubProps } from "./PortalTopFanclub";
import { FC } from "react";

export const PortalTopFanclubSliderSP: FC<PortalTopFanclubProps> = ({
  brandList,
}) => {
  const { t } = useI18n();

  return (
    <PortalTopSectionLayout
      heading={{
        mainText: "FANCLUB",
        subText: t("event.top.crew"),
      }}
    >
      <div
        css={css`
          display: flex;
          margin: auto;
          overflow-x: auto;
          scrollbar-width: none;
          -ms-overflow-style: none;
          &::-webkit-scrollbar {
            display: none;
          }
        `}
      >
        {brandList.map((brand) => {
          return (
            <div
              key={brand.brandSlug}
              css={css`
                width: 24.5%;
                /* padding: 0 12px; */
                flex-shrink: 0;
                &:not(:last-of-type) {
                  margin-right: 24px;
                }
                img {
                  display: block;
                  width: 100%;
                  max-width: 120px;
                  margin: 0 auto 8px;
                  border-radius: 50%;
                  aspect-ratio: 1 / 1;
                  object-fit: cover;
                }
                h3 {
                  color: #fff;
                  text-align: center;
                  line-height: 1.5em;
                }
              `}
            >
              <a
                href={`https://crew.spwn.jp/${brand.brandSlug}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={brand.thumbnail} alt={brand.brandName} />
                <h3>{brand.brandName}</h3>
              </a>
            </div>
          );
        })}
      </div>
    </PortalTopSectionLayout>
  );
};
