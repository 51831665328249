/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";
import { useI18n } from "hooks/i18n/i18n";
import { authActions } from "modules/auth";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSlashSeparatedDateTimeString } from "utility";
import { Store } from "../store";
import { ButtonList, LinkButton } from "../styles";
import { push } from "connected-react-router";
import firebase from "firebase/app";

/**
 * EmailAuthenticationの責務
 * メールアドレス認証を行っていないユーザーに表示するコンポーネント
 */
export const EmailAuthenticationLogic: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: Store) => state.auth.user);
  const sentTime = useSelector(
    (state: Store) => state.auth.verificationEmailSentTime
  );

  const [isSend, setIsSend] = useState<boolean>(false);

  const handleReSendEmail = useCallback(() => {
    setIsSend(true);
    dispatch(authActions.clearStateByKey("verificationEmailSentTime"));
    dispatch(authActions.sendVerificationEmail.started({}));
  }, [dispatch]);

  const handleNavigate = useCallback(
    (path: string) => () => {
      dispatch(push(path));
    },
    [dispatch]
  );

  const handleNavigateToSignup = useCallback(() => {
    dispatch(authActions.logout.started());
    handleNavigate("/signup");
  }, [dispatch, handleNavigate]);

  useEffect(() => {
    if (
      user.email &&
      (firebase.auth().currentUser === null ||
        // @ts-expect-error TS2531
        firebase.auth().currentUser.emailVerified)
    ) {
      handleNavigate("/")();
      return;
    }
  }, [user.email, handleNavigate]);

  return (
    <EmailAuthentication
      // @ts-expect-error TS2322
      email={user.email}
      emailSendStatus={
        !isSend && !sentTime
          ? "unsent"
          : isSend && !sentTime
          ? "sending"
          : "complete"
      }
      sentTime={sentTime}
      onClickReSendEmail={handleReSendEmail}
      handleNavigateToSignup={handleNavigateToSignup}
    />
  );
};

type EmailAuthenticationProps = {
  email: string;
  emailSendStatus: "unsent" | "sending" | "complete";
  sentTime: Date | null;
  onClickReSendEmail: () => void;
  handleNavigateToSignup: () => void;
};
export const EmailAuthentication: FC<EmailAuthenticationProps> = (props) => {
  const { t } = useI18n();
  const classesSendMailLoader = sendMailLoaderStyles();
  const classesVerificationEmailStyles = verificationEmailStyles();

  return (
    <div css={classesVerificationEmailStyles.root}>
      <div css={classesVerificationEmailStyles.layout}>
        <h2>{t("email.header")}</h2>

        <div css={classesVerificationEmailStyles.thxText}>
          <p>{t("email.para1")}</p>
          <p>{t("email.para2")}</p>
        </div>

        <div css={classesVerificationEmailStyles.userInfo}>
          <h3>{props.email}</h3>
          {/* {this.switchSendDatetime(classesSendMailLoader)} */}

          {/* 認証メール送信中の表示 */}
          {props.emailSendStatus === "sending" && (
            <div css={classesSendMailLoader.root}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}

          {/* 認証メール送信完了の表示 */}
          {props.emailSendStatus === "complete" && (
            <p>
              {t("email.sendTime")}：
              {/*
               // @ts-expect-error TS2345 */}
              {getSlashSeparatedDateTimeString(props.sentTime)}
            </p>
          )}
        </div>

        <div css={classesVerificationEmailStyles.caution}>
          <p>{t("email.annotation1")}</p>
          <p>{t("email.annotation2")}</p>
          <p>{t("email.annotation3")}</p>
          <p>{t("email.annotation4")}</p>
          <p>{t("email.annotation5")}</p>
        </div>

        <ButtonList>
          <LinkButton onClick={props.onClickReSendEmail}>
            {t("email.button1")}
          </LinkButton>

          <LinkButton className="cancel" onClick={props.handleNavigateToSignup}>
            {t("email.button3")}
          </LinkButton>
        </ButtonList>
      </div>
    </div>
  );
};

/**
 * EmailAuthenticationのStyleを設定する。
 */
const sendMailLoaderStyles = () => {
  return {
    root: css`
      margin-top: 16px;
      text-align: center;
      div {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin: 2px;
        background-color: #2d2d2d;
        border-radius: 100%;
        animation-fill-mode: both;
      }
      & > div:nth-child(1) {
        animation: ${sendAnimation} 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
        animation: ${sendAnimation} 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
      }
      & > div:nth-child(2) {
        animation: ${sendAnimation} 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
        animation: ${sendAnimation} 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
      }
      & > div:nth-child(3) {
        animation: ${sendAnimation} 0.75s 0s infinite
          cubic-bezier(0.2, 0.68, 0.18, 1.08);
        animation: ${sendAnimation} 0.75s 0s infinite
          cubic-bezier(0.2, 0.68, 0.18, 1.08);
      }
    `,
  };
};

const sendAnimation = keyframes`
  30% {
    -webkit-transform: scale(.3);
    transform: scale(.3);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

const verificationEmailStyles = () => {
  const root = css`
    width: 90%;
    margin: 60px auto 0;
    padding: 30px 5% 50px;
    background-color: #fff;
    @media screen and (min-width: 768px) {
      width: 100%;
      padding: 50px 2.5% 50px;
    }
    h2 {
      margin-bottom: 30px;
      font-size: 20px;
      text-align: center;
      line-height: 1.3em;
      @media screen and (min-width: 768px) {
        padding-bottom: 40px;
        margin-bottom: 32px;
        font-size: 25px;
        border-bottom: 1px solid #000;
      }
    }
  `;
  const layout = css`
    width: 100%;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 700px;
    }
  `;
  const thxText = css`
    margin-bottom: 40px;
    p {
      text-align: center;
      line-height: 1.5em;
    }
  `;
  const userInfo = css`
    margin-bottom: 40px;
    h3 {
      text-align: center;
      @media screen and (min-width: 768px) {
        font-size: 25px;
      }
    }
    p {
      margin-top: 16px;
      text-align: center;
    }
  `;
  const caution = css`
    margin-bottom: 24px;
    p {
      font-size: 80%;
      text-align: left;
      line-height: 1.5em;
      opacity: 0.5;
    }
  `;
  return {
    root,
    layout,
    thxText,
    userInfo,
    caution,
  };
};
