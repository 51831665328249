import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  EventTicketBonusMedia,
  useEventTicketsBonusMediaLazy,
} from "../../hooks/event/useEventTicketsBonusMediaLazy";

export const useLazyFetchTicketBonus = () => {
  const [observer, setObserver] = useState<IntersectionObserver>();
  useEffect(() => {
    const _observer = new IntersectionObserver((entries) => {
      entries
        .filter((entry) => entry.isIntersecting)
        .forEach((entry) => {
          const elm = entry.target as HTMLDivElement;
          const { eventId, ticketIds: joinedTicketIds, isCalled } = elm.dataset;
          if (!eventId || !joinedTicketIds || isCalled === "true") {
            return;
          }
          if (datum[eventId]?.[joinedTicketIds] != null) {
            // 既に取得済み
            return;
          }

          elm.dispatchEvent(new CustomEvent("fetcher"));
        });
    });
    setObserver(_observer);
    return () => _observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  type DatumDict = {
    [eventId: string]: { [ticketIds: string]: EventTicketBonusMedia[] };
  };
  const [datum, setDatum] = useState<DatumDict>({});

  const IntersectionDetector = ({
    eventId,
    ticketIds,
  }: {
    eventId: string;
    ticketIds: string[];
  }) => {
    const swr = useEventTicketsBonusMediaLazy(eventId, ticketIds);
    const joinedTicketIds = ticketIds.join();

    const fetcher = useCallback(() => {
      const isCalled = ref.current?.dataset.isCalled === "true";
      const isFetched = datum[eventId]?.[joinedTicketIds] != null;
      if (isCalled || isFetched || swr.isMutating) return;
      if (ref.current) ref.current.dataset.isCalled = "true";
      swr.trigger().then((data) => {
        setDatum((prevDataMap) => ({
          ...prevDataMap,
          [eventId]: { [joinedTicketIds]: data },
        }));
      });
    }, [eventId, joinedTicketIds, swr]);

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const currentRef = ref.current;
      if (currentRef) {
        observer?.observe(currentRef);
        currentRef.addEventListener("fetcher", fetcher);
      }
      return () => {
        if (currentRef) {
          observer?.unobserve(currentRef);
          currentRef.removeEventListener("fetcher", fetcher);
        }
      };
    }, [fetcher]);

    return (
      <div
        ref={ref}
        className="intersection-detector"
        data-event-id={eventId}
        data-ticket-ids={joinedTicketIds}
        data-is-called="false"
        style={{
          position: "absolute",
          visibility: "hidden",
        }}
      />
    );
  };

  return { datum, IntersectionDetector };
};
