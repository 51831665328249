import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import "firebase/firestore";

const actionCreator = actionCreatorFactory("firestore");

export const firestoreActions = {
  addChannel: actionCreator<ReqAddChannel>("addChannel"),
  closeAllChannel: actionCreator<void>("closeAllChannel"),
  closeChannel: actionCreator<{ channel: AllChannel }>("closeChannel"),
  closeUserChannel: actionCreator<void>("closeUserChannel"),
};

export interface firestoreState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  channels: any;
}

const initialState: firestoreState = {
  channels: [],
};

export interface ReqAddChannel {
  channels: FirestoreChannel[];
  name: WatchChannel | UserWatchChannel | string;
}
interface FirestoreChannel {
  name: WatchChannel | UserWatchChannel | string;
  close: () => void;
}
// all user watch (liveのcontextIdは網羅してない)
type WatchChannel =
  | "events"
  | "openEvents"
  | "places"
  | "liveContext"
  | "topInfo"
  | "notifications"
  | "superChat"
  | "streamingComments"
  | "oneEvent"
  | "streamingBANComments"
  | "eventVideos";
// only login user watch (issuedTicketとか網羅してない ${eventId}_SharedTickets ${eventId}_IssuedTickets )
type UserWatchChannel = "myCart" | "flowerStandPurchaseState" | "pawBalance";
// not including ( ${eventId}_SharedTickets, ${eventId}_IssuedTickets, ...)
export type AllChannel = WatchChannel | UserWatchChannel;

const firestoreReducer = reducerWithInitialState(initialState)
  .case(firestoreActions.addChannel, (state, payload) => {
    const { channels } = state;
    const isNewChannel = channels.every(
      // @ts-expect-error TS7006
      (channel) => channel.name !== payload.name
    );
    return {
      ...state,
      channels: isNewChannel ? [...state.channels, payload] : state.channels,
    };
  })
  .case(firestoreActions.closeAllChannel, (state) => {
    // @ts-expect-error TS7006
    state.channels.forEach((channel) => {
      channel.close();
    });
    return { ...state, channels: [] };
  })
  .case(firestoreActions.closeChannel, (state, payload) => {
    const _channel = payload.channel;
    if (!_channel) {
      return { ...state };
    }
    const { channels } = state;
    let closeIndex = -1;
    for (let i = 0; i < channels.length; i++) {
      const channel = channels[i];
      if (channel.name === _channel) {
        channel.close();
        closeIndex = i;
        break;
      }
    }
    if (closeIndex < 0) {
      return { ...state };
    }
    // closeするchannelを除外
    channels.splice(closeIndex, 1);
    return { ...state, channels };
  })
  .case(firestoreActions.closeUserChannel, (state) => {
    // close channel related user
    const commonChannels: WatchChannel[] = [
      "events",
      "openEvents",
      "places",
      "liveContext",
    ];
    const { channels } = state;
    const newChannels = [];
    for (let i = 0; i < channels.length; i++) {
      const channel = channels[i];
      if (commonChannels.indexOf(channel.name) >= 0) {
        newChannels.push(channel);
      } else {
        channel.close();
      }
    }
    return { ...state, channels: newChannels };
  });

export default firestoreReducer;

export function* firestoreSaga() {}

//listen済みチャンネルかどうかを返す
// @ts-expect-error TS7006
export const isRegisteredChannel = (channels, name) => {
  // @ts-expect-error TS7006
  return channels.some((channel) => channel.name === name);
};
