/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import "firebase/auth";
import "firebase/database";
import React from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles, { StyleRules } from "@material-ui/core/styles/withStyles";
import { useI18n } from "hooks/i18n/i18n";
import { Button } from "components/atoms/button/Button";

const styles: StyleRules = createStyles({
  buttonRoot: {
    width: "80vw",
    margin: "10px",
    color: "#fff",
    backgroundColor: "#000",
  },
});

interface Props {
  isSignup: boolean;
  onClick: () => void;
}
const DocomoLoginButton = (props: Props) => {
  const { isSignup } = props;
  const { t } = useI18n();
  const actionText = isSignup
    ? t("login.buttons.registration")
    : t("login.buttons.login");

  return (
    <Button
      text={`${t("login.loginMethod.docomo")}${actionText}`}
      color={"#eb7129"}
      onClick={props.onClick}
    />
  );
};

export default withStyles(styles)(DocomoLoginButton);
