import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import firebase from "firebase/app";
import { StreamingType } from "modules/streaming";
import { Store } from "../../store";

export const useConcurrentPlay = (
  currentVid: string,
  streamingType: StreamingType,
  isConcurrentPlayEnabled: boolean | undefined
) => {
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);
  const [concurrentPlay, setConcurrentPlay] = useState<number>(0);

  const useWatchCurrentPlay = () => {
    let unsubscribe: (() => void) | null = null;
    if (
      (currentVid && isConcurrentPlayEnabled && streamingType === "Live") ||
      isAdmin
    ) {
      unsubscribe = firebase
        .firestore()
        .doc(`/Youbora/concurrentPlay/videoIds/${currentVid}`)
        .onSnapshot((doc) => {
          const data = doc.data();
          setConcurrentPlay(data?.value || 0);
        });
    }
    return () => {
      if (unsubscribe !== null) {
        unsubscribe();
      }
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(useWatchCurrentPlay, [currentVid]);

  return concurrentPlay;
};
