import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../../store";
import { Link } from "react-router-dom";
import { BreadcrumbArea } from "components/common/Link";
import styled from "@emotion/styled";
import { ContentsLayout, subPageMixin } from "../../styles";

const _Container = styled.div`
  .text_block {
    p {
      padding-left: 0;
      text-indent: inherit;
    }
    ul {
      li {
        padding-left: 1em;
        text-indent: -1em;
      }
    }
  }
`;

const Container = styled(_Container)({}, subPageMixin);

interface Props {}

class PaymentServicesAct extends Component<Props> {
  render() {
    return (
      <Container id="contents">
        <BreadcrumbArea
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            [null, "資金決済法に基づく情報提供"],
          ]}
        />
        <ContentsLayout className="content">
          <h2>資金決済法に基づく情報提供</h2>

          <div className="text_block">
            <h3>前払式支払手段の名称</h3>
            <p>PAW</p>
          </div>

          <div className="text_block">
            <h3>前払式支払手段の発行者</h3>
            <p>GMOペイメントゲートウェイ株式会社</p>
            <p>東京都渋谷区道玄坂一丁目2番3号</p>
          </div>

          <div className="text_block">
            <h3>支払い可能金額等</h3>
            <p>チャージ可能金額は300,000円が上限となります。</p>
            <ul>
              <li>・1回あたりの支払可能金額は50,000円が上限となります。</li>
              <li>・1日あたりの支払可能金額は300,000円が上限となります。</li>
              <li>・入金可能金額は500円～300,000円です。</li>
              <li>
                （500円/1,000円/3,000円/5,000円/10,000円/30,000円/50,000円単位で入金可能）
              </li>
              <li>・1日あたりの入金上限回数は30回です。</li>
            </ul>
          </div>

          <div className="text_block">
            <h3>有効期限</h3>
            <p>PAWの購入日から1年間となります。</p>
          </div>

          <div className="text_block">
            <h3>お問い合わせ先</h3>
            <p>東京都千代田区外神田5-2-2</p>
            <p>050-5360-2274 ※</p>
            <p>
              ※
              PAWに関するお問い合わせは、上記の問い合わせ先（委託先会社の電話番号）にお願いいたします。
              <br />
              なお、お客様から委託先会社に対し、取引内容等に関する詳しい内容の提示をご請求いただければ、委託先会社から電子メール等の電磁的方法により取引内容等の提示をいたします。
            </p>
          </div>

          <div className="text_block">
            <h3>利用場所の範囲</h3>
            <p>SPWN内において使用できます。</p>
          </div>

          <div className="text_block">
            <h3>利用上のご注意</h3>
            <ul>
              <li>・SPWN会員のみPAWの購入および利用ができます。</li>
              <li>・PAWは1PAW＝1円です。</li>
              <li>
                ・当社が特に認めた場合を除き、購入したPAWの払戻しはお受けできません。
              </li>
              <li>
                ・その他詳細のご注意事項につきましては、「PAW約款」「プリカサービスのプライバシーポリシー」をご確認ください。
              </li>
            </ul>
          </div>

          <div className="text_block">
            <h3>未使用残高のご確認方法</h3>
            <p>SPWN会員メニューよりご確認いただけます。</p>
          </div>

          <div className="text_block">
            <h3>利用規約</h3>
            <p>以下の規約をご覧ください。</p>
            <ul>
              <li>
                ・<Link to={"/paw-terms"}>PAW約款</Link>
              </li>
              <li>
                ・
                <Link to={"/plica-privacy-policy"}>
                  プリカのプライバシーポリシー
                </Link>
              </li>
            </ul>
          </div>
        </ContentsLayout>
      </Container>
    );
  }
}

const mapStateToProps = (_state: Store) => {
  return {};
};

const mapDispatchToProps = (_dispatch: Dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentServicesAct);
