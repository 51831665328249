/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { ComponentProps, useCallback } from "react";
import type { Goods } from "./goodsModel";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { AddToCartButton } from "components/event/goods/detail/AddToCartButton";
import { AddItemsToCartArgs } from "./useAddItemsToCart";
import {
  CartEntry,
  Decrement,
  Increment,
  useGoodsCartEntry,
} from "./useGoodsCartEntry";
import { useUserCart } from "./useUserCart";

export const TenantGoodsCartEntry: React.FC<{
  goods: Goods;
  addToCart: (request: AddItemsToCartArgs) => Promise<void>;
  addToCartButtonStyleProps: Omit<
    ComponentProps<typeof AddToCartButton>,
    "addToCart"
  >;
}> = ({ goods, addToCart, addToCartButtonStyleProps }) => {
  const { mutate } = useUserCart();

  const { cartEntries, mutator } = useGoodsCartEntry(goods);
  const { increment, decrement } = mutator;

  const addToCartHandler = useCallback(async () => {
    // いずれかが0より大きい数を指定していればカート追加できる
    const canRequest = cartEntries.some((entry) => entry.quantity > 0);
    if (!canRequest) return;
    await addToCart(cartEntriesToRequest(cartEntries));
    await mutate();
  }, [addToCart, cartEntries]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!cartEntries) {
    return null;
  }

  return (
    <TenantGoodsCartEntryView
      cartEntries={cartEntries}
      increment={increment}
      decrement={decrement}
      addToCart={addToCartHandler}
      addToCartButtonStyleProps={addToCartButtonStyleProps}
    />
  );
};
/**
 * テナントグッズのカート追加用ーコンポーネント
 * - グッズを受け取り、CartEntryを作成する。
 *   - CartEntryは、カートリクエスト用の値を保持する。
 * - カート追加ボタンを押すと、CartEntryからCartRequestを作成する
 *   - CartRequestは、カート追加APIのリクエストになる
 */
export const TenantGoodsCartEntryView: React.FC<{
  cartEntries: CartEntry[];
  increment: Increment;
  decrement: Decrement;
  addToCart: () => void;
  addToCartButtonStyleProps: Omit<
    ComponentProps<typeof AddToCartButton>,
    "addToCart"
  >;
}> = ({
  cartEntries,
  increment,
  decrement,
  addToCart,
  addToCartButtonStyleProps,
}) => {
  const classes = styles();

  return (
    <React.Fragment>
      <div css={classes.selectSize}>
        {cartEntries.map((item, index) => {
          return (
            <div css={classes.selectSizeForm} key={index}>
              <p css={classes.selectSizeLabel}>{item.name}</p>
              {item.status === "soldOut" ? (
                <p css={classes.selectSoldOutLabel}>SOLD OUT</p>
              ) : item.status === "beforeSale" ? (
                <p css={classes.selectOutsideSalesPeriod}>販売前</p>
              ) : item.status === "afterSale" ? (
                <p css={classes.selectOutsideSalesPeriod}>販売終了</p>
              ) : (
                <div css={classes.selectSizeButton}>
                  <div
                    onClick={decrement(item.inventoryProductId)}
                    data-testid={`remove.${index}`}
                  >
                    <RemoveIcon />
                  </div>
                  <input
                    id={item.inventoryProductId}
                    type="text"
                    value={item.quantity}
                    readOnly
                    data-testid={`value.${index}`}
                  />
                  <div
                    onClick={increment(item.inventoryProductId)}
                    data-testid={`add.${index}`}
                  >
                    <AddIcon />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <AddToCartButton
        {...addToCartButtonStyleProps}
        addToCart={addToCart}
        dataTestId={"submit"}
      />
    </React.Fragment>
  );
};

const cartEntriesToRequest = (cartEntries: CartEntry[]): AddItemsToCartArgs => {
  return {
    items: cartEntries
      .map((el) => ({
        quantity: el.quantity,
        tenantId: el.tenantId,
        baseProductId: el.baseProductId,
        inventoryProductId: el.inventoryProductId,
        inventoryProductType: el.inventoryProductType,
      }))
      // 個数が0より大きいもののみをリクエスト対象とする
      .filter((el) => el.quantity > 0),
  };
};

const styles = () => {
  return {
    selectSize: css`
      margin-bottom: 20px;
    `,
    selectSizeForm: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      border: 2px solid #e5e5e5;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    `,
    selectSizeLabel: css`
      width: calc(100% - 90px);
    `,
    selectSizeButton: css`
      display: flex;
      width: 75px;
      margin-left: 15px;
      border: 1px solid #e5e5e5;
      border-radius: 3px;
      input {
        width: calc(100% / 3);
        padding: 0 4px;
        font-size: 14px;
        text-align: center;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 3);
        padding: 5px 0;
        border-left: 1px solid #e5e5e5;
        background-color: #f4f4f4;
        cursor: pointer;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    `,
    selectSoldOutLabel: css`
      padding: 4px 8px;
      color: #fff;
      font-size: 12px;
      background-color: #da3d50;
    `,
    selectOutsideSalesPeriod: css`
      padding: 4px 8px;
      color: #fff;
      font-size: 12px;
      background-color: #7e7e7e;
    `,
  };
};
