import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { select, put, take, call, takeEvery } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import firebase from "firebase/app";
import "firebase/firestore";
import { firestoreActions, isRegisteredChannel } from "./firestore";
import {
  getDateDayTimeString,
  toDoubleDigits,
  getUrlWithUpdateVersion,
  replaceBr,
  getEventDisplayInfo,
  EventInfo,
} from "../utility";
import { selectImgPath } from "../utility/event";
import appConfig from "../constants/appConfig";
import {
  fetchDatabaseValuesMap,
  fetchFirestoreDocument,
  convertDateToTimestamp,
  fetchFirestoreCollectionBySnapshotMap,
  fetchFbToken,
} from "../utility/firebase";
import type { PawItem } from "@spwn/types/firebase/database";
import type { Event } from "@spwn/types/firebase/firestore";

const actionCreator = actionCreatorFactory("event");

/* eslint-disable @typescript-eslint/no-explicit-any */
export const eventActions = {
  setStateByKey:
    actionCreator<{ [P in keyof eventState]?: eventState[P] }>("setStateByKey"),
  clearStateByKey: actionCreator<keyof eventState>("clearStateByKey"),
  getEventJson: actionCreator.async<ReqGetEventJson, any>("getEventJson"),
  clearEventJson: actionCreator<void>("clearEventJson"),
  fetchCheeringItems:
    actionCreator.async<ReqFetchCheeringItems, any>("fetchCheeringItems"),
  fileDownload: actionCreator.async<any, any>("fileDownload"),
  incrementDownload:
    actionCreator.async<ReqIncrementDownload, any>("incrementDownload"),
  fetchUserArAppInfo: actionCreator.async<any, any>("fetchUserArAppInfo"),
  getUserRelatedEventMap: actionCreator<{
    eventMap: eventState["userRelatedEventMap"];
  }>("getUserRelatedEventMap"), // getEvents is going to be deprecated
  watchOneEvent: actionCreator.async<ReqWatchOneEvent, any>("watchOneEvent"),
  setGoodsPageSettings: actionCreator<Partial<eventState["goodsPageSettings"]>>(
    "setGoodsPageSettings"
  ),
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export interface eventState {
  eventJson: EventJson;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cheeringItemMap: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fileDownloadInfo: any;
  userArAppInfo: UserArAppInfo;
  userRelatedEventMap: { [key: string]: Event }; // use for myticket or mycart
  /**
   * EventInfo
   *
   * - 変更をwatchしている
   */
  displayEvent: EventInfo;
  goodsPageSettings: {
    defaultPlaceCode: string;
    venuePlaceCodes: string[];
    selectedPlaceCode: string;
    havingTicketPlaceCodes: string[];
  };
}

export interface EventJson {
  basic_data: BasicDataJson;
  artists_data: ArtistDataJson;
  places_data: PlaceDataJson;
  tickets_data: TicketDataJson;
  goods_data: GoodsDataJson;
}
export interface BasicDataJson {
  eid: string;
  open: TypeValueObject;
  datetime: TypeValueObject;
  openTime: TypeValueObject;
  startTime: TypeValueObject;
  closeTime: TypeValueObject;
  title: string;
  artists: string;
  description: string;
  cautions: string;
  banner_img_path: string;
  parts: {
    name: string;
    openTime: TypeValueObject;
    startTime: TypeValueObject;
    closeTime: TypeValueObject;
  }[];
  isHide: boolean;
  isClosed: boolean;
  hasVOD: boolean;
}
export interface PlaceDataJson {
  places: {
    prefecture: string;
    place: string;
    walking_distance: string;
    place_hp_url: string;
    place_map_url: string;
    img_path: string;
  }[];
}
export interface ArtistDataJson {
  artists: {
    name: string;
    act_type: string;
    description: string;
    img_path: string;
    links: {
      name: string;
      url: string;
    }[];
  }[];
}
export interface TicketDataJson {
  display_sale_datetime: boolean;
  sale_datetime: TypeValueObject;
  tickets: {
    limit_description: string;
    name: string;
    price_jpy: string;
    description: string;
  }[];
}
export interface GoodsDataJson {
  online_goods_img_path: string;
  offline_goods_img_path: string;
  flower_stands_img_path: string;
}
interface TypeValueObject {
  __type: string;
  value: string;
  dateDayTimeString?: string;
  hhii: string;
}

export interface UserArAppInfo {
  hasArApp: boolean;
}

export interface ReqGetEventJson {
  eventId: string;
}

export interface DownloadContents {
  isError: boolean;
  msg: string;
  url: string;
  seconds: number;
}

interface ReqIncrementDownload {
  eid: string;
  itemId: string;
}

export type EventSaleStatus =
  | "ON_SALE"
  | "END"
  | "SOLD_OUT"
  | "BEFORE_SALE"
  | "NONE";

interface ReqWatchOneEvent {
  eventId: string;
  isPreview?: boolean;
}

const initialState: eventState = {
  // @ts-expect-error TS2322
  eventJson: null,
  cheeringItemMap: null,
  fileDownloadInfo: null,
  // @ts-expect-error TS2322
  userArAppInfo: null,
  // @ts-expect-error TS2322
  userRelatedEventMap: null,
  // @ts-expect-error TS2322
  displayEvent: null,
  // @ts-expect-error TS2322
  goodsPageSettings: null,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const eventReducer = reducerWithInitialState(initialState)
  .case(eventActions.setStateByKey, (state, payload) => {
    const [key] = Object.keys(payload);
    // @ts-expect-error TS7053
    return { ...state, [key]: payload[key] };
  })
  .case(eventActions.clearStateByKey, (state, key: keyof eventState) => {
    return { ...state, [key]: initialState[key] };
  })
  .case(eventActions.getEventJson.done, (state, payload: any) => {
    return { ...state, eventJson: payload };
  })
  // @ts-expect-error TS2345
  .case(eventActions.clearEventJson, (state) => {
    return { ...state, eventJson: null };
  })
  .case(eventActions.fetchCheeringItems.done, (state, payload: any) => {
    return { ...state, cheeringItemMap: payload };
  })
  .case(eventActions.fileDownload.done, (state, payload: any) => {
    return { ...state, fileDownloadInfo: payload };
  })
  .case(eventActions.fetchUserArAppInfo.done, (state, payload: any) => {
    return { ...state, userArAppInfo: payload };
  })
  .case(eventActions.getUserRelatedEventMap, (state, payload: any) => {
    return {
      ...state,
      userRelatedEventMap: {
        ...state.userRelatedEventMap,
        ...payload.eventMap,
      },
    };
  })
  .case(eventActions.watchOneEvent.done, (state, payload: any) => {
    return { ...state, displayEvent: payload };
  })
  .case(eventActions.setGoodsPageSettings, (state, payload: any) => {
    return {
      ...state,
      goodsPageSettings: { ...state.goodsPageSettings, ...payload },
    };
  });
/* eslint-enable @typescript-eslint/no-explicit-any */

export default eventReducer;

export function* eventSaga() {
  yield takeEvery(eventActions.getEventJson.started, getEventJson);
  yield takeEvery(eventActions.fetchCheeringItems.started, fetchCheeringItems);
  yield takeEvery(eventActions.fileDownload.started, fileDownload);
  yield takeEvery(eventActions.incrementDownload.started, incrementDownload);
  yield takeEvery(eventActions.fetchUserArAppInfo.started, fetchUserArAppInfo);
  yield takeEvery(eventActions.watchOneEvent.started, watchOneEvent);
}

function* getEventJson(action: { payload: ReqGetEventJson }) {
  try {
    const baseUrl = appConfig.eventPageDomain;
    const { eventId } = action.payload;
    const DATA_URL = getUrlWithUpdateVersion(
      `${baseUrl}/${eventId}/${appConfig.eventJsonName}`
    );
    // @ts-expect-error TS7057
    const response = yield fetch(DATA_URL, { mode: "cors", cache: "no-cache" });
    // if cms data json is none -- event is set by web cms, set tmp json data.
    if (response.status === 404) {
      yield put(
        eventActions.getEventJson.done({
          basic_data: null,
          artists_data: {
            artists: [],
          },
          places_data: {
            places: [],
          },
          tickets_data: {
            display_sale_datetime: true, // TODO イベント詳細ページにチケット販売開始日を表示する。cms jsonの関係上常にtrueにしておく。
            tickets: [],
          },
          goods_data: {
            online_goods_img_path: null,
            offline_goods_img_path: null,
            flower_stands_img_path: null,
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any)
      );
      return;
    }
    const jsonData: EventJson = yield response.json();

    // convert br
    jsonData.basic_data.title = replaceBr(jsonData.basic_data.title);
    jsonData.basic_data.artists = replaceBr(jsonData.basic_data.artists);
    // datetimeの文字列追加
    jsonData.basic_data.open = updateTypeValueObject(jsonData.basic_data.open);
    jsonData.basic_data.datetime = updateTypeValueObject(
      jsonData.basic_data.datetime
    );
    jsonData.basic_data.openTime = updateTypeValueObject(
      jsonData.basic_data.openTime
    );
    jsonData.basic_data.startTime = updateTypeValueObject(
      jsonData.basic_data.startTime
    );
    jsonData.basic_data.closeTime = updateTypeValueObject(
      jsonData.basic_data.closeTime
    );
    jsonData.tickets_data.sale_datetime = updateTypeValueObject(
      jsonData.tickets_data.sale_datetime
    );
    for (let i = 0; i < jsonData.basic_data.parts.length; i++) {
      const element = jsonData.basic_data.parts[i];
      // @ts-expect-error TS18048
      element.openTime = updateTypeValueObject(element.openTime);
      // @ts-expect-error TS18048
      element.startTime = updateTypeValueObject(element.startTime);
      // @ts-expect-error TS18048
      element.closeTime = updateTypeValueObject(element.closeTime);
    }
    // imgpathを相対パスから絶対パスへ置き換える
    jsonData.basic_data.banner_img_path = `${baseUrl}/${eventId}${jsonData.basic_data.banner_img_path}`;
    jsonData.artists_data.artists = jsonData.artists_data.artists.map(
      (artist) => {
        const img_path = getUrlWithUpdateVersion(
          `${baseUrl}/${eventId}${artist.img_path}`
        );
        return {
          ...artist,
          name: replaceBr(artist.name),
          img_path,
        };
      }
    );
    jsonData.goods_data.online_goods_img_path = jsonData.goods_data
      .online_goods_img_path
      ? getUrlWithUpdateVersion(
          `${baseUrl}/${eventId}${jsonData.goods_data.online_goods_img_path}`
        )
      : "";
    jsonData.goods_data.flower_stands_img_path = jsonData.goods_data
      .flower_stands_img_path
      ? getUrlWithUpdateVersion(
          `${baseUrl}/${eventId}${jsonData.goods_data.flower_stands_img_path}`
        )
      : selectImgPath(
          `${baseUrl}/assets/images/flowerStand/goods_flowerStand.png`,
          "1280"
        );
    jsonData.goods_data.offline_goods_img_path = jsonData.goods_data
      .offline_goods_img_path
      ? getUrlWithUpdateVersion(
          `${baseUrl}/${eventId}${jsonData.goods_data.offline_goods_img_path}`
        )
      : "";

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(eventActions.getEventJson.done(jsonData as any));
  } catch (error) {
    console.error(error);
  }
}

const updateTypeValueObject = (obj: TypeValueObject): TypeValueObject => {
  switch (obj.__type) {
    case "datetime": {
      // value is like 'JST_2019-11-09-19:30:00.000000'
      const { value } = obj;
      // replace '-' to '/' because safari can't new date by using '-'
      const yyyymmdd = value.slice(4, 14).replace(/-/g, "/");
      const hhiiss = value.slice(15, 23);
      const date = new Date(`${yyyymmdd} ${hhiiss}`);
      const hhii =
        toDoubleDigits(date.getHours()) +
        ":" +
        toDoubleDigits(date.getMinutes());
      return {
        ...obj,
        dateDayTimeString: getDateDayTimeString(date),
        hhii,
      };
    }
    default: {
      break;
    }
  }
  return obj;
};

// export const cheeringActions = {
//   fetchCheeringItems: actionCreator.async<ReqFetchCheeringItems, any>('fetchCheeringItems'),
// }

export interface ReqFetchCheeringItems {
  eid: string;
}

function* fetchCheeringItems(action: { payload: ReqFetchCheeringItems }) {
  const { eid } = action.payload;
  const {
    auth: {
      user: { uid },
    },
  } = yield select();

  if (!eid || !uid) {
    return;
  }
  try {
    // @ts-expect-error TS7057
    const masterDataItems = yield fetchDatabaseValuesMap<PawItem>(`/items`);
    // const giftMap = yield fetchFirestoreCollectionMap<GiftItem>(`/DLC/${eid}/cheering`)
    const snapshot = firebase
      .firestore()
      .collection(`/DLC/${eid}/cheering`)
      .orderBy("priority")
      .get();
    // @ts-expect-error TS7057
    const giftMap = yield fetchFirestoreCollectionBySnapshotMap(snapshot);

    Object.keys(giftMap).forEach((key) => {
      const gift = giftMap[key];
      const data = masterDataItems[gift.itemId];
      giftMap[key] = {
        ...gift,
        type: data.type,
        values: data.values,
      };
    });
    yield put(eventActions.fetchCheeringItems.done(giftMap));
  } catch (e) {
    console.error(e);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* fileDownload(action: { payload: any }) {
  // @ts-expect-error TS7057
  const fbToken = yield fetchFbToken();
  // @ts-expect-error TS2769
  const response = yield fetch(appConfig.CloudFunctions.getSignedURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${fbToken}`,
    },
    body: JSON.stringify({ eid: action.payload.eid, cid: action.payload.cid }),
  });
  // @ts-expect-error TS7057
  const resBody = yield response.json();
  yield put(eventActions.fileDownload.done(resBody));
}

function* incrementDownload(action: { payload: ReqIncrementDownload }) {
  const { eid, itemId } = action.payload;
  if (!eid || !itemId) {
    return;
  }
  yield firebase
    .firestore()
    .doc(`actionLog/${eid}`)
    .set(
      {
        downloadCount: firebase.firestore.FieldValue.increment(1),
        downloadCountMap: {
          [itemId]: firebase.firestore.FieldValue.increment(1),
        },
      },
      { merge: true }
    );
}

// @ts-expect-error TS7006
function* fetchUserArAppInfo(_action) {
  const {
    auth: {
      user: { uid },
    },
  } = yield select();
  if (!uid) {
    return;
  }

  // @ts-expect-error TS7057
  const userArAppInfo = yield fetchFirestoreDocument(
    `/ARApp/root/users/${uid}`
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any = { hasArApp: !!userArAppInfo };
  yield put(eventActions.fetchUserArAppInfo.done(data));
}

function oneEventChannel(eventId: string, isPreview: boolean) {
  const targetPath = isPreview
    ? `previewEvents/${eventId}`
    : `events/${eventId}`;
  return eventChannel((emitter) => {
    const unsubscribe = firebase
      .firestore()
      .doc(targetPath)
      .onSnapshot(
        (doc) => {
          const data = { ...doc.data(), _id: doc.id };
          emitter(data);
        },
        (error) => {
          console.error(error);
        }
      );
    return unsubscribe;
  });
}

function* watchOneEvent(action: { payload: ReqWatchOneEvent }) {
  const {
    firestore: { channels },
  } = yield select();
  const { eventId, isPreview } = action.payload;

  if (isRegisteredChannel(channels, "oneEvent")) {
    yield put(firestoreActions.closeChannel({ channel: "oneEvent" }));
  }

  try {
    // @ts-expect-error TS2769
    const channel = yield call(oneEventChannel, eventId, isPreview);
    while (true) {
      const event: Event = yield take(channel);
      // artistsがなければartistRefsから名前を取って生成する
      if (!event.artists && event.artistRefs) {
        const artistNames: string[] = yield Promise.all(
          event.artistRefs.map(
            async (artist) => (await artist.get()).data()?.name as string
          )
        );
        event.artists = artistNames
          .filter((artistName) => artistName)
          .join("、");
      }
      const displayEvent = isPreview
        ? // @ts-expect-error TS7057
          yield fetchFirestoreDocument(`events/${eventId}`)
        : {};
      const eventInfo = getEventDisplayInfo(
        isPreview
          ? {
              // previewの場合更新差分しか取得しないため、既存eventsの内容を読み込んだ上でpreview内容で上書きする
              ...displayEvent,
              ...event,
              // if preview, set 'open' to now datetime so that you can enter event page. (無理矢理感)
              open: convertDateToTimestamp(new Date()),
            }
          : event
      );
      // TODO: ここでplaceRefsを解釈してplace情報を肉付け
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield put(eventActions.watchOneEvent.done(eventInfo as any));
      yield put(firestoreActions.addChannel({ ...channel, name: "oneEvent" }));
    }
  } catch (e) {
    console.error(e);
  }
}
