import { PublicPlanContentDTO } from "./dto/publicPlanContent";

export type GetPublicPlanContentRequest = {
  tenantId: string;
  planContentId: string;
};

export type GetPublicPlanContentResponse =
  | {
      ok: true;
      planContent: PublicPlanContentDTO | null;
    }
  | {
      ok: false;
      status:
        | "notfound"
        | "unavailable"
        | "requiredPurchasePlan"
        | "requiredPurchaseBackNumber"
        | "requiredPurchasePlanAndBackNumber";
    };

export type GetPublicPlanContent = {
  path: typeof getPublicPlanContent["path"];
  request: GetPublicPlanContentRequest;
  response: GetPublicPlanContentResponse;
};

export const getPublicPlanContent = {
  path: "getPublicPlanContent",
} as const;
