/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../../store";

import logo_spwn from "../../designs/images/logo_spwn.svg";
import firebase from "firebase/app";

import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import { getDateTimeString } from "../../utility";
import BorderLoader from "../../components/atoms/loader/BorderLoader";
import type { MaintenanceInfo } from "@spwn/types/firebase/firestore";

type Props = {};

interface State {
  maintenanceInfo: null | MaintenanceInfo;
}

class Admin extends Component<Props, State> {
  _unsubscriber = null;
  state = {
    maintenanceInfo: {
      isFlowerStandMaintenance: false,
      isPawChargeMaintenance: false,
      isGoodsMaintenance: false,
      isTicketMaintenance: false,
      isLiveEventMaintenance: false,
      isMaintenance: false,
      startAt: null,
      endAt: null,
      msg: "",
      liveEventId: "",
    },
  };

  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    this.watcher();
  }

  componentWillUnmount(): void {
    if (this._unsubscriber !== null) {
      // @ts-expect-error TS2349
      this._unsubscriber();
      this._unsubscriber = null;
    }
  }

  watcher = () => {
    if (this._unsubscriber === null) {
      // @ts-expect-error TS2322
      this._unsubscriber = firebase
        .firestore()
        .doc("/management/maintenanceInfo")
        .onSnapshot((doc) => {
          const data = doc.data() as MaintenanceInfo;
          this.setState({ maintenanceInfo: data });
        });
    }
  };

  render() {
    console.log(this.state.maintenanceInfo.msg);
    const classesMaintenanceStyles = maintenanceStyles();
    const { maintenanceInfo } = this.state;

    if (maintenanceInfo.startAt === null || maintenanceInfo.endAt === null) {
      return <BorderLoader />;
    }

    return (
      <div css={classesMaintenanceStyles.root}>
        <div css={classesMaintenanceStyles.message}>
          <h1>
            <img src={logo_spwn} alt="spwn" />
          </h1>
          <p>{maintenanceInfo.msg}</p>
          <ul>
            <li>
              開始時刻：
              {getDateTimeString(
                // @ts-expect-error TS2339
                new Date(maintenanceInfo.startAt.seconds * 1000)
              )}
            </li>
            <li>
              終了時刻：
              {getDateTimeString(
                // @ts-expect-error TS2339
                new Date(maintenanceInfo.endAt.seconds * 1000)
              )}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (_state: Store) => {
  return {};
};
const mapDispatchToProps = (_dispatch: Dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Admin);

const maintenanceStyles = () => {
  return {
    root: css`
      width: 100%;
      height: 100vh;
      background-color: #000;
    `,
    message: css`
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      margin: 0 auto;
      padding: 30px;
      border: 1px solid #fff;
      transform: translate(-50%, -50%);
      @media screen and (min-width: 768px) {
        width: 600px;
      }
      h1 {
        img {
          display: block;
          width: 200px;
          margin: 0 auto 30px;
        }
      }
      p {
        margin-bottom: 30px;
        color: #fff;
        text-align: center;
        font-weight: bold;
        line-height: 1.3em;
      }
      ul {
        li {
          margin-bottom: 10px;
          color: #fff;
          text-align: center;
        }
      }
    `,
  };
};
