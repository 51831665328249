import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { EditForm, MyPageEditProfile, ContentsLayout } from "../../styles";

interface Props {}

const Container = MyPageEditProfile;

const DebugLogin: React.FC<Props> = (_props) => {
  const [token, setToken] = useState<string>("");

  const didMount = () => {};
  useEffect(didMount, []);

  const tokenLogin = async () => {
    console.log(token);
    try {
      const ret = await firebase.auth().signInWithCustomToken(token);
      console.log("Singed-in", ret);
    } catch (e) {
      console.error(e);
    }
  };

  // @ts-expect-error TS7006
  const updateToken = (event) => {
    const { value } = event.currentTarget;
    setToken(value);
  };

  return (
    <Container id="contents">
      <ContentsLayout>
        <div className="prifle_edit_info">
          <EditForm style={{ marginTop: "10px" }}>
            <input type="text" name="token" onChange={updateToken} />
            <Button onClick={tokenLogin}>Button</Button>
          </EditForm>
        </div>
      </ContentsLayout>
    </Container>
  );
};

export default DebugLogin;
