/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

type Props = {
  children: string;
};

export const EventTimetableEndLiveButton: React.FC<Props> = ({ children }) => {
  const endLive = css`
    width: 100%;
    margin-top: 32px;
    padding: 16px;
    border: 1px solid #000000;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    @media screen and (min-width: 768px) {
      padding: 16px;
      font-size: 18px;
      font-weight: bold;
    }
  `;
  return (
    <div css={endLive}>
      <p>{children}</p>
    </div>
  );
};
