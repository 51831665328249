import styled from "@emotion/styled";

// FIXME: button が p なのは cursor に対応していないので a11y 的によくない
export const LinkButton = styled.p`
  display: block;
  padding: 10px 0;
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2em;
  letter-spacing: 1px;
  background-color: #00c2ae;
  border: 2px solid #00c2ae;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    padding: 13px 0;
    font-size: 15px;
    letter-spacing: 2px;
  }
  &:hover {
    color: #fff;
  }
  &.modal_cvs_confirm {
    background-color: #ff0000;
    border: 2px solid #ff0000;
  }
  &.disenable {
    background-color: #d4d4d4;
    border: 2px solid #d4d4d4;
    pointer-events: none;
  }
  &.share {
    background-color: #4fb1ea;
    border: 2px solid #4fb1ea;
  }
  &.back {
    color: #fff;
    background-color: #5f6667;
    border: 2px solid #5f6667;
  }
  &.cancel {
    color: #00c2ae;
    background-color: #fff;
    border: 2px solid #00c2ae;
  }
  &.share_button {
    padding: 10px 0;
    color: #00acee;
    text-align: center;
    background-color: #fff;
    border: 2px solid #00acee;
    svg {
      margin-right: 10px;
      fill: #00acee;
    }
  }
`;

LinkButton.displayName = "LinkButton";
