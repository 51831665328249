import {
  GetGoodsPublicRequest,
  GetGoodsPublicResponse,
} from "@spwn/types/functions";
import firebase from "firebase/app";
import useSWR from "swr";
import { getShippingAtPreviewText, Goods } from "./goodsModel";
import { JSONContent } from "@tiptap/core";
import { useGenerateHTML } from "./useGenerateHTML";

export const useGetGoods = ({
  tenantId,
  brandId,
  shopId,
  goodsId,
}: {
  tenantId: string;
  brandId: string;
  shopId: string;
  goodsId: string;
}): {
  loading: boolean;
  goods: Goods;
  error: unknown;
} => {
  const { generateHTML } = useGenerateHTML({
    placeholder:
      "フリーサイズ（XXLサイズ相当：身幅約61cm／身丈約82cm／袖丈約26cm）",
  });
  const { data: goods, error } = useSWR(
    ["getGoodsPublic", tenantId, brandId, shopId, goodsId],
    async () => {
      const { data } = await callGetPublicGoods({
        tenantId,
        goodsId,
      });

      const goods = buildGoods(data, generateHTML, tenantId);

      return goods;
    }
  );

  return {
    loading: false,
    // @ts-expect-error TS2322
    goods,
    error,
  };
};

const callGetPublicGoods = async (
  request: GetGoodsPublicRequest
): Promise<{ data: GetGoodsPublicResponse }> => {
  return await firebase.functions().httpsCallable("getGoodsPublic")(request);
};

const buildGoods = (
  res: GetGoodsPublicResponse,
  generateHTML: (jsonContent: JSONContent) => string,
  tenantId: string
): Goods => {
  const thumbnails =
    res.images.length > 0
      ? res.images.map((image) => {
          return image.url;
        })
      : ["/images/no-img.png"];

  const variants = res.variants.map((variant) => {
    const { id, name, inventory, purchaseLimit, isUnlimitedPurchase } = variant;
    return {
      name,
      purchaseLimit,
      id,
      inventoryQuantity: inventory,
      isUnlimitedPurchase,
    };
  });

  return {
    fulfillmentShipment: {
      title: getShippingAtPreviewText(
        res.deliveryMethod,
        res.deliveryAtEpochMillis
      ),
    },
    thumbnails,
    name: res.name,
    price: res.price,
    id: res.id,
    tags: res.tags,
    // @ts-expect-error TS2322
    variants,
    tenantId,
    isUnlimitedPurchase: res.isUnlimitedPurchase,
    inventoryQuantity: res.inventory,
    purchaseLimit: res.purchaseLimit,
    salesPeriodType: res.salesPeriodType,
    salesStatus: res.salesStatus,
    releasedAt:
      res.releasedAtEpochMillis === null
        ? null
        : new Date(res.releasedAtEpochMillis),
    closedAt:
      res.closedAtEpochMillis === null
        ? null
        : new Date(res.closedAtEpochMillis),
    description: res.description === null ? "" : generateHTML(res.description),
  };
};
