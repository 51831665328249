import Box from "@material-ui/core/Box";
import { Place } from "@spwn/types/firebase/firestore";
import React, { FC } from "react";

export const AccountTicketItemGoodsInfo: FC<{
  productName: string;
  subClassName?: string;
  price: string;
  count: string;
  place?: Place;
}> = ({ productName, subClassName, price, count, place }) => {
  return (
    <Box marginBottom={"15px"} borderBottom={"1px solid #d4d4d4"}>
      {place && (
        <Box
          component={"p"}
          marginBottom={"8px"}
          fontSize={"14px"}
          fontWeight={"bold"}
        >
          {place.areaName} {place.venueName}
        </Box>
      )}

      <Box
        component={"p"}
        fontSize={["18px", "20px"]}
        fontWeight={"bold"}
        lineHeight={"1.3em"}
      >
        {productName} {subClassName}
      </Box>

      <Box
        display={"grid"}
        gridTemplateColumns={"1fr max-content"}
        marginTop={"20px"}
      >
        <Box component={"p"} fontSize={["18px", "20px"]} fontWeight={"bold"}>
          ¥{price}
        </Box>

        <Box
          fontWeight={"400"}
          fontStyle={"normal"}
          style={{
            /* stylelint-disable */
            fontFamily: `"din-condensed", "sans-serif"`,
          }}
        >
          <Box component={"span"} fontSize={"20px"}>
            ×
          </Box>
          <Box component={"span"} fontSize={"40px"}>
            {count}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
