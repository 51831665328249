/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import { createUrl } from "utility/createUrl";
import icon_Facebook from "../designs/images/shareButton/icon_Facebook_530*530.png";

export const FacebookShareButton: FC<{
  eventId: string;
}> = ({ eventId }) => {
  const classes = styles();

  const facebookLink = createUrl({
    location: "http://www.facebook.com/share.php",
    searchParams: [
      {
        key: "u",
        value: `https://${window.location.hostname}/events/${eventId}`,
      },
    ],
  });

  return (
    <div css={classes.root}>
      <button
        type="button"
        onClick={() => {
          window.open(facebookLink, "_blank", "width=800,height=600");
        }}
      >
        <img src={icon_Facebook} alt="button2" />
        <p>Facebook</p>
      </button>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 2px 10px 5px 10px;
        border: none;
        border-radius: 4px;
        background-color: #1877f2;
        cursor: pointer;
        img {
          width: 22px;
          height: auto;
        }
        p {
          padding-top: 4px;
          font-size: 12px;
          font-weight: 700;
          color: #fff;
        }
      }
    `,
  };
};
