import { css } from "@emotion/react";

export const checkedLayoutMixin = css`
  .checkbox_layout {
    display: block;
    margin-bottom: 25px;
    input {
      display: none;
      &:checked + .checkbox {
        background-color: #f2fdfd;
        border: 2px solid #009a9a;
      }
      &:checked + .checkbox:after {
        opacity: 1;
      }
    }
    .checkbox {
      position: relative;
      display: flex;
      background-color: #fff;
      border: 2px solid #dcdbdc;
      transition: 0.3s;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 15px;
        left: 14px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #009a9a;
        @media screen and (min-width: 768px) {
          width: 25px;
          height: 25px;
        }
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 18px;
        left: 17px;
        width: 14px;
        height: 14px;
        background: #009a9a;
        border-radius: 50%;
        opacity: 0;
        transition: 0.3s;
        @media screen and (min-width: 768px) {
          top: 18px;
          width: 19px;
          height: 19px;
        }
      }
      span {
        display: block;
      }
      .label_text {
        width: 100%;
        padding: 13px 4% 13px 15%;
        @media screen and (min-width: 768px) {
          display: flex;
          padding: 13px 20px 13px 60px;
        }
        span:first-child {
          @media screen and (min-width: 768px) {
            min-width: 75px;
            margin-right: 50px;
            font-size: 18px;
          }
        }
        span:last-child {
          font-weight: bold;
          @media screen and (min-width: 768px) {
            font-size: 18px;
          }
        }
      }
      .label_icon {
        display: flex;
        align-items: center;
        padding: 0 10px;
        background-color: #e0f7f8;
        img {
          width: 25px;
        }
      }
    }
  }
`;
