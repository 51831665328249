import React from "react";
import { useI18n } from "hooks/i18n/i18n";
import { TabState } from "./AccountTicket";
import Box from "@material-ui/core/Box";

export const AccountTicketCategoryTab: React.FC<{
  tabState: TabState;
  setTabState: (value: React.SetStateAction<TabState>) => void;
}> = ({ tabState, setTabState }) => {
  const { t } = useI18n();

  return (
    <Box
      component={"ul"}
      display={"grid"}
      gridTemplateColumns={"1fr 1fr 1fr"}
      paddingTop={"8px"}
      bgcolor={"#A1A1A1"}
      style={{
        gap: "8px",
      }}
    >
      <AccountTicketCategoryTabItem
        isSelected={tabState === "inSession"}
        onClick={() => setTabState("inSession")}
      >
        {t("ticket.tab.inSession")}
      </AccountTicketCategoryTabItem>

      <AccountTicketCategoryTabItem
        isSelected={tabState === "beforeEvent"}
        onClick={() => setTabState("beforeEvent")}
      >
        {t("ticket.tab.beforeEvent")}
      </AccountTicketCategoryTabItem>

      <AccountTicketCategoryTabItem
        isSelected={tabState === "archiveEvent"}
        onClick={() => setTabState("archiveEvent")}
      >
        {t("ticket.tab.archiveEvent")}
      </AccountTicketCategoryTabItem>
    </Box>
  );
};

const AccountTicketCategoryTabItem: React.FC<{
  children: string;
  isSelected: boolean;
  onClick: () => void;
}> = ({ children, isSelected, onClick }) => {
  return (
    <Box
      component={"li"}
      padding={"16px 0"}
      color={isSelected ? "#000" : "#00C2AD"}
      fontSize={"20px"}
      fontWeight={"bold"}
      textAlign={"center"}
      bgcolor={"#fff"}
      letterSpacing={"1px"}
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};
