import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";

const actionCreator = actionCreatorFactory("menu");

export const menuActions = {
  toggleSideMenu: actionCreator<void>("toggleSideMenu"),
};

export interface menuState {
  isOpenSideMenu: boolean;
}

const initialState: menuState = {
  isOpenSideMenu: false,
};

const menuReducer = reducerWithInitialState(initialState).case(
  menuActions.toggleSideMenu,
  (state) => {
    return { ...state, isOpenSideMenu: !state.isOpenSideMenu };
  }
);

export default menuReducer;
