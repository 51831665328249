/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

interface Props {
  id?: string;
  text: string;
  color?: string;
  textColor?: string;
  disabled?: boolean;
  onClick?: () => void;
}
export const Button: React.FC<Props> = (props) => {
  const classesButtonStyles = buttonStyles({
    // @ts-expect-error TS2322
    color: props.color,
    // @ts-expect-error TS2322
    textColor: props.textColor,
  });
  return (
    <button
      id={props.id}
      css={classesButtonStyles.root}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.text}
    </button>
  );
};

interface ButtonStylesProps {
  color: string;
  textColor: string;
}
const buttonStyles = (props: ButtonStylesProps) => {
  const textColor = props.textColor ? props.textColor : "#fff";
  const buttonColor = props.color ? props.color : "#00c2ae";
  return {
    root: css`
      display: block;
      width: 100%;
      padding: 13px 0;
      color: ${textColor};
      font-size: 14px;
      font-weight: bold;
      line-height: 1em;
      letter-spacing: 1px;
      background-color: ${buttonColor};
      border: none;
      cursor: pointer;
      outline: none;
      appearance: none;
      &:disabled {
        background-color: #d2d2d2;
        cursor: not-allowed;
      }
    `,
  };
};
