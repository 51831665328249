import { push } from "connected-react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { fetchFbToken } from "utility/firebase";
import appConfig from "../../constants/appConfig";
import { useValidReturnUrlQuery } from "./useValidReturnUrlQuery";

type LoginStatus =
  | {
      type: "watch" | "loading" | "complete";
    }
  | {
      type: "error";
      msg: string;
    };

/**
 * spwnサービス（現状ファンクラブのみ）がログインを行うために利用する
 *
 * - ログイン状態を監視する
 *   - ログインしていれば、セッションCookie生成リクエストを行い、Cookieを埋め込み、元のサービスのURLへ戻る
 *   - ログインを検知できなければ何もしない
 */
export const useAccountsAuthenticator = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<LoginStatus>({ type: "watch" });
  const user = useSelector((state: Store) => state.auth.user);
  const return_url = useValidReturnUrlQuery();

  useEffect(() => {
    // 未ログイン時のハンドリング
    if (!user.uid) return;

    // Email未認証の時のハンドリング
    if (!user.emailVerified) {
      dispatch(push("/signup/authentication"));
      return;
    }

    (async () => {
      try {
        setStatus({ type: "loading" });

        const fbToken = await fetchFbToken();
        const url = `${appConfig.backendApiUrl}/account/spwn/createSessionCookie`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fbToken}`,
          },
          body: JSON.stringify({}),
          /**
           * 別ホストのbackend-apiに送信する必要があるので、Cross Origin Requestとなり、same-originではなくincludeにする
           * 「same-site」と「same-origin」を理解する / https://web.dev/articles/same-site-same-origin?hl=ja
           */
          credentials: "include",
        });
        if (response.ok) {
          setStatus({ type: "complete" });
          window.location.assign(
            return_url ?? appConfig.accountsApp.defaultReturnCrewUrl
          );
        }
      } catch (error) {
        /**
         * 想定されないエラーで、sentryなどにログ送りたい
         */
        setStatus({ type: "error", msg: "予期せぬエラーが発生しました" });
        console.error(error);
      }
    })();
  }, [user, return_url, dispatch]);
  return {
    status,
  };
};
