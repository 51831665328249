import React from "react";
import styled from "@emotion/styled";
import { fontDinMixin } from "../../../styles";
import type { TicketConfig } from "@spwn/types/firebase/firestore";
import type { TicketData } from "@spwn/types/firebase/database";

interface Props {
  displaySubClassTickets: TicketData[];
  ticketConfig: TicketConfig;
  selectedClassGroupId: string;
  // @ts-expect-error TS7006
  selectTicketWithSubClass: (value) => void;
}

const Container = styled.div`
  :not(:last-child) {
    margin-bottom: 60px;
    @media screen and (min-width: 768px) {
      margin-bottom: 100px;
    }
  }
  .heading {
    display: flex;
    margin-bottom: 25px;
    font-size: 18px;
    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
      font-size: 25px;
    }
    span {
      ${fontDinMixin};
      display: block;
      margin-right: 5%;
      font-size: 35px;
      @media screen and (min-width: 768px) {
        margin-right: 20px;
        font-size: 43px;
      }
    }
  }
  .eventEntryTime {
    width: 100%;
    height: 42px;
    padding: 0 28px 0 17px;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #dedede;
    background: url("/images/select_pulldown.png") right 50% no-repeat;
    background-size: 29px, 100%;
    border-radius: 0;
    appearance: none;
    @media screen and (min-width: 768px) {
      width: 350px;
      font-size: 16px;
    }
  }
`;

/**
 * @deprecated 作成されたが使われていない機能。削除していい。
 */
const EventTicketSelectNum: React.FC<Props> = (props: Props) => {
  // @ts-expect-error TS7006
  const getTicketSalesStatus = (ticket) => {
    switch (ticket.remaining) {
      case -1:
        return "売切れ";

      case 0:
        return "購入済み";

      default:
        return null;
    }
  };

  return (
    <>
      {props.selectedClassGroupId && props.displaySubClassTickets.length > 0 ? (
        <Container>
          <h3 className="heading">
            <span>3</span>
            {props.ticketConfig.subClassGroupName}を選択してください
          </h3>
          <select
            className="eventEntryTime"
            onChange={(e) => {
              props.selectTicketWithSubClass(e.target.value);
            }}
          >
            <option value="0">
              {props.ticketConfig.subClassGroupName}を選択してください。
            </option>
            {props.displaySubClassTickets.map((ticket, i) => {
              const ticketSalesStatus = getTicketSalesStatus(ticket);
              return (
                <React.Fragment key={i}>
                  {ticketSalesStatus ? (
                    <option value={ticket._id} disabled>
                      {ticket.subClassName} {ticketSalesStatus}
                    </option>
                  ) : (
                    <option value={ticket._id}>{ticket.subClassName}</option>
                  )}
                </React.Fragment>
              );
            })}
          </select>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};
export default EventTicketSelectNum;
