/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useI18n } from "hooks/i18n/i18n";
import appConfig from "constants/appConfig";

/**
 * アカウントアプリケーションサービス用のFooter
 * PortalやCrewのサービスによらないFooterとして名前変えてもいいかも
 */
export const AccountsAppFooter: React.FC = () => {
  const { t } = useI18n();
  const classesFooterDesign = footerDesign();

  const FooterMenuItems = {
    FooterMenuBottom: [
      {
        name: t("common.footer.operatingCompany"),
        link: "https://balus.co/",
      },
      {
        name: t("common.footer.termsOfService"),
        link: appConfig.accountsApp.tosUrl,
      },
      { name: t("common.footer.law"), link: "https://spwn.jp/law" },
      {
        name: t("common.footer.paymentServicesAct"),
        icon: "law",
        link: "https://spwn.jp/payment-services-act",
      },
      {
        name: t("common.footer.privacyPolicy"),
        link: "https://spwn.jp/privacy-policy",
      },
      { name: "お問い合せ", link: "https://spwncrew.zendesk.com/hc/ja" },
    ],
  };
  const copyright = "© 2019 Balus CO., LTD. All rights reserved.";

  return (
    <footer css={classesFooterDesign.footer}>
      <div css={classesFooterDesign.container}>
        <div css={classesFooterDesign.footerBottomLayout}>
          <ul>
            {FooterMenuItems.FooterMenuBottom.map((el, i) => {
              return (
                <li key={i}>
                  <a href={el.link} target="_blank" rel="noreferrer">
                    {el.name}
                  </a>
                </li>
              );
            })}
          </ul>
          <p>{copyright}</p>
        </div>
      </div>
    </footer>
  );
};

const footerDesign = () => {
  return {
    footer: css`
      background-color: #141718;
      @media screen and (min-width: 768px) {
        padding: 40px 0px 50px;
      }
    `,
    container: css`
      width: 100%;
      @media screen and (min-width: 768px) {
        width: 95%;
        max-width: 1800px;
        margin: 0 auto;
      }
    `,
    footerBottomLayout: css`
      @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      ul {
        @media screen and (min-width: 768px) {
          display: flex;
          margin-bottom: 30px;
        }
        li {
          border-bottom: 2px solid #121212;
          @media screen and (min-width: 768px) {
            margin-right: 30px;
            border-bottom: none;
          }
        }
        a {
          display: block;
          padding: 20px 5%;
          color: #f0f0f0;
          font-size: 12px;
          @media screen and (min-width: 768px) {
            padding: 0;
            color: #77797a;
          }
        }
      }
      p {
        padding: 15px 0 40px;
        color: #77797a;
        font-size: 10px;
        text-align: center;
        background-color: #000;
        @media screen and (min-width: 768px) {
          padding: 0;
          font-size: 12px;
          text-align: right;
          background-color: inherit;
        }
      }
    `,
  };
};
