/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import React from "react";
import { isSmartPhone } from "../../../utility";
// @ts-expect-error TS7016
import AnchorLink from "react-anchor-link-smooth-scroll";
import { changeStyleWithHosting } from "../../../utility/hosting";

interface LinkInEventPageProps {
  hasGoods: boolean;
  hasFlowerStand: boolean;
}
export const EventDetailLinks: React.FC<LinkInEventPageProps> = (props) => {
  // @ts-expect-error TS7006
  const TopAnchorLink = (props) => {
    return (
      <AnchorLink
        href={props.href}
        offset={() => {
          return isSmartPhone() ? 30 : 100;
        }}
      >
        {props.children}
      </AnchorLink>
    );
  };
  const hostingLinkInPageMap =
    changeStyleWithHosting().pageSettings.eventPage.linkInPage;
  const classesLinkInEventPageDesign = linkInEventPageDesign();

  return (
    <div css={classesLinkInEventPageDesign.root}>
      <ul>
        <li className="top">
          <TopAnchorLink href="#contents">
            {hostingLinkInPageMap.link01.text}
          </TopAnchorLink>
        </li>
        <li className="appearance">
          {hostingLinkInPageMap.link02.isDisplay ? (
            <TopAnchorLink href="#act_info">
              {hostingLinkInPageMap.link02.text}
            </TopAnchorLink>
          ) : null}
        </li>
        <li className="ticket">
          {hostingLinkInPageMap.link03.isDisplay ? (
            <TopAnchorLink href="#place_ticket">
              {hostingLinkInPageMap.link03.text}
            </TopAnchorLink>
          ) : null}
        </li>
        <li className="goods">
          {hostingLinkInPageMap.link04.isDisplay ? (
            props.hasGoods ? (
              <TopAnchorLink href="#goods_area">
                {hostingLinkInPageMap.link04.text}
              </TopAnchorLink>
            ) : (
              <span className="disableLink">
                {hostingLinkInPageMap.link04.text}
              </span>
            )
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </li>
        <li className="flower-stand">
          {hostingLinkInPageMap.link05.isDisplay ? (
            props.hasFlowerStand ? (
              <TopAnchorLink href="#flower_stand_area">
                {hostingLinkInPageMap.link05.text}
              </TopAnchorLink>
            ) : (
              <span className="disableLink">
                {hostingLinkInPageMap.link05.text}
              </span>
            )
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </li>
      </ul>
    </div>
  );
};

const linkInEventPageDesign = () => {
  const hostingBaseStyleMap = changeStyleWithHosting().baseStyles;
  const linkInPageStyleMap =
    changeStyleWithHosting().pageSettings.eventPage.linkInPage;

  return {
    root: css`
      position: sticky;
      top: 0;
      background-color: ${hostingBaseStyleMap.mainColor};
      z-index: 10;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      ul {
        display: flex;
        width: 100%;
        max-width: 980px;
        margin: 0 auto;
      }
      li {
        width: 18%;
        text-align: center;
        @media screen and (min-width: 768px) {
          width: 100%;
          letter-spacing: 2px;
        }
        &.ticket {
          width: 25%;
          @media screen and (min-width: 768px) {
            width: 100%;
          }
        }
        &.flower-stand {
          width: 21%;
          @media screen and (min-width: 768px) {
            width: 100%;
          }
        }
      }
      a,
      span {
        display: block;
        padding: 15px 0;
        color: ${linkInPageStyleMap.color};
        font-size: 10px;
        font-weight: bold;
        line-height: 1em;
        border-bottom: 4px solid ${hostingBaseStyleMap.mainColor};
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
      a:hover {
        color: ${hostingBaseStyleMap.accentColor};
        border-bottom: 4px solid ${hostingBaseStyleMap.accentColor};
        background-color: hsla(0, 0%, 100%, 0.1);
      }
      span {
        opacity: 0.3;
      }
    `,
  };
};
