/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import { FullScreenDialog } from "../../components/common/Modal";
import { cartActions } from "../../modules/cart";
import { Product, useCart } from "../../hooks/cart/Cart";
import type { Cart as CartType } from "../../hooks/cart/Cart";
import { useStickyCartSummary } from "../../hooks/cart/StickyCartSummary";
import { CartHeading } from "../../components/cart/CartHeading";
import { CartOrderHeading } from "../../components/cart/CartOrderHeading";
import { CartOrderProduct } from "../../components/cart/CartOrderProduct";
import CloseIcon from "@material-ui/icons/Close";
import { useI18n } from "../../hooks/i18n/i18n";
import { EmptyCart } from "./EmptyCart";
import { useHistory } from "react-router";
import { SPView } from "../../components/common/MediaQuery";
import { CartStickyOrder } from "../../components/cart/CartStickyOrder";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { Cart as NewCart } from "../../features/Cart";
import { revalidate } from "../../features/useGetUserCart";
import { useUserCart } from "features/useUserCart";
import { useDeleteItemFromCart } from "features/useDeleteItemFromCart";

/**
 * @deprecated 削除する
 */
export const OrigCartDialog = () => {
  const isOpenMyCart = useSelector((state: Store) => state.cart.isOpenMyCart);
  const { cart, loading } = useCart();
  const dispatch = useDispatch();
  const closeUserCart = useCallback(() => {
    dispatch(cartActions.toggleMyCart({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <FullScreenDialog open={isOpenMyCart} onBackdropClick={closeUserCart}>
        <LoaderLayout />
      </FullScreenDialog>
    );
  }

  if (!cart) {
    return (
      <FullScreenDialog open={isOpenMyCart} onBackdropClick={closeUserCart}>
        <EmptyCart />
      </FullScreenDialog>
    );
  }

  return (
    <FullScreenDialog open={isOpenMyCart} onBackdropClick={closeUserCart}>
      <Cart cart={cart} />
    </FullScreenDialog>
  );
};

const NewCartDialog = () => {
  const isOpenMyCart = useSelector((state: Store) => state.cart.isOpenMyCart);

  const dispatch = useDispatch();
  const { data, isValidating } = useUserCart();
  const { deleteItem } = useDeleteItemFromCart();

  const closeUserCart = useCallback(() => {
    dispatch(cartActions.toggleMyCart({}));
  }, [dispatch]);

  const deleteFromCart = useCallback(
    async (product: Product) => {
      const { cartProductId, cartItemId, isTenantCart } = product;

      if (isTenantCart) {
        // @ts-expect-error TS2345
        await deleteItem(cartItemId);
        revalidate();
        return;
      }

      dispatch(
        cartActions.deleteFromCart.started({
          payload: { cartId: cartProductId },
        })
      );
    },
    [dispatch, deleteItem]
  );

  useEffect(() => {
    // カートモーダルを開いたら再取得。既存cartに関してはonSnapshotで監視しているので必要ない。
    if (isOpenMyCart) {
      revalidate();
    }
  }, [isOpenMyCart]);

  const isLoading = data === undefined;
  if (isLoading || isValidating) {
    return (
      <FullScreenDialog open={isOpenMyCart} onBackdropClick={closeUserCart}>
        <LoaderLayout />
      </FullScreenDialog>
    );
  }

  const isEmpty = data.orderCarts.length === 0;
  if (isEmpty) {
    return (
      <FullScreenDialog open={isOpenMyCart} onBackdropClick={closeUserCart}>
        <EmptyCart />
      </FullScreenDialog>
    );
  }

  return (
    <FullScreenDialog open={isOpenMyCart} onBackdropClick={closeUserCart}>
      <NewCart cart={data} deleteFromCart={deleteFromCart} />
    </FullScreenDialog>
  );
};

export const CartDialog = NewCartDialog;

export const Cart: React.FC<{
  cart: CartType;
}> = ({ cart }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = styles();
  const { t } = useI18n();
  const { headerRefs, stickyCartIndex } = useStickyCartSummary(cart);

  const changeRoute = useCallback(
    (url: string) => {
      if (/^https?:\/\//.exec(url)) {
        window.open(url, "_blank");
        return;
      }
      dispatch(cartActions.toggleMyCart({}));
      history.push(url);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const deleteFromCart = useCallback(
    (cartId: string) => {
      dispatch(
        cartActions.deleteFromCart.started({
          payload: { cartId },
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { orderCarts, cartItemCount } = cart;
  const isDisplay = stickyCartIndex !== null;

  return (
    <div css={classes.root}>
      <SPView>
        <CartStickyOrder
          isDisplay={isDisplay}
          // @ts-expect-error TS2322
          orderCart={orderCarts[stickyCartIndex]}
          goSettlement={() =>
            // @ts-expect-error TS2532
            changeRoute(orderCarts[stickyCartIndex].purchaseLink)
          }
        />
      </SPView>

      <div
        css={classes.closeIcon}
        onClick={() => {
          dispatch(cartActions.toggleMyCart({}));
        }}
      >
        <CloseIcon />
      </div>

      <div css={classes.heading}>
        <CartHeading numberOfProducts={cartItemCount} />
      </div>

      {orderCarts.map((cart, index) => {
        const {
          heading,
          shippingAtTitle,
          products,
          shippingFee,
          subTotalPrice,
          purchaseLink,
        } = cart;
        return (
          <div
            css={classes.product}
            key={`cart-${index}`}
            ref={headerRefs.current[index]}
          >
            <CartOrderHeading
              cartNumber={heading}
              date={shippingAtTitle}
              total={subTotalPrice}
              shippingFee={shippingFee}
              goSettlement={() => changeRoute(purchaseLink)}
            />

            {products.map((product, index) => {
              return (
                <React.Fragment key={index}>
                  <CartOrderProduct
                    product={product}
                    deleteFromCart={() => deleteFromCart(product.cartProductId)}
                    changeRoute={changeRoute}
                  />
                </React.Fragment>
              );
            })}
          </div>
        );
      })}

      <div
        css={classes.closeButton}
        onClick={() => {
          dispatch(cartActions.toggleMyCart({}));
        }}
      >
        {t("cart.button.closeButton")}
      </div>
    </div>
  );
};

const styles = () => ({
  root: css`
    position: relative;
    width: 90%;
    max-width: 980px;
    padding: 40px 0;
    margin: 0 auto;
  `,
  closeIcon: css`
    position: absolute;
    top: 12px;
    right: 0;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      top: 40px;
    }
    svg {
      @media screen and (min-width: 768px) {
        width: 32px;
        height: 32px;
      }
    }
  `,
  heading: css`
    margin-bottom: 16px;
    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
    }
  `,
  product: css`
    margin-bottom: 24px;
  `,
  closeButton: css`
    width: 100%;
    max-width: 400px;
    padding: 8px;
    margin: 40px auto 0;
    color: #00c2ad;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
    border: 2px solid #00c2ad;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  `,
});
