/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

export const QrcodeInfo: React.FC<{
  eventStatus: {
    eventName: string;
    eventDate: string;
  };
  purchasedGoodsStatus: {
    subClassName: string;
    name: string;
    count: number;
    price_jpy: number;
    priority: number;
    place: string;
  }[];
}> = (props) => {
  const classesQrcodeStyle = styles();
  const itemTotal = React.useMemo(() => {
    return props.purchasedGoodsStatus.reduce((acc, cur) => {
      acc = acc + cur.count;
      return acc;
    }, 0);
  }, [props.purchasedGoodsStatus]);
  return (
    <div css={classesQrcodeStyle.box}>
      <div css={classesQrcodeStyle.event}>
        <p css={classesQrcodeStyle.day}>{props.eventStatus.eventDate}</p>
        <p css={classesQrcodeStyle.name}>{props.eventStatus.eventName}</p>
      </div>
      {props.purchasedGoodsStatus.map((item, index) => {
        return (
          <div css={classesQrcodeStyle.goods} key={index}>
            <div css={classesQrcodeStyle.goodsName}>
              <p>{item.name}</p>
              {item.subClassName.length > 0 && (
                <p css={classesQrcodeStyle.goodsSubName}>
                  （{item.subClassName}）
                </p>
              )}
            </div>
            <p css={classesQrcodeStyle.goodsCount}> x {item.count}</p>
          </div>
        );
      })}
      <div css={classesQrcodeStyle.goodsTotal}>
        <p>合計点数: {itemTotal} 点</p>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    box: css`
      width: 100%;
      max-width: 640px;
      margin: 0 auto;
      margin-bottom: 40px;
      padding: 2.5%;
      border: 1px solid #d4d4d4;
      background-color: #fff;
      color: #000;
    `,
    day: css`
      margin-bottom: 10px;
      font-weight: 700;
    `,
    name: css`
      color: #12c9cf;
      font-weight: 700;
      line-height: 1.3em;
    `,
    event: css`
      margin: 0 auto 14px;
      border-bottom: 1px solid #d4d4d4;
      padding-bottom: 14px;
    `,
    goods: css`
      display: flex;
      font-size: 16px;
      font-weight: bold;
      align-items: center;
      border-bottom: 1px solid #d4d4d4;
      padding-top: 14px;
      &:not(:last-child) {
        padding-bottom: 14px;
      }
    `,
    goodsName: css`
      width: calc(100% - 2.5em - 16px);
      margin-right: 16px;
      p {
        line-height: 1.2em;
      }
    `,
    goodsSubName: css`
      margin-top: 8px;
      font-size: 14px;
      &:empty {
        margin-bottom: 22px;
      }
    `,
    goodsCount: css`
      width: 2.5em;
      text-align: right;
      font-size: 24px;
    `,
    goodsTotal: css`
      display: flex;
      justify-content: flex-end;
      padding-top: 14px;
      font-size: 24px;
      font-weight: bold;
    `,
  };
};
