import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "@emotion/styled";

import type { SurveyQuizData, Event } from "@spwn/types/firebase/firestore";

import { liveActions, ReqVote, ReqWatchSurveyData } from "modules/live";
import { AllChannel, firestoreActions } from "modules/firestore";
import { Store } from "store";
import { EventThumbImg, QuizBlock, LiveStreamHeader } from "styles";
import { replaceBr, convertMapToValues, getEventDisplayInfo } from "utility";
import { getLiveContextType } from "utility/live";

type Props = ValueProps &
  FuncProps & {
    event: Event;
    contextId: string;
  };
interface ValueProps {
  surveyData: SurveyQuizData;
}
interface FuncProps {
  watchSurveyData: (payload: ReqWatchSurveyData) => void;
  vote: (payload: ReqVote) => void;
  closeContextId: (contextId: string) => void;
}

interface States {
  contextId: string;
  currentAnswerId: number;
}

const QuizLayout01 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @media screen and (min-width: 768px) {
    width: 750px;
    margin: 0 auto 40px;
  }
  p {
    width: 48%;
    padding: 15px 0;
    color: #fff;
    text-align: center;
    background-color: #000;
    border: 2px solid #3e4446;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      padding: 20px 0;
    }
    &:hover {
      opacity: 0.7;
    }
    img {
      width: 40%;
      @media screen and (min-width: 768px) {
        width: 90px;
      }
    }
    &.selected_answer {
      border: 2px solid #01ffe1;
    }
  }
`;

const QuizLayout02 = styled.div`
  margin-bottom: 40px;
  @media screen and (min-width: 768px) {
    width: 750px;
    margin: 0 auto 40px;
  }
  p {
    padding: 12px 0;
    color: #fff;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    background-color: #000;
    border: 2px solid #3e4446;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      font-size: 30px;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:hover {
      opacity: 0.7;
    }
    &.selected_answer {
      border: 2px solid #01ffe1;
    }
  }
`;

const QuizLayout03 = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 750px;
    margin: 0 auto 40px;
  }
  .choices_item {
    padding: 3% 3% 4%;
    background-color: #000;
    border: 2px solid #3e4446;
    @media screen and (min-width: 768px) {
      width: 48%;
      margin-bottom: 30px;
    }
    &:not(:last-child) {
      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
    }
    img {
      width: 100%;
      margin-bottom: 15px;
    }
    p {
      color: #fff;
      line-height: 1.5em;
    }
    &:hover {
      opacity: 0.7;
    }
    &.selected_answer {
      border: 2px solid #01ffe1;
    }
  }
`;

class QuizEvent extends Component<Props, States> {
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    this.state = {
      // @ts-expect-error TS2322
      contextId: null,
      currentAnswerId: -1,
    };
  }

  static getDerivedStateFromProps(props: Props, state: States) {
    if (props.contextId !== state.contextId) {
      // watchしているsurveyDataをcloseし、新たにwatchする
      props.watchSurveyData({
        nextContextId: props.contextId,
        currentContextId: state.contextId,
      });
      return { contextId: props.contextId };
    }
    return null;
  }

  componentWillUnmount() {
    this.props.closeContextId(this.state.contextId);
  }

  // @ts-expect-error TS7006
  vote = (answerText: string, answerId) => {
    this.setState({ currentAnswerId: Number(answerId) });
    this.props.vote({
      answerText,
      answerId,
    });
  };

  render() {
    if (!this.props.event || !this.props.surveyData) {
      return <></>;
    }

    const { content, caption } = this.props.surveyData;
    const eventInfo = getEventDisplayInfo(this.props.event);

    return (
      <>
        <EventThumbImg src={eventInfo.thumbnail} alt={eventInfo.title} />

        <QuizBlock>
          <LiveStreamHeader>{!caption ? "QUIZ" : caption}</LiveStreamHeader>
          <p className="quiz_text">{content}</p>
          {this.answerRenderSwitcher()}
        </QuizBlock>
      </>
    );
  }

  answerRenderSwitcher = () => {
    const contextType = getLiveContextType(this.props.contextId);
    const answers = convertMapToValues(this.props.surveyData.answers);
    switch (contextType) {
      case "qid":
        if (answers.length === 2) {
          return this.answerType01(answers);
        } else if (this.props.surveyData.hasImgs) {
          return this.answerType03(this.props.surveyData);
        } else {
          return this.answerType02(answers);
        }
      default:
        return this.answerType02(answers);
    }
  };

  answerType01 = (answers: string[]) => {
    return (
      <QuizLayout01>
        {answers.map((text, id) => {
          const answerClass =
            id === this.state.currentAnswerId ? "selected_answer" : "";
          return (
            <p
              key={id}
              className={answerClass}
              onClick={() => this.vote(text, id)}
            >
              {text}
            </p>
          );
        })}
        {/* TODO@later ○×記号の判定は? */}
        {/* <p><img src={icon_circle} alt=""/></p>
        <p><img src={icon_cross} alt=""/></p> */}
      </QuizLayout01>
    );
  };

  answerType02 = (answers: string[]) => {
    return (
      <QuizLayout02>
        {answers.map((text, id) => {
          const answerClass =
            id === this.state.currentAnswerId ? "selected_answer" : "";
          return (
            <p
              key={id}
              className={answerClass}
              onClick={() => this.vote(text, id)}
            >
              {text}
            </p>
          );
        })}
      </QuizLayout02>
    );
  };

  answerType03 = (surveyData: SurveyQuizData) => {
    return (
      <QuizLayout03>
        {Object.keys(surveyData.answers).map((id, i) => {
          const text = surveyData.answers[id];
          // @ts-expect-error TS18048
          const img = surveyData.imgs[id];
          const answerClass =
            Number(id) === this.state.currentAnswerId ? "selected_answer" : "";
          return (
            <div
              key={i}
              className={`choices_item ${answerClass}`}
              // @ts-expect-error TS2345
              onClick={() => this.vote(text, id)}
            >
              <img src={img} alt="" />
              <p>{replaceBr(text)}</p>
            </div>
          );
        })}
      </QuizLayout03>
    );
  };
}

const mapStateToProps = (state: Store) => {
  const props: ValueProps = {
    surveyData: state.live.surveyData as SurveyQuizData,
  };
  return props;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const func: FuncProps = {
    watchSurveyData: (payload: ReqWatchSurveyData) => {
      dispatch(liveActions.watchSurveyData.started(payload));
    },
    vote: (payload) => {
      dispatch(liveActions.vote.started(payload));
    },
    // @ts-expect-error TS2322
    closeContextId: (contextId: AllChannel) => {
      dispatch(firestoreActions.closeChannel({ channel: contextId }));
    },
  };
  return func;
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizEvent);
