/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { phoneInfo } from "constants/purchase";
import arrow from "designs/images/streaming/arrow_down.png";
import { useI18n } from "hooks/i18n/i18n";
import type { PhoneCode } from "@spwn/types/gmo";

interface Props {
  isDarkModeEnabled: boolean;
  setPhoneCode: React.Dispatch<React.SetStateAction<PhoneCode>>;
  openChangePaymentMethod: () => void;
  canCareerPurchase: () => boolean;
  startPurchaseProcess: () => void;
}
export const StreamingLiveCommerceCareerPayment: React.FC<Props> = (props) => {
  const classesLiveCommerceCreditPaymentStyles =
    liveCommerceCreditPaymentStyles({
      isDarkModeEnabled: props.isDarkModeEnabled,
    });
  const { t } = useI18n();

  return (
    <React.Fragment>
      <div css={classesLiveCommerceCreditPaymentStyles.methodOfPayment}>
        <p>{t("streaming.commerceCareerPayment.paymentMethod")}</p>
        <p className="change" onClick={props.openChangePaymentMethod}>
          {t("streaming.commerceCareerPayment.changeButton")}
        </p>
      </div>

      <div css={classesLiveCommerceCreditPaymentStyles.phonePayment}>
        <p>{t("streaming.commerceCareerPayment.carrerCheckout")}</p>
        <select
          css={classesLiveCommerceCreditPaymentStyles.selectPhonePayment}
          onChange={(e) => props.setPhoneCode(e.target.value as PhoneCode)}
        >
          {/*
           // @ts-expect-error TS2322 */}
          <option value={null}>----</option>
          {Object.keys(phoneInfo()).map((el, i) => {
            return (
              <option key={i} value={el}>
                {/*
                 // @ts-expect-error TS7053 */}
                {phoneInfo()[el]}
              </option>
            );
          })}
        </select>
      </div>

      <div
        css={classesLiveCommerceCreditPaymentStyles.button}
        // @ts-expect-error TS2322
        className={props.canCareerPurchase() ? null : "disabled"}
        onClick={props.startPurchaseProcess}
      >
        {t("streaming.commerceCareerPayment.confirmPurchase")}
      </div>
    </React.Fragment>
  );
};

interface LiveCommerceCreditPaymentStylesProps {
  isDarkModeEnabled: boolean;
}
const liveCommerceCreditPaymentStyles = (
  props?: LiveCommerceCreditPaymentStylesProps
) => {
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;

  const methodOfPayment = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    p {
      ${textColorInDarkMode}
      font-size: 14px;
    }
    .change {
      color: #00c2ae;
      cursor: pointer;
    }
  `;

  const phonePayment = css`
    margin-bottom: 16px;
    p {
      margin-bottom: 12px;
      ${textColorInDarkMode}
      font-size: 14px;
    }
  `;
  const selectPhonePayment = css`
    width: 100%;
    padding: 8px;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 1px;
    background-color: #fff;
    background-image: url(${arrow});
    background-size: 8px auto;
    background-repeat: no-repeat;
    background-position: 96% center;
    border: 1px solid #dedede;
    border-radius: 5px;
    appearance: none;
  `;
  const button = css`
    padding: 12px 0;
    margin-top: 24px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    background-color: #f33074;
    cursor: pointer;
    &.disabled {
      background-color: #b1b1b1;
    }
  `;
  const register = css`
    padding: 25px 0 15px;
    p {
      font-size: 14px;
      text-align: center;
      line-height: 1.5em;
      span {
        color: #00c2ae;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  `;
  return {
    methodOfPayment,
    phonePayment,
    selectPhonePayment,
    button,
    register,
  };
};
