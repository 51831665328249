import { KEYUTIL, KJUR, RSAKey } from "jsrsasign";
import gmoConfig from "../constants/gmoConfigs";
import { errorReport } from "./logger";

// @ts-expect-error TS7006
export function hexToBase64(hexstring) {
  return btoa(
    hexstring
      .match(/\w{2}/g)
      // @ts-expect-error TS7006
      .map(function (a) {
        return String.fromCharCode(parseInt(a, 16));
      })
      .join("")
  );
}

/**
 * getCardTokenGMOのラッパー (引数や返り値を整理)
 *
 * @returns string | undefined
 */
export const getGMOToken = async (props: {
  cardNo: string;
  expireYear: string;
  expireMonth: string;
  holderName: string;
  securityCode: string;
}): Promise<string | undefined> => {
  // 参考: https://github.com/balus-co-ltd/spwn/blob/561d6dba16827ef52098b099c9fb541cda9c56a7/packages/portal/src/modules/purchase.tsx#L993-L1000
  const result = await getCardTokenGMO({
    cardNo: props.cardNo,
    expire: `${props.expireYear}${props.expireMonth}`,
    securityCode: props.securityCode,
    holderName: props.holderName,
    tokenNumber: 1,
  });
  const tokens = result?.tokens;
  if (tokens === undefined || tokens === null || tokens.length <= 0) {
    return undefined;
  } else {
    return tokens[0];
  }
};

/**
 * @example
 * getCardTokenGMO({
 *   cardNo: "4111111111111111",
 *   expire: "2012",
 *   holderName: `TEST${String(Math.random()).slice(2,10)}`,
 *   securityCode: 123,
 *   tokenNumber: 1
 * }).then((response: {isError: boolean, tokens:any})=>{
 *   this.props.registerCardInfo({token: response.tokens[0]})
 * });
 *
 * @see https://github.com/balus-co-ltd/spwn/blob/9a76585598d5ea88797d4c3ca09e0f66faf990ae/packages/portal/src/containers/modal/RegisterCard.tsx#L112-L122
 *
 * @deprecated
 */
export const getCardTokenGMO = async (orgPayload: {
  cardNo: string;
  expire: string;
  securityCode: string;
  holderName: string;
  tokenNumber: number;
}): Promise<{
  isError: boolean;
  error?: string;
  tokens?: string[] | undefined | null;
}> => {
  return new Promise((resolve, _reject) => {
    const payload = JSON.stringify(
      Object.keys(orgPayload)
        // @ts-expect-error TS7053
        .filter((key) => orgPayload[key] !== "")
        .reduce(
          // @ts-expect-error TS7053
          (obj, key) => Object.assign(obj, { [key]: orgPayload[key] }),
          {}
        )
    );

    // @ts-expect-error TS2345
    const key = KEYUTIL.getKey(gmoConfig.pubKey) as RSAKey;
    const encPayload = KJUR.crypto.Cipher.encrypt(payload, key, "RSA");
    const b64 = hexToBase64(encPayload);
    const msg = {
      Encrypted: b64,
      ShopID: gmoConfig.c_shop.id,
      KeyHash: gmoConfig.keyHash,
    };

    // @ts-expect-error TS2769
    fetch(gmoConfig.api.getCardToken, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: Object.keys(msg)
        // @ts-expect-error TS7053
        .map((key) => key + "=" + encodeURIComponent(msg[key]))
        .join("&"),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.error(response);
          response.text().then((text) => {
            const errorText = `getCardTokenGMO: HTTP ${response.status}: ${text}`;
            errorReport(new Error(errorText));
            resolve({
              isError: true,
              error: errorText,
            });
          });
        } else {
          response.json().then((json) => {
            const { resultCode } = json;
            if (resultCode[0] !== "000") {
              const errorText = `getCardTokenGMO: TokenError<br/> ErrorCode:${resultCode.join(
                ", "
              )}`;
              errorReport(new Error(errorText));
              resolve({
                isError: true,
                error: errorText,
              });
            } else {
              resolve({
                isError: false,
                tokens: json.tokenObject.token,
              });
            }
          });
        }
      })
      .catch((error) => {
        errorReport(error);
        console.error(error);
        resolve({
          isError: true,
          error: "トークン生成に失敗しました",
        });
      });
  });
};
