/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useState } from "react";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import GroupIcon from "@material-ui/icons/Group";

import type { Event } from "@spwn/types/firebase/firestore";

import { PortalEventListSkeleton } from "components/loading/SkeletonLoader";
import { Breadcrumb } from "components/atoms/Breadcrumb";
import { MainContents } from "components/templates/MainContents";
import { EventCard } from "features/EventCard";
import { useI18n } from "hooks/i18n/i18n";
import { useEventCategoryQuery } from "hooks/QueryString";
import { useGetEventList } from "hooks/event/useGetEventList";
import { getEventDisplayInfo } from "utility";
import { changeStyleWithHosting } from "utility/hosting";
import { useGetStatusChangedEventIds } from "hooks/event/useGetStatusChangedEventIds";

interface Props {}
export const PortalEventList: React.FC<Props> = (_props) => {
  const { data: events, isLoading } = useGetEventList({
    type: "allVisible",
  });

  const { data: statusChangedEventIds } = useGetStatusChangedEventIds();

  const [salesEventList, setSalesEventList] = useState<Event[]>([]);
  const [archiveEventList, setArchiveEventList] = useState<Event[]>([]);
  const [endSalesEventList, setEndSalesEventList] = useState<Event[]>([]);

  const { category } = useEventCategoryQuery();
  const breadcrumbText =
    changeStyleWithHosting().commonSettings.menu.event.text;

  const classesEventListDesign = eventListDesign();
  const { t } = useI18n();

  const watchEventList = () => {
    const salesEvent: Event[] = [];
    const archiveEvent: Event[] = [];
    const endSalesEvent: Event[] = [];

    let eventList = (events ?? [])
      .slice()
      .sort((prev, cur) => prev.datetime.seconds - cur.datetime.seconds);

    if (category) {
      eventList = eventList.filter((event) =>
        event?.categories?.includes(category)
      );
    }

    eventList.forEach((event) => {
      const { eventSaleStatus, hasVOD } = getEventDisplayInfo(event);

      /**
       * @description アーカイブ販売のものを販売中に表示したい
       * ここで /news/topInfo の prioritizeEventIds に格納されているイベントかどうかを判定する
       */
      if (event._id && statusChangedEventIds?.includes(event._id)) {
        if (
          eventSaleStatus === "ON_SALE" ||
          eventSaleStatus === "BEFORE_SALE"
        ) {
          salesEvent.push(event);
        } else {
          endSalesEvent.push(event);
        }
      } else if (
        (!hasVOD && eventSaleStatus === "ON_SALE") ||
        eventSaleStatus === "BEFORE_SALE"
      ) {
        salesEvent.push(event);
      } else if (hasVOD && eventSaleStatus === "ON_SALE") {
        archiveEvent.push(event);
      } else {
        endSalesEvent.push(event);
      }
    });

    setSalesEventList(salesEvent); // 昇順
    setArchiveEventList(archiveEvent.slice().reverse()); // 降順
    setEndSalesEventList(endSalesEvent.slice().reverse()); // 降順
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(watchEventList, [events, statusChangedEventIds]);

  // @ts-expect-error TS7006
  const eventListReader = (event, index) => {
    return (
      <React.Fragment key={index}>
        <EventCard event={event} />
      </React.Fragment>
    );
  };

  if (isLoading) {
    return (
      <MainContents>
        <Breadcrumb
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            [null, breadcrumbText],
          ]}
        />

        <section id="salesEvent" css={classesEventListDesign.root}>
          <h2 css={classesEventListDesign.headline}>
            <GroupIcon />
            {t("event.status.liveTicketSale")}
          </h2>
          <div css={classesEventListDesign.list}>
            <PortalEventListSkeleton />
            <PortalEventListSkeleton />
            <PortalEventListSkeleton />

            <PortalEventListSkeleton />
            <PortalEventListSkeleton />
            <PortalEventListSkeleton />

            <PortalEventListSkeleton />
            <PortalEventListSkeleton />
            <PortalEventListSkeleton />
          </div>
        </section>
      </MainContents>
    );
  }

  return (
    <MainContents>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, breadcrumbText],
        ]}
      />

      {salesEventList.length > 0 && (
        <section id="salesEvent" css={classesEventListDesign.root}>
          <h2 css={classesEventListDesign.headline}>
            <GroupIcon />
            {t("event.status.liveTicketSale")}
          </h2>
          <div css={classesEventListDesign.list}>
            {salesEventList.map((event, index) => {
              return (
                <div css={classesEventListDesign.item} key={index}>
                  {eventListReader(event, index)}
                </div>
              );
            })}
          </div>
        </section>
      )}

      {archiveEventList.length > 0 && (
        <section id="archiveEvent" css={classesEventListDesign.root}>
          <h2 css={classesEventListDesign.headline}>
            <SubscriptionsIcon />
            {t("event.status.archivedTicketSale")}
          </h2>
          <div css={classesEventListDesign.list}>
            {archiveEventList.map((event, index) => {
              return (
                <div css={classesEventListDesign.item} key={index}>
                  {eventListReader(event, index)}
                </div>
              );
            })}
          </div>
        </section>
      )}

      {endSalesEventList.length > 0 && (
        <section id="endSalesEvent" css={classesEventListDesign.root}>
          <h2 css={classesEventListDesign.headline}>
            <WatchLaterIcon />
            {t("event.status.endTicketSale")}
          </h2>
          <div className="endSalesList" css={classesEventListDesign.list}>
            {endSalesEventList.map((event, index) => {
              return (
                <div
                  className="endSalesItem"
                  css={classesEventListDesign.item}
                  key={index}
                >
                  {eventListReader(event, index)}
                </div>
              );
            })}
          </div>
        </section>
      )}
    </MainContents>
  );
};

const eventListDesign = () => {
  const root = css`
    width: 90%;
    max-width: 980px;
    padding: 40px 0 80px;
    margin: 0 auto;
    &#salesEvent,
    &#archiveEvent {
      padding-bottom: 16px;
      margin-bottom: 36px;
      border-bottom: 1px solid #3b3b3b;
    }
    &#endSalesEvent {
      .endSalesList {
        @media screen and (min-width: 768px) {
          &:before {
            content: "";
            display: block;
            width: 24%;
            order: 1;
          }
          &:after {
            content: "";
            display: block;
            width: 24%;
          }
        }
      }
      .endSalesItem {
        width: 47.5%;
        @media screen and (min-width: 768px) {
          width: 24%;
        }
      }
    }
  `;
  const headline = css`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    @media screen and (min-width: 768px) {
      font-size: 24px;
    }
    svg {
      margin-right: 8px;
      @media screen and (min-width: 768px) {
        width: 32px;
        height: 32px;
      }
    }
  `;
  const list = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after {
      content: "";
      display: block;
      width: 31%;
    }
  `;

  const item = css`
    width: 47.5%;
    margin-bottom: 24px;
    border-radius: 8px;
    overflow: hidden;
    @media screen and (min-width: 768px) {
      width: 31.5%;
    }
    a {
      display: block;
    }
  `;
  const thumbnail = css`
    position: relative;
    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
    p {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 6px 10px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1px;
      background-color: rgba(0, 0, 0, 0.7);
    }
    &::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 56.25%;
      background-color: #e3e3e3;
    }
  `;
  const info = css`
    padding: 16px;
    background-color: #fff;
  `;
  const flexbox = css`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  `;
  const datetime = css`
    width: 100%;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
    time {
      display: block;
      margin-bottom: 6px;
      @media screen and (min-width: 768px) {
        display: inline;
        margin-right: 1em;
        margin-bottom: 0px;
      }
    }
    span {
      display: block;
      @media screen and (min-width: 768px) {
        display: inline;
      }
    }
  `;
  const title = css`
    display: box;
    height: 4.5em;
    font-size: 14px;
    line-height: 1.5em;
    /* stylelint-disable-next-line property-no-unknown */
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 3;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  `;
  return {
    root,
    headline,
    list,
    item,
    thumbnail,
    info,
    flexbox,
    datetime,
    title,
  };
};
