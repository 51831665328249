import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "../../../store";

import { FullScreenDialog } from "components/common/Modal";
import { DialogStyles } from "components/Styled";

import { authActions } from "../../../modules/auth";
import { modalActions } from "../../../modules/modal";
import { purchaseActions } from "../../../modules/purchase";
import { getCommaStyleNumber } from "../../../utility";

import SelectPaw from "./SelectPaw";
import InputPaymentMethods from "./InputPaymentMethods";
import SettlementComplete from "./SettlementComplete";
import styled from "@emotion/styled";

interface Props {}

export interface InitialCardInfo {
  cardNumber: string;
  cardExpireMonth: string;
  cardExpireYear: string;
  cardHolderName: string;
  cardSecurityCode: string;
  useCreditInfoIndex: number;
}

const PawChargeFullScreenDialog = styled(FullScreenDialog)`
  overflow-y: scroll;
  @media screen and (min-width: 768px) {
    padding: 60px 0 0;
  }
`;

PawChargeFullScreenDialog.displayName = "PawChargeFullScreenDialog";

const PawChargeContent = styled.div`
  max-width: 800px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    min-height: auto;
  }
`;

PawChargeContent.displayName = "PawChargeContent";

const PawCharge: React.FC<Props> = () => {
  const initialCardInfo: InitialCardInfo = {
    cardNumber: "",
    cardExpireMonth: "",
    cardExpireYear: "",
    cardHolderName: "",
    cardSecurityCode: "",
    useCreditInfoIndex: 0,
  };

  const dispatch = useDispatch();
  const isPawCharge = useSelector((state: Store) => state.modal.isPawCharge);
  const pawBalance = useSelector(
    (state: Store) => state.purchase.emoBalance.paidValue
  );
  const cardInfoList = useSelector(
    (state: Store) => state.purchase.cardInfoList
  );

  const [isConfirmOrder, setConfirmOrder] = useState<boolean>(false);
  // @ts-expect-error TS2345
  const [selectedPaw, setSelectedPaw] = useState<[number, string]>(null);
  // @ts-expect-error TS2345
  const [selectCreditCard, setSelectCreditCard] = useState<string>(null);
  const [currentPageState, setCurrentPageState] = useState<string>("selectPaw");
  const [useCardInfo, setUseCardInfo] =
    useState<InitialCardInfo>(initialCardInfo);

  const pawBalanceNum =
    pawBalance === undefined ? 0 : getCommaStyleNumber(pawBalance);

  const didMount = () => {
    dispatch(purchaseActions.getEmoBalance.started());
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.getEmoBalance.started,
        args: null,
      })
    );

    dispatch(purchaseActions.fetchCardInfo.started({}));
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.fetchCardInfo.started,
        args: null,
      })
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(didMount, []);

  const watchCardInfoList = () => {
    if (cardInfoList && cardInfoList.length > 0) {
      setSelectCreditCard("currentCreditCard");
    } else if (cardInfoList && cardInfoList.length === 0) {
      setSelectCreditCard("newCreditCard");
    }
  };
  useEffect(watchCardInfoList, [cardInfoList]);

  const backToPawListPage = () => {
    setCurrentPageState("selectPaw");
    // @ts-expect-error TS2345
    setSelectedPaw(null);
  };

  const backToInputPaymentMethods = () => {
    setConfirmOrder(false);
    setUseCardInfo({ ...useCardInfo, cardSecurityCode: "" });
  };

  const toggleModal = () => {
    setConfirmOrder(false);
    setUseCardInfo(initialCardInfo);
    setCurrentPageState("selectPaw");
    dispatch(modalActions.togglePawChargeModal({}));
  };

  const switchPawChargeRender = () => {
    switch (currentPageState) {
      case "selectPaw":
        return (
          <SelectPaw
            toggleModal={toggleModal}
            pawBalanceNum={pawBalanceNum}
            currentPageState={currentPageState}
            setCurrentPageState={setCurrentPageState}
            setSelectedPaw={setSelectedPaw}
          />
        );

      case "inputPaymentMethods":
        return (
          <InputPaymentMethods
            toggleModal={toggleModal}
            // @ts-expect-error TS2322
            cardInfoList={cardInfoList}
            isConfirmOrder={isConfirmOrder}
            backToInputPaymentMethods={backToInputPaymentMethods}
            selectedPaw={selectedPaw[0]}
            selectedPawId={selectedPaw[1]}
            selectCreditCard={selectCreditCard}
            setSelectCreditCard={setSelectCreditCard}
            backToPawListPage={backToPawListPage}
            useCardInfo={useCardInfo}
            setUseCardInfo={setUseCardInfo}
            setConfirmOrder={setConfirmOrder}
          />
        );

      case "settlementComplete":
        return <SettlementComplete toggleModal={toggleModal} />;

      default:
        break;
    }
  };

  return (
    <PawChargeFullScreenDialog
      id="modalPawCharge"
      style={DialogStyles}
      open={isPawCharge}
    >
      <PawChargeContent>{switchPawChargeRender()}</PawChargeContent>
    </PawChargeFullScreenDialog>
  );
};
export default PawCharge;
