import type { ErrorCode } from "@spwn/types/gmo";

/**
 * 取引できないカードエラーコードリスト
 * @see https://mp-faq.gmo-pg.com/s/article/D00923
 * @see https://github.com/balus-co-ltd/spwn/pull/2800
 */
export const GMO_INVALID_CARD_ERROR_CODES: ErrorCode[] = [
  "42G120000",
  "42G220000",
  "42G300000",
  "42G540000",
  "42G560000",
  "42G600000",
  "42G610000",
  "42G920000",
  "42G950000",
  "42G960000",
  "42G970000", // カード会社のシステム設定が起因している可能性あり
  "42G980000", // カード会社のシステム設定が起因している可能性あり
  "42G990000", // カード会社のシステム設定が起因している可能性あり
];
