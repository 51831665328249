import React from "react";
import { Link } from "react-router-dom";
// import { changeStyleWithHosting } from "utility/hosting";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import manual01 from "../designs/images/manual/manual01.png";
import manual02 from "../designs/images/manual/manual02.png";
import manual03 from "../designs/images/manual/manual03.png";
import manual04 from "../designs/images/manual/manual04.png";
import manual05 from "../designs/images/manual/manual05.png";
import manual06 from "../designs/images/manual/manual06.png";
import manual07 from "../designs/images/manual/manual07.png";
import manual08 from "../designs/images/manual/manual08.png";
import manual09 from "../designs/images/manual/manual09.png";
import manual10 from "../designs/images/manual/manual10.png";
import manual11 from "../designs/images/manual/manual11.png";
import manual12 from "../designs/images/manual/manual12.png";
import manual14 from "../designs/images/manual/manual14.png";
import manual15 from "../designs/images/manual/manual15.png";
import manual16 from "../designs/images/manual/manual16.png";
import manual17 from "../designs/images/manual/manual17.png";
import manual18 from "../designs/images/manual/manual18.png";
import manual19 from "../designs/images/manual/manual19.png";
import manual20 from "../designs/images/manual/manual20.png";
import manual21 from "../designs/images/manual/manual21.png";
import manual22 from "../designs/images/manual/manual22.png";
import manual23 from "../designs/images/manual/manual23.png";
import manual24 from "../designs/images/manual/manual24.png";
import manual25 from "../designs/images/manual/manual25.png";
import { CART_EXPIRATION_MINUTES } from "./purchase";

// const hostingStyleMap = changeStyleWithHosting();

export interface ManualTextItem {
  heading: string;
  description: string;
  slide: MasualSlide[];
}

interface MasualSlide {
  image: string;
  message: string;
}

export const manualText = {
  Step1: {
    heading: "Step1 会員登録をする",
    description:
      "SPWNでのチケット購入、及び各種サービスをご利用いただくためには会員登録が必要になります。",
    slide: [
      {
        image: manual01,
        message: (
          <>
            <p>
              SPWNのホームページにアクセスすると、画面上部に
              <ExitToAppIcon />
              アイコンが表示されます。
            </p>
            <p>
              <span className="login_icon"></span>アイコンを押してください。
            </p>
          </>
        ),
      },
      {
        image: manual02,
        message: (
          <>
            <p>
              <ExitToAppIcon />
              アイコンをクリックすると、ログイン/新規会員登録を行うことができる画面が表示されます。
            </p>
            <p>
              会員登録がお済みでない方は、この画面から『新規登録』を行ってください。
            </p>
          </>
        ),
      },
      {
        image: manual03,
        message: (
          <>
            <p>
              新規登録は様々な方法をご用意しております。いずれかの方法で、新規登録をお願いいたします。
            </p>
            <ul>
              <li>・facebookアカウントでの新規登録</li>
              <li>・Twitter IDでの新規登録</li>
              <li>・Googleアカウントでの新規登録</li>
              <li>・メールアドレスでの新規登録</li>
            </ul>
          </>
        ),
      },
      {
        image: manual04,
        message: (
          <>
            <p>
              メールアドレス登録で新規登録された場合には入力されたメールアドレス、SNSでの新規登録の場合は該当のSNSに登録しているメールアドレスに、認証メールをお送りいたします。
            </p>
            <p>
              登録したメールをご確認し、記載されているURLにアクセスすることで、新規登録が完了いたします。
            </p>
            <p>
              メールアドレス認証が確認出来なかった場合、メール再送画面が表示されます。
            </p>
            <p>
              フィルタリング設定などによってメールが届かない場合があります。その場合は設定をご確認いただくか、サービス提供元へのお問い合わせをお願いいたします。
            </p>
          </>
        ),
      },
    ],
  },

  Step2: {
    heading: "Step2 チケットをカートに入れる",
    description:
      "SPWNでは様々なイベントを開催しており、購入をすることができます。",
    slide: [
      {
        image: manual05,
        message: (
          <p>
            SPWNの<Link to="/">TOPページ</Link>もしくは、
            <Link to="/events">イベント一覧ページ</Link>
            へアクセスし、チケットを購入したいイベントを選択してください。
          </p>
        ),
      },
      {
        image: manual06,
        message: (
          <>
            <p>イベント内容の詳細や注意事項を確認することができます。</p>
            <p>
              購入するチケットに間違いがなければ『チケット購入へ』のボタンを押してください。
            </p>
          </>
        ),
      },
      {
        image: manual07,
        message: (
          <>
            <p>
              会場や購入するチケットの枚数などの情報を選択（入力）してください。
            </p>
            <p>
              内容に間違いがなければ『チケットをカートに入れる』のボタンを押してください。
            </p>
          </>
        ),
      },
      {
        image: manual08,
        message: (
          <>
            <p>
              そのまま購入へ進む場合は『カートを確認する』のボタンを押してください。
            </p>
            <p>
              カートの中身に関しては、画面上部の
              <ShoppingCartIcon />
              アイコンを押すことで確認することができます。
            </p>
            <p>
              他のイベントのチケットを購入する場合は『閉じる』のボタンを押し、ポップアップを閉じたあとに『SPWN』のロゴを押すことでTOPページに戻ることができます。
            </p>
          </>
        ),
      },
      {
        image: manual09,
        message: (
          <>
            <p>
              カート内商品の保持期限は{CART_EXPIRATION_MINUTES}
              分です。保持期限を過ぎるとカートから商品が削除されますのでご注意ください。
            </p>
            <p>
              チケットの情報を確認し、内容に問題が無ければ『決済画面へ』のボタンを押してください。
            </p>
          </>
        ),
      },
    ],
  },

  Step3: {
    heading: "Step3 チケット代金を支払う",
    description: "SPWNでは様々な支払い方法をご用意しています。",
    slide: [
      {
        image: manual10,
        message: (
          <>
            <p>
              カート画面から『決済画面へ』のボタンを押すと、ご注文内容を確認する画面となります。購入するチケットの情報を確認し、ご希望のお支払い方法を選択してください。
            </p>
            <p>
              次に、表示された入力欄に必要な情報を入力し『この内容で決済』のボタンを押してください。
            </p>
            <p>
              コンビニ支払いのお支払い期限はご注文日翌日から3日となります。日曜にご注文日頂いた場合、お支払い期限は水曜日の23時59分までとなります。
            </p>
            <p>
              購入日から3日を過ぎますとキャンセル処理され商品をご購入頂けなくなりますのでご注意ください。
            </p>
          </>
        ),
      },
      {
        image: manual11,
        message: (
          <>
            <p>
              『クレジットカード決済』を選択した場合は、クレジットカードでの決済に進みます。事前にクレジットカード情報を入力されていた場合には、そのまま決済確認画面に進みます。クレジットカード情報が入力されていない場合は、クレジットカード情報を入力してください。
            </p>
            <p>
              チケットの情報、及びクレジットカード情報が正しいかご確認いただきましたら、『この内容で決済』のボタンを押してください。
            </p>
          </>
        ),
      },
      {
        image: manual20,
        message: (
          <>
            <p>
              『コンビニ決済』を選択した場合は、コンビニでの決済に進みます。
            </p>
            <p>
              チケットの情報、及びお客様情報が正しいかご確認いただきましたら、『この内容で決済』のボタンを押してください。
            </p>
          </>
        ),
      },
      {
        image: manual12,
        message: (
          <>
            <p>
              無事決済が完了しますと、『決済が完了しました』と画面に表示されます。
            </p>
            <p>
              コンビニ支払いを選択した方は、画面に表示されている「マイチケット」のボタンを押してください。
            </p>
          </>
        ),
      },
      {
        image: manual14,
        message: (
          <>
            <p>
              <Link to="/account/ticket">マイチケットページ</Link>
              に進むと、購入したチケットを確認することができます。
            </p>
            <p>
              コンビニ支払いを選択した方は、『決済情報を確認』のボタンを押すとお支払い番号を確認することができます。
            </p>
          </>
        ),
      },
      {
        image: manual15,
        message: (
          <>
            <p>『コンビニ支払い』を選択した場合は、このように表示されます。</p>
            <p>ご指定のコンビニエンスストアにて、決済をお願いいたします。</p>
          </>
        ),
      },
    ],
  },

  Step4: {
    heading: "Step4 チケットを確認する",
    description:
      "SPWNではデジタルチケットを採用しております。イベント当日はSPWNにログインできる端末をご用意し、会場へお越しください。",
    slide: [
      {
        image: manual16,
        message: (
          <>
            <p>
              画面上部の
              <span className="menu_icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
              アイコンを押すと、メニューが表示されます。
            </p>
            <p>メニュー内にある『アカウント情報』を押してください。</p>
          </>
        ),
      },
      {
        image: manual17,
        message: (
          <>
            <p>
              『アカウント情報』画面では、登録したアカウントの情報の編集、SPWN専用の仮想通貨PAWの購入や決済履歴の確認を行うことができます。
            </p>
            <p>
              チケットを確認する場合は『チケット』項目の『マイチケット』を押してください。
            </p>
          </>
        ),
      },
      {
        image: manual19,
        message: (
          <>
            <p>
              『マイチケット』を押すと、購入したチケットの一覧が表示されます。
            </p>
            <p>
              確認したいチケットの『デジタルチケットを表示する』のボタンを押してください。
            </p>
          </>
        ),
      },
      {
        image: manual18,
        message: (
          <>
            <p>購入したチケットの情報が表示されます。</p>
            <p>
              イベント当日は、イベントスタッフがこのページからチケットの確認を行います。イベントの入場時には、この画面を表示するようにご協力のほど、よろしくお願いいたします。イベント当日まで『係員専用』と記載されているボタンには触れないようにしてください。
            </p>
            <p>
              お客様の方で『係員専用』ボタンを操作してしまった場合、イベントに参加することが出来なくなることもございます。取り扱いには十分ご注意ください。
            </p>
          </>
        ),
      },
    ],
  },

  Step5: {
    heading: "Step5 ライブを視聴する",
    description:
      "SPWNではライブの配信も行っております。イベント当日はSPWNにログインできる端末でライブページにアクセスしてください。",
    slide: [
      {
        image: manual21,
        message: (
          <>
            <p>
              ライブの開催日になりますと、<Link to="/">TOPページ</Link>
              に開催ライブの案内が表示されます。
            </p>
            <p>
              『視聴する配信を選択』を押してください。イベントの詳細ページに移動します。
            </p>
          </>
        ),
      },
      {
        image: manual22,
        message: (
          <>
            <p>
              ライブ視聴ページへのリンクは、イベント詳細ページに表示されます。
            </p>
            <p>
              ライブ視聴ページへの入場は、開場時間になってからとなります。イベントの開場時間はイベント詳細の『OPEN/START』をご確認ください。
            </p>
            <p>
              ライブ視聴ページへのリンクは、ライブ開場時間までに表示されるので、それまでお待ちいただけると幸いです。
            </p>
          </>
        ),
      },
      {
        image: manual23,
        message: (
          <>
            <p>
              ライブ視聴ページでは、動画を視聴する他に、アーティストにコメントを送ることや、PAWを使用してギフトを送ることができます。
            </p>
            <p>
              イベントによってはギフト機能を利用できない場合もあります。その場合には『ギフト』が表示されません。
            </p>
          </>
        ),
      },
      {
        image: manual24,
        message: (
          <>
            <p>
              画面に表示されている『チャット』を押すと、コメント欄とコメント送信フォームが表示されます。
            </p>
            <p>
              送りたいコメントを打ち込み『送信』を押すことでコメントを送ることが出来ます。
            </p>
          </>
        ),
      },
      {
        image: manual25,
        message: (
          <>
            <p>
              画面に表示されている『ギフト』を押すと、ギフト選択画面が表示されます。
            </p>
            <p>
              送りたいギフトを選択し、コメントを添えてアーティストに送ることが出来ます。
            </p>
            <p>
              ギフトを送るとコメント欄に、金額に応じて目立つ形で表示されます。
            </p>
          </>
        ),
      },
    ],
  },
};
