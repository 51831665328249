import React from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router";
import { useGetBrand } from "./useGetBrand";

type TenantRouterComponentProps = {
  tenantId: string;
  brandId: string;
  shopId: string;
} & RouteComponentProps;

export const TenantRouter = ({
  component: Component,
  ...rest
}: Omit<RouteProps, "component"> & {
  component: React.FC<TenantRouterComponentProps>;
}) => {
  const { brand } = useGetBrand();
  if (brand === undefined) return <Loading />;
  return (
    <Route
      {...rest}
      render={(props) => (
        <Component
          {...props}
          tenantId={brand.tenant.id}
          brandId={brand.id}
          // @ts-expect-error TS2532
          shopId={brand.shops[0].id} // ブランドは1つ以上のshopを持つ
        />
      )}
    />
  );
};

const Loading = () => {
  return <p>Loading...</p>;
};
