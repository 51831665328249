/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { FC } from "react";
import { DeepMap, FieldError, UseFormRegisterReturn } from "react-hook-form";
import { FormType } from "./AccountAddress";

export const AccountAddressPhone: FC<{
  register: UseFormRegisterReturn;
  errors: DeepMap<FormType, FieldError>;
  defaultValue: string;
}> = ({ register, errors, defaultValue }) => {
  const classes = styles();

  return (
    <div css={classes.formItem}>
      <input
        type={"text"}
        defaultValue={defaultValue}
        className={errors?.phone ? "error" : ""}
        {...register}
      />

      {errors?.phone?.message && (
        <p css={classes.errorMessage}>{errors?.phone?.message}</p>
      )}
    </div>
  );
};

const styles = () => {
  return {
    formItem: css`
      input[type="text"] {
        width: 100%;
        padding: 8px;
        font-size: 16px;
        border: 1px solid #e6e5e6;
      }
      .error {
        border: 1px solid #ff0000 !important;
        background-color: rgba(255, 0, 0, 0.1) !important;
      }
    `,
    errorMessage: css`
      margin-top: 8px;
      color: #ff0000;
      font-size: 12px;
      font-weight: bold;
    `,
  };
};
