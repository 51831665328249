import styled from "@emotion/styled";

export const EventThumbImg = styled.img`
  display: block;
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    width: 500px;
  }
`;

EventThumbImg.displayName = "EventThumbImg";
