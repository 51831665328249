/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { changeStyleWithHosting } from "utility/hosting";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useI18n } from "hooks/i18n/i18n";
import { Place } from "@spwn/types/firebase/firestore";

interface Props {
  places: Place[];
  selectedPlaceCode: string;
  filterTicketByPlace: (code: string) => void;
  // @ts-expect-error TS7006
  handleChangePlace: (placeCode) => void;
}

const EventTicketSelectPlace: React.FC<Props> = (props: Props) => {
  const { t } = useI18n();
  const classesEventTicketSelectPlaceDesign = eventTicketSelectPlaceDesign();
  return (
    <div css={classesEventTicketSelectPlaceDesign.root}>
      <h3 css={classesEventTicketSelectPlaceDesign.heading}>
        <span>1</span>
        {t("event.ticket.headline.place")}
      </h3>
      {props.places.map((place, i) => {
        return (
          <label
            key={i}
            css={classesEventTicketSelectPlaceDesign.checkboxLayout}
            onClick={() => {
              props.filterTicketByPlace(place.code);
              props.handleChangePlace(place.code);
            }}
          >
            <input
              id="place"
              name="place"
              type="radio"
              defaultChecked={place.code === props.selectedPlaceCode}
            />
            <span
              className="checkbox"
              css={classesEventTicketSelectPlaceDesign.checkbox}
            >
              <span css={classesEventTicketSelectPlaceDesign.labelText}>
                <span>{place.areaName}</span>
                <span>{place.venueName}</span>
              </span>
              {!place.officialSiteUrl ? null : (
                <a
                  href={place.officialSiteUrl}
                  target="_blank"
                  css={classesEventTicketSelectPlaceDesign.labelLink}
                  rel="noreferrer"
                >
                  <HelpOutlineIcon />
                </a>
              )}
            </span>
          </label>
        );
      })}
    </div>
  );
};
export default EventTicketSelectPlace;

const eventTicketSelectPlaceDesign = () => {
  const hostingBaseStyleMap = changeStyleWithHosting().baseStyles;
  return {
    root: css`
      &:not(:last-child) {
        margin-bottom: 60px;
        @media screen and (min-width: 768px) {
          margin-bottom: 100px;
        }
      }
    `,
    heading: css`
      margin-bottom: 25px;
      display: flex;
      font-size: 18px;
      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
        font-size: 25px;
      }
      span {
        display: block;
        margin-right: 5%;
        font-family: "din-condensed", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 35px;
        @media screen and (min-width: 768px) {
          margin-right: 20px;
          font-size: 43px;
        }
      }
    `,
    checkboxLayout: css`
      display: block;
      margin-bottom: 25px;
      input {
        display: none;
        &:checked + .checkbox {
          background-color: ${hostingBaseStyleMap.accentColor}20;
          border: 2px solid ${hostingBaseStyleMap.accentColor};
        }
        &:checked + .checkbox:before {
          border: 1px solid ${hostingBaseStyleMap.accentColor};
        }
        &:checked + .checkbox:after {
          opacity: 1;
        }
      }
    `,
    checkbox: css`
      position: relative;
      display: flex;
      background-color: #fff;
      border: 2px solid #dcdbdc;
      transition: 0.3s;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 14px;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        border: 1px solid #999;
        border-radius: 50%;
        @media screen and (min-width: 768px) {
          top: 50%;
          width: 25px;
          height: 25px;
          margin-top: -12.5px;
        }
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 17px;
        width: 14px;
        height: 14px;
        margin-top: -7px;
        background: ${hostingBaseStyleMap.accentColor};
        border-radius: 50%;
        opacity: 0;
        transition: 0.3s;
        @media screen and (min-width: 768px) {
          top: 50%;
          width: 19px;
          height: 19px;
          margin-top: -9.5px;
        }
      }
    `,
    labelText: css`
      width: calc(100% - 20px);
      padding: 10px 4% 10px 15%;
      @media screen and (min-width: 768px) {
        display: flex;
        padding: 14px 40px 14px 65px;
      }
      span {
        display: block;
        &:first-of-type {
          min-width: 75px;
          margin-right: 50px;
          font-size: 14px;
          @media screen and (min-width: 768px) {
            font-size: 18px;
          }
        }
        &:last-child {
          font-weight: bold;
          @media screen and (min-width: 768px) {
            font-size: 18px;
          }
        }
      }
    `,
    labelLink: css`
      display: flex;
      align-items: center;
      padding: 0 10px;
      background-color: ${hostingBaseStyleMap.accentColor}30;
      svg {
        fill: ${hostingBaseStyleMap.accentColor};
      }
    `,
  };
};
