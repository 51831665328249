// イベントページのビデオの状態を判定するためだけのユーティリティ関数
import type { EventVideo } from "@spwn/types/firebase/firestore";

export type VideoStatusType =
  | "BeforePlay" // 配信前
  | "Live" // 配信中
  | "ArchivePreparing" // アーカイブ配信準備中
  | "Archive" // アーカイブ配信中
  | "StreamingEnd" // 配信終了
  | "Hidden" // 非公開(イベントページには表示されない)
  | "NotPlayable"; // 視聴不可(イベントページに表示される)

type VideoContext = {
  canWatch: boolean;
  isOpenStreamingPage: boolean;
  video: EventVideo;
  now: Date;
};

// 基本的なビデオの状態を取得するためのヘルパー関数
const getVideoProperties = (video: EventVideo) => ({
  videoStartAtWith: video.startAt
    ? new Date(video.startAt.seconds * 1000)
    : undefined,
  videoEndAt: video.endAt ? new Date(video.endAt.seconds * 1000) : undefined,
  videoExpiredAt: video.expiredAt
    ? new Date(video.expiredAt.seconds * 1000)
    : undefined,
  isVideoOpen: video.isOpen ?? true,
  isVideoHasVOD: video.hasVOD ?? true,
  isVideoHide: video.isHide ?? false,
});

// 各状態判定のための純粋関数
const isHidden = ({ video }: VideoContext): boolean => {
  const { isVideoHide } = getVideoProperties(video);
  return isVideoHide;
};

const isStreamingEnd = ({ video, now }: VideoContext): boolean => {
  const { videoExpiredAt, videoEndAt, isVideoOpen, isVideoHasVOD } =
    getVideoProperties(video);
  // ビデオ個別にvideoExpiredAtが設定されている場合、その時間を過ぎたら自動的に配信終了
  if (videoExpiredAt) {
    return Boolean(now > videoExpiredAt);
  }
  // 従来の判定
  return Boolean(
    videoEndAt && now > videoEndAt && !isVideoOpen && !isVideoHasVOD
  );
};

const isBeforePlay = ({
  isOpenStreamingPage,
  canWatch,
}: VideoContext): boolean => {
  return !isOpenStreamingPage && canWatch;
};

const isArchivePreparing = ({
  video,
  now,
  canWatch,
}: VideoContext): boolean => {
  const { videoEndAt, isVideoOpen, isVideoHasVOD } = getVideoProperties(video);
  return Boolean(
    videoEndAt && now > videoEndAt && !isVideoOpen && isVideoHasVOD && canWatch
  );
};

const isArchive = ({ video, canWatch }: VideoContext): boolean => {
  const { isVideoOpen, isVideoHasVOD } = getVideoProperties(video);
  return isVideoOpen && isVideoHasVOD;
};

const isLive = ({ isOpenStreamingPage, video }: VideoContext): boolean => {
  const { isVideoOpen } = getVideoProperties(video);
  return isOpenStreamingPage && isVideoOpen;
};

type DetermineVideoStatusProps = {
  canWatch: boolean;
  isOpenStreamingPage: boolean;
  video: EventVideo;
};

// メインの状態判定関数
export const determineVideoStatus = ({
  canWatch,
  isOpenStreamingPage,
  video,
}: DetermineVideoStatusProps): VideoStatusType => {
  const context: VideoContext = {
    canWatch,
    isOpenStreamingPage,
    video,
    now: new Date(),
  };

  // 状態判定の優先順位に基づいて判定
  if (isHidden(context)) {
    return "Hidden";
  }

  if (isStreamingEnd(context)) {
    return "StreamingEnd";
  }

  if (isBeforePlay(context)) {
    return "BeforePlay";
  }

  if (isArchivePreparing(context)) {
    return "ArchivePreparing";
  }

  if (isArchive(context)) {
    return "Archive";
  }

  if (isLive(context)) {
    return "Live";
  }

  return "NotPlayable";
};
