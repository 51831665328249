import React from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import BorderLoader from "components/atoms/loader/BorderLoader";
import { useI18n } from "hooks/i18n/i18n";
import { useGetEventList } from "hooks/event/useGetEventList";
import { menuActions } from "modules/menu";
import { Store } from "store";
import { replaceBr } from "utility";

/**
 * サイドメニューの「今日のイベント」
 */
export const SideMenuTodayEvent: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useI18n();

  const myTickets = useSelector((state: Store) => state.ticket.myTickets);

  const { data: events } = useGetEventList({
    type: "today",
    limit: 1,
  });
  const todayEvent = events && events?.length > 0 ? events[0] : undefined;

  const isMyTicketLoading = myTickets === null;
  const myTicketEventIds = (myTickets ?? [])
    .map((el) => el.event._id)
    .filter((v) => !!v) as string[];
  const isHasTodayEventTicket =
    !!events &&
    events.some((event) => myTicketEventIds.includes(event?._id ?? ""));

  const handleMenuItemClick = (path: string) => {
    dispatch(push(path));
    dispatch(menuActions.toggleSideMenu());
  };

  if (!todayEvent) {
    return null;
  }

  return (
    <TodayEventContainer>
      <h2>{t("common.sidebar.todayEvent")}</h2>
      <p className="place">{todayEvent.placeInfo.placeText}</p>
      <p
        className="event"
        style={{ whiteSpace: "pre-line" }}
        onClick={() => handleMenuItemClick("/events/" + todayEvent._id)}
      >
        {replaceBr(todayEvent.title)}
        {todayEvent.artists && `(${replaceBr(todayEvent.artists)})`}
      </p>
      <p onClick={() => handleMenuItemClick("/events/" + todayEvent._id)}>
        <img src={todayEvent.defaultImg} alt={todayEvent.title} />
      </p>

      {isMyTicketLoading ? (
        <BorderLoader />
      ) : isHasTodayEventTicket ? (
        <p
          className="buttonForLink"
          onClick={() =>
            handleMenuItemClick("/account/ticket/" + todayEvent._id)
          }
        >
          {t("common.sidebar.showTicket")}
        </p>
      ) : (
        <p
          className="buttonForLink"
          onClick={() => handleMenuItemClick("/events/" + todayEvent._id)}
        >
          {t("common.sidebar.eventDetail")}
        </p>
      )}
    </TodayEventContainer>
  );
};

const TodayEventContainer = styled.div`
  padding: 5% 5% 40px;
  @media screen and (min-width: 768px) {
    padding: 20px 40px 40px;
  }
  h2 {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: normal;
    color: #fff;
  }
  .place {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 1px;
  }
  .event {
    margin-bottom: 20px;
    color: #05d2da;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1.3em;
  }
  img {
    width: 100%;
    margin-bottom: 20px;
  }
  .buttonForLink {
    display: block;
    padding: 12px 0;
    /* margin: 0 auto; */
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    /* line-height: 1.2em; */
    letter-spacing: 1px;
    background-color: #00c2ae;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      padding: 15px 0;
      letter-spacing: 2px;
    }
  }
`;
