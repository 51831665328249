import React from "react";
import { useI18n } from "hooks/i18n/i18n";
import { fontDinMixin, ModalHeader, Paw } from "../../../styles";
import styled from "@emotion/styled";

const ChargePaw = styled(Paw)`
  justify-content: center;
  margin: 0 auto;
  font-size: 30px;
  @media screen and (min-width: 768px) {
    margin-bottom: 5px;
    font-size: 40px;
    line-height: 1.5;
  }
  &:after {
    background-position: 50%;
    @media screen and (min-width: 768px) {
      width: 35px;
      height: 35px;
      margin-left: 5px;
    }
  }
`;

ChargePaw.displayName = "ChargePaw";

const PawInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 5%;
  border-top: 1px solid #e6e5e6;
  border-bottom: 1px solid #e6e5e6;
  .heading {
    font-size: 18px;
    font-weight: bold;
  }
  .pawNum {
    display: flex;
    align-items: center;
    ${fontDinMixin};
    font-size: 35px;
    @media screen and (min-width: 768px) {
      font-size: 40px;
    }
    &:after {
      content: "";
      display: block;
      width: 30px;
      height: 30px;
      margin-left: 5px;
      background-image: url("/images/icon_paw.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      @media screen and (min-width: 768px) {
        margin-left: 10px;
      }
    }
  }
`;

PawInfo.displayName = "PawInfo";

const PawCautionText = styled.p`
  padding: 10px 0;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid #e6e5e6;
  background-color: #fafafa;
  letter-spacing: 1px;
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
`;

PawCautionText.displayName = "PawCautionText";

const PawChargeList = styled.div`
  width: 90%;
  padding: 40px 0 70px;
  margin: 0 auto;
  .selectChargeAmount {
    margin-bottom: 20px;
    font-weight: bold;
    @media screen and (min-width: 768px) {
      margin: 0 auto 40px;
    }
  }
  .pawChargeList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 740px;
    .pawChargeItem {
      width: 48%;
      padding: 10px 0;
      margin-bottom: 20px;
      border: 1px solid #e6e5e6;
      @media screen and (min-width: 768px) {
        width: 31%;
        padding: 25px 0;
      }
      .price {
        width: 100px;
        padding: 5px 0;
        margin: 0 auto;
        color: #fff;
        font-size: 14px;
        text-align: center;
        font-weight: 700;
        letter-spacing: 1px;
        background-color: #00c2ae;
        cursor: pointer;
        @media screen and (min-width: 768px) {
          width: 83%;
          padding: 8px 0;
          font-size: 20px;
        }
      }
    }
  }
  .closeModal {
    margin-top: 30px;
    text-align: center;
    span {
      color: #7c7c7c;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

PawChargeList.displayName = "PawChargeList";

interface Props {
  pawBalanceNum: string | 0;
  currentPageState: string;
  toggleModal: () => void;
  setCurrentPageState: (value: React.SetStateAction<string>) => void;
  setSelectedPaw: (value: React.SetStateAction<[number, string]>) => void;
}

const SelectPaw = (props: Props) => {
  const { t } = useI18n();

  const selectPurchasePaw = (price: number, pawId: string) => {
    props.setSelectedPaw([price, pawId]);
    props.setCurrentPageState("inputPaymentMethods");
  };

  return (
    <div id="selectPaw">
      <ModalHeader>
        <div className="modalHeader">
          <p className="back"></p>
          <p className="heading">{t("pawCharge.title")}</p>
          <p className="close" onClick={props.toggleModal}>
            ×
          </p>
        </div>

        <PawInfo>
          <p className="heading">{t("pawCharge.ownedPAW")}</p>
          <p className="pawNum">{props.pawBalanceNum}</p>
        </PawInfo>

        <PawCautionText>{t("pawCharge.note1")}</PawCautionText>
      </ModalHeader>

      <PawChargeList>
        <p className="selectChargeAmount">{t("pawCharge.note2")}</p>

        <div className="pawChargeList">
          <div className="pawChargeItem">
            <ChargePaw>500</ChargePaw>
            <div
              className="price"
              onClick={() => {
                selectPurchasePaw(500, "P500v1");
              }}
            >
              ¥500
            </div>
          </div>
          <div className="pawChargeItem">
            <ChargePaw>1,000</ChargePaw>
            <div
              className="price"
              onClick={() => {
                selectPurchasePaw(1000, "P1000v1");
              }}
            >
              ¥1,000
            </div>
          </div>
          <div className="pawChargeItem">
            <ChargePaw>3,000</ChargePaw>
            <div
              className="price"
              onClick={() => {
                selectPurchasePaw(3000, "P3000v1");
              }}
            >
              ¥3,000
            </div>
          </div>
          <div className="pawChargeItem">
            <ChargePaw>5,000</ChargePaw>
            <div
              className="price"
              onClick={() => {
                selectPurchasePaw(5000, "P5000v1");
              }}
            >
              ¥5,000
            </div>
          </div>
          <div className="pawChargeItem">
            <ChargePaw>10,000</ChargePaw>
            <div
              className="price"
              onClick={() => {
                selectPurchasePaw(10000, "P10000v1");
              }}
            >
              ¥10,000
            </div>
          </div>
          <div className="pawChargeItem">
            <ChargePaw>30,000</ChargePaw>
            <div
              className="price"
              onClick={() => {
                selectPurchasePaw(30000, "P30000v1");
              }}
            >
              ¥30,000
            </div>
          </div>
        </div>

        <p className="closeModal">
          <span onClick={props.toggleModal}>{t("pawCharge.close")}</span>
        </p>
      </PawChargeList>
    </div>
  );
};
export default SelectPaw;
