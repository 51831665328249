/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { PortalTopFanclubSliderSP } from "./PortalTopFanclubSliderSP";
import { PortalTopFanclubSlider } from "./PortalTopFanclubSlider";
import { useGetPortalTopFanclub } from "./useGetPortalTopFanclub";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

export const PortalTopFanclubFetcher: FC = () => {
  const { fanclub } = useGetPortalTopFanclub();

  if (!fanclub) return null;

  return <PortalTopFanclub brandList={fanclub} />;
};

export type PortalTopFanclubProps = {
  brandList: {
    thumbnail: string;
    brandName: string;
    brandSlug: string;
  }[];
};

export const PortalTopFanclub: FC<PortalTopFanclubProps> = ({ brandList }) => {
  const href = (() => {
    switch (process.env.NODE_ENV) {
      case "production":
        return "http://crew.spwn.jp/about/";
      default:
        return "http://crew-dev.spwn.jp/about";
    }
  })();
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  // PCとSPで挙動が違うのでコンポーネントを分ける。
  // PCの場合は6つ表示して、矢印を押すと次の1つが表示される。自動スクロールはなし。
  // SPの場合は6つのカードを表示する。スワイプで次のカードが表示される。自動スクロールはなし。
  if (isDesktop) {
    return (
      <PortalTopFanclubLayout href={href}>
        <PortalTopFanclubSlider brandList={brandList} />
      </PortalTopFanclubLayout>
    );
  }

  return (
    <PortalTopFanclubLayout href={href}>
      <PortalTopFanclubSliderSP brandList={brandList} />
    </PortalTopFanclubLayout>
  );
};

const PortalTopFanclubLayout: FC<{
  children: JSX.Element;
  href: string;
}> = ({ children, href }) => {
  return (
    <Box>
      <Box marginBottom={"20px"}>{children}</Box>
      <PortalTopLinkButton href={href}>くわしく知る</PortalTopLinkButton>
    </Box>
  );
};

const PortalTopLinkButton: FC<{
  children: string;
  href: string;
}> = ({ children, href }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
  const padding = isDesktop ? "16px 0" : "10px 0";

  return (
    <Link
      href={href}
      underline={"none"}
      style={{
        display: "block",
        width: "90%",
        maxWidth: "880px",
        padding,
        margin: "0 auto",
        color: "#fff",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "1.5em",
        background: "#00c2ad",
        cursor: "pointer",
      }}
    >
      {children}
    </Link>
  );
};
