/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "store";
import PlayButton from "components/event/detail/videoList/PlayButton";
import { convertMapToValues, replaceBr } from "utility";
import LiveAnimation from "components/event/detail/videoList/LiveAnimation";
import { useI18n } from "hooks/i18n/i18n";
import { canWatchVideo } from "utility/streaming";
import { modalActions } from "modules/modal";

const EventVideoList: React.FC = () => {
  const _pathname = useSelector(
    (state: Store) => state.router.location.pathname
  );
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const eventVideoMap = useSelector(
    (state: Store) => state.streaming.eventVideoMap ?? {}
  );
  const streamingKey = useSelector(
    (state: Store) => state.streaming.streamingKey
  );
  const isLogined = useSelector((state: Store) => state.auth.user.uid);
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);

  const dispatch = useDispatch();

  const { t } = useI18n();
  const classes = styles();

  const goLive = (vid: string) => {
    dispatch(
      push(`/events/${displayEvent.eventId}/streaming?vid=${vid}`, { vid })
    );
  };

  const eventVideos = convertMapToValues(eventVideoMap).sort(
    (a, b) => a.priority - b.priority
  );

  const isOpenLiveStreamingPage =
    displayEvent.isOpenStreamingPage && !displayEvent.isClosed;

  const buttonActionText = (isOpen: boolean, canWatch: boolean) => {
    const purchaseText = canWatch ? "" : t("event.archiveVideo.actionToWatch");
    const joinText = isOpenLiveStreamingPage
      ? `${purchaseText}${t("event.archiveVideo.joinLive")}`
      : `${purchaseText}${t("event.archiveVideo.watchLive")}`;
    return canWatch
      ? displayEvent.isOpenStreamingPage && isOpen
        ? joinText
        : t("event.archiveVideo.beforeLive")
      : displayEvent.eventSaleStatus === "ON_SALE"
      ? joinText
      : t("event.archiveVideo.outsideSalesPeriod");
  };

  const handleButtonAction = (
    videoId: string,
    isOpen: boolean,
    canWatch: boolean
  ) => {
    if (!isLogined) {
      dispatch(modalActions.toggleLoginMethod());
      return;
    }
    if (canWatch) {
      ((displayEvent.isOpenStreamingPage && isOpen) || isAdmin) &&
        goLive(videoId);
      return;
    }
    if (displayEvent.eventSaleStatus === "ON_SALE") {
      if (displayEvent.ticketUrl) {
        // if ticket purchase is external site, open its site
        // @ts-expect-error TS2345
        window.open(displayEvent.ticketUrl, null, "noopener");
        return;
      }
      // go to purchase
      dispatch(push(`/events/${displayEvent.eventId}/ticket`));
      return;
    }
    return;
  };

  const buttonClassName = (isOpen: boolean, canWatch: boolean) => {
    return canWatch
      ? isOpenLiveStreamingPage && isOpen
        ? ""
        : "disabled"
      : displayEvent.eventSaleStatus === "ON_SALE"
      ? ""
      : "disabled";
  };

  return (
    <div css={classes.root}>
      {eventVideos
        .filter(
          (video) =>
            !video.appViewingType ||
            (video.appViewingType && video.appViewingType !== "viewerOnly")
        )
        .map((video, i) => {
          const isOpen = video.isOpen === undefined || video.isOpen;
          // check if user purchase video ticket
          // @ts-expect-error TS2345
          const canWatch = canWatchVideo(streamingKey, video._id);
          return (
            <div key={i} css={classes.videoItem}>
              <div css={classes.thumbnailArea}>
                <img
                  src={video.thumbnail || displayEvent.thumbnail}
                  alt={video.name || displayEvent.title}
                />
                {canWatch && displayEvent.isOpenStreamingPage && isOpen && (
                  // @ts-expect-error TS2345
                  <PlayButton action={() => goLive(video._id)} />
                )}
                {isOpenLiveStreamingPage && isOpen && <LiveAnimation />}
              </div>
              <div css={classes.statusArea}>
                <div css={classes.status}>
                  {video.name && (
                    <p className="ticket">{replaceBr(video.name)}</p>
                  )}
                  {canWatch && (
                    <p className="price">{t("event.archiveVideo.purchased")}</p>
                  )}
                </div>

                {/* {
                  //TODO: マーダーミステリー後に元に戻す 後ほど修正する用の差分memo
                  pathname.indexOf("-murdermystery") !== -1 ? (
                    canWatch ? (
                      <p
                        css={classes.button}
                        onClick={() =>
                          handleButtonAction(video._id, isOpen, canWatch)
                        }
                        className={buttonClassName(isOpen, canWatch)}
                      >
                        {buttonActionText(isOpen, canWatch)}
                      </p>
                    ) : null
                  ) : (
                    <p
                      css={classes.button}
                      onClick={() =>
                        handleButtonAction(video._id, isOpen, canWatch)
                      }
                      className={buttonClassName(isOpen, canWatch)}
                    >
                      {buttonActionText(isOpen, canWatch)}
                    </p>
                  )
                } */}
                <p
                  css={classes.button}
                  onClick={() =>
                    // @ts-expect-error TS2345
                    handleButtonAction(video._id, isOpen, canWatch)
                  }
                  className={buttonClassName(isOpen, canWatch)}
                >
                  {buttonActionText(isOpen, canWatch)}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default EventVideoList;

const styles = () => {
  return {
    root: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &::after {
        @media screen and (min-width: 768px) {
          content: "";
          display: block;
          width: 31%;
        }
      }
    `,
    videoItem: css`
      width: 100%;
      border: 1px solid #d4d4d4;
      @media screen and (min-width: 768px) {
        width: 31%;
        margin-bottom: 20px;
      }
    `,
    thumbnailArea: css`
      position: relative;
      img {
        width: 100%;
      }
    `,
    statusArea: css`
      padding: 15px;
      a:not(:last-child) {
        margin-bottom: 10px;
      }
    `,
    status: css`
      /* display: flex;
      justify-content: center;
      align-items: center; */
      min-height: 70px;
      margin-bottom: 10px;
      .ticket {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        line-height: 1.5em;
        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }
      .price {
        font-size: 16px;
        font-weight: bold;
        text-align: right;
        line-height: 1.5em;
      }
      .text {
        margin-top: 20px;
        color: #f00;
        font-size: 14px;
        line-height: 1.3em;
      }
    `,
    button: css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 8px 0;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
      line-height: 24px;
      letter-spacing: 1px;
      background-color: #ff0076;
      transition: 0.3s;
      cursor: pointer;
      &.disabled {
        background-color: #d4d4d4;
      }
      &:hover {
        opacity: 0.6;
      }
    `,
  };
};
