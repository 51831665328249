/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";

import type { Event } from "@spwn/types/firebase/firestore";
import { useI18n } from "hooks/i18n/i18n";
import { useGetEventList } from "hooks/event/useGetEventList";
import { PortalTopLinkButton } from "./PortalTopLinkButton";
import { PortalTopEventCard } from "./PortalTopEventCard";
import { EventInfo, getEventDisplayInfo, isSmartPhone } from "utility";
import { getSalesState } from "utility/event";
import { PortalTopEventLargeCard } from "./PortalTopEventLargeCard";
import { PortalTopSectionLayout } from "./PortalTop";

export const PortalTopUpcomingEventsFetcher: FC<{}> = () => {
  const { data: events, isLoading } = useGetEventList({
    type: "openComing",
    limit: 5,
  });

  return <PortalTopUpcomingEventsLogic events={events} isLoading={isLoading} />;
};

const PortalTopUpcomingEventsLogic: FC<{
  events?: Event[];
  isLoading: boolean;
}> = ({ events, isLoading }) => {
  const displayEvents = (events ?? []).map((event) =>
    getEventDisplayInfo(event)
  );

  return (
    <PortalTopUpcomingEvents
      displayEvents={displayEvents}
      isLoading={isLoading}
    />
  );
};

export const PortalTopUpcomingEvents: FC<{
  displayEvents: EventInfo[];
  isLoading: boolean;
}> = ({ displayEvents, isLoading }) => {
  const classes = styles();
  const { t } = useI18n();

  if (displayEvents.length === 0 && !isLoading) {
    return (
      <section>
        <div css={classes.noEvent}>{t("event.noEvent.noEventTop")}</div>
      </section>
    );
  }

  const largeEvents = displayEvents.slice(0, 2);
  const smallEvents = isSmartPhone()
    ? displayEvents.slice(2, 6)
    : displayEvents.slice(2, 5);

  return (
    <PortalTopSectionLayout
      heading={{
        mainText: "UPCOMING EVENT",
        subText: t("event.top.upcomingEvent"),
      }}
    >
      <section>
        <div css={classes.layout}>
          {/* 大きい2件のイベントを表示する */}
          <div css={classes.largeEvent}>
            {largeEvents.map((event, index) => {
              const {
                eventId,
                eventTitle,
                thumbnail,
                eventOpenDate,
                eventOpenDay,
                eventOpenTime,
                eventSaleStatus,
                goodsSaleStatus,
              } = event;

              const { label, color } = getSalesState(
                eventSaleStatus,
                goodsSaleStatus
              );

              return (
                <React.Fragment key={`${eventId}_${index}`}>
                  <PortalTopEventLargeCard
                    href={`/events/${eventId}`}
                    // @ts-expect-error TS2322
                    tag={label}
                    // @ts-expect-error TS2322
                    tagColor={color}
                    thumbnail={thumbnail}
                    date={`${eventOpenDate}(${eventOpenDay})`}
                    time={eventOpenTime}
                    title={eventTitle}
                  />
                </React.Fragment>
              );
            })}
          </div>

          {/* 小さい3件のイベントを表示する */}
          <div css={classes.smallEvent}>
            {smallEvents.map((event, index) => {
              const {
                eventId,
                eventTitle,
                thumbnail,
                eventOpenDate,
                eventOpenDay,
                eventOpenTime,
                eventSaleStatus,
                goodsSaleStatus,
              } = getEventDisplayInfo(event);

              const { label, color } = getSalesState(
                eventSaleStatus,
                goodsSaleStatus
              );

              return (
                <React.Fragment key={`${eventId}_${index}`}>
                  <PortalTopEventCard
                    href={`/events/${eventId}`}
                    // @ts-expect-error TS2322
                    tag={label}
                    // @ts-expect-error TS2322
                    tagColor={color}
                    thumbnail={thumbnail}
                    date={`${eventOpenDate}(${eventOpenDay})`}
                    time={eventOpenTime}
                    title={eventTitle}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>

        <PortalTopLinkButton
          href={`/events`}
          label={t("event.top.eventList")}
        />
      </section>
    </PortalTopSectionLayout>
  );
};

const styles = () => {
  return {
    layout: css`
      margin-bottom: 20px;
    `,
    largeEvent: css`
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        gap: 40px;
      }
    `,
    smallEvent: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 28px;
      }
    `,
    noEvent: css`
      color: #888888;
      font-size: 20px;
      text-align: center;
      padding: 120px 0;
      @media screen and (min-width: 768px) {
        font-size: 24px;
      }
    `,
  };
};
