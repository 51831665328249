/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useCallback } from "react";

export const PreviewHeader: React.FC = () => {
  const classes = styles();

  const handlePreviewAction = useCallback(() => {
    window.close();
  }, []);

  return (
    <section css={classes.root}>
      <div css={classes.message}>
        <p>この画面はプレビューです。</p>
      </div>

      {/* 終了する動作がonClickかリンクでの遷移か不明だったので、一旦onClickで作成 */}
      <div css={classes.button} onClick={handlePreviewAction}>
        <p>プレビューを終了</p>
      </div>
    </section>
  );
};

const styles = () => {
  return {
    root: css`
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      padding: 19px 24px;
      background-color: #00c2ad;
      z-index: 1000;
      @media screen and (max-width: 767px) {
        display: grid;
        grid-template-columns: auto max-content;
        padding: 8px 2.5%;
      }
    `,
    button: css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      color: #00c2ad;
      font-size: 14px;
      font-weight: bold;
      background-color: #fff;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        position: absolute;
        top: 8px;
        right: 24px;
        padding: 12px 32px;
      }
    `,
    message: css`
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    `,
  };
};
