/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

interface Props {
  title: string;
  subTitle: string;
}
export const BottomBorderHeadline: React.FC<Props> = (props) => {
  const classes = styles();

  return (
    <div css={classes.root}>
      <div css={classes.layout}>
        <h2>{props.title}</h2>
        <p>{props.subTitle}</p>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
    `,
    layout: css`
      padding: 8px 16px;
      font-family: "DIN Alternate", sans-serif;
      font-weight: 400;
      font-style: normal;
      text-align: center;
      background-color: #fff82e;
      h2 {
        font-size: 28px;
        letter-spacing: 4px;
        &::after {
          content: "";
          display: block;
          width: 24px;
          height: 4px;
          margin: 8px auto 12px;
          background-color: rgb(1, 190, 168);
        }
      }
      p {
        font-size: 14px;
      }
    `,
  };
};
