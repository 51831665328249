/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { TIMETABLE_CELL_HEIGHT } from "../../../../containers/event/eventDetail/EventDetailTimetable";

/**
 * 時間軸のグリッド線を作成します。
 * EventTimetableLayoutTimeAxisと同じ値を渡す
 * @param {number} startAt イベントの開始時間です。
 * @param {number} betweenAt イベントの開催期間です。
 */
interface Props {
  startAt: number;
  betweenAt: number;
}
export const EventTimetableLayoutGridLine: React.FC<Props> = (props) => {
  const classes = styles();

  return (
    <div css={classes.root} className="background_grid_contents">
      <div className="day">
        <div className="hour">
          <div></div>
        </div>
        {Array(props.betweenAt)
          .fill("")
          .map((_, index) => {
            return (
              <div className="hour" key={index}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            );
          })}
        <div className="hour">
          <div></div>
        </div>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      .day {
        width: 100%;
      }
      .hour {
        &:not(:first-child) {
          border-top: 1px solid #a1a1a1;
        }
        div {
          height: ${TIMETABLE_CELL_HEIGHT}px;
          &:not(:last-child) {
            border-bottom: 1px solid #a1a1a1;
          }
        }
      }
    `,
  };
};
