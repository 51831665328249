/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { FullScreenDialog } from "components/common/Modal";
import { modalActions } from "modules/modal";
import { getCardTokenGMO } from "utility/gmo";
import { purchaseActions } from "modules/purchase";
import { DialogStyles } from "components/Styled";
import styled from "@emotion/styled";
import { PopupWindow, LinkButton, PopupWindowButtonList } from "../../styles";
import { useI18n } from "hooks/i18n/i18n";
import { useForm, UseFormRegisterReturn } from "react-hook-form";

type CardInputInfo = {
  cardNumber: string;
  cardExpireMonth: string;
  cardExpireYear: string;
  cardHolderName: string;
  cardSecurityCode: string;
};

const overrideMixin = css`
  .form {
    margin: 0 auto 20px;
  }
`;

const RegisterPopupWindow = styled(PopupWindow)(overrideMixin);

export const RegisterCard: FC = () => {
  const { t } = useI18n();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<CardInputInfo>();

  const isOpen = useSelector(
    (state: Store) => state.purchase.isOpenRegisterCard
  );

  const closeModal = useCallback(() => {
    reset();
    dispatch(purchaseActions.toggleRegisterCard());
  }, [reset, dispatch]);

  const registerNewCard = useCallback(() => {
    getCardTokenGMO({
      cardNo: getValues("cardNumber"),
      expire:
        getValues("cardExpireYear").toString() +
        getValues("cardExpireMonth").toString(),
      securityCode: getValues("cardSecurityCode"),
      holderName: getValues("cardHolderName"),
      tokenNumber: 1,
      // @ts-expect-error TS7008
    }).then((response: { isError: boolean; tokens }) => {
      if (
        response.tokens === undefined ||
        response.tokens === null ||
        response.tokens.length === 0
      ) {
        dispatch(
          modalActions.toggleError({
            msg: t("accountCredit.modal.registerNewCard.error"),
          })
        );
        return;
      }
      closeModal();
      dispatch(
        purchaseActions.registerCardInfo.started({ token: response.tokens[0] })
      );
    });

    // 仮データメモ
    // getCardTokenGMO({
    //   cardNo: "4111111111111111",
    //   expire: "2012",
    //   holderName: `TEST${String(Math.random()).slice(2,10)}`,
    //   securityCode: 123,
    //   tokenNumber: 1
    // }).then((response: {isError: boolean, tokens:any})=>{
    //   this.props.registerCardInfo({token: response.tokens[0]})
    // });
  }, [closeModal, dispatch, getValues, t]);

  return (
    <FullScreenDialog
      style={DialogStyles}
      open={isOpen}
      onBackdropClick={closeModal}
    >
      <RegisterPopupWindow>
        <form onSubmit={handleSubmit(registerNewCard)}>
          <div className="popup_window_layout">
            <h2>{t("accountCredit.modal.title")}</h2>

            <div className="form">
              <p>{t("accountCredit.modal.creditCardNumber")}</p>
              <InputTextForm
                inputType={"text"}
                inputName={"cardNumber"}
                register={register("cardNumber", { required: true })}
              />

              {errors.cardNumber && (
                <ErrorMessage message={"入力が必須の項目です"} />
              )}
            </div>

            <div className="credit_datetime form">
              <p>{t("accountCredit.modal.expirationDate")}</p>

              <div
                css={css`
                  input[name="cardExpireMonth"],
                  input[name="cardExpireYear"] {
                    width: 80px !important;
                    margin-right: 16px;
                  }
                `}
              >
                <InputTextForm
                  inputType={"text"}
                  inputName={"cardExpireMonth"}
                  register={register("cardExpireMonth", { required: true })}
                />
                <InputTextForm
                  inputType={"text"}
                  inputName={"cardExpireYear"}
                  register={register("cardExpireYear", { required: true })}
                />
              </div>

              {(errors.cardExpireMonth || errors.cardExpireYear) && (
                <ErrorMessage message={"入力が必須の項目です"} />
              )}
            </div>

            <div className="form">
              <p>{t("accountCredit.modal.creditCardHolder")}</p>
              <InputTextForm
                inputType={"text"}
                inputName={"cardHolderName"}
                register={register("cardHolderName", {
                  required: true,
                  pattern: /^[a-zA-Z\s]*$/i,
                })}
              />

              {errors.cardHolderName?.type === "required" && (
                <ErrorMessage message={"入力が必須の項目です"} />
              )}
              {errors.cardHolderName?.type === "pattern" && (
                <ErrorMessage message={"半角英で入力してください。"} />
              )}
            </div>

            <div
              className="form"
              css={css`
                input[name="cardSecurityCode"] {
                  width: 45px !important;
                }
              `}
            >
              <p>{t("accountCredit.modal.securityCode")}</p>
              <InputTextForm
                inputType={"password"}
                inputName={"cardSecurityCode"}
                register={register("cardSecurityCode", { required: true })}
              />

              {errors.cardSecurityCode && (
                <ErrorMessage message={"入力が必須の項目です"} />
              )}
            </div>

            <PopupWindowButtonList>
              <SubmitButton
                label={t("accountCredit.modal.registerCreditCard")}
              />

              <LinkButton className="cancel" onClick={closeModal}>
                {t("accountCredit.modal.cancel")}
              </LinkButton>
            </PopupWindowButtonList>
          </div>
        </form>
      </RegisterPopupWindow>
    </FullScreenDialog>
  );
};

const InputTextForm: FC<{
  inputType: "text" | "password";
  inputName:
    | "cardNumber"
    | "cardHolderName"
    | "cardSecurityCode"
    | "cardExpireMonth"
    | "cardExpireYear";
  register?: UseFormRegisterReturn;
}> = ({ inputType, inputName, register }) => {
  return (
    <input
      type={inputType}
      name={inputName}
      autoComplete={"off"}
      {...register}
    />
  );
};

const ErrorMessage: FC<{
  message: string;
}> = ({ message }) => {
  return (
    <p
      css={css`
        color: red;
        font-size: 12px !important;
        margin-top: 8px;
      `}
    >
      {message}
    </p>
  );
};

const SubmitButton: FC<{
  label: string;
}> = ({ label }) => {
  return (
    <button
      type={"submit"}
      css={css`
        display: block;
        width: 100%;
        padding: 10px 0;
        margin-top: 0;
        margin-right: auto;
        margin-left: auto;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        line-height: 1.2em;
        letter-spacing: 1px;
        background-color: #00c2ae;
        border: 2px solid #00c2ae;
        cursor: pointer;
        @media (768px <= width) {
          padding: 13px 0;
          font-size: 15px;
          letter-spacing: 2px;
        }
      `}
    >
      {label}
    </button>
  );
};
