/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useI18n } from "hooks/i18n/i18n";

export const GoodsVenueSelector: React.FC<{
  selectedPlaceCode: string;
  venues: { name: string; placeCode: string }[];
  setSelectedPlaceCode: (value: string) => void;
}> = (props) => {
  const classes = styles();
  const { t } = useI18n();
  return (
    <div css={classes.root}>
      <FormControl>
        <InputLabel id="demo-simple-select-label">
          {t("goods.placeSelector")}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          css={classes.select}
          value={props.selectedPlaceCode}
          onChange={(e) => props.setSelectedPlaceCode(e.target.value as string)}
        >
          {props.venues.map((venue, i) => (
            <MenuItem key={i} value={venue.placeCode}>
              {venue.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const styles = () => ({
  root: css`
    margin-bottom: 10px;
    margin-left: 20px;
    @media screen and (min-width: 768px) {
      margin-left: 0;
    }
  `,
  select: css`
    min-width: 200px;
  `,
});
