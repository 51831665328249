/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

interface Props {}
export const SubContents: React.FC<Props> = (props) => {
  const classesSubContentsStyles = subContentsStyles();
  return (
    <main id="sub-contents" css={classesSubContentsStyles.root}>
      {props.children}
    </main>
  );
};

const subContentsStyles = () => {
  return {
    root: css`
      min-height: calc(100vh - 65px - 190px);
      background-color: #fff;
    `,
  };
};
