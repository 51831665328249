/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import React, { useEffect, useState } from "react";
import { sanitizer } from "../../../utility/sanitize";
import { getUrlWithUpdateVersion } from "../../../utility";
import { ArtistDataJson } from "../../../modules/event";

import { changeStyleWithHosting } from "utility/hosting";
import { useI18n } from "hooks/i18n/i18n";

import label_act_pc from "designs/images/label_act_pc.svg";
import label_act_sp from "designs/images/label_act_sp.svg";
import { fetchFirestoreDocument } from "utility/firebase";
import appConfig from "constants/appConfig";
import styled from "@emotion/styled";
import { LeftBorderHeadline } from "../../../components/atoms/headline/LeftBorderHeadline";
import { useQueryString } from "hooks/QueryString";
import type { Event } from "@spwn/types/firebase/firestore";

interface Props {
  // TODO(takahashi): firestore 移行完了時に artistsData を消す
  artistsData: ArtistDataJson;
  eventId: string;
}

const Container = styled.div`
  .modalArtistDetail {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    padding: 60px 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    z-index: 910;
    &::-webkit-scrollbar {
      display: none;
    }
    .touchCloseArea {
      position: fixed;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      z-index: 920;
    }
    .artistDetailLayout {
      position: relative;
      max-width: 920px;
      width: 90%;
      margin: 0 auto;
      background-color: #fff;
      z-index: 930;
      .closingButton {
        position: absolute;
        top: -45px;
        right: 0;
        color: #fff;
        font-size: 35px;
        font-weight: bold;
        cursor: pointer;
      }
      .artistDetail {
        padding: 30px 5% 40px;
        @media screen and (min-width: 768px) {
          padding: 60px 100px 80px;
        }
        .artistThumbnail {
          display: block;
          width: 90%;
          margin: 0 auto 20px;
          @media screen and (min-width: 768px) {
            width: 420px;
            margin: 0 auto 40px;
          }
        }
        .artistName {
          margin-bottom: 20px;
          font-size: 20px;
          font-weight: bold;
          line-height: 1.3em;
          text-align: center;
          @media screen and (min-width: 768px) {
            margin-bottom: 40px;
            font-size: 25px;
          }
        }
        .artistDescription {
          padding-bottom: 40px;
          margin-bottom: 20px;
          font-size: 14px;
          line-height: 1.5em;
          border-bottom: 1px solid #e0e0e0;
          @media screen and (min-width: 768px) {
            padding-bottom: 60px;
            margin-bottom: 40px;
            font-size: 16px;
          }
        }
        .artistSnsListLayout {
          margin-bottom: 60px;
          @media screen and (min-width: 768px) {
            margin-bottom: 120px;
          }
          .heading {
            margin-bottom: 20px;
            text-align: center;
            font-weight: bold;
            @media screen and (min-width: 768px) {
              margin-bottom: 40px;
            }
          }
          .artistSnsList {
            li {
              margin-bottom: 10px;
              font-size: 14px;
              @media screen and (min-width: 768px) {
                font-size: 16px;
              }
              a {
                color: #00c5cb;
              }
            }
          }
        }
        .closeArtistDetail {
          color: #757575;
          text-align: center;
          cursor: pointer;
          &:before {
            content: "×";
            display: inline;
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

const EventDetailArtist: React.FC<Props> = (props) => {
  // @ts-expect-error TS2345
  const [selectArtistName, setSelectArtistName] = useState<string>(null);
  // @ts-expect-error TS2345
  const [artistsData, setArtistsData] = useState<ArtistDataJson>(null);

  const { preview: _ } = useQueryString<{ preview: "0" | "1" }>();

  useEffect(() => {
    (async () => {
      const event = await fetchFirestoreDocument<Event>(
        `/events/${props.eventId}`
      );

      if (!event) return;

      // TODO(takahashi): firestore 移行完了時に if 文全体を消す
      if (!("artistRefs" in event)) {
        setArtistsData({ artists: props.artistsData.artists });
        return;
      }

      const artists = (
        await Promise.all(
          // @ts-expect-error TS18048
          event.artistRefs.map(async (ref) => {
            const snapshot = await ref.get();
            const artistData = snapshot.data();
            if (!artistData) return;
            // TODO(takahashi): withConverter などを使って変換するようにしたい
            return {
              name: artistData.name,
              act_type: artistData.actType,
              description: artistData.description,
              img_path: getUrlWithUpdateVersion(
                `${appConfig.publicStorageDomain}${artistData.imgPath}`
              ),
              links: artistData.links,
            };
          })
        )
      ).filter((artist) => artist);
      // @ts-expect-error TS2322
      setArtistsData({ artists });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hostingArtistInfoMap =
    changeStyleWithHosting().pageSettings.eventPage.artistInfo;
  const classesActInfoDesign = actInfoDesign();

  // const displayAbbreviatedSentences = (text) => {
  //   const dataAfterSubstitution = removeBr(text);
  //   const displayTextLength = isSmartPhone() ? 35 : 80;

  //   if (dataAfterSubstitution.length <= displayTextLength) {
  //     return dataAfterSubstitution;
  //   }
  //   return dataAfterSubstitution.slice(0, displayTextLength) + "...";
  // };

  const closeArtistModal = () => {
    // @ts-expect-error TS2345
    setSelectArtistName(null);
  };

  if (artistsData === null || artistsData.artists.length === 0) {
    return null;
  }

  /**
   * render logic
   */
  return (
    <Container
      id="act_info"
      className="castInformationLayout"
      css={classesActInfoDesign.root}
    >
      {/* TODO: タイムテーブルが存在する時は背景を白、存在しない時は黒にする */}
      {/* TODO: タイムテーブルが存在する時は文字色を黒、存在しない時はhosting.jsonの指定の色にする */}
      {hostingArtistInfoMap.headline.isDisplay && (
        <div css={classesActInfoDesign.heading}>
          <LeftBorderHeadline
            title={hostingArtistInfoMap.headline.text}
            subTitle={hostingArtistInfoMap.headline.subText}
            fontColor={"secondary"}
          />
        </div>
      )}

      <div css={classesActInfoDesign.castInfoLayout}>
        {artistsData.artists.map((artist, i) => {
          return (
            <article
              key={i}
              css={classesActInfoDesign.castInfoItem}
              onClick={() => {
                setSelectArtistName(artist.name);
              }}
            >
              <div css={classesActInfoDesign.castInfoImage}>
                <img src={artist.img_path} alt={artist.name} />
                {hostingArtistInfoMap.isDisplayArtistStateLabel &&
                  artist.act_type && <p>{artist.act_type}</p>}
              </div>
              <div css={classesActInfoDesign.castInfoText}>
                <h3>{artist.name}</h3>
                {/*<p>{displayAbbreviatedSentences(artist.description)}</p>*/}
              </div>
              <p css={classesActInfoDesign.castInfoLink}>{`>>>`}</p>
            </article>
          );
        })}
      </div>

      {selectArtistName ? (
        <ShowArtistDetail
          artistsData={artistsData}
          selectArtistName={selectArtistName}
          closeArtistModal={closeArtistModal}
        />
      ) : null}
    </Container>
  );
};
export default EventDetailArtist;

const actInfoDesign = () => {
  const hostingBaseStyleMap = changeStyleWithHosting().baseStyles;
  // const textColor = hostingBaseStyleMap.fontPrimaryColor;

  return {
    root: css``,
    heading: css`
      margin-bottom: 40px;
      @media screen and (min-width: 768px) {
        margin-bottom: 60px;
      }
    `,
    castInfoLayout: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &::before {
        @media screen and (min-width: 768px) {
          content: "";
          display: block;
          width: 24%;
          order: 1;
        }
      }
      &::after {
        @media screen and (min-width: 768px) {
          content: "";
          display: block;
          width: 24%;
        }
      }
    `,
    castInfoItem: css`
      position: relative;
      width: 47.5%;
      margin-bottom: 40px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        width: 24%;
        margin-bottom: 60px;
      }
    `,
    castInfoImage: css`
      position: relative;
      img {
        width: 100%;
        object-fit: cover;
        object-position: 50% 0%;
        border-radius: 5px 5px 0 0;
      }
      p {
        position: absolute;
        top: -20px;
        left: -8px;
        width: 65px;
        height: 42px;
        padding-top: 9px;
        font-family: "din-condensed", sans-serif;
        font-weight: 400;
        font-style: normal;
        color: #01ffe1;
        font-size: 16px;
        text-align: center;
        background-image: url(${label_act_sp});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        @media screen and (min-width: 768px) {
          width: 100px;
          height: 48px;
          padding-top: 12px;
          font-size: 20px;
          background-image: url(${label_act_pc});
        }
      }
    `,
    castInfoText: css`
      padding: 16px 16px 40px;
      h3 {
        font-size: 15px;
        text-align: center;
        line-height: 1.4em;
        white-space: pre-line;
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
      }
      p {
        font-size: 10px;
        line-height: 1.5em;
        text-align: justify;
        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }
    `,
    castInfoLink: css`
      position: absolute;
      bottom: 10px;
      right: 7%;
      color: ${hostingBaseStyleMap.accentColor}40;
      font-weight: bold;
      font-size: 20px;
      @media screen and (min-width: 768px) {
        right: 20px;
      }
    `,
  };
};

// @ts-expect-error TS7006
const hasLinks = (artist) => {
  // 空の links は下の構造をしている
  // "links": [{ "name": "", "url": "" }]

  if (artist.links.length === 0) {
    return false;
  }

  const [link] = artist.links;
  return !(artist.links.length === 1 && link.name === "" && link.url === "");
};

interface ShowArtistDetailProps {
  artistsData: ArtistDataJson;
  selectArtistName: string;
  // @ts-expect-error TS7008
  closeArtistModal;
}

const ShowArtistDetail = (props: ShowArtistDetailProps) => {
  const [selectArtistData] = props.artistsData.artists.filter(
    (artist) => artist.name === props.selectArtistName
  );
  const { t } = useI18n();
  return (
    <div className="modalArtistDetail">
      <div className="touchCloseArea" onClick={props.closeArtistModal}></div>

      <div className="artistDetailLayout">
        <div className="closingButton" onClick={props.closeArtistModal}>
          ×
        </div>

        <div className="artistDetail">
          <img
            className="artistThumbnail"
            // @ts-expect-error TS18048
            src={selectArtistData.img_path}
            // @ts-expect-error TS18048
            alt={selectArtistData.name}
          />
          <p className="artistName" style={{ whiteSpace: "pre-line" }}>
            {/*
             // @ts-expect-error TS18048 */}
            {selectArtistData.name}
          </p>
          <p
            className="artistDescription"
            dangerouslySetInnerHTML={{
              // @ts-expect-error TS18048
              __html: sanitizer(selectArtistData.description),
            }}
          />

          {hasLinks(selectArtistData) && (
            <div className="artistSnsListLayout">
              <p className="heading">{t("event.detail.official")}</p>
              <ul className="artistSnsList">
                {/*
                 // @ts-expect-error TS18048 */}
                {selectArtistData.links.map((el, i) => {
                  return (
                    <li key={i}>
                      {el.name}
                      <br />
                      <a
                        href={el.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {el.url}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <p className="closeArtistDetail" onClick={props.closeArtistModal}>
            {t("event.detail.close")}
          </p>
        </div>
      </div>
    </div>
  );
};
