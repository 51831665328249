import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FlowerStandItemInfo } from "../../utility/event";
import { EventInfo } from "../../utility";
import { SELECTABLE_COLOR_MAP } from "../../constants/event";
import appConfig from "../../constants/appConfig";
import { modalActions } from "../../modules/modal";
import { BreadcrumbArea } from "components/common/Link";
import { changeStyleWithHosting } from "utility/hosting";
import { useI18n } from "hooks/i18n/i18n";
import styled from "@emotion/styled";
import {
  ContentsLayout,
  EventFlowerStandButtonList,
  EventPawInfo,
  fontDinMixin,
  LinkButton,
  Paw,
} from "../../styles";
import type { FlowerStandColor } from "@spwn/types";
import type { Place } from "@spwn/types/firebase/firestore";

type Props = {
  isUpdate: boolean;
  flowerStand: FlowerStandItemInfo;
  giftName: string;
  giftColor: FlowerStandColor;
  giftPlaces: string[];
  eventInfo: EventInfo;
  placeMap: Record<string, Place>;
  pawBalance: number;
  totalPawValue: number;
  customImagePath: string;
  profileImagePath: string;
  toggleConfirm: (clearInputField: boolean) => void;
  purchaseFlowerStand: () => void;
  // @ts-expect-error TS7006
  selectColor: (e) => void;
  // @ts-expect-error TS7006
  inputGiftName: (e) => void;
  // @ts-expect-error TS7006
  setCustomImagePath: (e) => void;
  setGeneratedImage: (image: Blob) => void;
};

const getFlowerImg = (
  flowerStand: FlowerStandItemInfo,
  giftColor: FlowerStandColor
) => {
  const flowerPath = `${appConfig.storageDomain}/items/flowerStand`;
  const flowerImg = `${flowerPath}/${flowerStand._id}/${giftColor}.png`;
  const flowerBackImg = `${flowerPath}/${flowerStand._id}/${giftColor}_back.jpg`;
  return { flowerImg, flowerBackImg };
};

type FlowerStandType = "original" | "grand" | "middle";

type Size = {
  width: number;
  height: number;
};

const canvasSizes: { [type in FlowerStandType]: Size } = {
  middle: {
    width: 718,
    height: 990,
  },
  grand: {
    width: 718,
    height: 990,
  },
  original: {
    width: 720,
    height: 1280,
  },
};

const pictureSize = {
  width: 520,
  height: 840,
};

const EventFlowerStandConfirmPawInfo = styled(EventPawInfo)`
  margin-bottom: 60px;
  @media screen and (min-width: 768px) {
    margin-bottom: 120px;
  }
  .layout {
    .button {
      width: 48%;
      margin: 0;
      @media screen and (min-width: 768px) {
        width: 350px;
      }
    }
  }
`;

EventFlowerStandConfirmPawInfo.displayName = "EventFlowerStandConfirmPawInfo";

const EventFlowerStandPaw = styled(Paw)`
  @media screen and (min-width: 768px) {
    font-size: 50px;
  }
  &:after {
    @media screen and (min-width: 768px) {
      width: 35px;
      height: 35px;
      margin-top: 4px;
    }
  }
`;

EventFlowerStandPaw.displayName = "EventFlowerStandPaw";

const Container = styled.section`
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 100px;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    padding-top: 0px;
  }
  .contents {
    .info {
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        padding-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
      }
      h2 {
        margin-bottom: 15px;
        font-size: 20px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
          font-size: 30px;
        }
      }
      .price {
        font-size: 16px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 25px;
        }
      }
    }
    .file_pre {
      position: relative;
      width: 280px;
      margin: 0 auto 40px;
      @media screen and (min-width: 768px) {
        width: 400px;
        margin: 0 auto 80px;
      }
      &.original {
        height: 510px;
        @media screen and (min-width: 768px) {
          height: 720px;
        }
      }
      &.grand {
        height: 410px;
        @media screen and (min-width: 768px) {
          height: 570px;
        }
      }
      &.middle {
        height: 320px;
        @media screen and (min-width: 768px) {
          height: 450px;
        }
      }
      img {
        position: absolute;
        &.flower_flame_back {
          width: 75%;
          top: 120px;
          left: 40px;
          @media screen and (min-width: 768px) {
            top: 160px;
            left: 50px;
          }
        }
        &.upload_img {
          width: 70%;
          top: 110px;
          left: 45px;
          @media screen and (min-width: 768px) {
            top: 140px;
            left: 62px;
          }
        }
        &.flower_flame {
          width: 100%;
        }
      }
      .name {
        position: absolute;
        top: 35px;
        left: 65px;
        width: 150px;
        @media screen and (min-width: 768px) {
          top: 55px;
          left: 90px;
          width: 220px;
        }
        p {
          font-size: 18px;
          text-align: center;
          font-weight: bold;
          line-height: 1.3em;
          @media screen and (min-width: 768px) {
            font-size: 25px;
          }
        }
      }
      .caution {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 14px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
    .selected_block {
      margin-bottom: 60px;
      @media screen and (min-width: 768px) {
        margin-bottom: 80px;
      }
      .basic_heading_design {
        margin-bottom: 20px;
        letter-spacing: 1px;
        @media screen and (min-width: 768px) {
          display: flex;
          align-items: center;
          margin-bottom: 40px;
        }
        h2 {
          ${fontDinMixin};
          padding: 5px 0 0 15px;
          margin-bottom: 5px;
          color: #000;
          font-size: 35px;
          line-height: 1em;
          border-left: 5px solid #01ffe1;
          @media screen and (min-width: 768px) {
            margin-bottom: 0;
            letter-spacing: 3px;
          }
        }
        p {
          padding-left: 21px;
          color: #000;
          font-size: 12px;
          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
        }
      }
      .select_color {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 10px 28px 10px 17px;
        font-size: 16px;
        font-weight: bold;
        border: 1px solid #dedede;
        background: url("/images/select_pulldown.png") right 50% no-repeat;
        background-size: 30px, 100%;
        border-radius: 0;
        appearance: none;
        @media screen and (min-width: 768px) {
          width: 740px;
          font-size: 18px;
        }
      }
      .place_list {
        width: 100%;
        margin: 0 auto;
        @media screen and (min-width: 768px) {
          width: 740px;
        }
        .place {
          padding: 15px 20px;
          border: 1px solid #d4d4d4;
          @media screen and (min-width: 768px) {
            display: flex;
            padding: 20px 30px;
          }
          &:not(:last-child) {
            margin-bottom: 20px;
          }
          p {
            font-size: 16px;
            font-weight: bold;
            @media screen and (min-width: 768px) {
              font-size: 18px;
            }
            &:first-child {
              margin-right: 20px;
              margin-bottom: 5px;
              @media screen and (min-width: 768px) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .name {
        width: 100%;
        margin: 0 auto;
        font-size: 16px;
        @media screen and (min-width: 768px) {
          width: 740px;
          font-size: 20px;
        }
      }
      .name_input {
        width: 100%;
        margin: 0 auto;
        @media screen and (min-width: 768px) {
          width: 740px;
        }
        input {
          width: 100%;
          padding: 10px;
          margin-bottom: 15px;
          font-size: 18px;
          border: 1px solid #d4d4d4;
        }
        .caution {
          font-size: 14px;
          text-align: right;
        }
      }
      .file_input {
        width: 100%;
        margin: 0 auto;
        @media screen and (min-width: 768px) {
          width: 740px;
        }
        .file_pre {
          width: 60%;
          margin: 0 auto 30px;
          @media screen and (min-width: 768px) {
            width: 360px;
          }
          img {
            position: relative;
            width: 100%;
          }
        }
        label {
          display: block;
          margin-bottom: 15px;
          input {
            display: none;
          }
        }
        .caution {
          font-size: 14px;
          line-height: 1.3em;
        }
      }
    }
    .total {
      padding-top: 25px;
      margin-bottom: 100px;
      border-top: 1px solid #d4d4d4;
      .layout {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        @media screen and (min-width: 768px) {
          width: 740px;
        }
        .heading {
          font-size: 14px;
          @media screen and (min-width: 768px) {
            font-size: 20px;
          }
        }
        .num {
          display: flex;
          ${fontDinMixin};
          font-size: 35px;
          @media screen and (min-width: 768px) {
            font-size: 50px;
          }
          &:after {
            content: "";
            display: block;
            width: 30px;
            height: 30px;
            margin-left: 5px;
            background-image: url("/images/icon_paw.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% auto;
            @media screen and (min-width: 768px) {
              width: 35px;
              height: 35px;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
`;

Container.displayName = "EventFlowerStandConfirmContainer";

const EventFlowerStandConfirm: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { t } = useI18n();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const togglePawChargeModal = () => {
    dispatch(modalActions.togglePawChargeModal({}));
  };

  const {
    isUpdate,
    flowerStand,
    giftName,
    giftColor,
    giftPlaces,
    eventInfo,
    placeMap,
    pawBalance,
    totalPawValue,
    customImagePath,

    toggleConfirm,
    purchaseFlowerStand,
    selectColor,
    inputGiftName,
    setCustomImagePath,
    setGeneratedImage,
  } = props;

  const { flowerImg, flowerBackImg } = getFlowerImg(flowerStand, giftColor);
  const imgPath = customImagePath;
  const breadcrumbText =
    changeStyleWithHosting().commonSettings.menu.event.text;

  const [foreImage, setForeImage] = useState<HTMLImageElement | null>(null);
  const [midImage, setMidImage] = useState<HTMLImageElement | null>(null);
  const [backImage, setBackImage] = useState<HTMLImageElement | null>(null);
  const [type, setType] = useState<FlowerStandType | null>(null);

  const onClickBuy = useCallback(() => {
    if (!canvasRef?.current) {
      return;
    }
    canvasRef.current.toBlob((image) => {
      if (type !== "middle") {
        // @ts-expect-error TS2345
        setGeneratedImage(image);
      }
      purchaseFlowerStand();
    });
  }, [type, setGeneratedImage, purchaseFlowerStand]);

  useEffect(() => {
    const type = flowerStand.addCustomImg
      ? "original"
      : flowerStand.pawValue !== 3000
      ? "grand"
      : "middle";
    setType(type);
  }, [flowerStand]);

  useEffect(() => {
    if (!flowerImg) {
      setForeImage(null);
      return;
    }
    const i = new Image();
    i.crossOrigin = "anonymous";
    const onImageLoaded = () => {
      i.removeEventListener("load", onImageLoaded);
      setForeImage(i);
    };
    i.addEventListener("load", onImageLoaded);
    // Add query for cache of CORS header
    i.src = `${flowerImg}?v=1`;
  }, [flowerImg]);

  useEffect(() => {
    if (!customImagePath) {
      setMidImage(null);
      return;
    }
    const i = new Image();
    i.crossOrigin = "anonymous";
    const onImageLoaded = () => {
      i.removeEventListener("load", onImageLoaded);
      setMidImage(i);
    };
    i.addEventListener("load", onImageLoaded);
    i.src = customImagePath;
  }, [customImagePath]);

  useEffect(() => {
    if (!flowerBackImg) {
      setBackImage(null);
      return;
    }
    const i = new Image();
    i.crossOrigin = "anonymous";
    const onImageLoaded = () => {
      i.removeEventListener("load", onImageLoaded);
      setBackImage(i);
    };
    i.addEventListener("load", onImageLoaded);
    // Add query for cache of CORS header
    i.src = `${flowerBackImg}?v=1`;
  }, [flowerBackImg]);

  useEffect(() => {
    if (!canvasRef?.current) {
      return;
    }
    const ctx = canvasRef.current.getContext("2d");
    // @ts-expect-error TS18047
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    if (backImage) {
      // @ts-expect-error TS18047
      ctx.drawImage(backImage, 93, 299);
    }
    if (midImage) {
      const expected = pictureSize.width / pictureSize.height;
      const actual = midImage.width / midImage.height;
      let w = pictureSize.width;
      let h = pictureSize.height;
      if (actual < expected) {
        w = h * actual;
      } else if (actual > expected) {
        h = w / actual;
      }
      // @ts-expect-error TS18047
      ctx.drawImage(
        midImage,
        102 + (pictureSize.width - w) / 2,
        308 + (pictureSize.height - h) / 2,
        w,
        h
      );
    }
    if (foreImage) {
      // @ts-expect-error TS18047
      ctx.drawImage(foreImage, 0, 0);
    }

    if (type === "middle") {
      return;
    }

    // @ts-expect-error TS18047
    ctx.fillStyle = "#000000";
    // @ts-expect-error TS18047
    ctx.textAlign = "left";
    // @ts-expect-error TS18047
    ctx.textBaseline = "top";

    const fontFamily = `"游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック", sans-seif`;

    const postfixFont = `bold 36px ${fontFamily}`;
    const postfixText = "より";
    // @ts-expect-error TS18047
    ctx.font = postfixFont;
    // @ts-expect-error TS18047
    const postfixMetrics = ctx.measureText(postfixText);
    const postfixSize = {
      width: postfixMetrics.width,
      height: postfixMetrics.actualBoundingBoxDescent,
      actualHeight:
        postfixMetrics.actualBoundingBoxDescent -
        postfixMetrics.actualBoundingBoxAscent,
    };

    const textRect = {
      x: 130 + postfixSize.width,
      y: 88,
      width: 460 - postfixSize.width * 2,
      height: 120,
    };
    const textMargin = 8;

    function measureSingle(text: string, fontSize: number) {
      // @ts-expect-error TS18047
      ctx.font = `bold ${fontSize}px ${fontFamily}`;
      // @ts-expect-error TS18047
      const textMetrics = ctx.measureText(text);
      return {
        width: textMetrics.width,
        height: textMetrics.actualBoundingBoxDescent,
        actualHeight:
          textMetrics.actualBoundingBoxDescent -
          textMetrics.actualBoundingBoxAscent,
      };
    }

    function fillSingle(text: string, textSize: Size) {
      const textX = textRect.x + (textRect.width - textSize.width) / 2;
      const textY = textRect.y + (textRect.height - textSize.height) / 2;
      // @ts-expect-error TS18047
      ctx.fillText(text, textX, textY);
      // @ts-expect-error TS18047
      ctx.font = postfixFont;
      // @ts-expect-error TS18047
      ctx.fillText(
        postfixText,
        textX + textSize.width,
        textY + textSize.height - postfixSize.height
      );
    }

    const textSize96 = measureSingle(giftName, 96);
    if (textSize96.width <= textRect.width) {
      fillSingle(giftName, textSize96);
      return;
    }

    const textSize72 = measureSingle(giftName, 72);
    if (textSize72.width <= textRect.width) {
      fillSingle(giftName, textSize72);
      return;
    }

    const textSize60 = measureSingle(giftName, 60);
    if (textSize60.width <= textRect.width) {
      fillSingle(giftName, textSize60);
      return;
    }

    function fillMulti(before: string, after: string) {
      const beforeSize = measureSingle(before, 60);
      const afterSize = measureSingle(after, 60);
      if (
        beforeSize.width > textRect.width ||
        afterSize.width > textRect.width
      ) {
        return false;
      }
      const beforeY =
        textRect.y +
        (textRect.height - beforeSize.height - afterSize.height - textMargin) /
          2;
      // @ts-expect-error TS18047
      ctx.fillText(
        before,
        textRect.x + (textRect.width - beforeSize.width) / 2,
        beforeY
      );
      const afterX = textRect.x + (textRect.width - afterSize.width) / 2;
      const afterY = beforeY + beforeSize.height + textMargin;
      // @ts-expect-error TS18047
      ctx.fillText(after, afterX, afterY);

      // @ts-expect-error TS18047
      ctx.font = postfixFont;
      // @ts-expect-error TS18047
      ctx.fillText(
        postfixText,
        afterX + afterSize.width,
        afterY + afterSize.height - postfixSize.height
      );
      return true;
    }

    // eslint-disable-next-line no-irregular-whitespace
    const s = /[\s　]/.exec(giftName);
    // @ts-expect-error TS18048
    if (s?.index > 0) {
      // @ts-expect-error TS18047
      const before = giftName.substr(0, s.index);
      // @ts-expect-error TS18047
      const after = giftName.substr(s.index + s[0].length);
      if (fillMulti(before, after)) {
        return;
      }
    }

    const len = giftName.length;
    for (let i = len - 2; i >= 1; i--) {
      const before = giftName.substr(0, i);
      const after = giftName.substr(i, len);
      if (fillMulti(before, after)) {
        return;
      }
    }
  }, [type, canvasRef, giftName, foreImage, midImage, backImage]);

  return (
    <Container id="contents">
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/events", breadcrumbText],
          ["/events/" + eventInfo.eventId, eventInfo.eventTitle],
          [`/events/${eventInfo.eventId}/flower-stand`, "フラワースタンド一覧"],
          [null, flowerStand.name],
        ]}
      />
      <div>
        <ContentsLayout className="contents">
          <div className="info">
            <h2>{flowerStand.name}</h2>
            <p className="price">{flowerStand.pawValue.toLocaleString()} PAW</p>
          </div>

          <div className={`file_pre ${flowerStand.previewClassName}`}>
            {type ? (
              <canvas
                ref={canvasRef}
                style={{ width: "100%" }}
                width={canvasSizes[type].width}
                height={canvasSizes[type].height}
              />
            ) : null}
            {flowerStand.addCustomImg ? (
              <p className="caution">
                ※ 枠の花に被らないようにアップロード画像の余白をご調整下さい
              </p>
            ) : null}
          </div>

          <div className="selected_block">
            <div className="basic_heading_design">
              <h2>COLOR</h2>
              <p>フラスタカラー</p>
            </div>

            <select
              className="select_color"
              defaultValue={giftColor}
              onChange={selectColor}
            >
              {Object.keys(SELECTABLE_COLOR_MAP).map(
                // @ts-expect-error TS2345
                (colorId: FlowerStandColor, i) => {
                  const colorData = SELECTABLE_COLOR_MAP[colorId];
                  return (
                    <option key={i} value={colorData.id}>
                      {colorData.name}
                    </option>
                  );
                }
              )}
            </select>
          </div>

          <div className="selected_block">
            <div className="basic_heading_design">
              <h2>PLACE</h2>
              <p>会場</p>
            </div>

            <div className="place_list">
              {giftPlaces.map((placeCode: string, i) => {
                const place = placeMap[placeCode];
                return (
                  <div key={i} className="place">
                    {/*
                     // @ts-expect-error TS18048 */}
                    <p>{place.areaName}</p>
                    {/*
                     // @ts-expect-error TS18048 */}
                    <p>{place.venueName}</p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="selected_block">
            <div className="basic_heading_design">
              <h2>NAME</h2>
              <p>名前</p>
            </div>

            <div className="name_input">
              <input
                type="text"
                name="name"
                maxLength={10}
                defaultValue={giftName}
                onChange={inputGiftName}
              />
              <p className="caution">{giftName.length}/10</p>
            </div>
          </div>

          {flowerStand.addCustomImg ? (
            <div className="selected_block">
              <div className="basic_heading_design">
                <h2>PHOTO UPLOAD</h2>
                <p>アップロード画像</p>
              </div>

              <div className="file_input">
                <div className="file_pre">
                  {imgPath ? <img src={imgPath} alt="" /> : <></>}
                </div>
                <label>
                  <input
                    type="file"
                    onChange={setCustomImagePath}
                    id="customImg"
                    name="name"
                    accept=".jpg,.png,image/jpeg,image/png"
                  />
                  <LinkButton className="button">
                    オリジナル画像をアップロード
                  </LinkButton>
                </label>
                <p className="caution">
                  {t("flowerstand.item.imageRecommendation")}
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}

          {isUpdate ? (
            <></>
          ) : (
            <div className="total">
              <div className="layout">
                <p className="heading">合計PAW</p>
                <p className="num">{totalPawValue.toLocaleString()}</p>
              </div>
            </div>
          )}
        </ContentsLayout>

        {isUpdate ? (
          <></>
        ) : (
          <EventFlowerStandConfirmPawInfo>
            <div className="layout">
              <div className="balance">
                <p className="heading">PAW残高</p>
                <EventFlowerStandPaw>
                  {pawBalance.toLocaleString()}
                </EventFlowerStandPaw>
              </div>
              <LinkButton className="button" onClick={togglePawChargeModal}>
                {t("account.topUpPAW")}
              </LinkButton>
            </div>
          </EventFlowerStandConfirmPawInfo>
        )}

        <EventFlowerStandButtonList>
          <LinkButton onClick={onClickBuy}>
            {isUpdate ? "この内容で更新" : "この内容で購入"}
          </LinkButton>
          <LinkButton className="back" onClick={() => toggleConfirm(isUpdate)}>
            戻る
          </LinkButton>
        </EventFlowerStandButtonList>
      </div>
    </Container>
  );
};

export default EventFlowerStandConfirm;
