import styled from "@emotion/styled";

export const ModalHeader = styled.div`
  background-color: #f9f9f9;
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 5%;
    background-color: #fff;
    @media screen and (min-width: 768px) {
      padding: 25px 5%;
    }
    .heading {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .close,
    .back {
      width: 25px;
      height: 25px;
      font-size: 25px;
      text-align: center;
      font-weight: bold;
      cursor: pointer;
    }
  }
`;

ModalHeader.displayName = "ModalHeader";
