/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useI18n } from "../hooks/i18n/i18n";
import React from "react";

export const ConfirmCvsPaymentOrderDetails: React.FC<{
  items: {
    name: string;
    variantName: string;
    price: number;
    count: number;
  }[];
}> = ({ items }) => {
  const { t } = useI18n();
  const classes = styles();

  return (
    <section css={classes.root}>
      <img src="/images/flame_line_top_bg_pc.svg" alt="" className="flame_pc" />
      <img src="/images/flame_line_top_bg.svg" alt="" className="flame_sp" />
      <div css={classes.list}>
        {items.map((item, index) => {
          const price = item.price.toLocaleString();
          const count = item.count.toString();
          return (
            <div key={"goods_info_" + index} css={classes.item}>
              <div>
                <p css={classes.name}>{t("confirmCvsPayment.goods")}</p>
                <p css={classes.variantName}>
                  {item.name}{" "}
                  {item.variantName === "" ? "" : `(${item.variantName})`}
                </p>
                <p css={classes.price}>
                  ¥{price}
                  <span>{t("confirmCvsPayment.taxIncluded")}</span>
                </p>
              </div>

              <div css={classes.num}>
                <p>
                  <span>×</span>
                  {count}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

const styles = () => {
  return {
    root: css`
      margin-bottom: 15px;
      img {
        width: 100%;
        background-color: #fff;
        &.flame_pc {
          display: none;
          @media screen and (min-width: 768px) {
            display: inline;
          }
        }
        &.flame_sp {
          display: inline;
          @media screen and (min-width: 768px) {
            display: none;
          }
        }
      }
    `,
    list: css`
      margin-top: -5px;
      padding: 0 20px 10px;
      border-right: 1px solid #d2d2d2;
      border-left: 1px solid #d2d2d2;
      border-bottom: 1px solid #d2d2d2;
      background-color: #fff;
    `,
    item: css`
      display: grid;
      grid-template-columns: auto max-content;
      width: 100%;
      margin: 0 auto 16px;
      &:not(:last-of-type) {
        padding-bottom: 16px;
        border-bottom: 1px solid #d2d2d2;
      }
    `,
    name: css`
      margin-bottom: 8px;
      font-size: 12px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        font-size: 14px;
      }
    `,
    variantName: css`
      margin-bottom: 15px;
      font-weight: bold;
      line-height: 1.3em;
      @media screen and (min-width: 768px) {
        font-size: 18px;
      }
    `,
    price: css`
      font-size: 16px;
      span {
        @media screen and (min-width: 768px) {
          margin-left: 5px;
          font-size: 12px;
        }
      }
    `,
    num: css`
      display: grid;
      align-items: end;
      justify-content: center;
      p {
        font-family: "din-condensed", sans-serif;
        font-size: 40px;
        font-weight: 400;
        font-style: normal;
        span {
          font-size: 25px;
        }
      }
    `,
  };
};
