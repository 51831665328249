/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { FC } from "react";
import appConfig from "../constants/appConfig";
import { SpwnLogo } from "ui/SpwnLogo";

export const HeaderForCrew: FC<{
  crewBrandSlug: string;
}> = ({ crewBrandSlug }) => {
  return (
    <header
      css={css`
        position: relative;
        display: block;
        width: 100%;
        margin: 0 auto;
        background-color: #000;
        z-index: 900;
      `}
    >
      <div
        css={css`
          width: 90%;
          max-width: 1800px;
          padding: 15px 0px;
          margin: 0 auto;
          @media screen and (min-width: 768px) {
            width: 95%;
          }
        `}
      >
        <h1
          css={css`
            width: auto;
            height: 25px;
            img {
              height: 100%;
            }
          `}
        >
          <a
            href={`https://${appConfig.crewlDomain}/${crewBrandSlug}`}
            rel={"noopener noreferrer"}
          >
            <SpwnLogo />
          </a>
        </h1>
      </div>
    </header>
  );
};
