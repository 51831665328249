import { MediaPlayer, PlayerEventMap, PlayerState } from "amazon-ivs-player";
import type { THEOPlayer } from "@spwn/types/external";
import { useCallback, useEffect } from "react";
import { pushDataLayer } from "utility/ga";

type StreamingAnalyticsProps = {
  player: THEOPlayer;
  awsPlayer: MediaPlayer;
  videoId: string;
};

/**
 * 動画 GA 用のコンポーネント (experimental)
 * npawで計測しているが、読み込みエラーで計測できないケースがあるため、暫定対応としてGAでも計測する
 * NOTE: クラスコンポーネントで呼ばれているため、カスタムフックを呼ぶだけのFunctionalコンポーネントを作成している
 */
export const StreamingAnalytics: React.FC<StreamingAnalyticsProps> = (
  props
) => {
  const { player, awsPlayer, videoId } = props;
  useTheoPlayerVideoStart({ player, videoId });
  useAwsPlayerVideoStart({ player: awsPlayer, videoId });
  return null;
};

type UseTheoPlayerVideoStart = (props: {
  player: THEOPlayer;
  videoId: string;
}) => void;

/**
 * theoplayerで、動画を初めて再生した場合にアナリティクスを送信する
 * 送信タイミング
 * - 配信ページに入ったとき
 * - 低遅延配信がある場合、低遅延配信から切り替えたとき
 * NOTE: replay は送信されない
 *
 * 初回再生のみ取得する実装サンプルに従っている
 * @see https://docs.theoplayer.com/how-to-guides/09-player/03-how-can-we-track-the-first-playing-event.md
 */
const useTheoPlayerVideoStart: UseTheoPlayerVideoStart = (props) => {
  const { player, videoId } = props;
  const videoStart = useCallback(() => {
    player.removeEventListener("playing", videoStart);
    pushDataLayer({
      event: "spwn_video_start",
      video_id: videoId,
      video_player_type: "theoplayer",
    });
  }, [player, videoId]);

  useTheoPlayerPlayEvent({
    player,
    event: "sourcechange",
    onTrigger: useCallback(() => {
      player.removeEventListener("playing", videoStart);
      player.addEventListener("playing", videoStart);
    }, [player, videoStart]),
  });
};

type UseAwsPlayerVideoStart = (props: {
  player: MediaPlayer;
  videoId: string;
}) => void;
/**
 * aws ivs playerで、動画を再生した場合にアナリティクスを送信する
 * 送信タイミング
 * - 配信ページに入ったとき
 * - 通常配信から、低遅延配信へ切り替えたとき
 * - 再生をpauseして、再度再生したとき
 * NOTE: theoplayerのように、初回再生のみ送信することが現状できなかったが、aws playerはライブ配信でのみ利用なため、pauseされることはそうないとして許容する
 * @param props
 */
const useAwsPlayerVideoStart: UseAwsPlayerVideoStart = (props) => {
  const { player, videoId } = props;

  useAwsPlayerPlayEvent({
    player,
    event: PlayerState.READY,
    onTrigger: useCallback(() => {
      pushDataLayer({
        event: "spwn_video_start",
        video_id: videoId,
        video_player_type: "aws",
      });
    }, [videoId]),
  });
};

const noop = () => void 0;

// TheoPlayer addEventListener util

type TriggerHandler = (event: unknown) => void;

type PlayerEvent = "play" | "playing" | "sourcechange";

const useTheoPlayerPlayEvent = (props: {
  player: THEOPlayer;
  event: PlayerEvent;
  onTrigger: TriggerHandler;
}) => {
  const { player, event, onTrigger = noop } = props;
  useEffect(() => {
    // playerがなければ何もしない
    if (!player) return;
    player.addEventListener(event, onTrigger);
    return () => {
      player.removeEventListener(event, onTrigger);
    };
  }, [player, event, onTrigger]);
};

// AwsPlayer addEventListener util

type AwsTriggerHandler = (event: unknown) => void;

type AwsPlayerEvent = keyof PlayerEventMap;

const useAwsPlayerPlayEvent = (props: {
  player: MediaPlayer;
  event: AwsPlayerEvent;
  onTrigger: AwsTriggerHandler;
}) => {
  const { player, event, onTrigger = noop } = props;
  useEffect(() => {
    // playerがなければ何もしない
    if (!player) return;
    player.addEventListener(event, onTrigger);
    return () => {
      player.removeEventListener(event, onTrigger);
    };
  }, [player, event, onTrigger]);
};
