export type APISchema = {
  [key: string]: {
    request: unknown;
    response: unknown;
  };
};

type APIResult<T> =
  | {
      ok: true;
      data: T;
    }
  | {
      ok: false;
      message: string;
    };

export type APIClientCallResult<T extends APISchema, Path extends keyof T> =
  APIResult<T[Path]["response"]>;

export type APIClient<T extends APISchema> = {
  call: <Path extends keyof T>(
    path: Path,
    request: T[Path]["request"],
    options?: RequestInit | undefined
  ) => Promise<APIClientCallResult<T, Path>>;
};

type APIClientOptions = {
  origin?: string;
  getAuthorizationHeader?: () => Promise<{ Authorization: string }>;
};

/**
 * @example
 * const client = createAPIClient<{
 *   hello: {
 *     request: {
 *       id: string;
 *     };
 *     response: {
 *       id: string;
 *       name: string;
 *       age: number;
 *     };
 *   };
 * }>();
 *
 * const result = await client.call("hello", { id: "1", hoge: "" });
 */
export const createAPIClient = <T extends APISchema>({
  origin,
  getAuthorizationHeader,
}: APIClientOptions = {}): APIClient<T> => {
  const createUri = (path: string) => {
    if (origin === undefined) {
      // originがない場合のuriは、`${今いるorigin}/${path}` となる
      return `/${path}`;
    }
    return `${origin}/${path}`;
  };

  return {
    call: async (path, request, options) => {
      const authorization = getAuthorizationHeader
        ? await getAuthorizationHeader()
        : {};

      const res = await fetch(createUri(path.toString()), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...authorization,
          ...options?.headers,
        },
        body: request ? JSON.stringify(request) : null,
        ...options,
      });
      if (!res.ok) {
        return {
          ok: false,
          message: "API call failed",
        };
      }

      return {
        ok: true,
        data: await res.json(),
      };
    },
  };
};
