import useSWR from "swr";
import firebase from "firebase";

import { Event } from "@spwn/types/firebase/firestore";

import { getBelongedHosting } from "utility/storage";

export class EventNotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "EventNotFoundError";
  }
}

export type UseGetEventProps = {
  /**
   * イベントID
   */
  eventId?: string | null;

  /**
   * ホスティングの検証を無視するか
   * デフォルトはtrueの挙動
   */
  ignoreHosting?: boolean;
};

export const useGetEvent = (arg?: string | null | UseGetEventProps) => {
  const props = typeof arg === "string" ? { eventId: arg } : arg ?? {};
  return useSWR(
    props.eventId
      ? ["getEvent", props.eventId, props.ignoreHosting ?? true]
      : null,
    async ([_, eventId, ignoreHosting]) => fetchEvent(eventId, ignoreHosting)
  );
};

const fetchEvent = async (eventId: string, ignoreHosting?: boolean) => {
  const snapshot = await firebase
    .firestore()
    .collection("events")
    .doc(eventId)
    .get();

  const event = {
    ...snapshot.data(),
    _id: snapshot.id,
  } as Event;

  // belongedHostingを検証
  if (!ignoreHosting) {
    const belongedHosting = getBelongedHosting();
    if (!event.belongedHostings.includes(belongedHosting)) {
      throw new EventNotFoundError();
    }
  }

  return event;
};
