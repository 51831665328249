import dompurify from "dompurify";

/**
 * sanitizer to use dangerouslySetInnerHTML
 */
// hook refference: https://github.com/cure53/DOMPurify/tree/master/demos
// Add a hook to make all links open a new window
dompurify.addHook("afterSanitizeAttributes", function (node) {
  // set all elements owning target to target=_blank
  if ("target" in node) {
    node.setAttribute("target", "_blank");
  }
  // set non-HTML/MathML links to xlink:show=new
  if (
    !node.hasAttribute("target") &&
    (node.hasAttribute("xlink:href") || node.hasAttribute("href"))
  ) {
    node.setAttribute("xlink:show", "new");
  }
});
// FIXME: dompurify.sanitize の内部実装が method のため this が実行時スコープ依存になっている。対処方法要検討
// eslint-disable-next-line @typescript-eslint/unbound-method
export const sanitizer = dompurify.sanitize;
