import appConfig from "constants/appConfig";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Store } from "store";
import useSWRImmutable from "swr/immutable";
import firebase from "firebase";
import "firebase/auth";
import { encodeReturnUrl } from "./accounts/AccountsAuthenticator";

const FUNC_NAME = "verifySessionCookie";

/**
 * @description
 * ローカル実行する場合はngrok利用する必要がある
 *  - crewでログインしていて、portalでログインしていない場合、自動でログインを行い元いたページにリダイレクトする。
 *  - crewでログインしていて、portalでログインしている場合、childrenがレンダリングされる。
 */
export const SwitchIfNotLoggedIn: FC<{
  children: JSX.Element;
  switchComponent: JSX.Element;
}> = ({ children, switchComponent }) => {
  const user = useSelector((state: Store) => state.auth.user);
  const { data, error } = useGetCustomToken();

  /**
   * accountsアプリ（Portalイベントのログイン）を利用したログイン
   * - ログイン完了するまでを責務とする
   *   - ログイン後の挙動は Login コンポーネントに任せる
   */
  useRedirectToAccountsApp(origin, error);
  useLoginWithCustomToken(data?.customToken);

  if (Object.keys(user).length === 0) {
    return switchComponent;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const useRedirectToAccountsApp = (origin: string, error: unknown) => {
  useEffect(() => {
    if (!error) return;
    /**
     * https://accounts.spwn.jp/login?return_url=https://spwn.jp/streams/[streamingId]に遷移させる。
     */
    window.location.replace(
      `${appConfig.accountsApp.appUrl}/login?return_url=${encodeReturnUrl({
        returnUrl: window.location.href,
      })}`
    );
  }, [origin, error]);
};

const useLoginWithCustomToken = (customToken: string | undefined) => {
  useEffect(() => {
    if (customToken === undefined) return;
    (async () => {
      /**
       * 返り値をセットしなくても、onAuthStateChanged によってログイン検知される
       */
      const _userCredential = await firebase
        .auth()
        .signInWithCustomToken(customToken);
    })();
  }, [customToken]);
};

const useGetCustomToken = () => {
  const { data, error } = useSWRImmutable(
    { key: FUNC_NAME },
    async () => {
      const response = await callVerifySessionCookie({});
      if (response.ok) {
        return response.data;
      }
      /**
       * いずれのエラーもログインが必要なためとして、エラーごとの条件分岐は行わない
       *
       * 考えられるエラー
       * - cookieにセッションcookieがセットされていない
       * - セッションcookieが期限切れ
       */
      throw new Error("ログインが必要なためリダイレクトを行います");
    },
    {
      /**
       * 現状 suspense はデフォルトで有効にしている
       * しかし有効にすると、useSWRからエラーが返ってこなくなり、
       * エラーによるリダイレクトやログインのハンドリングがやりづらくなるため無効にしている
       * https://swr.vercel.app/docs/suspense
       */
      suspense: false,
    }
  );

  return {
    data,
    error,
  };
};

type VerifySessionCookieRequest = {};
type VerifySessionCookieResponse = {
  customToken: string;
};
export const callVerifySessionCookie = async (
  request: VerifySessionCookieRequest = {}
) => {
  const url = `${appConfig.backendApiUrl}/account/spwn/${FUNC_NAME}`;
  const response = await post<
    VerifySessionCookieRequest,
    VerifySessionCookieResponse
  >(url, request, {
    /**
     * cookieのやり取りを行えるようにする
     * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#sending_a_request_with_credentials_included
     */
    credentials: "include",
  });
  return response.ok ? { ...response, data: response.data } : response;
};

type FetchResult<T> = FetchSuccessResult<T> | FetchErrorResult;
type FetchSuccessResult<T> = {
  data: T;
  ok: true;
};
type FetchErrorResult = {
  data: { code?: string; message: string };
  ok: false;
};
/**
 * POSTリクエスト
 * @param url
 * @param body
 * @param options
 * @returns
 */
export const post = async <Request, Response>(
  url: string,
  body: Request,
  options?: {
    /**
     * cookieをやりとりするときに指定が必要
     */
    credentials?: RequestInit["credentials"];
    /**
     * onRequestなfunctionの認証に利用する
     */
    bearerToken?: string;
  }
): Promise<FetchResult<Response>> => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(options?.bearerToken
        ? {
            Authorization: `Bearer ${options.bearerToken}`,
          }
        : {}),
    },
    body: JSON.stringify(body),
    ...(options?.credentials
      ? {
          credentials: options.credentials,
        }
      : {}),
  });
  const data = await response.json();
  // 成功
  if (response.ok) {
    return {
      ok: true,
      data,
    };
  }
  // 失敗
  // レスポンスデータのmessageが存在しない場合はステータスのテキストを設定する
  const errorMessage = data?.message ?? response.statusText;
  return {
    ok: false,
    data: { message: errorMessage, code: data?.code },
  };
};
