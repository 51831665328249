/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { changeStyleWithHosting } from "utility/hosting";

interface Props {
  title: string;
  subTitle?: string;
  fontColor: "primary" | "secondary";
}
export const LeftBorderHeadline: React.FC<Props> = (props) => {
  const classes = styles(props.fontColor);
  return (
    <div css={classes.root}>
      <h2>{props.title}</h2>
      <p>{props.subTitle}</p>
    </div>
  );
};

// @ts-expect-error TS7006
const styles = (fontColor) => {
  const {
    baseStyles: { accentColor, fontPrimaryColor, fontSecondaryColor },
  } = changeStyleWithHosting();
  const fontColorStyle =
    fontColor === "primary" ? fontPrimaryColor : fontSecondaryColor;
  return {
    root: css`
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }
      h2 {
        font-family: "din-condensed", sans-serif;
        font-weight: 400;
        font-style: normal;
        color: ${fontColorStyle};
        padding: 5px 0 0 15px;
        margin-bottom: 5px;
        font-size: 35px;
        border-left: 5px solid ${accentColor};
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
          letter-spacing: 3px;
        }
      }
      p {
        padding-left: 21px;
        font-size: 12px;
        color: ${fontColorStyle};
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    `,
  };
};
