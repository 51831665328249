/** @jsxRuntime classic /
/** @jsx jsx */
import { Global, css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "store";

import { streamingActions, streamingState } from "modules/streaming";
import ClearIcon from "@material-ui/icons/Clear";
import type { ProductData } from "@spwn/types/firebase/firestore";

import sample from "designs/images/__sample/sample.jpg";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

interface Props {
  giftItemMap: streamingState["giftItemMap"];
  setClickCount?: (clickCount: number) => void;
}
export const StreamingOnlyComment: React.FC<Props> = (props) => {
  const displayComments = useSelector(
    (state: Store) => state.streaming.displayComments
  );
  const eventGoodsMap = useSelector(
    (state: Store) => state.ticket.eventGoodsMap
  );

  const [isDisplay, setIsDisplay] = useState<boolean>(true);
  const [autoScroll, setAutoScroll] = useState<boolean>(true);

  const dispatch = useDispatch();
  /**
   * TODO 端末間のpxのズレを調整する
   * PCとタブレットで31pxズレているので、多めに50pxに設定している。
   */
  const DIFFERENCES_BETWEEN_TERMINALS = 50;

  const classesStreamingOnlyCommentStyles = streamingOnlyCommentStyles({
    isDisplay,
  });

  const didMount = () => {
    moveBottom();
    determineIfBottom();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(didMount, []);

  const watchDisplayComment = () => {
    if (autoScroll) {
      moveBottom();
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(watchDisplayComment, [displayComments]); // TODO

  const getElement = () => {
    const element = document.getElementById("onlyCommentList");
    return element;
  };

  const getBottomPosition = () => {
    // @ts-expect-error TS2531
    const elementHeight = getElement().scrollHeight;
    const windowHeight = document.documentElement.clientHeight - 80;
    const bottomPosition = elementHeight - windowHeight;
    return bottomPosition;
  };

  const determineIfBottom = () => {
    // @ts-expect-error TS2531
    getElement().onscroll = () => {
      const scrollValue =
        // @ts-expect-error TS2531
        getElement().scrollTop + DIFFERENCES_BETWEEN_TERMINALS;
      const bottomPosition = getBottomPosition();
      if (bottomPosition <= scrollValue) {
        setIsDisplay(false);
        setAutoScroll(true);
      } else {
        setIsDisplay(true);
        setAutoScroll(false);
      }
    };
  };

  const moveBottom = () => {
    // @ts-expect-error TS2531
    getElement().scrollTop = getBottomPosition();
  };

  const closeOnlyComment = () => {
    // @ts-expect-error TS2722
    props.setClickCount(0);
    dispatch(streamingActions.toggleOnlyChatMode());
  };

  return (
    <div css={classesStreamingOnlyCommentStyles.root}>
      <Global styles={noScrollStyles} />
      <div
        css={classesStreamingOnlyCommentStyles.cross}
        onClick={closeOnlyComment}
      >
        <ClearIcon />
      </div>
      <div css={classesStreamingOnlyCommentStyles.arrow} onClick={moveBottom}>
        <ArrowDownwardIcon />
      </div>

      <div id="onlyCommentList" css={classesStreamingOnlyCommentStyles.layout}>
        {displayComments.map((comment, index) => {
          if (comment.isBan === true) {
            return null;
          }
          if (comment.isSuperChat) {
            // @ts-expect-error TS2538
            const giftName = props.giftItemMap[comment.giftItemId]?.name;
            const classesSuperChatCommentStyles = superChatCommentStyles({
              color: comment.color,
            });
            return (
              <div css={classesSuperChatCommentStyles.root} key={index}>
                <div css={classesSuperChatCommentStyles.userInfo}>
                  <div css={classesSuperChatCommentStyles.icon}>
                    <img src={comment.icon} alt={comment.name} />
                  </div>
                  <div css={classesSuperChatCommentStyles.info}>
                    <p>{comment.name}</p>
                    <p>{giftName}を送りました！</p>
                  </div>
                </div>
                {comment.msg && (
                  <p css={classesSuperChatCommentStyles.comment}>
                    {comment.msg}
                  </p>
                )}
              </div>
            );
          } else if (comment.isPurchased) {
            const purchasedProduct: ProductData = comment.isPurchased
              ? // @ts-expect-error TS2538
                eventGoodsMap[comment.purchasedProductId]
              : null;
            const classesPurchasedCommentStyles = purchasedCommentStyles();
            return (
              <div css={classesPurchasedCommentStyles.root} key={index}>
                <div css={classesPurchasedCommentStyles.icon}>
                  <img
                    src={purchasedProduct.thumbnail}
                    alt={purchasedProduct.className}
                  />
                </div>
                <div css={classesPurchasedCommentStyles.text}>
                  {/* <p css={classesNormalCommentStyles.name}>{purchasedProduct.className}が購入されました!</p> */}
                  <p css={classesPurchasedCommentStyles.comment}>
                    {purchasedProduct.className}が購入されました!
                  </p>
                </div>
              </div>
            );
          } else {
            const classesNormalCommentStyles = normalCommentStyles();
            return (
              <div css={classesNormalCommentStyles.root} key={index}>
                <div css={classesNormalCommentStyles.icon}>
                  <img src={comment.icon} alt={comment.name} />
                </div>
                <div css={classesNormalCommentStyles.text}>
                  <p css={classesNormalCommentStyles.name}>{comment.name}</p>
                  <p css={classesNormalCommentStyles.comment}>{comment.msg}</p>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

const noScrollStyles = css`
  body {
    overflow: hidden;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

interface StreamingOnlyCommentStylesProps {
  isDisplay: boolean;
}
const streamingOnlyCommentStyles = (props: StreamingOnlyCommentStylesProps) => {
  const display = props.isDisplay
    ? css`
        opacity: 1;
        visibility: visible;
      `
    : css`
        opacity: 0;
        visibility: hidden;
      `;
  return {
    root: css`
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 40px;
      background-color: #202020;
      background-image: url(${sample});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      z-index: 1000;
    `,
    cross: css`
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      svg {
        fill: #fff;
      }
    `,
    arrow: css`
      position: absolute;
      bottom: 15px;
      right: 15px;
      ${display}
      padding: 4px;
      background-color: #8b4513aa;
      border-radius: 50%;
      transition: 0.3s;
      cursor: pointer;
      svg {
        fill: #fff;
      }
    `,
    layout: css`
      height: calc(100vh - 80px);
      overflow-y: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    `,
  };
};

const purchasedCommentStyles = () => {
  return {
    root: css`
      display: flex;
      align-items: center;
      width: 65%;
      padding: 16px 24px;
      margin: 0 auto 16px;
      background-color: #fff;
      border-radius: 36px;
    `,
    icon: css`
      width: 40px;
      height: 40px;
      margin-right: 10px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    `,
    text: css`
      width: calc(100% - 50px);
    `,
    /* name: css`
      font-size: 14px;
      margin-bottom: 5px;
    `, */
    comment: css`
      font-size: 16px;
      font-weight: bold;
    `,
  };
};

interface SuperChatCommentStylesProps {
  color: string;
}
const superChatCommentStyles = (props?: SuperChatCommentStylesProps) => {
  return {
    root: css`
      width: 65%;
      padding: 25px;
      margin: 0 auto 16px;
      background-color: ${
        // @ts-expect-error TS18048
        props.color
      };
      border-radius: 30px;
    `,
    userInfo: css`
      display: flex;
      align-items: center;
    `,
    icon: css`
      width: 40px;
      height: 40px;
      margin-right: 15px;
      img {
        width: 100%;
        background-color: #fff;
        border-radius: 50%;
      }
    `,
    info: css`
      width: calc(100% - 55px);
      p {
        color: #fff;
        font-size: 14px;
        &:first-child {
          margin-bottom: 5px;
        }
      }
    `,
    comment: css`
      margin-top: 16px;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    `,
  };
};

const normalCommentStyles = () => {
  return {
    root: css`
      display: flex;
      align-items: center;
      width: 65%;
      padding: 16px 24px;
      margin: 0 auto 16px;
      background-color: #fff;
      border-radius: 36px;
    `,
    icon: css`
      width: 40px;
      height: 40px;
      margin-right: 10px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    `,
    text: css`
      width: calc(100% - 50px);
    `,
    name: css`
      font-size: 14px;
      margin-bottom: 5px;
    `,
    comment: css`
      font-size: 18px;
      font-weight: bold;
    `,
  };
};
