/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { EventInfo } from "utility";
import { EventVideo } from "../../../../../types/firebase/firestore";
import { ResGetStreamingKey } from "../../../../../types/functions";
import { EventVideoThumbnail } from "components/event/detail/videoList/EventVideoThumbnail";

type Props = {
  displayEvent: EventInfo;
  eventVideos: EventVideo[];
  streamingKey: ResGetStreamingKey;
  isOpenLiveStreamingPage: boolean;
  goLive: (vid: string) => void;
};
export const EventVideoThumbnailList: React.FC<Props> = (props) => {
  const {
    displayEvent,
    eventVideos,
    streamingKey,
    goLive,
    isOpenLiveStreamingPage,
  } = props;
  const classes = styles();

  return (
    <div css={classes.root}>
      {eventVideos.map((video, i) => {
        // @ts-expect-error TS2322
        if (isDisplayHiddenVideoId({ videoId: video._id })) return null;

        return (
          <EventVideoThumbnail
            key={i}
            {...{
              displayEvent,
              video,
              isOpenLiveStreamingPage,
              streamingKey,
              goLive,
            }}
          />
        );
      })}
    </div>
  );
};

/**
 * 一度公開したビデオをビデオ一覧から非表示にできないため、ハードコーディングで非表示にする
 */
const isDisplayHiddenVideoId = ({ videoId }: { videoId: string }) => {
  return [
    // 22111902-lvtokinosora5th
    "ze2m4reeUf1mLNnQsdTO", // 昼公演本編
    "hVBSYYwZ97JoWALF9UNO", // 昼公演本編 アフタートーク付き
    "GPx8BO1iyrVSvFiaoeZu", // 夜公演本編
    "Hkvx153BC4n9Jo6CSaVS", // 夜公演本編 アフタートーク付き
    // 2022123121-mzmcountdown
    "sGaSqc52e7Fw8v8az6rK", // 本編＋新年会
    // 23022514-sasakiyoshihide
    "W4UjwUyXxxy0ose2xIms", // 2部(オンライン)
  ].includes(videoId);
};

const styles = () => {
  return {
    root: css`
      display: flex;
      flex-wrap: wrap;
      gap: 18px;
      justify-content: space-between;
      &::after {
        @media screen and (min-width: 768px) {
          content: "";
          display: block;
          width: 31%;
        }
      }
    `,
  };
};
