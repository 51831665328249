/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "store";
import { liveActions } from "modules/live";
import { getLiveContextType } from "utility/live";
import { replaceBr, convertMapToValues } from "utility";
import { AllChannel, firestoreActions } from "modules/firestore";
import type { SurveyQuizData } from "@spwn/types/firebase/firestore";

interface Props {
  eventId: string;
  contextId: string;
  THEOPlayerElementHeight?: number;
  isDarkModeEnabled?: boolean;
}
export const StreamingLiveEventQuiz: React.FC<Props> = (props) => {
  const contextId = useSelector((state: Store) => state.live.contextId);
  const surveyData = useSelector(
    (state: Store) => state.live.surveyData
  ) as SurveyQuizData;

  // @ts-expect-error TS2345
  const [currentContextId, setCurrentContextId] = useState<string>(null);
  const [currentAnswerId, setCurrentAnswerId] = useState<number>(-1);

  const dispatch = useDispatch();

  const didMount = () => {
    return () => {
      dispatch(
        firestoreActions.closeChannel({
          channel: currentContextId as AllChannel,
        })
      );
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(didMount, []);

  const watchContextId = () => {
    dispatch(
      liveActions.watchSurveyData.started({
        nextContextId: contextId,
        currentContextId,
      })
    );
    setCurrentContextId(contextId);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(watchContextId, [contextId]);

  // @ts-expect-error TS7006
  const vote = (answerText: string, answerId) => {
    setCurrentAnswerId(Number(answerId));
    dispatch(
      liveActions.vote.started({
        answerText,
        answerId,
      })
    );
  };

  if (!props.eventId || !surveyData) {
    return null;
  }

  const { content } = surveyData;
  const classesLiveEventStyles = liveEventStyles({
    THEOPlayerElementHeight: props.THEOPlayerElementHeight,
    isDarkModeEnabled: props.isDarkModeEnabled,
  });

  const answerRenderSwitcher = () => {
    const contextType = getLiveContextType(contextId);
    const answers = convertMapToValues(surveyData.answers);
    switch (contextType) {
      case "qid":
        if (surveyData.hasImgs) {
          return answerType02(surveyData);
        } else {
          return answerType01(answers);
        }
      default:
        return answerType01(answers);
    }
  };

  const answerType01 = (answers: string[]) => {
    return (
      <div css={classesLiveEventStyles.quizLayout_01}>
        {answers.map((text, index) => {
          const answerClass =
            index === currentAnswerId ? "selected_answer" : "";
          return (
            <p
              key={index}
              className={answerClass}
              onClick={() => vote(text, index)}
            >
              {text}
            </p>
          );
        })}
      </div>
    );
  };

  const answerType02 = (surveyData: SurveyQuizData) => {
    return (
      <div css={classesLiveEventStyles.quizLayout_02}>
        {Object.keys(surveyData.answers).map((key, index) => {
          const text = surveyData.answers[key];
          // @ts-expect-error TS18048
          const img = surveyData.imgs[key];
          const answerClass =
            index === currentAnswerId ? "selected_answer" : "";
          return (
            <div
              key={index}
              className={answerClass}
              // @ts-expect-error TS2345
              onClick={() => vote(text, index)}
            >
              <img src={img} alt="" />
              <p>{replaceBr(text)}</p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div css={classesLiveEventStyles.root}>
      <h3 css={classesLiveEventStyles.headline}>{content}</h3>
      {answerRenderSwitcher()}
    </div>
  );
};

interface LiveEventStylesProps {
  THEOPlayerElementHeight?: number;
  isDarkModeEnabled?: boolean;
}
const liveEventStyles = (props: LiveEventStylesProps) => {
  const actionAreaHeight = props.THEOPlayerElementHeight
    ? css`
        height: calc(${props.THEOPlayerElementHeight}px - 46px);
      `
    : null;
  return {
    root: css`
      height: 280px;
      padding: 5%;
      border-top: 1px solid #e0e0e0;
      overflow-y: scroll;
      @media screen and (min-width: 768px) {
        ${actionAreaHeight}
        min-height: 415px;
        border: 1px solid #e0e0e0;
      }
    `,
    headline: css`
      margin-bottom: 16px;
      font-size: 14px;
      text-align: center;
      font-weight: normal;
      ${props.isDarkModeEnabled
        ? css`
            color: #fff;
          `
        : null}
    `,
    quizLayout_01: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      p {
        width: calc(50% - 4px);
        padding: 10px 0;
        margin-bottom: 8px;
        color: #00c2ae;
        font-size: 14px;
        text-align: center;
        letter-spacing: 1px;
        border: 1px solid #00c2ae;
        cursor: pointer;
        &.selected_answer {
          color: #fff;
          background-color: #00c2ae;
        }
      }
    `,
    quizLayout_02: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      div {
        width: calc(50% - 4px);
        padding: 16px 0;
        margin-bottom: 8px;
        color: #00c2ae;
        border: 1px solid #00c2ae;
        border-radius: 8px;
        cursor: pointer;
        &.selected_answer {
          color: #fff;
          background-color: #00c2ae;
        }
        img {
          display: block;
          width: calc(100% - 64px);
          margin: 0 auto 16px;
        }
        p {
          padding: 0 16px;
          font-size: 14px;
          text-align: center;
          letter-spacing: 1px;
        }
      }
    `,
  };
};
