/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useI18n } from "hooks/i18n/i18n";

interface Props {
  isDarkModeEnabled: boolean;
  registerCardInfo: ReqRegisterCardInfo;
  registerNewCard: () => void;
  setRegisterCardInfo: React.Dispatch<
    React.SetStateAction<ReqRegisterCardInfo>
  >;
  closeRegisterCard: () => void;
  checkInputValue: () => boolean;
}

interface ReqRegisterCardInfo {
  creditNumber: string;
  creditExpirationMonth: string;
  creditExpirationYear: string;
  creditName: string;
  creditSecurityCode: string;
}

export const StreamingLiveCommerceRegisterCredit: React.FC<Props> = (props) => {
  const classesRegisterCardStyles = registerCardStyles({
    isDarkModeEnabled: props.isDarkModeEnabled,
  });
  const { t } = useI18n();

  return (
    <React.Fragment>
      <div css={classesRegisterCardStyles.form}>
        <p>{t("streaming.commerceRegister.credit")}</p>
        <input
          type="text"
          maxLength={16}
          onChange={(e) =>
            props.setRegisterCardInfo({
              ...props.registerCardInfo,
              creditNumber: e.currentTarget.value,
            })
          }
        />
      </div>

      <div css={classesRegisterCardStyles.form}>
        <p>{t("streaming.commerceRegister.creditDate")}</p>
        <div>
          <input
            type="number"
            maxLength={4}
            min={0}
            onChange={(e) =>
              props.setRegisterCardInfo({
                ...props.registerCardInfo,
                creditExpirationMonth: e.currentTarget.value,
              })
            }
          />
          <input
            type="number"
            maxLength={4}
            min={0}
            onChange={(e) =>
              props.setRegisterCardInfo({
                ...props.registerCardInfo,
                creditExpirationYear: e.currentTarget.value,
              })
            }
          />
        </div>
      </div>

      <div css={classesRegisterCardStyles.form}>
        <p>{t("streaming.commerceRegister.creditName")}</p>
        <input
          type="text"
          onChange={(e) =>
            props.setRegisterCardInfo({
              ...props.registerCardInfo,
              creditName: e.currentTarget.value,
            })
          }
        />
      </div>

      <div css={classesRegisterCardStyles.form}>
        <p>{t("streaming.commerceRegister.securityCode")}</p>
        <input
          type="password"
          maxLength={4}
          onChange={(e) =>
            props.setRegisterCardInfo({
              ...props.registerCardInfo,
              creditSecurityCode: e.currentTarget.value,
            })
          }
        />
      </div>

      <div css={classesRegisterCardStyles.buttonList}>
        <div
          css={classesRegisterCardStyles.button}
          // @ts-expect-error TS2322
          className={props.checkInputValue() ? null : "disabled"}
          onClick={props.registerNewCard}
        >
          {t("streaming.commerceRegister.registerCard")}
        </div>

        <div
          css={classesRegisterCardStyles.cancel}
          onClick={props.closeRegisterCard}
        >
          戻る
        </div>
      </div>
    </React.Fragment>
  );
};

interface RegisterCardStylesProps {
  isDarkModeEnabled: boolean;
}
const registerCardStyles = (props: RegisterCardStylesProps) => {
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;

  const form = css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    p {
      width: 100%;
      margin-bottom: 8px;
      ${textColorInDarkMode}
      font-size: 14px;
    }
    input[type="text"] {
      width: 100%;
      padding: 8px;
      font-size: 16px;
      border: 1px solid #b1b1b1;
      border-radius: 5px;
      letter-spacing: 1px;
    }
    input[type="number"] {
      width: 56px;
      padding: 8px;
      margin-right: 8px;
      font-size: 16px;
      text-align: center;
      border: 1px solid #b1b1b1;
      border-radius: 5px;
      letter-spacing: 1px;
    }
    input[type="password"] {
      width: 50px;
      padding: 8px;
      font-size: 16px;
      text-align: center;
      border: 1px solid #b1b1b1;
      border-radius: 5px;
      letter-spacing: 1px;
    }
  `;
  const buttonList = css``;
  const button = css`
    padding: 12px 0;
    margin-bottom: 8px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    background-color: #00c2ae;
    cursor: pointer;
    &.disabled {
      background-color: #b1b1b1;
    }
  `;
  const cancel = css`
    padding: 10px 0;
    color: #00c2ae;
    font-size: 14px;
    text-align: center;
    border: 2px solid #00c2ae;
    cursor: pointer;
  `;
  return {
    form,
    buttonList,
    button,
    cancel,
  };
};
