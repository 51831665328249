import styled from "@emotion/styled";

export const PawInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5%;
  border-bottom: 1px solid #e6e5e6;
  background-color: #f9f9f9;
  .heading {
    @media screen and (min-width: 768px) {
      font-size: 20px;
    }
  }
`;

PawInfo.displayName = "PawInfo";
