import { useCallback } from "react";
import firebase from "firebase/app";
import type { PhoneCode } from "@spwn/types/gmo";
import {
  PurchaseErrorDetails,
  PurchaseRequest,
  PurchaseResponse,
} from "@spwn/types/functions/purchase";
import type {
  PurchaseExternalCheckoutErrorDetails,
  PurchaseExternalCheckoutRequest,
  PurchaseExternalCheckoutResponse,
} from "@spwn/types/functions/purchaseExternalCheckout";
import { useIdempotencyKey } from "./useIdempotencyKey";
import { useDispatch } from "react-redux";
import { modalActions } from "modules/modal";
import { hasHttpsError } from "utility/functionsError";
import { i18nextT } from "hooks/i18n/i18n";

export class DuplicateTransactionIdError extends Error {}

export type PurchaseProps = {
  commonProps: CommonProps;
  paymentProps:
    | {
        method: "Card";
        token: string;
      }
    | {
        method: "Member";
        securityCode: string;
        cardSeq: string;
      }
    | {
        method: "CVS";
        cvesCode: string;
        customerName: string;
        customerKana: string;
        telNo: string;
      }
    | {
        method: "Phone";
        phoneCode: PhoneCode;
      };
};

type CommonProps = {
  tenantId: string;
  totalAmount: number;
};

export type PurchaseFunction = (props: PurchaseProps) => Promise<void>;

/**
 *
 * @param callPurchaseAPI mock用なので渡さなくて良い
 * @param callPurchaseExternalCheckoutAPI mock用なので渡さなくて良い
 */
export const usePurchase = (
  callPurchaseAPI: (
    req: PurchaseRequest
  ) => Promise<PurchaseResponse> = callPurchase,
  callPurchaseExternalCheckoutAPI: (
    req: PurchaseExternalCheckoutRequest
  ) => Promise<PurchaseExternalCheckoutResponse> = callPurchaseExternalCheckout
): PurchaseFunction => {
  const dispatch = useDispatch();
  // NOTE: 一度開いた決済画面ではkeyが変わらない
  // @see https://balus3d.slack.com/archives/C02TG439KS7/p1658894659756699?thread_ts=1658888241.111709&cid=C02TG439KS7
  const [idempotencyKey, regenerateKey] = useIdempotencyKey();

  return useCallback<PurchaseFunction>(
    async ({ commonProps, paymentProps }) => {
      const common = {
        ...commonProps,
        idempotencyKey,
      };
      try {
        switch (paymentProps.method) {
          case "Card":
            await callPurchaseAPI({
              ...common,
              paymentType: "Card",
              paymentRequest: {
                token: paymentProps.token,
              },
            });
            return;
          case "Member":
            await callPurchaseAPI({
              ...common,
              paymentType: "Member",
              paymentRequest: {
                cardSeq: paymentProps.cardSeq,
                securityCode: paymentProps.securityCode,
              },
            });
            return;
          case "CVS":
            await callPurchaseAPI({
              ...common,
              paymentType: "CVS",
              paymentRequest: {
                cvesCode: paymentProps.cvesCode,
                customerName: paymentProps.customerName,
                customerKana: paymentProps.customerKana,
                telNo: paymentProps.telNo,
              },
            });
            return;
          case "Phone":
            await callPurchaseExternalCheckoutAPI({
              ...common,
              paymentType: "Phone",
              paymentRequest: {
                phoneCode: paymentProps.phoneCode,
              },
            });
            return;
          default: {
            const _expectNever: never = paymentProps;
            return;
          }
        }
      } catch (error: unknown) {
        /**
         * @description バックエンドからdetailsが帰ってきている場合は、hasHttpsErrorにdetailsの型定義を渡す。
         */
        if (
          hasHttpsError<
            PurchaseErrorDetails | PurchaseExternalCheckoutErrorDetails
          >(error)
        ) {
          if (error?.details?.errorType === "AlreadyExistsFingerprintError") {
            // 同じ内容のリクエストを検知
            dispatch(
              modalActions.toggleError({
                msg: i18nextT("purchase.purchase.duplicatedRequestError"),
              })
            );
          } else if (
            error?.details?.errorType === "AlreadyExistsOrderIdError"
          ) {
            // たまたま注文番号の発行に失敗した可能性がある
            dispatch(
              modalActions.toggleError({
                msg: i18nextT("purchase.purchase.congestionPurchase"),
              })
            );
          } else {
            dispatch(modalActions.toggleError({ msg: error.message }));
          }
          regenerateKey();
          return;
        }
        dispatch(modalActions.toggleError({ msg: error as string }));
      }
    },
    [idempotencyKey, regenerateKey] // eslint-disable-line react-hooks/exhaustive-deps
  );
};

const callPurchase = async (
  request: PurchaseRequest
): Promise<PurchaseResponse> => {
  const response = await firebase.functions().httpsCallable("purchase")(
    request
  );
  return response.data;
};

const callPurchaseExternalCheckout = async (
  request: PurchaseExternalCheckoutRequest
): Promise<PurchaseExternalCheckoutResponse> => {
  const response = await firebase
    .functions()
    .httpsCallable("purchaseExternalCheckout")(request);
  return response.data;
};
