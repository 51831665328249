import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Store } from "store";
// import { isForbiddenWord } from "utility/streaming";
import type { UserMessage } from "@spwn/types/firebase/firestore";

export const useFilteringComment = (
  commentList: UserMessage[]
): UserMessage[] => {
  const user = useSelector((state: Store) => state.auth.user);
  // const ngWords = useSelector(
  //   (state: Store) => state.streaming.streamingSettings.ngWords
  // );

  const comments = useMemo(() => {
    if (commentList.length > 0 && user) {
      const filteringCommentList: UserMessage[] = [];
      commentList.forEach((comment) => {
        if (comment.isBan === true && comment.uid !== user.uid) return;
        /**
         * FIXME 現状、コメント表示時に、全てのコメントに対してNGワードチェックを行うと処理負荷が高くコメント描画が遅くなるのでコメントアウト
         * コメント送信時にNGワードチェックしてるので、表示側は許容する
         * もっと効率いい処理にすれば表示側もチェック入れていい
         */
        // if (comment.isBan === false && isForbiddenWord(comment.msg, ngWords))
        //   return;
        filteringCommentList.push(comment);
      });
      return filteringCommentList;
    } else {
      return [];
    }
  }, [commentList, user /** ngWords */]);

  return comments;
};
