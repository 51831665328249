/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import type { ReceiptBreakdownItem } from "containers/account/receipt/AccountReceipt";
import logo from "designs/images/receipt/logo.jpg";
import image from "designs/images/receipt/image.jpg";
import { calcTaxDetails } from "containers/account/receipt/calcTaxDetails";
import { SHIPPING_FEE_LABEL } from "constants/fulfillment";

export const AccountReceiptForSmartphone: React.FC<{
  effectiveDateString: string;
  spwnId: string;
  orderId: number;
  proviso: string;
  total: number;
  breakdown: ReceiptBreakdownItem[];
  serviceFee: number;
  settlementFee: number;
  shippingFee: number;
  resetState: () => void;
}> = (props) => {
  const classes = styles();
  const { targetTotal, excludedTaxAmount } = calcTaxDetails(props.total);

  return (
    <div css={classes.root}>
      <section id="receipt" css={classes.receipt}>
        <div css={classes.headline}>
          <ul>
            <li>
              <span>発行日：</span>
              {props.effectiveDateString}
            </li>
            <li>
              <span>SPWN ID：</span>
              {props.spwnId}
            </li>
            <li>
              <span>オーダーID：</span>
              {props.orderId}
            </li>
          </ul>
        </div>

        <div css={classes.price}>
          <div css={classes.total}>
            <p>合計金額（税込）</p>
            <p>{props.total.toLocaleString()}円</p>
          </div>
          <p css={classes.text}>
            但、{props.proviso}購入代として
            <br />
            上記正に領収しました。
          </p>
        </div>

        <div css={classes.footer}>
          <div css={classes.classification}>
            <div css={classes.subtotal}>
              <p>小計</p>
              <p>{props.total.toLocaleString()}円</p>
            </div>

            <p>(内訳)</p>

            <ul css={classes.subtotalList}>
              {props.breakdown.map((item, index) => {
                return (
                  <li key={index}>
                    <p>
                      {item.eventTitle ? (
                        <React.Fragment>
                          {item.eventTitle}
                          {item.productType === "goods" && (
                            <React.Fragment>
                              <br />
                              {item.name}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>{item.name}</React.Fragment>
                      )}
                    </p>
                    <p>{(item.price * item.quantity).toLocaleString()}円</p>
                  </li>
                );
              })}

              {props.serviceFee > 0 && (
                <li>
                  <p>サービス手数料</p>
                  <p>{props.serviceFee.toLocaleString()}円</p>
                </li>
              )}

              {props.settlementFee > 0 && (
                <li>
                  <p>決済手数料</p>
                  <p>{props.settlementFee.toLocaleString()}円</p>
                </li>
              )}

              {props.shippingFee > 0 && (
                <li>
                  <p>{SHIPPING_FEE_LABEL}</p>
                  <p>{props.shippingFee.toLocaleString()}円</p>
                </li>
              )}

              <div
                css={css`
                  padding-top: 24px;
                  margin-top: 24px;
                  border-top: 1px solid #000;
                `}
              >
                <li>
                  <p>10%対象計</p>
                  <p>{targetTotal.toLocaleString()}円</p>
                </li>

                <li>
                  <p>消費税額</p>
                  <p>{excludedTaxAmount.toLocaleString()}円</p>
                </li>
              </div>
            </ul>
          </div>

          <div css={classes.company}>
            <div>
              <img src={logo} alt="バルス株式会社" className="logo" />
              <div css={classes.address}>
                <p>バルス株式会社</p>
                <p>登録番号：T2010001187346</p>
              </div>
            </div>
            <div css={classes.companyLayout}>
              <img src={image} alt="バルス株式会社" className="sign" />
            </div>
          </div>
        </div>
      </section>
      <div css={classes.buttonList}>
        <button onClick={props.resetState}>閉じる</button>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      padding-bottom: 40px;
      margin: 0 auto;
      letter-spacing: 1px;
      background-color: #fff;
    `,
    receipt: css`
      width: 90%;
      padding: 56px 0px;
      margin: 0 auto;
    `,
    headline: css`
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      ul {
        li {
          span {
            font-weight: bold;
          }
        }
      }
    `,
    price: css`
      margin: 0 auto 56px;
    `,
    text: css`
      font-size: 18px;
      text-align: center;
      line-height: 1.3em;
    `,
    total: css`
      display: flex;
      justify-content: space-between;
      padding: 24px 16px;
      margin-bottom: 24px;
      border: 1px solid #000;
      p {
        font-size: 18px;
        line-height: 1.3em;
      }
    `,

    footer: css`
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 40px;
    `,
    classification: css`
      width: 100%;
      padding: 24px 0;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
    `,
    subtotal: css`
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      p {
        font-size: 18px;
      }
    `,
    subtotalList: css`
      margin-top: 8px;
      li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        p {
          font-size: 12px;
          line-height: 1.5em;
          &:first-of-type {
            width: calc(100% - 84px);
          }
          &:last-child {
            width: 60px;
            margin-left: 16px;
            text-align: right;
          }
        }
      }
    `,
    buttonList: css`
      width: 90%;
      margin: 0 auto;
      button {
        display: block;
        width: 100%;
        padding: 8px 0;
        color: #00c2ae;
        font-size: 14px;
        font-weight: bold;
        background-color: #fff;
        border: 2px solid #00c2ae;
        cursor: pointer;
        outline: none;
        appearance: none;
      }
    `,
    company: css`
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      /* margin: auto 0; */
      .logo {
        display: block;
        width: 160px;
        margin-bottom: 24px;
      }
    `,
    companyLayout: css`
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .sign {
        width: 100px;
      }
    `,
    address: css`
      p {
        line-height: 1.5em;
      }
    `,
  };
};
