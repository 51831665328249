import React, { ComponentProps } from "react";
import Switch from "react-switch";

export type ToggleSwitchHandleChange = ComponentProps<
  typeof Switch
>["onChange"];

export const ToggleSwitch = (
  check: boolean,
  handleChange: ToggleSwitchHandleChange
) => {
  return (
    <Switch
      checked={check}
      onChange={handleChange}
      onColor="#86d3ff"
      onHandleColor="#2693e6"
      handleDiameter={15}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={10}
      width={30}
      className="react-switch"
      id="material-switch"
    />
  );
};
