/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import Slider from "react-slick";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { manualText, ManualTextItem } from "../../constants/manual";

interface Props {}

const UseGuideForPC: React.FC<Props> = (_props: Props) => {
  const settings = {
    dots: true,
    prevArrow: (
      <div className="prevArrow">
        <ArrowBackIcon color={"action"} fontSize={"large"} />
      </div>
    ),
    nextArrow: (
      <div className="nextArrow">
        <ArrowForwardIcon color={"action"} fontSize={"large"} />
      </div>
    ),
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const classesUseGuideDesign = useGuideDesign();
  const manualTextKeys = Object.keys(manualText);

  return (
    <React.Fragment>
      {manualTextKeys.map((key, index) => {
        // @ts-expect-error TS7053
        const manualTextMap: ManualTextItem = manualText[key];
        return (
          <article css={classesUseGuideDesign.root} key={index}>
            <h3>{manualTextMap.heading}</h3>
            <p>{manualTextMap.description}</p>
            <Slider {...settings} css={classesUseGuideDesign.slider}>
              {manualTextMap.slide.map((slide, index) => {
                return (
                  <div key={index}>
                    <div css={classesUseGuideDesign.sliderItem}>
                      <img src={slide.image} alt="" />
                      <div css={classesUseGuideDesign.sliderText}>
                        {slide.message}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </article>
        );
      })}
    </React.Fragment>
  );
};
export default UseGuideForPC;

const useGuideDesign = () => {
  return {
    root: css`
      margin-bottom: 100px;
      h3 {
        padding-bottom: 10px;
        margin-bottom: 15px;
        line-height: 1.3em;
        border-bottom: 1px solid #ccc;
      }
      p {
        margin-bottom: 10px;
        font-size: 15px;
        line-height: 1.5em;
        letter-spacing: 1px;
      }
    `,
    slider: css`
      margin-top: 30px;
    `,
    sliderItem: css`
      display: flex;
      justify-content: space-around;
      img {
        display: block;
        width: 250px;
      }
    `,
    sliderText: css`
      width: 390px;
      padding-top: 100px;
      p {
        margin-bottom: 20px;
      }
      ul {
        padding-left: 1.4em;
      }
      li {
        font-size: 15px;
        padding-left: 1.8em;
        margin-bottom: 10px;
        text-indent: -1.8em;
      }
      a {
        font-size: 15px;
      }
    `,
  };
};
