import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import type {
  ActiveTransaction,
  Event,
  MyEventTicketData,
  Place,
} from "@spwn/types/firebase/firestore";
import { useI18n } from "hooks/i18n/i18n";
import React, { FC, useMemo } from "react";
import { getDateTimeString, getEventDisplayInfo } from "utility";
import { AccountTicketItemHeading } from "./AccountTicketItemHeading";
import { AccountTicketItemTicketInfo } from "./AccountTicketItemTicketInfo";
import { getDisplayPrice } from "utility/ticket";
import { AccountTicketItemXShareButton } from "./AccountTicketItemXShareButton";
import { getTicketStatusLabel } from "utility/event";
import { AccountTicketItemGoodsInfo } from "./AccountTicketItemGoodsInfo";
import { AccountTicketListHaveNotTicket } from "./AccountTicketListHaveNotTicket";
import { AccountTicketActionButton } from "./AccountTicketActionButton";
import { AccountTicketDigitalContentsDownload } from "./AccountTicketDigitalContentsDownload";
import { IconTicket } from "components/icons/Ticket";
import { useLazyFetchTicketBonus } from "./useLazyFetchTicketBonus";

export const AccountTicketListBeforeEvent: FC<{
  eventMap: {
    [key: string]: Event;
  };
  myTickets: MyEventTicketData[];
  myUnprocessData: ActiveTransaction[];
  places: Place[] | undefined;
  onClickToggleConfirmCvsPayment: (transactionData: ActiveTransaction) => void;
}> = ({
  eventMap,
  myTickets,
  myUnprocessData,
  places,
  onClickToggleConfirmCvsPayment,
}) => {
  const { t } = useI18n();

  const { datum, IntersectionDetector } = useLazyFetchTicketBonus();

  /**
   * 将来的にこのコンポーネントにfetch処理が入るので、以下のfilter処理はここに置いておく。
   */
  const beforeMyTickets = useMemo(() => {
    return myTickets.length !== 0
      ? myTickets
          .filter(
            (el) =>
              el.event.isClosed === false &&
              el.event.datetime.seconds > Date.now() / 1000
          )
          .sort((a, b) => {
            const prev = Number(a.event.datetime.seconds);
            const cur = Number(b.event.datetime.seconds);
            return prev - cur;
          })
      : [];
  }, [myTickets]);

  if (beforeMyTickets.length === 0) {
    return <AccountTicketListHaveNotTicket />;
  }

  return (
    <Box width={["90%", "740px"]} margin={"0 auto"}>
      {beforeMyTickets.map((eventData) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const event = eventMap[eventData.event._id!]!;
        const {
          belongedHostings,
          eventTitle,
          eventOpenDateFull,
          eventOpenDay,
          eventOpenTime,
          eventEndDateFull,
          isLongEvent,
          artists,
          eventId,
          isHide,
          isFesEvent,
        } = getEventDisplayInfo(event);

        const { twitterHashTag } = eventData.event;
        const unprocessData = myUnprocessData.filter(
          (el) => eventData.unprocessOrderIds.indexOf(String(el.orderId)) >= 0
        );

        const hasTicket = eventData.tickets.some(
          (product) => product.status === "PAYSUCCESS"
        );
        const hasGoods = eventData.goods.some(
          (product) => product.status === "PAYSUCCESS"
        );

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const joinedTicketIds = eventData.tickets.map((t) => t._id!).join();
        const downloadContents = joinedTicketIds
          ? datum[eventId]?.[joinedTicketIds]?.flatMap((b) => ({
              bonusId: b.mediaId,
              fileName: b.filename,
              downloadUrl: b.urlForDownload,
              downloadExpiredAt: b.expiredAt,
              isDownloadPreparing: b.releaseAt
                ? b.releaseAt > new Date()
                : false,
            }))
          : [];

        return (
          <Box key={eventId} marginBottom={["20px", "40px"]}>
            {!!eventData.tickets.length && (
              <IntersectionDetector
                eventId={eventId}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ticketIds={eventData.tickets.map((t) => t._id!)}
              />
            )}
            <Box
              padding={["16px 5% 32px", "20px 20px 40px"]}
              marginTop={"-6px"}
              bgcolor={"#fff"}
              style={{
                border: "1px solid #d4d4d4",
              }}
            >
              <AccountTicketItemHeading
                eventId={eventId}
                eventTitle={eventTitle}
                artists={artists}
                belongedHostings={belongedHostings}
                eventOpenDateFull={eventOpenDateFull}
                eventEndDateFull={eventEndDateFull}
                eventOpenDay={eventOpenDay}
                eventOpenTime={eventOpenTime}
                isLongEvent={isLongEvent}
              />

              {eventData.tickets.map((product) => {
                const productName = product.name;
                const price = product.price_jpy.toLocaleString();
                const count = product.count.toString();
                const place = places?.find((el) => product.place === el.code);

                const label = product.labelStatus
                  ? getTicketStatusLabel(product.labelStatus)
                  : "";
                const vodExpiredAt = getDateTimeString(
                  // @ts-expect-error TS2345
                  product.vodExpiredAt
                );
                return (
                  <Box key={product._id}>
                    <AccountTicketItemTicketInfo
                      ticketStateImageUrl={label}
                      place={place}
                      isFesEvent={isFesEvent}
                      productName={productName}
                      event={event}
                      vodExpiredAt={vodExpiredAt}
                      description={product.description}
                      price={price}
                      count={count}
                    />
                  </Box>
                );
              })}

              {eventData.goods.map((product) => {
                const productName = product.name;
                const price = getDisplayPrice(product).toLocaleString();
                const count = product.count.toString();
                const place = places?.find((el) => product.place === el.code);
                const subClassName =
                  product.subClassName && `（${product.subClassName}）`;
                return (
                  <Box key={product._id}>
                    <AccountTicketItemGoodsInfo
                      productName={productName}
                      subClassName={subClassName}
                      price={price}
                      count={count}
                      place={place}
                    />
                  </Box>
                );
              })}

              <Box
                width={["100%", "500px"]}
                margin={["32px auto 0", "40px auto 0"]}
              >
                {unprocessData.length !== 0 &&
                  unprocessData.map((transactionData, i) => {
                    const handleOnClickToggleConfirmCvsPayment = () => {
                      onClickToggleConfirmCvsPayment(transactionData);
                    };
                    return (
                      <Box marginBottom={"16px"} key={i}>
                        <AccountTicketActionButton
                          as="button"
                          variant={"caution"}
                          onClick={handleOnClickToggleConfirmCvsPayment}
                        >
                          {t("ticket.confirmSettlement")}
                        </AccountTicketActionButton>
                      </Box>
                    );
                  })}

                {(hasTicket || hasGoods) && (
                  <React.Fragment>
                    {/* フェスイベントはデジタルチケット未対応なのでイベントページに遷移させる */}
                    <Box>
                      {isFesEvent ? (
                        <AccountTicketActionButton
                          as="link"
                          href={`/events/${eventId}`}
                          variant={"normal"}
                        >
                          {t("ticket.moveEventDetail")}
                        </AccountTicketActionButton>
                      ) : (
                        <AccountTicketActionButton
                          as="link"
                          href={`/account/ticket/${eventId}`}
                          variant={"normal"}
                        >
                          <>
                            <IconTicket
                              style={{
                                marginRight: "8px",
                              }}
                            />
                            {t("ticket.viewDigitalTicket")}
                          </>
                        </AccountTicketActionButton>
                      )}
                    </Box>

                    {downloadContents ? (
                      downloadContents.map((downloadContent) => (
                        <AccountTicketDigitalContentsDownload
                          key={downloadContent.bonusId}
                          {...downloadContent}
                        />
                      ))
                    ) : (
                      <Box marginTop={"16px"}>
                        <Skeleton
                          variant="rect"
                          width="100%"
                          height={48}
                          animation="wave"
                        />
                      </Box>
                    )}

                    {!isHide && (
                      <Box marginTop={"16px"}>
                        <AccountTicketItemXShareButton
                          eventId={eventId}
                          eventTitle={eventTitle}
                          twitterHashTag={twitterHashTag}
                        />
                      </Box>
                    )}
                  </React.Fragment>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
