import firebase from "firebase/app";
import "firebase/auth";

const oauthProviderIds = [
  firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  firebase.auth.TwitterAuthProvider.PROVIDER_ID,
  firebase.auth.FacebookAuthProvider.PROVIDER_ID,
];

export const getProviderName = (providerId: string) => {
  switch (providerId) {
    case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
      return "Google";
    case firebase.auth.TwitterAuthProvider.PROVIDER_ID:
      return "Twitter";
    case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
      return "Facebook";
    case firebase.auth.EmailAuthProvider.PROVIDER_ID:
      return "メールアドレス";
    default:
      // 対応していないはずのAuth認証
      return null;
  }
};

export const isOAuthProvider = (providerId: string): boolean => {
  return oauthProviderIds.includes(providerId);
};

export const hasOAuthProvider = (providerIds: string[]): boolean => {
  const providers = providerIds.filter((el) => isOAuthProvider(el));
  return providers.length > 0;
};

/**
 * check if user has email provider data
 * @param user
 */
export const hasEmailProviderData = (user: firebase.User): boolean => {
  const havingProviderIdList = user.providerData.map(
    // @ts-expect-error TS18047
    (provider) => provider.providerId
  );
  return havingProviderIdList.includes(
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  );
};

/**
 * check if user need reauth
 * @param user
 */
export const isReAuthRequiredToEditAddress = (
  user: firebase.User,
  isWhenPageEnter = false
): boolean => {
  // @ts-expect-error TS2769
  const lastSignInTime = new Date(user.metadata.lastSignInTime);
  const now = new Date();
  // 登録APIの再認証要求までの時間は30分だが、
  // 再認証後に登録処理を行ってもらうようにするためフロントは短めにする。
  // isWhenPageEnter 判定はなるべくページに入ったら再認証をしてもらうようにするため
  // 時間を延ばした経緯: https://github.com/balus-co-ltd/spwn-web/issues/274
  const reauthRequirationSeconds = isWhenPageEnter ? 900 : 1500;
  return (
    lastSignInTime.getTime() + reauthRequirationSeconds * 1000 < now.getTime()
  );
};
