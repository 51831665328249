import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { modalActions } from "modules/modal";
import appConfig from "constants/appConfig";
import { useGetEventList } from "hooks/event/useGetEventList";
import { useGetContactCategoryList } from "features/contact/useGetContactCategoryList";
import { BreadcrumbArea } from "components/common/Link";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { Store } from "store";
import { getNowTimestamp, getCookieValue } from "utility";
import {
  ContentsLayout,
  LinkButton,
  SubPageSection,
  WidthDefinedButtonList,
} from "styles";
import { TextLink } from "ui/TextLink";

const Contact: React.FC = () => {
  const { data: events } = useGetEventList({
    type: "open",
  });
  const { data: categories } = useGetContactCategoryList();
  const user = useSelector((state: Store) => state.auth.user);

  const dispatch = useDispatch();

  // this uid is latest login user's
  const spwnUserId = user.uid || getCookieValue("uid");
  const lastLoginDomain = getCookieValue("last-domain");

  const send = () => {
    const name = document.getElementById("name") as HTMLInputElement;
    const email = document.getElementById("email") as HTMLInputElement;
    const spwnId = document.getElementById("spwnId") as HTMLInputElement;
    const select = document.getElementById("category") as HTMLSelectElement;
    const eventSelect = document.getElementById("event") as HTMLSelectElement;
    const category = select.options[select.selectedIndex];
    const eventTitle = eventSelect.options[eventSelect.selectedIndex];

    const message = document.getElementById("msg") as HTMLTextAreaElement;
    if (name.value.length <= 0) {
      dispatch(modalActions.toggleError({ msg: "お名前が未入力です" }));
      return;
    }
    if (email.value.length <= 0) {
      dispatch(modalActions.toggleError({ msg: "メールアドレスが未入力です" }));
      return;
    } else if (!email.checkValidity()) {
      dispatch(
        modalActions.toggleError({ msg: "メールアドレスを入力してください" })
      );
      return;
    }
    // @ts-expect-error TS18048
    if (category.value === "none") {
      dispatch(
        modalActions.toggleError({ msg: "お問い合わせ項目が未選択です" })
      );
      return;
    }
    // @ts-expect-error TS18048
    if (eventTitle.value === "none") {
      dispatch(
        modalActions.toggleError({ msg: "お問い合わせイベントが未選択です" })
      );
      return;
    }
    if (message.value.length <= 0) {
      dispatch(
        modalActions.toggleError({ msg: "お問い合わせ内容が未入力です" })
      );
      return;
    }
    const msg = {
      name: name.value,
      // uid priority: user in this domain , user logined in any spwn domains
      uid: spwnId.value || "未ログイン",
      email: email.value,
      // @ts-expect-error TS18048
      category: category.value,
      // @ts-expect-error TS18048
      eventTitle: `${eventTitle.value} ${
        lastLoginDomain ? `(${lastLoginDomain})` : ""
      }`,
      message: message.value,
      isSelfReportUid: !spwnUserId && spwnId.value, // if user input spwn id by ownself, send the flag
      isSelfReportEmail: !user.email && email.value, // if user input email by ownself, send the flag
    };
    // @ts-expect-error TS2769
    const promise = fetch(appConfig.CloudFunctions.sendQuery, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(msg),
    });
    dispatch(modalActions.toggleNotice({ msg: "送信中です" }));
    promise
      .then((response) => {
        if (response.status !== 200) {
          dispatch(modalActions.toggleNotice({}));
          dispatch(
            modalActions.toggleError({
              msg: "送信に失敗しました．もう一度お試しください",
            })
          );
        } else {
          clear();
          dispatch(modalActions.toggleNotice({}));
          dispatch(
            modalActions.toggleNotice({
              msg: "お問い合わせ内容を送信しました．",
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(modalActions.toggleNotice({}));
        dispatch(
          modalActions.toggleError({
            msg: "送信に失敗しました．もう一度お試しください",
          })
        );
      });
  };

  const clear = () => {
    const name = document.getElementById("name") as HTMLInputElement;
    name.value = "";
    const msg = document.getElementById("msg") as HTMLTextAreaElement;
    msg.value = "";
  };

  if (!categories) {
    return (
      <div id="contents-layout">
        <LoaderLayout />
      </div>
    );
  }

  return (
    <SubPageSection id="contents">
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, "お問い合わせ"],
        ]}
      />
      <ContentsLayout>
        <h2>お問い合わせ</h2>
        <p className="contact_sentence">
          <TextLink href={"https://spwn.zendesk.com/hc/ja"}>
            よくある質問
          </TextLink>
          、<TextLink href={"/event-notes"}>イベントの注意事項</TextLink>
          をご確認いただき、その他にお問い合わせがある場合は以下のフォームより送信してください。
          <br />
          <br />
          退会（アカウントの削除）をご希望の方は、
          <TextLink href={"/account/edit/delete"}>こちら</TextLink>
          からお願いいたします。
          <br />
          <br />
          営業日：月曜日から金曜日（但し祝日、年末年始は除く）
          <br />
          状況によっては2-3営業日程度、返信までにお時間を頂戴する可能性がございます。
        </p>

        <article className="text_block">
          <h3>お名前</h3>
          <input type="text" name="name" id="name" />
        </article>

        <article className="text_block">
          <h3>メールアドレス</h3>
          {user.email ? (
            <input
              type="email"
              name="address"
              id="email"
              value={user.email}
              readOnly
            />
          ) : (
            <input type="email" name="address" id="email" />
          )}
        </article>

        <article className="text_block">
          <h3>SPWN ID</h3>
          {spwnUserId ? (
            <input
              type="text"
              name="spwnId"
              id="spwnId"
              value={spwnUserId}
              readOnly
            />
          ) : (
            <input type="text" name="spwnId" id="spwnId" />
          )}
        </article>

        <article className="text_block">
          <h3>お問い合わせ項目</h3>
          <select name="category" id="category">
            <option value="none">お問い合わせ項目を選択してください。</option>
            {categories.length > 0
              ? categories
                  .sort((a, b) => a.priority - b.priority)
                  .map((data, idx) => {
                    return (
                      <option key={`category_${idx}`} value={data.text}>
                        {data.text}
                      </option>
                    );
                  })
              : ""}
          </select>
        </article>

        <article className="text_block">
          <h3>お問い合わせイベント</h3>
          <select name="event" id="event">
            <option value="none">
              お問い合わせイベントを選択してください。
            </option>
            <option>該当なし</option>
            {events && events.length > 0
              ? events
                  .filter(
                    (event) =>
                      event.open.seconds < getNowTimestamp() &&
                      getNowTimestamp() <
                        event.datetime.seconds + 60 * 60 * 24 * 31
                  )
                  .map((data, idx) => {
                    const title = data.title.replace(
                      /(<br>|<br \/>|<br\/>)/gi,
                      " "
                    );
                    return (
                      <option key={`events_${idx}`} value={title}>
                        {title}
                      </option>
                    );
                  })
              : null}
          </select>
        </article>

        <article className="text_block">
          <h3>お問い合わせ内容</h3>
          <textarea id="msg" name="msg"></textarea>
        </article>

        <WidthDefinedButtonList>
          <LinkButton onClick={send}>上記内容で問い合わせる</LinkButton>
        </WidthDefinedButtonList>
      </ContentsLayout>
    </SubPageSection>
  );
};

export default Contact;
