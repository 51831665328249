/**
 * 共有用Tag
 * @see https://tagmanager.google.com/?authuser=1#/container/accounts/4701243563/containers/11091738/workspaces/57/folders
 * shareの推奨イベントに則っている
 * @see [推奨イベント - share | Google アナリティクス 4 プロパティ | Google for Developers](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=620233602796859159-AP&hl=ja&client_type=gtm#share)
 */
type EventShare = {
  event: "share";
  method: "Twitter";
  content_type: "event";
  /**
   * content_type
   * - 'event'
   * eventId
   */
  item_id: string;
  eid: string;
};

/**
 * ページが切り替わるタイミングでpushする
 * データレイヤー変数のuidやdomainを後続のイベントに反映するために利用している
 * そのためeventを指定していない
 * 例えば、EventMessage → EventShare の順にイベントが発行された場合、EventShareイベントにもuid, domainが送信される
 */
type EventMessage = {
  uid: string;
  domain: string;
};

/**
 * add_to_cart 推奨イベント
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=620233602796859159-AP&hl=ja&client_type=gtm#add_to_cart
 *
 * NOTE: GTM - 詳細設定 - e コマースデータを送信 を有効にすることで、ecommerce オブジェクトが認識される
 *
 * カート追加が行われる箇所
 * - チケット
 * - グッズ
 * - ライブコマース
 *   - GAイベント未対応
 * - テナントグッズ
 *   - GAイベント未対応
 * - フェスチケット
 *   - GAイベント未対応
 */
type EventAddToCart = {
  event: "add_to_cart";
  eid: string;
  ecommerce: {
    /**
     * イベントに関連付けられた商品アイテムの通貨
     * 2023/08時点で日本円での販売のみのため、JPY固定
     */
    currency: "JPY";
    value: number;
    items: EcommerceEventItem[];
  };
};

/**
 * purchase 推奨イベント
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=620233602796859159-AP&hl=ja&client_type=gtm#purchase
 *
 * NOTE: GTM - 詳細設定 - e コマースデータを送信 を有効にすることで、ecommerce オブジェクトが認識される
 *
 * 測定箇所
 * 決済ページの決済
 * - 新規カード決済
 * - 登録カード決済
 * - コンビニ決済
 * - キャリア決済
 * - 無料決済（申込）
 *   ※申込となるため、収益として換算はしていない。別のイベントを利用した方がいいかも。
 *
 * 未測定箇所
 * - カプセル決済
 * - ライブコマース
 * - crew ファンクラブ（別プロダクト管理となっているため）
 */
type EventPurchase = {
  event: "purchase";
  ecommerce: {
    /**
     * イベントに関連付けられた商品アイテムの通貨
     * 2023/08時点で日本円での販売のみのため、JPY固定
     */
    currency: "JPY";
    /**
     * 取引を一意に識別する ID
     */
    transaction_id: string;
    /**
     * イベントの金銭的価値
     */
    value: number;
    /**
     * 取引に関連付けられた送料。
     */
    shipping?: number;
    /**
     * eCommerce イベントのitem配列
     */
    items: EcommerceEventItem[];
  };
};

/**
 * eCommerce イベントのitem。
 *
 * - 想定しているeCommerceのイベント
 * add_to_cart, view_item, begin_checkout, purchase
 * それぞれで同じパラメータのためまとめている
 */
type EcommerceEventItem = {
  /**
   * 商品アイテムのブランド。
   * @example hololive
   *
   * NOTE: ブランド名がGAに保存されていれば、spwn側でテナントと結びつけることができる想定。
   * ただし、現状Eventがブランド情報を持っていないため、
   */
  item_brand?: string;
  /**
   * 商品アイテムのカテゴリ。カテゴリの階層または分類の一部として使用されている場合は、これが最初のカテゴリになります。
   *
   * NOTE: 商品の種類を指定する。種類が増える場合は追加する。
   * item_categoryはこのフィールドを含め、1~5まで設定可能だが、現状は増やしていない。設定ルールを決めていないため。
   */
  item_category: "ticket" | "goods";
  /**
   * 商品アイテムのリストID
   *
   * NOTE: spwnではeventIdを指定することがある。eventIdで指定して、商品を特定することになるため、ある種リストIDと捉えられる。
   */
  item_list_id?: string;
  /**
   * 商品アイテムの ID。
   */
  item_id: string;
  /**
   * 商品アイテムの名前。
   * イベントIDがある場合は、イベンtIDを末尾につけるようにする（集計テスト用）。 createEcommerceEventItemName を利用する。
   */
  item_name: string;
  /**
   * 商品アイテムのバリエーションまたは固有のコード、あるいはその他の詳細やオプションに関する説明。
   */
  item_variant?: string;
  /**
   * 商品アイテムの数量。
   */
  quantity: number;
  /**
   * アイテムの通貨価格（指定した通貨パラメータの単位）。
   */
  price: number;
};

/**
 * 動画再生イベント（カスタムイベント）
 * ※npawのデータ欠落に対する処置のため、予告なくOmitされる可能性があります
 */
type EventVideoPlayCustom = {
  event: "spwn_video_start";
  video_id: string;
  video_player_type: "theoplayer" | "aws";
};

/**
 * イベントごとのデータレイヤー型
 */
export type DataLayerType =
  | EventShare
  | EventMessage
  | EventAddToCart
  | EventPurchase
  | EventVideoPlayCustom;

/**
 * ECイベントのitem_nameを作成する
 *
 * NOTE: 集計テスト用だが、ルールがあるため専用のfunctionを作成している
 */
export const createEcommerceEventItemName = ({
  itemName,
  eventId,
}: {
  itemName: string;
  eventId: string;
}) => `${itemName} ${eventId}`;
