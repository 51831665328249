import styled from "@emotion/styled";
import { fontDinMixin } from "../../mixin";

const _EventCard = styled.article`
  a {
    display: block;
    &:hover {
      opacity: 0.9;
    }
  }
  .datetime_area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 6%;
    margin-bottom: -15px;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
    }
    .datetime {
      ${fontDinMixin};
      color: #fff;
      font-size: 5.5vw;
    }
    .status {
      @media screen and (min-width: 768px) {
        padding: 0;
      }
    }
  }
  .thumbnail_area {
    position: relative;
    background-color: #fff;
    .event_img {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .event_place {
      position: absolute;
      bottom: -1px;
      right: 0;
      width: 50%;
      padding: 8px 0 8px 20px;
      color: #000;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      background: linear-gradient(135deg, transparent 21px, #fff 21px);
      @media screen and (min-width: 768px) {
        width: 170px;
        font-size: 14px;
      }
    }
  }
  .information_area {
    background-color: #fff;
    @media screen and (min-width: 768px) {
      padding: 10px 15px 40px;
    }
    .event_title {
      line-height: 1.4em;
    }
    .event_artist {
      color: #707070;
      font-size: 13px;
      font-weight: bold;
      line-height: 1.5em;
      @media screen and (min-width: 768px) {
        min-height: 42px;
        font-size: 14px;
      }
    }
  }
`;

export const EventMainCard = styled(_EventCard)`
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 48%;
  }
  .datetime_area {
    width: 85%;
    margin-left: -10px;
    background: linear-gradient(315deg, transparent 10px, #141718 10px);
    z-index: 1;
    @media screen and (min-width: 768px) {
      width: 55%;
      padding: 10px 22px;
    }
    &::before {
      bottom: -10px;
      width: 10px;
      height: 10px;
      background: linear-gradient(45deg, transparent 7px, #252e33 7px);
    }
    .datetime {
      font-size: 5.5vw;
      @media screen and (min-width: 768px) {
        width: 120px;
        font-size: 20px;
      }
      span {
        font-size: 5vw;
        @media screen and (min-width: 768px) {
          font-size: 19px;
        }
      }
    }
    .status {
      width: 90px;
      img {
        width: 75%;
        @media screen and (min-width: 768px) {
          width: 80%;
        }
      }
    }
  }
  .information_area {
    padding: 4% 4% 6%;
    .event_title {
      margin-bottom: 8px;
      @media screen and (min-width: 768px) {
        min-height: 56px;
        margin-bottom: 15px;
        font-size: 20px;
      }
    }
  }
`;

EventMainCard.displayName = "EventMainCard";

export const EventCard = styled(_EventCard)`
  .datetime_area {
    width: 100%;
    margin-left: -7px;
    background: linear-gradient(315deg, transparent 6px, #141718 6px);
    @media screen and (min-width: 768px) {
      width: 82%;
      padding: 10px 22px;
      background: linear-gradient(315deg, transparent 14px, #141718 14px);
    }
    &:before {
      bottom: -7px;
      width: 7px;
      height: 7px;
      background: linear-gradient(45deg, transparent 5px, #252e33 5px);
    }
    .datetime {
      width: 65%;
      font-size: 4vw;
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
      span {
        font-size: 4vw;
        @media screen and (min-width: 768px) {
          font-size: 19px;
        }
      }
    }
    .status {
      width: 50px;
      @media screen and (min-width: 768px) {
        width: 65px;
      }
      img {
        width: 100%;
        @media screen and (min-width: 768px) {
          width: 80%;
        }
      }
      &.to_be_released span:last-child {
        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
  .thumbnail_area {
    .event_place {
      display: none;
      @media screen and (min-width: 768px) {
        display: block;
      }
    }
  }
  .information_area {
    padding: 6% 7% 12%;
    .event_title {
      min-height: 50px;
      margin-bottom: 5px;
      font-size: 13px;
      @media screen and (min-width: 768px) {
        min-height: 85px;
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    .event_artist {
      min-height: 40px;
    }
  }
`;

EventCard.displayName = "EventCard";
