import React from "react";
import { useTranslation, Trans } from "react-i18next";
import i18nStringWrapper from "i18n/i18nStringWrapper";
import i18next from "i18next";

export const useI18n = () => {
  const { t, i18n } = useTranslation();
  return {
    // @ts-expect-error TS7006
    t: (str: Parameters<typeof i18nStringWrapper>[0], option?) =>
      t(i18nStringWrapper(str), option),
    i18n,
  };
};

export const i18nextT = (
  str: Parameters<typeof i18nStringWrapper>[0],
  // @ts-expect-error TS7006
  option?
) => {
  return i18next.t(str, option);
};

interface i18nParameter {
  i18nKey: Parameters<typeof i18nStringWrapper>[0];
}

export const I18nTrans: React.FC<i18nParameter> = (props) => {
  return <Trans i18nKey={props.i18nKey}>{props.children}</Trans>;
};
