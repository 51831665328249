import firebase from "firebase/app";
import {
  AddItemsToCartRequest,
  AddItemsToCartResponse,
  AddItemsToCartErrorDetails,
} from "@spwn/types/functions";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { loadingActions } from "../modules/loading";
import { i18nextT } from "../hooks/i18n/i18n";
import { modalActions } from "modules/modal";
import { cartActions } from "modules/cart";
import { hasHttpsError } from "../utility/functionsError";

export type AddItemsToCartArgs = AddItemsToCartRequest;

type UseAddItemsToCart = () => {
  addItemsToCart: (request: AddItemsToCartArgs) => Promise<void>;
};

export const useAddItemsToCart: UseAddItemsToCart = () => {
  const dispatch = useDispatch();
  return {
    addItemsToCart: useCallback(
      async (args) => {
        try {
          dispatch(
            loadingActions.toggleLoading({
              msg: i18nextT("cart.msg.nowCheckCart"),
            })
          );
          const response = await addItemsToCart(firebase.functions())(args);
          if ("error" in response) {
            dispatch(modalActions.toggleError({ msg: response.error.message }));
            return;
          }
          /**
           * カートを確認するためのモーダルを開く
           * @see https://github.com/balus-co-ltd/spwn/blob/ffbf80e9c069d326e935c9a181ea7e0dbfa6b708/packages/portal/src/modules/cart.tsx#L316-L327
           */
          dispatch(
            modalActions.toggleActionModal({
              actionModalType: "confirmAction",
              caption: i18nextT("cart.modal.addCartModal"),
              msg: "",
              btnMsg: i18nextT("cart.msg.checkCart"),
              action: () => {
                dispatch(cartActions.toggleMyCart({ isOpen: true }));
              },
              // @ts-expect-error TS2322
              args: null,
            })
          );
        } catch (error) {
          console.error(error);
          if (hasHttpsError<AddItemsToCartErrorDetails>(error)) {
            // error.code や error.detailsによって処理変える必要あれば変える
            dispatch(modalActions.toggleError({ msg: error.message }));
            return;
          }
          // 予期せぬエラー
          dispatch(
            modalActions.toggleError({
              msg: i18nextT("cart.msg.unexpectedError"),
            })
          );
        } finally {
          dispatch(loadingActions.toggleLoading({}));
        }
      },
      [dispatch]
    ),
  };
};

const addItemsToCart =
  (functions: firebase.functions.Functions) =>
  async (request: AddItemsToCartRequest): Promise<AddItemsToCartResponse> => {
    const response = await functions.httpsCallable("addItemsToCart")(request);
    return response.data;
  };
