import { useQuery } from "../../utility/useQuery";

/**
 * クエリパラメータからdecode済みのreturn_urlを取得する
 * 不正なURLの場合はundefinedを返す
 *
 * @example
 * const returnUrl = useValidReturnUrlQuery();
 */
export const useValidReturnUrlQuery = (): string | undefined => {
  const query = useQuery();
  const returnUrl = query.get("return_url");
  if (!returnUrl) return undefined;
  return isValidSPWNServiceUrl(returnUrl) ? returnUrl : undefined;
};

export const isValidSPWNServiceUrl = (url: string) => {
  try {
    const target = new URL(url);
    if (target.protocol !== "https:") return false;
    const domainNames = (
      process.env.REACT_APP_SPWN_DOMAIN_NAMES ?? "spwn.jp"
    ).split(",");
    // 指定されたドメイン名そのものか、そのサブドメインであれば許可
    return domainNames.some(
      (domainName) =>
        target.hostname.endsWith(`.${domainName}`) ||
        target.hostname === domainName
    );
  } catch (e) {
    return false;
  }
};
