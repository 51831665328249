import React, { FC } from "react";
import styled from "@emotion/styled";
import logo_spwn from "../../designs/images/logo_spwn.svg";

export const TopCatchCopy: FC = () => {
  return (
    <CatchCopyContainer id="catchCopyDisplayArea">
      <img src={logo_spwn} alt="SPWN" className="spwnLogo" />

      <div className="makeCharacterBold">
        <p>その空間は、突然あらわれる。</p>
        <p>その空間は、突然つくりだす。</p>
        <p>新たな熱狂を、この世界のどこへでも。</p>
      </div>

      <div className="spwnDescription">
        <p>SPWNはオンライン上のエンターテイメントスペースです。</p>
        <p>
          名称としてかかげる『SPWN（スポーン）』は、ゲームなどで使われる英単語「SPAWN」の「生まれる」「出てくる」と行った発生地点を意味します。
        </p>
        <p>
          オンラインに限らず、ライブハウスをはじめとする、全国の劇場、映画館、イベントスペースなどのあらゆるスペースとオンラインをつなぎアーティストのステージに。
        </p>
        <p>
          WEBアプリと連動することで、アーティストとファンが一緒に作っていく参加型ライブを実現。
        </p>
        <p>世界中にXRエンターテイメントの次世代の熱狂を届けていきます。</p>
      </div>
    </CatchCopyContainer>
  );
};

const CatchCopyContainer = styled.div`
  width: 980px;
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  .spwnLogo {
    width: 55%;
    margin-bottom: 40px;
    @media screen and (min-width: 768px) {
      width: 440px;
      margin-bottom: 90px;
    }
  }
  .makeCharacterBold {
    margin-bottom: 60px;
    @media screen and (min-width: 768px) {
      margin-bottom: 90px;
    }
    p {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        font-size: 24px;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
        @media screen and (min-width: 768px) {
          margin-bottom: 40px;
        }
      }
    }
  }
  .spwnDescription {
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 600px;
    }
    p {
      color: #fff;
      font-size: 12px;
      text-align: left;
      line-height: 1.8em;
      @media screen and (min-width: 768px) {
        font-size: 16px;
        text-align: center;
        line-height: 2.5em;
      }
      &:not(:last-child) {
        margin-bottom: 5px;
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
        }
      }
    }
  }
`;
