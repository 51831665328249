import { ButtonBase } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React, { FC, useMemo } from "react";
import ticket_icon from "designs/images/ticket_icon.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Store } from "store";

export const TodayEventFloatingButtonLogic: FC = () => {
  const currentDatetime = new Date().getTime();
  const myTickets = useSelector((state: Store) => state.ticket.myTickets);

  const ticketInSessionEvent = useMemo(() => {
    if (myTickets === null || myTickets.length === 0) {
      return [];
    }

    return myTickets
      .filter((el) => {
        // イベントが終了している場合は表示しない
        if (el.event.isClosed) return false;
        // myTicketsには、チケットがないイベントも含まれている（Goodsなど）ため、チケットがないイベントは表示しない
        if (el.tickets.length === 0) return false;
        // event.partsがない場合は表示しない
        if (el.event.parts.length === 0) return false;

        const { eventStartTime } = el.event;
        if (eventStartTime === undefined) return false;

        /**
         * @description
         * イベントが開催中のものを抽出する処理
         *
         * イベントの開催日時は、event.partsに格納されている。
         * イベントの開催中の判定は、event.parts.openTime < 現在時刻 < event.parts.openTimeの23:59:59 で行なっている。
         * イベント終了の判定をevent.parts.openTimeの23:59:59で行なっている理由は、DBにイベント終了日時が格納されていないため。
         * event.partsは配列なので、filterした結果が空の場合は、開催中のイベントがないと判定する。
         */
        const inSessionEvent = el.event.parts.filter((part) => {
          const eventOpenDate = new Date(part.openTime.seconds * 1000);
          const eventOpenDatetime = new Date(
            eventOpenDate.getFullYear(),
            eventOpenDate.getMonth(),
            eventOpenDate.getDate(),
            0,
            0,
            0
          ).getTime();
          const eventEndDatetime = new Date(
            eventOpenDate.getFullYear(),
            eventOpenDate.getMonth(),
            eventOpenDate.getDate(),
            23,
            59,
            59
          ).getTime();

          return (
            eventOpenDatetime < currentDatetime &&
            currentDatetime < eventEndDatetime
          );
        });

        return inSessionEvent.length > 0;
      })
      .sort((a, b) => {
        const prev = Number(a.event.datetime.seconds);
        const cur = Number(b.event.datetime.seconds);
        return cur - prev;
      });
  }, [currentDatetime, myTickets]);

  if (ticketInSessionEvent.length === 0 || !ticketInSessionEvent[0]) {
    return null;
  }

  return (
    <TodayEventFloatingButton
      href={
        ticketInSessionEvent.length === 1
          ? `/account/ticket/${ticketInSessionEvent[0].event._id}`
          : "/account/ticket?tabState=inSession"
      }
      label={ticketInSessionEvent.length === 1 ? "本日のチケット" : "購入済み"}
      subLabel={
        ticketInSessionEvent.length === 1
          ? ticketInSessionEvent[0].event.title
          : "本日のチケット"
      }
    />
  );
};

const TodayEventFloatingButton: FC<{
  href: string;
  label: string;
  subLabel: string;
}> = ({ href, label, subLabel }) => {
  return (
    <Box
      position={"fixed"}
      bottom={["16px", "24px"]}
      right={["16px", "24px"]}
      zIndex={1000}
    >
      <ButtonBase
        style={{
          backgroundColor: "#00c2ae",
          borderRadius: "50px",
          overflow: "hidden",
        }}
      >
        <Link to={href}>
          <Box
            display={"grid"}
            gridTemplateColumns={"34px 108px"}
            alignItems={"center"}
            padding={"12px 16px"}
            style={{
              gap: "6px",
            }}
          >
            <Box>
              <img src={ticket_icon} alt="" width="100%" />
            </Box>

            <Box paddingTop={"2px"} textAlign={"left"}>
              <Box
                component={"p"}
                color={"white"}
                fontSize={"12px"}
                lineHeight={"1.3em"}
              >
                {label}
              </Box>
              <Box
                component={"p"}
                color={"white"}
                fontSize={"12px"}
                lineHeight={"1.3em"}
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {subLabel}
              </Box>
            </Box>
          </Box>
        </Link>
      </ButtonBase>
    </Box>
  );
};
