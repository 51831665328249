/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { changeStyleWithHosting } from "utility/hosting";
import { getEventDisplayInfo } from "utility";
import { getSalesState } from "utility/event";
import { useI18n } from "hooks/i18n/i18n";
import type { Event } from "@spwn/types/firebase/firestore";

export const EventCard: React.FC<{
  event: Event;
  /**
   * @deprecated
   * 過去イベント一覧、配信中イベント一覧は例外でステータスラベルを追加。
   * 新しくステータスを追加する場合はgetSalesStateに実装する。FIXME exceptionStatusもgetSalesStateにおいてもいいかも。
   */
  exceptionStatus?: "archived" | "vod";
  action?: () => void;
}> = ({ event, exceptionStatus, action }) => {
  const { t } = useI18n();
  const {
    eventId,
    eventTitle,
    thumbnail,
    eventOpenDate,
    eventOpenDay,
    eventOpenTime,
    eventSaleStatus,
    goodsSaleStatus,
  } = getEventDisplayInfo(event);

  const eventState =
    exceptionStatus === "archived"
      ? t("event.status.endSale")
      : exceptionStatus === "vod"
      ? t("event.status.streamingSale")
      : getSalesState(eventSaleStatus, goodsSaleStatus).label;
  const eventStateColor =
    exceptionStatus === "archived"
      ? "#e0e0e0"
      : exceptionStatus === "vod"
      ? "#ff5f96"
      : getSalesState(eventSaleStatus, goodsSaleStatus).color;

  return (
    <article className="event-card" onClick={action}>
      <Link to={`/events/${eventId}`}>
        <EventCardComponent
          thumbnail={thumbnail}
          eventTitle={eventTitle}
          // @ts-expect-error TS2322
          eventState={eventState}
          // @ts-expect-error TS2322
          eventStateColor={eventStateColor}
          eventOpenDate={eventOpenDate}
          eventOpenDay={eventOpenDay}
          eventOpenTime={eventOpenTime}
        />
      </Link>
    </article>
  );
};

export const EventCardComponent: React.FC<{
  thumbnail: string;
  eventTitle: string;
  eventState: string;
  eventStateColor: string;
  eventOpenDate: string;
  eventOpenDay: string;
  eventOpenTime: string;
}> = ({
  thumbnail,
  eventTitle,
  eventState,
  eventStateColor,
  eventOpenDate,
  eventOpenDay,
  eventOpenTime,
}) => {
  const classes = styles();

  return (
    <div css={classes.root}>
      <div css={classes.thumbnail}>
        <LazyLoad height={"100%"} offset={400} once>
          <img src={thumbnail} alt={eventTitle} />
        </LazyLoad>

        {changeStyleWithHosting().pageSettings.eventListPage
          .isDisplayEventDatetimeLabel &&
          eventState !== null && (
            <p
              css={css`
                color: ${eventStateColor};
              `}
            >
              {eventState}
            </p>
          )}
      </div>

      <div css={classes.info}>
        {(changeStyleWithHosting().commonSettings.isDisplayEventDate ||
          changeStyleWithHosting().commonSettings.isDisplayEventTime) && (
          <p css={classes.datetime}>
            {changeStyleWithHosting().commonSettings.isDisplayEventDate && (
              <time>
                {eventOpenDate}({eventOpenDay})
              </time>
            )}
            {changeStyleWithHosting().commonSettings.isDisplayEventTime && (
              <span>OPEN {eventOpenTime}</span>
            )}
          </p>
        )}
        <h3 css={classes.title}>{eventTitle}</h3>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      width: 100%;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      a {
        display: block;
      }
    `,
    thumbnail: css`
      position: relative;
      overflow: hidden;
      .lazyload-wrapper {
        padding-top: 56.25%;
        background-color: #e3e3e3;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);
      }
      p {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 6px 10px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        background-color: rgba(0, 0, 0, 0.7);
      }
      /* &::before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 56.25%;
        background-color: #E3E3E3;
      } */
    `,
    info: css`
      padding: 16px;
      background-color: #fff;
    `,
    datetime: css`
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5em;
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
      time {
        display: block;
        margin-right: 1em;
      }
      span {
        display: block;
      }
    `,
    title: css`
      display: box;
      height: 4.5em;
      font-size: 14px;
      line-height: 1.5em;
      /* stylelint-disable-next-line property-no-unknown */
      box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      -webkit-line-clamp: 3;
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    `,
  };
};
