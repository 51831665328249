/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { authActions, LoginProvider } from "modules/auth";
import { I18nTrans, useI18n } from "hooks/i18n/i18n";
import { Button } from "components/atoms/button/Button";
import GoogleLoginButton from "components/GoogleLoginButton";
import { TextLink } from "ui/TextLink";
import appConfig from "constants/appConfig";
import { useValidReturnUrlQuery } from "./useValidReturnUrlQuery";
import { useLocation } from "react-router-dom";

export const Signup: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const return_url = useValidReturnUrlQuery();

  const dispatch = useDispatch();

  const handleChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    []
  );

  const handleChangePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    []
  );

  const handleSignupWithEmail = useCallback(() => {
    dispatch(
      authActions.createUserWithEmail.started({
        email,
        pass: password,
        return_url,
      })
    );
  }, [dispatch, email, password, return_url]);

  const handleSignupWithProvider = useCallback(
    (provider: LoginProvider) => {
      dispatch(authActions.login.started(provider));
    },
    [dispatch]
  );

  return (
    <SignupView
      handleChangeEmail={handleChangeEmail}
      handleChangePassword={handleChangePassword}
      handleSignupWithEmail={handleSignupWithEmail}
      handleSignupWithProvider={handleSignupWithProvider}
    />
  );
};

type SignupProps = {
  handleChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSignupWithEmail: () => void;
  handleSignupWithProvider: (provider: LoginProvider) => void;
  tosUrl?: string;
};

export const SignupView: React.FC<SignupProps> = ({
  handleChangeEmail,
  handleChangePassword,
  handleSignupWithEmail,
  handleSignupWithProvider,
  tosUrl = appConfig.accountsApp.tosUrl,
}) => {
  const { search } = useLocation();
  const classes = styles();
  const { t } = useI18n();

  return (
    <div css={classes.root}>
      <div className="method" css={classes.layout}>
        <h2>SIGN UP</h2>

        <div css={classes.form}>
          <div css={classes.item}>
            <p>{t("login.email")}</p>
            <input type="text" name="email" onChange={handleChangeEmail} />
          </div>

          <div css={classes.item}>
            <p>{t("login.password")}</p>
            <input
              type="password"
              name="pass"
              onChange={handleChangePassword}
            />
          </div>

          <div css={classes.button}>
            <Button
              text={t("login.buttons.registration")}
              onClick={handleSignupWithEmail}
            />
          </div>

          <p css={classes.or}>{t("login.or")}</p>

          <div css={classes.method}>
            {/* Portalに関わる文言なのでaccounts appでは非表示にしておく */}
            {/* <p css={classes.caution}>
              <I18nTrans i18nKey="login.notice" />
            </p> */}

            <div
              css={css`
                margin-bottom: 24px;
              `}
            >
              <GoogleLoginButton
                onClick={handleSignupWithProvider}
                isSignup={true}
              />
            </div>

            <div
              css={css`
                font-size: 14px;
                text-align: center;
                div {
                  &:not(:last-child) {
                    margin-bottom: 24px;
                  }
                }
              `}
            >
              <div>
                <I18nTrans i18nKey="signup.tosNote">
                  新規登録をすると、
                  <TextLink href={tosUrl}>利用規約</TextLink>
                  に同意したものと見なされます。
                </I18nTrans>
              </div>

              <div>
                <TextLink href={`/login${search}`}>
                  {t("login.switchToLogin")}
                </TextLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      position: relative;
      padding: 75px 0;
      background-color: #fff;
    `,
    closeIcon: css`
      position: absolute;
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      svg {
        width: 100%;
        height: 100%;
      }
    `,
    layout: css`
      width: 95%;
      max-width: 980px;
      margin: 0 auto;
      &.method {
        margin-bottom: 80px;
      }
      h2 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        font-style: normal;
        color: #000;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        line-height: 1em;
        letter-spacing: 1px;
        white-space: nowrap;
        @media screen and (min-width: 768px) {
          margin-bottom: 40px;
          font-size: 30px;
        }
        &:after,
        &:before {
          content: "";
          width: 30%;
          background: linear-gradient(
            transparent 40%,
            currentColor 40%,
            currentColor calc(40% + 2px),
            transparent calc(40% + 2px)
          );
          background-clip: padding;
          @media screen and (min-width: 768px) {
            width: 40%;
          }
        }
        &:after {
          margin-left: 4%;
        }
        &:before {
          margin-right: 4%;
        }
      }
    `,
    caution: css`
      font-size: 14px;
      text-align: center;
      line-height: 1.5em;
      margin-bottom: 20px;
    `,
    method: css`
      width: 100%;
      max-width: 560px;
      margin: 0 auto 64px;
    `,
    form: css`
      width: 100%;
      max-width: 560px;
      margin: 0 auto;
    `,
    item: css`
      margin-bottom: 16px;
      p {
        margin-bottom: 8px;
        font-size: 14px;
      }
      input {
        width: 100%;
        padding: 8px;
        font-size: 16px;
        border: 1px solid #d2d2d2;
        appearance: none;
      }
    `,
    button: css`
      width: 100%;
      max-width: 460px;
      margin: 32px auto 32px;
      p {
        margin-top: 16px;
        color: #757575;
        font-size: 14px;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
      }
    `,
    or: css`
      margin-bottom: 20px;
      text-align: center;
    `,
  };
};
