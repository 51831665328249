/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import styled from "@emotion/styled";

import { FLOWER_COLOR_COUNT } from "constants/event";
import { LeftBorderHeadline } from "components/atoms/headline/LeftBorderHeadline";
import { EventStartTime } from "components/account/ticket/EventDateTimeInfo";
import { BreadcrumbArea } from "components/common/Link";
import { useI18n } from "hooks/i18n/i18n";
import { RedirectState } from "modules/router";
import { Store } from "store";
import { breadcrumbMixin, fontDinMixin } from "styles";
import { getEventIdFromHref } from "utility";
import { getFlowerStandDisplayInfo } from "utility/event";
import { changeStyleWithHosting } from "utility/hosting";

interface Props {}

const Container = styled.div`
  margin: 0 auto 60px;
  ${breadcrumbMixin};
  .flowerStandInfoLayout {
    padding: 20px 0 40px;
    margin-bottom: 40px;
    background-color: #fff;
    @media screen and (min-width: 768px) {
      padding: 30px 0;
    }
    .flowerStandInfo {
      max-width: 980px;
      width: 90%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .eventThumbnail {
        margin-bottom: 20px;
        @media screen and (min-width: 768px) {
          width: 380px;
          margin-right: 40px;
          margin-bottom: 0;
        }
        img {
          width: 100%;
        }
      }
      .eventInfo {
        @media screen and (min-width: 768px) {
          width: 560px;
        }
        .datetime {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          @media screen and (min-width: 768px) {
            margin-bottom: 20px;
          }
          .date {
            display: flex;
            ${fontDinMixin};
            font-size: 25px;
            @media screen and (min-width: 768px) {
              font-size: 30px;
            }
            span {
              font-size: 20px;
              @media screen and (min-width: 768px) {
                font-size: 25px;
              }
            }
          }
        }
        .name {
          margin-bottom: 15px;
          font-size: 20px;
          font-weight: bold;
          line-height: 1.3em;
          @media screen and (min-width: 768px) {
            font-size: 25px;
          }
        }
        .artist {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
  .flowerStandListLayout {
    max-width: 980px;
    width: 90%;
    margin: 0 auto;
    .flowerStandList {
      @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      &:after {
        @media screen and (min-width: 768px) {
          content: "";
          display: block;
          width: 305px;
        }
      }
      .flowerStand {
        width: 100%;
        margin-bottom: 30px;
        background: linear-gradient(315deg, transparent 15px, #fff 15px);
        @media screen and (min-width: 768px) {
          width: 305px;
        }
        a {
          display: block;
        }
        img {
          width: 100%;
        }
        .flowerStandInfo {
          padding: 15px 15px 25px;
          .productName {
            margin-bottom: 15px;
            font-weight: bold;
          }
          .productPrice {
            font-weight: bold;
          }
        }
      }
    }
  }
`;

const EventFlowerStands: React.FC<Props> = (_props) => {
  const { t } = useI18n();
  const breadcrumbText =
    changeStyleWithHosting().commonSettings.menu.event.text;
  const hostingBaseStylesMap = changeStyleWithHosting().baseStyles;
  const classesCommonStyles = commonStyles({ hostingBaseStylesMap });

  const pathname = useSelector(
    (state: Store) => state.router.location.pathname
  );
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const flowerStandMap = useSelector(
    (state: Store) => state.flowerStand.flowerStandList
  );
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);

  // @ts-expect-error TS2345
  const [_eventId, setEventId] = useState<string>(null);

  const watchPathname = () => {
    const eventId = getEventIdFromHref(pathname, "events");
    // @ts-expect-error TS2345
    setEventId(eventId);
  };
  useEffect(watchPathname, [pathname]);

  if (!displayEvent) {
    return null;
  }

  if (!isAdmin && displayEvent.flowerStandSaleStatus !== "ON_SALE") {
    const msg = t("flowerstand.item.periodError");
    const redirectState: RedirectState = {
      noticeMsg: msg,
    };
    return (
      <Redirect
        to={{
          pathname: `/events/${displayEvent.eventId}`,
          state: redirectState,
        }}
      />
    );
  }

  return (
    <Container id="contents">
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/events", breadcrumbText],
          ["/events/" + displayEvent.eventId, displayEvent.eventTitle],
          [null, t("flowerstand.item.flowerList")],
        ]}
      />
      <div className="flowerStandInfoLayout">
        <div className="flowerStandInfo">
          <div className="eventThumbnail">
            <img src={displayEvent.thumbnail} alt={displayEvent.eventTitle} />
          </div>
          <div className="eventInfo">
            <div className="datetime">
              <p className="date">
                {displayEvent.eventOpenDateFull}
                <span>({displayEvent.eventOpenDay})</span>
              </p>
              <EventStartTime parts={displayEvent.parts} />
            </div>
            <p className="name">{displayEvent.eventTitle}</p>
            <p className="artist">{displayEvent.artists}</p>
          </div>
        </div>
      </div>

      <div className="flowerStandListLayout">
        <div css={classesCommonStyles.heading}>
          <LeftBorderHeadline
            title={t("event.flower.headline.text")}
            subTitle={t("event.flower.headline.subText")}
            fontColor={"primary"}
          />
        </div>

        <div className="flowerStandList">
          {!!flowerStandMap &&
            Object.keys(flowerStandMap).map((itemId, i) => {
              const flowerStand = flowerStandMap[itemId];
              // @ts-expect-error TS2345
              const flowerDispInfo = getFlowerStandDisplayInfo(flowerStand);
              const flowerStandImage = flowerDispInfo.imgUrl
                ? flowerDispInfo.imgUrl
                : flowerDispInfo.flowerListImg;
              return (
                <div key={i} className="flowerStand">
                  <Link
                    to={`/events/${displayEvent.eventId}/flower-stand/${flowerDispInfo.name}`}
                  >
                    <img src={flowerStandImage} alt={flowerDispInfo.name} />
                    <div className="flowerStandInfo">
                      <p className="productName">
                        {flowerDispInfo.name}(全{FLOWER_COLOR_COUNT}色)
                      </p>
                      <p className="productPrice">
                        {flowerDispInfo.values.paid.toLocaleString()} PAW
                      </p>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </Container>
  );
};
export default EventFlowerStands;

interface CommonStylesProps {
  // @ts-expect-error TS7008
  hostingBaseStylesMap;
}
const commonStyles = (_props?: CommonStylesProps) => {
  // const { accentColor } = props.hostingBaseStylesMap;
  // const headingTextColor = props.hostingBaseStylesMap.fontColor;

  const heading = css`
    margin-bottom: 40px;
    letter-spacing: 1px;
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      margin-bottom: 60px;
    }
  `;
  return {
    heading,
  };
};
