/** @jsxRuntime classic /
 /** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { Children, useEffect, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { eventActions } from "modules/event";
import {
  convertMapToValues,
  createListSetOfField,
  getLastDirNameFromHref,
} from "utility";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { ticketActions } from "modules/ticket";
import { isShippingItem, SHIPPING_PLACE_CODE } from "utility/ticket";
import { useI18n } from "hooks/i18n/i18n";
import { RedirectToInternal } from "components/common/Redirect";
import { isVODTicket } from "utility/event";

type Props = {};

const EventGoodsBase: React.FC<Props> = (props) => {
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const myTickets = useSelector((state: Store) => state.ticket.myTickets);
  const eventGoodsMap = useSelector(
    (state: Store) => state.ticket.eventGoodsMap
  );
  const goodsPageSettings = useSelector(
    (state: Store) => state.event.goodsPageSettings
  );
  const pathname = useSelector(
    (state: Store) => state.router.location.pathname
  );
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);

  const { t } = useI18n();

  const dispatch = useDispatch();

  const goodsName = getLastDirNameFromHref(pathname, "goods");

  useEffect(() => {
    dispatch(
      ticketActions.fetchEventGoods.started({ eventId: displayEvent.eventId })
    );
    return () => {
      dispatch(ticketActions.clearStateByKey("eventGoodsMap"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // ログインしていれば所持チケットの確認をして会場選択、ログインしていなければデフォルトの開場を選択。
    if (!goodsPageSettings && eventGoodsMap) {
      const goods = eventGoodsMap ? convertMapToValues(eventGoodsMap) : [];

      const hasShippments = goods.filter((el) => isShippingItem(el)).length > 0;

      const venuePlaceCodes = (() => {
        // メール送付商品などのデジタルコンテンツも除外する必要があるが、現状placeを `000-OPENLOGI` で統一している
        const venueProducts = goods.filter((goods) => !isShippingItem(goods));
        if (venueProducts.length === 0) {
          // fail safe
          return [];
        }
        const placeCodes = createListSetOfField(venueProducts, "place");
        return placeCodes.map((el) => el.place);
      })();
      const defaultPlaceCode = venuePlaceCodes?.[0];

      // 会場チケットを所持しているかの確認に使用する
      // index 0 is main. 複数会場購入は稀だができるので想定しておく。
      const havingTicketPlaceCodes = defaultPlaceCode
        ? (() => {
            // if venue goods is not present, don't check myTickets.
            const ticketPlaceSet = new Set<string>();
            if (myTickets) {
              myTickets.forEach((ticketData) => {
                if (
                  ticketData._id !== displayEvent.eventId ||
                  ticketData.tickets.length === 0
                ) {
                  return;
                }
                ticketData.tickets.forEach((ticket) => {
                  if (isVODTicket(ticket)) {
                    // skip if streaming ticket
                    return;
                  }
                  ticketPlaceSet.add(ticket.place);
                });
              });
            }
            return Array.from(ticketPlaceSet);
          })()
        : [];

      let selectedPlaceCode = hasShippments
        ? SHIPPING_PLACE_CODE
        : havingTicketPlaceCodes.length > 0
        ? havingTicketPlaceCodes[0]
        : defaultPlaceCode;

      if (goodsName) {
        // イベントに配送商品と会場商品の両方があり、会場と配送で別の商品がある場合、
        // 会場商品ページでリロードしても読み込まれない
        const classNamePlaceGoodsList = goods.filter(
          (el) => el.className === goodsName && el.place === selectedPlaceCode
        );
        if (classNamePlaceGoodsList.length === 0) {
          selectedPlaceCode =
            havingTicketPlaceCodes.length > 0
              ? havingTicketPlaceCodes[0]
              : defaultPlaceCode;
        }
      }

      dispatch(
        eventActions.setGoodsPageSettings({
          defaultPlaceCode,
          venuePlaceCodes,
          selectedPlaceCode,
          havingTicketPlaceCodes,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventGoodsMap, myTickets]);

  const newProps = {
    parentProps: {},
  };

  const childrenWithProps = Children.map(
    // @ts-expect-error TS2345
    props.children,
    (child: ReactElement) => {
      switch (typeof child) {
        case "string":
          return child;
        case "object":
          return React.cloneElement(child, newProps);
        default:
          return null;
      }
    }
  );

  if (
    (displayEvent.goodsSaleStatus === "BEFORE_SALE" ||
      displayEvent.goodsSaleStatus === "NONE") &&
    !isAdmin
  ) {
    const msg = t("event.goods.saleInfo.beforeOnSale");
    return (
      <RedirectToInternal
        path={`/events/${displayEvent.eventId}`}
        state={{ noticeMsg: msg }}
      />
    );
  }

  if (!displayEvent || !goodsPageSettings) {
    return (
      <div
        id="contents"
        css={css`
          margin: 0 auto;
        `}
      >
        <LoaderLayout />
      </div>
    );
  }

  return <React.Fragment>{childrenWithProps}</React.Fragment>;
};

export default EventGoodsBase;
