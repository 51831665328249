/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { changeStyleWithHosting } from "utility/hosting";
import { useI18n } from "hooks/i18n/i18n";
import { VideoThumbnailSkeleton } from "components/loading/SkeletonLoader";
import { EventVideoThumbnailList } from "./EventVideoThumbnailList";
import { LeftBorderHeadline } from "components/atoms/headline/LeftBorderHeadline";

type Props = React.ComponentProps<typeof EventVideoThumbnailList>;

export const EventDetailVideoEntrance: React.FC<Props> = (props) => {
  const { eventVideos, streamingKey } = props;

  const { t } = useI18n();
  const classesHeadingDesign = headingDesign();
  const classesArchiveVideoDesign = videoDesign();

  if (!streamingKey || !eventVideos) {
    return <VideoThumbnailSkeleton />;
  }

  return (
    <div css={classesHeadingDesign.root}>
      <div css={classesHeadingDesign.heading}>
        <LeftBorderHeadline
          title={t("event.headline.liveStreaming.text")}
          subTitle={t("event.headline.liveStreaming.subText")}
          fontColor={"secondary"}
        />
      </div>

      <div css={classesArchiveVideoDesign.root}>
        <EventVideoThumbnailList {...props} />
      </div>
    </div>
  );
};

const headingDesign = () => {
  const _hostingBaseStyleMap = changeStyleWithHosting().baseStyles;
  return {
    root: css`
      margin-top: 50px;
    `,
    heading: css`
      margin-bottom: 40px;
    `,
  };
};

const videoDesign = () => {
  return {
    root: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: start;
      &::after {
        @media screen and (min-width: 768px) {
          content: "";
          display: block;
          width: 31%;
        }
      }
    `,
  };
};
