/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { FC } from "react";

export const Center: FC<{
  children: JSX.Element;
}> = ({ children }) => {
  return (
    <div
      css={css`
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;
        text-align: center;
      `}
    >
      {children}
    </div>
  );
};
