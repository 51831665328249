/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { parseQuery } from "../../utility";
import BorderLoader from "../../components/atoms/loader/BorderLoader";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "../../store";
import { modalActions } from "../../modules/modal";
import { push } from "connected-react-router";
import { authActions } from "modules/auth";
import {
  createViewerAppDynamicLinkWithParam,
  createDynamicLinkWithParam,
} from "utility/firebase";
import { EditForm, ContentsLayout } from "../../styles";
import type { ParsedUrlQuery } from "querystring";

interface Props {}

const OpenId: React.FC<Props> = (_props) => {
  const dispatch = useDispatch();
  const location = useSelector((state: Store) => state.router.location.search);
  const query = parseQuery(location);

  const didMount = () => {
    const dictToURI = (dict: Record<string, unknown>) => {
      const str = [];
      for (const p in dict) {
        str.push(
          encodeURIComponent(p) + "=" + encodeURIComponent(dict[p] as never)
        );
      }
      return str.join("&");
    };
    const getQueryValue = (
      query: ParsedUrlQuery | null,
      target: string
    ): string | null => {
      if (!!query && !!query[target]) {
        // NOTE: 配列型は来ない想定でコードが書かれている
        return query[target] as string;
      } else {
        return null;
      }
    };
    const getRedirectUrl = (from: string, params: Record<string, unknown>) => {
      const queryStr = dictToURI(params);
      return from + (from.includes("?") ? "&" : "?") + queryStr;
    };
    const tokenLogin = async (
      from: string | null,
      token: string | null,
      params: Record<string, unknown>
    ) => {
      try {
        if (token !== null) {
          await firebase.auth().signInWithCustomToken(token);
        }
        if (from === null) {
          if (msg !== null) {
            dispatch(modalActions.toggleError({ msg }));
          } else if (errorCode !== null) {
            dispatch(
              modalActions.toggleError({
                msg: `エラーにより処理できませんでした．ErrorCode: ${errorCode}`,
              })
            );
          }
        } else {
          const url = getRedirectUrl(from, params);
          window.location.href = url;
        }
      } catch (e) {
        console.error(e);
      }
    };

    const msg = getQueryValue(query, "msg");
    const errorCode = getQueryValue(query, "errorCode");
    const token = getQueryValue(query, "ctoken");
    const from = getQueryValue(query, "from");
    const appName = getQueryValue(query, "appName");
    // if viewer app login,
    if (appName === "viewerApp") {
      dispatch(
        authActions.createAppDynamicLink({
          // @ts-expect-error TS2322
          ar: null,
          // @ts-expect-error TS2322
          viewer: createViewerAppDynamicLinkWithParam("docomo", { token }),
        })
      );
      dispatch(push(`/viewer-app-login`));
      return;
    } else if (appName === "arApp") {
      dispatch(
        authActions.createAppDynamicLink({
          // @ts-expect-error TS2322
          ar: createDynamicLinkWithParam("docomo", { token }),
          // @ts-expect-error TS2322
          viewer: null,
        })
      );
      dispatch(push(`/ar-app-login-ehgeywoyhoebavro`));
      return;
    }
    let params = {};
    if (msg !== null) {
      params = { msg, ...params };
    }
    if (errorCode !== null) {
      params = { errorCode, ...params };
    }
    tokenLogin(from, token, params);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(didMount, []);

  return (
    <div id="contents">
      <ContentsLayout
        css={css`
          min-height: calc(100vh - 601px);
          padding: 40px;
          @media screen and (min-width: 768px) {
            min-height: calc(100vh - 386px);
          }
        `}
      >
        <div className="prifle_edit_info">
          <EditForm>
            <BorderLoader />
          </EditForm>
        </div>
      </ContentsLayout>
    </div>
  );
};

export default OpenId;
