/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";

import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { useCart } from "hooks/cart/Cart";
import { useGetPlaceList } from "hooks/event/useGetPlaceList";
import { cartState, MyCartData } from "modules/cart";
import { Store } from "store";
import { unique } from "utility/array";

/**
 * inject cart custom hook to settlement component
 * TODO
 * - ここでuseCartカスタムフックと、クエリパラメータを利用して、Settlementコンポーネントに決済対象を渡す。myCartをフィルターしたもの。
 *
 * 今後の決済グルーピング改修ステップ想定
 * https://github.com/balus-co-ltd/spwn/issues/472
 * 1. SettlementにmyCartを選択したorderでフィルターしたものを渡す
 * 2. SettlementのUIを更新する。新しいUI用のモデル（orderCart）を渡す。
 * 3. myCartをorderCartに置き換える
 * @param Component
 * @returns
 */
// @ts-expect-error TS7006
export const InjectCartToSettlement = (Component) => {
  const myCart = useSelector((state: Store) => state.cart.myCart);
  const {
    query: { order },
    pathname,
  } = useSelector((state: Store) => state.router.location);
  const [orderGroupId, setOrderGroupId] = useState(order ?? null);
  const [shippingAtTitle, setShippingAtTitle] = useState<string>();
  const [pickedMyCart, setPickedMyCart] = useState<MyCartData[]>([]);
  const { cart, error } = useCart();

  // Place取得
  const placeIds = unique(
    myCart.flatMap((el) => {
      const ticketPlaces = el.tickets.map((el) => el.place);
      const goodsPlaces = el.goods.map((el) => el.place);
      return [...ticketPlaces, ...goodsPlaces];
    })
  );
  const { data: places } = useGetPlaceList(placeIds);

  useEffect(() => {
    if (!myCart || !cart) return;
    // if wrong order index, set null.
    if (!order) {
      setOrderGroupId(null);
      return;
    }
    const targetCart = cart.orderCarts.find((el) => el.groupId === order);

    if (!targetCart) {
      setOrderGroupId(null);
      return;
    }
    // pick product ids from selected order cart.
    const pickedProductIds = unique(targetCart.products.map((el) => el.id));
    setOrderGroupId(orderGroupId);
    setShippingAtTitle(targetCart.shippingAtTitle);
    setPickedMyCart(pickMyCartProducts(myCart, pickedProductIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCart, cart, order]);

  // if wrong order index on settlement page, redirect to top.
  // - ブラウザバックを行うと、orderIndexがnullになりTopへリダイレクトが走るので、決済ページでだけリダイレクトするようにしている
  if (pathname.indexOf("/settlement") === 0 && orderGroupId === null) {
    return <Redirect to="/" />;
  }

  // if error, redirect to top.
  // - orderがない場合のリダイレクト処理と同じだが、error時の挙動を変更する可能性があるのでifを分けている
  if (error === "product-not-found" || error === "unknown") {
    return <Redirect to="/" />;
  }

  if (pickedMyCart.length === 0) return <LoaderLayout />;

  return (
    <Component
      myCart={pickedMyCart}
      shippingAtTitle={shippingAtTitle}
      places={places}
    />
  );
};

/**
 * picking the specified products from the cart
 * @param myCart
 * @param pickedProductIds
 */
export const pickMyCartProducts = (
  myCart: cartState["myCart"],
  pickedProductIds: string[]
) => {
  return myCart.reduce((acc, cur) => {
    const goods = cur.goods.filter((el) =>
      pickedProductIds.includes(el.productId)
    );
    const tickets = cur.tickets.filter((el) =>
      pickedProductIds.includes(el.productId)
    );
    if (goods.length === 0 && tickets.length === 0) return acc;
    const products = goods.concat(tickets);
    const newMyCart: cartState["myCart"][number] = {
      ...cur,
      goods,
      tickets,
      productCount: products
        .map((el) => el.count)
        .reduce((prev, cur) => prev + cur),
      subTotal: products
        .map((el) => el.price_jpy * el.count)
        .reduce((prev, cur) => prev + cur),
      totalDisplayPrice: products
        .map((el) => (el.displayPrice || 0) * el.count) // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
        .reduce((prev, cur) => prev + cur),
    };
    acc.push(newMyCart);
    return acc;
  }, [] as MyCartData[]);
};
