/** @jsxRuntime classic /
/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { format } from "date-fns";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { useI18n } from "hooks/i18n/i18n";

type GroupedOrderedItem = {
  name: string;
  variantName?: string;
  variants: { variantName: string; quantity: number }[];
  totalQuantity: number;
};

type GroupedOrderedItemList = {
  name: string;
  variantName: string;
  variantInfo: string;
  totalQuantity: number;
}[];

type Props = {
  // 注文日
  orderedAt: Date;
  // 商品名と個数のリスト
  orderedItemList: {
    name: string;
    variantName?: string;
    quantity: number;
  }[];
  // 決済額合計
  totalAmount: number;
  status: {
    isPurchased: boolean;
    onClickAction: () => void;
  };
};

export const PurchaseHistoryListItem: React.FC<Props> = ({
  orderedAt,
  orderedItemList,
  totalAmount,
  status,
}: Props) => {
  const classes = styles();
  /*
   * 同じ商品をまとめた履歴の1項目
   * 一つの商品名でバリエーションのあるものをまとめて表示させたいため
   */
  const groupedOrderedItem: GroupedOrderedItem | {} = orderedItemList.reduce(
    (acc, item) => {
      const { name, variantName, quantity } = item;
      // @ts-expect-error TS7053
      if (!acc[name]) {
        // @ts-expect-error TS7053
        acc[name] = {
          name,
          variantName,
          variants: [],
          totalQuantity: 0,
        };
      }
      // @ts-expect-error TS7053
      acc[name].variants.push({
        variantName,
        quantity,
      });
      // @ts-expect-error TS7053
      acc[name].totalQuantity += quantity;
      return acc;
    },
    {}
  );
  // @ts-expect-error TS2322
  const groupedOrderedItemList: GroupedOrderedItemList = Object.values(
    groupedOrderedItem
    // @ts-expect-error TS2345
  ).map((item: GroupedOrderedItem) => ({
    name: item.name,
    variantName: item.variantName,
    variantInfo: item.variants
      .map((variant) => `${variant.variantName} ×${variant.quantity}`)
      .join(", "),
    totalQuantity: item.totalQuantity,
  }));

  return (
    <section css={classes.root}>
      <div css={classes.layout}>
        {!status.isPurchased && (
          <AlertPaymentProcedureRequest onClickAction={status.onClickAction} />
        )}

        <div css={classes.item}>
          <div className="name">
            <p
              className="date gray"
              aria-label={format(orderedAt, "yyyy/MM/dd")}
            >
              {format(orderedAt, "yyyy/MM/dd")}
            </p>
            <ul className="item bold">
              {groupedOrderedItemList.map((item, i) => {
                return item.variantName ? (
                  <li key={`${item.name}-${i}`} aria-label={item.name}>
                    {item.name} ×{item.totalQuantity}
                    <ul className="item bold">
                      <li aria-label={`${item.variantInfo}`}>
                        {item.variantInfo}
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li
                    key={i}
                    aria-label={`${item.name} ×${item.totalQuantity}`}
                  >
                    {item.name} ×{item.totalQuantity}
                  </li>
                );
              })}
            </ul>
          </div>

          <p
            className="amount bold"
            aria-label={`¥${totalAmount.toLocaleString()}`}
          >
            ¥{totalAmount.toLocaleString()}
          </p>

          <div className="receipt">
            {status.isPurchased && (
              <div
                onClick={status.onClickAction}
                data-testid="action"
                aria-label="領収書を確認"
                css={classes.confirmReceiptIcon}
              >
                <GetAppIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const AlertPaymentProcedureRequest = ({
  onClickAction,
}: {
  onClickAction: () => void;
}) => {
  const { t } = useI18n();

  return (
    <section
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 8px;
        background-color: #fffaf0;
        border: 1px solid #d69e2e;
        border-radius: 4px;
        @media screen and (min-width: 768px) {
          padding: 8px 16px;
        }
      `}
    >
      <div
        css={css`
          min-width: 24px;
        `}
      >
        <ReportProblemIcon htmlColor={"#975a16"} />
      </div>

      <div
        css={css`
          color: #975a16;
          font-size: 14px;
          @media screen and (min-width: 768px) {
            display: flex;
            gap: 8px;
          }
        `}
      >
        <p
          css={css`
            font-weight: bold;
            line-height: 1.3em;
          `}
        >
          {t("settlementHistory.paymentProcedureRequest")}
        </p>
        <p
          css={css`
            line-height: 1.3em;
          `}
        >
          {t("settlementHistory.necessaryProceduresRemain")}
        </p>
      </div>

      <div onClick={onClickAction} aria-label="決済を続ける">
        <p
          css={css`
            display: inline-block;
            min-width: calc(6em + 20px);
            padding: 6px 10px;
            color: #fff;
            font-size: 14px;
            background-color: #975a16;
            border-radius: 4px;
            cursor: pointer;
            &:hover {
              background-color: #319795;
            }
          `}
        >
          {t("settlementHistory.continueToSettle")}
        </p>
      </div>
    </section>
  );
};

const styles = () => {
  return {
    root: css`
      font-size: 14px;
      line-height: 1.3em;
      border-bottom: 1px solid #e6e5e6;
      .bold {
        font-weight: bold;
      }
      .gray {
        color: #8c8c8c;
      }
    `,
    layout: css`
      width: 95%;
      padding: 8px 0;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        max-width: 1080px;
      }
    `,
    item: css`
      display: grid;
      grid-template-columns: 60% 20% 20%;
      justify-items: center;
      align-items: center;
      margin-top: 8px;
      @media screen and (min-width: 768px) {
        justify-items: start;
      }
      .name {
        width: 100%;
      }
      .item {
        list-style: none;
        li:not(:first-of-type) {
          margin-bottom: 4px;
        }
        li > ul {
          padding-left: 1em;
          font-size: 12px;
        }
      }
      .date {
        margin-bottom: 4px;
      }
      .amount {
        width: 100%;
        text-align: center;
      }
      .receipt {
        width: 100%;
        text-align: center;
      }
    `,
    confirmReceiptIcon: css`
      display: grid;
      justify-items: center;
      align-items: center;
      width: 28px;
      height: 28px;
      padding: 4px;
      margin: 0 auto;
      background-color: #8c8c8c;
      border-radius: 50%;
      cursor: pointer;
      box-sizing: border-box;
      svg {
        width: 20px;
        height: 20px;
        fill: #fff;
      }
    `,
  };
};
