import useSWR from "swr";
import firebase from "firebase";

import { LotteryAppForm } from "@spwn/types/firebase/firestore";

import { useGetEventList } from "hooks/event/useGetEventList";
import { fetchFirestoreDocument } from "utility/firebase";

export type UseGetLotteryAppMapProps = {
  eventIds?: string[];
  enabled?: boolean;
};

export type UseGetLotteryAppMapResult = {
  lotteryAppMap?: { [key: string]: LotteryAppForm };
  isLoading: boolean;
  error: unknown;
};

// 抽選チケット申込内容を取得
export const useGetLotteryAppMap = ({
  eventIds,
  enabled,
}: UseGetLotteryAppMapProps): UseGetLotteryAppMapResult => {
  // EventIDが指定されていない場合は公開中のイベント一覧を取得
  const {
    data: openEvents,
    isLoading: isOpenEventsLoading,
    error: openEventsError,
  } = useGetEventList({
    type: "open",
    enabled: eventIds === undefined && enabled,
  });

  const ids = eventIds
    ? eventIds
    : openEvents &&
      (openEvents.map((event) => event._id).filter((id) => !!id) as string[]);

  // ユーザが申し込み中の抽選チケット内容を取得
  const {
    data: lotteryAppMap,
    isLoading: isWaitingTicketLoading,
    error: lotteryAppError,
  } = useSWR(
    enabled && ids && !!firebase.auth().currentUser
      ? ["lotteryAppMap", firebase.auth().currentUser?.uid, ids]
      : null,
    async ([_, userId, eventIds]) =>
      fetchLotteryApp(userId ?? "", eventIds ?? [])
  );

  return {
    lotteryAppMap,
    isLoading: (!eventIds && isOpenEventsLoading) || isWaitingTicketLoading,
    error: openEventsError || lotteryAppError,
  };
};

/**
 * 特定ユーザに紐づく指定EventIDsの抽選チケットの申し込み状況を取得
 *
 * @param userId
 * @param eventIds
 */
const fetchLotteryApp = async (userId: string, eventIds: string[]) => {
  if (eventIds.length === 0) {
    return {};
  }

  // そのうちCollectionGroupで処理する
  const lotteryPromises: Promise<LotteryAppForm>[] = [];
  eventIds.forEach((eventId) => {
    lotteryPromises.push(
      fetchFirestoreDocument<LotteryAppForm>(
        `tickets/${eventId}/lottery/${userId}`
      )
    );
  });

  const results = await Promise.all(lotteryPromises);
  const waitingList: { [key: string]: LotteryAppForm } = {};
  results.forEach((v, i) => {
    const eventId = eventIds[i];
    if (!eventId || !v) {
      return;
    }

    waitingList[eventId] = v;
  });

  return waitingList;
};
