import React from "react";
import { Shop } from "./Shop";
import { LoaderLayout } from "../components/atoms/loader/LoaderLayout";
import { useGetShopPublic } from "./useGetShopPublic";

type Props = {
  tenantId: string;
  brandId: string;
  shopId: string;
};
export const ShopContainer: React.FC<Props> = (props) => {
  const { loading, shop } = useGetShopPublic(props);
  if (loading || shop === undefined) return <LoaderLayout />;
  // TODO エラー・未ログインのハンドリング
  return <Shop {...props} shopInfo={shop} />;
};
