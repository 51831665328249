import React, { FC } from "react";

export const ExternalOpenIcon: FC = () => {
  return (
    <img
      src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDdWMTIuNzVDMTIgMTIuOTE0MiAxMS45Njc3IDEzLjA3NjcgMTEuOTA0OCAxMy4yMjg0QzExLjg0MiAxMy4zOCAxMS43NSAxMy41MTc4IDExLjYzMzkgMTMuNjMzOUMxMS41MTc4IDEzLjc1IDExLjM4IDEzLjg0MiAxMS4yMjg0IDEzLjkwNDhDMTEuMDc2NyAxMy45Njc3IDEwLjkxNDIgMTQgMTAuNzUgMTRIMy4yNUMyLjkxODQ4IDE0IDIuNjAwNTQgMTMuODY4MyAyLjM2NjEyIDEzLjYzMzlDMi4xMzE3IDEzLjM5OTUgMiAxMy4wODE1IDIgMTIuNzVWNS4yNUMyIDQuOTE4NDggMi4xMzE3IDQuNjAwNTQgMi4zNjYxMiA0LjM2NjEyQzIuNjAwNTQgNC4xMzE3IDIuOTE4NDggNCAzLjI1IDRIOC40ODM3NU0xMC41IDJIMTRWNS41TTcgOUwxMy43NSAyLjI1IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
      alt="SPWN Portal"
    />
  );
};
