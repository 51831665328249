/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { React } from "mdi-material-ui";
import { ForwardRefRenderFunction, forwardRef } from "react";

export type CapsuleSelectOption = {
  numOfRolls: number;
  hasBonus: boolean;
};

export type CapsuleSelectNumberOfRollsProps = {
  options: CapsuleSelectOption[];
  value: number;
  onChange: (value: number) => void;
};

const CapsuleSelectNumberOfRollsRender: ForwardRefRenderFunction<
  HTMLSelectElement,
  CapsuleSelectNumberOfRollsProps
> = ({ options, value, onChange }, ref) => {
  return (
    <div css={selectWrapStyle}>
      <select
        ref={ref}
        css={selectStyle}
        value={value}
        onChange={(e) => {
          const numOfRolls = parseInt(e.target.value);
          onChange(numOfRolls);
        }}
      >
        {options.map(({ numOfRolls, hasBonus }) => (
          <option key={numOfRolls} value={numOfRolls}>
            {`${numOfRolls}` + (hasBonus ? " 特典あり" : "")}
          </option>
        ))}
      </select>
    </div>
  );
};
CapsuleSelectNumberOfRollsRender.displayName = "CapsuleSelectNumberOfRolls";

export const CapsuleSelectNumberOfRolls = forwardRef(
  CapsuleSelectNumberOfRollsRender
);

const selectStyle = css`
  width: max-content;
  min-width: 141px;
  padding: 9px ${8 + 28}px 9px 12px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
  border: 1px solid #b1b1b1;
  background: #fff;
`;

const selectWrapStyle = css`
  position: relative;
  display: inline-block;
  max-width: 141px;
  max-height: 36px;
  margin-bottom: 24px;
  /* 三角形 */
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    right: 8px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 6px 0 6px;
    border-color: #000 transparent transparent transparent;
  }
`;
