import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../store";
import { FullScreenDialog } from "components/common/Modal";
import { DialogStyles } from "components/Styled";
import { modalActions, ErrorModalData } from "../modules/modal";
import { replaceBr } from "../utility";
import { GrayTextLink } from "../components/common/Modal";
import { withTranslation, WithTranslation } from "react-i18next";
import { Popup } from "../styles";

interface Props extends WithTranslation {
  isOpen: boolean;
  errorModalData: ErrorModalData;
  toggleError: () => void;
}

class Error extends Component<Props> {
  closeModal = () => {
    this.props.toggleError();
  };
  render() {
    const { isOpen, errorModalData, t } = this.props;
    if (!isOpen) {
      return <></>;
    }

    return (
      <FullScreenDialog
        open={isOpen}
        style={DialogStyles}
        onBackdropClick={this.closeModal}
      >
        <Popup>
          <h2>{replaceBr(errorModalData.caption) || "Error"}</h2>
          <p className="popup_message" style={{ whiteSpace: "pre-line" }}>
            {replaceBr(errorModalData.msg)}
          </p>
          <GrayTextLink
            onClick={this.closeModal}
            text={t("login.buttons.close")}
          />
        </Popup>
      </FullScreenDialog>
    );
  }
}

const mapStateToProps = (state: Store) => {
  return {
    isOpen: state.modal.isOpenError,
    errorModalData: state.modal.errorModalData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    toggleError: () => {
      dispatch(modalActions.toggleError({}));
    },
  };
};

const TransError = withTranslation()(Error);

export default connect(mapStateToProps, mapDispatchToProps)(TransError);
