import appConfig from "constants/appConfig";
import { useState, useCallback } from "react";
import { fetchFbToken } from "../../utility/firebase";

export type VoucherErrorType = "unknown";

const VoucherError = (errorObject: { code: string; msg: string }) =>
  errorObject;

export const useVoucherTOFES = () => {
  const [loading, setLoading] = useState(null);
  // @ts-expect-error TS2345
  const [error, setError] = useState<VoucherErrorType>(null);
  const [successAssign, setSuccessAssign] = useState(false);

  const assignVoucher = useCallback(async (voucherCode: string) => {
    try {
      // @ts-expect-error TS2345
      setLoading(true);
      setSuccessAssign(false);

      if (!voucherCode)
        throw VoucherError({
          code: "no-input",
          msg: "バウチャーコードを入力してください",
        });

      const fbToken = await fetchFbToken();
      if (!fbToken) return;
      const response = await fetch(
        // @ts-expect-error TS2769
        appConfig.CloudFunctions.assignVoucherTOFES,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `'Bearer ${fbToken}`,
          },
          body: JSON.stringify({ voucherCode }),
        }
      );
      if (response.status === 200) {
        setSuccessAssign(true);
      } else {
        throw VoucherError({
          code: "unknown",
          msg: "コードが間違っているか有効期限切れ/使用済みのコードです",
        });
      }
    } catch (error) {
      console.error(error);
      setSuccessAssign(false);
      setError("unknown");
    } finally {
      // @ts-expect-error TS2345
      setLoading(false);
    }
  }, []);

  return {
    loading,
    error,
    successAssign,
    assignVoucher,
  };
};
