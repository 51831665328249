/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../../store";
import firebase from "firebase/app";
import "firebase/firestore";

import "firebase/auth";
import { Timestamp } from "@google-cloud/firestore";
import { purchaseActions } from "../../modules/purchase";
import { modalActions, ReqToggleError } from "../../modules/modal";
import {
  streamingActions,
  ReqFetchGiftItems,
  ReqSetStateByKey,
  GiftItemData,
  StreamingType,
  ReqFetchShardIdArgs,
} from "../../modules/streaming";

import { errorReport } from "../../utility/logger";
import { fetchFirestoreDocument, setFirestoreDocument } from "utility/firebase";
import { changeStyleWithHosting } from "utility/hosting";
import {
  getEventIdFromHref,
  domain_from_url,
  getCookieValue,
  set_cookie,
  EventInfo,
} from "../../utility";
import {
  loadCurrentTimeFromSessionStorage,
  saveCurrentTimeToSessionStorage,
} from "../../utility/streaming";

import appConfig from "../../constants/appConfig";
import { PCView } from "components/common/MediaQuery";
import { BreadcrumbArea } from "components/common/Link";
import { createPlayer, createAWSPlayer } from "./StreaminngUtility";
import StreamingScreen from "./StreamingScreen";
import StreamingActionArea from "./StreamingActionArea";
import "../../designs/css/ui.css";
import { v4 as uuidv4 } from "uuid";
import { withTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { AlertBar } from "components/atoms/AlertBar";
import {
  isPlayerSupported,
  MediaPlayer,
  PlayerEventType,
  PlayerState,
} from "amazon-ivs-player";
// @ts-expect-error TS7016
import youbora from "youboralib";
import "youbora-adapter-html5";
import type { Cookie } from "@spwn/types/functions";
import type { THEOPlayer } from "@spwn/types/external";
import {
  EventVideo,
  THEOSetting,
  FingerprintArray,
} from "@spwn/types/firebase/firestore";
import { StreamingTimetable } from "./StreamingTimetable";
import { StreamingEventDetail } from "./StreamingEventDetail";
import { StreamingTab } from "./StreamingTab";
import { StreamingDownload } from "./StreamingDownload";
import { StreamingEventDescription } from "./StreamingEventDescription";
import { StreamingOnlyComment } from "./onlyComment/StreamingOnlyComment";
import { judgeCrewDomain } from "features/crew/domain";
import { StreamingAnalytics } from "./StreamingAnalytics";

type Props = ValueProps &
  FuncProps & {
    url: string;
    event: EventInfo;
    hasTicket: boolean;
    cookies: {
      [videoId: string]: {
        LL?: Cookie;
        default: Cookie;
      };
    };
    cookiesList?: {
      [videoId: string]: {
        [videoId: string]: {
          LL?: Cookie;
          default: Cookie;
        };
      }[];
    } | null;
    vid: string;
    vodSetting: THEOSetting;
    liveSetting: THEOSetting;
    eventVideoMap: { [videoId: string]: EventVideo };
    t: TFunction;
    getStreamingKey?: () => Promise<void>;
  };

interface ValueProps {
  pathname: string;
  giftItemMap: { [key: string]: GiftItemData };
  pawBalance: number | undefined;
  isDarkModeEnabled: boolean;
  isOnlyChatEnabled: boolean;
  isOpenError: boolean;
}

interface FuncProps {
  getEmoBalance: () => void;
  fetchGiftItems: (data: ReqFetchGiftItems) => void;
  // @ts-expect-error TS7051
  usePAW: (any) => void;
  toggleError: (any: ReqToggleError) => void;
  setStateByKey: (payload: ReqSetStateByKey) => void;
  fetchCommentShardId: (payload: ReqFetchShardIdArgs) => void;
}

interface States {
  theaterModeState: boolean;
  firstMutedFlag: boolean;
  use_ll: boolean;
  hasURL: boolean; // if false, delete touch sensor
  hasURLUpdatingRequested: boolean;
  isDarkModeEnabled: boolean;
  eventStart: Timestamp;
  isSuperChat: boolean;
  currentVideoId: string;
  THEOPlayerElementHeight: number;
  exceedWindowLimit: boolean;
  clickCount: number;
  isControllerRendering: boolean;
  isCancelLowLatencyMessage: boolean;
  selectVideoId: string;
}

export type CautionType =
  | "requestFullScreen"
  | "unableToPost"
  | "postFailed"
  | "giftFailed";

const isError = (error: unknown): error is Error => error instanceof Error;

/**
 * 対象の配信が低遅延モードが使用可能か
 */
const hasLowLatency = (
  cookies: {
    [videoId: string]: {
      LL?: Cookie;
      default: Cookie;
    };
  },
  currentVideoId: string
): boolean => {
  return (
    cookies &&
    cookies.hasOwnProperty(currentVideoId) &&
    // @ts-expect-error TS2532
    cookies[currentVideoId].hasOwnProperty("LL")
  );
};

/**
 * 対象videoのstremingTypeを取得する
 * - streamingType
 *   - Live ライブ配信
 *   - VOD アーカイブ配信
 *
 * NOTE: videoのhasVODは後発で追加されたため、undefinedのチェックを行なっている
 * 優先度: event.hasVOD > video.hasVOD
 */
const getVideoStreamingType = (
  video: EventVideo,
  event: EventInfo
): StreamingType => {
  const hasVODOfVideo = video.hasVOD;
  return hasVODOfVideo === undefined
    ? event.hasVOD
      ? "VOD"
      : "Live"
    : event.hasVOD && hasVODOfVideo
    ? "VOD"
    : "Live";
};

class Streaming extends Component<Props, States> {
  _eventId = null;
  _player: THEOPlayer = null;
  // @ts-expect-error TS2322
  _aws_player: MediaPlayer = null;
  _aws_youbora = null;
  _is_initial_play = true; // to explicit muted auto play
  _streamingUrl = null;
  _streamingType: StreamingType = "Live";
  _fingerprint = String(uuidv4());
  _unsubscribe = null;

  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    this.setStreamingType(this.props.vid);
    this.props.getEmoBalance();
    // @ts-expect-error TS2322
    this.props.fetchGiftItems({ eid: this.props.event._id });
    // @ts-expect-error TS2322
    this._eventId = getEventIdFromHref(this.props.pathname, "events");
    const video = this.props.eventVideoMap[this.props.vid];
    // @ts-expect-error TS2345
    const streamingType = getVideoStreamingType(video, this.props.event);
    this.state = {
      theaterModeState: false,
      firstMutedFlag: false,
      // 低遅延モードが使用可能であればデフォルトを低遅延モードにする
      use_ll:
        isPlayerSupported &&
        streamingType === "Live" &&
        hasLowLatency(this.props.cookies, this.props.vid),
      hasURL: true,
      hasURLUpdatingRequested: true,
      isDarkModeEnabled: false,
      // @ts-expect-error TS2322
      eventStart: null,
      isSuperChat: false,
      currentVideoId: this.props.vid,
      // @ts-expect-error TS2322
      THEOPlayerElementHeight: null,
      exceedWindowLimit: false,
      clickCount: 0,
      isControllerRendering: false,
      isCancelLowLatencyMessage: false,
      // @ts-expect-error TS2322
      selectVideoId: null,
    };
    // this.check_geo(this._eventId)
  }

  componentDidMount() {
    //TODO マーダーミステリー終了後に削除 後ほど修正する用の差分memo
    this.setState({ selectVideoId: this.props.vid });

    // initialize device unique id
    // @ts-expect-error TS2322
    this._fingerprint = localStorage.getItem("youbora.youboraDeviceUUID");
    if (!this._fingerprint) {
      // @ts-expect-error TS2322
      this._fingerprint = sessionStorage.getItem("fingerprint");
    }
    if (!this._fingerprint) {
      this._fingerprint = String(uuidv4());
    }
    sessionStorage.setItem("fingerprint", this._fingerprint);

    const poster = this.props.event.thumbnail;
    this.setFingerprint();
    if (this._streamingType === "VOD") {
      this._player = createPlayer(
        poster,
        this.props.url,
        this.props.vodSetting,
        this._streamingType,
        this.props.vid
      );
      // @ts-expect-error TS2322
      this._aws_player = createAWSPlayer();
    } else {
      this._player = createPlayer(
        poster,
        this.props.url,
        this.props.liveSetting,
        this._streamingType
      );
      // @ts-expect-error TS2322
      this._aws_player = createAWSPlayer();
    }
    // eslint-disable-next-line eqeqeq
    if (getCookieValue("preferDarkMode") == "true") {
      // !! 文字列なのかtrueなのかはっきりさせて確実に評価する
      this.setState({ isDarkModeEnabled: true });
    }

    // アーカイブ配信の場合、キーボードショートカットを有効にする
    if (this._streamingType === "VOD") {
      document.addEventListener("keydown", this.handleKeyDown);
      this._player.addEventListener("timeupdate", () => {
        saveCurrentTimeToSessionStorage(
          this.state.currentVideoId,
          this._player.currentTime
        );
      });
    }

    this.setState({
      // @ts-expect-error TS2322
      THEOPlayerElementHeight: document.getElementById("video")?.clientHeight,
    });
    window.addEventListener("resize", () =>
      this.setState({
        // @ts-expect-error TS2322
        THEOPlayerElementHeight: document.getElementById("video")?.clientHeight,
      })
    );

    // if comment sharding is enabled, set shard id
    this.props.fetchCommentShardId({
      chatVersion:
        this.props.event.streamingInfo?.optionSettings?.chatVersion || null, // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
      nCommentShards:
        this.props.event.streamingInfo?.optionSettings?.nCommentShards,
      isCommentShardingEnabled:
        this.props.event.streamingInfo?.optionSettings
          ?.isCommentShardingEnabled ?? false,
      // @ts-expect-error TS2322
      userId: firebase.auth()?.currentUser?.uid || null, // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
      // @ts-expect-error TS2322
      eventId: this.props.event._id,
      videoId: this.props.vid,
    });
  }

  componentDidUpdate(prevProps: Props, prevState: States) {
    // when videoId changed and the video stream url is exist, update current video.
    if (
      this.state.hasURLUpdatingRequested &&
      this.props.cookies?.[this.state.currentVideoId]
    ) {
      this.setWatchVideo(this.state.currentVideoId, prevState.currentVideoId);
      this.setState({ hasURLUpdatingRequested: false });
    }
    // when parent videoId is changed, update current video.
    if (this.props.vid !== prevProps.vid) {
      this.setState({ currentVideoId: this.props.vid });
      this.setState({ hasURLUpdatingRequested: true });
    }
  }

  /**
   * apply streaming type by hasVOD flag. priority: event.hasVOD > video.hasVOD
   * @param vid
   */
  setStreamingType = (vid: Props["vid"]) => {
    const video = this.props.eventVideoMap[vid];
    // @ts-expect-error TS2345
    const streamingType = getVideoStreamingType(video, this.props.event);
    this._streamingType = streamingType;
    this.props.setStateByKey({ streamingType });
  };

  // event listener に async function を渡すのは非推奨であることと await による記述を両立するため即時関数を使用
  setFingerprint = () => {
    (async () => {
      try {
        const userId = firebase.auth()?.currentUser?.uid;
        const eventId = this._eventId;
        if (!userId || !eventId) return;
        const registeredFingerprints =
          await fetchFirestoreDocument<FingerprintArray>(
            `/users/${userId}/playingState/${eventId}`
          );
        let newFingerprints = [];
        const now = new Date();
        if (registeredFingerprints) {
          const { fingerprints } = registeredFingerprints;
          //fingerprint field is null
          if (!fingerprints) {
            newFingerprints.push({
              fingerprint: this._fingerprint,
              createdAt: now,
            });
          }
          //my fingerprint is not set
          else if (
            !fingerprints.some(
              (fingerprint) => fingerprint.fingerprint === this._fingerprint
            )
          ) {
            newFingerprints = fingerprints.sort(
              (fingerprint1, fingerprint2) => {
                return (
                  // @ts-expect-error TS2551
                  fingerprint2["createdAt"].seconds -
                  // @ts-expect-error TS2551
                  fingerprint1["createdAt"].seconds
                );
              }
            );
            //pop old fingerprint
            if (newFingerprints.length > 0) {
              if (
                this.props.event?.nWindowLimit &&
                this.props.event.nWindowLimit > 0
              ) {
                const { nWindowLimit } = this.props.event;
                if (newFingerprints.length > nWindowLimit - 1) {
                  const n = Math.min(nWindowLimit - 1, newFingerprints.length);
                  newFingerprints = newFingerprints.slice(0, n);
                }
              } else {
                newFingerprints = [newFingerprints[0]];
              }
            }
            //add my finger print
            newFingerprints.push({
              fingerprint: this._fingerprint,
              // @ts-expect-error TS2345
              createdAt: now,
            });
            //keep current fingerprints (my fingerprint already has been set)
          } else {
            newFingerprints = fingerprints;
            //reject old finger print if fingerprints length is greater than nWindowLimit
            if (
              this.props.event?.nWindowLimit &&
              this.props.event.nWindowLimit > 0
            ) {
              const { nWindowLimit } = this.props.event;
              if (newFingerprints.length > nWindowLimit) {
                newFingerprints = fingerprints.sort(
                  (fingerprint1, fingerprint2) => {
                    return (
                      // @ts-expect-error TS2551
                      fingerprint2["createdAt"].seconds -
                      // @ts-expect-error TS2551
                      fingerprint1["createdAt"].seconds
                    );
                  }
                );
                const n = Math.min(nWindowLimit, newFingerprints.length);
                newFingerprints = newFingerprints.slice(0, n);
              }
            }
          }
          // create first fingerprint
        } else {
          newFingerprints.push({
            fingerprint: this._fingerprint,
            createdAt: now,
          });
        }
        await setFirestoreDocument(
          `/users/${userId}/playingState/${eventId}`,
          {
            fingerprints: newFingerprints,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          false
        );
        this._player.removeEventListener("play", this.setFingerprint);
        if (this._aws_player) {
          this._aws_player.removeEventListener(
            PlayerState.PLAYING,
            this.setFingerprint
          );
        }
        if (!this._unsubscribe) this.watchPlayingState();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  watchPlayingState = () => {
    const userId = firebase.auth()?.currentUser?.uid;
    const eventId = this._eventId;
    if (!userId || !eventId || this._unsubscribe) return;
    // @ts-expect-error TS2322
    this._unsubscribe = firebase
      .firestore()
      .doc(`/users/${userId}/playingState/${eventId}`)
      .onSnapshot(
        (snapshot) => {
          const data = snapshot.data();
          // @ts-expect-error TS2339
          const { fingerprints } = data;
          if (
            !!fingerprints &&
            !fingerprints.some(
              // @ts-expect-error TS7006
              (fingerprint) => fingerprint.fingerprint === this._fingerprint
            ) &&
            this._player &&
            this.props.event &&
            this.props.event?.enableWindowLimit
          ) {
            this.setState({ exceedWindowLimit: true });
            if (!this.props.isOpenError) {
              this.props.toggleError({
                msg: "他の端末での再生が検知されたため再生を停止しました<br/>Too many people are using your account right now",
              });
            }
            this._player.pause();
            this._player.addEventListener("play", this.setFingerprint);
            if (this._aws_player) {
              this._aws_player.pause();
              this._aws_player.addEventListener(
                PlayerState.PLAYING,
                this.setFingerprint
              );
            }
          }
          console.log("playingSate", this.state.exceedWindowLimit);
        },
        (error) => {
          console.error(error);
        }
      );
  };

  // @ts-expect-error TS7006
  getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  /**
   * videoを切り替える
   *
   * 呼ばれるトリガー
   * - 配信ページに入る
   * - EventVideoListで配信を切り替える
   * - 低遅延モードを切り替える
   */
  setWatchVideo = async (vid: string, prevVid?: string) => {
    if (vid !== prevVid && this.props.getStreamingKey !== undefined) {
      await this.props.getStreamingKey();
    }
    // update url when user has some cookies (streamig has started or user is admin)
    if (this.props.cookies !== null && Boolean(this.props.cookies[vid])) {
      // check if url is present. if url is not present, set video poster.

      // 低遅延モードが使用可能か
      const video = this.props.eventVideoMap[vid];
      // @ts-expect-error TS2345
      const streamingType = getVideoStreamingType(video, this.props.event);
      const useLL =
        isPlayerSupported &&
        this.state.use_ll &&
        streamingType === "Live" &&
        hasLowLatency(this.props.cookies, vid);

      const hasURL = useLL
        ? Boolean(this.props.cookies[vid]?.LL?.url)
        : Boolean(this.props.cookies[vid]?.default?.url);
      this.setState({ hasURL });
      if (hasURL) {
        const { cookies, cookiesList } = this.props;
        let settingCookie = useLL
          ? cookies?.[vid]?.["LL"]
          : cookies?.[vid]?.["default"];
        const cookie = cookiesList?.[vid];
        if (cookie) {
          const idx = this.getRandomInt(0, cookie.length - 1);
          const selectedCookie = cookie[idx]?.[vid];
          settingCookie = useLL
            ? selectedCookie?.["LL"]
            : selectedCookie?.["default"];
        }
        if (settingCookie) {
          this.updatePlayer(settingCookie, vid, useLL);
        }
      } else {
        this.hideAWSPlayer();
        // make source empty to stop streaming
        this._player.source = {
          sources: [],
        };
        // just show up THEOPlayer when source url is empty
        const theo_video_elem = document.querySelector(".theoplayer-container");
        // @ts-expect-error TS18047
        theo_video_elem.setAttribute("style", "display: 'block'");
        const touch_area_wrap = document.querySelector("#touch-area-wrap");
        // @ts-expect-error TS18047
        touch_area_wrap.setAttribute("style", "display: 'block'");

        // use video thumbnail for poster
        this._player.poster =
          this.props.eventVideoMap[vid]?.thumbnail || // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
          this.props.event.thumbnail;
      }
    }
    // @ts-expect-error TS2322
    this._streamingUrl = this.props.url;
    // set event start time
    if (this.props.eventVideoMap) {
      const video = this.props.eventVideoMap[vid];
      if (video && video.chatDatetime) {
        this.setState({ eventStart: video.chatDatetime });
      } else {
        // @ts-expect-error TS2322
        this.setState({ eventStart: this.props.event.chatDatetime });
      }
    }

    // リロードで配信ページに入った場合に表示するビデオを決定するため、ビデオ切り替え時に閲覧中ビデオidをqueryに持たせる
    const url = new URL(window.location.href);
    url.searchParams.set("vid", vid);
    window.history.pushState({}, "", url);

    this.setState({ currentVideoId: vid });
    this.setStreamingType(vid);
  };

  _keyCodes = {
    space: 32,
    right_arrow: 39,
    left_arrow: 37,
  };

  // @ts-expect-error TS7006
  handleKeyDown = (event) => {
    if (
      !this._player ||
      !this._player.source ||
      !this._player.source.sources ||
      !this._player.source.sources[0].src
    ) {
      return;
    }
    if (this._player.seekable.length === 0) {
      return;
    }
    const begin = this._player.seekable.start(0);
    const end = this._player.seekable.end(this._player.seekable.length - 1);
    const current = this._player.currentTime;
    switch (event.keyCode) {
      case this._keyCodes.space:
        event.preventDefault();
        if (this._player.paused) {
          this._player.play();
        } else {
          this._player.pause();
        }
        break;
      case this._keyCodes.right_arrow:
        if (current + 5 < end) {
          this._player.currentTime = current + 5;
        }
        break;
      case this._keyCodes.left_arrow:
        if (current - 5 >= begin) {
          this._player.currentTime = current - 5;
        } else {
          this._player.currentTime = begin;
        }
        break;
      default:
        break;
    }
  };

  componentWillUnmount() {
    // ストリーミングページのみで使用する className を削除する
    // document.getElementById(appConfig.indexRoot).removeAttribute("class");
    // document.getElementById("container").removeAttribute("style");

    if (this._streamingType === "VOD") {
      document.removeEventListener("keydown", this.handleKeyDown);
    }

    window.removeEventListener("resize", () =>
      this.setState({
        // @ts-expect-error TS2322
        THEOPlayerElementHeight: document.getElementById("video")?.clientHeight,
      })
    );

    if (this._unsubscribe) {
      // @ts-expect-error TS2349
      this._unsubscribe();
    }

    try {
      if (this._player) {
        this._player.destroy();
      }
    } catch (e) {
      console.error(e);
      // @ts-expect-error TS2345
      errorReport(isError(e) && e);
    }

    try {
      if (this._aws_player) {
        this._aws_player.delete();
      }
    } catch (e) {
      console.error(e);
      // @ts-expect-error TS2345
      errorReport(isError(e) && e);
    }

    try {
      if (this._aws_youbora) {
        // @ts-expect-error TS2339
        this._aws_youbora.removeAdapter();
      }
    } catch (e) {
      console.error(e);
      // @ts-expect-error TS2345
      errorReport(isError(e) && e);
    }
  }

  cancelLLPlaying = (_e = null) => {
    // LL機能がキャンセルした時,stateにtrueを格納する
    // 再度LLを有効にする可能性がある為、有効にした時の処理でstateにfalseを入れる
    this.setState({
      use_ll: false,
      hasURLUpdatingRequested: true,
      isCancelLowLatencyMessage: true,
    });
    window.setTimeout(() => {
      this.setState({ isCancelLowLatencyMessage: false });
    }, 5000);
  };

  /**
   * 低遅延Player（IVS Player）への切り替えのため、THEOPlayerを無効にする
   *
   * NOTE: IVS Player への切り替え以外で利用しないこと
   */
  hideTHEOPlayer = () => {
    if (this._player) {
      this._player.muted = true;
      /**
       * this._player.pause() だと、今読み込んでいる配信リソース（source）はリリースしないので、裏で読み込み続けることになる。
       * 低遅延モードへの切り替えは、player自体の切り替えで、sourceを読み込み続ける必要はないのでstopにすることでsourceをリリースする。
       * stopはdestroyと違って、player自体はリリースされないので、再度sourceを読み込み直せば再生可能である
       * ここでは activateTHEOPlayer によってsourceの再読み込みが実行されるのでstopとしている
       */
      this._player.stop();
      const theo_video_elem = document.querySelector(".theoplayer-container");
      // @ts-expect-error TS18047
      theo_video_elem.setAttribute("style", "display: none");
      const touch_area_wrap = document.querySelector("#touch-area-wrap");
      // @ts-expect-error TS18047
      touch_area_wrap.setAttribute("style", "display: none");
    }
  };

  hideAWSPlayer = () => {
    if (this._aws_player) {
      this._aws_player.setMuted(true);
      this._aws_player.setVolume(0.0); // add this because some times set muted not worked correctly...
      this._aws_player.pause();
      const element = this._aws_player.getHTMLVideoElement();
      element.style.display = "none";
      this._aws_player.removeEventListener(
        PlayerState.ENDED,
        // @ts-expect-error TS2345
        this.cancelLLPlaying
      );
      this._aws_player.removeEventListener(
        PlayerEventType.ERROR,
        // @ts-expect-error TS2345
        this.cancelLLPlaying
      );
      if (this._aws_youbora) {
        // @ts-expect-error TS2339
        this._aws_youbora.removeAdapter();
      }
    }
  };

  activateTHEOPlayer = (cookie: Cookie, vid: string) => {
    const theo_video_elem = document.querySelector(".theoplayer-container");
    // @ts-expect-error TS18047
    theo_video_elem.setAttribute("style", "display: 'block'");
    const touch_area_wrap = document.querySelector("#touch-area-wrap");
    // @ts-expect-error TS18047
    touch_area_wrap.setAttribute("style", "display: 'block'");
    try {
      this._player.source = {
        sources: [
          {
            src: cookie.url,
            type: "application/x-mpegurl",
            liveOffset: cookie.hasOwnProperty("liveOffset")
              ? cookie.liveOffset
              : 10,
            crossOrigin: cookie.cors ? "use-credentials" : "",
          },
        ],
      };
      // 本番環境でのみ、NPAW（youbora）へアナリティクスを送信する
      if (!appConfig.logDebug) {
        this._player.source = {
          ...this._player.source,
          analytics: [
            {
              integration: "youbora",
              accountCode: appConfig.Youbora.accountCode,
              "user.name": firebase.auth()?.currentUser?.uid || "undefined", // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
              enableAnalytics: true,
              "content.title": vid,
              "content.duration":
                this.props.eventVideoMap?.[vid]?.duration || 0, // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
              // ライブかアーカイブか
              "content.isLive": this._streamingType === "Live",
            },
          ],
        };
        /**
         * ABR設定
         * Live: bandwidth
         *   帯域幅に合わせる。画質よりもライブ配信を届けることを優先。
         * VOD: quality
         *   品質を重視する。アーカイブなので画質優先。
         *   NOTE: bandwidth にした方が、帯域によっては配信ページに入った瞬間に画質が良くなる
         * @see https://docs.theoplayer.com/api-reference/web/theoplayer.abrstrategytype.md
         */
        this._player.abr.strategy =
          this._streamingType === "Live"
            ? this.props.liveSetting.abr.strategy
            : this.props.vodSetting.abr.strategy;
        /**
         * バッファ設定
         * Live: 6秒
         * VOD: 12秒
         * バッファが短いほどライブに近いが、帯域幅によって画質が落ちるため、Liveは小さく、VODは大きくしている
         * @see https://docs.theoplayer.com/api-reference/web/theoplayer.abrconfiguration.md
         */
        this._player.abr.targetBuffer =
          this._streamingType === "Live"
            ? this.props.liveSetting.abr.targetBuffer
            : this.props.vodSetting.abr.targetBuffer;
      }
      /**
       * 動画の自動ループを有効にする（再生が終わったら自動で最初から再生される）
       * @deprecated 2023/10 調べたところ、enableLoopを有効にしている処理は見つからないので未使用っぽい
       * https://github.com/search?q=org%3Abalus-co-ltd%20enableLoop&type=code
       */
      if (this._streamingType !== "Live") {
        this._player.loop =
          this.props.eventVideoMap?.[vid]?.enableLoop || false; // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
      } else {
        this._player.loop = false;
      }
      /**
       * アーカイブ配信の場合、最後に見ていた再生時間にシークさせる
       */
      if (this._streamingType === "VOD") {
        const previousPlayHeadPosition = loadCurrentTimeFromSessionStorage(vid);
        if (previousPlayHeadPosition) {
          this._player.currentTime = previousPlayHeadPosition;
        }
      }
    } catch (e) {
      // Add error
      console.error(e);
      // @ts-expect-error TS2345
      errorReport(isError(e) && e);
      // npawのload errorなどの場合を考慮して、読み込み直している
      this._player.source = {
        sources: [
          {
            src: cookie.url,
            type: "application/x-mpegurl",
            liveOffset: cookie.hasOwnProperty("liveOffset")
              ? cookie.liveOffset
              : 10,
            crossOrigin: cookie.cors ? "use-credentials" : "",
          },
        ],
      };
    } finally {
      // `_is_initial_play` いらないけど残しておく
      if (this._is_initial_play) {
        // this._player.muted = false
        this._is_initial_play = false;
      }
      // FIX: ブラウザの仕様の為、リロードすると音声ON・自動再生がされない
      // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
      // 現状、check_geo が重い処理の為 setTimeout と同じ動作をしている
      this._player.play();
    }
  };

  activateAWSPlayer = (cookie: Cookie, vid: string) => {
    const aws_video_elem = this._aws_player.getHTMLVideoElement();
    // @ts-expect-error TS2345
    this._aws_player.addEventListener(PlayerState.ENDED, this.cancelLLPlaying);
    this._aws_player.addEventListener(
      PlayerEventType.ERROR,
      // @ts-expect-error TS2345
      this.cancelLLPlaying
    );

    aws_video_elem.style.display = "block";
    this._aws_player.load(cookie.url);
    this._aws_player.setLiveLowLatencyEnabled(true);
    this._aws_player.setAutoplay(true);
    this._aws_player.setMuted(false);
    this._aws_player.setVolume(1.0);
    this._aws_player.play();

    if (this._aws_youbora == null) {
      // youbora.Log.logLevel = youbora.Log.Level.DEBUG
      this._aws_youbora = new youbora.Plugin({
        accountCode: appConfig.Youbora.accountCode,
      });
    }
    try {
      // @ts-expect-error TS2531
      this._aws_youbora.setOptions({
        "user.name": firebase.auth()?.currentUser?.uid || "undefined", // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
        "content.title": vid,
        // ライブかアーカイブか
        "content.isLive": this._streamingType === "Live",
      });
      // @ts-expect-error TS2531
      this._aws_youbora.setAdapter(
        new youbora.adapters.Html5("aws-video-player")
      );
    } catch (e) {
      console.error(e);
      // @ts-expect-error TS2345
      errorReport(isError(e) && e);
    }
  };

  updatePlayer = (cookie: Cookie, vid: string, useLL = false) => {
    // @ts-expect-error TS2345
    set_cookie(cookie, domain_from_url(cookie.url));
    if (useLL && !!this._aws_player) {
      this.hideTHEOPlayer();
      this.activateAWSPlayer(cookie, vid);
    } else {
      try {
        this.hideAWSPlayer();
      } catch (e) {
        console.error(e);
        // @ts-expect-error TS2345
        errorReport(isError(e) && e);
      }
      try {
        this.activateTHEOPlayer(cookie, vid);
      } catch (e) {
        console.error(e);
        // @ts-expect-error TS2345
        errorReport(isError(e) && e);
      }
    }
    this.setState({ hasURLUpdatingRequested: false });
  };

  setTHEOPlayerElementHeight = (THEOPlayerElementHeight: number) => {
    this.setState({ THEOPlayerElementHeight });
  };

  setClickCount = (num: number) => {
    this.setState({ clickCount: num });
  };

  render() {
    if (!this.props.event) {
      return null;
    }
    const { event } = this.props;
    const classes = styles({
      isDarkModeEnabled: this.props.isDarkModeEnabled,
      // @ts-expect-error TS2322
      isHiddenStreamingComment: this.props.event.isHiddenStreamingComment,
    });
    const { isCrewDomain } = judgeCrewDomain();

    return (
      <React.Fragment>
        <StreamingAnalytics
          player={this._player}
          awsPlayer={this._aws_player}
          videoId={this.state.currentVideoId}
        />
        <PCView>
          <AlertBar />
        </PCView>

        {!isCrewDomain && (
          <BreadcrumbArea
            // @ts-expect-error TS2322
            paths={[
              ["/", "ホーム"],
              [
                "/events",
                changeStyleWithHosting().commonSettings.menu.event.text,
              ],
              ["/events/" + event.eventId, event.eventTitle],
              [null, this.props.t("common.routes.live")],
            ]}
            isDarkModeEnabled={this.props.isDarkModeEnabled}
          />
        )}

        <div id="Streaming" css={classes.root}>
          <StreamingScreen
            setTHEOPlayerElementHeight={this.setTHEOPlayerElementHeight}
            THEOPlayerElementHeight={this.state.THEOPlayerElementHeight}
            player={this._player}
            awsPlayer={this._aws_player}
            eventInfo={this.props.event}
            useLL={this.state.use_ll}
            hasLL={hasLowLatency(this.props.cookies, this.state.currentVideoId)}
            hasURL={this.state.hasURL}
            setLLUrl={(checked) => {
              this.setState({
                use_ll: checked,
                hasURLUpdatingRequested: true,
              });
            }}
            isControllerRendering={this.state.isControllerRendering}
            setIsControllerRendering={() =>
              this.setState({ isControllerRendering: true })
            }
            isCancelLowLatencyMessage={this.state.isCancelLowLatencyMessage}
          />

          {/* ユーザー操作エリア (コメント、ギフト、etc(later: goods, quiz)) */}
          {((this._streamingType === "Live" && this._player) ||
            // if vod mode, eventStart is required
            (this._streamingType === "VOD" &&
              this._player &&
              this.state.eventStart)) && (
            <StreamingActionArea
              event={this.props.event}
              selectVideoId={this.state.selectVideoId}
              player={this._player}
              eventStart={this.state.eventStart}
              eventInfo={this.props.event}
              giftItemMap={this.props.giftItemMap}
              currentVideoId={this.state.currentVideoId}
              THEOPlayerElementHeight={this.state.THEOPlayerElementHeight}
              isHiddenStreamingComment={
                this.props.event.isHiddenStreamingComment ?? false
              }
              setWatchVideo={this.setWatchVideo}
              isDarkModeEnabled={this.props.isDarkModeEnabled}
              clickCount={this.state.clickCount}
              setClickCount={this.setClickCount}
            />
          )}

          <PCView>
            <StreamingEventDetail
              selectVideoId={this.state.selectVideoId}
              event={this.props.event}
              currentVid={this.state.currentVideoId}
              clickCount={this.state.clickCount}
              setClickCount={this.setClickCount}
              isDarkModeEnabled={this.state.isDarkModeEnabled}
              setWatchVideo={this.setWatchVideo}
            />
          </PCView>
        </div>

        <PCView>
          {this.props.event.isFesEvent && (
            <StreamingTimetable
              currentVideoId={this.state.currentVideoId}
              isHiddenStreamingComment={
                this.props.event.isHiddenStreamingComment
              }
            />
          )}

          <div css={classes.details}>
            <div css={classes.inner}>
              {changeStyleWithHosting().pageSettings.streamingPage
                .isDisplaySuperChatArea ? (
                <div
                  css={css`
                    margin-bottom: 24px;
                  `}
                >
                  <StreamingTab
                    event={this.props.event}
                    currentVid={this.state.currentVideoId}
                  />
                </div>
              ) : null}

              <StreamingDownload />

              <StreamingEventDescription
                event={this.props.event}
                isDarkModeEnabled={this.props.isDarkModeEnabled}
                clickCount={this.state.clickCount}
                setClickCount={this.setClickCount}
              />
            </div>
          </div>

          {this.props.isOnlyChatEnabled && (
            <StreamingOnlyComment
              giftItemMap={this.props.giftItemMap}
              setClickCount={this.setClickCount}
            />
          )}
        </PCView>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: Store) => {
  const props: ValueProps = {
    pathname: state.router.location.pathname,
    giftItemMap: state.streaming.giftItemMap,
    pawBalance: state.purchase.emoBalance.paidValue,
    isDarkModeEnabled: state.streaming.streamingSettings.isDarkModeEnabled,
    isOnlyChatEnabled: state.streaming.streamingSettings.isOnlyChatEnabled,
    isOpenError: state.modal.isOpenError,
  };
  return props;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const func: FuncProps = {
    getEmoBalance: () => {
      dispatch(purchaseActions.getEmoBalance.started());
    },
    fetchGiftItems: (payload: ReqFetchGiftItems) => {
      dispatch(streamingActions.fetchGiftItems.started(payload));
    },
    usePAW: (payload) => {
      dispatch(purchaseActions.usePAW.started(payload));
    },
    toggleError: (msgObj) => {
      dispatch(modalActions.toggleError(msgObj));
    },
    setStateByKey: (payload: ReqSetStateByKey) => {
      dispatch(streamingActions.setStateByKey(payload));
    },
    fetchCommentShardId: (payload: ReqFetchShardIdArgs) => {
      dispatch(streamingActions.fetchCommentShardId.started(payload));
    },
  };
  return func;
};

const TransStreaming = withTranslation()(Streaming);
export default connect(mapStateToProps, mapDispatchToProps)(TransStreaming);

interface StyleProps {
  isDarkModeEnabled: boolean;
  isHiddenStreamingComment: boolean;
}
const styles = (props: StyleProps) => {
  const backgroundInDarkMode = props.isDarkModeEnabled ? "#202020" : "#fff";
  const hiddenStreamingCommentLayout = css`
    grid-template-columns: 1fr;
    min-width: auto;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 95%;
      max-width: 1200px;
      padding: 40px 0;
    }
  `;

  const displayStreamingCommentLayout = css`
    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 360px;
      grid-gap: 20px 20px;
      width: 95%;
      margin: 0 auto;
      padding: 40px 0 0;
    }
  `;

  const hiddenStreamingCommentDetailsInner = css`
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
  `;

  const displayStreamingCommentDetailsInner = css`
    width: 95%;
    margin: 0 auto;
  `;

  return {
    root: props.isHiddenStreamingComment
      ? hiddenStreamingCommentLayout
      : displayStreamingCommentLayout,
    details: css`
      padding: 40px 0;
      background-color: ${backgroundInDarkMode};
    `,
    inner: props.isHiddenStreamingComment
      ? hiddenStreamingCommentDetailsInner
      : displayStreamingCommentDetailsInner,
  };
};
