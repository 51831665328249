/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useI18n } from "hooks/i18n/i18n";
import { FC } from "react";

export const AccountAddressRequiredLabel: FC = () => {
  const { t } = useI18n();

  return (
    <p
      css={css`
        padding: 2px 4px;
        margin-left: 8px;
        color: #fff;
        font-size: 12px;
        background: #ff0000;
      `}
    >
      {t("accountAddress.required")}
    </p>
  );
};
