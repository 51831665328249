/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC, useMemo } from "react";
import { useI18n } from "hooks/i18n/i18n";
import { AccountTicketActionButton } from "./AccountTicketActionButton";
import Box from "@material-ui/core/Box";
import { getDateTimeString } from "utility";

export const AccountTicketDigitalContentsDownload: React.FC<{
  isDownloadPreparing: boolean;
  fileName: string;
  downloadUrl?: string;
  downloadExpiredAt?: Date;
}> = ({ fileName, downloadUrl, downloadExpiredAt, isDownloadPreparing }) => {
  const { t } = useI18n();
  const classes = styles();

  const isExpired = downloadExpiredAt ? new Date() > downloadExpiredAt : false;
  const isPreparing = !downloadUrl || isDownloadPreparing;
  const isDownloadDisabled = isExpired || isDownloadPreparing;

  const buttonLabel = useMemo(() => {
    // 期限切れ
    if (isExpired) {
      return t("ticket.downloadDigitalContentsExpired");
    }
    // 準備中
    if (isPreparing) {
      return t("ticket.downloadDigitalContentsPreparing");
    }
    // 有効
    return t("ticket.downloadDigitalContents");
  }, [isExpired, isPreparing, t]);

  return (
    <div css={classes.root}>
      {fileName && (
        <Box component={"p"} css={classes.fileName}>
          {fileName}
        </Box>
      )}
      {downloadUrl && !isDownloadDisabled ? (
        <AccountTicketActionButton
          as={"download"}
          href={downloadUrl}
          filename={fileName}
          variant={"outline"}
        >
          <React.Fragment>
            <IconDL />
            {buttonLabel}
          </React.Fragment>
        </AccountTicketActionButton>
      ) : (
        <AccountTicketActionButton
          as={"button"}
          variant={"disabled"}
          onClick={() => {}} // 見た目のためのダミー関数
        >
          <React.Fragment>
            <IconDL fill={"#fff"} />
            {buttonLabel}
          </React.Fragment>
        </AccountTicketActionButton>
      )}
      {downloadExpiredAt && (
        <Box component={"p"} css={classes.expirationDate}>
          {t("ticket.downloadDigitalContentsExpirationDate", {
            date: getDateTimeString(downloadExpiredAt),
          })}
        </Box>
      )}
    </div>
  );
};

const styles = () => ({
  root: css`
    margin-top: 16px;
  `,
  fileName: css`
    margin-bottom: 8px;
    color: #00c2ad;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    /* 3点リーダー省略 */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  expirationDate: css`
    margin-top: 8px;
    color: red;
    font-size: 12px;
    font-weight: 500;
  `,
});

const IconDL: FC<{
  fill?: string;
}> = ({ fill }) => (
  <svg
    aria-hidden="true"
    style={{ marginRight: "8px" }}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 20H19V18H5M19 9H15V3H9V9H5L12 16L19 9Z"
      fill={fill ? fill : "#00C2AD"}
    />
  </svg>
);
