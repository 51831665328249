/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "../../../store";
import {
  getEventDisplayInfo,
  convertMapToValues,
  isDisplayPeriod,
} from "../../../utility";
import { EventSaleStatus } from "../../../modules/event";
import { changeStyleWithHosting } from "utility/hosting";
import { modalActions } from "modules/modal";
import { useI18n } from "hooks/i18n/i18n";
import icon_ticket from "designs/images/icon_ticket.svg";
import type { Event as IEvent } from "@spwn/types/firebase/firestore";

interface TicketPurchaseButtonProps {
  event: IEvent;
  style: string;
  noticeNotOnSale: (saleStatus: EventSaleStatus, ticketUrl?: string) => void;
}

export const TicketPurchaseButton: React.FC<TicketPurchaseButtonProps> = (
  props
) => {
  const user = useSelector((state: Store) => state.auth.user);
  const eventTicketMap = useSelector(
    (state: Store) => state.ticket.eventTicketMap
  );
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);

  const { t } = useI18n();

  const dispatch = useDispatch();
  const btnImage =
    changeStyleWithHosting().pageSettings.eventPage.purchaseButton.path;

  const dispInfo = getEventDisplayInfo(props.event);
  const isTicketPurchaseOnExternal =
    Boolean(dispInfo.eventSaleStatus === "ON_SALE") &&
    Boolean(dispInfo.ticketUrl);
  const isTicketPurchase =
    (Boolean(dispInfo.eventSaleStatus === "ON_SALE") &&
      convertMapToValues(eventTicketMap).filter((ticket) => {
        return isDisplayPeriod(
          ticket.display,
          ticket.releaseDateTime,
          ticket.closeDateTime
        );
      }).length > 0) ||
    isTicketPurchaseOnExternal;

  const classesTicketPurchaseButtonDesign = useMemo(
    () => ticketPurchaseButtonDesign({ isTicketPurchase }),
    [isTicketPurchase]
  );

  const isEmptyEventTicketMap =
    !eventTicketMap || Object.keys(eventTicketMap).length === 0;

  if (isEmptyEventTicketMap || !dispInfo.isTicketSellingPageOpen) {
    return null;
  }

  if (!isTicketPurchase && !isAdmin) {
    let ticketSaleStatusText = "";
    switch (dispInfo.eventSaleStatus) {
      case "BEFORE_SALE":
        ticketSaleStatusText = t("event.saleInfo.ticket.beforeOnSale");
        break;
      case "END":
        ticketSaleStatusText = t("event.saleInfo.ticket.endOfSale");
        break;
      default:
        ticketSaleStatusText = t("event.saleInfo.ticket.outOfTerm");
        break;
    }

    if (btnImage) {
      return (
        <div
          css={classesTicketPurchaseButtonDesign.root}
          className="ticketPurchaseButtonLayout"
        >
          <div id={props.style} css={classesTicketPurchaseButtonDesign.layout}>
            <div
              className="notSales"
              css={classesTicketPurchaseButtonDesign.imgButton}
            >
              <img src={btnImage} alt="" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        css={classesTicketPurchaseButtonDesign.root}
        className="ticketPurchaseButtonLayout"
      >
        <div id={props.style} css={classesTicketPurchaseButtonDesign.layout}>
          <div css={classesTicketPurchaseButtonDesign.button}>
            <p>{ticketSaleStatusText}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    // src/containers/event/Event.tsx でclassName指定で閾値を計算している箇所があるためclassNameを書いておく
    <div
      css={classesTicketPurchaseButtonDesign.root}
      className="ticketPurchaseButtonLayout"
    >
      <div id={props.style} css={classesTicketPurchaseButtonDesign.layout}>
        <div
          css={
            btnImage
              ? classesTicketPurchaseButtonDesign.imgButton
              : classesTicketPurchaseButtonDesign.button
          }
          onClick={() => {
            return user.uid || isTicketPurchaseOnExternal
              ? props.noticeNotOnSale(
                  dispInfo.eventSaleStatus,
                  dispInfo.ticketUrl
                )
              : dispatch(modalActions.toggleLoginMethod());
          }}
        >
          {props.event.isLotteryTicketSellingMode ? (
            btnImage ? (
              <img src={btnImage} alt="" />
            ) : (
              <p>{t("event.buttons.lotteryPurchase")}</p>
            )
          ) : btnImage ? (
            <img src={btnImage} alt="" />
          ) : (
            <p>{t("event.buttons.purchase")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

type TicketPurchaseButtonDesignProps = {
  isTicketPurchase: boolean;
};
const ticketPurchaseButtonDesign = (props: TicketPurchaseButtonDesignProps) => {
  const hostingBaseStyleMap = changeStyleWithHosting().baseStyles;
  const backgroundColor = props.isTicketPurchase
    ? hostingBaseStyleMap.accentColor
    : "#d4d4d4";

  const root = css`
    height: 85px;
    background-color: #141718;
    @media screen and (min-width: 768px) {
      height: 100px;
    }
  `;
  const layout = css`
    width: 100%;
    background-color: rgba(95, 102, 103, 0.9);
    &#fixed {
      position: fixed;
      bottom: 0;
      z-index: 20;
    }
  `;
  const imgButton = css`
    width: 240px;
    margin: 0 auto;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      width: 320px;
    }
    img {
      width: 100%;
    }
    &.notSales {
      img {
        opacity: 0.5;
      }
    }
  `;
  const button = css`
    padding: 15px 0;
    @media screen and (min-width: 768px) {
      padding: 25px 0;
    }
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      padding: 7px 0;
      margin: 0 auto;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      letter-spacing: 1px;
      background-color: ${backgroundColor};
      cursor: pointer;
      @media screen and (min-width: 768px) {
        width: 560px;
        padding: 10px 0;
      }
      &::before {
        content: "";
        display: block;
        width: 30px;
        height: 30px;
        margin-right: 20px;
        background-image: url(${icon_ticket});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
      a {
        color: #fff;
      }
    }
  `;

  return {
    root,
    layout,
    imgButton,
    button,
  };
};
