/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

import { LeftBorderHeadline } from "components/atoms/headline/LeftBorderHeadline";
import { useGetEventList } from "hooks/event/useGetEventList";
import { getEventDisplayInfo, getNowTimestamp } from "utility";
import { changeStyleWithHosting } from "utility/hosting";

interface Props {
  voiceEventIds: string[];
}
export const VoiceEventList: React.FC<Props> = (props) => {
  const { data: events } = useGetEventList({
    type: "byIds",
    ids: props.voiceEventIds,
  });

  const hostingBaseStyleSettings = changeStyleWithHosting().baseStyles;
  const classesContentsLayoutDesign = contentsLayoutDesign({
    hostingBaseStyleSettings,
  });
  const classesVoiceSalesDesign = voiceSalesDesign();

  const nowTimestamp = getNowTimestamp();
  const displayEvents = (events ?? [])
    // 元の実装ではopenEventsから取得していたため、ここでisClosedなイベントを除外する
    .filter((event) => !event.isClosed && event.open.seconds < nowTimestamp)
    .map((event) => getEventDisplayInfo(event));
  if (displayEvents.length === 0) {
    return null;
  }

  return (
    <div id="place_ticket" css={classesContentsLayoutDesign.root}>
      <div css={classesContentsLayoutDesign.heading}>
        <LeftBorderHeadline
          title={"DIGITAL CONTENTS"}
          subTitle={"デジタルコンテンツ"}
          fontColor={"primary"}
        />
      </div>

      <div css={classesVoiceSalesDesign.voiceSalesLayout}>
        {displayEvents.map((event) => (
          <div css={classesVoiceSalesDesign.voiceItem} key={event._id}>
            <a href={`/events/${event._id}`}>
              <img src={event.thumbnail} alt={event.eventTitle} />
              {/*
               * FIXME adminツールではartistsは空文字になっているが、過去pyCMSではartistsを採用していたため、
               * 過去ボイスイベントにはartistsはあるが、eventTitleは空文字にしている運用がある
               * 影響箇所が見えないので撮り急ぎの対応
               * background https://balus3d.slack.com/archives/C01F64055PA/p1665649404265859?thread_ts=1664933891.270499&cid=C01F64055PA
               */}
              <h3>{event.artists || event.eventTitle}</h3>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

type ContentsLayoutDesignProps = {
  // @ts-expect-error TS7008
  hostingBaseStyleSettings;
};
const contentsLayoutDesign = (_props?: ContentsLayoutDesignProps) => {
  // const { accentColor } = props.hostingBaseStyleSettings;
  // const headingTextColor = props.hostingBaseStyleSettings.fontColor;
  return {
    root: css`
      margin: 0 auto 60px;
      @media screen and (min-width: 768px) {
        margin-bottom: 100px;
      }
    `,
    heading: css`
      margin-bottom: 40px;
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        margin-bottom: 60px;
      }
    `,
  };
};

type VoiceSalesDesignProps = {};
const voiceSalesDesign = (_props?: VoiceSalesDesignProps) => {
  return {
    voiceSalesLayout: css`
      display: flex;
      flex-wrap: wrap;
    `,
    voiceItem: css`
      width: calc(100% - 20px);
      margin: 0 10px 20px;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        width: calc(33.333% - 20px);
      }
      img {
        width: 100%;
      }
      h3 {
        padding: 15px;
        font-size: 16px;
        line-height: 1.3em;
      }
      p {
        padding: 0 15px 20px;
        font-size: 14px;
        line-height: 1.3em;
      }
    `,
  };
};
