/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useCallback, useEffect, useState } from "react";
import { fetchFirestoreCollectionMap } from "utility/firebase";
import {
  convertMapToValues,
  getSlashSeparatedDateTimeStringFromFbTimestamp,
} from "utility";
import { useI18n } from "hooks/i18n/i18n";
import type { TopInfoArticle } from "@spwn/types/firebase/firestore";
import { PortalTopSectionLayout } from "./PortalTop";

export const PortalTopInformationFetcher: React.FC = () => {
  const [infoMap, setInfoMap] = useState<{
    [key: string]: TopInfoArticle;
  }>({});

  useEffect(() => {
    fetchFirestoreZendesk();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchFirestoreZendesk = useCallback(async () => {
    const zendeskInfoMap = await fetchFirestoreCollectionMap<TopInfoArticle>(
      `/news/zendesk/info`
    );
    setInfoMap(zendeskInfoMap);
  }, []);

  return (
    <PortalTopInformation
      displayInfoList={convertMapToValues(infoMap).filter((el) => el.display)}
    />
  );
};

export const PortalTopInformation: React.FC<{
  displayInfoList: TopInfoArticle[];
}> = ({ displayInfoList }) => {
  const { t } = useI18n();
  const classesInfomationDesign = informationDesign();

  if (displayInfoList.length === 0) {
    return (
      <p css={classesInfomationDesign.noInfo}>{t("event.top.noInformation")}</p>
    );
  }

  return (
    <PortalTopSectionLayout
      heading={{
        mainText: "INFORMATION",
        subText: t("event.top.information"),
      }}
    >
      <ul>
        {displayInfoList.map((info, i) => (
          <li key={i} css={classesInfomationDesign.layout}>
            <p css={classesInfomationDesign.releaseDate}>
              {getSlashSeparatedDateTimeStringFromFbTimestamp(info.createdAt)}
            </p>

            <p css={classesInfomationDesign.releaseText}>
              <a href={info.URL} target="_blank" rel="noopener noreferrer">
                {info.title}
              </a>
            </p>
          </li>
        ))}
      </ul>
    </PortalTopSectionLayout>
  );
};

const informationDesign = () => {
  return {
    layout: css`
      &:not(:last-child) {
        margin-bottom: 20px;
        @media screen and (min-width: 768px) {
          margin-bottom: 24px;
        }
      }
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }
    `,
    releaseDate: css`
      margin-bottom: 8px;
      color: #fff;
      font-size: 16px;
      @media screen and (min-width: 768px) {
        margin-right: 64px;
        margin-bottom: 0px;
      }
    `,
    releaseText: css`
      font-size: 16px;
      line-height: 1.8em;
      a {
        color: #fff;
        text-decoration: underline;
      }
    `,
    noInfo: css`
      margin: 40px auto;
      color: #fff;
      text-align: center;
    `,
  };
};
