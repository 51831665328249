import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import React from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles, { StyleRules } from "@material-ui/core/styles/withStyles";
import { useI18n } from "hooks/i18n/i18n";
import { Button } from "components/atoms/button/Button";

const styles: StyleRules = createStyles({
  buttonRoot: {
    width: "80vw",
    margin: "10px",
    color: "#fff",
    backgroundColor: "#000",
  },
});

interface Props {
  // classes: any; 使用していない&anyなのでコメントアウト
  isSignup: boolean;
  // @ts-expect-error TS7006
  onClick: (provider) => void;
}

const FacebookLoginButton = (props: Props) => {
  const { isSignup } = props;
  const provider = new firebase.auth.FacebookAuthProvider();
  const { t } = useI18n();
  const actionText = isSignup
    ? t("login.buttons.registration")
    : t("login.buttons.login");

  const onClick = () => {
    props.onClick(provider);
  };

  return (
    <Button
      text={`${t("login.loginMethod.facebook")}${actionText}`}
      color={"#3c5a99"}
      onClick={onClick}
    />
  );
};

export default withStyles(styles)(FacebookLoginButton);
