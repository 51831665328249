/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { PublicPlanContentStreamingDTO } from "api/schema/spwn/dto/publicPlanContent";
import appConfig from "constants/appConfig";
import React, { FC } from "react";
import { LiveIcon } from "ui/LiveIcon";
import { createUrl } from "utility/createUrl";

export const StreamingStandbyOverlay: FC<{
  thumbnail: string;
  videoTitle: string;
  streamingStatus: PublicPlanContentStreamingDTO["details"]["streamingStatus"];
  startAt: string;
  brandSlug: string;
}> = (props) => {
  const linkToTopPage = createUrl({
    location: `https://${appConfig.crewlDomain}/${props.brandSlug}`,
    searchParams: [],
  });

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
      `}
    >
      <img
        src={props.thumbnail}
        alt={props.videoTitle}
        css={css`
          aspect-ratio: 16 / 9;
          width: 100%;
          object-fit: cover;
        `}
      />

      <div
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.8);
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
            color: white;
            font-weight: bold;
            text-align: center;
            letter-spacing: 1px;
          `}
        >
          {props.streamingStatus === "beforeLive" && (
            <React.Fragment>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 12px;
                  font-size: 18px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    width: 24px;
                    margin-right: 8px;
                    img {
                      width: 100%;
                      height: auto;
                    }
                  `}
                >
                  <LiveIcon />
                </div>
                <p>配信開始前です</p>
              </div>
              {/*
               * TODO: 一旦非表示、複数公演に対応する表示にさせたい
               * https://balus3d.slack.com/archives/C06RNRR38NS/p1720233266307089
               */}
              {/* <p
                css={css`
                  font-size: 12px;
                `}
              >
                {props.startAt}配信予定
              </p> */}
            </React.Fragment>
          )}
          {props.streamingStatus === "beforeVOD" && (
            <React.Fragment>
              <p
                css={css`
                  margin-bottom: 12px;
                  font-size: 18px;
                `}
              >
                ご覧いただきありがとうございました
              </p>
              <p
                css={css`
                  font-size: 12px;
                `}
              >
                アーカイブ配信の準備が整うまで、しばらくお待ちください
              </p>
              <div
                css={css`
                  width: 128px;
                  margin: 20px auto 0;
                `}
              >
                <a
                  href={linkToTopPage}
                  rel="noopener noreferrer"
                  css={css`
                    display: inline-block;
                    padding: 12px 16px;
                    color: white;
                    font-size: 12px;
                    font-weight: bold;
                    background-color: #00c2ad;
                    border-radius: 4px;
                  `}
                >
                  トップに戻る
                </a>
              </div>
            </React.Fragment>
          )}
          {props.streamingStatus === "ended" && (
            <React.Fragment>
              <p
                css={css`
                  margin-bottom: 12px;
                  font-size: 18px;
                `}
              >
                配信は終了しました
              </p>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
