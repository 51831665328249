/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { getDateString } from "utility";
import { createListSetOfField } from "utility/index";

import { SPView, PCView } from "components/common/MediaQuery";
import { AccountReceiptForSmartphone } from "components/account/receipt/AccountReceiptForSmartphone";

import logo from "designs/images/receipt/logo.jpg";
import image from "designs/images/receipt/image.jpg";
import { calcTaxDetails } from "./calcTaxDetails";
import { SHIPPING_FEE_LABEL } from "constants/fulfillment";

export interface ReceiptData {
  effectiveDate: Date;
  spwnId: string;
  userName: string;
  orderId: number;
  shippingFee: number;
  serviceFee: number;
  settlementFee: number;
  total: number;
  breakdown: ReceiptBreakdownItem[];
}

export type ReceiptBreakdownItem = {
  eventTitle: string | null;
  productType: "ticket" | "goods";
  name: string;
  price: number;
  quantity: number;
};

interface Props {
  receiptData: ReceiptData;
  resetState: () => void;
}
export const AccountReceipt: React.FC<Props> = (props) => {
  const [address, setAddress] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");

  const {
    spwnId,
    effectiveDate,
    userName,
    orderId,
    shippingFee,
    serviceFee,
    settlementFee,
    total,
    breakdown,
  } = props.receiptData;
  const effectiveDateString = getDateString(effectiveDate);

  // @ts-expect-error TS7034
  const productData = [];
  createListSetOfField<ReceiptBreakdownItem>(breakdown, "productType").forEach(
    (el) => {
      if (el.productType === "ticket") productData.push("チケット");
      if (el.productType === "goods") productData.push("グッズ");
    }
  );
  // @ts-expect-error TS7005
  const proviso = productData.length > 0 ? productData.join("・") : "商品";

  const classesRenderingReceiptStyles = renderingReceiptStyles();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    // @ts-expect-error TS2322
    content: () => componentRef.current,
  });

  const { targetTotal, excludedTaxAmount } = calcTaxDetails(total);

  return (
    <React.Fragment>
      <PCView>
        <div css={classesRenderingReceiptStyles.root}>
          {/*
           // @ts-expect-error TS2769 */}
          <Receipt
            ref={componentRef}
            spwnId={spwnId}
            userName={userName}
            address={address}
            title={title}
            orderId={orderId}
            effectiveDateString={effectiveDateString}
            proviso={proviso}
            total={total}
            serviceFee={serviceFee}
            shippingFee={shippingFee}
            settlementFee={settlementFee}
            breakdown={breakdown}
            classesRenderingReceiptStyles={classesRenderingReceiptStyles}
            targetTotal={targetTotal}
            excludedTaxAmount={excludedTaxAmount}
          />

          <div css={classesRenderingReceiptStyles.proviso}>
            <h2>宛名を入力してください。</h2>
            <div css={classesRenderingReceiptStyles.input}>
              <input
                type="text"
                placeholder={`宛名`}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
              <select
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              >
                <option value="">----</option>
                <option value="様">様</option>
                <option value="御中">御中</option>
              </select>
            </div>
          </div>

          <div css={classesRenderingReceiptStyles.buttonList}>
            <button
              // @ts-expect-error TS2322
              className={address.length === 0 && "disabled"}
              onClick={handlePrint}
              disabled={address.length === 0}
            >
              ダウンロード
            </button>
            <button onClick={() => props.resetState()}>閉じる</button>
          </div>
        </div>
      </PCView>

      <SPView>
        <AccountReceiptForSmartphone
          effectiveDateString={effectiveDateString}
          spwnId={spwnId}
          orderId={orderId}
          proviso={proviso}
          total={total}
          breakdown={breakdown}
          serviceFee={serviceFee}
          settlementFee={settlementFee}
          shippingFee={shippingFee}
          resetState={props.resetState}
        />
      </SPView>
    </React.Fragment>
  );
};

interface ReceiptProps {
  // @ts-expect-error TS7008
  ref;
  spwnId: string;
  userName: string;
  address: string;
  title: string;
  orderId: number;
  effectiveDateString: string;
  proviso: string;
  total: number;
  serviceFee: number;
  shippingFee: number;
  settlementFee: number;
  breakdown: ReceiptBreakdownItem[];
  targetTotal: number;
  excludedTaxAmount: number;
  // @ts-expect-error TS7008
  classesRenderingReceiptStyles;
}
class Receipt extends React.Component<ReceiptProps> {
  render() {
    return (
      <section
        id="receipt"
        css={this.props.classesRenderingReceiptStyles.receipt}
      >
        <h2>領収書</h2>

        <div css={this.props.classesRenderingReceiptStyles.headline}>
          <p>
            {this.props.address.length === 0
              ? "ここに宛名が表示されます"
              : this.props.address}
            &nbsp;{this.props.title.length === 0 ? null : this.props.title}
          </p>
          <ul>
            <li>
              <span>購入日：</span>
              {this.props.effectiveDateString}
            </li>
            <li>
              <span>SPWN ID：</span>
              {this.props.spwnId}
            </li>
            <li>
              <span>オーダーID：</span>
              {this.props.orderId}
            </li>
          </ul>
        </div>

        <div css={this.props.classesRenderingReceiptStyles.price}>
          <div css={this.props.classesRenderingReceiptStyles.total}>
            <p>合計金額（税込）</p>
            <p>{this.props.total.toLocaleString()}円</p>
          </div>
          <p css={this.props.classesRenderingReceiptStyles.text}>
            但、{this.props.proviso}購入代として
            <br />
            上記正に領収しました。
          </p>
        </div>

        <div css={this.props.classesRenderingReceiptStyles.footer}>
          <div css={this.props.classesRenderingReceiptStyles.classification}>
            <div css={this.props.classesRenderingReceiptStyles.subtotal}>
              <p>小計</p>
              <p>{this.props.total.toLocaleString()}円</p>
            </div>

            <p>(内訳)</p>

            <ul css={this.props.classesRenderingReceiptStyles.subtotalList}>
              {this.props.breakdown.map((item, index) => {
                return (
                  <li key={index}>
                    <p>
                      {item.eventTitle ? (
                        <React.Fragment>
                          {item.eventTitle}
                          <span
                            css={css`
                              margin-left: 0.5em;
                              font-size: 0.8em;
                            `}
                          >
                            ({item.name})
                          </span>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>{item.name}</React.Fragment>
                      )}
                      {`×${item.quantity}`}
                    </p>
                    <p>{(item.price * item.quantity).toLocaleString()}円</p>
                  </li>
                );
              })}

              {this.props.serviceFee > 0 && (
                <li>
                  <p>サービス手数料</p>
                  <p>{this.props.serviceFee.toLocaleString()}円</p>
                </li>
              )}

              {this.props.settlementFee > 0 && (
                <li>
                  <p>決済手数料</p>
                  <p>{this.props.settlementFee.toLocaleString()}円</p>
                </li>
              )}

              {this.props.shippingFee > 0 && (
                <li>
                  <p>{SHIPPING_FEE_LABEL}</p>
                  <p>{this.props.shippingFee.toLocaleString()}円</p>
                </li>
              )}

              <div
                css={css`
                  padding-top: 24px;
                  margin-top: 24px;
                  border-top: 1px solid #000;
                `}
              >
                <li>
                  <p>10%対象計</p>
                  <p>{this.props.targetTotal.toLocaleString()}円</p>
                </li>

                <li>
                  <p>消費税額</p>
                  <p>{this.props.excludedTaxAmount.toLocaleString()}円</p>
                </li>
              </div>
            </ul>
          </div>

          <div css={this.props.classesRenderingReceiptStyles.company}>
            <div>
              <img src={logo} alt="バルス株式会社" className="logo" />
              <div css={this.props.classesRenderingReceiptStyles.address}>
                <p>バルス株式会社</p>
                <p>登録番号：T2010001187346</p>
              </div>
            </div>
            <div css={this.props.classesRenderingReceiptStyles.companyLayout}>
              <img src={image} alt="バルス株式会社" className="sign" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const renderingReceiptStyles = () => {
  return {
    root: css`
      width: 1080px;
      padding-bottom: 40px;
      margin: 0 auto;
      letter-spacing: 1px;
      background-color: #fff;
    `,
    receipt: css`
      width: 960px;
      padding: 56px 40px;
      margin: 0 auto 40px;
      h2 {
        margin-bottom: 40px;
        font-size: 24px;
        font-weight: normal;
        text-align: center;
      }
    `,
    headline: css`
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      ul {
        li {
          span {
            font-weight: bold;
          }
        }
      }
    `,
    price: css`
      width: 60%;
      margin: 0 auto 56px;
    `,
    total: css`
      display: flex;
      justify-content: space-between;
      padding: 24px 16px;
      margin-bottom: 24px;
      border: 1px solid #000;
      p {
        font-size: 24px;
        line-height: 1.3em;
      }
    `,
    text: css`
      font-size: 18px;
      text-align: center;
      line-height: 1.3em;
    `,
    footer: css`
      display: flex;
      justify-content: space-between;
    `,
    classification: css`
      width: 45%;
      padding: 24px 0 16px;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
    `,
    subtotal: css`
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      p {
        font-size: 18px;
      }
    `,
    subtotalList: css`
      margin-top: 8px;
      li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        p {
          font-size: 12px;
          line-height: 1.5em;
          &:first-of-type {
            width: calc(100% - 84px);
          }
          &:last-child {
            width: 60px;
            margin-left: 16px;
            text-align: right;
          }
        }
      }
    `,
    company: css`
      width: 45%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      /* margin: auto 0; */
      .logo {
        display: block;
        width: 160px;
        margin-bottom: 24px;
      }
    `,
    companyLayout: css`
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .sign {
        width: 100px;
      }
    `,
    address: css`
      p {
        line-height: 1.5em;
      }
    `,
    proviso: css`
      width: 960px;
      padding: 0px 40px;
      margin: 0 auto 64px;
      h2 {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: normal;
        padding: 8px 0px 8px 16px;
        border-left: 5px solid rgb(0, 194, 174);
      }
    `,
    input: css`
      display: flex;
      input[type="text"] {
        display: block;
        width: 320px;
        padding: 8px 10px;
        margin-right: 8px;
        font-size: 16px;
        border: 2px solid #dedede;
      }
      select {
        width: 88px;
        padding: 8px 39px 8px 10px;
        font-size: 16px;
        font-weight: normal;
        line-height: 1;
        border: 2px solid #dedede;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAAByCAYAAADwDJeMAAAAAXNSR0IArs4c6QAAAc5JREFUeAHt17FOwmAARtFqnHkbeP8ZNl5I0wQSBsJFKX9rcrpAuEjp4XPox/l8/p4ux263uz71eBH4JPFYANBjnwkQoBCIbEGAQiCyBQEKgcgWBCgEIlsQoBCIbEGAQiCyBQEKgcgWBCgEIlsQoBCIbEGAQiCyBQEKgcgWBCgEIlsQoBCIbEGAQiCyBQEKgcgWBCgEIlsQoBCIbEGAQiCyBQEKgcgWBCgEIlsQoBCIbEGAQiCyBQEKgcgWBCgEIn9Ffzkfj8fpdDq9/Dn3PmC/30+Hw+FeWuy1t/+LzRcwX8jSxwic+Tu/HWg+ydJIo3CGAS2JNBJnKNASSKNxhgO9grQGzipAf0FaC2c1oN8grYmzKtAzSGvjrA70CGkLOJsAuoe0FZz5u739VmM+yTPH7S3D7fNn/vad79kM0HyRW4K5og+51bie7D8+AopfDRCgEIhsQYBCILIFAQqByBYEKAQiWxCgEIhsQYBCILIFAQqByBYEKAQiWxCgEIhsQYBCILIFAQqByBYEKAQiWxCgEIhsQYBCILIFAQqByBYEKAQiWxCgEIhsQYBCILIFAQqByBYEKAQiWxCgEIhsQYBCILIFAQqByBYEKAQi/wA6iyWDIRPy1AAAAABJRU5ErkJggg==")
          right 50% no-repeat;
        background-size: 29px, 100%;
        border-radius: 0;
        appearance: none;
      }
    `,
    buttonList: css`
      width: 500px;
      margin: 0 auto;
      button {
        display: block;
        width: 100%;
        padding: 8px 0;
        font-size: 14px;
        font-weight: bold;
        background-color: #fff;
        border: 2px solid #00c2ae;
        cursor: pointer;
        outline: none;
        appearance: none;
        &:first-of-type {
          margin-bottom: 16px;
          color: #fff;
          background-color: #00c2ae;
          &.disabled {
            background-color: #dedede;
            border: 2px solid #dedede;
            cursor: not-allowed;
          }
        }
        &:last-child {
          color: #00c2ae;
        }
      }
    `,
  };
};
