import { css } from "@emotion/core";

export const myPagePawHistoryMixin = css`
  .paw_caution_text {
    padding: 10px 0;
    font-size: 13px;
    text-align: center;
    border-bottom: 1px solid #e6e5e6;
    background-color: #fafafa;
    @media screen and (min-width: 768px) {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
  .paw_history_layout {
    padding-bottom: 40px;
  }
  .paw_history,
  .paw_history_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 980px;
      max-width: 90%;
    }
    p {
      color: #fff;
      text-align: center;
      &.purchase {
        width: 30%;
      }
      &.paw {
        width: 36%;
      }
      &.expiration {
        width: 30%;
      }
    }
    &.paw_history {
      p {
        font-size: 10px;
        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }
    }
    &.paw_history_content {
      p.expiration {
        font-size: 10px;
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
      }
    }
  }
  .paw_history_heading {
    padding: 5px 0;
    background-color: #8c8c8c;
    @media screen and (min-width: 768px) {
      padding: 10px 0;
    }
  }
  .paw_history_list {
    padding: 10px 0;
    border-bottom: 1px solid #e6e5e6;
    p {
      color: #000;
    }
    .purchase {
      font-size: 14px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        font-size: 20px;
        font-weight: normal;
      }
    }
    .expiration {
      font-weight: bold;
      @media screen and (min-width: 768px) {
        font-size: 20px;
        font-weight: normal;
      }
    }
    &.expired {
      background-color: #f2f2f2;
      p {
        color: #a3a4a5;
      }
    }
  }
  .additional_display {
    padding: 10px 0;
    background-color: #8c8c8c;
    cursor: pointer;
    .read-more {
      width: 90%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 980px;
        max-width: 90%;
      }
      p {
        color: #fff;
        font-size: 14px;
        text-align: right;
      }
    }
  }
`;
