import React from "react";
import { Link } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import { TFunction } from "i18next";
import styled from "@emotion/styled";

interface Props extends WithTranslation {
  t: TFunction;
  toggleModal: () => void;
}

const Container = styled.div`
  position: relative;
  padding: 60px 0;
  @media screen and (min-width: 768px) {
    padding: 80px 0 60px;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
  }
  .settlementCompleteDisplayLayout {
    max-width: 560px;
    width: 90%;
    margin: 0 auto;
    h2 {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
        font-size: 20px;
      }
    }
    .settlementCompleteText {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 1.5em;
      @media screen and (min-width: 768px) {
        margin-bottom: 60px;
        font-size: 18px;
      }
    }
    .buttonForLink {
      display: block;
      max-width: 560px;
      width: 100%;
      padding: 12px 0;
      margin: 0 auto 20px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      line-height: 1.2em;
      letter-spacing: 1px;
      background-color: #00c2ae;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        padding: 15px 0;
        margin: 0 auto 30px;
        letter-spacing: 2px;
      }
    }
    .backToList {
      font-size: 14px;
      text-align: center;
      a {
        color: #7c7c7c;
        text-decoration: underline;
      }
    }
  }
`;

const SettlementComplete = (props: Props) => {
  const { t } = props;
  return (
    <Container>
      <p className="close" onClick={props.toggleModal}>
        ×
      </p>
      <div className="settlementCompleteDisplayLayout">
        <h2>{t("modal.settlementComplete.goods")}</h2>
        <p className="settlementCompleteText">
          {t("modal.settlementComplete.backMsg")}
        </p>
        <p className="buttonForLink" onClick={props.toggleModal}>
          {t("modal.settlementComplete.close")}
        </p>
        <p className="backToList">
          <Link to="/" onClick={props.toggleModal}>
            {t("modal.settlementComplete.back")}
          </Link>
        </p>
      </div>
    </Container>
  );
};
const TransSettlementComplete = withTranslation()(SettlementComplete);
export default TransSettlementComplete;
