export const readFromSessionStorage = <T extends Object>(key: string): T => {
  // @ts-expect-error TS2322
  const value: string = sessionStorage.getItem(key);
  // TODO(takahashi): 現状は型の意味がないので何とかする
  return JSON.parse(value) as T;
};

export const writeToSessionStorage = <T extends Object>(
  key: string,
  value: T
): void => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

// USAGE(takahashi): /utility/index.tsx/isAllowedReferrer
export const withSessionStorage = async <T extends Object>(
  f: () => Promise<T>,
  key: string
): Promise<T> => {
  const cache = readFromSessionStorage<T>(key);
  if (cache) {
    return cache;
  }
  const result = await f();
  writeToSessionStorage<T>(key, result);
  return result;
};
