import {
  DeleteItemFromCartRequest,
  DeleteItemFromCartResponse,
} from "@spwn/types/functions";
import firebase from "firebase/app";
import { modalActions } from "modules/modal";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { hasHttpsError } from "utility/functionsError";

export const useDeleteItemFromCart = (): {
  deleteItem: (cartItemId: string) => Promise<void>;
} => {
  const dispatch = useDispatch();

  const deleteItem = useCallback(
    async (cartItemId: string) => {
      try {
        await callDeleteItemFromCart({ cartItemId });
      } catch (error: unknown) {
        if (hasHttpsError(error)) {
          dispatch(modalActions.toggleError({ msg: error.message }));
          return;
        }
        dispatch(modalActions.toggleError({ msg: error as string }));
      }
    },
    [firebase.functions] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    deleteItem,
  };
};

const callDeleteItemFromCart = async (
  request: DeleteItemFromCartRequest
): Promise<DeleteItemFromCartResponse> => {
  await firebase.functions().httpsCallable("deleteItemFromCart")(request);
};
