/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC, useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { CapsuleGoods } from "@spwn/types/firebase/firestore";
import { RoundLoader } from "../atoms/loader/RoundLoader";
import ChevronRight from "mdi-material-ui/ChevronRight";
import ChevronLeft from "mdi-material-ui/ChevronLeft";
import { Button } from "../../ui/Button";

const ANIMATION_TIME = 2.5;
const EFFECT_TIME = 2.6;

type CapsuleAnimation = {
  start: string;
  capsuleOpen: string;
  resultLoop: string;
};

type Props = {
  resultProducts: CapsuleGoods[];
  capsuleAnimationUrls: CapsuleAnimation;
  refreshImages: () => void;
  onClickClose: () => void;
};
export const CapsuleResultMultiple: React.FC<Props> = (props) => {
  const [isFinishedAnimation, setIsFinishedAnimation] =
    useState<boolean>(false);
  const [isOpenSummary, setIsOpenSummary] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFinishedAnimation(true);
    }, ANIMATION_TIME * 1000);
    return () => {
      // if error, this components is unmount. so clear setTimeout not to set state.
      clearTimeout(timer);
    };
  }, []);

  // 決済待ち
  const isLoading = props.resultProducts.length <= 0;

  if (!isFinishedAnimation || isLoading) {
    return (
      <CapsuleStart
        animation={props.capsuleAnimationUrls}
        isFinishedAnimation={isFinishedAnimation}
        isLoading={isLoading}
      />
    );
  } else if (!isOpenSummary) {
    const product = props.resultProducts[index];
    const next = props.resultProducts[index + 1];
    const isLastRoll = next === undefined;
    return (
      <CapsuleOpen
        key={index}
        animation={props.capsuleAnimationUrls}
        item={{
          // @ts-expect-error TS18048
          name: product.subClassName,
          // @ts-expect-error TS2322
          image: product.variantThumbnail,
        }}
        onClickNext={() => {
          if (isLastRoll) {
            setIsOpenSummary(true);
          } else {
            props.refreshImages();
            setIndex((prev) => prev + 1);
          }
        }}
        onClickSkip={
          isLastRoll
            ? undefined
            : () => {
                setIsOpenSummary(true);
              }
        }
        isLastRoll={isLastRoll}
      />
    );
  } else {
    return (
      <CapsuleSummary
        animation={props.capsuleAnimationUrls}
        // @ts-expect-error TS2322
        items={props.resultProducts.map((p) => ({
          name: p.subClassName,
          image: p.variantThumbnail,
        }))}
        onClickClose={props.onClickClose}
      />
    );
  }
};

/**
 * 回りはじめのアニメーション
 *
 * 購入処理が遅れている場合はloadingを表示する
 */
const CapsuleStart: FC<{
  animation: CapsuleAnimation;
  isLoading: boolean;
  isFinishedAnimation: boolean;
}> = ({ animation, isLoading, isFinishedAnimation }) => {
  const classes = styles();
  return (
    <div css={classes.root}>
      <div css={classes.layout}>
        <div css={classes.content}>
          <img
            key={"start"}
            src={animation.start}
            alt={""}
            className={"effect"}
          />
          {isFinishedAnimation && isLoading && (
            <div
              css={
                classes.loader
              } /*className={isFinishedAnimation && "display"}*/
            >
              <div className={"layout"}>
                <p className={"heading"}>ただいま決済中です。</p>
                <RoundLoader />
                <p className={"message"}>
                  決済が完了するまでしばらくお待ちください。
                  <br />
                  再読み込みや戻るボタンを押さないでください。
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * 回し終わり → 1回分の結果表示
 */
const CapsuleOpen: FC<{
  animation: CapsuleAnimation;
  item: {
    name: string;
    image: string;
  };
  isLastRoll?: boolean;
  onClickNext: () => void;
  onClickSkip?: () => void;
}> = ({ animation, item, isLastRoll, onClickNext, onClickSkip }) => {
  const classes = styles();

  const [isFinishedEffect, setIsFinishedEffect] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFinishedEffect(true);
    }, EFFECT_TIME * 1000);
    return () => {
      // if error, this components is unmount. so clear setTimeout not to set state.
      clearTimeout(timer);
    };
  }, []);

  return (
    <div css={classes.root}>
      <div css={classes.layout}>
        <div css={classes.content}>
          {isFinishedEffect ? (
            <img
              key={"loop"}
              src={animation.resultLoop}
              alt={""}
              className={"effect"}
            />
          ) : (
            <img
              key={"open"}
              src={animation.capsuleOpen}
              alt={""}
              className={"effect"}
            />
          )}
          {/*
           // @ts-expect-error TS2322 */}
          <div css={classes.result} className={isFinishedEffect && "display"}>
            <p css={classes.resultTitle}>{item.name}</p>
            <div className={"result_img"}>
              <img src={item.image} alt={item.name} />
            </div>
            <Button
              css={classes.nextButtonPc}
              size="full"
              onClick={onClickNext}
            >
              {isLastRoll ? "結果を見る" : "次へ"}
            </Button>
            {!isLastRoll && (
              <Button
                css={classes.skipButtonPc}
                size="full"
                variant="outline"
                onClick={onClickSkip}
              >
                スキップ
              </Button>
            )}
          </div>
          <div
            css={classes.resultActionAreaSp}
            // @ts-expect-error TS2322
            className={isFinishedEffect && "display"}
          >
            <div css={classes.buttonSpWrapper}>
              <Button
                css={classes.nextButtonSp}
                size="full"
                onClick={onClickNext}
              >
                {isLastRoll ? "結果を見る" : "次へ"}
              </Button>
              {!isLastRoll && (
                <Button
                  css={classes.skipButtonSp}
                  size="full"
                  variant="outline"
                  onClick={onClickSkip}
                >
                  スキップ
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * 最終結果表示
 */
export const CapsuleSummary: FC<{
  animation: CapsuleAnimation;
  items: { image: string; name: string }[];
  onClickClose: () => void;
}> = ({ animation, items, onClickClose }) => {
  const classes = styles();
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div css={classes.root}>
      <div css={classes.layout}>
        <div css={classes.content}>
          <img
            key={"loop"}
            src={animation.resultLoop}
            alt={""}
            className={"effect"}
          />
          <div css={classes.finalResult} className={"display"}>
            <div css={classes.finalResultHeader}>
              <p>今回のSPWNカプセル結果</p>
              <p>（決済履歴でも結果を確認できます）</p>
            </div>
            <div css={classes.finalResultItemListWrapper}>
              <button
                css={classes.iconButton}
                disabled={activeIndex === 0}
                onClick={() => {
                  setActiveIndex((prev) => prev - 1);
                }}
              >
                <ChevronLeft htmlColor="white" />
              </button>
              {items
                .reduce<{ image: string; name: string }[][]>(
                  (acc, item, index) => {
                    const chunkIndex = Math.floor(index / 10);
                    if (!acc[chunkIndex]) {
                      acc[chunkIndex] = [];
                    }
                    acc[chunkIndex]?.push(item);
                    return acc;
                  },
                  []
                )
                .map((chunk, i) => (
                  <div
                    key={i}
                    css={classes.finalResultItemList}
                    hidden={activeIndex !== i}
                  >
                    {chunk.map((p, j) => (
                      <div key={j} css={classes.finalResultItem}>
                        <img src={p.image} alt={p.name} />
                      </div>
                    ))}
                  </div>
                ))}
              <button
                css={classes.iconButton}
                disabled={items.length / 10 - 1 <= activeIndex}
                onClick={() => {
                  setActiveIndex((prev) => prev + 1);
                }}
              >
                <ChevronRight htmlColor="white" />
              </button>
            </div>
          </div>
          <div css={classes.actionArea}>
            <button css={classes.closeButton} onClick={onClickClose}>
              <CloseIcon />
              閉じる / もう一度回す
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      padding: 80px 0;
      background-color: rgba(0, 0, 0, 0.8);
      overflow-y: scroll;
      z-index: 1000;
    `,
    layout: css`
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 980px;
        transform: translate(-50%, -50%);
      }
    `,
    content: css`
      position: relative;
      width: 100%;
      margin-bottom: 24px;
      background-color: initial;
      .effect {
        width: 100%;
      }
    `,
    actionArea: css`
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    `,
    loader: css`
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.6);
      p {
        text-align: center;
      }
      .heading {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.8em;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
      .message {
        font-size: 12px;
        line-height: 1.8em;
      }
    `,
    result: css`
      position: absolute;
      top: 25%;
      left: 50%;
      width: 100%;
      padding: 10% 5% 0;
      transform: translate(-50%, calc(-50% - 8px));
      opacity: 0;
      visibility: hidden;
      @media screen and (min-width: 768px) {
        top: 50%;
        transform: translate(-50%, calc(-50% - 40px));
      }
      &.display {
        opacity: 1;
        visibility: visible;
      }
      p {
        margin-bottom: 8px;
        color: #282828;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.3em;
        text-align: center;
        @media screen and (min-width: 768px) {
          margin-bottom: 40px;
          font-size: 24px;
        }
      }
      .result_img {
        width: 130px;
        margin: 0 auto;
        border-radius: 50%;
        overflow: hidden;
        @media screen and (min-width: 768px) {
          width: 300px;
        }
      }
      img {
        width: 100%;
        height: auto;
        transform: scale(1.35);
      }
    `,
    resultActionAreaSp: css`
      opacity: 0;
      visibility: hidden;
      &.display {
        opacity: 1;
        visibility: visible;
      }
    `,
    finalResult: css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      padding: 8px 2px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 12px;
      @media screen and (min-width: 768px) {
        width: 980px;
        padding: 76px 24px;
        justify-content: flex-start;
        gap: 22px;
      }
    `,
    finalResultHeader: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;

      color: #282828;
      font-weight: 700;
      p:first-of-type {
        font-size: 16px;
        line-height: 1.5;
      }
      p:nth-of-type(2) {
        font-size: 8px;
        line-height: 1.5;
      }
      @media screen and (min-width: 768px) {
        p:first-of-type {
          font-size: 24px;
          line-height: 1.3;
        }
        p:nth-of-type(2) {
          font-size: 12px;
          line-height: 1.5;
        }
      }
    `,
    finalResultItemListWrapper: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
      margin: auto 0;
      @media screen and (min-width: 768px) {
        gap: 20px;
      }
    `,
    finalResultItemList: css`
      max-width: 296px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 4px;
      @media screen and (min-width: 768px) {
        max-width: 780px;
        gap: 20px;
      }
      &[hidden] {
        display: none;
      }
    `,
    finalResultItem: css`
      width: 56px;
      height: 56px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        transform: scale(1.35);
      }
      @media screen and (min-width: 768px) {
        width: 140px;
        height: 140px;
      }
    `,
    iconButton: css`
      width: 32px;
      height: 32px;
      padding: 0;
      background: rgba(0, 0, 0, 0.6);
      border: none;
      border-radius: 100%;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        width: 56px;
        height: 56px;
      }
      &:disabled {
        visibility: hidden;
      }
    `,
    nextButtonPc: css`
      display: none;
      max-width: 360px;
      min-height: 52px;
      margin: 20px auto 0;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        display: block;
      }
    `,
    nextButtonSp: css`
      max-width: 100%;
      min-height: 46px;
      margin: 0 auto;
      cursor: pointer;
    `,
    skipButtonPc: css`
      display: none;
      margin: 12px auto 0;
      max-width: 360px;
      min-height: 52px;
      border-width: 2px;
      border-radius: 0;
      background-color: #fff;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        display: block;
      }
    `,
    skipButtonSp: css`
      margin: 12px auto 0;
      max-width: 100%;
      min-height: 46px;
      border-width: 2px;
      border-radius: 0;
      background-color: #fff;
      cursor: pointer;
    `,
    buttonSpWrapper: css`
      display: block;
      margin-top: 20px;
      padding: 0 20px;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        display: none;
      }
    `,
    closeButton: css`
      appearance: none;
      padding: 0;
      background: initial;
      border: none;
      color: #fff;
      display: flex;
      margin: 24px auto 0;
      font-size: 16px;
      cursor: pointer;
      svg {
        margin-right: 8px;
      }
    `,
    resultTitle: css`
      color: #282828;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
    `,
  };
};
