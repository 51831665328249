/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useSelector } from "react-redux";
import { Store } from "store";
import { SETTLEMENT_FEE_DATA } from "constants/purchase";
import { useI18n } from "hooks/i18n/i18n";
import type { PayType } from "@spwn/types";

type StreamingLiveCommerceChagePaymentMethodProps = {
  paymentMethod: PayType;
  THEOPlayerElementHeight: number;
  closeChangePaymentMethod: () => void;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PayType>>;
};
const StreamingLiveCommerceChagePaymentMethod: React.FC<StreamingLiveCommerceChagePaymentMethodProps> =
  (props) => {
    const isDarkModeEnabled = useSelector(
      (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
    );
    const [selectedPaymentMethod, setSelectedPaymentMethod] =
      React.useState<PayType>(props.paymentMethod);
    const { t } = useI18n();

    const classesLiveCommerceChagePaymentMethod =
      liveCommerceChagePaymentMethod({
        isDarkModeEnabled,
        THEOPlayerElementHeight: props.THEOPlayerElementHeight,
      });

    const changePaymentMethod = () => {
      props.setPaymentMethod(selectedPaymentMethod);
      props.closeChangePaymentMethod();
    };

    return (
      <div css={classesLiveCommerceChagePaymentMethod.root}>
        <ul css={classesLiveCommerceChagePaymentMethod.list}>
          <li>
            <input
              type="radio"
              name="radio"
              className="radio-input"
              id="radio-01"
              onClick={() => {
                setSelectedPaymentMethod("Card");
              }}
              defaultChecked={props.paymentMethod === "Card"}
            />
            <label htmlFor="radio-01">
              {t("streaming.commerceChangePaymentMethod.credit")}
            </label>
          </li>
          <li>
            <input
              type="radio"
              name="radio"
              className="radio-input"
              id="radio-02"
              onClick={() => {
                setSelectedPaymentMethod("CVS");
              }}
              defaultChecked={props.paymentMethod === "CVS"}
            />
            <label htmlFor="radio-02">
              {t("streaming.commerceChangePaymentMethod.convenience", {
                CVSFee: SETTLEMENT_FEE_DATA["CVS"],
              })}
            </label>
          </li>
          <li>
            <input
              type="radio"
              name="radio"
              className="radio-input"
              id="radio-03"
              onClick={() => {
                setSelectedPaymentMethod("Phone");
              }}
              defaultChecked={props.paymentMethod === "Phone"}
            />
            <label htmlFor="radio-03">
              {t("streaming.commerceChangePaymentMethod.career", {
                careerFee: SETTLEMENT_FEE_DATA["Phone"],
              })}
            </label>
          </li>
        </ul>

        <div css={classesLiveCommerceChagePaymentMethod.button}>
          <button onClick={changePaymentMethod}>
            {t("streaming.commerceChangePaymentMethod.changePayment")}
          </button>
        </div>
      </div>
    );
  };
export default StreamingLiveCommerceChagePaymentMethod;

interface LiveCommerceChagePaymentMethodProps {
  isDarkModeEnabled: boolean;
  THEOPlayerElementHeight?: number;
}
const liveCommerceChagePaymentMethod = (
  props?: LiveCommerceChagePaymentMethodProps
) => {
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;

  const root = css``;
  const list = css`
    border-bottom: 1px solid #dedede;
    li {
      margin: 0 auto;
      ${textColorInDarkMode}
      font-size: 14px;
      &:not(:last-child) {
        border-bottom: 1px solid #dedede;
      }
    }
    .radio-input {
      display: none;
    }
    .radio-input + label {
      position: relative;
      display: block;
      width: 100%;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 20px;
      cursor: pointer;
    }
    .radio-input + label::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 15px;
      height: 15px;
      margin-top: -8px;
      background-color: #f4f4f4;
      border-radius: 50%;
    }
    .radio-input:checked + label::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 2px;
      width: 11px;
      height: 11px;
      margin-top: -6px;
      background: #00c2ae;
      border-radius: 50%;
    }
  `;
  const button = css`
    padding: 12px 0;
    button {
      width: 100%;
      padding: 12px 0;
      color: #fff;
      font-size: 14px;
      text-align: center;
      background-color: #00c2ae;
      border: none;
      outline: none;
      appearance: none;
      cursor: pointer;
      &.disabled {
        background-color: #b1b1b1;
      }
    }
  `;
  return {
    root,
    list,
    button,
  };
};
