import React, { Component } from "react";
import { connect } from "react-redux";
import { Store } from "../store";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Modal } from "@material-ui/core";

const cubeGridScaleDelay = keyframes`
  0%, 70%, 100% {
    transform: scale3D(1, 1, 1);
  } 35% {
      transform: scale3D(0, 0, 1);
    }
`;

const Container = styled.div`
  /* position: fixed; */
  /* top: 50%; */
  /* left: 50%; */
  /* margin-top: -48%; */
  /* margin-left: -45%; */
  width: 90%;
  margin: 40px auto 0;
  padding: 30px 5% 50px;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    width: 700px;
    padding: 50px 0 50px;
    /* margin-top: -179px; */
    /* margin-left: -350px; */
  }
  h2 {
    margin-bottom: 30px;
    font-size: 20px;
    text-align: center;
    @media screen and (min-width: 768px) {
      margin-bottom: 30px;
      font-size: 25px;
    }
  }
  p {
    text-align: center;
    line-height: 1.5em;
  }
  .sk-cube-grid {
    width: 60px;
    height: 60px;
    margin: 40px auto 80px;
    .sk-cube {
      width: 33%;
      height: 33%;
      background-color: #333;
      float: left;
      animation: ${cubeGridScaleDelay} 1.3s infinite ease-in-out;
    }
    .sk-cube1 {
      animation-delay: 0.2s;
    }
    .sk-cube2 {
      animation-delay: 0.3s;
    }
    .sk-cube3 {
      animation-delay: 0.4s;
    }
    .sk-cube4 {
      animation-delay: 0.1s;
    }
    .sk-cube5 {
      animation-delay: 0.2s;
    }
    .sk-cube6 {
      animation-delay: 0.3s;
    }
    .sk-cube7 {
      animation-delay: 0s;
    }
    .sk-cube8 {
      animation-delay: 0.1s;
    }
    .sk-cube9 {
      animation-delay: 0.2s;
    }
  }
`;

interface Props {
  isOpen: boolean;
  msg: string;
}

class Loading extends Component<Props> {
  render() {
    const { isOpen, msg } = this.props;

    return (
      <Modal open={isOpen}>
        <Container className="loading_layout">
          {" "}
          {/* WARN: Global 経由で style が注入されている箇所があるため、 className は消してはいけない */}
          {/* <Loader/> */}
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
          <h2>Loading...</h2>
          <p className="popup_message">{msg}</p>
        </Container>
      </Modal>
    );
  }
}

const mapStateToProps = (state: Store) => {
  return {
    isOpen: state.loading.isOpen,
    msg: state.loading.msg,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
