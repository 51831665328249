/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import React from "react";
import { ActionTabType } from "modules/streaming";
import { SPView, PCView } from "components/common/MediaQuery";
import { useI18n } from "hooks/i18n/i18n";
import type { Event, EventVideo } from "@spwn/types/firebase/firestore";

type Props = {
  isDarkModeEnabled: boolean;
  isHiddenStreamingComment: boolean;
  actionTabState: string;
  // @ts-expect-error TS2339
  commentTab: Event["streamingInfo"]["commentTab"];
  currentVideo: EventVideo | undefined;
  setActionTabState: (state: ActionTabType) => void;
};

/**
 *
 * @param props
 */
const StreamingCommentTab: React.FC<Props> = (props) => {
  const { t } = useI18n();
  // const isAdmin = useSelector((state: Store) => state.admin.isAdmin);

  const tabClasses = tabDesign({
    actionTabState: props.actionTabState,
    isDarkModeEnabled: props.isDarkModeEnabled,
  });

  const isLiveCommerceEnabled =
    props.commentTab?.isLiveCommerceEnabled &&
    (props.currentVideo?.isLiveCommerceEnabled === undefined ||
      props.currentVideo.isLiveCommerceEnabled);
  const isLiveEventEnabled =
    props.commentTab?.isLiveEventEnabled &&
    (props.currentVideo?.isLiveEventEnabled === undefined ||
      props.currentVideo.isLiveEventEnabled);

  return (
    <div css={tabClasses.tab}>
      <SPView>
        <div
          // @ts-expect-error TS2322
          className={
            !props.actionTabState || props.actionTabState === "eventDetail"
              ? "underline"
              : null
          }
          onClick={() => props.setActionTabState("eventDetail")}
        >
          {t("streaming.commentTab.summary")}
        </div>
        {props.isHiddenStreamingComment ? (
          <div className="disabled">{t("streaming.commentTab.comment")}</div>
        ) : (
          <div
            // @ts-expect-error TS2322
            className={props.actionTabState === "comment" ? "underline" : null}
            onClick={() => props.setActionTabState("comment")}
          >
            {t("streaming.commentTab.comment")}
          </div>
        )}
        {isLiveCommerceEnabled && (
          <div
            // @ts-expect-error TS2322
            className={props.actionTabState === "goods" ? "underline" : null}
            onClick={() => props.setActionTabState("goods")}
          >
            {t("streaming.commentTab.goods")}
          </div>
        )}
        {isLiveEventEnabled && (
          <div
            // @ts-expect-error TS2322
            className={props.actionTabState === "event" ? "underline" : null}
            onClick={() => props.setActionTabState("event")}
          >
            {t("streaming.commentTab.event")}
          </div>
        )}
      </SPView>
      <PCView>
        <div
          // @ts-expect-error TS2322
          className={
            !props.actionTabState || props.actionTabState === "comment"
              ? "underline"
              : null
          }
          onClick={() => props.setActionTabState("comment")}
        >
          {t("streaming.commentTab.comment")}
        </div>
        {isLiveCommerceEnabled && (
          <div
            // @ts-expect-error TS2322
            className={props.actionTabState === "goods" ? "underline" : null}
            onClick={() => props.setActionTabState("goods")}
          >
            {t("streaming.commentTab.goods")}
          </div>
        )}
        {isLiveEventEnabled && (
          <div
            // @ts-expect-error TS2322
            className={props.actionTabState === "event" ? "underline" : null}
            onClick={() => props.setActionTabState("event")}
          >
            {t("streaming.commentTab.event")}
          </div>
        )}
      </PCView>
    </div>
  );
};

type TabDesignProps = {
  actionTabState: string;
  isDarkModeEnabled: boolean;
};
const tabDesign = (props?: TabDesignProps) => {
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const borderColorDarkMode = props.isDarkModeEnabled
    ? css`
        border-bottom: inset 3px #ffffff;
      `
    : css`
        border-bottom: inset 3px #000000;
      `;
  return {
    tab: css`
      display: flex;
      border-bottom: 1px solid #dedede;
      @media screen and (min-width: 768px) {
        border-top: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
      }
      div {
        width: 100%;
        padding: 8px 0;
        ${textColorInDarkMode}
        font-size: 14px;
        text-align: center;
        letter-spacing: 1px;
        cursor: pointer;
      }
      .underline {
        ${borderColorDarkMode}
      }
      .disabled {
        color: #77797a;
      }
    `,
  };
};

export default StreamingCommentTab;
