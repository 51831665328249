import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";

const actionCreator = actionCreatorFactory("sample");

export const sampleActions = {
  updateNum: actionCreator<void>("updateNum"),
};

export interface sampleState {
  number: number;
}

const initialState: sampleState = {
  number: 0,
};

const sampleReducer = reducerWithInitialState(initialState).case(
  sampleActions.updateNum,
  (state) => {
    return { ...state, number: ++state.number };
  }
);

export default sampleReducer;
