/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  EventInfo,
  convertMapToValues,
  createSet,
  openWindow,
} from "../../utility";
import { Store } from "../../store";
import { canWatchVideo } from "../../utility/streaming";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { ticketActions } from "../../modules/ticket";
import { filterDisplayVideoTickets } from "utility/ticket";
import type { EventVideo } from "@spwn/types/firebase/firestore";

/**
 * 配信ページのタイトル下部に表示される、現在視聴中とは別にイベントに紐づいている配信を表示する
 */
export const EventVideoListContainer: React.FC<{
  selectVideoId: string;
  event: EventInfo;
  setWatchVideo: (vid: string) => void;
  currentVid: string;
}> = (props) => {
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);
  const eventTicketMap = useSelector(
    (state: Store) => state.ticket.eventTicketMap
  );
  const eventVideoMap = useSelector(
    (state: Store) => state.streaming.eventVideoMap
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      ticketActions.fetchEventTickets.started({ eventId: props.event.eventId })
    );
  }, [dispatch, props.event.eventId]);

  // 必要な情報がない場合はrenderしない
  if (eventTicketMap === null || eventVideoMap === null) {
    return null;
  }

  const filteredTickets = filterDisplayVideoTickets(eventTicketMap, isAdmin);
  const onSaleVideoIds: string[] = createSet(
    filteredTickets.flatMap((el) => el.videoIds)
  );
  const otherVideos = convertMapToValues(eventVideoMap).filter(
    (video) =>
      onSaleVideoIds.includes(video?._id ?? "") &&
      video._id !== props.currentVid
  );

  return (
    <EventVideoList
      selectVideoId={props.selectVideoId}
      eventVideoList={otherVideos}
      event={props.event}
      setWatchVideo={props.setWatchVideo}
    />
  );
};

interface EventVideoListProps {
  selectVideoId: string;
  event: EventInfo;
  eventVideoList: EventVideo[];
  setWatchVideo: (vid: string) => void;
}
/**
 * other videos in a event
 * @ref https://material-ui.com/components/grid-list/#single-line-grid-list
 * @param props
 */
const EventVideoList: React.FC<EventVideoListProps> = (props) => {
  const streamingKey = useSelector(
    (state: Store) => state.streaming.streamingKey
  );
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);
  const _pathname = useSelector(
    (state: Store) => state.router.location.pathname
  );

  if (!streamingKey) {
    // fail safe
    return null;
  }

  // TODO マーダーミステリー終了後削除する
  // 本公演、選択した俳優、感想戦のみを表示するようにする。
  // 登場人物を選択しない：Vi{eid}C1v1
  // 感想戦：Vi{eid}C8v1
  const sortedVideos = props.eventVideoList
    // .filter((value) => {
    //   if (pathname.indexOf("-murdermystery") !== -1) {
    //     return (
    //       //2月公演分
    //       value._id === "Vi21022014-murdermysteryC1v1" ||
    //       value._id === "Vi21022019-murdermysteryC1v1" ||
    //       value._id === "Vi21022114-murdermysteryC1v1" ||
    //       value._id === "Vi21022119-murdermysteryC1v1" ||
    //       value._id === "Vi21022214-murdermysteryC1v1" ||
    //       value._id === "Vi21022219-murdermysteryC1v1" ||
    //       value._id === "Vi21022014-murdermysteryC8v1" ||
    //       value._id === "Vi21022019-murdermysteryC8v1" ||
    //       value._id === "Vi21022114-murdermysteryC8v1" ||
    //       value._id === "Vi21022119-murdermysteryC8v1" ||
    //       value._id === "Vi21022214-murdermysteryC8v1" ||
    //       value._id === "Vi21022219-murdermysteryC8v1" ||
    //       //7月公演分
    //       value._id === "Vi21071714-murdermysteryC1v1" ||
    //       value._id === "Vi21071719-murdermysteryC1v1" ||
    //       value._id === "Vi21071814-murdermysteryC1v1" ||
    //       value._id === "Vi21071819-murdermysteryC1v1" ||
    //       value._id === "Vi21071914-murdermysteryC1v1" ||
    //       value._id === "Vi21071919-murdermysteryC1v1" ||
    //       value._id === "Vi21071714-murdermysteryC8v1" ||
    //       value._id === "Vi21071719-murdermysteryC8v1" ||
    //       value._id === "Vi21071814-murdermysteryC8v1" ||
    //       value._id === "Vi21071819-murdermysteryC8v1" ||
    //       value._id === "Vi21071914-murdermysteryC8v1" ||
    //       value._id === "Vi21071919-murdermysteryC8v1" ||
    //       //12月公演分
    //       value._id === "Vi21121415-murdermysteryC1v1" ||
    //       value._id === "Vi21121420-murdermysteryC1v1" ||
    //       value._id === "Vi21121514-murdermysteryC1v1" ||
    //       value._id === "Vi21121519-murdermysteryC1v1" ||
    //       value._id === "Vi21121614-murdermysteryC1v1" ||
    //       value._id === "Vi21121619-murdermysteryC1v1" ||
    //       value._id === "Vi21121415-murdermysteryC8v1" ||
    //       value._id === "Vi21121420-murdermysteryC8v1" ||
    //       value._id === "Vi21121514-murdermysteryC8v1" ||
    //       value._id === "Vi21121519-murdermysteryC8v1" ||
    //       value._id === "Vi21121614-murdermysteryC8v1" ||
    //       value._id === "Vi21121619-murdermysteryC8v1" ||
    //       value._id === props.selectVideoId
    //     );
    //   }
    //   return true;
    // })
    .sort((a, b) => a.priority - b.priority);
  const isOnSale = props.event.eventSaleStatus === "ON_SALE";

  const handleIconAction = (
    vid: string,
    canWatch: boolean,
    enableAction: boolean
  ) => {
    if (!enableAction) {
      return;
    }
    if (canWatch) {
      props.setWatchVideo(vid);
      window.scrollTo(0, 0);
      return;
    }
    // if event is on sale
    if (isOnSale) {
      if (props.event.ticketUrl) {
        // if ticket purchase is external site, open its site
        openWindow(props.event.ticketUrl);
        return;
      }
      // go ticket page
      openWindow(`/events/${props.event.eventId}/ticket`);
      return;
    }
    return;
  };

  const classesRelatedVideosStyle = relatedVideosStyle();

  return (
    <div css={classesRelatedVideosStyle.root}>
      {sortedVideos.map((eventVideo, index) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const vid = eventVideo._id!;

        const canWatch = canWatchVideo(streamingKey, vid);
        const videoImg = eventVideo.thumbnail || props.event.thumbnail;
        const isOpen = eventVideo.isOpen === undefined || eventVideo.isOpen;
        // handle action (watch live || buy ticket)
        const enableAnyAction =
          (isOpen && (canWatch || (!canWatch && isOnSale))) || isAdmin;
        if (!enableAnyAction) {
          return null;
        }

        return (
          <div css={classesRelatedVideosStyle.item} key={index}>
            <img src={videoImg} alt={eventVideo.name} />
            <div
              css={classesRelatedVideosStyle.info}
              onClick={() =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                handleIconAction(eventVideo._id!, canWatch, enableAnyAction)
              }
            >
              <div css={classesRelatedVideosStyle.inner}>
                <p css={classesRelatedVideosStyle.title}>{eventVideo.name}</p>
                <div css={classesRelatedVideosStyle.icon(enableAnyAction)}>
                  {canWatch ? (
                    <PlayCircleFilledIcon fontSize="large" />
                  ) : (
                    <ShoppingBasketIcon fontSize="large" />
                  )}
                  <p css={classesRelatedVideosStyle.state}>
                    {canWatch
                      ? isOpen
                        ? "視聴"
                        : "配信期間外"
                      : isOnSale
                      ? "購入"
                      : "販売終了"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const relatedVideosStyle = () => {
  return {
    root: css`
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      width: 100%;
      margin-top: 16px;
      overflow-x: auto;
    `,
    item: css`
      position: relative;
      flex-shrink: 0;
      width: 84%;
      margin-right: 16px;
      -webkit-overflow-scrolling: touch;
      @media screen and (min-width: 768px) {
        width: 26%;
        margin-right: 16px;
      }
      img {
        width: 100%;
      }
    `,
    info: css`
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      align-items: flex-end;
      /* stylelint-disable function-linear-gradient-no-nonstandard-direction */
      background: linear-gradient(
        top,
        rgba(0, 0, 0, 0) 30%,
        rgba(0, 0, 0, 0.75) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 30%,
        rgba(0, 0, 0, 0.75) 100%
      );
      /* stylelint-enable */
      filter: progid:dximagetransform.microsoft.gradient(
        startColorstr="#00000000",
        endColorstr="#bf000000",
        GradientType=0
      );
      cursor: pointer;
    `,
    inner: css`
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 16px 5%;
      @media screen and (min-width: 768px) {
        padding: 10px;
      }
    `,
    title: css`
      width: calc(100% - 72px);
      padding-top: 8px;
      color: #fff;
    `,
    icon: (enable: boolean) => css`
      max-width: 56px;
      margin-left: 16px;
      text-align: center;
      svg {
        fill: ${enable ? "#fff" : "rgba(255, 255, 255, 0.3)"};
      }
    `,
    state: css`
      color: #fff;
      font-size: 10px;
    `,
  };
};
