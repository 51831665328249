import styled from "@emotion/styled";
import { PawInfo } from "../PawInfo";

export const EventPawInfo = styled(PawInfo)`
  padding: 25px 0;
  background-color: #fafafa;
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  .layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 740px;
    }
    .balance {
      width: 48%;
      @media screen and (min-width: 768px) {
        width: 180px;
      }
      .heading {
        font-size: 14px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
          /* font-size: 20px; fixme: @yuta */
        }
      }
    }
    .button {
      width: 48%;
      margin: 0;
      @media screen and (min-width: 768px) {
        width: 350px;
      }
    }
  }
`;

EventPawInfo.displayName = "EventPawInfo";
