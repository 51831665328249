import styled from "@emotion/styled";

export const EditForm = styled.div`
  margin-bottom: 25px;
  p {
    margin-bottom: 10px;
    font-size: 14px;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #e6e5e6;
  }
  .formError {
    border: 1px solid #ff0000;
    background-color: rgba(#ff0000, 0.1);
  }
  .required_form {
    background: #ff0000;
    margin: 0 0 0 8px;
    padding: 2px 4px;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    vertical-align: top;
  }
  .required_form_text {
    padding-top: 4px;
    padding-left: 4px;
    color: #ff0000;
  }
`;

EditForm.displayName = "EditForm";
