/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useSelector } from "react-redux";
import { EventInfo } from "../../utility";
import { Store } from "../../store";
import { useConcurrentPlay } from "hooks/Streaming/ConcurrentPlay";
import { useI18n } from "hooks/i18n/i18n";
import { XShareButton } from "ui/XShareButton";
import { EventVideoListContainer } from "./StreamingEventDetailVideoList";

interface StreamingEventDetailProps {
  selectVideoId: string;
  event: EventInfo;
  currentVid: string; // current watching video id. if null, user has not purchased any video
  clickCount?: number;
  isDarkModeEnabled: boolean;
  // changeDrawerState?: (state: 'chat'|'gift') => void
  setClickCount?: (clickCount: number) => void;
  setWatchVideo: (vid: string) => void;
}
/**
 * イベント概要エリア
 * @param props
 */
export const StreamingEventDetail: React.FC<StreamingEventDetailProps> = (
  props
) => {
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );
  const isTheaterModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isTheaterModeEnabled
  );
  const eventVideoMap = useSelector(
    (state: Store) => state.streaming.eventVideoMap
  );
  const streamingType = useSelector(
    (state: Store) => state.streaming.streamingType
  );
  const { t } = useI18n();

  const isConcurrentPlayEnabled =
    props.event.streamingInfo?.optionSettings?.isConcurrentPlayEnabled;
  const concurrentPlay = useConcurrentPlay(
    props.currentVid,
    streamingType,
    isConcurrentPlayEnabled
  );

  if (props.event === null || !props.currentVid || eventVideoMap === null) {
    return <Loading />;
  }

  const currentVideo = eventVideoMap[props.currentVid];
  if (!currentVideo) {
    return <Loading />;
  }

  const videoTitle = currentVideo.name
    ? `${props.event.eventTitle}【${currentVideo.name}】`
    : props.event.eventTitle;

  const classesStreamingInfoLayout = streamingInfoLayout({
    isTheaterModeEnabled,
    isDarkModeEnabled,
  });

  return (
    <div css={classesStreamingInfoLayout.root}>
      <p css={classesStreamingInfoLayout.title}>{videoTitle}</p>
      {/* streamingTypeがliveの時、かつisOncurrentPlayEnabledがtrueの時表示する */}
      {((streamingType === "Live" && isConcurrentPlayEnabled) || isAdmin) && (
        <p css={classesStreamingInfoLayout.concurrentPlay}>
          {concurrentPlay.toLocaleString()} {t("streaming.detail.countPlay")}
        </p>
      )}
      <p css={classesStreamingInfoLayout.artist}>{props.event.artists}</p>

      <XShareButton
        eventId={props.event.eventId}
        eventTitle={props.event.eventTitle}
        hashTag={props.event.twitterHashTag}
      />

      <EventVideoListContainer
        selectVideoId={props.selectVideoId}
        event={props.event}
        setWatchVideo={props.setWatchVideo}
        currentVid={props.currentVid}
      />
    </div>
  );
};

const Loading: React.FC = () => {
  return (
    <div className="info">
      <p className="title"></p>
      <p className="artist"></p>
      <p className="description"></p>
    </div>
  );
};

interface StreamingInfoLayoutProps {
  isTheaterModeEnabled: boolean;
  isDarkModeEnabled: boolean;
}
const streamingInfoLayout = (props: StreamingInfoLayoutProps) => {
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;
  const borderColorInDarkMode = props.isDarkModeEnabled
    ? css`
        border-bottom: 1px solid #fff;
      `
    : css`
        border-bottom: 1px solid #e0e0e0;
      `;
  const theaterModeStyles = props.isTheaterModeEnabled
    ? css`
        grid-column-start: 1;
        grid-column-end: 3;
      `
    : css``;

  const root = css`
    ${theaterModeStyles};
    width: 90%;
    padding: 24px 0;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 100%;
      padding: 0;
    }
    p {
      line-height: 1.5em;
      letter-spacing: 1px;
    }
  `;

  const title = css`
    margin-bottom: 8px;
    ${textColorInDarkMode}
    font-size: 20px;
    font-weight: bold;
    @media screen and (min-width: 768px) {
      margin-bottom: 16px;
    }
  `;

  const concurrentPlay = css`
    margin-top: -8px;
    margin-bottom: 16px;
    ${textColorInDarkMode}
    font-size: 14px;
    @media screen and (min-width: 768px) {
      margin-top: -16px;
    }
  `;

  const artist = css`
    padding-bottom: 18px;
    margin-bottom: 21px;
    ${textColorInDarkMode}
    font-size: 14px;
    font-weight: bold;
    ${borderColorInDarkMode}
    @media screen and (min-width: 768px) {
      font-size: 15px;
    }
  `;

  const description = css`
    ${textColorInDarkMode}
    font-size: 14px;
    img {
      max-width: 100%;
    }
    a {
      color: #00c2ae;
    }
  `;

  return {
    root,
    title,
    concurrentPlay,
    artist,
    description,
  };
};
