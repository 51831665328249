/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Store } from "store";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import type { Event } from "@spwn/types/firebase/firestore";

import { PortalEventListSliderSkeleton } from "components/loading/SkeletonLoader";
import { useGetEventList } from "hooks/event/useGetEventList";
import { removeBr, getSlashSeparatedDateString } from "utility";

import spwn from "../designs/images/slider/1_spwn.jpg";
import crew from "../designs/images/slider/2_crew.jpg";
import capsule from "../designs/images/slider/3_capsule.jpg";

type FeatureEventListType = {
  title: string;
  image: string;
  eventId?: string;
  date?: string;
};

const SPWN_ADD_IMAGES: FeatureEventListType[] = [
  {
    title: "SPWN",
    image: spwn,
  },
  {
    title: "SPWN Fanclub",
    image: crew,
  },
  {
    title: "SPWN カプセル",
    image: capsule,
  },
];

export const PortalTopSliderFetcher: FC = () => {
  const featureEventIds = useSelector(
    (state: Store) => state.news.topInfo?.featureEventIds
  );

  const { data: featureEvents, isLoading } = useGetEventList({
    type: "byIds",
    ids: featureEventIds,
  });

  return (
    <PortalTopSliderLogic
      featureEvents={featureEvents}
      isFeatureEventsLoading={isLoading}
    />
  );
};

export const PortalTopSliderLogic: FC<{
  featureEvents?: Event[];
  isFeatureEventsLoading: boolean;
}> = ({ featureEvents, isFeatureEventsLoading }) => {
  const MINIMUM_NUMBER_OF_EVENTS_TO_DISPLAY = 3;
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  const featureEventList = (() => {
    if (!featureEvents) return [];
    /**
     * PortalTopのスライドに表示するイベントのリストを作成する。
     */
    const featureEventList: FeatureEventListType[] = featureEvents.flatMap(
      (event) => {
        if (!event) return [];
        return [
          {
            eventId: event._id,
            title: event.title,
            date: getSlashSeparatedDateString(
              new Date(event?.datetime?.seconds * 1000)
            ),
            image: event.defaultImg,
          },
        ];
      }
    );

    /**
     * もしスライダーに表示するイベント数が、MINIMUM_NUMBER_OF_EVENTS_TO_DISPLAYの値よりも少ない時、SPWN_ADD_IMAGESからバナー画像を表示する。
     */
    if (featureEventList.length < MINIMUM_NUMBER_OF_EVENTS_TO_DISPLAY) {
      for (const image of SPWN_ADD_IMAGES) {
        if (featureEventList.length >= MINIMUM_NUMBER_OF_EVENTS_TO_DISPLAY)
          break;

        featureEventList.push({
          title: image.title,
          image: image.image,
        });
      }
    }

    return featureEventList;
  })();

  /**
   * featureEventListのlengthが0のままSliderがレンダリングされて、currentSlideがズレることがないように、featureEventListを取得中はスケルトンローダーを表示する。
   * featureEventListに表示するイベントがない時、バナーが追加されるので、fetchの時だけここが表示される認識。
   */
  if (featureEventList.length === 0 || isFeatureEventsLoading) {
    return <PortalEventListSliderSkeleton isDesktop={isDesktop} />;
  }

  return (
    <PortalTopSlider
      isDesktop={isDesktop}
      featureEventList={featureEventList}
    />
  );
};

type PortalTopSliderProps = {
  isDesktop: boolean;
  featureEventList: FeatureEventListType[];
};
export const PortalTopSlider: React.FC<PortalTopSliderProps> = ({
  isDesktop,
  featureEventList,
}) => {
  const classesSliderStyles = sliderStyles();

  const settings = {
    className: "center",
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    infinite: true,
    centerPadding: isDesktop ? "20%" : "0%",
    slidesToShow: 1,
    speed: 500,
    prevArrow: (
      <button className="prevArrow">
        <ChevronLeftIcon color={"action"} fontSize={"large"} />
      </button>
    ),
    nextArrow: (
      <button className="nextArrow">
        <ChevronRightIcon color={"action"} fontSize={"large"} />
      </button>
    ),
  };

  return (
    <section css={classesSliderStyles.root}>
      <Slider {...settings}>
        {featureEventList.map((event, index) => {
          if (!event.eventId) {
            return (
              <div
                key={index}
                css={css`
                  overflow: hidden;
                `}
              >
                <img
                  src={event.image}
                  alt={event.title}
                  className={"slider_image"}
                />
                <h2>{removeBr(event.title)}</h2>
              </div>
            );
          }

          return (
            <div
              key={index}
              css={css`
                overflow: hidden;
              `}
            >
              <Link to={`/events/${event.eventId}`}>
                <img
                  src={event.image}
                  alt={event.title}
                  className={"slider_image"}
                />
              </Link>
              <h2>
                {event.date} {removeBr(event.title)}
              </h2>
            </div>
          );
        })}
      </Slider>
    </section>
  );
};

const sliderStyles = () => {
  return {
    root: css`
      .slick-slide {
        position: relative;
        .slider_image {
          width: 100%;
          aspect-ratio: 16 / 9;
          object-fit: cover;
        }
        h2 {
          display: box;
          height: 1em;
          padding: 0 16px;
          margin: 16px 0 0;
          color: #fff;
          font-size: 14px;
          text-align: center;
          /* stylelint-disable-next-line property-no-unknown */
          box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          -webkit-line-clamp: 1;
          @media screen and (min-width: 768px) {
            margin: 16px 0 16px;
            font-size: 16px;
          }
        }
        &:not(.slick-current) {
          h2 {
            display: none;
          }
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }
      }
      .slick-dots {
        position: relative;
        bottom: 0;
        li button::before {
          color: #dedede;
          font-size: 12px;
        }
        li.slick-active button::before {
          color: #2ee7d2;
        }
      }
      .slick-prev,
      .slick-next {
        width: auto;
        height: auto;
        margin-top: -35px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;
        @media screen and (min-width: 768px) {
          margin-top: calc(-17.5px - 48px);
        }
        &:before {
          display: none;
        }
        svg {
          fill: #fff;
        }
      }
      .slick-prev {
        left: 0;
      }
      .slick-next {
        right: 0;
      }
    `,
  };
};
