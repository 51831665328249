import appConfig from "../constants/appConfig";
import { fetchFbToken } from "../utility/firebase";

export const errorReport = async (error: Error) => {
  // 動作環境
  const { userAgent } = navigator;

  const fbToken = await fetchFbToken();
  // @ts-expect-error TS2769
  await fetch(appConfig.PaymentSystem.errorReporting, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `'Bearer ${fbToken}`,
    },
    body: JSON.stringify({
      error: error.message,
      userAgent,
      at: error.stack ? error.stack : "",
    }),
  });
};
