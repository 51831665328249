/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Link } from "react-router-dom";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { SubContents } from "components/templates/SubContents";

import nextone from "designs/images/permission/nextone.png";
import jasrac from "designs/images/permission/jasrac.jpg";

interface Props {}

/**
 * Unity アプリ向けの利用規約
 *
 * @deprecated 現在viewerアプリは運用されていないため削除予定。
 * @see https://balus3d.slack.com/archives/C01C7KHJ2J0/p1678287129841229?thread_ts=1678265651.393849&cid=C01C7KHJ2J0
 */
export const ViewerAppTerms: React.FC<Props> = () => {
  const classesTermsStyles = termsStyles();

  return (
    <SubContents>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, "SPWN利用規約"],
        ]}
      />

      <div css={classesTermsStyles.root}>
        <h2>SPWN利用規約</h2>
        <p css={classesTermsStyles.introduction}>
          本規約は、バルス株式会社（以下「当社」といいます）が提供するサービス「SPWN」アプリ（以下「本サービス」といいます）をご利用いただく際の取扱いにつき定めるものです。
        </p>

        <article css={classesTermsStyles.section}>
          <h3>1. （本規約への同意）</h3>
          <p>
            1.
            利用者は、本規約に同意いただいた場合に限り、本サービスを利用できるものとします。利用者が、本サービスにアクセスし、本規約への同意手続を行った時点で、利用者と当社との間で、本規約の諸規定に従った利用契約が成立するものとします。
          </p>
          <p>
            2.
            利用者が未成年者である場合には、親権者その他の法定代理人の同意を得たうえで、本サービスをご利用ください。
          </p>
          <p>
            3.
            当社は、本規約を改定するときは、その内容について本サイトに掲載するとともに、当社所定の方法により利用者に通知します。利用者は、本規約変更後、本サービスを利用した時点で、変更後の本利用規約に異議なく同意したものとみなされます。
          </p>
        </article>

        <article css={classesTermsStyles.section}>
          <h3>2. （ユーザー登録手続）</h3>
          <p>
            1.
            本サービスのアカウント登録希望者は、本規約に同意した上で、所定の方法で登録フォームより申込みを行ってください。
          </p>
          <p>
            2.
            当社は、登録ユーザー向けにメールで連絡事項の告知や広告その他の情報提供を行います。
          </p>
          <p>
            3.
            当社は、アカウント登録希望者が次の各号のいずれか一つに該当する場合は、当社の裁量により登録を承諾しないことができます。
          </p>
          <ul>
            <li>(1) 当社の定める方法によらず入会の申込みを行った場合</li>
            <li>
              (2)
              過去に本規約又は当社の定めるその他の利用規約等に違反したことを理由として処分を受けた者である場合
            </li>
            <li>(3) 不正な手段をもって登録を行っていると当社が判断した場合</li>
            <li>(4) 本人以外の情報を登録している場合</li>
            <li>(5) その他当社が不適切であると判断した場合</li>
          </ul>
        </article>

        <article css={classesTermsStyles.section}>
          <h3>3. （アカウントの管理）</h3>
          <p>
            1.
            利用者は、ユーザーID、パスワード等のアカウント情報を自己の責任の下で管理するものとします。利用者は、アカウントを第三者に利用させ、又は貸与、譲渡、名義変更、売買などをしてはならないものとします。
          </p>
          <p>
            2.
            当社は、アカウント情報によって本サービスへのログイン·利用があった場合、利用者本人が利用したものとみなすことができ、当該利用によって生じた結果及びそれに伴う一切の責任は、利用者本人に帰属するものとします。
          </p>
          <p>
            3.
            アカウント情報が不正に利用されていることが判明した場合、利用者は直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
          </p>
        </article>

        <article css={classesTermsStyles.section}>
          <h3>4. （利用者による投稿等）</h3>
          <p>
            1.
            利用者が本サービスに投稿した文章、画像、その他の情報（以下「投稿情報」といいます）の著作権は、お客様に留保されます。但し、当社は、投稿情報を、本サービスの改善やプロモーション等の目的のために、地域、期間、形態等に関して制約なく自由に利用することができるものとします。
          </p>
          <p>
            2.
            利用者は、本サービスにおいて、利用者のユーザー名、プロフィール画像、その他利用者が投稿した情報が他の利用者に公開されることを理解し、同意するものとします。
          </p>
          <p>
            3.
            利用者は、本サービスにおいて、第6条の禁止事項に抵触する内容を投稿してはなりません。
          </p>
        </article>

        <article css={classesTermsStyles.section}>
          <h3>5. （個人情報等の取扱い）</h3>
          <p>
            1.
            本サービスにおいて利用者が当社に提供する個人情報は、以下各号に定める目的で利用いたします。
          </p>
          <ul>
            <li>(1) ご注文の処理、納品、お支払の処理等</li>
            <li>(2) イベントにおける本人確認</li>
            <li>(3) その他、本サービスに関する利用者のご要望への対応</li>
            <li>(4) 利用者への商品·サービスのご案内</li>
            <li>
              (5)
              当社サービスの改善や新サービスの開発等に役立てるための統計データ作成
            </li>
            <li>(6) 本サービスの維持·管理</li>
          </ul>
          <p>
            2. 上記に定めるほか、個人情報の取扱いについては、当社が別途定める「
            <Link to="/privacy-policy-app">プライバシーポリシー</Link>
            」に則り、適正に取り扱うこととします。
          </p>
        </article>

        <article css={classesTermsStyles.section}>
          <h3>6. （禁止行為）</h3>
          <p className="indent_none">
            本サービスの利用に際し、利用者は、次に掲げる行為を行ってはなりません。利用者がこれに違反した場合、当社は、事前通知をすることなく、即時に利用停止、退会処分等、当社が本サービスの運営上、必要であると判断した措置を取ることができます。
          </p>
          <ul>
            <li>(1) 本規約又は当社の定めるその他の利用規約等に違反する行為</li>
            <li>
              (2)
              当社又は第三者の名誉·信用を毀損又は不当に差別若しくは誹謗中傷する行為
            </li>
            <li>
              (3)
              当社又は第三者の知的財産権、プライバシー権、肖像権、名誉·信用、財産権その他の権利を侵害する行為
            </li>
            <li>(4) 当社又は第三者に経済的損害を与える行為</li>
            <li>(5) 当社又は第三者に対する脅迫的な行為</li>
            <li>
              (6)
              詐欺、規制薬物の濫用、児童売買春、預貯金口座及び携帯電話の違法な売買等の犯罪に結びつく、又は結びつくおそれのある行為
            </li>
            <li>(7) 面識のない異性との出会い等を目的として利用する行為</li>
            <li>
              (8)
              わいせつ、児童ポルノ若しくは児童虐待に相当する投稿情報を投稿又は表示する行為、又はこれらの情報を収録した媒体を販売する行為、又はその送信、表示、販売を想起させる広告を投稿又は表示する行為
            </li>
            <li>
              (9)
              違法行為（けん銃等の譲渡、爆発物の製造、児童ポルノの提供、公文書偽造、殺人、脅迫等）を請け負い、仲介し、又は誘引する行為
            </li>
            <li>
              (10)
              コンピューターウィルス、有害なプログラムの使用又はそれを誘発する行為
            </li>
            <li>
              (11)
              本サービスのインフラ設備に対して過度な負担となるストレスをかける行為
            </li>
            <li>(12) 当サイトのサーバーやシステム、セキュリティへの攻撃</li>
            <li>
              (13)
              当社提供のインターフェース以外の方法で当社サービスにアクセスを試みる行為
            </li>
            <li>(14) 一人の利用者が、複数の利用者IDを取得する行為</li>
            <li>
              (15)
              その行為が前各号のいずれかに該当することを知りつつ、その行為を助長、教唆、勧誘する目的でする行為
            </li>
            <li>
              (16)
              法令、公序良俗に違反する又は違反するおそれがあると弊社が判断する行為
            </li>
            <li>
              (17)
              上記の他、当社が本サービスの運営上、不適切であると判断する行為
            </li>
          </ul>
        </article>

        <article css={classesTermsStyles.section}>
          <h3>7. （免責）</h3>
          <p>1. 当社は、次に掲げる事項について一切の保証を行いません。</p>
          <ul>
            <li>(1) 本サービスの永続性</li>
            <li>(2) 本サービスの一切の動作保証</li>
            <li>
              (3) 使用目的への適合性の保証及び使用結果に係る的確性や信頼性
            </li>
          </ul>
          <p>
            2.
            当社は、次に掲げる事項について、一切の責任を負わず、本サービスの内容、使用等に関して損害を被った場合でも、弊社は、弊社の故意又は重過失による場合を除き、一切の責任を負いません。
          </p>
          <ul>
            <li>(1) 利用者のパソコン等の利用環境</li>
            <li>(2) 本サービスの内容変更、中断、又は終了</li>
            <li>(3) アクセス過多、その他やむを得ない要因で発生した障害等</li>
            <li>(4) 広告掲載企業及び決済代行会社に関する事項</li>
            <li>
              (5)
              本サービスの各ページからリンクしているホームページに関する適法性、道徳性、信頼性、正確性又は最新性
            </li>
          </ul>
          <p>
            3.
            当社は、利用者及び会員に事前通知することなく、本サービスの内容変更、中断又は終了することができます。利用者は、当社が、サーバーのメンテナンスなど本サービスの提供に必要な行為のために本サービスを中断することがあることを了承するものとします。
          </p>
          <p>
            4.
            本サービスの利用にあたり、当社の責に帰すべき事由により利用者が損害を被った場合には、当社は、当該損害に係るサービス、チケット又は商品に関して利用者から受領した利用額を限度として賠償責任を負うものとします。ただし、当社の故意又は重過失に基づく場合は、この限りではありません。
          </p>
        </article>

        <article css={classesTermsStyles.section}>
          <h3>8. （準拠法、管轄裁判所）</h3>
          <p>
            1. 本規約は日本法に準拠し、日本法に従って解釈されるものとします。
          </p>
          <p>
            2.
            本サービスに関する当社と利用者等との間の一切の紛争については、訴額に応じて、東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          </p>
        </article>

        <article css={classesTermsStyles.section}>
          <h3>本サービスのライセンス</h3>
          {/* eslint-disable-next-line no-irregular-whitespace */}
          <p className="jasrac">JASRAC​許諾番号&nbsp;&nbsp;9024175002Y45037</p>
          {/* eslint-disable-next-line no-irregular-whitespace */}
          <p className="nextone">NexTone​許諾番号&nbsp;ID000006010</p>
        </article>
      </div>
    </SubContents>
  );
};

const termsStyles = () => {
  return {
    root: css`
      width: 90%;
      max-width: 980px;
      padding: 80px 0;
      margin: 0 auto;
      h2 {
        margin-bottom: 80px;
        font-size: 24px;
        text-align: center;
        font-weight: 400;
      }
    `,
    introduction: css`
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 1px;
    `,
    section: css`
      margin-bottom: 40px;
      h3 {
        margin-bottom: 16px;
        line-height: 1.3em;
      }
      p {
        padding-left: 1.3em;
        margin-bottom: 8px;
        font-size: 14px;
        text-indent: -1.3em;
        line-height: 1.5em;
        letter-spacing: 1px;
      }
      ul {
        padding-left: 1em;
        li {
          font-size: 14px;
          padding-left: 1.8em;
          margin-bottom: 8px;
          text-indent: -1.8em;
        }
      }
      .jasrac::before,
      .nextone::before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 5px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
      }
      .nextone::before {
        background-image: url(${nextone});
      }
      .jasrac::before {
        background-image: url(${jasrac});
      }
    `,
  };
};
