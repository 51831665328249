/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { EventInfo } from "utility";
import type { EventVideo, Ticket } from "@spwn/types/firebase/firestore";
import { ResGetStreamingKey } from "../../../../../types/functions";
import { EventVideoTicket } from "components/event/detail/videoList/EventVideoTicket";

type Props = {
  videoTickets: Ticket[];
  displayEvent: EventInfo;
  eventVideoMap: {
    [videoId: string]: EventVideo;
  } | null;
  streamingKey: ResGetStreamingKey;
  handleButtonAction: () => void;
};
export const EventVideoTicketList: React.FC<Props> = (props) => {
  const {
    displayEvent,
    eventVideoMap,
    streamingKey,
    handleButtonAction,
    videoTickets,
  } = props;

  const classes = eventVideoTicketListDesign();

  return (
    <div css={classes.root}>
      {videoTickets.map((videoTicket, i) => {
        return (
          <EventVideoTicket
            key={i}
            {...{
              videoTicket,
              displayEvent,
              eventVideoMap,
              streamingKey,
              handleButtonAction,
            }}
          />
        );
      })}
    </div>
  );
};

const eventVideoTicketListDesign = () => {
  return {
    root: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: start;
      &::after {
        @media screen and (min-width: 768px) {
          content: "";
          display: block;
          width: 31%;
        }
      }
    `,
  };
};
