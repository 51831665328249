/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";

import { getEventDisplayInfo, EventInfo, isDisplayPeriod } from "utility";

import { useI18n } from "hooks/i18n/i18n";
import { useGetEventList } from "hooks/event/useGetEventList";

import type { Event } from "@spwn/types/firebase/firestore";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { MainContents } from "components/templates/MainContents";
import { PortalTopEventCard } from "features/PortalTopEventCard";

export const CapsuleEventListFetcher: FC = () => {
  const { data: events, isLoading } = useGetEventList({
    type: "capsule",
  });

  return <CapsuleEventListLogic isLoading={isLoading} events={events} />;
};

const CapsuleEventListLogic: FC<{
  isLoading: boolean;
  events?: Event[];
}> = ({ isLoading, events }) => {
  const capsuleGoodsList: (EventInfo & { isOnSale: boolean })[] = (
    events ? events : []
  )
    .slice()
    .flatMap((event) => {
      const isOnSale = isDisplayPeriod(
        !event.isClosed,
        event.goodsSellingOpenTime,
        event.goodsSellingCloseTime
      );

      return {
        ...getEventDisplayInfo(event),
        isOnSale,
      };
    });

  return <CapsuleEventList events={capsuleGoodsList} isLoading={isLoading} />;
};

type GoodsEventListType = {
  events: (EventInfo & { isOnSale: boolean })[];
  isLoading: boolean;
};
export const CapsuleEventList: FC<GoodsEventListType> = ({
  events,
  isLoading,
}) => {
  const { t } = useI18n();
  const breadcrumbText = "カプセル一覧";

  if (events.length === 0 && !isLoading) {
    return (
      <ContentsLayout breadcrumbText={breadcrumbText}>
        <div
          css={css`
            height: calc(100vh - (255px + 194px));
            padding: 10px;
            margin-top: 100px;
            color: #888888;
            font-size: 20px;
            text-align: center;
            @media screen and (min-width: 768px) {
              font-size: 24px;
            }
          `}
        >
          {t("event.noEvent.noVOD")}
        </div>
      </ContentsLayout>
    );
  }

  return (
    <ContentsLayout breadcrumbText={breadcrumbText}>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr;
          gap: 24px;
          width: 90%;
          max-width: 980px;
          padding: 40px 0 80px;
          margin: 0 auto;
          @media screen and (min-width: 768px) {
            grid-template-columns: 1fr 1fr 1fr;
          }
        `}
      >
        {events.map((event, index) => {
          return (
            <React.Fragment key={`${event.eventId}_${index}`}>
              <PortalTopEventCard
                href={`/events/${event.eventId}/goods`}
                tag={event.isOnSale ? "販売中" : "販売終了"}
                tagColor={event.isOnSale ? "#00C2AD" : "#fff"}
                thumbnail={event.thumbnail}
                date={event.eventOpenDateFull}
                title={event.eventTitle}
              />
            </React.Fragment>
          );
        })}
      </div>
    </ContentsLayout>
  );
};

const ContentsLayout: FC<{
  children: JSX.Element;
  breadcrumbText: string;
}> = ({ children, breadcrumbText }) => {
  return (
    <MainContents>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, breadcrumbText],
        ]}
      />

      {children}
    </MainContents>
  );
};
