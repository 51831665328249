import Box from "@material-ui/core/Box";
import React, { FC } from "react";
import { TicketDigitalType } from "./AccountTicketDigital";

export const AccountTicketDigitalSeatNum: FC<{
  product: TicketDigitalType;
}> = ({ product }) => {
  return (
    <Box>
      <Box
        component={"p"}
        className="heading"
        marginBottom={"4px"}
        fontSize={"12px"}
        fontWeight={700}
      >
        {product.seatNumName}
      </Box>

      <Box component={"p"} fontSize={"20px"} lineHeight={"1.3em"}>
        {product.seat}
      </Box>
    </Box>
  );
};
