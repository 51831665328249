import ButtonBase from "@material-ui/core/ButtonBase";
import { Link } from "react-router-dom";
import React, { FC } from "react";

type Variant = "normal" | "caution" | "outline" | "disabled";

const buttonCommonStyle: (variant: Variant) => React.CSSProperties = (
  variant
) => {
  const bgColor = () => {
    switch (variant) {
      case "normal":
        return "#00c2ae";
      case "caution":
        return "#ff0000";
      case "outline":
        return "#fff";
      case "disabled":
        return "#A1A1A1";
    }
  };
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "48px",
    padding: "14px",
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "1px",
    lineHeight: "20px",
    backgroundColor: bgColor(),
    color: variant === "outline" ? "#00c2ae" : "#fff",
    border: variant === "outline" ? "2px solid #00c2ae" : "none",
    cursor: variant === "disabled" ? "not-allowed" : "pointer",
  };
};

type AccountTicketActionButtonProps =
  | {
      as: "button";
      children: string | JSX.Element;
      variant: Variant;
      onClick: () => void;
      href?: never;
      filename?: never;
    }
  | {
      as: "link";
      children: string | JSX.Element;
      variant: Variant;
      href: string;
      filename?: never;
      onClick?: never;
    }
  | {
      as: "download";
      children: string | JSX.Element;
      variant: Variant;
      href: string;
      filename?: string;
      onClick?: never;
    };

export const AccountTicketActionButton: FC<AccountTicketActionButtonProps> = ({
  as,
  children,
  href,
  filename,
  variant,
  onClick,
}) => {
  switch (as) {
    case "button":
      return (
        <ButtonBase
          onClick={onClick}
          disabled={variant === "disabled"}
          style={buttonCommonStyle(variant)}
        >
          {children}
        </ButtonBase>
      );
    case "link":
      return (
        <Link to={href} style={buttonCommonStyle(variant)}>
          {children}
        </Link>
      );
    case "download":
      return (
        <a href={href} style={buttonCommonStyle(variant)} download={filename}>
          {children}
        </a>
      );
  }
};
