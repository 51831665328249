/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFirestoreDocument } from "utility/firebase";
import Streaming from "../streaming/Streaming";
import { Store } from "../../store";
import { useVideoAuthenticator } from "hooks/video/VideoAuthenticator";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { streamingActions } from "modules/streaming";
import { VideoErrorBehavior } from "./VideoErrorBehavior";

/**
 * `/videos/{videoId}` routing component
 * created based on StreamingTicketAuthenticator
 * @returns
 */
export const VideoBase = () => {
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );
  const [vodSetting, setVodSetting] = useState(null);
  const [liveSetting, setLiveSetting] = useState(null);

  const dispatch = useDispatch();

  const { video, displayEvent, streamingKey, eventVideoMap, error } =
    useVideoAuthenticator();

  useEffect(() => {
    getPlayerSettings();
    dispatch(streamingActions.createMySmallIconUrl.started());
    return () => {};
  }, [dispatch]);

  const getPlayerSettings = async () => {
    // @ts-expect-error TS2345
    setVodSetting(await fetchFirestoreDocument(`/THEOSettings/VOD`));
    // @ts-expect-error TS2345
    setLiveSetting(await fetchFirestoreDocument(`/THEOSettings/Live`));
  };

  const classesStreamingContentsLayout = streamingContentsLayout({
    isDarkModeEnabled,
  });

  if (displayEvent && error) {
    return <VideoErrorBehavior displayEvent={displayEvent} error={error} />;
  }

  if (
    !video ||
    !displayEvent ||
    !streamingKey ||
    !eventVideoMap ||
    !vodSetting ||
    !liveSetting
  ) {
    return (
      <div id="contents-layout">
        <LoaderLayout />
      </div>
    );
  }

  return (
    <main css={classesStreamingContentsLayout.root}>
      <div css={classesStreamingContentsLayout.inner}>
        <Streaming
          url={""}
          // @ts-expect-error TS2322
          cookies={streamingKey.cookies}
          cookiesList={streamingKey.cookiesList}
          event={displayEvent}
          // @ts-expect-error TS2322
          hasTicket={streamingKey.hasTickets}
          // @ts-expect-error TS2322
          vid={video._id}
          eventVideoMap={eventVideoMap}
          vodSetting={vodSetting}
          liveSetting={liveSetting}
        />
      </div>
    </main>
  );
};

interface StreamingContentsLayoutProps {
  isDarkModeEnabled: boolean;
}
const streamingContentsLayout = (props?: StreamingContentsLayoutProps) => {
  // @ts-expect-error TS18048
  const backgroundColorInDarkMode = props.isDarkModeEnabled
    ? css`
        background-color: #202020;
      `
    : css`
        background-color: #fff;
      `;

  return {
    root: css``,
    inner: css`
      margin: 0 auto;
      ${backgroundColorInDarkMode}
      @media screen and (min-width: 768px) {
        width: 100%;
        max-width: 1800px;
        min-height: calc(100vh - 66px - 190px);
      }
    `,
  };
};
