import { all, call } from "redux-saga/effects";
import { eventSaga } from "./event";
import { authSaga } from "./auth";
import { firestoreSaga } from "./firestore";
import { ticketSaga } from "./ticket";
import { cartSaga } from "./cart";
import { purchaseSaga } from "./purchase";
import { flowerStandSaga } from "../modules/flowerStand";
import { adminSaga } from "../modules/admin";
import { liveSaga } from "../modules/live";
import { streamingSaga } from "../modules/streaming";
import { newsSaga } from "../modules/news";
import { notificationSaga } from "../modules/notification";
import { voucherSaga } from "../modules/voucher";

export default function* indexSaga() {
  yield all([
    call(eventSaga),
    call(authSaga),
    call(firestoreSaga),
    call(ticketSaga),
    call(cartSaga),
    call(purchaseSaga),
    call(flowerStandSaga),
    call(adminSaga),
    call(liveSaga),
    call(streamingSaga),
    call(newsSaga),
    call(notificationSaga),
    call(voucherSaga),
  ]);
}
