import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { select, put, take, call, all, takeEvery } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { loadingActions } from "./loading";
import { modalActions, ActionData } from "../modules/modal";
import { push } from "connected-react-router";
import { validateEmail, getLocalTimeDiff } from "../utility";
import {
  createDynamicLinkWithParam,
  createViewerAppDynamicLinkWithParam,
  fetchFbToken,
  fetchFirestoreDocument,
} from "../utility/firebase";
import { firestoreActions } from "./firestore";
import appConfig from "../constants/appConfig";
import { cartActions } from "./cart";
import { notificationActions } from "./notification";
import { Store } from "store";
import { changeStyleWithHosting } from "utility/hosting";

import { getBelongedHosting } from "utility/storage";
import { i18nextT } from "../../src/hooks/i18n/i18n";
import { APPLE_PROVIDER_ID } from "constants/firebase";
import { generateErrorMessage } from "./error";
import type { UserAddressState } from "@spwn/types/firebase/firestore";
import * as Sentry from "@sentry/browser";
import { isAccountsApp } from "features/accounts/AccountsApp";
import { createConnectClient } from "utility/connectWeb";

const actionCreator = actionCreatorFactory("auth");

/* eslint-disable @typescript-eslint/no-explicit-any */
export const authActions = {
  clearStateByKey: actionCreator<keyof authState>("clearStateByKey"),
  login: actionCreator.async<LoginProvider, any>("login"),
  logout: actionCreator.async<void, void>("logout"),
  loginWithDocomo:
    actionCreator.async<ReqLoginWithDocomo, any>("loginWithDocomo"),
  linkDocomoAccount: actionCreator.async<any, any>("linkDocomoAccount"),
  linkSNSAccount: actionCreator.async<ReqLinkSNSAccount, any>("linkSNSAccount"),
  unlinkSNSAccount:
    actionCreator.async<ReqLinkSNSAccount, any>("unlinkSNSAccount"),
  loginWithEmail: actionCreator.async<any, any>("loginWithEmail"),
  createUserWithEmail: actionCreator.async<
    { email: string; pass: string; return_url?: string | undefined },
    any
  >("createUserWithEmail"),
  createUserWithEmailLoading: actionCreator<{ isLoading: "start" | "end" }>(
    "createUserWithEmailLoading"
  ),
  listenLoginState: actionCreator.async<void, any>("listenLoginState"),
  getRedirectResult: actionCreator.async<void, any>("getRedirectResult"),
  addLoginAction: actionCreator<any>("addLoginAction"),
  clearLoginAction: actionCreator<void>("clearLoginAction"),
  uploadIconImgAction: actionCreator.async<void, any>("uploadIconImg"),
  setUserIconURL: actionCreator<{ url: string }>("setUserIconURL"),
  reAuth: actionCreator.async<ReqReAuth, any>("reAuth"),
  reAuthWithEmail: actionCreator.async<any, any>("reAuthWithEmail"),
  updateUserInfo:
    actionCreator.async<
      {
        credential: firebase.auth.AuthCredential;
        newEmail: string;
        newDisplayName: string;
      },
      any
    >("updateUserInfo"),
  resetPassword: actionCreator.async<any, any>("resetPassword"),
  sendVerificationEmail: actionCreator.async<
    { return_url?: string | undefined },
    any
  >("sendVerificationEmail"),
  createAppDynamicLink: actionCreator<{ ar: string; viewer: string }>(
    "createAppDynamicLink"
  ),
  holdDynamicLinkWindow: actionCreator<Window>("holdDynamicLinkWindow"),
  loginWithCredential: actionCreator.async<any, any>("loginWithCredential"),
  // address
  fetchIsAddressRegisterd: actionCreator.async<void, any>(
    "fetchIsAddressRegisterd"
  ),
  reAuthForEditUserInfo: actionCreator.async<ReqReAuthForEditUserInfo, any>(
    "reAuthForEditUserInfo"
  ),
  successReAuthForEditUserInfo: actionCreator<boolean>(
    "successReAuthForEditUserInfo"
  ), // use for success check
  saveAddress: actionCreator.async<ReqSaveAddress, any>("saveAddress"),
  loadAddress: actionCreator.async<ReqLoadAddress, any>("loadAddress"),
  setLocalTimeDiff: actionCreator.async<any, any>("setLocalTimeDiff"),
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export interface authState {
  user: firebase.User;
  isLogin: boolean | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionList: any; // ! no any
  userIconURL: string | null;
  verificationEmailSentTime: null | Date;
  arAppDynamicLink: string;
  viewerAppDynamicLink: string;
  dynamicLinkWindow: Window | null;
  isLoginWithCredential: boolean | null;
  addressInfo: AddressInfo;
  isReAuthRequired: boolean;
  isAddressRegistered: boolean;
  successReAuthForEditUserInfo: boolean;
  localTimeDiff: number;
  isCreatingUser: boolean;
}

export type LoginProvider =
  | firebase.auth.GoogleAuthProvider
  | firebase.auth.TwitterAuthProvider
  | firebase.auth.FacebookAuthProvider;

export type AddressInfo = {
  zipCode: string;
  prefecture: string;
  city: string;
  streetNum: string;
  buildingInfo: string;
  userName: string;
  phone: string;
};

interface ReqLoginWithEmail {
  email: string;
  pass: string;
}

interface ReqLoginWithDocomo {
  appName?: "viewerApp" | "arApp";
}

interface ReqLinkSNSAccount {
  providerType: "google" | "twitter" | "apple";
}

interface ReqLoginWithCredential {
  provider: string;
  token?: string; // if Google, Twitter, Facebook
  idToken?: string; // if Google
  secret?: string; // if Twitter
  email?: string; // if email
  pass?: string; // if email
}

interface ReqReAuth {
  email: string;
  displayName: string;
}

const initialState: authState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: {} as any, // ! any
  isLogin: null,
  actionList: [],
  userIconURL: null,
  verificationEmailSentTime: null,
  // @ts-expect-error TS2322
  arAppDynamicLink: null,
  // @ts-expect-error TS2322
  viewerAppDynamicLink: null,
  dynamicLinkWindow: null,
  isLoginWithCredential: null,
  // @ts-expect-error TS2322
  addressInfo: null,
  isReAuthRequired: true,
  // @ts-expect-error TS2322
  isAddressRegistered: null,
  successReAuthForEditUserInfo: false,
  localTimeDiff: 0,
  isCreatingUser: false,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const authReducer = reducerWithInitialState(initialState)
  .case(authActions.clearStateByKey, (state, key: keyof authState) => {
    return { ...state, [key]: initialState[key] };
  })
  .case(authActions.logout.done, (state) => {
    return { ...state, user: initialState.user, isLogin: false };
  })
  .case(authActions.unlinkSNSAccount.done, (state, payload: any) => {
    return { ...state, user: payload };
  })
  .case(authActions.getRedirectResult.done, (state, payload: any) => {
    return { ...state, user: payload };
  })
  .case(authActions.listenLoginState.done, (state, payload: any) => {
    return {
      ...state,
      user: payload ? payload : initialState.user,
      isLogin: !!payload,
    };
  })
  .case(authActions.addLoginAction, (state, payload) => {
    if (state.user.uid) {
      return state;
    } else {
      return { ...state, actionList: [...state.actionList, payload] };
    }
  })
  .case(authActions.clearLoginAction, (state) => {
    return { ...state, actionList: initialState.actionList };
  })
  .case(authActions.setUserIconURL, (state, payload) => {
    return { ...state, userIconURL: payload.url };
  })
  .case(authActions.reAuth.done, (state, _payload) => {
    return { ...state };
  })
  .case(authActions.reAuthWithEmail.done, (state, _payload) => {
    return { ...state };
  })
  .case(authActions.updateUserInfo.done, (state, _payload) => {
    return { ...state };
  })
  .case(authActions.resetPassword.done, (state, _payload) => {
    return { ...state };
  })
  .case(authActions.sendVerificationEmail.done, (state, _payload) => {
    return { ...state, verificationEmailSentTime: new Date() };
  })
  .case(authActions.createAppDynamicLink, (state, payload) => {
    return {
      ...state,
      arAppDynamicLink: payload.ar,
      viewerAppDynamicLink: payload.viewer,
    };
  })
  .case(authActions.holdDynamicLinkWindow, (state, payload) => {
    return { ...state, dynamicLinkWindow: payload };
  })
  .case(authActions.loginWithCredential.done, (state, payload: any) => {
    return { ...state, isLoginWithCredential: payload.isLogin };
  })
  .case(authActions.fetchIsAddressRegisterd.done, (state, payload: any) => {
    return { ...state, isAddressRegistered: payload };
  })
  .case(authActions.reAuthForEditUserInfo.done, (state, payload: any) => {
    return { ...state, isReAuthRequired: payload };
  })
  .case(authActions.successReAuthForEditUserInfo, (state, payload: any) => {
    return { ...state, successReAuthForEditUserInfo: payload };
  })
  .case(authActions.saveAddress.done, (state, payload: any) => {
    return { ...state, addressInfo: payload };
  })
  .case(authActions.loadAddress.done, (state, payload: any) => {
    return { ...state, addressInfo: payload };
  })
  .case(authActions.setLocalTimeDiff.done, (state, payload: any) => {
    return { ...state, localTimeDiff: payload };
  })
  .case(authActions.createUserWithEmailLoading, (state, payload) => {
    return { ...state, isCreatingUser: payload.isLoading === "start" };
  });
/* eslint-enable @typescript-eslint/no-explicit-any */

export default authReducer;

export function* authSaga() {
  yield takeEvery(authActions.login.started, login);
  yield takeEvery(authActions.logout.started, logout);
  yield takeEvery(authActions.loginWithDocomo.started, loginWithDocomo);
  yield takeEvery(authActions.linkDocomoAccount.started, linkDocomoAccount);
  yield takeEvery(authActions.linkSNSAccount.started, linkSNSAccount);
  yield takeEvery(authActions.unlinkSNSAccount.started, unlinkSNSAccount);
  yield takeEvery(authActions.loginWithEmail.started, loginWithEmail);
  yield takeEvery(authActions.createUserWithEmail.started, createUserWithEmail);
  yield takeEvery(authActions.listenLoginState.started, listenLoginState);
  yield takeEvery(authActions.getRedirectResult.started, getRedirectResult);
  yield takeEvery(authActions.uploadIconImgAction.started, uploadIconImg);
  yield takeEvery(authActions.reAuth.started, reAuth);
  yield takeEvery(authActions.reAuthWithEmail.started, reAuthWithEmail);
  yield takeEvery(authActions.updateUserInfo.started, updateUserInfo);
  yield takeEvery(authActions.resetPassword.started, resetPassword);
  yield takeEvery(
    authActions.sendVerificationEmail.started,
    sendVerificationEmail
  );
  yield takeEvery(authActions.loginWithCredential.started, loginWithCredential);
  yield takeEvery(
    authActions.fetchIsAddressRegisterd.started,
    fetchIsAddressRegisterd
  );
  yield takeEvery(
    authActions.reAuthForEditUserInfo.started,
    reAuthForEditUserInfo
  );
  yield takeEvery(authActions.saveAddress.started, saveAddress);
  yield takeEvery(authActions.loadAddress.started, loadAddress);
  yield takeEvery(authActions.setLocalTimeDiff.started, setLocalTimeDiff);
}

function* setLocalTimeDiff() {
  // @ts-expect-error TS7057
  const localTimeDiff = yield getLocalTimeDiff();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any = localTimeDiff * 1000;
  yield put(authActions.setLocalTimeDiff.done(data));
}

const hasAuthError = (e: unknown): e is { message?: string; code?: string } =>
  // @ts-expect-error TS18047
  typeof e === "object" && ("message" in e || "code" in e);

function* sendVerificationEmail(action: {
  payload: { return_url?: string | undefined };
}) {
  const user = firebase.auth().currentUser;
  if (user !== undefined && user !== null) {
    if (user.email === undefined || user.email === null || user.email === "") {
      // emailがない場合、メッセージを送らない
      return;
    }
  } else {
    // ユーザーがいない場合、メッセージを送らない
    return;
  }

  try {
    const url: string = (() => {
      if (action.payload.return_url) {
        const search = new URLSearchParams();
        search.set("return_url", action.payload.return_url);
        return `https://${window.location.hostname}/login?${search.toString()}`;
      } else {
        return (
          `https://${window.location.hostname}` +
          changeStyleWithHosting().commonSettings.route.home
        );
      }
    })();

    // if gibiate domain, change mail text
    if (getBelongedHosting() === "gibiate1") {
      // @ts-expect-error TS7057
      const fbToken = yield fetchFbToken();
      // const response = yield fetch("http://127.0.0.1:8001/", {
      // @ts-expect-error TS7057
      const response = yield fetch(
        // @ts-expect-error TS2769
        appConfig.CloudFunctions.sendEmailVerificationUrlViaEmail,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `'Bearer ${fbToken}`,
          },
          body: "",
        }
      );
      if (response.status === 200) {
        // @ts-expect-error TS7057
        const ret = yield response.json();
        console.info(ret);
      } else {
        // fail
        throw new Error("gibiate login error");
      }
      return;
    } else {
      yield user.sendEmailVerification({ url });
    }
    yield put(
      authActions.sendVerificationEmail.done({ params: action.payload })
    );
    // yield put(modalActions.toggleNotice(
    //     {msg: "メールアドレス確認メールを送信しました"}
    //     ))
  } catch (error) {
    console.error(error);
    if (hasAuthError(error)) {
      switch (error.code) {
        case "auth/too-many-requests":
          put(modalActions.toggleError({ msg: i18nextT("auth.mailSend") }));
          break;
        default:
          yield generateErrorMessage({
            // @ts-expect-error TS2322
            errorCode: null,
            errorDetail: {
              caption: null,
              msg: `${error.code}\n${error.message}`,
            },
          });
          break;
      }
    } else {
      put(modalActions.toggleError({ msg: i18nextT("auth.mailFailed") }));
    }
  }
}

function* login(action: { payload: LoginProvider }) {
  const provider = action.payload;
  yield put(loadingActions.toggleLoading({}));
  try {
    // google loginの場合は強制的にアカウントを選択させる
    if (provider.providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID) {
      provider.setCustomParameters({
        prompt: "select_account",
      });
    }
    // login情報取得処理はlistenLoginStateで。
    yield firebase.auth().signInWithRedirect(provider);
  } catch (e) {
    console.error(e);
    yield noticeLoginError(e);
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}
function* logout() {
  yield put(loadingActions.toggleLoading({}));
  try {
    yield firebase.auth().signOut();
    yield put(authActions.logout.done({}));
    yield put(firestoreActions.closeUserChannel());
    yield put(cartActions.clearMyCart());
    yield put(notificationActions.clearStateByKey("notificationMap"));
    yield put(authActions.clearStateByKey("addressInfo"));
    yield put(authActions.clearStateByKey("isReAuthRequired"));
    yield put(push(changeStyleWithHosting().commonSettings.route.home));
  } catch (e) {
    console.error(e);
    yield generateErrorMessage({
      errorCode: "unknown",
      errorDetail: null,
    });
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}
function* loginWithDocomo(action: { payload: ReqLoginWithDocomo }) {
  const { appName } = action.payload;
  try {
    yield put(loadingActions.toggleLoading({ msg: "loading" }));
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.CloudFunctions.startDSignIn, {
      // const response = yield fetch("http://127.0.0.1:8000/start_d_sign_in", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: window.location.href,
        email: null,
        displayName: null,
        appName: appName || null, // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
      }),
    });
    if (response.status === 200) {
      // @ts-expect-error TS7057
      const json = yield response.json();
      if (json.isError) {
        yield put(loadingActions.toggleLoading({}));
        yield put(modalActions.toggleError({ msg: json.msg }));
      } else {
        window.location.href = json.url;
      }
    }
  } catch (e) {
    console.error(e);
    yield put(loadingActions.toggleLoading({}));
    if (!hasAuthError(e)) return;
    yield put(modalActions.toggleError({ msg: e.message }));
  }
}
// @ts-expect-error TS7008
function* linkDocomoAccount(action: { payload }) {
  try {
    yield put(loadingActions.toggleLoading({ msg: "loading" }));
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.CloudFunctions.startDAccountLink, {
      // const response = yield fetch("http://127.0.0.1:8000/start_d_sign_in", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify({
        from: window.location.href,
        email: action?.payload?.email ? action.payload.email : null,
        displayName: action?.payload?.displayName
          ? action.payload.displayName
          : null,
      }),
    });
    if (response.status === 200) {
      // @ts-expect-error TS7057
      const json = yield response.json();
      if (json.isError) {
        yield put(loadingActions.toggleLoading({}));
        yield put(modalActions.toggleError({ msg: json.msg }));
      } else {
        window.location.href = json.url;
      }
    }
  } catch (e) {
    console.error(e);
    yield put(loadingActions.toggleLoading({}));
    yield generateErrorMessage({
      // @ts-expect-error TS2322
      errorCode: null,
      errorDetail: {
        caption: null,
        // @ts-expect-error TS2322
        msg: hasAuthError(e) && e.message,
      },
    });
  }
}

function* linkSNSAccount(action: { payload: ReqLinkSNSAccount }) {
  try {
    const { providerType } = action.payload;
    // @ts-expect-error TS2322
    let provider:
      | firebase.auth.GoogleAuthProvider
      | firebase.auth.TwitterAuthProvider = null;
    switch (providerType) {
      case "google":
        provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: "select_account",
        });
        break;
      case "twitter":
        provider = new firebase.auth.TwitterAuthProvider();
        break;
      default:
        break;
    }
    // @ts-expect-error TS2531
    yield firebase.auth().currentUser.linkWithRedirect(provider);
  } catch (e) {
    console.error(e);
  }
}

function* unlinkSNSAccount(action: { payload: ReqLinkSNSAccount }) {
  try {
    const { providerType } = action.payload;
    let providerId = null;
    switch (providerType) {
      case "google":
        providerId = firebase.auth.GoogleAuthProvider.PROVIDER_ID;
        break;
      case "twitter":
        providerId = firebase.auth.TwitterAuthProvider.PROVIDER_ID;
        break;
      case "apple":
        providerId = APPLE_PROVIDER_ID;
        break;
      default:
        break;
    }
    // @ts-expect-error TS2531
    const user = yield firebase.auth().currentUser.unlink(providerId);
    // create shallow copy to let to rerender
    yield put(authActions.unlinkSNSAccount.done({ ...user }));
  } catch (e) {
    console.error(e);
  }
}

function* loginWithEmail(action: { payload: ReqLoginWithEmail }) {
  try {
    const { email, pass } = action.payload;
    yield put(loadingActions.toggleLoading({}));
    yield firebase.auth().signInWithEmailAndPassword(email, pass);
    // リロードする
    window.location.reload();
  } catch (e) {
    console.error(e);
    if (!hasAuthError(e)) return;
    switch (e.code) {
      case "auth/user-not-found":
        yield generateErrorMessage({
          errorCode: "user-not-found",
          errorDetail: null,
        });
        break;

      case "auth/invalid-email":
        yield generateErrorMessage({
          errorCode: "invalid-email",
          errorDetail: null,
        });
        break;

      case "auth/wrong-password":
        yield generateErrorMessage({
          errorCode: "wrong-password",
          errorDetail: null,
        });
        break;

      case "auth/too-many-requests":
        yield generateErrorMessage({
          errorCode: "too-many-requests",
          errorDetail: null,
        });
        break;

      default:
        yield generateErrorMessage({
          errorCode: "unknown",
          errorDetail: null,
        });
    }
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}
function* createUserWithEmail(action: {
  payload: { email: string; pass: string; return_url?: string | undefined };
}) {
  const state: Store = yield select();
  // NOTE: 2重にfirebase authが登録されてしまうことを防ぐ
  // @see: https://balus3d.slack.com/archives/C05FC07F1JT/p1718524172391109?thread_ts=1718518133.420669&cid=C05FC07F1JT
  if (state.auth.isCreatingUser) {
    console.warn("ユーザー登録処理中です");
    return;
  }

  try {
    yield put(loadingActions.toggleLoading({ msg: i18nextT("auth.Register") }));
    yield put(authActions.createUserWithEmailLoading({ isLoading: "start" }));
    const { email, pass, return_url } = action.payload;
    yield firebase.auth().createUserWithEmailAndPassword(email, pass);
    yield put(
      authActions.sendVerificationEmail.started({
        return_url,
      })
    );
    // wait to send mail
    yield take("auth/sendVerificationEmail_DONE");

    if (isAccountsApp()) {
      window.location.href = `${appConfig.accountsApp.appUrl}/signup/authentication`;
      return;
    } else {
      window.location.reload();
    }
  } catch (e) {
    console.error(e);
    yield generateErrorMessage({
      // @ts-expect-error TS2322
      errorCode: null,
      errorDetail: {
        caption: null,
        // @ts-expect-error TS2322
        msg: hasAuthError(e) && e.message,
      },
    });
  } finally {
    yield put(authActions.createUserWithEmailLoading({ isLoading: "end" }));
    yield put(loadingActions.toggleLoading({}));
  }
}
function authChannel() {
  return eventChannel((emitter) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      emitter({ user });
    });
    return unsubscribe;
  });
}
function* listenLoginState() {
  const {
    auth: { actionList },
  } = yield select();
  try {
    yield put(
      loadingActions.toggleLoading({ msg: i18nextT("auth.loginCheck") })
    );
    yield getRedirectResult();
    // @ts-expect-error TS7057
    const channel = yield call(authChannel);
    while (true) {
      const { user } = yield take(channel);
      yield put(authActions.listenLoginState.done(user));
      if (user) {
        // ログインしていたらモーダルを閉じるように（ポップアップログイン用）
        yield put(modalActions.toggleLogin({ forceClose: true }));
      }

      //リダイレクトurlが設定されていればリダイレクト
      const redirectUrl = localStorage.getItem("redirectUrl");
      if (redirectUrl) yield put(push(redirectUrl));
      localStorage.removeItem("redirectUrl");

      //ログイン後に、登録されているアクションをDispatch
      if (user) {
        // @ts-expect-error TS7006
        yield all(actionList.map((item) => put(item.action(item.args))));
      }
      yield put(authActions.clearLoginAction());
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}
/**
 * ログインエラーハンドリングのためのみに使用する
 */
function* getRedirectResult() {
  try {
    // 新規登録が成功した場合、Email認証チェックが必要かどうかチェックする(google以外は必要)
    const result: firebase.auth.UserCredential = yield firebase
      .auth()
      .getRedirectResult();
    // reloadされるのでstoreの値が使用できない。email未認証だったら送ってしまう
    if (result.user?.emailVerified === false) {
      yield put(authActions.sendVerificationEmail.started({}));
    }
    const provider =
      result.credential && result.credential.providerId
        ? result.credential.providerId
        : null;
    // FIXME: unsafe any cast
    // eslint-disable-next-line prefer-destructuring,@typescript-eslint/no-explicit-any
    const credential: any = result.credential; // ! no any !
    if (
      provider &&
      credential &&
      (credential.accessToken || credential.idToken || credential.secret)
    ) {
      yield put(
        authActions.createAppDynamicLink({
          ar: createDynamicLinkWithParam(provider, credential),
          viewer: createViewerAppDynamicLinkWithParam(provider, credential),
        })
      );
    }
  } catch (e) {
    console.error(e);
    yield noticeLoginError(e);
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}
/**
 * ログインステータスからエラーポップアップを出す
 *
 * 予期せぬログインエラーの原因調査のために、エラーを特定できない分岐に`Sentry.captureException`を追加
 * しばらくはSentryにてログを収集する。
 * @see https://github.com/balus-co-ltd/spwn/issues/2368
 */
// @ts-expect-error TS7006
function* noticeLoginError(e) {
  switch (e.code) {
    case "auth/account-exists-with-different-credential": {
      // 既に認証されているProviderを取得する
      // Gets the list of possible sign in methods for the given email address
      // @ts-expect-error TS7057
      const providerIds = yield firebase
        .auth()
        .fetchSignInMethodsForEmail(e.email);
      const [providerId] = providerIds;
      let provErrorMsg = "";
      switch (providerId) {
        case firebase.auth.GoogleAuthProvider.PROVIDER_ID: {
          provErrorMsg = i18nextT("auth.googleUsed");
          break;
        }
        case firebase.auth.TwitterAuthProvider.PROVIDER_ID: {
          provErrorMsg = i18nextT("auth.twitterUsed");
          break;
        }
        case firebase.auth.FacebookAuthProvider.PROVIDER_ID: {
          provErrorMsg = i18nextT("auth.facebookUsed");
          break;
        }
        case APPLE_PROVIDER_ID: {
          provErrorMsg = "Apple認証で既に使用されているemailアドレスです";
          break;
        }
        case firebase.auth.EmailAuthProvider.PROVIDER_ID: {
          provErrorMsg = i18nextT("auth.emailUsed");
          break;
        }
        default: {
          // 対応していないはずのAuth認証
          provErrorMsg = i18nextT("auth.error");
          break;
        }
      }
      Sentry.captureException(e);
      yield put(modalActions.toggleError({ msg: provErrorMsg }));
      return;
    }
    case "auth/credential-already-in-use":
    case "auth/email-already-in-use": {
      // const msg = "This credential is already associated with a different user account."
      const msg = "既に異なるアカウントで使用されています";
      yield put(modalActions.toggleError({ msg }));
      return;
    }
    default: {
      break;
    }
  }
  Sentry.captureException(e);
  yield put(modalActions.toggleError({ msg: i18nextT("auth.error2") }));
}

// @ts-expect-error TS7006
function* uploadIconImg(action) {
  try {
    yield put(
      loadingActions.toggleLoading({ msg: i18nextT("auth.imageUpload") })
    );
    const user = firebase.auth().currentUser;
    const file = action.payload;
    // @ts-expect-error TS18047
    yield firebase.storage().ref(`/users/${user.uid}/profileImg`).put(file);
    // @ts-expect-error TS7057
    const url = yield firebase
      .storage()
      // @ts-expect-error TS18047
      .ref(`users/${user.uid}/profileImg`)
      .getDownloadURL();
    // @ts-expect-error TS18047
    yield user.updateProfile({ photoURL: url });
    yield put(authActions.setUserIconURL({ url }));
    yield put(loadingActions.toggleLoading({}));
    yield put(
      modalActions.toggleNotice({ msg: i18nextT("auth.imageUploaded") })
    );
  } catch (e) {
    console.error(e);
    yield put(loadingActions.toggleLoading({}));
    yield put(
      modalActions.toggleError({ msg: i18nextT("auth.unknownImageError") })
    );
  }
}

// @ts-expect-error TS7006
function* reAuthWithEmail(action) {
  try {
    const mode =
      action.payload.hasOwnProperty("mode") &&
      action.payload.mode === "updatePW"
        ? "updatePW"
        : "updateProfile";
    yield put(loadingActions.toggleLoading({}));
    yield put(
      loadingActions.toggleLoading({ msg: i18nextT("auth.Reauthentication2") })
    );
    const { email } = action.payload;
    const pw = action.payload.password;
    if (!validateEmail(email)) {
      yield put(loadingActions.toggleLoading({}));
      yield put(
        modalActions.toggleError({ msg: i18nextT("auth.Reauthentication2") })
      );
      return;
    }
    // @ts-expect-error TS7057
    const credential = yield firebase.auth.EmailAuthProvider.credential(
      email,
      pw
    );
    if (mode === "updateProfile") {
      yield put(
        authActions.updateUserInfo.started({
          credential,
          newEmail: action.payload.newEmail,
          newDisplayName: action.payload.newDisplayName,
        })
      );
    } else {
      const user = firebase.auth().currentUser;
      // @ts-expect-error TS18047
      yield user.reauthenticateWithCredential(credential);
      // @ts-expect-error TS18047
      yield user.updatePassword(action.payload.newPassword);
      yield put(loadingActions.toggleLoading({}));
      yield put(
        loadingActions.toggleLoading({ msg: i18nextT("auth.updatePassword") })
      );

      yield put(loadingActions.toggleLoading({}));
      yield put(
        modalActions.toggleNotice({ msg: i18nextT("auth.updatedPassword") })
      );
    }
  } catch (e) {
    console.error(e);
    yield put(loadingActions.toggleLoading({}));
    if (hasAuthError(e)) {
      switch (e.code) {
        case "signInWithEmailAndPassword":
          yield put(
            modalActions.toggleError({ msg: i18nextT("auth.updatedPassword") })
          );
          return;
        case "auth/weak-password":
          yield put(
            modalActions.toggleError({ msg: i18nextT("auth.badPassword") })
          );
          return;
        case "auth/requires-recent-login":
          yield put(
            modalActions.toggleError({ msg: i18nextT("auth.authError") })
          );
          return;
        case "auth/wrong-password":
          yield put(
            modalActions.toggleError({
              msg: i18nextT("auth.failureCurrentEmail"),
            })
          );
          return;
        case "auth/too-many-requests":
          yield put(
            modalActions.toggleError({
              msg: i18nextT("auth.faulureManyPassword"),
            })
          );
          return;
        default:
          yield put(
            modalActions.toggleError({ msg: i18nextT("auth.unknownUserError") })
          );
          throw e;
      }
    }
    yield put(
      modalActions.toggleError({ msg: i18nextT("auth.unknownUserError") })
    );
    throw e;
  }
}

// @ts-expect-error TS7006
function* reAuth(action) {
  try {
    const user = firebase.auth().currentUser;
    let pid = null;
    // @ts-expect-error TS18047
    if (!user.providerData[0]) {
      yield put(
        authActions.linkDocomoAccount.started({
          email: action.payload.email,
          displayName: action.payload.displayName,
        })
      );
      pid = null;
    } else {
      // @ts-expect-error TS2322
      pid = user.providerData[0].providerId;
    }
    let promise = null;
    let provider = null;
    switch (pid) {
      case firebase.auth.GoogleAuthProvider.PROVIDER_ID: {
        provider = new firebase.auth.GoogleAuthProvider();
        promise = firebase.auth().signInWithPopup(provider);
        break;
      }

      case firebase.auth.FacebookAuthProvider.PROVIDER_ID: {
        provider = new firebase.auth.FacebookAuthProvider();
        promise = firebase.auth().signInWithPopup(provider);
        break;
      }

      case firebase.auth.TwitterAuthProvider.PROVIDER_ID: {
        provider = new firebase.auth.TwitterAuthProvider();
        promise = firebase.auth().signInWithPopup(provider);
        break;
      }

      case APPLE_PROVIDER_ID: {
        provider = new firebase.auth.OAuthProvider(APPLE_PROVIDER_ID);
        provider.addScope("email");
        provider.addScope("name");
        promise = firebase.auth().signInWithPopup(provider);
        break;
      }

      case firebase.auth.EmailAuthProvider.PROVIDER_ID: {
        const emailReAuthModalPayload: ActionData = {
          trunk: {
            newEmail: action.payload.email,
            newDisplayName: action.payload.displayName,
          },
          caption: i18nextT("auth.operatingAuthError"),
          contents: {
            email: {
              title: "email",
              tagType: "input",
              placeHolderAttr: i18nextT("auth.mail"),
              typeAttr: "email",
              classAttr: "",
              // @ts-expect-error TS2322
              value: user.email,
              disable: true,
            },
            password: {
              title: "password",
              tagType: "input",
              placeHolderAttr: i18nextT("auth.password"),
              typeAttr: "password",
              classAttr: "",
            },
          },
          btnMsg: i18nextT("auth.Reauthentication"),
          callbackTarget: "reAuthWithEmail",
        };
        yield put(modalActions.toggleActionModal(emailReAuthModalPayload));
        return;
      }

      default: {
        return;
      }
    }
    yield put(
      loadingActions.toggleLoading({ msg: i18nextT("auth.Reauthentication2") })
    );
    // @ts-expect-error TS7057
    const result = yield promise;
    yield put(
      authActions.updateUserInfo.started({
        credential: result.credential,
        newEmail: action.payload.email,
        newDisplayName: action.payload.displayName,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(loadingActions.toggleLoading({}));
    if (e instanceof Error) {
      yield put(
        modalActions.toggleError({
          msg: i18nextT("auth.unknownUserError") + "\n" + e.message,
        })
      );
    } else {
      yield put(
        modalActions.toggleError({ msg: i18nextT("auth.unknownUserError") })
      );
    }
  }
}

// @ts-expect-error TS7006
function* updateUserInfo(action) {
  try {
    const { credential } = action.payload;
    const user = firebase.auth().currentUser;
    const auth = firebase.auth();
    const connectClient = createConnectClient(auth);
    // @ts-expect-error TS18047
    const existingEmail = user.email;
    yield put(loadingActions.toggleLoading({}));
    yield put(loadingActions.toggleLoading({ msg: i18nextT("auth.loadMail") }));
    // @ts-expect-error TS18047
    yield user.reauthenticateWithCredential(credential);
    // @ts-expect-error TS18047
    yield user.updateEmail(action.payload.newEmail);
    yield put(loadingActions.toggleLoading({}));
    yield put(loadingActions.toggleLoading({ msg: i18nextT("auth.loadUser") }));
    // @ts-expect-error TS18047
    yield user.updateProfile({ displayName: action.payload.newDisplayName });
    yield connectClient.updateUser({
      name: action.payload.newDisplayName,
      email: action.payload.newEmail,
    });
    yield put(loadingActions.toggleLoading({}));
    yield put(
      modalActions.toggleNotice({ msg: i18nextT("auth.succsessLoad") })
    );
    const updatedUser = firebase.auth().currentUser;
    if (
      existingEmail !== action.payload.newEmail &&
      // @ts-expect-error TS18047
      !updatedUser.emailVerified
    ) {
      yield put(authActions.sendVerificationEmail.started({}));
    }
  } catch (e) {
    console.error(e);
    yield put(loadingActions.toggleLoading({}));
    if (hasAuthError(e)) {
      switch (e.code) {
        case "auth/email-already-in-use":
          yield put(
            modalActions.toggleError({ msg: i18nextT("auth.usedMailError") })
          );
          return;
        case "auth/popup-closed-by-user":
        case "reauthenticateAndRetrieveDataWithCredential":
          yield put(
            modalActions.toggleError({ msg: i18nextT("auth.failedUser") })
          );
          return;
        case "auth/wrong-password":
          yield put(
            modalActions.toggleError({ msg: i18nextT("auth.failureInput") })
          );
          return;
        default:
          yield put(
            modalActions.toggleError({ msg: i18nextT("auth.unknownUserError") })
          );
          return;
      }
    } else {
      yield put(
        modalActions.toggleError({ msg: i18nextT("auth.unknownUserError") })
      );
    }
  }
}

// @ts-expect-error TS7006
function* resetPassword(action) {
  const { email } = action.payload;
  yield put(
    loadingActions.toggleLoading({ msg: i18nextT("auth.unknownUserError") })
  );
  try {
    if (validateEmail(email) === false) {
      yield put(modalActions.toggleError({ msg: i18nextT("auth.wrongMail") }));
      return;
    }
    // even user who validated with twitter or google can reset password
    // !!! if reset, the validation is overwrote to mail validation !!!
    yield firebase.auth().sendPasswordResetEmail(email);
    yield put(modalActions.toggleNotice({ msg: i18nextT("auth.resetedMail") }));
  } catch (e) {
    yield put(
      modalActions.toggleError({
        msg: `${i18nextT("auth.resetFail")}<br/>${e}`,
      })
    );
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

/**
 * AR APPからprovider, tokenが渡された場合、ログインする
 * @param action
 */
function* loginWithCredential(action: { payload: ReqLoginWithCredential }) {
  const { provider, token, idToken, secret, email, pass } = action.payload;
  yield put(loadingActions.toggleLoading({}));

  try {
    // @ts-expect-error TS2322
    let credential: firebase.auth.OAuthCredential = null;
    switch (provider) {
      case "google":
        credential = firebase.auth.GoogleAuthProvider.credential(idToken, null);
        break;
      case "twitter":
        credential = firebase.auth.TwitterAuthProvider.credential(
          // @ts-expect-error TS2345
          token,
          secret
        );
        break;
      case "facebook":
        // @ts-expect-error TS2345
        credential = firebase.auth.FacebookAuthProvider.credential(token);
        break;
      case "email":
        yield firebase
          .auth()
          // @ts-expect-error TS2345
          .signInWithEmailAndPassword(atob(email), atob(pass));
        yield put(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          authActions.loginWithCredential.done({ isLogin: true } as any)
        );
        return;
      default:
        break;
    }
    if (!credential) {
      yield put(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        authActions.loginWithCredential.done({ isLogin: false } as any)
      );
      return;
    }

    yield firebase.auth().signInWithCredential(credential);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(authActions.loginWithCredential.done({ isLogin: true } as any));
  } catch (e) {
    console.error(e);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(authActions.loginWithCredential.done({ isLogin: false } as any));
    yield noticeLoginError(e);
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

/**
 * call from App once logined
 */
function* fetchIsAddressRegisterd() {
  const state: Store = yield select();
  const { user } = state.auth;
  if (!user) return;
  const ref = `users/${user.uid}/addresses/main`;
  const address: UserAddressState = yield fetchFirestoreDocument(ref);
  const isRegistered = Boolean(address?.isRegistered);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield put(authActions.fetchIsAddressRegisterd.done(isRegistered as any));
}

interface ReqReAuthForEditUserInfo {
  password: string;
}
function* reAuthForEditUserInfo(action: { payload: ReqReAuthForEditUserInfo }) {
  const state: Store = yield select();
  const { user } = state.auth;
  if (!user) {
    return;
  }
  const { password } = action.payload;

  let success = false;

  try {
    // reAuth for d account
    const isOpenId = user.providerData.length === 0;
    if (isOpenId) {
      yield put(loadingActions.toggleLoading({}));
      yield put(
        authActions.linkDocomoAccount.started({
          email: null,
          displayName: null,
        })
      );
      return;
    }

    // @ts-expect-error TS2531
    const pid = user.providerData[0].providerId;
    if (pid === firebase.auth.EmailAuthProvider.PROVIDER_ID) {
      if (password === "") {
        yield put(
          modalActions.toggleError({ msg: i18nextT("auth.loginPassInput") })
        );
        return;
      }
    }
    let provider;
    switch (pid) {
      case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
        yield put(
          loadingActions.toggleLoading({
            msg: i18nextT("auth.Reauthentication2"),
          })
        );
        provider = new firebase.auth.GoogleAuthProvider();
        yield firebase.auth().signInWithPopup(provider);
        break;
      case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
        yield put(
          loadingActions.toggleLoading({
            msg: i18nextT("auth.Reauthentication2"),
          })
        );
        provider = new firebase.auth.FacebookAuthProvider();
        yield firebase.auth().signInWithPopup(provider);
        break;
      case firebase.auth.TwitterAuthProvider.PROVIDER_ID:
        yield put(
          loadingActions.toggleLoading({
            msg: i18nextT("auth.Reauthentication2"),
          })
        );
        provider = new firebase.auth.TwitterAuthProvider();
        yield firebase.auth().signInWithPopup(provider);
        break;
      case APPLE_PROVIDER_ID:
        provider = new firebase.auth.OAuthProvider(APPLE_PROVIDER_ID);
        provider.addScope("email");
        provider.addScope("name");
        yield firebase.auth().signInWithPopup(provider);
        break;
      case firebase.auth.EmailAuthProvider.PROVIDER_ID:
        yield put(
          loadingActions.toggleLoading({
            msg: i18nextT("auth.Reauthentication2"),
          })
        );
        // @ts-expect-error TS2345
        yield firebase.auth().signInWithEmailAndPassword(user.email, password);
        break;
      default:
        return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(authActions.reAuthForEditUserInfo.done(false as any));
    success = true;
  } catch (error) {
    console.error(error);
    yield put(modalActions.toggleError({ msg: i18nextT("auth.failedUser") }));
  } finally {
    yield put(loadingActions.toggleLoading({}));
    yield put(authActions.successReAuthForEditUserInfo(success));
  }
}

interface ReqSaveAddress {
  zipCode: string;
  prefecture: string;
  city: string;
  streetNum: string;
  buildingInfo: string;
  userName: string;
  phone: string;
  password: string;
}
function* saveAddress(action: { payload: ReqSaveAddress }) {
  try {
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    if (!fbToken) {
      return;
    }
    const state: Store = yield select();
    const { user, isAddressRegistered } = state.auth;
    if (!user || isAddressRegistered === null) {
      return;
    }
    yield put(loadingActions.toggleLoading({ msg: i18nextT("auth.Register") }));
    const msg = {
      zipCode: action.payload.zipCode,
      prefecture: action.payload.prefecture,
      city: action.payload.city,
      streetNum: action.payload.streetNum,
      buildingInfo: action.payload.buildingInfo,
      userName: action.payload.userName,
      phone: action.payload.phone,
    };
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.CloudFunctions.storeAddressInfo, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(msg),
    });

    if (response.status !== 200) {
      // @ts-expect-error TS7057
      const ret = yield response.json();
      console.error(ret.msg);
      yield put(modalActions.toggleError({ msg: ret.msg }));
    }
    // register address to store
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(authActions.saveAddress.done(msg as any));
    yield put(
      modalActions.toggleNotice({ caption: i18nextT("auth.Registered") })
    );
    // update store's isAddressRegistered only when saved address
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(authActions.fetchIsAddressRegisterd.done(true as any));
  } catch (error) {
    console.error(error);
    yield put(
      modalActions.toggleError({ msg: i18nextT("auth.failureRegister") })
    );
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

interface ReqLoadAddress {}
function* loadAddress(_action: { payload: ReqLoadAddress }) {
  try {
    const state: Store = yield select();
    const { user } = state.auth;
    if (!user.uid) {
      return;
    }
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    if (!fbToken) {
      return;
    }
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.CloudFunctions.loadAddressInfo, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify({}),
    });
    if (response.status === 200) {
      // @ts-expect-error TS7057
      const ret = yield response.json();
      const addressInfo = {
        zipCode: ret.userAddress.zipCode,
        prefecture: ret.userAddress.prefecture,
        city: ret.userAddress.city,
        streetNum: ret.userAddress.streetNum,
        buildingInfo: ret.userAddress.buildingInfo,
        userName: ret.userInfo.userName,
        phone: ret.userInfo.phone,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield put(authActions.loadAddress.done(addressInfo as any));
    } else if (response.status === 401) {
      // @ts-expect-error TS7057
      const ret = yield response.json();
      console.error(ret.msg);
      yield put(
        modalActions.toggleError({ msg: i18nextT("auth.needReauthentication") })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield put(authActions.reAuthForEditUserInfo.done(true as any));
    } else if (response.status === 404) {
      // not registered
      const addressInfo = {
        zipCode: "",
        prefecture: "",
        city: "",
        streetNum: "",
        buildingInfo: "",
        userName: "",
        phone: "",
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield put(authActions.loadAddress.done(addressInfo as any));
    } else {
      // @ts-expect-error TS7057
      const ret = yield response.json();
      console.error(ret.msg);
    }
  } catch (error) {
    console.error(error);
  }
}
