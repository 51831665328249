/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { FC } from "react";
import { useI18n } from "hooks/i18n/i18n";

import { changeStyleWithHosting } from "utility/hosting";
import { PCView, SPView } from "components/common/MediaQuery";
import { SpwnPortalLogo } from "ui/SpwnPortalLogo";
import { ExternalOpenIcon } from "ui/ExternalOpenIcon";
import { createUrl } from "utility/createUrl";
import { TextLink } from "ui/TextLink";

const Footer: FC = () => {
  const { t } = useI18n();
  const { pastEvents, eventNotes, guide, contact } =
    changeStyleWithHosting().commonSettings.menu;
  const footerMenuBelow = [
    {
      name: t("common.footer.operatingCompany"),
      link: "https://balus.co/",
      target: "_blank",
    },
    { name: t("common.footer.termsOfService"), link: "/terms-of-service" },
    { name: t("common.footer.law"), link: "/law" },
    {
      name: t("common.footer.paymentServicesAct"),
      link: "/payment-services-act",
    },
    { name: t("common.footer.privacyPolicy"), link: "/privacy-policy" },
  ];

  return (
    <footer
      css={css`
        background-color: #141718;
        @media screen and (min-width: 768px) {
          padding: 40px 0px 50px;
        }
      `}
    >
      <div
        css={css`
          width: 100%;
          @media screen and (min-width: 768px) {
            width: 95%;
            max-width: 1800px;
            margin: 0 auto;
          }
        `}
      >
        <SPView>
          <div
            css={css`
              padding: 12px 20px;
              @media screen and (min-width: 768px) {
                padding: 0 0;
              }
            `}
          >
            <LinkToSpwnPortal />
          </div>
        </SPView>

        {/* Footer上部のメニューレイアウト */}
        <div
          css={css`
            @media screen and (min-width: 768px) {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-bottom: 20px;
              margin-bottom: 20px;
              border-bottom: 1px solid #6a6a6a;
            }
          `}
        >
          <ul
            css={css`
              @media screen and (min-width: 768px) {
                display: flex;
              }
              li:not(:last-child) {
                @media screen and (min-width: 768px) {
                  margin-right: 40px;
                }
              }
            `}
          >
            {pastEvents.isDisplay && (
              <li>
                <LinkInTheUpperMenu
                  label={pastEvents.text}
                  href={"/archive-events"}
                />
              </li>
            )}
            {eventNotes.isDisplay && (
              <li>
                <LinkInTheUpperMenu
                  label={t("common.footer.eventNotes")}
                  href={"/event-notes"}
                />
              </li>
            )}
            {guide.isDisplay && (
              <li>
                <LinkInTheUpperMenu
                  label={t("common.footer.manual")}
                  href={
                    "https://spwn.zendesk.com/hc/ja/sections/360007018452-%E3%81%AF%E3%81%98%E3%82%81%E3%81%A6%E3%81%AE%E6%96%B9%E3%81%B8%E3%81%AE%E3%81%94%E5%88%A9%E7%94%A8%E3%82%AC%E3%82%A4%E3%83%89"
                  }
                />
              </li>
            )}
            {contact.isDisplay && (
              <li>
                <LinkInTheUpperMenu
                  label={t("common.footer.contact")}
                  href={"https://spwn.zendesk.com/hc/ja"}
                />
              </li>
            )}
          </ul>

          <PCView>
            <LinkToSpwnPortal />
          </PCView>
        </div>

        {/* Footer下部のメニューレイアウト */}
        <div
          css={css`
            @media screen and (min-width: 768px) {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          `}
        >
          <ul
            css={css`
              @media screen and (min-width: 768px) {
                display: flex;
              }
              li {
                a {
                  @media screen and (min-width: 768px) {
                    color: #77797a;
                  }
                }
                &:not(:last-child) {
                  @media screen and (min-width: 768px) {
                    margin-right: 40px;
                  }
                }
              }
            `}
          >
            {footerMenuBelow.map((item, index) => {
              return (
                <li key={index}>
                  <LinkInTheUpperMenu label={item.name} href={item.link} />
                </li>
              );
            })}
          </ul>

          <p
            css={css`
              padding: 16px 0 40px;
              color: #77797a;
              font-size: 12px;
              text-align: center;
              @media screen and (min-width: 768px) {
                padding: 0 0 0;
              }
            `}
          >
            {changeStyleWithHosting().commonSettings.meta.copyright}
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;

const LinkToSpwnPortal: FC = () => {
  return (
    <div
      css={css`
        display: inline-block;
        border: 1px solid white;
      `}
    >
      <a
        href={createUrl({
          location: "https://live.balus.co/",
          searchParams: [],
        })}
        target="_blank"
        rel="noopener noreferrer"
        css={css`
          display: flex;
          align-items: center;
          color: white;
          font-size: 12px;
          padding: 8px 20px;
        `}
      >
        <div
          css={css`
            img {
              width: auto;
              height: 12px;
              margin-right: 8px;
            }
          `}
        >
          <SpwnPortalLogo />
        </div>
        <p>について</p>
        <div
          css={css`
            img {
              width: auto;
              height: 18px;
              margin-left: 8px;
            }
          `}
        >
          <ExternalOpenIcon />
        </div>
      </a>
    </div>
  );
};

const LinkInTheUpperMenu: FC<{
  label: string;
  href: string;
}> = ({ label, href }) => {
  return (
    <div
      css={css`
        border-bottom: 2px solid #121212;
        @media screen and (min-width: 768px) {
          border-bottom: none;
        }
        a {
          display: block;
          padding: 20px 5%;
          font-size: 12px;
          @media screen and (min-width: 768px) {
            padding: 0;
          }
        }
      `}
    >
      <TextLink href={href} color={"#f0f0f0"} textDecoration={"none"}>
        {label}
      </TextLink>
    </div>
  );
};
