import { createUrl } from "./createUrl";

export const createXShareLink = ({
  eventId,
  eventTitle,
  twitterHashTag,
}: {
  eventId: string;
  eventTitle: string;
  twitterHashTag: string[];
}): string => {
  const authDomain = window.location.hostname;
  // アカウントのIDに関しては以下のnotionを参照
  // @see https://www.notion.so/balusco/SNS-866f484f8aef433da94debff87338756
  const relatedAccount = "@SPWN_official";
  const shareURL = `https://${authDomain}/events/${eventId}`;

  const nonEmptyHashTags = twitterHashTag.filter((tag) => 0 < tag.length);
  if (nonEmptyHashTags.length <= 0) {
    return createUrl({
      location: "https://twitter.com/share",
      searchParams: [
        {
          key: "url",
          value: shareURL,
        },
        {
          key: "related",
          value: relatedAccount,
        },
        {
          key: "text",
          value: `「${eventTitle}」を応援しています！`,
        },
      ],
    });
  } else {
    const hashtags = twitterHashTag.join(",");
    return createUrl({
      location: "https://twitter.com/share",
      searchParams: [
        {
          key: "url",
          value: shareURL,
        },
        {
          key: "related",
          value: relatedAccount,
        },
        {
          key: "hashtags",
          value: hashtags,
        },
        {
          key: "text",
          value: `「${eventTitle}」を応援しています！`,
        },
      ],
    });
  }
};
