import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { takeEvery, put, select, call, take } from "@redux-saga/core/effects";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import "firebase/auth";
import { modalActions } from "../modules/modal";
import { loadingActions } from "../modules/loading";
import { purchaseActions, ReqUsePAW } from "../modules/purchase";
import { eventChannel } from "@redux-saga/core";
import { firestoreActions, isRegisteredChannel } from "../modules/firestore";
import { fetchFirestoreDocument } from "../utility/firebase";
import { fetchFirestoreCollection } from "utility/firebase";
import type { FlowerStandColor } from "@spwn/types";
import type {
  FlowerStandPurchaseState,
  PurchasedFlowerStand,
} from "@spwn/types/firebase/firestore";
import {
  FlowerStandItemList,
  FlowerStandItem,
  PawItem,
} from "@spwn/types/firebase/database";

const actionCreator = actionCreatorFactory("flowerStand");

/* eslint-disable @typescript-eslint/no-explicit-any */
export const flowerStandActions = {
  updateNum: actionCreator<void>("updateNum"),
  toggleCompleteStatus: actionCreator<void>("toggleCompleteStatus"),
  fetchFlowerStandList: actionCreator.async<ReqFetchFlowerStandList, any>(
    "fetchFlowerStandList"
  ),
  purchaseFlowerStand: actionCreator.async<any, any>("purchaseFlowerStand"),
  fetchPurchaseStatus: actionCreator.async<ReqFetchPurchaseStatus, any>(
    "fetchPurchaseStatus"
  ),
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export interface flowerStandState {
  number: number;
  flowerStandList: FlowerStandItemList | null;
  purchaseState: FlowerStandPurchaseState | null; // 今後未使用
  purchasedFlowerStandMap: Record<string, PurchasedFlowerStand>;
  completePurchase: boolean; // check if end purchasee process
}

export interface ReqPurchaseFlowerStand {
  isUpdateMode: boolean;
  item: PawItem;
  itemId: string;
  eventId: string;
  orderId: string;
  giftingName: string;
  giftColor: FlowerStandColor;
  giftPlaces: string[];
  uploadImageFile: Blob | Uint8Array | ArrayBuffer;
  generatedImage: Blob;
  count?: number;
  displayValue: number;
}

export interface ReqFetchPurchaseStatus {
  eventId: string;
}

export interface ReqFetchFlowerStandList {
  eventId: string;
}

const initialState: flowerStandState = {
  number: 0,
  flowerStandList: null,
  purchaseState: null, // 今後未使用
  // @ts-expect-error TS2322
  purchasedFlowerStandMap: null,
  completePurchase: false,
};

const flowerStandReducer = reducerWithInitialState(initialState)
  .case(flowerStandActions.updateNum, (state) => {
    return { ...state, number: ++state.number };
  })
  .case(flowerStandActions.toggleCompleteStatus, (state) => {
    return { ...state, completePurchase: !state.completePurchase };
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .case(flowerStandActions.fetchFlowerStandList.done, (state, payload: any) => {
    return { ...state, flowerStandList: payload };
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .case(flowerStandActions.fetchPurchaseStatus.done, (state, payload: any) => {
    return { ...state, purchasedFlowerStandMap: payload };
  });

export default flowerStandReducer;

export function* flowerStandSaga() {
  yield takeEvery(
    flowerStandActions.fetchFlowerStandList.started,
    fetchFlowerStandList
  );
  yield takeEvery(
    flowerStandActions.purchaseFlowerStand.started,
    purchaseFlowerStand
  );
  yield takeEvery(
    flowerStandActions.fetchPurchaseStatus.started,
    fetchPurchaseStatus
  );
}

function createPurchaseStateChannel(eventId: string) {
  // @ts-expect-error TS2531
  const userId = firebase.auth().currentUser.uid;
  return eventChannel((emitter) => {
    const unsubscribe = firebase
      .firestore()
      .collection(`/users/${userId}/events/${eventId}/purchasedFlowerStands`)
      .onSnapshot(
        (snapshot) => {
          let map = {};
          snapshot.forEach((doc) => {
            map = { ...map, [doc.id]: { ...doc.data(), _id: doc.id } };
          });
          emitter(map);
        },
        (error) => {
          console.error(error);
        }
      );
    return unsubscribe;
  });
}

function* fetchPurchaseStatus(action: { payload: ReqFetchPurchaseStatus }) {
  const { auth } = yield select();
  if (Object.keys(auth.user).length === 0) {
    return;
  }
  const {
    firestore: { channels },
  } = yield select();
  if (isRegisteredChannel(channels, "flowerStandPurchaseState")) return;
  const { eventId } = action.payload;

  try {
    // @ts-expect-error TS7057
    const channel = yield call(createPurchaseStateChannel, eventId);
    while (true) {
      // @ts-expect-error TS7057
      const purchaseState = yield take(channel);
      yield put(flowerStandActions.fetchPurchaseStatus.done(purchaseState));
      yield put(
        firestoreActions.addChannel({
          ...channel,
          name: "flowerStandPurchaseState",
        })
      );
    }
  } catch (e) {
    console.error(e);
  }
}

function* purchaseFlowerStand(action: { payload: ReqPurchaseFlowerStand }) {
  try {
    // @ts-expect-error TS2339
    const { uid } = firebase.auth().currentUser;
    const {
      isUpdateMode,
      item,
      itemId,
      eventId,
      orderId,
      giftingName,
      uploadImageFile,
      generatedImage,
      count,
      giftColor,
      giftPlaces,
    } = action.payload;

    const updatedAt = new Date();
    const targetOrderId = orderId ? orderId : updatedAt.getTime().toString();
    let giftImgPath = "";
    let generatedImgPath = "";

    if (item.addCustomImg && uploadImageFile !== null) {
      yield put(loadingActions.toggleLoading({ msg: "画像を登録しています" }));
      giftImgPath = `/users/${uid}/FlowerStand/${eventId}/${itemId}/${targetOrderId}`;
      yield firebase.storage().ref(giftImgPath).put(uploadImageFile);
      yield put(loadingActions.toggleLoading({}));
    }
    if (generatedImage !== null) {
      yield put(loadingActions.toggleLoading({ msg: "画像を登録しています" }));
      generatedImgPath = `/users/${uid}/FlowerStand/${eventId}/${itemId}/${targetOrderId}.gen.png`;
      yield firebase.storage().ref(generatedImgPath).put(generatedImage);
      yield put(loadingActions.toggleLoading({}));
    }

    const loadingMsg = isUpdateMode
      ? "登録情報を更新しています"
      : "購入しています";
    yield put(loadingActions.toggleLoading({ msg: loadingMsg }));

    // update registration info (giftName, giftImg, color) / can't change place
    const ref = `/users/${uid}/events/${eventId}/purchasedFlowerStands/${itemId}`;
    // @ts-expect-error TS7057
    const addTarget = yield fetchFirestoreDocument(ref);
    let data;
    const addCount = !count ? 1 : count;
    if (!addTarget) {
      // create new document
      data = {
        uid,
        itemId,
        eid: eventId,
        totalCount: addCount,
        orders: {
          [targetOrderId]: {
            giftName: giftingName,
            giftColor,
            giftPlaces,
            giftImgPath,
            generatedImgPath,
            count: addCount,
          },
        },
        createdAt: updatedAt,
        updatedAt,
      };
    } else {
      if (addTarget.orders[targetOrderId]) {
        // update color or name
        data = {
          orders: {
            ...addTarget.orders,
            [targetOrderId]: {
              ...addTarget.orders[targetOrderId],
              giftName: giftingName,
              giftColor,
            },
          },
          updatedAt,
        };
      } else {
        // create new order
        data = {
          totalCount: firebase.firestore.FieldValue.increment(addCount),
          orders: {
            ...addTarget.orders,
            [targetOrderId]: {
              giftName: giftingName,
              giftColor,
              giftPlaces,
              giftImgPath,
              generatedImgPath,
              count: addCount,
            },
          },
          updatedAt,
        };
      }
    }
    yield firebase.firestore().doc(ref).set(data, { merge: true });
    yield put(loadingActions.toggleLoading({}));

    if (isUpdateMode) return;

    const msg: ReqUsePAW = {
      eid: eventId,
      itemId,
      type: item.type,
      orderId: targetOrderId,
      freePrice: item.values.free,
      paidPrice: item.values.paid,
      count: !count ? 1 : count,
      giftPlaces,
      displayValue: action.payload.displayValue,
    };

    yield put(purchaseActions.usePAW.started(msg));

    yield put(flowerStandActions.toggleCompleteStatus());
  } catch (e) {
    console.error(e);
    yield put(loadingActions.toggleLoading({}));
    yield put(
      modalActions.toggleError({ msg: "フラワースタンドの購入に失敗しました" })
    );
  } finally {
    // yield put(loadingActions.toggleLoading({}))
  }
}

function* fetchFlowerStandList(action: { payload: ReqFetchFlowerStandList }) {
  try {
    let flowerStandMap:
      | FlowerStandItemList
      | { [key: string]: FlowerStandItem & { imgUrl: string } };
    const { eventId } = action.payload;

    // realtimeDBに格納されているアイテムの情報を取得する
    // @ts-expect-error TS7057
    const itemsRef = yield firebase
      .database()
      .ref("items")
      .orderByChild("display")
      .equalTo(true)
      .once("value");
    const itemsMap: FlowerStandItemList = {
      ...itemsRef.val(),
      _id: itemsRef.key,
    };

    // realtimeDBに格納されているアイテムからフラスタの情報を取得する
    let defaultFlowerStandMap: FlowerStandItemList;
    Object.entries(itemsMap)
      .filter(([_key, val]) => {
        return val.type === "flowerStand";
      })
      .sort(
        ([_key, val], [_ckey, cval]) => Number(val.order) - Number(cval.order)
      )
      .forEach(([key, val]) => {
        defaultFlowerStandMap = {
          ...defaultFlowerStandMap,
          [key]: { ...val, _id: key },
        };
      });

    // オリジナルで設定されたフラスタの情報を取得する
    // @ts-expect-error TS7057
    const originalFlowerStandList = yield fetchFirestoreCollection(
      `/events/${eventId}/flowerStands`
    );

    // オリジナルのフラスタが設定されていた場合、設定されている情報でフラスタリストを作成する
    if (originalFlowerStandList.length > 0) {
      originalFlowerStandList
        // @ts-expect-error TS7006
        .filter((el) => el.display)
        // @ts-expect-error TS7006
        .sort((a, b) => a.priority - b.priority)
        // @ts-expect-error TS7006
        .forEach((flowerStand) => {
          flowerStandMap = {
            ...flowerStandMap,
            [flowerStand._id]: {
              ...defaultFlowerStandMap[flowerStand.itemId],
              imgUrl: flowerStand.imgUrl,
            },
          };
        });
    } else {
      // @ts-expect-error TS2454
      flowerStandMap = defaultFlowerStandMap;
    }
    yield put(
      // @ts-expect-error TS2454
      flowerStandActions.fetchFlowerStandList.done(flowerStandMap as any) // eslint-disable-line @typescript-eslint/no-explicit-any
    );
  } catch (e) {
    //TODO: Add error handling
    const hasMessage = (e: unknown): e is { message: string } =>
      // @ts-expect-error TS18047
      typeof e === "object" && "message" in e;

    console.error(hasMessage(e) && e.message);
    yield put(
      modalActions.toggleError({
        msg: "フラワースタンドリストの読み込みに失敗しました",
      })
    );
  }
}
