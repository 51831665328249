/** @jsxRuntime classic /
/** @jsx jsx */
import React, { useCallback, useMemo, useState } from "react";
import { css, jsx } from "@emotion/core";
import firebase from "firebase/app";
import "firebase/auth";
import { FullScreenDialog } from "components/common/Modal";
import { DialogStyles } from "components/Styled";
import { APPLE_PROVIDER_ID } from "constants/firebase";
import { PopupWindow, PopupWindowButtonList, LinkButton } from "../../styles";
import GoogleLoginButton from "components/GoogleLoginButton";
import AppleLoginButton from "components/AppleLoginButton";
import FacebookLoginButton from "components/FacebookLoginButton";
import { SquareLoader } from "components/atoms/loader/SquareLoader";
import { useI18n } from "hooks/i18n/i18n";
import * as Sentry from "@sentry/browser";

/**
 * ポップアップウィンドウでのログインを提供する
 * - ログインができない端末に対しての救済措置。
 * - @see [Background](https://github.com/balus-co-ltd/spwn/issues/2502)
 */
export const ModalFallbackLogin = ({
  open,
  onClose,
  parentOnClose,
}: {
  open: boolean;
  onClose: () => void;
  parentOnClose: () => void;
}): JSX.Element => {
  const { t } = useI18n();
  const [loading, setLoading] = useState(false);

  const loginWithPopup = useCallback(
    (provider: firebase.auth.AuthProvider) => async () => {
      try {
        setLoading(true);
        const _result = await firebase.auth().signInWithPopup(provider);
        window.alert(`ログインしました`);
        onClose();
        parentOnClose();
      } catch (err) {
        Sentry.captureException(err, {
          tags: {
            /**
             * どこからのエラーか分かるようにする
             * FIXME sentryClient作ってtagの命名絞ってもいい
             */
            component: "ModalFallbackLogin",
          },
        });
        if (err instanceof Error) {
          /**
           * エラーメッセージをそのまま載せてしまう
           * - sentry上限で送られない場合もあり、エラーあったらエラーメッセージ付きで問い合わせてもらえると助かる
           * - SNSログインのみなので、表示されてはいけないエラーメッセージは出なさそう
           *   - メールアドレス&パスワードだと何回も試行されると困る場合はありだが。
           */
          window.alert(`エラーが発生しました。\n ${err.message}`);
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading, onClose, parentOnClose]
  );

  const signInWithPopupGoogle = useMemo(() => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return loginWithPopup(provider);
  }, [loginWithPopup]);

  const signInWithPopupApple = useMemo(() => {
    // ref: https://github.com/balus-co-ltd/spwn/blob/5da33455b1148267f269e9ef188d706f87765953/packages/portal/src/components/AppleLoginButton.tsx#L29-L31
    const provider = new firebase.auth.OAuthProvider(APPLE_PROVIDER_ID);
    provider.addScope("email");
    provider.addScope("name");
    return loginWithPopup(provider);
  }, [loginWithPopup]);

  const signInWithPopupFacebook = useMemo(() => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return loginWithPopup(provider);
  }, [loginWithPopup]);

  return (
    <FullScreenDialog
      style={DialogStyles}
      open={open}
      onBackdropClick={onClose}
    >
      <PopupWindow>
        <div className="popup_window_layout">
          <h2>{"LOGIN"}</h2>
          {t("modal.fallbackLogin.tryLogin")}
          {loading ? (
            <div
              css={css`
                margin-top: 3rem;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                min-height: 200px;
              `}
            >
              <p>{t("modal.fallbackLogin.loading")}</p>
              <SquareLoader />
            </div>
          ) : (
            <PopupWindowButtonList>
              <GoogleLoginButton
                isSignup={false}
                onClick={signInWithPopupGoogle}
              />
              <AppleLoginButton
                isSignup={false}
                onClick={signInWithPopupApple}
              />
              <FacebookLoginButton
                isSignup={false}
                onClick={signInWithPopupFacebook}
              />
              <LinkButton className="cancel" onClick={onClose}>
                {t("modal.actionModal.cancel")}
              </LinkButton>
            </PopupWindowButtonList>
          )}
        </div>
      </PopupWindow>
    </FullScreenDialog>
  );
};
