/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import React from "react";
import { useSelector } from "react-redux";
import { Store } from "../../store";
import "firebase/firestore";
import "firebase/auth";

import { EventInfo } from "../../utility";
import { GiftItemData } from "../../modules/streaming";
import { authState } from "modules/auth";
import type { UserMessage } from "@spwn/types/firebase/firestore";

import Popper, { PopperPlacementType } from "@material-ui/core/Popper";

interface Props {
  event: EventInfo;
  superChatList: UserMessage[];
}

export const StreamingDisplaySuperChat: React.FC<Props> = (props) => {
  // const classes = superChatStyle()
  const user = useSelector((state: Store) => state.auth.user);
  const giftItemMap = useSelector(
    (state: Store) => state.streaming.giftItemMap
  );

  /**
   * イベントのgiftItemMapから、商品の値段リストを作成する
   */
  let giftItemAmountList: number[] = [];
  if (giftItemMap) {
    Object.keys(giftItemMap).forEach((key, _index) => {
      // TODO: mapに変えたい
      // @ts-expect-error TS2532
      giftItemAmountList.push(giftItemMap[key].values.paid);
    });
    giftItemAmountList = giftItemAmountList
      .sort((a, b) => {
        return b - a;
      })
      .filter((x, i, self) => {
        return self.indexOf(x) === i;
      });
  }

  return (
    <React.Fragment>
      {props.superChatList ? (
        giftItemAmountList.map((amount, index) => {
          const filteringSuperChatList = props.superChatList
            .filter((superChat) => superChat.amount === amount)
            .slice()
            .reverse();

          if (filteringSuperChatList.length === 0) {
            return null;
          }

          return (
            <React.Fragment key={index}>
              <RenderingSuperChatArea
                user={user}
                giftItemMap={giftItemMap}
                filteringSuperChatList={filteringSuperChatList}
              />
            </React.Fragment>
          );
        })
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};

interface RenderingSuperChatAreaProps {
  user: authState["user"];
  giftItemMap: { [key: string]: GiftItemData };
  filteringSuperChatList: UserMessage[];
}
const RenderingSuperChatArea: React.FC<RenderingSuperChatAreaProps> = (
  props
) => {
  const classes = superChatStyle();
  const classesPopperStyle = popperDesign();

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [userComment, setUserComment] = React.useState<UserMessage>();

  const [popperTextColor, setPopperTextColor] =
    React.useState<string>("#ffffff");

  const handleClick =
    (newPlacement: PopperPlacementType, comment: UserMessage) =>
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
      setUserComment(comment);

      // @ts-expect-error TS2538
      const textColor = props.giftItemMap[comment.giftItemId]?.textColor;
      if (textColor) setPopperTextColor(textColor);
    };

  return (
    <div id="superChatList" css={classes.superChatArea}>
      {props.filteringSuperChatList.map((comment, index) => {
        const { amount, color, icon, /* msg, */ name, giftItemId } = comment;
        // @ts-expect-error TS2538
        const backgroundImageUrl = props.giftItemMap[giftItemId]?.bgImgUrl;
        // @ts-expect-error TS2538
        const textColor = props.giftItemMap[giftItemId]?.textColor;
        const superChatStyle = classes.superChat(
          amount,
          color,
          backgroundImageUrl,
          textColor
        );

        if (comment.isBan === true && comment.uid !== props.user.uid) {
          return null;
        }

        return (
          <React.Fragment key={index}>
            <div
              id={`popper${index}`}
              css={superChatStyle.superChatLayout}
              onClick={handleClick("bottom-start", comment)}
            >
              <div css={superChatStyle.superChatLayoutInner}>
                <img src={icon} alt={name} />
                <p>{name}</p>
              </div>
            </div>
          </React.Fragment>
        );
      })}

      {userComment ? (
        <Popper
          css={classesPopperStyle.popper}
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          <div css={classesPopperStyle.root}>
            <div
              css={
                classesPopperStyle.superChatColor(
                  userComment.color,
                  popperTextColor
                ).upperSide
              }
            >
              <div css={classesPopperStyle.icon}>
                <img src={userComment.icon} alt="" />
              </div>
              <div
                css={
                  classesPopperStyle.superChatColor(
                    userComment.color,
                    popperTextColor
                  ).info
                }
              >
                <p className="name">{userComment.name}</p>
                <p className="amount">{userComment.amount.toLocaleString()}</p>
              </div>
            </div>

            <div
              css={
                classesPopperStyle.superChatColor(
                  userComment.color,
                  popperTextColor
                ).lowerSide
              }
            >
              {userComment.msg}
            </div>
          </div>
        </Popper>
      ) : null}
    </div>
  );
};

type StyleProps = {};
const superChatStyle = (_props?: StyleProps) => {
  return {
    superChatArea: css`
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 0 auto;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    `,
    superChat: (
      amount: number,
      color: string,
      backgroundImageUrl: string,
      textColor: string
    ) => {
      let changeLayout: string;
      if (amount < 1000) {
        changeLayout = `width: 41px;`;
      } else if (1000 <= amount && amount < 3000) {
        changeLayout = `width: 24%;`;
      } else if (3000 <= amount && amount < 5000) {
        changeLayout = `width: 32.33333%;`;
      } else if (5000 <= amount && amount < 10000) {
        changeLayout = `width: 49%;`;
      } else if (10000 <= amount) {
        changeLayout = `width: 100%;`;
      }

      const url = backgroundImageUrl
        ? "url(" + backgroundImageUrl + ")"
        : "none";

      const textColorCode = textColor ? textColor : "#ffffff";

      return {
        superChatLayout: css`
          padding: 8px 0;
          margin: 0 0.5% 10px;
          background-color: ${color};
          background-image: ${url};
          background-position: center;
          background-size: cover;
          border-radius: 3px;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
          ${
            // @ts-expect-error TS2454
            changeLayout
          }
        `,
        superChatLayoutInner: css`
          display: flex;
          align-items: center;
          width: calc(100% - 16px);
          margin: 0 auto;
          overflow: hidden;
          img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
            background-color: #fff;
            border-radius: 50%;
          }
          p {
            white-space: nowrap;
            color: ${textColorCode};
            font-size: 12px;
          }
        `,
      };
    },
  };
};

interface PopperDesignProps {}
const popperDesign = (_props?: PopperDesignProps) => {
  return {
    superChatColor: (color: string, textColor: string) => {
      const textColorCode = textColor ? textColor : "#ffffff";
      return {
        upperSide: css`
          display: flex;
          align-items: center;
          padding: 8px 16px;
          background-color: ${color};
          border-radius: 3px 3px 0 0;
        `,
        lowerSide: css`
          padding: 8px 16px;
          color: ${textColorCode};
          font-size: 14px;
          background-color: ${color}d0;
          border-radius: 0 0 3px 3px;
        `,
        info: css`
          width: calc(100% - 60px);
          .name {
            margin-bottom: 5px;
            color: ${textColorCode};
            font-size: 14px;
          }
          .amount {
            color: ${textColorCode};
            font-size: 14px;
          }
        `,
      };
    },
    popper: css`
      top: 5px !important;
      border: 1px solid #eeeeee;
      border-radius: 5px;
    `,
    root: css`
      width: 300px;
      background-color: #fff;
      border-radius: 3px;
    `,
    icon: css`
      margin-right: 20px;
      img {
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 50%;
        object-fit: cover;
      }
    `,
  };
};
