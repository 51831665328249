/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { TIMETABLE_CELL_HEIGHT } from "../../../../containers/event/eventDetail/EventDetailTimetable";
import { EventTimetableIcon } from "./EventTimetableIcon";
import type { Video } from "@spwn/types";

interface Props {
  video: Video;
  videoTime: number;
  afterAt: number;
  setRenderEvent: () => void;
  doButtonAction: () => void;
}
export const EventTimetableContentsShortItem: React.FC<Props> = (props) => {
  const classes = styles({
    afterAt: props.afterAt,
    videoTime: props.videoTime,
  });

  return (
    <div css={classes.root} onClick={props.setRenderEvent}>
      <div css={classes.thumbnail}>
        <img src={props.video.thumbnail} alt={props.video.name} />
      </div>

      <div css={classes.info}>
        <div className={"layout"}>
          <h4>{props.video.name}</h4>
        </div>
      </div>

      <div
        css={classes.cart}
        className={"cart"}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <EventTimetableIcon
          status={props.video.actionStatus}
          doButtonAction={props.doButtonAction}
        />
      </div>
    </div>
  );
};

/**
 * グリッド1メモリ10分なので、イベントの開始から何分後かで計算して、afterAtを変更する
 */
interface stylesProps {
  afterAt: number;
  videoTime: number;
}
const styles = (props: stylesProps) => {
  // 20分以上30分未満の表示の差を計算する。
  // 算出した差は半分にして上下のpaddingに加える
  // calcDisplayDifference = (1セル(10分)から1分の高さ) * (閾値20分からの差)
  const calcDisplayDifference =
    (TIMETABLE_CELL_HEIGHT / 10) * (props.videoTime - 20);

  return {
    root: css`
      position: absolute;
      /* デザインの関係でグリッドラインを1行追加しているので、その分を+1している。 */
      /* 1h毎に1pxずれるため、その分をプラスする */
      top: calc(
        (${TIMETABLE_CELL_HEIGHT}px * (${props.afterAt} + 1)) +
          (1px * ${props.afterAt} / 6)
      );
      left: 50%;
      display: grid;
      grid-template-columns: 48px auto 24px;
      grid-gap: 8px;
      width: calc(95% - 16px);
      padding: calc(13px + ${calcDisplayDifference}px / 2) 8px;
      background-color: #fff;
      border-radius: 8px;
      transform: translateX(-50%);
      box-sizing: content-box;
      overflow: hidden;
      cursor: pointer;
    `,
    thumbnail: css`
      width: 40px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
    `,
    /* stylelint-disable */
    info: css`
      display: flex;
      align-items: center;
      .layout {
        letter-spacing: 1px;
        h4 {
          display: box;
          height: 1.1em;
          font-size: 15px;
          font-weight: bold;
          box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 1;
        }
      }
    `,
    /* stylelint-enable */
    cart: css`
      display: flex;
      align-items: center;
      svg {
        fill: #a1a1a1;
        cursor: pointer;
      }
    `,
  };
};
