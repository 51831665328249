/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { purchaseState } from "modules/purchase";
import { ActionModalType } from "modules/modal";

import { useI18n } from "hooks/i18n/i18n";

import { EventInfo } from "utility/index";
import { getDateTimeString } from "utility";
import { getDisplayPrice, getVenuePayPrice } from "utility/ticket";

import { Button } from "components/atoms/button/Button";
import type {
  MyEventTicketData,
  IssuedTicket,
  ProductData,
} from "@spwn/types/firebase/firestore";
import type { ValueOf } from "@spwn/types/common";

interface Props {
  eventId: string;
  dispInfo: EventInfo;
  eventTicketData: MyEventTicketData;
  activeTransaction: ValueOf<purchaseState["activeTransactionMap"]>[];
  eventGoodsMap: {
    [goodsId: string]: ProductData;
  };
  getRelatedIssuedGoods: (eventId: string, orderId: number) => IssuedTicket;
  openActionModal: (
    modalType: ActionModalType,
    ticketNum: string,
    issuedTicket: IssuedTicket | null
  ) => void;
}
/**
 * デジタルチケット グッズ表示
 * FIXME event はpropsに渡される時点でfilterされていて欲しい
 */
export const GoodsTicket: React.FC<Props> = (props) => {
  const { t } = useI18n();
  const classes = styles();

  if (
    !props.eventTicketData.goods ||
    props.eventTicketData.goods.length === 0
  ) {
    return null;
  }

  return (
    <div css={classes.root}>
      <div css={classes.headline}>
        <h2>{t("ticketDetail.goods.title")}</h2>
        <p>{t("ticketDetail.goods.subTitle")}</p>
      </div>

      {props.eventGoodsMap &&
        props.activeTransaction?.length > 0 &&
        props.activeTransaction.map((transaction, index) => {
          if (transaction.status === "UNPROCESS") {
            //決済待ちは表示しない
            return null;
          }

          const { orderId } = transaction;

          // @ts-expect-error TS18048
          const orderGoods = transaction.products.filter(
            (el) => el.eid === props.eventId && el.productType === "goods"
          );

          const relatedTicket = props.getRelatedIssuedGoods(
            props.eventId,
            // @ts-expect-error TS2345
            orderId
          );
          const isRedeemed = !!relatedTicket && relatedTicket.isRedeemed;
          const ticketNum = !relatedTicket ? "" : relatedTicket.ticketNum;

          if (!orderGoods) {
            return null;
          }

          // check if venue pay goods are present
          const hasVenuePay =
            orderGoods.findIndex((goods) => getVenuePayPrice(goods) > 0) >= 0;
          // check if user have payed any goods
          const hasPayedVenueGoods =
            orderGoods.findIndex(
              (goods) =>
                !goods.isAddressRequired &&
                !goods.isAddressRequiredNoFee &&
                getVenuePayPrice(goods) === 0
            ) >= 0;

          return (
            <div css={classes.item} key={index}>
              <p css={classes.orderId}>注文番号：{orderId}</p>

              {orderGoods.map((el) => {
                const goodsNum = el.count;
                const price = getDisplayPrice(el);
                return (
                  <div key={index} css={classes.layout}>
                    <div css={classes.info}>
                      <p className="name">
                        {el.className}{" "}
                        {el.subClassName && `(${el.subClassName})`}
                      </p>
                      <p className="price">¥{price.toLocaleString()}</p>
                    </div>
                    <p css={classes.number}>
                      <span>×</span>
                      {goodsNum}
                    </p>
                  </div>
                );
              })}
              {/* 会場グッズあり・引き換え済み */}
              {hasPayedVenueGoods && isRedeemed && (
                <React.Fragment>
                  <p css={classes.checkinText}>
                    {t("ticketDetail.goods.goodsRedeemedDate", {
                      date: getDateTimeString(
                        // @ts-expect-error TS18048
                        new Date(relatedTicket.redeemedDateTime.seconds * 1000)
                      ),
                    })}
                  </p>
                  <div css={classes.button}>
                    <Button
                      text={t("ticketDetail.goods.goodsRedeemed")}
                      disabled={true}
                    />
                  </div>
                </React.Fragment>
              )}
              {/* 会場グッズあり・引き換え前 */}
              {hasPayedVenueGoods && !isRedeemed && (
                <div css={classes.button}>
                  <Button
                    text={t("ticketDetail.goods.goodsRedeemOnlyStaff")}
                    color={"#fff"}
                    textColor={"#ff9b35"}
                    onClick={() =>
                      props.openActionModal(
                        "goodsCheckIn",
                        // @ts-expect-error TS2345
                        ticketNum,
                        relatedTicket
                      )
                    }
                  />
                </div>
              )}
              {hasVenuePay && (
                <div css={classes.button}>
                  <Button
                    text={"オーダーシート引換"}
                    color={"#00c2ae"}
                    textColor={"#fff"}
                    onClick={() =>
                      props.openActionModal(
                        "goodsOrderSheetCheckIn",
                        // @ts-expect-error TS2345
                        ticketNum,
                        relatedTicket
                      )
                    }
                  />
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      margin-bottom: 60px;
    `,
    headline: css`
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
      }
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
      }
      h2 {
        font-family: "din-condensed", sans-serif;
        font-weight: 400;
        font-style: normal;
        padding: 5px 0 0 15px;
        margin-bottom: 5px;
        font-size: 35px;
        border-left: 5px solid #01ffe1;
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
          letter-spacing: 3px;
        }
      }
      p {
        padding-left: 21px;
        font-size: 12px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    `,
    item: css`
      width: 100%;
      max-width: 740px;
      padding: 5%;
      margin: 0 auto 24px;
      color: #fff;
      border: 1px solid #d4d4d4;
      background-color: #ff8e2d;
      @media screen and (min-width: 768px) {
        padding: 20px;
      }
    `,
    orderId: css`
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1px;
    `,
    layout: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #fff;
      @media screen and (min-width: 768px) {
        padding-bottom: 15px;
        margin-bottom: 15px;
      }
    `,
    info: css`
      width: calc(100% - 1.5em);
      .name {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 700;
        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }
      .price {
        font-size: 14px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    `,
    number: css`
      width: 1.5em;
      font-family: "din-condensed", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 32px;
      text-align: right;
      span {
        font-size: 25px;
      }
    `,
    button: css`
      width: 100%;
      max-width: 460px;
      margin: 0 auto;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    `,
    checkinText: css`
      margin-bottom: 10px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.3em;
      @media screen and (min-width: 768px) {
        text-align: center;
      }
    `,
  };
};
