import { changeStyleWithHosting } from "./hosting";

export const setMeta = () => {
  // @ts-expect-error TS2322
  const shortcutIcon: HTMLLinkElement = document.querySelector(
    'link[rel="shortcut icon"]'
  );
  // @ts-expect-error TS2322
  const appleTouchIcon: HTMLLinkElement = document.querySelector(
    'link[rel="apple-touch-icon"]'
  );

  const { title } = changeStyleWithHosting().commonSettings.meta;
  const faviconUrl = changeStyleWithHosting().commonSettings.meta.favicon;
  const appleTouchIconUrl =
    changeStyleWithHosting().commonSettings.meta.appleTouchIcon;
  const { description } = changeStyleWithHosting().commonSettings.meta;

  if (title) {
    document.title = title;
  }
  if (faviconUrl) {
    shortcutIcon.href = faviconUrl;
    // @ts-expect-error TS2322
    appleTouchIcon.href = appleTouchIconUrl;
  }
  if (description) {
    // @ts-expect-error TS2531
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description);
  }
};
