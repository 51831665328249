import { useCallback, useEffect, useMemo, useState } from "react";

const CAPSULE_ANIMATION_01 =
  "https://public.spwn.jp/spwn-store/capsule/capsule_01.gif";
const CAPSULE_ANIMATION_02 =
  "https://public.spwn.jp/spwn-store/capsule/capsule_02.gif";
const CAPSULE_ANIMATION_03 =
  "https://public.spwn.jp/spwn-store/capsule/capsule_03.gif";

/**
 * カプセルのアニメーション画像を返す
 *
 * 画像の読み込みが遅れて表示が崩れる場合があるので、画像をpreloadする
 */
export const useCapsuleAnimationImages = (): {
  animationImages: {
    start: string;
    capsuleOpen: string;
    resultLoop: string;
  };
  refreshImages: () => void;
} => {
  const [timestamp, setTimestamp] = useState<number>(Date.now());

  const animationImages = useMemo(
    () => ({
      start: `${CAPSULE_ANIMATION_01}?${timestamp}`,
      capsuleOpen: `${CAPSULE_ANIMATION_02}?${timestamp}`,
      resultLoop: `${CAPSULE_ANIMATION_03}?${timestamp}`,
    }),
    [timestamp]
  );

  const refreshImages = useCallback(() => {
    setTimestamp(Date.now());
  }, []);

  // カプセルアニメーションの初期表示不具合に対処するためimageファイルをpreloadしておく
  useEffect(() => {
    // 決済エラー後、gifが止まった状態で表示されてしまう不具合への対処
    preloadImage(animationImages.start);
    preloadImage(animationImages.capsuleOpen);
    preloadImage(animationImages.resultLoop);
  }, [animationImages]);

  return {
    animationImages,
    refreshImages,
  };
};

const preloadImage = async (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
  });
};
