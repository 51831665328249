import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../modules/auth";
import styled from "@emotion/styled";

const Container = styled.div`
  margin: 0 auto;
  .containerForLayout {
    max-width: 980px;
    width: 100%;
    min-height: calc(100vh - 59px - 497px);
    padding: 60px 0 100px;
    margin: 0 auto;
    background-color: #fff;
    @media screen and (min-width: 768px) {
      min-height: calc(100vh - 65px - 190px);
    }
    h2 {
      margin-bottom: 40px;
      font-size: 20px;
      text-align: center;
    }
    .resetFormLayout {
      width: 90%;
      margin: 0 auto 40px;
      @media screen and (min-width: 768px) {
        width: 500px;
      }
      h3 {
        margin-bottom: 20px;
      }
      .resetForm {
        width: 100%;
        padding: 10px 10px;
        font-size: 16px;
        border: 1px solid #dedede;
      }
    }
    .buttonForLink {
      display: block;
      width: 90%;
      padding: 12px 0;
      margin: 0 auto;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      line-height: 1.2em;
      letter-spacing: 1px;
      background-color: #00c2ae;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        width: 500px;
        padding: 15px 0;
        letter-spacing: 2px;
      }
      &:hover {
        background-color: #00afa8;
      }
    }
  }
`;

interface Props {}

const ResetPassword: React.FC<Props> = (_props) => {
  const [inputText, setInputText] = useState(null);
  const dispatch = useDispatch();

  const sendPasswordResetMail = () => {
    dispatch(
      authActions.resetPassword.started({
        email: inputText,
      })
    );
  };

  return (
    <Container id="contents">
      <div className="containerForLayout">
        <h2>パスワードをリセットする</h2>

        <div className="resetFormLayout">
          <h3 className="heading">E-mail</h3>
          <input
            type="text"
            name="email"
            id="email"
            className="resetForm"
            onChange={(e) => {
              // @ts-expect-error TS2345
              setInputText(e.currentTarget.value);
            }}
          />
        </div>

        <div className="buttonForLink" onClick={sendPasswordResetMail}>
          パスワードの再設定メールを送信する
        </div>
      </div>
    </Container>
  );
};
export default ResetPassword;
