/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useI18n } from "hooks/i18n/i18n";
import { TextLink } from "ui/TextLink";

// コードの正規表現
const codeRe = /^[0-9A-Z]+-[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]$/i;

interface Props {
  queryParam: string[];
  onSubmit: (values: string[]) => void;
}

const VoucherCode: React.FC<Props> = (props) => {
  const design = styles();
  const { onSubmit, queryParam } = props;

  const [isFormDisabled, setIsFormDisabled] = React.useState<boolean>(true);
  const [inputValue, setInputValue] = React.useState<
    {
      [input in
        | "input01"
        | "input02"
        | "input03"
        | "input04"
        | "input05"]: string;
    }
  >({
    input01: "",
    input02: "",
    input03: "",
    input04: "",
    input05: "",
  });
  const [errorText, setErrorText] = React.useState<string>("");
  const { t } = useI18n();

  const setQueryParam = () => {
    if (queryParam) {
      setInputValue({
        input01: queryParam[0] ?? "",
        input02: queryParam[1] ?? "",
        input03: queryParam[2] ?? "",
        input04: queryParam[3] ?? "",
        input05: queryParam[4] ?? "",
      });
    }
  };
  useEffect(setQueryParam, [queryParam]);

  const watchInputValue = () => {
    if (
      !inputValue.hasOwnProperty("input01") &&
      !inputValue.hasOwnProperty("input02") &&
      !inputValue.hasOwnProperty("input03") &&
      !inputValue.hasOwnProperty("input04") &&
      !inputValue.hasOwnProperty("input05")
    ) {
      return;
    }
    setIsFormDisabled(false);
  };
  useEffect(watchInputValue, [inputValue]);

  const handleChange = (
    inputId: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // input01にコードがすべてペーストされた場合には、それぞれのinputに分割して入力する
    if (inputId === "input01") {
      const code = e.target.value.trim().split("-");
      if (codeRe.test(e.target.value.trim()) && code.every(Boolean)) {
        setInputValue({
          input01: code[0] ?? "",
          input02: code[1] ?? "",
          input03: code[2] ?? "",
          input04: code[3] ?? "",
          input05: code[4] ?? "",
        });
        return;
      }
    }

    setInputValue({
      ...inputValue,
      [inputId]: e.target.value,
    });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const voucherCode = `${inputValue.input01}-${inputValue.input02}-${inputValue.input03}-${inputValue.input04}-${inputValue.input05}`;
    const checkDigit =
      encodeURI(
        `${inputValue.input01}-${inputValue.input02}-${inputValue.input03}-${inputValue.input04}-`
      )
        .split("")
        .map((c, idx) => c.charCodeAt(0) * ((idx % 2) + 1))
        .reduce((a, b) => a + b, 0) % 10;
    if (checkDigit !== Number(inputValue.input05)) {
      setErrorText(t("purchase.voucher.inputError"));
      return;
    }
    onSubmit([voucherCode]);
  };

  return (
    <React.Fragment>
      <h2 css={design.title}>{t("purchase.voucher.inputMsg")}</h2>
      {errorText && (
        <Alert severity="error" css={design.alert}>
          <AlertTitle>{t("purchase.voucher.invalidVoucher")}</AlertTitle>
          {errorText}
        </Alert>
      )}

      <div css={design.form}>
        <div css={design.fromItem}>
          <input
            id="input_01"
            type="text"
            placeholder="XXXXXXXX"
            value={inputValue.input01}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange("input01", e)
            }
          />
          <span>-</span>
          <input
            id="input_02"
            type="text"
            placeholder="XXXX"
            value={inputValue.input02}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange("input02", e)
            }
          />
          <span>-</span>
          <input
            id="input_03"
            type="text"
            placeholder="XXXX"
            value={inputValue.input03}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange("input03", e)
            }
          />
          <span>-</span>
          <input
            id="input_04"
            type="text"
            placeholder="XXXX"
            value={inputValue.input04}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange("input04", e)
            }
          />
          <span>-</span>
          <input
            id="input_05"
            type="text"
            placeholder="X"
            value={inputValue.input05}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange("input05", e)
            }
          />
        </div>

        <div css={design.buttonArea}>
          <button
            css={design.submitBtn}
            type="submit"
            disabled={isFormDisabled}
            onClick={handleSubmit}
          >
            {t("purchase.voucher.next")}
          </button>
        </div>
      </div>

      <div css={design.announcement}>
        <h3>バウチャーコードの入力方法は下記リンクよりご確認いただけます。</h3>
        <p>
          <TextLink
            href={
              "https://spwn.zendesk.com/hc/ja/articles/900001775983-%E3%83%90%E3%82%A6%E3%83%81%E3%83%A3%E3%83%BC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%AE%E5%85%A5%E5%8A%9B%E6%96%B9%E6%B3%95%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84"
            }
          >
            バウチャーコードの入力方法を教えてください
          </TextLink>
        </p>
      </div>
    </React.Fragment>
  );
};

const styles = () => {
  return {
    title: css`
      margin-bottom: 40px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    `,
    alert: css`
      width: 100%;
      max-width: 570px;
      margin: 0 auto;
    `,
    form: css`
      margin-top: 40px;
    `,
    fromItem: css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      input {
        display: block;
        padding: 10px;
        margin-bottom: 16px;
        font-size: 16px;
        text-align: center;
        border: 1px solid #eeeeee;
        &#input_01 {
          width: calc(8.5em + 20px);
        }
        &#input_02 {
          width: calc(4.5em + 20px);
        }
        &#input_03 {
          width: calc(4.5em + 20px);
        }
        &#input_04 {
          width: calc(4.5em + 20px);
        }
        &#input_05 {
          width: calc(1.5em + 20px);
        }
      }
      span {
        display: block;
        margin: 0 8px 16px;
      }
    `,
    addBtn: css`
      display: block;
      width: 54px;
      padding: 8px 10px;
      color: #ffffff;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      background-color: #000000;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      &:disabled {
        opacity: 0.4;
      }
    `,
    buttonArea: css`
      width: 100%;
      margin-top: 40px;
    `,
    submitBtn: css`
      display: block;
      max-width: 480px;
      width: 100%;
      padding: 8px 10px;
      margin: 0 auto;
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      background-color: #25c2ae;
      border: none;
      cursor: pointer;
      &:disabled {
        background-color: #cccccc;
        cursor: default;
      }
    `,
    announcement: css`
      padding: 8%;
      margin-top: 40px;
      border: 1px solid #dedede;
      @media screen and (min-width: 768px) {
        padding: 32px;
        margin-top: 80px;
      }
      h3 {
        margin-bottom: 24px;
        text-align: center;
        line-height: 1.5em;
      }
      p {
        font-size: 14px;
        text-align: center;
        line-height: 1.5em;
        a {
          color: #00c2ae;
          font-size: 14px;
        }
      }
    `,
  };
};

export default VoucherCode;
