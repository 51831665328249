/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Store } from "store";
import { scrollTop } from "utility/index";
import { HOUR_UNTIL_PURCHASE_PHONE_CANCELED } from "constants/purchase";
import { getBelongedHosting } from "utility/storage";
import { hostingMap } from "constants/admin";
import { useI18n } from "hooks/i18n/i18n";
import { TextLink } from "ui/TextLink";

type CompletePurchaseProps = {
  clearLiveCommerceCart: () => void;
};
export const CompletePurchase: React.FC<CompletePurchaseProps> = (props) => {
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );
  const phonePurchaseUrl = useSelector(
    (state: Store) => state.streaming.liveCommerce.phonePurchaseUrl
  );
  const classes = completePurchaseStyles({ isDarkModeEnabled });
  const [domain] = hostingMap[getBelongedHosting()];
  const { t } = useI18n();

  const didMount = () => {
    scrollTop();
  };
  useEffect(didMount, []);

  return (
    <div css={classes.root}>
      {phonePurchaseUrl ? (
        <React.Fragment>
          <p css={classes.headline}>{t("streaming.commerceModal.headline")}</p>
          <p css={classes.text}>
            {t("streaming.commerceModal.para1", {
              displayTime: HOUR_UNTIL_PURCHASE_PHONE_CANCELED,
            })}
          </p>
          <p css={classes.text}>{t("streaming.commerceModal.para2")}</p>
          <p css={classes.caution}>{t("streaming.commerceModal.caution")}</p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p css={classes.headline}>
            {t("streaming.commerceModal.doneHeadline")}
          </p>
          <p css={classes.text}>{t("streaming.commerceModal.donePara")}</p>
          <p css={classes.caution}>
            {t("streaming.commerceModal.doneCaution")}
          </p>
        </React.Fragment>
      )}

      <div css={classes.buttonList}>
        <p css={classes.close} onClick={props.clearLiveCommerceCart}>
          {t("streaming.commerceModal.closeButton")}
        </p>
        {phonePurchaseUrl ? (
          <p
            css={classes.purchase}
            onClick={() => {
              window.open(
                phonePurchaseUrl,
                "決済ページ",
                "width=500,height=500"
              );
            }}
          >
            {t("streaming.commerceModal.transitionButton")}
          </p>
        ) : (
          <p css={classes.account}>
            <TextLink href={`https://${domain}/account/ticket`}>
              マイチケットで確認する
            </TextLink>
          </p>
        )}
      </div>
    </div>
  );
};
const completePurchaseStyles = (props: { isDarkModeEnabled: boolean }) => {
  const fontColor = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;
  const root = css`
    height: 400px;
    padding: 32px 16px;
    border: 1px solid #e0e0e0;
    overflow-y: scroll;
  `;
  const headline = css`
    margin-bottom: 24px;
    font-weight: bold;
    text-align: center;
    ${fontColor};
  `;
  const text = css`
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 1.5em;
    ${fontColor};
  `;
  const caution = css`
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5em;
    ${fontColor};
  `;
  const buttonList = css`
    margin: 24px 0 0;
    p {
      padding: 10px 0px;
      text-align: center;
      cursor: pointer;
    }
  `;
  const close = css`
    color: #fff;
    padding: 12px 0px;
    font-size: 14px;
    background-color: #00c2ae;
  `;
  const account = css`
    margin: 8px 0 0;
    background-color: #fff;
    border: 2px solid #00c2ae;
    a {
      color: #00c2ae;
      font-size: 14px;
      text-decoration: none;
    }
  `;
  const purchase = css`
    margin: 8px 0 0;
    color: #00c2ae;
    font-size: 14px;
    background-color: #fff;
    border: 2px solid #00c2ae;
  `;
  return {
    root,
    headline,
    text,
    caution,
    buttonList,
    close,
    account,
    purchase,
  };
};

type ErrorModalProps = {
  caption?: string;
  msg: string;
  closeModal: () => void;
};
export const ErrorModal: React.FC<ErrorModalProps> = (props) => {
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );
  const classes = errorModalStyles({ isDarkModeEnabled });
  return (
    <div css={classes.root}>
      {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
      <p css={classes.headline}>{props.caption || "エラー"}</p>
      <p css={classes.text}>{props.msg}</p>
      <div css={classes.buttonList}>
        <p css={classes.close} onClick={props.closeModal}>
          閉じる
        </p>
      </div>
    </div>
  );
};
// same with completePurchaseStyles
const errorModalStyles = (props: { isDarkModeEnabled: boolean }) => {
  const fontColor = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;
  return {
    root: css`
      height: 400px;
      padding: 32px 16px;
      border: 1px solid #e0e0e0;
    `,
    headline: css`
      margin-bottom: 24px;
      font-weight: bold;
      text-align: center;
      ${fontColor};
    `,
    text: css`
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 1.5em;
      ${fontColor};
    `,
    buttonList: css`
      margin: 24px 0 0;
      p {
        padding: 10px 0px;
        text-align: center;
        cursor: pointer;
      }
    `,
    close: css`
      color: #fff;
      font-size: 14px;
      background-color: #00c2ae;
      border: 2px solid #fff;
    `,
  };
};
