/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { modalActions } from "modules/modal";
import { authActions } from "modules/auth";
import { parseQuery, validateEmail } from "utility";
import appConfig from "constants/appConfig";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import defaultIconImg from "designs/images/icon_profile.svg";
import docomoImg from "designs/images/auth/docomo.png";
import googleImg from "designs/images/auth/google.png";
import twitterImg from "designs/images/auth/twitter.png";
import { BreadcrumbArea } from "components/common/Link";
import { useI18n } from "hooks/i18n/i18n";
import { AccountEditDelete } from "components/account/molecules/AccountEditDelete";
import {
  EditForm,
  LinkButton,
  MyPageEditProfile,
  WidthDefinedButtonList,
  ContentsLayout,
} from "../../styles";
import { getSNSLinkageStatus } from "utility/getSNSLinkageStatus";

const Container = MyPageEditProfile;

export const AccountEdit: React.FC = () => {
  const user = useSelector((state: Store) => state.auth.user);
  const userIconURL = useSelector((state: Store) => state.auth.userIconURL);
  const location = useSelector((state: Store) => state.router.location);

  const [docomoAccounts, setDocomoAccounts] = useState([]);
  // @ts-expect-error TS2345
  const [email, setEmail] = useState<string>(user.email);
  // @ts-expect-error TS2345
  const [displayName, setDisplayName] = useState<string>(user.displayName);
  const [linkedSNSInfo, setLinkedSNSInfo] = useState<{
    google: boolean;
    twitter: boolean;
    apple: boolean;
  }>(getSNSLinkageStatus(user));

  const dispatch = useDispatch();

  const { t } = useI18n();
  const classes = styles();

  useEffect(() => {
    const query = parseQuery(location.search);
    if (!!query && !!query.msg) {
      //todo: Reduce priority when support eng-lang
      dispatch(modalActions.toggleError({ msg: query.msg as string }));
      // eslint-disable-next-line eqeqeq
    } else if (!!query && !!query.errorCode && query.errorCode == "409") {
      // TODO: string or number??
      //todo: add eng error message
      dispatch(
        modalActions.toggleError({
          msg: "他のアカウントで使用されているメールアドレスは登録できません",
        })
      );
    }
    const unsubscribe = firebase
      .firestore()
      .collection(`openId/docomo/linkingStates`)
      .where("uid", "==", user.uid)
      .onSnapshot((querySnapshot) => {
        // @ts-expect-error TS7034
        const data = [];
        querySnapshot.forEach((doc) => {
          let d = doc.data();
          d = {
            ...d,
            _id: doc.id,
            _path: doc.ref.path,
          };
          data.push(d);
        });
        // @ts-expect-error TS2345
        setDocomoAccounts(data);
      });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLinkedSNSInfo(getSNSLinkageStatus(user));
  }, [user]);

  const unlinkDocomo = async () => {
    if (window.confirm(t("accountEdit.ExternalService.releaseConfirm"))) {
      for (const doc of docomoAccounts) {
        // @ts-expect-error TS2339
        await firebase.firestore().doc(doc._path).delete();
      }
    }
  };

  const unlinkSNS = (providerType: "google" | "twitter") => {
    if (window.confirm(t("accountEdit.ExternalService.releaseConfirm"))) {
      dispatch(authActions.unlinkSNSAccount.started({ providerType }));
    }
  };

  const unlinkDeprecatedSNS = (providerType: "apple") => {
    if (
      window.confirm(
        t("accountEdit.ExternalService.releaseConfirmCannotBeReConnected")
      )
    ) {
      dispatch(authActions.unlinkSNSAccount.started({ providerType }));
    }
  };

  const onClickUpdateUserInfo = () => {
    if (user.email === email && user.displayName === displayName) {
      return;
    }
    if (!validateEmail(email)) {
      dispatch(modalActions.toggleError({ msg: t("accountEdit.error.email") }));
      return;
    }
    dispatch(
      authActions.reAuth.started({
        email,
        displayName,
      })
    );
  };

  // @ts-expect-error TS7006
  const onClickUpdateIconButton = (event) => {
    const [file] = event.currentTarget.files;
    if (file === undefined || file === "") {
      return;
    }
    if (file.size > appConfig.FlowerStand.customImgFileSize) {
      dispatch(modalActions.toggleError({ msg: t("accountEdit.error.file") }));
      return;
    }
    dispatch(authActions.uploadIconImgAction.started(file));
  };

  let photoURL = userIconURL === null ? user.photoURL : userIconURL;
  photoURL = photoURL === null ? defaultIconImg : photoURL;
  if (photoURL.includes("pbs.twimg.com")) {
    photoURL = photoURL.replace("_normal.", "_200x200.");
  } else if (photoURL.includes("graph.facebook.com")) {
    photoURL += "?width=200&height=200";
  }

  return (
    <Container id="contents">
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/account", t("common.routes.account")],
          [null, t("common.routes.accountEdit")],
        ]}
      />
      <ContentsLayout>
        <div className="profile_edit_img">
          <img src={photoURL} alt="accountEdit.img.src" />
          <div className="profile_edit_img_btn">
            <label htmlFor="updateButton">
              <span className="touch_area"></span>
              <input
                type="file"
                onChange={onClickUpdateIconButton}
                id="updateButton"
                accept=".jpg,.png,image/jpeg,image/png"
                style={{ display: "none" }}
              />
            </label>
          </div>
        </div>

        <div className="prifle_edit_info">
          <EditForm>
            <p>{t("accountEdit.username")}</p>
            <input
              type="text"
              name="user_name"
              onChange={(e) => setDisplayName(e.currentTarget.value)}
              // @ts-expect-error TS2322
              defaultValue={user.displayName}
            />
          </EditForm>
          <EditForm>
            <p>{t("accountEdit.email")}</p>
            <input
              type="email"
              name="user_address"
              onChange={(e) => setEmail(e.currentTarget.value)}
              // @ts-expect-error TS2322
              defaultValue={user.email}
            />
          </EditForm>
        </div>

        <WidthDefinedButtonList onClick={onClickUpdateUserInfo}>
          <a href="#!">
            <LinkButton>{t("accountEdit.changeUserInfo")}</LinkButton>
          </a>
        </WidthDefinedButtonList>

        <section css={classes.root}>
          <div css={classes.snsLogin}>
            <h2>{t("accountEdit.ExternalService.connectExternalService")}</h2>
            <p>
              {t("accountEdit.ExternalService.releaseExternalServiceParagraph")}
            </p>
          </div>
          <div css={classes.cancellation}>
            {linkedSNSInfo.google ? (
              <button
                onClick={() => unlinkSNS("google")}
                // if conected provider data is one account, can't disconnect its
                disabled={!user.emailVerified || user.providerData.length === 1}
              >
                <img src={googleImg} alt="" />
                {t("accountEdit.ExternalService.releaseGoogleAccount")}
              </button>
            ) : (
              <button
                onClick={() =>
                  dispatch(
                    authActions.linkSNSAccount.started({
                      providerType: "google",
                    })
                  )
                }
              >
                <img src={googleImg} alt="" />
                {t("accountEdit.ExternalService.connectGoogleAccount")}
              </button>
            )}
            {
              // Twitterログインは停止しているが、Twitter連携の解除はいつでもできるようにしておきたいので、連携解除ボタンのみ表示する
              // 連携解除の経緯: https://github.com/balus-co-ltd/spwn/issues/4264
              // Twitterログイン停止の経緯: https://github.com/balus-co-ltd/spwn/issues/3427
              linkedSNSInfo.twitter && (
                <button
                  onClick={() => unlinkSNS("twitter")}
                  // if conected provider data is one account, can't disconnect its
                  disabled={
                    !user.emailVerified || user.providerData.length === 1
                  }
                >
                  <img src={twitterImg} alt="Twitter" />
                  {t("accountEdit.ExternalService.releaseTwitterAccount")}
                </button>
              )
            }
            {docomoAccounts.length > 0 ? (
              <button
                onClick={unlinkDocomo}
                // className={user.emailVerified ? null : "disabled"}
                // disabled={!user.emailVerified}
              >
                <img src={docomoImg} alt="" />
                {t("accountEdit.ExternalService.releaseDocomoAccount")}
              </button>
            ) : (
              <button
                onClick={() =>
                  dispatch(authActions.linkDocomoAccount.started({}))
                }
              >
                <img src={docomoImg} alt="" />
                {t("accountEdit.ExternalService.connectDocomoAccount")}
              </button>
            )}
            {linkedSNSInfo.apple && (
              <button
                onClick={() => unlinkDeprecatedSNS("apple")}
                // if conected provider data is one account, can't disconnect its
                disabled={!user.emailVerified || user.providerData.length === 1}
              >
                {t("accountEdit.ExternalService.releaseAppleAccount")}
              </button>
            )}
          </div>
        </section>

        <div
          css={css`
            padding: 40px 0 0;
            margin: 40px auto 0px;
            border-top: 1px solid #dedede;
            @media screen and (min-width: 768px) {
              width: 500px;
            }
          `}
        >
          <AccountEditDelete />
        </div>
      </ContentsLayout>
    </Container>
  );
};

const styles = () => {
  const root = css`
    padding: 40px 0 0;
    margin: 40px auto 0px;
    border-top: 1px solid #dedede;
    @media screen and (min-width: 768px) {
      width: 500px;
    }
  `;
  const snsLogin = css`
    margin-bottom: 16px;
    h2 {
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
    }
  `;
  const cancellation = css`
    p {
      margin-bottom: 16px;
      span {
        font-weight: bold;
      }
    }
    button {
      display: flex;
      align-items: center;
      padding: 10px;
      margin-bottom: 8px;
      background-color: #fff;
      border: 2px solid #dedede;
      border-radius: 5px;
      cursor: pointer;
      outline: none;
      appearance: none;
      img {
        width: 24px;
        height: auto;
        margin-right: 10px;
      }
      &.disabled {
        background-color: #dedede;
        cursor: not-allowed;
        img {
          opacity: 0.5;
        }
      }
    }
  `;
  return {
    root,
    snsLogin,
    cancellation,
  };
};
