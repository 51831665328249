/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

type Props = {
  action: () => void;
};
const PlayButton: React.FC<Props> = (props) => {
  const classes = styles();
  return (
    <div css={classes.playButton}>
      <button onClick={props.action}>
        <PlayArrowIcon />
      </button>
    </div>
  );
};
const styles = () => {
  return {
    playButton: css`
      position: absolute;
      top: calc(50% - 40px);
      left: calc(50% - 40px);
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        background-color: rgba(0, 0, 0, 0.2);
        border: 3px solid #fff;
        border-radius: 50%;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        cursor: pointer;
        svg {
          width: 50px;
          height: 50px;
          fill: #fff;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 1);
          svg {
            fill: #ff007690;
          }
        }
      }
    `,
  };
};

export default PlayButton;
