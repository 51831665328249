/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

type Props = {
  description: string;
};

export const Description: React.FC<Props> = ({ description }) => (
  <React.Fragment>
    <p css={styles.heading}>グッズ説明</p>
    <div
      css={styles.content}
      dangerouslySetInnerHTML={{
        __html: description,
      }}
    ></div>
  </React.Fragment>
);

const styles = {
  heading: css`
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 4px;
  `,
  content: css`
    p {
      color: #3c3c3c;
      font-size: 16px;
      line-height: 1.5;
      /* 空のpタグに高さを持たせるため */
      min-height: 24px;
    }
    ul {
      list-style: inside;
      li {
        color: #3c3c3c;
        font-size: 16px;
        line-height: 1.5;
        p {
          display: inline-block;
        }
      }
    }
    a {
      color: #00c2ae;
      text-decoration: underline;
    }
  `,
};
