import styled from "@emotion/styled";

export const AuthProvider = styled.div`
  margin-bottom: 160px;
  @media screen and (min-width: 768px) {
    margin-bottom: 140px;
  }
  .button {
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid #d2d2d2;
    @media screen and (min-width: 768px) {
      padding-bottom: 60px;
    }
  }
`;

AuthProvider.displayName = "AuthProvider";
