/**
 * 映像のforward/replayの秒数を設定する
 */
export const VIDEO_SKIP_SECONDS: 5 | 10 = 10;

/**
 * スーパーチャットエリアに表示する数を設定する
 */
export const DISPLAY_SUPER_CHAT_NUMBER = 5;

export const IS_USING_ALD_MSG_COLLECTION = (eventId: string) => {
  switch (eventId) {
    case "181217-tubeout-vol1":
    case "190316-unionwave":
    case "190323-tubeout-sessions":
    case "190406-tubeout-vol2":
    case "190427-tenjinkotone":
    case "190518-mzm":
    case "190525-alice-1year":
    case "190615-fujiaoi":
    case "190629-rukiroki":
    case "190715-honeystrap":
    case "190720-matsuri-afternoon":
    case "190720-matsuri-night":
    case "190727-rukiroki_afternoon":
    case "190727-rukiroki_night":
    case "190803-azki":
    case "190817-tos-vol2":
    case "190824-matsuri":
    case "190901-azki":
    case "190907-virtualsnack":
    case "190914-tsunku":
    case "190915-fams":
    case "190921-amenosei":
    case "190921-virtualsnack":
    case "190923-matsuri":
    case "190928-azumalim":
    case "191005-fujiaoi":
    case "191005-unionwave":
    case "191006-hololive":
    case "191006-tokinosora":
    case "191011-alice":
    case "191012-virtualsnack":
    case "191014-azki":
    case "191019-patra":
    case "191026-alice":
    case "191026-virtualsnack":
    case "191027-alice":
    case "191101-alice":
    case "191102-ichika":
    case "191104-charlotte":
    case "191109-ikemenV":
    case "191109-virtualsnack":
    case "191110-haneru":
    case "191116-fubuki":
    case "191117-hinokuma":
    case "191119-watanukisanchino":
    case "191123-mary":
    case "191123-virtualsnack":
    case "191128-watanukisanchino":
    case "191201-azki":
    case "191201-patra":
    case "191203-watanukisanchino":
    case "191206-vtubernight":
    case "191207-shishigami":
    case "191208-mico":
    case "191212-watanukisanchino":
    case "191214-marinasu":
    case "191214-virtualsnack":
    case "191217-virtualsnack":
    case "191217-watanukisanchino":
    case "191220-alice":
    case "191222-honeystrap":
    case "191228-virtualsnack":
    case "191229-virtualsnack":
    case "191229-vrizin":
    case "191231-countdown":
    case "200104-megido72":
    case "200118-virtualsnack":
    case "200125-virtualsnack":
    case "200201-virtualsnack":
    case "200206-nazotoki-ani":
    case "200206-nazotoki-balus":
    case "200206-nazotoki-hani":
    case "200206-nazotoki-holo":
    case "200208-animare":
    case "200215-agefes":
    case "200216-agefes":
    case "200222-alice-birthday":
    case "200222-virtualsnack":
    case "200223-alice":
    case "200303-haneru":
    case "200307-tubeout-vol3":
    case "200314-ikemenV":
    case "200315-onsen-musume":
    case "200323-kaf":
    case "200330-istarfes":
    case "200404-canametoharuky":
    case "200408-mogura":
    case "200411-grapestokyo-0":
    case "200413-grapestokyo-1":
    case "200415-VApArt":
    case "200418-tos-vol3":
    case "200420-grapestokyo-2":
    case "200422-bizseminar":
    case "200422-grapestokyo-3":
    case "200422-lumieredespoir":
    case "200423-grapestokyo-4":
    case "200424-haraguchimakoto":
    case "200427-mamiyakotori-1800":
    case "200427-mamiyakotori-2000":
    case "200428-Yggy0711":
    case "200503-hugtime":
    case "200506-mzm":
    case "200509-earniefrogs":
    case "200920-Melt-in-Voice-vol3":
    case "201018-ejanimefes":
    case "201018-yamaha":
    case "201024-yamaha":
    case "201025-yamaha":
    case "201101-yamaha":
    case "201103-yamaha":
    case "201107-yamaha":
    case "201108-yamaha":
    case "201115-vmen":
    case "201129-4mitsu":
    case "201219-774fes-doit":
      return true;
    default:
      return false;
  }
};

/**
 * 禁止ワード
 */
export const NG_WORD = [
  "アナル",
  "アヘン",
  "インポ",
  "いんも",
  "陰毛",
  "インラン",
  "うんこ",
  "うんち",
  "エロ",
  "えっち",
  "多い日も安心",
  "おしっこ",
  "おしり",
  "おっぱい",
  "おめこ",
  "がんしゃ",
  "顔射",
  "きくもん",
  "キャバ嬢",
  "巨乳",
  "クンニ",
  "支那",
  "処女",
  "しゃせい",
  "射精",
  "スケベ",
  "すまた",
  "スカトロ",
  "セクハラ",
  "絶倫",
  "前立腺",
  "チェリーボーイ",
  "ちんこ",
  "チンタ",
  "つんぼ",
  "ていんた",
  "てまん",
  "童貞",
  "トップレス",
  "なかだし",
  "にがー",
  "呪い",
  "ぱいおつ",
  "発情期",
  "びっこ",
  "貧乳",
  "ファック",
  "風俗",
  "フェラ",
  "部落",
  "変質者",
  "ほも",
  "ホモ",
  "マリファナ",
  "マンコ",
  "まんちょ",
  "めくら",
  "もっこり",
  "よここてい",
  "レイプ",
  "ロリコン",
  "assww",
  "Adolf Hitler",
  "DIO",
  "FA!",
  "fuck",
  "fuk",
  "jap",
  "JOINT",
  "PEE",
  "SHEMALE",
  "shit",
  "T.T",
  "XX XY",
  "072",
  "206",
  "310",
  "455",
];
