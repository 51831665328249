import { css } from "@emotion/react";

export const globalStyle = css`
  select {
    width: 75px;
    padding: 8px 28px 8px 17px;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #dedede;
    background: url("/images/select_pulldown.png") right 50% no-repeat;
    background-size: 29px, 100%;
    border-radius: 0;
    appearance: none;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
`;
