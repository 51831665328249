import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import firebase from "firebase";
import { Store } from "store";
import { Event, UserCartProduct } from "@spwn/types/firebase/firestore";
import {
  createSet,
  EventInfo,
  getEventDisplayInfo,
  getNowTimestamp,
} from "utility";
import { i18nextT } from "hooks/i18n/i18n";
import { calcDomainShippingFee, checkSendingFee } from "utility/purchase";
import { ResGetSendingFee } from "@spwn/types/functions";
import { getVenuePayPrice } from "utility/ticket";

type CartProduct = {
  fulfillmentShipmentTitle?: string;
  relatedEventInfo: EventInfo;
} & UserCartProduct;

export type Product = {
  name: string;
  thumbnail: string;
  count: number;
  links: {
    name: string;
    url: string;
  }[];
  id: string;
  refPath: string | null;
  cartProductId: string;
  retentionSeconds: number | null;
  /**
   * テナントグッズで利用されるステータス。
   * テナントグッズ以外のグッズは購入可能想定なので、availableに設定する
   */
  status: "outsideSalesPeriod" | "soldOut" | "outOfStock" | "available";
  subClassName: string;
  /**
   * テナントグッズでは発送日でグルーピングせず、商品に発送日をつける
   */
  shippingAtTitle?: string;
  /**
   * テナントグッズ（テナントリソース）用のカートアイテムID
   */
  cartItemId?: string;
  isTenantCart?: boolean;
} & ProductPrice;

/**
 * 価格
 */
type ProductPrice =
  | {
      /**
       * Portalでは支払いがないということを示すために0円表示する
       */
      price: 0;
      venuePayPrice: number;
      isVenuePay: true;
    }
  | {
      price: number;
      isVenuePay: false;
    };

export type OrderCart = {
  groupId: string; // FulfillmentShipment document id or REGULAR_GROUP_ID
  heading: string; // カート1
  shippingAtTitle: string; // 配送日
  subTotalPrice: number; // 小計
  shippingFee: number; // 送料
  products: Product[];
  /**
   * 決済ページへのリンク
   * グッズによってリンクを分けている。一緒に購入することはできないため
   * - Portalグッズ → /settlement
   * - CMSグッズ（テナントグッズ） → /purchase
   */
  purchaseLink: string;
  productsSummary?: {
    // For purchaseProducts. Use in settlement page.
    id: string;
    refPath: string;
    count: number;
  }[];
};

export type Cart = {
  orderCarts: OrderCart[];
  cartItemCount: number;
  removedCartItems: Product[];
};

const REGULAR_GROUP_ID = "0";

/**
 * @description "product-not-found" は決済画面ではエラーだが、カート上ではエラーとは言えない気がするがまとめた
 */
export type CartError = "product-not-found" | "unknown" | null;

export const fetchCartProducts = async (): Promise<CartProduct[]> => {
  const { currentUser } = firebase.auth();
  if (!currentUser) return [];
  const cartProducts = await firebase
    .firestore()
    .collection(`users/${currentUser.uid}/cartProducts`)
    .where("expiredAt", ">", new Date())
    .get();
  const promises = cartProducts.docs.map(async (product) => {
    const userCartProduct = product.data() as UserCartProduct;
    const fulfillmentShipmentTitle =
      (await userCartProduct?.fulfillmentShipmentRef?.get())?.data()?.title ??
      null;
    const event = (
      await firebase.firestore().doc(`events/${userCartProduct.eid}`).get()
    )?.data() as Event;
    return {
      ...userCartProduct,
      fulfillmentShipmentTitle,
      relatedEventInfo: getEventDisplayInfo(event),
      _id: product.id,
      _refPath: product.ref.path,
    };
  });
  // @ts-expect-error TS2322
  return Promise.all(promises);
};

type GroupedCart = {
  fulfillmentShipmentId: string | null;
  fulfillmentShipmentTitle: string | null;
  shippingFee?: number;
  cartProducts: CartProduct[];
}[];

export const groupByFulfillmentShipment = (
  cartProducts: CartProduct[]
): GroupedCart => {
  return cartProducts.reduce((acc, cur) => {
    // find grouping target. null or fulfillmentShipmentId.
    const result = acc.find(
      (el) =>
        (!cur.fulfillmentShipmentRef && el.fulfillmentShipmentId === null) ||
        cur?.fulfillmentShipmentRef?.id === el.fulfillmentShipmentId
    );
    if (result) {
      // add if found.
      result.cartProducts.push(cur);
    } else {
      // create if not found.
      acc.push({
        fulfillmentShipmentId: cur?.fulfillmentShipmentRef?.id || null, // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
        // @ts-expect-error TS2322
        fulfillmentShipmentTitle: cur.fulfillmentShipmentTitle,
        cartProducts: [cur],
      });
    }
    return acc;
  }, [] as GroupedCart);
};

export const sortByShippingSchedule = (groupedCart: GroupedCart) => {
  const sortedCart = groupedCart
    .filter((cart) => cart.fulfillmentShipmentId !== null)
    .sort((n, m) => {
      // @ts-expect-error TS18047
      return n.fulfillmentShipmentId < m.fulfillmentShipmentId ? -1 : 1;
    });
  const unsetCart = groupedCart.find(
    (cart) => cart.fulfillmentShipmentId === null
  );
  return unsetCart ? [unsetCart, ...sortedCart] : sortedCart;
};

export const assignShippingFee = async (
  groupedCart: GroupedCart,
  shippingFeeInfo: ResGetSendingFee
) => {
  return await Promise.all(
    groupedCart.map(async (cart) => {
      const shippingFee = await calculateShippingFee(
        cart.cartProducts,
        shippingFeeInfo
      );
      return {
        ...cart,
        shippingFee,
      };
    })
  );
};

export const convertToCartModel = (groupedCart: GroupedCart): Cart => {
  const orderCarts: OrderCart[] = groupedCart.map(
    (
      {
        fulfillmentShipmentId,
        fulfillmentShipmentTitle,
        shippingFee,
        cartProducts,
      },
      i
    ) => {
      const heading = `カート${i + 1}`;
      const shippingAtTitle = fulfillmentShipmentTitle ?? null;
      const subTotalPrice = cartProducts.reduce(
        (result, current) => result + current.price_jpy * current.count,
        0
      );
      const nowTimestamp = getNowTimestamp();
      const products: Product[] = cartProducts.map((product) => {
        const venuePayPrice = getVenuePayPrice(product);
        const priceInfo: ProductPrice =
          venuePayPrice > 0
            ? {
                venuePayPrice,
                isVenuePay: true,
                price: 0,
              }
            : {
                isVenuePay: false,
                price: product.price_jpy,
              };
        const cartOrderProduct: Product = {
          name: product.name,
          // @ts-expect-error TS2322
          thumbnail:
            product.productType === "ticket"
              ? product.relatedEventInfo.thumbnail
              : product.thumbnail,
          count: product.count,
          links: createRelatedLinks(product),
          id: product.productId,
          // @ts-expect-error TS2322
          refPath: product._refPath,
          // @ts-expect-error TS2322
          cartProductId: product._id,
          retentionSeconds: product.expiredAt.seconds - nowTimestamp,
          // @ts-expect-error TS2322
          subClassName: product.subClassName,
          status: "available",
          ...priceInfo,
        };
        return cartOrderProduct;
      });
      // URLに使用するため、fulfillmentShipmentIdがない場合は専用の文字列を使用する
      const groupId = fulfillmentShipmentId ?? REGULAR_GROUP_ID;
      const purchaseLink = `/settlement?order=${groupId}`;
      const productsSummary = cartProducts.map((product) => ({
        id: product.productId,
        refPath: product._refPath,
        count: product.count,
      }));
      return {
        groupId,
        heading,
        shippingAtTitle,
        subTotalPrice,
        shippingFee,
        products,
        purchaseLink,
        productsSummary,
      } as OrderCart;
    }
  );
  const cartItemCount = groupedCart
    .map(({ cartProducts: products }) => products)
    .flat()
    .map((product) => product.count)
    .reduce((prev, cur) => prev + cur, 0);
  return { orderCarts, cartItemCount, removedCartItems: [] };
};

const createRelatedLinks = (product: CartProduct) => {
  const relatedLinks = [];
  if (product.relatedEventInfo.goodsSaleStatus === "ON_SALE") {
    relatedLinks.push({
      name: i18nextT("cart.box.relatedGoods") ?? "",
      url: product.relatedEventInfo.goodsUrl ?? `/events/${product.eid}/goods`,
    });
  }
  relatedLinks.push({
    name: i18nextT("cart.box.event") ?? "",
    url: `/events/${product.eid}`,
  });
  return relatedLinks;
};

const calculateShippingFee = async (
  cartProducts: CartProduct[],
  shippingFeeInfo: ResGetSendingFee
): Promise<number | null> => {
  if (!shippingFeeInfo) return null;
  if (!isShippingFeeRequired(cartProducts)) return null;
  const domains = extractAddressRequiredDomains(cartProducts);
  const shippingFee = await calcDomainShippingFee(
    // @ts-expect-error TS2345
    domains,
    shippingFeeInfo.destinationType
  );
  return shippingFee;
};

const isShippingFeeRequired = (cartProducts: CartProduct[]) => {
  return cartProducts.some((product) => product.isAddressRequired);
};

export const extractAddressRequiredDomains = (cartProducts: CartProduct[]) => {
  const domains = createSet(
    // @ts-expect-error TS2345
    cartProducts
      .filter((product) => product.isAddressRequired)
      .map((product) => product.relatedEventInfo.belongedHostings[0])
  );
  return domains;
};

export const useCart = () => {
  const user = useSelector((state: Store) => state.auth.user);
  const userCartProducts = useSelector(
    (state: Store) => state.cart.userCartProducts
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<CartError>(null);
  // @ts-expect-error TS2345
  const [cart, setCart] = useState<Cart>(null);

  const getCart = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      // setCart(getMockCart());
      // return;
      const [cartProducts, shippingFeeInfo] = await Promise.all([
        fetchCartProducts(),
        checkSendingFee(user),
      ]);
      if (cartProducts.length === 0) {
        // @ts-expect-error TS2345
        setCart(null);
        setError("product-not-found");
        return;
      }
      const groupedCart = groupByFulfillmentShipment(cartProducts);
      const sortedCart = sortByShippingSchedule(groupedCart);
      const cartWithShippingFee = await assignShippingFee(
        sortedCart,
        // @ts-expect-error TS2345
        shippingFeeInfo
      );
      // @ts-expect-error TS2345
      const cartModel = convertToCartModel(cartWithShippingFee);
      setCart(cartModel);
    } catch (error) {
      console.error(error);
      setError("unknown");
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCartProducts]);

  return {
    loading,
    error,
    cart,
  };
};

// TODO 使ってなさそうなのでとりあえずコメントアウト 不要なら完全削除してください
// const getMockCart = (): Cart => {
//   return {
//     orderCarts: [
//       {
//         groupId: REGULAR_GROUP_ID,
//         heading: "カート1",
//         shippingAtTitle: null,
//         subTotalPrice: 1000,
//         shippingFee: 1000,
//         products: [
//           {
//             name: "グッズA",
//             thumbnail:
//               "https://public.spwn.jp/spwn-store/goods/21052219-alice-3rd/3nd_1-0.jpg",
//             price: 1000,
//             count: 1,
//             links: [
//               {
//                 name: "関連商品を見る",
//                 url: "https://web-dev1.spwn.jp/events/21052219-alice-3rd/goods",
//               },
//             ],
//             id: "G21052219-alice-3_0010001C0101",
//             refPath:
//               "goods/21052219-alice-3rd/salesData/G21052219-alice-3_0010001C0101",
//             cartProductId: "1633392544772-G21052219-alice-3_0010001C0101",
//             retentionSeconds: 840,
//             subClassName: "A",
//           },
//         ],
//         purchaseLink: "/settlement?order=0",
//         productsSummary: [
//           {
//             // for purchaseProducts.
//             id: "G21052219-alice-3_0010001C0101",
//             refPath:
//               "goods/21052219-alice-3rd/salesData/G21052219-alice-3_0010001C0101",
//             count: 1,
//           },
//         ],
//       },
//       {
//         groupId: "Sd_spwn_21110001",
//         heading: "カート2",
//         shippingAtTitle: "8月上旬",
//         subTotalPrice: 3000,
//         shippingFee: 1000,
//         products: [
//           {
//             name: "グッズB",
//             thumbnail:
//               "https://public.spwn.jp/spwn-store/goods/21052219-alice-3rd/3nd_1-0.jpg",
//             price: 2000,
//             count: 1,
//             links: [
//               {
//                 name: "関連商品を見る",
//                 url: "https://web-dev1.spwn.jp/events/21052219-alice-3rd/goods",
//               },
//             ],
//             id: "G21052219-alice-3_0020001C0235",
//             refPath:
//               "goods/21052219-alice-3rd/salesData/G21052219-alice-3_0020001C0235",
//             cartProductId: "1633392561608-G21052219-alice-3_0020001C0235",
//             retentionSeconds: 60,
//             subClassName: "C",
//           },
//           {
//             name: "グッズC",
//             thumbnail:
//               "https://public.spwn.jp/spwn-store/goods/21052219-alice-3rd/3nd_1-0.jpg",
//             price: 1000,
//             count: 1,
//             links: [
//               {
//                 name: "関連商品を見る",
//                 url: "https://web-dev1.spwn.jp/events/21052219-alice-3rd/goods",
//               },
//             ],
//             id: "G21052219-alice-3_0030001C0232",
//             refPath:
//               "goods/21052219-alice-3rd/salesData/G21052219-alice-3_0030001C0232",
//             cartProductId: "1633392601274-G21052219-alice-3_0030001C0232",
//             retentionSeconds: 120,
//             subClassName: "C",
//           },
//         ],
//         purchaseLink: "/settlement?order=1",
//         productsSummary: [
//           {
//             // for purchaseProducts.
//             id: "G21052219-alice-3_0020001C0235",
//             refPath:
//               "goods/21052219-alice-3rd/salesData/G21052219-alice-3_0020001C0235",
//             count: 1,
//           },
//           {
//             // for purchaseProducts.
//             id: "G21052219-alice-3_0030001C0232",
//             refPath:
//               "goods/21052219-alice-3rd/salesData/G21052219-alice-3_0030001C0232",
//             count: 1,
//           },
//         ],
//       },
//     ],
//     cartItemCount: 3,
//   };
// };
