import type { HostingType } from "@spwn/types/firebase/firestore";

// !!! don't store secure data !!!
// localStorage value is must be string
type LocalStorage = {
  belongedHosting: HostingType;
};

export const getLocalStorage = <K extends keyof LocalStorage>(key: K) => {
  return localStorage.getItem(key) as HostingType;
};

export const setLocalStorage = (
  key: keyof LocalStorage,
  value: string
): void => {
  localStorage.setItem(key, value);
};

export const removeLocalStorage = (key: keyof LocalStorage): void => {
  localStorage.removeItem(key);
};

export const getBelongedHosting = () => {
  return getLocalStorage("belongedHosting");
};
