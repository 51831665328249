/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";
import React from "react";
import { useSelector } from "react-redux";
import { Store } from "store";
import "firebase/firestore";
import { multiByteStringSlice } from "utility";

interface Props {}
export const AlertBar: React.FC<Props> = (_props) => {
  const classes = styles();
  const pickUpNews = useSelector(
    (state: Store) => state.notification.pickUpNews
  );

  if (
    !pickUpNews.isActive ||
    pickUpNews.msg === undefined ||
    pickUpNews.msg === null ||
    pickUpNews.msg === "" ||
    pickUpNews.open === null ||
    pickUpNews.close === null ||
    pickUpNews.open.seconds * 1000 >= Date.now() ||
    pickUpNews.close.seconds * 1000 <= Date.now()
  ) {
    return null;
  }

  return (
    <div id="alert-bar" css={classes.root}>
      {pickUpNews.url ? (
        <a href={pickUpNews.url} target="_blank" rel="noopener noreferrer">
          <p css={classes.msg}>{multiByteStringSlice(pickUpNews.msg, 150)}</p>
        </a>
      ) : (
        <p css={classes.msg}>{multiByteStringSlice(pickUpNews.msg, 150)}</p>
      )}
    </div>
  );
};

const styles = () => {
  const marquee = keyframes`
    from{transform:translate(0)}
    to{transform:translate(-100%)}
  `;
  return {
    root: css`
      width: 100%;
      padding: 10px 0;
      background-color: #c51414;
      overflow: hidden;
    `,
    msg: css`
      width: 350%;
      padding-left: 100%;
      color: #fff;
      font-size: 13px;
      line-height: 1em;
      white-space: nowrap;
      animation: ${marquee} 20s linear 0s infinite;
      @media screen and (min-width: 768px) {
        width: 170%;
        animation: ${marquee} 30s linear 0s infinite;
      }
    `,
  };
};
