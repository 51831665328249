import styled from "@emotion/styled";
import { Paw } from "../Paw";

export const PawHistoryListPaw = styled(Paw)`
  justify-content: center;
  font-size: 25px;
  @media screen and (min-width: 768px) {
    font-size: 35px;
  }
  &:after {
    width: 20px;
    height: 20px;
  }
`;

PawHistoryListPaw.displayName = "PawHistoryListPaw";
