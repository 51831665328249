/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import { ToggleSwitch } from "components/switch/ReactSwitch";
import { streamingActions } from "modules/streaming";
import { useDispatch } from "react-redux";

export const StreamingStandbyToggleDarkmode: FC<{
  isDarkModeEnabled: boolean;
}> = ({ isDarkModeEnabled }) => {
  const dispatch = useDispatch();

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: right;
      `}
    >
      <p
        css={css`
          margin-right: 10px;
          color: ${isDarkModeEnabled ? "#fff" : "#000"};
          font-size: 12px;
        `}
      >
        ダークモード
      </p>

      {ToggleSwitch(isDarkModeEnabled, () =>
        dispatch(streamingActions.toggleDarkMode())
      )}
    </div>
  );
};
