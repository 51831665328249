import { authDomain } from "./appConfig";

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
};

export const APPLE_PROVIDER_ID = "apple.com";
