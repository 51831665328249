/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import BorderLoader from "../../components/atoms/loader/BorderLoader";
import { purchaseActions } from "../../modules/purchase";
import { Link } from "react-router-dom";
import { authActions } from "../../modules/auth";
import defaultIconImg from "../../designs/images/icon_profile.svg";
import "firebase/auth";
import { hasEmailProviderData } from "../../utility/auth";
import { BreadcrumbArea } from "components/common/Link";
import { modalActions } from "../../modules/modal";
import { useI18n } from "hooks/i18n/i18n";
import { AccountRegisterInfo } from "components/account/molecules/AccountRegisterInfo";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { firestoreActions } from "modules/firestore";
import styled from "@emotion/styled";
import { fontDinMixin, Paw } from "../../styles";

const Container = styled.div`
  padding-bottom: 60px;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    margin: 0 auto;
    padding-bottom: 200px;
  }
  .mypage_info {
    padding: 20px 5%;
    border-bottom: 1px solid #e6e5e6;
    @media screen and (min-width: 768px) {
      padding: 30px 0 40px;
    }
    .mypage_info_layout {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        width: 760px;
        margin: 0 auto;
      }
      .img {
        width: 20%;
        @media screen and (min-width: 768px) {
          width: 200px;
        }
        img {
          width: 100%;
          border: 2px solid #dedede;
          border-radius: 50%;
        }
      }
      .profile_area {
        width: 75%;
        @media screen and (min-width: 768px) {
          width: 520px;
        }
        /* PC表示の時のみ表示する */
        .mypage_spwnid_layout {
          display: none;
          @media screen and (min-width: 768px) {
            display: block;
          }
          .heading {
            @media screen and (min-width: 768px) {
              font-size: 18px;
            }
          }
          .id {
            @media screen and (min-width: 768px) {
              ${fontDinMixin};
              font-size: 35px;
            }
            span {
              margin-left: 15px;
              color: #00c5cb;
              font-size: 13px;
              text-decoration: underline;
            }
          }
        }
      }
      .profile_layout {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (min-width: 768px) {
          width: 520px;
          margin-bottom: 40px;
        }
        .profile {
          .name {
            margin-bottom: 5px;
            font-size: 18px;
            font-weight: bold;
            @media screen and (min-width: 768px) {
              margin-bottom: 10px;
              font-size: 30px;
            }
          }
          .address {
            color: #8a8f8f;
            font-size: 12px;
            @media screen and (min-width: 768px) {
              font-size: 20px;
            }
          }
        }
      }
    }

    /* スマホ表示の時のみ表示する */
    .mypage_spwnid_layout {
      @media screen and (min-width: 768px) {
        display: none;
      }
      .heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 13px;
        span {
          display: block;
          margin-left: 15px;
          color: #19d0d8;
          font-size: 11px;
          font-weight: bold;
          cursor: pointer;
        }
      }
      .id {
        display: flex;
        flex-wrap: wrap;
        ${fontDinMixin};
        font-size: 23px;
      }
    }
  }
  .owned_paw_information {
    padding: 15px 0;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e6e5e6;
    .owned_paw_layout {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 740px;
      }
      .owned_paw {
        .heading {
          font-size: 14px;
        }
        .paw {
          font-size: 35px;
          @media screen and (min-width: 768px) {
            font-size: 55px;
          }
          &:after {
            @media screen and (min-width: 768px) {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
  }
  .mypage_nav {
    margin-top: 40px;
    @media screen and (min-width: 768px) {
      width: 740px;
      margin: 40px auto 0;
    }
    .nav_heading {
      padding: 5px 5%;
      color: #fff;
      font-size: 12px;
      background-color: #979797;
      @media screen and (min-width: 768px) {
        padding: 5px 3%;
      }
    }
    .mypage_nav_list {
      li {
        border-bottom: 1px solid #e6e5e6;
        a {
          display: flex;
          align-items: center;
          width: 90%;
          padding: 10px 0;
          margin: 0 auto;
          color: #38444b;
          font-size: 13px;
          background-image: url("/images/sample_arrow_right.png");
          background-position: right center;
          background-repeat: no-repeat;
          @media screen and (min-width: 768px) {
            width: 700px;
          }
          &.ticket,
          &.lottery {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .item_name {
            display: flex;
            align-items: center;
            &:before {
              content: "";
              display: block;
              width: 30px;
              height: 30px;
              margin-right: 5px;
              background-image: url("/images/icon_map.svg");
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 100% auto;
            }
          }
          &.ticket .item_name:before {
            background-image: url("/images/icon_ticket_b.svg");
          }
          &.settlement_history .item_name:before {
            background-image: url("/images/icon_paymenthistory.svg");
          }
          &.lottery .item_name:before {
            background-image: url("/images/icon_ticket_b.svg");
          }
          &.charge_history .item_name:before {
            background-image: url("/images/icon_pawchargehistory.svg");
          }
          &.usage_history .item_name:before {
            background-image: url("/images/icon_paypaymenthistory.svg");
          }
          &.edit_profile .item_name:before {
            background-image: url("/images/icon_profile.svg");
          }
          &.credit_info .item_name:before {
            background-image: url("/images/icon_creditcard.svg");
          }
          &.edit_pass .item_name:before {
            background-image: url("/images/icon_password.svg");
          }
          &.logout .item_name:before {
            background-image: url("/images/icon_logout.svg");
          }
          .number_tickets {
            color: #01baa5;
            font-size: 14px;
            font-weight: bold;
            margin-right: 25px;
          }
        }
      }
    }
  }
  .chargePaw {
    width: 48%;
    background-color: #00c2ae;
    @media screen and (min-width: 768px) {
      width: 270px;
    }
    p {
      display: block;
      padding: 12px 0px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }
  }
  .mypage_nav.emergencyNews {
    .nav_heading {
      font-weight: bold;
      background-color: rgb(232, 74, 14);
    }
    .newsLayout {
      max-width: 700px;
      width: 90%;
      margin: 0 auto;
      .heading {
        padding: 15px 0;
        line-height: 1.5em;
        letter-spacing: 1px;
        cursor: pointer;
      }
      .description {
        margin-bottom: 30px;
        line-height: 1.5em;
        white-space: pre-line;
        a {
          color: #00c2ae;
          text-decoration: underline;
        }
      }
      .buttonForLink {
        display: block;
        max-width: 400px;
        width: 100%;
        padding: 12px 0;
        margin: 0 auto;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        line-height: 1.2em;
        letter-spacing: 1px;
        background-color: #00c2ae;
        cursor: pointer;
        @media screen and (min-width: 768px) {
          padding: 15px 0;
          font-size: 15px;
          letter-spacing: 2px;
        }
      }
    }
  }
`;

export const Account: React.FC = () => {
  const { t } = useI18n();
  const user = useSelector((state: Store) => state.auth.user);
  const emoBalance = useSelector((state: Store) => state.purchase.emoBalance);

  const classes = styles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(purchaseActions.getEmoBalance.started());
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.getEmoBalance.started,
        args: null,
      })
    );
    dispatch(purchaseActions.watchPawBalance.started());
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.watchPawBalance.started,
        args: null,
      })
    );

    return () => {
      dispatch(firestoreActions.closeChannel({ channel: "pawBalance" }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyToClipboard = (target: string) => {
    const uid = document.getElementById(target);
    const textArea = document.createElement("textarea");
    // @ts-expect-error TS2322
    textArea.value = uid.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
  };

  const togglePawChargeModal = () => {
    dispatch(modalActions.togglePawChargeModal({}));
  };

  const logout = () => {
    dispatch(authActions.logout.started());
  };

  const userDisplayName =
    user.displayName === null || user.displayName === undefined
      ? t("account.notSetUserName")
      : user.displayName;
  const userEmail =
    user.email === null || user.email === undefined
      ? t("account.notSetEmail")
      : user.email;
  let userPhotoURL =
    user.photoURL === null || user.photoURL === undefined
      ? defaultIconImg
      : user.photoURL;
  if (userPhotoURL.includes("pbs.twimg.com")) {
    userPhotoURL = userPhotoURL.replace("_normal.", "_200x200.");
  } else if (userPhotoURL.includes("graph.facebook.com")) {
    userPhotoURL += "?width=200&height=200";
  }

  const accountEditPassword =
    Object.keys(user).length === 0 || !hasEmailProviderData(user) ? (
      ""
    ) : (
      <li>
        <Link className="edit_pass" to={"/account/edit/password"}>
          <span className="item_name">{t("account.editPassword")}</span>
        </Link>
      </li>
    );

  return (
    <Container id="contents">
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, t("common.routes.account")],
        ]}
      />

      <AccountRegisterInfo
        user={user}
        userPhotoURL={userPhotoURL}
        userDisplayName={userDisplayName}
        userEmail={userEmail}
        copyToClipboard={copyToClipboard}
      />

      <div className="owned_paw_information">
        <div className="owned_paw_layout">
          <div className="owned_paw">
            <p className="heading">{t("account.ownedPAW")}</p>
            {emoBalance.paidValue !== undefined ? (
              <Paw className="paw">{emoBalance.paidValue}</Paw>
            ) : user !== undefined &&
              user !== null &&
              user.emailVerified === false ? (
              <React.Fragment>
                {t("account.emailUnauthenticated")}
              </React.Fragment>
            ) : (
              <BorderLoader />
            )}
          </div>
          <div className="chargePaw">
            <p onClick={togglePawChargeModal}>{t("account.topUpPAW")}</p>
          </div>
        </div>
      </div>

      <div className="mypage_nav emergencyNews">
        <p className="nav_heading">{t("account.news.title")}</p>
        <ul className="mypage_nav_list">
          <li>
            <Link css={classes.ticket} to={"/account/notifications"}>
              <InfoOutlinedIcon css={classes.infoUI} />
              <span>{t("account.news.list")}</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="mypage_nav">
        <p className="nav_heading">{t("account.ticket.title")}</p>
        <ul className="mypage_nav_list">
          {/*<li><Link className="ticket contents_layout" to={'/account/ticket'}><span className="item_name">マイチケット</span><span className="number_tickets">4</span></Link></li>*/}
          <li>
            <Link className="ticket" to={"/account/ticket"}>
              <span className="item_name">{t("account.ticket.myTicket")}</span>
            </Link>
          </li>
          <li>
            <Link className="ticket" to={"/account/ticket/vod"}>
              <span className="item_name">{t("account.ticket.myLive")}</span>
            </Link>
          </li>
          <li>
            <Link className="ticket" to={"/voucher"}>
              <span className="item_name">{t("account.ticket.voucher")}</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="mypage_nav">
        <p className="nav_heading">{t("account.history.title")}</p>
        <ul className="mypage_nav_list">
          <li>
            <Link
              className="settlement_history"
              to={"/account/settlement/history"}
            >
              <span className="item_name">
                {t("account.history.settlementHistory")}
              </span>
            </Link>
          </li>
          {/*<li><Link className="lottery" to={'/account/lottery'}><span className="item_name">抽選申込履歴</span><span className="number_tickets">1</span></Link></li>*/}
          <li>
            <Link className="lottery" to={"/account/lottery"}>
              <span className="item_name">
                {t("account.history.lotteryApplicationList")}
              </span>
            </Link>
          </li>
          <li>
            <Link className="charge_history" to={"/account/paw-charge-history"}>
              <span className="item_name">
                {t("account.history.topUpHistory")}
              </span>
            </Link>
          </li>
          <li>
            <Link className="usage_history" to={"/account/paw-use-history"}>
              <span className="item_name">
                {t("account.history.usageHistory")}
              </span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="mypage_nav">
        <p className="nav_heading">{t("account.account.title")}</p>
        <ul className="mypage_nav_list">
          <li>
            <Link className="edit_profile" to={"/account/edit"}>
              <span className="item_name">
                {t("account.account.editProfileInfo")}
              </span>
            </Link>
          </li>
          <li>
            <Link className="credit_info" to={"/account/credit"}>
              <span className="item_name">
                {t("account.account.creditCardInfo")}
              </span>
            </Link>
          </li>
          <li>
            <Link css={classes.ticket} to={"/account/address"}>
              <HomeOutlinedIcon css={classes.infoUI} />
              <span>{t("account.account.editShippingAddress")}</span>
            </Link>
          </li>
          {accountEditPassword}
          <li>
            <Link className="logout" to="/" onClick={logout}>
              <span className="item_name">{t("account.account.logout")}</span>
            </Link>
          </li>
        </ul>
      </div>
    </Container>
  );
};

const styles = () => {
  return {
    ticket: css`
      padding: 10px 0;
      margin: 0 auto;
      color: #38444b;
      font-size: 13px;
      line-height: 1em;
    `,
    infoUI: css`
      margin-right: 12px;
    `,
  };
};
