/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { SPView, PCView } from "components/common/MediaQuery";
import { useI18n } from "../../hooks/i18n/i18n";
import { OrderCart } from "hooks/cart/Cart";

type Props = {
  product: OrderCart["products"][number];
  deleteFromCart: () => void;
  changeRoute: (url: string) => void;
};
export const CartOrderProduct: React.FC<Props> = (props) => {
  const classes = styles();
  const { t } = useI18n();
  const {
    count,
    links,
    name,
    subClassName,
    price,
    thumbnail,
    retentionSeconds,
    shippingAtTitle,
    ...restProduct
  } = props.product;
  const expiredDate = retentionSeconds
    ? Math.floor(retentionSeconds / 60)
    : null;

  return (
    <div css={classes.root}>
      <PCView>
        <div id={"cart-order-product-pc"}>
          <div className={"cart-order-product-image"}>
            <img
              src={thumbnail ? thumbnail : "/images/no-img.png"}
              alt={name}
            />
          </div>

          <div className={"cart-order-product-info"}>
            <p className={"name"}>
              {name}
              {subClassName && ` (${subClassName})`}
            </p>
            <p className={"price"}>
              {price.toLocaleString()}
              {t("cart.box.yen")}
              {restProduct.isVenuePay &&
                ` (会場支払い ${restProduct.venuePayPrice.toLocaleString()}${t(
                  "cart.box.yen"
                )})`}
              <span>{t("cart.box.tax")}</span>
            </p>
            {shippingAtTitle && (
              <p className={"shippingAt"}>{`${t(
                "cart.box.scheduledShippingDate"
              )}：${shippingAtTitle}`}</p>
            )}
            <ul className={"link"}>
              {links.map((link, index) => {
                const { name, url } = link;
                return (
                  <li key={index}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={() => props.changeRoute(url)}>
                      {name}
                    </a>
                    {links.length - 1 !== index && <span>|</span>}
                  </li>
                );
              })}
            </ul>
            <p className={"retention"}>
              {expiredDate
                ? t("cart.box.timeLimit", { expiredDate })
                : t("cart.box.noRetention")}
            </p>
          </div>

          <div className={"cart-order-product-count"}>
            <p className={"count"}>×{count}</p>
            <p className={"delete"} onClick={props.deleteFromCart}>
              {t("cart.box.delete")}
            </p>
          </div>
        </div>
      </PCView>

      <SPView>
        <div id={"cart-order-product-sp"}>
          <div className={"cart-order-product-detail"}>
            <div className={"cart-order-product-image"}>
              <img src={thumbnail} alt={name} />
            </div>

            <div className={"cart-order-product-info"}>
              <p className={"name"}>{name}</p>
              <p className={"price"}>
                {price.toLocaleString()}
                {t("cart.box.yen")}
                <span>{t("cart.box.tax")}</span>
              </p>
              {shippingAtTitle && (
                <p className={"shippingAt"}>{`${t(
                  "cart.box.scheduledShippingDate"
                )}：${shippingAtTitle}`}</p>
              )}
              <p className={"retention"}>
                {expiredDate
                  ? t("cart.box.timeLimit", { expiredDate })
                  : t("cart.box.noRetention")}
              </p>
            </div>
          </div>

          <div className={"cart-order-product-other"}>
            <ul className={"cart-order-product-link"}>
              {links.map((link, index) => {
                const { name, url } = link;
                return (
                  <li key={index}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={() => props.changeRoute(url)}>
                      {name}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div className={"cart-order-product-count"}>
              <p className={"count"}>×{count}</p>
              <p className={"delete"} onClick={props.deleteFromCart}>
                {t("cart.box.delete")}
              </p>
            </div>
          </div>
        </div>
      </SPView>
    </div>
  );
};

const styles = () => ({
  root: css`
    padding: 8px;
    border-bottom: 2px solid #a1a1a1;
    border-right: 2px solid #a1a1a1;
    border-left: 2px solid #a1a1a1;
    @media screen and (min-width: 768px) {
      padding: 24px;
    }
    #cart-order-product-pc {
      display: grid;
      grid-template-columns: 130px auto 64px;
      gap: 16px;
      .cart-order-product-image {
        img {
          width: 100%;
        }
      }
      .cart-order-product-info {
        letter-spacing: 1px;
        .name {
          margin-bottom: 4px;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5em;
        }
        .price {
          margin-bottom: 8px;
          font-size: 18px;
          font-weight: bold;
          span {
            margin-left: 0.5em;
            font-size: 12px;
          }
        }
        .shippingAt {
          margin-bottom: 8px;
          font-size: 18px;
          font-weight: bold;
        }
        .link {
          display: flex;
          margin-bottom: 4px;
          a {
            color: #00c2ad;
            font-size: 12px;
          }
          li > span {
            margin: 0 8px;
          }
        }
        .retention {
          font-size: 12px;
        }
      }
      .cart-order-product-count {
        text-align: right;
        letter-spacing: 1px;
        .count {
          margin-bottom: 8px;
          font-size: 18px;
          font-weight: bold;
        }
        .delete {
          display: inline-block;
          color: #00c2ad;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
    #cart-order-product-sp {
      .cart-order-product-detail {
        display: grid;
        grid-template-columns: 100px auto;
        gap: 8px;
        margin-bottom: 8px;
      }
      .cart-order-product-image {
        img {
          width: 100%;
          height: auto;
          border: 1px solid #a1a1a1;
        }
      }
      .cart-order-product-info {
        padding-top: 8px;
        .name {
          font-size: 12px;
          font-weight: bold;
          line-height: 1.5em;
        }
        .price {
          margin-bottom: 8px;
          font-size: 12px;
          font-weight: bold;
          line-height: 1.5em;
          span {
            font-size: 10px;
          }
        }
        .shippingAt {
          margin-bottom: 8px;
          font-size: 12px;
          font-weight: bold;
          line-height: 1.5em;
        }
        .retention {
          font-size: 10px;
        }
      }
      .cart-order-product-other {
        display: grid;
        grid-template-columns: auto 50px;
        .cart-order-product-link {
          li:not(:last-child) {
            margin-bottom: 4px;
          }
          a {
            color: #00c2ad;
            font-size: 10px;
          }
        }
        .cart-order-product-count {
          text-align: right;
          .count {
            margin-bottom: 8px;
            font-size: 18px;
            font-weight: bold;
          }
          .delete {
            color: #00c2ad;
            font-size: 10px;
            cursor: pointer;
          }
        }
      }
    }
  `,
});
