const gmoConfig = {
  c_shop: {
    id: process.env.REACT_APP_GMO_C_SHOP_ID,
  },

  keyHash: process.env.REACT_APP_GMO_KEY_HASH,
  pubKey: process.env.REACT_APP_GMO_PUB_KEY,

  api: {
    getCardToken: process.env.REACT_APP_GMO_GET_CARD_TOKEN,
  },
};

export default gmoConfig;
