/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { sanitizer } from "../../../utility/sanitize";
import { BasicDataJson } from "../../../modules/event";
import { EventInfo } from "utility";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { changeStyleWithHosting } from "utility/hosting";
import { useI18n } from "hooks/i18n/i18n";
import { XShareButton } from "ui/XShareButton";
import { FacebookShareButton } from "ui/FacebookShareButton";

interface Props {
  eventInfo: EventInfo;
  basicData: BasicDataJson;
}

// const Container = styled.div`
//   padding: 30px 0;
//   margin-bottom: 40px;
//   background-color: #fff;
//   @media screen and (min-width: 768px) {
//     padding: 50px 0 50px;
//     margin-bottom: 100px;
//   }
//   > article {
//     max-width: 980px;
//     width: 90%;
//     margin: 0 auto;
//   }
// `;

const EventDetailInfo: React.FC<Props> = (props) => {
  const hostingEventPageStyleMap =
    changeStyleWithHosting().pageSettings.eventPage;

  const classesEventDetailDatetimeDesign = eventDetailDatetimeDesign();
  const { t } = useI18n();

  const notice = props.eventInfo.notice || props.basicData?.cautions || ""; // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing

  return (
    <React.Fragment>
      <div
        css={classesEventDetailDatetimeDesign.eventOverview}
        dangerouslySetInnerHTML={{
          __html: sanitizer(props.eventInfo.description),
        }}
      />

      {hostingEventPageStyleMap.isDisplayShareButton &&
        !props.eventInfo.isHide && <ShareButton event={props.eventInfo} />}

      {notice && (
        <ExpansionPanel
          css={classesEventDetailDatetimeDesign.eventExpansionPanel}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <p
              css={classesEventDetailDatetimeDesign.eventExpansionPanelHeading}
            >
              {t("event.buttons.eventNotice")}
            </p>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div
              css={classesEventDetailDatetimeDesign.eventConsiderations}
              dangerouslySetInnerHTML={{
                __html: sanitizer(notice),
              }}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </React.Fragment>
  );
};
export default EventDetailInfo;

const ShareButton: React.FC<{
  event: EventInfo;
}> = ({ event }) => {
  return (
    <div
      css={css`
        display: flex;
        gap: 8px;
        margin-bottom: 40px;
      `}
    >
      <XShareButton
        eventId={event.eventId}
        eventTitle={event.eventTitle}
        hashTag={event.twitterHashTag}
      />

      {changeStyleWithHosting().commonSettings.auth.loginMethod.facebook && (
        <FacebookShareButton eventId={event.eventId} />
      )}
    </div>
  );
};

const eventDetailDatetimeDesign = () => {
  return {
    eventState: css`
      margin-bottom: 15px;
      color: #ff0076;
      font-size: 14px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        margin-bottom: 18px;
        font-size: 20px;
      }
    `,
    eventTitle: css`
      margin-bottom: 10px;
      font-size: 22px;
      line-height: 1.3em;
      white-space: pre-line;
      @media screen and (min-width: 768px) {
        margin-bottom: 25px;
        font-size: 30px;
      }
    `,
    eventArtist: css`
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.3em;
      white-space: pre-line;
      @media screen and (min-width: 768px) {
        margin-bottom: 45px;
        font-size: 20px;
      }
    `,
    eventOverview: css`
      margin-bottom: 40px;
      line-height: 1.5em;
      img {
        max-width: 100%;
      }
    `,
    eventExpansionPanel: css`
      width: 100%;
    `,
    eventExpansionPanelHeading: css`
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    `,
    eventConsiderations: css`
      .heading {
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: bold;
      }
      .text {
        margin-top: 10px;
        font-size: 15px;
        line-height: 1.3em;
      }
      .block {
        margin-bottom: 30px;
      }
      a {
        color: #08ccd5;
        text-decoration: underline;
      }
    `,
  };
};
