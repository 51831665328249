/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { I18nTrans, useI18n } from "hooks/i18n/i18n";
import { TextLink } from "ui/TextLink";

export const AddToCartButton: React.FC<{
  isOnSale: boolean;
  isSoldOut: boolean;
  isLogined: boolean;
  isAddressRequired: boolean; // if true, user haven't register address.
  isNeedVenueTicket: boolean; // if true, user need to purchase venue ticket.
  ticketUrl: string;
  openLoginModal: () => void;
  addToCart: () => void;
  dataTestId?: string;
}> = (props) => {
  const classes = styles(
    props.isOnSale &&
      !props.isSoldOut &&
      !props.isAddressRequired &&
      !props.isNeedVenueTicket
  );
  const { t } = useI18n();

  if (props.isSoldOut) {
    return (
      <div css={classes.root}>
        <ShoppingCartIcon />
        <p>{t("goods.outOfStockError")}</p>
      </div>
    );
  }

  if (!props.isOnSale) {
    return (
      <div css={classes.root}>
        <ShoppingCartIcon />
        <p>{t("goods.periodError")}</p>
      </div>
    );
  }

  if (!props.isLogined) {
    return (
      <div css={classes.root} onClick={props.openLoginModal}>
        <ShoppingCartIcon />
        <p>{t("goods.cart")}</p>
      </div>
    );
  }

  if (props.isAddressRequired) {
    return (
      <React.Fragment>
        <p css={classes.caution}>
          {t("goods.selectNum.caption1")}
          <TextLink href={"/account/address"}>
            {t("goods.selectNum.caption2")}
          </TextLink>
          {t("goods.selectNum.caption3")}
        </p>
        <div css={classes.root}>
          <ShoppingCartIcon />
          <p>{t("goods.cart")}</p>
        </div>
      </React.Fragment>
    );
  }

  if (props.isNeedVenueTicket) {
    return (
      <React.Fragment>
        <p css={classes.caution}>
          <I18nTrans i18nKey="goods.cautions.noTicket">
            この会場のチケットを持っていません。チケットの購入は
            <TextLink href={props.ticketUrl}>
              {t("goods.cautions.here")}
            </TextLink>
          </I18nTrans>
        </p>
        <div css={classes.root}>
          <ShoppingCartIcon />
          <p>{t("goods.cart")}</p>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div
      css={classes.root}
      onClick={props.addToCart}
      data-testid={props.dataTestId}
    >
      <ShoppingCartIcon />
      <p>{t("goods.cart")}</p>
    </div>
  );
};

const styles = (enable: boolean) => ({
  root: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    margin-bottom: 20px;
    background-color: ${enable ? "#00c2ae" : "#e0e0e0"};
    cursor: pointer;
    svg {
      fill: #fff;
    }
    p {
      margin-left: 8px;
      color: #fff;
    }
  `,
  caution: css`
    margin-bottom: 10px;
    line-height: 1.3em;
  `,
});
