/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useEffect } from "react";
import { LoaderLayout } from "../../components/atoms/loader/LoaderLayout";
import { useAccountsAuthenticator } from "./useLoginAuthenticator";
import { Login } from "./Login";

/**
 * spwnサービス（現状ファンクラブのみ）がログインを行うために利用する
 *
 * - ログインが確認できるまでローディングを表示し、ログインしていない場合はログインページを表示する
 * - ログインが確認できた場合は、セッションCookie作成APIをリクエストし、cookieを埋め込み元のサービスへリダイレクトを行う
 */
export const AccountsAuthenticator: React.FC = () => {
  const { status } = useAccountsAuthenticator();

  useEffect(() => {
    /**
     * エラーの場合、簡易的なアラートダイアログを出している
     * FIXME portalトップページへの導線を用意する。そのまま繰り返しログイン施行させても失敗するので。
     */
    if (status.type === "error") {
      window.alert(status.msg);
    }
  }, [status]);

  /**
   * cookieセット完了後、リダイレクト完了するまでローディング表示しておく
   */
  if (status.type === "loading" || status.type === "complete") {
    // FIXME LoaderLayout renderでcss errorでてる
    return <LoaderLayout />;
  }

  return <Login />;
};

/**
 * AccountAppに渡すreturn_urlは、この関数を利用してエンコードを行う。
 */
export const encodeReturnUrl = ({ returnUrl }: { returnUrl: string }) => {
  return encodeURIComponent(returnUrl);
};

/**
 * AccountAppで使用するreturn_urlは、この関数を利用してデコードを行う。
 */
export const decodeReturnUrl = ({
  returnUrl,
}: {
  returnUrl: string | undefined;
}) => {
  // @ts-expect-error TS2345
  return decodeURIComponent(returnUrl);
};
