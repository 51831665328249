/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/core";
import React, { useState, useEffect, ComponentProps } from "react";
import { GiftItemData, StreamingType } from "../../modules/streaming";
import icon_up_arrow from "../../designs/images/icon_up_arrow.png";
import { EventInfo } from "../../utility";
import {
  getMsgListElement,
  getMsgListValue,
  msgScrollBottom,
} from "utility/streaming";
import { StreamingCommentItem } from "./StreamingCommentItem";
import { Timestamp } from "@google-cloud/firestore";
import { authState } from "modules/auth";
import { useI18n } from "hooks/i18n/i18n";
import { useFilteringComment } from "hooks/Streaming/useFilteringComment";
import type { THEOPlayer } from "@spwn/types/external";
import type { ProductData, UserMessage } from "@spwn/types/firebase/firestore";

type Props = {
  eventStart: Timestamp;
  streamingType: StreamingType;
  player: THEOPlayer;
  eventInfo: EventInfo;
  giftItemMap: { [key: string]: GiftItemData };
  currentVideoId: string; // !!! don't allow null !!!
  displayComments: UserMessage[];
  eventGoodsMap: { [goodsId: string]: ProductData };
  user: authState["user"];
  isDarkModeEnabled: boolean;
  THEOPlayerElementHeight?: number;
  commentFilterState: string;
  banComment: ComponentProps<typeof StreamingCommentItem>["msgBanFunction"];
  deleteComment: ComponentProps<
    typeof StreamingCommentItem
  >["msgDeleteFunction"];
};

const StreamingComment: React.FC<Props> = (props) => {
  const [autoScroll, setAutoScroll] = useState<boolean>(true);
  const classesStreamingCommentDesign = streamingCommentDesign({
    streamingType: props.streamingType,
    THEOPlayerElementHeight: props.THEOPlayerElementHeight,
  });
  const classesHideCommentsStyles = hideCommentsStyles();
  const { t } = useI18n();
  // NGワードをフィルタリングしたものを変数に格納する
  const filteringComments = useFilteringComment(props.displayComments);

  useEffect(() => {
    changeScrollState();
    msgScrollBottom();
  }, []);

  useEffect(() => {
    // scroll to bottom when auto scroll mode and displayComments is updated
    if (autoScroll) {
      msgScrollBottom();
    }
  }, [autoScroll, props.displayComments]);

  const changeScrollState = () => {
    const { innerElement, listElement } = getMsgListElement();
    // HOTFIX: テストの場合は onscroll を無視したい
    if (process.env.NODE_ENV !== "test") {
      // @ts-expect-error TS18047
      listElement.onscroll = () => {
        const bottomPosition = getMsgListValue(innerElement, listElement);
        // @ts-expect-error TS18047
        const scrollValue = listElement.scrollTop;
        if (bottomPosition <= scrollValue) {
          setAutoScroll(true);
        } else {
          setAutoScroll(false);
        }
      };
    }
  };

  /**
   * ギフトアイテムの金額リストを作成する
   */
  const giftAmountList: number[] = [];
  if (props.giftItemMap) {
    Object.keys(props.giftItemMap).forEach((key) => {
      // @ts-expect-error TS2532
      const value = props.giftItemMap[key].values.paid;
      giftAmountList.push(value);
    });
    giftAmountList.sort((a, b) => a - b);
  }

  if (props.commentFilterState === "none") {
    return (
      <div css={classesStreamingCommentDesign.root}>
        <div id="msg-list-inner" css={classesStreamingCommentDesign.inner}>
          <div id="msg-list-layout">
            <div css={classesStreamingCommentDesign.list}>
              <div css={classesHideCommentsStyles.root}>
                <p>{t("streaming.commentDisplay.hideview")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div css={classesStreamingCommentDesign.root}>
      <div id="msg-list-inner" css={classesStreamingCommentDesign.inner}>
        <div id="msg-list-layout">
          <div css={classesStreamingCommentDesign.list}>
            {filteringComments.map((el, i: number) => {
              const isCommentUser = el.uid === props.user.uid;

              return (
                <React.Fragment key={i}>
                  <StreamingCommentItem
                    // @ts-expect-error TS2322
                    eventId={props.eventInfo._id}
                    message={el}
                    isCommentUser={isCommentUser}
                    isDarkModeEnabled={props.isDarkModeEnabled}
                    giftItemMap={props.giftItemMap}
                    eventGoodsMap={props.eventGoodsMap}
                    giftAmountList={giftAmountList}
                    msgBanFunction={async () => props.banComment(el._id)}
                    msgDeleteFunction={async () => props.deleteComment(el._id)}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div id="msgEnd"></div>
      </div>
      {autoScroll ? (
        <div
          id="back_to_bottom"
          css={classesStreamingCommentDesign.button}
          className="hide"
          onClick={() => msgScrollBottom()}
        >
          <img src={icon_up_arrow} alt="" />
        </div>
      ) : (
        <div
          id="back_to_bottom"
          css={classesStreamingCommentDesign.button}
          onClick={() => msgScrollBottom()}
        >
          <img src={icon_up_arrow} alt="" />
        </div>
      )}
    </div>
  );
};

interface StreamingCommentDesignProps {
  streamingType: string;
  THEOPlayerElementHeight?: number;
}
const streamingCommentDesign = (props?: StreamingCommentDesignProps) => {
  let actionAreaHeight: SerializedStyles | null;
  // @ts-expect-error TS18048
  if (props.streamingType === "Live") {
    // @ts-expect-error TS18048
    actionAreaHeight = props.THEOPlayerElementHeight
      ? css`
          height: calc(
            ${
                // @ts-expect-error TS18048
                props.THEOPlayerElementHeight
              }px - 148px
          );
        `
      : null;
  } else {
    // @ts-expect-error TS18048
    actionAreaHeight = props.THEOPlayerElementHeight
      ? css`
          height: calc(
            ${
                // @ts-expect-error TS18048
                props.THEOPlayerElementHeight
              }px - 46px
          );
        `
      : null;
  }

  const root = css`
    position: relative;
    @media screen and (min-width: 768px) {
      display: block;
      padding-top: 38px;
      border: 1px solid #e0e0e0;
    }
  `;
  const inner = css`
    position: relative;
    height: calc(280px);
    overflow-y: scroll;
    @media screen and (min-width: 768px) {
      width: 100%;
      ${actionAreaHeight}
      min-height: 415px;
    }
  `;
  const list = css`
    padding: 10px 5%;
    @media screen and (min-width: 768px) {
      padding: 10px;
    }
  `;
  const button = css`
    position: absolute;
    bottom: 10px;
    right: 50%;
    width: 30px;
    height: 30px;
    padding: 7px;
    margin-right: -15px;
    background-color: #00c2ae;
    border-radius: 50%;
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
    &.hide {
      visibility: hidden;
      opacity: 0;
    }
    img {
      width: 100%;
    }
  `;

  return {
    root,
    inner,
    list,
    button,
  };
};

const hideCommentsStyles = () => {
  return {
    root: css`
      padding: 10px 10px;
      margin-top: 40px;
      border-radius: 3px;
      background-color: #3e3e3e;
      p {
        color: #fff;
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 1px;
      }
    `,
  };
};

export default StreamingComment;
