/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import LazyLoad from "react-lazyload";

import Card from "@material-ui/core/Card";
import { getDisplayPrice } from "utility/ticket";
import type { ProductData } from "@spwn/types/firebase/firestore";

interface Props {
  item: ProductData;
  index: number;
  hasVariation: boolean;
  moveDetailPage: () => void;
  displaySalesState: JSX.Element;
}
export const GoodsListCapsuleItem: React.FC<Props> = (props) => {
  const classesGoodsList = goodsList();
  const price = getDisplayPrice(props.item);
  return (
    <Card
      css={classesGoodsList.goodsItem}
      variant="outlined"
      onClick={props.moveDetailPage}
      key={props.index}
    >
      <LazyLoad height={"100%"} offset={400} once>
        <img
          className="goodsImage"
          src={props.item.thumbnail}
          alt={props.item.name}
        />
      </LazyLoad>

      <div css={classesGoodsList.goodsItemInfo}>
        <h3>{props.item.name}</h3>
        {props.displaySalesState}
        <p css={classesGoodsList.goodsItemPrice}>
          1回 ¥{price.toLocaleString()}
        </p>
      </div>
    </Card>
  );
};

const goodsList = () => {
  return {
    goodsItem: css`
      position: relative;
      width: 48%;
      margin-bottom: 15px;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        width: 32%;
      }
      ::before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 100%;
        background-color: #e3e3e3;
      }
      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
      h3 {
        min-height: 36px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.3em;
      }
    `,
    goodsItemInfo: css`
      padding: 20px 15px;
    `,
    goodsItemPrice: css`
      font-size: 14px;
    `,
  };
};
