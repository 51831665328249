/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { FC } from "react";
import { LinkButton } from "ui/Button";
import { Center } from "ui/Center";
import { InternetIcon } from "ui/InternetIcon";
import { Stack } from "ui/Stack";

export const NotFoundError: FC<{
  urlToTopPage: string;
}> = ({ urlToTopPage }) => {
  const headerHeight = "65px";
  const footerHeight = "150px";

  return (
    /**
     * @description
     * このCSSは本来ならApp.tsxに置くべきものだが、現状の実装では子コンポーネントに置かざる得ないのでこの実装にしている。
     */
    <section
      css={css`
        display: flex;
        min-height: calc(100vh - ${headerHeight} - ${footerHeight});
        background-color: #fff;
      `}
    >
      <Center>
        <Stack direction={"column"} gap={"24px"}>
          <Center>
            <InternetIcon width={48} height={48} />
          </Center>

          <Stack direction={"column"} gap={"8px"}>
            <h1>このページへアクセスできません。</h1>
            <p>お探しのページは見つかりませんでした。</p>
          </Stack>

          <Center>
            <LinkButton
              variant={"outline"}
              href={urlToTopPage}
              rel={"noopener noreferrer"}
            >
              トップに戻る
            </LinkButton>
          </Center>
        </Stack>
      </Center>
    </section>
  );
};
