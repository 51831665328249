/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useI18n } from "hooks/i18n/i18n";
import { LinkButton } from "components/atoms/button/LinkButton";

export const PurchaseComplete = () => {
  const { t } = useI18n();
  return (
    <ContentLayout
      content={
        <PurchaseCompleteContent
          title={t("modal.settlementComplete.title")}
          contentsComponent={<p>{t("modal.settlementComplete.message")}</p>}
          actionComponent={
            <LinkButton
              url={"/account/settlement/history"}
              label={t("modal.settlementComplete.label")}
            />
          }
        />
      }
    />
  );
};

/**
 * ページコンテンツのレイアウトを決めるコンポーネント
 * FIXME 使えそうならUIフォルダへの切り出し
 */
const ContentLayout = ({
  breadcrumb,
  content,
}: {
  breadcrumb?: JSX.Element;
  content: JSX.Element;
}) => {
  const design = contentLayoutStyles();
  return (
    <div id="contents" css={design.root}>
      {breadcrumb}
      <div css={design.main}>{content}</div>
    </div>
  );
};

const contentLayoutStyles = () => ({
  root: css`
    min-height: calc(100vh - 256px);
    margin: 0 auto;
    background-color: #ffffff;
  `,
  main: css`
    background-color: #ffffff;
    overflow: hidden;
  `,
});

/**
 * ModalBaseComponent からコピー
 * @see https://github.com/balus-co-ltd/spwn/blob/cf17fe9c47a6ee0f032878ab5145d9648bf0b6eb/packages/portal/src/features/PurchaseCompleteModal.tsx#L80
 */
const PurchaseCompleteContent = ({
  title,
  contentsComponent,
  actionComponent,
}: {
  title: string;
  contentsComponent: JSX.Element | null;
  actionComponent: JSX.Element | null;
}) => {
  const classes = contentStyles();

  return (
    <section css={classes.root}>
      <div css={classes.layout}>
        <h2 css={classes.title}>{title}</h2>

        <div css={classes.content}>{contentsComponent}</div>

        <div css={classes.action}>{actionComponent}</div>
      </div>
    </section>
  );
};

const contentStyles = () => {
  return {
    root: css`
      width: 100%;
      min-height: 100vh;
      padding: 50px 5%;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        width: 800px;
        min-height: auto;
        padding: 60px 2.5%;
        margin: 60px auto 0;
      }
    `,
    layout: css`
      @media screen and (min-width: 768px) {
        width: 600px;
        margin: 0 auto;
      }
    `,
    title: css`
      margin-bottom: 50px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      @media screen and (min-width: 768px) {
        margin-bottom: 60px;
      }
    `,
    content: css`
      margin-bottom: 48px;
      text-align: center;
      white-space: pre-line;
      p {
        line-height: 1.3em;
      }
    `,
    action: css``,
  };
};
