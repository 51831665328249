import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";

import { useGetEvent } from "hooks/event/useGetEvent";
import { ticketActions } from "modules/ticket";
import { modalActions } from "modules/modal";
import { fontDinMixin, LinkButton } from "styles";
import { Store } from "store";
import { getEventDisplayInfo, parseQuery } from "utility";
import flame_pc from "designs/images/flame_line_top_pc.svg";
import flame_sp from "designs/images/flame_line_top.svg";
import { useGetPlaceList } from "../../hooks/event/useGetPlaceList";

const Container = styled.div`
  min-height: calc(100vh - 436px);
  padding: 40px 0;
  margin: 0 auto;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    min-height: calc(100vh - 280px);
  }
  .receive_ticket {
    width: 90%;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 740px;
    }
  }
  .myticket {
    .ticket_flame {
      width: 100%;
      background-color: #fff;
      &.flame_pc {
        display: none;
        @media screen and (min-width: 768px) {
          display: inline;
        }
      }
      &.flame_sp {
        display: inline;
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }
  }
  .myticket_layout {
    padding: 0 5% 20px;
    margin-top: -5px;
    border-right: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
    .info {
      padding-bottom: 15px;
      border-bottom: 1px solid #d2d2d2;
      .datetime {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: bold;
        span img {
          height: 18px;
        }
      }
      .event {
        a {
          color: #54d8dc;
          font-size: 14px;
          font-weight: bold;
          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
        }
      }
    }
    .item {
      padding-top: 15px;
      &:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 1px solid #d4d4d4;
      }
      .place {
        font-size: 12px;
        margin-bottom: 8px;
      }
      .name {
        font-weight: bold;
      }
      .volume {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;
        .price {
          padding-bottom: 10px;
          font-weight: bold;
        }
        .num {
          ${fontDinMixin};
          font-size: 40px;
          span {
            font-size: 20px;
          }
        }
      }
    }
  }
`;

export const TicketReceive: React.FC = () => {
  const user = useSelector((state: Store) => state.auth.user);
  const location = useSelector((state: Store) => state.router.location);
  const tickets = useSelector((state: Store) => state.ticket.eventTickets);

  // query解析
  const queryObject = parseQuery(location.search) as { [key: string]: string };
  // TODO: 本来の型は右のだけど動作が違うから型修正してる { [key: string]: string | string[]; }

  const eventId =
    queryObject != null && queryObject.eid !== undefined
      ? queryObject.eid
      : null;

  const ticketId =
    queryObject != null && queryObject.tid !== undefined
      ? queryObject.tid
      : null;

  const ticketNumber =
    queryObject != null && queryObject.tnum !== undefined
      ? queryObject.tnum
      : null;

  const sharingCode =
    queryObject != null && queryObject.code !== undefined
      ? queryObject.code
      : null;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ticketActions.fetchEventTickets.started({ eventId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: event } = useGetEvent({
    eventId,
    ignoreHosting: true, // チケット受け取りはドメイン関係なく受け取れるようにする
  });

  // Place取得
  const placeIds = tickets.map((el) => el.place);
  const { data: places } = useGetPlaceList(placeIds);

  const receiveTicket = () => {
    if (!user.email || !user.emailVerified) {
      dispatch(
        modalActions.toggleError({
          msg: "チケットの受け取りにはemail認証が必要です",
        })
      );
      return;
    }
    const msg = {
      eid: eventId,
      ticketNum: ticketNumber,
      tid: ticketId,
      code: sharingCode,
    };
    dispatch(ticketActions.receiveTicket.started(msg));
  };

  if (
    !eventId ||
    !ticketId ||
    !ticketNumber ||
    !sharingCode ||
    !event ||
    tickets.length === 0
  ) {
    return null;
  }

  const filterTicket = tickets.filter((el) => el._id === ticketId);
  const ticket = filterTicket.length !== 0 ? filterTicket[0] : null;
  const place = places?.find((el) => el.code === ticket?.place);
  if (!ticket || !place) {
    return null;
  }

  const {
    eventTitle,
    artists,
    eventOpenDateFull,
    eventOpenTime,
    eventOpenDay,
  } = getEventDisplayInfo(event);

  return (
    <Container id="contents">
      <div className="receive_ticket">
        <div className="myticket">
          <img src={flame_pc} alt="" className="ticket_flame flame_pc" />
          <img src={flame_sp} alt="" className="ticket_flame flame_sp" />
          <div className="myticket_layout">
            <div className="info">
              <p className="datetime">
                <span>
                  {eventOpenDateFull}({eventOpenDay}) {eventOpenTime}
                </span>
              </p>
              <p className="event">
                <a href="#!">
                  {eventTitle}
                  {artists && ` (${artists})`}
                </a>
              </p>
            </div>
            <div className="item">
              <p className="place">
                {place.areaName} {place.venueName}
              </p>
              <p className="name">{ticket.name}</p>
              <div className="volume">
                <p className="price">¥{ticket.price_jpy.toLocaleString()}</p>
                {/* share ticket count is just one */}
                <p className="num">
                  <span>×</span>
                  {1}
                </p>
              </div>
            </div>
            <LinkButton onClick={receiveTicket}>チケットを受け取る</LinkButton>
          </div>
        </div>
      </div>
    </Container>
  );
};
