/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { GiftItemData } from "modules/streaming";
import type { UserMessage } from "@spwn/types/firebase/firestore";

interface Props {
  message: UserMessage;
  giftItemMap: { [key: string]: GiftItemData };
  giftAmountList: number[];
  msgBanFunction: (idx: unknown) => Promise<void>;
}
export const CommentItemSuperChat: React.FC<Props> = (props) => {
  const { giftItemId } = props.message;

  const giftItem = giftItemId && props.giftItemMap?.[giftItemId];
  // @ts-expect-error TS2339
  const isGiftItemUrl = Boolean(giftItem?.imgUrl);
  // @ts-expect-error TS2339
  const giftItemName = giftItem?.name;

  const superChatClasses = superChatDesign({
    color: props.message.color,
    amount: props.message.amount,
    // @ts-expect-error TS2339
    bgImgUrl: giftItem?.bgImgUrl,
    // @ts-expect-error TS2339
    textColor: giftItem?.textColor,
    // @ts-expect-error TS2339
    padding: giftItem?.padding,
    isGiftItemUrl,
    giftAmountList: props.giftAmountList,
  });

  return (
    <div css={superChatClasses.superChat} onClick={props.msgBanFunction}>
      <div css={superChatClasses.superChatInfoLayout}>
        <div css={superChatClasses.superChatUserInfo}>
          <img
            src={props.message.icon}
            alt={props.message.name}
            className="icon"
          />
          <div css={superChatClasses.superChatName}>
            <p className="name">{props.message.name}</p>
            <p>
              {giftItemName
                ? `${giftItemName}を送りました！`
                : props.message.amount.toLocaleString() + "PAWを送りました！"}
            </p>
          </div>
        </div>
        {props.message.msg && (
          <div css={superChatClasses.userMessage}>
            <p>{props.message.msg}</p>
          </div>
        )}
      </div>

      {isGiftItemUrl && (
        <div css={superChatClasses.superSticker}>
          {/*
           // @ts-expect-error TS2538 */}
          <img src={props.giftItemMap[giftItemId].imgUrl} alt="" />
        </div>
      )}
    </div>
  );
};

type SuperChatDesignProps = {
  color: string;
  amount: number;
  isGiftItemUrl: boolean;
  giftAmountList: number[];
  bgImgUrl: string;
  textColor: string;
  padding: number;
};
const superChatDesign = (props?: SuperChatDesignProps) => {
  // @ts-expect-error TS18048
  let superChatPadding: number = props.padding;
  if (!superChatPadding) {
    // @ts-expect-error TS18048
    if (props.amount < 1000) {
      superChatPadding = 10;
      // @ts-expect-error TS18048
    } else if (1000 <= props.amount && props.amount < 3000) {
      superChatPadding = 20;
      // @ts-expect-error TS18048
    } else if (3000 <= props.amount && props.amount < 5000) {
      superChatPadding = 40;
      // @ts-expect-error TS18048
    } else if (5000 <= props.amount && props.amount < 10000) {
      superChatPadding = 60;
      // @ts-expect-error TS18048
    } else if (10000 <= props.amount) {
      superChatPadding = 100;
    }
  }

  // @ts-expect-error TS18048
  const bgImgUrl = props.bgImgUrl ? "url(" + props.bgImgUrl + ")" : "none";
  // @ts-expect-error TS18048
  const textColor = props.textColor ? props.textColor : "#fff";
  // @ts-expect-error TS18048
  const userInfoWidth = props.isGiftItemUrl ? 78 : 100;

  return {
    superChat: css`
      display: flex;
      justify-content: space-between;
      padding: ${superChatPadding}px 10px;
      color: ${textColor};
      border-radius: 3px;
      background-color: ${
        // @ts-expect-error TS18048
        props.color
      };
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
      background-image: ${bgImgUrl};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    `,
    superChatInfoLayout: css`
      width: ${userInfoWidth}%;
    `,
    superChatUserInfo: css`
      display: flex;
      align-items: center;
      img {
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        background-color: #fff;
        border-radius: 50%;
        object-fit: cover;
      }
    `,
    superChatName: css`
      width: calc(100% - 50px);
      p {
        font-size: 12px;
      }
      .name {
        margin-bottom: 5px;
        font-weight: bold;
      }
    `,
    userMessage: css`
      margin-top: 8px;
      p {
        font-size: 12px;
        line-height: 1.5em;
      }
    `,
    superSticker: css`
      width: 20%;
      img {
        width: 100%;
      }
    `,
  };
};
