/** @jsxRuntime classic /
/** @jsx jsx */
import { css, Global, jsx } from "@emotion/core";
import React, { useState, useEffect, ComponentProps } from "react";
import { useSelector, useDispatch } from "react-redux";
import firebase from "firebase/app";
import { Store } from "../../store";
import QrReader from "react-qr-reader";
import { modalActions } from "../../modules/modal";
import { getSlashSeparatedDateTimeStringFromFbTimestamp } from "utility";
import {
  updateFirestoreDoc,
  fetchFirestoreDocument,
  fetchFirestoreCollectionMap,
} from "'../../utility/firebase";
import {
  decodeTicketQRCodeValue,
  getVenuePayPrice,
  SHIPPING_PLACE_CODE,
} from "utility/ticket";
import { loadingActions } from "../../modules/loading";
import { useI18n } from "hooks/i18n/i18n";
import { QrcodeInfo } from "../../components/qrcode/QrcodeInfo";
import { Button } from "components/atoms/button/Button";
import { QrcodePrint } from "components/qrcode/QrcodePrint";
import type {
  ActiveTransaction,
  Event,
  IssuedTicket,
  ProductData,
} from "@spwn/types/firebase/firestore";

const QrcodeReader: React.FC = () => {
  const { t } = useI18n();
  class DecordingTypeError extends TypeError {}
  const dispatch = useDispatch();
  const uid = useSelector((state: Store) => state.auth.user.uid);
  const classesQrcodeStyle = qrcodeStyle();

  const [qrcodeRowData, setQrcodeRowData] = useState<string>(""); //base64の状態 qrcodeReaderのパッケージで必要
  const [qrcodeJson, setQrcodeJson] =
    useState<ReturnType<typeof makePathDecodedJSON>>(); //qrcodeRowDataをmapに直したもの
  const [eventStatus, setEventStatus] =
    useState<{
      eventName: string;
      eventDate: string;
    }>();
  const [purchasedGoodsStatus, setPurchasedGoodsStatus] =
    useState<ComponentProps<typeof QrcodeInfo>["purchasedGoodsStatus"]>();
  const [isReadError, setIsReadError] = useState<boolean>(false); //qrcodeが指定されたフォーマットに即しているか モギリの際に使用
  const [isFireStorePermissionError, setIsFireStorePermissionError] =
    useState<boolean>(false);
  const [isPrint, setIsPrint] = useState<boolean>(false);

  const didMount = () => {
    if (!uid) {
      dispatch(modalActions.toggleLoginMethod());
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(didMount, []);

  // @ts-expect-error TS7006
  const handleError = (err) => {
    console.error(err);
  };

  // @ts-expect-error TS7006
  const handleScan = (data) => {
    if (data) {
      setQrcodeRowData(data);
    }
  };

  useEffect(() => {
    if (qrcodeRowData !== "") {
      (async () => {
        setIsFireStorePermissionError(false);
        try {
          dispatch(
            loadingActions.toggleLoading({ msg: t("qrcode.loadingQrcode") })
          );
          const data = makePathDecodedJSON(qrcodeRowData);
          if (data) {
            setQrcodeJson(data);
            await fetchTicketData(data);
          }
        } catch (e) {
          dispatch(
            modalActions.toggleError({ msg: t("cart.msg.unexpectedError") })
          );
          console.log(e);
        } finally {
          dispatch(loadingActions.toggleLoading({}));
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrcodeRowData]); // TODO

  const initialState = () => {
    setQrcodeRowData("");
    // @ts-expect-error TS2345
    setQrcodeJson(null);
    // @ts-expect-error TS2345
    setEventStatus(null);
    // @ts-expect-error TS2345
    setPurchasedGoodsStatus(null);
    setIsReadError(false);
  };

  const fetchTicketData = async (ticketData: {
    ticketPath: string;
    eventId: string;
    ticketType: string;
    authCode: string;
    orderId: string;
  }) => {
    if (ticketData.ticketType !== "isGoods") {
      return;
    }
    try {
      const eventTicketData = await fetchFirestoreDocument<IssuedTicket>(
        ticketData.ticketPath
      );
      if (eventTicketData) {
        fetchEventInfo(ticketData.eventId);
        if (ticketData.ticketType === "isGoods") {
          const activetranData =
            await fetchFirestoreDocument<ActiveTransaction>(
              `settlement/${eventTicketData.owner}/activeTransactions/${ticketData.orderId}`
            );
          const goodsCollectionMap =
            await fetchFirestoreCollectionMap<ProductData>(
              `/goods/${ticketData.eventId}/goods`
            );
          if (
            !activetranData.isPurchased ||
            activetranData.status !== "PAYSUCCESS"
          ) {
            dispatch(
              modalActions.toggleNotice({
                msg: t("qrcode.errorQrcode3"),
              })
            );
            return;
          }
          if (activetranData.isCancelled) {
            dispatch(
              modalActions.toggleNotice({
                msg: t("qrcode.errorQrcode4"),
              })
            );
            return;
          }
          if (goodsCollectionMap && activetranData) {
            const goodsList = Object.keys(
              // @ts-expect-error TS2532
              activetranData.eventProductCountMap[ticketData.eventId].goods
            ).map((gid) => {
              return {
                // @ts-expect-error TS2532
                name: goodsCollectionMap[gid].name,
                // @ts-expect-error TS2532
                subClassName: goodsCollectionMap[gid].subClassName,
                // @ts-expect-error TS18047
                count: activetranData.productCountMap[gid],
                // @ts-expect-error TS2345
                price_jpy: getVenuePayPrice(goodsCollectionMap[gid]),
                // @ts-expect-error TS2532
                priority: goodsCollectionMap[gid].priority,
                // @ts-expect-error TS2532
                place: goodsCollectionMap[gid].place,
              };
            });
            const sortedGoodsList = goodsList
              .filter((el) => el.place !== SHIPPING_PLACE_CODE)
              .sort((a, b) => a.priority - b.priority);
            // @ts-expect-error TS2345
            setPurchasedGoodsStatus(sortedGoodsList);
          }
        }
      }
    } catch (e) {
      setIsFireStorePermissionError(true);
      console.log(e);
    }
  };

  // @ts-expect-error TS7006
  const fetchEventInfo = async (eid) => {
    const eventDocument = await fetchFirestoreDocument<Event>(
      `/events/${eid}/`
    );
    if (eventDocument) {
      const eventDataList = {
        eventName: eventDocument.title,
        eventDate: getSlashSeparatedDateTimeStringFromFbTimestamp(
          eventDocument.datetime
        ),
      };
      setEventStatus(eventDataList);
    }
  };

  const makePathDecodedJSON = (value: string) => {
    setIsReadError(false);
    try {
      const { ticketType, eventId, ticketNumber, authCode, orderId } =
        decodeTicketQRCodeValue(value);
      if (!decodeTicketQRCodeValue(value)) {
        setIsReadError(true);
        return;
      }
      if (
        ticketType === undefined ||
        eventId === undefined ||
        ticketNumber === undefined ||
        authCode === undefined
      ) {
        setIsReadError(true);
        return;
      }
      if (!ticketType.match(/isChecked|isRedeemed|isGoods/)) {
        setIsReadError(true);
        return;
      }
      return {
        ticketPath: `/tickets/${eventId}/issuedTickets/${ticketNumber}`,
        eventId,
        ticketType,
        authCode,
        orderId,
      };
    } catch (e) {
      const hasMessage = (e: unknown): e is { message: string } =>
        // @ts-expect-error TS18047
        typeof e === "object" && "message" in e;
      // @ts-expect-error TS2769
      throw new DecordingTypeError(hasMessage(e) && e.message);
    }
  };

  const checkTicket = async () => {
    try {
      dispatch(
        loadingActions.toggleLoading({ msg: t("qrcode.loadingQrcode") })
      );
      //firestoreから情報をとってくる
      const eventTicketData = await fetchFirestoreDocument<IssuedTicket>(
        // @ts-expect-error TS18048
        qrcodeJson.ticketPath
      );

      // check if doc exist / authCodeの認証
      if (
        !eventTicketData ||
        // @ts-expect-error TS18048
        (qrcodeJson.authCode &&
          // @ts-expect-error TS18048
          qrcodeJson.authCode !== eventTicketData.authCode)
      ) {
        throw new DecordingTypeError("Authcode error");
      }

      // @ts-expect-error TS18048
      if (qrcodeJson.ticketType === "isChecked" && !eventTicketData.isChecked) {
        const settingTicketData = {
          isChecked: true,
          checkedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          whoChecked: uid,
        };
        // @ts-expect-error TS18048
        await updateFirestoreDoc(qrcodeJson.ticketPath, settingTicketData);
        dispatch(modalActions.toggleNotice({ msg: t("qrcode.loadQrcode") }));
        return;
      } else if (
        // @ts-expect-error TS18048
        (qrcodeJson.ticketType === "isRedeemed" ||
          // @ts-expect-error TS18048
          qrcodeJson.ticketType === "isGoods") &&
        !eventTicketData.isRedeemed
      ) {
        const settingTicketData = {
          isRedeemed: true,
          whoRedeemed: uid,
          redeemedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        };
        // @ts-expect-error TS18048
        await updateFirestoreDoc(qrcodeJson.ticketPath, settingTicketData);
        dispatch(
          modalActions.toggleNotice({
            msg: `${
              // @ts-expect-error TS18048
              qrcodeJson.ticketType === "isGoods"
                ? t("qrcode.goods")
                : t("qrcode.redeem")
            }${t("qrcode.checkQrcode")}`,
          })
        );
        return;
      } else {
        dispatch(modalActions.toggleNotice({ msg: t("qrcode.useTicket") }));
        return;
      }
    } catch (e) {
      if (e instanceof DecordingTypeError) {
        dispatch(modalActions.toggleError({ msg: t("qrcode.errorQrcode1") }));
        console.log(e.message);
      } else {
        dispatch(modalActions.toggleError({ msg: t("qrcode.errorQrcode2") }));
        console.log(e);
      }
    } finally {
      //読み込んだ後に初期化
      setQrcodeRowData("");
      // @ts-expect-error TS2345
      setPurchasedGoodsStatus(null);
      // @ts-expect-error TS2345
      setQrcodeJson(null);
      // @ts-expect-error TS2345
      setEventStatus(null);
      setIsReadError(false);
      dispatch(loadingActions.toggleLoading({}));
    }
  };

  if (isPrint) {
    return (
      <QrcodePrint
        // @ts-expect-error TS2322
        eventStatus={eventStatus}
        // @ts-expect-error TS2322
        purchasedGoodsStatus={purchasedGoodsStatus}
        closePrint={() => setIsPrint(false)}
      />
    );
  }

  if (!(uid && !isFireStorePermissionError)) {
    return (
      <div css={classesQrcodeStyle.root}>
        <Global styles={globalStyle} />
        <div css={classesQrcodeStyle.headline}>
          <h2>QRCODE READER</h2>
        </div>

        <p css={classesQrcodeStyle.cautionText}>{t("qrcode.noLogin")}</p>
        <div
          css={css`
            max-width: 500px;
            margin: 0 auto;
          `}
        >
          <Button
            text={t("qrcode.login")}
            onClick={() => {
              dispatch(modalActions.toggleLoginMethod());
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div css={classesQrcodeStyle.root}>
      <Global styles={globalStyle} />
      <div css={classesQrcodeStyle.headline}>
        <h2>QRCODE READER</h2>
      </div>

      <div css={classesQrcodeStyle.qrcodeReader}>
        <QrReader delay={300} onError={handleError} onScan={handleScan} />
      </div>

      {isReadError && <p>{t("qrcode.errorQrcode1")}</p>}

      {qrcodeJson && qrcodeJson.ticketType === "isGoods" ? (
        <React.Fragment>
          {purchasedGoodsStatus && (
            <QrcodeInfo
              // @ts-expect-error TS2322
              eventStatus={eventStatus}
              purchasedGoodsStatus={purchasedGoodsStatus}
            />
          )}

          <div
            css={css`
              max-width: 500px;
              margin: 0 auto 16px;
            `}
          >
            <Button
              text={
                !(qrcodeRowData && purchasedGoodsStatus)
                  ? t("qrcode.loading")
                  : t("qrcode.cutTicket")
              }
              disabled={!purchasedGoodsStatus}
              onClick={checkTicket}
            />
          </div>

          <div
            css={css`
              max-width: 500px;
              margin: 0 auto 16px;
            `}
          >
            <Button
              text={"チケットを印刷する"}
              onClick={() => setIsPrint(true)}
            />
          </div>

          <div
            css={css`
              max-width: 500px;
              margin: 0 auto;
            `}
          >
            <Button
              text={t("qrcode.reset")}
              color={"#E84B0F"}
              disabled={!(qrcodeRowData && qrcodeJson)}
              onClick={initialState}
            />
          </div>
        </React.Fragment>
      ) : (
        <div
          css={css`
            max-width: 500px;
            margin: 0 auto;
          `}
        >
          <Button
            text={!qrcodeRowData ? t("qrcode.loading") : t("qrcode.cutTicket")}
            disabled={!qrcodeJson}
            onClick={checkTicket}
          />
        </div>
      )}
    </div>
  );
};

const globalStyle = css`
  #container {
    background-color: #000;
  }
`;

const qrcodeStyle = () => {
  return {
    root: css`
      width: 90%;
      max-width: 980px;
      min-height: calc(100vh - 255px);
      padding: 32px 0 80px;
      margin: 0 auto;
    `,
    headline: css`
      margin-bottom: 40px;
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        margin-bottom: 60px;
      }
      h2 {
        font-family: "din-condensed", sans-serif;
        font-weight: 400;
        font-style: normal;
        padding: 5px 0 0 15px;
        margin-bottom: 5px;
        color: #fff;
        font-size: 35px;
        line-height: 1em;
        border-left: 5px solid #00c2ae;
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
          letter-spacing: 3px;
        }
      }
    `,
    cautionText: css`
      color: #ffffff;
      font-size: 24px;
      text-align: center;
      padding: 240px 0;
    `,
    infoText: css`
      color: #ffffff;
    `,
    qrcodeReader: css`
      width: 100%;
      max-width: 480px;
      margin: 0 auto 40px;
    `,
  };
};
export default QrcodeReader;
