import { css } from "@emotion/react";

export const subPageMixin = css`
  padding: 40px 0 60px;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    padding: 0 0 130px;
    margin: 0 auto;
  }
  h2 {
    margin-bottom: 40px;
    font-size: 20px;
    text-align: center;
    font-weight: normal;
    @media screen and (min-width: 768px) {
      font-size: 25px;
    }
  }
  p {
    font-size: 15px;
    line-height: 1.5em;
    letter-spacing: 1px;
    &.law_lead_sentence,
    &.contact_sentence {
      margin-bottom: 40px;
    }
    &.write_spwn {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  a {
    color: #11d4db;
    font-weight: bold;
    text-decoration: underline;
  }
  .text_block {
    margin-bottom: 25px;
    h3 {
      margin-bottom: 15px;
      line-height: 1.3em;
    }
    h4 {
      margin-bottom: 10px;
      line-height: 1.3em;
    }
    p {
      padding-left: 1em;
      margin-bottom: 10px;
      text-indent: -1em;
      &.indent_none {
        padding-left: 0;
        text-indent: inherit;
      }
      a {
        font-size: 15px;
      }
    }
    ul {
      padding-left: 1.4em;
      li {
        font-size: 15px;
        padding-left: 1.8em;
        margin-bottom: 10px;
        text-indent: -1.8em;
      }
    }
    input,
    textarea {
      width: 100%;
      font-size: 16px;
      padding: 10px;
      border: 1px solid #d4d4d4;
    }
    textarea {
      height: 250px;
    }
    select {
      width: 100%;
      height: 42px;
      font-size: 16px;
      font-weight: normal;
    }
  }
`;
