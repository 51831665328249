/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ticketActions } from "modules/ticket";
import { Store } from "store";
import { fontDinMixin } from "styles";

/**
 * display survey data
 */
export const SurveyResult: React.FC<{
  eventId: string;
}> = (props) => {
  const eventSurveyResult = useSelector(
    (state: Store) => state.ticket.eventSurveyResult
  );

  const dispatch = useDispatch();

  const classes = styles();

  useEffect(() => {
    dispatch(
      ticketActions.fetchEventTicketSurveyResult.started({
        eventId: props.eventId,
      })
    );
    return () => {
      dispatch(ticketActions.clearStateByKey("eventSurveyResult"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!eventSurveyResult) {
    return null;
  }

  return (
    // sass使えなかったので一旦
    <div css={classes.root}>
      <div css={classes.heading}>
        <h2>Survey</h2>
        <p>アンケート</p>
      </div>
      <div css={classes.content}>
        {eventSurveyResult.map((surveyResult, i) => {
          return (
            <div key={i} css={classes.result}>
              <p css={classes.caption}>{surveyResult.caption}</p>
              <p css={classes.inputText}>{surveyResult.inputText}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styles = () => ({
  root: css`
    margin-bottom: 60px;
  `,
  heading: css`
    margin-bottom: 20px;
    letter-spacing: 1px;
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
    }
    h2 {
      ${fontDinMixin};
      padding: 5px 0 0 15px;
      margin-bottom: 5px;
      font-size: 35px;
      border-left: 5px solid #01ffe1;
      @media screen and (min-width: 768px) {
        margin-bottom: 0;
        letter-spacing: 3px;
      }
    }
    p {
      padding-left: 21px;
      font-size: 12px;
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }
  `,
  content: css`
    max-width: 740px;
    width: 100%;
    margin: 0 auto;
  `,
  result: css`
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  `,
  caption: css`
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.3em;
  `,
  inputText: css`
    font-size: 16px;
  `,
});
