import { CapsuleGoods } from "@spwn/types/firebase/firestore";
import type {
  ReqPurchaseCapsule,
  ResPurchaseCapsule,
  ErrorResponsePurchaseCapsule,
} from "@spwn/types/functions";
import appConfig from "constants/appConfig";
import { purchaseActions } from "modules/purchase";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { fetchFbToken } from "utility/firebase";

export type CapsuleErrorType = "unknown" | "soldout";
export type ErrorPurchaseCapsule = ErrorResponsePurchaseCapsule["errors"];

export const usePurchaseCapsule = () => {
  const fingerprint = useSelector((state: Store) => state.purchase.fingerprint);
  const [loading, setLoading] = useState(false);
  // @ts-expect-error TS2345
  const [error, setError] = useState<ErrorPurchaseCapsule>(null);
  const [resultLotteryProducts, setResultLotteryProducts] = useState<
    CapsuleGoods[]
  >([]);
  const [resultBonusProducts, setResultBonusProducts] = useState<
    CapsuleGoods[]
  >([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(purchaseActions.refreshFingerprint());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const purchaseCapsule = useCallback(
    async (reqBody: Omit<ReqPurchaseCapsule, "fingerprint">) => {
      try {
        setLoading(true);
        const responseJson = await callPurchaseCapsule({
          ...reqBody,
          fingerprint,
        });
        // error process
        if (responseJson.type === "error") {
          const { errors } = responseJson.body;
          if (errors?.type && errors?.msg) {
            setError({
              type: errors.type,
              msg: errors.msg,
            });
            return;
          }
          setError({
            type: "UNKNOWN_ERROR",
            msg: "予期せぬエラーが発生しました。",
          });
          return;
        }

        // success process. set result products.

        const lotteryProducts = responseJson.body.pickedProducts
          .filter((p) => p.capsuleGoodsType === "lottery")
          .flatMap((el) => [...Array(el.count)].map((_) => el.product));
        const bonusProducts = responseJson.body.pickedProducts
          .filter((p) => p.capsuleGoodsType === "bonus")
          .flatMap((el) => [...Array(el.count)].map((_) => el.product));

        // 通常排出グッズだけ順番をランダムに入れ替える(理由: バックエンドからのデータがソートされてしまっていて、そのまま表示すると偏って排出されたようにも見えてしまうため)
        setResultLotteryProducts(shuffle(lotteryProducts));
        setResultBonusProducts(bonusProducts);
      } catch (error) {
        console.error(error);
        setError({
          type: "UNKNOWN_ERROR",
          msg: "予期せぬエラーが発生しました。",
        });
      } finally {
        setLoading(false);
        dispatch(purchaseActions.refreshFingerprint());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fingerprint]
  );

  const clearResult = useCallback(() => {
    setResultLotteryProducts([]);
    setResultBonusProducts([]);
  }, []);

  return {
    loading,
    error,
    resultLotteryProducts,
    resultBonusProducts,
    purchaseCapsule,
    clearResult,
  };
};

/**
 * Call purchaseCapsule API
 * For inference, return type have same fields in all cases. 推論のため、返り値に同じフィールドを持たせている。
 * @param reqBody
 * @returns
 */
const callPurchaseCapsule = async (
  reqBody: ReqPurchaseCapsule
): Promise<
  | {
      type: "success";
      body: ResPurchaseCapsule;
    }
  | {
      type: "error";
      body: ErrorResponsePurchaseCapsule;
    }
> => {
  // return {
  //   type: "success",
  //   body: {
  //     pickedProducts: getMockResults(),
  //   }
  // }
  // buy process
  // isCrowding入れた方がいい？
  const fbToken = await fetchFbToken();
  const endpoint = appConfig.CloudFunctions.purchaseCapsule; // "http://localhost:5000/bls-boost/us-central1/purchaseCapsule"
  // @ts-expect-error TS2769
  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `'Bearer ${fbToken}`,
    },
    body: JSON.stringify(reqBody),
  });
  if (response.status === 200) {
    const responseBody = await response.json();
    return {
      type: "success",
      body: responseBody,
    };
  }
  const errorResponse: ErrorResponsePurchaseCapsule = await response.json();
  console.info(errorResponse);
  return {
    type: "error",
    body: errorResponse,
  };
};

/**
 * @description Fisher-Yatesアルゴリズムを用いて、配列をランダムに並び替えて返す関数
 * @param _array ランダムに並び替えたい配列()
 * @returns ランダムに並び替えた配列
 */
export const shuffle = <T>(array: T[]): T[] => {
  const _array = [...array];
  for (let i = _array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // swap
    [_array[i], _array[j]] = [_array[j], _array[i]] as [T, T];
  }
  return _array;
};

// TODO: 仮コメントアウト 不要なら削除してください

// const getMockResults = (): ResPurchaseCapsule["pickedProducts"] => [
//   {
//     product: {
//       subClassName: "アイテムE",
//       priority: 141,
//       eventLongTitle: "ガチャグッズテスト",
//       place: "000-OPENLOGI",
//       productType: "goods",
//       eventTitle: "ガチャテスト",
//       purchaseLimit: 999999,
//       subClass: 4,
//       thumbnail:
//         "https://public.spwn.jp/spwn-store/goods/210915-gacha-test/unnamed.png",
//       specification: "",
//       price_jpy: 330,
//       eid: "210915-gacha-test",
//       isAddressRequired: true,
//       name: "ガチャA",
//       serviceFee: 0,
//       total: 999999,
//       display: true,
//       remaining: 999999,
//       hashtags: null,
//       isAddressRequiredNoFee: false,
//       releaseDateTime: null,
//       description: "",
//       cartStock: 999999,
//       class: 1,
//       classThumbnails: [],
//       className: "ガチャA",
//       closeDateTime: null,
//       _id: "G210915-gacha-tes_0010401C02",
//       _refPath:
//         "goods/210915-gacha-test/salesData/G210915-gacha-tes_0010401C02",
//       ref: null,
//       variantThumbnail:
//         "https://public.spwn.jp/spwn-store/goods/210915-gacha-test/itemE.jpg",
//     },
//     count: 1,
//   },
// ];
