import React, { useCallback } from "react";
import { i18nextT } from "hooks/i18n/i18n";

import { Button } from "components/atoms/button/Button";
import { TextLink } from "ui/TextLink";

export const FallbackOnUnexpectedError = () => {
  const refresh = useCallback(() => {
    window.location.assign(window.location.origin);
  }, [window.location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "3rem",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          padding: "3rem",
          display: "flex",
          backgroundColor: "white",
          flexDirection: "column",
          alignItems: "center",
          gap: "3rem",
        }}
      >
        <h1>{i18nextT("cart.msg.unexpectedError")}</h1>
        <TextLink href={"https://spwn.zendesk.com/hc/ja"}>
          {i18nextT("common.sidebar.nav.contact")}
        </TextLink>
        <Button text={"トップページに戻る"} onClick={refresh} />
      </div>
    </div>
  );
};
