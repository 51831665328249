/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { useI18n } from "hooks/i18n/i18n";
import type { Goods } from "./goodsModel";
import { TenantGoodsCartEntry } from "./TenantGoodsCartEntry";
import { modalActions } from "modules/modal";
import { useAddItemsToCart } from "./useAddItemsToCart";
import { SalesPeriod } from "./TenantGoodsInfoSalesPeriod";
import { Shipment } from "./TenantGoodsInfoShipment";
import { Tags } from "./TenantGoodsInfoTags";
import { Description } from "./TenantGoodsInfoDescription";
import { Notes } from "./TenantGoodsInfoNotes";

type Props = {
  goods: Goods;
};
export const TenantGoodsInfo: React.FC<Props> = ({ goods }) => {
  const isLogined = useSelector((state: Store) => Boolean(state.auth.user.uid));
  const isAddressRegistered = useSelector(
    (state: Store) => state.auth.isAddressRegistered
  );

  const dispatch = useDispatch();
  const { t } = useI18n();
  const { addItemsToCart } = useAddItemsToCart();

  const isOnSale = goods.salesStatus === "onSale";
  // 商品がすべて売り切れの場合はカートボタンを売り切れ状態にする
  const isSoldOutCartButton =
    goods.variants.length > 0
      ? goods.variants.every((variant) => variant.inventoryQuantity === 0)
      : goods.inventoryQuantity === 0;

  const openLoginModal = () => {
    dispatch(modalActions.toggleLoginMethod());
  };

  return (
    <div css={styles.root}>
      <h2>{goods.name}</h2>
      <p css={styles.price}>
        {
          // if there is valiation
          goods.variants.length > 1
            ? t("goods.each", {
                price: `¥${goods.price.toLocaleString()}`,
              })
            : `¥${goods.price.toLocaleString()}`
        }
      </p>

      {/* 既存のグッズ詳細に記載されている販売期間をこちらに移動させる必要あり */}
      {goods.salesPeriodType !== "none" && (
        <div css={styles.section}>
          <SalesPeriod
            releasedAt={goods.releasedAt}
            closedAt={goods.closedAt}
          />
        </div>
      )}

      <TenantGoodsCartEntry
        goods={goods}
        addToCart={addItemsToCart}
        addToCartButtonStyleProps={{
          isOnSale,
          isSoldOut: isSoldOutCartButton,
          isLogined,
          isAddressRequired: isLogined && !isAddressRegistered,
          isNeedVenueTicket: false,
          ticketUrl: "",
          openLoginModal,
        }}
      />

      {goods.fulfillmentShipment && (
        <div css={styles.section}>
          <Shipment shipment={goods.fulfillmentShipment.title} />
        </div>
      )}

      <div css={styles.section}>
        <Tags tags={goods.tags} />
      </div>

      {
        // グッズ説明が未入力の場合は見出しごと表示しない
        goods.description !== "" && (
          <div css={styles.section}>
            <Description description={goods.description} />
          </div>
        )
      }

      <div css={styles.section}>
        <Notes>
          <NanamiNotes />
        </Notes>
      </div>
    </div>
  );
};

// TODO: ハードコーディングしてる
const NanamiNotes: React.FC = () => {
  return (
    <div css={styles.notes.content}>
      <p>
        不良品以外の返品・交換は致しかねます。予めご了承ください。
        <br />
        尚、不良品のお問い合わせはお受け取りから1週間以内に
        <a
          href={`${window.location.origin}/contact`}
          target="_blank"
          rel="noreferrer"
        >
          【お問い合わせ窓口】
        </a>
        へお問い合わせください。
        <br />
        ※グッズが購入できない、届かない場合お気軽にお問い合わせください。
        <br />
        注文内容や商品状態等により注文の取り消し・返品をお受けできない場合がございます。
      </p>
    </div>
  );
};

const styles = {
  root: css`
    width: 90%;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: calc(41% - 17px);
    }
    h2 {
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 1.5em;
    }
  `,
  price: css`
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: bold;
  `,
  section: css`
    margin-bottom: 24px;
  `,
  notes: {
    heading: css`
      font-size: 14px;
      line-height: 1;
      font-weight: 700;
      margin-bottom: 4px;
      color: #3c3c3c;
    `,
    content: css`
      p {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.5;
        color: #3c3c3c;
      }
      a {
        color: #00c2ae;
      }
    `,
  },
};
