/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useI18n } from "hooks/i18n/i18n";
import { replaceBr, getNowTimestamp } from "utility";
import { getDotSeparatedDayTimeString } from "utility/index";
import type { ProductData } from "@spwn/types/firebase/firestore";

interface Props {
  ticket: ProductData;
}
export const LiveListItemState: React.FC<Props> = (props) => {
  const classes = styles();
  const { t } = useI18n();
  /**
   * 販売終了日時を表示する閾値
   */
  const THRESHOLD_YEAR = 1;
  const THRESHOLD_SECOND = 365 * 24 * 60 * 60 * THRESHOLD_YEAR;

  /**
   * チケットの種類に応じて見出しを変更する
   * ライブチケットの場合は、「チケット販売期間」を表示する
   * アーカイブ販売の場合は、「アーカイブ販売期間」を表示する
   */
  const salesPeriodText =
    props.ticket.streamingType === "Live"
      ? t("event.saleInfo.ticket.ticketSalesPeriod")
      : t("event.saleInfo.ticket.archiveSalesPeriod");
  const salesPeriodColor =
    props.ticket.streamingType === "Live" ? "#01bea8" : "#2c7eee";

  /**
   * チケットの期間を表示する
   * チケット販売の場合は、チケットの販売期間を表示する
   * アーカイブ販売の場合、考えられるのは以下の2パターンのどちらかを表示する
   *  - 販売期間がある場合「10.23(Fri)21:00 〜 01.11(Mon)23:59」の形式で表示する
   *  - 販売期間がない場合「10.23(Fri)21:00 〜 」の形式で表示する
   */
  const releaseDateTime = props.ticket.releaseDateTime.seconds;
  const closeDateTime = props.ticket.closeDateTime.seconds;

  const displayCloseDate = closeDateTime < getNowTimestamp() + THRESHOLD_SECOND;
  const releaseDate = getDotSeparatedDayTimeString(
    new Date(releaseDateTime * 1000)
  );
  const closeDate = getDotSeparatedDayTimeString(
    new Date(closeDateTime * 1000)
  );

  return (
    <div css={classes.root}>
      <p className="ticketName">{replaceBr(props.ticket.name)}</p>

      <p className="stateHeader">
        <span
          css={css`
            background-color: ${salesPeriodColor};
          `}
        >
          {salesPeriodText}
        </span>
      </p>

      <p className="ticketDate">
        {releaseDate} 〜 {displayCloseDate && closeDate}
      </p>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      padding: 16px;
      background-color: #f3f3f3;
      .ticketName {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.3em;
      }
      .stateHeader {
        margin-bottom: 8px;
        span {
          display: inline-block;
          padding: 4px 8px;
          color: #fff;
          font-size: 12px;
          font-weight: bold;
          border-radius: 4px;
        }
      }
      .ticketDate {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.3em;
      }
    `,
  };
};
