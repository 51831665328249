import { put } from "redux-saga/effects";
import { modalActions } from "./modal";
import { i18nextT } from "../hooks/i18n/i18n";

type ErrorType = {
  errorCode: string;
  errorDetail: {
    caption: string | null;
    msg: string;
  } | null;
};

export function* generateErrorMessage(error: ErrorType) {
  // let resError: ErrorType;

  if (error.errorDetail) {
    const caption = error.errorDetail.caption || "Error"; // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
    const { msg } = error.errorDetail;
    yield put(modalActions.toggleError({ caption, msg }));
    return;
  }

  let resError: ErrorType["errorDetail"];
  switch (error.errorCode) {
    case "user-not-found":
    case "invalid-email":
    case "wrong-password":
      resError = {
        caption: "Error",
        msg: i18nextT("error.wrong-login-input"),
      };
      break;

    case "too-many-requests":
      resError = {
        caption: "Error",
        msg: i18nextT("error.too-many-requests"),
      };
      break;

    default:
      resError = {
        caption: "Error",
        msg: i18nextT("error.unknown"),
      };
  }

  yield put(modalActions.toggleError({ msg: resError.msg }));
}
