/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { useI18n, I18nTrans } from "../hooks/i18n/i18n";
import React from "react";
import { checkedLayoutMixin } from "../styles";
import { PurchaseOrderDetailsItems } from "./PurchaseOrderDetailsItems";
import { RemovedCartItemsMessage } from "./RemovedCartItemsMessage";
import { TextLink } from "ui/TextLink";

type Props = {
  shippingAtTitle: string | null;
  items: {
    name: string;
    variantName?: string;
    price: number;
    count: number;
    shippingAtTitle: string;
  }[];
  toggleMyCart: () => void;
  displayAddressCaution: boolean;
  shippingFee: number;
  isAdditionalFee: boolean; // destinationType === "far"
  serviceFee: number;
  settlementFee: number;
  totalPrice: number;
};
export const PurchaseOrderDetails: React.FC<Props> = (props) => {
  const { t } = useI18n();
  return (
    <Container>
      <div className="settlement_order_details">
        <div className={"settlement_order_heading"}>
          <RemovedCartItemsMessage />
          <h2>{t("settlement.contentsHeadline")}</h2>

          {props.shippingAtTitle !== null && (
            <p className={"shippingAtTitle"}>
              {t("cart.box.scheduledShippingDate")}：{props.shippingAtTitle}
            </p>
          )}
        </div>

        <div className="contents">
          <PurchaseOrderDetailsItems items={props.items} />

          {/* TODO: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/anchor-is-valid.md */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="change_button" onClick={props.toggleMyCart}>
            {t("settlement.changeCart")}
          </a>
          {props.displayAddressCaution ? (
            <p className="address_caution">
              <I18nTrans i18nKey="settlement.addressCaution">
                ※配送が必要な商品は、購入時点で
                <TextLink href={"/account/address"}>こちら</TextLink>
                に登録されている住所に配送されます。
              </I18nTrans>
            </p>
          ) : null}
          {props.shippingFee ? (
            <div className="shipping_fee">
              {
                <p className="heading">
                  {t("settlement.shippingFee")}
                  {props.isAdditionalFee &&
                    `${t("settlement.shippingFeeWithFar")}`}
                </p>
              }
              <p className="total">
                ¥{props.shippingFee.toLocaleString()}
                <span>({t("settlement.taxIncluded")})</span>
              </p>
            </div>
          ) : null}
          {props.serviceFee ? (
            <div className="shipping_fee">
              <p className="heading">{t("settlement.serviceFee")}</p>
              <p className="total">
                ¥{props.serviceFee.toLocaleString()}
                <span>({t("settlement.taxIncluded")})</span>
              </p>
            </div>
          ) : null}
          {props.settlementFee ? (
            <div className="shipping_fee">
              <p className="heading">{t("settlement.settlementFee")}</p>
              <p className="total">
                ¥{props.settlementFee.toLocaleString()}
                <span>({t("settlement.taxIncluded")})</span>
              </p>
            </div>
          ) : null}
          <div className="total_price">
            <p className="heading">{t("settlement.productTotal")}</p>
            <p className="total">
              ¥{props.totalPrice.toLocaleString()}
              <span>({t("settlement.taxIncluded")})</span>
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

const _Container = styled.div`
  .settlement_order_details {
    background-color: #fafafa;
    border-bottom: 1px solid #e0e0e0;
    @media screen and (min-width: 768px) {
      padding: 40px 0 80px;
    }
    .settlement_order_heading {
      width: 90%;
      margin: 0 auto 20px;
      @media screen and (min-width: 768px) {
        width: 960px;
        margin: 0 auto 40px;
      }
      h2 {
        font-size: 16px;
        @media screen and (min-width: 768px) {
          width: 960px;
          font-size: 24px;
        }
      }
      .shippingAtTitle {
        margin-top: 8px;
        font-size: 14px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          margin-top: 16px;
          font-size: 16px;
        }
      }
    }
    .contents {
      width: 90%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 740px;
        max-width: 90%;
      }
    }
  }
  .change_button {
    display: block;
    width: 100px;
    padding: 10px 0;
    margin-bottom: 25px;
    color: #fff;
    font-size: 11px;
    text-align: center;
    background-color: #62b5e5;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      width: 170px;
      padding: 12px 0;
      font-size: 14px;
    }
  }
  .address_caution {
    color: #ff0000;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.3em;
  }
  .total_price {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .heading {
      padding-bottom: 8px;
      font-size: 12px;
      @media screen and (min-width: 768px) {
        font-size: 14px;
      }
    }
    .total {
      font-size: 30px;
      font-weight: bold;
      span {
        margin-left: 5px;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  .shipping_fee {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 5px;
    .heading {
      padding-bottom: 8px;
      font-size: 12px;
      @media screen and (min-width: 768px) {
        font-size: 14px;
      }
    }
    .total {
      font-size: 20px;
      span {
        margin-left: 5px;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
`;

const Container = styled(_Container)({}, checkedLayoutMixin);
