/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "../../store";
import { GiftItemData, streamingActions } from "../..//modules/streaming";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { convertMapToValues, EventInfo } from "utility";
import icon_paw from "../../designs/images/icon_paw.svg";
import { GiftIcon } from "components/svg/streaming/GiftIcon";
import { isSmartPhone } from "../../utility";
import { useI18n } from "hooks/i18n/i18n";
import SendIcon from "@material-ui/icons/Send";
import { isForbiddenWord } from "utility/streaming";

const POST_INTERVAL_SECONDS = 5;

type Props = {
  hasTicket: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedGift: any;
  setOpenSelectGift: (open: boolean) => void;
  selectedGift: GiftItemData;
  eventInfo: EventInfo;
  videoId: string;
  openSelectGift: boolean;
};

/**
 * コメントエリア下のコメント送信エリア
 * NOTE: ログインしていない場合はコメントは投稿できない
 * FIXME: コメントはできないが、コメントの閲覧はできるようにしたい。
 * @see https://www.notion.so/balusco/cf127373c94744c495144820c6f40ec8?pvs=4#38f7b6c4b11a453293efe7c6316eeea8
 */
const StreamingSendComment: React.FC<Props> = (props) => {
  const [postedTime, setPostedTime] = useState<number>(0);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");

  const user = useSelector((state: Store) => state.auth.user);
  const iconUrls = useSelector((state: Store) => state.streaming.iconUrls);
  const localTimeDiff = useSelector((state: Store) => state.auth.localTimeDiff);
  const giftItemMap = useSelector(
    (state: Store) => state.streaming.giftItemMap
  );
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );
  const ngWords = useSelector(
    (state: Store) => state.streaming.streamingSettings.ngWords
  );

  const dispatch = useDispatch();

  const giftItems = useMemo(() => {
    return convertMapToValues(giftItemMap).filter((el) => el.display);
  }, [giftItemMap]);

  const post = () => {
    if (!iconUrls || !props.hasTicket) return;

    // cannot post comment if empty / can post gift even if comment is empty
    if (!comment && !props.selectedGift) return;

    // cannot post comment continuously
    const currentDate = new Date(new Date().getTime() + localTimeDiff);
    const postingTime =
      Math.floor(currentDate.getTime() / 1000) + POST_INTERVAL_SECONDS;
    if (postingTime < postedTime + 5) return;

    const isGift = !!props.selectedGift;
    dispatch(
      streamingActions.postComment.started({
        // @ts-expect-error TS2322
        eventId: props.eventInfo._id,
        videoId: props.videoId,
        postData: {
          msg: comment,
          icon: iconUrls["32"],
          uid: user.uid,
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          name: user.displayName || " ", // if undefined, permission error
          color: props.selectedGift?.colorCode || "",
          isBan: isForbiddenWord(comment, ngWords),
          isSuperChat: isGift,
          amount: props.selectedGift?.values.paid || 0,
          giftItemId: props.selectedGift?._id || "", // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
        },
        isGift,
        itemId: props.selectedGift?.itemId,
      })
    );

    setDisableButton(true);
    setPostedTime(postingTime);
    window.setTimeout(() => {
      setDisableButton(false);
    }, POST_INTERVAL_SECONDS * 1000);
    props.setSelectedGift(null);

    // clear comment
    setComment("");
    const sendCommentInput = document.getElementById(
      "sendCommentInput"
    ) as HTMLInputElement;
    sendCommentInput.value = "";
  };

  // @ts-expect-error TS7006
  const enterPressed = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      post();
    }
  };

  // @ts-expect-error TS7006
  const onFocusCommentInput = (_) => {
    if (isSmartPhone()) {
      window.setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 500);
    }
  };

  const giftImgUrl = props.selectedGift?.imgUrl
    ? props.selectedGift?.imgUrl
    : icon_paw;
  const classesSendCommentDesign = sendCommentDesign({ isDarkModeEnabled });

  const { t } = useI18n();

  // ログインしていない場合は何も表示しない
  if (!user.uid) {
    return null;
  }

  return (
    <div css={classesSendCommentDesign.root}>
      <div css={classesSendCommentDesign.inputLayout}>
        {/*
         // @ts-expect-error TS2322 */}
        <img src={iconUrls ? iconUrls[64] : null} alt={user.displayName} />
        <input
          type="text"
          id="sendCommentInput"
          name="comment"
          autoComplete="off"
          placeholder={t("streaming.comment.sendUser", {
            displayName: user.displayName,
          })}
          maxLength={50}
          onChange={(e) => setComment(e.currentTarget.value)}
          onKeyPress={enterPressed}
          onFocus={() => {
            console.log("comment onFocus");
            onFocusCommentInput(true);
          }}
          onBlur={() => {
            console.log("comment onBlur");
            onFocusCommentInput(false);
          }}
        />
        <button
          className={
            !iconUrls ||
            !props.hasTicket ||
            disableButton ||
            props.openSelectGift
              ? "disable"
              : ""
          }
          onClick={post}
          css={classesSendCommentDesign.sendButton}
        >
          <SendIcon />
        </button>
      </div>

      {giftItemMap && giftItems.length !== 0 && (
        <div css={classesSendCommentDesign.option}>
          <div
            css={classesSendCommentDesign.selectButton}
            onClick={() => props.setOpenSelectGift(true)}
          >
            <GiftIcon
              width={25}
              height={25}
              fontColor={"#f25b8a"}
              isDarkModeEnabled={isDarkModeEnabled}
              className={!iconUrls || !props.hasTicket ? "disable" : ""}
            />
          </div>

          {props.selectedGift ? (
            <React.Fragment>
              <ArrowRightIcon css={classesSendCommentDesign.svgArrowIcon} />
              <div css={classesSendCommentDesign.selectedGift}>
                <img src={giftImgUrl} alt={props.selectedGift?.name} />
                <div>
                  <p>{props.selectedGift?.name}</p>
                </div>
                <HighlightOffIcon
                  onClick={() => props.setSelectedGift(null)}
                  css={classesSendCommentDesign.svgRemoveIcon}
                />
              </div>
            </React.Fragment>
          ) : (
            <p
              css={classesSendCommentDesign.unselectedText}
              onClick={() => props.setOpenSelectGift(true)}
            >
              {t("streaming.comment.giftInfomation")}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

interface SendCommentDesignProps {
  isDarkModeEnabled: boolean;
}
const sendCommentDesign = (props?: SendCommentDesignProps) => {
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const backgroundColorInDarkMode = props.isDarkModeEnabled
    ? css`
        background-color: #202020 !important;
      `
    : css`
        background-color: #ffffff !important;
      `;
  return {
    root: css`
      padding: 12px 5%;
      ${backgroundColorInDarkMode}
      border-bottom: 1px solid #dedede;
      @media screen and (min-width: 768px) {
        position: relative;
        display: block;
        padding: 12px 8px;
        border: 1px solid #e0e0e0;
        border-top: none;
      }
      button {
        padding: 6px 10px;
        color: #fff;
        font-size: 14px;
        border: none;
      }
    `,
    inputLayout: css`
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        margin-right: 8px;
        background-color: #fff;
        border-radius: 50%;
        object-fit: cover;
      }
      input {
        width: calc(100% - 94px);
        padding: 8px 12px;
        font-size: 16px;
        background-color: #f4f4f4;
        border-radius: 18px;
        &::placeholder {
          color: #bbbbbb;
        }
      }
    `,
    sendButton: css`
      margin-left: 8px;
      background-color: #00c2ae;
      border-radius: 5px;
      &.disable {
        background-color: #77797a;
      }
    `,
    option: css`
      display: flex;
      align-items: center;
      padding: 0 5px;
      margin-top: 10px;
    `,
    gift: css``,
    selectButton: css`
      padding: 5px 0;
      cursor: pointer;
    `,

    selectedGift: css`
      display: flex;
      align-items: center;
      width: calc(
        100% - 49px
      ); /* 100% - (ギフトボタンの横幅 + arrow svgの横幅) */
      padding: 0px 8px;
      border: 1px solid #c7c7cc;
      border-radius: 5px;
      img {
        width: 25px;
        height: 25px;
        margin-right: 8px;
        object-fit: cover;
      }
      div {
        display: flex;
        align-items: center;
        width: calc(100% - 56px); /* 100% - (ギフト画像の横幅 + margin-right) */
        height: 2em;
      }
      p {
        display: box;
        width: 100%;
        ${textColorInDarkMode}
        font-size: 14px;
        /* stylelint-disable-next-line property-no-unknown */
        box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
      }
      svg {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        cursor: pointer;
      }
    `,
    unselectedText: css`
      margin-left: 16px;
      color: #bbbbbb;
      font-size: 14px;
      cursor: pointer;
    `,
    svgArrowIcon: css`
      fill: #f25b8a !important;
    `,
    svgRemoveIcon: css`
      fill: #77797a !important;
    `,
  };
};

export default StreamingSendComment;
