/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { SubContents } from "components/templates/SubContents";
import { TextLink } from "ui/TextLink";

interface Props {}

/**
 * 特定商取引法に基づく表記
 *
 * 2023/08/14 更新ドキュメント
 * @see https://docs.google.com/document/d/17KQnsmUGOlDw42XGF1M_vst-DbH1XLOQEDTjLs1kr1c/edit
 */
export const Rule: React.FC<Props> = (_props) => {
  const classesRuleStyles = ruleStyles();

  return (
    <SubContents>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, "特定商取引法に基づく表記"],
        ]}
      />

      <div css={classesRuleStyles.root}>
        <h2>特定商取引法に基づく表記</h2>

        <div css={classesRuleStyles.section}>
          <h3>１. 運営会社</h3>
          <p>バルス株式会社</p>
        </div>

        <div css={classesRuleStyles.section}>
          <h3>２. 住所</h3>
          {/* eslint-disable no-irregular-whitespace */}
          <p className="indent_none">
            所在地：〒103-0015 東京都中央区日本橋箱崎町 36-2
          </p>
          {/* eslint-enable no-irregular-whitespace */}
        </div>

        <div css={classesRuleStyles.section}>
          <h3>３. 電話番号</h3>
          <p className="indent_none">
            電話での操作方法等のお問い合わせは受け付けておりません。
            こちらのヘルプ（
            <TextLink href={"https://spwn.zendesk.com/hc/ja"}>
              https://spwn.zendesk.com/hc/ja
            </TextLink>
            ）をご参照ください。
          </p>
          <p>サポート電話番号：050-5360-2274</p>
          <p>サポート時間：11:00 ~ 17:00</p>
          <p>※土日祝日及び12/30～1/3は休業いたします。</p>
        </div>

        <div css={classesRuleStyles.section}>
          <h3>４. お問い合わせ先</h3>
          <p>メールアドレス：support@spwn.zendesk.com</p>
          <p>※土日祝日及び12/30～1/3は休業いたします。</p>
          <p>
            ※お問合せは原則メールにて承っております。何卒ご協力のほどお願い申し上げます。
          </p>
        </div>

        <div css={classesRuleStyles.section}>
          <h3>５. 代表者</h3>
          <p>代表取締役 林 範和</p>
        </div>

        <div css={classesRuleStyles.section}>
          <h3>６. 販売価格</h3>
          <p>商品ごとに販売ページに記載いたします。</p>
        </div>

        <div css={classesRuleStyles.section}>
          <h3>７. 販売価格以外の費用</h3>
          <p className="indent_none">
            電気通信回線の通信料金等(インターネット接続料金を含む)、送料の費用は、お客様のご負担となります。
          </p>
          <p>
            ※料金は、お客様がご利用のインターネットプロバイダー等にお問い合わせください。
          </p>
          <p>
            ※送料について、詳しくは{" "}
            <TextLink
              href={
                "https://spwn.zendesk.com/hc/ja/articles/900006416503-%E3%82%B0%E3%83%83%E3%82%BA%E3%81%AE%E9%80%81%E6%96%99%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84"
              }
            >
              ヘルプページ
            </TextLink>{" "}
            をご覧ください。
          </p>
        </div>

        <div css={classesRuleStyles.section}>
          <h3>８. 代金のお支払時期</h3>
          <ul>
            <li>
              ・クレジットカード決済：サイト上の決済ボタンを押した時点でお申込みを完了し、決済いたします。但し、抽選によるチケット販売の場合は、抽選結果発表時に決済いたします。
            </li>
            <li>
              ・コンビニ決済：ご注文の際にお知らせする支払期限までに、コンビニエンスストアにてお支払いください。
            </li>
            <li>
              ・キャリア決済：お客様ご契約の携帯電話会社の定める時期に、携帯電話料金と一緒に請求されるため、その支払期限に基づきます。（各キャリアの決済方法に従ってください。）
            </li>
            <li>
              ・サービス内通貨による決済：サイト上の決済ボタンを押した時点でお申込みを完了し、決済いたします。
            </li>
          </ul>
        </div>

        <div css={classesRuleStyles.section}>
          <h3>９. 代金のお支払方法</h3>

          <div css={classesRuleStyles.block}>
            <h4>＜サービス内通貨＞</h4>
            <p>クレジットカード決済</p>
          </div>

          <div css={classesRuleStyles.block}>
            <h4>＜チケット＞</h4>
            <p>お支払方法は、以下よりお選びいただけます。</p>
            <ul>
              <li>・クレジットカード決済</li>
              <li>・コンビニ決済</li>
              <li>・キャリア決済</li>
            </ul>
          </div>

          <div css={classesRuleStyles.block}>
            <h4>＜デジタルコンテンツ＞</h4>
            <p>サービス内通貨「PAW」により購入することができます。</p>
          </div>

          <div css={classesRuleStyles.block}>
            <h4>＜グッズ＞</h4>
            <p>お支払方法は、以下よりお選びいただけます。</p>
            <ul>
              <li>・クレジットカード決済</li>
              <li>・コンビニ決済</li>
              <li>・キャリア決済</li>
            </ul>
          </div>
        </div>

        <div css={classesRuleStyles.section}>
          <h3>１０. 提供時期</h3>

          <div css={classesRuleStyles.block}>
            <h4>＜サービス内通貨＞</h4>
            <p>決済完了後、直ちにご提供いたします。</p>
          </div>

          <div css={classesRuleStyles.block}>
            <h4>＜チケット＞</h4>
            <p className="indent_none">
              チケットの配信時期についてはイベント毎の説明をご確認ください。
            </p>
          </div>

          <div css={classesRuleStyles.block}>
            <h4>＜デジタルコンテンツ＞</h4>
            <p className="indent_none">決済完了後、直ちにご提供いたします。</p>
          </div>

          <div css={classesRuleStyles.block}>
            <h4>＜グッズ＞</h4>
            <p className="indent_none">
              商品の発送時期については商品毎の説明をご確認ください。
            </p>
          </div>
        </div>

        <div css={classesRuleStyles.section}>
          <h3>１１. 返品について</h3>

          <div css={classesRuleStyles.block}>
            <h4>＜サービス内通貨・デジタルコンテンツ・チケット＞</h4>
            <p>返品及び交換はできません。</p>
          </div>

          <div css={classesRuleStyles.block}>
            <h4>＜グッズ＞</h4>
            <p className="indent_none">
              お届けした商品が、お申し込み商品と異なる場合や、汚損、破損などしていた場合には、交換又は返金させていただきますので、お手数ではございますが、上記お問い合わせ先メールアドレスまでご連絡ください。商品の交換・返品のご連絡は、商品到着後8日以内にお願いいたします。交換・返品の際はお届けした商品の返送をお願いいたします（送料当社負担）。
            </p>
            <p className="indent_none">
              以下に該当する場合には商品の交換・返品はお受けできませんので、あらかじめご了承ください。
            </p>
            <ul>
              <li>
                ・お客様の都合による返品（サイズが合わない、イメージと異なる、注文間違い等）
              </li>
              <li>・使用・着用された商品</li>
              <li>
                ・その他、出荷時の商品状態と異なる場合（タグ、付属品、包装等を含む）
              </li>
            </ul>
          </div>
        </div>

        <div id="operating_environment" css={classesRuleStyles.section}>
          <h3>１２. 動作環境</h3>
          <p className="indent_none">
            サービス内通貨・チケット・コンテンツの動作環境は下記のとおりです。
          </p>

          <div css={classesRuleStyles.block}>
            <h4>iOS 端末ご利用の場合のブラウザ環境について</h4>
            <p>iOS13.1 が動作するiPhone 端末のSafari</p>
          </div>

          <div css={classesRuleStyles.block}>
            <h4>Android 端末ご利用の場合のブラウザ環境について</h4>
            <p>Android 9 が動作する端末のChrome</p>
          </div>

          <div css={classesRuleStyles.block}>
            <h4>macOSをご利用の場合のブラウザ環境について</h4>
            <p>Chrome 78以上</p>
          </div>

          <div css={classesRuleStyles.block}>
            <h4>Windowsをご利用の場合のブラウザ環境について</h4>
            <p>Chrome 78以上</p>
          </div>
        </div>
      </div>
    </SubContents>
  );
};

const ruleStyles = () => {
  return {
    root: css`
      width: 90%;
      max-width: 980px;
      padding: 80px 0;
      margin: 0 auto;
      h2 {
        margin-bottom: 80px;
        font-size: 24px;
        text-align: center;
        font-weight: 400;
      }
    `,
    introduction: css`
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 1px;
    `,
    section: css`
      margin-bottom: 40px;
      h3 {
        margin-bottom: 16px;
        line-height: 1.3em;
      }
      h4 {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 1.3em;
      }
      p {
        padding-left: 1.3em;
        margin-bottom: 8px;
        font-size: 14px;
        text-indent: -1.3em;
        line-height: 1.5em;
        letter-spacing: 1px;
      }
      ul {
        li {
          font-size: 14px;
          padding-left: 1em;
          margin-bottom: 8px;
          text-indent: -1em;
        }
      }
      .indent_none {
        padding-left: 0;
        text-indent: 0;
      }
    `,
    block: css`
      margin-bottom: 16px;
    `,
  };
};
