/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { TermsOfServiceIframe } from "features/terms/TermsOfService";
import React from "react";

export const GoToConfirm: React.FC = () => {
  const classesGotoConfirmStyles = gotoConfirmStyles();

  return (
    <div css={classesGotoConfirmStyles.root}>
      <p css={classesGotoConfirmStyles.modalCaution}>SPWN利用規約</p>

      <div id="term-layout" css={classesGotoConfirmStyles.modalTerm}>
        <TermsOfServiceIframe />
      </div>
    </div>
  );
};

const gotoConfirmStyles = () => {
  return {
    root: css`
      width: 100%;
      max-width: 740px;
      margin: 0 auto;
      background-color: #fff;
    `,
    modalCaution: css`
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      line-height: 1.5em;
    `,
    modalTerm: css`
      border: 1px solid #dedede;
      iframe {
        width: 100%;
        height: 240px;
      }
    `,
  };
};
