import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { select, put, take, call, takeEvery } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { firestoreActions, isRegisteredChannel } from "./firestore";
import firebase from "firebase/app";
import { modalActions } from "../modules/modal";
import { getLiveContextType, getLiveDataPath } from "../utility/live";
import type {
  EventContext,
  SurveyQuizData,
  TapPublicData,
} from "@spwn/types/firebase/firestore";

const actionCreator = actionCreatorFactory("live");

/* eslint-disable @typescript-eslint/no-explicit-any */
export const liveActions = {
  watchEventContext: actionCreator.async<any, any>("watchEventContext"),
  watchSurveyData: actionCreator.async<any, any>("watchSurveyData"),
  vote: actionCreator.async<any, any>("vote"),
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export interface liveState {
  eventId: string;
  contextId: string;
  surveyData: SurveyQuizData | TapPublicData;
}

export interface ReqVote {
  answerText: string;
  answerId: number;
}

export interface ReqWatchSurveyData {
  currentContextId: string;
  nextContextId: string;
}

export type LiveContextType = "qid" | "vid" | "cid" | "did";
export type LiveDataPath = "clicks" | "surveys";

const initialState: liveState = {
  // @ts-expect-error TS2322
  eventId: null,
  // @ts-expect-error TS2322
  contextId: null,
  // @ts-expect-error TS2322
  surveyData: null,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const liveReducer = reducerWithInitialState(initialState)
  .case(liveActions.watchEventContext.done, (state, payload: any) => {
    return { ...state, eventId: payload.eventId, contextId: payload.contextId };
  })
  .case(liveActions.watchSurveyData.done, (state, payload: any) => {
    return { ...state, surveyData: payload };
  })
  .case(liveActions.vote.done, (state, payload: any) => {
    return { ...state, surveyData: payload };
  });
/* eslint-enable @typescript-eslint/no-explicit-any */

export default liveReducer;

export function* liveSaga() {
  yield takeEvery(liveActions.watchEventContext.started, watchEventContext);
  yield takeEvery(liveActions.watchSurveyData.started, watchSurveyData);
  yield takeEvery(liveActions.vote.started, vote);
}

function contextChannel(eventId: string) {
  return eventChannel((emitter) => {
    const unsubscribe = firebase
      .firestore()
      .doc(`/eventContexts/${eventId}`)
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            const data = doc.data();
            emitter(data);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    return unsubscribe;
  });
}

// @ts-expect-error TS7006
function* watchEventContext(action) {
  const {
    firestore: { channels },
  } = yield select();

  const { eventId } = action.payload;

  //既にlisten済ならreturn
  if (isRegisteredChannel(channels, "liveContext")) return;

  try {
    // @ts-expect-error TS7057
    const channel = yield call(contextChannel, eventId);
    while (true) {
      const eventContext: EventContext = yield take(channel);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = {
        eventId,
        contextId: eventContext.contextId,
      };
      yield put(liveActions.watchEventContext.done(data));
      yield put(
        firestoreActions.addChannel({ ...channel, name: "liveContext" })
      );
    }
  } catch (e) {
    console.error(e);
  }
}

function surveyChannel(
  liveDataPath: LiveDataPath,
  eventId: string,
  contextId: string
) {
  console.log(liveDataPath, eventId, contextId);
  return eventChannel((emitter) => {
    const unsubscribe = firebase
      .firestore()
      .doc(`/${liveDataPath}/${eventId}/${contextId}/public`)
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            const data = { ...doc.data(), _id: doc.id };
            emitter(data);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    return unsubscribe;
  });
}

function* watchSurveyData(action: { payload: ReqWatchSurveyData }) {
  const {
    live: { eventId },
  } = yield select();
  const { currentContextId, nextContextId } = action.payload;

  if (!eventId || !nextContextId) {
    return;
  }

  const contextType = getLiveContextType(nextContextId);
  const liveDataPath = getLiveDataPath(contextType);
  if (!liveDataPath) {
    return;
  }

  try {
    yield put(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      firestoreActions.closeChannel({ channel: currentContextId as any })
    );
    // @ts-expect-error TS7057
    const channel = yield call(
      surveyChannel,
      liveDataPath,
      eventId,
      nextContextId
    );
    while (true) {
      // @ts-expect-error TS7057
      const surveyData: any = yield take(channel); // eslint-disable-line @typescript-eslint/no-explicit-any
      yield put(liveActions.watchSurveyData.done(surveyData));
      yield put(
        firestoreActions.addChannel({ ...channel, name: nextContextId })
      );
    }
  } catch (e) {
    console.error(e);
  }
}

function* vote(action: { payload: ReqVote }) {
  const {
    auth: {
      user: { uid },
    },
    live: { eventId, contextId, surveyData },
  } = yield select();
  if (!uid || !eventId || !contextId) {
    return;
  }
  const { answerId } = action.payload;
  const { answerText } = action.payload;
  // HOTFIX: suppress msg if quiz event used as effect event
  const displaySelectedMsg = ["201018-ejanimefes"].includes(eventId) === false;
  try {
    yield firebase
      .firestore()
      .collection(`/surveys/${eventId}/${contextId}/results/users`)
      .doc(`${uid}`)
      .set({ ans: Number(answerId) });
    if (displaySelectedMsg) {
      yield put(
        modalActions.toggleNotice({ msg: `${answerText}を選択しました` })
      );
    }
  } catch (error) {
    // permission errorだったら回答受付前ポップアップ
    console.error("Error writing document: ", error);
    const msg = !surveyData.closeMsg
      ? "送信に失敗しました。<br/>再送信をお願いします。"
      : surveyData.closeMsg;
    yield put(modalActions.toggleNotice({ msg }));
  } finally {
    //
  }
}
