/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { TextLink } from "ui/TextLink";

export type CantUseCVSReason = "amountLimit" | "periodLimit";

export type CantUsePhoneReason = "amountLimit";

type CantUseCVSProps = {
  reason: CantUseCVSReason;
};
export const CantUseCVS = (props: CantUseCVSProps) => {
  let reason = "";
  switch (props.reason) {
    case "amountLimit":
      reason =
        "※コンビニ決済の利用限度額を超えているため、コンビニ決済はご利用いただけません。";
      break;
    case "periodLimit":
      reason =
        "※コンビニ決済が可能な期限を過ぎている商品を含むため、コンビニ決済はご利用いただけません。";
      break;
    default:
      reason =
        "※コンビニ決済が可能な期限を過ぎている商品を含むため、コンビニ決済はご利用いただけません。";
      break;
  }
  return (
    <ul>
      <li>{reason}</li>
      {props.reason !== "amountLimit" ? (
        <li>
          ※コンビニで購入できるプリペイドカード「
          <TextLink href={"http://vpc.lifecard.co.jp/"}>Vプリカ</TextLink>
          」をご利用いただけます。
        </li>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </ul>
  );
};

type CantUsePhoneProps = {
  reason: CantUsePhoneReason;
};
export const CantUsePhone = (props: CantUsePhoneProps) => {
  let reason = "";
  switch (props.reason) {
    case "amountLimit":
      reason =
        "※キャリア決済の利用限度額を超えているため、キャリア決済はご利用いただけません。";
      break;
    default:
      reason = "";
      break;
  }
  return (
    <ul>
      <li>{reason}</li>
    </ul>
  );
};
