import { useSelector } from "react-redux";
import { useState, useCallback } from "react";
import { Store } from "store";
import type { StageContent, Video } from "@spwn/types";
import { fetchData as fetchTimetableModel } from "../../hooks/event/TimetableContent";

type StreamingTimetable = {
  name: string;
  videos: Video[];
  actionStatus:
    | "NotAvailableForPurchase"
    | "NotPurchased"
    | "InCart"
    | "Purchased"
    | "SettlementConfirm"
    | "BeforePlay" // 購入済み & 配信前
    | "Playable" // 購入済み & 配信中
    | "Loading"
    | null;
  ticketPath: string | null;
};

export const useStreamingTimetableContent = () => {
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  // @ts-expect-error TS2345
  const [models, setModels] = useState<StreamingTimetable[]>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(
    async (currentVideoId: string) => {
      // if not fes event, don't fetch
      if (!displayEvent.isFesEvent) return;
      if (!models) setLoading(true);
      try {
        // 全てのタイムテーブルの情報を取得する
        const timetableModels = await fetchTimetableModel(
          displayEvent,
          isAdmin
        );

        // currentVideoIdを持つビデオが属しているステージを判別する
        let dayContentNum = 0;
        let stageContentNum = 0;
        for (let i = 0; i < timetableModels.dayContents.length; i++) {
          dayContentNum = i;
          // @ts-expect-error TS2339
          const { stageContents } = timetableModels.dayContents[i];

          for (let j = 0; j < stageContents.length; j++) {
            stageContentNum = j;
            const { videos } = stageContents[j];
            const isCurrentStage =
              // @ts-expect-error TS7006
              videos.filter((video) => video._id === currentVideoId).length > 0;
            if (isCurrentStage) break;
          }
        }
        const currentDayContent =
          // @ts-expect-error TS2532
          timetableModels.dayContents[dayContentNum].stageContents;

        // Streamingページに表示するステージを取得する
        const streamingStageContents = displayEvent.hasVOD
          ? currentDayContent
          : currentDayContent.filter(
              (stage, index) => index !== stageContentNum
            );

        const renderStageContents = streamingStageContents.map((stage) => {
          const videos = displayEvent.hasVOD
            ? getArchivedStageVideos(stage, currentVideoId)
            : getAnotherStageFeatureVideos(stage);
          return {
            ...stage,
            videos,
          };
        });

        setModels(renderStageContents);
      } catch (error) {
        console.error(error);
        // @ts-expect-error TS2345
        setError(typeof error === "string" && error);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayEvent, models]
  );

  return {
    displayEvent,
    models,
    fetch,
    loading,
    error,
  };
};

/**
 * 他のステージの配信中のものから4枠分取得する
 * @param stageVideos
 * @returns
 */
const getAnotherStageFeatureVideos = (stage: StageContent) => {
  // 開場順にvideoをsort。
  const sortedVideosByStartAt = stage.videos.sort(
    (a, b) => a.startAt - b.startAt
  );
  // stageが持ってる配信中のvideoからindexを取得
  const playVideoIndex = sortedVideosByStartAt.findIndex(
    (el) => el._id === stage.playVideoId
  );
  // 4つまで配信枠を取得
  return sortedVideosByStartAt.slice(playVideoIndex, playVideoIndex + 4);
};

/**
 * アーカイブの場合は
 * - 視聴中のステージの、視聴中のライブ以外のライブを全て
 * - 他のステージのライブ全て
 * @param stage
 */
const getArchivedStageVideos = (
  stage: StageContent,
  currentVideoId: string
) => {
  // 開場順にvideoをsort。
  const sortedVideosByStartAt = stage.videos
    .filter((v) => v._id !== currentVideoId)
    .sort((a, b) => a.startAt - b.startAt);
  return sortedVideosByStartAt;
};
