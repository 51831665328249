import firebase from "firebase/app";
import {
  GetGoodsListRequest,
  GetGoodsListResponse,
  GetShopRequest,
  GetShopResponse,
} from "@spwn/types/functions";
import useSWR from "swr";
import { Shop, ShopGoods } from "./shopModel";

export const useGetShopPreview = ({
  tenantId,
  shopId,
}: {
  tenantId: string;
  brandId: string;
  shopId: string;
}): {
  shopInfo: Shop;
  error: unknown;
} => {
  const { data: shopInfo, error } = useSWR(
    ["getActiveGoodsList", tenantId, shopId],
    async () => {
      const { data: goodsList } = await callGetGoodsList({
        tenantId,
        shopId,
      });
      const { data: shop } = await callGetShop({ tenantId, shopId });
      return buildShopInfo({ shop, goodsList });
    }
  );

  return {
    // @ts-expect-error TS2322
    shopInfo,
    error,
  };
};

const callGetGoodsList = async (
  request: GetGoodsListRequest
): Promise<{ data: GetGoodsListResponse }> => {
  return await firebase.functions().httpsCallable("getActiveGoodsList")(
    request
  );
};

const callGetShop = async (
  request: GetShopRequest
): Promise<{ data: GetShopResponse }> =>
  firebase.functions().httpsCallable("getShop")(request);

/**
 * レスポンスをグッズモデルへ変換する
 */
const buildShopInfo = ({
  shop,
  goodsList,
}: {
  shop: GetShopResponse;
  goodsList: GetGoodsListResponse;
}): Shop => {
  return {
    name: shop.name,
    thumbnail: shop.thumbnail,
    // @ts-expect-error TS2322
    goodsList: goodsList.map((goods) => ({
      id: goods.id,
      name: goods.name,
      price: goods.price,
      salesStatus: getSalesStatus({
        ...goods,
        today: new Date().getTime(),
      }),
      hasVariant: goods.variants.length > 0,
      thumbnail: goods.images[0]?.url,
    })),
  };
};

type GetSalesStatus = (
  props: Pick<
    GetGoodsListResponse[number],
    | "inventory"
    | "isUnlimitedInventory"
    | "variants"
    | "releasedAtEpochMillis"
    | "closedAtEpochMillis"
  > & { today: number }
) => ShopGoods["salesStatus"];
const getSalesStatus: GetSalesStatus = ({
  inventory,
  isUnlimitedInventory,
  variants,
  releasedAtEpochMillis,
  closedAtEpochMillis,
  today,
}) => {
  /**
   * 売り切れ判定ロジック
   *
   * - バリエーションがない場合
   *   - 在庫がない場合、売り切れ
   *   - 在庫制限がない場合、売り切れにはならない
   * - バリエーションがある場合
   *   - すべてのバリエーションの在庫がない場合、売り切れ
   *   - 在庫制限がないバリエーションが含まれる場合は、売り切れにはならない
   */
  const isSoldOut =
    // バリエーションがない場合
    (variants.length === 0 && !isUnlimitedInventory && inventory === 0) ||
    // バリエーションがある場合
    (variants.length !== 0 &&
      variants.every(
        (variant) => !variant.isUnlimitedInventory && variant.inventory === 0
      ));

  /**
   * 販売期間タイプ判定ロジック
   *
   * - 販売開始日も販売終了日も設定されていない
   * - 販売開始日のみ設定されている
   * - 販売開始日も販売終了日も設定されている
   */
  const salesPeriodType =
    releasedAtEpochMillis === null
      ? "none"
      : closedAtEpochMillis === null
      ? "releaseOnly"
      : "releaseAndClose";

  /**
   * - 販売期間タイプ、今日の日付との比較、売り切れ判定ロジックをもとに販売ステータスを判定
   * - 販売ステータスの表示優先順位は、販売期間 > 売り切れ > 販売中
   */
  switch (salesPeriodType) {
    case "none":
      return isSoldOut ? "soldOut" : "onSale";
    case "releaseOnly":
      // @ts-expect-error TS18047
      return today < releasedAtEpochMillis
        ? "beforeSale"
        : isSoldOut
        ? "soldOut"
        : "onSale";
    case "releaseAndClose":
      // @ts-expect-error TS18047
      return today < releasedAtEpochMillis
        ? "beforeSale"
        : // @ts-expect-error TS18047
        closedAtEpochMillis <= today
        ? "afterSale"
        : isSoldOut
        ? "soldOut"
        : "onSale";
  }
};
