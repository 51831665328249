/** @jsxRuntime classic /
 /** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC, useEffect } from "react";
import {
  useCrewStreaming,
  useFetchStreamingPlanContent,
} from "./useCrewStreaming";
import { LoaderLayout } from "../../components/atoms/loader/LoaderLayout";
import Streaming from "containers/streaming/Streaming";
import { StreamingStandby } from "./StreamingStandby";
import { NotFoundError } from "features/NotFoundError";
import { useSelector } from "react-redux";
import { Store } from "store";
import { useQuery } from "../../utility/useQuery";
import { createUrl } from "utility/createUrl";
import { PublicPlanContentStreamingDTO } from "api/schema/spwn/dto/publicPlanContent";

type CrewStreamingProps = {};

const CrewStreamingFetcher: FC<CrewStreamingProps> = () => {
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );

  // https://github.com/balus-co-ltd/spwn/blob/24355fb4ecd861dcaabd17c3a10b15add5d0da84/main/apps/portal/src/features/BrandPlanContentStreaming.tsx#L16C52-L16C520
  const brandSlug = useQuery().get("crewBrandSlug");

  const { streamingPlanContent, mutate, error } =
    useFetchStreamingPlanContent();

  return (
    <CrewStreamingLogic
      // @ts-expect-error TS2322
      brandSlug={brandSlug}
      streamingPlanContent={streamingPlanContent}
      mutateStreamingPlanContent={mutate}
      error={error}
      isDarkModeEnabled={isDarkModeEnabled}
    />
  );
};

type CrewStreamingLogicProps = {
  streamingPlanContent: PublicPlanContentStreamingDTO | undefined;
  brandSlug: string | undefined;
  mutateStreamingPlanContent: () => Promise<
    PublicPlanContentStreamingDTO | undefined
  >;
  error?: unknown;
  isDarkModeEnabled: boolean;
};

/**
 * Crew の配信プランコンテンツ向けの配信ページ
 *
 * URL 構造: /streams/{streamingId}?brandSlug={brandSlug}&eventId={eventId}&tenantId={tenantId}&planContentId={planContentId}
 *
 * @see https://www.notion.so/balusco/bdafef1d926d49a9ae117b5c4937e6bc
 */
const CrewStreamingLogic: FC<CrewStreamingLogicProps> = ({
  brandSlug,
  streamingPlanContent,
  mutateStreamingPlanContent,
  error,
  isDarkModeEnabled,
}) => {
  const linkToTopPage = createUrl({
    location: `https://crew.spwn.jp/${brandSlug ?? ""}`,
    searchParams: [],
  });
  const { displayEvent, eventVideoMap, liveSetting, vodSetting } =
    useCrewStreaming();

  // イベントの配信ステータスが変化したときに、配信コンテンツのステータスを再取得する
  useEffect(() => {
    (async () => {
      await mutateStreamingPlanContent();
    })();
  }, [displayEvent, eventVideoMap]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * 配信を閲覧する権限がない時、エラーが返ってくるので、ここの条件分岐が表示される。
   */
  if (error) {
    return <NotFoundError urlToTopPage={linkToTopPage} />;
  }

  if (
    !streamingPlanContent ||
    !displayEvent ||
    !eventVideoMap ||
    !vodSetting ||
    !liveSetting
  ) {
    return (
      <div id="contents-layout">
        <LoaderLayout />
      </div>
    );
  }

  if (
    streamingPlanContent.details.streamingStatus === "beforeLive" ||
    streamingPlanContent.details.streamingStatus === "beforeVOD" ||
    streamingPlanContent.details.streamingStatus === "ended"
  ) {
    return (
      <StreamingStandby
        planContent={streamingPlanContent}
        displayEvent={displayEvent}
        eventVideoMap={eventVideoMap}
        isDarkModeEnabled={isDarkModeEnabled}
      />
    );
  }

  return (
    <main>
      <div
        css={css`
          margin: 0 auto;
          background-color: ${isDarkModeEnabled ? "#202020" : "#fff"};
          @media screen and (min-width: 768px) {
            width: 100%;
            max-width: 1800px;
            min-height: calc(100vh - 66px - 190px);
          }
        `}
      >
        <Streaming
          url={""}
          cookies={{
            [streamingPlanContent.details.streamingId]:
              streamingPlanContent.details.streamingSource,
          }}
          cookiesList={undefined}
          event={displayEvent}
          hasTicket={true}
          vid={streamingPlanContent.details.streamingId}
          eventVideoMap={eventVideoMap}
          vodSetting={vodSetting}
          liveSetting={liveSetting}
        />
      </div>
    </main>
  );
};

export default CrewStreamingFetcher;
