/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { MouseEventHandler } from "react";

type Props = {
  children: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  size?: "medium" | "large";
  disabled?: boolean;
};

export const EventTimetablePlayButton: React.FC<Props> = ({
  children,
  onClick,
  size = "medium",
  disabled = false,
}) => {
  const medium = css`
    width: 100%;
    padding: 12px 0;
    @media screen and (min-width: 768px) {
      width: 100%;
      padding: 12px 0;
    }
  `;
  const large = css`
    width: 80%;
    padding: 12px 0;
    @media screen and (min-width: 768px) {
      width: 100%;
      padding: 14px 0;
    }
  `;
  const buttonSize = size === "medium" ? medium : large;

  const disabledStyle = css`
    cursor: initial;
    pointer-events: none;
    background-color: #d9d9d9;
  `;

  const cartButton = css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
    color: #fff;
    background-color: #ea3376;
    ${buttonSize};
    ${disabled && disabledStyle};
  `;
  const icon = css`
    position: relative;
    margin-right: 8px;
  `;
  return (
    <div css={cartButton} onClick={onClick}>
      <div css={icon}>
        <PlayArrowIcon />
      </div>
      <div>{children}</div>
    </div>
  );
};
