import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../../store";

interface Props {}

class Company extends Component<Props> {
  render() {
    return <div>運営会社</div>;
  }
}

const mapStateToProps = (_state: Store) => {
  return {};
};

const mapDispatchToProps = (_dispatch: Dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
