/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

type Props = {
  children: React.ReactNode;
};

export const Notes: React.FC<Props> = ({ children }) => (
  <div css={styles.notes}>{children}</div>
);

const styles = {
  notes: css`
    padding: 16px;
    background: #f8f8f8;
    border-radius: 4px;
  `,
};
