/** @jsxRuntime classic /
/** @jsx jsx */
import { css, Global, jsx } from "@emotion/core";
import React, { useEffect, useMemo } from "react";
import StreamingLiveCommerceGoodsList from "./liveCommerce/StreamingLiveCommerceGoodsList";
import StreamingLiveCommerceCart from "./liveCommerce/StreamingLiveCommerceCart";
import StreamingLiveCommerceDisplayInfo from "./liveCommerce/StreamingLiveCommerceDisplayInfo";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { streamingActions } from "modules/streaming";
import { convertMapToValues } from "utility";
import {
  CompletePurchase,
  ErrorModal,
} from "./liveCommerce/StreamingLiveCommerceModal";
import { purchaseActions } from "modules/purchase";
import type { ProductData } from "@spwn/types/firebase/firestore";

interface Props {
  currentVideoId: string;
  THEOPlayerElementHeight?: number;
}
const StreamingLiveCommerce: React.FC<Props> = (props) => {
  const [openCart, setOpenCart] = React.useState<boolean>(false);
  const [openInfo, setOpenInfo] = React.useState<boolean>(false);
  // @ts-expect-error TS2345
  const [goodsInfo, setGoodsInfo] = React.useState<ProductData>(null);

  const eventGoodsMap = useSelector(
    (state: Store) => state.ticket.eventGoodsMap
  );
  const eventTicketMap = useSelector(
    (state: Store) => state.ticket.eventTicketMap
  );
  const { cartItemMap, cartTotalNum, isPurchased, errorInfo } = useSelector(
    (state: Store) => state.streaming.liveCommerce
  );

  const pseudoCart = useMemo(
    () => convertMapToValues(cartItemMap),
    [cartItemMap]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(purchaseActions.refreshFingerprint());
    return () => {
      clearLiveCommerceCart();
      closeErrorModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const incrementCartItem = (itemData: ProductData) => {
    let item: ProductData;
    const { productType } = itemData;
    if (productType === "ticket") {
      // @ts-expect-error TS2538
      item = eventTicketMap[itemData._id];
    } else if (productType === "goods") {
      // @ts-expect-error TS2538
      item = eventGoodsMap[itemData._id];
    }
    // @ts-expect-error TS2454
    dispatch(streamingActions.updateLiveCommerceCart({ item, count: 1 }));
  };

  const decrementCartItem = (itemData: ProductData) => {
    let item: ProductData;
    const { productType } = itemData;
    if (productType === "ticket") {
      // @ts-expect-error TS2538
      item = eventTicketMap[itemData._id];
    } else if (productType === "goods") {
      // @ts-expect-error TS2538
      item = eventGoodsMap[itemData._id];
    }
    // @ts-expect-error TS2454
    dispatch(streamingActions.updateLiveCommerceCart({ item, count: -1 }));
  };

  const removeCartItem = (itemData: ProductData) => {
    let item: ProductData;
    const { productType } = itemData;
    if (productType === "ticket") {
      // @ts-expect-error TS2538
      item = eventTicketMap[itemData._id];
    } else if (productType === "goods") {
      // @ts-expect-error TS2538
      item = eventGoodsMap[itemData._id];
    }
    dispatch(
      streamingActions.updateLiveCommerceCart({
        // @ts-expect-error TS2454
        item,
        count: 0,
        isRemove: true,
      })
    );
  };

  // TODO 修正必要(?)
  const clearLiveCommerceCart = () => {
    dispatch(purchaseActions.refreshFingerprint());
    dispatch(streamingActions.successPurchaseLiveCommerce(false));
    setOpenCart(false);
  };

  const closeErrorModal = () => {
    dispatch(
      streamingActions.toggleLiveCommerceError({
        isError: false,
        caption: "",
        msg: "",
      })
    );
  };

  // @ts-expect-error TS7006
  const openGoodsInfo = (goodsItem) => {
    setOpenInfo(true);
    setGoodsInfo(goodsItem);
  };

  const closeGoodsInfo = () => {
    setOpenInfo(false);
    // @ts-expect-error TS2345
    setGoodsInfo(null);
  };

  if (!eventGoodsMap) {
    return null;
  }

  if (errorInfo.isError) {
    return (
      <ErrorModal
        caption={errorInfo.caption}
        msg={errorInfo.msg}
        closeModal={closeErrorModal}
      />
    );
  }

  if (isPurchased) {
    return <CompletePurchase clearLiveCommerceCart={clearLiveCommerceCart} />;
  }

  if (openInfo) {
    return (
      <StreamingLiveCommerceDisplayInfo
        openInfo={openInfo}
        goodsInfo={goodsInfo}
        // @ts-expect-error TS2322
        THEOPlayerElementHeight={props.THEOPlayerElementHeight}
        closeGoodsInfo={closeGoodsInfo}
      />
    );
  }

  return (
    <React.Fragment>
      <Global styles={liveCommerceModalOverrideStyles} />

      {!openCart && (
        <StreamingLiveCommerceGoodsList
          THEOPlayerElementHeight={props.THEOPlayerElementHeight}
          itemNum={cartTotalNum}
          pseudoCart={pseudoCart}
          openGoodsInfo={openGoodsInfo}
          openLiveCommerceCart={() => setOpenCart(true)}
          incrementCartItem={incrementCartItem}
        />
      )}

      {openCart && (
        <StreamingLiveCommerceCart
          currentVideoId={props.currentVideoId}
          THEOPlayerElementHeight={props.THEOPlayerElementHeight}
          pseudoCart={convertMapToValues(cartItemMap)}
          incrementCartItem={incrementCartItem}
          decrementCartItem={decrementCartItem}
          removeCartItem={removeCartItem}
          closeLiveCommerceCart={() => setOpenCart(false)}
        />
      )}
    </React.Fragment>
  );
};

// TODO: global 経由でのモーダルスタイル注入をしている。適切な方法を考えたい
const liveCommerceModalOverrideStyles = css`
  .loading_layout {
    display: flex;
    align-items: center;
    height: 100% !important;
    margin: 0px auto 0 !important;
    padding: 0px !important;
    background-color: initial !important;
    &:focus {
      outline: none;
    }
    .sk-cube-grid {
      margin: 0 auto !important;
    }
    .sk-cube {
      background-color: #ffffff !important;
    }
    h2 {
      display: none !important;
    }
    .popup_message {
      display: none !important;
    }
  }
`;

export default StreamingLiveCommerce;
