import React from "react";
import styled from "@emotion/styled";
import { createStyles, WithStyles } from "@material-ui/core/styles";
import withStyles, { StyleRules } from "@material-ui/core/styles/withStyles";

import type { MyProductData } from "@spwn/types";
import type { CVSCode, PhoneCode } from "@spwn/types/gmo";
import type { Event, ActiveTransaction } from "@spwn/types/firebase/firestore";

import { FullScreenDialog } from "components/common/Modal";
import {
  cvsInfo,
  getCVSNote,
  HOUR_UNTIL_PURCHASE_PHONE_CANCELED,
  CVSNote,
  phoneInfo,
} from "constants/purchase";
import {
  isOrderTransaction,
  hasVariantOrderLineItem,
} from "features/orderModel";
import { ConfirmCvsPaymentOrderDetails } from "features/ConfirmCvsPaymentOrderDetails";
import { ConfirmCvsPaymentOrderDetailsWithEvent } from "features/ConfirmCvsPaymentOrderDetailsWithEvent";
import { useI18n, I18nTrans } from "hooks/i18n/i18n";
import { LinkButton, modalWindowMixin } from "styles";
import { getEventDisplayInfo } from "utility";

const styles: StyleRules = createStyles({
  modalScroll: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
});

const _PaymentNumberContainer = styled.div`
  .contents_block {
    margin: 0 auto;
    width: 90%;
    @media screen and (min-width: 768px) {
      width: 680px;
    }
  }
  h2 {
    margin-bottom: 60px;
    font-size: 20px;
    text-align: center;
    font-weight: normal;
    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
      font-size: 25px;
    }
  }
  .shipping_fee {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
    .heading {
      padding-bottom: 5px;
      font-size: 12px;
    }
    .total {
      font-size: 14px;
      span {
        margin-left: 5px;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  .total_num {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 80px;
    .heading {
      padding-bottom: 5px;
      font-size: 12px;
    }
    .total {
      font-size: 25px;
      font-weight: bold;
      span {
        margin-left: 5px;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  .payment_info_block {
    margin-bottom: 25px;
    .info {
      &:not(:last-child) {
        margin-bottom: 25px;
      }
      h3 {
        margin-bottom: 15px;
        line-height: 1.3em;
        letter-spacing: 1px;
      }
      p {
        letter-spacing: 1px;
        line-height: 1.3em;
      }
      a {
        color: #00c2ae;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .payment_Notes {
    margin-bottom: 50px;
    @media screen and (min-width: 768px) {
      margin-bottom: 100px;
    }
    h3 {
      margin-bottom: 15px;
      font-size: 15px;
      line-height: 1.3em;
      letter-spacing: 1px;
    }
    p {
      padding-left: 1em;
      margin-bottom: 8px;
      font-size: 15px;
      text-indent: -1em;
      line-height: 1.3em;
      letter-spacing: 1px;
    }
  }
`;

const PaymentNumberContainer = styled(_PaymentNumberContainer)(
  modalWindowMixin
);

interface Props extends WithStyles<typeof styles> {
  isOpen: boolean;
  unprocessData: UnProcessData[];
  cvsCode?: CVSCode;
  ReceiptNo?: string; //"LW192350584431"
  ConfNo?: string; //"2961"
  phoneCode?: PhoneCode;
  phoneURL?: string;
  orderId: number;
  activeTransactionMap: { [orderId: string]: ActiveTransaction };
  close: () => void;
}

export interface UnProcessData {
  event: Event;
  ticketList: MyProductData[];
  goodsList: MyProductData[];
}

/**
 * コンビニ決済確認
 */
const ConfirmCvsPayment = ({
  activeTransactionMap,
  unprocessData,
  orderId,
  cvsCode,
  ReceiptNo,
  ConfNo,
  phoneCode,
  phoneURL,
  isOpen,
  close,
  classes,
}: Props) => {
  const { t } = useI18n();

  const data = activeTransactionMap?.[orderId];

  if (!unprocessData || !data) {
    return <div></div>;
  }

  const { status } = data;
  const shippingFee = data?.shippingFee ?? 0;
  const serviceFee = data?.serviceFee ?? 0;
  const settlementFee = data?.settlementFee ?? 0;

  const cvsNote = cvsCode ? getCVSNote(cvsCode, ConfNo, ReceiptNo) : null;

  const items = isOrderTransaction(data)
    ? data.lineItems.map((p) => ({
        name: p.name,
        variantName: hasVariantOrderLineItem(p) ? p.variantName : "",
        price: p.price,
        count: p.quantity,
      }))
    : (data.products ?? []).map((p) => ({
        name: p.name,
        variantName: p.subClassName ?? "",
        price: p.price_jpy,
        count: p.count,
      }));

  const totalPrice =
    items.reduce((acc, cur) => {
      return acc + cur.price * cur.count;
    }, 0) +
    shippingFee +
    serviceFee +
    settlementFee;

  const renderPhoneInfo = (status: ActiveTransaction["status"]) => {
    return (
      <>
        <div className="payment_info_block">
          <div className="info">
            <h3>{t("confirmCvsPayment.designatedPaymentMethod")}</h3>

            <p>
              {phoneCode && phoneCode !== "docomo" && phoneInfo()?.[phoneCode]}
            </p>
          </div>

          {status === "UNPROCESS" ? (
            <div className="info">
              <p>
                <I18nTrans i18nKey="confirmCvsPayment.paymentThisPage">
                  お支払いは
                  <a href={phoneURL}>こちら</a>のページからお願いします。
                </I18nTrans>
              </p>
              <p>{t("confirmCvsPayment.moveExternalSite")}</p>
              <p>
                {HOUR_UNTIL_PURCHASE_PHONE_CANCELED +
                  t("confirmCvsPayment.hourUntilPurchasePhoneCanceled")}
              </p>
            </div>
          ) : (
            <div className="info">
              <p>{t("confirmCvsPayment.note1")}</p>
              <p>
                {HOUR_UNTIL_PURCHASE_PHONE_CANCELED +
                  t("confirmCvsPayment.hourUntilPurchasePhoneCanceled")}
              </p>
            </div>
          )}
          <div className="info"></div>
        </div>
      </>
    );
  };

  const renderCvsInfo = (cvsNote: CVSNote) => {
    return (
      <>
        <div className="payment_info_block">
          <div className="info">
            <h3>{t("confirmCvsPayment.designatedConvenienceStore")}</h3>
            <p>{cvsCode && cvsInfo()[cvsCode]}</p>
          </div>

          <div className="info">
            <h3>{cvsNote.ReceiptNumberCaption}</h3>
            <p>{ReceiptNo}</p>
          </div>

          <div className="info">
            <h3>{cvsNote.ConfirmNumberCaption}</h3>
            <p>{ConfNo}</p>
          </div>
        </div>

        <div className="payment_Notes">
          <h3>{t("confirmCvsPayment.readBeforePayment")}</h3>
          {cvsNote.message.split("<br/>").map((msg, index) => {
            if (msg.startsWith("http") || msg.startsWith(" http")) {
              //httpスタートの文字の場合aタグでリンクを作成
              const link = msg.replace(" ", "");
              return (
                <p key={index}>
                  <a href={link} target="_blank" rel="noreferrer">
                    {msg}
                  </a>
                </p>
              );
            }
            return <p key={index}>{msg}</p>;
          })}
        </div>
      </>
    );
  };
  return (
    <FullScreenDialog
      className={classes.modalScroll}
      open={isOpen}
      onBackdropClick={close}
    >
      <PaymentNumberContainer>
        <div className="contents_block">
          <h2>{t("confirmCvsPayment.paymentDetails")}</h2>
          {isOrderTransaction(data) ? (
            <ConfirmCvsPaymentOrderDetails items={items} />
          ) : (
            unprocessData.map((eventData, i) => {
              const {
                eventTitle,
                artists,
                eventOpenDateFull,
                eventEndDateFull,
                isLongEvent,
                eventOpenDay,
              } = getEventDisplayInfo(eventData.event);
              const unprocessProducts = eventData.ticketList.concat(
                eventData.goodsList
              );
              return (
                <ConfirmCvsPaymentOrderDetailsWithEvent
                  key={"details_item_" + i}
                  eventTitle={eventTitle}
                  artists={artists}
                  eventOpenDateFull={eventOpenDateFull}
                  eventEndDateFull={eventEndDateFull}
                  isLongEvent={isLongEvent}
                  eventOpenDay={eventOpenDay}
                  products={unprocessProducts}
                />
              );
            })
          )}
          {shippingFee ? (
            <div className="shipping_fee">
              <p className="heading">{t("confirmCvsPayment.postage")}</p>
              <p className="total">
                ¥{shippingFee.toLocaleString()}
                <span>{t("confirmCvsPayment.taxIncluded")}</span>
              </p>
            </div>
          ) : null}
          {serviceFee ? (
            <div className="shipping_fee">
              <p className="heading">{t("confirmCvsPayment.serviceFee")}</p>
              <p className="total">
                ¥{serviceFee.toLocaleString()}
                <span>{t("confirmCvsPayment.taxIncluded")}</span>
              </p>
            </div>
          ) : null}
          {settlementFee ? (
            <div className="shipping_fee">
              <p className="heading">
                {t("confirmCvsPayment.settlementSystemFees")}
              </p>
              <p className="total">
                ¥{settlementFee.toLocaleString()}
                <span>{t("confirmCvsPayment.taxIncluded")}</span>
              </p>
            </div>
          ) : null}
          <div className="total_num">
            <p className="heading">{t("confirmCvsPayment.totalOfProducts")}</p>
            <p className="total">
              ¥{totalPrice.toLocaleString()}
              <span>{t("confirmCvsPayment.taxIncluded")}</span>
            </p>
          </div>

          {!!cvsCode && !!cvsNote && renderCvsInfo(cvsNote)}
          {phoneCode && renderPhoneInfo(status)}

          <LinkButton onClick={close}>
            {t("confirmCvsPayment.close")}
          </LinkButton>
        </div>
        <p
          className="modal_close"
          onClick={close}
          aria-label={t("confirmCvsPayment.close")}
        >
          ×
        </p>
      </PaymentNumberContainer>
    </FullScreenDialog>
  );
};

export default withStyles(styles)(ConfirmCvsPayment);
