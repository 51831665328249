import { css } from "@emotion/core";

export const myPageEditMixin = css`
  min-height: calc(100vh - 436px);
  padding: 40px 0 80px;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    min-height: calc(100vh - 280px);
    padding-top: 0;
  }
`;
