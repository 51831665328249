import { THEOSetting } from "@spwn/types/firebase/firestore";

import firebase from "firebase";
import useSWR, { SWRResponse } from "swr";

type UseGetPlayerSettingResult = {
  liveSetting: THEOSetting | null;
  vodSetting: THEOSetting | null;
};

type UseGetPlayerSetting = () => SWRResponse<UseGetPlayerSettingResult>;

export const useGetPlayerSetting: UseGetPlayerSetting = () => {
  return useSWR("getPlayerSetting", async () => {
    const [liveSetting, vodSetting] = await Promise.all([
      fetchPlayerLiveSetting(),
      fetchPlayerVodSetting(),
    ]);
    return {
      liveSetting,
      vodSetting,
    };
  });
};

const fetchPlayerLiveSetting = async () => {
  const snapshot = await firebase
    .firestore()
    .collection("THEOSettings")
    .doc("Live")
    .get();
  if (!snapshot.exists) return null;
  return snapshot.data() as THEOSetting;
};

const fetchPlayerVodSetting = async () => {
  const snapshot = await firebase
    .firestore()
    .collection("THEOSettings")
    .doc("VOD")
    .get();
  if (!snapshot.exists) return null;
  return snapshot.data() as THEOSetting;
};
