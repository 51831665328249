import React, { Children, useEffect, useState, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { eventActions } from "modules/event";
import { getEventIdFromHref } from "utility";
import { getRedirectDomainByHostingType } from "utility/hosting";
import { firestoreActions } from "modules/firestore";
import { GoExternalSite } from "components/common/Link";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { useQueryString } from "hooks/QueryString";

type Props = {};

const EventBase: React.FC<Props> = (props) => {
  const location = useSelector((state: Store) => state.router.location);
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const queryObject = useQueryString<{ preview: "0" | "1" }>();

  // @ts-expect-error TS2345
  const [redirectURL, setRedirectURL] = useState<string>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    // fetch specific event data
    const eventId = getEventIdFromHref(location.pathname, "events");
    dispatch(
      eventActions.watchOneEvent.started({
        // @ts-expect-error TS2322
        eventId,
        isPreview: queryObject.preview === "1",
      })
    );
    return () => {
      dispatch(firestoreActions.closeChannel({ channel: "oneEvent" }));
      dispatch(eventActions.clearStateByKey("displayEvent"));
      dispatch(eventActions.clearStateByKey("goodsPageSettings"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!displayEvent) {
      return;
    }
    // !!! use first index of belongedHostings !!!
    const redirectDomain = getRedirectDomainByHostingType(
      displayEvent.belongedHostings
    );
    if (redirectDomain) {
      setRedirectURL(`https://${redirectDomain}${location.pathname}`);
    } else {
      setRedirectURL("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayEvent]);

  const newProps = {
    parentProps: {},
  };

  const childrenWithProps = Children.map(
    // @ts-expect-error TS2345
    props.children,
    (child: ReactElement) => {
      switch (typeof child) {
        case "string":
          return child;
        case "object":
          return React.cloneElement(child, newProps);
        default:
          return null;
      }
    }
  );

  if (!displayEvent || redirectURL === null) {
    return (
      <div id="contents-layout">
        <LoaderLayout />
      </div>
    );
  }

  if (redirectURL) {
    return (
      <GoExternalSite
        externalLink={redirectURL}
        caution={
          "※遷移先で再度ログインを求められる場合があります。アカウントにお間違いのないようログインをお願いします。 / You may be asked to log in again at the destination. Please make sure you have the correct account."
        }
      />
    );
  }

  return <>{childrenWithProps}</>;
};

export default EventBase;
