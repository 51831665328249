/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import Skeleton from "react-loading-skeleton";
import sample_skeleton from "../../designs/img/sample_skeleton_event_img.jpg";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import styled from "@emotion/styled";
import { EventCard, EventMainCard, fontDinMixin } from "../../styles";
import { changeStyleWithHosting } from "utility/hosting";
import { useI18n } from "hooks/i18n/i18n";
import { LeftBorderHeadline } from "components/atoms/headline/LeftBorderHeadline";

export const EventMainCardSkeleton = () => {
  return (
    <EventMainCard>
      <div className="datetime_area">
        <p className="datetime">
          <span>
            <Skeleton width={100} />
          </span>
        </p>
      </div>
      <div className="thumbnail_area">
        <div className="event_img">
          <img src={sample_skeleton} alt="eventImage" />
        </div>
        <p className="event_place">
          <span>
            <Skeleton width={100} />
          </span>
        </p>
      </div>
      <div className="information_area">
        <h3 className="event_title">
          <Skeleton count={2} />
        </h3>
        <p className="event_artist">
          <Skeleton count={1} />
        </p>
      </div>
    </EventMainCard>
  );
};

const SkeletonLoaderEventCard = styled(EventCard)`
  width: 47.5%;
  @media screen and (min-width: 768px) {
    width: 31%;
  }
`;

SkeletonLoaderEventCard.displayName = "SkeletonLoaderEventCard";

export const EventCardSkeleton = () => {
  return (
    <SkeletonLoaderEventCard>
      <div className="datetime_area">
        <p className="datetime">
          <span>
            <Skeleton width={50} />
          </span>
        </p>
      </div>
      <div className="thumbnail_area">
        <div className="event_img">
          <img src={sample_skeleton} alt="EventImage" />
        </div>
        <p className="event_place">
          <span>
            <Skeleton width={100} />
          </span>
        </p>
      </div>
      <div className="information_area">
        <h3 className="event_title">
          <Skeleton count={2} />
        </h3>
        <p className="event_artist">
          <Skeleton count={1} />
        </p>
      </div>
    </SkeletonLoaderEventCard>
  );
};

const TopSkeletonContainer = styled.section`
  max-width: 980px;
  width: 90%;
  margin: 0 auto 80px;
  @media screen and (min-width: 768px) {
    margin-bottom: 120px;
  }
  .topPageHeadingDesign {
    margin-bottom: 20px;
    letter-spacing: 1px;
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
    }
    h2 {
      ${fontDinMixin};
      padding: 5px 0 0 15px;
      margin-bottom: 5px;
      color: #fff;
      font-size: 35px;
      border-left: 5px solid #01ffe1;
      @media screen and (min-width: 768px) {
        margin-bottom: 0;
        letter-spacing: 3px;
      }
    }
    p {
      padding-left: 21px;
      color: #fff;
      font-size: 12px;
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }
  }
  .pickedUpEvent {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    > article {
      margin-bottom: 20px;
    }
  }
  .newArrivalsEventList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &:after {
      content: "";
      display: block;
      width: 47.5%;
      @media screen and (min-width: 768px) {
        width: 31%;
      }
    }
    > article {
      margin-bottom: 20px;
    }
  }
  .buttonForLink {
    display: block;
    padding: 12px 0;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 1.2em;
    letter-spacing: 1px;
    background-color: #00c2ae;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      padding: 15px 0;
      letter-spacing: 2px;
    }
    &:hover {
      background-color: #00afa8;
    }
  }
`;

export const TopSkeleton = () => {
  return (
    <TopSkeletonContainer id="futureEventList">
      <div className="topPageHeadingDesign">
        <h2>UPCOMING EVENT</h2>
        <p>今後のイベント</p>
      </div>
      <div className="pickedUpEvent">
        <EventMainCardSkeleton />
        <EventMainCardSkeleton />
      </div>
      <div className="newArrivalsEventList">
        <EventCardSkeleton />
        <EventCardSkeleton />
        <EventCardSkeleton />
      </div>
      <Link className="buttonForLink" to={`/events`}>
        イベント一覧へ
      </Link>
    </TopSkeletonContainer>
  );
};

const {
  baseStyles: { accentColor, fontSecondaryColor },
} = changeStyleWithHosting();
const ArchiveVideoSkeletonContainer = styled.div`
  margin-top: 50px;
  > .headingDesign {
    margin-bottom: 50px;
    letter-spacing: 1px;
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
    }
    > h2 {
      ${fontDinMixin};
      padding: 5px 0 0 15px;
      margin-bottom: 5px;
      color: ${fontSecondaryColor};
      font-size: 35px;
      line-height: 1em;
      border-left: 5px solid ${accentColor};
      @media screen and (min-width: 768px) {
        margin-bottom: 0;
        letter-spacing: 3px;
      }
    }
    > p {
      padding-left: 21px;
      color: ${fontSecondaryColor};
      font-size: 12px;
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }
  }
  > .deliveringLiveVideoList {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &:after {
      @media screen and (min-width: 768px) {
        content: "";
        display: block;
        width: 31%;
      }
    }
    > .deliveringLiveVideos {
      width: 100%;
      border: 1px solid #d4d4d4;
      @media screen and (min-width: 768px) {
        width: 31%;
        margin-bottom: 20px;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      > .thumbnailDisplayArea {
        position: relative;
        .videoThumbnail {
          width: 100%;
        }
      }
      > .deliveryVideoInformationLayout {
        padding: 15px;
        @media screen and (min-width: 768px) {
          padding: 20px;
        }
        > .deliveryVideoInformation {
          min-height: 70px;
          margin-bottom: 15px;
          @media screen and (min-width: 768px) {
            min-height: 100px;
          }
          > .ticketName {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5em;
            @media screen and (min-width: 768px) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
`;

export const ArchiveVideoSkeleton = () => {
  return (
    <ArchiveVideoSkeletonContainer id="archive_video">
      <div className="headingDesign">
        <h2>LIVE</h2>
        <p>ライブ配信</p>
      </div>
      <div className="deliveringLiveVideoList">
        <div className="deliveringLiveVideos">
          <div className="thumbnailDisplayArea">
            <img src={sample_skeleton} alt={""} className="videoThumbnail" />
          </div>
          <div className="deliveryVideoInformationLayout">
            <div className="deliveryVideoInformation">
              <p className="ticketName">
                <Skeleton height={30} width={200} />
              </p>
            </div>
            <Skeleton height={40} width={270} />
          </div>
        </div>
      </div>
    </ArchiveVideoSkeletonContainer>
  );
};

export const VideoThumbnailSkeleton = () => {
  const { t } = useI18n();
  return (
    <ArchiveVideoSkeletonContainer id="archive_video">
      <div className="headingDesign">
        <LeftBorderHeadline
          title={t("event.headline.liveStreaming.text")}
          subTitle={t("event.headline.liveStreaming.subText")}
          fontColor={"secondary"}
        />
      </div>
      <div className="deliveringLiveVideoList">
        <div className="deliveringLiveVideos">
          <div className="thumbnailDisplayArea">
            <img src={sample_skeleton} alt={""} className="videoThumbnail" />
          </div>
        </div>
      </div>
    </ArchiveVideoSkeletonContainer>
  );
};

export const PurchaseTicketSkeleton = () => {
  const { t } = useI18n();
  return (
    <ArchiveVideoSkeletonContainer id="archive_video">
      <div className="headingDesign">
        <LeftBorderHeadline
          title={t("event.headline.streamingTicket.text")}
          subTitle={t("event.headline.streamingTicket.subText")}
          fontColor={"secondary"}
        />
      </div>
      <div className="deliveringLiveVideoList">
        <div className="deliveringLiveVideos">
          <div className="thumbnailDisplayArea">
            <img src={sample_skeleton} alt={""} className="videoThumbnail" />
          </div>
          <div className="deliveryVideoInformationLayout">
            <div className="deliveryVideoInformation">
              <p className="ticketName">
                <Skeleton height={30} width={200} />
              </p>
            </div>
            <Skeleton height={40} width={270} />
          </div>
        </div>
      </div>
    </ArchiveVideoSkeletonContainer>
  );
};

export const PortalEventListSkeleton = () => {
  const classesEventListDesign = eventListDesign();
  return (
    <article css={classesEventListDesign.item}>
      <div css={classesEventListDesign.thumbnail}>
        <img src={sample_skeleton} alt="" />
        <p>
          <Skeleton width={50} />
        </p>
      </div>

      <div css={classesEventListDesign.info}>
        <p css={classesEventListDesign.datetime}>
          <time>
            <Skeleton width={55} />
          </time>
        </p>
        <h3 css={classesEventListDesign.title}>
          <Skeleton count={3} />
        </h3>
      </div>
    </article>
  );
};
const eventListDesign = () => {
  const item = css`
    width: 47.5%;
    margin-bottom: 24px;
    border-radius: 8px;
    @media screen and (min-width: 768px) {
      width: 31.5%;
    }
    a {
      display: block;
    }
  `;
  const thumbnail = css`
    position: relative;
    img {
      width: 100%;
      border-radius: 8px 8px 0 0;
    }
    p {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 6px 10px;
      font-size: 14px;
      font-weight: bold;
      -webkit-letter-spacing: 1px;
      -moz-letter-spacing: 1px;
      -ms-letter-spacing: 1px;
      letter-spacing: 1px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 0 8px 0 0;
    }
  `;
  const info = css`
    padding: 16px;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
  `;
  const flexbox = css`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  `;
  const datetime = css`
    width: 100%;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    @media screen and (min-width: 768px) {
      font-size: 18px;
    }
  `;
  const title = css`
    display: box;
    height: 4.5em;
    font-size: 14px;
    line-height: 1.5em;
    /* stylelint-disable-next-line property-no-unknown */
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 3;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  `;
  return {
    item,
    thumbnail,
    info,
    flexbox,
    datetime,
    title,
  };
};

interface PortalEventListSliderProps {
  isDesktop: boolean;
}
export const PortalEventListSliderSkeleton = (
  props: PortalEventListSliderProps
) => {
  const settings = {
    className: "center",
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    infinite: true,
    centerPadding: props.isDesktop ? "20%" : "0%",
    slidesToShow: 1,
    speed: 500,
    prevArrow: (
      <button className="prevArrow">
        <ChevronLeftIcon color={"action"} fontSize={"large"} />
      </button>
    ),
    nextArrow: (
      <button className="nextArrow">
        <ChevronRightIcon color={"action"} fontSize={"large"} />
      </button>
    ),
  };

  const classesPortalEventListSlider = portalEventListSlider();
  return (
    <section css={classesPortalEventListSlider.root}>
      <Slider {...settings}>
        <div
          css={css`
            overflow: hidden;
          `}
        >
          <img src={sample_skeleton} alt="" />
          <h2>
            <Skeleton count={1} />
          </h2>
        </div>
        <div
          css={css`
            overflow: hidden;
          `}
        >
          <img src={sample_skeleton} alt="" />
          <h2>
            <Skeleton count={1} />
          </h2>
        </div>
        <div
          css={css`
            overflow: hidden;
          `}
        >
          <img src={sample_skeleton} alt="" />
          <h2>
            <Skeleton count={1} />
          </h2>
        </div>
      </Slider>
    </section>
  );
};

const portalEventListSlider = () => {
  return {
    root: css`
      @media screen and (min-width: 768px) {
        padding-top: 65px;
      }
      .slick-slide {
        position: relative;
        h2 {
          display: box;
          height: 1em;
          padding: 0 16px;
          margin: 16px 0 0;
          font-size: 14px;
          text-align: center;
          /* stylelint-disable-next-line property-no-unknown */
          box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          -webkit-line-clamp: 1;
          @media screen and (min-width: 768px) {
            margin: 16px 0 16px;
            font-size: 16px;
          }
        }
        &:not(.slick-current) {
          h2 {
            display: none;
          }
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }
        img {
          width: 100%;
        }
      }
      .slick-dots {
        position: relative;
        bottom: 0;
        li button::before {
          color: #dedede;
          font-size: 12px;
        }
        li.slick-active button::before {
          color: #2ee7d2;
        }
      }
      .slick-prev,
      .slick-next {
        width: auto;
        height: auto;
        margin-top: -35px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;
        @media screen and (min-width: 768px) {
          margin-top: calc(-17.5px - 48px);
        }
        &:before {
          display: none;
        }
        svg {
          fill: #fff;
        }
      }
      .slick-prev {
        left: 0;
      }
      .slick-next {
        right: 0;
      }
    `,
  };
};
