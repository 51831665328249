import { EventInfo } from "../../utility";
// import { PublicPlanContentStreamingDTO } from "@spwn/types/functions";
import { EventVideo, THEOSetting } from "@spwn/types/firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import { useEffect, useState } from "react";
import { useQuery } from "../../utility/useQuery";
import { eventActions } from "../../modules/event";
import { streamingActions } from "../../modules/streaming";
import { firestoreActions } from "../../modules/firestore";
import { useGetCrewPlanContent } from "./useGetCrewPlanContent";
import { fetchFirestoreDocument } from "../../utility/firebase";
import { PublicPlanContentStreamingDTO } from "api/schema/spwn/dto/publicPlanContent";

type UseCrewStreamingAuthenticatorResult = {
  displayEvent: EventInfo;
  eventVideoMap: { [videoId: string]: EventVideo } | null;
  liveSetting: THEOSetting | null;
  vodSetting: THEOSetting | null;
};

/**
 * CrewStreaming 向けのカスタムフック
 */
export const useCrewStreaming = (): UseCrewStreamingAuthenticatorResult => {
  const { displayEvent, eventVideoMap } = useHandleEventVideoAndVideo();
  const { liveSetting, vodSetting } = useHandleLiveAndVODSetting();

  return {
    displayEvent,
    eventVideoMap,
    liveSetting,
    vodSetting,
  };
};

type UseHandleEventVideoAndVideoResult = {
  displayEvent: EventInfo;
  eventVideoMap: { [videoId: string]: EventVideo } | null;
};

/**
 * イベントとイベント動画の取得を行う
 * onSnapshot で DB の変更をウォッチする
 */
const useHandleEventVideoAndVideo = (): UseHandleEventVideoAndVideoResult => {
  const eventId = useQuery().get("eventId");

  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const eventVideoMap = useSelector(
    (state: Store) => state.streaming.eventVideoMap
  );

  // イベントを onSnapshot でウォッチする
  const dispatch = useDispatch();
  useEffect(() => {
    if (eventId === "") {
      return;
    }

    // @ts-expect-error TS2322
    dispatch(eventActions.watchOneEvent.started({ eventId }));
    // @ts-expect-error TS2322
    dispatch(streamingActions.watchEventVideos.started({ eventId }));

    return () => {
      // ページローディングが走らないようにonSnapshotのみclose。
      dispatch(firestoreActions.closeChannel({ channel: "oneEvent" }));
      dispatch(firestoreActions.closeChannel({ channel: "eventVideos" }));
    };
  }, [dispatch, eventId /* setError */]);

  return { displayEvent, eventVideoMap };
};

type UseHandleLiveAndVODSettingResult = {
  liveSetting: THEOSetting | null;
  vodSetting: THEOSetting | null;
};

/**
 * ライブ配信とアーカイブ配信の THEO プレイヤー設定を取得する
 */
const useHandleLiveAndVODSetting = (): UseHandleLiveAndVODSettingResult => {
  const [liveSetting, setLiveSetting] = useState(null);
  const [vodSetting, setVodSetting] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    getPlayerSettings();
    dispatch(streamingActions.createMySmallIconUrl.started());
    return () => {};
  }, [dispatch]);

  const getPlayerSettings = async () => {
    // @ts-expect-error TS2345
    setLiveSetting(await fetchFirestoreDocument(`/THEOSettings/Live`));
    // @ts-expect-error TS2345
    setVodSetting(await fetchFirestoreDocument(`/THEOSettings/VOD`));
  };

  return {
    liveSetting,
    vodSetting,
  };
};

type FetchStreamingPlanContentResult = {
  streamingPlanContent: PublicPlanContentStreamingDTO | undefined;
  mutate: () => Promise<PublicPlanContentStreamingDTO | undefined>;
  error?: unknown;
};

/**
 * 配信コンテンツを取得する
 * プランコンテンツの取得結果が存在しない場合は undefined を返します
 */
export const useFetchStreamingPlanContent =
  (): FetchStreamingPlanContentResult => {
    const undefinedResult: FetchStreamingPlanContentResult = {
      streamingPlanContent: undefined,
      mutate: async () => Promise.resolve(undefined),
    };

    const tenantId = useQuery().get("tenantId");
    const planContentId = useQuery().get("planContentId");

    // プランコンテンツと mutate 関数を取得
    const { data, mutate, error } = useGetCrewPlanContent({
      // @ts-expect-error TS2322
      tenantId,
      // @ts-expect-error TS2322
      planContentId,
    });
    // エラーがあれば先に返す（APIエラーで、予期せぬエラー）
    if (error) {
      return {
        ...undefinedResult,
        error,
      };
    }
    // データ取得待ち
    if (data === undefined) {
      return undefinedResult;
    }
    // 想定内のステータスエラー（権限がないなど）
    if (!data.ok) {
      return {
        ...undefinedResult,
        error: `エラーが発生しました。エラーステータス: ${data.status}`,
      };
    }
    // コンテンツが存在しない場合
    if (!data.planContent) {
      return undefinedResult;
    }
    // 配信コンテンツ以外は除外
    if (data.planContent.type !== "streaming") {
      return undefinedResult;
    }

    // mutate し、配信コンテンツを取得する関数を構築
    const mutateFunc: FetchStreamingPlanContentResult["mutate"] = async () => {
      const data = await mutate();
      if (!data || !data.ok || !data.planContent) {
        return undefined;
      }
      if (data.planContent.type !== "streaming") {
        return undefined;
      }

      return data.planContent;
    };

    return {
      streamingPlanContent: data.planContent,
      mutate: mutateFunc,
      error,
    };
  };
