/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../../store";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { Redirect } from "react-router";
import { RedirectState } from "modules/router";

import appConfig from "../../constants/appConfig";
import { modalActions, ActionData } from "../../modules/modal";
import { getEventIdFromHref, EventInfo } from "../../utility";
import Streaming from "../../containers/streaming/Streaming";
import { push } from "connected-react-router";
import { streamingActions } from "../../modules/streaming";
import { errorReport } from "utility/logger";
import { firestoreActions } from "modules/firestore";
import { fetchFirestoreDocument } from "../../utility/firebase";
import { WithTranslation, withTranslation } from "react-i18next";
import { TFunction } from "i18next";

import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { fetchFirestoreCollectionBySnapshot } from "utility/firebase";
import { SubContents } from "components/templates/SubContents";
import { DocomoAccountCaution } from "components/molecules/DocomoAccountCaution";
import { isDocomoDomain } from "utility/hosting";
import type { Cookie, ResGetStreamingKey } from "@spwn/types/functions";
import type {
  Event,
  EventVideo,
  THEOSetting,
} from "@spwn/types/firebase/firestore";

interface Props extends WithTranslation {
  t: TFunction;
  // @ts-expect-error TS7051
  toggleNotice: (any) => void;
  // @ts-expect-error TS7051
  toggleError: (any) => void;
  // @ts-expect-error TS7051
  changeRoute: (string) => void;
  toggleActionModal: (payload: ActionData) => void;
  watchEventVideos: (eventId: string) => void;
  clearStreamingData: () => void;
  setStreamingKey: (payload: ResGetStreamingKey) => void;
  createMySmallIconUrl: () => void;
  getStreamingSettings: () => void;
  fetchNgWords: (eventId: string) => void;
  // @ts-expect-error TS7008
  user: { uid };
  pathname: string;
  cookies: unknown;
  eventInfo: EventInfo | null;
  isAdmin: boolean;
  // @ts-expect-error TS7008
  location: { state };
  eventVideoMap: { [videoId: string]: EventVideo } | null;
  isDarkModeEnabled: boolean;
}

interface States {
  url: string;
  hasTicket: boolean;
  cookies: {
    [videoId: string]: {
      LL?: Cookie;
      default: Cookie;
    };
  };
  cookiesList: {
    [videoId: string]: {
      [videoId: string]: {
        LL?: Cookie;
        default: Cookie;
      };
    }[];
  };
  isPlayerSettingsLoaded: boolean;
  needTicket: boolean;
  linkageState: unknown;
}

class StreamingTicketAuthenticator extends Component<Props, States> {
  _eventId = null;
  /**
   * @deprecated Use props.eventInfo
   */
  // @ts-expect-error TS2322
  _event: Event = null;
  // @ts-expect-error TS2416
  state = {
    url: "",
    hasTicket: false,
    cookies: null,
    cookiesList: null,
    isPlayerSettingsLoaded: false,
    needTicket: false,
    linkageState: [],
  };
  _vid = null;
  _playerSettingsAreLoaded = false;
  _liveTheoSetting: THEOSetting = {
    abr: {
      strategy: "bandwidth",
      targetBuffer: 7,
    },
    playBackRates: [1],
    allowNativeFullscreen: false,
    initialLiveOffset: 10,
  };
  _vodTheoSetting: THEOSetting = {
    abr: {
      strategy: "quality",
      targetBuffer: 12,
    },
    playBackRates: [2, 1.5, 1, 0.75],
    allowNativeFullscreen: false,
    initialLiveOffset: 10,
  };

  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    this.getPlayerSettings();
    // @ts-expect-error TS2322
    this._eventId = getEventIdFromHref(this.props.pathname, "events");
    this.setRestorationVid();
  }

  goTicketPage = () => {
    this.props.changeRoute(`/events/${this._eventId}/ticket`);
  };

  setRestorationVid = () => {
    // 閲覧中のビデオ情報を復元する
    // 導出元 通常遷移redux store, リロード遷移 queryパラメータ
    const url = new URL(window.location.href);
    const queryVid = url.searchParams.get("vid");
    this._vid =
      (!!this.props.location.state && this.props.location.state.vid) ||
      queryVid ||
      null;
  };

  getPlayerSettings = async () => {
    const vod = await fetchFirestoreDocument(`/THEOSettings/VOD`);
    if (vod) {
      this._vodTheoSetting = {
        ...this._vodTheoSetting,
        ...vod,
      };
    }
    const live = await fetchFirestoreDocument(`/THEOSettings/Live`);
    if (live) {
      this._liveTheoSetting = {
        ...this._liveTheoSetting,
        ...live,
      };
    }
    this.setState({ isPlayerSettingsLoaded: true });
  };

  get_streaming_key = async () => {
    const { t } = this.props;
    try {
      // @ts-expect-error TS2531
      const fbToken = await firebase.auth().currentUser.getIdToken(true);
      // @ts-expect-error TS2769
      const response = await fetch(appConfig.CloudFunctions.getStreamingKey, {
        // const response = await fetch("http://www.spwn.jp:8000/get_streaming_key", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `'Bearer ${fbToken}`,
        },
        body: JSON.stringify({ eid: this._eventId }),
      });

      if (response.status === 200) {
        const json = (await response.json()) as ResGetStreamingKey;
        // HOTFIX: actually, should use getStreamingKey module in streaming module.
        this.props.setStreamingKey(json);

        if (json.hasOwnProperty("cookies")) {
          // @ts-expect-error TS2322
          this._vid = this._vid || Object.keys(json.cookies)[0]; // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
          if (json.hasOwnProperty("url")) {
            this.setState({
              // @ts-expect-error TS2322
              cookies: json.cookies,
              // @ts-expect-error TS2322
              cookiesList: json?.cookiesList,
              hasTicket: true,
              url: json.url,
            });
          } else {
            this.setState({
              // @ts-expect-error TS2322
              cookies: json.cookies,
              // @ts-expect-error TS2322
              cookiesList: json?.cookiesList,
              hasTicket: true,
            });
          }

          // streaming is free open
        } else if (json.hasOwnProperty("url") && Boolean(json.url)) {
          this.setState({ url: json.url });
        } else {
          if (json.hasTickets) {
            this.setState({ hasTicket: true });
          } else {
            this.props.toggleActionModal({
              actionModalType: "confirmAction",
              caption: t("streaming.authenticator.needTicket"),
              msg: t("streaming.authenticator.needTicketCaution"),
              btnMsg: t("streaming.authenticator.buyTicket"),
              action: this.goTicketPage,
              // @ts-expect-error TS2322
              args: null,
            });
            this.setState({ needTicket: true });
          }
        }
        return;
      } else {
        console.error("other error");
        this.props.toggleError({
          msg: t("streaming.authenticator.lostTicket"),
        });
      }
    } catch (e) {
      console.error(e);
      this.props.toggleError({ msg: t("streaming.authenticator.lostTicket") });
    }
  };

  fetchDocomoAccountLinkingState = async () => {
    const snapshot = firebase
      .firestore()
      .collection(`openId/docomo/linkingStates`)
      .where("uid", "==", this.props.user.uid)
      .get();
    const linkageState = await fetchFirestoreCollectionBySnapshot(snapshot);
    this.setState({ linkageState });
  };

  // @ts-expect-error TS7006
  geoError = async (eid) => {
    // @ts-expect-error TS2531
    const fbToken = await firebase.auth().currentUser.getIdToken(true);
    // @ts-expect-error TS2769
    const response = await fetch(appConfig.CloudFunctions.checkGeo, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify({ eid }),
    });
    if (response.status === 200) {
      const json = await response.json();
      if (json.hasOwnProperty("isError") && json.isError) {
        return true;
      }
      return false;
    }
    return false;
  };

  forceExit = (t: TFunction) => {
    // if streaming is end in streaming page, pass this.
    // 文言はeventごとに変えるかも？
    const caption = t("streaming.authenticator.end");
    // const msg = 'ご視聴ありがとうございました。<br/>アーカイブ配信を視聴予定の方は今しばらくお待ちください。'
    const msg = t("streaming.authenticator.thank");
    const redirectState: RedirectState = {
      noticeCaption: caption,
      noticeMsg: msg,
    };
    return (
      <Redirect
        to={{ pathname: `/events/${this._eventId}`, state: redirectState }}
      />
    );
  };

  // @ts-expect-error TS7006
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    // ref: https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html#introducing-error-boundaries
    errorReport(error);
    return null;
  }

  async componentDidMount() {
    const { t } = this.props;
    const isError = await this.geoError(this._eventId);
    if (isError) {
      this.props.toggleError({ msg: t("streaming.IPBlock.p1") });
      this.props.changeRoute(`/events/${this._eventId}`);
      return;
    }
    await this.fetchDocomoAccountLinkingState();
    await this.get_streaming_key();
    // @ts-expect-error TS2345
    this.props.watchEventVideos(this._eventId);
    // @ts-expect-error TS2345
    this.props.fetchNgWords(this._eventId);
    // create icon image
    this.props.createMySmallIconUrl();
    this.props.getStreamingSettings();
  }

  async componentDidUpdate(prevProps: Props, prevState: States) {
    if (
      this.props.user.uid === prevProps.user.uid &&
      this.state.url === prevState.url &&
      this.state.cookies === prevState.cookies &&
      this.props.eventInfo === prevProps.eventInfo
    )
      return;
    if (
      this.props.user.uid !== undefined &&
      this.state.url === "" &&
      this.props.eventInfo !== null
    ) {
      if (
        this.props.eventInfo.hasStreamingStarted === true &&
        this.state.cookies === null
      ) {
        await this.get_streaming_key();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearStreamingData();
  }

  render() {
    const { t, eventInfo } = this.props;
    // check if render is enable
    if (!eventInfo || this.props.isAdmin === null) {
      return null;
    }

    // check if user can enter page (* admin can enter)
    if (this.state.needTicket) {
      return <Redirect to={{ pathname: `/events/${this._eventId}` }} />;
    }
    // イベント単位での強制退出チェック
    if (!(eventInfo.isOpenStreamingPage || this.props.isAdmin)) {
      return this.forceExit(t);
    }

    const enableEnterStreamingPage =
      this.props.eventVideoMap !== null &&
      (this.state.cookies !== null ||
        this.state.url !== "" ||
        (eventInfo.isOpenStreamingPage && this.state.hasTicket) ||
        this.props.isAdmin) &&
      this._vid !== null; // !!! urlの機能を使えなくなるが、無料配信は無料チケットを作成するフローになってるので基本そうする !!!

    const classesStreamingContentsLayout = streamingContentsLayout({
      isDarkModeEnabled: this.props.isDarkModeEnabled,
    });

    if (!enableEnterStreamingPage || !this.state.isPlayerSettingsLoaded) {
      return (
        <div id="contents-layout">
          <LoaderLayout
            loadingText={`
              ${t("streaming.authenticator.loading1")}
            `}
          />
        </div>
      );
    }

    // ビデオ単位での強制退出チェック
    if (
      this._vid &&
      !!this.props.eventVideoMap[this._vid] &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.props.eventVideoMap[this._vid]!.isOpen === false &&
      !this.props.isAdmin
    ) {
      return this.forceExit(t);
    }

    if (isDocomoDomain() && this.state.linkageState.length === 0) {
      return (
        <SubContents>
          <div
            css={css`
              width: 90%;
              padding: 80px 0;
              margin: 0 auto;
            `}
          >
            <DocomoAccountCaution />
          </div>
        </SubContents>
      );
    }

    return (
      <React.Fragment>
        <main css={classesStreamingContentsLayout.root}>
          <div css={classesStreamingContentsLayout.inner}>
            <Streaming
              url={this.state.url}
              // @ts-expect-error TS2322
              cookies={this.state.cookies}
              cookiesList={this.state.cookiesList}
              event={eventInfo}
              hasTicket={this.state.hasTicket}
              // @ts-expect-error TS2322
              vid={this._vid}
              eventVideoMap={this.props.eventVideoMap}
              vodSetting={this._vodTheoSetting}
              liveSetting={this._liveTheoSetting}
              getStreamingKey={this.get_streaming_key}
            />
          </div>
        </main>
        {/*TODO: switch player to reduce player cost*/}
        {/*{(this._event === null || (!!this._event.hasVOD && this._event.hasVOD)) ?*/}
        {/*  <script*/}
        {/*    src="https://public.spwn.jp/utils/THEOplayer-dev/THEOplayer.js"></script>*/}
        {/*  :<script*/}
        {/*    src="https://cdn.myth.theoplayer.com/fffa7290-b785-46e7-8e75-d9bb4b22c211/THEOplayer.js"></script>*/}
        {/*}*/}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: Store) => {
  return {
    user: state.auth.user,
    eventInfo: state.event.displayEvent,
    pathname: state.router.location.pathname,
    isAdmin: state.admin.isAdmin,
    location: state.router.location,
    eventVideoMap: state.streaming.eventVideoMap,
    isDarkModeEnabled: state.streaming.streamingSettings.isDarkModeEnabled,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    // @ts-expect-error TS7006
    toggleNotice: (payload) => {
      dispatch(modalActions.toggleNotice(payload));
    },
    // @ts-expect-error TS7006
    toggleError: (payload) => {
      dispatch(modalActions.toggleError(payload));
    },
    // @ts-expect-error TS7006
    changeRoute: (link) => {
      dispatch(push(link));
    },
    // @ts-expect-error TS7006
    toggleActionModal: (payload) => {
      dispatch(modalActions.toggleActionModal(payload));
    },
    // @ts-expect-error TS7006
    watchEventVideos: (eventId) => {
      dispatch(streamingActions.watchEventVideos.started({ eventId }));
    },
    clearStreamingData: () => {
      dispatch(streamingActions.clearStateByKey("eventVideoMap"));
      dispatch(firestoreActions.closeChannel({ channel: "streamingComments" }));
      dispatch(
        firestoreActions.closeChannel({ channel: "streamingBANComments" })
      );
      dispatch(firestoreActions.closeChannel({ channel: "eventVideos" }));
      dispatch(streamingActions.clearStateByKey("liveCommerce"));
    },
    // @ts-expect-error TS7006
    setStreamingKey: (payload) => {
      dispatch(streamingActions.getStreamingKey.done(payload));
    },
    createMySmallIconUrl: () => {
      dispatch(streamingActions.createMySmallIconUrl.started());
    },
    getStreamingSettings: () => {
      dispatch(streamingActions.getStreamingSettings());
    },
    // @ts-expect-error TS7006
    fetchNgWords: (eventId) => {
      dispatch(streamingActions.fetchNgWords.started({ eventId }));
    },
  };
};

interface StreamingContentsLayoutProps {
  isDarkModeEnabled: boolean;
}
const streamingContentsLayout = (props?: StreamingContentsLayoutProps) => {
  // @ts-expect-error TS18048
  const backgroundColorInDarkMode = props.isDarkModeEnabled
    ? css`
        background-color: #202020;
      `
    : css`
        background-color: #fff;
      `;

  return {
    root: css``,
    inner: css`
      margin: 0 auto;
      ${backgroundColorInDarkMode}
      @media screen and (min-width: 768px) {
        width: 100%;
        max-width: 1800px;
        min-height: calc(100vh - 66px - 190px);
      }
    `,
  };
};

const TransTicketAuthenticator = withTranslation()(
  StreamingTicketAuthenticator
);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransTicketAuthenticator);
