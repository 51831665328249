import { HostingType } from "@spwn/types/firebase/firestore";
import React from "react";
import { IS_HIDE_DATETIME } from "utility/event";
import { changeStyleWithHosting, isPermanentGoods } from "utility/hosting";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";

export const AccountTicketItemHeading: React.FC<{
  eventId: string;
  eventTitle: string;
  artists: string;
  belongedHostings: HostingType[];
  eventOpenDateFull: string;
  eventEndDateFull: string;
  eventOpenDay: string;
  eventOpenTime: string;
  isLongEvent: boolean;
}> = ({
  eventId,
  eventTitle,
  artists,
  belongedHostings,
  eventOpenDateFull,
  eventEndDateFull,
  eventOpenDay,
  eventOpenTime,
  isLongEvent,
}) => {
  const isDisplayDateAndTime =
    changeStyleWithHosting().commonSettings.isDisplayEventDate &&
    changeStyleWithHosting().commonSettings.isDisplayEventTime &&
    !isPermanentGoods(belongedHostings);

  return (
    <Box
      paddingBottom={"15px"}
      marginBottom={"15px"}
      borderBottom={"1px solid #d4d4d4"}
    >
      {isDisplayDateAndTime && (
        <Box marginBottom={"10px"} fontWeight={"bold"}>
          {isLongEvent
            ? eventOpenDateFull + " ~ " + eventEndDateFull
            : eventOpenDateFull +
              "(" +
              eventOpenDay +
              ")" +
              `${IS_HIDE_DATETIME(eventId) ? "" : eventOpenTime}`}
        </Box>
      )}

      <Box component={"p"}>
        <Link to={`/events/${eventId}`}>
          <Box
            component="span"
            color={"#12c9cf"}
            fontWeight={"bold"}
            lineHeight={"1.3em"}
          >
            {eventTitle}
          </Box>

          {artists && (
            <Box
              component="span"
              color={"#12c9cf"}
              fontWeight={"bold"}
              lineHeight={"1.3em"}
            >
              {artists}
            </Box>
          )}
        </Link>
      </Box>
    </Box>
  );
};
