import useSWR, { SWRResponse } from "swr";

import {
  GetRestrictedStreamingKeyResponse,
  getRestrictedStreamingKey,
} from "api/schema/stream/getRestrictedStreamingKey";
import { useStreamApiClient } from "api/useSpwnApiClient";

const generateKey = ({
  eventId,
  videoId,
  code,
}: {
  eventId: string;
  videoId: string;
  code: string;
}) => ({
  path: getRestrictedStreamingKey.path,
  eventId,
  videoId,
  code,
});

type UseGetRestrictedStreamingKey = (args: {
  eventId: string;
  videoId: string;
  code: string;
}) => SWRResponse<GetRestrictedStreamingKeyResponse>;

export const useGetRestrictedStreamingKey: UseGetRestrictedStreamingKey = ({
  eventId,
  videoId,
  code,
}) => {
  const apiClient = useStreamApiClient();
  return useSWR(
    generateKey({
      eventId,
      videoId,
      code,
    }),
    async ({ eventId, videoId, code }) => {
      const res = await apiClient.call("getRestrictedStreamingKey", {
        eventId,
        videoId,
        code,
      });
      if (!res.ok) {
        throw new Error("API error occurred.");
      }
      return res.data;
    }
  );
};
