/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useI18n } from "hooks/i18n/i18n";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";

import { notificationActions } from "modules/notification";

import { AutoLink } from "utility";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { SubContents } from "components/templates/SubContents";
import type { UserNotification } from "@spwn/types/firebase/firestore";

type StyleProps = {};
export const AccountNotification: React.FC = () => {
  const location = useSelector((state: Store) => state.router.location);
  const notificationMap = useSelector(
    (state: Store) => state.notification.notificationMap
  );

  // @ts-expect-error TS2345
  const [notification, setNotification] = useState<UserNotification>(null);

  const dispatch = useDispatch();
  const { t } = useI18n();
  const classes = accountNotificationStyle();

  useEffect(() => {
    if (notificationMap) {
      const [notificationId] = location.pathname.split("/").slice(-1);
      // @ts-expect-error TS2538
      const notification = notificationMap[notificationId];
      setNotification(notification);
      // mark as read action if unread
      if (notification && !notification.hasRead) {
        dispatch(
          notificationActions.markAsRead.started({
            notificationId: notification._id,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationMap]);

  if (!notification) {
    return (
      <SubContents>
        <Breadcrumb
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            ["/account", t("common.routes.account")],
            ["/account/notifications", t("common.routes.notifications")],
            [null, t("common.routes.notification")],
          ]}
        />
      </SubContents>
    );
  }

  return (
    <SubContents>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/account", t("common.routes.account")],
          ["/account/notifications", t("common.routes.notifications")],
          [null, t("common.routes.notification")],
        ]}
      />

      <div css={classes.root}>
        <h2>{notification.subject}</h2>
        <p>{AutoLink(notification.body)}</p>
      </div>
    </SubContents>
  );
};

const accountNotificationStyle = (_props?: StyleProps) => {
  return {
    root: css`
      width: 90%;
      max-width: 980px;
      padding: 40px 0 80px;
      margin: 0 auto;
      h2 {
        padding-bottom: 8px;
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: normal;
        letter-spacing: 1px;
        border-bottom: 1px solid #e6e5e6;
      }
      p {
        font-size: 14px;
        line-height: 1.5em;
        white-space: pre-line;
        letter-spacing: 1px;
        a {
          color: #00c2ae;
          text-decoration: underline;
        }
      }
    `,
  };
};
