/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useState, Fragment } from "react";
import ModalVoucherEvent from "components/voucher/ModalVoucherEvent";
import ModalVoucherTicket, {
  SelectedTicketMap,
} from "components/voucher/ModalVoucherTicket";
import firebase from "firebase/app";
import type { VoucherState } from "@spwn/types";
import type { Event } from "@spwn/types/firebase/firestore";

export interface SelectedEvent {
  eventId: string | null;
  eventName: string | null;
  tickets: {
    id: string;
    name: string;
    num: number;
  }[];
}

interface Props {
  user: firebase.User;
  enableVoucherData: VoucherState["enableVoucherData"];
  excludeEventIds: string[];
  onSubmit: (selectedEvents: SelectedEvent[]) => void;
}

const VoucherEvent: React.FC<Props> = (props) => {
  const design = styles();
  const classesCautionTextStyle = cautionTextStyle();
  const classesLoginUserStyles = loginUserStyles();

  const { enableVoucherData, onSubmit } = props;
  const [currentCount, setCurrentCount] = useState<number>(1);
  const [visibleEventModal, setEventModal] = useState(false);
  const [visibleTicketModal, setTicketModal] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<SelectedEvent[]>([]);
  const [event, setEvent] = useState<Event | null>(null);
  const [editIndex, setEditIndex] = useState(0);

  const { selectableEventMap } = enableVoucherData;
  const { selectableTicketMap } = enableVoucherData;
  const { usableCount } = enableVoucherData;
  const selectedEventIds = selectedEvents.map((event) => event.eventId);
  const filterEventMap = { ...selectableEventMap };
  Object.keys(filterEventMap).map((eventId) => {
    // @ts-expect-error TS2769
    const excludeEventIds = props.excludeEventIds.concat(selectedEventIds);
    if (excludeEventIds.indexOf(eventId) > -1) {
      delete filterEventMap[eventId];
    }
    return null;
  });
  useEffect(() => {
    if (selectedEvents.length === 0) {
      const selectEvent = {
        eventId: null,
        eventName: null,
        tickets: [],
      };
      setSelectedEvents([...selectedEvents, selectEvent]);
    }
  }, [selectedEvents]);

  const handleShowEventModal = (index: number) => {
    setEditIndex(index);
    setEventModal(true);
  };

  const handleShowTicketModal = (index: number) => {
    setEditIndex(index);
    setTicketModal(true);
  };

  const handleAddEvent = (count: number) => {
    const selectEvent = {
      eventId: null,
      eventName: null,
      tickets: [],
    };
    setCurrentCount(count);
    setSelectedEvents([...selectedEvents, selectEvent]);
  };

  const handleSendEvent = (index: number, eventId: string) => {
    // @ts-expect-error TS2532
    selectedEvents[index].eventId = eventId;
    // @ts-expect-error TS2532
    selectedEvents[index].tickets.length = 0;
    // @ts-expect-error TS2345
    setEvent({
      ...selectableEventMap[eventId],
      _id: eventId,
    });
    setSelectedEvents([...selectedEvents]);
    setEventModal(false);
  };

  const handleSendTicket = (
    // @ts-expect-error TS7006
    index,
    event: Event,
    selectedTicketMap: SelectedTicketMap
  ) => {
    selectedEvents[index] = {
      // @ts-expect-error TS2322
      eventId: event._id,
      eventName: event.title,
      tickets: Object.keys(selectedTicketMap).map((ticketId) => {
        return {
          id: ticketId,
          // @ts-expect-error TS2532
          name: selectedTicketMap[ticketId].name,
          // @ts-expect-error TS2532
          num: selectedTicketMap[ticketId].num,
        };
      }),
    };
    setSelectedEvents([...selectedEvents]);
    setTicketModal(false);
  };

  if (selectedEvents.length === 0) return null;

  return (
    <Fragment>
      <div>
        <h2 css={design.title}>イベントを選択してください</h2>
        <div css={design.form}>
          {Array.from(Array(currentCount).keys()).map((_, i) => {
            return (
              <div css={design.formContent} key={i}>
                <div css={design.fieldSelect}>
                  <p css={design.fieldTitle}>イベント</p>
                  <p
                    css={[
                      design.select,
                      // @ts-expect-error TS2532
                      !selectedEvents[i].eventId ? design.noSelect : null,
                    ]}
                    onClick={() => handleShowEventModal(i)}
                  >
                    {/*
                     // @ts-expect-error TS2532 */}
                    {selectedEvents[i].eventId
                      ? // @ts-expect-error TS2538
                        selectableEventMap[selectedEvents[i].eventId].title
                      : "イベントを選択する"}
                  </p>
                </div>
                <div css={design.fieldSelect}>
                  <p css={design.fieldTitle}>チケット</p>
                  <p
                    css={[
                      design.select,
                      // @ts-expect-error TS2532
                      selectedEvents[i].tickets.length === 0
                        ? design.noSelect
                        : null,
                      !event ? design.disable : null,
                    ]}
                    onClick={() => handleShowTicketModal(i)}
                  >
                    {/*
                     // @ts-expect-error TS2532 */}
                    {selectedEvents[i].tickets.length === 0
                      ? "チケットを追加する"
                      : // @ts-expect-error TS2532
                        selectedEvents[i].tickets.map((selectedTicket) => {
                          return (
                            <span
                              key={selectedTicket.id}
                              css={design.selectedTicket}
                            >{`${selectedTicket.name} ${selectedTicket.num}枚`}</span>
                          );
                        })}
                  </p>
                </div>
              </div>
            );
          })}
          {usableCount > 0 && usableCount > currentCount && (
            <div css={design.formBottom}>
              <button
                css={design.addBtn}
                type="button"
                disabled={
                  // @ts-expect-error TS2532
                  !selectedEvents[selectedEvents.length - 1].eventId ||
                  // @ts-expect-error TS2532
                  selectedEvents[selectedEvents.length - 1].tickets.length === 0
                }
                onClick={() => handleAddEvent(currentCount + 1)}
              >
                追加
              </button>
              <p css={design.usableCountLabel}>{`付与可能数が ${
                usableCount - 1
              } 枚 残っています`}</p>
            </div>
          )}

          <div css={classesLoginUserStyles.root}>
            <h2 css={classesLoginUserStyles.header}>
              チケットを付与するアカウント
            </h2>
            <div css={classesLoginUserStyles.user}>
              <div css={classesLoginUserStyles.image}>
                {/*
                 // @ts-expect-error TS2322 */}
                <img src={props.user.photoURL} alt={props.user.displayName} />
              </div>
              <div css={classesLoginUserStyles.info}>
                <p className="name">{props.user.displayName}</p>
                <p className="email">{props.user.email}</p>
                <p className="uid">{props.user.uid}</p>
              </div>
            </div>
          </div>

          <div css={classesCautionTextStyle.root}>
            <p css={classesCautionTextStyle.text}>
              ※チケットを付与するアカウントが合っているか確認をお願いいたします。
            </p>
            <p css={classesCautionTextStyle.text}>
              ※登録前に、選択しているチケットが間違っていないか確認をお願いいたします。
            </p>
          </div>

          <div css={design.buttonArea}>
            <button
              css={design.submitBtn}
              type="submit"
              // @ts-expect-error TS2532
              disabled={!event || selectedEvents[0].tickets.length === 0}
              onClick={() => onSubmit(selectedEvents)}
            >
              登録
            </button>
          </div>
        </div>
      </div>
      {visibleEventModal && filterEventMap && (
        <ModalVoucherEvent
          index={editIndex}
          eventMap={filterEventMap}
          open={visibleEventModal}
          onCloseModal={() => setEventModal(false)}
          onSendEvent={handleSendEvent}
        />
      )}
      {/*
       // @ts-expect-error TS2538 */}
      {event && selectableTicketMap[event._id] && visibleTicketModal && (
        <ModalVoucherTicket
          index={editIndex}
          event={event}
          // @ts-expect-error TS2538
          tickets={selectableTicketMap[event._id]}
          open={visibleTicketModal}
          onCloseModal={() => setTicketModal(false)}
          onSendTicket={handleSendTicket}
        />
      )}
    </Fragment>
  );
};

const cautionTextStyle = () => {
  return {
    root: css`
      margin-top: 16px;
    `,
    text: css`
      font-size: 14px;
      line-height: 1.5em;
      font-weight: bold;
    `,
  };
};

const loginUserStyles = () => {
  return {
    root: css`
      margin-top: 40px;
      margin-bottom: 40px;
    `,
    header: css`
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: bold;
    `,
    user: css`
      display: flex;
      align-items: center;
    `,
    image: css`
      width: 80px;
      margin-right: 24px;
      @media screen and (min-width: 768px) {
        width: 100px;
        margin-right: 32px;
      }
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 2px solid #dedede;
        object-fit: cover;
        @media screen and (min-width: 768px) {
          width: 100px;
          height: 100px;
        }
      }
    `,
    info: css`
      p {
        font-weight: bold;
      }
      .name {
        margin-bottom: 12px;
        font-size: 18px;
        @media screen and (min-width: 768px) {
          margin-bottom: 16px;
          font-size: 24px;
        }
      }
      .email {
        margin-bottom: 5px;
      }
      .email,
      .uid {
        font-size: 14px;
        line-height: 1.3em;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    `,
  };
};

const styles = () => {
  return {
    title: css`
      font-size: 18px;
      font-weight: bold;
    `,
    form: css`
      margin-top: 24px;
    `,
    formContent: css`
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #e0e0e0;
      &:first-of-type {
        margin-top: 0;
        padding-top: 0;
        border: none;
      }
    `,
    fieldSelect: css`
      margin-top: 10px;
      padding: 16px 18px;
      border: 1px solid #cccccc;
      border-radius: 6px;
      &:first-of-type {
        margin-top: 0;
      }
    `,
    fieldTitle: css`
      color: #7b7b7b;
      font-size: 12px;
      font-weight: bold;
    `,
    select: css`
      display: block;
      width: 100%;
      margin-top: 8px;
      font-size: 15px;
      cursor: pointer;
    `,
    noSelect: css`
      color: #8d8d8d;
    `,
    disable: css`
      pointer-events: none;
      cursor: default;
    `,
    selectedTicket: css`
      display: block;
      font-size: 15px;
      margin-top: 10px;
      &:first-of-type {
        margin-top: 0;
      }
    `,
    formBottom: css`
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-top: 10px;
      border-top: 1px solid #e0e0e0;
    `,
    usableCountLabel: css`
      color: #ff5a5a;
      font-size: 13px;
      font-weight: bold;
    `,
    addBtn: css`
      display: block;
      width: 54px;
      padding: 8px 10px;
      margin-right: 16px;
      color: #ffffff;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      background-color: #000000;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      &:disabled {
        opacity: 0.4;
      }
    `,
    buttonArea: css`
      max-width: 480px;
      width: 100%;
      margin: 40px auto 0;
    `,
    caution: css`
      margin-bottom: 10px;
      line-height: 1.3em;
      font-weight: bold;
      span {
        color: red;
      }
    `,
    submitBtn: css`
      display: block;
      width: 100%;
      padding: 8px 10px;
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      background-color: #25c2ae;
      border: none;
      cursor: pointer;
      &:disabled {
        background-color: #cccccc;
        cursor: default;
      }
    `,
  };
};

export default VoucherEvent;
