/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import "firebase/firestore";
import "firebase/auth";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { streamingActions } from "../../modules/streaming";
import { firestoreActions } from "../../modules/firestore";

import { StreamingDisplaySuperChat } from "./StreamingDisplaySuperChat";
import { EventInfo } from "../../utility";
import { useI18n } from "hooks/i18n/i18n";

interface Props {
  event: EventInfo; // must not null
  currentVid: string; // must not null
}
/**
 * ギフトを送った人表示エリア
 * @param props
 */
export const StreamingTab: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const superChatList = useSelector(
    (state: Store) => state.streaming.superChatList
  );
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );
  const commentShardId = useSelector(
    (state: Store) => state.streaming.commentShardId
  );

  const classesListOfGifts = useMemo(
    () => listOfGifts({ isDarkModeEnabled }),
    [isDarkModeEnabled]
  );
  const { t } = useI18n();

  const didMount = () => {
    if (commentShardId.type === "init") return;
    dispatch(
      streamingActions.watchSuperChat.started({
        // @ts-expect-error TS2322
        eid: props.event._id,
        vid: props.currentVid,
      })
    );
    return () => {
      // execute this every called useEffect
      dispatch(firestoreActions.closeChannel({ channel: "superChat" }));
      dispatch(streamingActions.clearStateByKey("superChatList"));
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(didMount, [props.event, props.currentVid, commentShardId]);

  if (!superChatList || superChatList.length === 0) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <div css={classesListOfGifts.root}>
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="expansionPanelSummary"
        >
          <Typography>{t("streaming.gift.giftUser")}</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <StreamingDisplaySuperChat
            event={props.event}
            superChatList={superChatList}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

type ListOfGiftsProps = {
  isDarkModeEnabled: boolean;
};
const listOfGifts = (props?: ListOfGiftsProps) => {
  // @ts-expect-error TS18048
  const svgColorInDarkMode = props.isDarkModeEnabled
    ? css`
        fill: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const backgroundColorInDarkMode = props.isDarkModeEnabled
    ? css`
        background-color: #202020;
      `
    : css`
        background-color: #fff;
      `;

  return {
    root: css`
      width: 100%;
      .MuiPaper-root.MuiExpansionPanel-root.MuiExpansionPanel-rounded.MuiPaper-elevation1.MuiPaper-rounded {
        border: 1px solid #e0e0e0;
        box-shadow: none;
        ${backgroundColorInDarkMode}
      }
      .MuiExpansionPanelSummary-root.Mui-expanded {
        min-height: 48px;
      }
      .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: 12px 0;
      }
      .MuiTypography-root.MuiTypography-body1 {
        ${textColorInDarkMode}
        font-size: 14px;
        font-weight: bold;
      }

      /* prettier-ignore */
      .MuiButtonBase-root.MuiIconButton-root.MuiExpansionPanelSummary-expandIcon.Mui-expanded.MuiIconButton-edgeEnd
      svg {
        ${svgColorInDarkMode}
      }
      .MuiExpansionPanelDetails-root {
        display: block;
        padding: 0px 16px 8px;
      }
    `,
  };
};
