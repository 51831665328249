import styled from "@emotion/styled";
import { fontDinMixin } from "../mixin";

const _LiveStreamHeader = styled.h2`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #01ffe1;
  font-size: 35px;
  text-align: center;
  line-height: 1em;
  letter-spacing: 1px;
  white-space: nowrap;

  /** FIXME:@sy check
   *  margin-bottom: 30px;
   *  color: #000;
   */
  @media screen and (min-width: 768px) {
    /** FIXME:@sy check
     *  margin-bottom: 60px;
     */
    margin-bottom: 40px;
    font-size: 40px;
  }
  &:after,
  &:before {
    content: "";
    width: 30%;
    background: linear-gradient(
      transparent 40%,
      currentColor 40%,
      currentColor calc(40% + 2px),
      transparent calc(40% + 2px)
    );
    background-clip: padding;
    @media screen and (min-width: 768px) {
      width: 40%;
    }
  }
  &:after {
    margin-left: 4%;
  }
  &:before {
    margin-right: 4%;
  }
`;

export const LiveStreamHeader = styled(_LiveStreamHeader)(fontDinMixin);

LiveStreamHeader.displayName = "LiveStreamHeader";
