import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import {
  getCommaStyleNumber,
  convertMapToValues,
  getSlashSeparatedDateStyle,
} from "utility";

import "firebase/firestore";
import "firebase/database";
import { modalActions } from "modules/modal";
import { purchaseActions } from "modules/purchase";
import { authActions } from "modules/auth";
import { BreadcrumbArea } from "components/common/Link";
import { SubContents } from "components/templates/SubContents";
import { useI18n } from "hooks/i18n/i18n";
import styled from "@emotion/styled";
import {
  breadcrumbMixin,
  myPageHistoryMixin,
  myPagePawHistoryMixin,
  Paw,
  AccountPawInfo,
  PawHistoryListPaw,
} from "../../styles";

interface Histories {
  [key: string]: {
    chargeType: string;
    createAt: number;
    freeExpire: number;
    freeValue: number;
    paidExpire: number;
    paidValue: number;
    remainingFreeValue: number;
    remainingPaidValue: number;
    status: string;
    updateAt: number;
  };
}

const _Container = styled.div`
  @media screen and (min-width: 768px) {
    margin: 0 auto;
  }
`;
const Container = styled(_Container)(
  myPageHistoryMixin,
  myPagePawHistoryMixin,
  breadcrumbMixin
);

export const AccountPawChargeHistory: React.FC = () => {
  const pawBalance = useSelector((state: Store) => {
    return state.purchase.emoBalance.paidValue === undefined
      ? 0
      : getCommaStyleNumber(state.purchase.emoBalance.paidValue);
  });
  const pawHistoryContainer = useSelector(
    (state: Store) => state.purchase.pawHistoryContainer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(purchaseActions.getEmoBalance.started());
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.getEmoBalance.started,
        args: null,
      })
    );

    dispatch(purchaseActions.getPAWChargeHistory.started({ end: null }));
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.getPAWChargeHistory.started,
        args: { end: null },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let end = Number.MAX_SAFE_INTEGER;

  const reloadHistory = () => {
    dispatch(purchaseActions.getPAWChargeHistory.started({ end }));
  };

  const historyContents = (histories: Histories) => {
    if (
      histories === undefined ||
      histories == null ||
      Object.keys(histories).length === 0
    )
      return <div />;
    return convertMapToValues(histories)
      .sort((a, b) => {
        return -(a.createAt - b.createAt);
      })
      .map((history) => {
        console.log(history);
        end = end < history.createAt ? end : history.createAt;
        if (
          !history.hasOwnProperty("createAt") ||
          !history.hasOwnProperty("paidExpire")
        )
          return <div />;
        const purchaseDate = new Date(history.createAt);
        const expireDate = new Date(history.paidExpire);
        const remaining = history.remainingPaidValue;
        const idExpired =
          expireDate < new Date() || remaining === 0 ? "expired" : "";

        return (
          <div
            key={`pawChargeHistory_${purchaseDate.toTimeString()}`}
            className={`paw_history_list ${idExpired}`}
          >
            <div className="paw_history_content">
              <p className="purchase">
                {getSlashSeparatedDateStyle(Number(history.createAt))}
              </p>
              <PawHistoryListPaw>
                {getCommaStyleNumber(Number(history.paidValue))}
              </PawHistoryListPaw>
              <p className="expiration">
                {getSlashSeparatedDateStyle(Number(history.paidExpire))}
              </p>
            </div>
          </div>
        );
      });
  };

  const togglePawChargeModal = () => {
    dispatch(modalActions.togglePawChargeModal({}));
  };

  const { t } = useI18n();

  return (
    <SubContents>
      <Container id="contents">
        <BreadcrumbArea
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            ["/account", t("common.routes.account")],
            [null, t("common.routes.pawChargeHistory")],
          ]}
        />
        <AccountPawInfo>
          <div className="owned_paw">
            <p className="heading">{t("pawChargeHistory.ownedPAW")}</p>
            <Paw>{pawBalance}</Paw>
          </div>
          <div>
            <p onClick={togglePawChargeModal}>
              {t("pawChargeHistory.topUpPAW")}
            </p>
          </div>
        </AccountPawInfo>

        <p className="paw_caution_text">{t("pawChargeHistory.note1")}</p>

        <div className="paw_history_layout">
          <div className="paw_history_heading">
            <div className="paw_history">
              <p className="purchase">{t("pawChargeHistory.dateOfPurchase")}</p>
              <p className="paw">{t("pawChargeHistory.topedUpPAW")}</p>
              <p className="expiration">
                {t("pawChargeHistory.expirationDate")}
              </p>
            </div>
          </div>
          {historyContents(pawHistoryContainer)}
          <div className="additional_display" onClick={reloadHistory}>
            <div className="read-more">
              <p>{t("pawChargeHistory.readMore")}</p>
            </div>
          </div>
        </div>
      </Container>
    </SubContents>
  );
};
