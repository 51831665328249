/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

import { SquareLoader } from "./SquareLoader";

interface Props {
  loadingText?: string;
}
export const LoaderLayout: React.FC<Props> = (props) => {
  const classesLoaderLayoutStyles = loaderLayoutStyles();

  return (
    <div css={classesLoaderLayoutStyles.root}>
      <div css={classesLoaderLayoutStyles.inner}>
        <SquareLoader />
        <p>
          {props.loadingText &&
            props.loadingText.split("<br/>").map((string, index) => {
              return (
                <React.Fragment key={index}>
                  {string}
                  <br />
                </React.Fragment>
              );
            })}
        </p>
      </div>
    </div>
  );
};

const loaderLayoutStyles = () => {
  return {
    root: css`
      width: 100%;
      max-width: 1800px;
      min-height: calc(100vh - 255px);
      padding: 80px 0;
      margin: 0 auto;
      background-color: #fff;
    `,
    inner: css`
      width: 95%;
      margin: 0 auto;
      #square-loader {
        margin: 0 auto 32px;
      }
      p {
        text-align: center;
        line-height: 1.5em;
      }
    `,
  };
};
