import type { Event, HostingType } from "@spwn/types/firebase/firestore";

import { MyCartData } from "modules/cart";

const isShippingFeeRequiredInCart = (myCart: MyCartData) => {
  const products = myCart.tickets.concat(myCart.goods);
  for (const product of products) {
    if (product.isAddressRequired) {
      return true;
    }
  }
  return false;
};

export const extractAddressRequiredDomains = (
  myCart: MyCartData[],
  eventMap: { [key: string]: Pick<Event, "belongedHostings"> }
): HostingType[] => {
  const domains = myCart.reduce((acc, cartData) => {
    if (isShippingFeeRequiredInCart(cartData)) {
      // !!! adopt first index of belongedHostings !!!
      const domain = eventMap[cartData.eventId]?.belongedHostings[0];
      if (domain) {
        acc.add(domain);
      }
    }
    return acc;
  }, new Set<HostingType>());

  return Array.from(domains);
};
