/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useSelector } from "react-redux";

import type { ProductData } from "@spwn/types/firebase/firestore";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { useI18n } from "hooks/i18n/i18n";
import { Store } from "store";
import { convertMapToValues, getNowTimestamp } from "utility";
import { changeStyleWithHosting } from "utility/hosting";
import { isShippingItem } from "utility/ticket";
import { EventGoodsSideMenu } from "./EventGoodsSideMenu";
import { EventGoodsList } from "./EventGoodsList";
import { EventCapsuleList } from "./EventCapsuleList";

/**
 * これをEventGoodsに変わるコンポーネントとして実装する
 */
export const EventGoodsHome: React.FC = () => {
  const isAdmin: boolean = useSelector((state: Store) => state.admin.isAdmin);
  const goods: ProductData[] = useSelector((state: Store) => {
    return state.ticket.eventGoodsMap
      ? convertMapToValues(state.ticket.eventGoodsMap).filter(
          (el) =>
            (el.display && el.releaseDateTime.seconds <= getNowTimestamp()) ||
            (el.display && isAdmin)
        )
      : [];
  });
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const pathname: string = useSelector(
    (state: Store) => state.router.location.pathname
  );
  const isAddressRegistered: boolean = useSelector(
    (state: Store) => state.auth.isAddressRegistered
  );
  const goodsPageSettings = useSelector(
    (state: Store) => state.event.goodsPageSettings
  );
  const { t } = useI18n();
  const hasShipments = goods.filter((el) => isShippingItem(el)).length > 0;

  const classesPageLayout = pageLayout();
  const breadcrumbText =
    changeStyleWithHosting().commonSettings.menu.event.text;

  if (goods.length === 0) {
    return (
      <div id="contents">
        <LoaderLayout />
      </div>
    );
  }

  return (
    <div css={classesPageLayout.root}>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/events", breadcrumbText],
          [`/events/${displayEvent.eventId}`, displayEvent.eventTitle],
          [null, "グッズ一覧"],
        ]}
      />

      <div css={classesPageLayout.container}>
        {goodsPageSettings && displayEvent?.isCapsuleGoodsSellingEnabled ? (
          <React.Fragment>
            <EventGoodsSideMenu
              pathname={pathname}
              title={"CAPSULE"}
              subTitle={"カプセル"}
              eventId={displayEvent.eventId}
            />
            <EventCapsuleList goods={goods} eventId={displayEvent.eventId} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <EventGoodsSideMenu
              pathname={pathname}
              title={"GOODS"}
              subTitle={t("goods.headerSubTitle")}
              eventId={displayEvent.eventId}
            />
            <EventGoodsList
              goods={goods}
              isAddressRegistered={isAddressRegistered}
              eventId={displayEvent.eventId}
              hasShipments={hasShipments}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const pageLayout = () => {
  return {
    root: css`
      min-height: calc(100vh - 54px);
      margin: 0 auto;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        width: 100%;
        max-width: 1800px;
        min-height: calc(100vh - 65px - 190px);
      }
    `,
    container: css`
      max-width: 1120px;
      width: 100%;
      padding: 40px 0 80px;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        display: flex;
        width: 95%;
      }
    `,
  };
};
