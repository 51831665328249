/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { Select, MenuItem } from "@material-ui/core";
import TranslateIcon from "@material-ui/icons/Translate";
import { changeStyleWithHosting } from "utility/hosting";

import { LANG_LIST, LanguageType } from "constants/lang";
import { getCookieValue } from "utility";
import { useI18n } from "hooks/i18n/i18n";
import { useDispatch } from "react-redux";
import { adminActions } from "modules/admin";
import { PCView } from "components/common/MediaQuery";

export const LanguageDropdownMenu: React.FC = () => {
  const classes = styles();
  const { i18n } = useI18n();
  const dispatch = useDispatch();

  /**
   * cookieから言語を取得する.
   */
  const language = getCookieValue("lang") ?? "ja";

  const [selectedLanguage, setSelectedLanguage] = useState<LanguageType>({
    label: "日本語",
    value: "ja",
  });

  /**
   * 初回ロード時、cookieから取得した言語をi18nとstateにセットする.
   */
  useEffect(() => {
    i18n.changeLanguage(language);
    findSelectedLanguageInLangList(language);
  }, [i18n, language, selectedLanguage]);

  /**
   * セレクトボックスで言語が変更された時の処理.
   */
  const handleChangeLang = (
    event: React.ChangeEvent<{ name?: string; value: string }>
  ) => {
    const lang = event.target.value;
    document.cookie = `lang=${lang}`;
    i18n.changeLanguage(lang);
    findSelectedLanguageInLangList(lang);
    dispatch(adminActions.setI18nLang(lang));
  };

  /**
   * 選択された言語をLANG_LISTから探してselectedLanguageにセットする.
   */
  const findSelectedLanguageInLangList = (language: string) => {
    const selectLanguage = LANG_LIST.find((item) => item.value === language);
    if (!selectLanguage) return;

    setSelectedLanguage(selectLanguage);
    return;
  };

  /**
   * @todo SelectをMenuに置き換える
   * @see https://github.com/balus-co-ltd/spwn/issues/7003
   */
  return (
    <div css={classes.root}>
      <PCView>
        <div css={classes.icon} aria-label={"Language"}>
          <TranslateIcon />
        </div>
      </PCView>

      <Select
        css={classes.selectbox}
        value={selectedLanguage ? selectedLanguage.value : ""}
        label={selectedLanguage ? selectedLanguage.label : ""}
        disableUnderline={true}
        // @ts-expect-error TS2322
        onChange={handleChangeLang}
        MenuProps={{
          getContentAnchorEl: null,
        }}
      >
        {LANG_LIST.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const styles = () => {
  const { textColor } = changeStyleWithHosting().commonSettings.header;

  return {
    root: css`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    `,
    icon: css`
      color: ${textColor};
      svg {
        width: 0.8em;
        height: 0.8em;
        padding: 2px;
        fill: initial;
        background-color: #fff;
        border-radius: 2px;
      }
    `,
    selectbox: css`
      .MuiInputBase-input {
        padding: 7px 0 6px;
      }
      .MuiInput-input {
        padding-right: 32px;
        color: ${textColor};
        font-size: 12px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          padding-right: 0;
          font-size: 14px;
        }
      }
      input {
        display: none;
      }
      svg {
        fill: ${textColor};
      }
    `,
  };
};
