/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useI18n } from "hooks/i18n/i18n";
import { fontDinMixin } from "../../styles";

export const AccountTicketDigitalSpwnId: React.FC<{
  uid: string;
  email: string | null;
}> = ({ uid, email }) => {
  const { t } = useI18n();
  const classes = styles();

  const copyToClipboard = (target: string) => {
    const uid = document.getElementById(target);
    const textArea = document.createElement("textarea");
    // @ts-expect-error TS2322
    textArea.value = uid.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
  };

  return (
    <div css={classes.root}>
      <div css={classes.heading}>
        <h2>{t("ticketDetail.spwnId")}</h2>
      </div>

      <div css={classes.layout}>
        <span className="id" id="__spwn_uid">
          {uid}
        </span>

        <p
          className="copy"
          onClick={() => {
            copyToClipboard("__spwn_uid");
          }}
        >
          {t("ticketDetail.copyId")}
        </p>

        {email && <p className="address">{email}</p>}
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      margin-bottom: 60px;
    `,
    heading: css`
      margin-bottom: 20px;
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
      }
      h2 {
        ${fontDinMixin};
        padding: 5px 0 0 15px;
        margin-bottom: 5px;
        font-size: 35px;
        border-left: 5px solid #01ffe1;
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
          letter-spacing: 3px;
        }
      }
      p {
        padding-left: 21px;
        font-size: 12px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    `,
    layout: css`
      max-width: 740px;
      width: 100%;
      margin: 0 auto;
      .id {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-right: 10px;
        margin-bottom: 5px;
        ${fontDinMixin};
        color: #000;
        font-size: 22px;
        @media screen and (min-width: 768px) {
          font-size: 40px;
        }
      }
      .copy {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        color: #1ad6dd;
        font-size: 12px;
        cursor: pointer;
      }
      .address {
        color: #666c6d;
      }
    `,
  };
};
