/** @jsxRuntime classic */
/** @jsx jsx */
import { Global, css, jsx } from "@emotion/core";
import React, { useState } from "react";
import {
  STAGE_WIDTH,
  TIMETABLE_HOUR_CELL_HEIGHT,
} from "../../../../containers/event/eventDetail/EventDetailTimetable";
import { EventTimetableContentsShortItem } from "./EventTimetableContentsShortItem";
import { EventTimetableContentsMiddleItem } from "./EventTimetableContentsMiddleItem";
import { EventTimetableContentsLongItem } from "./EventTimetableContentsLongItem";
import { EventTimetableContentsItemDetail } from "./EventTimetableContentsItemDetail";
import { EventTimetableContentsHourItem } from "./EventTimetableContentsHourItem";
import { EventTimetableStageHeading } from "./EventTimetableStageHeading";
import type { DayContent, StageContent, Video } from "@spwn/types";

interface Props {
  stageNum: number;
  startAt: number;
  betweenAt: number;
  models: DayContent;
  dayActionStatus: string;
  selectedDay: DayContent;
  doContentAction: (content: {
    routePath?: string;
    actionStatus:
      | DayContent["actionStatus"]
      | StageContent["actionStatus"]
      | Video["actionStatus"];
    ticketPath: string;
  }) => void;
}
export const EventTimetableContents: React.FC<Props> = (props) => {
  // @ts-expect-error TS2345
  const [renderEvent, setRenderEvent] = useState<Video>(null);
  // @ts-expect-error TS2345
  const [selectedStage, setSelectedStage] = useState<StageContent>(null);

  const classes = styles(props.betweenAt, props.stageNum);

  const initializeState = () => {
    // @ts-expect-error TS2345
    setRenderEvent(null);
  };

  const openVideoModal = (video: Video, stage: StageContent) => {
    setRenderEvent(video);
    setSelectedStage(stage);
  };

  return (
    <div css={classes.root}>
      {props.models.stageContents.map((stage, index) => {
        return (
          <div className="stage" key={index}>
            <EventTimetableStageHeading
              stage={stage}
              // @ts-expect-error TS2345
              doAction={() => props.doContentAction(stage)}
            />

            <div className="stage_list">
              {stage.videos.map((video, index) => {
                const { startAt, endAt } = video;

                // イベントの開催時間を計算する
                const videoTime = (endAt - startAt) / 60;

                // afterAt（(startAt.seconds - STAGE_START_AT.seconds) / 60(分に変換) / 10(グリッド線の1メモリが10の為)）を計算してpropsに渡す。
                const afterAt = (startAt - props.startAt) / 60 / 10;

                // アーティスト情報を持っているかを判定する
                const hasArtistsArray = video.artists.length > 0;

                return (
                  <React.Fragment key={index}>
                    {/* 15分の配信はデザイン的に整合性が取れず、今後使われなくなる可能性がある、
                        かつ210829-TOFESでは15分枠はないので機能としてなくす可能性がある。 */}
                    {/* 15分のイベントを表示する際には、「EventTimetableContentsShortItem」を使用する */}
                    {15 <= videoTime && videoTime < 20 && (
                      <EventTimetableContentsShortItem
                        video={video}
                        videoTime={videoTime}
                        afterAt={afterAt}
                        setRenderEvent={() => openVideoModal(video, stage)}
                        // @ts-expect-error TS2345
                        doButtonAction={() => props.doContentAction(video)}
                      />
                    )}

                    {/* 20分のイベントを表示する際には、「EventTimetableContentsMiddleItem」を使用する */}
                    {20 <= videoTime && videoTime < 30 && (
                      <EventTimetableContentsMiddleItem
                        video={video}
                        videoTime={videoTime}
                        afterAt={afterAt}
                        setRenderEvent={() => openVideoModal(video, stage)}
                        // @ts-expect-error TS2345
                        doButtonAction={() => props.doContentAction(video)}
                      />
                    )}

                    {/* 30分のイベントを表示する際には、「EventTimetableContentsLongItem」を使用する */}
                    {30 <= videoTime && videoTime < 60 && (
                      <EventTimetableContentsLongItem
                        video={video}
                        videoTime={videoTime}
                        afterAt={afterAt}
                        hasArtistsArray={hasArtistsArray}
                        setRenderEvent={() => openVideoModal(video, stage)}
                        // @ts-expect-error TS2345
                        doButtonAction={() => props.doContentAction(video)}
                      />
                    )}

                    {/* 60分以上のイベントを表示する際には、「EventTimetableContentsLongItem」を使用する */}
                    {60 <= videoTime && (
                      <EventTimetableContentsHourItem
                        video={video}
                        videoTime={videoTime}
                        afterAt={afterAt}
                        hasArtistsArray={hasArtistsArray}
                        setRenderEvent={() => openVideoModal(video, stage)}
                        // @ts-expect-error TS2345
                        doButtonAction={() => props.doContentAction(video)}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </div>

            {/* イベントがクリックされた時、イベント詳細のポップアップを表示する */}
            {renderEvent && (
              <React.Fragment>
                <Global styles={globalStyle} />{" "}
                {/* ポップアップ表示時に背景をスクロールしないようにする */}
                <EventTimetableContentsItemDetail
                  dayName={props.selectedDay.dayName}
                  stageName={selectedStage.name}
                  renderEvent={renderEvent}
                  dayActionStatus={props.selectedDay.actionStatus}
                  stageActionStatus={selectedStage.actionStatus}
                  initializeState={initializeState}
                  doDayButtonAction={() =>
                    // @ts-expect-error TS2345
                    props.doContentAction(props.selectedDay)
                  }
                  doStageButtonAction={() =>
                    // @ts-expect-error TS2345
                    props.doContentAction(selectedStage)
                  }
                  // @ts-expect-error TS2345
                  doVideoButtonAction={() => props.doContentAction(renderEvent)}
                />
              </React.Fragment>
            )}
          </div>
        );
      })}
    </div>
  );
};

const globalStyle = css`
  body {
    height: 100vh;
    overflow-y: hidden;
  }
`;

const styles = (betweenAt: number, stageNum: number) => {
  return {
    root: css`
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: calc(${STAGE_WIDTH}px * ${stageNum});
      min-width: calc(${STAGE_WIDTH}px * 3);
      .stage {
        position: relative;
        width: 100%;
        margin-top: 115px;
        &:not(:last-of-type) .stage_heading {
          border-right: 1px dashed #00c2ad;
        }
        &:not(:last-of-type) .stage_list {
          border-right: 1px dashed #00c2ad;
        }
        .stage_list {
          height: calc(
            ${TIMETABLE_HOUR_CELL_HEIGHT}px * ${betweenAt} + 66px
          ); /* 1hの高さ + イベント時間 + 2セル分の高さ */
        }
      }
    `,
  };
};
