/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { eventState } from "modules/event";
import React from "react";
import { convertDatetimeToHHII } from "utility";

export const EventDate: React.FC<{
  isDisplayEventDate: boolean;
  isDisplayEventTime: boolean;
  datetimeStr: string;
  parts: eventState["displayEvent"]["parts"];
}> = (props) => {
  const classes = styles();
  if (!props.isDisplayEventDate && !props.isDisplayEventTime) {
    return null;
  }
  return (
    <div css={classes.root}>
      {props.isDisplayEventDate && (
        <p css={classes.eventDate}>{props.datetimeStr}</p>
      )}
      {props.isDisplayEventTime && (
        <div css={classes.eventTime}>
          <p className="open">OPEN/START</p>
          <p className="time" style={{ whiteSpace: "pre-line" }}>
            {props.parts &&
              props.parts.map((el, i) => {
                const openTime = convertDatetimeToHHII(el.openTime);
                const startTime = convertDatetimeToHHII(el.startTime);
                return (
                  <React.Fragment
                    key={i}
                  >{`${el.name} ${openTime} / ${startTime}\n`}</React.Fragment>
                );
              })}
          </p>
        </div>
      )}
    </div>
  );
};

const styles = () => ({
  root: css`
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #eeeeee;
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  `,
  eventDate: css`
    margin-bottom: 24px;
    font-size: 4.5vw;
    font-weight: bold;
    letter-spacing: 1px;
    @media screen and (min-width: 768px) {
      font-size: 24px;
    }
  `,
  eventTime: css`
    font-weight: bold;
    letter-spacing: 1px;
    .open {
      margin-bottom: 8px;
      font-size: 14px;
    }
    .time {
      font-size: 4.5vw;
      line-height: 1.3em;
      @media screen and (min-width: 768px) {
        font-size: 24px;
      }
    }
  `,
});
