/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { TIMETABLE_CELL_HEIGHT } from "../../../../containers/event/eventDetail/EventDetailTimetable";
import { EventTimetableIcon } from "./EventTimetableIcon";
import { EventTimetableLabel } from "./EventTimetableLabel";
import type { Video } from "@spwn/types";

interface Props {
  video: Video;
  videoTime: number;
  afterAt: number;
  hasArtistsArray: boolean;
  setRenderEvent: () => void;
  doButtonAction: () => void;
}
export const EventTimetableContentsLongItem: React.FC<Props> = (props) => {
  const classes = styles({
    afterAt: props.afterAt,
    videoTime: props.videoTime,
    hasArtistsArray: props.hasArtistsArray,
  });

  return (
    <div css={classes.root} onClick={props.setRenderEvent}>
      <div css={classes.thumbnail}>
        <img src={props.video.thumbnail} alt={props.video.name} />
      </div>
      <EventTimetableLabel status={props.video.actionStatus} />

      <div css={classes.layout}>
        <div css={classes.info}>
          <h4>{props.video.name}</h4>
          {props.hasArtistsArray && (
            <p>
              出演：
              {props.video.artists.map((chara, index) => {
                if (index === 0)
                  return (
                    <React.Fragment key={index}>{chara.name}</React.Fragment>
                  );
                return (
                  <React.Fragment key={index}> / {chara.name} </React.Fragment>
                );
              })}
            </p>
          )}
        </div>

        <div
          css={classes.cart}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <EventTimetableIcon
            status={props.video.actionStatus}
            doButtonAction={props.doButtonAction}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * グリッド1メモリ10分なので、イベントの開始から何分後かで計算して、afterAtを変更する
 */
interface stylesProps {
  afterAt: number;
  videoTime: number;
  hasArtistsArray: boolean;
}
const styles = (props: stylesProps) => {
  // 30分以上60分未満の表示の差を計算する。
  // 算出した差は半分にして上下のpaddingに加える
  // calcDisplayDifference = (1セル(10分)から1分の高さ) * (閾値30分からの差)
  const calcDisplayDifference =
    (TIMETABLE_CELL_HEIGHT / 10) * (props.videoTime - 30);

  // アーティスト情報がない時、アイテムの高さを調整する
  const calcDisplayArtistAreaNum = props.hasArtistsArray ? `0` : `22`;

  return {
    root: css`
      position: absolute;
      /* デザインの関係でグリッドラインを1行追加しているので、その分を+1している。 */
      /* 1h毎に1pxずれるため、その分をプラスする */
      top: calc(
        (${TIMETABLE_CELL_HEIGHT}px * (${props.afterAt} + 1)) +
          (1px * ${props.afterAt} / 6)
      );
      left: 50%;
      width: 95%;
      background-color: #fff;
      border-radius: 8px;
      box-sizing: content-box;
      transform: translateX(-50%);
      overflow: hidden;
      cursor: pointer;
      z-index: 5;
    `,
    thumbnail: css`
      position: relative;
      width: 100%;
      img {
        width: 100%;
        height: calc(
          40px + ${calcDisplayDifference}px + ${calcDisplayArtistAreaNum}px / 2
        );
        object-fit: cover;
        object-position: top center;
      }
    `,
    layout: css`
      display: grid;
      grid-template-columns: auto 24px;
      align-items: center;
      grid-gap: 8px;
      padding: 8px 10px;
    `,
    /* stylelint-disable */
    info: css`
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      letter-spacing: 1px;
      h4 {
        display: box;
        width: 100%;
        height: 1.1em;
        font-size: 16px;
        font-weight: bold;
        /* stylelint-disable-next-line property-no-unknown */
        box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
      }
      p {
        display: box;
        height: 1em;
        margin-top: 8px;
        font-size: 14px;
        /* stylelint-disable-next-line property-no-unknown */
        box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
      }
    `,
    /* stylelint-enable */
    cart: css`
      display: flex;
      align-items: center;
      width: 24px;
      height: 24px;
      svg {
        fill: #a1a1a1;
        cursor: pointer;
      }
    `,
  };
};
