import { getBelongedHosting } from "utility/storage";
import { vtuber1BaseStyles } from "constants/hosting/templates/vtuber1";
import { event1BaseStyles } from "constants/hosting/templates/event1";
import { inc1BaseStyles } from "constants/hosting/templates/774inc1";
import { webinar1BaseStyles } from "constants/hosting/templates/webinar1";
import { php1BaseStyles } from "constants/hosting/templates/php1";
import { baseStyles as portalTopStyles } from "constants/hosting/templates/portal-1";
import { baseStyles as fent1BaseStyles } from "constants/hosting/templates/fent1";
import { baseStyles as gekiKeshigomuBaseStyles } from "constants/hosting/templates/geki-keshigomu1";
import { baseStyles as kioichostudioBaseStyles } from "constants/hosting/templates/kioichostudio1";
import { baseStyles as bizBaseStyles } from "constants/hosting/templates/biz1";
import { baseStyles as plattoBaseStyles } from "constants/hosting/templates/platto1";
import { baseStyles as uchigekiBaseStyles } from "constants/hosting/templates/uchigeki1";
import { baseStyles as gibiate1BaseStyles } from "constants/hosting/templates/gibiate1";
import { baseStyles as clan1BaseStyles } from "constants/hosting/templates/v-clan1";
import { baseStyles as harevutai1BaseStyles } from "constants/hosting/templates/harevutai1";
import { baseStyles as akilab1BaseStyles } from "constants/hosting/templates/akilab1";
import { baseStyles as theaterAcademy1BaseStyles } from "constants/hosting/templates/theater-academy1";
import { baseStyles as hiroki774BaseStyles } from "constants/hosting/templates/hiroki773-1";
import { baseStyles as mdl1BaseStyles } from "constants/hosting/templates/mdl-1";
import { baseStyles as engekiichibaBaseStyles } from "constants/hosting/templates/engekiichiba-1";
import { baseStyles as ufgBaseStyles } from "constants/hosting/templates/ufg-1";
import { baseStyles as tvfBaseStyles } from "constants/hosting/templates/tvf-1";
import { baseStyles as theatreWorkshopBaseStyles } from "constants/hosting/templates/theatre-workshop-1";
import { baseStyles as ultramanBaseStyles } from "constants/hosting/templates/ultraman-1";
import { baseStyles as keytalkBaseStyles } from "constants/hosting/templates/keytalk-1";
import { baseStyles as tifBaseStyles } from "constants/hosting/templates/tif-1";
import { baseStyles as phantomBeatsOperaBaseStyles } from "constants/hosting/templates/phantom-beats-opera-1";
import { baseStyles as hadajinShotenBaseStyles } from "constants/hosting/templates/hadajin-shoten-1";
import { baseStyles as yam2020BaseStyles } from "constants/hosting/templates/yam2020-1";
import { baseStyles as balusBaseStyles } from "constants/hosting/templates/balus-1";
import { baseStyles as toukenranbuBaseStyles } from "constants/hosting/templates/stage-toukenranbu-1";
import { baseStyles as frankensteinBaseStyles } from "constants/hosting/templates/stage-frankenstein-1";
import { baseStyles as bs12BaseStyles } from "constants/hosting/templates/BS12-1";
import { baseStyles as confacBaseStyles } from "constants/hosting/templates/confac-1";
import { baseStyles as capsuleBaseStyles } from "constants/hosting/templates/capsule-1";
import { baseStyles as pokopeaBaseStyles } from "constants/hosting/templates/pokopea-1";
import { baseStyles as hamaKentoBaseStyle } from "constants/hosting/templates/hama-kento-1";
import { baseStyles as strayCityBaseStyles } from "constants/hosting/templates/stage-straycity-1";
import { baseStyles as tsudakenBaseStyles } from "constants/hosting/templates/tsudaken-1";
import { baseStyles as watakonBaseStyles } from "constants/hosting/templates/watakon-stage-1";
import { baseStyles as themoneyBaseStyles } from "constants/hosting/templates/themoney-1";
import { baseStyles as leminoBaseStyles } from "constants/hosting/templates/lemino-1";
import { baseStyles as virtuaculBaseStyles } from "constants/hosting/templates/virtuacul-1";
import { baseStyles as terashimaJuntaBaseStyles } from "constants/hosting/templates/terashima-junta-1";
import { baseStyles as ofTheDeadBaseStyles } from "constants/hosting/templates/of-the-dead-1";

import { hostingMap } from "constants/admin";
import type { HostingType } from "@spwn/types/firebase/firestore";

export type HostingStyleMap = {
  baseStyles: {
    baseColor: string;
    mainColor: string;
    accentColor: string;
    fontPrimaryColor: string;
    fontSecondaryColor: string;
    backgroundImage: string | null;
  };

  commonSettings: {
    auth: {
      loginMethod: {
        twitter: boolean;
        facebook: boolean;
      };
    };
    route: {
      home: "/" | "/events";
    };
    meta: {
      favicon: string | null;
      appleTouchIcon: string | null;
      title: string | null;
      description: string | null;
      copyright: string | null;
    };
    header: {
      logo: {
        isDisplay: boolean;
        path: string | null;
      };
      textColor: string;
      svgColor: string;
    };
    menu: {
      home: {
        isDisplay: boolean;
      };
      event: {
        isDisplay: boolean;
        text: string;
      };
      archive: {
        isDisplay: boolean;
        text: string;
      };
      account: {
        isDisplay: boolean;
      };
      pastEvents: {
        isDisplay: boolean;
        text: string;
      };
      eventNotes: {
        isDisplay: boolean;
        path: string | null; // if use other event notes url. null is default.
      };
      guide: {
        isDisplay: boolean;
      };
      contact: {
        isDisplay: boolean;
        path: string | null; // if use other event notes url. null is default.
      };
    };
    isDisplayBreadcrumb: boolean;
    isDisplayEventDate: boolean;
    isDisplayEventTime: boolean;
  };

  pageSettings: {
    topPage: {
      upcomingHeadline: {
        isDisplay: boolean;
        mainText: string | null;
        subText: string | "" | null;
      };
      isDisplayTodaysEvent: boolean;
      isDisplayInfoArea?: boolean;
      isDisplayDeliveryDisplayArea: boolean;
      isDisplayCatchCopy: boolean;
    };
    eventListPage: {
      /** -- 基本的にtrueで設定する -- */
      isDisplayEventDatetimeLabel: boolean;
      /** ------------------------- */
    };
    eventPage: {
      /** -- 基本的にtrueで設定する -- */
      isDisplayEventState: boolean;
      isDisplayEventTitle: boolean;
      isDisplayArtist: boolean;
      isDisplayShareButton: boolean;
      /** ------------------------- */
      linkInPage: {
        color: string;
        link01: {
          isDisplay: boolean;
          text: string;
        };
        link02: {
          isDisplay: boolean;
          text: string;
        };
        link03: {
          isDisplay: boolean;
          text: string;
        };
        link04: {
          isDisplay: boolean;
          text: string;
        };
        link05: {
          isDisplay: boolean;
          text: string;
        };
      };
      artistInfo: {
        isDisplayArtistStateLabel: boolean;
        headline: {
          isDisplay: boolean;
          text: string;
          subText: string;
        };
      };
      purchaseButton: {
        path: string | null;
      };
    };
    goodsPage: {
      /** -- 基本的にtrueで設定する -- */
      isDisplayEventTitle: boolean;
      isDisplayArtist: boolean;
      isDisplayGoodsInfo: boolean;
      /** ------------------------- */
    };
    streamingPage: {
      isDisplaySuperChatArea: boolean;
    };
    accountTicketPage: {
      /** -- 基本的にtrueで設定する -- */
      isDisplayEventTitle: boolean;
      isDisplayArtist: boolean;
      isDisplayShareButton: boolean;
      /** ------------------------- */
    };
  };
};

/**
 * return param for style by hostname
 */
export const changeStyleWithHosting = (): HostingStyleMap => {
  const belongedHosting = getBelongedHosting();
  switch (belongedHosting) {
    case "portal-1":
      return portalTopStyles();
    case "vtuber1":
      return vtuber1BaseStyles();
    case "event1":
    case "brand-1":
      return event1BaseStyles();
    case "774inc1":
      return inc1BaseStyles();
    case "webinar1":
      return webinar1BaseStyles();
    case "php1":
      return php1BaseStyles();
    case "fent1":
      return fent1BaseStyles();
    case "geki-keshigomu1":
      return gekiKeshigomuBaseStyles();
    case "kioichostudio1":
      return kioichostudioBaseStyles();
    case "biz1":
      return bizBaseStyles();
    case "platto1":
      return plattoBaseStyles();
    case "uchigeki1":
      return uchigekiBaseStyles();
    case "gibiate1":
      return gibiate1BaseStyles();
    case "v-clan1":
      return clan1BaseStyles();
    case "harevutai1":
      return harevutai1BaseStyles();
    case "akilab-1":
      return akilab1BaseStyles();
    case "theater-academy-1":
      return theaterAcademy1BaseStyles();
    case "hiroki773-1":
      return hiroki774BaseStyles();
    case "mdl-1":
      return mdl1BaseStyles();
    case "engekiichiba-1":
      return engekiichibaBaseStyles();
    case "ufg-1":
      return ufgBaseStyles();
    case "tvf-1":
      return tvfBaseStyles();
    case "theatre-workshop-1":
      return theatreWorkshopBaseStyles();
    case "ultraman-1":
      return ultramanBaseStyles();
    case "keytalk-1":
      return keytalkBaseStyles();
    case "tif-1":
      return tifBaseStyles();
    case "phantom-beats-opera-1":
      return phantomBeatsOperaBaseStyles();
    case "hadajin-shoten-1":
      return hadajinShotenBaseStyles();
    case "yam2020-1":
      return yam2020BaseStyles();
    case "balus-1":
      return balusBaseStyles();
    case "toukenranbu-1":
      return toukenranbuBaseStyles();
    case "frankenstein-1":
      return frankensteinBaseStyles();
    case "BS12-1":
      return bs12BaseStyles();
    case "confac-1":
      return confacBaseStyles();
    case "capsule-1":
    case "capsule-50-1":
      return capsuleBaseStyles();
    case "pokopea-1":
      return pokopeaBaseStyles();
    case "hama-kento-1":
      return hamaKentoBaseStyle();
    case "straycity-1":
      return strayCityBaseStyles();
    case "tsudaken-1":
      return tsudakenBaseStyles();
    case "watakon-stage-1":
      return watakonBaseStyles();
    case "themoney-1":
      return themoneyBaseStyles();
    case "lemino-1":
      return leminoBaseStyles();
    case "virtuacul-1":
      return virtuaculBaseStyles();
    case "terashima-junta-1":
      return terashimaJuntaBaseStyles();
    case "of-the-dead-1":
      return ofTheDeadBaseStyles();
    default:
      // fail safe
      return vtuber1BaseStyles();
  }
};

/**
 * return destination domain of event
 * @param hostingType
 */
export const getRedirectDomainByHostingType = (
  belongedHostings: HostingType[]
) => {
  const belongedHosting = getBelongedHosting();
  // if already belonged event domain, return null
  if (belongedHostings.includes(belongedHosting)) {
    return null;
  }
  // !!! use first index of belongedHostings !!!
  const [hostingType] = belongedHostings;

  if (!hostingType || !hostingMap[hostingType]) {
    return hostingMap["portal-1"][0];
  }
  // !!! use first index !!!
  return hostingMap[hostingType][0];
};

export const isPortalDomain = () => {
  return getBelongedHosting() === "portal-1";
};

export const isToukenranbuDomain = () => {
  return getBelongedHosting() === "toukenranbu-1";
};

export const isVirtuaculDomain = () => {
  return getBelongedHosting() === "virtuacul-1";
};

export const isDocomoDomain = () => {
  return (
    getBelongedHosting() === "ultraman-1" || getBelongedHosting() === "lemino-1"
  );
};

export const isPermanentGoods = (hostingArray: string[]) => {
  return hostingArray.includes("774inc1") || hostingArray.includes("balus-1");
};

export const getBelongedDomain = (hosting: HostingType) => {
  // first index is belonged domain.
  if (!hosting || !hostingMap[hosting]) {
    return hostingMap["portal-1"][0];
  }
  return hostingMap[hosting][0];
};
