/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";

import { fetchFirestoreDocument } from "../../../utility/firebase";

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { useI18n } from "hooks/i18n/i18n";
import type { GoodsConfig } from "@spwn/types/firebase/firestore";

type EventGoodsSideMenuProps = {
  pathname: string;
  eventId: string;
  title: string;
  subTitle: string;
};
export const EventGoodsSideMenu: React.FC<EventGoodsSideMenuProps> = (
  props
) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState<boolean>(false);
  // @ts-expect-error TS2345
  const [searchWord, setSearchWord] = useState<string>(null);
  // @ts-expect-error TS2345
  const [goodsHashtags, setGoodsHashtags] = useState<string[]>(null);

  const classesSidebarDesign = sidebarDesign({ isOpen });
  const classesSearchFormDesign = searchFormDesign({ isOpen });
  const { t } = useI18n();

  const didMount = () => {
    if (props.eventId) {
      getGoodsHashtags();
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(didMount, [props.eventId]); // TODO

  const onFocus = () => {
    setOpen(true);
  };
  const searchGoods = () => {
    if (searchWord) {
      dispatch(
        push("/events/" + props.eventId + "/goods/?search=" + searchWord)
      );
    } else {
      dispatch(push("/events/" + props.eventId + "/goods/"));
    }
  };
  // @ts-expect-error TS7006
  const enterPressed = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      searchGoods();
    }
  };
  const initialize = () => {
    setOpen(false);
  };

  const getGoodsHashtags = async () => {
    const goodsDocumentRef = `/goods/${props.eventId}/`;
    const goodsDocument = await fetchFirestoreDocument<GoodsConfig>(
      goodsDocumentRef
    );
    const hastags =
      goodsDocument && goodsDocument.hashtags
        ? goodsDocument.hashtags
            .sort((a, b) => b.weight - a.weight)
            .map((el) => el.name)
        : null;
    // @ts-expect-error TS2345
    setGoodsHashtags(hastags);
  };

  if (!goodsHashtags) {
    return (
      <div css={classesSidebarDesign.root}>
        <div css={classesSidebarDesign.heading}>
          <h2>{props.title}</h2>
          <p>{props.subTitle}</p>
        </div>
      </div>
    );
  }

  return (
    <div css={classesSidebarDesign.root}>
      <div css={classesSidebarDesign.heading}>
        <h2>{props.title}</h2>
        <p>{props.subTitle}</p>
      </div>

      <div css={classesSearchFormDesign.root}>
        <Paper component="div" css={classesSearchFormDesign.search}>
          <IconButton
            type="submit"
            onClick={searchGoods}
            css={classesSearchFormDesign.iconButton}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
          <InputBase
            placeholder={t("goods.search.placeHolder")}
            inputProps={{ "aria-label": "search product and tags" }}
            onFocus={onFocus}
            onChange={(e) => {
              setSearchWord(e.target.value);
            }}
            onKeyPress={enterPressed}
            css={classesSearchFormDesign.input}
          />
        </Paper>
        <p onClick={initialize}>キャンセル</p>
      </div>

      <ul css={classesSidebarDesign.tagsList}>
        {goodsHashtags.map((tag, index) => {
          const url = `/events/${props.eventId}/goods/?search=${tag}`;
          return (
            <li key={index}>
              <Link to={url} onClick={initialize}>
                {tag}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

interface SliderDesignProps {
  isOpen: boolean;
}
const sidebarDesign = (props?: SliderDesignProps) => {
  // @ts-expect-error TS18048
  const displayState = props.isOpen ? "block" : "none";

  return {
    root: css`
      width: 90%;
      padding-top: 20px;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 200px;
        padding-top: 0px;
        margin-right: 40px;
      }
    `,
    heading: css`
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      letter-spacing: 1px;
      h2 {
        font-family: "din-condensed", sans-serif;
        font-weight: 400;
        font-style: normal;
        padding: 0 0 0 20px;
        font-size: 28px;
        line-height: 1em;
        letter-spacing: 2px;
        border-left: 5px solid #00c2ae;
      }
      p {
        padding-left: 8px;
        font-size: 12px;
        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }
    `,
    tagsList: css`
      display: ${displayState};
      margin-bottom: 30px;
      @media screen and (min-width: 768px) {
        display: block;
      }
      li {
        font-size: 14px;
        letter-spacing: 1px;
        &:not(:last-child) {
          margin-bottom: 14px;
        }
        a {
          color: #25c2af;
        }
      }
    `,
  };
};

interface SearchFormDesignDesignProps {
  isOpen: boolean;
}
const searchFormDesign = (props?: SearchFormDesignDesignProps) => {
  // @ts-expect-error TS18048
  const displayState = props.isOpen ? "block" : "none";
  // @ts-expect-error TS18048
  const formWidth = props.isOpen ? `calc(100% - 85px)` : `100%`;

  return {
    root: css`
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p {
        display: ${displayState};
        width: 70px;
        margin-left: 15px;
        font-size: 14px;
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    `,
    search: css`
      display: flex;
      align-items: center;
      width: ${formWidth};
      padding: 10px;
      background-color: #f8f8f8 !important;
      box-shadow: none !important;
      @media screen and (min-width: 768px) {
        width: 100%;
      }
      input {
        font-size: 16px;
      }
    `,
    input: css`
      width: calc(100% - 34px);
    `,
    iconButton: css`
      padding: 0px !important;
      margin-right: 10px !important;
      svg {
        fill: #000;
      }
    `,
  };
};
