/* eslint-disable react-hooks/exhaustive-deps */
// TODO: 修正必要

/** @jsxRuntime classic /
/** @jsx jsx */
import { Global, css, jsx } from "@emotion/core";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { useI18n } from "hooks/i18n/i18n";

import { streamingActions } from "modules/streaming";

import { EventInfo } from "utility";

import { SPView, PCView } from "components/common/MediaQuery";
import {
  ToggleSwitch,
  ToggleSwitchHandleChange,
} from "components/switch/ReactSwitch";
import { AlertBar } from "components/atoms/AlertBar";

import { StreamingController } from "./StreamingController";
import { VIDEO_SKIP_SECONDS } from "constants/streaming";
import SettingsIcon from "@material-ui/icons/Settings";
import Popper, { PopperPlacementType } from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";

import { MediaPlayer } from "amazon-ivs-player";
import type { THEOPlayer } from "@spwn/types/external";

type StreamingScreenProps = {
  THEOPlayerElementHeight: number;
  player: THEOPlayer;
  awsPlayer: MediaPlayer;
  eventInfo: EventInfo;
  useLL: boolean;
  hasLL: boolean;
  hasURL: boolean;
  isControllerRendering: boolean;
  isCancelLowLatencyMessage: boolean;
  setLLUrl: ToggleSwitchHandleChange;
  setTHEOPlayerElementHeight: (THEOPlayerElementHeight: number) => void;
  setIsControllerRendering: () => void;
};
const StreamingScreen: React.FC<StreamingScreenProps> = (props) => {
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );
  const isTheaterModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isTheaterModeEnabled
  );
  const streamingType = useSelector(
    (state: Store) => state.streaming.streamingType
  );

  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();

  const classesStreamingScreenStyle = useMemo(
    () => streamingScreenStyle({ isTheaterModeEnabled }),
    [isTheaterModeEnabled]
  );
  const classesStreamingScreenSettingLayout = useMemo(
    () => streamingScreenSettingLayout({ isDarkModeEnabled }),
    [isDarkModeEnabled]
  );
  const classesAwsPlayerStyles = useMemo(
    () => awsPlayerStyles(),
    [props.awsPlayer]
  );

  const handleClick =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (newPlacement: PopperPlacementType) => (event: React.MouseEvent<any>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  return (
    <div id="video" css={classesStreamingScreenStyle.video}>
      <Global styles={THEOPlayerStyle} />
      <SPView>
        <AlertBar />
        <div css={classesStreamingScreenSettingLayout.root}>
          <SettingsIcon onClick={handleClick("bottom-end")} />
          <Popper
            css={classesStreamingScreenSettingLayout.popper}
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <StreamingScreenSP {...props} />
              </Fade>
            )}
          </Popper>
        </div>
      </SPView>

      <div css={classesStreamingScreenStyle.controllerLayout}>
        <SPView>
          <StreamingController
            player={props.player}
            awsPlayer={props.awsPlayer}
            streamingType={streamingType}
            THEOPlayerElementHeight={props.THEOPlayerElementHeight}
            isSP={true}
            isCancelLowLatencyMessage={props.isCancelLowLatencyMessage}
            hasURL={props.hasURL}
          />
        </SPView>
        <PCView>
          <StreamingController
            player={props.player}
            awsPlayer={props.awsPlayer}
            streamingType={streamingType}
            THEOPlayerElementHeight={props.THEOPlayerElementHeight}
            isSP={false}
            isCancelLowLatencyMessage={props.isCancelLowLatencyMessage}
            hasURL={props.hasURL}
          />
        </PCView>
        <div
          className="theoplayer-container video-js theoplayer-skin"
          style={{ display: "" }}
        ></div>
        <video
          id="aws-video-player"
          className="aws-video-player"
          css={classesAwsPlayerStyles.root}
          poster={props.eventInfo.thumbnail}
          style={{ display: "none" }}
          playsInline
          controls
        ></video>
      </div>

      <PCView>
        <StreamingScreenPC {...props} />
      </PCView>
    </div>
  );
};

export default StreamingScreen;

type StreamingScreenSPProps = StreamingScreenProps;
/**
 * SPView component
 */
const StreamingScreenSP: React.FC<StreamingScreenSPProps> = (props) => {
  return <StreamingScreenSetting {...props} isSP={true} />;
};

type StreamingScreenPCProps = StreamingScreenProps;
/**
 * PCView component
 */
const StreamingScreenPC: React.FC<StreamingScreenPCProps> = (props) => {
  const isTheaterModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isTheaterModeEnabled
  );

  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { THEOplayer } = window as any;
  const Button = THEOplayer.videojs.getComponent("Button");

  /**
   * @see {@link https://docs.portal.theoplayer.com/how-to-guides/11-ui/07-how-to-insert-a-button.md|Theoplayer}
   **/
  class TheaterModeButton extends Button {
    // @ts-expect-error TS7006
    constructor(player, options = {}) {
      super(player, options);
      const tooltipSpan = document.createElement("span");
      tooltipSpan.className = "theo-button-tooltip tooltip-theater vjs-hidden";
      tooltipSpan.innerText = "Theater";
      const toggleTooltip = () => {
        tooltipSpan.classList.toggle("vjs-hidden");
      };
      this.el().addEventListener("mouseover", toggleTooltip);
      this.el().addEventListener("mouseout", toggleTooltip);
      this.el().appendChild(tooltipSpan);
    }

    handleClick() {
      toggleTheaterModeLayout();
    }

    buildCSSClass() {
      return "player-icon icon-crop-169 vjs-button";
    }
  }

  class RewindButton extends Button {
    // @ts-expect-error TS7006
    constructor(player, options = {}) {
      super(player, options);

      // add tooltip
      const tooltipSpan = document.createElement("span");
      tooltipSpan.className = "theo-button-tooltip vjs-hidden";
      tooltipSpan.innerText = `Go back ${VIDEO_SKIP_SECONDS} seconds`;
      const toggleTooltip = () => {
        tooltipSpan.classList.toggle("vjs-hidden");
      };
      this.el().addEventListener("mouseover", toggleTooltip);
      this.el().addEventListener("mouseout", toggleTooltip);
      this.el().appendChild(tooltipSpan);
    }

    handleClick() {
      props.player.currentTime -= VIDEO_SKIP_SECONDS;
    }

    buildCSSClass() {
      return `player-icon icon-replay-${VIDEO_SKIP_SECONDS} vjs-button`;
    }
  }

  class ForwardButton extends Button {
    // @ts-expect-error TS7006
    constructor(player, options = {}) {
      super(player, options);

      const tooltipSpan = document.createElement("span");
      tooltipSpan.className = "theo-button-tooltip vjs-hidden";
      tooltipSpan.innerText = `Go forward ${VIDEO_SKIP_SECONDS} seconds`;
      function toggleTooltip() {
        tooltipSpan.classList.toggle("vjs-hidden");
      }
      this.el().addEventListener("mouseover", toggleTooltip);
      this.el().addEventListener("mouseout", toggleTooltip);
      this.el().appendChild(tooltipSpan);
    }
    handleClick() {
      props.player.currentTime += VIDEO_SKIP_SECONDS;
    }
    buildCSSClass() {
      return `player-icon icon-forward-${VIDEO_SKIP_SECONDS} vjs-button`;
    }
  }

  const didMount = () => {
    document.addEventListener(
      "fullscreenchange",
      toggleTheaterModeButtonDisplay,
      false
    );
    document.addEventListener("fullscreenchange", toggleSetVideoHeight, false);

    return () => {
      document.removeEventListener(
        "fullscreenchange",
        toggleTheaterModeButtonDisplay,
        false
      );
      document.removeEventListener(
        "fullscreenchange",
        toggleSetVideoHeight,
        false
      );
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(didMount, [
    /* toggleSetVideoHeight */
    // TODO 一時的に
  ]);

  const watchPlayer = () => {
    if (!!props.player && !props.isControllerRendering) {
      THEOplayer.videojs.registerComponent(
        "theaterModeButton",
        TheaterModeButton
      );
      props.player.ui.getChild("controlBar").addChild("theaterModeButton", {});

      THEOplayer.videojs.registerComponent("rewindButton", RewindButton);
      props.player.ui.getChild("controlBar").addChild("rewindButton", {});

      THEOplayer.videojs.registerComponent("forwardButton", ForwardButton);
      props.player.ui.getChild("controlBar").addChild("forwardButton", {});

      props.setIsControllerRendering();
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(watchPlayer, [props.player]);

  const watchTheaterMode = () => {
    if (props.player) {
      const tooltipSpan = document.getElementsByClassName(
        "tooltip-theater"
      )[0] as HTMLElement;
      tooltipSpan.innerText = isTheaterModeEnabled ? "Default" : "Theater";
    }
  };
  useEffect(watchTheaterMode, [props.player, isTheaterModeEnabled]);

  // シアターモードボタンをクリックした時、ページのレイアウトを切り替える
  const toggleTheaterModeLayout = () => {
    dispatch(streamingActions.toggleTheaterMode());
  };

  // 動画のフルスクリーンの際に、シアターモードボタンを非表示にする
  const toggleTheaterModeButtonDisplay = () => {
    // eslint-disable-next-line prefer-destructuring
    const theaterModeControl =
      document.getElementsByClassName("icon-crop-169")[0];
    if (theaterModeControl) {
      theaterModeControl.classList.toggle("hide");
    }
  };

  const toggleSetVideoHeight = () => {
    props.setTHEOPlayerElementHeight(
      // @ts-expect-error TS2532
      document.getElementById("video")?.clientHeight -
        // @ts-expect-error TS2532
        document.getElementById("streamingOption")?.clientHeight
    );
  };

  return <StreamingScreenSetting {...props} isSP={false} />;
};

type StreamingScreenCommonProps = StreamingScreenProps & {
  isSP: boolean;
};
/**
 * screen view common component
 */
const StreamingScreenSetting: React.FC<StreamingScreenCommonProps> = (
  props
) => {
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );
  const streamingType = useSelector(
    (state: Store) => state.streaming.streamingType
  );
  const dispatch = useDispatch();
  const classesStreamingScreenSetting = useMemo(
    () => streamingScreenSetting({ isDarkModeEnabled }),
    [isDarkModeEnabled]
  );
  const isLowLatencyDisabled =
    props.eventInfo?.streamingInfo?.screenSettings?.isLowLatencyDisabled;
  const { t } = useI18n();

  return (
    <div id="streamingOption" css={classesStreamingScreenSetting.switchList}>
      <div css={classesStreamingScreenSetting.switch}>
        <p>{t("streaming.mode.dark")}</p>
        {ToggleSwitch(isDarkModeEnabled, () =>
          dispatch(streamingActions.toggleDarkMode())
        )}
      </div>
      {streamingType === "Live" && props.hasLL ? (
        props.isSP && isLowLatencyDisabled ? null : (
          <div css={classesStreamingScreenSetting.switch}>
            <p>{t("streaming.mode.LL")}</p>
            {ToggleSwitch(props.useLL, props.setLLUrl)}
          </div>
        )
      ) : null}
    </div>
  );
};

/**
 * CSS-in-JS
 */
const THEOPlayerStyle = css`
  .theoplayer-skin .vjs-big-play-button:after {
    content: none !important;
  }
  .theo-primary-color,
  .theo-upnext-panel .theo-upnext-panel-content .theo-upnext-panel-autoplay,
  .theoplayer-skin .theo-settings-control-menu-item-value,
  .theoplayer-skin .vjs-volume-level,
  .theoplayer-skin .vjs-play-progress,
  .theoplayer-skin .vjs-slider-bar,
  .theoplayer-skin .vjs-big-play-button {
    color: #fff !important;
  }
  .theoplayer-skin .theo-settings-control-menu-item-hd-label {
    color: red !important;
  }
  .theoplayer-skin .vjs-big-play-button {
    width: auto !important;
    height: auto !important;
    padding-bottom: 0 !important;
  }
  .video-js .vjs-big-play-button {
    height: auto !important;
  }
  .theoplayer-skin .theo-menu-header {
    display: none !important;
  }
  .theoplayer-skin .theo-settings-control-label-hd {
    color: #fff !important;
    background-color: red !important;
  }
  .theoplayer-skin .theo-menu-item {
    padding: 0.6em 1em !important;
    padding-right: 2em !important;
  }
  .theoplayer-skin .theo-menu {
    min-height: auto !important;
  }
  .vjs-time-tooltip.theo-secondary-color,
  .theoplayer-skin .vjs-progress-control:hover .vjs-time-tooltip {
    width: 70px;
  }
  .theo-settings-control-menu {
    z-index: 25 !important;
  }
  .theo-secondary-color.vjs-control-bar {
    z-index: 10;
  }
  .player-icon {
    padding: 0;
    cursor: pointer;
    &:before {
      content: "" !important;
      display: block;
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  .icon-crop-169 {
    order: 99;
    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0id2hpdGUiIHdpZHRoPSIxOHB4IiBoZWlnaHQ9IjE4cHgiPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMTkgNUg1Yy0xLjEgMC0yIC45LTIgMnYxMGMwIDEuMS45IDIgMiAyaDE0YzEuMSAwIDItLjkgMi0yVjdjMC0xLjEtLjktMi0yLTJ6bTAgMTJINVY3aDE0djEweiIvPjwvc3ZnPg==");
      background-size: 24px;
    }
    &.hide {
      display: none;
    }
  }
  .icon-replay-5 {
    order: -101;
    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9IndoaXRlIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij48Zz48cmVjdCBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiLz48L2c+PGc+PGc+PHBhdGggZD0iTTEyLDVWMUw3LDZsNSw1VjdjMy4zMSwwLDYsMi42OSw2LDZzLTIuNjksNi02LDZzLTYtMi42OS02LTZINGMwLDQuNDIsMy41OCw4LDgsOHM4LTMuNTgsOC04UzE2LjQyLDUsMTIsNXoiLz48Zz48cGF0aCBkPSJNMTAuNjksMTMuOWwwLjI1LTIuMTdoMi4zOXYwLjcxaC0xLjdsLTAuMTEsMC45MmMwLjAzLTAuMDIsMC4wNy0wLjAzLDAuMTEtMC4wNXMwLjA5LTAuMDQsMC4xNS0wLjA1IHMwLjEyLTAuMDMsMC4xOC0wLjA0czAuMTMtMC4wMiwwLjItMC4wMmMwLjIxLDAsMC4zOSwwLjAzLDAuNTUsMC4xczAuMywwLjE2LDAuNDEsMC4yOHMwLjIsMC4yNywwLjI1LDAuNDVzMC4wOSwwLjM4LDAuMDksMC42IGMwLDAuMTktMC4wMywwLjM3LTAuMDksMC41NHMtMC4xNSwwLjMyLTAuMjcsMC40NXMtMC4yNywwLjI0LTAuNDUsMC4zMXMtMC4zOSwwLjEyLTAuNjQsMC4xMmMtMC4xOCwwLTAuMzYtMC4wMy0wLjUzLTAuMDggcy0wLjMyLTAuMTQtMC40Ni0wLjI0cy0wLjI0LTAuMjQtMC4zMi0wLjM5cy0wLjEzLTAuMzMtMC4xMy0wLjUzaDAuODRjMC4wMiwwLjE4LDAuMDgsMC4zMiwwLjE5LDAuNDFzMC4yNSwwLjE1LDAuNDIsMC4xNSBjMC4xMSwwLDAuMi0wLjAyLDAuMjctMC4wNnMwLjE0LTAuMSwwLjE4LTAuMTdzMC4wOC0wLjE1LDAuMTEtMC4yNXMwLjAzLTAuMiwwLjAzLTAuMzFzLTAuMDEtMC4yMS0wLjA0LTAuMzEgcy0wLjA3LTAuMTctMC4xMy0wLjI0cy0wLjEzLTAuMTItMC4yMS0wLjE1cy0wLjE5LTAuMDUtMC4zLTAuMDVjLTAuMDgsMC0wLjE1LDAuMDEtMC4yLDAuMDJzLTAuMTEsMC4wMy0wLjE1LDAuMDUgcy0wLjA4LDAuMDUtMC4xMiwwLjA3cy0wLjA3LDAuMDYtMC4xLDAuMDlMMTAuNjksMTMuOXoiLz48L2c+PC9nPjwvZz48L3N2Zz4=");
    }
  }
  .icon-forward-5 {
    order: -100;
    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9IndoaXRlIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij48Zz48cmVjdCBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiLz48L2c+PGc+PGc+PHBhdGggZD0iTTE4LDEzYzAsMy4zMS0yLjY5LDYtNiw2cy02LTIuNjktNi02czIuNjktNiw2LTZ2NGw1LTVsLTUtNXY0Yy00LjQyLDAtOCwzLjU4LTgsOGMwLDQuNDIsMy41OCw4LDgsOGM0LjQyLDAsOC0zLjU4LDgtOCBIMTh6Ii8+PHBhdGggZD0iTTEyLjAzLDE1LjM4Yy0wLjQ0LDAtMC41OC0wLjMxLTAuNi0wLjU2aC0wLjg0YzAuMDMsMC44NSwwLjc5LDEuMjUsMS40NCwxLjI1YzAuOTMsMCwxLjQ0LTAuNjMsMS40NC0xLjQzIGMwLTEuMzMtMC45Ny0xLjQ0LTEuMy0xLjQ0Yy0wLjIsMC0wLjQzLDAuMDUtMC42NCwwLjE2bDAuMTEtMC45MmgxLjd2LTAuNzFoLTIuMzlsLTAuMjUsMi4xN2wwLjY3LDAuMTcgYzAuMTMtMC4xMywwLjI4LTAuMjMsMC41Ny0wLjIzYzAuNCwwLDAuNjksMC4yMywwLjY5LDAuNzVDMTIuNjIsMTQuNjQsMTIuNjUsMTUuMzgsMTIuMDMsMTUuMzh6Ii8+PC9nPjwvZz48L3N2Zz4=");
    }
  }
  .icon-replay-10 {
    order: -101;
    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmYiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGQ9Ik0xMS45OSA1VjFsLTUgNSA1IDVWN2MzLjMxIDAgNiAyLjY5IDYgNnMtMi42OSA2LTYgNi02LTIuNjktNi02aC0yYzAgNC40MiAzLjU4IDggOCA4czgtMy41OCA4LTgtMy41OC04LTgtOHoiLz48cGF0aCBkPSJNMTAuODkgMTZoLS44NXYtMy4yNmwtMS4wMS4zMXYtLjY5bDEuNzctLjYzaC4wOVYxNnpNMTUuMTcgMTQuMjRjMCAuMzItLjAzLjYtLjEuODJzLS4xNy40Mi0uMjkuNTctLjI4LjI2LS40NS4zMy0uMzcuMS0uNTkuMS0uNDEtLjAzLS41OS0uMS0uMzMtLjE4LS40Ni0uMzMtLjIzLS4zNC0uMy0uNTctLjExLS41LS4xMS0uODJ2LS43NGMwLS4zMi4wMy0uNi4xLS44MnMuMTctLjQyLjI5LS41Ny4yOC0uMjYuNDUtLjMzLjM3LS4xLjU5LS4xLjQxLjAzLjU5LjEuMzMuMTguNDYuMzMuMjMuMzQuMy41Ny4xMS41LjExLjgydi43NHptLS44NS0uODZjMC0uMTktLjAxLS4zNS0uMDQtLjQ4cy0uMDctLjIzLS4xMi0uMzEtLjExLS4xNC0uMTktLjE3LS4xNi0uMDUtLjI1LS4wNS0uMTguMDItLjI1LjA1LS4xNC4wOS0uMTkuMTctLjA5LjE4LS4xMi4zMS0uMDQuMjktLjA0LjQ4di45N2MwIC4xOS4wMS4zNS4wNC40OHMuMDcuMjQuMTIuMzIuMTEuMTQuMTkuMTcuMTYuMDUuMjUuMDUuMTgtLjAyLjI1LS4wNS4xNC0uMDkuMTktLjE3LjA5LS4xOS4xMS0uMzIuMDQtLjI5LjA0LS40OHYtLjk3eiIvPjwvc3ZnPg==");
      background-size: 70% auto;
    }
  }
  .icon-forward-10 {
    order: -100;
    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmYiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGQ9Ik0xOCAxM2MwIDMuMzEtMi42OSA2LTYgNnMtNi0yLjY5LTYtNiAyLjY5LTYgNi02djRsNS01LTUtNXY0Yy00LjQyIDAtOCAzLjU4LTggOHMzLjU4IDggOCA4IDgtMy41OCA4LThoLTJ6Ii8+PHBhdGggZD0iTTEwLjg2IDE1Ljk0di00LjI3aC0uMDlMOSAxMi4zdi42OWwxLjAxLS4zMXYzLjI2ek0xMi4yNSAxMy40NHYuNzRjMCAxLjkgMS4zMSAxLjgyIDEuNDQgMS44Mi4xNCAwIDEuNDQuMDkgMS40NC0xLjgydi0uNzRjMC0xLjktMS4zMS0xLjgyLTEuNDQtMS44Mi0uMTQgMC0xLjQ0LS4wOS0xLjQ0IDEuODJ6bTIuMDQtLjEydi45N2MwIC43Ny0uMjEgMS4wMy0uNTkgMS4wM3MtLjYtLjI2LS42LTEuMDN2LS45N2MwLS43NS4yMi0xLjAxLjU5LTEuMDEuMzgtLjAxLjYuMjYuNiAxLjAxeiIvPjwvc3ZnPg==");
      background-size: 70% auto;
    }
  }
  .aws-video-player {
    display: block;
    width: 100%;
  }
`;

const awsPlayerStyles = () => {
  return {
    root: css`
      &::-webkit-media-controls-timeline {
        display: none;
      }
      &::-webkit-media-controls-current-time-display {
        display: none;
      }
      &::-webkit-media-controls-time-remaining-display {
        display: none;
      }
    `,
  };
};

interface StreamingScreenStyleProps {
  isTheaterModeEnabled: boolean;
}
const streamingScreenStyle = (props?: StreamingScreenStyleProps) => {
  const normalModeScreenStyle = css`
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    overflow: hidden;
    @media screen and (min-width: 768px) {
      position: relative;
      grid-column-start: 1;
      grid-column-end: 2;
    }
  `;
  const theaterModeScreenStyle = css`
    overflow: hidden;
    @media screen and (min-width: 768px) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  `;

  const controllerLayout = css`
    position: relative;
  `;

  // @ts-expect-error TS18048
  if (props.isTheaterModeEnabled) {
    return {
      video: theaterModeScreenStyle,
      controllerLayout,
    };
  } else {
    return {
      video: normalModeScreenStyle,
      controllerLayout,
    };
  }
};

interface StreamingScreenSettingLayoutProps {
  isDarkModeEnabled: boolean;
}
const streamingScreenSettingLayout = (
  props?: StreamingScreenSettingLayoutProps
) => {
  // @ts-expect-error TS18048
  const svgColorInDarkMode = props.isDarkModeEnabled
    ? css`
        fill: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const backgroundColorInDarkMode = props.isDarkModeEnabled
    ? css`
        background-color: #202020;
      `
    : css`
        background-color: #ffffff;
      `;
  // @ts-expect-error TS18048
  const popupBackgroundColorInDarkMode = props.isDarkModeEnabled
    ? css`
        background-color: #202020;
      `
    : css`
        background-color: #efefef;
      `;

  return {
    root: css`
      padding: 8px 5%;
      text-align: right;
      ${backgroundColorInDarkMode}
      svg {
        ${svgColorInDarkMode}
      }
      .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
        width: 100%;
      }
    `,
    popper: css`
      position: fixed !important;
      top: 42px !important;
      left: auto !important;
      right: 0 !important;
      padding: 16px 5%;
      ${popupBackgroundColorInDarkMode}
      transform: none !important;
      z-index: 250;
    `,
  };
};

const streamingScreenSetting = (props?: { isDarkModeEnabled: boolean }) => {
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;
  return {
    switchList: css`
      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: flex-end;
        padding: 8px 0;
      }
    `,
    switch: css`
      display: flex;
      @media screen and (min-width: 768px) {
        margin-left: 16px;
      }
      &:not(:last-child) {
        margin-bottom: 16px;
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
        }
      }
      p {
        margin-right: 10px;
        ${textColorInDarkMode};
        font-size: 14px;
      }
    `,
  };
};
