import Box from "@material-ui/core/Box";
import { useI18n } from "hooks/i18n/i18n";
import React, { FC } from "react";

export const AccountTicketListHaveNotTicket: FC = () => {
  const { t } = useI18n();
  return (
    <Box width={["90%", "740px"]} margin={"0 auto"}>
      <Box
        component={"p"}
        style={{
          padding: "80px 0",
          fontSize: "14px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {t("ticket.empty")}
      </Box>
    </Box>
  );
};
