/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useI18n } from "hooks/i18n/i18n";
import { FC } from "react";
import { PortalTopSectionLayout } from "./PortalTop";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Slider from "react-slick";
import { PortalTopFanclubProps } from "./PortalTopFanclub";

export const PortalTopFanclubSlider: FC<PortalTopFanclubProps> = ({
  brandList,
}) => {
  const { t } = useI18n();

  const isDisplayArrow = 6 < brandList.length;
  const classes = styles({ isDisplayArrow });

  const settings = {
    arrows: isDisplayArrow,
    autoplay: false,
    dots: false,
    infinite: false,
    accessibility: true,
    swipe: true,
    swipeToSlide: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    className: "fanclub-slider",
    prevArrow: (
      <button className="prevArrow">
        <NavigateBeforeIcon color={"action"} fontSize={"large"} />
      </button>
    ),
    nextArrow: (
      <button className="nextArrow">
        <NavigateNextIcon color={"action"} fontSize={"large"} />
      </button>
    ),
  };

  return (
    <PortalTopSectionLayout
      heading={{
        mainText: "FANCLUB",
        subText: t("event.top.crew"),
      }}
    >
      <div css={classes.root}>
        <Slider {...settings}>
          {brandList.map((brand) => {
            return (
              <div
                key={brand.brandSlug}
                css={css`
                  padding: 0 12px;
                  @media screen and (min-width: 768px) {
                    padding: 0 20px;
                  }
                  img {
                    display: block;
                    width: 100%;
                    max-width: 120px;
                    margin: 0 auto 8px;
                    border-radius: 50%;
                    aspect-ratio: 1 / 1;
                    object-fit: cover;
                    @media screen and (min-width: 768px) {
                      max-width: 240px;
                    }
                  }
                  h3 {
                    color: #fff;
                    text-align: center;
                    line-height: 1.5em;
                  }
                `}
              >
                <a
                  href={`https://crew.spwn.jp/${brand.brandSlug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={brand.thumbnail} alt={brand.brandName} />
                  <h3>{brand.brandName}</h3>
                </a>
              </div>
            );
          })}
        </Slider>
      </div>
    </PortalTopSectionLayout>
  );
};

const styles = ({ isDisplayArrow }: { isDisplayArrow: boolean }) => {
  const slideWidth = isDisplayArrow ? "calc(100% - 75px)" : "100%";

  return {
    root: css`
      @media screen and (min-width: 768px) {
        width: ${slideWidth};
        margin: 0 auto;
      }
      .slick-prev,
      .slick-next {
        top: 64px;
        width: auto;
        height: auto;
        z-index: 10;
        &:before {
          display: none;
        }
        svg {
          fill: #fff;
        }
      }
      .slick-prev {
        left: -50px;
      }
      .slick-next {
        right: -50px;
      }
      .slick-disabled {
        opacity: 0.25;
      }
    `,
  };
};
