import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { PayType } from "@spwn/types";
import { ActiveTransaction } from "@spwn/types/firebase/firestore";
import type { CVSCode, PhoneCode } from "@spwn/types/gmo";

import ConfirmCvsPayment, { UnProcessData } from "components/ConfirmCvsPayment";
import { Store } from "store";

type CvsInfo = {
  cvsCode: CVSCode;
  ReceiptNo?: string;
  ConfNo?: string;
  payType: PayType;
};

type PhoneInfo = {
  phoneCode: PhoneCode;
  phoneURL?: string;
  payType: PayType;
};

export const useCreateUnprocessedOrderConfirmModal = () => {
  const myTickets = useSelector((state: Store) => state.ticket.myTickets);
  const activeTransactionMap = useSelector(
    (state: Store) => state.purchase.activeTransactionMap
  );

  const [isOpenConfirmCvsPayment, setIsOpenConfirmCvsPayment] =
    useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);
  const [unprocessedData, setUnprocessedData] = useState<UnProcessData[]>([]);
  const [cvsInfo, setCvsInfo] = useState<CvsInfo | null>(null);
  const [phoneInfo, setPhoneInfo] = useState<PhoneInfo | null>(null);

  const OpenCvsConfirmModal = useCallback(() => {
    setIsOpenConfirmCvsPayment(true);
  }, []);

  const closeCvsConfirmModal = useCallback(() => {
    setIsOpenConfirmCvsPayment(false);
  }, []);

  const createConfirmCvsPaymentData = useCallback(
    (transactionData: ActiveTransaction) => {
      const { orderId } = transactionData;
      const unprocessedDataList: UnProcessData[] = [];
      for (const { event, tickets, goods, unprocessOrderIds } of myTickets) {
        if (!unprocessOrderIds.includes(String(orderId))) continue;
        const ticketList = tickets.filter(
          (el) => el.orderId === String(orderId)
        );
        const goodsList = goods.filter((el) => el.orderId === String(orderId));
        const productList = ticketList.concat(goodsList);
        if (productList.length !== 0) {
          unprocessedDataList.push({
            event,
            ticketList,
            goodsList,
          });
        }
      }

      setSelectedOrderId(Number(orderId));
      setUnprocessedData(unprocessedDataList);
      setCvsInfo(
        transactionData.payType === "CVS"
          ? {
              ConfNo: transactionData.confNo ?? undefined,
              ReceiptNo: transactionData.receiptNo ?? undefined,
              cvsCode: transactionData.convenience as CVSCode,
              payType: transactionData.payType,
            }
          : null
      );

      setPhoneInfo(
        transactionData.payType === "Phone"
          ? {
              phoneCode: transactionData.phoneCode as PhoneCode,
              phoneURL: transactionData.phoneURL ?? undefined,
              payType: transactionData.payType,
            }
          : null
      );

      OpenCvsConfirmModal();
    },
    [myTickets, OpenCvsConfirmModal]
  );

  const renderConfirmCvsPayment = useCallback(() => {
    return (
      isOpenConfirmCvsPayment &&
      selectedOrderId &&
      (!!cvsInfo || !!phoneInfo) && (
        <ConfirmCvsPayment
          isOpen={isOpenConfirmCvsPayment}
          close={closeCvsConfirmModal}
          unprocessData={unprocessedData}
          cvsCode={cvsInfo?.cvsCode ?? undefined}
          ReceiptNo={cvsInfo?.ReceiptNo}
          ConfNo={cvsInfo?.ConfNo}
          phoneCode={phoneInfo?.phoneCode ?? undefined}
          phoneURL={phoneInfo?.phoneURL}
          orderId={selectedOrderId}
          activeTransactionMap={activeTransactionMap}
        />
      )
    );
  }, [
    isOpenConfirmCvsPayment,
    unprocessedData,
    activeTransactionMap,
    selectedOrderId,
    closeCvsConfirmModal,
    cvsInfo,
    phoneInfo,
  ]);

  return {
    loading: !myTickets,
    createConfirmCvsPaymentData,
    renderConfirmCvsPayment,
  };
};
