/** @jsxRuntime classic /
/** @jsx jsx */
import React, { FC, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";

import { Event, TopInfo } from "@spwn/types/firebase/firestore";
import { useGetEventList } from "hooks/event/useGetEventList";
import { TopUpcomingEvent } from "components/top/TopUpcomingEvent";
import { ArchiveEventListLogic } from "containers/top/ArchiveEventList";
import { TopInformation } from "containers/top/TopInformation";
import { TopCatchCopy } from "containers/top/TopCatchCopy";
import { changeStyleWithHosting } from "utility/hosting";
import { BrandTopLiveStream } from "./BrandTopLiveStream";

const Container = styled.div`
  padding-top: 20px;
  margin: 0 auto;
`;

export type BrandTopFetcherProps = {
  topInfo: TopInfo;
  isLiveEventMaintenance: boolean;
  maintenanceLiveEventId: string;
  isAdmin: boolean;
};

export const BrandTopFetcher: FC<BrandTopFetcherProps> = ({
  topInfo,
  isLiveEventMaintenance,
  maintenanceLiveEventId,
  isAdmin,
}) => {
  // ライブイベント取得
  const { data: liveEvents } = useGetEventList({
    type: "live",
    limit: 30, // Query結果に対して絞り込んでいるため暫定的に直近30件の中から絞り込むようにする
    enabled: () => !maintenanceLiveEventId,
  });

  // メンテナンス中のライブイベント取得
  const { data: maintenanceLiveEvents } = useGetEventList({
    type: "byIds",
    ids: [maintenanceLiveEventId],
    enabled: () => !!maintenanceLiveEventId,
  });

  // 今後のイベント
  const { data: comingEvents, isLoading: comingEventIsLoading } =
    useGetEventList({
      type: "coming",
      limit: 30, // 本来は6件でいいものの、Firestoreの制約によりopenで絞り込みしつつdatetime順でソートしたものから5件取得ということはできないため、多めに取得する
    });

  // アーカイブ配信中のイベント
  const { data: vodEvents, isLoading: vodEventIsLoading } = useGetEventList({
    type: "openVOD",
    limit: 6,
  });

  // 終了したイベント
  const { data: archiveEvents, isLoading: archiveEventIsLoading } =
    useGetEventList({
      type: "archive",
      limit: 6,
      enabled: () =>
        !comingEventIsLoading &&
        !comingEvents?.length &&
        !vodEventIsLoading &&
        !vodEvents?.length,
    });

  return (
    <BrandTop
      topInfo={topInfo}
      isLiveEventMaintenance={isLiveEventMaintenance}
      isAdmin={isAdmin}
      liveEvents={liveEvents}
      maintenanceLiveEvents={maintenanceLiveEvents}
      comingEvents={comingEvents}
      vodEvents={vodEvents}
      archiveEvents={archiveEvents}
      isLoading={
        comingEventIsLoading || vodEventIsLoading || archiveEventIsLoading
      }
    />
  );
};

export type BrandTopProps = {
  topInfo: TopInfo;
  isLiveEventMaintenance: boolean;
  isAdmin: boolean;
  liveEvents?: Event[];
  maintenanceLiveEvents?: Event[];
  comingEvents?: Event[];
  vodEvents?: Event[];
  archiveEvents?: Event[];
  isLoading: boolean;
};

export const BrandTop: FC<BrandTopProps> = ({
  topInfo,
  isLiveEventMaintenance,
  isAdmin,
  liveEvents,
  maintenanceLiveEvents,
  comingEvents,
  vodEvents,
  archiveEvents,
  isLoading,
}) => {
  const [upcomingEventType, setUpcomingEventType] =
    useState<"UPCOMING EVENT" | "VIDEO ON DEMAND" | "ARCHIVE EVENT" | "EVENT">(
      "EVENT"
    );
  const [displayEvents, setDisplayEvents] = useState<Event[]>([]);

  const liveEvent = maintenanceLiveEvents?.[0] ?? liveEvents?.[0];

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (comingEvents?.length) {
      setUpcomingEventType("UPCOMING EVENT");
      setDisplayEvents(comingEvents);
    } else if (vodEvents?.length) {
      setUpcomingEventType("VIDEO ON DEMAND");
      setDisplayEvents(vodEvents);
    } else if (archiveEvents?.length) {
      setUpcomingEventType("ARCHIVE EVENT");
      setDisplayEvents(archiveEvents);
    } else {
      setUpcomingEventType("EVENT");
      setDisplayEvents([]);
    }
  }, [isLoading, comingEvents, vodEvents, archiveEvents]);

  return (
    <Container id="contents">
      {changeStyleWithHosting().pageSettings.topPage.isDisplayTodaysEvent &&
        liveEvent && (
          <BrandTopLiveStream
            event={liveEvent}
            isLiveEventMaintenance={isLiveEventMaintenance}
            isAdmin={isAdmin}
          />
        )}

      {/* 今後開催するイベントを表示する */}
      <div>
        <TopUpcomingEvent
          topInfo={topInfo}
          displayEvents={displayEvents}
          upcomingEventType={upcomingEventType}
          isLoading={isLoading}
        />
      </div>

      {changeStyleWithHosting().pageSettings.topPage
        .isDisplayDeliveryDisplayArea && (
        <div
          css={css`
            padding-top: 60px;
            @media screen and (min-width: 768px) {
              padding-top: 80px;
            }
          `}
        >
          <ArchiveEventListLogic vodEvents={vodEvents} />
        </div>
      )}

      {changeStyleWithHosting().pageSettings.topPage.isDisplayInfoArea && (
        <div
          css={css`
            padding-top: 60px;
            @media screen and (min-width: 768px) {
              padding-top: 80px;
            }
          `}
        >
          <TopInformation />
        </div>
      )}

      {changeStyleWithHosting().pageSettings.topPage.isDisplayCatchCopy && (
        <div
          css={css`
            padding-top: 100px;
            padding-bottom: 100px;
            @media screen and (min-width: 768px) {
              padding-top: 200px;
              padding-bottom: 200px;
            }
          `}
        >
          <TopCatchCopy />
        </div>
      )}
    </Container>
  );
};
