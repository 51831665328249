/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

import type { DayContent } from "@spwn/types";
import { EventTimetableCartButton } from "./EventTimetableCartButton";

interface Props {
  selectedDay: DayContent;
  dayActionStatus: string;
  doButtonAction: () => void;
}
export const EventTimetableSelectedDate: React.FC<Props> = (props) => {
  const classes = styles();

  if (!props.selectedDay.ticketPath) {
    return (
      <div css={classes.root}>
        <div css={classes.layout}>
          <h3>{props.selectedDay.dayName}</h3>
        </div>
      </div>
    );
  }

  return (
    <div css={classes.root}>
      <div css={classes.layout}>
        <h3>{props.selectedDay.dayName}</h3>

        <div css={classes.button}>
          {props.dayActionStatus === "NotPurchased" && (
            <EventTimetableCartButton
              onClick={props.doButtonAction}
              size="large"
            >
              DAYチケットを購入
            </EventTimetableCartButton>
          )}

          {props.dayActionStatus === "InCart" && (
            <React.Fragment>
              <EventTimetableCartButton
                onClick={props.doButtonAction}
                badge={true}
                variant="outlined"
                size="large"
              >
                カートを確認する
              </EventTimetableCartButton>
            </React.Fragment>
          )}

          {props.dayActionStatus === "SettlementConfirm" && (
            <EventTimetableCartButton
              onClick={props.doButtonAction}
              variant="outlined"
              size="large"
            >
              支払状況を確認
            </EventTimetableCartButton>
          )}

          {props.dayActionStatus === "Purchased" && (
            <EventTimetableCartButton size="large" disabled={true}>
              チケット購入済み
            </EventTimetableCartButton>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      border-top: 4px solid #00c2ad;
      border-bottom: 2px solid #00c2ad;
      border-left: 4px solid #00c2ad;
      border-right: 4px solid #00c2ad;
      background-color: #000;
      @media screen and (min-width: 768px) {
        border: none;
        border-bottom: 2px solid #00c2ad;
      }
    `,
    layout: css`
      width: 100%;
      max-width: 500px;
      padding: 24px 0;
      margin: 0 auto;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        padding: 40px 0;
      }
      h3 {
        font-family: "DIN Alternate", sans-serif;
        color: #fff;
        font-size: 28px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 4px;
        text-align: center;
      }
    `,
    button: css`
      margin: 16px auto 0;
      display: flex;
      justify-content: center;
    `,
  };
};
