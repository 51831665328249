/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "store";
import { liveActions } from "modules/live";
import { getLiveContextType } from "utility/live";
import { EventInfo } from "utility";
import { StreamingLiveEventQuiz } from "./StreamingLiveEventQuiz";
import { firestoreActions } from "modules/firestore";
import { useI18n } from "hooks/i18n/i18n";

interface Props {
  isDarkModeEnabled: boolean;
  eventInfo: EventInfo;
  THEOPlayerElementHeight?: number;
}
export const StreamingLiveEvent: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const classesLiveEventLayoutStyles = liveEventLayoutStyles({
    isDarkModeEnabled: props.isDarkModeEnabled,
  });

  const contextId = useSelector((state: Store) => state.live.contextId);
  // @ts-expect-error TS2345
  const [eventId, setEventId] = useState<string>(null);
  const { t } = useI18n();

  const watchEventInfo = () => {
    const eventId = props.eventInfo._id;
    dispatch(liveActions.watchEventContext.started({ eventId }));
    // @ts-expect-error TS2345
    setEventId(eventId);
    return () => {
      dispatch(firestoreActions.closeChannel({ channel: "liveContext" }));
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(watchEventInfo, [props.eventInfo]);

  if (!eventId || contextId === null) {
    return null;
  }

  const contextType = getLiveContextType(contextId);
  switch (contextType) {
    case "qid":
      return (
        <StreamingLiveEventQuiz
          eventId={eventId}
          contextId={contextId}
          THEOPlayerElementHeight={props.THEOPlayerElementHeight}
          isDarkModeEnabled={props.isDarkModeEnabled}
        />
      );
    case "cid":
      return (
        /**
         * TODO@Kawano
         * 今現在スタイルは無し
         * Tap Eventを使うことになったらスタイルを作成する
         *
         **/
        <p>{t("streaming.commerceLiveEvent.eventTap")}</p>
      );
    default:
      return (
        <div css={classesLiveEventLayoutStyles.root}>
          <p>{t("streaming.commerceLiveEvent.loadingEvent")}</p>
        </div>
      );
  }
};

interface LiveEventLayoutStylesProps {
  isDarkModeEnabled: boolean;
}
const liveEventLayoutStyles = (props?: LiveEventLayoutStylesProps) => {
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : css`
        color: #000;
      `;
  return {
    root: css`
      display: flex;
      align-items: center;
      justify-content: center;
      height: 280px;
      border: 1px solid #e0e0e0;
      @media screen and (min-width: 768px) {
        height: calc(478px - 45px);
      }
      p {
        ${textColorInDarkMode}
      }
    `,
  };
};
