/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO fsa系型崩壊してるのでとりあえずのdisable

import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { put, takeEvery } from "redux-saga/effects";
import appConfig from "../constants/appConfig";
import { fetchFbToken } from "utility/firebase";
import { modalActions } from "./modal";
import { loadingActions } from "./loading";
import { getDateTimeString } from "utility/index";
import { errorReport } from "utility/logger";
import type { ResRevealVoucherCodeInfo } from "@spwn/types/functions";
import type { Event, ProductData } from "@spwn/types/firebase/firestore";

const actionCreator = actionCreatorFactory("voucher");

export const voucherActions = {
  clearStateByKey: actionCreator<keyof VoucherState>("clearStateByKey"),
  fetchEnableVoucherData: actionCreator.async<ReqFetchEnableVoucherData, any>(
    "fetchEnableVoucherData"
  ),
  redeemVoucherCode:
    actionCreator.async<ReqRedeemVoucherCode, any>("redeemVoucherCode"),
};

export interface VoucherState {
  enableVoucherData: {
    usableCount: number;
    selectableEventMap: { [eid: string]: Event };
    selectableTicketMap: {
      [eid: string]: {
        [pid: string]: {
          selectableCount: number;
        } & ProductData;
      };
    };
  };
  successRedeemVoucherCode: boolean;
  redeemedTicketDataList: {
    eventId: string;
    ticketId: string;
    num: number;
  }[];
}

const initialState: VoucherState = {
  // @ts-expect-error TS2322
  enableVoucherData: null,
  // @ts-expect-error TS2322
  successRedeemVoucherCode: null,
  // @ts-expect-error TS2322
  redeemedTicketDataList: null,
};

type ReqFetchEnableVoucherData = {
  voucherCode: string;
};

type ReqRedeemVoucherCode = {
  code: string;
  tickets: {
    eventId: string;
    ticketId: string;
    num: number;
  }[];
};

const voucherReducer = reducerWithInitialState(initialState)
  .case(voucherActions.clearStateByKey, (state, key: keyof VoucherState) => {
    return { ...state, [key]: initialState[key] };
  })
  .case(voucherActions.fetchEnableVoucherData.done, (state, payload: any) => {
    return { ...state, enableVoucherData: payload };
  })
  .case(voucherActions.redeemVoucherCode.done, (state, payload: any) => {
    return { ...state, successRedeemVoucherCode: payload };
  });

export default voucherReducer;

export function* voucherSaga() {
  yield takeEvery(
    voucherActions.fetchEnableVoucherData.started,
    fetchEnableVoucherData
  );
  yield takeEvery(voucherActions.redeemVoucherCode.started, redeemVoucherCode);
}

/**
 * fetch event and ticket list by voucher code
 * @param action
 */
function* fetchEnableVoucherData(action: {
  payload: ReqFetchEnableVoucherData;
}) {
  try {
    if (!action.payload.voucherCode) {
      return;
    }
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    if (!fbToken) {
      return;
    }

    yield put(loadingActions.toggleLoading({ msg: "イベント取得中" }));

    // @ts-expect-error TS7057
    const response = yield fetch(
      // @ts-expect-error TS2769
      appConfig.CloudFunctions.revealVoucherCodeInfo,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `'Bearer ${fbToken}`,
        },
        body: JSON.stringify({ code: action.payload.voucherCode }),
      }
    );

    if ([200, 201].includes(response.status)) {
      const resBody: ResRevealVoucherCodeInfo = yield response.json();
      yield put(voucherActions.fetchEnableVoucherData.done(resBody as any));
    } else {
      const resBody: ResRevealVoucherCodeInfo = yield response.json();
      if (resBody.isError) {
        let msg: string;
        if (resBody.lockedUntil) {
          const dateTimeString = getDateTimeString(
            new Date(resBody?.lockedUntil * 1000)
          );
          msg = `複数回に渡る入力エラーのため${dateTimeString}までロックされています`;
        } else {
          const { msg: message } = resBody;
          // @ts-expect-error TS2322
          msg = message;
        }
        yield put(modalActions.toggleError({ caption: msg }));
      }
    }
    return;
  } catch (error) {
    console.error(error);
  } finally {
    yield put(loadingActions.toggleLoading({}));
  }
}

/**
 * use voucher code
 * @param action
 */
function* redeemVoucherCode(action: { payload: ReqRedeemVoucherCode }) {
  try {
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    if (!fbToken) {
      return;
    }

    // yield put(loadingActions.toggleLoading({msg: "登録中"}))

    const reqBody = {
      code: action.payload.code,
      tickets: action.payload.tickets,
    };
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.CloudFunctions.redeemVoucherCode, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify(reqBody),
    });

    if ([200, 201].includes(response.status)) {
      // @ts-expect-error TS7057
      const resBody = yield response.json();
      console.info(resBody);
      yield put(voucherActions.redeemVoucherCode.done(true as any));
    } else {
      // @ts-expect-error TS7057
      const resBody = yield response.json();
      if (resBody.isError) {
        yield put(modalActions.toggleError({ caption: resBody.msg }));
      }
      yield put(voucherActions.redeemVoucherCode.done(false as any));
    }
  } catch (error) {
    console.error(error);
    if (error instanceof Error) errorReport(error);
    yield put(voucherActions.redeemVoucherCode.done(false as any));
  } finally {
    // yield put(loadingActions.toggleLoading({}))
  }
}
