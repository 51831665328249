/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { MouseEventHandler } from "react";

type Props = {
  children: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  badge?: boolean;
  variant?: "contained" | "outlined";
  size?: "medium" | "large";
  disabled?: boolean;
};

export const EventTimetableCartButton: React.FC<Props> = ({
  children,
  onClick,
  badge = false,
  variant = "contained",
  size = "medium",
  disabled = false,
}) => {
  const contained = css`
    color: #fff;
    background-color: #00c2ad;
    border: 2px solid #00c2ad;
  `;
  const outlined = css`
    color: #00c2ad;
    background-color: #fff;
    border: 2px solid #00c2ad;
  `;
  const buttonStyle = variant === "contained" ? contained : outlined;

  const medium = css`
    width: 100%;
    padding: 10px 0;
    @media screen and (min-width: 768px) {
      width: 100%;
      padding: 10px 0;
    }
  `;
  const large = css`
    width: 80%;
    padding: 10px 0;
    @media screen and (min-width: 768px) {
      width: 100%;
      padding: 14px 0;
    }
  `;
  const buttonSize = size === "medium" ? medium : large;

  const disabledStyle = css`
    opacity: 0.6;
    cursor: initial;
    pointer-events: none;
  `;

  const cartButton = css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
    ${buttonStyle};
    ${buttonSize};
    ${disabled && disabledStyle};
  `;
  const icon = css`
    position: relative;
    margin-right: 8px;
  `;
  const badgeStyle = css`
    content: "";
    position: absolute;
    top: 2.5px;
    right: 0;
    width: 6px;
    height: 6px;
    background-color: #f00;
    border-radius: 50%;
  `;
  return (
    <div css={cartButton} onClick={onClick}>
      <div css={icon}>
        <ShoppingCartIcon />
        {badge && <span css={badgeStyle}></span>}
      </div>
      <div>{children}</div>
    </div>
  );
};
