/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "../../store";
import { AddressInfo, authActions } from "../../modules/auth";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import {
  hasEmailProviderData,
  isReAuthRequiredToEditAddress,
} from "utility/auth";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { AccountAddress } from "./AccountAddress";
import {
  ContentsLayout,
  EditForm,
  LinkButton,
  MyPageEditProfile,
  WidthDefinedButtonList,
} from "../../styles";
import { BreadcrumbArea } from "../../components/common/Link";
import { useI18n } from "../../hooks/i18n/i18n";
import {
  SNSLinkageStatus,
  getSNSLinkageStatus,
} from "utility/getSNSLinkageStatus";
import { AccountAddressLinkedTwitter } from "./AccountAddressLinkedTwitter";

export const AccountAddressBase: React.FC = () => {
  const user = useSelector((state: Store) => state.auth.user);
  const originAddressInfo = useSelector(
    (state: Store) => state.auth.addressInfo
  );
  const isReAuthRequired = useSelector(
    (state: Store) => state.auth.isReAuthRequired
  );
  const isAddressRegistered = useSelector(
    (state: Store) => state.auth.isAddressRegistered
  );
  const successReAuthForEditUserInfo = useSelector(
    (state: Store) => state.auth.successReAuthForEditUserInfo
  );

  // @ts-expect-error TS2345
  const [addressInfo, setAddressInfo] = useState<AddressInfo>(null);
  const [password, setPassword] = useState<string>("");
  /** ユーザーがSNS連携を行っているかの情報を取得する。*/
  const [snsLinkageStatus, setSNSLinkageStatus] = useState<SNSLinkageStatus>(
    getSNSLinkageStatus(user)
  );

  const Container = MyPageEditProfile;
  const { t } = useI18n();
  const dispatch = useDispatch();
  const isEmailProvider = hasEmailProviderData(user);

  /**
   * ユーザーがSNS連携を行っているかの情報をStateに保存する。
   */
  useEffect(() => {
    setSNSLinkageStatus(getSNSLinkageStatus(user));
  }, [user]);

  // initialize to original address
  useEffect(() => {
    if (originAddressInfo) {
      setAddressInfo(originAddressInfo);
    }
  }, [originAddressInfo]);

  // ログインから時間が経っていたら再認証を要求する
  useEffect(() => {
    if (!user.uid || isAddressRegistered === null) {
      return;
    }
    const isRequired =
      isAddressRegistered && isReAuthRequiredToEditAddress(user, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch(authActions.reAuthForEditUserInfo.done(isRequired as any)); //!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAddressRegistered]);

  // 再認証が完了していれば登録ずみの住所を取得する(loadAddress)
  useEffect(() => {
    if (isReAuthRequired === false) {
      dispatch(authActions.loadAddress.started({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReAuthRequired]);

  const initialReAuth = () => {
    dispatch(authActions.reAuthForEditUserInfo.started({ password }));
  };

  // @ts-expect-error TS7006
  const onChangePassword = (event) => {
    const { value } = event.currentTarget;
    setPassword(value);
  };

  // 再認証が必要であれば再認証コンポーネントを出す
  if (isReAuthRequired) {
    return (
      <Container id="contents">
        <BreadcrumbArea
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            ["/account", t("common.routes.account")],
            [null, t("common.routes.accountAddress")],
          ]}
        />

        <ContentsLayout>
          <p style={{ textAlign: "center", paddingBottom: "10px" }}>
            {t("accountAddress.reAuthenticateUser")}
          </p>
          {isEmailProvider ? (
            <div className="prifle_edit_info">
              <EditForm>
                <p>{t("auth.password")}</p>
                <input
                  type="password"
                  name="password"
                  onChange={onChangePassword}
                  placeholder={t("auth.loginpass")}
                  value={password}
                />
              </EditForm>
            </div>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <WidthDefinedButtonList onClick={initialReAuth}>
            <a href="#!">
              <LinkButton>{t("accountAddress.reAuthenticate")}</LinkButton>
            </a>
          </WidthDefinedButtonList>
        </ContentsLayout>
      </Container>
    );
  }

  if (!addressInfo) {
    return (
      <div id="contents-layout">
        <LoaderLayout loadingText={"Loading..."} />
      </div>
    );
  }

  if (snsLinkageStatus.twitter) {
    return <AccountAddressLinkedTwitter user={user} />;
  }

  return (
    <AccountAddress
      user={user}
      addressInfo={addressInfo}
      password={password}
      isEmailProvider={isEmailProvider}
      isReAuthRequired={isReAuthRequired}
      isAddressRegistered={isAddressRegistered}
      successReAuthForEditUserInfo={successReAuthForEditUserInfo}
      setAddressInfo={setAddressInfo}
      onChangePassword={onChangePassword}
    />
  );
};
