import { KeyedMutator, mutate } from "swr";
import useSWRImmutable from "swr/immutable";
import firebase from "firebase/app";
import {
  GetUserCartRequest,
  GetUserCartResponse,
  UserCartDTO,
} from "@spwn/types/functions";

export type UserCart = GetUserCartResponse;

const functionName = "getUserCart";
const generateKey = () => functionName;

export const useGetUserCart = (): {
  data: UserCart;
  error: unknown;
  isValidating: boolean;
  mutate: KeyedMutator<UserCartDTO>;
} => {
  const { data, error, isValidating, mutate } = useSWRImmutable(
    generateKey(),
    async () => {
      const { data } = await callGetUserCart();
      return data;
    }
  );
  return {
    // @ts-expect-error TS2322
    data,
    error,
    isValidating,
    mutate,
  };
};

const callGetUserCart = async (
  request: GetUserCartRequest
): Promise<{ data: GetUserCartResponse }> => {
  return await firebase.functions().httpsCallable(functionName)(request);
};

/**
 * 再検証
 */
export const revalidate = async (): Promise<void> => {
  await mutate(generateKey());
};
