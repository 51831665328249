/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../../store";
import { push } from "connected-react-router";
import { authActions } from "../../modules/auth";
import { cartActions, MyCartData } from "../../modules/cart";
import {
  purchaseActions,
  CardInfo,
  ReqPurchaseProductsWithCard,
  ReqPurchaseProductsWithMember,
  ReqPurchaseProductsWithCVS,
  ReqPurchaseProductsWithPhone,
  ReqPurchaseProductsWithFree,
} from "../../modules/purchase";
import { Redirect } from "react-router";
import {
  getItemTotal,
  getEventDisplayInfo,
  getNowTimestamp,
} from "../../utility";
import BorderLoader from "../../components/atoms/loader/BorderLoader";
import {
  phoneInfo,
  defaultPhoneCode,
  cvsInfo,
  defaultCvsCode,
  MAX_PURCHASE_AMOUNT_BY_CVS,
  DAY_PERIOD_OF_CVS_PAYMENT,
  MAX_PURCHASE_AMOUNT_BY_PHONE,
  HOUR_UNTIL_PURCHASE_PHONE_CANCELED,
  SETTLEMENT_FEE_DATA,
} from "../../constants/purchase";
import { modalActions, ActionData } from "../../modules/modal";
import { Link } from "react-router-dom";
import {
  isArchiveTicket,
  isLiveTicket,
  IS_HIDE_DATETIME,
} from "../../utility/event";
import {
  CantUseCVS,
  CantUseCVSReason,
  CantUsePhone,
  CantUsePhoneReason,
} from "../../components/purchase/Notice";
import { BreadcrumbArea } from "components/common/Link";
import {
  checkSendingFee,
  calcDomainShippingFee,
  DISABLED_PURCHASE_BY_CVS,
} from "utility/purchase";
import { extractAddressRequiredDomains } from "utility/cart";
import { withTranslation, WithTranslation } from "react-i18next";
import { I18nTrans } from "hooks/i18n/i18n";
import { PurchaseButton } from "components/settlement/PurchaseButton";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { isToukenranbuDomain } from "utility/hosting";
import { getDisplayPriceInfo } from "utility/ticket";
import styled from "@emotion/styled";
import {
  checkedLayoutMixin,
  fontDinMixin,
  LinkButton,
  ContentsLayout,
  WidthDefinedButtonList,
} from "../../styles";
import type { CVSCode, PhoneCode } from "@spwn/types/gmo";
import type {
  Event,
  Place,
  UserCartProduct,
} from "@spwn/types/firebase/firestore";
import { InjectCartToSettlement } from "./InjectCartToSettlement";
import { TextLink } from "ui/TextLink";
import firebase from "firebase/app";
import "firebase/auth";

interface Props extends WithTranslation {
  user: firebase.User;
  myCart: MyCartData[];
  eventMap: { [eventId: string]: Event };
  places: Place[];
  cardInfoList: null | CardInfo[];
  isPurchasing: boolean;
  toggleMyCart: () => void;
  // @ts-expect-error TS7051
  addLoginAction: (any) => void;
  fetchCardInfo: () => void;
  purchaseProductsWithCard: (payload: ReqPurchaseProductsWithCard) => void;
  purchaseProductsWithMemId: (payload: ReqPurchaseProductsWithMember) => void;
  purchaseProductsWithCVS: (payload: ReqPurchaseProductsWithCVS) => void;
  purchaseProductsWithPhone: (payload: ReqPurchaseProductsWithPhone) => void;
  purchaseProductsWithFree: (payload: ReqPurchaseProductsWithFree) => void;
  // @ts-expect-error TS7006
  toggleNotice: (msgObj) => void;
  toggleActionModal: (payload: ActionData) => void;
  setIsPurchasing: (payload: boolean) => void;
  clearSettlementState: () => void;
  refreshFingerprint: () => void;
  shippingAtTitle?: string;
  /**
   * テナント商品用の注文内容コンポーネント
   * 存在する場合はこちらを描画する
   */
  OrderDetails?: JSX.Element;
  /**
   * テナント商品用の購入処理
   * 存在する場合はこちらを利用する
   */
  purchase?: (
    paymentMethod: PaymentMethod,
    onChangeField: OnChangeField
  ) => Promise<void>;
}

export interface States {
  currentState: PurchaseState;
  nextState: PurchaseState;
  prevState: PurchaseState;
  paymentMethod: PaymentMethod;
  isRegisteredCard: null | boolean; // nullの場合はクレカ情報fetch中
  onChangeField: OnChangeField;
  destinationType: null | "far" | "regular";
  shippingFee: number;
  settlementFee: number;
  serviceFee: number;
  isChecked: boolean;
}
type PurchaseState =
  | null
  | PaymentMethod
  | "creditConfirm"
  | "cvsConfirm"
  | "phoneConfirm";
type PaymentMethod = null | "creditMember" | "creditNew" | "cvs" | "phone";
type OnChangeField = CardInputInfo & CvsInputInfo & PhoneInputInfo;
type CardInputInfo = {
  cardNumber: string;
  cardExpireMonth: string;
  cardExpireYear: string;
  cardHolderName: string;
  cardSecurityCode: string;
  checkCardRegister: boolean;
  useCreditInfoIndex: number;
};
type CvsInputInfo = {
  cvsCode: CVSCode;
  customerKana: string;
  customerName: string;
  telNo: string;
};
type PhoneInputInfo = {
  phoneCode: PhoneCode;
};

const SettlementButtonList = styled(WidthDefinedButtonList)`
  > * {
    margin-bottom: 35px;
  }
`;

const _Container = styled.div`
  @media screen and (min-width: 768px) {
    margin: 0 auto;
  }
  padding-bottom: 60px;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    padding-bottom: 150px;
  }
  .settlement_order_details {
    background-color: #fafafa;
    border-bottom: 1px solid #e0e0e0;
    @media screen and (min-width: 768px) {
      padding: 40px 0 80px;
    }
    .settlement_order_heading {
      width: 90%;
      margin: 0 auto 20px;
      @media screen and (min-width: 768px) {
        width: 960px;
        margin: 0 auto 40px;
      }
      h2 {
        font-size: 16px;
        @media screen and (min-width: 768px) {
          width: 960px;
          font-size: 24px;
        }
      }
      .shippingAtTitle {
        margin-top: 8px;
        font-size: 14px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          margin-top: 16px;
          font-size: 16px;
        }
      }
    }
    .contents {
      width: 90%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 740px;
        max-width: 90%;
      }
    }
  }
  .order_item {
    margin-bottom: 15px;
    img {
      width: 100%;
      background-color: #fff;
      &.flame_pc {
        display: none;
        @media screen and (min-width: 768px) {
          display: inline;
        }
      }
      &.flame_sp {
        display: inline;
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }
  }
  .order_item_layout {
    margin-top: -5px;
    border-right: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
    background-color: #fff;
    .item_info {
      width: 94%;
      padding-bottom: 15px;
      margin: 0 auto 10px;
      border-bottom: 1px solid #d2d2d2;
      @media screen and (min-width: 768px) {
        padding-bottom: 20px;
        margin: 0 auto 30px;
      }
      .event,
      .date {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
      .place {
        font-size: 12px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }
    }
    .item {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 94%;
      padding-bottom: 15px;
      margin: 0 auto 10px;
      border-bottom: 1px solid #d2d2d2;
      @media screen and (min-width: 768px) {
        padding-bottom: 20px;
        margin: 0 auto 20px;
      }
      .heading {
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }
      .ticket,
      .goods {
        margin-bottom: 15px;
        font-weight: bold;
        line-height: 1.3em;
        @media screen and (min-width: 768px) {
          margin-bottom: 25px;
          font-size: 18px;
        }
      }
      .price {
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
        span {
          @media screen and (min-width: 768px) {
            margin-left: 5px;
            font-size: 12px;
            font-weight: normal;
          }
        }
      }
      .num {
        ${fontDinMixin};
        font-size: 40px;
        span {
          font-size: 25px;
        }
      }
      &:last-child {
        padding-bottom: 10px;
        border: none;
        @media screen and (min-width: 768px) {
          padding-bottom: 0;
        }
      }
    }
  }
  .change_button {
    display: block;
    width: 100px;
    padding: 10px 0;
    margin-bottom: 25px;
    color: #fff;
    font-size: 11px;
    text-align: center;
    background-color: #62b5e5;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      width: 170px;
      padding: 12px 0;
      font-size: 14px;
    }
  }
  .address_caution {
    color: #ff0000;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.3em;
  }
  .total_price {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .heading {
      padding-bottom: 8px;
      font-size: 12px;
      @media screen and (min-width: 768px) {
        font-size: 14px;
      }
    }
    .total {
      font-size: 30px;
      font-weight: bold;
      span {
        margin-left: 5px;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  .shipping_fee {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 5px;
    .heading {
      padding-bottom: 8px;
      font-size: 12px;
      @media screen and (min-width: 768px) {
        font-size: 14px;
      }
    }
    .total {
      font-size: 20px;
      span {
        margin-left: 5px;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  .settlement_01_details,
  .settlement_02_details,
  .settlement_03_details,
  .settlement_04_details,
  .settlement_05_details,
  .settlement_06_details {
    padding: 40px 0 0;
    @media screen and (min-width: 768px) {
      padding: 60px 0 0;
    }
    h2 {
      margin-bottom: 20px;
      font-size: 18px;
      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
        font-size: 25px;
      }
    }
    .form_layout {
      margin-bottom: 60px;
      @media screen and (min-width: 768px) {
        width: 740px;
        margin: 0 auto 100px;
      }
      .text {
        line-height: 1.5em;
        span,
        a {
          color: #00c2ae;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .form {
        margin-bottom: 20px;
        @media screen and (min-width: 768px) {
          margin-bottom: 40px;
        }
        p {
          margin-bottom: 10px;
          line-height: 1.3em;
          @media screen and (min-width: 768px) {
            margin-bottom: 15px;
          }
        }
        input {
          width: 100%;
          font-size: 16px;
          padding: 10px 3%;
          border: 1px solid #d2d2d2;
        }
      }
      select {
        width: 100%;
        font-size: 16px;
        border-radius: 0;
      }
    }
  }
  .settlement_03_details,
  .settlement_04_details,
  .settlement_05_details {
    .form_layout {
      .form {
        select {
          ${fontDinMixin};
          padding: 3px 28px 3px 17px;
          font-size: 25px;
        }
        input[name="code"] {
          ${fontDinMixin};
          width: 20%;
          padding: 5px 0;
          font-size: 25px;
          text-align: center;
          @media screen and (min-width: 768px) {
            width: 85px;
          }
        }
      }
      .credit_datetime {
        input {
          width: 22%;
          padding: 10px 3%;
          margin-right: 10px;
          font-size: 18px;
          @media screen and (min-width: 768px) {
            width: 70px;
          }
        }
      }
      .credit_register {
        label,
        input[type="checkbox"] {
          position: relative;
          cursor: pointer;
        }
        label {
          span {
            margin-left: 10px;
          }
        }
        input[type="checkbox"] {
          width: 1.2rem;
          &:before {
            position: absolute;
            z-index: 1;
            top: 0.2rem;
            left: 0.35rem;
            width: 0.8rem;
            height: 0.4rem;
            content: "";
            transition: -webkit-transform 0.4s
              cubic-bezier(0.45, 1.8, 0.5, 0.75);
            transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
            transform: rotate(-45deg) scale(0, 0);
            border: 3px solid #00c5cb;
            border-top-style: none;
            border-right-style: none;
          }
          &:after {
            position: absolute;
            top: -0.3rem;
            left: 0;
            width: 1.4rem;
            height: 1.4rem;
            content: "";
            cursor: pointer;
            border: 1px solid #adaeae;
            background: #ffffff;
          }
          &:checked:before {
            transform: rotate(-45deg) scale(1, 1);
          }
        }
      }
    }
    .select_payment {
      p {
        font-size: 18px;
        @media screen and (min-width: 768px) {
          font-size: 25px;
        }
        span {
          margin-left: 20px;
          color: #05d2da;
          font-size: 12px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .settlement_04_details,
  .settlement_05_details {
    .form_layout {
      input {
        ${fontDinMixin};
        padding: 5px 3%;
        font-size: 25px;
      }
      .credit_name {
        input {
          padding: 5px 3%;
          font-size: 25px;
        }
      }
    }
  }
  .settlement_06_details {
    .form_layout {
      margin-bottom: 40px;
      @media screen and (min-width: 768px) {
        margin-bottom: 50px;
      }
      h2 {
        font-size: 14px;
        font-weight: normal;
        @media screen and (min-width: 768px) {
          margin-bottom: 20px;
        }
      }
      p {
        font-size: 16px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
        &.font_din {
          ${fontDinMixin};
          font-size: 22px;
          @media screen and (min-width: 768px) {
            font-size: 30px;
          }
        }
      }
    }
  }
`;

const Container = styled(_Container)({}, checkedLayoutMixin);

class Settlement extends Component<Props, States> {
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    this.state = {
      currentState: null,
      nextState: "creditNew",
      prevState: null,
      paymentMethod: null,
      isRegisteredCard: null,
      // @ts-expect-error TS2322
      shippingFee: null,
      // @ts-expect-error TS2322
      settlementFee: null,
      serviceFee: 0,
      destinationType: null,
      onChangeField: {
        cardNumber: "",
        cardExpireMonth: "",
        cardExpireYear: "",
        cardHolderName: "",
        cardSecurityCode: "",
        checkCardRegister: false,
        useCreditInfoIndex: 0,
        cvsCode: defaultCvsCode,
        customerKana: "",
        customerName: "",
        telNo: "",
        phoneCode: defaultPhoneCode,
      },
      isChecked: false,
    };
    this.props.refreshFingerprint();
  }

  componentDidMount() {
    this.props.fetchCardInfo();
    this.checkSendingFee(this.props.myCart);

    // recalculate service fee
    const serviceFee = calcTotalServiceFee(this.props.myCart);
    // @ts-expect-error TS2322
    this.setState({ serviceFee });
  }

  componentDidUpdate(prevProps: Props) {
    const { myCart } = this.props;
    // if cart's content change, recheck shipping fee
    const prevProductLength =
      prevProps.myCart.length !== 0
        ? prevProps.myCart
            .map((el) => el.tickets.length + el.goods.length)
            .reduce((a, b) => a + b)
        : 0;
    const productLength =
      myCart.length !== 0
        ? myCart
            .map((el) => el.tickets.length + el.goods.length)
            .reduce((a, b) => a + b)
        : 0;
    if (prevProductLength !== productLength) {
      this.checkSendingFee(myCart);

      // recalculate service fee
      const serviceFee = calcTotalServiceFee(myCart);
      // @ts-expect-error TS2322
      this.setState({ serviceFee });
    }
  }

  componentWillUnmount() {
    this.props.clearSettlementState();
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isChecked: event.target.checked });
  };

  checkSendingFee = async (myCart: MyCartData[]) => {
    const isRegisteredShippingFee = hasAddressRequiredProduct(myCart);
    if (isRegisteredShippingFee) {
      const response = await checkSendingFee(this.props.user);
      if (response) {
        // if no fee.
        if (isShippingFeeRequired(myCart)) {
          // calc shipping fee
          const domains = extractAddressRequiredDomains(
            myCart,
            this.props.eventMap
          );
          const shippingFee = await calcDomainShippingFee(
            domains,
            response.destinationType
          );
          // @ts-expect-error TS2322
          this.setState({ shippingFee });
          this.setState({ destinationType: response.destinationType });
        } else {
          this.setState({ shippingFee: 0 });
        }
      } else {
        // invalid address
        this.props.toggleActionModal({
          actionModalType: "confirmTransition",
          trunk: { link: "/account/address" },
          caption: "無効な住所が確認されました",
          msg: "配送先住所が登録されていない、または、登録された住所が無効になっています。住所をご確認の上、「配送先住所の編集」より登録をよろしくお願いします。",
          btnMsg: "配送先住所の編集",
        });
      }
    } else {
      this.setState({ shippingFee: 0 });
    }
  };

  static getDerivedStateFromProps(props: Props, state: States) {
    // 非同期でカード情報を取得し、その結果から状態を変更する
    if (props.cardInfoList === null) {
      // ローディング中は何もしない
      return null;
    }
    const isRegisteredCard = props.cardInfoList.length !== 0;

    // 現在の状態から次の状態を決める
    let { currentState, nextState, onChangeField, paymentMethod } = state;
    if (
      state.currentState === "creditMember" ||
      state.currentState === "creditNew"
    ) {
      nextState = "creditConfirm";
    } else if (state.currentState === "cvs") {
      nextState = "cvsConfirm";
    } else if (state.currentState === "phone") {
      nextState = "phoneConfirm";
    } else if (state.currentState === null) {
      nextState = isRegisteredCard ? "creditMember" : "creditNew";
      nextState = state.nextState === "cvs" ? "cvs" : nextState; // if cvs, overwrite
      nextState = state.nextState === "phone" ? "phone" : nextState; // if cvs, overwrite
      onChangeField = {
        // clear input info
        cardNumber: "",
        cardExpireMonth: "",
        cardExpireYear: "",
        cardHolderName: "",
        cardSecurityCode: "",
        checkCardRegister: false,
        useCreditInfoIndex: 0,
        cvsCode: defaultCvsCode,
        customerKana: "",
        customerName: "",
        telNo: "",
        phoneCode: defaultPhoneCode,
      };
    }

    if (state.isRegisteredCard === null && state.currentState !== null) {
      // cardInfo取得が初めて・支払い方法を選択していない場合
      currentState = isRegisteredCard ? "creditMember" : "creditNew";
      currentState = state.currentState === "cvs" ? "cvs" : currentState; // if cvs, overwrite
    }
    paymentMethod =
      currentState === "creditNew" ||
      currentState === "creditMember" ||
      currentState === "cvs" ||
      currentState === "phone"
        ? currentState
        : paymentMethod;
    return {
      isRegisteredCard,
      currentState,
      nextState,
      paymentMethod,
      onChangeField,
    };
  }

  /**
   * 購入状態・支払い方法の更新を行う
   */
  setIsPurchasing = (currentState: PurchaseState, nextState: PurchaseState) => {
    if (
      currentState === "creditNew" ||
      currentState === "creditMember" ||
      currentState === "cvs" ||
      currentState === "phone"
    ) {
      const paymentMethod = currentState;
      this.setState({ currentState, nextState, paymentMethod });
      return;
    }
    // set settlement fee
    switch (nextState) {
      case "creditNew":
        this.setState({ settlementFee: SETTLEMENT_FEE_DATA["Card"] });
        break;
      case "creditMember":
        this.setState({ settlementFee: SETTLEMENT_FEE_DATA["Member"] });
        break;
      case "cvs":
        this.setState({ settlementFee: SETTLEMENT_FEE_DATA["CVS"] });
        break;
      case "phone":
        this.setState({ settlementFee: SETTLEMENT_FEE_DATA["Phone"] });
        break;
      default:
        break;
    }
    this.setState({ currentState, nextState });
  };

  /**
   * 次の状態に遷移する
   */
  goNext = () => {
    // 入力チェック
    const notEnteredItems = this.getNotEnteredItems();
    if (notEnteredItems.length !== 0) {
      this.props.toggleNotice({
        msg: notEnteredItems.join("・") + "を入力してください",
      });
      return;
    }

    const HalfWidthTelNo = this.convertFullWidthToHalfWidth(
      this.state.onChangeField.telNo
    );

    this.setState({
      onChangeField: {
        ...this.state.onChangeField,
        telNo: HalfWidthTelNo,
      },
    });

    const prevState = this.state.currentState;
    // scroll to top when confirmation
    if (
      this.state.nextState === "creditConfirm" ||
      this.state.nextState === "cvsConfirm" ||
      this.state.nextState === "phoneConfirm"
    ) {
      window.scrollTo(0, 0);
    }
    this.setState({
      currentState: this.state.nextState,
      nextState: null,
      prevState,
    });
  };

  /**
   * 前の状態に遷移する
   */
  goBack = () => {
    // reset settlement fee
    if (this.state.prevState === null) {
      this.setState({ settlementFee: 0 });
    }
    this.setState({
      currentState: this.state.prevState,
      nextState: null,
      prevState: null,
      isChecked: false,
    });
    // reset fingerprint when edit. go back is regarded as input editing.
    this.props.refreshFingerprint();
  };

  // @ts-expect-error TS7006
  onChangeHandler(value, key: keyof OnChangeField) {
    this.setState({
      onChangeField: { ...this.state.onChangeField, [key]: value },
    });
  }

  /**
   * 全角を半角に変換する
   */
  // @ts-expect-error TS7006
  convertFullWidthToHalfWidth = (str) => {
    return (
      str
        .replace(/-/g, "")
        .replace(/ー/g, "")
        // @ts-expect-error TS7006
        .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
        })
    );
  };

  /**
   * 入力チェック
   */
  getNotEnteredItems = () => {
    const notEnteredItems = [];
    const { t } = this.props;
    switch (this.state.currentState) {
      case "creditNew":
        if (!this.state.onChangeField.cardNumber) {
          notEnteredItems.push(t("settlement.credit.creditCardNumber"));
        }
        if (
          !this.state.onChangeField.cardExpireMonth ||
          !this.state.onChangeField.cardExpireYear
        ) {
          notEnteredItems.push(t("settlement.credit.cardExpirationDate"));
        }
        if (!this.state.onChangeField.cardHolderName) {
          notEnteredItems.push(t("settlement.credit.cardHolder"));
        }
        if (!this.state.onChangeField.cardSecurityCode) {
          notEnteredItems.push(t("settlement.credit.securityCode"));
        }
        break;
      case "creditMember":
        if (!this.state.onChangeField.cardSecurityCode) {
          notEnteredItems.push(t("settlement.credit.securityCode"));
        }
        break;
      case "cvs":
        if (!this.state.onChangeField.customerName) {
          notEnteredItems.push(t("settlement.cvs.name"));
        }
        if (!this.state.onChangeField.customerKana) {
          notEnteredItems.push(t("settlement.cvs.furigana"));
        }
        if (!this.state.onChangeField.telNo) {
          notEnteredItems.push(t("settlement.cvs.phoneNumber"));
        }
        break;
      default:
        break;
    }
    return notEnteredItems;
  };

  canPurchaseByCvs = (displayPrice: number): [boolean, CantUseCVSReason] => {
    // check if purchase amount is over the limit of cvs
    if (displayPrice >= Number(MAX_PURCHASE_AMOUNT_BY_CVS)) {
      return [false, "amountLimit"];
    }

    // can't use cvs in specific domain
    if (isToukenranbuDomain()) {
      // @ts-expect-error TS2322
      return [false, null];
    }

    // チケット販売期間中に決済を確定させるために、
    // チケット販売終了日の3日前(コンビニ支払い期間)からはクレカ決済のみとする
    for (const cartData of this.props.myCart) {
      // if goods only, ignore
      if (cartData.tickets.length === 0) {
        continue;
      }

      // exclude hidden datetime event
      if (IS_HIDE_DATETIME(cartData.eventId)) {
        continue;
      }

      // TODO@later goodsSellingTimeも。23:59:59設定なのでどうするか
      const event = this.props.eventMap[cartData.eventId];

      // if disabled purchase by cvs, return
      // @ts-expect-error TS2345
      if (DISABLED_PURCHASE_BY_CVS(event._id)) {
        return [false, "periodLimit"];
      }

      // if ar event, ignore
      // @ts-expect-error TS18048
      if (event.isRequiredArApp) {
        continue;
      }

      // if not vod ticket that is not expired only, ignore next check step
      const notVodTickets = cartData.tickets.filter((el) => isNotVodTicket(el));
      if (notVodTickets.length === 0) {
        continue;
      }

      // if free ticket is included, can't use cvs
      if (cartData.tickets.findIndex((el) => el.price_jpy <= 0) >= 0) {
        // @ts-expect-error TS2322
        return [false, null];
      }

      // @ts-expect-error TS18048
      const _d = new Date(event.datetime.seconds * 1000);
      const cvsPaymentCloseDate = new Date(
        _d.getFullYear(),
        _d.getMonth(),
        _d.getDate(),
        0,
        0,
        0
      );
      cvsPaymentCloseDate.setDate(
        cvsPaymentCloseDate.getDate() -
          DAY_PERIOD_OF_CVS_PAYMENT(cartData.eventId)
      );
      if (getNowTimestamp(cvsPaymentCloseDate) < getNowTimestamp()) {
        return [false, "periodLimit"];
      }
    }
    // @ts-expect-error TS2322
    return [true, null];
  };

  /**
   * check if can purchase by phone
   */
  canPurchaseByPhone = (
    displayPrice: number
  ): [boolean, CantUsePhoneReason] => {
    // check if purchase amount is over the limit of carrier payment
    if (displayPrice > Number(MAX_PURCHASE_AMOUNT_BY_PHONE)) {
      return [false, "amountLimit"];
    }
    // @ts-expect-error TS2322
    return [true, null];
  };

  /**
   * 支払い方法ごとに購入処理を行う
   * 無料決済のみpaymentMethodでの管理をやめる（突貫対応）
   */
  purchase = () => {
    if (
      this.props
        .purchase /** テナントグッズの購入functionが親から渡ってきてるなら */
    ) {
      // テナントグッズの購入処理をよぶ
      this.props.purchase(this.state.paymentMethod, this.state.onChangeField);
      return;
    }

    this.props.setIsPurchasing(true);
    /**
     * アナリティクス計測ようにeventIdを取得
     * カートの最初に入っているイベントのコンバージョンを取得する。
     * 複数のイベントのコンバージョン取得については今後検討
     */
    // @ts-expect-error TS2532
    const eid = this.props.myCart[0].eventId;
    const purchaseCartProducts = requestPurchaseCartProductsParam(
      this.props.myCart
    );
    const displayPrice =
      getItemTotal(this.props.myCart, "subTotal") +
      this.state.shippingFee +
      this.state.settlementFee +
      this.state.serviceFee;

    if (displayPrice <= 0) {
      const paymentInfo = {
        eid,
        displayPrice,
      };
      this.props.purchaseProductsWithFree({
        paymentInfo,
        cartProducts: purchaseCartProducts,
        gtm: {
          myCarts: this.props.myCart,
          totalPrice: displayPrice,
          shippingFee: this.state.shippingFee,
        },
      });
      return;
    }

    switch (this.state.paymentMethod) {
      case "creditNew": {
        const paymentInfo = {
          eid,
          cardNumber: this.state.onChangeField.cardNumber,
          cardExpireYear: this.state.onChangeField.cardExpireYear,
          cardExpireMonth: this.state.onChangeField.cardExpireMonth,
          cardSecurityCode: this.state.onChangeField.cardSecurityCode,
          cardHolderName: this.state.onChangeField.cardHolderName,
          displayPrice,
        };
        this.props.purchaseProductsWithCard({
          paymentInfo,
          cartProducts: purchaseCartProducts,
          gtm: {
            myCarts: this.props.myCart,
            totalPrice: displayPrice,
            shippingFee: this.state.shippingFee,
          },
        });
        break;
      }
      case "creditMember": {
        const paymentInfo = {
          eid,
          cardSecurityCode: this.state.onChangeField.cardSecurityCode,
          cardSeq:
            // @ts-expect-error TS2531
            this.props.cardInfoList[this.state.onChangeField.useCreditInfoIndex]
              .cardSeq,
          displayPrice,
        };
        this.props.purchaseProductsWithMemId({
          paymentInfo,
          cartProducts: purchaseCartProducts,
          gtm: {
            myCarts: this.props.myCart,
            totalPrice: displayPrice,
            shippingFee: this.state.shippingFee,
          },
        });
        break;
      }
      case "cvs": {
        const paymentInfo = {
          eid,
          cvesCode: this.state.onChangeField.cvsCode,
          customerName: this.state.onChangeField.customerName,
          customerKana: this.state.onChangeField.customerKana,
          telNo: this.state.onChangeField.telNo,
          displayPrice,
        };
        this.props.purchaseProductsWithCVS({
          paymentInfo,
          cartProducts: purchaseCartProducts,
          gtm: {
            myCarts: this.props.myCart,
            totalPrice: displayPrice,
            shippingFee: this.state.shippingFee,
          },
        });
        break;
      }
      case "phone": {
        const paymentInfo = {
          eid,
          phoneCode: this.state.onChangeField.phoneCode,
          displayPrice,
        };
        this.props.purchaseProductsWithPhone({
          paymentInfo,
          cartProducts: purchaseCartProducts,
          gtm: {
            myCarts: this.props.myCart,
            totalPrice: displayPrice,
            shippingFee: this.state.shippingFee,
          },
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  render() {
    const { t } = this.props;

    // テナントグッズ用の注文内容コンポーネント表示
    if (this.props.OrderDetails) {
      return (
        <Container id="contents">
          <BreadcrumbArea
            // @ts-expect-error TS2322
            paths={[
              ["/", "ホーム"],
              [this.props.toggleMyCart, t("common.routes.cart")],
              [null, t("common.routes.settlement")],
            ]}
          />
          {this.props.OrderDetails}
          {this.purchaseRenderSwitcher("stripe")}
        </Container>
      );
    }

    if (this.props.myCart.length === 0) {
      return <Redirect to="/" />;
    }

    if (this.state.shippingFee === null) {
      return (
        <div id="contents-layout">
          <LoaderLayout />
        </div>
      );
    }

    const totalPrice =
      getItemTotal(this.props.myCart, "subTotal") +
      this.state.shippingFee +
      this.state.settlementFee +
      this.state.serviceFee;
    const totalDicplayPrice = getItemTotal(
      this.props.myCart,
      "totalDisplayPrice"
    );
    const displayAddressCaution = hasAddressRequiredProduct(this.props.myCart);

    return (
      <Container id="contents">
        <BreadcrumbArea
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            [this.props.toggleMyCart, t("common.routes.cart")],
            [null, t("common.routes.settlement")],
          ]}
        />
        <div className="settlement_order_details">
          <div className={"settlement_order_heading"}>
            <h2>{t("settlement.contentsHeadline")}</h2>

            {!!this.props.shippingAtTitle && (
              <p className={"shippingAtTitle"}>
                {t("cart.box.scheduledShippingDate")}：
                {this.props.shippingAtTitle}
              </p>
            )}
          </div>

          <div className="contents">
            {this.props.myCart.map((eventProducts, index) => {
              const event = this.props.eventMap[eventProducts.eventId];
              const {
                eventTitle,
                eventOpenDateFull,
                eventOpenDay,
                isLongEvent,
                eventEndDateFull,
                // @ts-expect-error TS2345
              } = getEventDisplayInfo(event);
              const { tickets } = eventProducts;
              const { goods } = eventProducts;

              return (
                <div key={"order_item_" + index} className="order_item">
                  <img
                    src="/images/flame_line_top_bg_pc.svg"
                    alt=""
                    className="flame_pc"
                  />
                  <img
                    src="/images/flame_line_top_bg.svg"
                    alt=""
                    className="flame_sp"
                  />
                  <div className="order_item_layout">
                    <div className="item_info">
                      <p className="event">{eventTitle}</p>
                      <p className="date">
                        {t("settlement.eventDate")}：
                        {isLongEvent
                          ? `${eventOpenDateFull} ~ ${eventEndDateFull}`
                          : `${eventOpenDateFull}(${eventOpenDay})`}
                      </p>
                      {/* <p className="place">東京 池袋HUMAXシネマズ</p> */}
                    </div>
                    {tickets.length !== 0
                      ? tickets.map((item, index) => {
                          const productName = item.name;
                          const price = item.price_jpy.toLocaleString();
                          const count = item.count.toString();
                          const place = this.props.places?.find(
                            (el) => item.place === el.code
                          );

                          return (
                            // TODO@design
                            <div key={"ticket_info_" + index} className="item">
                              <div className="ticket_info_l">
                                <p className="heading">
                                  {t("settlement.ticket")}
                                </p>
                                {/* <p className="place">{place.areaName} {place.venueName}</p> */}
                                <p className="ticket">
                                  {!place?.selectablePlaces &&
                                    `【${[place?.areaName, place?.venueName]
                                      .filter((v) => !!v)
                                      .join(" ")}】`}
                                  {productName}
                                </p>
                                <p className="price">
                                  ¥{price}
                                  <span>({t("settlement.taxIncluded")})</span>
                                </p>
                              </div>
                              <div className="ticket_info_r">
                                <p className="num">
                                  <span>×</span>
                                  {count}
                                </p>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                    {goods.length !== 0
                      ? goods.map((item, index) => {
                          const productName = item.name;
                          const displayPriceInfo = getDisplayPriceInfo(item);
                          const price = item.price_jpy.toLocaleString();
                          const count = item.count.toString();

                          const place = this.props.places?.find(
                            (el) => item.place === el.code
                          );

                          return (
                            <div key={"goods_info_" + index} className="item">
                              <div className="goods_info_l">
                                <p className="heading">
                                  {t("settlement.goods")}
                                </p>
                                {/* <p className="place">{place?.areaName} {place?.venueName}</p> */}
                                <p className="goods">
                                  【
                                  {[place?.areaName, place?.venueName]
                                    .filter((v) => !!v)
                                    .join(" ")}
                                  】{productName}{" "}
                                  {item.subClassName &&
                                    `(${item.subClassName})`}
                                </p>
                                <p className="price">
                                  ¥{price}
                                  {displayPriceInfo.isDisplayOnly &&
                                    ` (会場支払い ¥${displayPriceInfo.price.toLocaleString()})`}
                                  <span>({t("settlement.taxIncluded")})</span>
                                </p>
                              </div>
                              <div className="goods_info_r">
                                <p className="num">
                                  <span>×</span>
                                  {count}
                                </p>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              );
            })}
            {/* TODO: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/anchor-is-valid.md */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="change_button" onClick={this.props.toggleMyCart}>
              {t("settlement.changeCart")}
            </a>
            {displayAddressCaution ? (
              <p className="address_caution">
                <I18nTrans i18nKey="settlement.addressCaution">
                  ※配送が必要な商品は、購入時点で
                  <TextLink href={"/account/address"}>こちら</TextLink>
                  に登録されている住所に配送されます。
                </I18nTrans>
              </p>
            ) : null}
            {this.state.shippingFee ? (
              <div className="shipping_fee">
                {
                  <p className="heading">
                    {t("settlement.shippingFee")}
                    {this.state.destinationType === "far" &&
                      `${t("settlement.shippingFeeWithFar")}`}
                  </p>
                }
                <p className="total">
                  ¥{this.state.shippingFee.toLocaleString()}
                  <span>({t("settlement.taxIncluded")})</span>
                </p>
              </div>
            ) : null}
            {this.state.serviceFee ? (
              <div className="shipping_fee">
                <p className="heading">{t("settlement.serviceFee")}</p>
                <p className="total">
                  ¥{this.state.serviceFee.toLocaleString()}
                  <span>({t("settlement.taxIncluded")})</span>
                </p>
              </div>
            ) : null}
            {this.state.settlementFee ? (
              <div className="shipping_fee">
                <p className="heading">{t("settlement.settlementFee")}</p>
                <p className="total">
                  ¥{this.state.settlementFee.toLocaleString()}
                  <span>({t("settlement.taxIncluded")})</span>
                </p>
              </div>
            ) : null}
            <div className="total_price">
              <p className="heading">{t("settlement.productTotal")}</p>
              <p className="total">
                ¥{totalPrice.toLocaleString()}
                <span>({t("settlement.taxIncluded")})</span>
              </p>
            </div>
            {totalDicplayPrice > 0 && (
              <div className="total_price">
                <p className="heading">会場お支払い合計</p>
                <p className="total">
                  ¥{totalDicplayPrice.toLocaleString()}
                  <span>({t("settlement.taxIncluded")})</span>
                </p>
              </div>
            )}
          </div>
        </div>

        {this.purchaseRenderSwitcher(totalPrice <= 0 ? "free" : false)}
      </Container>
    );
  }

  purchaseRenderSwitcher = (state: "free" | "stripe" | false) => {
    // 状態によってrender切り替え
    if (state === "free") {
      return this.confirmFreePurchase();
    }

    if (state === "stripe") {
      return this.confirmStripePurchase();
    }

    switch (this.state.currentState) {
      case "creditMember":
      case "creditNew":
        return this.creditPurchase();
      case "cvs":
        return this.cvsPurchase();
      case "creditConfirm":
        return this.confirmCreditPurchase();
      case "cvsConfirm":
        return this.confirmCvsPurchase();
      case "phone":
        return this.phonePurchase();
      case "phoneConfirm":
        return this.confirmPhonePurchase();
      default:
        return this.selectorPaymentMethod();
    }
  };

  selectorPaymentMethod = () => {
    const { t } = this.props;
    const creditMethod: PurchaseState = this.state.isRegisteredCard
      ? "creditMember"
      : "creditNew";
    const displayPrice = getItemTotal(this.props.myCart, "subTotal");
    const [canPurchaseByCvs, reason] = this.canPurchaseByCvs(displayPrice);
    const [canPurchaseByPhone, phoneReason] =
      this.canPurchaseByPhone(displayPrice);

    return (
      <ContentsLayout className="settlement_01_details">
        <h2>{t("settlement.paymentMethod.headline")}</h2>
        <div className="form_layout">
          <div className="form">
            {!canPurchaseByCvs && reason && <CantUseCVS reason={reason} />}
            {!canPurchaseByPhone && phoneReason && (
              <CantUsePhone reason={phoneReason} />
            )}
          </div>
          <label className="checkbox_layout">
            <input
              id="place"
              name="place"
              type="radio"
              defaultChecked={true}
              onClick={() =>
                this.setIsPurchasing(this.state.currentState, creditMethod)
              }
            />
            <span className="checkbox">
              <span className="label_text">
                <span>{t("settlement.paymentMethod.credit")}</span>
              </span>
            </span>
          </label>
          {canPurchaseByCvs ? (
            <label className="checkbox_layout">
              <input
                id="place"
                name="place"
                type="radio"
                onClick={() =>
                  this.setIsPurchasing(this.state.currentState, "cvs")
                }
              />
              <span className="checkbox">
                <span className="label_text">
                  <span>{`${t("settlement.paymentMethod.cvs")}（${t(
                    "settlement.settlementFee"
                  )} ¥${SETTLEMENT_FEE_DATA["CVS"]}）`}</span>
                </span>
              </span>
            </label>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          {canPurchaseByPhone ? (
            <label className="checkbox_layout">
              <input
                id="place"
                name="place"
                type="radio"
                onClick={() =>
                  this.setIsPurchasing(this.state.currentState, "phone")
                }
              />
              <span className="checkbox">
                <span className="label_text">
                  <span>{`${t("settlement.paymentMethod.phone")}（${t(
                    "settlement.settlementFee"
                  )} ¥${SETTLEMENT_FEE_DATA["Phone"]}）`}</span>
                </span>
              </span>
            </label>
          ) : null}
        </div>
        <SettlementButtonList>
          <LinkButton onClick={this.goNext}>{t("settlement.next")}</LinkButton>
        </SettlementButtonList>
      </ContentsLayout>
    );
  };

  creditPurchase = () => {
    const { t } = this.props;
    if (this.state.isRegisteredCard === null) {
      return <BorderLoader />;
    }
    return (
      <ContentsLayout className="settlement_03_details">
        <h2>{t("settlement.paymentMethod.headline")}</h2>
        <div className="select_payment form_layout">
          <p>一括{/* <span>編集</span>*/}</p>
        </div>
        {/*
         // @ts-expect-error TS2531 */}
        {this.props.cardInfoList.length === 0 ? (
          <div>
            <h2>{t("settlement.credit.cardSelection")}</h2>
            <div className="form_layout">
              <p className="text">
                <I18nTrans i18nKey="settlement.credit.noneCreditCaution">
                  登録されているカードがありません。
                  <br />
                  <Link to="/account/credit">こちら</Link>
                  からカード登録をお願いします。
                </I18nTrans>
              </p>
            </div>
          </div>
        ) : (
          <div>
            <h2>{t("settlement.credit.cardSelection")}</h2>
            <div className="form_layout">
              <label className="checkbox_layout">
                <input
                  id="place"
                  name="place"
                  type="radio"
                  defaultChecked={this.state.currentState === "creditMember"}
                  onClick={() =>
                    this.setIsPurchasing("creditMember", "creditConfirm")
                  }
                />
                <span className="checkbox">
                  <span className="label_text">
                    <span>{t("settlement.credit.registeredCreditCard")}</span>
                  </span>
                </span>
              </label>
              <label className="checkbox_layout">
                <input
                  id="place"
                  name="place"
                  type="radio"
                  defaultChecked={this.state.currentState === "creditNew"}
                  onClick={() =>
                    this.setIsPurchasing("creditNew", "creditConfirm")
                  }
                />
                <span className="checkbox">
                  <span className="label_text">
                    <span>{t("settlement.credit.newCreditCard")}</span>
                  </span>
                </span>
              </label>
            </div>
          </div>
        )}
        {this.state.currentState === "creditMember" ? (
          <div>
            <h2>{t("settlement.credit.cardInfo")}</h2>
            <div className="form_layout">
              <div className="form">
                <p>{t("settlement.credit.usedCreditCard")}</p>
                <select
                  onChange={(e) =>
                    this.onChangeHandler(
                      Number(e.currentTarget.value),
                      "useCreditInfoIndex"
                    )
                  }
                >
                  {/*
                   // @ts-expect-error TS2531 */}
                  {this.props.cardInfoList.map((cardInfo, i) => {
                    return (
                      <option key={i} value={i}>
                        {cardInfo.cardNumber}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form">
                <p>{t("settlement.credit.securityCode")}</p>
                <input
                  type="password"
                  name="code"
                  placeholder="000"
                  onChange={(e) =>
                    this.onChangeHandler(
                      e.currentTarget.value,
                      "cardSecurityCode"
                    )
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h2>{t("settlement.credit.cardInfo")}</h2>
            <div className="form_layout">
              <div className="form">
                <p>{t("settlement.credit.creditCardNumber")}</p>
                <input
                  type="text"
                  name="credit_num"
                  defaultValue={this.state.onChangeField.cardNumber}
                  onChange={(e) =>
                    this.onChangeHandler(e.currentTarget.value, "cardNumber")
                  }
                />
              </div>
              <div className="credit_datetime form">
                <p>{t("settlement.credit.cardExpirationDate")}</p>
                <input
                  type="text"
                  name="month"
                  defaultValue={this.state.onChangeField.cardExpireMonth}
                  onChange={(e) =>
                    this.onChangeHandler(
                      e.currentTarget.value,
                      "cardExpireMonth"
                    )
                  }
                />
                <input
                  type="text"
                  name="year"
                  defaultValue={this.state.onChangeField.cardExpireYear}
                  onChange={(e) =>
                    this.onChangeHandler(
                      e.currentTarget.value,
                      "cardExpireYear"
                    )
                  }
                />
              </div>
              <div className="credit_name form">
                <p>{t("settlement.credit.cardHolder")}</p>
                <input
                  type="text"
                  name="name"
                  defaultValue={this.state.onChangeField.cardHolderName}
                  onChange={(e) =>
                    this.onChangeHandler(
                      e.currentTarget.value,
                      "cardHolderName"
                    )
                  }
                />
              </div>
              <div className="form">
                <p>{t("settlement.credit.securityCode")}</p>
                <input
                  type="password"
                  name="code"
                  onChange={(e) =>
                    this.onChangeHandler(
                      e.currentTarget.value,
                      "cardSecurityCode"
                    )
                  }
                />
              </div>
              {/* <div className="credit_register form">
                    <label>
                      <input type="checkbox" name="register"
                        defaultValue={this.state.onChangeField.checkCardRegister ? '1' : '0'}
                        defaultChecked={this.state.onChangeField.checkCardRegister}
                        onChange={(e) => this.onChangeHandler(!this.state.onChangeField.checkCardRegister, 'checkCardRegister')}/>
                      <span>このカードをアカウントに登録する</span>
                    </label>
                  </div> */}
            </div>
          </div>
        )}
        <SettlementButtonList>
          <LinkButton onClick={this.goNext}>
            {t("settlement.confirm")}
          </LinkButton>
          <LinkButton className="back" onClick={this.goBack}>
            {t("settlement.back")}
          </LinkButton>
        </SettlementButtonList>
      </ContentsLayout>
    );
  };

  confirmCreditPurchase = () => {
    const { t } = this.props;
    let creditInfo = null;
    if (this.state.paymentMethod === "creditMember") {
      creditInfo =
        // @ts-expect-error TS2531
        this.props.cardInfoList[this.state.onChangeField.useCreditInfoIndex];
    } else {
      creditInfo = this.state.onChangeField;
    }
    // @ts-expect-error TS2339
    const { cardNumber, cardExpireMonth, cardExpireYear, cardHolderName } =
      creditInfo;

    return (
      <ContentsLayout className="settlement_06_details">
        <div className="form_layout">
          <h2>{t("settlement.paymentMethod.headline")}</h2>
          <p>{t("settlement.credit.lumpSum")}</p>
        </div>
        <div className="form_layout">
          <h2>{t("settlement.credit.creditCardNumber")}</h2>
          <p className="font_din">{cardNumber}</p>
        </div>
        <div className="form_layout">
          <h2>{t("settlement.credit.cardExpirationDate")}</h2>
          <p className="font_din">
            {cardExpireMonth}/{cardExpireYear}
          </p>
        </div>
        <div className="form_layout">
          <h2>{t("settlement.credit.cardHolder")}</h2>
          <p className="font_din">{cardHolderName}</p>
        </div>
        {/* {
          checkCardRegister
            ?
              <div className="form_layout">
                <h2>カード登録</h2>
                <p>このカードをアカウントに登録する</p>
              </div>
            : ''
        } */}

        <PurchaseButton
          isChecked={this.state.isChecked}
          isPurchasing={this.props.isPurchasing}
          handleChange={this.handleChange}
          purchase={this.purchase}
          goBack={this.goBack}
        />
      </ContentsLayout>
    );
  };

  cvsPurchase = () => {
    const { t } = this.props;
    return (
      <ContentsLayout className="settlement_02_details">
        <div className="form_layout">
          <h2>{t("settlement.cvs.usedCVS")}</h2>
          <select
            defaultValue={this.state.onChangeField.cvsCode}
            onChange={(e) =>
              this.onChangeHandler(e.currentTarget.value, "cvsCode")
            }
          >
            {Object.keys(cvsInfo()).map((el, i) => {
              return (
                <option key={i} value={el}>
                  {/*
                   // @ts-expect-error TS7053 */}
                  {cvsInfo()[el]}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form_layout">
          <h2>{t("settlement.cvs.pleaseEnter")}</h2>
          <div className="form">
            <p>{t("settlement.cvs.name")}</p>
            <input
              type="text"
              name="name"
              defaultValue={this.state.onChangeField.customerName}
              onChange={(e) =>
                this.onChangeHandler(e.currentTarget.value, "customerName")
              }
            />
          </div>
          <div className="form">
            <p>{t("settlement.cvs.furigana")}</p>
            <input
              type="text"
              name="name_f"
              defaultValue={this.state.onChangeField.customerKana}
              onChange={(e) =>
                this.onChangeHandler(e.currentTarget.value, "customerKana")
              }
            />
          </div>
          <div className="form">
            <p>{t("settlement.cvs.phoneNumber")}</p>
            <input
              type="text"
              name="tel"
              pattern="\d*"
              defaultValue={this.state.onChangeField.telNo}
              onChange={(e) =>
                this.onChangeHandler(e.currentTarget.value, "telNo")
              }
            />
          </div>
        </div>
        <SettlementButtonList>
          <LinkButton onClick={this.goNext}>
            {t("settlement.confirm")}
          </LinkButton>
          <LinkButton className="back" onClick={this.goBack}>
            {t("settlement.back")}
          </LinkButton>
        </SettlementButtonList>
      </ContentsLayout>
    );
  };

  phonePurchase = () => {
    const { t } = this.props;
    return (
      <ContentsLayout className="settlement_02_details">
        <div className="form_layout">
          <h2 style={{ lineHeight: "1.3em" }}>
            {t("settlement.phone.cautions.first")}
          </h2>
          <p style={{ lineHeight: "1.5em" }}>
            {t("settlement.phone.cautions.second", {
              hour: HOUR_UNTIL_PURCHASE_PHONE_CANCELED,
            })}
          </p>
          <p style={{ lineHeight: "1.5em", color: "red" }}>
            {t("settlement.phone.cautions.third")}
          </p>
        </div>
        <div className="form_layout">
          <h2>{t("settlement.phone.usedPhone")}</h2>
          <select
            defaultValue={this.state.onChangeField.phoneCode}
            onChange={(e) =>
              this.onChangeHandler(e.currentTarget.value, "phoneCode")
            }
          >
            {Object.keys(phoneInfo()).map((el, i) => {
              return (
                <option key={i} value={el}>
                  {/*
                   // @ts-expect-error TS7053 */}
                  {phoneInfo()[el]}
                </option>
              );
            })}
          </select>
        </div>
        <SettlementButtonList>
          <LinkButton onClick={this.goNext}>
            {t("settlement.confirm")}
          </LinkButton>
          <LinkButton className="back" onClick={this.goBack}>
            {t("settlement.back")}
          </LinkButton>
        </SettlementButtonList>
      </ContentsLayout>
    );
  };

  confirmCvsPurchase = () => {
    const { t } = this.props;
    const { cvsCode, customerName, customerKana, telNo } =
      this.state.onChangeField;

    return (
      <ContentsLayout className="settlement_06_details">
        <div className="form_layout">
          <h2>{t("settlement.cvs.usedCVS")}</h2>
          <p className="font_din">{cvsInfo()[cvsCode]}</p>
        </div>
        <div className="form_layout">
          <h2>{t("settlement.cvs.name")}</h2>
          <p className="font_din">{customerName}</p>
        </div>
        <div className="form_layout">
          <h2>{t("settlement.cvs.furigana")}</h2>
          <p className="font_din">{customerKana}</p>
        </div>
        <div className="form_layout">
          <h2>{t("settlement.cvs.phoneNumber")}</h2>
          <p className="font_din">{telNo}</p>
        </div>

        <PurchaseButton
          isChecked={this.state.isChecked}
          isPurchasing={this.props.isPurchasing}
          handleChange={this.handleChange}
          purchase={this.purchase}
          goBack={this.goBack}
        />
      </ContentsLayout>
    );
  };

  confirmPhonePurchase = () => {
    const { t } = this.props;
    // @ts-expect-error TS7053
    const methodPayment = phoneInfo()[this.state.onChangeField.phoneCode];
    const methodPaymentText =
      this.state.onChangeField.phoneCode === "dpay"
        ? `${methodPayment}（${t("purchase.caution.docomo")}）`
        : methodPayment;

    return (
      <ContentsLayout className="settlement_02_details">
        <div className="form_layout">
          <h2 style={{ lineHeight: "1.3em" }}>
            {t("settlement.phone.cautions.first")}
          </h2>
          <p style={{ lineHeight: "1.5em" }}>
            {t("settlement.phone.cautions.second", {
              hour: HOUR_UNTIL_PURCHASE_PHONE_CANCELED,
            })}
          </p>
          <p style={{ lineHeight: "1.5em", color: "red" }}>
            {t("settlement.phone.cautions.third")}
          </p>
        </div>
        <div className="form_layout">
          <h2>{t("settlement.phone.usedPhone")}</h2>
          <p style={{ lineHeight: "1.5em" }}>{methodPaymentText}</p>
        </div>

        <PurchaseButton
          isChecked={this.state.isChecked}
          isPurchasing={this.props.isPurchasing}
          handleChange={this.handleChange}
          purchase={this.purchase}
          goBack={this.goBack}
        />
      </ContentsLayout>
    );
  };

  confirmFreePurchase = () => {
    return (
      <ContentsLayout className="settlement_02_details">
        <PurchaseButton
          isChecked={this.state.isChecked}
          isPurchasing={this.props.isPurchasing}
          handleChange={this.handleChange}
          purchase={this.purchase}
          goBack={this.props.toggleMyCart}
        />
      </ContentsLayout>
    );
  };

  confirmStripePurchase = () => {
    const { t } = this.props;
    return (
      <ContentsLayout className="settlement_02_details">
        <PurchaseButton
          submitBtnText={t("settlement.startPaymentProcess")}
          isChecked={this.state.isChecked}
          isPurchasing={this.props.isPurchasing}
          handleChange={this.handleChange}
          purchase={this.purchase}
          goBack={this.props.toggleMyCart}
        />
      </ContentsLayout>
    );
  };
}

const mapStateToProps = (state: Store) => {
  return {
    user: state.auth.user,
    eventMap: state.event.userRelatedEventMap,
    cardInfoList: state.purchase.cardInfoList,
    isPurchasing: state.purchase.isPurchasing,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    // @ts-expect-error TS7006
    changeRoute: (link) => {
      dispatch(push(link));
    },
    toggleMyCart: () => {
      dispatch(cartActions.toggleMyCart({}));
    },
    // @ts-expect-error TS7006
    addLoginAction: (action) => {
      dispatch(authActions.addLoginAction(action));
    },
    fetchCardInfo: () => {
      dispatch(purchaseActions.fetchCardInfo.started({}));
    },
    // @ts-expect-error TS7006
    purchaseProductsWithCard: (payload) => {
      dispatch(purchaseActions.purchaseProductsWithCard.started(payload));
    },
    // @ts-expect-error TS7006
    purchaseProductsWithMemId: (payload) => {
      dispatch(purchaseActions.purchaseProductsWithMemId.started(payload));
    },
    // @ts-expect-error TS7006
    purchaseProductsWithCVS: (payload) => {
      dispatch(purchaseActions.purchaseProductsWithCVS.started(payload));
    },
    // @ts-expect-error TS7006
    purchaseProductsWithPhone: (payload) => {
      dispatch(purchaseActions.purchaseProductsWithPhone.started(payload));
    },
    // @ts-expect-error TS7006
    purchaseProductsWithFree: (payload) => {
      dispatch(purchaseActions.purchaseProductsWithFree.started(payload));
    },
    // @ts-expect-error TS7006
    toggleNotice: (msgObj) => {
      dispatch(modalActions.toggleNotice(msgObj));
    },
    // @ts-expect-error TS7006
    toggleActionModal: (msgObj) => {
      dispatch(modalActions.toggleActionModal(msgObj));
    },
    setIsPurchasing: (payload: boolean) => {
      dispatch(purchaseActions.setIsPurchasing(payload));
    },
    clearSettlementState: () => {
      dispatch(purchaseActions.clearStateByKey("isPurchasing"));
    },
    refreshFingerprint: () => {
      dispatch(purchaseActions.refreshFingerprint());
    },
  };
};

const TransSettlement = withTranslation()(Settlement);

export const SettlementWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(TransSettlement);

const SettlementComponent = () => {
  return InjectCartToSettlement(SettlementWithConnect);
};

export default SettlementComponent;

/**
 * prepare the purchase product parameters for payment.
 * @param myCart
 */
const requestPurchaseCartProductsParam = (
  myCart: MyCartData[]
): { id: string }[] => {
  if (myCart.length === 0) return [];
  // @ts-expect-error TS2322
  return myCart
    .map((cartData) => {
      return cartData.tickets.concat(cartData.goods).map((product) => ({
        id: product._id,
      }));
    })
    .flat();
};

/**
 * check if not vod ticket that is not expired only
 * @param cartProduct
 */
const isNotVodTicket = (cartProduct: UserCartProduct) => {
  const nowTimestamp = getNowTimestamp();
  if (isLiveTicket(cartProduct)) {
    // check expiredAt / (delete: buffer is 3*24*60*60 = 3 days) bufferいらない気がしてきたので。
    return cartProduct.expiredDateTime.seconds < nowTimestamp;
  } else if (isArchiveTicket(cartProduct)) {
    // check expiredAt / buffer is vodActiveHours
    return false;
  }
  return true;
};

/**
 * check if user will purchase address required ticket
 * @param myCart
 */
const hasAddressRequiredProduct = (myCart: MyCartData[]) => {
  for (const cartData of myCart) {
    const products = cartData.tickets.concat(cartData.goods);
    for (const product of products) {
      if (product.isAddressRequired || product.isAddressRequiredNoFee) {
        return true;
      }
    }
  }
  return false;
};

/**
 * check if user will purchase address required ticket
 * @param myCart
 */
const isShippingFeeRequired = (myCart: MyCartData[]) => {
  for (const cartData of myCart) {
    const products = cartData.tickets.concat(cartData.goods);
    for (const product of products) {
      if (product.isAddressRequired) {
        return true;
      }
    }
  }
  return false;
};

const calcTotalServiceFee = (myCart: MyCartData[]) => {
  if (myCart.length === 0) {
    return;
  }
  return myCart
    .map((cartData) => {
      return cartData.tickets
        .concat(cartData.goods)
        .map((product) => {
          if (!product.serviceFee) {
            return 0;
          }
          return product.serviceFee * product.count;
        })
        .reduce((a, b) => Number(a) + Number(b));
    })
    .reduce((prev, cur) => Number(prev) + Number(cur));
};
