/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useCallback, useEffect } from "react";
import type { Product } from "../hooks/cart/Cart";
import ErrorIcon from "@material-ui/icons/Error";
import { useDeleteItemFromCart } from "./useDeleteItemFromCart";
import { useDispatch } from "react-redux";
import { useUserCart } from "./useUserCart";

export const RemovedCartItemsMessage = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { data, isValidating } = useUserCart();
  const { deleteItem } = useDeleteItemFromCart();

  const deleteFromCart = useCallback(
    async (product: Product) => {
      // @ts-expect-error TS2345
      await deleteItem(product.cartItemId);
    },
    [dispatch, deleteItem] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    return () => {
      // @ts-expect-error TS18048
      if (data.removedCartItems.length > 0) {
        /* eslint-disable array-callback-return */
        // @ts-expect-error TS18048
        data.removedCartItems.map((item) => {
          deleteFromCart(item);
        });
        /* eslint-enable */
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    isValidating ||
    data === undefined ||
    data.removedCartItems.length === 0
  ) {
    return null;
  }

  return (
    <div css={classes.error}>
      <div css={classes.errorHeading}>
        <ErrorIcon />
        <p>カートに入っている商品に関する重要なお知らせ</p>
      </div>

      <ul css={classes.errorItem}>
        {data.removedCartItems.map((item) => {
          return (
            <li key={item.name}>
              ・
              <a
                // @ts-expect-error TS2532
                href={`${item.links[0].url}/goods/${item.cartItemId}`}
                target="_blank"
                rel="noreferrer"
              >
                {item.name}
                {item.subClassName && `- ${item.subClassName}`}
              </a>
              は
              {item.status === "soldOut"
                ? "在庫切れのためカートから削除されました。"
                : item.status === "outOfStock"
                ? "在庫不足のためカートから削除されました。"
                : "販売期間外のためカートから削除されました。"}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const styles = () => ({
  error: css`
    padding: 5%;
    margin-bottom: 24px;
    border: 3px solid #ff0000;
    @media screen and (min-width: 768px) {
      padding: 24px;
    }
  `,
  errorHeading: css`
    display: grid;
    grid-template-columns: 24px auto;
    gap: 8px;
    margin-bottom: 16px;
    @media screen and (min-width: 768px) {
      align-items: center;
    }
    svg {
      fill: #ff0000;
    }
    p {
      color: #ff0000;
      font-weight: bold;
      line-height: 1.3em;
      letter-spacing: 1px;
    }
  `,
  errorItem: css`
    li {
      padding-left: 1em;
      font-size: 14px;
      font-weight: bold;
      text-indent: -1em;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
    a {
      color: #00c2ad;
    }
  `,
});
