/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "../../store";

import { eventActions, DownloadContents } from "../../modules/event";

import { fetchFirestoreDocument } from "../../utility/firebase";
import { getEventIdFromHref, convertMapToValuesWithId } from "../../utility";

import { Card, CardContent, IconButton, Typography } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import type { DlcInfo, DlcItem } from "@spwn/types/firebase/firestore";

export const StreamingDownload: React.FC = () => {
  const dispatch = useDispatch();
  const pathname = useSelector(
    (state: Store) => state.router.location.pathname
  );
  const fileDownloadInfo: DownloadContents = useSelector(
    (state: Store) => state.event.fileDownloadInfo
  );
  // @ts-expect-error TS2345
  const [eventId, setEventId] = useState<string>(null);

  // @ts-expect-error TS2345
  const [dlcItems, setDlcItems] = useState<DlcItem[]>(null);
  // @ts-expect-error TS2345
  const [selectedDlcItem, setSelectedDlcItem] = useState<DlcItem>(null);

  const [isDisplayModal, setDisplayModal] = useState<boolean>(false);
  // const [selectedDlcId, setSelectedDlcId] = useState<string>(null)

  const classesPopupDesign = popupDesign();
  const classesDisplayInfoToDownload = displayInfoToDownloadDesign();

  const didMount = () => {
    // @ts-expect-error TS2322
    const getEventId: string = getEventIdFromHref(pathname, "events");
    setEventId(getEventId);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(didMount, []);

  useEffect(() => {
    if (eventId) {
      getDownloadContens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const getDownloadContens = async () => {
    const dlcItemMap = await fetchFirestoreDocument<DlcInfo>(`/DLC/${eventId}`);
    const dlcData = convertMapToValuesWithId(dlcItemMap);
    const dlcItems = dlcData.filter(
      (el) => el._id?.indexOf("content") !== -1
    ) as DlcItem[];
    setDlcItems(dlcItems);
  };

  // @ts-expect-error TS7006
  const createDownloadLink = (dlcId, dlcInfo) => {
    setSelectedDlcItem(dlcInfo);
    const payload = {
      eid: eventId,
      cid: dlcId, //content1 ~ content3
    };
    dispatch(eventActions.fileDownload.started(payload));
    setDisplayModal(true);
  };

  const initialize = () => {
    setDisplayModal(false);
    // @ts-expect-error TS2345
    setSelectedDlcItem(null);
    dispatch(eventActions.clearStateByKey("fileDownloadInfo"));
  };

  if (!dlcItems || dlcItems.length === 0) {
    return null;
  }

  return (
    <div css={classesDisplayInfoToDownload.root}>
      <h3>チャリティダウンロード</h3>
      <ul>
        <li>
          ※スマートフォン・タブレットでもお楽しみいただけますが、iOS（iPhone/iPad等）をお使いの方はPCからダウンロードの上、iTunes等よりお楽しみください。
        </li>
        <li>
          ※フィーチャーフォンには対応しておりませんので、PCをご利用ください。
        </li>
        <li>
          ※各種再生用プレーヤーの使用方法についてご不明の点は、各種ソフトのヘルプ等をご確認ください。
        </li>
        <li>
          ※サイトアクセスならびに、ファイルダウンロード等にかかる通信費はお客様のご負担となります。
        </li>
      </ul>
      <div>
        {dlcItems.length !== 0
          ? dlcItems
              .filter((el) => el._id?.indexOf("content") !== -1)
              .sort((a: DlcItem, b: DlcItem) => Number(a.id) - Number(b.id))
              .map((dlc: DlcItem, index) => {
                return (
                  <Card
                    css={classesDisplayInfoToDownload.cardDesignRoot}
                    key={index}
                  >
                    <div css={classesDisplayInfoToDownload.cardDesignDetails}>
                      <CardContent
                        css={classesDisplayInfoToDownload.cardDesignContent}
                      >
                        <Typography component="h5" variant="h5">
                          {dlc.name}
                        </Typography>
                      </CardContent>
                      <div
                        css={classesDisplayInfoToDownload.cardDesignControls}
                      >
                        <IconButton
                          aria-label="download"
                          onClick={() =>
                            createDownloadLink(
                              "content" + Number(index + 1),
                              dlc
                            )
                          }
                        >
                          <GetAppIcon />
                        </IconButton>
                      </div>
                    </div>
                  </Card>
                );
              })
          : null}
      </div>
      {isDisplayModal === true ? (
        <div css={classesPopupDesign.root}>
          <div css={classesPopupDesign.rootInner}>
            {/* 応援PAWの選択で表示を変える */}
            <div css={classesPopupDesign.dlcInfo}>
              {/* <img src={eventInfo.thumbnail} alt={eventInfo.title}/> */}
              <h3 className="contents" style={{ whiteSpace: "pre-line" }}>
                {selectedDlcItem.name}
              </h3>
              <p className="artist">{selectedDlcItem.artist}</p>
              <p className="fileInfo">
                ファイル形式：{selectedDlcItem.extension}
              </p>
              <p className="fileInfo">
                ファイルサイズ：{selectedDlcItem.fileSize}
              </p>
            </div>

            <div css={classesPopupDesign.downloadButton}>
              {fileDownloadInfo ? (
                <p>
                  <a
                    href={fileDownloadInfo.url}
                    download
                    onClick={() => {
                      initialize();
                    }}
                  >
                    ダウンロード
                  </a>
                </p>
              ) : (
                <p>ダウンロードリンク作成中...</p>
              )}
            </div>

            <div
              css={classesPopupDesign.canselButton}
              onClick={() => {
                setDisplayModal(false);
                initialize();
              }}
            >
              <p>キャンセル</p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

interface PopupDesignProps {}
const popupDesign = (_props?: PopupDesignProps) => {
  return {
    root: css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 2000;
    `,
    rootInner: css`
      width: 90%;
      padding: 30px 5%;
      margin: 60px auto 0;
      background-color: #fff;
      overflow-y: scroll;
      @media screen and (min-width: 768px) {
        width: 550px;
        height: auto;
        padding: 50px 2.5%;
        overflow-y: auto;
      }
    `,
    dlcInfo: css`
      margin-bottom: 40px;
      h3 {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 1.3em;
        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }
      .artist {
        margin-bottom: 20px;
        font-weight: bold;
      }
      .fileInfo {
        color: gray;
        font-size: 14px;
        text-align: right;
        line-height: 1.5em;
      }
    `,
    downloadButton: css`
      width: 100%;
      max-width: 90%;
      margin: 0 auto 20px;
      text-align: center;
      background-color: #00c2ae;
      border: 2px solid #00c2ae;
      cursor: pointer;
      p {
        padding: 11px 0;
        color: #fff;
        font-weight: bold;
        a {
          display: block;
          color: #fff;
        }
      }
    `,
    canselButton: css`
      width: 100%;
      max-width: 90%;
      padding: 11px 0;
      margin: 0 auto;
      text-align: center;
      background-color: #fff;
      border: 2px solid #00c2ae;
      cursor: pointer;
      p {
        color: #00c2ae;
        font-weight: bold;
      }
    `,
  };
};

interface DisplayInfoToDownloadDesign {}
const displayInfoToDownloadDesign = (_props?: DisplayInfoToDownloadDesign) => {
  return {
    root: css`
      padding: 0 5%;
      margin: 30px 0;
      @media screen and (min-width: 768px) {
        padding: 0;
      }
      h3 {
        margin-bottom: 15px;
      }
      ul {
        margin-bottom: 15px;
      }
      li {
        padding-left: 1em;
        font-size: 12px;
        line-height: 1.5em;
        text-indent: -1em;
        letter-spacing: 1px;
      }
    `,
    cautionText: css`
      margin-bottom: 40px;
      p {
        padding-left: 1em;
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 1.5em;
        text-indent: -1em;
        letter-spacing: 1px;
      }
    `,
    cardDesignLayout: css`
      display: flex;
      flex-wrap: wrap;
    `,
    cardDesignRoot: css`
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 15px;
      @media screen and (min-width: 768px) {
        width: calc(50% - 50px);
        margin-right: 25px;
      }
    `,
    cardDesignDetails: css`
      display: flex;
      width: 100%;
    `,
    cardDesignContent: css`
      flex: 1 0 auto;
      width: calc(100% - 50px);
      h5 {
        display: box;
        height: 1em;
        /* margin-bottom: 5px; */
        font-size: 16px;
        font-weight: bold;
        /* stylelint-disable-next-line property-no-unknown */
        box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 1;
      }
      h6 {
        font-size: 16px;
      }
    `,
    cardDesignCover: css`
      width: 151px;
    `,
    cardDesignControls: css`
      display: flex;
      align-items: center;
      width: 50px;
    `,
    cardDesignPlayIcon: css`
      height: 38px;
      width: 38px;
    `,
  };
};
