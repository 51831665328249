import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { purchaseActions } from "modules/purchase";
import { authActions } from "modules/auth";
import { modalActions } from "modules/modal";
import {
  getCommaStyleNumber,
  getSlashSeparatedDateStyle,
  replaceBr,
} from "utility";
import { BreadcrumbArea } from "components/common/Link";
import { useI18n } from "hooks/i18n/i18n";
import { SubContents } from "components/templates/SubContents";
import styled from "@emotion/styled";
import {
  breadcrumbMixin,
  myPageHistoryMixin,
  myPagePawHistoryMixin,
  AccountPawInfo,
  Paw,
  PawHistoryListPaw,
} from "../../styles";
interface Histories {
  [key: string]: {
    eid: string;
    itemId: string;
    usageFreeValue: number;
    usagePaidValue: number;
  };
}

const Container = styled.div(
  myPageHistoryMixin,
  myPagePawHistoryMixin,
  breadcrumbMixin
);

export const AccountPawUseHistory: React.FC = () => {
  const pawBalance = useSelector((state: Store) => {
    return state.purchase.emoBalance.paidValue === undefined
      ? 0
      : getCommaStyleNumber(state.purchase.emoBalance.paidValue);
  });
  const pawHistoryContainer = useSelector(
    (state: Store) => state.purchase.pawUsageHistoryContainer
  );
  const pawItemList = useSelector((state: Store) => state.purchase.pawItemList);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(purchaseActions.getPAWItemList.started());
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.getPAWItemList.started,
        args: null,
      })
    );
    dispatch(purchaseActions.getEmoBalance.started());
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.getEmoBalance.started,
        args: null,
      })
    );
    dispatch(purchaseActions.getPAWUsageHistory.started({ end: null }));
    dispatch(
      authActions.addLoginAction({
        action: purchaseActions.getPAWUsageHistory.started,
        args: { end: null },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let end = String(Number.MAX_SAFE_INTEGER);

  // const reloadHistory = () =>{
  //   dispatch(purchaseActions.getPAWUsageHistory.started({end: end as any}))
  // }

  const historyContents = (histories: Histories) => {
    if (
      pawItemList === undefined ||
      pawItemList == null ||
      Object.keys(pawItemList).length === 0
    ) {
      dispatch(purchaseActions.getPAWItemList.started());
      return <div />;
    }
    return Object.entries(histories)
      .sort(([a_key, _a_val], [b_key, _b_val]) => {
        return -(Number(a_key) - Number(b_key));
      })
      .map(([history_key, history]) => {
        end = Number(end) < Number(history_key) ? end : history_key;
        if (!history.hasOwnProperty("eid") || !history.hasOwnProperty("itemId"))
          return <div />;
        const purchaseDate = new Date(Number(history_key));
        const { usagePaidValue } = history;

        return (
          <div key={`pawUsage_${history_key}`} className="paw_history_list">
            <div className="paw_history_content">
              <p className="purchase">
                {getSlashSeparatedDateStyle(purchaseDate)}
              </p>
              <PawHistoryListPaw>
                -{getCommaStyleNumber(usagePaidValue)}
              </PawHistoryListPaw>
              <p className="expiration">
                {/*
                 // @ts-expect-error TS7053 */}
                {replaceBr(pawItemList[history.itemId].name)}
              </p>
            </div>
          </div>
        );
      });
  };

  const togglePawChargeModal = () => {
    dispatch(modalActions.togglePawChargeModal({}));
  };

  const { t } = useI18n();
  return (
    <SubContents>
      <Container id="contents">
        <BreadcrumbArea
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            ["/account", t("common.routes.account")],
            [null, t("common.routes.pawUseHistory")],
          ]}
        />
        <AccountPawInfo>
          <div className="owned_paw">
            <p className="heading">{t("pawUseHistory.ownedPAW")}</p>
            <Paw>{getCommaStyleNumber(pawBalance)}</Paw>
          </div>
          <div>
            <p onClick={togglePawChargeModal}>{t("pawUseHistory.topUpPAW")}</p>
          </div>
        </AccountPawInfo>

        <p className="paw_caution_text">
          {t("pawUseHistory.topUpPAW")}
          {t("pawUseHistory.note1")}
        </p>

        <div className="paw_history_layout">
          <div className="paw_history_heading">
            <div className="paw_history">
              <p className="purchase">{t("pawUseHistory.dateOfUse")}</p>
              <p className="paw">{t("pawUseHistory.usedPAW")}</p>
              <p className="expiration">{t("pawUseHistory.use")}</p>
            </div>
          </div>
          {historyContents(pawHistoryContainer)}
          {/*TODO: Add reload method */}
          {/*<div className="paw_history_heading" onClick={this.reloadHistory}>*/}
          {/*  <div className="paw_history contents_layout">*/}
          {/*    <p>続きを読み込む</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </Container>
    </SubContents>
  );
};
