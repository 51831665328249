import React, { ComponentProps } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Redirect, Route, Switch } from "react-router";
import Loading from "containers/Loading";
import Notice from "containers/Notice";
import Error from "containers/Error";
import ActionModal from "containers/ActionModal";
import { AccountsAppFooter } from "./AccountsAppFooter";
import appConfig from "constants/appConfig";
import { Signup } from "./Signup";
import { AccountsAuthenticator } from "./AccountsAuthenticator";
import { LayoutNoHeader } from "ui/LayoutNoHeader";
import { EmailAuthenticationLogic } from "../EmailAuthentication";

/**
 * アカウントアプリケーションコンポーネント
 * - Headerなくす
 * - /login のURLパスを指定しているのは、login、signupでページを分ける余地を残すため
 *   - 現状 / にきたらloginページへリダイレクトするようにしている
 */
export const AccountsApp: React.FC<{
  history: ComponentProps<typeof ConnectedRouter>["history"];
}> = ({ history }) => {
  return (
    <LayoutNoHeader footer={<AccountsAppFooter />}>
      <ConnectedRouter history={history}>
        {/* 遷移先がないのでHeaderはいれない */}
        {/* アカウントアプリケーションのRouting */}
        <Switch>
          <Route exact path="/login" component={AccountsAuthenticator} />
          <Route exact path="/signup" component={Signup} />
          {/* メールアドレス認証を表示する */}
          <Route
            exact
            path="/signup/authentication"
            component={EmailAuthenticationLogic}
          />
          {/* メールアドレス認証後などでトップページに戻ることがあるので、/loginに誘導する */}
          <Redirect from="/" to={"/login"} />
        </Switch>
        {/* ローディングモーダルのために入れてる */}
        <Loading />
        {/* エラーモーダルのために入れてる */}
        <Error />
        {/* 通知モーダルのために入れてる */}
        <Notice />
        {/* 特定モーダルのために入れてる（パスワードリセット） */}
        <ActionModal />
      </ConnectedRouter>
    </LayoutNoHeader>
  );
};

/**
 * アカウントアプリケーションかどうかの判定
 */
export const isAccountsApp = () => {
  return isAccountsAppOrigin() || appConfig.accountsApp.developmentEnabled;
};

/**
 * アカウントアプリケーション用のドメインか判定する
 */
const isAccountsAppOrigin = () => {
  return appConfig.accountsApp.appUrl === window.location.origin;
};
