/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

interface Props {
  width: number;
  height: number;
  fontColor?: string;
  isDarkModeEnabled?: boolean;
  className?: string;
}
export const GiftIcon: React.FC<Props> = (props) => {
  const svgColorStyle = svgColor({
    width: props.width,
    height: props.height,
    isDarkModeEnabled: props.isDarkModeEnabled,
    fontColor: props.fontColor,
  });
  return (
    <div id="GiftIcon" css={svgColorStyle.root}>
      <svg version="1.1" viewBox="0 0 95.4 95.4" css={svgColorStyle.svg}>
        <path
          d="M54.4,37.5h25.2v14.2c0,1.2,0.9,2.1,2.1,2.1s2.1-0.9,2.1-2.1c0,0,0,0,0,0V35.4c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1l-2.1-13.5
          c-0.2-1-1-1.8-2.1-1.8H74C75.4,10.6,67.2,4,67.2,4c-9.7-8.8-19.4,3.7-23.3,11.7C40,7.7,30.3-4.8,20.6,4c0,0-8.2,6.6-6.9,15.8H8.1
          c-1,0-1.9,0.8-2.1,1.8L4,35.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2v56.9c0,1.1,0.9,2,2,2.1h48.4v-4.2l0,0L54.4,37.5z M79.2,33.3H54
          l-1.1-7.1C64,28.4,69.3,26.1,71.8,24h6L79.2,33.3z M9.9,24h6c2.4,2.1,7.7,4.5,18.8,2.2l-1.1,7.1H8.5L9.9,24z M8.1,37.5h25.2v52.7
          H8.1V37.5z M93.4,75.9c0,2.4-1.9,4.3-4.3,4.3h-9.4v9.4c-0.1,2.4-2.1,4.2-4.5,4.1c-2.2-0.1-4-1.9-4.1-4.1v-9.4h-9.4
          c-2.4-0.1-4.2-2.1-4.1-4.5c0.1-2.2,1.9-4,4.1-4.1h9.4v-9.4c-0.1-2.4,1.7-4.4,4.1-4.5c2.4-0.1,4.4,1.7,4.5,4.1c0,0.1,0,0.3,0,0.4v9.4
          h9.4C91.5,71.6,93.4,73.5,93.4,75.9z"
        />
      </svg>
    </div>
  );
};

interface svgStyle {
  width: number;
  height: number;
  isDarkModeEnabled?: boolean;
  fontColor?: string;
}
const svgColor = (props?: svgStyle) => {
  // @ts-expect-error TS18048
  const svgColorInDarkMode = props.isDarkModeEnabled ? "#fff" : props.fontColor;
  return {
    root: css`
      position: relative;
      width: 100%;
    `,
    svg: css`
      width: ${
        // @ts-expect-error TS18048
        props.width
      }px;
      height: ${
        // @ts-expect-error TS18048
        props.height
      }px;
      fill: ${svgColorInDarkMode} rect {
        stroke: ${svgColorInDarkMode};
      }
      path {
        fill: ${svgColorInDarkMode};
      }
    `,
  };
};
