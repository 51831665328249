/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import type { StageContent } from "@spwn/types";
import { EventTimetableCartButton } from "./EventTimetableCartButton";
import { EventTimetablePlayButton } from "./EventTimetablePlayButton";

export const EventTimetableStageHeading: React.FC<{
  stage: StageContent;
  doAction: () => void;
}> = ({ stage, ...props }) => {
  const style = styles();
  return (
    <div className="stage_heading" css={style.root}>
      <h4>{stage.name}</h4>
      <div css={style.button}>
        {stage.ticketPath && stage.actionStatus === "NotPurchased" && (
          <EventTimetableCartButton onClick={props.doAction}>
            STAGE チケットを購入
          </EventTimetableCartButton>
        )}

        {stage.ticketPath && stage.actionStatus === "InCart" && (
          <EventTimetableCartButton
            onClick={props.doAction}
            variant="outlined"
            badge={true}
          >
            カートを確認する
          </EventTimetableCartButton>
        )}

        {stage.ticketPath && stage.actionStatus === "Purchased" && (
          <EventTimetableCartButton disabled={true}>
            STAGE チケット購入済み
          </EventTimetableCartButton>
        )}

        {stage.ticketPath && stage.actionStatus === "SettlementConfirm" && (
          <EventTimetableCartButton onClick={props.doAction}>
            支払状況を確認
          </EventTimetableCartButton>
        )}

        {stage.actionStatus === "Playable" && (
          <EventTimetablePlayButton onClick={props.doAction}>
            ライブを視聴
          </EventTimetablePlayButton>
        )}

        {stage.actionStatus === "BeforePlay" && (
          <EventTimetablePlayButton disabled={true}>
            ライブを待機中
          </EventTimetablePlayButton>
        )}
      </div>
    </div>
  );
};

const styles = () => ({
  root: css`
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 115px;
    padding: 16px;
    margin-top: -115px;
    color: #fff;
    text-align: center;
    background-color: #132423;
    border-bottom: 2px solid #00c2ad;
    z-index: 7;
    h4 {
      width: 100%;
      font-family: "DIN Alternate", sans-serif;
      font-size: 24px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 4px;
    }
  `,
  button: css`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 12px 0;
  `,
});
