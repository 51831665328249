/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Link } from "react-router-dom";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import type { DayContent, StageContent, Video } from "@spwn/types";
import { EventTimetableCartButton } from "./EventTimetableCartButton";
import { EventTimetablePlayButton } from "./EventTimetablePlayButton";

type DayAndStageActionStatus =
  | StageContent["actionStatus"]
  | DayContent["actionStatus"];

export const EventTimetableVideoButton = (props: {
  actionStatus: Video["actionStatus"];
  doVideoButtonAction: () => void;
}) => {
  switch (props.actionStatus) {
    case "NotAvailableForPurchase":
      return (
        <EventTimetableCartButton disabled={true}>
          SINGLE チケットを購入
        </EventTimetableCartButton>
      );

    case "NotPurchased":
      return (
        <EventTimetableCartButton onClick={props.doVideoButtonAction}>
          SINGLE チケットを購入
        </EventTimetableCartButton>
      );

    case "InCart":
      return (
        <EventTimetableCartButton
          onClick={props.doVideoButtonAction}
          variant="outlined"
          badge={true}
        >
          カートを確認する
        </EventTimetableCartButton>
      );

    case "Playable":
      return (
        <EventTimetablePlayButton onClick={props.doVideoButtonAction}>
          ライブを視聴
        </EventTimetablePlayButton>
      );

    case "BeforePlay":
      return (
        <EventTimetablePlayButton
          onClick={props.doVideoButtonAction}
          disabled={true}
        >
          ライブを待機中
        </EventTimetablePlayButton>
      );

    case "NotPlayable":
    default:
      return null;
  }
};

export const EventTimetableDayAndStageButton = (props: {
  name: string;
  actionStatus: DayAndStageActionStatus;
  doButtonAction: () => void;
}) => {
  switch (props.actionStatus) {
    case "NotAvailableForPurchase":
      return (
        <EventTimetableCartButton disabled={true}>
          {`${props.name}チケットを購入`}
        </EventTimetableCartButton>
      );

    case "NotPurchased":
      return (
        <EventTimetableCartButton onClick={props.doButtonAction}>
          {`${props.name}チケットを購入`}
        </EventTimetableCartButton>
      );

    case "InCart":
      return (
        <EventTimetableCartButton
          onClick={props.doButtonAction}
          variant="outlined"
          badge={true}
        >
          {`${props.name}チケットのカートを確認する`}
        </EventTimetableCartButton>
      );

    case "SettlementConfirm":
      return (
        <EventTimetableCartButton onClick={props.doButtonAction}>
          {`${props.name}チケットの支払状況を確認`}
        </EventTimetableCartButton>
      );

    case "Purchased":
    case "BeforePlay": // ステージのみ。ポップアップでは購入済みとする
    case "Playable": // ステージのみ。ポップアップでは購入済みとする
      return (
        <EventTimetableCartButton disabled={true}>
          {`${props.name}チケット購入済み`}
        </EventTimetableCartButton>
      );

    default:
      return null;
  }
};

/**
 *  hotfix: streamingページのvideoの表示に対応
 *  https://balus3d.slack.com/archives/C01C7KHJ2J0/p1630132052219000
 */
export const StreamingVideoButton = (props: {
  eventId: string;
  actionStatus: Video["actionStatus"];
  doVideoButtonAction: () => void;
}) => {
  const classes = styles();

  switch (props.actionStatus) {
    case "NotAvailableForPurchase":
    case "NotPurchased":
    case "InCart":
      return (
        <Link
          to={`/events/${props.eventId}`}
          target={"_blank"}
          css={classes.button}
          className="enable"
        >
          <ShoppingCartIcon />
          SINGLE チケットを購入
        </Link>
      );

    case "Playable":
      return (
        <EventTimetablePlayButton onClick={props.doVideoButtonAction}>
          ライブを視聴
        </EventTimetablePlayButton>
      );

    case "BeforePlay":
      return (
        <EventTimetablePlayButton
          onClick={props.doVideoButtonAction}
          disabled={true}
        >
          ライブを待機中
        </EventTimetablePlayButton>
      );

    default:
      return null;
  }
};

const styles = () => {
  return {
    button: css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 10px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      background-color: #d0d0d0;
      cursor: initial;
      @media screen and (min-width: 768px) {
        padding: 14px 8px;
      }
      svg {
        margin-right: 8px;
      }
      &.enable {
        background-color: #00c2ad;
        cursor: pointer;
      }
    `,
  };
};
