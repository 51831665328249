/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { eventState } from "modules/event";
import { getNowTimestamp, EventInfo } from "utility";
import { changeStyleWithHosting } from "utility/hosting";
import { Link } from "react-router-dom";
import { useI18n } from "hooks/i18n/i18n";
import { LeftBorderHeadline } from "components/atoms/headline/LeftBorderHeadline";

type Props = {
  goodsData: eventState["eventJson"]["goods_data"];
  eventInfo: EventInfo;
};
/**
 * Goods image area
 * @param props
 */
const GoodsThumbnail: React.FC<Props> = (props) => {
  const { t } = useI18n();

  const isPublicGoodsUrl =
    props.eventInfo.goodsUrl &&
    props.eventInfo.goodsUrlOpenTime &&
    props.eventInfo.goodsUrlOpenTime.seconds < getNowTimestamp();

  const hostingBaseStyleSettings = changeStyleWithHosting().baseStyles;
  const classesCommonStyles = styles({ hostingBaseStyleSettings });

  const onlineGoodsBannerURL =
    props.eventInfo.goodsBannerURL ?? props.goodsData.online_goods_img_path;
  const offlineGoodsBannerURL = props.goodsData.offline_goods_img_path;

  return (
    <div id="goods_area" css={classesCommonStyles.root}>
      <div css={classesCommonStyles.heading}>
        <LeftBorderHeadline
          title={t("event.headline.goods.text")}
          subTitle={t("event.headline.goods.subText")}
          fontColor={"primary"}
        />
      </div>

      <div css={classesCommonStyles.eventGoodsInfo}>
        {/* <h3>事前購入可能なグッズ</h3> */}
        {onlineGoodsBannerURL && <img src={onlineGoodsBannerURL} alt="Goods" />}

        {isPublicGoodsUrl ? (
          <a href={props.eventInfo.goodsUrl} target="_blank" rel="noreferrer">
            {t("event.headline.goods.button")}
          </a>
        ) : (
          <Link to={`/events/${props.eventInfo._id}/goods`}>
            {t("event.headline.goods.button")}
          </Link>
        )}
      </div>

      {/* <h3>その他会場限定グッズ</h3> */}
      {offlineGoodsBannerURL && (
        <div>
          <img src={offlineGoodsBannerURL} alt="Goods" />
        </div>
      )}
    </div>
  );
};

interface StylesProps {
  // @ts-expect-error TS7008
  hostingBaseStyleSettings;
}
const styles = (_props?: StylesProps) => {
  const {
    baseStyles: { accentColor },
  } = changeStyleWithHosting();
  return {
    root: css`
      margin: 0 auto 60px;
      @media screen and (min-width: 768px) {
        margin-bottom: 100px;
      }
    `,
    heading: css`
      margin-bottom: 40px;
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        margin-bottom: 60px;
      }
    `,
    eventGoodsInfo: css`
      img {
        width: 100%;
      }
      a {
        display: block;
        padding: 12px 0;
        margin: 40px auto 0;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 1px;
        background-color: ${accentColor};
        cursor: pointer;
        @media screen and (min-width: 768px) {
          width: 100%;
          max-width: 880px;
          padding: 15px 0;
          margin: 60px auto 0;
        }
      }
    `,
  };
};

export default GoodsThumbnail;
