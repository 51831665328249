import BigNumber from "bignumber.js";

/**
 * @description
 * 税込価格に含まれる消費税額を計算するために、税込価格を (1 + 消費税率) * 消費税率 で割る必要がある。
 * 消費税は10%なので、consumptionTax は 1.1 になる。
 */
const consumptionTax = 1.1;

/**
 * @description
 * JavaScript では小数の計算が正しく出来ない。
 * 数値によっては誤差が出てしまうため、本来であればサーバーサイドなりに任せるのがベストだが、
 * 今回は JavaScript で小数計算するためのライブラリ bignumber.js を使用して計算を行う。
 * @see https://qiita.com/niharu/items/52ee466c37c701f9109b#%E3%81%AF%E3%81%98%E3%82%81%E3%81%AB
 *
 * config関数で、端数処理の扱いを設定する。
 * DECIMAL_PLACES で小数部の桁数、ROUNDING_MODEで丸めモード（切り上げ、切り捨てなど）が指定できる。
 * バルスが端数切り捨てで計算しているため、以下の設定では小数点第一位で切り捨て設定にしている。
 * @see https://www.notion.so/balusco/3194ff0b501343deaa4a19b5a24cd5bf?pvs=4
 */
BigNumber.config({
  DECIMAL_PLACES: 1,
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
});

type CalcTaxDetailsReturnValues = {
  targetTotal: number;
  excludedTaxAmount: number;
};

/**
 * @description
 * 合計金額から、消費税抜き金額と消費税額を算出する関数。
 *
 * @param {number} total -  receiptDataにある合計金額
 * @returns {targetTotal: number, excludedTaxAmount: number} - 合計金額から消費税を抜いた税抜金額（targetTotal）と、合計金額から税抜金額を引いた消費税額（excludedTaxAmount）を返す
 */
export const calcTaxDetails = (total: number): CalcTaxDetailsReturnValues => {
  const targetTotal = new BigNumber(total).div(consumptionTax);
  const excludedTaxAmount = new BigNumber(total).minus(targetTotal);

  return {
    targetTotal: Math.floor(targetTotal.toNumber()),
    excludedTaxAmount: Math.floor(excludedTaxAmount.toNumber()),
  };
};
