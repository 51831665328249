/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import UseGuideForPC from "./UseGuideForPC";
import UseGuideForSP from "./UseGuideForSP";
import { isSmartPhone } from "../../utility";
import { BreadcrumbArea } from "../../components/common/Link";
import { SubPageSection } from "../../styles";
import { TextLink } from "ui/TextLink";

interface Props {}

const Manual: React.FC<Props> = (_props: Props) => {
  const classesManualLayoutDesign = manualLayoutDesign();

  return (
    <SubPageSection id="contents">
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, "ご利用ガイド"],
        ]}
      />
      <div css={classesManualLayoutDesign.root}>
        <h2>SPWN ご利用ガイド</h2>
        <p>
          SPWNを初めて利用される方は、まずこちらの利用ガイドをご覧ください。
          ご不明な事があった場合はSPWNの
          <TextLink href={"https://spwn.zendesk.com/hc/ja"}>FAQ</TextLink>
          も併せてご確認ください。
        </p>

        {isSmartPhone() ? <UseGuideForSP /> : <UseGuideForPC />}
      </div>
    </SubPageSection>
  );
};
export default Manual;

const manualLayoutDesign = () => {
  return {
    root: css`
      width: 90%;
      margin: 40px auto 0;
      @media screen and (min-width: 768px) {
        max-width: 680px;
        width: 100%;
      }
      h2 {
        margin-bottom: 40px;
        font-size: 20px;
        text-align: left;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 25px;
        }
      }
      p {
        margin-bottom: 40px;
        font-size: 15px;
        line-height: 1.5em;
        letter-spacing: 1px;
      }
    `,
  };
};
