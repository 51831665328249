import type { CSSProperties } from "react";

export type CSSVariableName<T extends string> = `--${T}`;

export const createVariable = <T extends string>(
  name: T
): CSSVariableName<T> => {
  return `--${name}` as const;
};

type VariableDefinition = {
  [name in CSSVariableName<string>]: string;
};

export const variables = (
  ...variableDefinitions: (VariableDefinition | false)[]
): CSSProperties => {
  return variableDefinitions.reduce<VariableDefinition>((acc, cur) => {
    return cur
      ? {
          ...acc,
          ...cur,
        }
      : acc;
  }, {});
};
