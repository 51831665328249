/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { CardInfo } from "modules/purchase";
import arrow from "designs/images/streaming/arrow_down.png";
import { useI18n } from "hooks/i18n/i18n";

interface Props {
  isError: boolean;
  isDarkModeEnabled: boolean;
  registeredCardList: CardInfo[];
  setCreditCardSeq: React.Dispatch<unknown>;
  setSecurityCode: React.Dispatch<React.SetStateAction<string>>;
  openChangePaymentMethod: () => void;
  canPurchase: () => boolean;
  toggleRegisterCard: () => void;
  startPurchaseProcess: () => void;
  openRegisterCard: () => void;
}
export const StreamingLiveCommerceCreditPayment: React.FC<Props> = (props) => {
  const classesLiveCommerceCreditPaymentStyles =
    liveCommerceCreditPaymentStyles({
      isDarkModeEnabled: props.isDarkModeEnabled,
    });
  const { t } = useI18n();

  return (
    <React.Fragment>
      {props.registeredCardList && props.registeredCardList.length > 0 ? (
        <React.Fragment>
          <div css={classesLiveCommerceCreditPaymentStyles.methodOfPayment}>
            <p>{t("streaming.commerceCreditPayment.paymentMethod")}</p>
            <p className="change" onClick={props.openChangePaymentMethod}>
              {t("streaming.commerceCreditPayment.changeButton")}
            </p>
          </div>

          <div css={classesLiveCommerceCreditPaymentStyles.selectCredit}>
            <p>{t("streaming.commerceCreditPayment.cardCheckout")}</p>
            <div css={classesLiveCommerceCreditPaymentStyles.selectCard}>
              <p>{t("streaming.commerceCreditPayment.card4string")}</p>
              <select onChange={(e) => props.setCreditCardSeq(e.target.value)}>
                <option value={-1}>----</option>
                {props.registeredCardList.map((card, index) => {
                  return (
                    <option value={card.cardSeq} key={index}>
                      {card.cardNumber.slice(-4)}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div css={classesLiveCommerceCreditPaymentStyles.securityCode}>
            <p>{t("streaming.commerceCreditPayment.securityCode")}</p>
            <input
              type="password"
              maxLength={4}
              onChange={(e) => props.setSecurityCode(e.target.value)}
            />
          </div>

          <div css={classesLiveCommerceCreditPaymentStyles.registerCard}>
            <p onClick={props.openRegisterCard}>
              {t("streaming.commerceCreditPayment.otherCard")}
            </p>
          </div>

          <div
            css={classesLiveCommerceCreditPaymentStyles.button}
            // @ts-expect-error TS2322
            className={props.canPurchase() ? null : "disabled"}
            onClick={props.startPurchaseProcess}
          >
            {t("streaming.commerceCreditPayment.confirmPurchase")}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div css={classesLiveCommerceCreditPaymentStyles.methodOfPayment}>
            <p>{t("streaming.commerceCreditPayment.paymentMethod")}</p>
            <p className="change" onClick={props.openChangePaymentMethod}>
              {/*paymentMethod*/}
              {t("streaming.commerceCreditPayment.changeButton")}
            </p>
          </div>
          {/* <div css={classesLiveCommerceCreditPaymentStyles.register}>
                <p>クレジットカードが登録されていません。</p>
                <p><span onClick={props.toggleRegisterCard}>こちら</span>からカードの登録をお願いします。</p>
              </div> */}
          <div css={classesLiveCommerceCreditPaymentStyles.registerCard}>
            <p onClick={props.openRegisterCard}>
              {t("streaming.commerceCreditPayment.registerCard")}
            </p>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

interface LiveCommerceCreditPaymentStylesProps {
  isDarkModeEnabled: boolean;
}
const liveCommerceCreditPaymentStyles = (
  props?: LiveCommerceCreditPaymentStylesProps
) => {
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;

  const methodOfPayment = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    p {
      ${textColorInDarkMode}
      font-size: 14px;
    }
    .change {
      color: #00c2ae;
      cursor: pointer;
    }
  `;
  const selectCredit = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    p {
      ${textColorInDarkMode}
      font-size: 14px;
    }
  `;
  const securityCode = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    p {
      ${textColorInDarkMode}
      font-size: 14px;
    }
    input[type="password"] {
      width: 50px;
      padding: 8px;
      font-size: 16px;
      text-align: center;
      border: 1px solid #b1b1b1;
      border-radius: 5px;
      letter-spacing: 1px;
    }
  `;
  const selectCard = css`
    display: flex;
    align-items: center;
    p {
      margin-right: 8px;
      ${textColorInDarkMode}
    }
    select {
      width: 70px;
      padding: 8px;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 1px;
      background-color: #f4f4f4;
      background-image: url(${arrow});
      background-size: 8px auto;
      background-repeat: no-repeat;
      background-position: 90% center;
      border: 1px solid #dedede;
      border-radius: 5px;
      appearance: none;
    }
  `;
  const registerCard = css`
    padding-bottom: 32px;
    p {
      color: #00c2ae;
      font-size: 14px;
      text-align: right;
      cursor: pointer;
    }
  `;
  const button = css`
    padding: 12px 0;
    color: #fff;
    font-size: 14px;
    text-align: center;
    background-color: #f33074;
    cursor: pointer;
    &.disabled {
      background-color: #b1b1b1;
    }
  `;
  const register = css`
    padding: 25px 0 15px;
    p {
      font-size: 14px;
      text-align: center;
      line-height: 1.5em;
      span {
        color: #00c2ae;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  `;
  return {
    methodOfPayment,
    selectCredit,
    securityCode,
    selectCard,
    registerCard,
    button,
    register,
  };
};
