import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "store";
import { modalActions } from "../modules/modal";

export const withRequireLogin = <P extends Record<string, unknown>>(
  Component: (p: P) => JSX.Element
) => {
  const WithRequireLogin = (props: P): JSX.Element => {
    const isLogin = useSelector((store: Store) => store.auth.isLogin);
    const dispatch = useDispatch();

    useEffect(() => {
      if (!isLogin) {
        dispatch(modalActions.toggleLoginMethod());
      }
    }, [isLogin, dispatch]);

    return <Component {...props} />;
  };
  return WithRequireLogin;
};
