/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

import type { DayContent } from "@spwn/types";

interface Props {
  models: DayContent[];
  selectedDay: number;
  changeDisplayStage: (index: number) => void;
}
export const EventTimetableDateList: React.FC<Props> = (props) => {
  const classes = styles();

  return (
    <React.Fragment>
      <ul css={classes.root} className="date_list">
        {props.models.map((stage, index) => {
          return (
            <li
              className={props.selectedDay === index ? "current_day" : ""}
              key={index}
              onClick={() => props.changeDisplayStage(index)}
            >
              {stage.dayName}
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
};

const styles = () => {
  return {
    root: css`
      overflow-x: scroll;
      white-space: nowrap;
      text-align: center;
      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
        overflow: initial;
      }
      li {
        display: inline-block;
        padding: 4px 40px;
        margin-right: 8px;
        font-family: "din-condensed", sans-serif;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        border: 1px solid #fff;
        border-radius: 23px;
        transition: width 0.2s;
        outline: none;
        cursor: pointer;
        user-select: none;
      }
      .current_day {
        color: #000;
        background-color: #fff;
      }
    `,
  };
};
