import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import { authActions } from "../../modules/auth";
import FacebookLoginButton from "../../components/FacebookLoginButton";
import TwitterLoginButton from "../../components/TwitterLoginButton";
import GoogleLoginButton from "../../components/GoogleLoginButton";
import { parseQuery } from "../../utility";
import DocomoLoginButton from "components/DocomoLoginButton";
import {
  AuthContent,
  AuthProvider,
  LinkButton,
  LiveStreamHeader,
  ContentsLayout,
} from "../../styles";

interface Props {}

interface QueryFromArApp {
  provider: "google" | "twitter" | "facebook" | "docomo";
}

const Container = AuthContent;

const ARAppLogin: React.FC<Props> = (_props) => {
  const dispatch = useDispatch();
  const isSignup = useSelector((state: Store) => state.modal.isSignup);
  const dynamicLink = useSelector(
    (state: Store) => state.auth.arAppDynamicLink
  );
  // const dynamicLinkWindow = useSelector(
  //   (state: Store) => state.auth.dynamicLinkWindow
  // );
  const location = useSelector((state: Store) => state.router.location);

  // @ts-expect-error TS2322
  const queryObj: Partial<QueryFromArApp> = parseQuery(location.search);

  // delay update window location to prevent popup block
  if (dynamicLink) {
    return (
      <Container id="contents">
        <ContentsLayout>
          <LiveStreamHeader>SPWN AR</LiveStreamHeader>
          <LinkButton
            className="link_button"
            onClick={() => window.open(dynamicLink, "_blank")}
          >
            アプリへ移動
          </LinkButton>
        </ContentsLayout>
      </Container>
    );
  }

  // @ts-expect-error TS7006
  const login = (provider) => {
    dispatch(authActions.login.started(provider));
  };

  const loginWithDocomo = () => {
    dispatch(authActions.loginWithDocomo.started({ appName: "arApp" }));
  };

  /**
   * select provider button by query string
   * if no query string, return facebook
   */
  const providerSelector = () => {
    if (!queryObj) {
      return <FacebookLoginButton onClick={login} isSignup={isSignup} />;
    }
    switch (queryObj.provider) {
      case "google":
        return <GoogleLoginButton onClick={login} isSignup={isSignup} />;
      case "twitter":
        return <TwitterLoginButton onClick={login} isSignup={isSignup} />;
      case "facebook":
        return <FacebookLoginButton onClick={login} isSignup={isSignup} />;
      case "docomo":
        return (
          <DocomoLoginButton onClick={loginWithDocomo} isSignup={isSignup} />
        );
      default:
        return <FacebookLoginButton onClick={login} isSignup={isSignup} />;
    }
  };

  return (
    <Container id="contents">
      <ContentsLayout>
        <LiveStreamHeader>{isSignup ? "SIGNUP" : "LOGIN"}</LiveStreamHeader>
        {/* {isSignup && <p className="signup_message">登録をすると<span onClick={()=>{clickMenuItem("/terms-of-service")}}>会員規約</span>に同意したものと見なされます</p>} */}
        <AuthProvider>
          <ul className="button">{providerSelector()}</ul>
        </AuthProvider>
      </ContentsLayout>
    </Container>
  );
};

export default ARAppLogin;

// const MailLoginForm = (props: {isSignup: boolean}) => {

//   const [email, setEmail] = useState<string>('')
//   const [pass, setPass] = useState<string>('')

//   const dispatch = useDispatch()

//   const resetPassword = () => {
//     const actionData: ActionData = {
//       caption: "パスワードのリセット",
//       contents: {
//         "email": {
//           title: "email",
//           tagType: 'input',
//           placeHolderAttr: "メールアドレス",
//           typeAttr: "email",
//           classAttr: ""
//         },
//       },
//       btnMsg: "パスワードの再設定メールを送信する",
//       callbackTarget: "resetPassword"
//     };
//     dispatch(modalActions.toggleActionModal(actionData))
//   }

//   return (
//     <div className="form">
//       <p className="item">メールアドレス</p>
//       <input type="text" name="email" onChange={e => setEmail(e.target.value)}/>
//       <p className="item">パスワード</p>
//       <input type="password" name="pass" onChange={e => setPass(e.target.value)}/>
//       <p
//           className="link_button"
//           onClick={
//             props.isSignup
//               ? () => { dispatch(authActions.createUserWithEmail.started({email, pass})) }
//               : () => { dispatch(authActions.loginWithEmail.started({email, pass})) }
//           }
//       >
//           {props.isSignup ? '新規登録' : 'ログイン'}
//       </p>
//       <p className="forget_pass" onClick={resetPassword}>パスワードを忘れた方</p>
//     </div>
//   )
// }
