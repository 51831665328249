import React from "react";
import { Shop } from "./Shop";
import { useGetShopPreview } from "./useGetShopPreview";
import { LoaderLayout } from "../components/atoms/loader/LoaderLayout";

type Props = {
  tenantId: string;
  brandId: string;
  shopId: string;
};
export const ShopPreviewContainer: React.FC<Props> = (props) => {
  const { shopInfo } = useGetShopPreview(props);
  if (shopInfo === undefined) return <LoaderLayout />;
  // TODO エラー・未ログインのハンドリング
  return <Shop {...props} shopInfo={shopInfo} />;
};
