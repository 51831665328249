import React, { FC } from "react";

export const IconTicket: FC<{
  fill?: string;
  style?: React.CSSProperties;
}> = ({ fill, style }) => (
  <svg
    aria-hidden="true"
    style={style}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 10V6C22.5 4.89 21.6 4 20.5 4H4.5C3.4 4 2.51 4.89 2.51 6V10C3.61 10 4.5 10.9 4.5 12C4.5 13.1 3.61 14 2.5 14V18C2.5 19.1 3.4 20 4.5 20H20.5C21.6 20 22.5 19.1 22.5 18V14C21.4 14 20.5 13.1 20.5 12C20.5 10.9 21.4 10 22.5 10ZM13.5 17.5H11.5V15.5H13.5V17.5ZM13.5 13H11.5V11H13.5V13ZM13.5 8.5H11.5V6.5H13.5V8.5Z"
      fill={fill ? fill : "#fff"}
    />
  </svg>
);
