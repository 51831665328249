/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { FC, useCallback, useMemo } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import * as Sentry from "@sentry/browser";

export const FallbackLogin: FC = () => {
  const classes = styles();

  const loginWithPopup = useCallback(
    (provider) => async () => {
      try {
        const _result = await firebase.auth().signInWithPopup(provider);
      } catch (err) {
        Sentry.captureException(err, {
          tags: {
            /**
             * どこからのエラーか分かるようにする
             * FIXME sentryClient作ってtagの命名絞ってもいい
             */
            component: "SpwnCrewFallbackLogin",
          },
        });
        if (err instanceof Error) {
          /**
           * エラーメッセージをそのまま載せてしまう
           * - sentry上限で送られない場合もあり、エラーあったらエラーメッセージ付きで問い合わせてもらえると助かる
           * - SNSログインのみなので、表示されてはいけないエラーメッセージは出なさそう
           *   - メールアドレス&パスワードだと何回も試行されると困る場合はありだが。
           */
          window.alert(`エラーが発生しました。\n ${err.message}`);
        }
      }
    },
    []
  );

  const handleGoogleLogin = useMemo(() => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return loginWithPopup(provider);
  }, [loginWithPopup]);

  return (
    <div css={classes.paragraph}>
      <p
        css={css`
          margin-bottom: 1rem;
        `}
      >
        <span onClick={handleGoogleLogin}>
          Googleアカウントログインに失敗する方
        </span>
      </p>
    </div>
  );
};

const styles = () => {
  return {
    paragraph: css`
      color: rgb(117, 117, 117);
      font-size: 14px;
      text-align: center;
      span {
        text-decoration: underline;
        cursor: pointer;
      }
    `,
  };
};
