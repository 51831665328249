/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Link } from "react-router-dom";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useI18n } from "hooks/i18n/i18n";

interface Props {}
export const AccountEditDelete: React.FC<Props> = (_props) => {
  const classes = styles();
  const { t } = useI18n();
  return (
    <section css={classes.root}>
      <Link to="/account/edit/delete">
        <DeleteForeverIcon />
        {t("accountDelete.h2")}
      </Link>
    </section>
  );
};

const styles = () => {
  return {
    root: css`
      a {
        display: inline-flex;
        align-items: center;
        padding: 8px 16px 8px 10px;
        color: #ff0000;
        font-size: 14px;
        background-color: #fff;
        border: 2px solid #ff0000;
        border-radius: 5px;
        svg {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
    `,
  };
};
