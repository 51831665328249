import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  StreamingType,
  GiftItemData,
  CommentFilterType,
  streamingActions,
} from "modules/streaming";
import { Store } from "../../store";
import { EventInfo } from "../../utility";
import StreamingCommentComponent from "components/streaming/StreamingComment";
import { useTHEOplayerTimeupdate } from "hooks/Streaming/useTHEOplayer";
import { Timestamp } from "@google-cloud/firestore";
import { firestoreActions } from "modules/firestore";
import { updateFirestoreDoc, deleteFirestoreDoc } from "../../utility/firebase";
import { getCommentCollectionPath } from "utility/streaming";
import type { THEOPlayer } from "@spwn/types/external";
import type { UserMessage } from "@spwn/types/firebase/firestore";

type Props = {
  eventStart: Timestamp;
  streamingType: StreamingType;
  player: THEOPlayer;
  eventInfo: EventInfo;
  giftItemMap: { [key: string]: GiftItemData };
  currentVideoId: string; // !!! don't allow null !!!
  commentFilterState: CommentFilterType;
  THEOPlayerElementHeight?: number;
};

const StreamingComment: React.FC<Props> = (props) => {
  const user = useSelector((state: Store) => state.auth.user);
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);
  const displayComments = useSelector(
    (state: Store) => state.streaming.displayComments
  );
  const eventGoodsMap = useSelector(
    (state: Store) => state.ticket.eventGoodsMap
  );
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );
  const commentShardId = useSelector(
    (state: Store) => state.streaming.commentShardId
  );

  // @ts-expect-error TS2345
  const [previousTime, setPreviousTime] = useState<number>(null);
  const [filterdDisplayComments, setFilterdDisplayComments] = useState<
    UserMessage[]
  >([]);

  const [currentTime] = useTHEOplayerTimeupdate(props.player);

  const dispatch = useDispatch();

  // TODO
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDisplayComments = () => {
    if (props.streamingType === "Live") {
      dispatch(
        streamingActions.fetchDisplayComments.started({
          // @ts-expect-error TS2322
          eventId: props.eventInfo._id,
          videoId: props.currentVideoId,
        })
      );
      dispatch(
        streamingActions.fetchBANComments.started({
          // @ts-expect-error TS2322
          eventId: props.eventInfo._id,
          videoId: props.currentVideoId,
        })
      );
    } else {
      handleSeekEvents();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSeekEvents = () => {
    console.log("handleSeekEvents", props.currentVideoId);
    dispatch(
      streamingActions.handleSeekEvents.started({
        // @ts-expect-error TS2322
        eventId: props.eventInfo._id,
        videoId: props.currentVideoId,
        player: props.player,
        eventStart: props.eventStart,
      })
    );
  };

  /**
   * to ban, set `isChatAdmin` to True in `/admins/<userId>` document
   * allow firestore permission warning when comment has deleted or user is not admin
   */
  const banComment = useCallback(
    async (target) => {
      const msgCollectionRef = getCommentCollectionPath(
        // @ts-expect-error TS2345
        props.eventInfo._id,
        props.currentVideoId,
        props.eventInfo?.streamingInfo?.optionSettings
          ?.isCommentShardingEnabled,
        commentShardId.value
      );
      const msgRef = `${msgCollectionRef}/${target}`;
      // console.info(msgRef)
      await updateFirestoreDoc(msgRef, { isBan: true });
    },
    [
      commentShardId,
      props.currentVideoId,
      props.eventInfo._id,
      props.eventInfo?.streamingInfo?.optionSettings?.isCommentShardingEnabled,
    ]
  );

  /**
   * 管理者だけがBan処理する
   */
  const banCommentAdminOnly = useCallback(
    async (target) => {
      if (!isAdmin) {
        return;
      }
      await banComment(target);
    },
    [banComment, isAdmin]
  );

  /**
   * コメントを物理削除する
   *
   * 削除の際にBAN処理を呼んでから削除している理由：
   * - onSnapshotでは削除されたことを検知はしない
   * - そのため、一度banすることで変更検知を走らせて、コメントを更新している
   * - その後時間をおいて物理削除する
   */
  const deleteComment = useCallback(
    async (target) => {
      await banComment(target);
      // delete from display
      dispatch(streamingActions.deleteDisplayComment(target));
      // delay deletion to detect baned in fetchBANComments
      window.setTimeout(() => {
        const msgCollectionRef = getCommentCollectionPath(
          // @ts-expect-error TS2345
          props.eventInfo._id,
          props.currentVideoId,
          props.eventInfo?.streamingInfo?.optionSettings
            ?.isCommentShardingEnabled,
          commentShardId.value
        );
        const msgRef = `${msgCollectionRef}/${target}`;
        // console.info(msgRef)
        deleteFirestoreDoc(msgRef);
      }, 5000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      banComment,
      commentShardId,
      props.currentVideoId,
      props.eventInfo._id,
      props.eventInfo?.streamingInfo?.optionSettings?.isCommentShardingEnabled,
    ]
  );

  // switch watching video
  useEffect(() => {
    console.log("didMount StreamingComment");
    if (commentShardId.type === "init") return;

    switch (props.commentFilterState) {
      case "all":
        fetchDisplayComments();
        break;
      case "none":
        dispatch(
          firestoreActions.closeChannel({ channel: "streamingComments" })
        );
        dispatch(
          firestoreActions.closeChannel({ channel: "streamingBANComments" })
        );
        break;
      default:
        fetchDisplayComments();
    }
    if (props.streamingType === "VOD" && !!props.player) {
      props.player.addEventListener("seeked", handleSeekEvents);
    }
    // unMount
    return () => {
      console.log("unMount StreamingComment");
      dispatch(firestoreActions.closeChannel({ channel: "streamingComments" }));
      dispatch(
        firestoreActions.closeChannel({ channel: "streamingBANComments" })
      );
      if (props.streamingType === "VOD" && !!props.player) {
        props.player.removeEventListener("seeked", handleSeekEvents);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    /**
     * // FIXME 現状useEffectを正しく使えていない。useCallbackなど使う。
     * fetchDisplayComments で currentVideoId を利用しているため依存関係に入れてる
     */
    props.currentVideoId,
    props.streamingType,
    props.commentFilterState,
    commentShardId,
  ]);

  useEffect(() => {
    // only use in VOD
    if (
      props.streamingType === "Live" ||
      !props.eventStart ||
      currentTime === previousTime ||
      props.player.paused
    ) {
      return;
    }
    // @ts-expect-error TS2345
    setPreviousTime(currentTime);
    dispatch(
      streamingActions.fetchDisplayCommentsByTimeUpdate.started({
        // @ts-expect-error TS2322
        eventId: props.eventInfo._id,
        videoId: props.currentVideoId,
        player: props.player,
        eventStart: props.eventStart,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  // filter comment by filter type
  useEffect(() => {
    switch (props.commentFilterState) {
      case "all":
        setFilterdDisplayComments(displayComments);
        break;
      case "none":
        setFilterdDisplayComments([]);
        break;
      default:
        setFilterdDisplayComments(displayComments);
    }
  }, [displayComments, props.commentFilterState]);

  return (
    <StreamingCommentComponent
      player={props.player}
      eventStart={props.eventStart}
      streamingType={props.streamingType}
      eventInfo={props.eventInfo}
      eventGoodsMap={eventGoodsMap}
      giftItemMap={props.giftItemMap}
      currentVideoId={props.currentVideoId}
      displayComments={filterdDisplayComments}
      user={user}
      isDarkModeEnabled={isDarkModeEnabled}
      THEOPlayerElementHeight={props.THEOPlayerElementHeight}
      // @ts-expect-error TS2322
      commentFilterState={props.commentFilterState}
      banComment={banCommentAdminOnly}
      deleteComment={deleteComment}
    />
  );
};

export default StreamingComment;
