/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useI18n } from "hooks/i18n/i18n";
import React from "react";

type TabState = "shipping" | "venue";

export const GoodsListTab: React.FC<{
  selectedTab: TabState;
  isNoneShipments: boolean;
  isNoneVenue: boolean;
  handleSelectPlaceTab: (state: TabState) => void;
}> = ({ selectedTab, isNoneShipments, isNoneVenue, handleSelectPlaceTab }) => {
  const classes = styles();
  const { t } = useI18n();
  return (
    <div css={classes.tabs}>
      {/* className="current"を付けと下線が付く */}
      <div
        className={`${selectedTab === "shipping" ? "current" : ""} ${
          isNoneShipments ? "disabled" : ""
        }`}
        css={classes.tab}
        onClick={
          isNoneShipments ? undefined : () => handleSelectPlaceTab("shipping")
        }
      >
        {t("goods.goodsCategories.delivery")}
      </div>
      <div
        className={`${selectedTab === "venue" ? "current" : ""} ${
          isNoneVenue ? "disabled" : ""
        }`}
        css={classes.tab}
        onClick={isNoneVenue ? undefined : () => handleSelectPlaceTab("venue")}
      >
        {t("goods.goodsCategories.venue")}
      </div>
    </div>
  );
};

const styles = () => ({
  tabs: css`
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid #f4f4f4;
  `,
  tab: css`
    width: 100%;
    padding-bottom: 10px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    &.current {
      border-bottom: 2px solid #000;
    }
    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  `,
});
