/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useStreamingTimetableContent } from "../../hooks/Streaming/StreamingTimetable";
import LoaderSmall from "../../components/atoms/loader/BorderLoader";
import { convertDatetimeToHHII } from "../../utility";
import { StreamingTimetableLabel } from "./StreamingTimetableLabel";
import { StreamingVideoButton } from "../../components/event/detail/timetable/EventTimetableVideoButton";
import { useTimetableContent } from "../../hooks/event/TimetableContent";
import { modalActions } from "../../modules/modal";
import { cartActions } from "../../modules/cart";
import type { DayContent, StageContent, Video } from "@spwn/types";
import { Store } from "../../store";

interface Props {
  currentVideoId: string;
  isHiddenStreamingComment?: boolean;
}
export const StreamingTimetable: React.FC<Props> = (props) => {
  const isLogin = useSelector((state: Store) => state.auth.isLogin);

  const classes = styles({
    // @ts-expect-error TS2322
    isHiddenStreamingComment: props.isHiddenStreamingComment,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const { dispatchAddToCart } = useTimetableContent();
  const { loading, displayEvent, models, fetch } =
    useStreamingTimetableContent();

  useEffect(() => {
    fetch(props.currentVideoId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentVideoId]);

  const doContentAction = useCallback(
    ({
      actionStatus,
      ticketPath,
      _id: videoId,
    }: {
      actionStatus:
        | DayContent["actionStatus"]
        | StageContent["actionStatus"]
        | Video["actionStatus"];
      ticketPath: string;
      _id: string;
    }) => {
      console.info("doContentAction", actionStatus);

      if (!isLogin) {
        dispatch(modalActions.toggleLoginMethod());
        return;
      }

      switch (actionStatus) {
        case "NotPurchased":
          dispatchAddToCart({
            id: ticketPath.split("/").slice(-1)[0],
            count: 1,
            refPath: ticketPath,
          });
          break;
        case "Playable":
          history.push(`/videos/${videoId}`);
          break;
        case "InCart":
          dispatch(cartActions.toggleMyCart({}));
          break;
        case "SettlementConfirm":
          dispatch(
            modalActions.toggleActionModal({
              actionModalType: "confirmAction",
              caption: "支払状況を確認", // {t("ticket.confirmSettlement")}
              msg: "ご入金が確認できていないチケットがございます。マイチケットにてお支払い状況をご確認ください。",
              btnMsg: "マイチケットで確認する",
              action: () => history.push(`/account/ticket`),
              // @ts-expect-error TS2322
              args: null,
            })
          );
          break;
        default:
          // do nothing
          console.log("do nothing");
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatchAddToCart]
  );

  // if not fes, return nothing.
  if (!displayEvent.isFesEvent) {
    return null;
  }

  if (loading) {
    return (
      <div css={classes.root}>
        <div css={classes.inner}>
          <h2>配信中の別ステージ</h2>

          <LoaderSmall />
        </div>
      </div>
    );
  }

  if (!models) {
    return null;
  }

  return (
    <div css={classes.root}>
      <div css={classes.inner}>
        <h2>配信中の別ステージ</h2>

        <div className={"stage_list"}>
          {models.map((stage, index) => {
            return (
              <div className={"stage_item"} key={`stage_item_${index}`}>
                <h3>{stage.name}</h3>
                <div className={"video_list"}>
                  {stage.videos.length === 0 && (
                    <p
                      css={css`
                        width: 100%;
                        padding: 40px 0;
                        font-weight: bold;
                        text-align: center;
                      `}
                    >
                      本日予定しているステージは終了しました。
                    </p>
                  )}

                  {stage.videos.map((video, index) => {
                    return (
                      <div className={"video_item"} key={`video_item_${index}`}>
                        <div className={"thumbnail"}>
                          <img src={video.thumbnail} alt={video.name} />
                          <StreamingTimetableLabel
                            actionStatus={video.actionStatus}
                          />
                        </div>
                        <div className={"detail"}>
                          <div className={"info"}>
                            <h4>{video.name}</h4>
                            <p>
                              {convertDatetimeToHHII(
                                new Date(video.startAt * 1000)
                              )}
                              ~
                            </p>
                          </div>

                          {/* hotfix: streamingページのvideoの表示に対応 */}
                          {/*<EventTimetableVideoButton*/}
                          {/*  actionStatus={video.actionStatus}*/}
                          {/*  doVideoButtonAction={() => doContentAction(video)}*/}
                          {/*/>*/}
                          <StreamingVideoButton
                            eventId={displayEvent.eventId}
                            actionStatus={video.actionStatus}
                            // @ts-expect-error TS2345
                            doVideoButtonAction={() => doContentAction(video)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const styles = (props: { isHiddenStreamingComment: boolean }) => {
  const hiddenStreamingCommentDetailsInner = props.isHiddenStreamingComment
    ? "1200px"
    : "100%";

  return {
    root: css`
      padding: 40px 0;
      color: #fff;
      background-color: #000;
      @media screen and (min-width: 768px) {
        margin-top: 32px;
      }
    `,
    /* stylelint-disable */
    inner: css`
      width: 90%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 95%;
        max-width: ${hiddenStreamingCommentDetailsInner};
      }
      h2 {
        margin-bottom: 24px;
        font-size: 20px;
      }
      .stage_item {
        &:not(:last-of-type) {
          margin-bottom: 40px;
        }
        h3 {
          padding-bottom: 8px;
          margin-bottom: 24px;
          font-family: "DIN Alternate", sans-serif;
          font-size: 20px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 1px;
          border-bottom: 1px solid #fff;
        }
      }
      .video_list {
        display: flex;
        overflow-x: scroll;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .video_item {
        width: calc(80% - 12px);
        min-width: 240px;
        border: 2px solid #d6d6d6;
        transition: width 0.2s;
        outline: none;
        cursor: pointer;
        user-select: none;
        background-color: #fff;
        @media screen and (min-width: 768px) {
          width: calc(25% - 12px);
        }
        &:not(:last-of-type) {
          margin-right: 16px;
        }
        .thumbnail {
          position: relative;
          img {
            width: 100%;
          }
        }
        .detail {
          padding: 16px;
          background-color: #fff;
          .info {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            color: #000;
            h4 {
              /* stylelint-disable-next-line property-no-unknown */
              display: box;
              height: 1em;
              width: calc(100% - 56px - 16px);
              margin-right: 16px;
              /* stylelint-disable-next-line property-no-unknown */
              box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
              -webkit-line-clamp: 1;
            }
            p {
              width: 56px;
              font-family: "DIN Alternate", sans-serif;
              font-size: 20px;
              font-weight: 400;
              font-style: normal;
            }
          }
        }
      }
    `,
    /* stylelint-enable */
  };
};
