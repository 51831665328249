/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useI18n } from "hooks/i18n/i18n";

import { getNowTimestamp, getEventDisplayInfo, isSmartPhone } from "utility";
import { changeStyleWithHosting } from "utility/hosting";

import { EVENT_OPEN_TIME_PERIOD } from "constants/event";

import { TopSkeleton } from "components/loading/SkeletonLoader";
import { LinkButton } from "components/atoms/button/LinkButton";
import { Headline } from "components/top/headline/Headline";
import { EventCard } from "features/EventCard";
import type { Event, TopInfo } from "@spwn/types/firebase/firestore";

interface Props {
  topInfo: TopInfo;
  displayEvents: Event[];
  upcomingEventType: string;
  isLoading: boolean;
}
export const TopUpcomingEvent: React.FC<Props> = (props) => {
  const { t } = useI18n();
  const classes = styles();
  const nowTimestamp = getNowTimestamp();

  // @ts-expect-error TS7034
  const mainList = [];
  // @ts-expect-error TS7034
  const subList = [];
  const mainListCount: number = isSmartPhone() ? 2 : 2;
  const subListCount: number = isSmartPhone() ? 4 : 3;

  // 一瞬違うイベントが表示されないようにSkeletonLoader
  if (!props.topInfo) {
    return <TopSkeleton />;
  }

  // 注目させたいイベントをメインリストに持ってくる
  // メインリストの優先順位 指定したイベント → 直近の販売中イベント
  // @ts-expect-error TS7034
  const featureEvents = [];
  if (!!props.topInfo && !!props.topInfo.featureEventIds) {
    props.topInfo.featureEventIds.forEach((eid) => {
      const _event = props.displayEvents.filter((el) => el._id === eid);
      if (_event.length === 1) {
        featureEvents.push(_event[0]);
      }
    });
  }

  // @ts-expect-error TS7034
  const otherEvents = [];
  props.displayEvents
    .filter(
      (event) => nowTimestamp < event.datetime.seconds + EVENT_OPEN_TIME_PERIOD
    )
    .forEach((event: Event) => {
      if (
        !!props.topInfo &&
        !!props.topInfo.featureEventIds &&
        // @ts-expect-error TS2345
        props.topInfo.featureEventIds.indexOf(event._id) >= 0
      )
        return;

      const { eventSaleStatus } = getEventDisplayInfo(event);
      switch (eventSaleStatus) {
        case "ON_SALE":
          if (featureEvents.length < mainListCount) {
            featureEvents.push(event);
          } else {
            otherEvents.push(event);
          }
          return;
        case "NONE":
        default:
          otherEvents.push(event);
          return;
      }
    });

  // @ts-expect-error TS7005
  const sortEvents = featureEvents.concat(otherEvents);
  const fixedEvents =
    sortEvents.length === 0 ? props.displayEvents : sortEvents;

  fixedEvents.forEach((event, index) => {
    // break
    if (index >= mainListCount + subListCount) {
      return true;
    }

    if (index < mainListCount) {
      // メインリスト
      mainList.push(
        <div css={classes.largeEvent} key={index}>
          <EventCard event={event} />
        </div>
      );
    } else if (index < mainListCount + subListCount) {
      // サブリスト
      subList.push(
        <div css={classes.smallEvent} key={index}>
          <EventCard event={event} />
        </div>
      );
    }
  });

  if (props.displayEvents.length === 0 && !props.isLoading) {
    return (
      <section id="futureEventList" css={classes.root}>
        <div css={classes.headline}>
          {changeStyleWithHosting().pageSettings.topPage.upcomingHeadline
            .isDisplay ? (
            <Headline upcomingEventType={props.upcomingEventType} />
          ) : null}
        </div>
        <div css={classes.noEvent}>{t("event.noEvent.noEventTop")}</div>
      </section>
    );
  }

  return (
    <section id="futureEventList" css={classes.root}>
      <div css={classes.headline}>
        {changeStyleWithHosting().pageSettings.topPage.upcomingHeadline
          .isDisplay ? (
          <Headline upcomingEventType={props.upcomingEventType} />
        ) : null}
      </div>

      <div className="mainList" css={classes.eventList}>
        {/*
         // @ts-expect-error TS7005 */}
        {mainList}
      </div>
      <div className="subList" css={classes.eventList}>
        {/*
         // @ts-expect-error TS7005 */}
        {subList}
      </div>

      <div css={classes.button}>
        <LinkButton url={`/events`} label={t("event.top.eventList")} />
      </div>
    </section>
  );
};

const styles = () => {
  return {
    root: css`
      max-width: 980px;
      width: 90%;
      margin: 0 auto;
    `,
    headline: css`
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
      }
    `,
    eventList: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &.subList:after {
        content: "";
        display: block;
        width: 47.5%;
        @media screen and (min-width: 768px) {
          width: 31%;
        }
      }
    `,
    largeEvent: css`
      width: 100%;
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        width: 48%;
      }
    `,
    smallEvent: css`
      width: 47.5%;
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        width: 31%;
      }
    `,
    button: css`
      max-width: 680px;
      margin: 0 auto;
    `,
    noEvent: css`
      min-height: calc(100vh - 65px - 190px);
      color: #888888;
      font-size: 20px;
      text-align: center;
      padding-top: 60px;
      @media screen and (min-width: 768px) {
        font-size: 24px;
      }
    `,
  };
};
