/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState } from "react";

import { CART_EXPIRATION_MINUTES } from "constants/purchase";
import { useI18n, I18nTrans } from "hooks/i18n/i18n";

import styled from "@emotion/styled";
import { LinkButton } from "../../../styles";
import { EventInfo } from "utility";
import type { TicketData } from "@spwn/types/firebase/database";
import { GoToConfirm } from "tmp/goto/GoToConfirm";
import { TextLink } from "ui/TextLink";

interface Props {
  event: EventInfo;
  disenableAddToCart: boolean;
  displayTickets: TicketData[];
  applyLottery: () => void;
  checkInputField: () => boolean;
  addToCart: () => void;
}

const Container = styled.div`
  > .button {
    @media screen and (min-width: 768px) {
      width: 500px;
      margin: 0 auto;
    }
  }
  .caution {
    margin-bottom: 20px;
    color: #f00;
    font-size: 14px;
    text-align: left;
    line-height: 1.5em;
    @media screen and (min-width: 768px) {
      text-align: center;
    }
  }
`;

const EventTicketActionButton: React.FC<Props> = (props) => {
  const [isRead, setIsRead] = useState<boolean>(false);
  let isStreamingTicketListed = false;

  const { t } = useI18n();

  props.displayTickets.forEach((ticket, _i) => {
    if (ticket.streamingType === "Live" || ticket.streamingType === "VOD") {
      isStreamingTicketListed = true;
    }
  });

  return (
    <Container>
      {props.event.isLotteryTicketSellingMode ? (
        <React.Fragment>
          <div style={{ margin: "64px 0 16px" }}>
            <GoToConfirm />
          </div>

          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
              margin-bottom: 16px;
              font-size: 14px;
              * {
                cursor: pointer;
              }
            `}
          >
            <input
              type="checkbox"
              id="horns"
              onClick={() => setIsRead(!isRead)}
            />
            <label htmlFor="horns">利用規約に同意する</label>
          </div>

          <LinkButton
            className={`button ${!isRead && "disenable"}`}
            onClick={() => props.checkInputField() && props.applyLottery()}
          >
            {t("event.ticket.buttons.applyLottery")}
          </LinkButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {isStreamingTicketListed ? (
            <p className="caution">
              <I18nTrans i18nKey="event.ticket.cautions.streamingTest">
                ※ライブ配信チケットは
                <TextLink href={`/streaming-test`}>
                  {t("event.ticket.cautions.this")}
                </TextLink>
                のページで視聴できることをご確認のうえ、ご購入ください。
              </I18nTrans>
            </p>
          ) : null}

          <p className="caution">
            {t("event.ticket.cautions.cartExpiration", {
              cartExpiratonMinutes: CART_EXPIRATION_MINUTES,
            })}
          </p>
          <LinkButton
            className={`button ${props.disenableAddToCart ? "disenable" : ""}`}
            onClick={() => props.checkInputField() && props.addToCart()}
          >
            {t("event.ticket.buttons.addToCart")}
          </LinkButton>
        </React.Fragment>
      )}
    </Container>
  );
};
export default EventTicketActionButton;
