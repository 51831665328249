/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useMemo } from "react";

import { useGetEventList } from "hooks/event/useGetEventList";
import { useI18n } from "hooks/i18n/i18n";
import { useEventCategoryQuery } from "hooks/QueryString";
import { Breadcrumb } from "components/atoms/Breadcrumb";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { MainContents } from "components/templates/MainContents";
import { EventCard } from "features/EventCard";
import { changeStyleWithHosting } from "utility/hosting";

export const BrandEventList: React.FC = () => {
  const { t } = useI18n();
  const { category } = useEventCategoryQuery();

  const { data: events, isLoading } = useGetEventList({
    type: "coming",
  });

  const breadcrumbText =
    changeStyleWithHosting().commonSettings.menu.event.text;

  const classesEventListDesign = eventListDesign();

  const filteredEvents = useMemo(() => {
    if (category) {
      return (events ?? []).filter((event) =>
        event?.categories?.includes(category)
      );
    } else {
      return events ?? [];
    }
  }, [category, events]);

  return isLoading ? (
    <LoaderLayout />
  ) : (
    <MainContents>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, breadcrumbText],
        ]}
      />

      {filteredEvents?.length > 0 ? (
        <div css={classesEventListDesign.root}>
          {filteredEvents.map((event) => {
            return (
              <React.Fragment key={event._id}>
                <EventCard event={event} />
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <div css={classesEventListDesign.eventNothingLabel}>
          {t("event.noEvent.noEvent")}
        </div>
      )}
    </MainContents>
  );
};

const eventListDesign = () => {
  return {
    root: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 90%;
      max-width: 980px;
      padding: 40px 0 80px;
      margin: 0 auto;
      &::after {
        content: "";
        display: block;
        width: 31%;
        margin-bottom: 20px;
      }
      .event-card {
        width: 47.5%;
        margin-bottom: 20px;
        @media screen and (min-width: 768px) {
          width: 31%;
        }
      }
    `,
    eventNothingLabel: css`
      height: calc(100vh - (255px + 194px));
      padding: 10px;
      margin-top: 100px;
      color: #888888;
      font-size: 20px;
      text-align: center;
      @media screen and (min-width: 768px) {
        font-size: 24px;
      }
    `,
  };
};
