/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { ticketActions } from "modules/ticket";
import { scrollTop } from "utility/index";
import { BreadcrumbArea } from "components/common/Link";
import { purchaseActions } from "modules/purchase";
import {
  AccountReceipt,
  ReceiptData,
  ReceiptBreakdownItem,
} from "./receipt/AccountReceipt";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { useI18n } from "hooks/i18n/i18n";
import { SubContents } from "components/templates/SubContents";
import { breadcrumbMixin, myPageHistoryMixin } from "../../styles";
import styled from "@emotion/styled";
import type { MyProductData } from "@spwn/types";
import type { ActiveTransaction } from "@spwn/types/firebase/firestore";
import {
  isOrderTransaction,
  hasVariantOrderLineItem,
} from "features/orderModel";
import { PurchaseHistoryListItem } from "../../features/PurchaseHistoryListItem";
import { useCreateUnprocessedOrderConfirmModal } from "features/useCreateUnprocessedOrderConfirmModal";

/**
 * FIXME クラスそもそも使われてないのありそう
 */
const Container = styled.div`
  ${myPageHistoryMixin};
  ${breadcrumbMixin};

  min-height: calc(100vh - 436px);
  padding-bottom: 80px;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    min-height: calc(100vh - 280px);
    margin: 0 auto;
  }
  .heading,
  .settlement_history_item_layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 768px) {
      width: 740px;
      margin: 0 auto;
    }
  }
  .settlement_history_heading {
    padding: 4px 5%;
    background-color: #8c8c8c;
    @media screen and (min-width: 768px) {
      padding: 5px 5%;
    }
    p {
      color: #fff;
      font-size: 12px;
      @media screen and (min-width: 768px) {
        font-size: 14px;
        text-align: left;
      }
    }
    p:first-of-type {
      width: 70%;
    }
    p:last-child {
      width: 25%;
      text-align: center;
    }
  }
  .settlement_history_item {
    padding: 10px 5%;
    border-bottom: 1px solid #e6e5e6;
    .content {
      width: 70%;
      .date {
        margin-bottom: 5px;
        color: #8c8c8c;
        font-size: 12px;
        @media screen and (min-width: 768px) {
          margin-bottom: 10px;
        }
      }
      .item {
        font-size: 14px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
      }
    }
    .amount {
      width: 100%;
      text-align: center;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
      span {
        font-size: 14px;
      }
    }
  }
`;

type Settlement = ActiveTransaction & {
  products: ActiveTransaction["products"] & MyProductData[];
};

export const AccountSettlementHistory: React.FC = () => {
  const initialRecipeData = {
    effectiveDate: null,
    spwnId: null,
    userName: null,
    orderId: null,
    shippingFee: 0,
    serviceFee: 0,
    settlementFee: 0,
    total: 0,
    breakdown: null,
  };

  const { t } = useI18n();
  const classes = styles();
  const dispatch = useDispatch();
  const { loading, createConfirmCvsPaymentData, renderConfirmCvsPayment } =
    useCreateUnprocessedOrderConfirmModal();

  const user = useSelector((state: Store) => state.auth.user);
  const isLoggedIn = useSelector((state: Store) => state.auth.user.uid);
  const activeTransactionMap = useSelector(
    (state: Store) => state.purchase.activeTransactionMap
  );

  const [settlementHistory, setSettlementHistory] =
    // @ts-expect-error TS2345
    useState<Settlement[]>(null);
  const [isReceipt, setIsReceipt] = useState<boolean>(false);
  const [receiptData, setReceiptData] =
    // @ts-expect-error TS2345
    useState<ReceiptData>(initialRecipeData);

  useEffect(() => {
    // @ts-expect-error TS2345
    if (!activeTransactionMap) return setSettlementHistory(null);
    const result = Object.values(activeTransactionMap)
      /**
       * @see https://github.com/balus-co-ltd/spwn/issues/1748#issuecomment-1163875595
       * @see https://github.com/balus-co-ltd/spwn/blob/b69d9da9ffa53de41729bd6a08138a86bc6e4a9a/packages/portal/src/utility/purchase.tsx#L127-L135
       * @description
       * statusが"PAYSUCCESS"の場合、決済履歴に表示する。
       * お支払い期限を過ぎても、オーダーのステータスが「CANCEL」にならないケースがある。
       * statusが"UNPROCESS", "AUTHPROCESS"の場合は、isCancelledがfalseかをチェックして決済履歴に表示する。
       */
      .filter((el) => {
        return (
          // @ts-expect-error TS2345
          ["PAYSUCCESS"].includes(el.status) ||
          // @ts-expect-error TS2345
          (["UNPROCESS", "AUTHPROCESS"].includes(el.status) &&
            el.isCancelled === false)
        );
      })
      /**
       * HOTFIX
       * productsフィールドがないものを除外。
       * 除外処理を入れているのは、移行処理でスタッフ分を移行していなかったため。対象orderが表示されないのは許容。
       * スタッフ分もproductsを移行したらこのfilter処理は不要。
       * @see https://github.com/balus-co-ltd/spwn/issues/323
       */
      .filter((el) => {
        return isOrderTransaction(el)
          ? el.lineItems && el.lineItems.length > 0
          : el.products && el.products.length > 0;
      })
      .sort(({ orderId: first }, { orderId: second }) => {
        return -(Number(first) - Number(second));
      }) as Settlement[];
    setSettlementHistory(result);
  }, [activeTransactionMap]);

  useEffect(() => {
    dispatch(ticketActions.fetchMyTickets.started({}));
    dispatch(purchaseActions.fetchUserActiveTransactions.started());
  }, [isLoggedIn, dispatch]);

  const setSelectedSettlementHistory = (orderId: number, data: Settlement) => {
    const effectiveDate = new Date(orderId);
    const spwnId = user.uid;
    const userName = user.displayName;

    const shippingFee = data.shippingFee || 0; // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
    const serviceFee = data.serviceFee || 0; // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
    const settlementFee = data.settlementFee || 0; // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing

    const breakdown: ReceiptBreakdownItem[] = isOrderTransaction(data)
      ? data.lineItems.map((p) => ({
          eventTitle: null,
          productType: "goods" as const,
          name: p.name,
          price: p.price,
          quantity: p.quantity,
        }))
      : data.products.map((p) => ({
          eventTitle: p.eventTitle,
          productType: p.productType,
          name: p.name,
          price: p.price_jpy,
          quantity: p.count,
        }));

    // HOTFIX: totalPriceが存在しないケースが有るので計算する
    // const total = data.totalPrice;
    const total =
      breakdown.reduce((acc, cur) => {
        return acc + cur.price * cur.quantity;
      }, 0) +
      shippingFee +
      serviceFee +
      settlementFee;

    setIsReceipt(true);
    setReceiptData({
      effectiveDate,
      spwnId,
      // @ts-expect-error TS2322
      userName,
      orderId,
      shippingFee,
      serviceFee,
      settlementFee,
      total,
      breakdown,
    });

    scrollTop();
  };

  const resetState = () => {
    setIsReceipt(false);
    setReceiptData({
      // @ts-expect-error TS2322
      effectiveDate: null,
      // @ts-expect-error TS2322
      spwnId: null,
      // @ts-expect-error TS2322
      userName: null,
      // @ts-expect-error TS2322
      orderId: null,
      shippingFee: 0,
      serviceFee: 0,
      settlementFee: 0,
      total: 0,
      // @ts-expect-error TS2322
      breakdown: null,
    });
    scrollTop();
  };

  if (!settlementHistory || loading) {
    return (
      <div id="contents-layout">
        <LoaderLayout />
      </div>
    );
  }

  const content = settlementHistory.map((settlement) => {
    const { orderId, status } = settlement;
    // if orderId is not correct, don't render receipt. 2019年代だとhotfixで適当な文字列の場合がある。
    const isPurchased = !isNaN(Number(orderId)) && status === "PAYSUCCESS";
    const orderedItemList = isOrderTransaction(settlement)
      ? settlement.lineItems.map((p) => ({
          name: p.name,
          variantName: hasVariantOrderLineItem(p) ? p.variantName : "",
          quantity: p.quantity,
        }))
      : settlement.products.map((p) => ({
          name: p.name,
          variantName: p.subClassName,
          quantity: p.count,
        }));

    return (
      <React.Fragment key={orderId}>
        <PurchaseHistoryListItem
          // @ts-expect-error TS18047
          orderedAt={settlement.createdAt.toDate()}
          orderedItemList={orderedItemList}
          totalAmount={settlement.totalPrice}
          status={{
            isPurchased,
            onClickAction: isPurchased
              ? // @ts-expect-error TS2345
                () => setSelectedSettlementHistory(orderId, settlement)
              : () => createConfirmCvsPaymentData(settlement),
          }}
        />
      </React.Fragment>
    );
  });

  /**
   * 領収書を表示する
   */
  if (isReceipt) {
    return <AccountReceipt receiptData={receiptData} resetState={resetState} />;
  }

  return (
    <React.Fragment>
      {renderConfirmCvsPayment()}
      <SubContents>
        <Container id="contents" css={classes.root}>
          <BreadcrumbArea
            // @ts-expect-error TS2322
            paths={[
              ["/", "ホーム"],
              ["/account", t("common.routes.account")],
              [null, t("common.routes.settlementHistory")],
            ]}
          />

          <div css={classes.headline}>
            <div css={classes.headlineLayout}>
              <p className="orderDate">
                {t("settlementHistory.orderDatePaymentDetails")}
              </p>
              <p className="paymentAmount">
                {t("settlementHistory.settlementAmount")}
              </p>
              <p className="receipt">領収書</p>
            </div>
          </div>

          {content}
        </Container>
      </SubContents>
    </React.Fragment>
  );
};

const styles = () => {
  return {
    root: css`
      width: 100%;
      max-width: 1800px;
      min-height: calc(100vh - 436px);
      padding-bottom: 80px;
      margin: 0 auto;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        min-height: calc(100vh - 280px);
      }
    `,
    headline: css`
      background-color: #8c8c8c;
    `,
    headlineLayout: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 95%;
      padding: 8px 0;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        justify-content: start;
        max-width: 1080px;
      }
      p {
        color: #fff;
        font-size: 14px;
        text-align: center;
      }
      .orderDate {
        width: 60%;
      }
      .paymentAmount {
        width: 20%;
      }
      .receipt {
        width: 20%;
      }
    `,
    historyItem: css`
      border-bottom: 1px solid #e6e5e6;
    `,
    historyItemLayout: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 95%;
      padding: 8px 0;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        justify-content: start;
        max-width: 1080px;
      }
      .content {
        width: 60%;
        .date {
          color: #8c8c8c;
          font-size: 14px;
          line-height: 1.3em;
        }
        .item {
          font-size: 14px;
          font-weight: bold;
          line-height: 1.3em;
        }
      }
      .amount {
        width: 20%;
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        line-height: 1.3em;
      }
      .receipt {
        width: 20%;
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        span {
          display: inline-block;
          padding: 4px;
          background-color: #8c8c8c;
          border-radius: 50%;
          cursor: pointer;
          svg {
            width: 20px;
            height: 20px;
            fill: #fff;
          }
        }
      }
    `,
  };
};
