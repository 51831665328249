/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { FC } from "react";
import { Link } from "react-router-dom";

export const PortalTopLinkButton: FC<{
  href: string;
  label: string;
}> = ({ href, label }) => {
  return (
    <Link
      css={css`
        display: block;
        width: 100%;
        max-width: 880px;
        padding: 10px 0;
        margin: 0 auto;
        color: #fff;
        font-weight: bold;
        text-align: center;
        line-height: 1.5em;
        background-color: #00c2ad;
        cursor: pointer;
        @media screen and (min-width: 768px) {
          padding: 16px 0;
        }
      `}
      to={href}
    >
      {label}
    </Link>
  );
};
