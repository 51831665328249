import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../../store";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import { replaceBr } from "../../utility";
import { BreadcrumbArea } from "components/common/Link";
import { ContentsLayout, SubPageSection } from "../../styles";
import type { Answer } from "@spwn/types/firebase/firestore";

interface Props {}
interface State {
  FAQs: Answer[];
}

class Question extends Component<Props, State> {
  _unsubscriber = null;

  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    this.startFAQWatcher();
    this.state = {
      FAQs: [],
    };
  }

  componentWillUnmount(): void {
    if (this._unsubscriber !== null) {
      // @ts-expect-error TS2349
      this._unsubscriber();
      this._unsubscriber = null;
    }
  }

  startFAQWatcher = () => {
    if (this._unsubscriber === null) {
      // @ts-expect-error TS2322
      this._unsubscriber = firebase
        .firestore()
        .collection("/FAQ/public/answers")
        .where("isReleased", "==", true)
        .onSnapshot((querySnapshot) => {
          // @ts-expect-error TS7034
          const faqs = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            console.log(data);
            faqs.push(data);
          });
          // @ts-expect-error TS7005
          this.setState({ FAQs: faqs });
        });
    }
  };

  render() {
    const faqs = this.state.FAQs.filter((data) => {
      return data.isReleased;
    })
      .sort((a, b) => {
        return a.priority - b.priority;
      })
      .map((data) => {
        return (
          <article
            key={data.title}
            className="text_block"
            style={{ whiteSpace: "pre-line" }}
          >
            <h3>{replaceBr(data.title)}</h3>
            <p>{replaceBr(data.ans)}</p>
          </article>
        );
      });

    return (
      <SubPageSection id="contents">
        <BreadcrumbArea
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            [null, "よくある質問"],
          ]}
        />
        <ContentsLayout>
          <h2>よくある質問</h2>
          {faqs}
        </ContentsLayout>
      </SubPageSection>
    );
  }
}

const mapStateToProps = (_state: Store) => {
  return {};
};

const mapDispatchToProps = (_dispatch: Dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Question);
