/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useI18n } from "hooks/i18n/i18n";
import type { Ticket } from "@spwn/types/firebase/firestore";

interface Props {
  canWatch: boolean;
  isDonationEvent?: boolean;
  ticket: Ticket;
}
export const LiveListItemPrice: React.FC<Props> = (props) => {
  const classes = styles({ canWatch: props.canWatch });

  const { t } = useI18n();
  const isFreeTicket = Number(props.ticket.price_jpy) <= 0;
  /** @deprecated 明らかに意図的にバウチャーチケットとして設定されているケース(saleType=voucherOnly移行推奨) */
  const isVoucherPrice = Number(props.ticket.price_jpy) === 1;

  if (props.ticket.saleType === "voucherOnly") return null;

  if (
    (props.canWatch && !props.isDonationEvent) ||
    isFreeTicket ||
    isVoucherPrice
  )
    return null;

  return (
    <div css={classes.root}>
      <p>{t("event.archiveVideo.sellingPrice")}</p>
      <p>
        {props.ticket.price_jpy.toLocaleString()}
        {t("pawCharge.yen")}
      </p>
    </div>
  );
};

interface EventLiveItemPriceProps {
  canWatch: boolean;
}
const styles = (props: EventLiveItemPriceProps) => {
  if (props.canWatch) {
    return {
      root: css`
        margin-bottom: 12px;
        p {
          font-size: 16px;
          font-weight: bold;
          text-align: right;
        }
      `,
    };
  }

  return {
    root: css`
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      p {
        font-size: 16px;
        font-weight: bold;
      }
    `,
  };
};
