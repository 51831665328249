import styled from "@emotion/styled";

export const ContentsLayout = styled.div`
  width: 90%;
  margin: 40px auto 0;
  @media screen and (min-width: 768px) {
    width: 980px;
    max-width: 90%;
  }
`;

ContentsLayout.displayName = "ContentsLayout";
