/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../../store";
import { FullScreenDialog } from "components/common/Modal";
import { modalActions, ActionData } from "../../modules/modal";
import { authActions } from "../../modules/auth";
import { Close } from "mdi-material-ui";
import { push } from "connected-react-router";
import FacebookLoginButton from "../../components/FacebookLoginButton";
import GoogleLoginButton from "../../components/GoogleLoginButton";
import DocomoLoginButton from "../../components/DocomoLoginButton";
import AppleLoginButton from "../../components/AppleLoginButton";
import { ModalFallbackLogin } from "./ModalFallbackLogin";
import { changeStyleWithHosting } from "utility/hosting";
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { I18nTrans } from "hooks/i18n/i18n";
import { Button } from "components/atoms/button/Button";
import { TextLink } from "ui/TextLink";

interface Props extends WithTranslation {
  t: TFunction;
  // @ts-expect-error TS7008
  i18n;
  isOpen: boolean;
  isSignup: boolean;
  toggleLogin: (isSignup?: boolean) => unknown;
  // @ts-expect-error TS7006
  login: (provider) => unknown;
  loginWithDocomo: () => unknown;
  // @ts-expect-error TS7006
  loginWithEmail: (params) => unknown;
  // @ts-expect-error TS7006
  createUserWithEmail: (params) => unknown;
  // @ts-expect-error TS7006
  toggleActionModal: (payload) => void;
  // @ts-expect-error TS7051
  changeRoute: (string) => void;
  toggleModal: () => void;
}
interface State {
  email: string;
  pass: string;
  fallbackLoginModalOpen: boolean;
}

/**
 * ログインコンポーネント（モーダル）
 *
 * NOTE: Twitterログインは復活させる可能性もあるので、削除ではなくコメントアウトとしている
 * @see https://github.com/balus-co-ltd/spwn/issues/3427 Twitterログイン停止経緯
 */
class ModalLogin extends Component<Props, State> {
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      pass: "",
      fallbackLoginModalOpen: false,
    };
  }

  // @ts-expect-error TS7006
  changeState = (obj) => {
    this.setState({ ...this.state, ...obj });
  };

  loginWithEmail = () => {
    const params = { email: this.state.email, pass: this.state.pass };
    this.props.loginWithEmail(params);
  };

  createUserWithEmail = () => {
    const params = { email: this.state.email, pass: this.state.pass };
    this.props.createUserWithEmail(params);
  };

  closeModal = () => {
    this.props.toggleLogin();
  };

  openFallbackLoginModal = () => {
    this.setState((prev) => ({ ...prev, fallbackLoginModalOpen: true }));
  };
  closeFallbackLoginModal = () => {
    this.setState((prev) => ({ ...prev, fallbackLoginModalOpen: false }));
  };

  resetPassword = (t: TFunction) => {
    const actionData: ActionData = {
      caption: t("login.passwordReset"),
      contents: {
        email: {
          title: "email",
          tagType: "input",
          placeHolderAttr: t("login.email"),
          typeAttr: "email",
          classAttr: "",
        },
      },
      btnMsg: t("login.buttons.passwordResetMail"),
      callbackTarget: "resetPassword",
    };
    this.props.toggleActionModal(actionData);
  };

  render() {
    const { isOpen, isSignup, t } = this.props;
    const classes = styles();
    const _isTwitterMethod =
      changeStyleWithHosting().commonSettings.auth.loginMethod.twitter;
    const isFacebookMethod =
      changeStyleWithHosting().commonSettings.auth.loginMethod.facebook;
    const isLoginButtonDisabled = !this.state.email || !this.state.pass;

    return (
      <FullScreenDialog open={isOpen} onBackdropClick={this.closeModal}>
        <div css={classes.root}>
          <div css={classes.closeIcon}>
            <Close onClick={this.closeModal} />
          </div>

          <div className="method" css={classes.layout}>
            <h2>{isSignup ? "SIGNUP" : "LOGIN"}</h2>

            <div css={classes.form}>
              {!isSignup && (
                <p css={classes.twitterNotice}>
                  <I18nTrans i18nKey="login.twitterNotice">
                    Twitter
                    APIの仕様変更に伴い、Twitterアカウントでのログインを停止しました。Twitterアカウントでのログインをご利用だった方は
                    <TextLink
                      href={
                        "https://spwn.zendesk.com/hc/ja/articles/17980653563673"
                      }
                    >
                      こちら
                    </TextLink>
                  </I18nTrans>
                </p>
              )}

              <div css={classes.item}>
                <p>{t("login.email")}</p>
                <input
                  type="text"
                  name="email"
                  onChange={(e) => this.changeState({ email: e.target.value })}
                />
              </div>

              <div css={classes.item}>
                <p>{t("login.password")}</p>
                <input
                  type="password"
                  name="pass"
                  onChange={(e) => this.changeState({ pass: e.target.value })}
                />
              </div>

              <div css={classes.button}>
                <Button
                  text={
                    isSignup
                      ? t("login.buttons.registration")
                      : t("login.buttons.login")
                  }
                  disabled={isLoginButtonDisabled}
                  onClick={
                    isSignup ? this.createUserWithEmail : this.loginWithEmail
                  }
                />

                {
                  // パスワードを忘れた方はログイン時のみ表示
                  !isSignup && (
                    <p onClick={() => this.resetPassword(t)}>
                      {t("login.passwordReset")}
                    </p>
                  )
                }
              </div>

              <p css={classes.or}>{t("login.or")}</p>

              {
                // ログインに失敗する方への導線はログイン時のみ表示
                !isSignup && (
                  <div>
                    <button
                      css={css`
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        margin-bottom: 1rem;
                        color: #757575;
                        font-size: 14px;
                        text-align: center;
                        text-decoration: underline;
                        cursor: pointer;
                      `}
                      onClick={this.openFallbackLoginModal}
                    >
                      {t("login.failedToLogin")}
                    </button>
                  </div>
                )
              }

              {isSignup && (
                <p css={classes.caution}>
                  <I18nTrans i18nKey="login.notice" />
                </p>
              )}
              <div css={classes.method}>
                <div
                  css={css`
                    margin-bottom: 20px;
                  `}
                >
                  <GoogleLoginButton
                    onClick={this.props.login}
                    isSignup={isSignup}
                  />
                </div>
                {/* エレメントを削除すると新規登録画面とログイン画面両方からボタンが消えるので、フラグを追加する。 */}
                {/* <div
                  css={css`
                    margin-bottom: 20px;
                  `}
                >
                  {isTwitterMethod && !isSignup && (
                    <TwitterLoginButton
                      onClick={this.props.login}
                      isSignup={isSignup}
                    />
                  )}
                </div> */}
                {/* ---------------------------------------------------------------- */}
                <div
                  css={css`
                    margin-bottom: 20px;
                  `}
                >
                  <DocomoLoginButton
                    onClick={this.props.loginWithDocomo}
                    isSignup={isSignup}
                  />
                </div>
                <div
                  css={css`
                    margin-bottom: 20px;
                  `}
                >
                  {!isSignup && (
                    <AppleLoginButton
                      onClick={this.props.login}
                      isSignup={isSignup}
                    />
                  )}
                </div>
                <div
                  css={css`
                    margin-bottom: 20px;
                  `}
                >
                  {isFacebookMethod && !isSignup && (
                    <FacebookLoginButton
                      onClick={this.props.login}
                      isSignup={isSignup}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="button" css={classes.layout}>
            <h2>{isSignup ? "LOGIN" : "SIGNUP"}</h2>
            <div css={classes.button}>
              <Button
                text={
                  isSignup
                    ? t("login.buttons.login")
                    : t("login.buttons.registration")
                }
                onClick={
                  isSignup
                    ? () => {
                        this.closeModal();
                        this.props.toggleLogin(false);
                      }
                    : () => {
                        this.closeModal();
                        this.props.toggleLogin(true);
                      }
                }
              />
            </div>
          </div>
        </div>
        <ModalFallbackLogin
          open={this.state.fallbackLoginModalOpen}
          onClose={this.closeFallbackLoginModal}
          parentOnClose={this.props.toggleModal}
        />
      </FullScreenDialog>
    );
  }
  // @ts-expect-error TS7006
  clickMenuItem = (link) => {
    this.props.changeRoute(link);
    this.props.toggleModal();
  };
}

const mapStateToProps = (state: Store) => {
  return {
    isOpen: state.modal.isOpenLogin,
    isSignup: state.modal.isSignup,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    // @ts-expect-error TS7006
    toggleLogin: (isSignup) => {
      dispatch(modalActions.toggleLogin({ isSignup }));
    },
    // @ts-expect-error TS7006
    login: (provider) => {
      dispatch(authActions.login.started(provider));
    },
    // @ts-expect-error TS7006
    loginWithDocomo: (_provider) => {
      dispatch(authActions.loginWithDocomo.started({}));
    },
    // @ts-expect-error TS7006
    loginWithEmail: (params) => {
      dispatch(authActions.loginWithEmail.started(params));
    },
    // @ts-expect-error TS7006
    createUserWithEmail: (params) => {
      dispatch(authActions.createUserWithEmail.started(params));
    },
    // @ts-expect-error TS7006
    toggleActionModal: (payload) => {
      dispatch(modalActions.toggleActionModal(payload));
    },

    // @ts-expect-error TS7006
    changeRoute: (link) => {
      dispatch(push(link));
    },
    toggleModal: () => {
      dispatch(modalActions.toggleLoginMethod());
    },
  };
};
const TransModalLogin = withTranslation()(ModalLogin);

// @ts-expect-error TS2345
export default connect(mapStateToProps, mapDispatchToProps)(TransModalLogin);

const styles = () => {
  return {
    root: css`
      position: relative;
      padding: 75px 0;
    `,
    closeIcon: css`
      position: absolute;
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      svg {
        width: 100%;
        height: 100%;
      }
    `,
    layout: css`
      width: 95%;
      max-width: 980px;
      margin: 0 auto;
      &.method {
        margin-bottom: 80px;
      }
      h2 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        font-style: normal;
        color: #000;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        line-height: 1em;
        letter-spacing: 1px;
        white-space: nowrap;
        @media screen and (min-width: 768px) {
          margin-bottom: 40px;
          font-size: 30px;
        }
        &:after,
        &:before {
          content: "";
          width: 30%;
          background: linear-gradient(
            transparent 40%,
            currentColor 40%,
            currentColor calc(40% + 2px),
            transparent calc(40% + 2px)
          );
          background-clip: padding;
          @media screen and (min-width: 768px) {
            width: 40%;
          }
        }
        &:after {
          margin-left: 4%;
        }
        &:before {
          margin-right: 4%;
        }
      }
    `,
    caution: css`
      font-size: 14px;
      text-align: center;
      line-height: 1.5em;
      margin-bottom: 20px;
    `,
    method: css`
      width: 100%;
      max-width: 560px;
      margin: 0 auto 64px;
    `,
    form: css`
      width: 100%;
      max-width: 560px;
      margin: 0 auto;
    `,
    item: css`
      margin-bottom: 16px;
      p {
        margin-bottom: 8px;
        font-size: 14px;
      }
      input {
        width: 100%;
        padding: 8px;
        font-size: 16px;
        border: 1px solid #d2d2d2;
        appearance: none;
      }
    `,
    button: css`
      width: 100%;
      max-width: 460px;
      margin: 32px auto 32px;
      p {
        margin-top: 16px;
        color: #757575;
        font-size: 14px;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
      }
    `,
    or: css`
      margin-bottom: 20px;
      text-align: center;
    `,
    twitterNotice: css`
      margin-bottom: 24px;
      text-align: center;
      font-size: 14px;
      line-height: 1.5em;
    `,
  };
};
