import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { InitialCardInfo } from "./PawCharge";
import { CardInfo, purchaseActions } from "../../../modules/purchase";

import { getCardTokenGMO } from "../../../utility/gmo";
import { useI18n } from "hooks/i18n/i18n";
import { fontDinMixin, ModalHeader } from "../../../styles";
import styled from "@emotion/styled";
import { modalActions } from "../../../modules/modal";

const InputPaymentMethodsPaw = styled.p`
  @media screen and (min-width: 768px) {
    font-size: 40px;
  }
  &:after {
    width: 25px;
    height: 25px;
    @media screen and (min-width: 768px) {
      width: 30px;
      height: 30px;
    }
  }
`;

const Container = styled.div`
  .orderPawLayout {
    padding: 20px 0 40px;
    border-top: 1px solid #e6e5e6;
    border-bottom: 1px solid #e6e5e6;
    @media screen and (min-width: 768px) {
      padding: 40px 0;
    }
    .orderPaw {
      max-width: 640px;
      width: 90%;
      margin: 0 auto;
    }
    .selectPaw {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 8%;
      margin-bottom: 20px;
      border: 1px solid #e6e5e6;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        padding: 25px 40px;
      }
      .price {
        font-size: 20px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 25px;
        }
      }
    }
    .purchaseDetailChange {
      display: block;
      width: 120px;
      padding: 8px 0;
      margin-bottom: 25px;
      color: #fff;
      font-size: 12px;
      text-align: center;
      letter-spacing: 1px;
      background-color: #62b5e5;
      border: none;
      cursor: pointer;
      appearance: none;
      @media screen and (min-width: 768px) {
        width: 170px;
        font-size: 14px;
        transition: 0.3s;
      }
    }
    .totalAmount {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .heading {
        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }
      .price {
        font-size: 25px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 30px;
        }
        span {
          margin-left: 5px;
          font-size: 12px;
        }
      }
    }
  }
`;

const ModalContent = styled.div`
  max-width: 640px;
  width: 90%;
  padding: 40px 0 70px;
  margin: 0 auto;
  h2 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
    }
  }
`;

const ModalFooter = styled.div`
  .cautionText {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
  .buttonForLink {
    display: block;
    max-width: 560px;
    width: 100%;
    padding: 12px 0;
    margin: 0 auto 20px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 1.2em;
    letter-spacing: 1px;
    background-color: #00c2ae;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      padding: 15px 0;
      margin: 0 auto 30px;
      letter-spacing: 2px;
    }
  }
  .backToList {
    text-align: center;
    span {
      color: #7c7c7c;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const CardInfoInputField = styled.div`
  margin-bottom: 40px;
  @media screen and (min-width: 768px) {
    margin-bottom: 60px;
  }
  .checkboxLayout {
    display: block;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    input {
      display: none;
      &:checked + .checkbox {
        background-color: #f2fdfd;
        border: 2px solid #009a9a;
      }
      &:checked + .checkbox:before {
        border: 1px solid #009a9a;
      }
      &:checked + .checkbox:after {
        opacity: 1;
      }
    }
    .checkbox {
      position: relative;
      display: flex;
      width: 100%;
      padding: 10px 4% 10px 15%;
      font-size: 15px;
      font-weight: bold;
      background-color: #fff;
      border: 2px solid #dcdbdc;
      transition: 0.3s;
      @media screen and (min-width: 768px) {
        padding: 14px 40px 14px 65px;
        font-size: 18px;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 11px;
        left: 14px;
        width: 20px;
        height: 20px;
        border: 1px solid #999;
        border-radius: 50%;
        @media screen and (min-width: 768px) {
          top: 15px;
          width: 25px;
          height: 25px;
        }
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 14px;
        left: 17px;
        width: 14px;
        height: 14px;
        background: #009a9a;
        border-radius: 50%;
        opacity: 0;
        transition: 0.3s;
        @media screen and (min-width: 768px) {
          top: 18px;
          width: 19px;
          height: 19px;
        }
      }
    }
  }
  .text {
    line-height: 1.5em;
    a {
      color: #00c2ae;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const CardInfoView = styled.div`
  margin-bottom: 60px;
  @media screen and (min-width: 768px) {
    margin-bottom: 40px;
  }
  .cardInfo {
    &:not(:last-child) {
      margin-bottom: 25px;
      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
      }
    }
    .heading {
      font-size: 14px;
      margin-bottom: 15px;
    }
    select {
      width: 100%;
      padding: 10px 28px 10px 10px;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1px;
      border: 1px solid #dedede;
      background: url("/images/select_pulldown.png") right 50% no-repeat;
      background-size: 29px, 100%;
      border-radius: 0;
      appearance: none;
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
    }
    input {
      width: 100%;
      padding: 10px;
      ${fontDinMixin};
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      letter-spacing: 1px;
      border: 1px solid #d2d2d2;
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
      &[name="cardNum"],
      &[name="cardName"] {
        text-align: left;
      }
      &[name="code"],
      &[name="cardMonth"],
      &[name="cardYear"] {
        width: 20%;
        @media screen and (min-width: 768px) {
          width: 70px;
        }
      }
      &[name="cardMonth"] {
        margin-right: 15px;
      }
    }
    .formError {
      border: 1px solid #ff0000;
      background-color: rgba(#ff0000, 0.1);
    }
    .paymentMethods {
      font-size: 18px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
    }
    .confirmText {
      ${fontDinMixin};
      font-size: 25px;
      &.normalFont {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
`;

interface Props {
  selectedPaw: number;
  selectedPawId: string;
  useCardInfo: InitialCardInfo;
  cardInfoList: CardInfo[];
  isConfirmOrder: boolean;
  selectCreditCard: string;
  toggleModal: () => void;
  backToPawListPage: () => void;
  backToInputPaymentMethods: () => void;
  // @ts-expect-error TS7051
  setUseCardInfo: (any) => void;
  setConfirmOrder: (boolean: boolean) => void;
  setSelectCreditCard: (string: string) => void;
}

const InputPaymentMethods: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { t } = useI18n();

  // @ts-expect-error TS7006
  const changeUseCardInfoState = (value, key) => {
    props.setUseCardInfo({ ...props.useCardInfo, [key]: value });
  };

  const goToConfirmOrderPage = () => {
    const errorElement = Array.prototype.slice.call(
      document.getElementsByClassName("formError")
    );
    errorElement.forEach((el) => {
      el.classList.remove("formError");
    });

    const notEnteredItems = verifyInputDetail();
    if (notEnteredItems.length > 0) {
      notEnteredItems.forEach((el) => {
        const targetElement = document.getElementById(el);
        // @ts-expect-error TS18047
        targetElement.classList.add("formError");
      });
      return;
    }
    props.setConfirmOrder(true);
  };

  const verifyInputDetail = () => {
    const notEnteredItems = [];
    switch (props.selectCreditCard) {
      case "newCreditCard":
        if (!props.useCardInfo.cardNumber) {
          notEnteredItems.push("cardNumber");
        }
        if (!props.useCardInfo.cardExpireMonth) {
          notEnteredItems.push("cardExpireMonth");
        }
        if (!props.useCardInfo.cardExpireYear) {
          notEnteredItems.push("cardExpireYear");
        }
        if (!props.useCardInfo.cardHolderName) {
          notEnteredItems.push("cardHolderName");
        }
        if (!props.useCardInfo.cardSecurityCode) {
          notEnteredItems.push("cardSecurityCode");
        }
        break;

      case "currentCreditCard":
        if (!props.useCardInfo.cardSecurityCode) {
          notEnteredItems.push("cardSecurityCode");
        }
        break;

      default:
        break;
    }
    return notEnteredItems;
  };

  /**
   * [memo]
   * クレジットでのPaw購入の「この内容で決済」ボタンを押すと、ここの関数が実行される。
   **/
  // @ts-expect-error TS7006
  const doPurchaseProcess = (selectPaw, selectPawId) => {
    switch (props.selectCreditCard) {
      case "newCreditCard":
        getCardTokenGMO({
          cardNo: props.useCardInfo.cardNumber,
          expire:
            props.useCardInfo.cardExpireYear.toString() +
            props.useCardInfo.cardExpireMonth.toString(),
          securityCode: props.useCardInfo.cardSecurityCode,
          holderName: props.useCardInfo.cardHolderName,
          tokenNumber: 1,
          // @ts-expect-error TS7008
        }).then((response: { isError: boolean; tokens }) => {
          /**
           * [memo]
           * getCardTokenGMOの成否で返り値が変わっている
           * 成功した場合：{ isError: boolean, tokens: any }
           * 失敗した場合：{ isError: boolean, error: string }
           **/
          if (response.isError) {
            dispatch(
              modalActions.toggleError({
                msg: `${t("purchase.purchase.pleaseCheckCardInfo")}`,
              })
            );
            return;
          }

          dispatch(
            purchaseActions.chargeWithCard.started({
              token: response.tokens[0],
              productId: String(selectPawId),
              price: selectPaw,
            })
          );
        });
        //setCurrentPageState("settlementComplete")
        props.toggleModal();
        break;

      case "currentCreditCard":
        dispatch(
          purchaseActions.chargeWithMemId.started({
            selectedCardIdx: props.useCardInfo.useCreditInfoIndex,
            securityCode: props.useCardInfo.cardSecurityCode,
            productId: String(selectPawId),
            price: selectPaw,
          })
        );
        //setCurrentPageState("settlementComplete")
        props.toggleModal();
        break;

      default:
        break;
    }
  };

  return (
    <Container id="inputPaymentMethods">
      <ModalHeader>
        <div className="modalHeader">
          <p className="back" onClick={props.backToPawListPage}>
            &lt;
          </p>
          <p className="heading">
            {props.isConfirmOrder
              ? t("pawCharge.InputPayment.orderCheck")
              : t("pawCharge.InputPayment.order")}
          </p>
          <p className="close" onClick={props.toggleModal}>
            ×
          </p>
        </div>

        <div className="orderPawLayout">
          <div className="orderPaw">
            <div className="selectPaw">
              <InputPaymentMethodsPaw>
                {props.selectedPaw.toLocaleString()}
              </InputPaymentMethodsPaw>
              <p className="price">
                {props.selectedPaw.toLocaleString()}
                {t("pawCharge.yen")}
              </p>
            </div>

            <button
              className="purchaseDetailChange"
              onClick={props.backToPawListPage}
            >
              {t("pawCharge.InputPayment.orderChange")}
            </button>

            <div className="totalAmount">
              <p className="heading">
                {t("pawCharge.InputPayment.orderTotal")}
              </p>
              <p className="price">
                ¥{props.selectedPaw.toLocaleString()}
                <span>{t("pawCharge.InputPayment.orderTax")}</span>
              </p>
            </div>
          </div>
        </div>
      </ModalHeader>

      <ModalContent>
        {props.isConfirmOrder ? (
          <ConfirmOrderDetail
            cardInfoList={props.cardInfoList}
            useCardInfo={props.useCardInfo}
            selectCreditCard={props.selectCreditCard}
          />
        ) : (
          <>
            <h2>{t("pawCharge.InputPayment.cardChoose")}</h2>
            <SelectUseCreditCard
              cardInfoList={props.cardInfoList}
              selectCreditCard={props.selectCreditCard}
              toggleModal={props.toggleModal}
              setSelectCreditCard={props.setSelectCreditCard}
            />

            <h2>{t("pawCharge.InputPayment.cardInformation")}</h2>
            <InputUseCreditCardInfo
              cardInfoList={props.cardInfoList}
              useCardInfo={props.useCardInfo}
              selectCreditCard={props.selectCreditCard}
              changeUseCardInfoState={changeUseCardInfoState}
            />
          </>
        )}

        <ModalFooter>
          {props.isConfirmOrder ? (
            <>
              <p
                className="buttonForLink"
                onClick={() => {
                  doPurchaseProcess(props.selectedPaw, props.selectedPawId);
                }}
              >
                {t("pawCharge.InputPayment.settlement")}
              </p>
              <p
                className="backToList"
                onClick={props.backToInputPaymentMethods}
              >
                <span>{t("pawCharge.InputPayment.settlementChange")}</span>
              </p>
            </>
          ) : (
            <>
              <p className="cautionText">
                {t("pawCharge.InputPayment.notice")}
              </p>
              <p className="buttonForLink" onClick={goToConfirmOrderPage}>
                {t("pawCharge.InputPayment.confirm")}
              </p>
              <p className="backToList" onClick={props.backToPawListPage}>
                <span>{t("pawCharge.InputPayment.back")}</span>
              </p>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Container>
  );
};
export default InputPaymentMethods;

interface SelectUseCreditCardProps {
  // @ts-expect-error TS7008
  cardInfoList;
  selectCreditCard: string;
  toggleModal: () => void;
  // @ts-expect-error TS7051
  setSelectCreditCard: (string) => void;
}
const SelectUseCreditCard = (props: SelectUseCreditCardProps) => {
  const { t } = useI18n();
  return (
    <>
      {props.cardInfoList.length > 0 ? (
        <CardInfoInputField>
          <label className="checkboxLayout">
            <input
              id="place"
              name="place"
              type="radio"
              defaultChecked={props.selectCreditCard === "currentCreditCard"}
              onClick={() => props.setSelectCreditCard("currentCreditCard")}
            />
            <span className="checkbox">
              {t("pawCharge.InputPayment.credit")}
            </span>
          </label>

          <label className="checkboxLayout">
            <input
              id="place"
              name="place"
              type="radio"
              defaultChecked={props.selectCreditCard === "newCreditCard"}
              onClick={() => props.setSelectCreditCard("newCreditCard")}
            />
            <span className="checkbox">
              {t("pawCharge.InputPayment.newCredit")}
            </span>
          </label>
        </CardInfoInputField>
      ) : (
        <div className="cardInfoInputField">
          <p className="text">
            {t("pawCharge.InputPayment.noCredit")}
            <br />
            <Link to="/account/credit" onClick={props.toggleModal}>
              {t("pawCharge.InputPayment.registrationCard")}
            </Link>
          </p>
        </div>
      )}
    </>
  );
};

interface InputUseCreditCardInfoProps {
  // @ts-expect-error TS7008
  cardInfoList;
  // @ts-expect-error TS7008
  useCardInfo;
  selectCreditCard: string;
  // @ts-expect-error TS7051
  changeUseCardInfoState: (any, string) => void;
}
const InputUseCreditCardInfo = (props: InputUseCreditCardInfoProps) => {
  const { t } = useI18n();
  return (
    <>
      <CardInfoView>
        <div className="cardInfo">
          {props.selectCreditCard === "currentCreditCard" ? (
            <>
              <h3 className="heading">
                {t("pawCharge.InputPayment.useCredit")}
              </h3>
              <select
                onChange={(e) =>
                  props.changeUseCardInfoState(
                    Number(e.currentTarget.value),
                    "useCreditInfoIndex"
                  )
                }
              >
                {/*
                 // @ts-expect-error TS7006 */}
                {props.cardInfoList.map((cardInfo, i) => {
                  return (
                    <option key={i} value={i}>
                      {cardInfo.cardNumber}
                    </option>
                  );
                })}
              </select>
            </>
          ) : (
            <>
              <h3 className="heading">
                {t("pawCharge.InputPayment.creditCardNumber")}
              </h3>
              <input
                type="text"
                id="cardNumber"
                name="cardNum"
                defaultValue={props.useCardInfo.cardNumber}
                onChange={(e) =>
                  props.changeUseCardInfoState(
                    e.currentTarget.value,
                    "cardNumber"
                  )
                }
              />
            </>
          )}
        </div>

        {props.selectCreditCard === "currentCreditCard" ? (
          <></>
        ) : (
          <>
            <div className="cardInfo">
              <h3 className="heading">
                {t("pawCharge.InputPayment.expirationDate")}
              </h3>
              <input
                type="text"
                id="cardExpireMonth"
                name="cardMonth"
                placeholder="MM"
                defaultValue={props.useCardInfo.cardExpireMonth}
                onChange={(e) =>
                  props.changeUseCardInfoState(
                    e.currentTarget.value,
                    "cardExpireMonth"
                  )
                }
              />
              <input
                type="text"
                id="cardExpireYear"
                name="cardYear"
                placeholder="YY"
                defaultValue={props.useCardInfo.cardExpireYear}
                onChange={(e) =>
                  props.changeUseCardInfoState(
                    e.currentTarget.value,
                    "cardExpireYear"
                  )
                }
              />
            </div>
            <div className="cardInfo">
              <h3 className="heading">
                {t("pawCharge.InputPayment.creditCardHolder")}
              </h3>
              <input
                type="text"
                id="cardHolderName"
                name="cardName"
                defaultValue={props.useCardInfo.cardHolderName}
                onChange={(e) =>
                  props.changeUseCardInfoState(
                    e.currentTarget.value,
                    "cardHolderName"
                  )
                }
              />
            </div>
          </>
        )}

        <div className="cardInfo">
          <h3 className="heading">
            {t("pawCharge.InputPayment.securityCode")}
          </h3>
          <input
            type="password"
            id="cardSecurityCode"
            name="code"
            placeholder="000"
            onChange={(e) =>
              props.changeUseCardInfoState(
                e.currentTarget.value,
                "cardSecurityCode"
              )
            }
          />
        </div>

        <div className="cardInfo">
          <h3 className="heading">{t("pawCharge.InputPayment.payMethod")}</h3>
          <p className="paymentMethods">
            {t("pawCharge.InputPayment.lumpSum")}
          </p>
        </div>
      </CardInfoView>
    </>
  );
};

interface ConfirmOrderDetailProps {
  // @ts-expect-error TS7008
  cardInfoList;
  // @ts-expect-error TS7008
  useCardInfo;
  selectCreditCard: string;
}

const ConfirmOrderDetail = (props: ConfirmOrderDetailProps) => {
  const { t } = useI18n();
  let cardInfo;
  if (props.selectCreditCard === "currentCreditCard") {
    cardInfo = props.cardInfoList[props.useCardInfo.useCreditInfoIndex];
  } else {
    cardInfo = props.useCardInfo;
  }
  const { cardNumber, cardExpireMonth, cardExpireYear, cardHolderName } =
    cardInfo;

  return (
    <>
      <h2>お支払い詳細</h2>
      <CardInfoView>
        <div className="cardInfo">
          <h3 className="heading">
            {t("pawCharge.InputPayment.creditCardNumber")}
          </h3>
          <p className="confirmText">{cardNumber}</p>
        </div>
        <div className="cardInfo">
          <h3 className="heading">
            {t("pawCharge.InputPayment.expirationDate")}
          </h3>
          <p className="confirmText">
            {cardExpireMonth}/{cardExpireYear}
          </p>
        </div>
        <div className="cardInfo">
          <h3 className="heading">
            {t("pawCharge.InputPayment.creditCardHolder")}
          </h3>
          <p className="confirmText">{cardHolderName}</p>
        </div>
        {/* <div className="cardInfo">
          <h3 className="heading">セキュリティコード</h3>
          <p className="confirmText">{cardSecurityCode}</p>
        </div> */}
        <div className="cardInfo">
          <h3 className="heading">{t("pawCharge.InputPayment.payMethod")}</h3>
          <p className="confirmText normalFont">
            {t("pawCharge.InputPayment.lumpSum")}
          </p>
        </div>
      </CardInfoView>
    </>
  );
};
