import { useRef, useEffect } from "react";

/**
 * return previous value
 * @ref https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
 * @param value
 */
export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
