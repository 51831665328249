/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect } from "react";
import { BreadcrumbArea } from "components/common/Link";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "modules/modal";
import { Store } from "store";
import { Button } from "components/atoms/button/Button";
import { useI18n } from "hooks/i18n/i18n";

export const PromptToLogin: React.FC = () => {
  const user = useSelector((state: Store) => state.auth.user);

  const { t } = useI18n();
  const design = styles();
  const dispatch = useDispatch();

  const watchLoginState = () => {
    if (!user.uid) {
      dispatch(modalActions.toggleLoginMethod());
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(watchLoginState, [user.uid]);

  return (
    <div id="contents" css={design.root}>
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, t("common.routes.voucherCode")],
        ]}
      />

      <div css={design.main}>
        <div css={design.inner}>
          <div css={design.caution}>
            <div
              css={css`
                margin-bottom: 21px;
              `}
            >
              <p>バウチャーコードの入力にはログインが必要です。</p>
              <p>
                SPWN
                IDをお持ちの方は、下記のボタンよりログイン画面にお進みください。
              </p>
            </div>

            <div
              css={css`
                width: 360px;
                margin: 0 auto;
              `}
            >
              <Button
                text="ログイン"
                onClick={() => dispatch(modalActions.toggleLoginMethod())}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      min-height: calc(100vh - 256px);
      margin: 0 auto;
      background-color: #ffffff;
    `,
    main: css`
      background-color: #ffffff;
    `,
    inner: css`
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      padding: 45px 20px;
      box-sizing: border-box;
    `,
    caution: css`
      text-align: center;
      p {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.8em;
      }
    `,
  };
};
