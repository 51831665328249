import React, { FC } from "react";

export const LiveIcon: FC = () => {
  return (
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAgCAYAAACcuBHKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEzSURBVHgB7ZbhbcIwEIWfqw6QEcIEpRukG7QblAkQG8AGMAEwAWwAGwATkA1gg/BMDmQhUO7ASviRTzpdRIzy5Xx2DLS0vCHuclEURZ+pi3hsnXMTzUAnAinTHvHpUCSvGvSBBuBLdxkLRtKIhEz7hvHLOEt8oiZkyqeM7PZeLZUI3j67d99SiZyRwkbi5x5l6R+irUTOLu8w90RGy6pKwCJxhiIzph/GXPmXRDPI3BN+3TP+Ya9KPIlAZsb0DX1V4ksEHPAiT+8T7PoM5bpP8SJmCdlq/cMru16LaTpk09kbBI6aQVqJlAJ+xxtDueyEPyga11KJZ84aquVcy7cj2OTWaEpCRHxVvMgITUkEMkMm/x1aQhq3tvPEjUiOsmlxlfA/svsHvPxCPHaa82VLy9tyAv0TUrwmRnApAAAAAElFTkSuQmCC"
      alt=""
    />
  );
};
