import { v4 as uuidv4 } from "uuid";
import { useState, useCallback } from "react";

type RegenerateKey = () => void;

export const useIdempotencyKey = (): [string, RegenerateKey] => {
  const [key, setKey] = useState(uuidv4());

  const regenerate: RegenerateKey = useCallback(() => {
    setKey(uuidv4());
  }, []);

  return [key, regenerate];
};
