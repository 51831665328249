/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

type Props = {
  tags: string[];
};

export const Tags: React.FC<Props> = ({ tags }) => (
  <ul css={styles.tags}>
    {tags.map((tag) => (
      <li key={tag} css={styles.tag}>
        {tag}
      </li>
    ))}
  </ul>
);

const styles = {
  tags: css`
    margin-bottom: -8px;
    display: flex;
    flex-wrap: wrap;
  `,
  tag: css`
    padding: 5px 15px;
    margin-right: 8px;
    margin-bottom: 8px;
    color: #00c2ae;
    font-size: 12px;
    background-color: #eefaf9;
    border-radius: 14px;
  `,
};
