/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { manualText, ManualTextItem } from "../../constants/manual";

interface Props {}

const UseGuideForSP: React.FC<Props> = (_props: Props) => {
  const classesUseGuideDesign = useGuideDesign();
  const manualTextKeys = Object.keys(manualText);

  return (
    <React.Fragment>
      {manualTextKeys.map((key, index) => {
        // @ts-expect-error TS7053
        const manualTextMap: ManualTextItem = manualText[key];
        return (
          <article css={classesUseGuideDesign.root} key={index}>
            <h3>{manualTextMap.heading}</h3>
            <p>{manualTextMap.description}</p>
            {manualTextMap.slide.map((slide, index) => {
              return (
                <div css={classesUseGuideDesign.sliderItem} key={index}>
                  <img src={slide.image} alt="" className="manual_image" />
                  <div css={classesUseGuideDesign.sliderText}>
                    {slide.message}
                  </div>
                </div>
              );
            })}
          </article>
        );
      })}
    </React.Fragment>
  );
};
export default UseGuideForSP;

const useGuideDesign = () => {
  return {
    root: css`
      margin-bottom: 50px;
      h3 {
        padding-bottom: 10px;
        margin-bottom: 15px;
        line-height: 1.3em;
        border-bottom: 1px solid #ccc;
      }
      p {
        margin-bottom: 10px;
        font-size: 15px;
        line-height: 1.5em;
        letter-spacing: 1px;
      }
    `,
    slider: css`
      margin-top: 30px;
    `,
    sliderItem: css`
      img {
        display: block;
        width: 200px;
        margin: 0 auto;
      }
    `,
    sliderText: css`
      padding-top: 20px;
      p {
        margin-bottom: 20px;
      }
      ul {
        padding-left: 1.4em;
        margin-bottom: 20px;
      }
      li {
        font-size: 15px;
        padding-left: 1.8em;
        margin-bottom: 10px;
        text-indent: -1.8em;
      }
      a {
        font-size: 15px;
      }
    `,
  };
};
