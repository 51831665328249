/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { ComponentProps } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../../modules/cart";
import { isDisplayPeriod } from "../../../utility";
import { sanitizer } from "../../../utility/sanitize";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Store } from "store";
import { modalActions } from "modules/modal";
import { GoodsVenueSelector } from "components/event/goods/GoodsVenueSelector";
import { eventActions } from "modules/event";
import { useI18n } from "hooks/i18n/i18n";
import { AddToCartButton } from "components/event/goods/detail/AddToCartButton";
import { getDisplayPrice, isShippingItem } from "utility/ticket";
import { ticketState } from "modules/ticket";

/**
 * カートへ追加するためのリクエスト値となる商品
 */
type CartProduct = {
  id: string;
  productType: "goods";
  count: number;
  price: number;
  name: string;
  variantName: string;
};

type EventGoodsItemInfoProps = {
  eventId: string;
  classGoodsList: ticketState["eventGoods"];
  repGoods: ticketState["eventGoodsMap"][number];
  selectablePlaces: ComponentProps<typeof GoodsVenueSelector>["venues"];
  isAdmin: boolean;
};
export const EventGoodsItemInfo: React.FC<EventGoodsItemInfoProps> = (
  props
) => {
  const MAX_GOODS_PURCHASE = 99;
  const isLogined = useSelector((state: Store) => Boolean(state.auth.user.uid));
  const { selectedPlaceCode, havingTicketPlaceCodes } = useSelector(
    (state: Store) => state.event.goodsPageSettings
  );
  const isAddressRegistered = useSelector(
    (state: Store) => state.auth.isAddressRegistered
  );
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const dispatch = useDispatch();
  const maxDisplayCount =
    // @ts-expect-error TS18048
    props.repGoods?.purchaseLimit > MAX_GOODS_PURCHASE
      ? MAX_GOODS_PURCHASE
      : props.repGoods?.purchaseLimit;
  const isOnSale =
    (displayEvent.goodsSaleStatus === "ON_SALE" &&
      isDisplayPeriod(
        props.repGoods.display,
        props.repGoods.releaseDateTime,
        props.repGoods.closeDateTime
      )) ||
    props.isAdmin;
  const classesGoodsInfoDesign = goodsInfoDesign({ isOnSale });
  const { t } = useI18n();
  const price = getDisplayPrice(props.repGoods);

  const incrementNum = (itemId: string) => {
    const element = document.getElementById(itemId) as HTMLInputElement;
    const { value } = element;

    if (Number(value) === maxDisplayCount) {
      element.value = value;
    } else {
      element.value = String(Number(value) + 1);
    }
  };
  const decrementNum = (itemId: string) => {
    const element = document.getElementById(itemId) as HTMLInputElement;
    const { value } = element;

    if (Number(value) === 0) {
      element.value = value;
    } else {
      element.value = String(Number(value) - 1);
    }
  };

  const openLoginModal = () => {
    dispatch(modalActions.toggleLoginMethod());
  };

  /**
   * グッズをカートに追加する
   */
  const addToCart = () => {
    const productList: CartProduct[] = [];
    props.classGoodsList.forEach((item) => {
      const itemId = item._id;
      // @ts-expect-error TS2345
      const element = document.getElementById(itemId) as HTMLInputElement;
      if (element) {
        const selectedCount: string = element.value;
        productList.push({
          // @ts-expect-error TS2322
          id: itemId,
          productType: "goods",
          count: Number(selectedCount),
          price: item.price_jpy,
          name: item.name,
          // @ts-expect-error TS2322
          variantName: item.subClassName,
        });
      }
    });

    const addCartProduct = productList.filter((product) => product.count !== 0);
    // 購入する商品の個数が0の時処理を止める
    if (addCartProduct.length === 0) {
      return;
    }
    dispatch(
      cartActions.addToCart.started({
        body: {
          eventId: props.eventId,
          products: addCartProduct,
        },
        actionOnModal: () =>
          dispatch(cartActions.toggleMyCart({ isOpen: true })),
      })
    );
  };

  const setSelectedPlaceCode = (selectedPlaceCode: string) => {
    dispatch(
      eventActions.setGoodsPageSettings({
        selectedPlaceCode,
      })
    );
  };

  // 商品がすべて売り切れの場合はカートボタンを売り切れ状態にする
  const isSoldOutCartButton = props.classGoodsList.every(
    (item) => item.remaining === -1
  );
  return (
    <div css={classesGoodsInfoDesign.root}>
      <h2>{props.repGoods?.name}</h2>
      <p css={classesGoodsInfoDesign.price}>
        {
          // if there is valiation
          props.repGoods?.subClassName
            ? t("goods.each", {
                price: `¥${price.toLocaleString()}`,
              })
            : `¥${price.toLocaleString()}`
        }
      </p>

      {props.repGoods.fulfillmentShipment && (
        <p css={classesGoodsInfoDesign.shipmentTitle}>
          {props.repGoods.fulfillmentShipment.title} 発送予定
        </p>
      )}

      {/* 販売期間がある場合は表示する */}
      {/* 既存のグッズ詳細に記載されている販売期間をこちらに移動させる必要あり */}
      {/* <p css={classesGoodsInfoDesign.salesPeriod}>販売期間：04/24(金)18:00 〜 05/10(日)23:59</p> */}

      {/* サイズ選択がある場合は表示する */}
      <div css={classesGoodsInfoDesign.selectSize}>
        {props.classGoodsList
          ? props.classGoodsList.map((item, index) => {
              // 売り切れの場合は売り切れラベルを表示する
              const isSoldOut = item.remaining === -1;
              return (
                <div css={classesGoodsInfoDesign.selectSizeForm} key={index}>
                  <p css={classesGoodsInfoDesign.selectSizeLabel}>
                    {item.subClassName}
                  </p>
                  {!isSoldOut ? (
                    <div css={classesGoodsInfoDesign.selectSizeButton}>
                      {/*
                       // @ts-expect-error TS2345 */}
                      <div onClick={() => decrementNum(item._id)}>
                        <RemoveIcon />
                      </div>
                      <input id={item._id} type="text" defaultValue={`0`} />
                      {/*
                       // @ts-expect-error TS2345 */}
                      <div onClick={() => incrementNum(item._id)}>
                        <AddIcon />
                      </div>
                    </div>
                  ) : (
                    <div css={classesGoodsInfoDesign.selectSoldOutLabel}>
                      <p>SOLD OUT</p>
                    </div>
                  )}
                </div>
              );
            })
          : null}
      </div>

      {/* 配送・会場を含め選択肢をだす */}
      <div css={classesGoodsInfoDesign.placeSelector}>
        <GoodsVenueSelector
          selectedPlaceCode={selectedPlaceCode}
          venues={props.selectablePlaces}
          setSelectedPlaceCode={setSelectedPlaceCode}
        />
      </div>

      <AddToCartButton
        isOnSale={isOnSale}
        isSoldOut={isSoldOutCartButton}
        isLogined={isLogined}
        // @ts-expect-error TS2322
        isAddressRequired={
          isLogined && isShippingItem(props.repGoods) && !isAddressRegistered
        }
        // 会場チケットを購入していないと会場グッズが購入できない設定をデフォルトでは外す。会場グッズのみ売りたいイベントがあるため。
        // TODO 会場グッズが増えてきたらもう少し仕様を詰めたい
        // @ts-expect-error TS2322
        isNeedVenueTicket={
          displayEvent?.goodsInfo?.isVenueTicketRequired &&
          isLogined &&
          !isShippingItem(props.repGoods) &&
          !havingTicketPlaceCodes.includes(selectedPlaceCode)
        }
        ticketUrl={
          displayEvent.ticketUrl || `/events/${displayEvent.eventId}/ticket` // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
        }
        openLoginModal={openLoginModal}
        addToCart={addToCart}
      />

      <ul css={classesGoodsInfoDesign.tags}>
        {props.repGoods?.hashtags
          ? props.repGoods.hashtags.map((tag, index) => {
              return <li key={index}>{tag}</li>;
            })
          : null}
      </ul>

      {props.repGoods?.specification ? (
        <div
          css={classesGoodsInfoDesign.text}
          dangerouslySetInnerHTML={{
            __html: sanitizer(props.repGoods.specification),
          }}
        />
      ) : null}
    </div>
  );
};

interface GoodsInfoDesignProps {
  isOnSale: boolean;
}
const goodsInfoDesign = (_props?: GoodsInfoDesignProps) => {
  // const buttonState = props.isOnSale ? "#00c2ae" : "#e0e0e0";

  return {
    root: css`
      width: 90%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: calc(41% - 17px);
      }
      h2 {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 1.5em;
      }
    `,
    price: css`
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: bold;
    `,
    shipmentTitle: css`
      color: #69afe5;
      font-size: 14px;
      margin-bottom: 32px;
    `,
    salesPeriod: css`
      padding: 12px 20px;
      margin-bottom: 20px;
      color: #fc1f74;
      font-size: 14px;
      text-align: center;
      border: 1px solid #fc1f74;
    `,

    selectSize: css`
      margin-bottom: 20px;
    `,
    selectSizeForm: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      border: 2px solid #e5e5e5;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    `,
    selectSizeLabel: css`
      width: calc(100% - 90px);
    `,
    selectSizeButton: css`
      display: flex;
      width: 75px;
      margin-left: 15px;
      border: 1px solid #e5e5e5;
      border-radius: 3px;
      input {
        width: calc(100% / 3);
        padding: 0 4px;
        font-size: 14px;
        text-align: center;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 3);
        padding: 5px 0;
        border-left: 1px solid #e5e5e5;
        background-color: #f4f4f4;
        cursor: pointer;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    `,
    selectSoldOutLabel: css`
      display: flex;
      p {
        display: inline-block;
        padding: 4px 8px;
        color: #fff;
        font-size: 12px;
        background-color: #da3d50;
        @media screen and (min-width: 768px) {
          font-size: 10px;
        }
      }
    `,
    tags: css`
      display: flex;
      flex-wrap: wrap;
      padding-top: 15px;
      margin-bottom: calc(25px - 8px);
      border-top: 1px solid #e5e5e5;
      li {
        padding: 5px 15px;
        margin-right: 8px;
        margin-bottom: 8px;
        color: #00c2ae;
        font-size: 12px;
        background-color: #eefaf9;
        border-radius: 14px;
      }
    `,
    text: css`
      h3 {
        margin: 15px 0 10px;
      }
      p {
        color: #3c3c3c;
        font-size: 14px;
        line-height: 1.5em;
      }
      ul {
        li {
          color: #3c3c3c;
          font-size: 14px;
          line-height: 1.5em;
        }
      }
    `,
    placeSelector: css`
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    `,
  };
};
