import type { FlowerStandColor, FlowerStandColorData } from "@spwn/types";

/**
 * 秒
 */
export const EVENT_OPEN_TIME_PERIOD = 10800; // 3h
export const LIVE_EVENT_PRE_OPEN_TIME_PERIOD = 10800; // 3h

/**
 * flower stand
 */
export const SELECTABLE_COLOR_MAP: Record<
  FlowerStandColor,
  FlowerStandColorData
> = {
  RED: {
    id: "RED",
    name: "赤",
    className: "red",
  },
  BLUE: {
    id: "BLUE",
    name: "青",
    className: "blue",
  },
  YELLOW: {
    id: "YELLOW",
    name: "黄",
    className: "yellow",
  },
  GREEN: {
    id: "GREEN",
    name: "緑",
    className: "green",
  },
  PURPLE: {
    id: "PURPLE",
    name: "紫",
    className: "purple",
  },
};
export const FLOWER_COLOR_COUNT = Object.keys(SELECTABLE_COLOR_MAP).length;

// ページングを一旦使用しないように大きめの値に設定する
export const EVENT_LIMIT = 500; // 一度に取得するイベント数
export const PER_PAGE = 100; // 1ページに表示するイベント数
