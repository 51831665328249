/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  url: string;
  label: string;
  color?: string;
}
export const BorderLinkButton: React.FC<Props> = (props) => {
  const classesButtonStyles = buttonStyles({
    // @ts-expect-error TS2322
    color: props.color,
  });
  return (
    <Link to={props.url} css={classesButtonStyles.root}>
      {props.label}
    </Link>
  );
};

interface ButtonStylesProps {
  color: string;
}
const buttonStyles = (props: ButtonStylesProps) => {
  const color = props.color ? props.color : "#00c2ae";
  return {
    root: css`
      display: block;
      width: 100%;
      padding: 11px 0;
      margin: 0 auto;
      color: ${color};
      font-size: 14px;
      font-weight: bold;
      line-height: 1em;
      text-align: center;
      letter-spacing: 1px;
      background-color: #fff;
      border: 2px solid ${color};
      cursor: pointer;
      outline: none;
      appearance: none;
    `,
  };
};
