/** @jsxRuntime classic /
/** @jsx jsx */
import { css, keyframes, jsx } from "@emotion/core";

const LiveAnimation: React.FC = () => {
  const classes = styles();
  return (
    <div css={classes.liveStatus}>
      <div css={classes.liveStatusAnimation}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <p>LIVE</p>
    </div>
  );
};

export default LiveAnimation;

const styles = () => {
  const animation = keyframes`
    0% {
      height: 14px;
    }
    50% {
      height: 3px;
    }
    100% {
      height: 14px;
    }
  `;
  return {
    liveStatus: css`
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-end;
      padding: 4px 10px;
      background-color: #ff0076b0;
      p {
        color: #fff;
        font-size: 14px;
      }
    `,
    liveStatusAnimation: css`
      display: flex;
      align-items: flex-end;
      height: 14px;
      margin-right: 8px;
      line-height: 1em;
      span {
        display: block;
        width: 4px;
        height: 14px;
        margin-right: 2px;
        background-color: #fff;
        &:nth-child(1) {
          animation: ${animation} infinite 1.5s;
          animation-delay: 0s;
        }
        &:nth-child(2) {
          animation: ${animation} infinite 1.5s;
          animation-delay: 0.3s;
        }
        &:nth-child(3) {
          animation: ${animation} infinite 1.5s;
          animation-delay: 0.9s;
        }
      }
    `,
  };
};
