import appConfig from "constants/appConfig";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Store } from "store";
import {
  fetchFbToken,
  fetchFirestoreCollection,
  fetchFirestoreDocumentBySnapshot,
} from "utility/firebase";

import { EventInfo, isDisplayPeriod } from "utility";
import firebase from "firebase/app";

import type {
  DayContent,
  FesTicket,
  StageContent,
  TimetableDay,
  TimetableSettings,
  TimetableStage,
  UserCartProduct,
  UserOrder,
  UserVideo,
  Video,
} from "@spwn/types";
import type {
  ActiveTransaction,
  EventVideo,
} from "@spwn/types/firebase/firestore";
import { DocumentReference } from "@google-cloud/firestore";

/**
 *
 * @param eventId
 */
export const useTimetableContent = () => {
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const myCart = useSelector((state: Store) => state.cart.myCart);
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);
  // @ts-expect-error TS2345
  const [models, setModels] = useState<TimetableSettings>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetch = useCallback(async () => {
    if (!models) setLoading(true);
    try {
      const SAMPLE_DATA = await fetchData(displayEvent, isAdmin);
      setModels(SAMPLE_DATA);
    } catch (error) {
      console.error(error);
      // @ts-expect-error TS2345
      setError(typeof error === "string" && error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models, displayEvent]);

  const dispatchAddToCart = useCallback(
    ({ id, count, refPath }) => {
      try {
        // no wait.
        addToCart({
          // @ts-expect-error TS2322
          eventId: displayEvent._id,
          products: Array({
            id,
            productType: "ticket", // fixed to `ticket` when model is ticket
            count,
            refPath,
          }),
        });
        // update target content to `InCart` state.
        const newModels = { ...models };
        newModels.dayContents.forEach((d) => {
          // if dayTicket, set `InCart` status to all the dayVideos.
          if (d.ticketPath === refPath) {
            d.actionStatus = "InCart";
            d.stageContents.forEach((s) => {
              s.actionStatus = "InCart";
              s.videos.forEach((v) => {
                v.actionStatus = "InCart";
              });
            });
          }
          // if stageTicket, set `InCart` status to all the stageVideos.
          d.stageContents.forEach((s) => {
            if (s.ticketPath === refPath) {
              s.actionStatus = "InCart";
              s.videos.forEach((v) => {
                v.actionStatus = "InCart";
              });
            }
            // if videoTicket, set `InCart` status to the videos.
            s.videos.forEach((v) => {
              if (v.ticketPath === refPath) {
                v.actionStatus = "InCart";
              }
            });
          });
        });
        setModels(newModels);
      } catch (error) {
        console.error(error);
      }
    },
    [displayEvent._id, models]
  );

  /**
   * fetch again and update action status
   * - if cart changed
   * - if event changed
   * 現状カートダイアログを開くと、cartの再fetchが行われるため
   * こちらのfetchも走ってしまうが許容
   */
  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCart, displayEvent, isAdmin]);

  return {
    loading,
    error,
    models,
    fetch,
    dispatchAddToCart,
  };
};

export const fetchData = async (
  eventInfo: EventInfo,
  isAdmin: boolean
): Promise<TimetableSettings> => {
  // fetch fesTickets, user carts and user videos.
  const [fesTickets, userCartProducts, userVideos, userUnprocessedOrders] =
    await Promise.all([
      fetchFesTickets(firebase.firestore().doc(`events/${eventInfo._id}`)),
      // @ts-expect-error TS2345
      fetchUserCartProducts(eventInfo._id),
      // @ts-expect-error TS2345
      fetchUnexpiredUserVideos(eventInfo._id),
      fetchUserUnprocessedOrders(),
    ]);
  // fetch day → stage → video and set action status.
  const dayContents = await Promise.all(
    eventInfo.dayRefs.map(async (dayRef) => {
      const day = await fetchFirestoreDocumentBySnapshot<TimetableDay>(
        dayRef.get()
      );
      const dayStages = await fetchFirestoreCollection<{
        ref: DocumentReference<TimetableStage>;
        priority: number;
      }>(`days/${dayRef.id}/stages`);
      // @ts-expect-error TS2322
      const stages: StageContent[] = await Promise.all(
        dayStages
          .sort((a, b) => a.priority - b.priority)
          .map(async (dayStage) => {
            const stage =
              await fetchFirestoreDocumentBySnapshot<TimetableStage>(
                dayStage.ref.get()
              );
            const stageVideos = await fetchFirestoreCollection<{
              ref: DocumentReference<EventVideo>;
              priority: number;
            }>(`stages/${dayStage.ref.id}/videos`);
            // 各eventVideoをfetchし、stageVideoを組み込む
            const eventVideos = await Promise.all(
              stageVideos
                .sort((a, b) => a.priority - b.priority)
                .map(async (stageVideo) => {
                  const video =
                    await fetchFirestoreDocumentBySnapshot<EventVideo>(
                      stageVideo.ref.get()
                    );
                  return {
                    ...video,
                    stageVideo,
                  };
                })
            );
            // 各eventVideoに次の配信情報を組み込む。ない場合はnull。
            const eventVideosAddedNextVideo = eventVideos.map((v, i) => {
              return {
                ...v,
                nextVideo: eventVideos?.[i + 1] || null, // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
              };
            });
            // timetable用のvideoを作成する
            // @ts-expect-error TS2322
            const videos: Video[] = await Promise.all(
              eventVideosAddedNextVideo.map(async (video) => {
                const videoTicket = fesTickets.find(
                  (el) =>
                    el.purchaseTargetRef.path === video.stageVideo.ref.path
                );
                // adminであれば無条件で配信ページに入れるようにする
                const actionStatus = isAdmin
                  ? "Playable"
                  : (getActionStatus({
                      // @ts-expect-error TS2322
                      ticket: videoTicket,
                      // @ts-expect-error TS2322
                      targetVideoIds: [video._id],
                      userVideos,
                      userCartProducts,
                      userUnprocessedOrders,
                      // @ts-expect-error TS2322
                      video,
                      eventInfo,
                      type: "video",
                    }) as Video["actionStatus"]);

                let artists: { name: string; imgPath: string }[] = [];
                if (video.artistProfileRefs) {
                  artists = await Promise.all(
                    video.artistProfileRefs.map(async (artistProfileRef) => {
                      const artistProfile =
                        await fetchFirestoreDocumentBySnapshot<
                          typeof artists[0]
                        >(artistProfileRef.get());
                      return {
                        name: artistProfile.name,
                        imgPath:
                          appConfig.publicStorageDomain + artistProfile.imgPath,
                      };
                    })
                  );
                }

                // TODO 不要なパラメータは削除
                return {
                  _id: video._id,
                  _refPath: video._refPath,
                  description: video.description,
                  durationText: video.durationText,
                  eid: video.eid,
                  hasVOD: video.hasVOD,
                  isChatEnabled: video.isChatEnabled,
                  isLiveCommerceEnabled: video.isLiveCommerceEnabled,
                  isLiveEventEnabled: video.isLiveEventEnabled,
                  isOpen: video.isOpen,
                  name: video.name,
                  priority: video.priority,
                  storagePath: "",
                  thumbnail: video.thumbnail,
                  startAt: video.startAt.seconds,
                  endAt: video.endAt.seconds,
                  artists,
                  actionStatus,
                  ticketPath: videoTicket?._refPath ?? null,
                  playVideoId: video._id,
                };
              })
            );
            const stageTicket = fesTickets.find(
              (el) => el.purchaseTargetRef.path === dayStage.ref.path
            );
            // ステージの最初のvideoを取得。
            const sortedVideosByStartAt = videos.sort(
              (a, b) => a.startAt - b.startAt
            );
            const [startingVideo] = sortedVideosByStartAt;
            const actionStatus = getActionStatus({
              // @ts-expect-error TS2322
              ticket: stageTicket,
              targetVideoIds: stageVideos.map((el) => el.ref.id),
              userVideos,
              userCartProducts,
              userUnprocessedOrders,
              eventInfo,
              type: "stage",
              // @ts-expect-error TS18048
              stageStartAt: startingVideo.startAt,
            }) as StageContent["actionStatus"];
            // 配信中のvideoを取得。ステージの最新（開場時間が遅い）で配信中のvideoを選択。
            const playVideo = sortedVideosByStartAt
              .slice()
              .reverse()
              .find((el) => el.isOpen);
            return {
              name: stage.name,
              actionStatus,
              ticketPath: stageTicket?._refPath ?? null,
              videos,
              playVideoId: playVideo ? playVideo._id : null,
            };
          })
      );
      const stageNum = stages.length;
      // @ts-expect-error TS2532
      const stageStartTime = stages
        .flatMap((el) => el.videos)
        .sort((a, b) => a.startAt - b.startAt)[0].startAt;
      // @ts-expect-error TS2532
      const stageEndTime = stages
        .flatMap((el) => el.videos)
        .sort((a, b) => b.endAt - a.endAt)[0].endAt;
      const dayTicket = fesTickets.find(
        (el) => el.purchaseTargetRef.path === dayRef.path
      );
      const actionStatus = getActionStatus({
        // @ts-expect-error TS2322
        ticket: dayTicket,
        targetVideoIds: stages.flatMap((el) => el.videos.map((v) => v._id)),
        userVideos,
        userCartProducts,
        userUnprocessedOrders,
        eventInfo,
        type: "day",
      }) as DayContent["actionStatus"];
      return {
        dayName: day.name,
        stageNum,
        stageStartAt: stageStartTime,
        stageBetweenAt: stageEndTime - stageStartTime,
        actionStatus,
        ticketPath: dayTicket?._refPath ?? null,
        stageContents: stages,
      };
    })
  );

  return {
    dayContents,
  };
};

const fetchFesTickets = async (
  eventRef: firebase.firestore.DocumentReference // ! ここのDocumentReferenceちょっと型違う...
): Promise<FesTicket[]> => {
  return (
    await firebase
      .firestore()
      .collection(`fesTickets`)
      .where("display", "==", true)
      .where("eventRef", "==", eventRef)
      .get()
  ).docs
    .map((doc) => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(doc.data() as any), // ! 型ちゃんとする
      _id: doc.id,
      _refPath: doc.ref.path,
    }))
    .filter((el: FesTicket) =>
      isDisplayPeriod(el.display, el.releaseDateTime, el.closeDateTime)
    );
};

const fetchUserCartProducts = async (
  eventId: string
): Promise<UserCartProduct[]> => {
  const { currentUser } = firebase.auth();
  if (!currentUser) return [];
  return (
    await firebase
      .firestore()
      .collection(`users/${currentUser.uid}/cartProducts`)
      .where("eid", "==", eventId)
      .where("expiredAt", ">", new Date())
      .get()
  ).docs
    .map((doc) => ({
      ...(doc.data() as UserCartProduct),
      _id: doc.id,
      _refPath: doc.ref.path,
    }))
    .filter((el) => el.videoRefs); // 配信チケットのみ対象とする
};

/**
 * fetch user unprocessed orders
 * UNPROCESS: コンビニ決済待ち
 * AUTHPROCESS: キャリア決済認証待ち
 * @returns
 */
const fetchUserUnprocessedOrders = async () => {
  const { currentUser } = firebase.auth();
  if (!currentUser) return [];
  return (
    // fetch not paysuccess order
    (
      await firebase
        .firestore()
        .collection(`settlement/${currentUser.uid}/activeTransactions`)
        .where("isPurchased", "==", true)
        .where("isCancelled", "==", false)
        .get()
    ).docs
      .map((doc) => ({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(doc.data() as any), // !!
        _id: doc.id,
        _refPath: doc.ref.path,
      }))
      .filter((el) => ["UNPROCESS", "AUTHPROCESS"].includes(el.status))
  );
};

/**
 * check if user have purchased ticket already.
 * user have purchased ticket already if following conditions.
 * - userVideos have all target videos.
 * @param param0
 */
const isPurchasedTicket = ({
  targetVideoIds, // day or stage or video で視聴できるvideoIdsが入る
  userVideos,
}: {
  targetVideoIds: string[];
  userVideos: UserVideo[];
}) => {
  // check if userVideos have all target videos.
  const purchasedStageVideoIds = userVideos.map((el) => el.videoRef.id);
  return targetVideoIds.every((el) => purchasedStageVideoIds.includes(el));
};

/**
 * check if ticket is in user cart.
 * user have added to cart already if following conditions.
 * - user have added
 */
const isTicketInCart = ({
  ticket,
  userCartProducts,
}: {
  ticket: FesTicket;
  userCartProducts: UserCartProduct[];
}) => {
  const userCartProduct = userCartProducts.find(
    (el) => el.productRef.path === ticket._refPath
  );
  if (userCartProduct) return true;
  const inCartVideoIds = userCartProducts.flatMap((el) =>
    el.videoRefs.flatMap((e) => e.id)
  );
  const ticketVideoIds = ticket.videoRefs.map((el) => el.id);
  return ticketVideoIds.every((el) => inCartVideoIds.includes(el));
};

/**
 * check if ticket's settlement is unprocessed
 * @param param0
 * @returns
 */
const isTicketSettlementConfirm = ({
  ticket,
  userUnprocessedOrders,
}: {
  ticket: FesTicket;
  userUnprocessedOrders: UserOrder[];
}) => {
  const products = userUnprocessedOrders
    .flatMap((el) => el.products)
    .filter((el) => el);
  // if there is unprocessed settlement ticket, return immediately.
  const matchedTicket = products.find((el) => el.ref.path === ticket._refPath);
  if (matchedTicket) return true;
  // check unprocessed video ids
  const unprocessedVideoIds = products
    .flatMap((el) => el?.videoRefs?.flatMap((e) => e.id))
    .filter((el) => el);
  const ticketVideoIds = ticket.videoRefs.map((el) => el.id);
  return ticketVideoIds.every((el) => unprocessedVideoIds.includes(el));
};

/**
 * videoの配信ステータスを返す
 * BeforePlay → イベントの開閉場に関わらず、videoのisOpenがtrueでstartAtが現在日時より前。
 * EndLive → イベントが開場していて、videoのhasVODがfalseで、videoの次の配信がopenしている。
 * Live → イベントが開場していて、ライブ配信中。
 * Archive → イベントが開場していて、アーカイブ配信中。
 * NotPlayable → イベントとvideoが閉場している
 * @param param0
 * @returns
 */
export const getVideoStreamingStatus = ({
  video,
  isOpenStreamingPage,
}: {
  video: EventVideo & { nextVideo: EventVideo };
  isOpenStreamingPage: boolean;
}): Parameters<typeof getVideoActionStatus>[number]["streamingStatus"] => {
  const nowDate = new Date();
  if (isOpenStreamingPage) {
    if (!video.hasVOD && video?.nextVideo?.isOpen) return "EndLive";
    if (video.isOpen) return video.hasVOD ? "Archive" : "Live";
    if (nowDate < video.startAt.toDate()) return "BeforePlay";
    // 通らないはず
    return "NotPlayable";
  }
  if (nowDate < video.startAt.toDate()) return "BeforePlay";
  return "NotPlayable";
};

/**
 * stageの配信ステータスを返す
 *
 * BeforeLive: ライブ配信前
 * Live: ライブ配信中
 * Archive: アーカイブ配信中
 * NotPlayable: ライブ配信前以外の配信期間外
 * @returns
 */
export const getStageStreamingStatus = ({
  isOpenStreamingPage,
  hasVOD,
  startAt,
}: {
  isOpenStreamingPage: boolean;
  hasVOD: boolean;
  startAt: number; // timestamp
}): Parameters<typeof getStageActionStatus>[number]["streamingStatus"] => {
  if (isOpenStreamingPage) {
    if (hasVOD) return "Archive";
    return "Live";
  }
  const nowTimestampMills = Date.now();
  if (nowTimestampMills < startAt * 1000) return "BeforeLive";
  return "NotPlayable";
};

/**
 * get stage action status
 * TODO: testing
 * @param param0
 * @returns
 */
export const getStageActionStatus = ({
  isPurchased,
  isInCart,
  isOnSale,
  isSettlementConfirm,
  streamingStatus, // FIXME: 夏フェス段階ではここはeventのstatusだけでいいとしてるが、videoも考慮した方がいいかは検討
}: {
  isPurchased: boolean;
  isInCart: boolean;
  isOnSale: boolean;
  isSettlementConfirm: boolean;
  isOpenStreamingPage?: boolean;
  streamingStatus: "BeforeLive" | "Live" | "Archive" | "NotPlayable";
}): DayContent["actionStatus"] | StageContent["actionStatus"] => {
  if (isPurchased) {
    console.info(streamingStatus);
    if (streamingStatus === "BeforeLive") return "BeforePlay";
    if (streamingStatus === "Live") return "Playable";
    return "Purchased";
  }
  if (isSettlementConfirm) return "SettlementConfirm";
  if (isInCart) return "InCart";
  if (isOnSale) return "NotPurchased";
  return "NotAvailableForPurchase";
};

/**
 * get day action status
 * @param param0
 * @returns
 */
export const getDayActionStatus = ({
  isPurchased,
  isInCart,
  isOnSale,
  isSettlementConfirm,
}: {
  isPurchased: boolean;
  isInCart: boolean;
  isOnSale: boolean;
  isSettlementConfirm: boolean;
}): DayContent["actionStatus"] => {
  if (isPurchased) return "Purchased";
  if (isSettlementConfirm) return "SettlementConfirm";
  if (isInCart) return "InCart";
  if (isOnSale) return "NotPurchased";
  return "NotAvailableForPurchase";
};

/**
 * get video action status
 * @param param0
 * @returns
 */
export const getVideoActionStatus = ({
  isPurchased,
  isInCart,
  isOnSale,
  // @ts-expect-error TS2322
  isSettlementConfirm = null,
  isExistTicket,
  streamingStatus,
}: {
  isPurchased: boolean;
  isInCart: boolean;
  isOnSale: boolean;
  isSettlementConfirm?: boolean;
  isExistTicket: boolean;
  // liveとarchiveは今回区別する必要はないが用意だけしておく
  streamingStatus:
    | "Live"
    | "Archive"
    | "EndLive"
    | "BeforePlay"
    | "NotPlayable";
}): Video["actionStatus"] => {
  if (streamingStatus === "EndLive") return "EndLive";
  // if purchased
  if (isPurchased) {
    if (["Live", "Archive"].includes(streamingStatus)) return "Playable";
    if (streamingStatus === "BeforePlay") return "BeforePlay";
    return "NotPlayable";
  }
  // ↓ if not purchased
  if (isSettlementConfirm) return "SettlementConfirm";
  if (isInCart) return "InCart";
  if (isOnSale) return "NotPurchased";
  if (isExistTicket) return "NotAvailableForPurchase";
  return "NotPlayable";
};

/**
 * return day, stage and video action status
 * if video is null, return day or stage action status.
 * @param param0
 * @returns
 */
const getActionStatus = ({
  ticket,
  targetVideoIds, // day or stage or video で視聴できるvideoIdsが入る
  userVideos,
  userCartProducts,
  userUnprocessedOrders,
  eventInfo: { isOpenStreamingPage, hasVOD },
  type,
  // @ts-expect-error TS2322
  video = null,
  // @ts-expect-error TS2322
  stageStartAt = null,
}: {
  ticket: FesTicket;
  targetVideoIds: string[];
  userVideos: UserVideo[];
  userCartProducts: UserCartProduct[];
  userUnprocessedOrders: UserOrder[];
  eventInfo: EventInfo;
  type: "video" | "stage" | "day";
  video?: EventVideo & { nextVideo: EventVideo };
  stageStartAt?: number; // timestamp
}) => {
  const isPurchased = isPurchasedTicket({
    targetVideoIds,
    userVideos,
  });
  const isInCart = ticket
    ? isTicketInCart({
        ticket,
        userCartProducts,
      })
    : null;
  const isOnSale =
    ticket &&
    isDisplayPeriod(
      ticket.display,
      ticket.releaseDateTime,
      ticket.closeDateTime
    );
  const isSettlementConfirm =
    ticket &&
    isTicketSettlementConfirm({
      ticket,
      userUnprocessedOrders,
    });
  if (type === "video")
    return getVideoActionStatus({
      isPurchased,
      // @ts-expect-error TS2322
      isInCart,
      isOnSale,
      // event docのglobalなopenフラグも確認しておく
      streamingStatus: getVideoStreamingStatus({
        video,
        isOpenStreamingPage,
      }),
      isSettlementConfirm,
      isExistTicket: isPurchased !== null, // TODO
    });
  if (type === "stage")
    return getStageActionStatus({
      isPurchased,
      // @ts-expect-error TS2322
      isInCart,
      isOnSale,
      isSettlementConfirm,
      streamingStatus: getStageStreamingStatus({
        isOpenStreamingPage,
        hasVOD,
        startAt: stageStartAt,
      }),
    });
  if (type === "day")
    return getDayActionStatus({
      isPurchased,
      // @ts-expect-error TS2322
      isInCart,
      isOnSale,
      isSettlementConfirm,
    });
};

/**
 * add ticket to cart
 * TODO 別ファイル切り出すか検討
 * @param param0
 */
const addToCart = async ({
  eventId,
  products,
}: {
  eventId: string;
  products: {
    id: string;
    productType: "ticket";
    count: number;
    refPath: string;
  }[];
  fanClubId?: string;
  invitationCode?: string;
}) => {
  const fbToken = await fetchFbToken();
  // @ts-expect-error TS2769
  const response = await fetch(appConfig.PaymentSystem.addToCart, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `'Bearer ${fbToken}`,
    },
    body: JSON.stringify({ eventId, products }),
  });
  if (response.status === 200) {
    return await response.json();
  }
  return null;
};

// 以下HOTFIX
// バックエンドとほぼ同じ処理なのでほぼコピペ。
// sharedにする、タイムテーブルデータをfetchするAPIを作り、コピー元のfunctionを直接流用するなどする。
// ファイル構造など決めきれてないのでこのファイルに閉じ込めておく

/**
 * copy from @see https://github.com/balus-co-ltd/spwn/blob/2f4f05c29cdfd23b4165ceaee3399a4c758fb47b/packages/functions/src/models/UserVideo.ts#L7
 * @param eventId
 * @returns
 */
export const fetchUnexpiredUserVideos = async (eventId: string) => {
  const { currentUser } = firebase.auth();
  if (!currentUser) return [];
  const userVideo = await fetchUserVideos(currentUser.uid, eventId);
  const newUserVideos = await Promise.all(
    userVideo.map(async (v) => {
      if (v.expiredAt) return v;
      const expiredAt = await makeUserVideoExpiredAt(v);
      return {
        ...v,
        expiredAt,
      };
    })
  );
  return newUserVideos.filter(
    (userVideo) => Date.now() < userVideo.expiredAt.toMillis()
  );
};

/**
 * copy from @see https://github.com/balus-co-ltd/spwn/blob/2f4f05c29cdfd23b4165ceaee3399a4c758fb47b/packages/functions/src/repositry/userVideo.ts#L5
 * @param userId
 * @param eventId
 * @returns
 */
export const fetchUserVideos = async (
  userId: string,
  eventId: string
): Promise<UserVideo[]> => {
  return (
    await firebase
      .firestore()
      .collection(`users/${userId}/videos`)
      .where("eventRef", "==", firebase.firestore().doc(`events/${eventId}`))
      .get()
  ).docs.map((doc) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(doc.data() as any), // !!!
    _id: doc.id,
    _refPath: doc.ref.path,
  }));
};

/**
 * userVideoに視聴期限があればそのまま返し、なければマスターデータの参照を見にいく
 * @param userVideo
 * @returns
 */
export const makeUserVideoExpiredAt = async (userVideo: UserVideo) => {
  // fetch ticket master data.
  const ticketSnapshot = await userVideo.ticketRef.get();
  const ticket = ticketSnapshot.data();
  // get updatedAt.
  // userVideoモデルにupdatedAtを付与するべきだったのを当初していなかった
  const updatedAt =
    userVideo.updatedAt || (await fetchOrder(userVideo)).updatedAt;
  // streamingTypeによって視聴期限を返す
  // @ts-expect-error TS2345
  const expiredAtMillis = getExpired(ticket, updatedAt.toMillis());
  // @ts-expect-error TS2345
  return firebase.firestore.Timestamp.fromMillis(expiredAtMillis);
};

/**
 * fetch order.
 * こちらのorderRefへの参照処理は将来的に削除できる想定。
 * @param userVideo
 * @returns
 */
const fetchOrder = async (userVideo: UserVideo) => {
  // fetch order data.
  const orderSnapshot = await userVideo.orderRef.get();
  const order = orderSnapshot.data() as ActiveTransaction;
  return order;
};

/**
 * copy from @see https://github.com/balus-co-ltd/spwn/blob/2f4f05c29cdfd23b4165ceaee3399a4c758fb47b/packages/functions/src/models/Ticket.ts#L77
 * JS の都合上コード内では時間を ms 単位の unixtime で扱う
 * @param ticket
 * @param updatedAt
 * @returns
 */
export const getExpired = (ticket: FesTicket, updatedAt: number) => {
  const { streamingType } = ticket;
  if (streamingType === "Live") {
    return ticket["expiredDateTime"].toMillis();
  } else if (streamingType === "VOD") {
    return updatedAt + Number(ticket["vodActiveHours"]) * 3600 * 1000;
  }
  return null;
};
