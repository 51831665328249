/**
 * 今いるドメインが、テナントブランドドメインかどうか
 * FIXME テナント作成時点でDBに永続化した値を参照する
 */
export const isBrandDomain = () => {
  const brandDomains = [
    "dev-brand-a.spwn.jp",
    "stg-brand-a.spwn.jp",
    "0222.spwn.jp",
    "jintomo.spwn.jp",
    // "localhost"
  ];
  return brandDomains.includes(window.location.hostname);
};
