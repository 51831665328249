/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import React from "react";

type Props = {
  releasedAt: Date | null;
  closedAt: Date | null;
};

export const SalesPeriod: React.FC<Props> = ({ releasedAt, closedAt }) => {
  const dateToString = (date: Date) =>
    format(date, "yyyy年M月d日(E)HH:mm", { locale: ja });

  return releasedAt !== null && closedAt !== null ? (
    <div>
      <p css={styles.heading}>販売期間</p>
      <p css={styles.content}>
        {dateToString(releasedAt)} 〜 {dateToString(closedAt)}
      </p>
    </div>
  ) : releasedAt !== null && closedAt === null ? (
    <div>
      <p css={styles.heading}>販売期間</p>
      <p css={styles.content}>{dateToString(releasedAt)} 〜</p>
    </div>
  ) : null;
};

const styles = {
  heading: css`
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 4px;
  `,
  content: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #ff2600;
  `,
};
