/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useI18n, I18nTrans } from "hooks/i18n/i18n";
import { TextLink } from "ui/TextLink";

export const GoodsListHeaderCaution: React.FC<{
  isLoggedIn: boolean;
  isVenueTabSelected: boolean;
  isVenueTicketRequired: boolean;
  selectedPlaceCode: string;
  havingTicketPlaceCodes: string[];
  hasShipments: boolean;
  isAddressRegistered: boolean;
  ticketUrl: string;
}> = ({
  isLoggedIn,
  isVenueTabSelected,
  isVenueTicketRequired,
  selectedPlaceCode,
  havingTicketPlaceCodes,
  hasShipments,
  isAddressRegistered,
  ticketUrl,
}) => {
  const classes = styles();
  const { t } = useI18n();
  // ログインしている場合は何も表示しない
  if (!isLoggedIn) {
    return null;
  }
  return (
    <React.Fragment>
      {/* 会場受け取り商品選択時、会場のチケットを持っていない場合表示する */}
      {isVenueTabSelected &&
        isVenueTicketRequired &&
        !havingTicketPlaceCodes.includes(selectedPlaceCode) && (
          <div css={classes.root}>
            <ErrorOutlineIcon />
            <p>
              <I18nTrans i18nKey="goods.cautions.noTicket">
                この会場のチケットを持っていません。チケットの購入は
                <TextLink href={ticketUrl}>{t("goods.cautions.here")}</TextLink>
              </I18nTrans>
            </p>
          </div>
        )}
      {/* 配送商品タブ選択時、配送商品があり、住所が登録されていない場合表示する */}
      {!isVenueTabSelected && hasShipments && !isAddressRegistered && (
        <div css={classes.root}>
          <ErrorOutlineIcon />
          <p>
            <I18nTrans i18nKey="goods.cautions.noAddress">
              配送先の住所が登録されていません。住所の登録は
              <TextLink href={"/account/address"}>
                {t("goods.cautions.here")}
              </TextLink>
            </I18nTrans>
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

const styles = () => {
  return {
    root: css`
      display: flex;
      align-items: center;
      width: 90%;
      padding: 10px;
      margin: 0 auto 20px;
      background-color: #de3030;
      @media screen and (min-width: 768px) {
        width: 100%;
      }
      p {
        margin-left: 10px;
        color: #fff;
        font-size: 14px;
        line-height: 1.3em;
      }
      a {
        color: #fff;
        text-decoration: underline;
      }
      svg {
        fill: #fff;
      }
    `,
  };
};
