import { KeyedMutator } from "swr";

import useSWRImmutable from "swr/immutable";
import { useSpwnApiClient } from "api/useSpwnApiClient";
import {
  GetPublicPlanContentResponse,
  getPublicPlanContent,
} from "api/schema/spwn/getPublicPlanContent";

const generateKey = () => getPublicPlanContent.path;

type UseGetCrewPlanContentProps = {
  tenantId: string;
  planContentId: string;
};

type UseGetCrewPlanContentResult = {
  data?: GetPublicPlanContentResponse;
  error?: unknown;
  isValidating: boolean;
  mutate: KeyedMutator<GetPublicPlanContentResponse>;
};

export const useGetCrewPlanContent = ({
  tenantId,
  planContentId,
}: UseGetCrewPlanContentProps): UseGetCrewPlanContentResult => {
  const apiClient = useSpwnApiClient();
  // NOTE: Immutable にする
  // src/features/crew/CrewStreaming.tsx で使用しており、ライブ配信で大人数に revalidate されると大量通信につながるため
  // @see https://swr.vercel.app/docs/revalidation#disable-automatic-revalidations
  const { data, error, isValidating, mutate } = useSWRImmutable(
    generateKey(),
    async () => {
      const res = await apiClient.call("getPublicPlanContent", {
        tenantId,
        planContentId,
      });
      if (!res.ok) {
        throw new Error("API error occurred.");
      }
      return res.data;
    }
  );

  return { data, error, isValidating, mutate };
};
