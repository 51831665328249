/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "store";

import { useTimetableContent } from "hooks/event/TimetableContent";
import { BottomBorderHeadline } from "../../../components/atoms/headline/BottomBorderHeadline";
import LoaderSmall from "components/atoms/loader/BorderLoader";
import { EventTimetableDateList } from "../../../components/event/detail/timetable/EventTimetableDateList";
import { EventTimetableLayoutTimeAxis } from "../../../components/event/detail/timetable/EventTimetableLayoutTimeAxis";
import { EventTimetableLayoutGridLine } from "../../../components/event/detail/timetable/EventTimetableLayoutGridLine";
import { EventTimetableContents } from "../../../components/event/detail/timetable/EventTimetableContents";
import { EventTimetableSelectedDate } from "../../../components/event/detail/timetable/EventTimetableSelectedDate";
import { useHistory } from "react-router";
import { modalActions } from "../../../modules/modal";
import { cartActions } from "../../../modules/cart";
import type { DayContent, StageContent, Video } from "@spwn/types";

//タイムテーブルの設定
// export const STAGE_WIDTH = 320;
export const STAGE_WIDTH = 304;
export const TIMETABLE_CELL_HEIGHT = 32;
export const TIMETABLE_HOUR_CELL_HEIGHT = 193;

interface Props {}
export const EventDetailTimetable: React.FC<Props> = () => {
  const isLogin = useSelector((state: Store) => state.auth.isLogin);
  const [selectedDay, setSelectedDay] = useState<number>(0);
  const { loading, models, fetch, dispatchAddToCart } = useTimetableContent();
  // @see https://reactrouter.com/web/api/Hooks/usehistory
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * dispatch day, stage, video action.
   */
  const doContentAction = useCallback(
    ({
      actionStatus,
      ticketPath,
      // @ts-expect-error TS2322
      playVideoId = null,
    }: {
      actionStatus:
        | DayContent["actionStatus"]
        | StageContent["actionStatus"]
        | Video["actionStatus"];
      ticketPath: string;
      playVideoId?: string;
    }) => {
      console.info("doContentAction", actionStatus);

      if (!isLogin) {
        dispatch(modalActions.toggleLoginMethod());
        return;
      }

      switch (actionStatus) {
        case "NotPurchased":
          dispatchAddToCart({
            id: ticketPath.split("/").slice(-1)[0],
            count: 1,
            refPath: ticketPath,
          });
          break;
        case "Playable":
          if (!playVideoId) break;
          history.push(`/videos/${playVideoId}`);
          break;
        case "InCart":
          dispatch(cartActions.toggleMyCart({}));
          break;
        case "SettlementConfirm":
          dispatch(
            modalActions.toggleActionModal({
              actionModalType: "confirmAction",
              caption: "支払状況を確認", // {t("ticket.confirmSettlement")}
              msg: "ご入金が確認できていないチケットがございます。マイチケットにてお支払い状況をご確認ください。",
              btnMsg: "マイチケットで確認する",
              action: () => history.push(`/account/ticket`),
              // @ts-expect-error TS2322
              args: null,
            })
          );
          break;
        default:
          // do nothing
          console.log("do nothing");
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatchAddToCart]
  );

  if (loading) {
    return <LoaderSmall />;
  }

  if (!models) {
    return null;
  }

  const changeDisplayStage = (selectedDateIndex: number) => {
    setSelectedDay(selectedDateIndex);
  };

  // @ts-expect-error TS2532
  const STAGE_NUM = models.dayContents[selectedDay].stageNum;
  // @ts-expect-error TS2532
  const STAGE_START_AT = models.dayContents[selectedDay].stageStartAt;
  // @ts-expect-error TS2532
  const EVENT_BETWEEN_AT = models.dayContents[selectedDay].stageBetweenAt;

  // @ts-expect-error TS2532
  const dayActionStatus = models.dayContents[selectedDay].actionStatus;

  const classes = styles(STAGE_NUM);

  return (
    <section id="timetable">
      <BottomBorderHeadline title={"TIME TABLE"} subTitle={"タイムテーブル"} />

      {/* 開催日が1日しかない時、EventTimetableDateListを非表示にする */}
      {models.dayContents.length > 1 && (
        <EventTimetableDateList
          models={models.dayContents}
          selectedDay={selectedDay}
          changeDisplayStage={changeDisplayStage}
        />
      )}

      <section css={classes.timetable}>
        <EventTimetableSelectedDate
          // @ts-expect-error TS2322
          selectedDay={models.dayContents[selectedDay]}
          // @ts-expect-error TS2322
          dayActionStatus={dayActionStatus}
          doButtonAction={() =>
            // @ts-expect-error TS2345
            doContentAction(models.dayContents[selectedDay])
          }
        />

        {/* MEMO: EVENT_BETWEEN_AT / 3600 は小数になる可能性があるため、Math.floor()で小数点以下を切り上げる */}
        <section css={classes.layout}>
          {/*EventTimetableLayoutTimeAxis と EventTimeTableLayoutContentsGridLine には同じ値を渡す*/}
          <EventTimetableLayoutTimeAxis
            startAt={new Date(STAGE_START_AT * 1000).getHours()}
            betweenAt={Math.ceil(EVENT_BETWEEN_AT / 3600)}
          />

          <div css={classes.contents}>
            <EventTimetableLayoutGridLine
              startAt={new Date(STAGE_START_AT * 1000).getHours()}
              betweenAt={Math.ceil(EVENT_BETWEEN_AT / 3600)}
            />

            <EventTimetableContents
              stageNum={STAGE_NUM}
              startAt={STAGE_START_AT}
              betweenAt={Math.ceil(EVENT_BETWEEN_AT / 3600)}
              // @ts-expect-error TS2322
              models={models.dayContents[selectedDay]}
              // @ts-expect-error TS2322
              dayActionStatus={dayActionStatus}
              doContentAction={doContentAction}
              // @ts-expect-error TS2322
              selectedDay={models.dayContents[selectedDay]}
            />
          </div>
        </section>
      </section>
    </section>
  );
};

const styles = (STAGE_NUM: number) => {
  return {
    root: css``,
    timetable: css`
      @media screen and (min-width: 768px) {
        border: 4px solid #00c2ad;
      }
    `,
    layout: css`
      display: grid;
      grid-template-columns: 64px calc(${STAGE_WIDTH}px * ${STAGE_NUM});
      border-left: 4px solid #00c2ad;
      border-right: 4px solid #00c2ad;
      border-bottom: 4px solid #00c2ad;
      background-color: #000;
      overflow-x: scroll;
      overflow-y: hidden;
      @media screen and (min-width: 768px) {
        height: 800px;
        border: none;
        overflow: scroll;
      }
    `,
    contents: css`
      position: relative;
      padding-top: 115px;
    `,
  };
};
