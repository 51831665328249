/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useSelector } from "react-redux";

import type {
  Place,
  TicketConfig,
  Event,
} from "@spwn/types/firebase/firestore";
import type { TicketData } from "@spwn/types/firebase/database";

import { Store } from "store";
import { changeStyleWithHosting } from "utility/hosting";
import { sanitizer } from "utility/sanitize";

interface Props {
  displayTickets: TicketData[];
  selectedPlaceCode: string;
  placeMap: Record<string, Place>;
  ticketConfig: TicketConfig;
  event: Event;
  handleSelectorChange: (
    e: React.ChangeEvent<HTMLSelectElement>,
    ticket: TicketData
  ) => void;
}

const EventTicketSelectNum: React.FC<Props> = ({
  displayTickets,
  selectedPlaceCode,
  placeMap,
  ticketConfig,
  handleSelectorChange,
}: Props) => {
  const PURCHASE_MAX_LIMIT = 99;
  const isAddressRegistered = useSelector(
    (state: Store) => state.auth.isAddressRegistered
  );

  const classesEventTicketSelectNumDesign = eventTicketSelectNumDesign();
  return (
    <div css={classesEventTicketSelectNumDesign.root}>
      <h3 css={classesEventTicketSelectNumDesign.heading}>
        <span>2</span>チケットの枚数を選択してください
      </h3>

      <div css={classesEventTicketSelectNumDesign.ticketLayout}>
        {displayTickets.map((ticket, i) => {
          const place = placeMap[selectedPlaceCode];
          const countOptions = [];

          let n_ticket = Math.min(
            ticket.purchaseLimit,
            ticketConfig.config?.maxTicketPurchase ?? 0
          );
          /**
           * n_ticketが99999など極端に大きい数値がループに渡されないように
           * PURCHASE_MAX_LIMITより大きい場合は、n_ticketにPURCHASE_MAX_LIMITが入るようにする
           */
          n_ticket = Math.min(n_ticket, PURCHASE_MAX_LIMIT);
          for (let i = 0; i <= n_ticket; i++) {
            countOptions.push(
              <option id={ticket._id} key={i} value={i}>
                {i}
              </option>
            );
          }

          const isFree = Number(ticket.price_jpy) <= 0;
          // const alertInfo = ""; // eg. 限定21席 TODO@later
          return (
            <div css={classesEventTicketSelectNumDesign.ticketItem} key={i}>
              <div css={classesEventTicketSelectNumDesign.ticketItemText}>
                <div className="layout_l">
                  <p className="place">{place?.areaName}</p>
                  <h4 className="name">{ticket.name}</h4>
                </div>
                {isFree ||
                (ticket.isAddressRequired && !isAddressRegistered) ? null : (
                  <div className="layout_r">
                    <select
                      className="select"
                      defaultValue={"0"}
                      onChange={(e) => handleSelectorChange(e, ticket)}
                    >
                      {countOptions}
                    </select>
                  </div>
                )}
              </div>
              {ticket.description ? (
                <div
                  css={classesEventTicketSelectNumDesign.ticketItemBenefits}
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(ticket.description),
                  }}
                ></div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const eventTicketSelectNumDesign = () => {
  const hostingBaseStyleMap = changeStyleWithHosting().baseStyles;
  return {
    root: css``,
    heading: css`
      margin-bottom: 25px;
      display: flex;
      font-size: 18px;
      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
        font-size: 18px;
      }
      span {
        display: block;
        margin-right: 5%;
        font-family: "din-condensed", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 35px;
        @media screen and (min-width: 768px) {
          margin-right: 13px;
          font-size: 24px;
        }
      }
    `,
    ticketLayout: css`
      @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
      }
      &::after {
        @media screen and (min-width: 768px) {
          content: "";
          display: block;
          width: 32%;
        }
      }
    `,
    ticketItem: css`
      margin-bottom: 25px;
      border: 1px solid #d2d2d2;
      @media screen and (min-width: 768px) {
        width: 32%;
      }
    `,
    ticketItemText: css`
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 15px 15px 20px;
      .layout_l {
        width: calc(100% - 90px);
        margin-right: 15px;
        p.place {
          margin-bottom: 8px;
          color: ${hostingBaseStyleMap.accentColor};
          font-size: 11px;
          font-weight: bold;
          @media screen and (min-width: 768px) {
            font-size: 12px;
          }
        }
        h4.name {
          font-weight: bold;
          @media screen and (min-width: 768px) {
            font-size: 13px;
          }
        }
        p.price {
          margin-top: 12px;
          font-weight: bold;
          @media screen and (min-width: 768px) {
            font-size: 13px;
            font-weight: bold;
          }
        }
      }
      .layout_r {
        width: 64px;
      }
      .select {
        width: 64px;
        @media screen and (min-width: 768px) {
          font-size: 13px;
          padding: 8px 30px 8px 14px;
        }
      }
    `,
    ticketItemBenefits: css`
      padding: 15px;
      font-size: 10px;
      background-color: ${hostingBaseStyleMap.accentColor}20;
      li {
        padding-left: 1em;
        text-indent: -1em;
        line-height: 1.5em;
      }
    `,
  };
};

export default EventTicketSelectNum;
