/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import type { ProductData } from "@spwn/types/firebase/firestore";
import { Store } from "../../../store";

type EventGoodsItemSliderProps = {
  repGoods: ProductData;
};
export const EventGoodsItemSlider: React.FC<EventGoodsItemSliderProps> = (
  props
) => {
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const classesGoodsSliderDesign = goodsSliderDesign();

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const imgList = props.repGoods?.classThumbnails || [
    props.repGoods?.thumbnail,
  ];
  const capsuleThumbnail = props.repGoods.thumbnail;
  const settings = {
    // @ts-expect-error TS7006
    customPaging(i) {
      return <img key={i} src={imgList[i]} alt="goods thumbnail" />; // TODO: alt確認
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
  };

  if (displayEvent?.isCapsuleGoodsSellingEnabled) {
    return (
      <div css={classesGoodsSliderDesign.root}>
        <div className="mainImage">
          <img src={capsuleThumbnail} alt={""} />
        </div>
      </div>
    );
  }

  return (
    <div css={classesGoodsSliderDesign.root}>
      <Slider {...settings}>
        {imgList.map((thumbnail, i) => {
          return (
            <div key={i} className="mainImage">
              <img src={thumbnail} alt="" />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

const goodsSliderDesign = () => {
  return {
    root: css`
      width: 90%;
      padding-top: 20px;
      margin: 0 auto 16px;
      @media screen and (min-width: 768px) {
        width: calc(59% - 17px);
        padding-top: 0;
        margin-right: 34px;
        margin-bottom: 0;
      }
      .mainImage {
        margin-bottom: 15px;
        img {
          width: calc(100% - 1px);
          border: 2px solid #e5e5e5;
        }
      }
      .slick-thumb {
        position: inherit;
        bottom: 0;
        display: flex !important;
        flex-wrap: wrap !important;
        align-items: start;
        width: 100%;
        li {
          width: 17.6%;
          height: auto;
          margin: 0 3% 3% 0;
          border: 2px solid #e5e5e5;
          &:nth-of-type(5n) {
            margin: 0 0 3% 0;
          }
          &.slick-active {
            border: 2px solid #00c2ae;
          }
        }
        img {
          width: 100%;
        }
      }
    `,
  };
};
