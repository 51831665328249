import firebase from "firebase";
import useSWR from "swr";

import { Event } from "@spwn/types/firebase/firestore";

import {
  EVENT_OPEN_TIME_PERIOD,
  LIVE_EVENT_PRE_OPEN_TIME_PERIOD,
} from "constants/event";
import { getNowTimestamp } from "utility";
import { getBelongedHosting } from "utility/storage";
import { filterQueryDocuments } from "utility/firebase";

export type UseGetEventListProps =
  | {
      type:
        | "all"
        | "allVisible"
        | "goods"
        | "archive"
        | "open"
        | "closed"
        | "coming"
        | "openComing"
        | "vod"
        | "openVOD"
        | "live"
        | "capsule"
        | "openCapsule"
        | "openGoods"
        | "openRandom"
        | "lottery"
        | "today";
      enabled?: boolean | (() => boolean);
      limit?: number;
    }
  | {
      type: "byIds";
      enabled?: boolean | (() => boolean);
      ids?: string[];
    };

export const useGetEventList = (props: UseGetEventListProps) => {
  return useSWR(
    () => {
      // enabledがfalseの場合はuseSWRにnullキーを渡すことでデータ取得をスキップする
      const { enabled, ...rest } = props;
      let key: [string, UseGetEventListProps] | null = ["getEventList", rest];
      if (enabled === false) {
        key = null;
      } else if (typeof enabled === "function" && !enabled()) {
        key = null;
      }

      return key;
    },
    async ([_, props]) => {
      try {
        const { type } = props;
        switch (type) {
          case "all":
            return await getAllEventList(props.limit);
          case "allVisible":
            return await getAllVisibleEventList(props.limit);
          case "goods":
            return await getGoodsEventList(props.limit);
          case "archive":
            return await getArchiveEventList(props.limit);
          case "open":
            return await getOpenEventList(props.limit);
          case "closed":
            return await getClosedEventList(props.limit);
          case "coming":
            return await getComingEventList(props.limit);
          case "openComing":
            return await getOpenComingEventList(props.limit);
          case "vod":
            return await getVODEventList(props.limit);
          case "openVOD":
            return await getOpenVODEventList(props.limit);
          case "live":
            return await getLiveEventList(props.limit);
          case "capsule":
            return await getCapsuleEventList(props.limit);
          case "openCapsule":
            return await getOpenCapsuleEventList(props.limit);
          case "openGoods":
            return await getOpenGoodsEventList(props.limit);
          case "openRandom":
            return await getOpenRandomEventList(props.limit);
          case "today":
            return await getTodayEventList(props.limit);
          case "byIds":
            return props.ids && props.ids.length > 0
              ? await getEventListByIds(props.ids)
              : undefined;
          case "lottery":
            return await getOpenLotteryEventList(props.limit);
          default: {
            const shouldNotReachHere: never = type;
            throw new Error(
              `useGetEventList: invalid type: ${shouldNotReachHere}`
            );
          }
        }
      } catch (err) {
        // フックを使用する側でエラーハンドリングするのが望ましいものの、errorをチェックするのが面倒なので一旦ここでログだけ出しておく
        console.error(err);
        throw err;
      }
    }
  );
};

/**
 * 全イベント一覧取得
 */
const getAllEventList = async (limit?: number) => {
  const belongedHosting = getBelongedHosting();
  const q = firebase
    .firestore()
    .collection("events")
    .where("belongedHostings", "array-contains", belongedHosting);
  const snapshot = await (limit ? q.limit(limit).get() : q.get());

  return snapshot.docs
    .map((doc) => {
      return { ...doc.data(), _id: doc.id } as Event;
    })
    .filter(isOpen)
    .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
};

/**
 * 表示可能な全イベント一覧取得
 */
const getAllVisibleEventList = async (limit?: number) => {
  const belongedHosting = getBelongedHosting();
  const q = firebase
    .firestore()
    .collection("events")
    .where("belongedHostings", "array-contains", belongedHosting)
    .where("isHide", "==", false)
    .where("open", "<", firebase.firestore.Timestamp.now())
    .orderBy("open", "desc");
  const snapshot = await (limit ? q.limit(limit).get() : q.get());

  return snapshot.docs
    .map((doc) => {
      return { ...doc.data(), _id: doc.id } as Event;
    })
    .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
};

/**
 * グッズイベント一覧取得
 */
const getGoodsEventList = async (limit?: number) => {
  const belongedHosting = getBelongedHosting();
  const q = firebase
    .firestore()
    .collection("events")
    .where("belongedHostings", "array-contains", belongedHosting)
    .where("isHide", "==", false)
    .where("isGoodsSellingPageOpen", "==", true);
  const snapshot = await (limit ? q.limit(limit).get() : q.get());

  return snapshot.docs
    .map((doc) => {
      return { ...doc.data(), _id: doc.id } as Event;
    })
    .filter(isOpen)
    .filter((el) => {
      /**
       * optionalなキーはQueryで絞り込めないためここでフィルタリングする
       */
      return !el.isCapsuleGoodsSellingEnabled;
    })
    .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
};

/**
 * アーカイブイベント一覧取得
 */
const getArchiveEventList = async (limit?: number) => {
  const belongedHosting = getBelongedHosting();
  const q = firebase
    .firestore()
    .collection("events")
    .where("belongedHostings", "array-contains", belongedHosting)
    .where("isHide", "==", false)
    .where("isClosed", "==", true)
    .where("hasVOD", "==", false);
  const snapshot = await (limit ? q.limit(limit).get() : q.get());

  return snapshot.docs
    .map((doc) => {
      return { ...doc.data(), _id: doc.id } as Event;
    })
    .filter(isOpen)
    .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
};

/**
 * openイベント一覧取得
 */
const getOpenEventList = async (limit?: number) => {
  const belongedHosting = getBelongedHosting();
  const q = firebase
    .firestore()
    .collection("events")
    .where("belongedHostings", "array-contains", belongedHosting)
    .where("isHide", "==", false)
    .where("isClosed", "==", false);
  const snapshot = await (limit ? q.limit(limit).get() : q.get());

  return snapshot.docs
    .map((doc) => {
      return { ...doc.data(), _id: doc.id } as Event;
    })
    .filter(isOpen)
    .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
};

/**
 * closedイベント一覧取得
 */
const getClosedEventList = async (limit?: number) => {
  const belongedHosting = getBelongedHosting();
  const q = firebase
    .firestore()
    .collection("events")
    .where("belongedHostings", "array-contains", belongedHosting)
    .where("isHide", "==", false)
    .where("isClosed", "==", true);
  const snapshot = await (limit ? q.limit(limit).get() : q.get());

  return snapshot.docs
    .map((doc) => {
      return { ...doc.data(), _id: doc.id } as Event;
    })
    .filter(isOpen)
    .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
};

/**
 * Upcomingイベント一覧取得
 */
const getComingEventList = async (limit?: number) => {
  const belongedHosting = getBelongedHosting();
  // limitが指定されている場合はlimit分を設定しなければlimitを適用しない
  const q = firebase
    .firestore()
    .collection("events")
    .where("belongedHostings", "array-contains", belongedHosting)
    .where("isHide", "==", false)
    .where("isClosed", "==", false)
    .where("open", "<", firebase.firestore.Timestamp.now())
    .orderBy("open", "desc");
  const snapshot = await (limit ? q.limit(limit).get() : q.get());

  return snapshot.docs
    .map((doc) => {
      return { ...doc.data(), _id: doc.id } as Event;
    })
    .sort((a, b) => a.datetime.seconds - b.datetime.seconds); // 昇順
};

/**
 * 販売中/販売前のComingイベント一覧取得
 */
const getOpenComingEventList = async (limit?: number) => {
  const belongedHosting = getBelongedHosting();
  // 期間限定でハードコード
  // https://balus3d.slack.com/archives/C05U36GAERF/p1730970936516729
  const perPage = 20;

  return await filterQueryDocuments<Event>(
    () =>
      firebase
        .firestore()
        .collection("events")
        .where("belongedHostings", "array-contains", belongedHosting)
        .where("isHide", "==", false)
        .where("isClosed", "==", false)
        .where("hasVOD", "==", false)
        .where("isTicketSellingPageOpen", "==", true)
        .where(
          "ticketSellingOpenTime",
          "<=",
          firebase.firestore.Timestamp.now()
        )
        .orderBy("ticketSellingOpenTime", "desc"),
    (rows) => {
      return rows
        .filter(
          (el) =>
            !!el.ticketSellingCloseTime &&
            firebase.firestore.Timestamp.now().seconds <
              el.ticketSellingCloseTime.seconds
        )
        .filter(isOpen)
        .sort((a, b) => {
          // 期間限定でハードコード
          // https://balus3d.slack.com/archives/C05U36GAERF/p1730970936516729
          const pickupEvents = [
            "evt_Cl8dbh6QtKC3j8468BFu",
            "evt_rytzFKwUIsU7NfKLPboO",
            "evt_OT5maz9bMov4RDCQo2wy",
          ];
          if (a._id === undefined || b._id === undefined) return 0;

          if (pickupEvents.includes(a._id)) return -1;
          if (pickupEvents.includes(b._id)) return 1;

          return b.datetime.seconds - a.datetime.seconds;
        });
    },
    undefined,
    limit,
    perPage
  );
};

/**
 * VODイベント一覧取得
 * @deprecated 未使用であれば削除
 */
const getVODEventList = async (limit?: number) => {
  const belongedHosting = getBelongedHosting();
  const q = firebase
    .firestore()
    .collection("events")
    .where("belongedHostings", "array-contains", belongedHosting)
    .where("isHide", "==", false)
    .where("isClosed", "==", true)
    .where("hasVOD", "==", true);
  const snapshot = await (limit ? q.limit(limit).get() : q.get());

  return snapshot.docs
    .map((doc) => {
      return { ...doc.data(), _id: doc.id } as Event;
    })
    .filter(isOpen)
    .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
};

/**
 * 開催中のVODイベント一覧取得
 *
 * FIXME streamingPageOpenTime でwhereをかけ取得したデータに対して、datetime のソートをかけているため
 * Limitを利用した取得と全取得とでは、必ずしも同じデータが取得されるとは限らない
 * whereにdatetimeを加える必要がある
 */
const getOpenVODEventList = async (limit?: number) => {
  const belongedHosting = getBelongedHosting();
  const q = firebase
    .firestore()
    .collection("events")
    .where("belongedHostings", "array-contains", belongedHosting)
    .where("isHide", "==", false)
    .where("hasVOD", "==", true)
    .where("hasStreamingPageOpen", "==", true)
    .where("streamingPageOpenTime", "<=", firebase.firestore.Timestamp.now())
    .orderBy("streamingPageOpenTime", "desc");
  const snapshot = await (limit ? q.limit(limit).get() : q.get());

  return snapshot.docs
    .map((doc) => {
      return { ...doc.data(), _id: doc.id } as Event;
    })
    .filter((el) => {
      /**
       * isReleasedが存在しないのは想定外。
       * 過去意図しないdocumentがあることでイベント全体が表示されない事象が発生したことがあるため影響を最低限にする
       * @see https://www.notion.so/balusco/10-12-spwn-d998acac39f44a5b84d9a73e6a19522f
       */
      return el.isReleased !== undefined;
    })
    .filter(isOpen)
    .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
};

/**
 * Liveイベント一覧取得
 */
const getLiveEventList = async (limit?: number) => {
  const belongedHosting = getBelongedHosting();
  const q = firebase
    .firestore()
    .collection("events")
    .where("belongedHostings", "array-contains", belongedHosting)
    .where("isHide", "==", false)
    .where("isClosed", "==", false)
    .where("open", "<", firebase.firestore.Timestamp.now())
    .orderBy("open", "desc");
  const snapshot = await (limit ? q.limit(limit).get() : q.get());

  const now = getNowTimestamp();

  return snapshot.docs
    .map((doc) => {
      return { ...doc.data(), _id: doc.id } as Event;
    })
    .filter((el) => {
      if (el.isRequiredArApp) {
        return false;
      }

      return (
        (el.datetime.seconds - LIVE_EVENT_PRE_OPEN_TIME_PERIOD < now &&
          now < el.datetime.seconds + EVENT_OPEN_TIME_PERIOD) ||
        // if there is eventEndTime, display until end at.
        (el.eventEndTime &&
          el.datetime.seconds - LIVE_EVENT_PRE_OPEN_TIME_PERIOD < now &&
          now < el.eventEndTime.seconds)
      );
    })
    .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
};

/**
 * /capsuleのイベント一覧を取得する
 * ここで表示するイベントは、belongedHostingsの所属に関わらず、販売開始期間を過ぎた全てのカプセルイベントを表示する。
 */
const getCapsuleEventList = async (limit?: number) => {
  const q = firebase
    .firestore()
    .collection("events")
    .where("isHide", "==", false)
    .where("isCapsuleGoodsSellingEnabled", "==", true)
    .where("goodsSellingOpenTime", "<=", firebase.firestore.Timestamp.now())
    .orderBy("goodsSellingOpenTime", "desc");

  const snapshot = await (limit ? q.limit(limit).get() : q.get());

  return snapshot.docs
    .map((doc) => {
      return { ...doc.data(), _id: doc.id } as Event;
    })
    .filter(isOpen);
};

/**
 * 販売中のカプセルイベント一覧取得
 * @deprecated 未使用であれば削除
 */
const getOpenCapsuleEventList = async (limit?: number) => {
  const results = await filterQueryDocuments<Event>(
    () =>
      firebase
        .firestore()
        .collection("events")
        .where("isHide", "==", false)
        .where("isClosed", "==", false)
        .where("isCapsuleGoodsSellingEnabled", "==", true)
        .where("goodsSellingOpenTime", "<=", firebase.firestore.Timestamp.now())
        .orderBy("goodsSellingOpenTime", "desc"),
    (rows) => {
      return rows
        .filter(isOpen)
        .filter(
          (el) =>
            !!el.goodsSellingCloseTime &&
            el.goodsSellingCloseTime.seconds >
              firebase.firestore.Timestamp.now().seconds
        )
        .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
    },
    undefined,
    limit
  );

  return results.reverse();
};

/**
 * 販売中のグッズイベント一覧取得
 */
const getOpenGoodsEventList = async (limit?: number) => {
  const results = await filterQueryDocuments<Event>(
    () =>
      firebase
        .firestore()
        .collection("events")
        .where("isHide", "==", false)
        .where("isGoodsSellingPageOpen", "==", true)
        .where("isCapsuleGoodsSellingEnabled", "==", false)
        .where("goodsSellingOpenTime", "<=", firebase.firestore.Timestamp.now())
        .orderBy("goodsSellingOpenTime", "desc"),
    (items) => {
      return items
        .filter(isOpen)
        .filter(
          (el) =>
            !!el.goodsSellingCloseTime &&
            el.goodsSellingCloseTime.seconds >
              firebase.firestore.Timestamp.now().seconds
        )
        .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
    },
    undefined,
    limit
  );

  return results.reverse();
};

/**
 * 販売中のイベントランダム取得
 */
const getOpenRandomEventList = async (limit?: number) => {
  const belongedHosting = getBelongedHosting();

  return await filterQueryDocuments<Event>(
    () =>
      firebase
        .firestore()
        .collection("events")
        .where("belongedHostings", "array-contains", belongedHosting)
        .where("isHide", "==", false)
        .where("open", "<", firebase.firestore.Timestamp.now())
        .orderBy("open", "desc"),
    (rows) => {
      return rows
        .filter((el) => !el.isClosed || el.hasVOD)
        .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
    },
    (rows) => rows.sort(() => 0.5 - Math.random()),
    limit,
    limit && limit > 30 ? limit * 2 : 30
  );
};

/**
 * 今日開催のイベント一覧取得
 */
const getTodayEventList = async (limit?: number) => {
  const belongedHosting = getBelongedHosting();

  const startDate = new Date(Date.now() - EVENT_OPEN_TIME_PERIOD * 1000);
  // 次の日の0時
  const endDate = new Date();
  endDate.setHours(0, 0, 0, 0);
  endDate.setDate(endDate.getDate() + 1);

  return await filterQueryDocuments<Event>(
    () =>
      firebase
        .firestore()
        .collection("events")
        .where("belongedHostings", "array-contains", belongedHosting)
        .where("isClosed", "==", false)
        .where("isHide", "==", false)
        .where(
          "datetime",
          ">=",
          firebase.firestore.Timestamp.fromDate(startDate)
        )
        .orderBy("datetime", "asc"),
    (rows) => {
      return rows
        .filter(isOpen)
        .filter((el) => el.datetime.seconds < endDate.getTime() / 1000)
        .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
    },
    undefined,
    limit
  );
};

/*
 * 指定したidのイベント一覧取得
 */
const getEventListByIds = async (ids: string[]) => {
  // INには最大10個までしか指定できないので、10個ずつに分割する
  const queryList: string[][] = [];
  const perPage = 10;
  for (let i = 0; i < ids?.length; i += perPage) {
    queryList.push(ids.slice(i, i + perPage));
  }

  const collection = firebase.firestore().collection("events");
  const queryPromises = queryList.map(async (query) => {
    return collection
      .where(firebase.firestore.FieldPath.documentId(), "in", query)
      .get();
  });

  const events = await Promise.all(queryPromises).then((querySnapshotList) => {
    return querySnapshotList.flatMap((querySnapshot) => {
      return querySnapshot.docs
        .map((doc) => {
          return { ...doc.data(), _id: doc.id } as Event;
        })
        .filter(isOpen);
    });
  });

  // イベントリストの順番をidsの順番に並び替える
  return ids
    .map((id: string) => events.find((event) => event._id === id))
    .filter((event) => event !== undefined) as Event[];
};

/**
 * イベント一覧取得（抽選申込用）
 */
const getOpenLotteryEventList = async (limit?: number) => {
  const q = firebase
    .firestore()
    .collection("events")
    .where("isLotteryTicketSellingMode", "==", true)
    .where("isClosed", "==", false);
  const snapshot = await (limit ? q.limit(limit).get() : q.get());

  return snapshot.docs
    .map((doc) => {
      return { ...doc.data(), _id: doc.id } as Event;
    })
    .filter(isOpen)
    .sort((a, b) => b.datetime.seconds - a.datetime.seconds);
};

/**
 * 公開されているかどうか
 *
 * Portalで表示するイベントは、必ず公開後のものであるため、基本的には公開日時でフィルターする
 * クライアントサイドでのフィルターでしかないことは注意
 *
 * HOTFIX: 2024/03
 * @see https://www.notion.so/balusco/Tacitly-3rd-3b0fffa20c9b4a68aa0e9fa640352fa6
 *
 * 以前はadmin権限のあるユーザーに対しては非公開のイベントも表示するようにしていたが、一覧取得では利用しない
 * @see https://www.notion.so/balusco/spwn-jp-8a73407f2e754a63920b556af7e2d231
 */
export const isOpen = (event: Event) =>
  event.open.seconds <= new Date().getTime() / 1000;
