/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Link } from "react-router-dom";
import { changeStyleWithHosting } from "../../utility/hosting";
import { useI18n } from "../../hooks/i18n/i18n";

type BreadcrumbProps = {
  paths: [["/", "ホーム"], ...[string | (() => void), string][]]; // [to, name]
  additionalClassName?: string; // use for flowerstand
  isDarkModeEnabled?: boolean;
};
export const Breadcrumb = (props: BreadcrumbProps) => {
  const { t } = useI18n();
  // TODO: Variadic Tuple Types が導入される TypeScript 4.1 まで ignore
  // eslint-disable-next-line prefer-destructuring
  let paths: [string | (() => void), string][] = props.paths;
  const classesBreadcrumbDesign = breadcrumbDesign({
    isDarkModeEnabled: props.isDarkModeEnabled,
  });

  /**
   * remove home breadcrumb. first item is must to be home.
   */
  if (changeStyleWithHosting().commonSettings.route.home !== "/") {
    paths = paths.slice(1);
  }

  return (
    <div css={classesBreadcrumbDesign.root}>
      <ul>
        {paths.map((path, i, array) => {
          const [to] = path;
          const name = path[1] === "ホーム" ? t("common.routes.home") : path[1];
          if (i < array.length - 1) {
            if (typeof to === "string") {
              return (
                <li key={i}>
                  <Link to={to}>{name}</Link>
                </li>
              );
            } else {
              return (
                <li key={i}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={to}>カート</a>
                </li>
              );
            }
          } else {
            return <li key={i}>{name}</li>;
          }
        })}
      </ul>
    </div>
  );
};

// @ts-expect-error TS7006
const breadcrumbDesign = (props) => {
  const theSpecifiedTextColor = changeStyleWithHosting().baseStyles.accentColor;
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : css`
        color: ${theSpecifiedTextColor};
      `;
  const arrowColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : css`
        color: #000;
      `;
  const backgroundColorInDarkMode = props.isDarkModeEnabled
    ? css`
        background-color: #202020;
      `
    : css`
        background-color: #fff;
      `;
  const borderColorInDarkMode = props.isDarkModeEnabled
    ? css`
        border-bottom: 1px solid #fff;
      `
    : css`
        border-bottom: 1px solid #d2d2d2;
      `;

  return {
    root: css`
      display: none;
      padding: 6px 0;
      ${backgroundColorInDarkMode}
      ${borderColorInDarkMode}
      @media screen and (min-width: 768px) {
        display: block;
      }
      ul {
        display: flex;
        width: 95%;
        margin: 0 auto;
      }
      li {
        display: flex;
        align-items: center;
        ${textColorInDarkMode}
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        &:not(:last-child):after {
          content: ">";
          display: block;
          margin: 0 10px;
          ${arrowColorInDarkMode}
          font-weight: normal;
        }
      }
      a {
        ${textColorInDarkMode}
      }
    `,
  };
};
