import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";
import { TFunction } from "i18next";
import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { Redirect } from "react-router";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "@emotion/styled";

import type { FlowerStandColor } from "@spwn/types";
import type {
  Event,
  Place,
  PurchasedFlowerStand,
  PurchasedFlowerStandOrder,
} from "@spwn/types/firebase/firestore";
import type { PawItem } from "@spwn/types/firebase/database";

import { PawBalance as PawBalanceComponent } from "components/account/Paw";
import { BreadcrumbArea } from "components/common/Link";
import appConfig from "constants/appConfig";
import { FLOWER_COLOR_COUNT, SELECTABLE_COLOR_MAP } from "constants/event";
import defaultImg from "designs/images/customFlowerStandDefaultImg.svg";
import { useGetPlaceList } from "hooks/event/useGetPlaceList";
import {
  flowerStandActions,
  ReqPurchaseFlowerStand,
} from "modules/flowerStand";
import {
  ActionData,
  modalActions,
  ReqToggleError,
  NoticeData,
  NoticeModalType,
} from "modules/modal";
import { PawBalance } from "modules/purchase";
import { RedirectState } from "modules/router";
import { Store } from "store";
import {
  EventFlowerStandButtonList,
  EventPawInfo,
  fontDinMixin,
  LinkButton,
} from "styles";
import {
  getEventDisplayInfo,
  getLastDirNameFromHref,
  convertMapToValues,
  getDateString,
  replaceBr,
  convertPlacesArrayToDict,
} from "utility";
import { getFlowerStandDisplayInfo, FlowerStandItemInfo } from "utility/event";
import { changeStyleWithHosting } from "utility/hosting";
import EventFlowerStandConfirm from "./EventFlowerStandConfirm";

export const EventFlowerStandItem: React.FC = () => {
  const event = useSelector((state: Store) => state.event.displayEvent);

  // Place情報を取得
  const placeCodes = event.placeInfo.flowerPlaces;
  const { data: places } = useGetPlaceList(placeCodes);
  const placeMap =
    places !== undefined ? convertPlacesArrayToDict(places) : undefined;

  return <EventFlowerStandItemConnected event={event} placeMap={placeMap} />;
};

type Props = ValueProps &
  FuncProps &
  ParentProps & {
    event: Event;
    placeMap?: Record<string, Place>;
  };

interface ParentProps extends WithTranslation {
  t: TFunction;
}
interface ValueProps {
  pathname: string;
  flowerStandMap: Record<string, PawItem>;
  pawBalance: PawBalance;
  user: firebase.User;
  purchasedFlowerStandMap: Record<string, PurchasedFlowerStand>;
  completePurchase: boolean;
  isAdmin: boolean;
}
interface FuncProps {
  toggleNotice: (msgObj: NoticeData) => void;
  toggleError: (msgObj: ReqToggleError) => void;
  toggleActionModal: (obj: ActionData) => void;
  purchaseFlowerStand: (obj: ReqPurchaseFlowerStand) => void;
  toggleCompleteStatus: () => void;
  togglePawChargeModal: () => void;
}
interface States {
  isConfirm: boolean; // to confirm page
  isUpdate: boolean; // to confirm page to update
  flowerStandName: string;
  flowerStand: FlowerStandItemInfo;
  giftColor: FlowerStandColor;
  giftPlaces: string[];
  giftName: string;
  selectedPlaceMap: Record<string, boolean>;
  previewImg: string;
  customImagePath: string;
  profileImagePath: string;
  // @ts-expect-error TS7008
  uploadImageFile; // for upload firebase storage
  generatedImage: Blob; // for upload firebase storage
  updateOrderId: string; // for update flower stand item
  displayValue: number; // total paw value
}

const EventFlowerStandItemPawInfo = styled(EventPawInfo)`
  margin-bottom: 30px;
  @media screen and (min-width: 768px) {
    margin-bottom: 60px;
  }
  .layout {
    .pawChargeButton {
      display: block;
      width: 48%;
      padding: 12px 0;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      line-height: 1.2em;
      letter-spacing: 1px;
      background-color: #00c2ae;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        width: 350px;
        padding: 15px 0;
        font-size: 15px;
        letter-spacing: 2px;
      }
    }
  }
`;

EventFlowerStandItemPawInfo.displayName = "EventFlowerStandITemPawInfo";

const Container = styled.section`
  padding-bottom: 100px;
  margin: 0 auto;
  background-color: #fff;
  .flowerStandInfoLayout {
    padding-top: 30px;
    @media screen and (min-width: 768px) {
      padding-top: 40px;
    }
    &.purchase_notification_layout {
      padding-top: 0;
    }
    /* TODO@Kawano 表示の確認・class名の変更 */
    .purchase_notification {
      margin-bottom: 40px;
      background-color: #06cfd7;
      .link {
        display: block;
        border-bottom: 1px solid #fff;
        .text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 0;
          width: 90%;
          margin: 0 auto;
          @media screen and (min-width: 768px) {
            padding: 5px 0;
            width: 980px;
            max-width: 90%;
          }
          p {
            color: #fff;
            font-size: 14px;
            letter-spacing: 1px;
          }
        }
      }
    }
    .flowerStandInfo {
      max-width: 980px;
      width: 90%;
      margin: 0 auto;
      .flowerStandInfoHeader {
        margin-bottom: 40px;
        .productName {
          margin-bottom: 15px;
          font-size: 20px;
          font-weight: bold;
          line-height: 1.3em;
          @media screen and (min-width: 768px) {
            font-size: 30px;
          }
        }
        .productText {
          margin-bottom: 20px;
          line-height: 1.5em;
          @media screen and (min-width: 768px) {
            margin-bottom: 40px;
          }
        }
        .productPrice {
          font-size: 20px;
          font-weight: bold;
          text-align: right;
          @media screen and (min-width: 768px) {
            font-size: 25px;
          }
        }
      }
      .flowerStandImage {
        width: 100%;
        margin-bottom: 45px;
      }
      .inputFieldLayout {
        margin-bottom: 40px;
        @media screen and (min-width: 768px) {
          margin-bottom: 80px;
        }
        .headingDesign {
          margin-bottom: 20px;
          letter-spacing: 1px;
          @media screen and (min-width: 768px) {
            display: flex;
            align-items: center;
            margin-bottom: 40px;
          }
          h2 {
            ${fontDinMixin};
            padding: 5px 0 0 15px;
            margin-bottom: 5px;
            font-size: 35px;
            border-left: 5px solid #01ffe1;
            @media screen and (min-width: 768px) {
              margin-bottom: 0;
              letter-spacing: 3px;
            }
          }
          p {
            padding-left: 21px;
            font-size: 12px;
            @media screen and (min-width: 768px) {
              font-size: 16px;
            }
          }
        }
        .selectColorFormLayout {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          margin: 0 auto;
          @media screen and (min-width: 768px) {
            width: 740px;
          }
          &:after {
            content: "";
            display: block;
            width: 30%;
            @media screen and (min-width: 768px) {
              content: none;
            }
          }
          .selectColor {
            display: block;
            width: 30%;
            margin-bottom: 15px;
            @media screen and (min-width: 768px) {
              width: 120px;
              margin-bottom: 0;
            }
            input {
              display: none;
              &:checked + .checkbox {
                background-color: #f2fdfd;
                border: 2px solid #009a9a;
              }
              &:checked + .checkbox:before {
                border: 2px solid #009a9a;
              }
              &:checked + .checkbox:after {
                opacity: 1;
              }
            }
            .checkbox {
              position: relative;
              display: flex;
              background-color: #fff;
              border: 2px solid #dcdbdc;
              transition: 0.3s;
              &:before {
                content: "";
                display: block;
                position: absolute;
                top: 13px;
                left: 14px;
                width: 20px;
                height: 20px;
                border: 1px solid #999;
                border-radius: 50%;
                @media screen and (min-width: 768px) {
                  top: 15px;
                  left: 15px;
                  width: 25px;
                  height: 25px;
                }
              }
              &:after {
                content: "";
                display: block;
                position: absolute;
                top: 16px;
                left: 17px;
                width: 14px;
                height: 14px;
                background: #009a9a;
                border-radius: 50%;
                opacity: 0;
                transition: 0.3s;
                @media screen and (min-width: 768px) {
                  top: 19px;
                  left: 19px;
                  width: 17px;
                  height: 17px;
                }
              }
              .label {
                width: 100%;
                padding: 10px 4% 10px 52%;
                @media screen and (min-width: 768px) {
                  padding: 14px 35px 14px 60px;
                }
                span {
                  font-weight: bold;
                  @media screen and (min-width: 768px) {
                    font-size: 18px;
                  }
                }
              }
            }
          }
          .red.selectColor {
            input {
              &:checked + .checkbox {
                background-color: #ffeaee;
                border: 2px solid #ef7086;
              }
              &:checked + .checkbox:before {
                border: 2px solid #ef7086;
              }
            }
            .checkbox:after {
              background: #ef7086;
            }
          }
          .blue.selectColor {
            input {
              &:checked + .checkbox {
                background-color: #ebf2fd;
                border: 2px solid #5e96f1;
              }
              &:checked + .checkbox:before {
                border: 2px solid #5e96f1;
              }
            }
            .checkbox:after {
              background: #5e96f1;
            }
          }
          .yellow.selectColor {
            input {
              &:checked + .checkbox {
                background-color: #fff9d5;
                border: 2px solid #f3c043;
              }
              &:checked + .checkbox:before {
                border: 2px solid #f3c043;
              }
            }
            .checkbox:after {
              background: #f3c043;
            }
          }
          .green.selectColor {
            input {
              &:checked + .checkbox {
                background-color: #eaf9ee;
                border: 2px solid #60be79;
              }
              &:checked + .checkbox:before {
                border: 2px solid #60be79;
              }
            }
            .checkbox:after {
              background: #60be79;
            }
          }
          .purple.selectColor {
            input {
              &:checked + .checkbox {
                background-color: #f5ebfb;
                border: 2px solid #aa88ee;
              }
              &:checked + .checkbox:before {
                border: 2px solid #aa88ee;
              }
            }
            .checkbox:after {
              background: #aa88ee;
            }
          }
        }
        .selectLocationFormLayout {
          width: 100%;
          margin: 0 auto;
          @media screen and (min-width: 768px) {
            width: 740px;
          }
          .selectLocation {
            display: block;
            width: 100%;
            cursor: pointer;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            input {
              display: none;
              &:checked + .selectbox {
                background-color: #f2fdfd;
                border: 2px solid #009a9a;
              }
              &:checked + .selectbox:before {
                transform: rotate(-45deg) scale(1, 1);
              }
            }
            .selectbox {
              position: relative;
              display: block;
              background-color: #fff;
              border: 2px solid #dcdbdc;
              &:before {
                content: "";
                position: absolute;
                top: 18px;
                left: 25px;
                width: 14px;
                height: 7px;
                border: 3px solid #00c5cb;
                border-top-style: none;
                border-right-style: none;
                transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
                transform: rotate(-45deg) scale(0, 0);
                z-index: 1;
                @media screen and (min-width: 768px) {
                  top: 23px;
                }
              }
              &:after {
                content: "";
                position: absolute;
                top: 11px;
                left: 20px;
                width: 23px;
                height: 23px;
                border: 1px solid #adaeae;
                background: #ffffff;
                cursor: pointer;
                @media screen and (min-width: 768px) {
                  top: 16px;
                }
              }
            }
            .label {
              display: block;
              padding: 10px 30px 10px 65px;
              @media screen and (min-width: 768px) {
                display: flex;
                padding: 14px 30px 14px 65px;
              }
              span {
                display: block;
                font-size: 16px;
                font-weight: bold;
                @media screen and (min-width: 768px) {
                  font-size: 18px;
                }
              }
            }
          }
        }
        .inputNameFormLayout {
          @media screen and (min-width: 768px) {
            width: 740px;
            margin: 0 auto;
          }
          .cautionText {
            margin-bottom: 10px;
          }
          input {
            width: 100%;
            padding: 10px;
            margin-bottom: 15px;
            font-size: 18px;
            border: 2px solid #d4d4d4;
          }
          .letterLimit {
            font-size: 14px;
            text-align: right;
          }
        }
        .uploadImageFormLayout {
          max-width: 740px;
          width: 100%;
          margin: 0 auto;
          .previewImage {
            width: 60%;
            margin: 0 auto 30px;
            @media screen and (min-width: 768px) {
              width: 360px;
            }
            img {
              width: 100%;
            }
          }
          .uploadCautionText {
            margin-bottom: 20px;
            p {
              font-size: 14px;
              line-height: 1.3em;
              &:not(:last-child) {
                margin-bottom: 10px;
              }
              span {
                text-decoration: underline;
              }
            }
          }
          .uploadImageButtonLayout {
            display: block;
            input {
              display: none;
            }
            .uploadImageButton {
              display: block;
              padding: 10px 0;
              margin: 0 auto;
              color: #fff;
              font-size: 14px;
              font-weight: bold;
              text-align: center;
              line-height: 1.2em;
              letter-spacing: 1px;
              background-color: #00c2ae;
              border: 2px solid #00c2ae;
              cursor: pointer;
              @media screen and (min-width: 768px) {
                padding: 13px 0;
                font-size: 15px;
                letter-spacing: 2px;
              }
            }
          }
        }
      }
    }
    .total {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 90%;
      margin: 0 auto 60px;
      @media screen and (min-width: 768px) {
        width: 740px;
        margin: 0 auto 100px;
      }
      .heading {
        margin-right: 100px;
        font-size: 18px;
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
      }
      .num {
        font-size: 25px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 30px;
        }
      }
    }
  }
`;

Container.displayName = "EventFlowerStandItemContainer";

class EventFlowerStandItemLogic extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isConfirm: false,
      isUpdate: false,
      flowerStandName: getLastDirNameFromHref(
        this.props.pathname,
        "flower-stand"
      ),
      // @ts-expect-error TS2322
      flowerStand: null,
      // @ts-expect-error TS2322
      giftColor: null,
      giftPlaces: [],
      giftName: "",
      // @ts-expect-error TS2322
      selectedPlaceMap: null,
      previewImg: defaultImg,
      // @ts-expect-error TS2322
      customImagePath: null,
      // @ts-expect-error TS2322
      profileImagePath: null,
      uploadImageFile: null,
      // @ts-expect-error TS2322
      generatedImage: null,
      // @ts-expect-error TS2322
      updateOrderId: null,
      displayValue: 0,
    };
  }

  static getDerivedStateFromProps(props: Props, state: States) {
    let { flowerStand } = state;
    const { flowerStandName } = state;

    if (
      !props.flowerStandMap ||
      Object.keys(props.flowerStandMap).length === 0
    ) {
      return null;
    }
    const _list = convertMapToValues(props.flowerStandMap).filter(
      (el) => el.name === flowerStandName
    );
    if (_list.length === 0) {
      return null;
    }
    // @ts-expect-error TS2345
    flowerStand = getFlowerStandDisplayInfo(_list[0]);

    if (props.completePurchase) {
      // reset complete purchase status
      props.toggleCompleteStatus();
      // go top
      window.scrollTo(0, 0);
      return {
        updateOrderId: null,
        giftColor: null,
        giftPlaces: [],
        giftName: "",
        selectedPlaceMap: null,
        previewImg: defaultImg,
        customImagePath: null,
        isUpdate: false,
        isConfirm: false,
      };
    }

    return { flowerStand };
  }

  showRegulation = (modalType: NoticeModalType) => {
    this.props.toggleNotice({ modalType });
  };

  /**
   *
   */
  checkOnChangeField = (checkPaw = false): boolean => {
    const { t } = this.props;
    if (!this.state.giftColor) {
      this.props.toggleError({ msg: t("flowerstand.item.colorError") });
      return false;
    }
    if (this.state.giftPlaces.length === 0) {
      this.props.toggleError({ msg: t("flowerstand.item.placeError") });
      return false;
    }
    if (this.state.flowerStand.addCustomName && this.state.giftName === "") {
      this.props.toggleError({ msg: t("flowerstand.item.nameError") });
      return false;
    }
    if (
      this.state.flowerStand.addProfileImg &&
      this.state.profileImagePath === null
    ) {
      this.props.toggleError({ msg: t("flowerstand.item.profileError") });
      return false;
    }
    if (
      this.state.flowerStand.addCustomImg &&
      this.state.customImagePath === null
    ) {
      this.props.toggleError({ msg: t("flowerstand.item.imageError") });
      return false;
    }
    const diff =
      this.state.flowerStand.pawValue * this.state.giftPlaces.length -
      this.props.pawBalance.paidValue;
    if (checkPaw && diff > 0) {
      this.props.togglePawChargeModal();
      return false;
    }
    return true;
  };

  toggleConfirm = (clearInputField = false) => {
    // clear input field if back from update mode
    clearInputField &&
      this.setState({
        // @ts-expect-error TS2322
        updateOrderId: null,
        // @ts-expect-error TS2322
        giftColor: null,
        giftPlaces: [],
        giftName: "",
        // @ts-expect-error TS2322
        selectedPlaceMap: null,
        previewImg: defaultImg,
        // @ts-expect-error TS2322
        customImagePath: null,
        isUpdate: false,
      });
    // check input info
    if (!clearInputField && !this.checkOnChangeField()) {
      return;
    }
    // go top
    window.scrollTo(0, 0);
    this.setState({
      isConfirm: !this.state.isConfirm,
    });
  };

  // @ts-expect-error TS7006
  selectColor = (e) => {
    this.setState({ giftColor: e.currentTarget.value });
  };

  selectPlace = (placeCode: string) => {
    const isSelected =
      !this.state.selectedPlaceMap ||
      (this.state.selectedPlaceMap && !this.state.selectedPlaceMap[placeCode]);
    const selectedPlaceMap = {
      ...this.state.selectedPlaceMap,
      [placeCode]: isSelected,
    };
    const giftPlaces = [];
    for (const placeCode of Object.keys(selectedPlaceMap)) {
      if (selectedPlaceMap[placeCode]) {
        giftPlaces.push(placeCode);
      }
    }
    this.setState({
      selectedPlaceMap,
      giftPlaces,
      displayValue: this.state.flowerStand.pawValue * giftPlaces.length,
    });
  };

  inputGiftName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ giftName: e.currentTarget.value });
  };

  setCustomImagePath = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { t } = this.props;
    const { files } = e.currentTarget;
    if (!files || files.length === 0 || files[0] === undefined) {
      return;
    }
    // eslint-disable-next-line prefer-destructuring
    const file = files[0];
    if (file.size > appConfig.FlowerStand.customImgFileSize) {
      this.props.toggleError({ msg: t("flowerstand.common.uploadSize") });
      this.setState({ previewImg: defaultImg });
      return;
    }
    const imgUrl = URL.createObjectURL(file);
    this.setState({
      uploadImageFile: file,
      previewImg: imgUrl,
      customImagePath: imgUrl,
    });
  };

  setGenerateImage = (image: Blob) => {
    this.setState({
      generatedImage: image,
    });
  };

  purchaseFlowerStand = () => {
    const {
      isUpdate,
      flowerStand,
      giftName,
      giftPlaces,
      giftColor,
      updateOrderId,
      displayValue,
    } = this.state;
    const eventId = this.props.event._id;
    if (!this.checkOnChangeField(isUpdate === false) || !eventId) {
      return;
    }
    const count = giftPlaces.length;
    let items = {};
    giftPlaces.forEach((placeCode) => {
      items = {
        ...items,
        [placeCode]: {
          color: giftColor,
          place: placeCode,
        },
      };
    });
    const payload: ReqPurchaseFlowerStand = {
      isUpdateMode: isUpdate,
      eventId,
      itemId: flowerStand._id,
      item: flowerStand,
      giftingName: giftName,
      uploadImageFile: this.state.uploadImageFile,
      generatedImage: this.state.generatedImage,
      count,
      giftColor,
      giftPlaces,
      orderId: updateOrderId,
      displayValue,
    };
    this.props.purchaseFlowerStand(payload);
  };

  /**
   * go to confirm page to update
   */
  toUpdateMode = (orderId: string, data: PurchasedFlowerStandOrder) => {
    // check only if can upload img
    this.state.flowerStand.addCustomImg &&
      this.fetchConfirmPreviewImg(data.giftImgPath);
    this.setState({
      updateOrderId: orderId,
      giftColor: data.giftColor,
      giftPlaces: data.giftPlaces,
      giftName: data.giftName,
      isConfirm: true,
      isUpdate: true,
    });
  };
  fetchConfirmPreviewImg = (giftImgPath: string) => {
    const { t } = this.props;
    firebase
      .storage()
      .ref(giftImgPath)
      .getDownloadURL()
      .then((url) => {
        this.setState({
          customImagePath: url,
        });
      })
      .catch((error) => {
        let msg = "";
        switch (error.code) {
          case "storage/unauthorized":
            msg = t("flowerstand.common.authError");
            break;
          default:
            msg = t("flowerstand.common.exceptionError");
            break;
        }
        this.props.toggleError({ msg });
      });
  };

  togglePawChargeModal = () => {
    this.props.togglePawChargeModal();
  };

  render() {
    const { t, event, placeMap, pawBalance, user, purchasedFlowerStandMap } =
      this.props;
    const { flowerStand } = this.state;

    if (!event || !placeMap || !flowerStand || !purchasedFlowerStandMap) {
      return <></>;
    }

    const dispInfo = getEventDisplayInfo(event);
    const purchasedFlowerStand = purchasedFlowerStandMap[flowerStand._id];
    const toConfirmClassName = purchasedFlowerStand
      ? "purchase_notification_layout"
      : "";

    if (!this.props.isAdmin && dispInfo.flowerStandSaleStatus !== "ON_SALE") {
      const msg = t("flowerstand.item.periodError");
      const redirectState: RedirectState = {
        noticeMsg: msg,
      };
      return (
        <Redirect
          to={{ pathname: `/events/${dispInfo.eventId}`, state: redirectState }}
        />
      );
    }

    if (this.state.isConfirm) {
      return (
        <EventFlowerStandConfirm
          isUpdate={this.state.isUpdate}
          flowerStand={flowerStand}
          giftName={this.state.giftName}
          giftColor={this.state.giftColor}
          giftPlaces={this.state.giftPlaces}
          eventInfo={dispInfo}
          placeMap={placeMap}
          pawBalance={this.props.pawBalance.paidValue}
          totalPawValue={this.state.displayValue}
          customImagePath={this.state.customImagePath}
          profileImagePath={this.state.profileImagePath}
          toggleConfirm={this.toggleConfirm}
          purchaseFlowerStand={this.purchaseFlowerStand}
          selectColor={this.selectColor}
          inputGiftName={this.inputGiftName}
          setCustomImagePath={this.setCustomImagePath}
          setGeneratedImage={this.setGenerateImage}
        />
      );
    }

    const breadcrumbText =
      changeStyleWithHosting().commonSettings.menu.event.text;
    const flowerStandImage = flowerStand.imgUrl
      ? flowerStand.imgUrl
      : flowerStand.flowerListImg;

    return (
      <Container id="contents">
        <BreadcrumbArea
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            ["/events", breadcrumbText],
            ["/events/" + dispInfo.eventId, dispInfo.eventTitle],
            [
              `/events/${dispInfo.eventId}/flower-stand`,
              t("flowerstand.item.flowerList"),
            ],
            [null, flowerStand.name],
          ]}
          additionalClassName={toConfirmClassName}
        />

        {/* FIXME: consider to use clsx or classnames */}
        <div className={`flowerStandInfoLayout ${toConfirmClassName ?? ""}`}>
          {
            // display confirm button for purchased flowerstand
            purchasedFlowerStand ? (
              <div className="purchase_notification">
                {Object.keys(purchasedFlowerStand.orders).map((orderId, i) => {
                  const order = purchasedFlowerStand.orders[orderId];
                  const purchasedDate = getDateString(
                    new Date(Number(orderId))
                  );
                  // @ts-expect-error TS18048
                  if (order.purchaseFailed) {
                    return <></>;
                  }
                  return (
                    // TODO: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/anchor-is-valid.md
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      key={i}
                      className="link"
                      // @ts-expect-error TS2345
                      onClick={() => this.toUpdateMode(orderId, order)}
                    >
                      <div className="text">
                        <p>
                          {purchasedDate} {t("flowerstand.item.purchase")}
                        </p>
                        <p>{t("flowerstand.item.check")}</p>
                      </div>
                    </a>
                  );
                })}
              </div>
            ) : (
              <></>
            )
          }

          <div className="flowerStandInfo">
            <div className="flowerStandInfoHeader">
              <h2 className="productName">{`${flowerStand.name}${t(
                "flowerstand.item.flowerStandSum",
                {
                  count: FLOWER_COLOR_COUNT,
                }
              )}`}</h2>
              <p className="productText" style={{ whiteSpace: "pre-line" }}>{`${
                flowerStand.fullname
              }\n${replaceBr(flowerStand.description)}`}</p>
              <p className="productPrice">
                {flowerStand.pawValue.toLocaleString()} PAW
              </p>
            </div>

            <img
              src={flowerStandImage}
              alt={flowerStand.name}
              className="flowerStandImage"
            />

            <div className="inputFieldLayout">
              <div className="headingDesign">
                <h2>COLOR</h2>
                <p>{t("flowerstand.item.flowerColor")}</p>
              </div>

              <div className="selectColorFormLayout">
                {Object.keys(SELECTABLE_COLOR_MAP).map(
                  // @ts-expect-error TS2345
                  (colorId: FlowerStandColor, i) => {
                    const colorData = SELECTABLE_COLOR_MAP[colorId];
                    const checked = colorData.id === this.state.giftColor;
                    return (
                      <label
                        key={i}
                        className={`selectColor ${colorData.className}`}
                        onClick={() =>
                          this.setState({ giftColor: colorData.id })
                        }
                      >
                        <input
                          id="place"
                          name="place"
                          type="radio"
                          defaultChecked={checked}
                        />
                        <span className="checkbox">
                          <span className="label">
                            <span>{colorData.name}</span>
                          </span>
                        </span>
                      </label>
                    );
                  }
                )}
              </div>
            </div>

            <div className="inputFieldLayout">
              <div className="headingDesign">
                <h2>PLACE</h2>
                <p>{t("flowerstand.item.place")}</p>
              </div>

              <div className="selectLocationFormLayout">
                {dispInfo.placeInfo.flowerPlaces.map((placeCode: string, i) => {
                  const place = placeMap[placeCode];
                  const checked =
                    this.state.selectedPlaceMap &&
                    this.state.selectedPlaceMap[placeCode];
                  return (
                    <label key={i} className="selectLocation">
                      <input
                        id="place"
                        name="place"
                        type="checkbox"
                        defaultChecked={checked}
                        onClick={() => this.selectPlace(placeCode)}
                      />
                      <span className="selectbox">
                        <span className="label">
                          {/*
                           // @ts-expect-error TS18048 */}
                          <span>{place.areaName}</span>
                          {/*
                           // @ts-expect-error TS18048 */}
                          <span>{place.venueName}</span>
                        </span>
                      </span>
                    </label>
                  );
                })}
              </div>
            </div>

            {
              // 種類に関わらず名前を入力できるようにする。（addCustomNameがfalseでも確認画面で入力できてしまっていたため）
              // addCustomNameがtrueの場合は、必ず入力しなければならない
              this.addCustomName()
            }

            {flowerStand.addCustomImg ? this.addCustomImage() : <></>}
          </div>

          {/* TODO@Kawano コンフリクトを避けるため、PAW購入ポップアップブランチをマージ後CSSの調整を行う。 */}
          <EventFlowerStandItemPawInfo>
            <div className="layout">
              <div className="balance">
                <p className="heading">{t("flowerstand.item.balance")}</p>
                <PawBalanceComponent
                  pawValue={pawBalance.paidValue}
                  user={user}
                />
              </div>
              <p
                onClick={this.togglePawChargeModal}
                className="pawChargeButton"
              >
                {t("pawChargeHistory.topUpPAW")}
              </p>
            </div>
          </EventFlowerStandItemPawInfo>

          <div className="total">
            <p className="heading">{t("flowerstand.item.sum")}</p>
            <p className="num">
              {this.state.displayValue.toLocaleString()} PAW
            </p>
          </div>

          <EventFlowerStandButtonList onClick={() => this.toggleConfirm()}>
            <LinkButton>{t("flowerstand.item.purchaseConfirm")}</LinkButton>
          </EventFlowerStandButtonList>
        </div>
      </Container>
    );
  }

  addCustomName = () => {
    const { t } = this.props;
    return (
      <div className="inputFieldLayout">
        <div className="headingDesign">
          <h2>NAME</h2>
          <p>{t("flowerstand.item.name")}</p>
        </div>

        <div className="inputNameFormLayout">
          <p
            className="cautionText"
            onClick={() => this.showRegulation("flowerStandNameRegulation")}
          >
            {t("flowerstand.item.namePostingRules1")}
            <u>{t("flowerstand.item.namePostingRules2")}</u>
            {t("flowerstand.item.namePostingRules3")}
          </p>
          <input
            type="text"
            name="name"
            maxLength={10}
            defaultValue={this.state.giftName}
            onChange={this.inputGiftName}
          />
          <p className="letterLimit">{this.state.giftName.length}/10</p>
        </div>
      </div>
    );
  };

  addCustomImage = () => {
    const { t } = this.props;
    return (
      <div className="inputFieldLayout">
        <div className="headingDesign">
          <h2>PHOTO UPLOAD</h2>
          <p>{t("flowerstand.item.uploadImage")}</p>
        </div>

        <div className="uploadImageFormLayout">
          <div className="previewImage">
            <img src={this.state.previewImg} alt="" />
          </div>

          <div className="uploadCautionText">
            <p>
              {t("flowerstand.common.ImageDraft1")}
              <span
                onClick={() => this.showRegulation("flowerStandImgRegulation")}
              >
                {t("flowerstand.common.ImageDraft2")}
              </span>
              {t("flowerstand.common.ImageDraft3")}
            </p>
            <p className="caution">
              {t("flowerstand.item.imageRecommendation")}
            </p>
          </div>

          <label className="uploadImageButtonLayout">
            <input
              type="file"
              onChange={this.setCustomImagePath}
              id="customImg"
              name="customImg"
              accept=".jpg,.png,image/jpeg,image/png"
            />
            <p className="uploadImageButton">
              {t("flowerstand.item.originalButton")}
            </p>
          </label>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state: Store) => {
  const props: ValueProps = {
    pathname: state.router.location.pathname,
    // @ts-expect-error TS2322
    flowerStandMap: state.flowerStand.flowerStandList,
    pawBalance: state.purchase.emoBalance,
    user: state.auth.user,
    purchasedFlowerStandMap: state.flowerStand.purchasedFlowerStandMap,
    completePurchase: state.flowerStand.completePurchase,
    isAdmin: state.admin.isAdmin,
  };
  return props;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const func: FuncProps = {
    toggleNotice: (msgObj) => {
      dispatch(modalActions.toggleNotice(msgObj));
    },
    toggleError: (msgObj) => {
      dispatch(modalActions.toggleError(msgObj));
    },
    toggleActionModal: (payload) => {
      dispatch(modalActions.toggleActionModal(payload));
    },
    purchaseFlowerStand: (payload) => {
      dispatch(flowerStandActions.purchaseFlowerStand.started(payload));
    },
    toggleCompleteStatus: () => {
      dispatch(flowerStandActions.toggleCompleteStatus());
    },
    togglePawChargeModal: () => {
      dispatch(modalActions.togglePawChargeModal({}));
    },
  };
  return func;
};

const TransEventFlowerStandItem = withTranslation()(EventFlowerStandItemLogic);
export const EventFlowerStandItemConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(TransEventFlowerStandItem);
