/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { Timestamp } from "@google-cloud/firestore";

import {
  streamingState,
  GiftItemData,
  ActionTabType,
  CommentFilterType,
} from "modules/streaming";
import { modalActions } from "modules/modal";
import { ticketActions } from "modules/ticket";

import { EventInfo, convertMapToValues } from "utility";

import StreamingComment from "./StreamingComment";
import { StreamingEventDetail } from "./StreamingEventDetail";
import StreamingSendComment from "./StreamingSendComment";
import StreamingLiveCommerce from "./StreamingLiveCommerce";

import { SPView, PCView } from "components/common/MediaQuery";
import StreamingCommentTab from "components/streaming/StreamingCommentTab";
import StreamingSelectGift from "components/streaming/StreamingSelectGift";
import StreamingCommentFilter from "components/streaming/StreamingCommentFilter";
import { StreamingLiveEvent } from "./liveEvent/StreamingLiveEvent";
import type { THEOPlayer } from "@spwn/types/external";
import type { EventVideo } from "@spwn/types/firebase/firestore";
import { StreamingTimetable } from "./StreamingTimetable";
import { changeStyleWithHosting } from "../../utility/hosting";
import { StreamingTab } from "./StreamingTab";
import { StreamingEventDescription } from "./StreamingEventDescription";

type Props = {
  event: EventInfo;
  selectVideoId: string;
  player: THEOPlayer;
  eventStart: Timestamp;
  eventInfo: EventInfo;
  giftItemMap: streamingState["giftItemMap"];
  currentVideoId: string;
  THEOPlayerElementHeight: number;
  isHiddenStreamingComment: boolean;
  setWatchVideo: (vid: string) => void;
  isDarkModeEnabled: boolean;
  clickCount?: number;
  setClickCount?: (clickCount: number) => void;
};

/**
 * UserActionArea
 * comment, gift, (later: goods sales, quiz, vote)
 * @param props
 */
const StreamingActionArea: React.FC<Props> = (props) => {
  const [actionTabState, setActionTabState] = useState<ActionTabType>(null);
  const [commentFilterState, setCommentFilterState] =
    useState<CommentFilterType>(null);
  const [openSelectGift, setOpenSelectGift] = useState<boolean>(false);
  const [openPawCharge, setOpenPawCharge] = useState<boolean>(false);
  // @ts-expect-error TS2345
  const [selectedGift, setSelectedGift] = useState<GiftItemData>(null);

  const emoBalance = useSelector(
    (state: Store) => state.purchase.emoBalance.paidValue
  );
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );
  const isTheaterModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isTheaterModeEnabled
  );
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);
  const eventVideoMap = useSelector(
    (state: Store) => state.streaming.eventVideoMap
  );
  const streamingType = useSelector(
    (state: Store) => state.streaming.streamingType
  );

  const dispatch = useDispatch();
  const isLiveCommerceEnabled =
    props.eventInfo?.streamingInfo?.commentTab?.isLiveCommerceEnabled;

  useEffect(() => {
    if (isLiveCommerceEnabled) {
      dispatch(
        ticketActions.fetchEventGoods.started({
          eventId: props.eventInfo.eventId,
        })
      );
    }

    // if selected tab is closed, init tab state
    const currentVideo = eventVideoMap?.[props.currentVideoId];
    if (isClosedSelectedTab(props.eventInfo, actionTabState, currentVideo)) {
      setActionTabState(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eventInfo, eventVideoMap, props.currentVideoId]);

  const currentVideo = eventVideoMap?.[props.currentVideoId];
  const isTabRequired =
    // @ts-expect-error TS2345
    convertMapToValues(props.eventInfo?.streamingInfo?.commentTab).includes(
      true
    ) &&
    (currentVideo?.isLiveCommerceEnabled === undefined ||
      currentVideo.isLiveCommerceEnabled ||
      currentVideo.isLiveEventEnabled === undefined ||
      currentVideo.isLiveEventEnabled);

  const classesStreamingActionAreaDesign = streamingActionAreaDesign({
    isTheaterModeEnabled,
    isHiddenStreamingComment: props.isHiddenStreamingComment,
  });

  const functionsWhenSelectingGift = (selectedGift: GiftItemData) => {
    if (emoBalance < selectedGift.values.paid) {
      setOpenPawCharge(true);
      return;
    }
    setSelectedGift(selectedGift);
    setOpenSelectGift(false);
  };

  const closeSelectGift = () => {
    setOpenSelectGift(false);
    setOpenPawCharge(false);
  };

  const togglePawChargeModal = () => {
    setOpenPawCharge(false);
    dispatch(modalActions.togglePawChargeModal({}));
  };

  const isClosedTab =
    isClosedSelectedTab(props.eventInfo, actionTabState) && !isAdmin;

  return (
    <div css={classesStreamingActionAreaDesign.root}>
      {/* sp view */}

      <SPView>
        {/* Comment Area Tab */}
        <StreamingCommentTab
          isDarkModeEnabled={isDarkModeEnabled}
          isHiddenStreamingComment={props.isHiddenStreamingComment}
          // @ts-expect-error TS2322
          actionTabState={actionTabState}
          commentTab={props.eventInfo?.streamingInfo?.commentTab}
          currentVideo={currentVideo}
          setActionTabState={setActionTabState}
        />

        {/* Component in selected tab */}
        {(actionTabState === null || actionTabState === "eventDetail") && (
          <React.Fragment>
            <StreamingEventDetail
              selectVideoId={props.selectVideoId}
              event={props.eventInfo}
              currentVid={props.currentVideoId}
              isDarkModeEnabled={false}
              setWatchVideo={props.setWatchVideo}
            />

            <StreamingTimetable currentVideoId={props.currentVideoId} />

            {changeStyleWithHosting().pageSettings.streamingPage
              .isDisplaySuperChatArea ? (
              <div
                css={css`
                  width: 90%;
                  margin: 0 auto;
                `}
              >
                <StreamingTab
                  event={props.event}
                  currentVid={props.currentVideoId}
                />
              </div>
            ) : null}

            <div
              css={css`
                width: 90%;
                padding: 24px 0;
                margin: 0 auto;
              `}
            >
              <StreamingEventDescription
                event={props.event}
                isDarkModeEnabled={props.isDarkModeEnabled}
                clickCount={props.clickCount}
                setClickCount={props.setClickCount}
              />
            </div>
          </React.Fragment>
        )}

        {actionTabState === "comment" && !props.isHiddenStreamingComment && (
          <React.Fragment>
            {/* TODO: ログインしてなかったらコメント送信は無効 */}
            {streamingType === "Live" && (
              <StreamingSendComment
                hasTicket={true}
                setSelectedGift={setSelectedGift}
                setOpenSelectGift={setOpenSelectGift}
                selectedGift={selectedGift}
                eventInfo={props.eventInfo}
                videoId={props.currentVideoId}
                openSelectGift={openSelectGift}
              />
            )}

            {/* スマホの場合はポップアップ */}
            <StreamingSelectGift
              open={openSelectGift}
              openPawCharge={openPawCharge}
              emoBalance={emoBalance}
              isDarkModeEnabled={isDarkModeEnabled}
              giftItemMap={props.giftItemMap}
              functionsWhenSelectingGift={functionsWhenSelectingGift}
              closeSelectGift={closeSelectGift}
              closePawCharge={() => setOpenPawCharge(false)}
              togglePawChargeModal={togglePawChargeModal}
            />

            <div
              css={classesStreamingActionAreaDesign.StreamingCommentPosition}
            >
              {/* <StreamingCommentFilter
                    isDarkModeEnabled={isDarkModeEnabled}
                    commentFilterState={commentFilterState}
                    setCommentFilterState={setCommentFilterState}
                  /> */}

              <StreamingComment
                player={props.player}
                eventStart={props.eventStart}
                streamingType={streamingType}
                eventInfo={props.eventInfo}
                giftItemMap={props.giftItemMap}
                currentVideoId={props.currentVideoId}
                commentFilterState={commentFilterState}
              />
            </div>
          </React.Fragment>
        )}

        {!isClosedTab && actionTabState === "goods" && (
          <StreamingLiveCommerce currentVideoId={props.currentVideoId} />
        )}

        {!isClosedTab && actionTabState === "event" && (
          <StreamingLiveEvent
            isDarkModeEnabled={isDarkModeEnabled}
            eventInfo={props.eventInfo}
          />
        )}
      </SPView>

      {/* pc view */}

      <PCView>
        {isTabRequired && (
          <StreamingCommentTab
            isDarkModeEnabled={isDarkModeEnabled}
            isHiddenStreamingComment={props.isHiddenStreamingComment}
            // @ts-expect-error TS2322
            actionTabState={actionTabState}
            commentTab={props.eventInfo?.streamingInfo?.commentTab}
            currentVideo={currentVideo}
            setActionTabState={setActionTabState}
          />
        )}

        {(actionTabState === null || actionTabState === "comment") &&
          !props.isHiddenStreamingComment && (
            <React.Fragment>
              <div
                css={classesStreamingActionAreaDesign.StreamingCommentPosition}
              >
                <StreamingCommentFilter
                  isDarkModeEnabled={isDarkModeEnabled}
                  // @ts-expect-error TS2322
                  commentFilterState={commentFilterState}
                  setCommentFilterState={setCommentFilterState}
                />

                <StreamingComment
                  player={props.player}
                  eventStart={props.eventStart}
                  streamingType={streamingType}
                  eventInfo={props.eventInfo}
                  giftItemMap={props.giftItemMap}
                  currentVideoId={props.currentVideoId}
                  commentFilterState={commentFilterState}
                  THEOPlayerElementHeight={props.THEOPlayerElementHeight}
                />

                <StreamingSelectGift
                  open={openSelectGift}
                  openPawCharge={openPawCharge}
                  emoBalance={emoBalance}
                  isDarkModeEnabled={isDarkModeEnabled}
                  giftItemMap={props.giftItemMap}
                  functionsWhenSelectingGift={functionsWhenSelectingGift}
                  closeSelectGift={closeSelectGift}
                  closePawCharge={() => setOpenPawCharge(false)}
                  togglePawChargeModal={togglePawChargeModal}
                />
              </div>

              {/* TODO: ログインしてなかったらコメント送信は無効 */}
              {streamingType === "Live" && (
                <StreamingSendComment
                  hasTicket={true}
                  setSelectedGift={setSelectedGift}
                  setOpenSelectGift={setOpenSelectGift}
                  selectedGift={selectedGift}
                  eventInfo={props.eventInfo}
                  videoId={props.currentVideoId}
                  openSelectGift={openSelectGift}
                />
              )}
            </React.Fragment>
          )}

        {!isClosedTab && actionTabState === "goods" && (
          <StreamingLiveCommerce
            currentVideoId={props.currentVideoId}
            THEOPlayerElementHeight={props.THEOPlayerElementHeight}
          />
        )}

        {!isClosedTab && actionTabState === "event" && (
          <StreamingLiveEvent
            isDarkModeEnabled={isDarkModeEnabled}
            eventInfo={props.eventInfo}
            THEOPlayerElementHeight={props.THEOPlayerElementHeight}
          />
        )}
      </PCView>
    </div>
  );
};

interface StreamingActionAreaDesignProps {
  isTheaterModeEnabled: boolean;
  isHiddenStreamingComment: boolean;
}
const streamingActionAreaDesign = (props?: StreamingActionAreaDesignProps) => {
  const displayStreamingCommentLayout = css`
    @media screen and (min-width: 768px) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      padding-top: 0;
    }
  `;
  const hiddenStreamingCommentLayout = css`
    @media screen and (min-width: 768px) {
      display: none;
      padding-top: 0;
    }
  `;
  const TheaterModeStreamingCommentLayout = css`
    @media screen and (min-width: 768px) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
      padding-top: 0;
    }
  `;
  const StreamingCommentPosition = css`
    position: relative;
  `;

  // @ts-expect-error TS18048
  if (props.isHiddenStreamingComment) {
    return {
      root: hiddenStreamingCommentLayout,
      StreamingCommentPosition,
    };
    // @ts-expect-error TS18048
  } else if (props.isTheaterModeEnabled) {
    return {
      root: TheaterModeStreamingCommentLayout,
      StreamingCommentPosition,
    };
  } else {
    return {
      root: displayStreamingCommentLayout,
      StreamingCommentPosition,
    };
  }
};

export default StreamingActionArea;

/**
 * return if selected tab is closed
 * @param eventInfo
 * @param tabState
 */
const isClosedSelectedTab = (
  eventInfo: Props["eventInfo"],
  tabState: ActionTabType,
  // @ts-expect-error TS2322
  currentVideo: EventVideo = null
) => {
  const commentTab = eventInfo?.streamingInfo?.commentTab;
  // @ts-expect-error TS2345
  const isTabRequired = convertMapToValues(commentTab).includes(true);
  if (!isTabRequired) {
    return true;
  }
  if (
    // @ts-expect-error TS18048
    (tabState === "goods" && !commentTab.isLiveCommerceEnabled) ||
    // @ts-expect-error TS18048
    (tabState === "event" && !commentTab.isLiveEventEnabled)
  ) {
    return true;
  }
  if (currentVideo) {
    if (
      (tabState === "goods" && !currentVideo.isLiveCommerceEnabled) ||
      (tabState === "event" && !currentVideo.isLiveEventEnabled)
    ) {
      return true;
    }
  }
  return false;
};
