/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { CapsuleGoods } from "@spwn/types/firebase/firestore";
import { RoundLoader } from "../atoms/loader/RoundLoader";

const ANIMATION_TIME = 2.5;
const EFFECT_TIME = 2.6;

type CapsuleAnimation = {
  start: string;
  capsuleOpen: string;
  resultLoop: string;
};

type Props = {
  resultProduct?: CapsuleGoods;
  capsuleAnimationUrls: CapsuleAnimation;
  refreshImages: () => void;
  onClickClose: () => void;
};

export const CapsuleResultSingle: React.FC<Props> = (props) => {
  const [isFinishedAnimation, setIsFinishedAnimation] =
    useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFinishedAnimation(true);
    }, ANIMATION_TIME * 1000);
    return () => {
      // if error, this components is unmount. so clear setTimeout not to set state.
      clearTimeout(timer);
    };
  }, []);

  // 回す
  if (props.resultProduct === undefined || !isFinishedAnimation) {
    return (
      <CapsuleStart
        animation={props.capsuleAnimationUrls}
        isFinishedAnimation={isFinishedAnimation}
        isLoading={props.resultProduct === undefined}
      />
    );
  }

  // 開封+開封結果表示
  return (
    <CapsuleOpen
      animation={props.capsuleAnimationUrls}
      item={{
        name: props.resultProduct.subClassName,
        image: props.resultProduct.variantThumbnail ?? "",
      }}
      onClickClose={props.onClickClose}
    />
  );
};

// 回りはじめのアニメーション
// NOTE: 購入処理が遅れている場合はloadingを表示する
// Multipleの方と共通化してもいいかもしれない
export const CapsuleStart: React.FC<{
  animation: CapsuleAnimation;
  isLoading: boolean;
  isFinishedAnimation: boolean;
}> = ({ animation, isLoading, isFinishedAnimation }) => {
  const classes = styles();
  return (
    <div css={classes.root}>
      <div css={classes.layout}>
        <div css={classes.content}>
          <img
            key={"start"}
            src={animation.start}
            alt={""}
            className={"effect"}
          />
          {isFinishedAnimation && isLoading && (
            <div css={classes.loader}>
              <div className={"layout"}>
                <p className={"heading"}>ただいま決済中です。</p>
                <RoundLoader />
                <p className={"message"}>
                  決済が完了するまでしばらくお待ちください。
                  <br />
                  再読み込みや戻るボタンを押さないでください。
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * 開封アニメーション → 開封結果表示
 */
const CapsuleOpen: React.FC<{
  animation: CapsuleAnimation;
  item: {
    name: string;
    image: string;
  };
  onClickClose: () => void;
}> = ({ animation, item, onClickClose }) => {
  const classes = styles();

  const [isFinishedEffect, setIsFinishedEffect] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFinishedEffect(true);
    }, EFFECT_TIME * 1000);
    return () => {
      // if error, this components is unmount. so clear setTimeout not to set state.
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={classes.root}>
      <div css={classes.layout}>
        <div css={classes.content}>
          {isFinishedEffect ? (
            <img src={animation.resultLoop} alt={""} className={"effect"} />
          ) : (
            <img src={animation.capsuleOpen} alt={""} className={"effect"} />
          )}
          {/*
           // @ts-expect-error TS2322 */}
          <div css={classes.result} className={isFinishedEffect && "display"}>
            <p>{item.name}</p>
            <div className={"result_img"}>
              <img src={item.image} alt={item.name} />
            </div>
          </div>
          <div
            css={classes.actionArea}
            // @ts-expect-error TS2322
            className={isFinishedEffect && "display"}
          >
            <div css={classes.closeButton} onClick={onClickClose}>
              <CloseIcon />
              閉じる / もう一度回す
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      padding: 80px 0;
      background-color: rgba(0, 0, 0, 0.8);
      overflow-y: scroll;
      z-index: 1000;
    `,
    layout: css`
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 980px;
        transform: translate(-50%, -50%);
      }
    `,
    content: css`
      position: relative;
      width: 100%;
      margin-bottom: 24px;
      background-color: #fff;
      .effect {
        width: 100%;
      }
    `,
    actionArea: css`
      position: absolute;
      /* 左右中央寄せ */
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      /* 左右中央寄せここまで */
      /* デフォルト非表示 */
      opacity: 0;
      visibility: hidden;
      /* デフォルト非表示ここまで */
      &.display {
        opacity: 1;
        visibility: visible;
      }
    `,
    loader: css`
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.6);
      p {
        text-align: center;
      }
      .heading {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.8em;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
      .message {
        font-size: 12px;
        line-height: 1.8em;
      }
    `,
    result: css`
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      padding: 0 5%;
      transform: translate(-50%, calc(-50% - 8px));
      opacity: 0;
      visibility: hidden;
      @media screen and (min-width: 768px) {
        transform: translate(-50%, calc(-50% - 40px));
      }
      &.display {
        opacity: 1;
        visibility: visible;
      }
      p {
        margin-bottom: 8px;
        color: #282828;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.3em;
        text-align: center;
        @media screen and (min-width: 768px) {
          margin-bottom: 40px;
          font-size: 24px;
        }
      }
      .result_img {
        width: 130px;
        margin: 0 auto;
        border-radius: 50%;
        overflow: hidden;
        @media screen and (min-width: 768px) {
          width: 300px;
        }
      }
      img {
        width: 100%;
        height: auto;
        transform: scale(1.35);
      }
    `,
    closeButton: css`
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin: 24px auto 0;
      font-size: 16px;
      cursor: pointer;
      svg {
        margin-right: 8px;
      }
    `,
  };
};
