import { JSONContent, Extensions, generateHTML } from "@tiptap/core";

/* eslint-disable import/no-named-as-default */
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import History from "@tiptap/extension-history";
import Link from "@tiptap/extension-link";
import OrderedList from "@tiptap/extension-ordered-list";
import Placeholder from "@tiptap/extension-placeholder";
import { useCallback } from "react";
/* eslint-enable import/no-named-as-default */

type UseGenerateHTML = (props: { placeholder: string }) => {
  generateHTML: (jsonContent: JSONContent) => string;
};

export const useGenerateHTML: UseGenerateHTML = ({ placeholder }) => ({
  generateHTML: useCallback(
    (jsonContent) => {
      const extensions: Extensions = [
        Document,
        Paragraph,
        Text,
        Bold,
        BulletList,
        ListItem,
        History,
        Link,
        OrderedList,
        Placeholder.configure({
          placeholder,
        }),
      ];

      return generateHTML(jsonContent, extensions);
    },
    [placeholder]
  ),
});
