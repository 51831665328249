import { ticketState } from "modules/ticket";
import { isVODTicket, isLiveTicket, isArchiveTicket } from "./event";
import { isDisplayPeriod, createSet, convertMapToValues } from "utility";
import { Timestamp } from "@google-cloud/firestore";
import { CVS_EXPIRATION_DAYS } from "constants/purchase";
import {
  fetchFirestoreCollectionMap,
  fetchFirestoreDocument,
} from "utility/firebase";
import { isToukenranbuDomain, isVirtuaculDomain } from "./hosting";
import { withSessionStorage } from "./sessionStorage";
import type {
  ProductData,
  ReferrerWhitelist,
  Ticket,
  UserCartProduct,
} from "@spwn/types/firebase/firestore";

export const SHIPPING_PLACE_CODE = "000-OPENLOGI";

export const isShippingItem = (goods: ProductData) => {
  return goods.isAddressRequired || goods.isAddressRequiredNoFee; // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
};

type TicketQRCodeValue = {
  eventId: string;
  ticketNumber: string;
  authCode: string | null;
  ticketType: "isChecked" | "isRedeemed" | "isGoods";
  orderId?: number;
};

export const encodeTicketQRCodeValue = (sourceValue: TicketQRCodeValue) => {
  return btoa(JSON.stringify(sourceValue));
};

export const decodeTicketQRCodeValue = (encodedValue: string) => {
  if (encodedValue.includes("http://") || encodedValue.includes("https://")) {
    return null;
  }
  return JSON.parse(atob(encodedValue));
};

export const makeEncodedIssuedTicketQRCodeValue = (
  eventId: string,
  ticketNumber: string, // `tickets/${eventId}/issuedTickets/${ticketNumber}
  authCode: string | null,
  ticketType: Parameters<typeof encodeTicketQRCodeValue>[0]["ticketType"],
  orderId?: number
) => {
  return encodeTicketQRCodeValue({
    eventId,
    ticketNumber,
    authCode,
    ticketType,
    orderId,
  });
};

/**
 * check if event has vod tickets that is on sale
 * @param eventTicketMap
 * @param isAdmin
 */
export const filterDisplayVideoTickets = (
  eventTicketMap: ticketState["eventTicketMap"],
  isAdmin: boolean
) => {
  if (!eventTicketMap) {
    return [];
  }
  const videoTickets = Object.values(eventTicketMap).filter((ticket) => {
    if (isAdmin) {
      return isVODTicket(ticket);
    }
    if (isLiveTicket(ticket)) {
      // if 'Live' type, check expiredDateTime
      return isDisplayPeriod(
        ticket.display,
        ticket.releaseDateTime,
        ticket.expiredDateTime
      );
    } else if (isArchiveTicket(ticket)) {
      // if 'VOD' type, check vodActiveHours
      const vodExpiredDatetime = {
        ...ticket.closeDateTime,
        // closeDateTime + vodActiveHours + cvs expiration days (just to be sure, include cvs expiration buffer)
        seconds:
          ticket.closeDateTime.seconds +
          ticket.vodActiveHours * 60 * 60 +
          CVS_EXPIRATION_DAYS * 24 * 60 * 60,
      } as Timestamp;
      return isDisplayPeriod(
        ticket.display,
        ticket.releaseDateTime,
        vodExpiredDatetime
      );
    }
    return false;
  });
  // if admin, return all streaming ticket
  if (isAdmin) {
    return videoTickets.sort((a, b) => a.priority - b.priority);
  }
  // 異なるチケットで同一のビデオを参照している場合、VODチケットを優先して表示する
  const videoIdsList = createSet(videoTickets.map((ticket) => ticket.videoIds));
  const displayVideoTickets: Ticket[] = [];
  for (const videoIds of videoIdsList) {
    const vodTickets = videoTickets.filter(
      (ticket) => JSON.stringify(ticket.videoIds) === JSON.stringify(videoIds)
    );
    // if vod ticket is present, display it
    const archiveTickets = vodTickets.filter((ticket) =>
      isArchiveTicket(ticket)
    );
    if (archiveTickets.length > 0) {
      displayVideoTickets.push(...archiveTickets);
    } else {
      displayVideoTickets.push(
        ...vodTickets.filter((ticket) => isLiveTicket(ticket))
      );
    }
  }
  displayVideoTickets.sort((a, b) => a.priority - b.priority);
  return displayVideoTickets;
};

/**
 * コンビニ決済がなければconfirmTranを実行しない
 * TODO: もうちょっとチェックちゃんとする
 * @param uid
 */
export const hasUnprocessedOrder = async (uid: string) => {
  const ref = `settlement/${uid}/activeTransactions`;
  const settlementMap = await fetchFirestoreCollectionMap(ref);
  for (const settlment of convertMapToValues(settlementMap)) {
    // @ts-expect-error TS7053
    if (settlment?.["status"] === "UNPROCESS") {
      return true;
    }
  }
  return false;
};

/**
 * if free ticket is purchasable, return true
 * 整理券用に機能追加
 * TODO: 今後は無料チケットは全て購入必須にしてもいいかも
 * @param eventId
 */
export const IS_FREE_PURCHASE = () => {
  if (isToukenranbuDomain() || isVirtuaculDomain()) {
    return true;
  }
  return false;
};

/**
 * return displayPrice if price_jpy is zero and displayPrice is present, if not price_jpy
 * price_jpyが0出なくて、displayPriceがある場合は想定していないが安全装置
 * @param price_jpy
 * @param displayPrice
 */
export const getDisplayPrice = (
  product: Pick<ProductData, "price_jpy" | "displayPrice">
) => {
  return product.price_jpy === 0 && product.displayPrice
    ? product.displayPrice
    : product.price_jpy;
};

export const getDisplayPriceInfo = (product: ProductData | UserCartProduct) => {
  return product.price_jpy === 0 && product.displayPrice
    ? { price: product.displayPrice, isDisplayOnly: true }
    : { price: product.price_jpy, isDisplayOnly: false };
};

/**
 * return price of venue payment.
 * 会場で支払いが必要な金額を返す（SPWNで決済済みのものは0円にする）
 * price_jpy、displayPriceの両方が0より大きいのは設定エラー
 *
 * FIXME venueアプリでスクエア決済を行うスタッフのorderは、price_jpy・displayPriceともに有料になるため、エラーになる
 *
 * https://www.notion.so/balusco/FIXME-3fb49f4e585c4164a8d0fd5946c7a993
 * - 原因コード箇所 https://github.com/balus-co-ltd/spwn/blob/3851a4af04bbabebee0e9a223125afe096f891f2/packages/functions/src/models/venue.ts#L574
 *   - ここで price_jpy を有料にしている。おそらく、Redash上で売上を反映させるためにこの変換を行なっている。
 * - 現状スタッフアカウントのみで発生するため、クリティカルではない
 *   - venueアプリはスタッフアカウントでログインして利用し、オーダーシートの決済はスタッフのアカウントとして決済されるようになっているため
 *     - 時間リソースの都合上そうした気がする
 *   - スタッフアカウント https://www.notion.so/balusco/venue-07bc7b586c2f4f369c387755408bb23d
 */
export const getVenuePayPrice = (product: {
  price_jpy: number;
  displayPrice?: number;
}) => {
  if (product.price_jpy === 0 && product.displayPrice) {
    return product.displayPrice;
  }
  if (
    product.price_jpy >= 0 &&
    (product.displayPrice === 0 || product.displayPrice === undefined)
  ) {
    return 0;
  }
  throw new Error(
    `Unexpected price setting: price_jpy:${product.price_jpy} displayPrice:${product.displayPrice}`
  );
};

/**
 * リファラ認証用の関数で、以下の 3 パターンで true を返す
 * DB に ticketId に対応するレコードがない
 * DB の allowedURLs が空配列
 * allowedURLs にリファラが含まれている
 * @param ticketId
 * @param referrer
 * @returns
 */
export const isAllowedReferrer = async (ticketId: string, referrer: string) => {
  return withSessionStorage<boolean>(async () => {
    const allowedReferrers = await fetchFirestoreDocument<ReferrerWhitelist>(
      `/referrerWhitelist/${ticketId}`
    );
    if (!allowedReferrers) {
      return true;
    }
    const urls = allowedReferrers.allowedURLs;
    return !urls || urls.length === 0 || urls.includes(referrer);
  }, `${ticketId}/ticketPurchaseReferrers`);
};
