/** @jsxRuntime classic /
 /** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { ComponentProps, FC, useEffect } from "react";
import { LoaderLayout } from "../../components/atoms/loader/LoaderLayout";
import Streaming from "containers/streaming/Streaming";
import { NotFoundError } from "features/NotFoundError";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "store";
import { useQuery } from "../../utility/useQuery";
import { createUrl } from "utility/createUrl";
import { StreamingStandby } from "./StreamingStandby";
import { useGetRestrictedStreamingKey } from "./useGetRestrictedStreamingKey";
import { useParams } from "react-router";
import { useGetEvent } from "hooks/event/useGetEvent";
import { getEventDisplayInfo } from "utility";
import { useGetStreamingVideo } from "./useGetStreamingVideo";
import { useGetPlayerSetting } from "./useGetPlayerSetting";
import { streamingActions } from "modules/streaming";
import { eventActions } from "modules/event";

type StreamingLogicProps = {};

/**
 * 関係者限定の配信ページ
 *
 * URL 構造: /streams/p/{videoId}?event={eventId}&code={code}
 *
 * @see https://www.notion.so/balusco/cf127373c94744c495144820c6f40ec8
 */
export const RestrictedStreaming: FC<StreamingLogicProps> = () => {
  // URLから必要なパラメータを取得
  const { videoId } = useParams<{ videoId: string }>();
  const eventId = useQuery().get("event") as string;
  const code = useQuery().get("code") as string;

  // Redux Store からダークモードの設定を取得
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );

  // イベント情報を取得
  const { data: event } = useGetEvent({
    eventId,
  });
  const displayEvent = event ? getEventDisplayInfo(event) : null;

  // 配信情報を取得
  const { data: streamingVideo } = useGetStreamingVideo({
    eventId,
    videoId,
  });
  const eventVideoMap = streamingVideo ? { [videoId]: streamingVideo } : null;

  const { data: playerSetting } = useGetPlayerSetting();
  const vodSetting = playerSetting?.vodSetting;
  const liveSetting = playerSetting?.liveSetting;

  const { data, error } = useGetRestrictedStreamingKey({
    eventId,
    videoId,
    code,
  });

  /**
   * 配信を閲覧する権限がない時、エラーが返ってくるので、ここの条件分岐が表示される。
   */
  if (error || data?.ok === false) {
    return (
      <NotFoundError
        urlToTopPage={createUrl({
          location: window.location.origin,
          searchParams: [],
        })}
      />
    );
  }

  if (
    data === undefined ||
    !displayEvent ||
    !eventVideoMap ||
    !vodSetting ||
    !liveSetting
  ) {
    return (
      <div id="contents-layout">
        <LoaderLayout />
      </div>
    );
  }

  if (
    ["beforeLive", "beforeVOD", "ended"].includes(data.value.streamingStatus) &&
    streamingVideo
  ) {
    return (
      <StreamingStandby
        videoId={videoId}
        streamingStatus={data.value.streamingStatus}
        displayEvent={displayEvent}
        currentVideo={streamingVideo}
        isDarkModeEnabled={isDarkModeEnabled}
      />
    );
  }

  return (
    <main>
      <div
        css={css`
          margin: 0 auto;
          background-color: ${isDarkModeEnabled ? "#202020" : "#fff"};
          @media screen and (min-width: 768px) {
            width: 100%;
            max-width: 1800px;
            min-height: calc(100vh - 66px - 190px);
          }
        `}
      >
        <StreamingContext
          url={""}
          cookies={{
            [videoId]: data.value.streamingSource,
          }}
          cookiesList={undefined}
          event={displayEvent}
          hasTicket={true}
          vid={videoId}
          eventVideoMap={eventVideoMap}
          vodSetting={vodSetting}
          liveSetting={liveSetting}
        />
      </div>
    </main>
  );
};

/**
 * Streaming 以下のコンポーネントで、 storeに依存しているコンポーネント用に、取得した値をstoreに保存する
 * Streaming コンポーネントを利用するには、以下がstoreに保存されている必要がある
 * - eventVideoMap
 * - displayEvent
 * コメントシャーディングの判定などで利用されている
 *
 * FIXME: ログインしていない場合にコメントを非表示にしているのは、ログインを指定していない状態で
 *  コメント取得などの redux-saga にある処理経由でredux storeに値を保存しようとしてもできないため。原因不明。
 * @see https://www.notion.so/balusco/cf127373c94744c495144820c6f40ec8?pvs=4#38f7b6c4b11a453293efe7c6316eeea8
 */
const StreamingContext: FC<ComponentProps<typeof Streaming>> = ({
  children: _,
  ...props
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: Store) => state.auth.user);
  useEffect(() => {
    dispatch(
      streamingActions.setStateByKey({
        eventVideoMap: props.eventVideoMap,
      })
    );
    dispatch(
      eventActions.setStateByKey({
        displayEvent: props.event,
      })
    );
    // コメント用のアイコンを作成
    dispatch(streamingActions.createMySmallIconUrl.started());
  }, [dispatch, props.event, props.eventVideoMap]);

  // ログインしていない場合、コメントを非表示にする
  const isHiddenStreamingComment =
    user.uid === undefined ? true : props.event.isHiddenStreamingComment;

  return (
    <Streaming
      {...{
        ...props,
        event: {
          ...props.event,
          isHiddenStreamingComment,
        },
      }}
    />
  );
};
