/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useI18n } from "hooks/i18n/i18n";
import { getDateTimeString } from "utility";
import { isLiveTicket } from "utility/event";
import type { ProductData } from "@spwn/types/firebase/firestore";

interface Props {
  ticket: ProductData;
  isHiddenVODExpiredAt: boolean;
}
export const LiveListItemCaution: React.FC<Props> = (props) => {
  /**
   * 配信のステータスによって表示を変更する。
   * 見逃し配信、アーカイブ配信がない場合「※リアルタイム配信のみ」を表示する
   * 見逃し配信の場合は、視聴期限を表示する
   * アーカイブ配信の場合、「ライブ配信時より2021年01月11日23:59までご視聴いただけます。」を表示する
   */
  const { t } = useI18n();
  const classes = styles();

  if (props.isHiddenVODExpiredAt) return null;
  // TODO 2020/12/27 イベント終了後削除
  if (props.ticket._id === "Ti20122720-musyoP1100c7_0201") return null;

  if (isLiveTicket(props.ticket)) {
    const vodExpiredAt = getDateTimeString(
      new Date(props.ticket.expiredDateTime.seconds * 1000)
    );
    return (
      <p css={classes.root}>
        {t("event.archiveVideo.liveExpiredAtText", {
          vodExpiredAt,
        })}
      </p>
    );
  } else {
    // @ts-expect-error TS18048
    const vodActiveDays = Number.isInteger(props.ticket.vodActiveHours / 24)
      ? // @ts-expect-error TS18048
        props.ticket.vodActiveHours / 24
      : // @ts-expect-error TS18048
        (props.ticket.vodActiveHours / 24).toFixed(1);

    return (
      <p css={classes.root}>
        {t("event.archiveVideo.vodExpiredAtText", {
          vodActiveDays,
          vodActiveHours: props.ticket.vodActiveHours,
        })}
      </p>
    );
  }
};

const styles = () => {
  return {
    root: css`
      margin-bottom: 12px;
      color: #ff0000;
      font-size: 12px;
      line-height: 1.3em;
      letter-spacing: 1px;
    `,
  };
};
