import Box from "@material-ui/core/Box";
import React, { FC } from "react";
import { TicketDigitalType } from "./AccountTicketDigital";

export const AccountTicketDigitalInfo: FC<{
  product: TicketDigitalType;
}> = ({ product }) => {
  return (
    <Box>
      <Box
        component={"p"}
        marginBottom={"8px"}
        fontSize={"14px"}
        fontWeight={"700"}
      >
        {product.place?.areaName} {product.place?.venueName}
      </Box>

      <Box
        component={"p"}
        marginBottom={["8px", "16px"]}
        fontSize={["20px", "24px"]}
        fontWeight={"700"}
        lineHeight={"1.3em"}
      >
        {product.name}
      </Box>

      {product.price_jpy > 0 && (
        <Box component={"p"} fontSize={["18px", "24px"]} fontWeight={"700"}>
          ¥{product.price_jpy.toLocaleString()}
        </Box>
      )}
    </Box>
  );
};
