/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Link } from "react-router-dom";
import { SelectedEvent } from "components/voucher/VoucherEvent";

interface Props {
  selectedEvent: SelectedEvent[];
}
const VoucherComplete: React.FC<Props> = (props) => {
  const classesVoucherCompletePageStyles = voucherCompletePageStyles();

  return (
    <React.Fragment>
      <h2 css={classesVoucherCompletePageStyles.headline}>
        チケットが追加されました
      </h2>

      <section css={classesVoucherCompletePageStyles.root}>
        {props.selectedEvent.map((ticket, index) => {
          return (
            <div css={classesVoucherCompletePageStyles.item} key={index}>
              <div css={classesVoucherCompletePageStyles.event}>
                <h3>イベント</h3>
                <p>{ticket.eventName}</p>
              </div>
              <div css={classesVoucherCompletePageStyles.ticket}>
                <h3>チケット</h3>
                <ul>
                  {ticket.tickets.map((item, index) => {
                    return (
                      <li key={index}>
                        {item.name} {item.num}枚
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </section>

      <div css={classesVoucherCompletePageStyles.button}>
        <Link to="/account/ticket">マイチケット</Link>
      </div>
    </React.Fragment>
  );
};

const voucherCompletePageStyles = () => {
  return {
    headline: css`
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    `,
    caution: css`
      margin-top: 24px;
      margin-bottom: 24px;
      p {
        font-size: 14px;
        text-align: center;
        line-height: 1.5em;
      }
    `,
    root: css`
      width: 570px;
      max-width: 100%;
      margin: 65px auto 0;
    `,
    item: css`
      padding: 16px;
      border-radius: 5px;
      border: 1px solid #dedede;
      &:not(:last-child) {
        margin-bottom: 32px;
      }
      h3 {
        margin-bottom: 8px;
        color: #7b7b7b;
        font-size: 15px;
        font-weight: bold;
      }
      p,
      li {
        font-size: 15px;
        line-height: 1.3em;
      }
    `,
    event: css`
      margin-bottom: 24px;
    `,
    ticket: css``,
    button: css`
      width: 100%;
      max-width: 480px;
      margin: 80px auto 0;
      a,
      p {
        display: block;
        padding: 12px 10px;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        background-color: #25c2ae;
        cursor: pointer;
      }
    `,
  };
};
export default VoucherComplete;
