import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import { authActions } from "../../modules/auth";
import FacebookLoginButton from "../../components/FacebookLoginButton";
import TwitterLoginButton from "../../components/TwitterLoginButton";
import GoogleLoginButton from "../../components/GoogleLoginButton";
import DocomoLoginButton from "../../components/DocomoLoginButton";
import { parseQuery } from "../../utility";
import {
  AuthContent,
  AuthProvider,
  LinkButton,
  LiveStreamHeader,
  ContentsLayout,
} from "../../styles";

interface QueryFromArApp {
  provider: "google" | "twitter" | "facebook" | "docomo";
}

const Container = AuthContent;

const ViewerAppLogin: React.FC = () => {
  const dispatch = useDispatch();
  const isSignup = useSelector((state: Store) => state.modal.isSignup);
  const dynamicLink = useSelector(
    (state: Store) => state.auth.viewerAppDynamicLink
  );
  const location = useSelector((state: Store) => state.router.location);

  // @ts-expect-error TS2322
  const queryObj: Partial<QueryFromArApp> = parseQuery(location.search);

  // delay update window location to prevent popup block
  if (dynamicLink) {
    return (
      <Container id="contents">
        <ContentsLayout>
          <LiveStreamHeader>SPWN</LiveStreamHeader>
          <LinkButton
            className="link_button"
            onClick={() => window.open(dynamicLink, "_blank")}
          >
            アプリへ移動
          </LinkButton>
        </ContentsLayout>
      </Container>
    );
  }

  // @ts-expect-error TS7006
  const login = (provider) => {
    dispatch(authActions.login.started(provider));
  };

  const loginWithDocomo = () => {
    dispatch(authActions.loginWithDocomo.started({ appName: "viewerApp" }));
  };

  /**
   * select provider button by query string
   * if no query string, return facebook
   */
  const providerSelector = () => {
    if (!queryObj) {
      return <FacebookLoginButton onClick={login} isSignup={isSignup} />;
    }
    switch (queryObj.provider) {
      case "google":
        return <GoogleLoginButton onClick={login} isSignup={isSignup} />;
      case "twitter":
        return <TwitterLoginButton onClick={login} isSignup={isSignup} />;
      case "facebook":
        return <FacebookLoginButton onClick={login} isSignup={isSignup} />;
      case "docomo":
        return (
          <DocomoLoginButton onClick={loginWithDocomo} isSignup={isSignup} />
        );
      default:
        return <FacebookLoginButton onClick={login} isSignup={isSignup} />;
    }
  };

  return (
    <Container id="contents">
      <ContentsLayout>
        <LiveStreamHeader>{isSignup ? "SIGNUP" : "LOGIN"}</LiveStreamHeader>
        {/* {isSignup && <p className="signup_message">登録をすると<span onClick={()=>{clickMenuItem("/terms-of-service")}}>会員規約</span>に同意したものと見なされます</p>} */}
        <AuthProvider>
          <ul className="button">{providerSelector()}</ul>
        </AuthProvider>
      </ContentsLayout>
    </Container>
  );
};

export default ViewerAppLogin;
