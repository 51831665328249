/** @jsxRuntime classic /
/** @jsx jsx */
/** @jsxFrag Fragment */
import React, { FC, useEffect, Fragment } from "react";
import { css, jsx } from "@emotion/core";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Event } from "@spwn/types/firebase/firestore";

import { useI18n } from "hooks/i18n/i18n";
import { Store } from "store";
import { liveActions } from "modules/live";

import { getEventDisplayInfo } from "utility";
import { getBelongedHosting } from "utility/storage";
import { getLiveContextWord } from "utility/live";
import { changeStyleWithHosting } from "utility/hosting";

export type BrandTopLiveStreamProps = {
  event: Event;
  isAdmin: boolean;
  isLiveEventMaintenance: boolean;
};

export const BrandTopLiveStream: FC<BrandTopLiveStreamProps> = ({
  event,
  isAdmin,
  isLiveEventMaintenance,
}) => {
  const { t } = useI18n();

  const dispatch = useDispatch();
  const contextId = useSelector((state: Store) => state.live.contextId);

  useEffect(() => {
    // ライブイベントがあるか eventContextsにドキュメントがあるかで判定(なければ初期値のnull)
    dispatch(liveActions.watchEventContext.started({ eventId: event._id }));
  }, [dispatch, event._id]);

  const isLive = typeof event !== "undefined";
  if (!isLive || event.isFanPage) {
    return <></>;
  }

  const eventInfo = getEventDisplayInfo(event);
  // if we can watch live streaming in spwn.jp
  const isSpwnLive = eventInfo.isOpenStreamingPage;

  const classes = styles();

  return (
    <div css={classes.root}>
      <div css={classes.todaysEventLayout}>
        {isSpwnLive || event.liveUrl ? (
          <h2 css={classes.heading}>LIVE STREAM</h2>
        ) : (
          <h2 css={classes.heading}>Today&apos;s Event</h2>
        )}
        <div className="flexLayout" css={classes.todaysEvent}>
          <div css={classes.todaysEventLeft}>
            <img src={eventInfo.thumbnail} alt={eventInfo.eventTitle} />
          </div>
          <div css={classes.todaysEventRight}>
            <h3>{eventInfo.eventTitle}</h3>
            <p>{eventInfo.artists}</p>
            <div css={classes.linkButton}>
              {isSpwnLive ? (
                <Link
                  to={`/events/${event._id}`}
                  className="buttonForLink buttonForYoutube"
                >
                  <span>{t("event.top.chooseStreaming")}</span>
                </Link>
              ) : !event.liveUrl ? (
                <></>
              ) : (
                <a
                  href={event.liveUrl}
                  target="_blank"
                  className="buttonForLink buttonForYoutube"
                  rel="noreferrer"
                >
                  <span>{t("event.top.streamingNow")}</span>
                </a>
              )}
              {event.isNoEventPage ? (
                <></>
              ) : (
                <Link to={"/events/" + event._id} className="buttonForLink">
                  <span>{t("event.top.detailEvent")}</span>
                </Link>
              )}
              {/* FIXME */}
              {/* eslint-disable-next-line no-constant-condition */}
              {((contextId !== null && !isLiveEventMaintenance) || isAdmin) &&
              getBelongedHosting() !== "platto1" &&
              false ? ( // TODO チケットを購入してないと投票できないようにする
                <Link
                  to={`/live-event?eid=${event._id}`}
                  className="buttonForLink buttonForQuiz"
                >
                  <span>
                    {getLiveContextWord(contextId)}
                    {t("event.top.entryEvent")}
                  </span>
                </Link>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// TODO@Kawano Today's EventのボタンのCSSを移動する。
// Today's Event Style
const styles = () => {
  const hostingBaseStyleMap = changeStyleWithHosting().baseStyles;
  return {
    root: css`
      padding: 40px 0;
      margin-bottom: 80px;
      background-color: rgba(0, 0, 0, 0.6);
    `,
    todaysEventLayout: css`
      max-width: 980px;
      width: 90%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 100%;
      }
    `,
    heading: css`
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      color: ${hostingBaseStyleMap.accentColor};
      font-size: 24px;
      letter-spacing: 1px;
      white-space: nowrap;
      @media screen and (min-width: 768px) {
        margin-bottom: 20px;
        font-size: 40px;
      }
      &::before,
      &::after {
        content: "";
        width: 30%;
        background: linear-gradient(
          transparent 40%,
          currentColor 40%,
          currentColor calc(40% + 2px),
          transparent calc(40% + 2px)
        );
        background-clip: padding;
        @media screen and (min-width: 768px) {
          width: 40%;
        }
      }
      &::before {
        margin-right: 4%;
      }
      &::after {
        margin-left: 4%;
      }
    `,
    todaysEvent: css`
      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
      }
    `,
    todaysEventLeft: css`
      margin-bottom: 10px;
      @media screen and (min-width: 768px) {
        width: 48%;
        margin-bottom: 0;
      }
      img {
        width: 100%;
      }
    `,
    todaysEventRight: css`
      @media screen and (min-width: 768px) {
        width: 48%;
      }
      h3 {
        margin-bottom: 10px;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 1.4em;
        white-space: pre-line;
        @media screen and (min-width: 768px) {
          font-size: 24px;
        }
      }
      p {
        margin-bottom: 30px;
        color: #d2d2d2;
        font-size: 16px;
        line-height: 1.3em;
        letter-spacing: 1px;
        @media screen and (min-width: 768px) {
          margin-bottom: 40px;
          font-size: 18px;
        }
      }
    `,
    linkButton: css`
      a {
        display: block;
        padding: 9px 0;
        background-color: ${hostingBaseStyleMap.accentColor};
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: bold;
        &::before {
          content: "";
          display: block;
          width: 30px;
          height: 30px;
          margin-right: 10px;
          /* !!this file might be output of webpack sass-loader!! */
          background-image: url("/static/media/icon_details.40b1ead3.svg");
          background-repeat: no-repeat;
          background-size: 100% auto;
          background-position: center center;
        }
      }
    `,
  };
};
//NoEvents Style
// const noEventListDesign = () => {
//   return {
//     noEvent: css`
//       color: #888888;
//       font-size: 20px;
//       text-align: center;
//       margin-top: 100px;
//       height: calc(100vh - (255px + 194px));
//       @media screen and (min-width: 768px) {
//         font-size: 24px;
//       }
//     `,
//   };
// };
