import { useCallback, useReducer } from "react";
import { Goods } from "./goodsModel";

export type CartEntry = {
  quantity: number;
  tenantId: string;
  baseProductId: string;
  inventoryProductType: "goods" | "goodsVariant";
  inventoryProductId: string;
  name: string;
  status: "soldOut" | "beforeSale" | "onSale" | "afterSale";
  purchaseLimit: number;
  isUnlimitedPurchase: boolean;
};

export type Increment = (itemId: string) => () => void;

export type Decrement = (itemId: string) => () => void;

const cartActions = {
  increment: (itemId: string) => ({
    type: "increment" as const,
    value: itemId,
  }),
  decrement: (itemId: string) => ({
    type: "decrement" as const,
    value: itemId,
  }),
};
type CartAction = ReturnType<typeof cartActions[keyof typeof cartActions]>;

type CartReducer = (state: CartEntry[], action: CartAction) => CartEntry[];

export const useGoodsCartEntry = (
  goods: Goods
): {
  cartEntries: CartEntry[];
  mutator: {
    increment: Increment;
    decrement: Decrement;
  };
} => {
  const cartReducer: CartReducer = (state, action) => {
    switch (action.type) {
      case "increment":
        return state.map((el) => {
          // クリック対象以外のものはそのまま返す
          if (action.value !== el.inventoryProductId) return el;
          // 購入制限以上には増やせない
          // WANT FIX
          // - 既存グッズカートに合わせているがそもそも推せない見た目にするのがいい気がする
          // - 自身の購入数まではここで考慮できていないが、カート追加APIで弾ける
          if (!el.isUnlimitedPurchase && el.purchaseLimit <= el.quantity)
            return el;

          return { ...el, quantity: el.quantity + 1 };
        });
      case "decrement":
        // CartEntriesの再生成
        return state.map((el) => {
          // クリック対象以外のものはそのまま返す
          if (action.value !== el.inventoryProductId) return el;
          if (el.quantity === 0) return el;
          return { ...el, quantity: el.quantity - 1 };
        });
      default:
        return state;
    }
  };
  const initialState: CartEntry[] = goodsToCartEntries(goods);
  const [currentState, dispatch] = useReducer(cartReducer, initialState);

  const increment = useCallback<Increment>(
    (itemId) => () => {
      dispatch(cartActions.increment(itemId));
    },
    []
  );

  const decrement = useCallback<Decrement>(
    (itemId) => () => {
      dispatch(cartActions.decrement(itemId));
    },
    []
  );

  return {
    cartEntries: currentState,
    mutator: {
      increment,
      decrement,
    },
  };
};

const goodsToCartEntries = (goods: Goods): CartEntry[] => {
  const { tenantId } = goods;
  if (goods.variants.length === 0) {
    return [
      {
        quantity: 0,
        tenantId,
        name: "",
        baseProductId: goods.id,
        inventoryProductType: "goods",
        inventoryProductId: goods.id,
        status: goods.salesStatus,
        // @ts-expect-error TS2322
        purchaseLimit: goods.purchaseLimit,
        // @ts-expect-error TS2322
        isUnlimitedPurchase: goods.isUnlimitedPurchase,
      },
    ];
  }
  // @ts-expect-error TS2322
  return goods.variants.map((el) => ({
    quantity: 0,
    tenantId,
    name: el.name,
    baseProductId: goods.id,
    inventoryProductType: "goodsVariant",
    inventoryProductId: el.id,
    status: goods.salesStatus,
    purchaseLimit: el.purchaseLimit,
    isUnlimitedPurchase: el.isUnlimitedPurchase,
  }));
};
