/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { SubContents } from "components/templates/SubContents";
import { TermSidebar } from "features/terms/TermSidebar";

export const PrivacyPolicy: React.FC = () => {
  const classes = styles();

  return (
    <SubContents>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, "プライバシーポリシー"],
        ]}
      />

      <div css={classes.root}>
        {/* サイドバーを表示する */}
        <TermSidebar />

        {/* コンテンツを表示する */}
        <div css={classes.main}>
          <iframe
            title="プライバシーポリシー"
            src="https://docs.google.com/document/d/e/2PACX-1vTk1I036nY5VhVj5Ik18LMFZ9BGF-VC1ToB2o-ZBVpTy-UyK9k3Rv9VNIvRRFC78HPIKqp_RM0B1ZiW/pub?embedded=true"
          ></iframe>
        </div>
      </div>
    </SubContents>
  );
};

const styles = () => {
  return {
    root: css`
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 16px;
      width: 90%;
      max-width: 1140px;
      padding: 80px 0;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        grid-template-columns: 320px auto;
      }
    `,
    main: css`
      position: relative;
      width: 100%;
      iframe {
        width: 100%;
        height: 4000px;
      }
    `,
  };
};
