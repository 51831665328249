import styled from "@emotion/styled";
import { PawInfo } from "../PawInfo";

export const AccountPawInfo = styled(PawInfo)`
  @media screen and (min-width: 768px) {
    padding: 10px 245px;
  }
  .owned_paw {
    width: 54%;
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
    }
    .heading {
      font-size: 13px;
      margin-bottom: 5px;
      @media screen and (min-width: 768px) {
        margin-right: 20px;
        margin-bottom: 0px;
        font-size: 20px;
      }
    }
  }
`;

AccountPawInfo.displayName = "AccountPawInfo";
