/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { FC } from "react";

/**
 * ヘッダーなしレイアウトコンポーネント
 *
 * Layoutの責務
 * - コンポーネントを受け取って、配置する
 * - 受け取るコンポーネントがどのようなコンポーネントであるかは気にしない
 *   - 一部ページで別のヘッダーを使用したいとき、既存のページに変更を加えることなく対応可能
 *
 * css参考
 * @see https://zenn.dev/catnose99/articles/a873bbbe25b15b
 */
export const LayoutNoHeader: FC<{
  footer: JSX.Element;
  children: JSX.Element;
}> = ({ children, footer }) => {
  return (
    <div
      css={css`
        display: grid;
        grid-template-rows: 1fr auto;
        grid-template-columns: 100%;
        min-height: 100vh;
      `}
    >
      <main>{children}</main>
      <footer>{footer}</footer>
    </div>
  );
};
