/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import appConfig from "constants/appConfig";
import { authActions, AddressInfo } from "../../modules/auth";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import { BreadcrumbArea } from "components/common/Link";
import { useI18n } from "hooks/i18n/i18n";
import { ContentsLayout } from "../../styles";
import { useForm } from "react-hook-form";
import { unique } from "utility/array";
import { AccountAddressFormItemLayout } from "./AccountAddressFormItemLayout";
import { AccountAddressZipCode } from "./AccountAddressZipCode";
import { AccountAddressPrefecture } from "./AccountAddressPrefecture";
import { AccountAddressCity } from "./AccountAddressCity";
import { AccountAddressStreetNum } from "./AccountAddressStreetNum";
import { AccountAddressBuildingInfo } from "./AccountAddressBuildingInfo";
import { AccountAddressUserName } from "./AccountAddressUserName";
import { AccountAddressPhone } from "./AccountAddressPhone";
import { AccountAddressPassword } from "./AccountAddressPassword";
import { isReAuthRequiredToEditAddress } from "utility/auth";
import { convertFullWidthToHalfWidth } from "utility/convertFullWidthToHalfWidth";

interface Props {
  user: firebase.User;
  addressInfo: AddressInfo;
  password: string;
  isEmailProvider: boolean;
  isReAuthRequired: boolean;
  isAddressRegistered: boolean;
  successReAuthForEditUserInfo: boolean;
  setAddressInfo: React.Dispatch<React.SetStateAction<AddressInfo>>;
  // @ts-expect-error TS7006
  onChangePassword: (event) => void;
}

type ResAddressData = {
  prefecture: string;
  city: string;
  town: string;
};

export type FormType = {
  city: string;
  phone: string;
  prefecture: string;
  streetNum: string;
  buildingInfo: string;
  userName: string;
  zipCode: string;
  password: string;
};

export const AccountAddress: React.FC<Props> = (props) => {
  const { t } = useI18n();
  const classes = styles();
  const dispatch = useDispatch();

  const [isWaitingSave, setIsWaitingSave] = useState<boolean>(false);
  // @ts-expect-error TS2345
  const [resAddressData, setResAddressData] = useState<ResAddressData[]>(null);
  const [isWarning, setIsWarning] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormType>({
    /**
     * Validation mode @see https://react-hook-form.com/api/useform
     */
    mode: "onChange",
    defaultValues: {
      city: props.addressInfo.city,
      phone: props.addressInfo.phone,
      prefecture: props.addressInfo.prefecture,
      streetNum: props.addressInfo.streetNum,
      buildingInfo: convertFullWidthToHalfWidth(props.addressInfo.buildingInfo),
      userName: props.addressInfo.userName,
      zipCode: props.addressInfo.zipCode,
      password: props.password,
    },
  });
  const inputValues = watch();

  /**
   * すでに住所が登録されている時、登録されている郵便番号から住所を取得し、Stateに保存する。
   */
  useEffect(() => {
    (async () => {
      const response = await fetchAddressFromZipCode(inputValues.zipCode);
      if (response.error) return;
      setResAddressData(response.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // save address if re-auth is success
  useEffect(() => {
    if (isWaitingSave && props.successReAuthForEditUserInfo === true) {
      dispatch(
        authActions.saveAddress.started({
          ...inputValues,
          password: inputValues.password,
        })
      );
      dispatch(authActions.successReAuthForEditUserInfo(false));
      setIsWaitingSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.successReAuthForEditUserInfo, isWaitingSave]);

  const fetchAddressFromZipCode = useCallback(async (zipCode: string) => {
    const response = await fetch(
      `${appConfig.PostcodeJP.revealJpAddress}?&postcode=${zipCode}&fields=pref,city,town`,
      {
        method: "GET",
        // @ts-expect-error TS2769
        headers: {
          "Content-Type": "application/json",
          apikey: appConfig.PostcodeJP.apiKey,
        },
      }
    );

    if (response.status !== 200) {
      /**
       * FIXME: postcode-jpのエラーなのでsentryに送るなどしたい。
       * @see https://api-doc.postcode-jp.com/v3#c9ce1c037b エラーレスポンス
       */
      const errorMsg = await response.text();
      console.error(errorMsg);
      return {
        error: {
          message: t("accountAddress.error.unexpectedError"),
        },
      };
    }

    const json: {
      data: {
        postcode: string;
        pref: string;
        city: string;
        town: string;
      }[];
    } = await response.json();

    if (!json?.data || json.data.length === 0) {
      return {
        error: {
          message: t("accountAddress.error.noneExistZipCode"),
        },
      };
    }

    return {
      data: json.data.map((el) => ({
        prefecture: el.pref,
        city: el.city,
        town: el.town,
      })),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeZipCode = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    async (e) => {
      /**
       * フォームに入力されているハイフンを削除する
       */
      const zipCode = e.target.value.replace(/-/g, "");

      /**
       * React Hook FormのValueにzipCodeをセット後、入力内容のバリデーションを行う
       */
      setValue("zipCode", zipCode, { shouldValidate: true });

      /**
       * 郵便番号から住所を取得する。
       */
      const response = await fetchAddressFromZipCode(zipCode);
      if (response.error) return;

      /**
       * 取得した住所をReact Hook FormのValueにセットする。
       * - prefecture: 複数の候補が存在する時、重複を削除し0番目の値を採用する。
       * - city, town: 複数の候補が存在する時、prefectureと同じ値を持つcity, townを採用する。
       */
      const [prefecture] = unique(response.data.map((el) => el.prefecture));
      // @ts-expect-error TS2339
      const [{ city, town }] = unique(
        response.data
          .filter((el) => el.prefecture === prefecture)
          .map((el) => {
            return { city: el.city, town: el.town };
          })
      );

      /**
       * apiで取得した値と入力内容を検証するために、一度Stateに保存する。
       */
      setResAddressData(response.data);
      // @ts-expect-error TS2345
      setValue("prefecture", prefecture, { shouldValidate: true });
      setValue("city", `${city}${town}`, {
        shouldValidate: true,
      });
    },
    [setValue, fetchAddressFromZipCode]
  );

  /**
   * バリデートに関して、以下の内容に注意して検証を行う必要がある。
   * これらを考慮して、apiから取得した住所情報の配列の中に、入力した都道府県や市区町村が含まれているか検証を行う。
   *
   * - 郵便番号は必ず1つの町名に紐づいているわけではないケース
   *   - 郵便番号 807-0042
   *   - 福岡県 遠賀郡水巻町 吉田
   *   - 福岡県 遠賀郡水巻町 吉田団地
   *
   * - 市区町村をまたいで同じ郵便番号を持つケース
   *   - 郵便番号 907-0000
   *   - 沖縄県 石垣市
   *   - 沖縄県 八重山郡竹富町
   *
   * - 県を飛び越えて同じ郵便番号を持ちうるケース
   *   - 郵便番号 871-0000
   *   - 福岡県 築上郡吉富町
   *   - 大分県 中津市
   */
  const validatePrefectureRule = useCallback(
    (prefecture: string) => {
      setIsWarning(false);
      /**
       * 郵便番号が未入力であればスキップする
       */
      if (inputValues.zipCode.length === 0) return true;
      /**
       * apiから取得した住所情報がStateにセットされていない場合はスキップする。
       */
      if (resAddressData === null) return true;

      /**
       * apiから取得した住所情報のリストの中に、入力した都道府県が含まれているか検証する。
       * - リストに含まれていなければ、注意文を表示する。
       */
      const prefectureList = unique(resAddressData.map((el) => el.prefecture));
      if (!prefectureList.includes(prefecture)) {
        setIsWarning(true);
      }

      return true;
    },
    [resAddressData, inputValues.zipCode]
  );

  const validateCityRule = useCallback(
    (city: string) => {
      setIsWarning(false);
      /**
       * 郵便番号が未入力であればスキップする
       */
      if (inputValues.zipCode.length === 0) return true;

      /**
       * apiから取得した住所情報がStateにセットされていない場合はスキップする。
       */
      if (resAddressData === null) return true;

      /**
       * apiから取得した住所情報のリストの中に、入力した市区町村が含まれているか検証する。
       * - リストに含まれていなければ、注意文を表示する。
       */
      if (resAddressData.some((data) => `${data.city}${data.town}` === city)) {
        return true;
      } else {
        setIsWarning(true);
      }

      return true;
    },
    [resAddressData, inputValues.zipCode]
  );

  const validateBuildingInfoRule = useCallback(
    (buildingInfo: string) => {
      /**
       * 番地が未入力であればスキップする
       */
      if (inputValues.streetNum.length === 0) return true;

      /**
       * 番地とその他建物名の文字数を計算して、64文字より多ければエラーを表示する。
       * 1文字は半角、2文字は全角として計算する。
       * @see: https://service.openlogi.com/release-note/2023-07-25/
       */
      const fullAddress = inputValues.streetNum + buildingInfo;
      const len = Array.from(fullAddress).reduce((count, char) => {
        return count + (/[ -~]/.test(char) ? 1 : 2);
      }, 0);

      if (len > 64) {
        return "番地と建物名を合わせて64文字以内で入力してください。";
      }

      return true;
    },
    [inputValues.streetNum]
  );

  /**
   * onBlur時に、その他、建物名の入力内容を半角に変換する
   */
  const handleOnBlurBuildingInfo = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      /**
       * フォームに入力されている全角を半角に変換する
       */
      const buildingInfo = convertFullWidthToHalfWidth(e.target.value);

      /**
       * React Hook FormのValueにbuildingInfoをセット後、入力内容のバリデーションを行う
       */
      setValue("buildingInfo", buildingInfo, { shouldValidate: true });
    },
    [setValue]
  );

  /**
   * onBlur時に、その他、建物名の入力内容を半角に変換する
   */
  const handleOnBlurStreetNum = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      /**
       * フォームに入力されている全角を半角に変換する
       */
      const streetNum = convertFullWidthToHalfWidth(e.target.value);

      /**
       * React Hook FormのValueにbuildingInfoをセット後、入力内容のバリデーションを行う
       */
      setValue("streetNum", streetNum, { shouldValidate: true });
    },
    [setValue]
  );

  /**
   * onChange時に、パスワードの入力内容をstateとReact Hook FormのValueにセットする。
   */
  const handleOnChangePassword = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      const password = e.target.value;
      props.onChangePassword(e);
      setValue("password", password, { shouldValidate: true });
    },
    [props, setValue]
  );

  // @ts-expect-error TS7006
  const onSubmit = (_data) => {
    // TODO: dataをmoduleの引数に渡すようにする
    setIsWaitingSave(true);
    // check if re-auth required
    if (isReAuthRequiredToEditAddress(props.user)) {
      dispatch(
        authActions.reAuthForEditUserInfo.started({
          // @ts-expect-error TS2322
          password: inputValues.password || null,
        })
      );
    } else {
      dispatch(authActions.successReAuthForEditUserInfo(true));
    }
  };

  return (
    <div css={classes.root}>
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/account", t("common.routes.account")],
          [null, t("common.routes.accountAddress")],
        ]}
      />

      <ContentsLayout>
        <div css={classes.header}>
          <h2>配送先住所の編集</h2>
          <p>お客様の住所をご入力いただき、「送信」ボタンを押してください。</p>

          <div className="caution">
            <p>
              ※番地・建物名などの住所情報でカタカナとアルファベットを使用する場合は、半角で入力してください。
            </p>
            <p>
              ※全角で入力した場合でも、カーソルを外すと自動で半角に変換されます。
            </p>
          </div>
        </div>

        <form css={classes.contents} onSubmit={handleSubmit(onSubmit)}>
          <div css={classes.formItem}>
            <AccountAddressFormItemLayout
              heading={t("accountAddress.zipCode")}
              isRequired={true}
            >
              <AccountAddressZipCode
                register={register("zipCode", {
                  required: {
                    value: true,
                    message: t("accountAddress.error.required"),
                  },
                  minLength: {
                    value: 7,
                    message: t("accountAddress.error.minLength"),
                  },
                  maxLength: {
                    value: 8,
                    message: t("accountAddress.error.maxLength"),
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: t("accountAddress.error.pattern"),
                  },
                })}
                defaultValue={inputValues.zipCode}
                errors={errors}
                onChange={handleChangeZipCode}
              />
            </AccountAddressFormItemLayout>
          </div>

          <div css={classes.formItem}>
            <AccountAddressFormItemLayout
              heading={t("accountAddress.prefectures")}
              isRequired={true}
            >
              <AccountAddressPrefecture
                register={register("prefecture", {
                  required: {
                    value: true,
                    message: t("accountAddress.error.required"),
                  },
                  validate: validatePrefectureRule,
                })}
                errors={errors}
                defaultValue={inputValues.prefecture}
              />
            </AccountAddressFormItemLayout>
          </div>

          <div css={classes.formItem}>
            <AccountAddressFormItemLayout
              heading={t("accountAddress.municipalities")}
              isRequired={true}
            >
              <AccountAddressCity
                register={register("city", {
                  required: {
                    value: true,
                    message: t("accountAddress.error.required"),
                  },
                  validate: validateCityRule,
                })}
                errors={errors}
                defaultValue={inputValues.city}
              />
            </AccountAddressFormItemLayout>
          </div>

          <div css={classes.formItem}>
            <AccountAddressFormItemLayout
              heading={t("accountAddress.houseNumbers")}
              isRequired={true}
            >
              <AccountAddressStreetNum
                register={register("streetNum", {
                  required: {
                    value: true,
                    message: t("accountAddress.error.required"),
                  },
                  pattern: {
                    value: /^[-0-9０-９a-zA-Zぁ-んァ-ヶｱ-ﾝﾞﾟ一-龥々 ]*$/,
                    message: t("accountAddress.error.pattern"),
                  },
                })}
                errors={errors}
                defaultValue={inputValues.streetNum}
                onBlur={handleOnBlurStreetNum}
              />
            </AccountAddressFormItemLayout>
          </div>

          <div css={classes.formItem}>
            <AccountAddressFormItemLayout
              heading={t("accountAddress.other")}
              isRequired={false}
            >
              <AccountAddressBuildingInfo
                register={register("buildingInfo", {
                  pattern: {
                    value: /^[-0-9０-９a-zA-Zぁ-んァ-ヶｦ-ﾟ一-龥々 ]*$/,
                    message: t("accountAddress.error.pattern"),
                  },
                  validate: validateBuildingInfoRule,
                })}
                errors={errors}
                defaultValue={inputValues.buildingInfo}
                onBlur={handleOnBlurBuildingInfo}
              />
            </AccountAddressFormItemLayout>
          </div>

          <div css={classes.formItem}>
            <AccountAddressFormItemLayout
              heading={t("accountAddress.name")}
              isRequired={true}
            >
              <AccountAddressUserName
                register={register("userName", {
                  required: {
                    value: true,
                    message: t("accountAddress.error.required"),
                  },
                  pattern: {
                    value: /^[ 0-9０-９a-zA-Zぁ-んァ-ヶｱ-ﾝﾞﾟ一-龠々]*$/,
                    message: t("accountAddress.error.pattern"),
                  },
                })}
                errors={errors}
                defaultValue={inputValues.userName}
              />
            </AccountAddressFormItemLayout>
          </div>

          <div css={classes.formItem}>
            <AccountAddressFormItemLayout
              heading={t("accountAddress.phoneNumber")}
              isRequired={true}
            >
              <AccountAddressPhone
                register={register("phone", {
                  required: {
                    value: true,
                    message: t("accountAddress.error.required"),
                  },
                  minLength: {
                    value: 10,
                    message: t("accountAddress.error.minLength"),
                  },
                  maxLength: {
                    value: 11,
                    message: t("accountAddress.error.maxLength"),
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: t("accountAddress.error.pattern"),
                  },
                })}
                errors={errors}
                defaultValue={inputValues.phone}
              />
            </AccountAddressFormItemLayout>
          </div>

          {props.isEmailProvider && (
            <div css={classes.formItem}>
              <AccountAddressFormItemLayout
                heading={t("accountAddress.password")}
                isRequired={true}
              >
                <AccountAddressPassword
                  register={register("password", {
                    required: {
                      value: props.isEmailProvider ? true : false,
                      message: t("accountAddress.error.required"),
                    },
                  })}
                  errors={errors}
                  defaultValue={inputValues.password}
                  onChange={handleOnChangePassword}
                />
              </AccountAddressFormItemLayout>
            </div>
          )}

          {isWarning && (
            <div
              css={css`
                padding: 16px;
                margin-bottom: 24px;
                background-color: #ffffcb;
                p {
                  font-size: 14px;
                  line-height: 1.5em;
                }
              `}
            >
              <p>
                入力された郵便番号と住所が一致しませんでした。
                <br />
                送信前に入力内容のご確認をお願いいたします。
              </p>
              {/* <p>入力内容：{inputValues.prefecture}{inputValues.city}</p>
              <p>候補：{resAddressData.prefecture}{resAddressData.city}{resAddressData.town}</p> */}
            </div>
          )}

          <div css={classes.button}>
            <input
              type="submit"
              defaultValue={t("accountAddress.updateAddress")}
              disabled={Object.keys(errors).length > 0}
            />
          </div>
        </form>
      </ContentsLayout>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      padding: 40px 0 80px;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        max-width: 1800px;
        width: 100%;
        min-height: calc(100vh - 280px);
        padding-top: 0;
        margin: 0 auto;
      }
    `,
    header: css`
      margin: 0 auto 40px;
      @media screen and (min-width: 768px) {
        width: 960px;
      }
      h2 {
        margin-bottom: 16px;
        font-size: 18px;
      }
      p {
        font-size: 14px;
        line-height: 1.5em;
      }
      .caution {
        margin: 0 0 16px;
        p {
          padding-left: 1em;
          font-size: 12px;
          text-indent: -1em;
        }
      }
    `,
    contents: css`
      width: 100%;
      margin: 0 auto 80px;
      @media screen and (min-width: 768px) {
        width: 960px;
      }
    `,
    formItem: css`
      margin-bottom: 24px;
    `,
    button: css`
      @media screen and (min-width: 768px) {
        width: 400px;
        margin: 0 auto;
      }
      input[type="submit"] {
        display: block;
        width: 100%;
        padding: 8px 16px;
        color: #fff;
        font-weight: bold;
        letter-spacing: 1px;
        background-color: #00c2ae;
        border: none;
        cursor: pointer;
        &:disabled {
          cursor: not-allowed;
          background-color: #b1b1b1;
        }
      }
    `,
  };
};
