/** @jsxRuntime classic /
 /** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { Button } from "components/atoms/button/Button";
import { useI18n } from "hooks/i18n/i18n";
import { usePrevious } from "hooks/Reference";
import { CircularProgress } from "@material-ui/core";
import { GoToConfirm } from "tmp/goto/GoToConfirm";
import styled from "@emotion/styled";
import { ButtonList } from "../../styles";

interface PurchaseButtonProps {
  isChecked: boolean;
  isPurchasing: boolean;
  purchase: () => void;
  goBack: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  submitBtnText?: string;
}

const _PurchaseButtonList = styled(ButtonList)`
  .flex-container {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    .buy {
      width: 500px;
    }
  }
  .back {
    max-width: 500px;
    margin: 0 auto;
  }
  .circular-progress {
    position: absolute;
    margin-top: 5px;
  }
`;
const purchaseButtonListOverrideMixin = css`
  margin-bottom: 32px;
`;
const PurchaseButtonList = styled(_PurchaseButtonList)(
  purchaseButtonListOverrideMixin
);

export const PurchaseButton = (props: PurchaseButtonProps) => {
  const { t } = useI18n();
  const [isRead, setIsRead] = useState<boolean>(false);
  const [_isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [failedPurchaseCount, setFailedPurchaseCount] = useState<number>(0);
  const prevIsPurchasing = usePrevious(props.isPurchasing);

  const watchButtonState = () => {
    if (isRead) {
      // if isPurchasing: true → false, settlement is failed.
      // if settlement is failed, can't push purchase button for a while
      // loading中に同意するボタンを押すとちょっと挙動怪しい
      if (prevIsPurchasing === true && props.isPurchasing === false) {
        setLoading(true);
        window.setTimeout(() => {
          setLoading(false);
          setIsButtonDisabled(false);
        }, getIntervalMillisUntilPurchase(failedPurchaseCount));
        setFailedPurchaseCount(failedPurchaseCount + 1);
      } else if (!props.isPurchasing) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    } else {
      setIsButtonDisabled(true);
    }
  };
  useEffect(watchButtonState, [
    failedPurchaseCount,
    isRead,
    prevIsPurchasing,
    props.isPurchasing,
  ]);

  return (
    <React.Fragment>
      {/* 利用規約を表示する */}
      <GoToConfirm />

      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          margin-top: 16px;
          font-size: 14px;
          * {
            cursor: pointer;
          }
        `}
      >
        <input type="checkbox" id="horns" onClick={() => setIsRead(!isRead)} />
        <label htmlFor="horns">利用規約に同意する</label>
      </div>

      <PurchaseButtonList>
        <div className="flex-container">
          <div className="buy">
            <Button
              text={props.submitBtnText || t("settlement.buy")} // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
              onClick={props.purchase}
              disabled={!isRead || loading}
            />
          </div>

          {loading && (
            <CircularProgress
              size={24}
              thickness={6}
              style={{ color: "#00c2ae" }}
              className="circular-progress"
            />
          )}
        </div>

        <div className="back">
          <Button
            text={t("settlement.back")}
            color={"#5f6667"}
            onClick={props.goBack}
          />
        </div>
      </PurchaseButtonList>
    </React.Fragment>
  );
};

/**
 * 指数バックオフを利用して購入失敗してから購入できるまでの時間を返す
 * @see https://developers.google.com/analytics/devguides/reporting/core/v4/errors?hl=ja#backoff
 */
const getIntervalMillisUntilPurchase = (count: number) => {
  return Math.pow(2, count) * 1000;
};
