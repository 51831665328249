/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Link } from "react-router-dom";
import { createUrl } from "utility/createUrl";

/**
 * @description テキストリンクを作成するコンポーネント
 * @param href リンク先のパス
 * @param searchParams リンク先のパスに付与するクエリパラメータ（デフォルトは[]）
 * @param children リンクに表示するテキスト
 * @param color リンクの色（デフォルトは#01baa5）
 * @param textDecoration リンクの下線の有無（デフォルトはunderline）
 */
export const TextLink: React.FC<{
  href: string;
  searchParams?: {
    key: string;
    value: string;
  }[];
  children: string;
  color?: string;
  textDecoration?: "underline" | "none";
}> = ({
  href,
  searchParams = [],
  children,
  color = "#01baa5",
  textDecoration = "underline",
}) => {
  const styles = css`
    color: ${color};
    text-decoration: ${textDecoration};
  `;

  // 外部リンクの場合はaタグを使用する
  // urlはcreateUrlで作成する
  if (href.startsWith("https://")) {
    const url = createUrl({
      location: href,
      searchParams,
    });
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" css={styles}>
        {children}
      </a>
    );
  }

  // 内部リンクの場合はLinkタグを使用する
  return (
    <Link to={href} css={styles}>
      {children}
    </Link>
  );
};
