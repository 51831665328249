import { css } from "@emotion/react";

export const modalWindowMixin = css`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  padding: 60px 0 !important;
  @media screen and (min-width: 768px) {
    /* width: 1280px; */
    margin: 0 auto;
    padding: 80px 0;
  }
  .modal_close {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 35px;
    cursor: pointer;
  }
  .button_list {
    width: 100%;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 500px;
    }
  }
`;
