import { createStore, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import reducers from "../modules";
import indexSaga from "../modules/indexSaga";
import * as Sentry from "@sentry/browser";

export const history = createBrowserHistory();

export type Store = ReturnType<typeof store.getState>;
const sagaMiddleware = createSagaMiddleware({
  onError(err) {
    Sentry.captureException(err);
  },
});

const loggerMiddleware = createLogger({
  // omit redux-logger
  predicate: () => process.env.NODE_ENV === "development" && false,
});

const store = createStore(
  reducers(history),
  composeWithDevTools(
    applyMiddleware(sagaMiddleware, loggerMiddleware, routerMiddleware(history))
  )
);
sagaMiddleware.run(indexSaga);

export default store;
