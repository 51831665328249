/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useI18n } from "hooks/i18n/i18n";
import type { Place } from "@spwn/types/firebase/firestore";
import { isToukenranbuDomain } from "utility/hosting";
import { fontDinMixin } from "styles";

export const AccountTicketDigitalPlace: React.FC<{
  placeCodes: string[];
  places: Place[] | undefined;
}> = ({ placeCodes, places }) => {
  const { t } = useI18n();
  const classes = styles();

  if (IS_HIDE_PLACE_AREA()) return null;

  return (
    <div css={classes.root}>
      <div css={classes.heading}>
        <h2>{t("ticketDetail.place.title")}</h2>
        <p>{t("ticketDetail.place.subTitle")}</p>
      </div>

      <div css={classes.list}>
        {placeCodes.map((placeCode, i) => {
          const place = places?.find((el) => el.code === placeCode);
          return (
            <div key={i} css={classes.item}>
              <div css={classes.info}>
                <p className="area">{place?.areaName}</p>
                <h4 className="venue">{place?.venueName}</h4>
                <p className="time">{place?.accessInfo}</p>
              </div>

              <div css={classes.link}>
                {place?.officialSiteUrl && (
                  <a
                    href={place.officialSiteUrl}
                    target="_blank"
                    className="website"
                    rel="noreferrer"
                  >
                    {t("ticketDetail.website")}
                  </a>
                )}
                {place?.mapUrl && (
                  <a
                    href={place.mapUrl}
                    target="_blank"
                    className="website"
                    rel="noreferrer"
                  >
                    {t("ticketDetail.map")}
                  </a>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const IS_HIDE_PLACE_AREA = () => {
  return isToukenranbuDomain();
};

const styles = () => {
  return {
    root: css`
      margin-bottom: 60px;
    `,
    heading: css`
      margin-bottom: 20px;
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
      }
      h2 {
        ${fontDinMixin};
        padding: 5px 0 0 15px;
        margin-bottom: 5px;
        font-size: 35px;
        border-left: 5px solid #01ffe1;
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
          letter-spacing: 3px;
        }
      }
      p {
        padding-left: 21px;
        font-size: 12px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    `,
    list: css`
      max-width: 740px;
      width: 100%;
      margin: 0 auto;
    `,
    item: css`
      border: 1px solid #d4d4d4;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    `,
    info: css`
      padding: 5%;
      @media screen and (min-width: 768px) {
        padding: 20px;
      }
      .area {
        font-size: 14px;
        margin-bottom: 10px;
      }
      .venue {
        margin-bottom: 20px;
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
      }
    `,
    link: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4%;
      background-color: #f6fcfc;
      @media screen and (min-width: 768px) {
        padding: 15px;
      }
      a {
        color: #06d2da;
        font-size: 14px;
        font-weight: bold;
      }
    `,
  };
};
