/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useI18n } from "hooks/i18n/i18n";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getEventDisplayInfo } from "utility";
import { changeStyleWithHosting } from "utility/hosting";
import type { Event } from "@spwn/types/firebase/firestore";

type ArchiveEventListProps = {
  eventId: string;
  eventTitle: string;
  artists: string[];
  thumbnail: string;
  eventOpenDateFull: string;
};

export const ArchiveEventListLogic: FC<{
  vodEvents?: Event[];
}> = ({ vodEvents }) => {
  const LIST_COUNT = 5;
  const [eventList, setEventList] = useState<ArchiveEventListProps[]>([]);

  useEffect(() => {
    (async () => {
      const eventsInfo = (vodEvents ?? [])
        .flatMap((event) => {
          const eventInfo = getEventDisplayInfo(event);
          if (!eventInfo.isOpenStreamingPage) return [];
          return [eventInfo];
        })
        .slice(0, LIST_COUNT);

      const eventList = await Promise.all(
        eventsInfo.map(async (event) => {
          if (!event.artistRefs) {
            return {
              eventId: event.eventId,
              eventTitle: event.eventTitle,
              artists: [event.artists],
              thumbnail: event.thumbnail,
              eventOpenDateFull: event.eventEndDateFull,
            };
          }

          const artists: string[] = await Promise.all(
            event.artistRefs.map(async (ref) => {
              const snapshot = await ref.get();
              const artistData = snapshot.data();
              // @ts-expect-error TS18048
              return artistData.name;
            })
          );

          return {
            eventId: event.eventId,
            eventTitle: event.eventTitle,
            artists,
            thumbnail: event.thumbnail,
            eventOpenDateFull: event.eventEndDateFull,
          };
        })
      );
      setEventList(eventList);
    })();
  }, [vodEvents]);

  if (eventList.length === 0) {
    return null;
  }

  return <ArchiveEventList eventList={eventList} />;
};

const ArchiveEventList: FC<{
  eventList: ArchiveEventListProps[];
}> = ({ eventList }) => {
  const { t } = useI18n();
  const classesArchiveListDesign = archiveListDesign();

  return (
    <section id="deliveringEventList" css={classesArchiveListDesign.root}>
      <div css={classesArchiveListDesign.heading}>
        <h2>ARCHIVES</h2>
        <p>{t("event.top.archiveEvent")}</p>
      </div>

      <div css={classesArchiveListDesign.archiveListArea}>
        <div css={classesArchiveListDesign.archiveScrollArea}>
          <div css={classesArchiveListDesign.archiveList}>
            {eventList.map((event, index) => {
              return (
                <article
                  key={"archive_item" + index}
                  css={classesArchiveListDesign.archiveItem}
                >
                  <Link to={`/events/${event.eventId}`}>
                    <img src={event.thumbnail} alt={event.eventTitle} />
                    <div css={classesArchiveListDesign.archiveItemText}>
                      <p className="date">{event.eventOpenDateFull}</p>
                      <h3 className="title">{event.eventTitle}</h3>
                      <p className="artist">
                        {event.artists.map((artist, index) => {
                          return event.artists.length - 1 === index
                            ? `${artist}`
                            : `${artist} / `;
                        })}
                      </p>
                    </div>
                  </Link>
                </article>
              );
            })}
          </div>
        </div>
      </div>

      <Link css={classesArchiveListDesign.linkButton} to={`/vod-events`}>
        {t("event.top.archiveEventList")}
      </Link>
    </section>
  );
};

const archiveListDesign = () => {
  const hostingStyleMap = changeStyleWithHosting().baseStyles;
  const textColor = hostingStyleMap.fontPrimaryColor;

  return {
    root: css``,
    heading: css`
      max-width: 980px;
      width: 90%;
      margin: 0 auto 20px;
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
      }
      h2 {
        padding: 5px 0 5px 16px;
        margin-bottom: 5px;
        color: ${textColor};
        font-size: 24px;
        line-height: 1.3em;
        border-left: 5px solid ${hostingStyleMap.accentColor};
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
          letter-spacing: 3px;
        }
      }
      p {
        padding-left: 21px;
        color: ${textColor};
        font-size: 14px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    `,
    linkButton: css`
      display: block;
      width: 90%;
      padding: 12px 0;
      margin: 0 auto;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      line-height: 1.2em;
      letter-spacing: 1px;
      background-color: ${hostingStyleMap.accentColor};
      cursor: pointer;
      @media screen and (min-width: 768px) {
        width: 680px;
        padding: 15px 0;
        margin: 0 auto;
        letter-spacing: 2px;
      }
    `,
    archiveListArea: css`
      width: 100%;
      padding: 15px 0% 20px;
      margin-bottom: 20px;
      background-color: rgba(0, 0, 0, 0.6);
      @media screen and (min-width: 768px) {
        padding: 40px 0 40px;
      }
    `,
    archiveScrollArea: css`
      max-width: 980px;
      width: 90%;
      margin: 0 auto;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      @media screen and (min-width: 768px) {
        width: 100%;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    `,
    archiveList: css`
      display: flex;
      flex-wrap: wrap;
      width: calc(830px + 5%);
      @media screen and (min-width: 768px) {
        width: calc(1580px + 150px);
      }
    `,
    archiveItem: css`
      width: 150px;
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      @media screen and (min-width: 768px) {
        width: 300px;
      }
      &:not(:last-child) {
        margin-right: 20px;
      }
      a {
        display: block;
        height: 100%;
      }
      img {
        width: 100%;
        border-radius: 5px 5px 0 0;
      }
    `,
    archiveItemText: css`
      padding: 12px 15px 35px;
      .date {
        margin-bottom: 10px;
        color: #000;
        font-size: 14px;
        letter-spacing: 1px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
      h3 {
        min-height: 48px;
        margin-bottom: 10px;
        font-size: 13px;
        line-height: 1.3em;
        white-space: pre-line;
        @media screen and (min-width: 768px) {
          min-height: 52px;
          font-size: 20px;
        }
      }
      .artist {
        margin-bottom: 10px;
        color: #707070;
        font-size: 12px;
        font-weight: bold;
        white-space: pre-line;
        @media screen and (min-width: 768px) {
          font-size: 14px;
          line-height: 1.3em;
        }
      }
    `,
  };
};
