/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Store } from "store";
import {
  createListSetOfField,
  isDisplayPeriod,
  convertMapToValues,
} from "utility";
import arrow from "designs/images/streaming/arrow_down.png";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ReplayIcon from "@material-ui/icons/Replay";
import InfoIcon from "@material-ui/icons/Info";
import { isShippingItem } from "utility/ticket";
import { scrollTop } from "utility/index";
import { useI18n } from "hooks/i18n/i18n";
import type { ProductData } from "@spwn/types/firebase/firestore";

interface Props {
  THEOPlayerElementHeight?: number;
  itemNum: number;
  pseudoCart: ProductData[];
  // @ts-expect-error TS7006
  openGoodsInfo: (goodsItem) => void;
  openLiveCommerceCart: () => void;
  incrementCartItem: (itemData: ProductData) => void;
}
const StreamingLiveCommerceGoodsList: React.FC<Props> = (props) => {
  // @ts-expect-error TS2345
  const [selectType, setType] = useState<string>(null);
  const [ticketsCategoryList, setTicketsCategoryList] = useState<ProductData[]>(
    []
  );
  const [goodsCategoryList, setGoodsCategoryList] = useState<ProductData[]>([]);
  const goods = useSelector((state: Store) => state.ticket.eventGoods);
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );
  const isAdmin = useSelector((state: Store) => state.admin.isAdmin);
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const eventTicketMap = useSelector(
    (state: Store) => state.ticket.eventTicketMap
  );

  const { t } = useI18n();
  const classesLiveCommerceDesign = liveCommerceDesign({
    isDarkModeEnabled,
    THEOPlayerElementHeight: props.THEOPlayerElementHeight,
  });

  const didMount = () => {
    scrollTop();
    setSellingItemsList();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(didMount, []); // TODO

  const getCartItem = useCallback(
    (itemId: string) => {
      return props.pseudoCart.find((e) => e._id === itemId);
    },
    [props.pseudoCart]
  );

  const getAddItem = (classGoodsList: ProductData[]) => {
    const addItem =
      classGoodsList.length === 1
        ? classGoodsList[0]
        : selectType
        ? classGoodsList.filter((el) => el._id === selectType)[0]
        : null;
    return addItem;
  };

  const addToCart = (classGoodsList: ProductData[]) => {
    const addItem = getAddItem(classGoodsList);
    if (!addItem) {
      return;
    }
    props.incrementCartItem(addItem);
  };

  const addToCartToBuy = (classGoodsList: ProductData[]) => {
    const addItem = getAddItem(classGoodsList);
    if (!addItem) {
      return;
    }
    // カートに商品がなければ追加する
    // @ts-expect-error TS2345
    const item = getCartItem(addItem._id);
    if (!item) {
      props.incrementCartItem(addItem);
    }
    props.openLiveCommerceCart();
  };

  const selectedLiveCommerceGoods = goods
    .filter(
      (goods) =>
        (isDisplayPeriod(
          goods.display,
          goods.releaseDateTime,
          goods.closeDateTime
        ) &&
          isShippingItem(goods)) ||
        (goods.display && isAdmin)
    )
    .sort(
      (prev, cur) =>
        cur.releaseDateTime.seconds - prev.releaseDateTime.seconds ||
        prev.priority - cur.priority
    );

  const selectedLiveCommerceTickets = convertMapToValues(eventTicketMap).filter(
    (ticket) =>
      ticket?.saleType &&
      (ticket?.saleType === "liveCommerce" ||
        ticket?.saleType === "liveCommerceOnly") &&
      isDisplayPeriod(
        ticket.display,
        ticket.releaseDateTime,
        ticket.closeDateTime
      )
  );

  const setSellingItemsList = () => {
    setGoodsCategoryList(
      createListSetOfField<ProductData>(selectedLiveCommerceGoods, "class")
    );
    setTicketsCategoryList(selectedLiveCommerceTickets);
  };

  return (
    <div css={classesLiveCommerceDesign.root}>
      <div css={classesLiveCommerceDesign.list}>
        {ticketsCategoryList.length > 0 && (
          <React.Fragment>
            <div css={classesLiveCommerceDesign.header}>
              <p>TICKET</p>
            </div>
            <div css={classesLiveCommerceDesign.item}>
              <div css={classesLiveCommerceDesign.ticketImage}>
                <img src={displayEvent.thumbnail} alt={displayEvent.title} />
              </div>
              <div css={classesLiveCommerceDesign.text}>
                <h3>{displayEvent.title}</h3>
              </div>
            </div>
            {ticketsCategoryList.map((ticket, index) => {
              const classTicketList = [ticket];
              return (
                <div css={classesLiveCommerceDesign.ticketItem} key={index}>
                  <h3>{ticket.name}</h3>
                  <p css={classesLiveCommerceDesign.price}>
                    ¥{ticket.price_jpy.toLocaleString()}
                    <span>税込</span>
                  </p>
                  <div css={classesLiveCommerceDesign.ticketButtonList}>
                    <p
                      css={classesLiveCommerceDesign.cart}
                      onClick={() => addToCart(classTicketList)}
                    >
                      <ShoppingCartIcon />
                    </p>
                    <p
                      css={classesLiveCommerceDesign.purchase}
                      onClick={() => addToCartToBuy(classTicketList)}
                    >
                      購入する
                    </p>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        )}

        {goodsCategoryList.length > 0 && (
          <React.Fragment>
            <div css={classesLiveCommerceDesign.header}>
              <p>GOODS</p>
            </div>
            {goodsCategoryList.map((item, index) => {
              const classGoodsList = selectedLiveCommerceGoods.filter(
                (el) => el.className === item.name
              );
              return (
                <div css={classesLiveCommerceDesign.item} key={index}>
                  <div css={classesLiveCommerceDesign.image}>
                    <img src={item.thumbnail} alt={item.name} />
                    <p onClick={() => props.openGoodsInfo(item)}>
                      <InfoIcon />
                    </p>
                  </div>
                  <div css={classesLiveCommerceDesign.text}>
                    <h3>{item.name}</h3>

                    {classGoodsList.length === 1 ? null : (
                      <select
                        css={classesLiveCommerceDesign.select}
                        onChange={(e) => setType(e.target.value)}
                      >
                        {/*
                         // @ts-expect-error TS2322 */}
                        <option value={null}>選択してください</option>
                        {classGoodsList.map((className, index) => {
                          return (
                            <option key={index} value={className._id}>
                              {className.subClassName}
                            </option>
                          );
                        })}
                      </select>
                    )}

                    <p css={classesLiveCommerceDesign.price}>
                      ¥{item.price_jpy.toLocaleString()}
                      <span>{t("streaming.commerceGoodsList.tax")}</span>
                    </p>

                    <div css={classesLiveCommerceDesign.buttonList}>
                      <p
                        css={classesLiveCommerceDesign.cart}
                        onClick={() => addToCart(classGoodsList)}
                      >
                        <ShoppingCartIcon />
                      </p>
                      <p
                        css={classesLiveCommerceDesign.purchase}
                        onClick={() => addToCartToBuy(classGoodsList)}
                      >
                        {t("streaming.commerceGoodsList.purchase")}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        )}

        {ticketsCategoryList.length === 0 && goodsCategoryList.length === 0 && (
          <p
            css={classesLiveCommerceDesign.replay}
            onClick={setSellingItemsList}
          >
            <ReplayIcon />
          </p>
        )}
      </div>

      <div css={classesLiveCommerceDesign.ItemsInYourCart}>
        {/* <p>カートに入っている商品：{props.itemNum}点</p> */}
        <p>
          {t("streaming.commerceGoodsList.cart", { itemNum: props.itemNum })}
        </p>
        <ShoppingCartIcon onClick={props.openLiveCommerceCart} />
      </div>
    </div>
  );
};

interface LiveCommerceDesignProps {
  isDarkModeEnabled: boolean;
  THEOPlayerElementHeight?: number;
}
const liveCommerceDesign = (props?: LiveCommerceDesignProps) => {
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const borderColorInDarkMode = props.isDarkModeEnabled
    ? css`
        border: 1px solid #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const actionAreaHeight = props.THEOPlayerElementHeight
    ? css`
        height: calc(
          ${
              // @ts-expect-error TS18048
              props.THEOPlayerElementHeight
            }px - 101px
        );
      `
    : null;

  const root = css`
    @media screen and (min-width: 768px) {
      position: relative;
      padding-bottom: 56px;
    }
  `;
  const header = css`
    padding: 8px 0;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f4f4f4;
    p {
      padding: 0 12px;
      font-size: 15px;
      font-weight: bold;
    }
  `;
  const list = css`
    border: 1px solid #e0e0e0;
    overflow-y: scroll;
    @media screen and (min-width: 768px) {
      ${actionAreaHeight}
      min-height: 500px;
    }
  `;
  const item = css`
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
  `;
  const ticketItem = css`
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    h3 {
      margin-bottom: 8px;
      ${textColorInDarkMode}
      font-size: 16px;
      font-weight: normal;
      line-height: 1.3em;
    }
  `;
  const image = css`
    position: relative;
    width: 150px;
    height: 150px;
    margin-right: 10px;
    object-fit: cover;
    img {
      width: 100%;
    }
    p {
      position: absolute;
      top: 4px;
      left: 4px;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
      svg {
        fill: #bbb;
      }
    }
  `;
  const ticketImage = css`
    width: 150px;
    margin-right: 10px;
    img {
      width: 100%;
    }
  `;
  const text = css`
    width: calc(100% - 160px);
    h3 {
      margin-bottom: 16px;
      ${textColorInDarkMode}
      font-size: 16px;
      font-weight: normal;
      line-height: 1.3em;
    }
  `;
  const select = css`
    width: 100%;
    padding: 5px 8px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: normal;
    background-color: #f4f4f4;
    background-image: url(${arrow});
    background-size: 8px auto;
    background-repeat: no-repeat;
    background-position: 95% center;
    border: 1px solid #dedede;
    border-radius: 5px;
    appearance: none;
  `;
  const replay = css`
    margin: 100px 40%;
    text-align: center;
    cursor: pointer;
    &:active {
      opacity: 0.6;
    }
  `;
  const price = css`
    margin-bottom: 10px;
    ${textColorInDarkMode}
    font-size: 16px;
    span {
      margin-left: 4px;
      font-size: 12px;
    }
  `;
  const buttonList = css`
    display: flex;
    p {
      background-color: #00c2ae;
      border-radius: 5px;
    }
  `;
  const ticketButtonList = css`
    display: flex;
    width: calc(100% - 160px);
    margin-left: auto;
    p {
      background-color: #00c2ae;
      border-radius: 5px;
    }
  `;
  const cart = css`
    width: 35px;
    height: 35px;
    padding: 8px;
    margin-right: 8px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      fill: #fff;
    }
  `;
  const purchase = css`
    width: calc(100% - 43px);
    padding: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  `;
  const ItemsInYourCart = css`
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 24px;
    background-color: #202020;
    ${borderColorInDarkMode}
    @media screen and (min-width: 768px) {
      position: absolute;
      bottom: 0;
    }
    p {
      color: #fff;
      font-size: 14px;
    }
    svg {
      fill: #fff;
      cursor: pointer;
    }
  `;
  const addressRegistration = css`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #202020;
    ${borderColorInDarkMode}
    @media screen and (min-width: 768px) {
      position: absolute;
      bottom: 0;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
    }
    p {
      color: #fff;
      font-size: 14px;
      line-height: 1.3em;
      span {
        font-size: 12px;
      }
    }
    svg {
      fill: #fff;
    }
  `;
  return {
    root,
    header,
    list,
    item,
    ticketItem,
    image,
    ticketImage,
    text,
    select,
    replay,
    price,
    buttonList,
    ticketButtonList,
    cart,
    purchase,
    ItemsInYourCart,
    addressRegistration,
  };
};
export default StreamingLiveCommerceGoodsList;
