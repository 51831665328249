// cloudfrontのドメインはここに追加する
// https://www.notion.so/balusco/dev-spwn-jp-03ce3ee353844047989e633976f9cdfc
const HOSTING_BY_CLOUDFRONT_DOMAINS = [
  "web-dev1.spwn.jp",
  "web-dev2.spwn.jp",
  "web-dev3.spwn.jp",
  "web-dev4.spwn.jp",
  "web-dev5.spwn.jp",
  "web-dev6.spwn.jp",
  "web-dev7.spwn.jp",
  "web-dev8.spwn.jp",
  "web-dev9.spwn.jp",
  "web-dev10.spwn.jp",
  "admin-dev.spwn.jp",
  "admin.spwn.jp",
  "dev-brand-a.spwn.jp",
];

// @param window.location.host
export const isCloudFrontDomain = (host: string) =>
  HOSTING_BY_CLOUDFRONT_DOMAINS.includes(host);

export const isLocalhost = (host: string) =>
  host === "localhost" || host.startsWith("localhost:");
