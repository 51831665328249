import styled from "@emotion/styled";

export const QuizBlock = styled.div`
  width: 90%;
  padding-top: 40px;
  padding-bottom: 80px;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    padding-top: 50px;
  }
  .quiz_text {
    margin-bottom: 30px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    line-height: 1.5em;
    @media screen and (min-width: 768px) {
      width: 750px;
      margin: 0 auto 30px;
    }
    @media screen and (min-width: 768px) {
      margin-bottom: 50px;
      font-size: 20px;
    }
  }
`;

QuizBlock.displayName = "QuizBlock";
