/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { ComponentProps } from "react";
import { Dialog } from "@material-ui/core";

const grayTextLink = css`
  margin-top: 30px;
  text-align: center;
  color: #7c7c7c;
  text-decoration: underline;
  cursor: pointer;
`;
interface GrayTextLinkProps {
  text: string;
  onClick: () => void;
}
export const GrayTextLink: React.FC<GrayTextLinkProps> = (props) => {
  return (
    <p css={grayTextLink} onClick={props.onClick}>
      {props.text}
    </p>
  );
};

interface ModalHeaderProps {
  title?: string;
  onClick: () => void;
}

export const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  const design = modalHeaderStyle();
  const { title, onClick } = props;

  return (
    <div css={design.header}>
      <div css={design.headerInner}>
        {title && <h3 css={design.title}>{title}</h3>}
        <button css={design.closeBtn} type="button" onClick={onClick}>
          <span css={design.iconClose}></span>
        </button>
      </div>
    </div>
  );
};

interface DialogProps {
  open: boolean;
  id?: string;
  className?: string;
  style?: {
    [key: string]: string | number;
  };
  maxWidth?: ComponentProps<typeof Dialog>["maxWidth"];
  onBackdropClick?: () => void;
}
export const FullScreenDialog: React.FC<DialogProps> = (props) => {
  const { open, style, onBackdropClick, children, ...rest } = props;
  return (
    <Dialog
      {...rest}
      PaperProps={{
        // @ts-expect-error TS2322
        style: style ? style : null,
      }}
      id={props.id}
      className={props.className}
      open={open}
      onBackdropClick={onBackdropClick}
      scroll="body"
      maxWidth={props.maxWidth || "xl"} // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
      fullWidth={true}
    >
      {children}
    </Dialog>
  );
};

export const ConfirmationDialog: React.FC<DialogProps> = (props) => {
  return (
    <Dialog
      open={props.open}
      onBackdropClick={props.onBackdropClick}
      scroll="body"
      maxWidth="md"
      fullWidth={true}
    >
      {props.children}
    </Dialog>
  );
};

const modalHeaderStyle = () => {
  return {
    header: css`
      position: relative;
      background-color: #ffffff;
    `,
    headerInner: css`
      padding: 22px 24px;
    `,
    title: css`
      color: #000000;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    `,
    closeBtn: css`
      position: absolute;
      top: calc(50% - 8px);
      right: 24px;
      padding: 0;
      border: none;
      background-color: inherit;
      cursor: pointer;
    `,
    iconClose: css`
      position: relative;
      display: block;
      width: 16px;
      height: 16px;
      :before,
      :after {
        content: "";
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        width: 16px;
        height: 2px;
        background-color: #0b0b0b;
      }
      :before {
        transform: rotate(135deg);
      }
      :after {
        transform: rotate(45deg);
      }
    `,
  };
};
