import { PayType } from "@spwn/types";
import { CVSCode } from "@spwn/types/gmo";

import { i18nextT } from "hooks/i18n/i18n";

export const phoneInfo = () => ({
  dpay: i18nextT("purchase.phoneInfo.docomo"),
  au: i18nextT("purchase.phoneInfo.au"),
  sb: i18nextT("purchase.phoneInfo.sb"),
});
export const defaultPhoneCode = "dpay";

export const cvsInfo = () => ({
  10001: i18nextT("purchase.lawson.name"),
  10002: i18nextT("purchase.familymart.name"),
  10008: i18nextT("purchase.seikomart.name"),
});
export const defaultCvsCode = "10001";

export type CVSNote = {
  name: string;
  message: string;
  ReceiptNumberCaption: string;
  ConfirmNumberCaption: string;
};

export const getCVSNote = (
  cvsCode: CVSCode,
  ConfNo?: string,
  ReceiptNo?: string
): CVSNote | null => {
  switch (cvsCode) {
    //ローソン
    case "10001":
      return {
        name: i18nextT("purchase.lawson.name"),
        message: i18nextT("purchase.lawson.message"),
        ReceiptNumberCaption: i18nextT("purchase.lawson.receiptNumberCaption"),
        ConfirmNumberCaption: i18nextT("purchase.lawson.confirmNumberCaption"),
      };
    //ファミリーマート
    case "10002":
      return {
        name: i18nextT("purchase.familymart.name"),
        message: i18nextT("purchase.familymart.message", {
          ConfNo,
          ReceiptNo,
        }),
        ReceiptNumberCaption: i18nextT(
          "purchase.familymart.receiptNumberCaption"
        ),
        ConfirmNumberCaption: i18nextT(
          "purchase.familymart.confirmNumberCaption"
        ),
      };
    //セイコーマート
    case "10008":
      return {
        name: i18nextT("purchase.seikomart.name"),
        message: i18nextT("purchase.seikomart.message"),
        ReceiptNumberCaption: i18nextT(
          "purchase.seikomart.receiptNumberCaption"
        ),
        ConfirmNumberCaption: i18nextT(
          "purchase.seikomart.confirmNumberCaption"
        ),
      };
    default:
      return null;
  }
};

export const MAX_PURCHASE_AMOUNT_BY_CVS = 300000;

export const MAX_PURCHASE_AMOUNT_BY_PHONE = 90000;

export const DAY_PERIOD_OF_CVS_PAYMENT = (eventId: string) => {
  //イベント開催日から5日前までを標準の期限とする
  let day = 5;
  switch (eventId) {
    case "200408-mogura":
      day = 3;
      break;
    case "191214-marinasu":
    case "191222-honeystrap":
      day = 6;
      break;
    default:
      break;
  }
  return day;
};

export const N_PURCHASABLE_TICKETS_WITH_SINGLE_CODE = (eventId: string) => {
  //the default value is one (one ticket can be purchasable by one code)
  let n = 1;
  switch (eventId) {
    case "200404-canametoharuky":
      n = 2;
      break;
    default:
      break;
  }
  return n;
};

export const HOUR_UNTIL_PURCHASE_PHONE_CANCELED = 1;

export const CVS_EXPIRATION_DAYS = 3;

/**
 * 決済システム手数料
 */
export const SETTLEMENT_FEE_DATA: { [key in PayType]: number } = {
  Card: 0,
  Member: 0,
  CVS: 220,
  Phone: 220,
  Free: 0,
  Venue: 0,
  /** FIXME Stripeの決済手数料は別途検討中 */
  Stripe: 0,
};

export const CART_EXPIRATION_MINUTES = 15;
