import type {
  ActiveTransaction,
  OrderTransaction,
  WithVariantForOrderLineItem,
} from "@spwn/types/firebase/firestore";

export const isOrderTransaction = (
  data: ActiveTransaction
): data is OrderTransaction => {
  return "lineItems" in data;
};

export const hasVariantOrderLineItem = <
  T extends OrderTransaction["lineItems"][number]
>(
  item: T
): item is T & WithVariantForOrderLineItem => {
  return "hasVariant" in item && item.hasVariant === true;
};
