/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { FC } from "react";

export const StreamingStandbyEventTitle: FC<{
  isDarkModeEnabled: boolean;
  text: string;
}> = ({ isDarkModeEnabled, text }) => {
  return (
    <h1
      css={css`
        color: ${isDarkModeEnabled ? "#fff" : "#000"};
      `}
    >
      {text}
    </h1>
  );
};
