import { EventVideo } from "@spwn/types/firebase/firestore";

import firebase from "firebase";
import useSWR, { SWRResponse } from "swr";

const generateKey = ({
  eventId,
  videoId,
}: {
  eventId: string;
  videoId: string;
}) => ({
  path: "getStreamingEvent",
  eventId,
  videoId,
});

type UseGetStreamingVideo = (args: {
  eventId: string;
  videoId: string;
}) => SWRResponse<EventVideo>;

export const useGetStreamingVideo: UseGetStreamingVideo = ({
  eventId,
  videoId,
}) => {
  return useSWR(
    generateKey({
      eventId,
      videoId,
    }),
    async ({ eventId, videoId }) => await fetchStreamingVideo(eventId, videoId)
  );
};

const fetchStreamingVideo = async (eventId: string, videoId: string) => {
  const snapshot = await firebase
    .firestore()
    .collection("streaming")
    .doc(eventId)
    .collection("videos")
    .doc(videoId)
    .get();

  const data = {
    ...snapshot.data(),
    _id: snapshot.id,
  } as EventVideo;

  return data;
};
