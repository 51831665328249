/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { TFunction } from "i18next";
import { authActions, LoginProvider } from "modules/auth";
import { modalActions } from "modules/modal";
import { I18nTrans, useI18n } from "hooks/i18n/i18n";
import { Button } from "components/atoms/button/Button";
import GoogleLoginButton from "components/GoogleLoginButton";
import { TextLink } from "ui/TextLink";
import "firebase/auth";
import { FallbackLogin } from "./FallbackLogin";
import { useLocation } from "react-router-dom";

/**
 * ログインコンポーネント
 *
 * - crewでは、portalで利用できるログイン方法を一部なくしている（Facebook、Apple、docomo）
 *   - crewを利用する想定の利用者が少ない、ログインのメンテナンスコストを下げるため（docomoは後発で開発するかも）
 *   - portalにあって、crewにないログイン方法でspwnに登録しているユーザーは、portalでアカウント連携をしてもらう
 *     - お問い合わせリンク用意してもらう https://spwn.zendesk.com/hc/ja/sections/11873215937945-%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E9%80%A3%E6%90%BA%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6
 *
 * NOTE: Twitterログインは復活させる可能性もあるので、削除ではなくコメントアウトとしている
 * @see https://github.com/balus-co-ltd/spwn/issues/3427 Twitterログイン停止経緯
 */
export const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const handleChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    []
  );

  const handleChangePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    []
  );

  const handleLoginWithProvider = useCallback(
    (provider: LoginProvider) => {
      dispatch(authActions.login.started(provider));
    },
    [dispatch]
  );

  const handleLoginWithEmail = useCallback(() => {
    const params = { email, pass: password };
    dispatch(authActions.loginWithEmail.started(params));
  }, [email, password, dispatch]);

  const resetPassword = useCallback(
    (t: TFunction) => () => {
      dispatch(
        modalActions.toggleActionModal({
          caption: t("login.passwordReset"),
          contents: {
            email: {
              title: "email",
              tagType: "input",
              placeHolderAttr: t("login.email"),
              typeAttr: "email",
              classAttr: "",
            },
          },
          btnMsg: t("login.buttons.passwordResetMail"),
          callbackTarget: "resetPassword",
        })
      );
    },
    [dispatch]
  );

  return (
    <LoginView
      handleChangeEmail={handleChangeEmail}
      handleChangePassword={handleChangePassword}
      handleLoginWithEmail={handleLoginWithEmail}
      handleLoginWithProvider={handleLoginWithProvider}
      resetPassword={resetPassword}
    />
  );
};

type LoginViewProps = {
  handleChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleLoginWithEmail: () => void;
  handleLoginWithProvider: (provider: LoginProvider) => void;
  resetPassword: (t: TFunction) => () => void;
};

/**
 * ログインのUIコンポーネント
 *
 * FIXME ファイル分けてもいいが名前迷ったのでここに置いている
 */
const LoginView: React.FC<LoginViewProps> = ({
  handleChangeEmail,
  handleChangePassword,
  handleLoginWithEmail,
  handleLoginWithProvider,
  resetPassword,
}) => {
  const { search } = useLocation();
  const classes = styles();
  const { t } = useI18n();
  return (
    <div css={classes.root}>
      <div className="method" css={classes.layout}>
        <h2>LOGIN</h2>

        <div css={classes.form}>
          <p css={classes.twitterNotice}>
            <I18nTrans i18nKey="login.twitterNotice">
              Twitter
              APIの仕様変更に伴い、Twitterアカウントでのログインを停止しました。Twitterアカウントでのログインをご利用だった方は
              <TextLink
                href={"https://spwn.zendesk.com/hc/ja/articles/17980653563673"}
              >
                こちら
              </TextLink>
            </I18nTrans>
          </p>

          <div css={classes.item}>
            <p>{t("login.email")}</p>
            <input type="text" name="email" onChange={handleChangeEmail} />
          </div>

          <div css={classes.item}>
            <p>{t("login.password")}</p>
            <input
              type="password"
              name="pass"
              onChange={handleChangePassword}
            />
          </div>

          <div css={classes.button}>
            <Button
              text={t("login.buttons.login")}
              onClick={handleLoginWithEmail}
            />
            <p onClick={resetPassword(t)}>{t("login.passwordReset")}</p>
          </div>

          <p css={classes.or}>{t("login.or")}</p>

          <div css={classes.method}>
            <div
              css={css`
                margin-bottom: 20px;
              `}
            >
              <GoogleLoginButton
                onClick={handleLoginWithProvider}
                isSignup={false}
              />
            </div>
            {/* <div
              css={css`
                margin-bottom: 20px;
              `}
            >
              <TwitterLoginButton
                onClick={handleLoginWithProvider}
                isSignup={false}
              />
            </div> */}

            {/* 
            FIXME style 「新規登録」ボタンリンクにしたい
            https://www.figma.com/file/SP0QKTfAvX3OdFHYQi5jrC/FanClub-Portal?node-id=439%3A47135&t=eydDF5kTP8mruDqD-4

            FIXME i18n
             */}
            <div
              css={css`
                font-size: 14px;
                text-align: center;
                div {
                  &:not(:last-child) {
                    margin-bottom: 20px;
                  }
                }
              `}
            >
              <div>
                <FallbackLogin />
              </div>

              <div>
                アカウントをすでにお持ちの方で、上記ログイン方法に当てはまらない方は
                <TextLink
                  href={
                    "https://spwncrew.zendesk.com/hc/ja/articles/16515125486873"
                  }
                >
                  こちら
                </TextLink>
              </div>

              {/* 新規登録 */}
              <div>
                <TextLink href={`/signup${search}`}>
                  {t("login.switchToSignUp")}
                </TextLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      position: relative;
      padding: 75px 0;
      background-color: #fff;
    `,
    closeIcon: css`
      position: absolute;
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      svg {
        width: 100%;
        height: 100%;
      }
    `,
    layout: css`
      width: 95%;
      max-width: 980px;
      margin: 0 auto;
      &.method {
        margin-bottom: 80px;
      }
      h2 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        font-style: normal;
        color: #000;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        line-height: 1em;
        letter-spacing: 1px;
        white-space: nowrap;
        @media screen and (min-width: 768px) {
          margin-bottom: 40px;
          font-size: 30px;
        }
        &:after,
        &:before {
          content: "";
          width: 30%;
          background: linear-gradient(
            transparent 40%,
            currentColor 40%,
            currentColor calc(40% + 2px),
            transparent calc(40% + 2px)
          );
          background-clip: padding;
          @media screen and (min-width: 768px) {
            width: 40%;
          }
        }
        &:after {
          margin-left: 4%;
        }
        &:before {
          margin-right: 4%;
        }
      }
    `,
    caution: css`
      font-size: 14px;
      text-align: center;
      line-height: 1.5em;
      margin-bottom: 20px;
    `,
    method: css`
      width: 100%;
      max-width: 560px;
      margin: 0 auto 64px;
    `,
    form: css`
      width: 100%;
      max-width: 560px;
      margin: 0 auto;
    `,
    item: css`
      margin-bottom: 16px;
      p {
        margin-bottom: 8px;
        font-size: 14px;
      }
      input {
        width: 100%;
        padding: 8px;
        font-size: 16px;
        border: 1px solid #d2d2d2;
        appearance: none;
      }
    `,
    button: css`
      width: 100%;
      max-width: 460px;
      margin: 32px auto 32px;
      p {
        margin-top: 16px;
        color: #757575;
        font-size: 14px;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
      }
    `,
    or: css`
      margin-bottom: 20px;
      text-align: center;
    `,
    twitterNotice: css`
      margin-bottom: 24px;
      text-align: center;
      font-size: 14px;
      line-height: 1.5em;
    `,
  };
};
