import firebase from "firebase";
import useSWR from "swr";

import type { Category } from "@spwn/types/firebase/firestore";
import { fetchFirestoreCollectionBySnapshot } from "utility/firebase";

export const useGetContactCategoryList = () => {
  return useSWR("contact/categories", async () => fetchContactCategoryList(), {
    revalidateOnFocus: false,
  });
};

const fetchContactCategoryList = async () => {
  const snapshot = firebase
    .firestore()
    .collection("/FAQ/public/categories")
    .where("isReleased", "==", true)
    .get();
  return await fetchFirestoreCollectionBySnapshot<Category>(snapshot);
};
