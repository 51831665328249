import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import { authState } from "modules/auth";
import { APPLE_PROVIDER_ID } from "constants/firebase";

export type SNSLinkageStatus = {
  google: boolean;
  twitter: boolean;
  apple: boolean;
};
export const getSNSLinkageStatus = (
  user: authState["user"]
): SNSLinkageStatus => {
  return {
    google:
      user.providerData.findIndex(
        // @ts-expect-error TS18047
        (el) => el.providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ) >= 0,
    twitter:
      user.providerData.findIndex(
        // @ts-expect-error TS18047
        (el) => el.providerId === firebase.auth.TwitterAuthProvider.PROVIDER_ID
      ) >= 0,
    apple:
      user.providerData.findIndex(
        // @ts-expect-error TS18047
        (el) => el.providerId === APPLE_PROVIDER_ID
      ) >= 0,
  };
};
