import { EventDateTimeInfo } from "components/account/ticket/EventDateTimeInfo";
import React from "react";
import { useSelector } from "react-redux";
import { Store } from "store";
import { changeStyleWithHosting, isPermanentGoods } from "utility/hosting";
import styled from "@emotion/styled";
import { XShareButton } from "ui/XShareButton";

const Container = styled.div`
  margin-bottom: 40px;
  @media screen and (min-width: 768px) {
    display: flex;
    padding-bottom: 40px;
    border-bottom: 1px solid #dbdbdb;
  }
  .eventThumbnail {
    margin-bottom: 15px;
    @media screen and (min-width: 768px) {
      width: 400px;
      margin-right: 45px;
      margin-bottom: 0;
    }
    img {
      width: 100%;
    }
  }
  .eventInfo {
    @media screen and (min-width: 768px) {
      width: 520px;
    }
    .title {
      margin-bottom: 20px;
      color: #09d3db;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.3em;
      @media screen and (min-width: 768px) {
        font-size: 25px;
      }
    }
    .artist {
      margin-bottom: 30px;
      font-size: 15px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
        font-size: 18px;
      }
    }
  }
`;

export const DigitalTicketHeader: React.FC = () => {
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const _user = useSelector((state: Store) => state.auth.user);
  const hostingCommonSettingsMap = changeStyleWithHosting().commonSettings;
  const hostingAccountTicketPageMap =
    changeStyleWithHosting().pageSettings.accountTicketPage;
  if (!displayEvent) {
    return null;
  }

  return (
    <Container>
      {hostingCommonSettingsMap.isDisplayEventDate &&
      hostingCommonSettingsMap.isDisplayEventTime &&
      hostingAccountTicketPageMap.isDisplayEventTitle &&
      hostingAccountTicketPageMap.isDisplayArtist &&
      hostingAccountTicketPageMap.isDisplayShareButton ? (
        <React.Fragment>
          <div className="eventThumbnail">
            <img src={displayEvent.thumbnail} alt={displayEvent.eventTitle} />
          </div>
          <div className="eventInfo">
            {!isPermanentGoods(displayEvent.belongedHostings) && (
              <EventDateTimeInfo
                parts={displayEvent.parts}
                isLongEvent={displayEvent.isLongEvent}
                eventOpenDateFull={displayEvent.eventOpenDateFull}
                eventEndDateFull={displayEvent.eventEndDateFull}
              />
            )}

            {hostingAccountTicketPageMap.isDisplayEventTitle && (
              <p className="title" style={{ whiteSpace: "pre-line" }}>
                {displayEvent.eventTitle}
              </p>
            )}

            {hostingAccountTicketPageMap.isDisplayArtist && (
              <p className="artist">{displayEvent.artists}</p>
            )}

            {hostingAccountTicketPageMap.isDisplayShareButton &&
              !displayEvent.isHide && (
                <XShareButton
                  eventId={displayEvent.eventId}
                  eventTitle={displayEvent.eventTitle}
                  hashTag={displayEvent.twitterHashTag}
                />
              )}
          </div>
        </React.Fragment>
      ) : (
        <img
          src={displayEvent.thumbnail}
          alt={displayEvent.eventTitle}
          width="100%"
        />
      )}
    </Container>
  );
};
