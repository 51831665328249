import styled from "@emotion/styled";

export const ButtonList = styled.div`
  /* TODO: a をリンクに使わずすべて LinkButton に置き換えるのが望ましい */
  > a {
    display: block;
  }
  /* TODO: ButtonList の Children である Button はすべて同じ要素を扱いセレクタ指定するのが望ましい */
  > * {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

ButtonList.displayName = "ButtonList";

export const WidthDefinedButtonList = styled(ButtonList)`
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    width: 500px;
  }
`;

WidthDefinedButtonList.displayName = "WidthDefinedButtonList";
