import { LiveContextType, LiveDataPath } from "../modules/live";

// @ts-expect-error TS7006
export const getLiveContextType = (contextId): LiveContextType => {
  return !contextId ? null : contextId.slice(0, 3);
};

export const getLiveContextWord = (contextId: string) => {
  switch (getLiveContextType(contextId)) {
    case "qid":
      return "クイズ";
    case "cid":
      return "タップ";
    default:
      return "";
  }
};

export const getLiveDataPath = (
  contextIdType: LiveContextType
): LiveDataPath => {
  switch (contextIdType) {
    case "cid":
      return "clicks";
    case "qid":
      return "surveys";
    default:
      // @ts-expect-error TS2322
      return null;
  }
};
