import { useQuery } from "../utility/useQuery";

type TenantId = string | undefined;

const keyTenantId = "tenantId";

/**
 * tenantIdクエリパラメータを取得する
 *
 * @example
 * // tenantIdの取得
 * const [tenantId] = useTenantIdQuery()
 * return <p>{`tenantId: ${tenantId}`}</p>
 */
export const useTenantIdQuery = (): TenantId => {
  const query = useQuery();
  const tenantId = query.get(keyTenantId); // MEMO: string | null
  return tenantId ?? undefined;
};

/**
 * tenantIdクエリパラメータ付きのパスを生成する
 */
type PathWithTenantIdQuery = (
  path: string,
  tenantId: string
) => `${string}?tenantId=${string}`;

export const pathWithTenantIdQuery: PathWithTenantIdQuery = (path, tenantId) =>
  `${path}?${keyTenantId}=${tenantId}`;
