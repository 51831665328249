/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { FC } from "react";
import { AccountAddressRequiredLabel } from "./AccountAddressRequiredLabel";

export const AccountAddressFormItemLayout: FC<{
  children: JSX.Element;
  heading: string;
  isRequired: boolean;
}> = ({ children, heading, isRequired }) => {
  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 8px;
        `}
      >
        <h2
          css={css`
            font-size: 14px;
          `}
        >
          {heading}
        </h2>

        {isRequired && <AccountAddressRequiredLabel />}
      </div>

      {children}
    </div>
  );
};
