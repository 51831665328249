import { cartActions } from "modules/cart";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "store";
import type { ProductData } from "@spwn/types/firebase/firestore";
import { extractAddressRequiredDomains } from "utility/cart";
import { checkSendingFee, calcDomainShippingFee } from "utility/purchase";
import { EventInfo, createSet } from "utility";
import { usePrevious } from "./Reference";

/**
 * calc and return shipping fee from my cart and products(use for live commerce)
 * @param myCart
 */
export const useShippingFee = (
  // @ts-expect-error TS2322
  liveCommerce: { eventInfo: EventInfo; products: ProductData[] } = null
) => {
  const [isCalculatingShippingFee, setIsCalculatingShippingFee] =
    useState<boolean>(false);
  const [shippingFee, setShippingFee] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);

  const user = useSelector((state: Store) => state.auth.user);
  const myCart = useSelector((state: Store) => state.cart.myCart);
  const userRelatedEventMap = useSelector(
    (state: Store) => state.event.userRelatedEventMap
  );

  const prevLiveCommerceProducts = usePrevious(liveCommerce.products);
  const prevMyCart = usePrevious(myCart);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cartActions.fetchMyCart.started());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // calc shipping fee when changed products
  useEffect(() => {
    console.info(
      prevLiveCommerceProducts,
      liveCommerce.products,
      prevLiveCommerceProducts === liveCommerce.products
    );
    // コンソールで吐き出すと同じでもなぜかfalseになる...
    // これのせいで無駄に1回呼ばれてしまう
    console.info(prevMyCart, myCart, prevMyCart === myCart);

    async function calcShippingFee() {
      try {
        // extract shipping required domains
        const domains = extractAddressRequiredDomains(
          myCart,
          userRelatedEventMap
        );
        // if live commerce products are present, calc shipping fee including that
        if (liveCommerce) {
          const shippingRequiredProducts = liveCommerce.products.filter(
            (el) => el.isAddressRequired
          );
          if (shippingRequiredProducts.length > 0) {
            const [liveEventDomain] = liveCommerce.eventInfo.belongedHostings;
            // @ts-expect-error TS2345
            domains.push(liveEventDomain);
          }
        }
        const uniqueDomains = createSet(domains);
        // check shipping fee
        if (uniqueDomains.length > 0) {
          const response = await checkSendingFee(user);
          if (!response) {
            throw new Error("error address");
          }
          const shippingFee = await calcDomainShippingFee(
            uniqueDomains,
            response.destinationType
          );
          // @ts-expect-error TS2345
          setShippingFee(shippingFee);
        }
      } catch (error) {
        console.error(error);
        setIsError(true);
      } finally {
        setIsCalculatingShippingFee(false);
      }
    }

    if (
      // skip if product is nothing
      (myCart.length === 0 &&
        liveCommerce &&
        liveCommerce.products.length === 0) ||
      // skip if products are same with previous products
      (prevLiveCommerceProducts === liveCommerce.products &&
        prevMyCart === myCart)
    ) {
      console.log("skip to fetch shipping fee");
    } else {
      console.log("start to calc shipping fee");
      calcShippingFee();
      setIsCalculatingShippingFee(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveCommerce, myCart]);

  return { isCalculatingShippingFee, shippingFee, isError };
};

/**
 * calc and return shipping fee from live commerce products
 * @param myCart
 */
export const useLiveCommerceShippingFee = (
  eventInfo: EventInfo,
  products: ProductData[]
) => {
  const [isCalculatingShippingFee, setIsCalculatingShippingFee] =
    useState<boolean>(false);
  const [shippingFee, setShippingFee] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);

  const user = useSelector((state: Store) => state.auth.user);
  const isAddressRegistered = useSelector(
    (state: Store) => state.auth.isAddressRegistered
  );

  // const cartItemMap = useSelector((state: Store) => state.streaming.liveCommerce.cartItemMap)
  // const products = convertMapToValues(cartItemMap)
  const prevProducts = usePrevious(products);

  // calc shipping fee when changed products
  useEffect(() => {
    console.info(prevProducts, products, prevProducts === products);

    if (!isAddressRegistered) return;

    async function calcShippingFee() {
      try {
        // extract shipping required domains
        const shippingRequiredProducts = products.filter(
          (el) => el.isAddressRequired
        );
        // check shipping fee
        if (shippingRequiredProducts.length > 0) {
          const response = await checkSendingFee(user);
          if (!response) {
            throw new Error("error address");
          }
          const domains = [eventInfo.belongedHostings[0]];
          const shippingFee = await calcDomainShippingFee(
            // @ts-expect-error TS2345
            domains,
            response.destinationType
          );
          // @ts-expect-error TS2345
          setShippingFee(shippingFee);
        } else {
          setShippingFee(0);
        }
      } catch (error) {
        console.error(error);
        setIsError(true);
      } finally {
        setIsCalculatingShippingFee(false);
      }
    }

    if (
      // skip if product is nothing
      products.length === 0 ||
      // skip if products are same with previous products
      prevProducts === products ||
      (prevProducts && products && prevProducts.length === products.length)
    ) {
      console.log("skip to fetch shipping fee");
    } else {
      console.log("start to calc shipping fee");
      setIsCalculatingShippingFee(true);
      calcShippingFee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return { isCalculatingShippingFee, shippingFee, isError };
};
