import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../store";
import { FullScreenDialog } from "components/common/Modal";
import { DialogStyles } from "../components/Styled";
import { modalActions } from "../modules/modal";
import { replaceBr } from "../utility";
import { GrayTextLink } from "../components/common/Modal";
import { useI18n } from "hooks/i18n/i18n";
import {
  PopupWindow,
  PopupWindowButtonList,
  LinkButton,
  Popup,
} from "../styles";

const Notice: React.FC = () => {
  const isOpen = useSelector((state: Store) => state.modal.isOpenNotice);
  const noticeData = useSelector((state: Store) => state.modal.noticeModalData);
  const { t } = useI18n();

  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(modalActions.toggleNotice({}));
  };

  const renderSwither = () => {
    switch (noticeData.modalType) {
      case "flowerStandImgRegulation":
        return flowerStandImgRegulation();
      case "flowerStandNameRegulation":
        return flowerStandNameRegulation();
      case "flowerStandProfileImgRegulation":
        return flowerStandProfileImgRegulation();
      default:
        return defaultModal();
    }
  };

  const defaultModal = () => {
    return (
      <Popup>
        <h2>{replaceBr(noticeData.caption) || "Notice"}</h2>
        <p className="popup_message" style={{ whiteSpace: "pre-line" }}>
          {replaceBr(noticeData.msg)}
        </p>
        <GrayTextLink onClick={closeModal} text={t("login.buttons.close")} />
      </Popup>
    );
  };

  if (!isOpen) {
    return null;
  }

  const flowerStandImgRegulation = () => {
    return (
      <PopupWindow>
        <div className="popup_window_layout">
          <h2>{t("flowerstand.notice.header1")}</h2>
          <ul className="text_block">
            <li>{t("flowerstand.notice.format")}</li>
            <li>{t("flowerstand.notice.background")}</li>
            <li>{t("flowerstand.notice.resolution")}</li>
            <li>{t("flowerstand.notice.fileSize")}</li>
          </ul>

          <h3>{t("flowerstand.notice.flowerStandImageRule")}</h3>
          <ul className="text_block">
            <li>{t("flowerstand.notice.prohibitImage")}</li>
            <li>
              <ul className="list_in_list">
                <li>{t("flowerstand.notice.prohabit1")}</li>
                <li>{t("flowerstand.notice.prohabit2")}</li>
                <li>{t("flowerstand.notice.prohabit3")}</li>
                <li>{t("flowerstand.notice.prohabit4")}</li>
              </ul>
            </li>
          </ul>
          <PopupWindowButtonList>
            <LinkButton onClick={closeModal}>
              {t("flowerstand.notice.close")}
            </LinkButton>
          </PopupWindowButtonList>
        </div>
      </PopupWindow>
    );
  };

  const flowerStandNameRegulation = () => {
    return (
      <PopupWindow>
        <div className="popup_window_layout">
          <h2>{t("flowerstand.notice.header2")}</h2>
          <ul className="text_block">
            <li>{t("flowerstand.notice.prohabitName")}</li>
            <li>
              <ul className="list_in_list">
                <li>{t("flowerstand.notice.prohabit5")}</li>
                <li>{t("flowerstand.notice.prohabit6")}</li>
                <li>{t("flowerstand.notice.prohabit3")}</li>
                <li>{t("flowerstand.notice.prohabit4")}</li>
              </ul>
            </li>
          </ul>
          <PopupWindowButtonList>
            <LinkButton onClick={closeModal}>
              {t("flowerstand.notice.close")}
            </LinkButton>
          </PopupWindowButtonList>
        </div>
      </PopupWindow>
    );
  };

  const flowerStandProfileImgRegulation = () => {
    return (
      <PopupWindow>
        <div className="popup_window_layout">
          <h2>{t("flowerstand.notice.header1")}</h2>
          <ul className="text_block">
            <li>{t("flowerstand.notice.imgRules1")}</li>
            <li>{t("flowerstand.notice.imgRules2")}</li>
            <li>{t("flowerstand.notice.imgRules3")}</li>
            <li>{t("flowerstand.notice.imgRules4")}</li>
            <li>{t("flowerstand.notice.imgRules5")}</li>
          </ul>

          <h3>{t("flowerstand.notice.header3")}</h3>
          <ul className="text_block">
            <li>{t("flowerstand.notice.prohibitImage")}</li>
            <li>
              <ul className="list_in_list">
                <li>{t("flowerstand.notice.prohabit1")}</li>
                <li>{t("flowerstand.notice.prohabit2")}</li>
                <li>{t("flowerstand.notice.prohabit3")}</li>
                <li>{t("flowerstand.notice.prohabit4")}</li>
              </ul>
            </li>
          </ul>
          <PopupWindowButtonList>
            <LinkButton onClick={closeModal}>
              {t("flowerstand.notice.close")}
            </LinkButton>
          </PopupWindowButtonList>
        </div>
      </PopupWindow>
    );
  };

  return (
    <FullScreenDialog
      style={DialogStyles}
      open={isOpen}
      onBackdropClick={closeModal}
    >
      {renderSwither()}
    </FullScreenDialog>
  );
};

export default Notice;
