/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import CloseIcon from "@material-ui/icons/Close";

import { useI18n } from "hooks/i18n/i18n";
import { useGetEventList } from "hooks/event/useGetEventList";
import { cartActions } from "modules/cart";
import { EventSaleStatus } from "modules/event";
import { EventCard } from "features/EventCard";
import { RemovedCartItemsMessage } from "features/RemovedCartItemsMessage";

export const EmptyCart: React.FC = () => {
  const { data: featureEvents } = useGetEventList({
    type: "openRandom",
    limit: 2,
  });

  const { t } = useI18n();
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  const changeRoute = useCallback(
    // @ts-expect-error TS2322
    (link, saleStatus: EventSaleStatus = null) => {
      if (saleStatus && saleStatus !== "ON_SALE") {
        return;
      }
      history.push(link);
      dispatch(cartActions.toggleMyCart({}));
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div css={classes.root}>
      <div
        css={classes.closeIcon}
        onClick={() => {
          dispatch(cartActions.toggleMyCart({}));
        }}
      >
        <CloseIcon />
      </div>

      <RemovedCartItemsMessage />

      <p css={classes.caution}>{t("cart.modal.noProductModal")}</p>

      <div css={classes.other}>
        <p className={"heading"}>{t("cart.modal.otherEventModal")}</p>

        <div className={"suggest"}>
          {featureEvents &&
            featureEvents.length > 0 &&
            featureEvents.map((event, index) => {
              return (
                <React.Fragment key={index}>
                  <EventCard
                    event={event}
                    action={() => changeRoute(`/events/${event._id}`)}
                  />
                </React.Fragment>
              );
            })}
        </div>

        <div css={classes.button}>
          <button
            onClick={() => {
              changeRoute("/events");
            }}
          >
            {t("cart.button.otherEventButton")}
          </button>

          <button
            className="cancel"
            onClick={() => dispatch(cartActions.toggleMyCart({}))}
          >
            {t("cart.button.closeButton")}
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = () => ({
  root: css`
    position: relative;
    width: 90%;
    max-width: 980px;
    padding: 40px 0;
    margin: 0 auto;
  `,
  closeIcon: css`
    margin-bottom: 16px;
    text-align: right;
    cursor: pointer;
    svg {
      @media screen and (min-width: 768px) {
        width: 32px;
        height: 32px;
      }
    }
  `,
  caution: css`
    padding: 30px 0px 70px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 1.5em;
    @media screen and (min-width: 768px) {
      font-size: 24px;
      padding: 200px 0px;
    }
  `,
  other: css`
    .heading {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
        font-size: 20px;
      }
    }
    .suggest {
      display: grid;
      grid-template-columns: calc(50% - 8px) calc(50% - 8px);
      gap: 16px;
      @media screen and (min-width: 768px) {
        gap: 32px;
      }
    }
  `,
  button: css`
    margin-top: 40px;
    button {
      display: block;
      width: 100%;
      max-width: 400px;
      padding: 8px;
      margin: 0 auto;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      letter-spacing: 1px;
      background-color: #00c2ad;
      border: 2px solid #00c2ad;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
      &.cancel {
        color: #00c2ad;
        background-color: #fff;
      }
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
  `,
});
