type JudgeCrewDomainType =
  | {
      isCrewDomain: true;
      crewBrandSlug: string;
    }
  | {
      isCrewDomain: false;
    };
export const judgeCrewDomain = (): JudgeCrewDomainType => {
  const { searchParams } = new URL(window.location.href);
  const crewBrandSlug = searchParams.get("crewBrandSlug");

  return crewBrandSlug
    ? {
        isCrewDomain: true,
        crewBrandSlug,
      }
    : {
        isCrewDomain: false,
      };
};
