/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState } from "react";
import { BreadcrumbArea } from "components/common/Link";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "modules/modal";
import { Store } from "store";
import { Button } from "components/atoms/button/Button";
import { useI18n } from "hooks/i18n/i18n";
import { useVoucherTOFES } from "../../hooks/voucherTOFES/VoucherTOFES";
import { Alert, AlertTitle } from "@material-ui/lab";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { useHistory } from "react-router";

export const VoucherTOFES: React.FC = () => {
  const design = styles();
  const { t } = useI18n();
  return (
    <div id="contents" css={design.root}>
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, t("common.routes.voucherCode")],
        ]}
      />
      <div css={design.main}>
        <VoucherTOFESMain />
      </div>
    </div>
  );
};

const VoucherTOFESMain: React.FC = () => {
  const design = styles();
  // @ts-expect-error TS2345
  const [voucherCode, setVoucherCode] = useState<string>(null);
  const { loading, error, successAssign, assignVoucher } = useVoucherTOFES();
  const history = useHistory();

  const user = useSelector((state: Store) => state.auth.user);
  const dispatch = useDispatch();

  const { t } = useI18n();

  // @ts-expect-error TS7006
  const handleChange = (e) => {
    setVoucherCode(e.currentTarget.value);
  };

  const handleSubmit = () => {
    assignVoucher(voucherCode);
  };

  if (loading) {
    return (
      <div id="contents-layout">
        <LoaderLayout />
      </div>
    );
  }

  if (!user.uid) {
    return (
      <div css={design.inner}>
        <div css={design.caution}>
          <div>
            <p>バウチャーコードの入力は、ログインを行わないと出来ません。</p>
            <p>ログインを行ってから、入力をお願いいたします。</p>
          </div>

          <div css={design.buttonArea}>
            <Button
              text="ログイン"
              onClick={() => dispatch(modalActions.toggleLoginMethod())}
            />
          </div>
        </div>
      </div>
    );
  }

  if (successAssign) {
    return (
      <div css={design.inner}>
        <h2 css={design.title}>{"チケットの付与が完了しました"}</h2>
        <div css={design.caution}>
          <div>
            <p>
              配信は公演開始時間より、「TUBEOUT!FES -2021
              SUMMER-」のイベントページからご覧いただけます。
            </p>
          </div>
          <div css={design.buttonArea}>
            <Button
              text="イベントページへ"
              onClick={() => history.push(`/events/210829-TOFES`)}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div css={design.inner}>
      <h2 css={design.title}>{t("purchase.voucher.inputMsg")}</h2>
      {error && (
        <Alert severity="error" css={design.alert}>
          <AlertTitle>{t("purchase.voucher.invalidVoucher")}</AlertTitle>
          コードが間違っているか有効期限切れ/使用済みのコードです
        </Alert>
      )}
      <div css={design.form}>
        <div css={design.caution}>
          <div>
            <p>
              「TUBEOUT!FES -2021
              SUMMER-」のバウチャーコードを入力してください。
            </p>
          </div>
        </div>
        <div css={design.fromItem}>
          <input
            type="text"
            placeholder="XXXXXXXX"
            value={voucherCode}
            onChange={handleChange}
          />
        </div>
        <div css={design.buttonArea}>
          <Button text="登録" onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      min-height: calc(100vh - 256px);
      margin: 0 auto;
      background-color: #ffffff;
    `,
    main: css`
      background-color: #ffffff;
    `,
    inner: css`
      max-width: 960px;
      width: 100%;
      margin: 0 auto;
      padding: 45px 20px;
      box-sizing: border-box;
    `,
    title: css`
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    `,
    caution: css`
      text-align: center;
      div {
        margin-bottom: 21px;
      }
      p {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.8em;
      }
    `,
    alert: css`
      width: 100%;
      max-width: 570px;
      margin: 0 auto;
    `,
    form: css`
      margin-top: 40px;
    `,
    fromItem: css`
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 360px;
      }
      input {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 16px;
        font-size: 16px;
        text-align: center;
        border: 1px solid #eeeeee;
      }
    `,
    buttonArea: css`
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 360px;
      }
    `,
  };
};
