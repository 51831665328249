/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import type { Place, Event } from "@spwn/types/firebase/firestore";
import type { TicketData } from "@spwn/types/firebase/database";

import BorderLoader from "components/atoms/loader/BorderLoader";
import { FullScreenDialog, ModalHeader } from "components/common/Modal";
import EventTicketSelectPlace from "components/voucher/EventTicketSelectPlace";
import EventTicketSelectNum from "components/voucher/EventTicketSelectNum";
import { useGetPlaceList } from "hooks/event/useGetPlaceList";
import { ticketActions, ticketState } from "modules/ticket";
import { Store } from "store";
import {
  createSet,
  createListSetOfField,
  convertMapToValuesWithId,
  convertPlacesArrayToDict,
} from "utility";
import { unique } from "utility/array";

export interface SelectedTicketMap {
  [ticketId: string]: {
    name: string;
    num: number;
  };
}

interface Props {
  index: number;
  event: Event;
  tickets: ticketState["eventTicketData"]["ticketData"];
  open: boolean;
  onCloseModal: () => void;
  onSendTicket: (
    index: number,
    event: Event,
    selectedTicketMap: SelectedTicketMap
  ) => void;
}

const ModalVoucherTicket: React.FC<Props> = ({
  index,
  event,
  open,
  tickets,
  onCloseModal,
  onSendTicket,
}) => {
  const dispatch = useDispatch();
  const classesTicketListDesign = ticketListDesign();

  const sortTickets = convertMapToValuesWithId(tickets);

  // Place情報を取得
  const placeIds = unique(sortTickets.map((el) => el.place));
  const { data: places } = useGetPlaceList(placeIds);
  const placeMap = places ? convertPlacesArrayToDict(places) : {};

  const [selectedPlaceCode, setSelectedPlaceCode] = useState("");
  const [selectedTicketMap, setSelectedTicket] = useState<SelectedTicketMap>(
    {}
  );
  const [width, setWidth] = useState(window.innerWidth);

  const ticketConfig = useSelector(
    (state: Store) => state.ticket.config.ticketConfig
  );

  const modalStyle = {
    position: "absolute",
    top: width >= 768 ? 65 : 50,
    margin: "0 auto",
    left: 0,
    right: 0,
    maxWidth: width >= 768 ? "calc(100% - 64px)" : "calc(100% - 40px)",
    width: "100%",
    height: `calc(100% - ${width >= 768 ? 65 : 50}px)`,
    backgroundColor: "#ffffff",
    borderRadius: 0,
  };

  const updateWidth = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    if (!ticketConfig) {
      dispatch(
        ticketActions.fetchProductConfig.started({ eventId: event._id })
      );
    }
  }, [dispatch, ticketConfig, event._id]);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [updateWidth]);

  // sort place
  const checkPlaceCodes = createSet(sortTickets.map((el) => el.place));
  const filterPlaces: Place[] = [];
  const isSelectPlaceLaterMode =
    checkPlaceCodes.length === 1 &&
    !!checkPlaceCodes[0] &&
    !!placeMap[checkPlaceCodes[0]]?.selectablePlaces;
  const placeCodes =
    isSelectPlaceLaterMode && !!checkPlaceCodes[0]
      ? placeMap[checkPlaceCodes[0]]?.selectablePlaces ?? []
      : checkPlaceCodes;
  for (const placeCode of placeCodes) {
    const place = places?.find((el) => el.code === placeCode);
    if (place) {
      filterPlaces.push(place);
    }
  }

  let displayTickets: TicketData[];
  displayTickets = isSelectPlaceLaterMode
    ? sortTickets
    : sortTickets.filter((el) => el.place === selectedPlaceCode);

  if (ticketConfig && ticketConfig.hasSubClass) {
    displayTickets = createListSetOfField<TicketData>(
      displayTickets,
      "classGroupId"
    );
  }

  const handleChangePlace = (placeCode: string) => {
    setSelectedPlaceCode(placeCode);
  };

  const handleSelectorChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    ticket: TicketData
  ) => {
    const select = e.currentTarget;
    const option = select.options[select.selectedIndex];
    if (!option) {
      return;
    }
    const ticketId = option.id;
    const num = parseInt(option.value, 10);

    if (selectedTicketMap[ticketId] && num === 0) {
      delete selectedTicketMap[ticketId];
      setSelectedTicket(selectedTicketMap);
    } else {
      const selectedTicket = {
        [ticketId]: {
          name: ticket.name,
          num,
        },
      };
      setSelectedTicket({ ...selectedTicketMap, ...selectedTicket });
    }
  };

  return (
    <FullScreenDialog
      open={open}
      style={modalStyle}
      onBackdropClick={onCloseModal}
    >
      <ModalHeader title="チケットを追加する" onClick={onCloseModal} />
      <div css={classesTicketListDesign.root}>
        <div css={classesTicketListDesign.content}>
          {ticketConfig ? (
            <React.Fragment>
              <EventTicketSelectPlace
                places={filterPlaces}
                selectedPlaceCode={selectedPlaceCode}
                handleChangePlace={handleChangePlace}
              />
              <EventTicketSelectNum
                displayTickets={displayTickets}
                selectedPlaceCode={selectedPlaceCode}
                placeMap={placeMap}
                ticketConfig={ticketConfig}
                event={event}
                handleSelectorChange={handleSelectorChange}
              />
              <div css={classesTicketListDesign.buttonList}>
                <button
                  css={classesTicketListDesign.submitBtn}
                  type="submit"
                  disabled={Object.keys(selectedTicketMap).length === 0}
                  onClick={() => onSendTicket(index, event, selectedTicketMap)}
                >
                  OK
                </button>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p css={classesTicketListDesign.caution}>
                チケットの情報を取得しています。
              </p>
              <BorderLoader />
            </React.Fragment>
          )}
        </div>
      </div>
    </FullScreenDialog>
  );
};

const ticketListDesign = () => {
  return {
    root: css`
      border-top: 1px solid #e0e0e0;
    `,
    content: css`
      width: 100%;
      margin: 0 auto;
      padding: 20px 6%;
      @media screen and (min-width: 768px) {
        max-width: 700px;
        padding: 36px 20px 60px;
      }
    `,
    buttonList: css`
      width: 224px;
      margin: 80px auto 0;
    `,
    submitBtn: css`
      display: block;
      width: 100%;
      padding: 11px 10px;
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      background-color: #25c2ae;
      border: none;
      cursor: pointer;
      &:disabled {
        background-color: #cccccc;
        cursor: default;
      }
    `,
    caution: css`
      margin-bottom: 24px;
      text-align: center;
    `,
  };
};

export default ModalVoucherTicket;
