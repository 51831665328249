/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { TIMETABLE_CELL_HEIGHT } from "../../../../containers/event/eventDetail/EventDetailTimetable";
import ScheduleIcon from "@material-ui/icons/Schedule";

/**
 * 時間軸のグリッド線を作成します。
 * EventTimeTableLayoutContentsGridLineと同じ値を渡す
 * @param {number} startAt イベントの開始時間です。
 * @param {number} betweenAt イベントの開催期間です。
 */
interface Props {
  startAt: number;
  betweenAt: number;
}
export const EventTimetableLayoutTimeAxis: React.FC<Props> = (props) => {
  let HOUR = 0;
  const classes = styles();

  return (
    <div css={classes.root}>
      <div className={"icon"}>
        <ScheduleIcon />
      </div>

      <div className="hour">
        <div></div>
      </div>
      {Array(props.betweenAt)
        .fill("")
        .map((value, index) => {
          HOUR = props.startAt + index;
          return (
            <div className="hour" key={index}>
              <p>{HOUR}:00</p>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          );
        })}
      <div className="hour">
        <p>{HOUR + 1}:00</p>
        <div></div>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      position: relative;
      background-color: #003c35;
      border-right: 1px dashed #00c2ad;
      .icon {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        height: 115px;
        color: #fff;
        background-color: #003c35;
        border-bottom: 2px solid #00c2ad;
        z-index: 5;
        svg {
          position: absolute;
          top: 45px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .hour {
        position: relative;
        &:not(:last-child) {
          border-bottom: 1px solid #a1a1a1;
        }
        p {
          position: absolute;
          top: -11px;
          width: 100%;
          font-family: "DIN Alternate", sans-serif;
          color: #fff;
          font-size: 20px;
          text-align: center;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 2px;
        }
        div {
          height: ${TIMETABLE_CELL_HEIGHT}px;
          &:not(:last-child) {
            border-bottom: 1px solid #a1a1a1;
          }
        }
      }
    `,
  };
};
