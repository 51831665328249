/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Dispatch } from "redux";
import { Store } from "../../store";
import { modalActions } from "../../modules/modal";

import firebase from "firebase/app";
import "firebase/firestore";

import { newsActions } from "../../modules/news";
import { firestoreActions } from "../../modules/firestore";
import { changeStyleWithHosting, isPortalDomain } from "utility/hosting";
import { PortalTopFetcher } from "features/PortalTop";
import { WithTranslation, withTranslation } from "react-i18next";
import { TFunction } from "i18next";

import type { TopInfo, PickUpNewsData } from "@spwn/types/firebase/firestore";
import { BrandTopFetcher } from "features/BrandTop";

interface Props extends WithTranslation {
  t: TFunction;
  isAdmin: boolean;
  isLiveEventMaintenance: boolean;
  maintenanceLiveEventId: string; // adminでメンテナンスモードであれば値が入る
  number: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: any; // !!
  topInfo: TopInfo;
  toggleLoginMethod: () => void;
  watchTopInfo: () => void;
  closeTopInfoChannel: () => void;
  changeRoute: (path: string) => void;
}
interface States {
  pickUpNewsData: PickUpNewsData;
  isUpcomingEvents: boolean;
}

class Top extends Component<Props, States> {
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    this.state = {
      pickUpNewsData: {
        msg: "",
        isActive: false,
        // @ts-expect-error TS2322
        open: null,
        // @ts-expect-error TS2322
        close: null,
      },
      isUpcomingEvents: false,
    };
    this.redirectHostingRootPage();
    this.fetchPickUpMsg();
  }

  componentDidMount(): void {
    if (
      !!this.props.location.state &&
      this.props.location.state.openLoginModal
    ) {
      // AppのPrivateRouteコンポーネントでリダイレクト後にpopupが出るようにしている
      this.props.toggleLoginMethod();
    }
    this.props.watchTopInfo();
  }

  componentWillUnmount() {
    this.props.closeTopInfoChannel();
  }

  render() {
    if (isPortalDomain()) {
      return <PortalTopFetcher />;
    } else {
      return (
        <BrandTopFetcher
          topInfo={this.props.topInfo}
          isLiveEventMaintenance={this.props.isLiveEventMaintenance}
          maintenanceLiveEventId={this.props.maintenanceLiveEventId}
          isAdmin={this.props.isAdmin}
        />
      );
    }
  }

  redirectHostingRootPage = () => {
    if (changeStyleWithHosting().commonSettings.route.home !== "/") {
      this.props.changeRoute(
        changeStyleWithHosting().commonSettings.route.home
      );
      return null;
    }
  };

  fetchPickUpMsg = async () => {
    const ret = await firebase.firestore().doc("news/pickUpNews/").get();
    const data = ret.data();

    this.setState({ pickUpNewsData: data as PickUpNewsData }); //!!
  };
}

const mapStateToProps = (state: Store) => {
  return {
    number: state.sample.number,
    location: state.router.location,
    topInfo: state.news.topInfo,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    toggleLoginMethod: () => {
      dispatch(modalActions.toggleLoginMethod());
    },
    // @ts-expect-error TS7006
    watchTopInfo: (payload) => {
      dispatch(newsActions.watchTopInfo.started(payload));
    },
    closeTopInfoChannel: () => {
      dispatch(firestoreActions.closeChannel({ channel: "topInfo" }));
    },
    // @ts-expect-error TS7006
    changeRoute: (link) => {
      dispatch(push(link));
    },
  };
};

const Account = withTranslation()(Top);
// @ts-expect-error TS2345
export default connect(mapStateToProps, mapDispatchToProps)(Account);
