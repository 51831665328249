/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect, ComponentProps } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";

import type { ProductData } from "@spwn/types/firebase/firestore";

import { GoodsListItem } from "components/event/goods/GoodsListItem";
import { GoodsListTab } from "components/event/goods/GoodsListTab";
import { GoodsSearchResult } from "components/event/goods/GoodsSearchResult";
import { GoodsVenueSelector } from "components/event/goods/GoodsVenueSelector";
import { GoodsListHeaderCaution } from "components/event/goods/GoodsListHeaderCaution";
import { useGoodsSerachQuery } from "hooks/QueryString";
import { useI18n } from "hooks/i18n/i18n";
import { useGetPlaceList } from "hooks/event/useGetPlaceList";
import { ticketState } from "modules/ticket";
import { eventActions } from "modules/event";
import { Store } from "store";
import { createListSetOfField, getNowTimestamp } from "utility";
import { isShippingItem, SHIPPING_PLACE_CODE } from "utility/ticket";

type TabState = ComponentProps<typeof GoodsListTab>["selectedTab"];

type EventGoodsListProps = {
  goods: ticketState["eventGoods"];
  isAddressRegistered: boolean;
  eventId: string;
  hasShipments: boolean;
};
export const EventGoodsList: React.FC<EventGoodsListProps> = ({
  goods,
  isAddressRegistered,
  eventId,
  hasShipments,
}: EventGoodsListProps) => {
  const {
    havingTicketPlaceCodes,
    selectedPlaceCode,
    venuePlaceCodes,
    defaultPlaceCode,
  } = useSelector((state: Store) => state.event.goodsPageSettings);

  const { data: places } = useGetPlaceList(venuePlaceCodes);

  const isLoggedIn = useSelector((state: Store) =>
    Boolean(state.auth.user.uid)
  );
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);

  const [selectedTab, setSelectedTab] = useState<TabState>(
    selectedPlaceCode === SHIPPING_PLACE_CODE ? "shipping" : "venue"
  );
  const [searchResultsNum, setSearchResultsNum] = useState<number>(0);
  const [displayResult, setDisplayResult] = useState<ProductData[]>([]);

  const dispatch = useDispatch();
  const { search } = useGoodsSerachQuery();
  const classesGoodsList = goodsList();

  const isNoneShipments = goods.filter((el) => isShippingItem(el)).length === 0;
  const isNoneVenue = goods.filter((el) => !isShippingItem(el)).length === 0;

  const { t } = useI18n();

  const setDisplayGoods = () => {
    // 配送商品、会場受け取り、タグでのソートを行う
    const filteredGoodsList = goods.filter((goods) => {
      // filter by place and sale date
      const isDisplay = goods.place === selectedPlaceCode;
      // filter by search query if present
      if (search) {
        return (
          isDisplay && goods.hashtags && goods.hashtags.indexOf(search) !== -1
        );
      }
      return isDisplay;
    });

    // make class remaining map
    const classGoodsRemainingMap: { [cid: string]: number[] } = {};
    filteredGoodsList.forEach((goods) => {
      // @ts-expect-error TS2538
      if (!classGoodsRemainingMap[goods.class])
        // @ts-expect-error TS2538
        classGoodsRemainingMap[goods.class] = [];
      // @ts-expect-error TS2538
      classGoodsRemainingMap[goods.class].push(goods.remaining);
    });

    // make class list
    const classGoodsList = createListSetOfField(filteredGoodsList, "class");

    // sort
    const currentDate = getNowTimestamp();
    const sortSoldGoodsList = classGoodsList
      .filter((goods) => goods.closeDateTime.seconds > currentDate)
      .sort(
        (prev, cur) =>
          // TODO@later 管理ツールで並び替えができるようになったら、priorityだけ見るようにした方がいいかも。
          cur.releaseDateTime.seconds - prev.releaseDateTime.seconds ||
          prev.priority - cur.priority
      );
    const sortCurrentGoodsList = classGoodsList
      .filter((goods) => goods.closeDateTime.seconds <= currentDate)
      .sort(
        (prev, cur) =>
          cur.releaseDateTime.seconds - prev.releaseDateTime.seconds ||
          prev.priority - cur.priority
      );

    const concatGoodsList = sortSoldGoodsList.concat(sortCurrentGoodsList);

    const displayGoodsList = concatGoodsList.map((goods) => {
      // バリエーションがすべて売り切れの場合に売り切れ状態にする
      // @ts-expect-error TS2538
      const isSoldOut = classGoodsRemainingMap[goods.class]
        ? // @ts-expect-error TS2538
          classGoodsRemainingMap[goods.class].every(
            // @ts-expect-error TS7006
            (remaining) => remaining === -1
          )
        : false;
      return {
        ...goods,
        remaining: isSoldOut ? -1 : 1,
      };
    });

    setDisplayResult(displayGoodsList);
    if (search) {
      setSearchResultsNum(displayGoodsList.length);
    } else {
      setSearchResultsNum(0);
    }
  };
  useEffect(setDisplayGoods, [goods, selectedPlaceCode, search]);

  const clearSearch = () => {
    dispatch(push("/events/" + eventId + "/goods/"));
  };

  const setSelectedPlaceCode = (selectedPlaceCode: string) => {
    dispatch(
      eventActions.setGoodsPageSettings({
        selectedPlaceCode,
      })
    );
  };

  const handleSelectPlaceTab = (selectedTab: TabState) => {
    setSelectedTab(selectedTab);
    const selectedPlaceCode =
      selectedTab === "shipping"
        ? SHIPPING_PLACE_CODE
        : havingTicketPlaceCodes.length > 0
        ? havingTicketPlaceCodes[0]
        : defaultPlaceCode;
    if (selectedPlaceCode) {
      setSelectedPlaceCode(selectedPlaceCode);
    }
  };

  const moveDetailPage = (goodsName: string | null = null) => {
    if (!goodsName) {
      return;
    }

    dispatch(
      push("/events/" + eventId + "/goods/" + encodeURIComponent(goodsName))
    );
  };

  const displaySalesState = (
    releaseDateTime: number,
    closeDateTime: number,
    isSoldOut = false
  ) => {
    const datetime = releaseDateTime;
    const FewDaysLaterDatetime = datetime + 7 * 24 * 60 * 60;

    const currentDate = new Date(getNowTimestamp() * 1000);
    const releaseDate = new Date(datetime * 1000);
    const FewDaysLaterDate = new Date(FewDaysLaterDatetime * 1000);
    const closeDate = new Date(closeDateTime * 1000);

    const isNewRelease =
      releaseDate <= currentDate && currentDate <= FewDaysLaterDate;
    const isEndOfSale = closeDate < currentDate;

    let stateColor: string;
    let stateText: string;

    if (isEndOfSale) {
      stateColor = "#ff9d00";
      stateText = "販売期間外";
    } else if (isSoldOut) {
      stateColor = "#da3d50";
      stateText = "SOLD OUT";
    } else if (isNewRelease) {
      stateColor = "#25c2af";
      stateText = "NEW";
    } else {
      stateColor = "inherit";
      stateText = "";
    }

    return (
      <div css={classesGoodsList.goodsItemState(stateColor).goodsItemState}>
        <p>{stateText}</p>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div css={classesGoodsList.root}>
        <GoodsListTab
          selectedTab={selectedTab}
          isNoneShipments={isNoneShipments}
          isNoneVenue={isNoneVenue}
          handleSelectPlaceTab={handleSelectPlaceTab}
        />

        {/* 会場受け取り商品選択時、選択した会場名を表示する */}
        {selectedTab === "venue" && (
          <GoodsVenueSelector
            selectedPlaceCode={selectedPlaceCode}
            venues={venuePlaceCodes.map((el) => {
              const place = places?.find((place) => place.code === el);
              return {
                name: place?.name ?? "",
                placeCode: el,
              };
            })}
            setSelectedPlaceCode={setSelectedPlaceCode}
          />
        )}

        {/* 選択したタグ名の検索結果を表示する */}
        {
          <GoodsSearchResult
            search={search}
            searchResultsNum={searchResultsNum}
            clearSearch={clearSearch}
          />
        }

        <GoodsListHeaderCaution
          isLoggedIn={isLoggedIn}
          isVenueTabSelected={selectedTab === "venue"}
          isVenueTicketRequired={
            displayEvent?.goodsInfo?.isVenueTicketRequired ?? false
          }
          selectedPlaceCode={selectedPlaceCode}
          havingTicketPlaceCodes={havingTicketPlaceCodes}
          hasShipments={hasShipments}
          isAddressRegistered={isAddressRegistered}
          ticketUrl={
            displayEvent.ticketUrl || `/events/${displayEvent.eventId}/ticket` // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
          }
        />

        {/* グッズ一覧を表示する */}
        {displayResult && displayResult.length > 0 ? (
          <div css={classesGoodsList.goodsList}>
            {displayResult.map((item, index) => {
              const { className } = item;
              const releaseDateTime = item.releaseDateTime.seconds;
              const closeDateTime = item.closeDateTime.seconds;
              const hasVariation =
                !!item.subClassName && item.subClassName.length > 0;
              const isSoldOut = item?.remaining === -1;
              return (
                <GoodsListItem
                  key={index}
                  item={item}
                  index={index}
                  hasVariation={hasVariation}
                  moveDetailPage={() => moveDetailPage(className)}
                  displaySalesState={displaySalesState(
                    releaseDateTime,
                    closeDateTime,
                    isSoldOut
                  )}
                />
              );
            })}
          </div>
        ) : (
          <div css={classesGoodsList.notApplicable}>
            <p>{t("goods.search.noResult")}</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const goodsList = () => {
  return {
    root: css`
      width: 100%;
      @media screen and (min-width: 768px) {
        width: calc(100% - 240px);
      }
    `,
    changePlaceName: css`
      width: 90%;
      margin: 0 auto 20px;
      font-size: 14px;
      @media screen and (min-width: 768px) {
        width: 100%;
      }
      span {
        margin-left: 15px;
        color: #25c2af;
      }
    `,
    goodsList: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 100%;
      }
      &::after {
        display: block;
        content: "";
        width: 48%;
        @media screen and (min-width: 768px) {
          width: 32%;
        }
      }
    `,
    // @ts-expect-error TS7006
    goodsItemState: (stateColor) => {
      return {
        goodsItemState: css`
          min-height: 24px;
          margin-bottom: 10px;
          p {
            display: inline-block;
            padding: 4px 8px;
            color: #fff;
            font-size: 12px;
            background-color: ${stateColor};
            @media screen and (min-width: 768px) {
              font-size: 10px;
            }
          }
        `,
      };
    },
    notApplicable: css`
      width: 90%;
      margin: 0 auto;
      p {
        font-size: 14px;
      }
    `,
  };
};
