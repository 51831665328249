export type LanguageType =
  | {
      label: "English";
      value: "en";
    }
  | {
      label: "日本語";
      value: "ja";
    };

export const LANG_LIST: LanguageType[] = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "日本語",
    value: "ja",
  },
];
