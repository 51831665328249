/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { FC } from "react";
import { changeStyleWithHosting } from "utility/hosting";
import { TextLink } from "./TextLink";

/**
 * @description
 * パンくずリストを表示するコンポーネント
 * 既存のコンポーネントと比較すると、配列で渡したものを表示する実装している。
 * @param breadcrumbs パンくずリストの配列
 * @param isDarkModeEnabled ダークモードが有効かどうか
 */
type BreadcrumbNavigationProps = {
  breadcrumbs: {
    label: string;
    link: string | null;
  }[];
  isDarkModeEnabled?: boolean;
};
export const BreadcrumbNavigation: FC<BreadcrumbNavigationProps> = ({
  breadcrumbs,
  isDarkModeEnabled = false,
}) => {
  const classes = styles({ isDarkModeEnabled });

  return (
    <div css={classes.root}>
      <ul css={classes.nav}>
        {breadcrumbs.map((page, index) => {
          return (
            <li key={`${page}_${index}`} css={classes.item}>
              {page.link === null ? (
                page.label
              ) : (
                <TextLink href={page.link}>{page.label}</TextLink>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const styles = ({ isDarkModeEnabled }: { isDarkModeEnabled: boolean }) => {
  const { accentColor } = changeStyleWithHosting().baseStyles;

  return {
    root: css`
      padding: 8px 0;
      background-color: ${isDarkModeEnabled ? "#202020" : "#fff"};
      border-bottom: 1px solid ${isDarkModeEnabled ? "#fff" : "#d2d2d2"};
    `,
    nav: css`
      display: flex;
      width: 90%;
      margin: 0 auto;
      overflow: scroll;
      word-break: keep-all;
      white-space: nowrap;
      scrollbar-width: none;
      -ms-overflow-style: none;
      @media screen and (min-width: 768px) {
        width: 95%;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    `,
    item: css`
      color: ${isDarkModeEnabled ? "#fff" : "#000"};
      font-size: 12px;
      font-weight: bold;
      &:not(:last-child):after {
        content: ">";
        padding: 0 8px;
        color: ${isDarkModeEnabled ? "#fff" : "#000"};
      }
      a {
        color: ${isDarkModeEnabled ? "#fff" : accentColor};
        text-decoration: none;
      }
    `,
  };
};
