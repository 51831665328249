import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./i18n";
import "./designs/style.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store, { history } from "./store";
import { CookiesProvider } from "react-cookie";
import appConfig from "./constants/appConfig";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ErrorBoundary } from "./features/ErrorBoundary";
import { FallbackOnUnexpectedError } from "./features/FallbackOnUnexpectedError";

if (!appConfig.logDebug) {
  // DOM実装の console を置き換える
  const noop = () => {};
  window.console.log = noop;
  window.console.dir = noop;
  window.console.info = noop;
  window.console.warn = noop;
  window.console.debug = noop;
  window.console.error = noop;
  // sentry setting
  Sentry.init({
    /**
     * portalでsentryのログをあまり活用できていない、課金が大きいので一時的に無効にしていた
     * @see https://github.com/balus-co-ltd/spwn/issues/1437
     *
     * しかし、外形監視改善を行うため有効にする
     * @see https://github.com/balus-co-ltd/spwn/issues/2376
     */
    enabled: true,
    dsn: "https://605f6f470ea5488c967f38edf90a78cd@o503713.ingest.sentry.io/5598391",
    environment: process.env.NODE_ENV || "development",
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: process.env.NODE_ENV === "production" ? 0.05 : 1.0, // quota上限に達しないように本番環境では低めの値を設定する
  });
}

// TransactionIDを実装時に再度有効にする
// Sentry.configureScope(scope => {
//   scope.setTag("Transaction ID", Math.random().toString(36).substr(2, 9));
// });

const rootElement = document.getElementById(appConfig.indexRoot);

if (rootElement === null) {
  // don't render
} else {
  ReactDOM.render(
    <ErrorBoundary fallback={<FallbackOnUnexpectedError />}>
      <CookiesProvider>
        <Provider store={store}>
          <App history={history} />
        </Provider>
      </CookiesProvider>
    </ErrorBoundary>,
    rootElement
  );
}

serviceWorker.unregister();
