/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { changeStyleWithHosting } from "utility/hosting";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "store";
import { push } from "connected-react-router";
import { modalActions } from "modules/modal";
import { useI18n } from "hooks/i18n/i18n";
import { selectImgPath } from "utility/event";
import appConfig from "constants/appConfig";
import { LeftBorderHeadline } from "components/atoms/headline/LeftBorderHeadline";

interface Props {
  // @ts-expect-error TS7008
  goodsData;
  // @ts-expect-error TS7008
  eventInfo;
}
const FlowerStandThumbnail: React.FC<Props> = (props) => {
  const hostingBaseStyleSettings = changeStyleWithHosting().baseStyles;

  const user = useSelector((state: Store) => state.auth.user);
  const dispatch = useDispatch();
  const { t } = useI18n();

  const classesCommonStyles = commonStyles({ hostingBaseStyleSettings });
  const classesFlowerStandStyles = flowerStandStyles({
    hostingBaseStyleSettings,
  });

  const flowerStandBannerURL = props.eventInfo.flowerStandBannerURL
    ? props.eventInfo.flowerStandBannerURL
    : props.goodsData.flower_stands_img_path ||
      selectImgPath(
        `${appConfig.eventPageDomain}/assets/images/flowerStand/goods_flowerStand.png`,
        "1280"
      );

  return (
    <div id="flower_stand_area" css={classesFlowerStandStyles.root}>
      <div css={classesCommonStyles.heading}>
        <LeftBorderHeadline
          title={t("event.flower.headline.text")}
          subTitle={t("event.flower.headline.subText")}
          fontColor={"primary"}
        />
      </div>

      <img src={flowerStandBannerURL} alt="Goods" />
      <div
        css={classesFlowerStandStyles.button}
        onClick={
          user.uid
            ? () =>
                dispatch(push(`/events/${props.eventInfo._id}/flower-stand`))
            : () => dispatch(modalActions.toggleLoginMethod())
        }
      >
        {t("event.flower.purchase")}
      </div>
    </div>
  );
};

export default FlowerStandThumbnail;

interface CommonStylesProps {
  // @ts-expect-error TS7008
  hostingBaseStyleSettings;
}
const commonStyles = (_props?: CommonStylesProps) => {
  // const { accentColor } = props.hostingBaseStyleSettings;
  // const headingTextColor = props.hostingBaseStyleSettings.fontColor;

  const heading = css`
    margin-bottom: 40px;
    letter-spacing: 1px;
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      margin-bottom: 60px;
    }
  `;
  return {
    heading,
  };
};

interface FlowerStandStylesProps {
  // @ts-expect-error TS7008
  hostingBaseStyleSettings;
}
const flowerStandStyles = (props?: FlowerStandStylesProps) => {
  // @ts-expect-error TS18048
  const { accentColor } = props.hostingBaseStyleSettings;

  const root = css`
    margin: 0 auto;
    img {
      width: 100%;
    }
  `;
  const button = css`
    display: block;
    padding: 12px 0;
    margin: 40px auto 0;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
    background-color: ${accentColor};
    cursor: pointer;
    @media screen and (min-width: 768px) {
      max-width: 880px;
      width: 100%;
      padding: 15px 0;
      margin: 60px auto 0;
    }
  `;
  return {
    root,
    button,
  };
};
