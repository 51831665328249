import { Result } from "../shared/result";

export type GetRestrictedStreamingKeyRequest = {
  eventId: string;
  videoId: string;
  code: string;
};

export type GetRestrictedStreamingKeyResponse = Result<
  {
    streamingStatus: "beforeLive" | "live" | "beforeVOD" | "vod" | "ended";
    streamingSource: {
      default: StreamingCookie;
      LL?: StreamingCookie;
    };
  },
  {
    status: "notfound" | "unavailable";
  }
>;

export type GetRestrictedStreamingKey = {
  path: typeof getRestrictedStreamingKey["path"];
  request: GetRestrictedStreamingKeyRequest;
  response: GetRestrictedStreamingKeyResponse;
};

export const getRestrictedStreamingKey = {
  path: "getRestrictedStreamingKey",
} as const;

/**
 * 配信 Cookie
 */
type StreamingCookie = {
  cookie: {
    "CloudFront-Key-Pair-Id": string;
    "CloudFront-Policy": string;
    "CloudFront-Signature": string;
  };
  cors: boolean;
  url: string;
  liveOffset?: number;
};
