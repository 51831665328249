import { parseQuery } from "utility";
import { useSelector } from "react-redux";
import { Store } from "store";
import { ParsedUrlQuery } from "querystring";
import type { EventCategory } from "@spwn/types/firebase/firestore";

export const useQueryString = <T extends ParsedUrlQuery>(): T => {
  const location = useSelector((state: Store) => state.router.location);

  const queryObject = parseQuery(location.search) as T;

  return queryObject || ({} as T);
};

type EventCategoryQuery = {
  category: EventCategory;
};
export const useEventCategoryQuery = () => {
  return useQueryString<EventCategoryQuery>();
};

type VoucherCodeQuery = {
  code: string;
};
export const useVoucherCodeQuery = () => {
  return useQueryString<VoucherCodeQuery>();
};

type GoodsSearchQuery = {
  search: string;
};
export const useGoodsSerachQuery = () => {
  return useQueryString<GoodsSearchQuery>();
};
