import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import { useMemo } from "react";
import { createAPIClient } from "api/apiClient";

import { SpwnSchema, StreamSchema } from "./schema";

export const useSpwnApiClient = () => {
  const { currentUser } = firebase.auth();
  const spwnApiClient = useMemo(() => {
    return createAPIClient<SpwnSchema>({
      origin:
        process.env.REACT_APP_FANCLUB_PUBLIC_API_URL ??
        "http://localhost:6002/public/fanclub",
      getAuthorizationHeader: async () => {
        const idToken = await currentUser?.getIdToken();
        if (idToken === undefined) return { Authorization: "" };
        return { Authorization: "Bearer " + idToken };
      },
    });
  }, [currentUser]);
  return spwnApiClient;
};

/**
 * stream moduleのAPIクライアント
 */
export const useStreamApiClient = () => {
  const { currentUser } = firebase.auth();
  const spwnApiClient = useMemo(() => {
    return createAPIClient<StreamSchema>({
      origin:
        process.env.REACT_APP_STREAM_API_URL ??
        "http://localhost:6002/portal/stream/public",
      getAuthorizationHeader: async () => {
        const idToken = await currentUser?.getIdToken();
        if (idToken === undefined) return { Authorization: "" };
        return { Authorization: "Bearer " + idToken };
      },
    });
  }, [currentUser]);
  return spwnApiClient;
};
