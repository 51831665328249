/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import LiveAnimation from "../../components/event/detail/videoList/LiveAnimation";

interface Props {
  actionStatus:
    | "NotAvailableForPurchase" // 販売期間外
    | "NotPurchased" // 販売中 & 未購入  & カートに入っていない
    | "InCart" // 販売中 & 未購入 & カートに入っている
    | "SettlementConfirm" // 決済待ち状態（コンビニ決済・キャリア決済）
    | "Playable" // 購入済み & 配信中
    | "BeforePlay" // 購入済み & 配信前
    | "NotPlayable" // 購入済み & 配信期間外
    | "EndLive"
    | "Loading" // ローディング（カート追加中など）
    | null; // 未購入でvideo単体で販売していない
}
export const StreamingTimetableLabel: React.FC<Props> = (props) => {
  const classes = styles();
  switch (props.actionStatus) {
    case "NotAvailableForPurchase":
    case "NotPurchased":
    case "InCart":
    case "SettlementConfirm":
    case "Loading":
      return (
        <div css={classes.root}>
          <p>未購入</p>
        </div>
      );

    case "Playable":
      return <LiveAnimation />;

    case "BeforePlay":
    case "NotPlayable":
      return (
        <div css={classes.root} className={"purchased"}>
          <p>購入済み</p>
        </div>
      );

    default:
      return null;
  }
};

const styles = () => {
  return {
    root: css`
      position: absolute;
      top: 0;
      left: 0;
      padding: 4px 10px;
      background-color: #00c2ad;
      p {
        color: #fff;
        font-size: 14px;
      }
    `,
  };
};
