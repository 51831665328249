/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import { ContentsLayout } from "../../styles";
import { Link } from "react-router-dom";
import { BreadcrumbNavigation } from "ui/BreadcrumbNavigation";

export const ProvisionOfPersonalInfo: React.FC = () => {
  return (
    <div
      css={css`
        min-height: calc(100vh - 245px);
        background-color: #fff;
      `}
    >
      <BreadcrumbNavigation
        breadcrumbs={[
          { label: "ホーム", link: "/" },
          { label: "アカウント", link: "/account" },
          { label: "個人情報の第三者提供について", link: null },
        ]}
      />

      <ContentsLayout>
        <div
          css={css`
            max-width: 640px;
            margin: 0 auto;
          `}
        >
          <h2
            css={css`
              margin-bottom: 16px;
              font-size: 20px;
              font-weight: bold;
            `}
          >
            個人情報の第三者提供について
          </h2>

          <p
            css={css`
              font-size: 14px;
              line-height: 1.5em;
              a {
                color: #00c2ae;
                text-decoration: underline;
              }
            `}
          >
            <Link to={"/privacy-policy"}>プライバシーポリシー</Link>
            に個人情報の第三者提供の詳細内容が記載されています。
            <br />
            個人情報の第三者提供を停止したい場合は
            <a
              href="https://forms.gle/Tzuh931E8Gh1vV2k8"
              target="_blank"
              rel="noopener noreferrer"
            >
              停止依頼フォーム
            </a>
            からご依頼ください。
          </p>
        </div>
      </ContentsLayout>
    </div>
  );
};
