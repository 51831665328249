/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

type StatusColor = "default" | "primary";

type Props = {
  statusText: string;
  color: StatusColor;
};

export const VideoStatus: React.FC<Props> = (props) => {
  const classes = styles(props.color);
  return (
    <div css={classes.liveStatus}>
      <p>{props.statusText}</p>
    </div>
  );
};

const styles = (color: StatusColor) => {
  const genBgColor = () => {
    switch (color) {
      case "primary":
        return "#00C2AD";
      case "default":
      default:
        return "#696568";
    }
  };

  return {
    liveStatus: css`
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-end;
      padding: 4px 10px;
      background-color: ${genBgColor()};
      p {
        color: #fff;
        font-size: 14px;
      }
    `,
  };
};
