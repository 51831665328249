import React from "react";
import { isSmartPhone, isAndroid } from "../../utility";
import appStoreBadge from "../../designs/images/arApp/badge-app-store.svg";
import googlePlayBadge from "../../designs/images/arApp/badge-google-play.png";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import styled from "@emotion/styled";
import { fontDinMixin } from "../../styles";

const Container = styled.div`
  margin-bottom: 10px;
  > .description {
    p {
      line-height: 1.3em;
      margin-bottom: 10px;
    }
    .heading {
      margin-bottom: 25px;
      display: flex;
      font-size: 18px;
      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
        font-size: 25px;
      }
      span {
        ${fontDinMixin};
        display: block;
        margin-right: 5%;
        font-size: 35px;
        @media screen and (min-width: 768px) {
          margin-right: 20px;
          font-size: 43px;
        }
      }
    }
  }
  > .app_badge_list {
    text-align: left;
    .app_store_badge {
      width: 130px;
      height: 50px;
      margin: 12px 12px 12px 12px;
      vertical-align: middle;
    }
    .google_play_badge {
      width: 165px;
      height: 70px;
      vertical-align: middle;
    }
  }
`;

/**
 * require ar app installation in event ticket page
 */
export const ArAppRequired = (props: { eventId: string }) => {
  const dispatch = useDispatch();

  // @ts-expect-error TS7006
  const openLink = (url) => {
    // before open link, move event page to have user fetch user app info again after install
    dispatch(push(`/events/${props.eventId}`));
    window.open(url);
  };

  const isSP = isSmartPhone();
  const badgeList = [];
  if (isSP) {
    if (isAndroid()) {
      badgeList.push(<GooglePlayBadge onClick={openLink} />);
    } else {
      badgeList.push(<AppStoreBadge onClick={openLink} />);
    }
  } else {
    badgeList.push(<AppStoreBadge onClick={openLink} />);
    badgeList.push(<GooglePlayBadge onClick={openLink} />);
  }
  return (
    <>
      <Container>
        <div className="description">
          <h3 className="heading">
            <span></span>このチケットを購入するには「SPWN
            AR」アプリのログインが必要です
          </h3>
          <p>
            このチケットを購入するには「SPWN
            AR」アプリをダウンロードし、アプリ内でログインをする必要があります。
            <br />
            アプリ内でログインをしたあと、もう一度 SPWN
            サイトから該当のチケットの購入に進んでください。
            <br />
            ※「SPWN
            AR」アプリでログインするアカウントと、チケットを購入するアカウントは同一のものを利用してください。
            ログインするアカウントが異なっていた場合は、チケットを購入することができません。
          </p>
        </div>
        <div className="app_badge_list">
          {badgeList.map((el, i) => {
            return <React.Fragment key={i}>{el}</React.Fragment>;
          })}
        </div>
      </Container>
    </>
  );
};

// @ts-expect-error TS7008
const AppStoreBadge = (props: { onClick }) => {
  return (
    <img
      className="app_store_badge"
      src={appStoreBadge}
      alt="app_store_badge"
      onClick={() =>
        props.onClick("https://apps.apple.com/jp/app/spwn-ar/id1485670336")
      }
    />
  );
};

// @ts-expect-error TS7008
const GooglePlayBadge = (props: { onClick }) => {
  return (
    <img
      className="google_play_badge"
      src={googlePlayBadge}
      alt="google_play_badge"
      onClick={() =>
        props.onClick(
          "https://play.google.com/store/apps/details?id=com.Balus.SpwnAr&hl=ja"
        )
      }
    />
  );
};
