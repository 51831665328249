/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { Redirect } from "react-router";
import { useI18n } from "hooks/i18n/i18n";
import { RedirectState } from "modules/router";
import { EventInfo } from "../../utility";
import { VideoErrorType } from "hooks/video/VideoAuthenticator";

/**
 * エラーによる振る舞いを行う
 * - チケットが未購入だったら、チケット購入が必要というポップアップを出し、イベントページにリダイレクト。
 * - 配信ページが閉場だったら、配信は終了したというポップアップを出し、イベントページにリダイレクト。
 * - 予期しないエラーだったら、エラーポップアップを出し、イベントページにリダイレクト。
 * - TODO: getErrorだったら
 * @returns
 */
// @ts-expect-error TS2322
export const VideoErrorBehavior: React.FC<{
  displayEvent: EventInfo;
  error: VideoErrorType;
}> = (props) => {
  const { t } = useI18n();
  if (props.error === "notStreamPageOpen") {
    const redirectState: RedirectState = {
      noticeCaption: t("streaming.authenticator.end"),
      noticeMsg: t("streaming.authenticator.thank"),
    };
    return (
      <Redirect
        to={{
          pathname: `/events/${props.displayEvent._id}`,
          state: redirectState,
        }}
      />
    );
  }
  if (props.error === "needTicket") {
    const redirectState: RedirectState = {
      noticeCaption: t("streaming.authenticator.needTicket"),
      noticeMsg: t("streaming.authenticator.needTicketCaution"),
    };
    return (
      <Redirect
        to={{
          pathname: `/events/${props.displayEvent._id}`,
          state: redirectState,
        }}
      />
    );
  }
  if (props.error === "unknown") {
    const redirectState: RedirectState = {
      noticeCaption: "予期せぬエラーが発生しました",
      noticeMsg:
        "ログインアカウントをご確認のうえ、時間を置いて再度お試しください。",
    };
    return (
      <Redirect
        to={{
          pathname: `/events/${props.displayEvent._id}`,
          state: redirectState,
        }}
      />
    );
  }
};
