import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import { authActions } from "../../modules/auth";
import { parseQuery } from "../../utility";
import { Redirect } from "react-router";

interface Props {}

interface QueryFromArApp {
  provider: string;
  token: string;
  idToken: string;
  secret: string;
  email: string;
  pass: string;
}

const ARAppLoginRequired: React.FC<Props> = () => {
  const isLoginWithCredential = useSelector(
    (state: Store) => state.auth.isLoginWithCredential
  );
  const location = useSelector((state: Store) => state.router.location);
  const user = useSelector((state: Store) => state.auth.user);

  const dispatch = useDispatch();

  const [isLogin, setIsLogin] = useState(null);

  useEffect(() => {
    // ログインしている状態でaccountへ飛ばすため
    // @ts-expect-error TS2345
    setIsLogin(isLoginWithCredential);
  }, [user, isLoginWithCredential]);

  // ログインできていればtrueまたはfalseが入る
  if (isLogin === true) {
    return <Redirect to={{ pathname: "/account" }} />;
  } else if (isLogin === false) {
    return <Redirect to={{ pathname: "/account" }} />;
  }

  // @ts-expect-error TS2322
  const queryObj: Partial<QueryFromArApp> = parseQuery(location.search);

  if (!queryObj || (queryObj && !queryObj.provider)) {
    return <Redirect to={{ pathname: "/account" }} />;
  }

  dispatch(authActions.loginWithCredential.started(queryObj));

  return <></>;
};

export default ARAppLoginRequired;
