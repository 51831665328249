/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useI18n } from "hooks/i18n/i18n";

import { VIDEO_SKIP_SECONDS } from "constants/streaming";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Replay5Icon from "@material-ui/icons/Replay5";
import Forward5Icon from "@material-ui/icons/Forward5";
import Replay10Icon from "@material-ui/icons/Replay10";
import Forward10Icon from "@material-ui/icons/Forward10";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import InfoIcon from "@material-ui/icons/Info";

import { StreamingType } from "../../modules/streaming";
import { MediaPlayer, PlayerEventType } from "amazon-ivs-player";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  player: any; //!!
  awsPlayer: MediaPlayer;
  streamingType: StreamingType;
  THEOPlayerElementHeight: number;
  isSP: boolean;
  isCancelLowLatencyMessage: boolean;
  hasURL: boolean;
}
export const StreamingController: React.FC<Props> = (props) => {
  const { t } = useI18n();
  const classesStreamingControllerStyles = streamingControllerStyles();

  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [tapCount, setTapCount] = useState<number>(0);
  const [overlayControllerHiddenTimer, setOverlayControllerHiddenTimer] =
    // @ts-expect-error TS2345
    useState<number>(null);

  const watchPlayer = () => {
    if (props.player) {
      props.player.addEventListener("play", updatePlayerState);
      props.player.addEventListener("pause", updatePlayerState);
      props.player.addEventListener("sourcechange", updatePlayerState);
      props.player.addEventListener("volumechange", updatePlayerState);
    }
    return () => {
      if (props.player) {
        props.player.removeEventListener("play", updatePlayerState);
        props.player.removeEventListener("pause", updatePlayerState);
        props.player.removeEventListener("sourcechange", updatePlayerState);
        props.player.removeEventListener("volumechange", updatePlayerState);
      }
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(watchPlayer, [props.player]);

  const watchAWSPlayer = () => {
    if (props.awsPlayer) {
      props.awsPlayer.addEventListener(
        PlayerEventType.MUTED_CHANGED,
        updatePlayerState
      );
      props.awsPlayer.addEventListener(
        PlayerEventType.AUDIO_BLOCKED,
        updatePlayerState
      );
      props.awsPlayer.addEventListener(
        PlayerEventType.SEEK_COMPLETED,
        updatePlayerState
      ); // add this for the known issue of aws player
      props.awsPlayer.addEventListener(
        PlayerEventType.INITIALIZED,
        updatePlayerState
      );
    }
    return () => {
      if (props.awsPlayer) {
        props.awsPlayer.removeEventListener(
          PlayerEventType.MUTED_CHANGED,
          updatePlayerState
        );
        props.awsPlayer.removeEventListener(
          PlayerEventType.AUDIO_BLOCKED,
          updatePlayerState
        );
        props.awsPlayer.removeEventListener(
          PlayerEventType.SEEK_COMPLETED,
          updatePlayerState
        );
        props.awsPlayer.removeEventListener(
          PlayerEventType.INITIALIZED,
          updatePlayerState
        );
      }
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(watchAWSPlayer, [props.awsPlayer]);

  // TODO: useCallbackとかで囲う(?)
  const updatePlayerState = () => {
    if (props.awsPlayer && !props.awsPlayer.isPaused()) {
      setIsPaused(props.awsPlayer.isPaused());
      setIsMuted(props.awsPlayer.isMuted());
      if (props.player.paused) {
        // setIsControllerHidden(false)
      }
    }

    if (props.player && !props.player.paused) {
      setIsPaused(props.player.paused);
      setIsMuted(props.player.muted);
      if (props.player.paused) {
        // setIsControllerHidden(false)
      }
    }
  };

  const play = () => {
    if (!props.hasURL) {
      // fail safe
      return;
    }
    if (props.player.paused) {
      props.player.play();
      if (!props.isSP) {
        const play = document.getElementById("play");
        displayOperationIcon(play);
      }
    } else {
      props.player.pause();
      if (!props.isSP) {
        const pause = document.getElementById("pause");
        displayOperationIcon(pause);
      }
    }
    setIsPaused(props.player.paused);
  };

  const replay = () => {
    props.player.currentTime -= VIDEO_SKIP_SECONDS;
  };

  const forward = () => {
    props.player.currentTime += VIDEO_SKIP_SECONDS;
  };

  const unmute = () => {
    if (!!props.awsPlayer && !props.awsPlayer.isPaused()) {
      props.awsPlayer.setMuted(false);
      props.awsPlayer.setVolume(1.0);
    }
    if (!!props.player && !props.player.paused) {
      props.player.muted = false;
    }
    setIsMuted(false);
  };

  // @ts-expect-error TS7006
  const skipSeconds = (event, type) => {
    let tapTimer: number;
    if (tapCount < 1) {
      /**
       * シングルタップ時の処理
       */
      setTapCount(tapCount + 1);
      tapTimer = window.setTimeout(() => {
        setTapCount(0);
      }, 300);
    } else {
      /**
       * ダブルタップ時の処理
       */
      setTapCount(0);
      // @ts-expect-error TS2454
      clearTimeout(tapTimer);
      event.preventDefault();
      if (type === "replay") {
        const replayElement = document.getElementById(type);
        replay();
        displayOperationIcon(replayElement);
      } else if (type === "forward") {
        const forwardElement = document.getElementById(type);
        forward();
        displayOperationIcon(forwardElement);
      }
    }
  };

  const displayOverlayController = () => {
    // @ts-expect-error TS2531
    document.getElementById("overlay-controller").classList.remove("hidden");
    setOverlayControllerHiddenTimer(
      window.setTimeout(() => {
        // @ts-expect-error TS2531
        document.getElementById("overlay-controller").classList.add("hidden");
      }, 2000)
    );
  };

  // @ts-expect-error TS7006
  const displayOperationIcon = (element) => {
    element.classList.remove("hidden");
    window.setTimeout(() => {
      element.classList.add("hidden");
    }, 300);
  };

  const setHiddenTimeout = () => {
    if (overlayControllerHiddenTimer) {
      clearTimeout(overlayControllerHiddenTimer);
      // @ts-expect-error TS2345
      setOverlayControllerHiddenTimer(null);
    }
    setOverlayControllerHiddenTimer(
      window.setTimeout(() => {
        // @ts-expect-error TS2531
        document.getElementById("overlay-controller").classList.add("hidden");
      }, 2000)
    );
  };

  return (
    <React.Fragment>
      {isMuted && (
        <div
          id="unmute"
          css={classesStreamingControllerStyles.unmute}
          onClick={unmute}
        >
          <VolumeOffIcon />
          {t("streaming.controller.mute")}
        </div>
      )}

      {
        // 低遅延モードが無効になりました。を表示する。
        <div
          css={classesStreamingControllerStyles.changeLowLatency}
          className={props.isCancelLowLatencyMessage ? `` : `hide`}
        >
          <InfoIcon />
          通信速度低下のため低遅延モードが無効になりました
          <br />
          Due to slow network, low latency mode was cancelled
        </div>
      }

      {/* 1. シングルタップの際の表示 */}
      {props.hasURL && props.isSP && (
        <div
          id="overlay-controller"
          className="hidden"
          css={classesStreamingControllerStyles.overlay}
        >
          <div
            onClick={() => {
              replay();
              setHiddenTimeout();
            }}
          >
            {VIDEO_SKIP_SECONDS === 5 && <Replay5Icon />}
            {VIDEO_SKIP_SECONDS === 10 && <Replay10Icon />}
          </div>

          <div onClick={play}>
            {isPaused ? <PlayArrowIcon /> : <PauseIcon />}
          </div>

          <div
            onClick={() => {
              forward();
              setHiddenTimeout();
            }}
          >
            {VIDEO_SKIP_SECONDS === 5 && <Forward5Icon />}
            {VIDEO_SKIP_SECONDS === 10 && <Forward10Icon />}
          </div>
        </div>
      )}

      <div
        id="touch-area-wrap"
        css={
          classesStreamingControllerStyles.root
        } /*onClick={displayTouchArea}*/
      >
        <div id="touch-area" className="hidden">
          {props.hasURL && props.isSP && (
            <div
              id="touch-area-left"
              onClick={(event) => skipSeconds(event, "replay")}
            >
              <div css={classesStreamingControllerStyles.operationIcon}>
                <div id="replay" className="icon hidden">
                  <FastRewindIcon />
                </div>
              </div>
            </div>
          )}

          {props.hasURL ? (
            props.isSP ? (
              <div
                id="touch-area-center"
                onClick={displayOverlayController}
              ></div>
            ) : (
              <div id="touch-area-center" onClick={play}>
                <div css={classesStreamingControllerStyles.operationIcon}>
                  <div id="play" className="icon hidden">
                    <PlayArrowIcon />
                  </div>
                  <div id="pause" className="icon hidden">
                    <PauseIcon />
                  </div>
                </div>
              </div>
            )
          ) : null}

          {props.hasURL && props.isSP && (
            <div
              id="touch-area-right"
              onClick={(event) => skipSeconds(event, "forward")}
            >
              <div css={classesStreamingControllerStyles.operationIcon}>
                <div id="forward" className="icon hidden">
                  <FastForwardIcon />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const streamingControllerStyles = () => {
  return {
    root: css`
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 5;
      #touch-area {
        display: flex;
        width: 100%;
        height: 100%;
        /* background-color: rgba(0, 0, 0, 0.8); */
        opacity: 1;
        visibility: visible;
        transition: 0.5s;
        /* &.hidden{
               opacity: 0;
               visibility: hidden;
            } */
      }
      #touch-area-left {
        width: 20%;
        height: 100%;
        /* background-color: rgba(255, 0, 0, 0.5); */
      }
      #touch-area-center {
        width: 60%;
        height: 100%;
        /* background-color: rgba(0, 255, 0, 0.5); */
        @media screen and (min-width: 768px) {
          width: 100%;
        }
      }
      #touch-area-right {
        width: 20%;
        height: 100%;
        /* background-color: rgba(0, 0, 255, 0.5); */
      }
    `,
    operationIcon: css`
      position: relative;
      height: 100%;
      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 48px;
        height: 48px;
        margin-top: -24px;
        margin-left: -24px;
        box-sizing: content-box;
        cursor: pointer;
        opacity: 1;
        visibility: visible;
        transition: 0.5s;
        @media screen and (min-width: 768px) {
          width: 64px;
          height: 64px;
          margin-top: -32px;
          margin-left: -32px;
        }
        &#replay,
        &#forward {
          width: 40px;
          height: 40px;
          margin-top: -20px;
          margin-left: -20px;
        }
      }
      .hidden {
        opacity: 0;
        visibility: hidden;
      }
      svg {
        width: 100%;
        height: 100%;
        fill: #fff;
      }
    `,
    overlay: css`
      position: absolute;
      top: 50%;
      display: flex;
      width: 100%;
      margin-top: -28px;
      align-items: center;
      justify-content: center;
      opacity: 1;
      visibility: visible;
      transition: 0.5s;
      z-index: 20;
      /* background-color: rgba(0, 255, 255, 0.5); */
      div {
        width: 40px;
        height: 40px;
        margin: 8px 16px;
      }
      svg {
        width: 100%;
        height: 100%;
        fill: #fff;
      }
      &.hidden {
        opacity: 0;
        visibility: hidden;
      }
    `,
    unmute: css`
      position: absolute;
      top: 8px;
      left: 8px;
      display: flex;
      align-items: center;
      padding: 7px 14px 7px 12px;
      color: #fff;
      font-size: 12px;
      background-color: rgba(0, 0, 0, 0.7);
      border: 1px solid #fff;
      border-radius: 3px;
      z-index: 20;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    `,
    changeLowLatency: css`
      position: absolute;
      top: 8px;
      right: 8px;
      display: flex;
      align-items: center;
      padding: 8px 14px 8px 12px;
      color: #fff;
      font-size: 12px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 3px;
      z-index: 20;
      transition: 0.3s;
      opacity: 1;
      visibility: visible;
      &.hide {
        opacity: 0;
        visibility: hidden;
      }
      svg {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    `,
  };
};
