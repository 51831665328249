/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useDispatch } from "react-redux";
import { sanitizer } from "../../utility/sanitize";
import { EventInfo } from "../../utility";
import { streamingActions } from "../../modules/streaming";
import { PCView } from "components/common/MediaQuery";

interface Props {
  event: EventInfo;
  isDarkModeEnabled: boolean;
  clickCount?: number;
  setClickCount?: (clickCount: number) => void;
}
export const StreamingEventDescription: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const classes = styles({ isDarkModeEnabled: props.isDarkModeEnabled });

  const displayOnlyComment = () => {
    // @ts-expect-error TS18048
    if (props.clickCount >= 9) {
      dispatch(streamingActions.toggleOnlyChatMode());
    }
    // @ts-expect-error TS2722
    props.setClickCount(props.clickCount + 1);
  };

  return (
    <React.Fragment>
      <p
        css={classes.description}
        dangerouslySetInnerHTML={{ __html: sanitizer(props.event.description) }}
      />

      <PCView>
        <p
          css={css`
            display: inline-block;
            font-size: 12px;
            opacity: 0;
          `}
          onClick={displayOnlyComment}
        >
          コメントのみ表示
        </p>
      </PCView>
    </React.Fragment>
  );
};

const styles = (props: { isDarkModeEnabled: boolean }) => {
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;

  return {
    description: css`
      ${textColorInDarkMode};
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 1px;
      img {
        max-width: 100%;
      }
      a {
        color: #00c2ae;
      }
    `,
  };
};
