/**
 * hoge.spwn.jp から spwn.jp を除いた hoge を取得する
 *
 * 取得できなかった場合は空文字列を返す
 *
 * @example
 * const slug = getSPWNSlug(window.location.hostname) // hololive.spwn.jp
 * console.log(slug) // → hololive
 */
export const getSPWNSlug = (hostname: string): string => {
  const match = /^(.+)\.spwn\.jp$/.exec(hostname);
  return match?.[1] ?? "";
};
