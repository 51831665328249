import { LoaderLayout } from "../components/atoms/loader/LoaderLayout";
import React from "react";
import { useParams } from "react-router";
import { TenantGoods } from "./TenantGoods";
import { useGetGoodsPreview } from "./useGetGoodsPreview";

type Props = {
  tenantId: string;
  brandId: string;
  shopId: string;
};
export const TenantGoodsPreview: React.FC<Props> = (props) => {
  const { goodsId } = useParams<{ goodsId: string }>();
  const { goods } = useGetGoodsPreview({
    ...props,
    goodsId,
  });
  // if (error) {
  //   console.log(error);
  //   return <Redirect to="/" />;
  // }
  if (goods === undefined) {
    return <LoaderLayout />;
  }
  return <TenantGoods {...props} goods={goods} />;
};
