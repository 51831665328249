/**
 * @description Create a URL with search params;
 * @param location - URL(https://example.com)
 * @param searchParams - Search params({ key: "key", value: "value" })
 * @returns URL with search params(https://example.com?key=value)
 */
export const createUrl = ({
  location,
  searchParams,
}: {
  location: string;
  searchParams: {
    key: string;
    value: string;
  }[];
}) => {
  const url = new URL(location);
  searchParams.forEach((searchParam) => {
    url.searchParams.append(searchParam.key, searchParam.value);
  });
  return url.href;
};
