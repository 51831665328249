import resourcesJp from "./namespace/ja/resource.json";
import resourcesEn from "./namespace/en/resource.json";

const resources = {
  ja: {
    translation: resourcesJp,
  },
  en: {
    translation: resourcesEn,
  },
};

export default resources;
