/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import React from "react";
import { CommentFilterType } from "modules/streaming";
import { msgScrollBottom } from "utility/streaming";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import { useI18n } from "hooks/i18n/i18n";

type Props = {
  isDarkModeEnabled: boolean;
  commentFilterState: string;
  setCommentFilterState: (state: CommentFilterType) => void;
};

/**
 * Comment Filter
 * @param props
 */
const StreamingCommentFilter: React.FC<Props> = (props) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const filterClasses = filterDesign({
    isDarkModeEnabled: props.isDarkModeEnabled,
  });
  const { t } = useI18n();

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const closeExpanded = (filter: CommentFilterType) => {
    setIsExpanded(false);
    props.setCommentFilterState(filter);
    msgScrollBottom();
  };

  const selectedFilterNameRender = () => {
    if (props.commentFilterState === "none") {
      return t("streaming.commentDisplay.hide");
    } else {
      return t("streaming.commentDisplay.show");
    }
  };

  // TODO@kawano 選択したらアコーディオン閉じる
  // ギフトがない場合コメント欄がなくなる。高さの調整が必要。
  return (
    <ExpansionPanel expanded={isExpanded} css={filterClasses.root}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={toggleExpanded}
      >
        <Typography>{selectedFilterNameRender()}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails css={filterClasses.selectDisplayType}>
        <ul>
          <li onClick={() => closeExpanded("all")}>
            {t("streaming.commentDisplay.show")}
          </li>
          <li onClick={() => closeExpanded("none")}>
            {t("streaming.commentDisplay.hide")}
          </li>
        </ul>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

interface FilterDesignProps {
  isDarkModeEnabled: boolean;
}
const filterDesign = (props?: FilterDesignProps) => {
  // @ts-expect-error TS18048
  const svgColorInDarkMode = props.isDarkModeEnabled
    ? css`
        fill: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const backgroundColorInDarkMode = props.isDarkModeEnabled
    ? css`
        background-color: #202020 !important;
      `
    : css`
        background-color: #f7f7f7 !important;
      `;
  // @ts-expect-error TS18048
  const drawerBackgroundColorInDarkMode = props.isDarkModeEnabled
    ? css`
        background-color: #202020 !important;
      `
    : css`
        background-color: #dcedeb !important;
      `;

  const root = css`
    position: absolute !important;
    width: 100%;
    margin-bottom: 0px !important;
    font-size: 14px;
    ${backgroundColorInDarkMode}
    border-top: 1px solid #e0e0e0;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: none !important;
    z-index: 1;
    @media screen and (min-width: 768px) {
      border-right: 1px solid #e0e0e0;
      border-left: 1px solid #e0e0e0;
    }
    .MuiButtonBase-root.MuiExpansionPanelSummary-root {
      min-height: 38px;
      border-bottom: 1px solid #e0e0e0;
    }
    .MuiExpansionPanelSummary-root.Mui-expanded {
      min-height: 38px !important;
    }
    .MuiExpansionPanelSummary-content {
      margin: 0 !important;
      p {
        width: 100%;
        ${textColorInDarkMode}
        font-size: 14px;
        text-align: center;
      }
    }
    .MuiExpansionPanelSummary-expandIcon {
      position: absolute;
      top: 50%;
      right: 16px;
      padding: 0 !important;
      margin-top: -12px;
      margin-right: 0 !important;
      svg {
        ${svgColorInDarkMode}
      }
    }
  `;
  const selectDisplayType = css`
    padding: 0 !important;
    ul {
      width: 100%;
      li {
        padding: 8px 0;
        ${textColorInDarkMode}
        text-align: center;
        ${drawerBackgroundColorInDarkMode}
        border-bottom: 1px solid #fff;
        cursor: pointer;
      }
    }
  `;
  return {
    root,
    selectDisplayType,
  };
};

export default StreamingCommentFilter;
