import useSWR from "swr";
import firebase from "firebase/app";
import {
  GetPublicShopRequest,
  GetPublicShopResponse,
} from "@spwn/types/functions";
import { Shop } from "./shopModel";

export const useGetShopPublic = ({
  tenantId,
  brandId,
  shopId,
}: {
  tenantId: string;
  brandId: string;
  shopId: string;
}): {
  loading: boolean;
  shop: Shop | undefined;
  error: unknown;
} => {
  const response = useSWR(
    ["getShopPublic", tenantId, brandId, shopId],
    async () => {
      const { data } = await callGetPublicShop({
        tenantId,
        brandId,
        shopId,
      });
      // ResponseからShopModelに変換
      const shop: Shop = {
        name: data.name,
        thumbnail: data.thumbnail,
        goodsList: data.goodsList.map((goods) => ({
          id: goods.id,
          name: goods.name,
          price: goods.price,
          thumbnail: goods.thumbnail,
          salesStatus: goods.salesStatus,
          hasVariant: goods.hasVariant,
        })),
      };
      return shop;
    }
  );

  return {
    loading: response.data === undefined,
    shop: response.data,
    error: response.error,
  };
};

const callGetPublicShop = async (
  request: GetPublicShopRequest
): Promise<{ data: GetPublicShopResponse }> => {
  return await firebase.functions().httpsCallable("getShopPublic")(request);
};
