/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { EventInfo } from "utility";
import { EventStartTime } from "components/account/ticket/EventDateTimeInfo";

interface Props {
  eventInfo: EventInfo;
}
export const EventTicketHeader: React.FC<Props> = (props) => {
  const { eventInfo } = props;
  const classes = styles();

  return (
    <div css={classes.eventInfo}>
      <div css={classes.thumbnail}>
        <img src={eventInfo.thumbnail} alt={eventInfo.eventTitle} />
      </div>

      <div css={classes.info}>
        <div css={classes.datetime}>
          <p className="date">
            {eventInfo.isLongEvent
              ? eventInfo.eventOpenDateFull + " ~ " + eventInfo.eventEndDateFull
              : eventInfo.eventOpenDateFull}
          </p>
          {!eventInfo.isLongEvent && (
            <div className="time">
              <EventStartTime parts={eventInfo.parts} />
            </div>
          )}
        </div>

        <h2>{eventInfo.eventTitle}</h2>
        <p className="artist">{eventInfo.artists}</p>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      width: 95%;
      max-width: 980px;
      padding: 8px 0 72px;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        padding: 8px 0;
      }
    `,
    eventInfo: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 16px;
      margin-bottom: 40px;
      border-bottom: 1px solid #eee;
      @media screen and (min-width: 768px) {
        padding-bottom: 0;
        margin-bottom: 100px;
        border-bottom: none;
      }
    `,
    thumbnail: css`
      width: 100%;
      margin-bottom: 24px;
      img {
        width: 100%;
      }
      @media screen and (min-width: 768px) {
        width: 380px;
        margin-right: 40px;
        margin-bottom: 0px;
      }
    `,
    info: css`
      width: 100%;
      @media screen and (min-width: 768px) {
        width: calc(100% - 420px);
      }
      h2 {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.3em;
        @media screen and (min-width: 768px) {
          font-size: 24px;
        }
      }
      .artist {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.5em;
        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }
    `,
    datetime: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        margin-bottom: 32px;
      }
      .date {
        width: 100%;
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          width: auto;
          margin-bottom: 0px;
          font-size: 24px;
        }
      }
      .time {
        display: flex;
        font-size: 18px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 24px;
        }
        span {
          padding-top: 4px;
          margin-right: 16px;
          font-size: 14px;
          @media screen and (min-width: 768px) {
            margin-right: 8px;
          }
        }
      }
    `,
  };
};
