/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import React, { useMemo } from "react";
// import { useSelector } from "react-redux";
// import { Store } from "../../../store";
import { EventInfo, isSmartPhone } from "../../../utility";
// @ts-expect-error TS7016
import AnchorLink from "react-anchor-link-smooth-scroll";
import label_delivery from "../../../designs/images/label_delivery.svg";
import { changeStyleWithHosting } from "utility/hosting";

interface EventThumbnailProps {
  eventInfo: EventInfo;
}
export const EventDetailThumbnail: React.FC<EventThumbnailProps> = (props) => {
  // const location = useSelector((state: Store) => state.router.location);

  const classesEventThumbnail = useMemo(() => eventThumbnailDesign(), []);

  // @ts-expect-error TS7006
  const TopAnchorLink = (props) => {
    return (
      <AnchorLink
        href={props.href}
        offset={() => {
          return isSmartPhone() ? 30 : 100;
        }}
      >
        {props.children}
      </AnchorLink>
    );
  };

  return (
    <div css={classesEventThumbnail.root}>
      <div css={classesEventThumbnail.thumbnailLayout}>
        <img
          css={classesEventThumbnail.thumbnail}
          src={props.eventInfo.thumbnail}
          alt={props.eventInfo.title}
        />
        {props.eventInfo.isOpenStreamingPage ? (
          <TopAnchorLink href="#archive_video">
            <img
              src={label_delivery}
              alt="ライブ配信中"
              css={classesEventThumbnail.label}
            />
          </TopAnchorLink>
        ) : null}
      </div>
    </div>
  );
};

const eventThumbnailDesign = () => {
  const hostingBaseStyleMap = changeStyleWithHosting().baseStyles;

  return {
    root: css`
      background-color: ${hostingBaseStyleMap.baseColor};
    `,
    thumbnailLayout: css`
      position: relative;
      width: 100%;
      max-width: 980px;
      margin: 0 auto;
    `,
    thumbnail: css`
      width: 100%;
    `,
    label: css`
      position: absolute;
      top: 10px;
      left: 10px;
      width: 100px;
    `,
  };
};
