/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";

import { EventInfo, getEventDisplayInfo, getNowTimestamp } from "utility";

import { useI18n } from "hooks/i18n/i18n";
import { useGetEventList } from "hooks/event/useGetEventList";

import type { Event } from "@spwn/types/firebase/firestore";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { MainContents } from "components/templates/MainContents";
import {
  PortalTopEventCard,
  PortalTopExternalGoodsCard,
} from "features/PortalTopEventCard";

export const GoodsEventListFetcher: FC = () => {
  const { data: events, isLoading } = useGetEventList({
    type: "goods",
  });

  return <GoodsEventListLogic events={events} isLoading={isLoading} />;
};

const GoodsEventListLogic: FC<{
  events?: Event[];
  isLoading: boolean;
}> = ({ events, isLoading }) => {
  /**
   * @description
   * イベント一覧からカプセルイベントだけを取得する。
   * EventInfoからグッズの状態を判定しているため、グッズの在庫数が0でも販売中であれば「販売中」と表示されてしまう。
   * 現状の仕様からグッズの詳細情報を取得することは難しいため、将来的な課題としてコメントを残しておく。
   */
  const goodsList: (EventInfo & { isOnSale: boolean })[] = (
    events ? events : []
  ).flatMap((event) => {
    // isOnSaleの判定処置
    const nowTimestamp = getNowTimestamp();
    const {
      isGoodsSellingPageOpen,
      goodsSaleStatus,
      goodsSellingOpenTime,
      goodsSellingCloseTime,
    } = getEventDisplayInfo(event);

    const isOnSale = (() => {
      if (!isGoodsSellingPageOpen) return false;
      if (goodsSaleStatus !== "ON_SALE") return false;

      if (
        goodsSellingOpenTime?.seconds &&
        nowTimestamp < goodsSellingOpenTime.seconds
      )
        return false;
      if (
        goodsSellingCloseTime?.seconds &&
        goodsSellingCloseTime.seconds <= nowTimestamp
      )
        return false;

      return true;
    })();

    return {
      ...getEventDisplayInfo(event),
      isOnSale,
    };
  });

  return <GoodsEventList events={goodsList} isLoading={isLoading} />;
};

type GoodsEventListType = {
  events: (EventInfo & { isOnSale: boolean })[];
  isLoading: boolean;
};
export const GoodsEventList: FC<GoodsEventListType> = ({
  events,
  isLoading,
}) => {
  const { t } = useI18n();
  const breadcrumbText = "グッズ一覧";

  if (events.length === 0 && !isLoading) {
    return (
      <ContentsLayout breadcrumbText={breadcrumbText}>
        <div
          css={css`
            height: calc(100vh - (255px + 194px));
            padding: 10px;
            margin-top: 100px;
            color: #888888;
            font-size: 20px;
            text-align: center;
            @media screen and (min-width: 768px) {
              font-size: 24px;
            }
          `}
        >
          {t("event.noEvent.noVOD")}
        </div>
      </ContentsLayout>
    );
  }

  return (
    <ContentsLayout breadcrumbText={breadcrumbText}>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr;
          gap: 24px;
          width: 90%;
          max-width: 980px;
          padding: 40px 0 80px;
          margin: 0 auto;
          @media screen and (min-width: 768px) {
            grid-template-columns: 1fr 1fr 1fr;
          }
        `}
      >
        {events.map((event, index) => {
          const { goodsUrl } = event;

          if (goodsUrl) {
            return (
              <React.Fragment key={`${event.eventId}_${index}`}>
                <PortalTopExternalGoodsCard
                  href={goodsUrl}
                  tag={event.isOnSale ? "販売中" : "販売終了"}
                  tagColor={event.isOnSale ? "#00C2AD" : "#fff"}
                  thumbnail={event.thumbnail}
                  date={event.eventOpenDateFull}
                  title={event.eventTitle}
                />
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={`${event.eventId}_${index}`}>
              <PortalTopEventCard
                href={`/events/${event.eventId}/goods`}
                tag={event.isOnSale ? "販売中" : "販売終了"}
                tagColor={event.isOnSale ? "#00C2AD" : "#fff"}
                thumbnail={event.thumbnail}
                date={event.eventOpenDateFull}
                title={event.eventTitle}
              />
            </React.Fragment>
          );
        })}
      </div>
    </ContentsLayout>
  );
};

const ContentsLayout: FC<{
  children: JSX.Element;
  breadcrumbText: string;
}> = ({ children, breadcrumbText }) => {
  return (
    <MainContents>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, breadcrumbText],
        ]}
      />

      {children}
    </MainContents>
  );
};
