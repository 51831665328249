/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useI18n } from "../../hooks/i18n/i18n";

interface Props {
  numberOfProducts: number;
}
export const CartHeading: React.FC<Props> = (props) => {
  const classes = styles();
  const { t } = useI18n();

  return (
    <div css={classes.root}>
      <div className={"cart-heading-count"}>
        <p className={"text"}>
          {t("cart.header.includeItem", { cartCount: props.numberOfProducts })}
        </p>
      </div>
      <div className={"cart-heading-caution"}>
        <p className={"text"}>{t("cart.header.paymentInfo")}</p>
        <p className={"text"}>{t("cart.header.paymentForEachDelivery")}</p>
      </div>
    </div>
  );
};

const styles = () => ({
  root: css`
    letter-spacing: 1px;
    .cart-heading-count {
      margin-bottom: 16px;
      @media screen and (min-width: 768px) {
        margin-bottom: 24px;
      }
      p {
        font-size: 16px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 28px;
        }
      }
    }
    .cart-heading-caution {
      p {
        font-size: 12px;
        line-height: 1.5em;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
  `,
});
