import { IssuedTicket } from "@spwn/types/firebase/firestore";
import { useI18n } from "hooks/i18n/i18n";

type AccountTicketDigitalQrCodeArguments = {
  userId: string;
  relatedTicket: IssuedTicket;
  benefits: {
    productClass: number | undefined;
    isRedeemed: boolean;
  };
};

type AccountTicketDigitalQrCodeReturnValue = {
  shareStatus: string;
  ticketShareMessage: string;
  isShared: boolean;
  isBenefitGoods: boolean;
};

export const useAccountTicketDigitalQrCode = ({
  userId,
  relatedTicket,
  benefits,
}: AccountTicketDigitalQrCodeArguments): AccountTicketDigitalQrCodeReturnValue => {
  const { t } = useI18n();
  const shareStatus = ticketShareStatus(relatedTicket, userId);

  /**
   * @description
   * チケットが他人に共有されているかを判定するフラグ
   * "beforeSharing" or "createShareUrl" の時は共有されていない
   */
  const isShared =
    shareStatus === "beforeSharing" || shareStatus === "createShareUrl"
      ? false
      : true;

  /**
   * @description
   * 特典付きチケットかを判定するフラグ
   */
  const isBenefitGoods = Boolean(
    benefits.productClass && benefits.productClass < 3
  );

  /**
   * @description チケット共有エリアに表示するメッセージを作成する
   * @todo もっと良い実装方法を考える
   */
  const ticketShareMessage = (() => {
    if (shareStatus === "beforeSharing" || shareStatus === "createShareUrl") {
      return t("ticketDetail.caution");
    }

    const friendName = relatedTicket.friendName
      ? relatedTicket.friendName !== ""
        ? relatedTicket.friendName
        : `SPWN ID: ${relatedTicket.friend}`
      : "";

    if (shareStatus === "shared") {
      return t("ticketDetail.shareFriend", { friendName });
    }

    if (shareStatus === "receivedFriend") {
      return t("ticketDetail.receiveFriend", { friendName });
    }

    console.warn("Error: shareStatus is not defined");
    return "";
  })();

  return {
    shareStatus,
    ticketShareMessage,
    isShared,
    isBenefitGoods,
  };
};

type TicketShareStatusResponse =
  | "beforeSharing"
  | "createShareUrl"
  | "shared"
  | "receivedFriend";
/**
 * @description チケットの共有状態を取得する
 * "beforeSharing" | "createShareUrl" | "shared" | "receivedFriend"
 */
export const ticketShareStatus = (
  relatedTicket: IssuedTicket,
  userId: string
): TicketShareStatusResponse => {
  if (
    relatedTicket !== null &&
    relatedTicket.owner === userId &&
    relatedTicket.friend !== undefined &&
    relatedTicket.friend !== null &&
    relatedTicket.friend !== ""
  ) {
    return "shared";
  }

  if (
    relatedTicket !== null &&
    relatedTicket.friend !== undefined &&
    relatedTicket.friend !== null &&
    relatedTicket.friend !== "" &&
    relatedTicket.friend === userId
  ) {
    return "receivedFriend";
  }

  if (
    relatedTicket !== null &&
    relatedTicket.owner === userId &&
    relatedTicket.sharingCode !== undefined &&
    relatedTicket.sharingCode !== null &&
    relatedTicket.sharingCode !== ""
  ) {
    return "createShareUrl";
  }

  return "beforeSharing";
};
