/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { LinkButton } from "components/atoms/button/LinkButton";
import { TextLink } from "ui/TextLink";

interface Props {}
export const DocomoAccountCaution: React.FC<Props> = (_props) => {
  const classes = styles();
  return (
    <div css={classes.caution}>
      <div className="text">
        <p>
          このイベントはdアカウント連携が必要なイベントです。
          <br />
          プロフィール情報の編集ページでdアカウントの連携をお願いいたします。
        </p>
        <p>
          連携方法は
          <TextLink
            href={
              "https://spwn.zendesk.com/hc/ja/articles/900001988463-d%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%81%A8%E3%81%AE%E9%80%A3%E6%90%BA%E6%96%B9%E6%B3%95"
            }
          >
            こちら
          </TextLink>
          をご確認ください。
        </p>
      </div>

      <div className="button">
        <LinkButton url={"/account/edit"} label={"dアカウント連携を行う"} />
      </div>
    </div>
  );
};

const styles = () => {
  return {
    caution: css`
      .text {
        margin-bottom: 24px;
        text-align: center;
      }
      p {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.8em;
      }
      br {
        display: none;
        @media screen and (min-width: 768px) {
          display: inline;
        }
      }
      .button {
        max-width: 400px;
        margin: 0 auto;
      }
    `,
  };
};
