/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Link } from "react-router-dom";
import { changeStyleWithHosting } from "utility/hosting";

interface Props {
  url: string;
  label: string;
}
export const LinkButton: React.FC<Props> = (props) => {
  const classes = styles();

  return (
    <Link to={props.url} css={classes.root}>
      {props.label}
    </Link>
  );
};

const styles = () => {
  const backgroundColor = changeStyleWithHosting().baseStyles.accentColor;
  return {
    root: css`
      display: block;
      padding: 12px 0;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      letter-spacing: 1px;
      background-color: ${backgroundColor};
    `,
  };
};
