/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";

export const StreamingStandbyCommentArea: FC<{
  isDarkModeEnabled: boolean;
}> = ({ isDarkModeEnabled }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${isDarkModeEnabled ? "#fff" : "#000"};
        font-weight: bold;
        border: 1px solid #e0e0e0;
      `}
    >
      <p>コメントはオフになっています。</p>
    </div>
  );
};
