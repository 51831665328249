/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { BreadcrumbArea } from "components/common/Link";
import { useI18n } from "hooks/i18n/i18n";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { ContentsLayout } from "styles";
import { authActions } from "modules/auth";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import twitterImg from "designs/images/shareButton/icon_Twitter_400*409_bl.png";

export const AccountAddressLinkedTwitter: FC<{
  user: firebase.User;
}> = ({ user }) => {
  const { t } = useI18n();
  const classes = styles();
  const dispatch = useDispatch();

  const cancelTwitterLinkage = () => {
    if (window.confirm(t("accountEdit.ExternalService.releaseConfirm"))) {
      dispatch(
        authActions.unlinkSNSAccount.started({ providerType: "twitter" })
      );
    }
  };

  return (
    <div css={classes.root}>
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          ["/account", t("common.routes.account")],
          [null, t("common.routes.accountAddress")],
        ]}
      />

      <ContentsLayout>
        <div css={classes.header}>
          <h2>X（旧Twitter）連携を行っています。</h2>
          <p>
            メールアドレスでログインし、Xとの連携を行っている場合、Xとの連携機能廃止の影響で住所の変更を行えない場合がございます。
          </p>
          <p>
            以下のボタンから連携を解除していただくか、一度SPWNからログアウトしていただき、SPWNのパスワードのリセットをお願いいたします。
          </p>

          <button
            onClick={() => cancelTwitterLinkage()}
            // if conected provider data is one account, can't disconnect its
            disabled={!user.emailVerified || user.providerData.length === 1}
          >
            <img src={twitterImg} alt="X" />
            X（旧Twitter）連携を解除する
          </button>
        </div>
      </ContentsLayout>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      padding: 40px 0 80px;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        max-width: 1800px;
        width: 100%;
        min-height: calc(100vh - 280px);
        padding-top: 0;
        margin: 0 auto;
      }
    `,
    header: css`
      margin: 0 auto 40px;
      @media screen and (min-width: 768px) {
        width: 960px;
      }
      h2 {
        margin-bottom: 16px;
        font-size: 18px;
      }
      p {
        font-size: 14px;
        line-height: 1.5em;
      }
      .caution {
        margin: 0 0 16px;
        p {
          padding-left: 1em;
          font-size: 12px;
          text-indent: -1em;
        }
      }
      button {
        display: flex;
        align-items: center;
        padding: 10px;
        margin-top: 16px;
        background-color: #fff;
        border: 2px solid #dedede;
        border-radius: 5px;
        cursor: pointer;
        outline: none;
        appearance: none;
        img {
          width: 24px;
          height: auto;
          margin-right: 10px;
        }
        &:disabled {
          background-color: #dedede;
          cursor: not-allowed;
          img {
            opacity: 0.5;
          }
        }
      }
    `,
  };
};
