/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import { sanitizer } from "utility/sanitize";

export const StreamingStandbyDescription: FC<{
  isDarkModeEnabled: boolean;
  description: string;
}> = ({ isDarkModeEnabled, description }) => {
  return (
    <div
      css={css`
        color: ${isDarkModeEnabled ? "#fff" : "#000"};
        font-size: 14px;
      `}
      dangerouslySetInnerHTML={{
        __html: sanitizer(description),
      }}
    />
  );
};
