/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useCallback, useState } from "react";
import { convertMapToValues, getEventDisplayInfo } from "utility";
import { changeStyleWithHosting } from "utility/hosting";
import { IS_HIDE_DATETIME } from "utility/event";
import { FullScreenDialog, ModalHeader } from "components/common/Modal";
import type { Event } from "@spwn/types/firebase/firestore";

interface Props {
  index: number;
  eventMap: { [key: string]: Event };
  open: boolean;
  onCloseModal: () => void;
  onSendEvent: (index: number, eventId: string) => void;
}

const ModalVoucherEvent: React.FC<Props> = (props) => {
  const { index, open, eventMap, onCloseModal, onSendEvent } = props;
  const [width, setWidth] = useState(window.innerWidth);

  const classesEventListDesign = eventListDesign();
  const { isDisplayEventDatetimeLabel } =
    changeStyleWithHosting().pageSettings.eventListPage;
  const displayEvents = convertMapToValues(eventMap);

  const modalStyle = {
    position: "absolute",
    top: width >= 768 ? 65 : 50,
    margin: "0 auto",
    left: 0,
    right: 0,
    maxWidth: width >= 768 ? "calc(100% - 64px)" : "calc(100% - 40px)",
    width: "100%",
    height: `calc(100% - ${width >= 768 ? 65 : 50}px)`,
    backgroundColor: "#efefef",
    borderRadius: 0,
  };

  const updateWidth = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [updateWidth]);

  return (
    <FullScreenDialog
      open={open}
      style={modalStyle}
      onBackdropClick={onCloseModal}
    >
      <ModalHeader title="イベントを選択する" onClick={onCloseModal} />
      <div css={classesEventListDesign.root}>
        {displayEvents.map((event, i: number) => {
          const eventId = Object.keys(eventMap)[i];
          const {
            eventTitle,
            artists,
            thumbnail,
            eventOpenDate,
            eventOpenDay,
            eventOpenTime,
            placeInfo,
          } = getEventDisplayInfo(event);

          return (
            <article
              key={"event_card_" + i}
              css={classesEventListDesign.eventItem}
            >
              {isDisplayEventDatetimeLabel ? (
                <div css={classesEventListDesign.eventItemLabel}>
                  <p className="datetime">
                    {eventOpenDate}
                    <span>{"(" + eventOpenDay + ")"}</span>
                    {/*
                     // @ts-expect-error TS2345 */}
                    {IS_HIDE_DATETIME(eventId) ? null : " " + eventOpenTime}
                  </p>
                </div>
              ) : null}
              <div
                css={classesEventListDesign.eventItemLayout}
                // @ts-expect-error TS2345
                onClick={() => onSendEvent(index, eventId)}
              >
                <div css={classesEventListDesign.eventItemImage}>
                  <img src={thumbnail} alt={eventTitle} />
                  {placeInfo.placeText ? <p>{placeInfo.placeText}</p> : null}
                </div>
                <div css={classesEventListDesign.eventItemText}>
                  <h3>{eventTitle}</h3>
                  <p>{artists}</p>
                </div>
              </div>
            </article>
          );
        })}
      </div>
    </FullScreenDialog>
  );
};

const eventListDesign = () => {
  return {
    root: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px 6%;
      border-top: 1px solid #e0e0e0;
      @media screen and (min-width: 768px) {
        padding: 30px 46px;
      }
      &::after {
        content: "";
        display: block;
        width: 31%;
        margin-bottom: 20px;
      }
    `,
    eventItem: css`
      width: 47.5%;
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        width: 31%;
      }
    `,
    eventItemLabel: css`
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 6%;
      margin-bottom: -15px;
      margin-left: -7px;
      background: linear-gradient(315deg, transparent 6px, #141718 6px);
      z-index: 1;
      @media screen and (min-width: 768px) {
        width: 82%;
        padding: 10px 22px;
        background: linear-gradient(315deg, transparent 14px, #141718 14px);
      }
      &::before {
        content: "";
        position: absolute;
        left: 0px;
        bottom: -7px;
        display: block;
        width: 7px;
        height: 7px;
        background: linear-gradient(45deg, transparent 5px, #252e33 5px);
      }
      p.datetime {
        width: 65%;
        font-family: "din-condensed", sans-serif;
        font-weight: 400;
        font-style: normal;
        color: #fff;
        font-size: 4vw;
        @media screen and (min-width: 768px) {
          width: 120px;
          font-size: 20px;
        }
        span {
          font-size: 4vw;
          @media screen and (min-width: 768px) {
            font-size: 19px;
          }
        }
      }
      p.status {
        width: 50px;
        @media screen and (min-width: 768px) {
          width: 65px;
        }
        img {
          width: 100%;
          vertical-align: baseline;
        }
      }
    `,
    eventItemLayout: css`
      display: block;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      cursor: pointer;
    `,
    eventItemImage: css`
      position: relative;
      background-color: #fff;
      img {
        width: 100%;
      }
      p {
        display: none;
        position: absolute;
        bottom: -1px;
        right: 0;
        width: 50%;
        padding: 8px 0px 8px 20px;
        color: #000;
        font-size: 13px;
        font-weight: bold;
        text-align: center;
        background: linear-gradient(135deg, transparent 21px, #fff 21px);
        @media screen and (min-width: 768px) {
          display: block;
          width: 170px;
          font-size: 14px;
        }
      }
    `,
    eventItemText: css`
      padding: 6% 7% 12%;
      background-color: #fff;
      border-radius: 0 0 5px 5px;
      @media screen and (min-width: 768px) {
        padding: 10px 15px 40px;
      }
      h3 {
        min-height: 50px;
        margin-bottom: 5px;
        font-size: 13px;
        line-height: 1.4em;
        @media screen and (min-width: 768px) {
          min-height: 85px;
          margin-bottom: 10px;
          font-size: 20px;
        }
      }
      p {
        min-height: 40px;
        color: #707070;
        font-size: 13px;
        font-weight: bold;
        line-height: 1.5em;
        @media screen and (min-width: 768px) {
          min-height: 42px;
          font-size: 14px;
        }
      }
    `,
  };
};

export default ModalVoucherEvent;
