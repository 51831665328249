/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import icon_trash from "designs/images/icon_trash.svg";
import type { UserMessage } from "@spwn/types/firebase/firestore";

interface Props {
  message: UserMessage;
  isCommentUser: boolean;
  isDarkModeEnabled: boolean;
  msgBanFunction: (idx: unknown) => Promise<void>;
  msgDeleteFunction: (idx: unknown) => Promise<void>;
}
export const CommentItemNormal: React.FC<Props> = (props) => {
  const chatClasses = chatDesign({
    isDarkModeEnabled: props.isDarkModeEnabled,
  });

  return (
    <div css={chatClasses.chat} onClick={props.msgBanFunction}>
      <div css={chatClasses.chatIcon}>
        <img
          src={props.message.icon}
          alt={props.message.name}
          className="icon"
        />
      </div>
      <div css={chatClasses.chatInfo}>
        <p>
          <span className="name">{props.message.name}</span>
          {props.message.msg}
        </p>
        {
          //コメント削除ボタンを表示する
          props.isCommentUser ? (
            <img
              src={icon_trash}
              alt=""
              className="msg_menu"
              onClick={props.msgDeleteFunction}
            />
          ) : (
            <React.Fragment></React.Fragment>
          )
        }
      </div>
    </div>
  );
};

type ChatDesignProps = {
  isDarkModeEnabled: boolean;
};
const chatDesign = (props?: ChatDesignProps) => {
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;
  return {
    chat: css`
      display: flex;
      position: relative;
      padding: 0px 5px;
      margin-bottom: 8px;
    `,
    chatIcon: css`
      width: 30px;
      margin-right: 10px;
      img {
        width: 30px;
        height: 30px;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 50%;
        object-fit: cover;
      }
    `,
    chatInfo: css`
      width: calc(100% - 40px);
      padding: 5px 20px 0 0;
      line-height: 1em;
      p {
        ${textColorInDarkMode}
        font-size: 12px;
        line-height: 1.5em;
        word-wrap: break-word;
        span {
          margin-right: 8px;
          font-weight: bold;
        }
      }
      img {
        position: absolute;
        top: 50%;
        right: 5px;
        width: 13px;
        height: 13px;
        margin-top: -6px;
        cursor: pointer;
      }
    `,
  };
};
