/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

export const SquareLoader = () => {
  const classesSquareLoaderStyles = squareLoaderStyles();
  return (
    <div id="square-loader" css={classesSquareLoaderStyles.root}>
      <div className="square"></div>
      <div className="square"></div>
      <div className="square"></div>
      <div className="square clear"></div>
      <div className="square"></div>
      <div className="square"></div>
      <div className="square clear"></div>
      <div className="square"></div>
      <div className="square"></div>
    </div>
  );
};

const squareLoaderStyles = () => {
  return {
    root: css`
      top: calc(-10px * 2 / 3);
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      & * {
        box-sizing: border-box;
      }
      .square {
        position: relative;
        top: calc(-10px * 2 / 3);
        float: left;
        width: 10px;
        height: 10px;
        margin-top: calc(10px / 3);
        margin-right: calc(10px / 3);
        background: #00c2ae;
        opacity: 0;
        animation: self-building-square-spinner 6s infinite;
        &:nth-child(1) {
          animation-delay: calc(300ms * 6);
        }
        &:nth-child(2) {
          animation-delay: calc(300ms * 7);
        }
        &:nth-child(3) {
          animation-delay: calc(300ms * 8);
        }
        &:nth-child(4) {
          animation-delay: calc(300ms * 3);
        }
        &:nth-child(5) {
          animation-delay: calc(300ms * 4);
        }
        &:nth-child(6) {
          animation-delay: calc(300ms * 5);
        }
        &:nth-child(7) {
          animation-delay: calc(300ms * 0);
        }
        &:nth-child(8) {
          animation-delay: calc(300ms * 1);
        }
        &:nth-child(9) {
          animation-delay: calc(300ms * 2);
        }
      }
      .clear {
        clear: both;
      }
      @keyframes self-building-square-spinner {
        0% {
          opacity: 0;
        }
        5% {
          opacity: 1;
          top: 0;
        }
        50.9% {
          opacity: 1;
          top: 0;
        }
        55.9% {
          opacity: 0;
          top: inherit;
        }
      }
    `,
  };
};
