/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Store } from "../../../store";
import { TicketDataJson, PlaceDataJson } from "../../../modules/event";
import { sanitizer } from "utility/sanitize";
import { changeStyleWithHosting } from "utility/hosting";
import { convertMapToValues, replaceBr } from "utility";
import { useI18n } from "hooks/i18n/i18n";
import { fetchFirestoreDocumentBySnapshot } from "utility/firebase";
import type { Place } from "@spwn/types/firebase/firestore";
import { isVODTicket } from "utility/event";
import { LeftBorderHeadline } from "components/atoms/headline/LeftBorderHeadline";

interface Props {
  placesData: PlaceDataJson;
  ticketsData: TicketDataJson;
}
export const EventPlacesAndTickets: React.FC<Props> = (props) => {
  const classesPlacesAndTicketsDesign = placesAndTicketsDesign();
  const { t } = useI18n();
  const [eventPlaces, setEventPlaces] = useState<Place[]>([]);
  const event = useSelector((state: Store) => state.event.displayEvent);
  const eventTickets = useSelector((state: Store) =>
    convertMapToValues(state.ticket.eventTicketMap)
      .filter((el) => el.display)
      .filter(filterToFix1478)
      .sort((a, b) => a.priority - b.priority)
  );

  useEffect(() => {
    (async () => {
      if (event.placeInfo?.placeRefs) {
        const promises = event.placeInfo.placeRefs.map(async (ref) =>
          fetchFirestoreDocumentBySnapshot<Place>(ref.get())
        );
        setEventPlaces(await Promise.all(promises));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // チケットと場所がひとつも表示されない場合
  if (
    eventPlaces.length === 0 &&
    props.placesData.places.length === 0 &&
    eventTickets.length === 0 &&
    props.ticketsData.tickets.length === 0
  ) {
    return null;
  }

  return (
    <div id="place_ticket" css={classesPlacesAndTicketsDesign.root}>
      <div css={classesPlacesAndTicketsDesign.heading}>
        <LeftBorderHeadline
          title={t("event.headline.placeAndTicket.text")}
          subTitle={t("event.headline.placeAndTicket.subText")}
          fontColor={"primary"}
        />
      </div>
      {eventPlaces.length > 0 && (
        <React.Fragment>
          <h3 css={classesPlacesAndTicketsDesign.subHeading}>
            {<p>{t("event.headline.place")}</p>}
          </h3>
          <div css={classesPlacesAndTicketsDesign.placesRoot}>
            {eventPlaces.map((place, i) => {
              return (
                <div key={i} css={classesPlacesAndTicketsDesign.placeItem}>
                  <div css={classesPlacesAndTicketsDesign.placeItemText}>
                    <p className="prefecture">{place.areaName}</p>
                    <h4>{place.venueName}</h4>
                  </div>
                  <div css={classesPlacesAndTicketsDesign.placeItemLink}>
                    {place.officialSiteUrl ? (
                      <a
                        href={place.officialSiteUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("event.place.officialSite")}
                      </a>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    {place.mapUrl ? (
                      <a
                        href={place.mapUrl}
                        target="_blank"
                        className="map"
                        rel="noreferrer"
                      >
                        {t("event.place.map")}
                      </a>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      )}
      {/* TODO jsonをplaceRefsに移行したら消せる */}
      {eventPlaces.length === 0 && props.placesData.places.length !== 0 ? (
        <React.Fragment>
          <h3 css={classesPlacesAndTicketsDesign.subHeading}>
            {<p>{t("event.headline.place")}</p>}
          </h3>
          <div css={classesPlacesAndTicketsDesign.placesRoot}>
            {props.placesData.places.map((place, i) => {
              return (
                <div key={i} css={classesPlacesAndTicketsDesign.placeItem}>
                  <div css={classesPlacesAndTicketsDesign.placeItemText}>
                    <p className="prefecture">{place.prefecture}</p>
                    <h4>{place.place}</h4>
                    {!place.walking_distance ? (
                      <React.Fragment></React.Fragment>
                    ) : (
                      <p className="remarksField">{place.walking_distance}</p>
                    )}
                  </div>
                  <div css={classesPlacesAndTicketsDesign.placeItemLink}>
                    {place.place_hp_url ? (
                      <a
                        href={place.place_hp_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("event.place.officialSite")}
                      </a>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    {place.place_map_url ? (
                      <a
                        href={place.place_map_url}
                        target="_blank"
                        className="map"
                        rel="noreferrer"
                      >
                        {t("event.place.map")}
                      </a>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {props.ticketsData.tickets.length !== 0 ? (
        <React.Fragment>
          <h3 css={classesPlacesAndTicketsDesign.subHeading}>
            {t("event.headline.ticket")}
          </h3>
          <div css={classesPlacesAndTicketsDesign.ticketsRoot}>
            {props.ticketsData.tickets.map((ticket, i) => {
              const isFree = Number(ticket.price_jpy) <= 0;
              /** @deprecated 明らかに意図的にバウチャーチケットとして設定されているケース */
              const isVoucherPrice = Number(ticket.price_jpy) === 1;
              return (
                <div key={i} css={classesPlacesAndTicketsDesign.ticketsItem}>
                  <div css={classesPlacesAndTicketsDesign.ticketsItemText}>
                    {ticket.limit_description ? (
                      <p className="remarksField">{ticket.limit_description}</p>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    <h4>{replaceBr(ticket.name)}</h4>
                    {
                      /* 0円（無料）、1円（販売期間外、バウチャーonly想定）以外の場合、金額を表示する */
                      !(isFree || isVoucherPrice) && (
                        <p className="price">
                          {ticket.price_jpy.toLocaleString()}{" "}
                          {t("pawCharge.yen")}
                        </p>
                      )
                    }
                  </div>
                  {ticket.description ? (
                    <ul
                      css={classesPlacesAndTicketsDesign.ticketsItemPrivilege}
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(ticket.description),
                      }}
                    />
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              );
            })}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {/*
        TODO: jsonがあればそちらを優先する。非表示チケット以外全て表示させるためその合意が必要。
        管理ツール移行時に優先度を逆転させ、json部分を削除。
      */}
      {props.ticketsData?.tickets &&
        props.ticketsData.tickets.length === 0 &&
        eventTickets.length > 0 && (
          <React.Fragment>
            <h3 css={classesPlacesAndTicketsDesign.subHeading}>
              {t("event.headline.ticket")}
            </h3>
            <div css={classesPlacesAndTicketsDesign.ticketsRoot}>
              {eventTickets.map((ticket, i) => {
                const isFree = Number(ticket.price_jpy) <= 0;
                const isVoucher = ticket.saleType === "voucherOnly";
                /** @deprecated 明らかに意図的にバウチャーチケットとして設定されているケース(saleType=voucherOnly移行推奨) */
                const isVoucherPrice = Number(ticket.price_jpy) === 1;
                return (
                  <div key={i} css={classesPlacesAndTicketsDesign.ticketsItem}>
                    <div css={classesPlacesAndTicketsDesign.ticketsItemText}>
                      <h4>{replaceBr(ticket.name)}</h4>
                      {
                        /* saleType=voucherOnly、0円（無料）、1円（販売期間外、バウチャーonly想定）以外の場合、金額を表示する */
                        !(isFree || isVoucher || isVoucherPrice) && (
                          <p className="price">
                            {ticket.price_jpy.toLocaleString()}{" "}
                            {t("pawCharge.yen")}
                          </p>
                        )
                      }
                    </div>
                    {ticket.description && (
                      <ul
                        css={classesPlacesAndTicketsDesign.ticketsItemPrivilege}
                        dangerouslySetInnerHTML={{
                          __html: sanitizer(ticket.description),
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
    </div>
  );
};

const placesAndTicketsDesign = () => {
  const hostingBaseStyleMap = changeStyleWithHosting().baseStyles;
  const textColor = hostingBaseStyleMap.fontPrimaryColor;

  return {
    root: css`
      margin: 0 auto 60px;
      @media screen and (min-width: 768px) {
        margin-bottom: 100px;
      }
    `,
    heading: css`
      margin-bottom: 40px;
    `,
    subHeading: css`
      margin-bottom: 20px;
      color: ${textColor};
      font-size: 18px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        margin-bottom: 25px;
        font-size: 24px;
      }
    `,
    placesRoot: css`
      margin-bottom: 60px;
      @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 40px;
      }
      &::after {
        @media screen and (min-width: 768px) {
          content: "";
          display: block;
          width: 30%;
        }
      }
    `,
    placeItem: css`
      width: 100%;
      margin-bottom: 20px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      @media screen and (min-width: 768px) {
        width: 32%;
      }
    `,
    placeItemText: css`
      padding: 15px 4%;
      @media screen and (min-width: 768px) {
        min-height: 105px;
        padding: 15px;
      }
      p.prefecture {
        margin-bottom: 10px;
        font-size: 12px;
        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }
      h4 {
        line-height: 1.5em;
      }
      p.remarksField {
        margin-top: 10px;
        font-size: 12px;
        text-align: right;
      }
    `,
    placeItemLink: css`
      display: flex;
      justify-content: space-between;
      padding: 14px 4%;
      background-color: ${hostingBaseStyleMap.accentColor}20;
      border-radius: 0 0 5px 5px;
      @media screen and (min-width: 768px) {
        padding: 14px 15px;
      }
      a {
        display: flex;
        align-items: center;
        color: ${hostingBaseStyleMap.accentColor};
        font-size: 14px;
        font-weight: bold;
      }
    `,
    ticketsRoot: css`
      @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
      }
      &::after {
        @media screen and (min-width: 768px) {
          content: "";
          display: block;
          width: 32%;
        }
      }
    `,
    ticketsItem: css`
      margin-bottom: 20px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      @media screen and (min-width: 768px) {
        width: 32%;
      }
    `,
    ticketsItemText: css`
      padding: 15px 4%;
      background-color: #fff;
      border-radius: 5px;
      @media screen and (min-width: 768px) {
        min-height: 100px;
        padding: 15px;
      }
      p.remarksField {
        margin-bottom: 8px;
        color: ${hostingBaseStyleMap.accentColor};
        font-size: 12px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }
      h4 {
        line-height: 1.3em;
        white-space: pre-line;
      }
      p.price {
        width: 100%;
        margin-top: 12px;
        font-weight: bold;
      }
    `,
    ticketsItemPrivilege: css`
      padding: 15px 4%;
      font-size: 12px;
      background-color: ${hostingBaseStyleMap.accentColor}20;
      border-radius: 0 0 5px 5px;
      @media screen and (min-width: 768px) {
        padding: 15px;
      }
      li {
        padding-left: 1em;
        text-indent: -1em;
        line-height: 1.5em;
      }
    `,
  };
};

/**
 * hotfix対応として特定イベントのみ、イベントページ詳細下部のチケットは会場チケットのみとする。
 * @see https://github.com/balus-co-ltd/spwn/issues/1478
 * @see https://balus3d.slack.com/archives/C01C7KHJ2J0/p1650942924126359?thread_ts=1650942729.808789&cid=C01C7KHJ2J0
 */
const filterToFix1478 = (ticket: {
  eid: string;
  streamingType?: Parameters<typeof isVODTicket>[0]["streamingType"];
}) => {
  return (
    ["22102001-jpholostars2ndact", "22102002-engholostars2ndact"].includes(
      ticket.eid
    ) === false || !isVODTicket(ticket)
  );
};
