/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import type { Video } from "@spwn/types";

interface Props {
  status: Video["actionStatus"];
  onClick?: () => void;
  doButtonAction?: () => void;
}
export const EventTimetableIcon: React.FC<Props> = (props) => {
  switch (props.status) {
    case "NotAvailableForPurchase":
      return <ShoppingCartIcon />;

    case "NotPurchased":
      return (
        <ShoppingCartIcon
          css={css`
            fill: #fff !important;
            stroke: #00c2ad !important;
          `}
          onClick={props.doButtonAction}
        />
      );

    case "InCart":
      return (
        <ShoppingCartIcon
          css={css`
            fill: #00c2ad !important;
          `}
          onClick={props.doButtonAction}
        />
      );

    case "Playable":
      return (
        <PlayArrowIcon
          css={css`
            fill: #ea3376 !important;
          `}
          onClick={props.doButtonAction}
        />
      );

    case "NotPlayable":
      return null;

    case "BeforePlay":
      return <PlayArrowIcon />;

    default:
      return null;
  }
};
