/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";

import { EventInfo, getEventDisplayInfo } from "utility";
import type { Event } from "@spwn/types/firebase/firestore";
import { useI18n } from "hooks/i18n/i18n";
import { useGetEventList } from "hooks/event/useGetEventList";
import { PortalTopLinkButton } from "./PortalTopLinkButton";
import { PortalTopEventCard } from "./PortalTopEventCard";
import { PortalTopSectionLayout } from "./PortalTop";

const LIST_COUNT = 6;

export const PortalTopArchiveEventsFetcher: FC = () => {
  const { data: events } = useGetEventList({
    type: "openVOD",
    limit: LIST_COUNT,
  });
  return <PortalTopArchiveEventsLogic events={events} />;
};

const PortalTopArchiveEventsLogic: FC<{
  events?: Event[];
}> = ({ events }) => {
  const displayEvents = (events ?? []).map((event) =>
    getEventDisplayInfo(event)
  );

  if (displayEvents.length === 0) return null;

  return <PortalTopArchiveEvents displayEvents={displayEvents} />;
};

export const PortalTopArchiveEvents: FC<{
  displayEvents: EventInfo[];
}> = ({ displayEvents }) => {
  const { t } = useI18n();
  const styles = archiveListStyles();

  return (
    <PortalTopSectionLayout
      heading={{
        mainText: "ARCHIVES",
        subText: t("event.top.archiveEvent"),
      }}
    >
      <section>
        <div css={styles.layout}>
          {displayEvents.map((event, index) => {
            return (
              <React.Fragment key={`${event.eventId}_${index}`}>
                <PortalTopEventCard
                  href={`/events/${event.eventId}`}
                  tag={"動画配信中"}
                  tagColor={"#ff5f96"}
                  thumbnail={event.thumbnail}
                  date={event.eventOpenDateFull}
                  title={event.eventTitle}
                />
              </React.Fragment>
            );
          })}
        </div>

        <PortalTopLinkButton
          href={`/vod-events`}
          label={t("event.top.archiveEventList")}
        />
      </section>
    </PortalTopSectionLayout>
  );
};

const archiveListStyles = () => {
  return {
    root: css``,
    layout: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 28px;
      }
    `,
    item: css`
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;
    `,
    thumbnail: css`
      position: relative;
      .tag {
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px 10px;
        color: #ff5f96;
        font-size: 14px;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.7);
      }
      img {
        width: 100%;
      }
    `,
    info: css`
      padding: 16px;
      font-weight: bold;
      line-height: 1.5em;
      .date {
        margin-bottom: 12px;
        @media screen and (min-width: 768px) {
          margin-bottom: 8px;
        }
      }
      .title {
        line-height: 1.5em;
      }
    `,
  };
};
