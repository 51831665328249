/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import { PCView } from "components/common/MediaQuery";
import { EventInfo, toDoubleDigits } from "utility";
import { EventVideo } from "@spwn/types/firebase/firestore";

import { StreamingStandbyOverlay } from "./StreamingStandbyOverlay";
import { StreamingStandbyToggleDarkmode } from "./StreamingStandbyToggleDarkmode";
import { StreamingStandbyEventTitle } from "./StreamingStandbyEventTitle";
import { StreamingStandbyDescription } from "./StreamingStandbyDescription";
import { XShareButton } from "ui/XShareButton";

export const StreamingStandby: FC<{
  videoId: string;
  streamingStatus: "beforeLive" | "live" | "beforeVOD" | "vod" | "ended";
  displayEvent: EventInfo;
  currentVideo: EventVideo;
  isDarkModeEnabled: boolean;
}> = ({ currentVideo, displayEvent, ...props }) => {
  const thumbnail =
    currentVideo.thumbnail.length === 0
      ? displayEvent.thumbnail
      : currentVideo.thumbnail;
  const videoTitle = currentVideo.name
    ? `${displayEvent.eventTitle}【${currentVideo.name}】`
    : displayEvent.eventTitle;

  const [firstEventPart] = displayEvent.parts.slice(0, 1);

  const dt = firstEventPart
    ? new Date(firstEventPart.startTime.seconds * 1000)
    : undefined;
  const streamingStartAt = dt
    ? `${dt.getFullYear()}年${
        dt.getMonth() + 1
      }月${dt.getDate()}日 ${toDoubleDigits(dt.getHours())}:${toDoubleDigits(
        dt.getMinutes()
      )}`
    : "";

  return (
    <StreamingStandbyLayout
      isDarkModeEnabled={props.isDarkModeEnabled}
      StreamingStandbyOverlay={
        <StreamingStandbyOverlay
          thumbnail={thumbnail}
          videoTitle={videoTitle}
          streamingStatus={props.streamingStatus}
          startAt={streamingStartAt}
        />
      }
      StreamingStandbyToggleDarkmode={
        <StreamingStandbyToggleDarkmode
          isDarkModeEnabled={props.isDarkModeEnabled}
        />
      }
      StreamingStandbyEventTitle={
        <StreamingStandbyEventTitle
          text={videoTitle}
          isDarkModeEnabled={props.isDarkModeEnabled}
        />
      }
      StreamingStandbyTwitterShareButton={
        <XShareButton
          eventId={displayEvent.eventId}
          eventTitle={displayEvent.eventTitle}
          hashTag={displayEvent.twitterHashTag}
        />
      }
      StreamingStandbyDescription={
        <StreamingStandbyDescription
          isDarkModeEnabled={props.isDarkModeEnabled}
          description={displayEvent.description}
        />
      }
    />
  );
};

export const StreamingStandbyLoader: FC = () => {
  return (
    <StreamingStandbyLayout
      isDarkModeEnabled={false}
      StreamingStandbyOverlay={
        <div
          css={css`
            width: 100%;
            background-color: gray;
            aspect-ratio: 16 / 9;
          `}
        />
      }
      StreamingStandbyToggleDarkmode={
        <div
          css={css`
            width: 100px;
            height: 15px;
            margin-left: auto;
            background-color: gray;
          `}
        />
      }
      StreamingStandbyEventTitle={
        <div
          css={css`
            width: 100%;
            height: 16px;
            background-color: gray;
          `}
        />
      }
      StreamingStandbyTwitterShareButton={
        <div
          css={css`
            width: 90px;
            height: 24px;
            background-color: gray;
          `}
        />
      }
      StreamingStandbyDescription={
        <div
          css={css`
            p:not(:last-child) {
              margin-bottom: 1em;
            }
          `}
        >
          <p
            css={css`
              width: 100%;
              height: 14px;
              background-color: gray;
            `}
          />
          <p
            css={css`
              width: 100%;
              height: 14px;
              background-color: gray;
            `}
          />
          <p
            css={css`
              width: 100%;
              height: 14px;
              background-color: gray;
            `}
          />
          <p
            css={css`
              width: 100%;
              height: 14px;
              background-color: gray;
            `}
          />
        </div>
      }
    />
  );
};

const StreamingStandbyLayout: FC<{
  isDarkModeEnabled: boolean;
  StreamingStandbyOverlay: JSX.Element;
  StreamingStandbyToggleDarkmode: JSX.Element;
  StreamingStandbyEventTitle: JSX.Element;
  StreamingStandbyTwitterShareButton: JSX.Element;
  StreamingStandbyDescription: JSX.Element;
}> = ({
  isDarkModeEnabled,
  StreamingStandbyOverlay,
  StreamingStandbyToggleDarkmode,
  StreamingStandbyEventTitle,
  StreamingStandbyTwitterShareButton,
  StreamingStandbyDescription,
}) => {
  return (
    <section
      css={css`
        padding-top: 2.5%;
        padding-bottom: 120px;
        margin: 0 auto;
        background-color: ${isDarkModeEnabled ? "#000" : "#fff"};
        @media screen and (min-width: 768px) {
          width: 100%;
          max-width: 1800px;
          min-height: calc(100vh - 66px - 190px);
          padding-top: 0;
        }
      `}
    >
      <div
        id="Streaming"
        css={css`
          width: 95%;
          margin: 0 auto;
          @media screen and (min-width: 768px) {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px 20px;
            width: 80%;

            padding: 40px 0 0;
          }
        `}
      >
        <div>
          {/* 配信ステータスに応じた文言をオーバーレイで表示する */}
          {StreamingStandbyOverlay}

          {/* ダークモード切り替えボタンを表示する */}
          <PCView>
            <div
              css={css`
                margin-top: 8px;
              `}
            >
              {StreamingStandbyToggleDarkmode}
            </div>
          </PCView>
        </div>
      </div>

      <div
        css={css`
          width: 95%;
          margin: 24px auto 0;
          @media screen and (min-width: 768px) {
            width: 80%;
            margin: 40px auto 0;
          }
        `}
      >
        <div
          css={css`
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #e0e0e0;
            @media screen and (min-width: 768px) {
              padding-bottom: 32px;
            }
          `}
        >
          {StreamingStandbyEventTitle}
        </div>

        <div
          css={css`
            margin-bottom: 48px;
          `}
        >
          {StreamingStandbyTwitterShareButton}
        </div>

        {StreamingStandbyDescription}
      </div>
    </section>
  );
};
