import { useMemo } from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import firebase from "firebase";

import {
  Event,
  LotteryAppForm,
  Refference,
  Ticket,
} from "@spwn/types/firebase/firestore";
import { useGetEventList } from "hooks/event/useGetEventList";
import appConfig from "constants/appConfig";
import { IsJsonString } from "utility";
import { fetchFirestoreCollectionMap } from "utility/firebase";
import { useGetLotteryAppMap } from "./useGetLotteryAppMap";

// 大本のtypoは後で修正する
export type TicketReference = Ticket & Refference;

export type LotteryTicket = {
  // 抽選チケット対象のイベント
  event: Event;
  // 抽選申込内容
  lottery: LotteryAppForm;
  // 抽選チケット
  tickets: LotteryTicketRecord[];
};

export type LotteryTicketRecord = {
  ticket: TicketReference;
  purchaseCount: number;
};

type UseGetLotteryTicketList = () => {
  lotteryTicketList: LotteryTicket[];
  isLoading: boolean;
  error: unknown;
  withdrawLotteryTicketMutate: (eventId: string) => Promise<void>;
  isWithdrawLotteryTicketMutating: boolean;
};

export const useGetLotteryTicketList: UseGetLotteryTicketList = () => {
  const {
    data: openEvents,
    isLoading: isOpenEventsLoading,
    error: openEventError,
  } = useGetEventList({
    type: "lottery",
  });

  const openEventIds = openEvents
    ? (openEvents.map((event) => event._id).filter((v) => !!v) as string[])
    : [];

  // ユーザが申し込み中の抽選チケット内容を取得
  const {
    lotteryAppMap,
    isLoading: isWaitingTicketLoading,
    error: lotteryAppError,
  } = useGetLotteryAppMap({
    eventIds: openEventIds,
    enabled: openEventIds.length > 0,
  });

  // 処理対象のイベントID
  const eventIds = lotteryAppMap ? Object.keys(lotteryAppMap) : [];

  // 処理対象のイベントの全チケットを取得
  const {
    data: ticketListMap,
    isLoading: isTicketListLoading,
    error: ticketListError,
  } = useSWR(
    eventIds.length > 0 ? ["ticketList", eventIds] : null,
    async ([_, eventIds]) => fetchEventTicketListMap(eventIds)
  );

  const lotteryTicketList = useMemo(
    () =>
      (
        Object.entries(lotteryAppMap ?? {})
          .map(([eventId, lottery]) => {
            const event = openEvents?.find((event) => event._id === eventId);
            if (
              !event ||
              !ticketListMap ||
              ticketListMap[eventId] === undefined ||
              !lottery.cart
            ) {
              return undefined;
            }

            const tickets = (
              Object.entries(lottery.cart)
                .map(([ticketId, purchaseCount]) => {
                  const ticket = ticketListMap?.[eventId]?.[ticketId];
                  if (!ticket) {
                    return undefined;
                  }

                  return {
                    ticket,
                    purchaseCount,
                  };
                })
                .filter((v) => v !== undefined) as LotteryTicketRecord[]
            )
              // 優先度で昇順ソート
              .sort((a, b) => a.ticket.priority - b.ticket.priority);

            if (tickets.length === 0) {
              return undefined;
            }

            return {
              event,
              tickets,
              lottery,
            };
          })
          .filter((v) => v !== undefined) as LotteryTicket[]
      )
        // 抽選申込内容がisActiveのもののみ表示
        .filter((v) => v.lottery.isActive)
        // イベント日時で昇順ソート
        .sort((a, b) => a.event.datetime.seconds - b.event.datetime.seconds),
    [lotteryAppMap, openEvents, ticketListMap]
  );

  // 抽選チケットの申し込みをキャンセル
  const {
    trigger: withdrawLotteryTicketMutate,
    isMutating: isWithdrawLotteryTicketMutating,
  } = useSWRMutation(
    firebase.auth().currentUser?.uid
      ? ["lotteryAppMap", firebase.auth().currentUser?.uid, openEventIds]
      : null,
    async (_, { arg }: { arg: string }) => withdrawLotteryTicket(arg)
  );

  const isLoading =
    isOpenEventsLoading || isWaitingTicketLoading || isTicketListLoading;
  const error = openEventError || lotteryAppError || ticketListError;

  return {
    lotteryTicketList: isLoading ? [] : lotteryTicketList,
    isLoading,
    error,
    withdrawLotteryTicketMutate,
    isWithdrawLotteryTicketMutating,
  };
};

/**
 * 指定イベントの全チケットを取得
 *
 * @param eventIds
 */
const fetchEventTicketListMap = async (eventIds: string[]) => {
  if (eventIds.length === 0) {
    return {};
  }

  // fetchWaitingTicketで取得完了したイベント分だけQueryが発生する。同じくバックエンド側で処理したい。
  const ticketPromises: Promise<{ [key: string]: TicketReference }>[] = [];
  eventIds.forEach((eventId) => {
    ticketPromises.push(
      fetchFirestoreCollectionMap<TicketReference>(`tickets/${eventId}/tickets`)
    );
  });

  const results = await Promise.all(ticketPromises);

  const ticketListMap: { [key: string]: { [key: string]: TicketReference } } =
    {};
  results.forEach((v, i) => {
    const eventId = eventIds[i];
    if (!eventId || !v) {
      return;
    }

    ticketListMap[eventId] = v;
  });

  return ticketListMap;
};

export class BackendAppError extends Error {}

const withdrawLotteryTicket = async (eventId: string) => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error("not logged in");
  }

  const token = await currentUser.getIdToken(true);

  const endpoint = appConfig.CloudFunctions.cancelTicketLottery;
  if (!endpoint) {
    throw new Error("endpoint is not defined");
  }

  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `'Bearer ${token}`,
    },
    body: JSON.stringify({
      eid: eventId,
    }),
  });

  if (response.ok) {
    return;
  }

  const text = await response.text();
  if (IsJsonString(text)) {
    throw new BackendAppError(JSON.parse(text).msg);
  } else {
    throw new Error("unknown error");
  }
};
