/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useMemo, useState } from "react";
import { replaceBr, EventInfo } from "../../../utility";
import { useSelector } from "react-redux";
import { Store } from "store";
import { changeStyleWithHosting } from "utility/hosting";
import { useI18n, i18nextT, I18nTrans } from "hooks/i18n/i18n";
import { isAllowedReferrer, IS_FREE_PURCHASE } from "utility/ticket";
import type { Place, TicketConfig } from "@spwn/types/firebase/firestore";
import type { TicketData } from "@spwn/types/firebase/database";
import { sanitizer } from "utility/sanitize";
import { TextLink } from "ui/TextLink";

interface Props {
  displayTickets: TicketData[];
  selectedPlaceCode: string;
  placeMap: Record<string, Place>;
  ticketConfig: TicketConfig;
  event: EventInfo;
  // @ts-expect-error TS7006
  displaySubClass: (value) => void;
  // @ts-expect-error TS7006
  selectTicket: (e, ticketId: string) => void;
  // @ts-expect-error TS7006
  handleSelectorChange: (e, ticket: TicketData) => void;
}

const EventTicketSelectNum: React.FC<Props> = (props: Props) => {
  const PURCHASE_MAX_LIMIT = 99;

  const isAddressRegistered = useSelector(
    (state: Store) => state.auth.isAddressRegistered
  );
  const { t } = useI18n();
  const classesEventTicketSelectNumDesign = eventTicketSelectNumDesign();

  const [displayTickets, setDisplayTickets] = useState([]);

  useEffect(() => {
    (async () => {
      // SEE(takahashi): https://qiita.com/daishi/items/4423878a1cd7a0ab69eb
      const bits = await Promise.all(
        props.displayTickets.map(async (ticket) =>
          isAllowedReferrer(ticket._id, document.referrer)
        )
      );
      // @ts-expect-error TS2345
      setDisplayTickets(props.displayTickets.filter((_, i) => bits[i]));
    })();
  }, [props.displayTickets]);

  return (
    <div
      css={classesEventTicketSelectNumDesign.root}
      key={props.selectedPlaceCode}
    >
      <h3 css={classesEventTicketSelectNumDesign.heading}>
        <span>2</span>
        {t("event.ticket.headline.ticket", { selectTarget: "チケットの枚数" })}
      </h3>

      <div css={classesEventTicketSelectNumDesign.ticketLayout}>
        {displayTickets.map((ticket, i) => {
          const place = props.placeMap[props.selectedPlaceCode];
          const countOptions = [];

          let n_ticket = Math.min(
            // @ts-expect-error TS2339
            ticket.purchaseLimit,
            // @ts-expect-error TS18048
            props.ticketConfig.config.maxTicketPurchase
          );
          /**
           * n_ticketが99999など極端に大きい数値がループに渡されないように
           * PURCHASE_MAX_LIMITより大きい場合は、n_ticketにPURCHASE_MAX_LIMITが入るようにする
           */
          n_ticket = Math.min(n_ticket, PURCHASE_MAX_LIMIT);

          if (props.event.isLotteryTicketSellingMode) {
            for (let i = 0; i <= n_ticket; i++) {
              if (
                props.ticketConfig.hasSubClass &&
                // @ts-expect-error TS2339
                ticket.subClassName.length > 0
              ) {
                countOptions.push(
                  // @ts-expect-error TS2345
                  <option key={i} value={i + "_" + ticket.classGroupId}>
                    {i}
                  </option>
                );
              } else {
                countOptions.push(
                  // @ts-expect-error TS2345
                  <option id={ticket._id} key={i} value={i}>
                    {i}
                  </option>
                );
              }
            }
          } else {
            // @ts-expect-error TS2339
            n_ticket = Math.min(n_ticket, ticket.remaining);
            for (let i = 0; i <= n_ticket; i++) {
              if (
                props.ticketConfig.hasSubClass &&
                // @ts-expect-error TS2339
                ticket.subClassName.length > 0
              ) {
                countOptions.push(
                  // @ts-expect-error TS2345
                  <option key={i} value={i + "_" + ticket.classGroupId}>
                    {i}
                  </option>
                );
              } else {
                countOptions.push(
                  // @ts-expect-error TS2345
                  <option id={ticket._id} key={i} value={i}>
                    {i}
                  </option>
                );
              }
            }
          }
          // @ts-expect-error TS2339
          const isPurchased = ticket.remaining === 0;
          // @ts-expect-error TS2339
          const isSoldOut = ticket.remaining === -1;
          // @ts-expect-error TS2339
          const isFree = Number(ticket.price_jpy) <= 0 && !IS_FREE_PURCHASE();
          let sellStatus = "";
          if (!props.event.isLotteryTicketSellingMode && !isFree) {
            if (isPurchased) {
              sellStatus = "purchased";
            } else if (isSoldOut) {
              sellStatus = "sold_out";
            }
          }
          const alertInfo = ""; // eg. 限定21席 TODO@later
          return (
            <div
              className={sellStatus}
              css={classesEventTicketSelectNumDesign.ticketItem}
              key={i}
            >
              <div css={classesEventTicketSelectNumDesign.ticketItemText}>
                <div className="layout_l">
                  <p className="place">
                    {/* eslint-disable no-irregular-whitespace */}
                    {/*
                     // @ts-expect-error TS18048 */}
                    {place.areaName}　{alertInfo}
                    {/* eslint-enable */}
                  </p>
                  {/*
                   // @ts-expect-error TS2339 */}
                  <h4 className="name">{replaceBr(ticket.name)}</h4>
                  {/*
                   // @ts-expect-error TS2339 */}
                  {ticket.saleType !== "voucherOnly" && (
                    <p className="price">
                      {/*
                       // @ts-expect-error TS2339 */}
                      {ticket.price_jpy.toLocaleString()}
                      {i18nextT("pawCharge.yen")}{" "}
                      {isFree ? i18nextT("pawCharge.freeTicket") : ""}
                    </p>
                  )}
                </div>
                {isFree ||
                // @ts-expect-error TS2339
                ((ticket.isAddressRequired || ticket.isAddressRequiredNoFee) &&
                  !isAddressRegistered) ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <div className="layout_r">
                    {props.ticketConfig.hasSubClass &&
                    // @ts-expect-error TS2339
                    ticket.subClassName.length > 0 ? (
                      <select
                        defaultValue={"0"}
                        onChange={(e) => {
                          props.displaySubClass(e.target.value);
                        }}
                      >
                        {countOptions}
                      </select>
                    ) : (
                      <select
                        defaultValue={"0"}
                        onChange={(e) => {
                          props.handleSelectorChange(e, ticket); // check and add lottery cart
                          // @ts-expect-error TS2339
                          props.selectTicket(e, ticket._id); // only add to cart
                        }}
                      >
                        {countOptions}
                      </select>
                    )}
                  </div>
                )}
              </div>
              <AddressRequiredNotice
                // @ts-expect-error TS2339
                isAddressRequired={ticket.isAddressRequired}
                // @ts-expect-error TS2339
                isAddressRequiredNoFee={ticket.isAddressRequiredNoFee}
                isAddressRegistered={isAddressRegistered}
              />

              {/*
               // @ts-expect-error TS2339 */}
              {ticket.description ? (
                <div
                  css={classesEventTicketSelectNumDesign.ticketItemBenefits}
                  dangerouslySetInnerHTML={{
                    // @ts-expect-error TS2339
                    __html: sanitizer(ticket.description),
                  }}
                ></div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default EventTicketSelectNum;

type AddressRequiredNoticeProps = {
  isAddressRequired: boolean;
  isAddressRequiredNoFee: boolean;
  isAddressRegistered: boolean;
};
const AddressRequiredNotice = (props: AddressRequiredNoticeProps) => {
  // const { t } = useI18n();
  const classes = useMemo(() => {
    return css`
      padding: 0 15px 15px;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.5em;
      letter-spacing: 1px;
    `;
  }, []);

  // add notice
  if (props.isAddressRequired || props.isAddressRequiredNoFee) {
    return (
      <p css={classes}>
        <I18nTrans i18nKey="event.ticket.requiredNotice">
          このチケットを購入するには
          <TextLink href={"/account/address"}>こちら</TextLink>
          で住所登録が必要です。
        </I18nTrans>
      </p>
    );
  }
  return null;
};

const eventTicketSelectNumDesign = () => {
  const hostingBaseStyleMap = changeStyleWithHosting().baseStyles;
  return {
    root: css``,
    heading: css`
      margin-bottom: 25px;
      display: flex;
      font-size: 18px;
      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
        font-size: 25px;
      }
      span {
        display: block;
        margin-right: 5%;
        font-family: "din-condensed", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 35px;
        @media screen and (min-width: 768px) {
          margin-right: 20px;
          font-size: 43px;
        }
      }
    `,
    ticketLayout: css`
      @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
      }
      &::after {
        @media screen and (min-width: 768px) {
          content: "";
          display: block;
          width: 305px;
        }
      }
    `,
    ticketItem: css`
      position: relative;
      margin-bottom: 25px;
      border: 1px solid #d2d2d2;
      @media screen and (min-width: 768px) {
        width: 305px;
      }
      &.purchased::after,
      &.sold_out::after {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-family: "din-condensed", sans-serif;
        color: #fff;
        text-align: center;
        font-weight: 400;
        font-style: normal;
        background-color: rgba(0, 0, 0, 0.5);
      }
      &.purchased::after {
        content: "";
        font-size: 30px;
      }
      &.sold_out::after {
        content: "SOLD OUT";
        font-size: 35px;
      }
    `,
    ticketItemText: css`
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 15px 15px 20px;
      .layout_l {
        width: calc(100% - 90px);
        margin-right: 15px;
        p.place {
          margin-bottom: 8px;
          color: ${hostingBaseStyleMap.accentColor};
          font-size: 11px;
          font-weight: bold;
          @media screen and (min-width: 768px) {
            font-size: 14px;
          }
        }
        p.price {
          margin-top: 12px;
          font-weight: bold;
        }
      }
      .layout_r {
        width: 75px;
      }
    `,
    ticketItemBenefits: css`
      padding: 15px;
      font-size: 10px;
      background-color: ${hostingBaseStyleMap.accentColor}20;
      li {
        padding-left: 1em;
        text-indent: -1em;
        line-height: 1.5em;
      }
    `,
  };
};
