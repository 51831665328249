import styled from "@emotion/styled";

export const AuthContent = styled.div`
  position: relative;
  padding: 75px 0;
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    min-height: 100vh;
  }
  .MuiSvgIcon-root {
    /* 利用されている？ */
    position: absolute;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    @media screen and (min-width: 768px) {
      width: 50px;
      height: 50px;
    }
  }
`;

AuthContent.displayName = "AuthContent";
