import React, { FC } from "react";

export const SpwnLogo: FC = () => {
  return (
    <img
      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNjYgMjQiPjxnIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyI+PHBhdGggZD0iTTQ1LjA3OCAxOC4zMzJsMjkuMDA3LTMuNDA4YTIuMjk3IDIuMjk3IDAgMDAyLjA0Ni0yLjI3MlYzLjU5NmMwLTEuMjY1LTEuMDU2LTIuMjcxLTIuMzEtMi4yNzFINDIuNzY4Yy0xLjI4NyAwLTIuMzEgMS4wMzgtMi4zMSAyLjI3MVYyMy40Nmg0LjY1M3YtNS4xMjhoLS4wMzN6bTAtMTIuNDMxaDI2LjR2NC43MzhsLTI2LjQgMy4xMTZWNS45MDF6TTM2LjY2MyA1LjlWMS4zMjZIMi4zMUMxLjAyMyAxLjMyNSAwIDIuMzYzIDAgMy41OTZ2Ny4zMzZjMCAxLjIuOTI0IDIuMTc0IDIuMTQ1IDIuMjcybDMwLjQ5MiAyLjQwMXYyLjkyMUguMzYzdjQuNTc3aDM0LjU4NGMxLjI4NyAwIDIuMzEtMS4wMzkgMi4zMS0yLjI3MnYtNy4zMzVjMC0xLjIwMS0uOTI0LTIuMTc1LTIuMTQ1LTIuMjcyTDQuNjIgOC44NTR2LTIuOTJoMzIuMDQzVjUuOXpNMTA1LjEwNSAxOC40NjFWMy41MzFjMC0xLjI2NS0xLjA1Ni0yLjI3MS0yLjMxLTIuMjcxaC0yLjYwN2MtLjY2IDAtMS4zMi4yOTItMS43NDkuNzc5TDgzLjgyIDE4LjQ5NFYxaC00LjY1M3YxOS43OThjMCAxLjI2NiAxLjA1NiAyLjI3MiAyLjMxIDIuMjcyaDMuMzMzYy42NiAwIDEuMzItLjI5MiAxLjc0OS0uNzc5TDEwMC40NTIgNi42OHYxNC4xMThjMCAxLjI2NiAxLjA1NiAyLjI3MiAyLjMxIDIuMjcyaDMuMzY2Yy42OTMgMCAxLjMyLS4yOTIgMS43NDktLjgxMUwxMjYuNDU2IDFoLTYuMTM4bC0xNS4yMTMgMTcuNDYxek0xMzEuNTA1IDUuOWgxLjE1NWwyNC40NTMgMTYuNzQ4Yy4zOTYuMjYuODU4LjQyMiAxLjMyLjQyMmg0LjI1N2MxLjI4NyAwIDIuMzEtMS4wMzggMi4zMS0yLjI3MlYxaC00LjY1M3YxNy40OTRoLTEuMTU1TDEzNC43MzkgMS43NDZjLS4zOTYtLjI2LS44NTgtLjQyMS0xLjMyLS40MjFoLTQuMjI0Yy0xLjI4NyAwLTIuMzEgMS4wMzgtMi4zMSAyLjI3MXYxOS44MzFoNC42NTNWNS45MDFoLS4wMzN6Ii8+PC9nPjwvc3ZnPg=="
      alt="SPWN"
    />
  );
};
