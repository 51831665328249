/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { cvsInfo } from "constants/purchase";
import arrow from "designs/images/streaming/arrow_down.png";
import { useI18n } from "hooks/i18n/i18n";
import type { CVSCode } from "@spwn/types/gmo";

interface Props {
  isDarkModeEnabled: boolean;
  setCvesCode: React.Dispatch<React.SetStateAction<CVSCode>>;
  setCustomerName: React.Dispatch<React.SetStateAction<string>>;
  setCustomerKana: React.Dispatch<React.SetStateAction<string>>;
  setTelNo: React.Dispatch<React.SetStateAction<string>>;
  openChangePaymentMethod: () => void;
  canCvsPurchase: () => boolean;
  startPurchaseProcess: () => void;
}
export const StreamingLiveCommerceConveniencePayment: React.FC<Props> = (
  props
) => {
  const classesLiveCommerceCreditPaymentStyles =
    liveCommerceCreditPaymentStyles({
      isDarkModeEnabled: props.isDarkModeEnabled,
    });
  const { t } = useI18n();

  return (
    <React.Fragment>
      <div css={classesLiveCommerceCreditPaymentStyles.methodOfPayment}>
        <p>{t("streaming.commerceConveniencePayment.paymentMethod")}</p>
        <p className="change" onClick={props.openChangePaymentMethod}>
          {t("streaming.commerceConveniencePayment.changeButton")}
        </p>
      </div>

      <div css={classesLiveCommerceCreditPaymentStyles.cvsPayment}>
        <p>{t("streaming.commerceConveniencePayment.convenienceCheckout")}</p>
        <select
          css={classesLiveCommerceCreditPaymentStyles.selectCvs}
          onChange={(e) => props.setCvesCode(e.currentTarget.value as CVSCode)}
        >
          {/*
           // @ts-expect-error TS2322 */}
          <option value={null}>----</option>
          {Object.keys(cvsInfo()).map((el, index) => {
            return (
              <option value={el} key={index}>
                {/*
                 // @ts-expect-error TS7053 */}
                {cvsInfo()[el]}
              </option>
            );
          })}
        </select>
      </div>

      <div css={classesLiveCommerceCreditPaymentStyles.inputInfo}>
        <p>{t("streaming.commerceConveniencePayment.name")}</p>
        <input
          type="text"
          onChange={(e) => props.setCustomerName(e.target.value)}
        />
      </div>

      <div css={classesLiveCommerceCreditPaymentStyles.inputInfo}>
        <p>{t("streaming.commerceConveniencePayment.kana")}</p>
        <input
          type="text"
          onChange={(e) => props.setCustomerKana(e.target.value)}
        />
      </div>

      <div css={classesLiveCommerceCreditPaymentStyles.inputInfo}>
        <p>{t("streaming.commerceConveniencePayment.phone")}</p>
        <input type="text" onChange={(e) => props.setTelNo(e.target.value)} />
      </div>

      <div
        css={classesLiveCommerceCreditPaymentStyles.button}
        // @ts-expect-error TS2322
        className={props.canCvsPurchase() ? null : "disabled"}
        onClick={props.startPurchaseProcess}
      >
        {t("streaming.commerceConveniencePayment.confirmPurchase")}
      </div>
    </React.Fragment>
  );
};

interface LiveCommerceCreditPaymentStylesProps {
  isDarkModeEnabled: boolean;
}
const liveCommerceCreditPaymentStyles = (
  props?: LiveCommerceCreditPaymentStylesProps
) => {
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;

  const methodOfPayment = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    p {
      ${textColorInDarkMode}
      font-size: 14px;
    }
    .change {
      color: #00c2ae;
      cursor: pointer;
    }
  `;

  const cvsPayment = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    p {
      ${textColorInDarkMode}
      font-size: 14px;
    }
  `;
  const selectCvs = css`
    width: 180px;
    padding: 8px;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 1px;
    background-color: #f4f4f4;
    background-image: url(${arrow});
    background-size: 8px auto;
    background-repeat: no-repeat;
    background-position: 94% center;
    border: 1px solid #dedede;
    border-radius: 5px;
    appearance: none;
  `;
  const inputInfo = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    p {
      width: 56px;
      margin-right: 24px;
      ${textColorInDarkMode}
      font-size: 14px;
    }
    input[type="text"] {
      width: calc(100% - 80px);
      padding: 8px;
      font-size: 16px;
      background-color: #fff;
      border: 1px solid #b1b1b1;
      border-radius: 5px;
      letter-spacing: 1px;
    }
  `;

  const button = css`
    padding: 12px 0;
    margin-top: 24px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    background-color: #f33074;
    cursor: pointer;
    &.disabled {
      background-color: #b1b1b1;
    }
  `;
  const register = css`
    padding: 25px 0 15px;
    p {
      font-size: 14px;
      text-align: center;
      line-height: 1.5em;
      span {
        color: #00c2ae;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  `;
  return {
    methodOfPayment,
    cvsPayment,
    selectCvs,
    inputInfo,
    button,
    register,
  };
};
