import firebase from "firebase/app";

/**
 * HttpsErrorのdetailsを上書き
 */
type HttpsError<T> = Omit<firebase.functions.HttpsError, "details"> & {
  details?: T;
};

/**
 * [モックアップ] HttpsErrorのdetailsに型をつける
 * 
 * FIXME いい案あったら改良してくれていいです
 * @see https://www.notion.so/balusco/httpsCallable-function-9a0e983a0f5e4191bac9ca78b28474da
 * @example
 * ```
   } catch (error) {
     if (hasHttpsError<ErrorDetails>(error)) {
      // error.code、error.message, error.details によって処理を行う
    }
 * ```
 */
export const hasHttpsError = <T>(e: unknown): e is HttpsError<T> =>
  // @ts-expect-error TS18047
  typeof e === "object" && "message" in e && "code" in e && "details" in e;
