import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import sample, { sampleState } from "./sample";
import menu, { menuState } from "./menu";
import event, { eventState } from "./event";
import ticket, { ticketState } from "./ticket";
import flowerStand, { flowerStandState } from "./flowerStand";
import cart, { cartState } from "./cart";
import firestore, { firestoreState } from "./firestore";
import auth, { authState } from "./auth";
import modal, { modalState } from "./modal";
import loading, { loadingState } from "./loading";
import purchase, { purchaseState } from "./purchase";
import admin, { adminState } from "./admin";
import live, { liveState } from "./live";
import streaming, { streamingState } from "./streaming";
import news, { newsState } from "./news";
import notification, { notificationState } from "./notification";
import voucher from "./voucher";
import type { VoucherState } from "@spwn/types";

export type AppState = {
  router: RouterState;
  sample: sampleState;
  menu: menuState;
  event: eventState;
  ticket: ticketState;
  flowerStand: flowerStandState;
  cart: cartState;
  firestore: firestoreState;
  auth: authState;
  modal: modalState;
  loading: loadingState;
  purchase: purchaseState;
  admin: adminState;
  live: liveState;
  streaming: streamingState;
  news: newsState;
  notification: notificationState;
  voucher: VoucherState;
};

const reducers = (history: History) =>
  combineReducers<AppState>({
    router: connectRouter(history),
    sample,
    menu,
    event,
    ticket,
    flowerStand,
    cart,
    firestore,
    auth,
    modal,
    loading,
    purchase,
    admin,
    live,
    streaming,
    news,
    notification,
    voucher,
  });

export default reducers;
