/** @jsxRuntime classic /
/** @jsx jsx */
import { jsx } from "@emotion/core";

import React from "react";
import { GiftItemData } from "../../modules/streaming";

import { CommentItemNormal } from "./commentItem/CommentItemNormal";
import { CommentItemSuperChat } from "./commentItem/CommentItemSuperChat";
import { CommentItemPurchasedHiddenName } from "./commentItem/CommentItemPurchasedHiddenName";
import type { ProductData, UserMessage } from "@spwn/types/firebase/firestore";

// Implemented in the future

interface Props {
  eventId: string;
  message: UserMessage;
  isCommentUser: boolean;
  isDarkModeEnabled?: boolean;
  giftItemMap: { [key: string]: GiftItemData };
  eventGoodsMap: { [key: string]: ProductData };
  giftAmountList: number[];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  msgBanFunction: (idx: any) => Promise<void>;
  msgDeleteFunction: (idx: any) => Promise<void>;
  // 本来の型がわからないのでとりあえずanyにしておく
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

/**
 * PC表示におけるコメントエリア内のコメント単体
 * @param props
 */
export const StreamingCommentItem: React.FC<Props> = (props) => {
  const { isSuperChat, isPurchased, purchasedProductId } = props.message;
  const purchasedProduct: ProductData = isPurchased
    ? // @ts-expect-error TS2538
      props.eventGoodsMap?.[purchasedProductId]
    : null;

  return (
    <React.Fragment>
      {
        // 普通のコメントの時の表示
        !isSuperChat && !isPurchased && (
          <CommentItemNormal
            message={props.message}
            isCommentUser={props.isCommentUser}
            // @ts-expect-error TS2322
            isDarkModeEnabled={props.isDarkModeEnabled}
            msgBanFunction={props.msgBanFunction}
            msgDeleteFunction={props.msgDeleteFunction}
          />
        )
      }

      {
        // スパチャコメントの時の表示
        isSuperChat && !isPurchased && (
          <CommentItemSuperChat
            message={props.message}
            giftItemMap={props.giftItemMap}
            giftAmountList={props.giftAmountList}
            msgBanFunction={props.msgBanFunction}
          />
        )
      }

      {
        // 購入した時の表示
        // 名前を表示しない時の表示
        !isSuperChat && isPurchased && (
          // && !isDisplayName
          <CommentItemPurchasedHiddenName purchasedProduct={purchasedProduct} />
        )
      }

      {
        // Implemented in the future
        // 名前を表示する場合の表示
        // !isSuperChat
        //   && isPurchased
        //   && isDisplayName
        //   &&  <CommentItemPurchasedDisplayName
        //         message={props.message}
        //         purchasedProduct={purchasedProduct}
        //       />
      }
    </React.Fragment>
  );
};
