import { useCallback } from "react";
import firebase from "firebase/app";
import {
  PurchaseWithStripeErrorDetails,
  PurchaseWithStripeRequest,
  PurchaseWithStripeResponse,
} from "@spwn/types/functions";
import { useIdempotencyKey } from "./useIdempotencyKey";
import { useDispatch } from "react-redux";
import { modalActions } from "modules/modal";
import { hasHttpsError } from "utility/functionsError";
import { loadingActions } from "modules/loading";
import { i18nextT } from "hooks/i18n/i18n";

export class DuplicateTransactionIdError extends Error {}

export type PurchaseProps = {
  tenantId: string;
  totalAmount: number;
};

export type PurchaseFunction = (props: PurchaseProps) => Promise<void>;

/**
 *
 * @param callPurchaseAPI mock用なので渡さなくて良い
 */
export const usePurchaseWithStripe = (
  callPurchaseAPI: (
    req: PurchaseWithStripeRequest
  ) => Promise<PurchaseWithStripeResponse> = callPurchase
): PurchaseFunction => {
  const dispatch = useDispatch();
  const [idempotencyKey, regenerateKey] = useIdempotencyKey();

  return useCallback<PurchaseFunction>(
    async ({ tenantId, totalAmount }) => {
      try {
        dispatch(
          loadingActions.toggleLoading({
            msg: i18nextT("purchase.purchase.nowSettlement"),
          })
        );
        const response = await callPurchaseAPI({
          tenantId,
          totalAmount,
          idempotencyKey,
        });
        /**
         * Stripeの決済ページへリダイレクト
         * 決済方法はStripe側で選択する
         */
        window.location.href = response.url;
      } catch (error: unknown) {
        console.error(error);
        if (hasHttpsError<PurchaseWithStripeErrorDetails>(error)) {
          if (error?.details?.errorType === "AlreadyExistsFingerprintError") {
            // 同じ内容のリクエストを検知
            dispatch(
              modalActions.toggleError({
                msg: i18nextT("purchase.purchase.duplicatedRequestError"),
              })
            );
          } else if (
            error?.details?.errorType === "AlreadyExistsOrderIdError"
          ) {
            // たまたま注文番号の発行に失敗した可能性がある
            dispatch(
              modalActions.toggleError({
                msg: i18nextT("purchase.purchase.congestionPurchase"),
              })
            );
          } else if (
            error?.details?.errorType === "PurchaseDisplayPriceError"
          ) {
            dispatch(
              modalActions.toggleActionModal({
                actionModalType: "confirmAction",
                action: () => {
                  if (window) {
                    window.location.reload();
                  }
                },
                caption: "Error",
                msg: i18nextT("purchase.purchase.invalidDisplayPrice"),
                btnMsg: "ページを更新する",
              })
            );
          } else {
            dispatch(modalActions.toggleError({ msg: error.message }));
          }
          regenerateKey();
          return;
        }
        dispatch(modalActions.toggleError({ msg: error as string }));
      } finally {
        dispatch(loadingActions.toggleLoading({}));
      }
    },
    [idempotencyKey, regenerateKey] // eslint-disable-line react-hooks/exhaustive-deps
  );
};

const callPurchase = async (
  request: PurchaseWithStripeRequest
): Promise<PurchaseWithStripeResponse> => {
  const response = await firebase
    .functions()
    .httpsCallable("purchaseWithStripe")(request);
  return response.data;
};
