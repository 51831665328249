/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

interface Props {
  width: number;
  height: number;
  fontColor?: string;
  isDarkModeEnabled?: boolean;
  className?: string;
}
export const ChargeIcon: React.FC<Props> = (props) => {
  const svgColorStyle = svgColor({
    width: props.width,
    height: props.height,
    isDarkModeEnabled: props.isDarkModeEnabled,
    fontColor: props.fontColor,
  });
  return (
    <div id="ChargeIcon" css={svgColorStyle.root}>
      <svg viewBox="0 0 64 64" css={svgColorStyle.svg}>
        <g
          id="03_ico/account/pawchargehistory"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Group-8"
            transform="translate(8.000000, 9.000000)"
            fill="#3A3D44"
          >
            <path
              d="M29.1416,22.9424 C29.1226,23.2224 29.0666,23.4574 28.9696,23.6464 C28.8836,23.8214 28.7536,23.9564 28.5706,24.0564 C28.3766,24.1634 28.0696,24.2184 27.6806,24.2184 L26.9586,24.2184 L26.9586,19.4314 L27.7686,19.4314 C28.2376,19.4314 28.4836,19.5174 28.6056,19.5884 C28.7776,19.6894 28.9016,19.8304 28.9876,20.0194 C29.0836,20.2314 29.1396,20.4874 29.1516,20.7804 C29.1656,21.1174 29.1736,21.4734 29.1736,21.8484 C29.1736,22.2494 29.1626,22.6144 29.1416,22.9424 M31.7676,19.4124 C31.6066,18.7834 31.3116,18.2274 30.8926,17.7594 C30.4726,17.2904 29.9946,16.9584 29.4696,16.7704 C28.9666,16.5904 28.3796,16.5004 27.7256,16.5004 L23.9996,16.5004 L23.9996,33.5004 L26.9586,33.5004 L26.9586,27.1514 L27.7466,27.1514 C28.6396,27.1514 29.3786,26.9854 29.9456,26.6584 C30.5126,26.3314 30.9716,25.8684 31.3086,25.2864 C31.5956,24.8044 31.7866,24.2834 31.8746,23.7364 C31.9566,23.2184 31.9996,22.5764 31.9996,21.8254 C31.9996,20.8234 31.9216,20.0114 31.7676,19.4124"
              id="Fill-1"
            ></path>
            <g id="Group-5">
              <path
                d="M4.3184,20.5811 C4.3184,11.6061 11.6424,4.3051 20.6464,4.3051 C22.2174,4.3051 23.7334,4.5391 25.1724,4.9541 C14.8094,6.0441 6.7074,14.8041 6.7074,25.4181 C6.7074,26.9281 6.8784,28.3981 7.1934,29.8151 C7.1894,29.8011 7.1844,29.7861 7.1814,29.7711 C5.3784,27.1531 4.3184,23.9901 4.3184,20.5811 M45.4754,17.4761 C45.3344,19.1271 44.4514,20.5561 43.1744,21.4661 C43.4944,22.7341 43.6814,24.0531 43.6814,25.4181 C43.6814,34.3941 36.3574,41.6951 27.3534,41.6951 C18.3494,41.6951 11.0244,34.3941 11.0244,25.4181 C11.0244,16.4421 18.3494,9.1411 27.3534,9.1411 C28.3184,9.1411 29.2574,9.2401 30.1764,9.4011 C31.0364,7.8141 32.6344,6.6861 34.5244,6.5241 C34.5524,6.1911 34.6154,5.8691 34.7004,5.5571 C31.0124,2.1211 26.0814,0.0001 20.6464,0.0001 C9.2614,0.0001 0.0004,9.2321 0.0004,20.5811 C0.0004,28.9251 5.0144,36.1091 12.1884,39.3381 C15.9644,43.4221 21.3564,46.0001 27.3534,46.0001 C38.7384,46.0001 48.0004,36.7681 48.0004,25.4181 C48.0004,22.5411 47.3924,19.8091 46.3174,17.3221 C46.0434,17.3911 45.7644,17.4511 45.4754,17.4761"
                id="Fill-3"
              ></path>
            </g>
            <path
              d="M45,10 L42,10 L42,7 C42,5.896 41.104,5 40,5 C38.896,5 38,5.896 38,7 L38,10 L35,10 C33.896,10 33,10.896 33,12 C33,13.104 33.896,14 35,14 L38,14 L38,17 C38,18.104 38.896,19 40,19 C41.104,19 42,18.104 42,17 L42,14 L45,14 C46.104,14 47,13.104 47,12 C47,10.896 46.104,10 45,10"
              id="Fill-6"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

interface svgStyle {
  width: number;
  height: number;
  isDarkModeEnabled?: boolean;
  fontColor?: string;
}
const svgColor = (props?: svgStyle) => {
  // @ts-expect-error TS18048
  const svgColorInDarkMode = props.isDarkModeEnabled ? "#fff" : props.fontColor;
  return {
    root: css`
      position: relative;
      text-align: center;
    `,
    svg: css`
      width: ${
        // @ts-expect-error TS18048
        props.width
      }px;
      height: ${
        // @ts-expect-error TS18048
        props.height
      }px;
      fill: ${svgColorInDarkMode} rect {
        stroke: ${svgColorInDarkMode};
      }
      path {
        fill: ${svgColorInDarkMode};
      }
    `,
  };
};
