/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import LiveAnimation from "components/event/detail/videoList/LiveAnimation";
import PlayButton from "components/event/detail/videoList/PlayButton";

interface Props {
  displayEvent: { title: string; isOpenStreamingPage: boolean };
  videoThumbnail: string;
  isOpen: boolean;
  canWatch: boolean;
  isOpenLiveStreamingPage: boolean;
  goLive: () => void;
}
export const LiveListItemThumbnail: React.FC<Props> = (props) => {
  const classes = styles();
  return (
    <div css={classes.root}>
      <img src={props.videoThumbnail} alt={props.displayEvent.title} />
      {props.canWatch &&
        props.displayEvent.isOpenStreamingPage &&
        props.isOpen && <PlayButton action={props.goLive} />}
      {props.isOpenLiveStreamingPage && props.isOpen && <LiveAnimation />}
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      position: relative;
      img {
        width: 100%;
      }
    `,
  };
};
