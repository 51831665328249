/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { Link } from "react-router-dom";

import { changeStyleWithHosting } from "utility/hosting";

import { BellNotification } from "containers/account/notification/HeaderNotification";

import DehazeIcon from "@material-ui/icons/Dehaze";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

interface Props {
  uid: string;
  isEmptyCart: boolean;
  isDisplayLogo: boolean;
  domainLogo: JSX.Element;
  isBackIconRoute: boolean;
  HeaderMenuItems: { name: string; link: string; display: boolean }[];
  toggleSideMenu: () => void;
  toggleMyCart: () => void;
  toggleLoginMethod: () => void;
  goBack: () => void;
}
export const HeaderSpDisplay: React.FC<Props> = (props) => {
  const classesHeaderForSp = headerDesignForSp();

  return (
    <header css={classesHeaderForSp.header}>
      <div css={classesHeaderForSp.container}>
        {props.isBackIconRoute ? (
          <div css={classesHeaderForSp.backToPrev} onClick={props.goBack}>
            <KeyboardArrowLeftIcon />
          </div>
        ) : (
          <div css={classesHeaderForSp.openMenu} onClick={props.toggleSideMenu}>
            <DehazeIcon />
          </div>
        )}

        {props.isDisplayLogo && (
          <h1 css={classesHeaderForSp.logo}>
            {/*
             // @ts-expect-error TS2532 */}
            <Link to={props.HeaderMenuItems[0].link}>{props.domainLogo}</Link>
          </h1>
        )}

        <div css={classesHeaderForSp.iconList}>
          {props.uid ? (
            <React.Fragment>
              <BellNotification />
              <div>
                <button
                  id="cart"
                  css={resetButtonStyles}
                  className={props.isEmptyCart ? "" : "putBadge"}
                  onClick={props.toggleMyCart}
                >
                  <ShoppingCartIcon aria-label="Cart" />
                  <span></span>
                </button>
              </div>
            </React.Fragment>
          ) : (
            <button
              id="login"
              css={resetButtonStyles}
              className={props.isEmptyCart ? "" : "putBadge"}
              onClick={props.toggleLoginMethod}
            >
              <ExitToAppIcon aria-label="Login" />
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

const headerDesignForSp = () => {
  const { textColor } = changeStyleWithHosting().commonSettings.header;
  const { svgColor } = changeStyleWithHosting().commonSettings.header;
  return {
    header: css`
      display: block;
      padding: 12px 0;
    `,
    container: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto;
    `,
    logo: css`
      height: 20px;
      img {
        height: 100%;
      }
    `,
    backToPrev: css`
      width: 30px;
      height: 30px;
      svg {
        fill: ${textColor};
      }
    `,
    openMenu: css`
      width: 30px;
      svg {
        fill: ${textColor};
      }
    `,
    iconList: css`
      display: flex;
      #badge {
        margin-right: 10px;
      }
      #cart {
        svg {
          fill: ${textColor};
          cursor: pointer;
        }
        &.putBadge {
          position: relative;
          span {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 10px;
            height: 10px;
            background-color: #f00;
            border-radius: 50%;
            border: 1px solid #fff;
          }
        }
      }
      #bell svg {
        fill: ${textColor};
        cursor: pointer;
      }
      #login svg {
        fill: ${svgColor};
        cursor: pointer;
      }
    `,
  };
};

const resetButtonStyles = css`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;
