/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/core";
import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import { createVariable, variables } from "./common/variables";
import { Link } from "react-router-dom";

type ButtonStyleProps = {
  margin?: SerializedStyles;
  variant?: "solid" | "outline" | "ghost";
  size?: "full" | "md" | "sm";
  prefix?: JSX.Element;
  suffix?: JSX.Element;
  children: string;
};

type OmittedStyleStyleProps = "css" | "style" | "className";

export const LinkButton = forwardRef<
  ElementRef<"a">,
  { href: string } & ButtonStyleProps &
    Omit<
      ComponentPropsWithoutRef<"a">,
      OmittedStyleStyleProps | keyof ButtonStyleProps
    >
>(function LinkButton(props, ref) {
  const propsWithStyles = withStyles(props);
  if (props.href.startsWith("https://")) {
    return (
      // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/anchor-has-content.md;
      <a ref={ref} {...propsWithStyles}>
        {propsWithStyles.children}
      </a>
    );
  } else {
    const { href, ...rest } = props;
    return <Link to={href} {...withStyles(rest)} />;
  }
});

export const Button = forwardRef<
  ElementRef<"button">,
  ButtonStyleProps &
    Omit<
      ComponentPropsWithoutRef<"button">,
      OmittedStyleStyleProps | keyof ButtonStyleProps
    >
>(function PrimaryButton(props, ref) {
  return <button ref={ref} {...withStyles(props)} />;
});

const variableWidth = createVariable("ui-button-width");
const variablePadding = createVariable("ui-button-padding");
const variableFontWeight = createVariable("ui-button-font-weight");

const withStyles = <T extends ButtonStyleProps>({
  margin,
  variant = "solid",
  size = "md",
  prefix,
  suffix,
  children,
  ...rest
}: T) => {
  return {
    css: [
      margin,
      styleBase,
      variant === "solid" && styleSolid,
      variant === "outline" && styleOutline,
      variant === "ghost" && styleGhost,
    ],
    /**
     * NOTE: 脱emotionも視野に入れて、動的な部分と静的な部分を分離する試み
     * vanilla-extractの `createVar` に似せていて、emotionの best practice に載っている方法でもある
     * - https://vanilla-extract.style/documentation/api/create-var/
     * - https://emotion.sh/docs/best-practices#advanced-css-variables-with-style
     */
    style: variables(
      size === "full" && {
        [variableWidth]: "100%",
        [variablePadding]: "13px 16px",
        [variableFontWeight]: "bold",
      },
      size === "md" && {
        [variableWidth]: "max-content",
        [variablePadding]: "13px 16px",
        [variableFontWeight]: "bold",
      },
      size === "sm" && {
        [variableWidth]: "max-content",
        [variablePadding]: "4px 8px",
        [variableFontWeight]: "medium",
      }
    ),
    children: (
      <React.Fragment>
        {prefix}
        <span>{children}</span>
        {suffix}
      </React.Fragment>
    ),
    ...rest,
  };
};

const styleBase = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: var(${variableWidth});
  padding: var(${variablePadding});
  font-family: var(--font-base);
  font-size: 14px;
  line-height: 20px;
  font-weight: var(${variableFontWeight});
  text-align: center;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;

const styleSolid = css`
  color: #fff;
  background-color: #00c2ad;
  border: none;
  &:hover {
    background-color: #49aca0;
  }
  &:active {
    background-color: #1e8a7c;
  }
  &:disabled {
    background-color: #b1b1b1;
  }
`;

const styleOutline = css`
  color: #00c2ad;
  background-color: transparent;
  border: 1px solid #00c2ad;
  border-radius: 6px;
  &:active {
    background-color: #e7fefd;
  }
  &:disabled {
    opacity: 0.6;
  }
`;

const styleGhost = css`
  color: #00c2ae;
  background-color: transparent;
  border: none;
  &:active {
    background-color: #e7fefd;
  }
  &:disabled {
    opacity: 0.6;
  }
`;
