import styled from "@emotion/styled";
import { WidthDefinedButtonList } from "./ButtonList";

export const PopupWindow = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 50px 5%;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    width: 800px;
    min-height: auto;
    padding: 60px 2.5%;
    margin: 60px auto 0;
  }
  h2 {
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    @media screen and (min-width: 768px) {
      margin-bottom: 60px;
    }
  }
  h3 {
    width: 100%;
    margin-bottom: 10px;
  }
  .heading {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
  }
  .text_block {
    width: 100%;
    margin: 0 auto 30px;
    p {
      line-height: 1.5em;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
    .list_in_list {
      li {
        padding-left: 1em;
        text-indent: -1em;
      }
    }
    textarea {
      width: 100%;
      height: 100px;
    }
    a {
      color: #11d4db;
      font-weight: 700;
      text-decoration: underline;
    }
  }
  .form {
    margin-bottom: 20px;
    p {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
      .error {
        padding-left: 10px;
        font-size: 14px;
        color: red;
      }
    }
    select {
      width: 130px;
      height: 40px;
    }
    input {
      width: 300px;
      padding: 10px;
      margin-top: 10px;
      font-size: 16px;
      border: 1px solid #d4d4d4;
    }
    input[name="month"],
    input[name="year"] {
      width: 70px;
      margin-right: 20px;
    }
  }
  .popup_window_layout {
    @media screen and (min-width: 768px) {
      width: 600px;
      margin: 0 auto;
    }
  }
`;

PopupWindow.displayName = "PopupWindow";

export const PopupWindowButtonList = styled(WidthDefinedButtonList)`
  margin: 50px auto 0;
`;

PopupWindowButtonList.displayName = "PopupWindowButtonList";
