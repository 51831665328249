/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useI18n } from "hooks/i18n/i18n";
import { SPView, PCView } from "components/common/MediaQuery";

interface Props {
  user: { uid: string };
  userPhotoURL: string;
  userDisplayName: string;
  userEmail: string;
  copyToClipboard: (target: string) => void;
}
export const AccountRegisterInfo: React.FC<Props> = (props) => {
  const { t } = useI18n();
  const classes = styles();

  return (
    <div css={classes.root}>
      <div css={classes.layout}>
        <div css={classes.thumbnail}>
          <img src={props.userPhotoURL} alt={props.userDisplayName} />
        </div>

        <div css={classes.info}>
          <div css={classes.profile}>
            <p className="name">{props.userDisplayName}</p>
            <p className="address">{props.userEmail}</p>
          </div>

          <PCView>
            <div css={classes.spwnId}>
              <p className="headline">
                {t("account.spwnId")}
                <span
                  onClick={() => {
                    props.copyToClipboard("__spwn_uid");
                  }}
                >
                  {t("account.copyId")}
                </span>
              </p>
              <p id="__spwn_uid">{props.user.uid}</p>
            </div>
          </PCView>
        </div>
      </div>

      <SPView>
        <div css={classes.spwnId}>
          <p className="headline">
            {t("account.spwnId")}
            <span
              onClick={() => {
                props.copyToClipboard("__spwn_uid");
              }}
            >
              {t("account.copyId")}
            </span>
          </p>
          <p id="__spwn_uid">{props.user.uid}</p>
        </div>
      </SPView>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      padding: 20px 5%;
      border-bottom: 1px solid #e6e5e6;
      @media screen and (min-width: 768px) {
        padding: 30px 0 40px;
      }
    `,
    layout: css`
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        width: 760px;
        margin: 0 auto;
      }
    `,
    thumbnail: css`
      width: 64px;
      margin-right: 16px;
      @media screen and (min-width: 768px) {
        width: 200px;
        margin-right: 40px;
      }
      img {
        width: 64px;
        height: 64px;
        border: 2px solid #dedede;
        border-radius: 50%;
        object-fit: cover;
        @media screen and (min-width: 768px) {
          width: 200px;
          height: 200px;
        }
      }
    `,
    info: css`
      width: calc(100% - 86px);
      @media screen and (min-width: 768px) {
        width: 520px;
      }
    `,
    profile: css`
      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
      }
      .name {
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          margin-bottom: 10px;
          font-size: 30px;
        }
      }
      .address {
        color: #8a8f8f;
        font-size: 12px;
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
      }
    `,
    spwnId: css`
      .headline {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 13px;
        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
        span {
          display: block;
          margin-left: 15px;
          color: #19d0d8;
          font-size: 11px;
          font-weight: bold;
          cursor: pointer;
        }
      }
      #__spwn_uid {
        font-family: "Share Tech Mono", monospace;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -1px;
        @media screen and (min-width: 768px) {
          font-size: 30px;
        }
      }
    `,
  };
};
