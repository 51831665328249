/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useI18n } from "hooks/i18n/i18n";
import React, { FC } from "react";
import { createXShareLink } from "utility/createXShareLink";
import { pushDataLayer } from "utility/ga";
import icon_Twitter from "../designs/images/shareButton/icon_Twitter_400*409.png";

export const XShareButton: FC<{
  eventId: string;
  eventTitle: string;
  hashTag: string[];
}> = ({ eventId, eventTitle, hashTag }) => {
  const { t } = useI18n();
  const classes = styles();

  const twitterLink = createXShareLink({
    eventId,
    eventTitle,
    twitterHashTag: hashTag,
  });

  const countClicks = () => {
    pushDataLayer({
      event: "share",
      method: "Twitter",
      content_type: "event",
      item_id: eventId,
      eid: eventId,
    });
  };

  return (
    <div css={classes.root}>
      <button
        type="button"
        onClick={() => {
          window.open(twitterLink, "_blank", "width=800,height=600");
          countClicks();
        }}
      >
        <img src={icon_Twitter} alt="X" />
        <p>{t("ticket.tweet")}</p>
      </button>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 6.5px 10px;
        border: none;
        border-radius: 4px;
        background-color: #000;
        cursor: pointer;
        img {
          width: 16px;
          height: auto;
        }
        p {
          font-size: 12px;
          font-weight: 700;
          color: #fff;
        }
      }
    `,
  };
};
