import { Component } from "react";
import { withRouter } from "react-router-dom";

type Props = {
  location: unknown;
};
class ScrollToTop extends Component<Props> {
  // @ts-expect-error TS7006
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default withRouter(ScrollToTop as any);
