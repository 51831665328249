import React from "react";
import { EventDetailVideoEntrance } from "./EventDetailVideoEntrance";
import { EventDetailPurchaseTicket } from "./EventDetailPurchaseTicket";
import { useEventDetailVideoList } from "hooks/event/useEventDetailVideoList";
import EventLiveList from "./eventLiveList/EventLiveList";
import { isListByVideo } from "utility/event";

export const EventDetailVideoList: React.FC = () => {
  const {
    pathname: _,
    isAdmin,
    displayEvent,
    eventVideos,
    eventVideoMap,
    videoTickets,
    streamingKey,
    isOpenLiveStreamingPage,
    goLive,
    handleButtonAction,
  } = useEventDetailVideoList();

  if (!(videoTickets.length > 0 || isAdmin)) {
    return null;
  }

  // TIF, マーダーの特殊表示
  if (
    isListByVideo(displayEvent.eventId) /*||
    pathname.indexOf("-murdermystery") !== -1*/
  ) {
    return <EventLiveList displayTickets={videoTickets} />;
  }

  return (
    <React.Fragment>
      <EventDetailVideoEntrance
        {...{
          displayEvent,
          eventVideos,
          streamingKey,
          goLive,
          isOpenLiveStreamingPage,
        }}
      />
      <EventDetailPurchaseTicket
        {...{
          videoTickets,
          displayEvent,
          eventVideoMap,
          handleButtonAction,
          streamingKey,
        }}
      />
    </React.Fragment>
  );
};
