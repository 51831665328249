/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useMemo } from "react";
import { streamingState, GiftItemData } from "modules/streaming";
import { SPView, PCView } from "components/common/MediaQuery";
import { convertMapToValues } from "../../utility";
import icon_paw from "../../designs/images/icon_paw.svg";
import CloseIcon from "@material-ui/icons/Close";
import { ChargeIcon } from "../../components/svg/streaming/ChargeIcon";
import { useI18n } from "hooks/i18n/i18n";

type Props = {
  open: boolean;
  openPawCharge: boolean;
  emoBalance: number;
  isDarkModeEnabled: boolean;
  giftItemMap: streamingState["giftItemMap"];
  functionsWhenSelectingGift: (gift: GiftItemData) => void;
  closeSelectGift: () => void;
  closePawCharge: () => void;
  togglePawChargeModal: () => void;
};

/**
 * Select Gift Component
 * @param props
 */
const StreamingSelectGift: React.FC<Props> = (props) => {
  const { t } = useI18n();
  const giftItems = useMemo(() => {
    return convertMapToValues(props.giftItemMap)
      .filter((el) => el.display)
      .sort((a, b) => a.priority - b.priority);
  }, [props.giftItemMap]);

  if (!props.open) {
    return null;
  }

  // @ts-expect-error TS7034
  const list = [];
  const classesSelectGiftDesign = selectGiftDesign({
    isDarkModeEnabled: props.isDarkModeEnabled,
  });
  const classesNotEnoughPawDesign = notEnoughPawDesign({
    isDarkModeEnabled: props.isDarkModeEnabled,
  });

  giftItems.forEach((item, i) => {
    list.push(
      <div
        key={i}
        css={classesSelectGiftDesign.itemDesign(item.colorCode).item}
        onClick={() => props.functionsWhenSelectingGift(item)}
      >
        <img src={item.imgUrl || icon_paw} alt="" />
        <p className="name">{item.name}</p>
        <p className="price">{item.values.paid.toLocaleString()}</p>
      </div>
    );
  });

  return (
    <React.Fragment>
      {/* sp view popup */}
      <SPView>
        <div css={classesSelectGiftDesign.root}>
          <div css={classesSelectGiftDesign.inner}>
            <div css={classesSelectGiftDesign.layout}>
              <div css={classesSelectGiftDesign.header}>
                <p>{t("streaming.gift.information")}</p>
                <CloseIcon onClick={props.closeSelectGift} />
              </div>

              {props.openPawCharge ? (
                <div css={classesNotEnoughPawDesign.root}>
                  <p css={classesNotEnoughPawDesign.heading}>
                    {t("streaming.gift.notEnough")}
                  </p>
                  <div>
                    <p
                      css={classesNotEnoughPawDesign.charge}
                      onClick={props.togglePawChargeModal}
                    >
                      {t("streaming.gift.PAWCharge")}
                    </p>
                    <p
                      css={classesNotEnoughPawDesign.cancel}
                      onClick={props.closePawCharge}
                    >
                      {t("streaming.gift.cancel")}
                    </p>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  {/*
                   // @ts-expect-error TS7005 */}
                  <div css={classesSelectGiftDesign.itemList}>{list}</div>
                  <div css={classesSelectGiftDesign.pawInfo}>
                    <p css={classesSelectGiftDesign.currentPaw}>
                      {t("streaming.gift.amount")}
                      <span>{props.emoBalance.toLocaleString()}PAW</span>
                    </p>
                    <div
                      css={classesSelectGiftDesign.charge}
                      onClick={props.togglePawChargeModal}
                    >
                      <ChargeIcon width={32} height={32} fontColor="#f8ca02" />
                      <p
                        css={css`
                          font-size: 10px;
                        `}
                      >
                        {t("streaming.gift.charge")}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </SPView>

      {/* pc view */}
      <PCView>
        <div css={classesSelectGiftDesign.root}>
          <div css={classesSelectGiftDesign.layout}>
            <div css={classesSelectGiftDesign.header}>
              <p>{t("streaming.gift.information")}</p>
              <CloseIcon onClick={props.closeSelectGift} />
            </div>

            {props.openPawCharge ? (
              <div css={classesNotEnoughPawDesign.root}>
                <p css={classesNotEnoughPawDesign.heading}>
                  {t("streaming.gift.notEnough")}
                </p>
                <div>
                  <p
                    css={classesNotEnoughPawDesign.charge}
                    onClick={props.togglePawChargeModal}
                  >
                    {t("streaming.gift.PAWCharge")}
                  </p>
                  <p
                    css={classesNotEnoughPawDesign.cancel}
                    onClick={props.closePawCharge}
                  >
                    {t("streaming.gift.cancel")}
                  </p>
                </div>
              </div>
            ) : (
              <React.Fragment>
                {/*
                 // @ts-expect-error TS7005 */}
                <div css={classesSelectGiftDesign.itemList}>{list}</div>
                <div css={classesSelectGiftDesign.pawInfo}>
                  <p css={classesSelectGiftDesign.currentPaw}>
                    {t("streaming.gift.amount")}
                    <span>{props.emoBalance.toLocaleString()}PAW</span>
                  </p>
                  <div
                    css={classesSelectGiftDesign.charge}
                    onClick={props.togglePawChargeModal}
                  >
                    <ChargeIcon width={32} height={32} fontColor="#f8ca02" />
                    <p
                      css={css`
                        font-size: 10px;
                      `}
                    >
                      {t("streaming.gift.charge")}
                    </p>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </PCView>
    </React.Fragment>
  );
};

interface SelectedGiftDesign {
  isDarkModeEnabled: boolean;
}
const selectGiftDesign = (props?: SelectedGiftDesign) => {
  // @ts-expect-error TS18048
  const svgColorInDarkMode = props.isDarkModeEnabled
    ? css`
        fill: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const backgroundColorInDarkMode = props.isDarkModeEnabled
    ? css`
        background-color: #202020 !important;
      `
    : css`
        background-color: #fff !important;
      `;
  // @ts-expect-error TS18048
  const borderColorInDarkMode = props.isDarkModeEnabled
    ? css`
        border-top: 1px solid #fff;
      `
    : css`
        border-top: 1px solid #000;
      `;
  return {
    root: css`
      position: fixed;
      top: 0;
      display: flex;
      align-items: flex-start;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 300;
      @media screen and (min-width: 768px) {
        position: absolute;
        flex-flow: column-reverse;
        height: 100%;
        border: 1px solid #e0e0e0;
      }
    `,
    inner: css`
      display: flex;
      align-items: center;
      width: 100%;
      height: 100vh;
    `,
    layout: css`
      width: 90%;
      margin: 0 auto;
      ${backgroundColorInDarkMode}
      @media screen and (min-width: 768px) {
        width: 100%;
      }
    `,
    header: css`
      position: relative;
      padding: 16px 0;
      background-color: #000;
      p {
        padding: 0 12%;
        color: #fff;
        font-weight: bold;
        text-align: center;
      }
      svg {
        position: absolute;
        top: 26%;
        right: 8px;
        fill: #fff;
        cursor: pointer;
      }
    `,
    itemList: css`
      display: flex;
      flex-wrap: wrap;
      max-height: 270px;
      padding: 20px 6px 0;
      overflow-y: scroll;
      /* -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display:none;
      } */
    `,
    // @ts-expect-error TS7006
    itemDesign: (color) => {
      return {
        item: css`
          width: calc(25% - 8px);
          padding: 5px;
          margin: 0 4px 8px;
          border: 2px solid ${color};
          @media screen and (min-width: 768px) {
            padding: 8px;
          }
          img {
            display: block;
            width: 35px;
            height: 35px;
            margin: 0 auto 8px;
          }
          p {
            ${textColorInDarkMode}
            font-size: 14px;
            line-height: 1.3em;
          }
          .name {
            display: box;
            height: 2.5em;
            margin-bottom: 4px;
            text-align: center;
            /* stylelint-disable-next-line property-no-unknown */
            box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            -webkit-line-clamp: 2;
          }
          .price {
            padding-left: 18px;
            font-size: 12px;
            background-image: url(${icon_paw});
            background-position: 0 50%;
            background-repeat: no-repeat;
            background-size: 15px 15px;
          }
        `,
      };
    },
    pawInfo: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 24px);
      padding-top: 12px;
      padding-bottom: 16px;
      margin: 16px auto 0;
      ${borderColorInDarkMode}
    `,
    currentPaw: css`
      ${textColorInDarkMode}
      font-size: 14px;
      span {
        margin-left: 8px;
        font-size: 20px;
        font-weight: bold;
      }
    `,
    charge: css`
      svg {
        ${svgColorInDarkMode}
        cursor: pointer;
      }
      p {
        ${textColorInDarkMode}
        font-size: 10px;
      }
    `,
  };
};

const notEnoughPawDesign = (props: { isDarkModeEnabled: boolean }) => {
  const textColor = props.isDarkModeEnabled
    ? css`
        color: white;
      `
    : null;
  return {
    root: css`
      width: 90%;
      padding-bottom: 24px;
      padding-top: 24px;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        min-height: 327px;
      }
    `,
    heading: css`
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      ${textColor}
    `,
    buttonList: css``,
    charge: css`
      padding: 8px 0;
      margin-bottom: 8px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      background-color: #00c2ae;
      cursor: pointer;
    `,
    cancel: css`
      color: #77797a;
      font-size: 14px;
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
    `,
  };
};

export default StreamingSelectGift;
