/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import type { Event } from "@spwn/types/firebase/firestore";

import { getEventDisplayInfo, EventInfo } from "utility";
import { changeStyleWithHosting } from "utility/hosting";

import { useI18n } from "hooks/i18n/i18n";
import { useGetEventList } from "hooks/event/useGetEventList";

import { Breadcrumb } from "components/atoms/Breadcrumb";
import { MainContents } from "components/templates/MainContents";
import { EventCard } from "features/EventCard";

export const VodEventListFetcher: FC = () => {
  const { data: events, isLoading } = useGetEventList({
    type: "openVOD",
  });

  return <VodEventListLogic {...{ isLoading, events }} />;
};

const VodEventListLogic: FC<{
  isLoading: boolean;
  events?: Event[];
}> = ({ isLoading, events }) => {
  const displayEvents = (events ? events : []).map((event) =>
    getEventDisplayInfo(event)
  );

  return <VodEventList displayEvents={displayEvents} isLoading={isLoading} />;
};

export const VodEventList: FC<{
  displayEvents: EventInfo[];
  isLoading: boolean;
}> = ({ displayEvents, isLoading }) => {
  const { t } = useI18n();
  const classesEventListDesign = eventListDesign();
  const breadcrumbText =
    changeStyleWithHosting().commonSettings.menu.archive.text;

  if (displayEvents.length === 0 && !isLoading) {
    return (
      <MainContents>
        <Breadcrumb
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            [null, breadcrumbText],
          ]}
        />

        <div css={classesEventListDesign.eventNothingLabel}>
          {t("event.noEvent.noVOD")}
        </div>
      </MainContents>
    );
  }

  return (
    <MainContents>
      <Breadcrumb
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"],
          [null, breadcrumbText],
        ]}
      />

      <div css={classesEventListDesign.root}>
        {displayEvents.map((event, index) => {
          return (
            <React.Fragment key={index}>
              <EventCard event={event} exceptionStatus={"vod"} />
            </React.Fragment>
          );
        })}
      </div>
    </MainContents>
  );
};

const eventListDesign = () => {
  return {
    root: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 90%;
      max-width: 980px;
      padding: 40px 0 80px;
      margin: 0 auto;
      &::after {
        content: "";
        display: block;
        width: 31%;
        margin-bottom: 20px;
      }
      .event-card {
        width: 47.5%;
        margin-bottom: 20px;
        @media screen and (min-width: 768px) {
          width: 31%;
        }
      }
    `,
    eventNothingLabel: css`
      height: calc(100vh - (255px + 194px));
      padding: 10px;
      margin-top: 100px;
      color: #888888;
      font-size: 20px;
      text-align: center;
      @media screen and (min-width: 768px) {
        font-size: 24px;
      }
    `,
  };
};
