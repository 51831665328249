/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useI18n } from "../../hooks/i18n/i18n";

interface Props {
  cartNumber: string;
  date: string;
  total: number;
  shippingFee: number | null;
  goSettlement: () => void;
}
export const CartOrderHeading: React.FC<Props> = (props) => {
  const classes = styles();
  const { t } = useI18n();

  return (
    <div css={classes.root}>
      <div css={classes.heading}>
        <p className={"cart-number"}>{props.cartNumber}</p>
        {props.date && (
          <p className={"shipping-date"}>
            {t("cart.box.scheduledShippingDate")}：{props.date}
          </p>
        )}
      </div>

      <div css={classes.price}>
        <div className={"cart-order-sub-total"}>
          <p className={"heading"}>{t("cart.box.subtotal")}</p>
          <p className={"total"}>
            {props.total.toLocaleString()}
            {t("cart.box.yen")}
          </p>
          <p className={"tax-included"}>{t("cart.box.tax")}</p>
        </div>

        {props.shippingFee !== null && (
          <div className={"cart-order-shipping-fee"}>
            <p className={"heading"}>{t("cart.box.postage")}</p>
            <p className={"total"}>
              {props.shippingFee?.toLocaleString()}
              {t("cart.box.yen")}
            </p>
            <p className={"tax-included"}>{t("cart.box.tax")}</p>
          </div>
        )}

        <button
          className={"cart-order-purchase-button"}
          onClick={props.goSettlement}
        >
          <ShoppingCartIcon />
          <p>{t("cart.button.checkoutButton")}</p>
        </button>
      </div>
    </div>
  );
};

const styles = () => ({
  root: css`
    padding: 8px;
    background-color: rgba(196, 196, 196, 0.1);
    border: 2px solid #a1a1a1;
    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 24px;
    }
  `,
  heading: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    margin-bottom: 8px;
    letter-spacing: 1px;
    border-bottom: 1px solid #a1a1a1;
    @media screen and (min-width: 768px) {
      display: block;
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
    .cart-number {
      font-size: 18px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        margin-bottom: 16px;
        font-size: 24px;
      }
    }
    .shipping-date {
      font-size: 12px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }
  `,
  price: css`
    @media screen and (min-width: 768px) {
      width: 400px;
    }
    .cart-order-sub-total,
    .cart-order-shipping-fee {
      display: grid;
      grid-template-columns: 24px auto 40px;
      place-items: end;
      gap: 8px;
      width: 70%;
      margin-left: auto;
      font-weight: bold;
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        grid-template-columns: 56px auto 56px;
        gap: 16px;
        width: 100%;
      }
    }
    .cart-order-sub-total {
      margin-bottom: 8px;
      .heading {
        font-size: 10px;
        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }
      .total {
        font-size: 14px;
        text-align: right;
        @media screen and (min-width: 768px) {
          font-size: 24px;
        }
      }
      .tax-included {
        font-size: 10px;
        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }
    }
    .cart-order-shipping-fee {
      margin-bottom: 16px;
      .heading {
        font-size: 10px;
        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }
      .total {
        font-size: 12px;
        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }
      .tax-included {
        font-size: 10px;
        @media screen and (min-width: 768px) {
          font-size: 12px;
        }
      }
    }
    .cart-order-purchase-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 8px;
      color: #fff;
      background-color: #00c2ad;
      border: none;
      cursor: pointer;
      svg {
        margin-right: 8px;
      }
      p {
        font-size: 14px;
        letter-spacing: 1px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
  `,
});
