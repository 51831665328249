import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { select, put, take, call, takeEvery } from "redux-saga/effects";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import appConfig from "../constants/appConfig";
import {
  fetchDatabaseValuesMap,
  fetchFirestoreCollectionMap,
  fetchFbToken,
  fetchFirestoreCollectionRaw,
  fetchFirestoreCollectionBySnapshot,
  setFirestoreDocument,
  fetchFirestoreDocument,
} from "../utility/firebase";
import { IsJsonString, convertMapToValues, getCookieValue } from "utility";
import { getNowTimestamp } from "../utility";
import { isVODTicket } from "../utility/event";
import { firestoreActions, isRegisteredChannel } from "./firestore";
import { eventChannel } from "redux-saga";
import { Timestamp } from "@google-cloud/firestore";
import { purchaseActions } from "./purchase";
import { Store } from "store";
import {
  getCommentCollectionPath,
  getCommentCountPath,
} from "utility/streaming";
import type { MyProductData } from "@spwn/types";
import type { ResGetStreamingKey } from "@spwn/types/functions";
import type {
  GiftItem,
  EventVideo,
  ProductData,
  UserMessage,
  EventChatInfo,
  MyEventTicketData,
} from "@spwn/types/firebase/firestore";
import type { PawItem } from "@spwn/types/firebase/database";
import { isNumber } from "util";

const actionCreator = actionCreatorFactory("streaming");

/* eslint-disable @typescript-eslint/no-explicit-any */
export const streamingActions = {
  setStateByKey: actionCreator<ReqSetStateByKey>("setStateByKey"),
  fetchCommentShardId: actionCreator.async<
    ReqFetchShardIdArgs | undefined,
    CommentShardId["value"]
  >("fetchCommentShardId"),
  clearStateByKey: actionCreator<keyof streamingState>("clearStateByKey"),
  fetchGiftItems: actionCreator.async<ReqFetchGiftItems, any>("fetchGiftItems"),
  getStreamingKey:
    actionCreator.async<ReqGetStreamingKey, any>("getStreamingKey"),
  watchEventVideos:
    actionCreator.async<ReqWatchEventVideos, any>("watchEventVideos"),
  createMyVideos:
    actionCreator.async<MyEventTicketData[], any>("createMyVideos"), // create my video data from myTickets
  watchSuperChat: actionCreator.async<ReqWatchSuperChat, any>("watchSuperChat"),
  createMySmallIconUrl: actionCreator.async<void, any>("createMySmallIconUrl"),
  setDisplayComments: actionCreator<any>("setDisplayComments"),
  fetchDisplayComments: actionCreator.async<ReqFetchDisplayComments, any>(
    "fetchDisplayComments"
  ),
  fetchBANComments:
    actionCreator.async<ReqFetchBANComments, any>("fetchBANComments"),
  handleSeekEvents:
    actionCreator.async<ReqHandleSeekEvents, any>("handleSeekEvents"),
  fetchDisplayCommentsByTimeUpdate: actionCreator.async<
    ReqFetchDisplayCommentsByTimeUpdate,
    any
  >("fetchDisplayCommentsByTimeUpdate"),
  deleteDisplayComment: actionCreator<string>("deleteComment"),
  setLastCommentedSeconds: actionCreator<number>("setLastCommentedSeconds"),
  getStreamingSettings: actionCreator<void>("getStreamingSettings"),
  toggleDarkMode: actionCreator<void>("toggleDarkMode"),
  toggleTheaterMode: actionCreator<void>("toggleTheaterMode"),
  toggleOnlyChatMode: actionCreator<void>("toggleOnlyChatMode"),
  postComment: actionCreator.async<ReqPostComment, any>("postComment"),
  updateLiveCommerceCart: actionCreator<{
    item: ProductData;
    count: number;
    isRemove?: boolean;
  }>("updateLiveCommerceCart"),
  successPurchaseLiveCommerce: actionCreator<
    streamingState["liveCommerce"]["isPurchased"]
  >("successPurchaseLiveCommerce"),
  setPhonePurchaseUrl: actionCreator<
    streamingState["liveCommerce"]["phonePurchaseUrl"]
  >("setPhonePurchaseUrl"),
  toggleLiveCommerceError: actionCreator<
    streamingState["liveCommerce"]["errorInfo"]
  >("toggleLiveCommerceError"),
  fetchNgWords: actionCreator.async<ReqNgWords, any>("fetchNgWords"),
};
/* eslint-enable @typescript-eslint/no-explicit-any */

type CommentShardId =
  // 初期化
  | { type: "init"; value: undefined }
  // shardingしていて部屋番号割り当てられている
  // nullはshardingしていない
  | { type: "loaded"; value: number | null };

export interface streamingState {
  giftItemMap: { [key: string]: GiftItemData };
  streamingKey: ResGetStreamingKey;
  streamingType: StreamingType;
  eventVideoMap: { [videoId: string]: EventVideo } | null;
  myVideoInfo: {
    myVideoMap: { [eventId: string]: MyEventTicketData };
    videoDataMap: { [videoId: string]: EventVideo };
  };
  superChatList: UserMessage[];
  iconUrls: {
    "32": string;
    "64": string;
    "128": string;
  };
  displayComments: UserMessage[];
  reservedComments: UserMessage[]; // for VOD streaming
  lastCommentedSeconds: number;
  commentShardId: CommentShardId;
  streamingSettings: {
    isDarkModeEnabled: boolean;
    isTheaterModeEnabled: boolean;
    isOnlyChatEnabled: boolean;
    ngWords: string[];
  };
  liveCommerce: {
    cartItemMap: { [pid: string]: ProductData & { count: number } };
    cartTotalNum: number;
    isPurchased: boolean;
    phonePurchaseUrl: string;
    errorInfo: {
      isError: boolean;
      caption?: string;
      msg: string;
    };
  };
}

export type GiftItemData = GiftItem & {
  type: string;
  values: {
    free: number;
    paid: number;
  };
};

export type StreamingType = "Live" | "VOD";

export type ActionTabType =
  | "eventDetail"
  | "comment"
  | "goods"
  | "event"
  | null; // pc: comment only now

export type CommentFilterType = "all" | "comment" | "gift" | "none" | null;

export type ReqSetStateByKey = {
  [P in keyof streamingState]?: streamingState[P];
};

export interface ReqFetchGiftItems {
  eid: string;
}
export interface ReqGetStreamingKey {
  eid: string;
}

type ReqWatchEventVideos = {
  eventId: string;
};

interface ReqWatchSuperChat {
  eid: string;
  vid: string;
}

type ReqFetchDisplayComments = {
  eventId: string;
  videoId: string;
};

type ReqFetchBANComments = {
  eventId: string;
  videoId: string;
};

type ReqHandleSeekEvents = {
  eventId: string;
  videoId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  player: any; // theoplayer
  eventStart: Timestamp;
};

type ReqFetchDisplayCommentsByTimeUpdate = {
  eventId: string;
  videoId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  player: any; // theoplayer
  eventStart: Timestamp;
};

type ReqPostComment = {
  eventId: string;
  videoId: string;
  isGift: boolean;
  itemId: string; // for usePAW
  postData: Omit<UserMessage, "timestamp">;
};

type ReqNgWords = {
  eventId: string;
};

const initialState: streamingState = {
  // @ts-expect-error TS2322
  giftItemMap: null,
  // @ts-expect-error TS2322
  streamingKey: null,
  streamingType: "Live",
  eventVideoMap: null,
  // @ts-expect-error TS2322
  myVideoInfo: null,
  // @ts-expect-error TS2322
  superChatList: null,
  // @ts-expect-error TS2322
  iconUrls: null,
  displayComments: [],
  reservedComments: [],
  lastCommentedSeconds: 0,
  commentShardId: { type: "init", value: undefined },
  streamingSettings: {
    // @ts-expect-error TS2322
    isDarkModeEnabled: null,
    isTheaterModeEnabled: false,
    isOnlyChatEnabled: false,
    ngWords: [],
  },
  liveCommerce: {
    // @ts-expect-error TS2322
    cartItemMap: null,
    cartTotalNum: 0,
    isPurchased: false,
    // @ts-expect-error TS2322
    phonePurchaseUrl: null,
    errorInfo: {
      isError: false,
      caption: "",
      msg: "",
    },
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const streamingReducer = reducerWithInitialState(initialState)
  .case(streamingActions.setStateByKey, (state, payload) => {
    const [key] = Object.keys(payload);
    // @ts-expect-error TS7053
    return { ...state, [key]: payload[key] };
  })
  // @ts-expect-error TS2345
  .case(streamingActions.fetchCommentShardId.done, (state, payload) => {
    return {
      ...state,
      commentShardId: { type: "loaded", value: payload.result },
    };
  })
  .case(
    streamingActions.clearStateByKey,
    (state, key: keyof streamingState) => {
      return { ...state, [key]: initialState[key] };
    }
  )
  .case(streamingActions.fetchGiftItems.done, (state, payload: any) => {
    return { ...state, giftItemMap: payload };
  })
  .case(streamingActions.getStreamingKey.done, (state, payload: any) => {
    return { ...state, streamingKey: payload };
  })
  .case(streamingActions.watchEventVideos.done, (state, payload: any) => {
    return { ...state, eventVideoMap: payload };
  })
  .case(streamingActions.createMyVideos.done, (state, payload: any) => {
    return { ...state, myVideoInfo: payload };
  })
  .case(streamingActions.watchSuperChat.done, (state, payload: any) => {
    return { ...state, superChatList: payload };
  })
  .case(streamingActions.createMySmallIconUrl.done, (state, payload: any) => {
    return { ...state, iconUrls: payload };
  })
  .case(streamingActions.setDisplayComments, (state, payload) => {
    // FIXME: action が呼ばれない
    console.log("reducer!!!");
    console.log(payload);
    return { ...state, displayComments: payload };
  })
  .case(streamingActions.fetchDisplayComments.done, (state, payload: any) => {
    return { ...state, displayComments: payload };
  })
  .case(streamingActions.fetchBANComments.done, (state, payload: any) => {
    return { ...state, displayComments: payload };
  })
  .case(streamingActions.handleSeekEvents.done, (state, payload: any) => {
    return { ...state, displayComments: payload };
  })
  .case(
    streamingActions.fetchDisplayCommentsByTimeUpdate.done,
    (state, payload: any) => {
      return { ...state, reservedComments: payload };
    }
  )
  .case(streamingActions.deleteDisplayComment, (state, commentId: string) => {
    const { displayComments } = state;
    // remove comment from displayComments
    const index = displayComments.findIndex(
      (comment) => comment._id === commentId
    );
    if (index !== -1) {
      displayComments.splice(index, 1);
    }
    return {
      ...state,
      displayComments: displayComments.slice(-DISPLAY_COMMENT_NUM),
    };
  })
  .case(streamingActions.setLastCommentedSeconds, (state, timestamp) => {
    return { ...state, lastCommentedSeconds: timestamp };
  })
  .case(streamingActions.getStreamingSettings, (state) => {
    // get straeming setting(dark mode) from cookie
    const isDarkModeEnabled = getCookieValue("preferDarkMode") === "true";
    return {
      ...state,
      streamingSettings: { ...state.streamingSettings, isDarkModeEnabled },
    };
  })
  .case(streamingActions.toggleDarkMode, (state) => {
    const isDarkModeEnabled = !state.streamingSettings.isDarkModeEnabled;
    document.cookie = `preferDarkMode=${isDarkModeEnabled}`;
    return {
      ...state,
      streamingSettings: { ...state.streamingSettings, isDarkModeEnabled },
    };
  })
  .case(streamingActions.toggleTheaterMode, (state) => {
    const isTheaterModeEnabled = !state.streamingSettings.isTheaterModeEnabled;
    return {
      ...state,
      streamingSettings: { ...state.streamingSettings, isTheaterModeEnabled },
    };
  })
  .case(streamingActions.toggleOnlyChatMode, (state) => {
    const isOnlyChatEnabled = !state.streamingSettings.isOnlyChatEnabled;
    return {
      ...state,
      streamingSettings: { ...state.streamingSettings, isOnlyChatEnabled },
    };
  })
  .case(streamingActions.postComment.done, (state, _payload: any) => {
    return { ...state };
  })
  .case(streamingActions.updateLiveCommerceCart, (state, payload) => {
    const { item, count, isRemove } = payload;
    // increment or decrement or remove
    let incomingCartItemMap = state.liveCommerce.cartItemMap;
    if (isRemove) {
      // @ts-expect-error TS2538
      delete incomingCartItemMap[item._id];
      // @ts-expect-error TS2538
    } else if (!incomingCartItemMap || !incomingCartItemMap[item._id]) {
      incomingCartItemMap = {
        ...incomingCartItemMap,
        // @ts-expect-error TS2464
        [item._id]: {
          ...item,
          count,
        },
      };
    } else {
      // @ts-expect-error TS2538
      incomingCartItemMap[item._id].count += count;
    }
    // sum cart item count
    const cartItems = convertMapToValues(incomingCartItemMap);
    const cartTotalNum =
      cartItems.length === 0
        ? 0
        : cartItems.map((el) => el.count).reduce((a, b) => a + b);
    return {
      ...state,
      liveCommerce: {
        ...state.liveCommerce,
        cartItemMap: incomingCartItemMap,
        cartTotalNum,
      },
    };
  })
  .case(streamingActions.successPurchaseLiveCommerce, (state, isPurchased) => {
    // if success, clear cart
    const liveCommerce = isPurchased
      ? { ...initialState.liveCommerce, isPurchased }
      : { ...state.liveCommerce, isPurchased };
    return { ...state, liveCommerce };
  })
  .case(streamingActions.setPhonePurchaseUrl, (state, phonePurchaseUrl) => {
    console.log(phonePurchaseUrl);
    return {
      ...state,
      liveCommerce: {
        ...state.liveCommerce,
        phonePurchaseUrl,
      },
    };
  })
  .case(streamingActions.toggleLiveCommerceError, (state, errorInfo) => {
    const liveCommerce = { ...state.liveCommerce, errorInfo };
    return { ...state, liveCommerce };
  })
  .case(streamingActions.fetchNgWords.done, (state, payload: any) => {
    return {
      ...state,
      streamingSettings: { ...state.streamingSettings, ngWords: payload },
    };
  });
/* eslint-enable @typescript-eslint/no-explicit-any */

export default streamingReducer;

export function* streamingSaga() {
  yield takeEvery(
    // @ts-expect-error TS2769
    streamingActions.fetchCommentShardId.started,
    fetchCommentShardId
  );
  yield takeEvery(streamingActions.fetchGiftItems.started, fetchGiftItems);
  yield takeEvery(streamingActions.getStreamingKey.started, getStreamingKey);
  yield takeEvery(streamingActions.watchEventVideos.started, watchEventVideos);
  yield takeEvery(streamingActions.createMyVideos.started, createMyVideos);
  yield takeEvery(streamingActions.watchSuperChat.started, watchSuperChat);
  yield takeEvery(
    streamingActions.createMySmallIconUrl.started,
    createMySmallIconUrl
  );
  yield takeEvery(
    streamingActions.fetchDisplayComments.started,
    fetchDisplayComments
  );
  yield takeEvery(streamingActions.fetchBANComments.started, fetchBANComments);
  yield takeEvery(streamingActions.handleSeekEvents.started, handleSeekEvents);
  yield takeEvery(
    streamingActions.fetchDisplayCommentsByTimeUpdate.started,
    fetchDisplayCommentsByTimeUpdate
  );
  yield takeEvery(streamingActions.postComment.started, postComment);
  yield takeEvery(streamingActions.fetchNgWords.started, fetchNgWords);
}

function* fetchGiftItems(action: { payload: ReqFetchGiftItems }) {
  const { eid } = action.payload;
  if (!eid) {
    return;
  }
  try {
    // @ts-expect-error TS7057
    const masterDataItems = yield fetchDatabaseValuesMap<PawItem>(`/items`);
    const giftMap: {
      [key: string]: GiftItemData;
    } = yield fetchFirestoreCollectionMap(`/chat/${eid}/gifts`);
    Object.keys(giftMap).forEach((key) => {
      const gift = giftMap[key];
      // @ts-expect-error TS18048
      const data = masterDataItems[gift.itemId];
      // @ts-expect-error TS2322
      giftMap[key] = {
        ...gift,
        type: data.type,
        values: data.values,
      };
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(streamingActions.fetchGiftItems.done(giftMap as any));
  } catch (e) {
    console.error(e);
  }
}

function* getStreamingKey(action: { payload: ReqGetStreamingKey }) {
  try {
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    if (!fbToken) {
      const data = { isError: true };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield put(streamingActions.getStreamingKey.done(data as any));
      return;
    }
    // @ts-expect-error TS2769
    const response = yield fetch(appConfig.CloudFunctions.getStreamingKey, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify({ eid: action.payload.eid }),
    });
    if (response.status === 200) {
      // @ts-expect-error TS7057
      const json = yield response.json();
      yield put(streamingActions.getStreamingKey.done(json));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = { isError: true };
      yield put(streamingActions.getStreamingKey.done(data));
    }
  } catch (e) {
    console.error(e);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { isError: true };
    yield put(streamingActions.getStreamingKey.done(data));
  }
}

function videosChannel(eventId: string) {
  return eventChannel((emitter) => {
    const unsubscribe = firebase
      .firestore()
      .collection(`streaming/${eventId}/videos`)
      .onSnapshot(
        (snapshot) => {
          let map = {};
          snapshot.forEach((doc) => {
            map = { ...map, [doc.id]: { ...doc.data(), _id: doc.id } };
          });
          emitter(map);
        },
        (error) => {
          console.error(error);
        }
      );
    return unsubscribe;
  });
}
function* watchEventVideos(action: { payload: ReqWatchEventVideos }) {
  const { eventId } = action.payload;
  const {
    firestore: { channels },
  } = yield select();
  if (isRegisteredChannel(channels, "eventVideos")) return;
  try {
    // @ts-expect-error TS7057
    const channel = yield call(videosChannel, eventId);
    yield put(firestoreActions.addChannel({ ...channel, name: "eventVideos" }));
    while (true) {
      // @ts-expect-error TS7057
      const data = yield take(channel);
      yield put(streamingActions.watchEventVideos.done(data));
    }
  } catch (e) {
    console.error(e);
  }
}

/**
 * create only viewable video data
 * !!! call only from fetchMyTickets !!!
 * @param action
 */
function* createMyVideos(action: { payload: MyEventTicketData[] }) {
  const myTickets = action.payload;

  let myVideoMap = {};
  const videoPromises = [];
  for (const myTicketInfo of myTickets) {
    // get vod ticket (unpurchased / not expired)
    const vodTickets = myTicketInfo.tickets.filter(
      (el) =>
        isVODTicket(el) &&
        (el.status === "UNPROCESS" ||
          getNowTimestamp() < getNowTimestamp(el.vodExpiredAt))
    );
    if (vodTickets.length === 0) {
      continue;
    }
    const eid = myTicketInfo.event._id;
    myVideoMap = {
      ...myVideoMap,
      // @ts-expect-error TS2464
      [eid]: {
        ...myTicketInfo,
        tickets: vodTickets,
      },
    };
    // fetch master video data
    videoPromises.push(
      fetchFirestoreCollectionMap("streaming/" + eid + "/videos")
    );
  }

  // create master video data
  // @ts-expect-error TS7057
  const promiseRootList = yield Promise.all(videoPromises);
  let videoDataMap: { [pid: string]: MyProductData } = {};
  for (let i = 0; i < promiseRootList.length; i++) {
    const element = promiseRootList[i];
    videoDataMap = { ...videoDataMap, ...element };
  }

  yield put(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    streamingActions.createMyVideos.done({ myVideoMap, videoDataMap } as any)
  );
}

function* watchSuperChat(action: { payload: ReqWatchSuperChat }) {
  const {
    firestore: { channels },
    event: { displayEvent },
    streaming: { commentShardId },
  } = yield select();
  if (isRegisteredChannel(channels, "superChat")) return;
  try {
    const msgRef = getCommentCollectionPath(
      action.payload.eid,
      action.payload.vid,
      displayEvent?.streamingInfo?.optionSettings?.isCommentShardingEnabled,
      commentShardId.value
    );
    // @ts-expect-error TS7057
    const channel = yield call(superChatChannel, msgRef);
    while (true) {
      // @ts-expect-error TS7057
      const data = yield take(channel);
      yield put(streamingActions.watchSuperChat.done(data));
      yield put(firestoreActions.addChannel({ ...channel, name: "superChat" }));
    }
  } catch (e) {
    console.error(e);
  }
}

function superChatChannel(msgRef: string) {
  console.info(msgRef);
  return eventChannel((emitter) => {
    const unsubscribe = firebase
      .firestore()
      .collection(msgRef)
      .where("isSuperChat", "==", true)
      .orderBy("timestamp")
      .onSnapshot(
        { includeMetadataChanges: true },
        (doc) => {
          if (!doc.metadata.hasPendingWrites) {
            // @ts-expect-error TS7034
            const commentList = [];
            doc.forEach((doc) => {
              commentList.push({ ...doc.data(), _id: doc.id });
            });
            // @ts-expect-error TS7005
            emitter(commentList);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    return unsubscribe;
  });
}

function* createMySmallIconUrl() {
  let isError = true;
  try {
    // @ts-expect-error TS7057
    const fbToken = yield fetchFbToken();
    // @ts-expect-error TS7057
    const response = yield fetch(
      // @ts-expect-error TS2769
      appConfig.CloudFunctions.createMySmallIconUrl,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `'Bearer ${fbToken}`,
        },
        body: JSON.stringify({}),
      }
    );
    // success
    if (response.status === 200) {
      // @ts-expect-error TS7057
      const json = yield response.json();
      yield put(streamingActions.createMySmallIconUrl.done(json.urls));
      isError = false;
      return;
    }
    // @ts-expect-error TS7057
    const text = yield response.text();
    if (IsJsonString(text)) {
      console.error(JSON.parse(text).msg);
    } else {
      console.error(text);
    }
  } catch (e) {
    console.error(e);
  } finally {
    // failed
    if (isError) {
      const unknownIconUrls = {
        "32": "https://public.spwn.jp/utils/spwn/imgs/icon_profile.svg",
        "64": "https://public.spwn.jp/utils/spwn/imgs/icon_profile.svg",
        "128": "https://public.spwn.jp/utils/spwn/imgs/icon_profile.svg",
      };
      yield put(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        streamingActions.createMySmallIconUrl.done(unknownIconUrls as any)
      );
    }
  }
}

const COMMENT_RECEIVER_SIZE = 1;
const DISPLAY_COMMENT_NUM = 70;
const ARCHIVE_COMMENT_FETCH_SIZE = 100;

function streamingCommentChannel(msgRef: string) {
  return eventChannel((emitter) => {
    // !!FIXME
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let unsubscribe: any = fetchFirestoreCollectionRaw(msgRef);
    unsubscribe = unsubscribe.orderBy("timestamp", "desc");
    unsubscribe = unsubscribe.limit(COMMENT_RECEIVER_SIZE);
    unsubscribe = unsubscribe.onSnapshot(
      { includeMetadataChanges: true },
      // @ts-expect-error TS7006
      (doc) => {
        // ref: https://qiita.com/subaru44k/items/a88e638333b8d5cc29f2#%E5%A4%89%E6%9B%B4%E7%99%BA%E7%94%9F%E6%99%82%E3%81%AE%E9%80%9A%E7%9F%A5
        // `doc.metadata.hasPendingWrites ? "Local" : "Server";`
        emitter({
          isLocalChange: doc.metadata.hasPendingWrites,
          docChanges: doc.docChanges(),
        });
      }
    );
    return unsubscribe;
  });
}

/**
 * fetch all streaming comments first time
 */
function* fetchDisplayComments(action: { payload: ReqFetchDisplayComments }) {
  const { eventId, videoId } = action.payload;

  //既にlisten済ならclose
  const {
    firestore: { channels },
    event: { displayEvent },
    streaming: { commentShardId },
  } = yield select();
  if (isRegisteredChannel(channels, "streamingComments")) {
    yield put(firestoreActions.closeChannel({ channel: "streamingComments" }));
  }
  try {
    const msgRef = getCommentCollectionPath(
      eventId,
      videoId,
      displayEvent?.streamingInfo?.optionSettings?.isCommentShardingEnabled,
      commentShardId.value
    );
    const snapshot = firebase
      .firestore()
      .collection(msgRef)
      .orderBy("timestamp", "desc")
      .limit(DISPLAY_COMMENT_NUM)
      .get();
    // @ts-expect-error TS7057
    const newArray = yield fetchFirestoreCollectionBySnapshot(snapshot);
    const comments = newArray.length > 0 ? newArray.reverse() : [];
    yield put(streamingActions.fetchDisplayComments.done(comments));

    // @ts-expect-error TS7057
    const channel = yield call(streamingCommentChannel, msgRef);
    yield put(
      firestoreActions.addChannel({ ...channel, name: "streamingComments" })
    );
    while (true) {
      // @ts-expect-error TS7057
      const data = yield take(channel);
      const { isLocalChange } = data;
      const { docChanges } = data;
      const {
        streaming: { displayComments, lastCommentedSeconds },
      }: Store = yield select();
      // @ts-expect-error TS7006
      docChanges.forEach((change) => {
        if (
          change.type === "added" &&
          !displayComments.some((comment) => comment._id === change.doc.id)
        ) {
          // added かつ重複idでなければ追加
          displayComments.push({
            ...(change.doc.data() as UserMessage),
            _id: change.doc.id,
          });
        } else if (change.type === "modified") {
          // 自分の書き込みの場合は`modified`を通る
          const index = displayComments.findIndex(
            (comment) => comment._id === change.doc.id
          );
          if (index === -1) {
            // modified かつ重複idでなければ追加
            displayComments.push({
              ...(change.doc.data() as UserMessage),
              _id: change.doc.id,
            });
          } else {
            // modified かつ重複idなら同じidに上書き
            displayComments.splice(index, 1, {
              ...(change.doc.data() as UserMessage),
              _id: change.doc.id,
            });
          }
        }
        // `change.type: removed` はonSnapshotのlimit対象から外れたドキュメントのことも指すため削除されたコメントとは限らない
        // ここでは判定できないので何もしない。 ref: https://qiita.com/mktu/items/17a993f675ccd6d17aed
      });
      // コメント描画負荷対策として、1秒間に1度の描画になるようにコメント取得の発火を一部無視する
      const unixTimestamp = getNowTimestamp();
      if (isLocalChange || lastCommentedSeconds < unixTimestamp) {
        yield put(
          streamingActions.fetchDisplayComments.done(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (displayComments as any).slice(-DISPLAY_COMMENT_NUM)
          )
        );
        yield put(streamingActions.setLastCommentedSeconds(unixTimestamp));
      }
    }
  } catch (e) {
    console.error(e);
  }
}

function streamingBANCommentChannel(msgRef: string) {
  return eventChannel((emitter) => {
    const unsubscribe = firebase
      .firestore()
      .collection(msgRef)
      .where("isBan", "==", true)
      .orderBy("timestamp", "desc")
      .limit(DISPLAY_COMMENT_NUM) // 表示分のみ取得
      .onSnapshot((docs) => {
        emitter({
          isLocalChange: docs.metadata.hasPendingWrites,
          docChanges: docs.docChanges(),
        });
      });
    return unsubscribe;
  });
}

/**
 * use for detection deleted comment
 * @param action
 */
function* fetchBANComments(action: { payload: ReqFetchBANComments }) {
  const { eventId, videoId } = action.payload;
  const {
    firestore: { channels },
    auth: { user },
    event: { displayEvent },
    streaming: { commentShardId },
  }: Store = yield select();
  if (isRegisteredChannel(channels, "streamingBANComments")) return;
  try {
    const msgRef = getCommentCollectionPath(
      eventId,
      videoId,
      // @ts-expect-error TS2345
      displayEvent?.streamingInfo?.optionSettings?.isCommentShardingEnabled,
      commentShardId.value
    );
    // @ts-expect-error TS7057
    const channel = yield call(streamingBANCommentChannel, msgRef);
    yield put(
      firestoreActions.addChannel({ ...channel, name: "streamingBANComments" })
    );
    while (true) {
      // @ts-expect-error TS7057
      const data = yield take(channel);
      const { isLocalChange } = data;
      const { docChanges } = data;
      const {
        streaming: { displayComments },
      }: Store = yield select();
      if (docChanges.length > COMMENT_RECEIVER_SIZE) {
        // HOTFIX: if first fetch, ignore
        continue;
      }
      // @ts-expect-error TS7006
      docChanges.forEach((change) => {
        // ignore permission denied update. localチェックがないと一瞬消えて再表示される。
        if (!isLocalChange && change.type === "added") {
          // remove ban comment from displayComments
          const index = displayComments.findIndex(
            (comment) => comment._id === change.doc.id
          );
          // @ts-expect-error TS2532
          if (displayComments[index].uid === user.uid) {
            // exclude own comment
            return;
          }
          if (index !== -1) {
            displayComments.splice(index, 1);
          }
        }
      });
      yield put(
        streamingActions.fetchBANComments.done(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          displayComments.slice(-DISPLAY_COMMENT_NUM) as any
        )
      );
    }
  } catch (e) {
    console.error(e);
  }
}

/**
 * trigger by seek in VOD streming
 * @param action
 */
function* handleSeekEvents(action: { payload: ReqHandleSeekEvents }) {
  try {
    const { eventId, videoId, eventStart, player } = action.payload;
    if (!eventStart) {
      return;
    }
    const {
      event: { displayEvent },
      streaming: { commentShardId },
    }: Store = yield select();

    // initialize reserved comments
    yield put(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      streamingActions.fetchDisplayCommentsByTimeUpdate.done([] as any)
    );
    const end = eventStart.seconds + player.currentTime;
    const msgRef = getCommentCollectionPath(
      eventId,
      videoId,
      // @ts-expect-error TS2345
      displayEvent?.streamingInfo?.optionSettings?.isCommentShardingEnabled,
      commentShardId.value
    );
    // 表示用のメッセージを取得する
    const snapshot = firebase
      .firestore()
      .collection(msgRef)
      .where("timestamp", "<", new Date(end * 1000))
      .orderBy("timestamp", "desc")
      .limit(DISPLAY_COMMENT_NUM)
      .get();
    // @ts-expect-error TS7057
    const newArray = yield fetchFirestoreCollectionBySnapshot(snapshot);
    const comments = newArray.length > 0 ? newArray.reverse() : [];
    yield put(streamingActions.handleSeekEvents.done(comments));
  } catch (error) {
    console.error(error);
  }
}

function* fetchDisplayCommentsByTimeUpdate(action: {
  payload: ReqFetchDisplayCommentsByTimeUpdate;
}) {
  const { player, eventStart, eventId, videoId } = action.payload;

  const {
    auth: { user },
    event: { displayEvent },
    streaming: { reservedComments, displayComments, commentShardId },
  }: Store = yield select();
  const startEvent = eventStart.seconds;

  try {
    const { currentTime } = player;
    // @ts-expect-error TS7034
    const newComments = [];
    reservedComments.forEach((el) => {
      const commentTime = el.timestamp.seconds - startEvent;
      if (
        commentTime < currentTime &&
        (el.uid === user.uid || !el.hasOwnProperty("isBan") || !el.isBan)
      ) {
        newComments.push(el);
      }
    });

    // @ts-expect-error TS7005
    const commentsToDisplay = displayComments.concat(newComments);
    const begin = Math.max(commentsToDisplay.length - DISPLAY_COMMENT_NUM, 0);
    yield put(
      streamingActions.fetchDisplayComments.done(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        commentsToDisplay.slice(begin) as any
      )
    );

    // remove displayed comment
    const _reservedComments = reservedComments.filter(
      (el) => el.timestamp.seconds - startEvent >= currentTime
    );

    // store next comment
    if (_reservedComments.length === 0) {
      const msgRef = getCommentCollectionPath(
        eventId,
        videoId,
        // @ts-expect-error TS2345
        displayEvent?.streamingInfo?.optionSettings?.isCommentShardingEnabled,
        commentShardId.value
      );
      // const snapshot = fetchFirestoreDisplayMessage(msgRef, startEvent + currentTime, COMMENT_RECEIVER_SIZE)
      const snapshot = firebase
        .firestore()
        .collection(msgRef)
        .orderBy("timestamp")
        .startAfter(new Date((startEvent + currentTime) * 1000))
        .limit(ARCHIVE_COMMENT_FETCH_SIZE)
        .get();
      // @ts-expect-error TS7057
      const newArray = yield fetchFirestoreCollectionBySnapshot(snapshot);
      if (newArray.length > 0) {
        yield put(
          streamingActions.fetchDisplayCommentsByTimeUpdate.done(newArray)
        );
        // this._reservedComments = newArray
      }
    } else {
      yield put(
        streamingActions.fetchDisplayCommentsByTimeUpdate.done(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          _reservedComments as any
        )
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* postComment(action: { payload: ReqPostComment }) {
  try {
    const {
      auth: { user },
    } = yield select();
    if (!user?.uid) {
      return;
    }

    const { eventId, videoId, isGift, postData, itemId } = action.payload;

    const data = {
      ...postData,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    const {
      event: { displayEvent },
      streaming: { commentShardId },
    } = yield select();

    const msgRef = getCommentCollectionPath(
      eventId,
      videoId,
      displayEvent?.streamingInfo?.optionSettings?.isCommentShardingEnabled,
      commentShardId.value
    );
    yield firebase.firestore().collection(msgRef).add(data);
    const msgCountRef = getCommentCountPath(
      eventId,
      videoId,
      displayEvent?.streamingInfo?.optionSettings?.isCommentShardingEnabled,
      commentShardId.value
    );
    // !!! must create msgCount before start streaming !!!
    setFirestoreDocument(msgCountRef, {
      msgCount: firebase.firestore.FieldValue.increment(1),
    });

    // if payed gift, use paw after send comment
    // pawを使用してギフトが送られない場合の保険
    if (isGift) {
      yield put(
        purchaseActions.usePAW.started({
          suppressMsg: true,
          eid: eventId,
          itemId,
          freePrice: 0,
          paidPrice: postData.amount,
        })
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(streamingActions.postComment.done({} as any));
  } catch (error) {
    console.error(error);
  }
}

function* fetchNgWords(action: { payload: ReqNgWords }) {
  const { eventId } = action.payload;
  try {
    const msgRef = `/chat/${eventId}`;
    const data: EventChatInfo = yield fetchFirestoreDocument(msgRef);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yield put(streamingActions.fetchNgWords.done((data.ngWords as any) || []));
  } catch (error) {
    console.error(error);
  }
}

/***
 * chatVersion null
 *   shardIdをコメントに訪れるたびに生成する
 * chatVersion 2
 *   初回でshardIdを生成し、以降はshardIdをDBから取得する
 */
type CHAT_VERSION = null | 2;
export type ReqFetchShardIdArgs = {
  chatVersion: CHAT_VERSION;
  // 設定されているshard数
  nCommentShards?: number;
  isCommentShardingEnabled: boolean;
  userId: string;
  eventId: string;
  videoId: string;
};

const generateShardId = (nCommentShards?: number): number => {
  const nShards = nCommentShards || 0; // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
  return Math.floor(Math.random() * nShards);
};

const getCommentShardId = async (
  params: ReqFetchShardIdArgs
): Promise<number | undefined> => {
  const query = firebase
    .firestore()
    .collection(`/users/${params.userId}/userChatRooms`)
    .where("eventId", "==", params.eventId)
    .where("videoId", "==", params.videoId)
    .limit(1);
  const userChatRoom = await query.get();
  if (userChatRoom.empty) return undefined;
  // @ts-expect-error TS2532
  return userChatRoom.docs[0].data().shardId;
};

const createChatRoom = async (
  params: ReqFetchShardIdArgs & {
    shardId: number;
  }
): Promise<void> => {
  const docId = `${params.eventId}-${params.videoId}-${params.shardId}`;
  const ref = `/users/${params.userId}/userChatRooms/${docId}` as const;
  await setFirestoreDocument(ref, {
    videoId: params.videoId,
    eventId: params.eventId,
    shardId: params.shardId,
  });
};

const getOrCreateCommentShardId = async (
  params: ReqFetchShardIdArgs
): Promise<number> => {
  // v2以前は変わらずランダムでshardIdを生成する
  if (params.chatVersion !== 2) return generateShardId(params.nCommentShards);

  // shardIdが設定されている場合はそれを返す
  const storedCommentShardId = await getCommentShardId(params);
  // NOTE: 0のshardIdがある
  if (isNumber(storedCommentShardId)) return storedCommentShardId;

  // shardIdが設定されていない場合は新しく生成しroom情報として保存する
  const shardId = generateShardId(params.nCommentShards);
  await createChatRoom({ ...params, shardId });
  return shardId;
};

function* fetchCommentShardId(action: { payload: ReqFetchShardIdArgs }) {
  if (!action.payload.isCommentShardingEnabled) {
    put(
      streamingActions.fetchCommentShardId.done({
        params: undefined,
        result: null,
      })
    );
  }

  // @ts-expect-error TS7057
  const shardId = yield getOrCreateCommentShardId(action.payload);
  yield put(
    streamingActions.fetchCommentShardId.done({
      params: undefined,
      result: shardId,
    })
  );
}
