/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import { EventInfo, getEventDisplayInfo, isDisplayPeriod } from "utility";
import type { Event } from "@spwn/types/firebase/firestore";
import { useI18n } from "hooks/i18n/i18n";
import { PortalTopEventCard } from "./PortalTopEventCard";
import { PortalTopSectionLayout } from "./PortalTop";
import { PortalTopLinkButton } from "./PortalTopLinkButton";
import { useGetEventList } from "hooks/event/useGetEventList";

export const PortalTopCapsuleFetcher: FC = () => {
  const LIST_COUNT = 3;

  const { data: events, isLoading } = useGetEventList({
    type: "openCapsule",
    limit: LIST_COUNT,
  });

  return <PortalTopCapsuleLogic events={events} isLoading={isLoading} />;
};

const PortalTopCapsuleLogic: FC<{
  events?: Event[];
  isLoading?: boolean;
}> = ({ events, isLoading }) => {
  const capsuleGoodsList: (EventInfo & { isOnSale: boolean })[] = (
    events ? events : []
  )
    .slice()
    .flatMap((event) => {
      // カプセルの販売期間内かどうか判定する
      // カプセルの販売期間内であれば、販売中として表示する
      // カプセルの販売期間外であれば、販売終了として表示する
      // goodsSellingOpenTimeかgoodsSellingCloseTimeがundefinedの場合は、常に販売終了として表示する
      const isOnSale = isDisplayPeriod(
        !event.isClosed,
        event.goodsSellingOpenTime,
        event.goodsSellingCloseTime
      );

      return {
        ...getEventDisplayInfo(event),
        isOnSale,
      };
    });

  return capsuleGoodsList.length === 0 && !isLoading ? null : (
    <PortalTopCapsule displayEvents={capsuleGoodsList} />
  );
};

export const PortalTopCapsule: FC<{
  displayEvents: (EventInfo & { isOnSale: boolean })[];
}> = ({ displayEvents }) => {
  const { t } = useI18n();
  const styles = capsuleEventListStyles();

  return (
    <PortalTopSectionLayout
      heading={{
        mainText: "CAPSULE",
        subText: "カプセル",
      }}
    >
      <section>
        <div css={styles.layout}>
          {displayEvents.map((event, index) => {
            return (
              <React.Fragment key={`${event.eventId}_${index}`}>
                <PortalTopEventCard
                  href={`/events/${event.eventId}/goods`}
                  tag={event.isOnSale ? "販売中" : "販売終了"}
                  tagColor={event.isOnSale ? "#00C2AD" : "#fff"}
                  thumbnail={event.thumbnail}
                  date={event.eventOpenDateFull}
                  title={event.eventTitle}
                />
              </React.Fragment>
            );
          })}
        </div>

        <PortalTopLinkButton
          href={`/capsule`}
          label={t("event.top.archiveEventList")}
        />
      </section>
    </PortalTopSectionLayout>
  );
};

const capsuleEventListStyles = () => {
  return {
    root: css``,
    layout: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 28px;
      }
    `,
    item: css`
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;
    `,
    thumbnail: css`
      position: relative;
      .tag {
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px 10px;
        color: #ff5f96;
        font-size: 14px;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.7);
      }
      img {
        width: 100%;
      }
    `,
    info: css`
      padding: 16px;
      font-weight: bold;
      line-height: 1.5em;
      .date {
        margin-bottom: 12px;
        @media screen and (min-width: 768px) {
          margin-bottom: 8px;
        }
      }
      .title {
        line-height: 1.5em;
      }
    `,
  };
};
