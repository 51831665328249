import React from "react";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { Event, LotteryAppForm, Place } from "@spwn/types/firebase/firestore";

import { useGetPlaceList } from "hooks/event/useGetPlaceList";
import { i18nextT, useI18n } from "hooks/i18n/i18n";
import {
  BackendAppError,
  LotteryTicketRecord,
  useGetLotteryTicketList,
} from "./useGetLotteryTicketList";
import { BreadcrumbArea } from "components/common/Link";
import { EventDate } from "components/event/detail/header/EventDate";
import { SanitizedText } from "components/common/SanitizedText";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { CVSNames } from "modules/purchase";
import { loadingActions } from "modules/loading";
import { modalActions } from "modules/modal";
import { getEventDisplayInfo } from "utility";
import { ContentsLayout, fontDinMixin } from "styles";
import flame_pc from "designs/images/flame_line_top_bg_pc.svg";
import flame_sp from "designs/images/flame_line_top_bg.svg";

export const AccountLottery: React.FC = () => {
  const { t } = useI18n();
  const dispatch = useDispatch();

  const { lotteryTicketList, isLoading, withdrawLotteryTicketMutate } =
    useGetLotteryTicketList();

  // Place情報を取得する
  const placeIds = lotteryTicketList?.flatMap((ticket) =>
    ticket.tickets.map((t) => t.ticket.place)
  );
  const { data: places } = useGetPlaceList(placeIds);

  // チケット申し込み取り下げ
  const handleWithdraw = async (eventId: string) => {
    dispatch(
      loadingActions.toggleLoading({ msg: t("ticket.lottery.withdrawal") })
    );

    try {
      await withdrawLotteryTicketMutate(eventId);

      dispatch(
        modalActions.toggleNotice({
          msg: i18nextT("ticket.lottery.withdrawaled"),
        })
      );
    } catch (err: unknown) {
      if (err instanceof BackendAppError) {
        dispatch(
          modalActions.toggleError({
            msg: err.message,
          })
        );
      } else if (err instanceof Error) {
        dispatch(
          modalActions.toggleError({
            msg: i18nextT("ticket.lottery.failWithdrawal"),
          })
        );
      }
    } finally {
      dispatch(loadingActions.toggleLoading({}));
    }
  };

  const handleJumpToLotteryPage = (eventId: string) => {
    dispatch(push(`/events/${eventId}/ticket`));
  };

  return isLoading ? (
    <LoaderLayout />
  ) : (
    <Container id="contents">
      <BreadcrumbArea
        // @ts-expect-error TS2322
        paths={[
          ["/", "ホーム"], // BreadcrumbArea側で形 "ホーム" が指定されているためstringがはいらない t("common.routes.home")
          ["/account", t("common.routes.account")],
          [null, t("common.routes.lottery")],
        ]}
      />
      <ContentsLayout>
        {/* ticketContents */}
        {lotteryTicketList?.length === 0 ? (
          <div className="no_ticket">{t("lottery.noLotteryTicket")}</div>
        ) : (
          lotteryTicketList.map((lotteryTicket) => (
            <TicketContentItem
              key={`lottery_${lotteryTicket.event._id}`}
              event={lotteryTicket.event}
              lottery={lotteryTicket.lottery}
              tickets={lotteryTicket.tickets}
              places={places}
              onJumpToLotteryPage={handleJumpToLotteryPage}
              onWithdraw={handleWithdraw}
            />
          ))
        )}
      </ContentsLayout>
    </Container>
  );
};

const TicketContentItem: React.FC<{
  event: Event;
  lottery: LotteryAppForm;
  tickets: LotteryTicketRecord[];
  places?: Place[];
  onJumpToLotteryPage: (eventId: string) => void;
  onWithdraw: (eventId: string) => void;
}> = ({ event, lottery, tickets, places, onJumpToLotteryPage, onWithdraw }) => {
  const { t } = useI18n();

  const eventId = event._id ?? "";
  const displayInfo = getEventDisplayInfo(event);

  const eventDate = displayInfo.isLongEvent
    ? displayInfo.eventOpenDateFull + " ~ " + displayInfo.eventEndDateFull
    : displayInfo.eventOpenDateFull;

  return (
    <div className="lottery_ticket" key={`lottery_${event._id}`}>
      <img src={flame_pc} alt="" className="flame_pc" />
      <img src={flame_sp} alt="" className="flame_sp" />
      <div className="ticket_layout">
        <div className="info">
          <div className="date">
            <EventDate
              isDisplayEventDate={true}
              isDisplayEventTime={true}
              datetimeStr={eventDate}
              parts={displayInfo.parts}
            />
          </div>
          <p className="title">{displayInfo.eventTitle}</p>
        </div>

        {tickets.map((ticketRecord) => {
          const { ticket } = ticketRecord;
          const { purchaseCount } = ticketRecord;
          const place = places?.find((p) => p.code === ticket.place);
          return (
            <div
              className="lottery_item"
              key={`tid_${ticketRecord.ticket._id}`}
            >
              <div className="ticket">
                <div className="layout_l">
                  <p className="place">{place?.name}</p>
                  <p className="name">{ticket.name}</p>
                  {event.lotteryTicketSellingMode !== "free" && (
                    <p className="price">
                      ¥{ticket.price_jpy.toLocaleString()}
                    </p>
                  )}
                </div>
                <div className="layout_r">
                  <p className="num">
                    <span>×</span>
                    {purchaseCount.toLocaleString()}
                  </p>
                </div>
              </div>
              <SanitizedText
                className="benefit"
                description={ticket.description}
              />
            </div>
          );
        })}

        {/* appFormContents */}
        <AppFormContents>
          <div className="text_box">
            {event.lotteryTicketSellingMode !== "free" && (
              <div>
                <p>{t("lottery.settlementInfo")}</p>
                <p className="purchase_info">
                  {lottery.orderType === "CVS"
                    ? t("lottery.convenienceStoreSettlement")
                    : t("lottery.registrationCardSettlement")}
                </p>
              </div>
            )}
          </div>
          {lottery.orderType === "CVS" ? (
            <p className="text_block">
              <p className="heading">{t("lottery.convenienceStoreToUse")}</p>
              <p className="item">{CVSNames[lottery.cvsCode]}</p>
            </p>
          ) : (
            ""
          )}
          {lottery.isFanClubIdEncrypted === false &&
          lottery.fanClubId !== undefined &&
          lottery.fanClubId !== null &&
          lottery.fanClubId !== "" ? (
            <p className="text_block">
              <p className="heading">{event.fanClubIdName}</p>
              <p className="item">{lottery.fanClubId}</p>
            </p>
          ) : (
            ""
          )}
          {lottery.verificationCode !== null &&
          lottery.verificationCode !== undefined &&
          lottery.verificationCode !== "" ? (
            <p className="text_block">
              <p className="heading">{event.verificationCodeName}</p>
              <p className="item">{lottery.verificationCode}</p>
            </p>
          ) : (
            ""
          )}
          {event.ticketSellingCloseTime.seconds * 1000 > Date.now() ? (
            <div>
              <button
                className="button_check"
                onClick={() => onJumpToLotteryPage?.(eventId)}
              >
                {t("lottery.changeApplicationDetails")}
              </button>
              <button
                className="button_cancel"
                onClick={() => onWithdraw?.(eventId)}
              >
                {t("lottery.withdrawApplication")}
              </button>
            </div>
          ) : (
            ""
          )}
        </AppFormContents>
      </div>
      <br />
    </div>
  );
};

const Container = styled.div`
  margin: 0 auto;
  min-height: calc(100vh - 436px);
  padding: 40px 0 80px;
  background-color: #fafafa;
  @media screen and (min-width: 768px) {
    min-height: calc(100vh - 280px);
    padding-top: 0px;
  }
  .lottery_ticket {
    width: 100%;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 740px;
    }
    .flame_pc {
      display: none;
      width: 100%;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        display: inline;
      }
    }
    .flame_sp {
      display: inline-block;
      width: 100%;
      background-color: #fff;
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
    .ticket_layout {
      margin-top: -5px;
      background-color: #fff;
      border-left: 1px solid #d4d4d4;
      border-right: 1px solid #d4d4d4;
      border-bottom: 1px solid #d4d4d4;
      .info {
        width: 90%;
        padding-bottom: 15px;
        margin: 0 auto;
        border-bottom: 1px solid #d4d4d4;
        @media screen and (min-width: 768px) {
          width: 95%;
        }
        .date {
          font-weight: bold;
          div {
            border: none;
          }
          p {
            font-size: 16px;
          }
          .open {
            font-size: 12px;
          }
        }
        .title {
          color: #05c6cc;
          font-size: 16px;
          font-weight: bold;
          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
        }
      }
    }
    .lottery_item {
      padding-top: 15px;
      .ticket {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 90%;
        padding-bottom: 20px;
        margin: 0 auto;
        @media screen and (min-width: 768px) {
          width: 95%;
        }
        .layout_l {
          .place {
            margin-bottom: 10px;
            font-size: 12px;
            font-weight: bold;
            @media screen and (min-width: 768px) {
              font-size: 14px;
            }
          }
          .name {
            margin-bottom: 15px;
            font-size: 18px;
            font-weight: bold;
            @media screen and (min-width: 768px) {
              margin-bottom: 20px;
              font-size: 20px;
            }
          }
          .price {
            font-size: 18px;
            font-weight: bold;
            @media screen and (min-width: 768px) {
              font-size: 20px;
            }
          }
        }
        .layout_r {
          .num {
            margin-bottom: -15px;

            ${fontDinMixin};
            font-size: 40px;
            span {
              font-size: 20px;
            }
          }
        }
      }
      .benefit {
        padding: 15px 2.5%;
        background-color: #f6fcfc;
        border-top: 1px solid #cee7e8;
        border-bottom: 1px solid #cee7e8;
        line-height: 1.3em;
        a {
          color: #01baa5;
          text-decoration: underline;
        }
      }
    }
  }
  .no_ticket {
    padding: 32px 16px;
    text-align: center;
    font-weight: bold;
  }
`;

const AppFormContents = styled.div`
  margin: 30px 2.5% 30px;
  .purchase_info {
    font-size: 20px;
  }
  .text_box {
    margin: 30px 0;
  }
  .button_check {
    display: block;
    margin: 10px auto;
    padding: 13px 0;
    color: #fff;
    background-color: #00c2ae;
    border: 2px solid #00c2ae;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    width: 80%;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      padding: 13px 0;
      font-size: 15px;
      letter-spacing: 2px;
      width: 70%;
    }
  }
  .button_cancel {
    display: block;
    margin: 10px auto;
    padding: 13px 0;
    color: #00c2ae;
    background-color: #fff;
    border: 2px solid #00c2ae;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    width: 80%;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      padding: 13px 0;
      font-size: 15px;
      letter-spacing: 2px;
      width: 70%;
    }
  }
`;
