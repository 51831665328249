/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useMemo } from "react";
import { LiveListItemState } from "./LiveListItemState";
import { LiveListItemPrice } from "./LiveListItemPrice";
import { LiveListItemCaution } from "./LiveListItemCaution";
import { TicketPurchaseButton } from "./TicketPurchaseButton";
import { EventVideo, Ticket } from "../../../../../../types/firebase/firestore";
import { ResGetStreamingKey } from "../../../../../../types/functions";
import { EventInfo, isDisplayPeriod } from "../../../../utility";
import {
  canWatchVideo,
  extractDefaultVideoId,
} from "../../../../utility/streaming";

type Props = {
  videoTicket: Ticket;
  displayEvent: EventInfo;
  eventVideoMap: {
    [videoId: string]: EventVideo;
  } | null;
  streamingKey: ResGetStreamingKey;
  handleButtonAction: () => void;
};

export const EventVideoTicket: React.FC<Props> = (props) => {
  const classes = eventVideoTicketListDesign();
  const {
    eventVideoMap,
    displayEvent,
    streamingKey,
    videoTicket,
    handleButtonAction,
  } = props;
  const { isHiddenVODExpiredAt, isLotteryTicketSellingMode } = displayEvent;
  const defaultVideoId = extractDefaultVideoId(
    eventVideoMap,
    videoTicket.videoIds
  );
  // @ts-expect-error TS2538
  const video = eventVideoMap[defaultVideoId];
  const videoThumbnail = useMemo(() => {
    const hotfixThumbnail = getTicketThumbnailToFix1478(
      videoTicket as unknown as {
        eid: string;
        _id: string;
      }
    );
    if (hotfixThumbnail) return hotfixThumbnail;
    return videoTicket.videoIds.length > 1
      ? displayEvent.thumbnail
      : video?.thumbnail || displayEvent.thumbnail;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTicket.videoIds, displayEvent.thumbnail, video?.thumbnail]);
  // Boolean: ビデオを視聴出来るかどうか(チケットを持っているかどうか)判定する
  const canWatch = canWatchVideo(streamingKey, video?._id);
  const isPurchased =
    canWatch &&
    // @ts-expect-error TS18048
    videoTicket.videoIds.every((el) => streamingKey.videoIds.includes(el));
  // Boolean: チケットが販売期間中かどうか判定する
  const isOnSale =
    displayEvent.eventSaleStatus === "ON_SALE" &&
    isDisplayPeriod(
      videoTicket.display,
      videoTicket.releaseDateTime,
      videoTicket.closeDateTime
    );

  return (
    <div css={classes.videoItem}>
      <div>
        <img
          src={videoThumbnail}
          alt={displayEvent.title}
          style={{ width: "100%" }}
        />
      </div>
      <LiveListItemState ticket={videoTicket} />
      <div css={classes.ticketInfo}>
        <LiveListItemPrice canWatch={canWatch} ticket={videoTicket} />
        <LiveListItemCaution
          ticket={videoTicket}
          // @ts-expect-error TS2322
          isHiddenVODExpiredAt={isHiddenVODExpiredAt}
        />
        <TicketPurchaseButton
          {...{
            isPurchased,
            isOnSale,
            handleButtonAction,
            isLotteryTicketSellingMode,
          }}
        />
      </div>
    </div>
  );
};

const eventVideoTicketListDesign = () => {
  return {
    videoItem: css`
      width: 100%;
      border: 2px solid #d6d6d6;
      @media screen and (min-width: 768px) {
        width: 31%;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    `,
    ticketInfo: css`
      padding: 16px;
    `,
  };
};

/**
 * HOTFIX
 * @see https://github.com/balus-co-ltd/spwn/issues/1478
 */
const getTicketThumbnailToFix1478 = ({
  eid: eventId,
  _id: ticketId,
}: {
  eid: string;
  _id: string;
}) => {
  if (
    ["22102001-jpholostars2ndact", "22102002-engholostars2ndact"].includes(
      eventId
    ) === false
  ) {
    return null;
  }
  const thumbnailMap = {
    /**
     * 22102001-jpholostars2ndact
     */
    // 2階席全景固定プレミアム配信チケット
    gpFh2PlfWBVCscgLbofr:
      "https://public.spwn.jp/event-pages/22102001-jpholostars2ndact/ticketImg/tickets_banner_03.jpg",
    // 2階席全景固定配信チケット
    "0WWe55wJTdSBYj9fXcO5":
      "https://public.spwn.jp/event-pages/22102001-jpholostars2ndact/ticketImg/tickets_banner_03.jpg",
    // 通常配信チケット
    XjEVGWc7JUP2MePSvPBP:
      "https://public.spwn.jp/event-pages/22102001-jpholostars2ndact/ticketImg/top.png",

    /**
     * 22102002-engholostars2ndact
     */
    // Ticket(FixedAngle)with a Bonus
    "0nJ0FPNcoWs3PWqvPkJb":
      "https://public.spwn.jp/event-pages/22102002-engholostars2ndact/ticketImg/tickets_banner_03_en.png",
    // Ticket(Fixed Angle ver.)
    mfCbJa0AR4adanU5GSZB:
      "https://public.spwn.jp/event-pages/22102002-engholostars2ndact/ticketImg/tickets_banner_03_en.png",
    // Streaming Ticket
    Ma9kQGzaZWsUhtQz7Ph0:
      "https://public.spwn.jp/event-pages/22102002-engholostars2ndact/ticketImg/top.png",
  };
  // @ts-expect-error TS7053
  return thumbnailMap[ticketId];
};
