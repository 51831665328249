/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { TextLink } from "../../ui/TextLink";

export const TermSidebar: FC = () => {
  const classes = styles();
  const { pathname } = useLocation();

  return (
    <div css={classes.sidebar}>
      <dl>
        <dt>ファン対象</dt>
        <dd>
          <SidebarItem
            href={"/terms-of-service"}
            isCurrent={pathname === "/terms-of-service"}
          >
            利用規約
          </SidebarItem>
        </dd>
        <dd>
          <SidebarItem
            href={"/privacy-policy"}
            isCurrent={pathname === "/privacy-policy"}
          >
            プライバシーポリシー
          </SidebarItem>
        </dd>
      </dl>

      <dl>
        <dt>クリエイター対象</dt>
        <dd>
          <SidebarItem
            href={"/basic-terms-of-service"}
            isCurrent={pathname === "/basic-terms-of-service"}
          >
            基本利用規約
          </SidebarItem>
        </dd>
        <dd>
          <SidebarItem
            href={"/fanclub-terms-of-service"}
            isCurrent={pathname === "/fanclub-terms-of-service"}
          >
            ファンクラブ運営サービス利用規約
          </SidebarItem>
        </dd>
        <dd>
          <SidebarItem
            href={"/cms-terms-of-service"}
            isCurrent={pathname === "/cms-terms-of-service"}
          >
            コンテンツマネジメントシステム利用規約
          </SidebarItem>
        </dd>
        <dd>
          <SidebarItem
            href={"/music-copyright-guidelines"}
            isCurrent={pathname === "/music-copyright-guidelines"}
          >
            音楽著作権ガイドライン
          </SidebarItem>
        </dd>
        <dd>
          <SidebarItem
            href={"/creator-privacy-policy"}
            isCurrent={pathname === "/creator-privacy-policy"}
          >
            プライバシーポリシー
          </SidebarItem>
        </dd>
      </dl>
    </div>
  );
};

const SidebarItem: FC<{ href: string; isCurrent: boolean; children: string }> =
  ({ href, isCurrent, children }) => {
    if (isCurrent) {
      return <p>{children}</p>;
    }
    return <TextLink href={href}>{children}</TextLink>;
  };

const styles = () => {
  return {
    sidebar: css`
      dl {
        &:not(:last-of-type) {
          margin-bottom: 16px;
        }
      }
      dt {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: bold;
      }
      dd {
        margin: 0;
        margin-bottom: 8px;
        font-size: 16px;
      }
      p {
        line-height: 1.5;
      }
      a {
        text-decoration: none;
      }
    `,
  };
};
