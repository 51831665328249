/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../../store";
import { BreadcrumbArea } from "components/common/Link";
import { changeStyleWithHosting } from "utility/hosting";
import { SubPageSection } from "../../styles";

interface Props {}

class Terms extends Component<Props> {
  render() {
    const privacyInfoList: string[] = [];
    if (changeStyleWithHosting().commonSettings.auth.loginMethod.twitter) {
      privacyInfoList.push("Twitterアカウント");
    }
    if (changeStyleWithHosting().commonSettings.auth.loginMethod.facebook) {
      privacyInfoList.push("Facebookアカウント");
    }
    privacyInfoList.push("Googleアカウント");
    privacyInfoList.push("Emailアドレス");
    privacyInfoList.push("氏名");
    privacyInfoList.push("電話番号");
    privacyInfoList.push("住所");

    const classesSubPageDesign = subPageDesign();

    return (
      <SubPageSection id="contents">
        <BreadcrumbArea
          // @ts-expect-error TS2322
          paths={[
            ["/", "ホーム"],
            [null, "プライバシーポリシー"],
          ]}
        />
        <div css={classesSubPageDesign.root}>
          <h2>プライバシーポリシー</h2>
          <p css={classesSubPageDesign.lawLeadSentence}>
            バルス株式会社(以下「当社」といいます。)は、当社が運営する「SPWN」アプリ(以下「本サービス」といいます。)を通じて取得される個人情報の保護に関する基本方針及び取組みとして、下記のプライバシーポリシー(以下「本プライバシーポリシー」といいます。)を制定・公表いたします。
          </p>

          <p css={classesSubPageDesign.writeSpwn}>
            記<br />
            SPWN
          </p>

          <article css={classesSubPageDesign.textBlock}>
            <h3>1.基本方針</h3>
            <p className="indent_none">
              当社は、「個人情報の保護に関する法律」及び関連する適用法令、条例、関係各省庁の制定するガイドライン並びに加盟団体のガイドラインを遵守・履行し、個人情報の漏洩、滅失、破棄損等の防止のために、これらに基づき適切な安全管理策を施し、また従業員に対しても個人情報の適切な取扱い等についての教育を行い、当社が取得・保有する個人情報の保護に努めます。
            </p>
          </article>

          <article css={classesSubPageDesign.textBlock}>
            <h3>2.個人情報の取得</h3>
            <p className="indent_none">
              当社は、個人情報の入手を適法かつ公正な手段によって行うものとし、不正な入手を行わないものとします。本サービスにおいては、下記の個人情報を取得いたします。
            </p>
            <dl>
              <dt>お客様から提供される情報</dt>
              <dd>電子メールアドレス、氏名、電話番号、住所、生年月日等</dd>
              <dt>本サービスの利用状況に関する情報</dt>
              <dd>コンテンツの利用状況、お問い合わせ状況等</dd>
              <dt>
                外部サービス（Twitter、Google等）との連携により提供される情報
              </dt>
              <dd>
                外部サービスのID、氏名、メールアドレス、生年月日、友達リスト等
              </dd>
            </dl>
            <p className="indent_none">
              なお、お客様により良いサービスを提供するため、お客様のサービス利用に関してお客様の本サービスにおける履歴(使用履歴、検索履歴、閲覧URL)、IP
              アドレス、ウェブブラウザ、OS、言語などの情報を取得及び使用することがあります。
            </p>
          </article>

          <article css={classesSubPageDesign.textBlock}>
            <h3>3.個人情報の利用目的</h3>
            <p className="indent_none">
              お客様に提供いただいた個人情報は、法令で利用が認められる場合の他に、以下の目的で利用されます。
            </p>
            <ul>
              <li>(1)本サービスの円滑な運用</li>
              <li>
                (2)本サービスでのユーザーの個人認証並びにコンテンツ等の配信及びその他のサービスの提供
              </li>
              <li>
                (3)本サービスに関するお客様からのご意見、お問い合わせへの対応
              </li>
              <li>
                (4)本サービス及び当社が行うサービス等に伴う連絡・DM・MM等各種お知らせの配信及び送付
              </li>
              <li>(5)本サービスの改善、新規サービスの開発及びマーケティング</li>
              <li>
                (6)当社が運営するサービスの更新の通知、キャンペーン情報等の告知
              </li>
              <li>
                (7)当社が運営するサービスに関するお客様からのお問い合わせへの対応
              </li>
            </ul>
          </article>

          <article css={classesSubPageDesign.textBlock}>
            <h3>4.個人情報の第三者への開示</h3>
            <p className="indent_none">
              本サービスでは、以下のいずれかの場合を除き、お客様に無断で個人情報を第三者に提供することはありません。
            </p>
            <ul>
              <li>
                (1)本サービスを利用してイベントの配信又はコンテンツ・物品の配信を行う事業者（以下「主催者等」といいます。）への提供
                <ul>
                  <li>
                    本サービスにてコンテンツを視聴したお客様に対して、主催者等が、(i)イベント・配信の変更・中止等のご案内その他必要なご案内、及び(ii)主催者等のイベント、商品、サービス等のご案内を行うため、当社は主催者等に対して、お客様の個人情報の全部又は一部を提供する場合があります。主催者等に提供される個人情報には、お客様のID、氏名、住所、電話番号、電子メールアドレス、コンテンツの視聴状況、お問い合わせ状況等を含みます。但し、当社は、主催者等対し、当該主催者等が主催する事業に関連しない情報（第三者が主催するイベントへの参加状況等）を提供することはありません。
                  </li>
                </ul>
              </li>
              <li>
                (2)法令に基づき許容される場合
                <ul>
                  <li>
                    ・本サービスのシステム等の保守・運営のために当社が監督を行う業務委託先に提供する場合
                  </li>
                  <li>
                    ・利用目的の達成のため、当社が監督を行う業務委託先に提供する場合
                  </li>
                  <li>
                    ・法令により開示を求められた場合や、個人の生命、身体又は財産の保護のために提供する場合
                  </li>
                  <li>・予めお客様の同意を得て提供する場合</li>
                  <li>
                    ・当社が当事者となる合併、会社分割、事業譲渡等(その実施のための検討も含みます。)に伴い、個人情報を提供する場合
                  </li>
                  <li>・その他法令に基づき提供が許容される場合</li>
                </ul>
              </li>
              <li>
                (3)統計的な利用
                <ul>
                  <li>
                    当社は、個人が特定できないように処理を講じた上で、お客様に関する統計的な集計・分析結果を第三者に提供することがあります。
                  </li>
                </ul>
              </li>
            </ul>
          </article>

          <article css={classesSubPageDesign.textBlock}>
            <h3>5.個人情報の開示・訂正等</h3>
            <p className="indent_none">
              当社は、お客様に関する保有個人データについて、お客様ご本人又はその代理人からの開示、利用目的の通知、訂正、追加、削除、停止又は消去等(以下、あわせて「開示等」といいます。)の請求に誠実に対応・処理させていただきます。
            </p>

            <p>(1)開示等の求めに関する申出先</p>
            <p className="indent_none">
              開示等の求めは、必要書類を添付の上、下記7記載の窓口を宛先としてEメールにて送信していただきますようお願い申し上げます。
            </p>

            <p>(2)開示等の求めに際して提出すべき書類</p>
            <p className="indent_none">
              開示等の求めを行う場合は、本人確認のための書類(運転免許証、パスポート、保険証等。必ずマイナンバーの記載がないものをご利用ください。)を添付し、下記7記載の窓口までEメールにて送信ください。なお、開示等の求めを代理人等を通じて行う場合は、お客様ご本人との関係を証明する書類をお送りいただく必要があります。
            </p>

            <p>(3)開示等の求めに対する対応</p>
            <p className="indent_none">
              当社は、お客様ご本人又はその代理人からその個人情報についての開示の請求がある場合、速やかに開示します。当該個人情報の内容に誤りがあり、お客様ご本人又はその代理人から訂正、追加又は削除の請求がある場合、調査の上、その結果に基づき、速やかにこれらの請求に対応します。また、当該個人情報が利用目的又は個人情報保護法上の適正な取得の規定に違反し、お客様ご本人又はその代理人から利用停止又は消去の請求がある場合、当該違反を是正するために必要な限度で、速やかにこれらの請求に対応します。これらの請求等に際して、本人確認できない場合又は法令に定めのある場合には、当該請求等に応じないものとします。なお、お客様からの請求を受けてその個人情報を削除、利用停止又は消去した場合においては、当該お客様は、以後本サービスの提供を受けられないものとします。
            </p>
          </article>

          <article css={classesSubPageDesign.textBlock}>
            <h3>6.適用される範囲</h3>
            <p className="indent_none">
              本プライバシーポリシーは、お客様が本サービスの利用に際して当社に提供した個人情報及びお客様による本サービスに関連する当社のウェブサイトの利用に際して当社が取得した情報の管理等に適用されます。また、お客様が本サービスを利用される為に登録された個人情報は、本プライバシーポリシーに従って管理されるものとします。本プライバシーポリシーと当社のその他のプライバシーポリシーの内容に齟齬がある場合には、本プライバシーポリシーが優先するものとします。
            </p>
          </article>

          <article css={classesSubPageDesign.textBlock}>
            <h3>7.ご意見、お問い合わせ等の受付窓又に関する事項</h3>
            <p className="indent_none">
              当社の個人情報の取扱いに関するご意見、お問い合わせ等につきましては、下記の窓口にご連絡ください。なお、直接の来社によるお問い合わせにつきましてはお断りさせていただいておりますので、予めご了承ください。
              <br />
              <br />
              バルス株式会社
              <br />
              support@spwn.jp
            </p>
          </article>

          <article css={classesSubPageDesign.textBlock}>
            <h3>8.変更及び修正</h3>
            <p className="indent_none">
              本プライバシーポリシーの内容は、サービス内容や技術動向の変化、法令の改正等に従って予告なく変更及び修正されることがあります。
            </p>
          </article>
        </div>
      </SubPageSection>
    );
  }
}

const mapStateToProps = (_state: Store) => {
  return {};
};

const mapDispatchToProps = (_dispatch: Dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Terms);

const subPageDesign = () => {
  return {
    root: css`
      width: 90%;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        max-width: 680px;
        width: 100%;
      }
      h2 {
        margin-bottom: 40px;
        font-size: 20px;
        text-align: center;
        font-weight: normal;
        @media screen and (min-width: 768px) {
          font-size: 25px;
        }
      }
    `,
    lawLeadSentence: css`
      margin-bottom: 40px;
    `,
    writeSpwn: css`
      margin-bottom: 40px;
      text-align: center;
    `,
    textBlock: css`
      margin-bottom: 25px;
      h3 {
        margin-bottom: 15px;
        line-height: 1.3em;
      }
      p {
        margin-bottom: 10px;
      }
      .indent_none {
        padding-left: 0;
        text-indent: inherit;
      }
      dl {
        dt {
          font-size: 15px;
          font-weight: bold;
        }
        dd {
          margin: 0 0 15px;
          font-size: 15px;
          line-height: 1.5em;
          letter-spacing: 1px;
        }
      }
      ul {
        margin-bottom: 10px;
        li {
          margin: 0 0 5px;
          font-size: 15px;
          line-height: 1.5em;
          letter-spacing: 1px;
          ul {
            padding-left: 1.5em;
          }
        }
      }
      a {
        font-size: 15px;
      }
    `,
  };
};
