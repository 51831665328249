/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";

export const PortalTopEventLargeCard: FC<{
  href: string;
  tag: string;
  tagColor: string;
  thumbnail: string;
  date: string;
  time?: string;
  title: string;
}> = ({ href, tag, tagColor, thumbnail, date, time, title }) => {
  const classes = styles({
    tagColor,
  });

  return (
    <article css={classes.item}>
      <Link to={href}>
        <div css={classes.thumbnail}>
          <p className="tag">{tag}</p>
          <img src={thumbnail} alt={title} />
        </div>

        <div css={classes.info}>
          <div css={classes.datetime}>
            <p className="date">{date}</p>
            {time && <p>OPEN {time}</p>}
          </div>
          <h3 className="title">{title}</h3>
        </div>
      </Link>
    </article>
  );
};

const styles = ({ tagColor }: { tagColor: string }) => {
  return {
    item: css`
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;
    `,
    thumbnail: css`
      position: relative;
      .tag {
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px 10px;
        color: ${tagColor};
        font-size: 14px;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.7);
      }
      img {
        width: 100%;
      }
    `,
    info: css`
      padding: 16px 16px 32px;
      font-weight: bold;
      .title {
        line-height: 1.5em;
      }
    `,
    datetime: css`
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      @media screen and (min-width: 768px) {
        margin-bottom: 8px;
      }
      .date {
        margin-right: 8px;
      }
    `,
  };
};
