/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";

export const Stack: FC<{
  children: JSX.Element[];
  direction?: "row" | "column";
  gap?: string;
}> = ({ children, direction = "row", gap }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${direction};
        gap: ${gap};
      `}
    >
      {children.map((element) => element)}
    </div>
  );
};
