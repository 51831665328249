/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import { push } from "connected-react-router";
import { Store } from "../../../store";
import { convertMapToValues, getNowTimestamp } from "../../../utility";
import { modalActions } from "modules/modal";

type BellNotificationProps = {};
export const BellNotification: React.FC<BellNotificationProps> = (_props) => {
  // store values
  const notificationMap = useSelector(
    (state: Store) => state.notification.notificationMap
  );
  const location = useSelector((state: Store) => state.router.location);

  // store modules
  const dispatch = useDispatch();

  // states
  const [hasUnRead, setHasUnRead] = React.useState<boolean>(false);

  const classes = styles();

  useEffect(() => {
    if (notificationMap) {
      const nowTimestamp = getNowTimestamp();
      const unReadNotifications = convertMapToValues(notificationMap).filter(
        (noification) =>
          !noification.hasRead && noification.startAt.seconds < nowTimestamp
      );
      const hasUnRead = unReadNotifications.length > 0;
      setHasUnRead(hasUnRead);

      // force popup for confirmation
      const requiredReadNotifications = convertMapToValues(
        notificationMap
      ).filter((el) => el.isReadRequired && !el.hasRead);
      // don't popup in specific page (streaming(配信中を考慮), notification (2つ以上あることを考慮))
      const isExcludedLocation =
        location.pathname.indexOf("streaming") > 0 ||
        location.pathname.indexOf("notifications") > 0;
      if (!isExcludedLocation && requiredReadNotifications.length > 0) {
        const [requiredReadNotification] = requiredReadNotifications;
        dispatch(
          modalActions.toggleActionModal({
            actionModalType: "confirmTransition",
            trunk: {
              // @ts-expect-error TS18048
              link: `/account/notifications/${requiredReadNotification._id}`,
            },
            // @ts-expect-error TS18048
            caption: requiredReadNotification.subject,
            msg: "以下より詳細ををご確認ください。",
            btnMsg: "確認する",
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationMap]);

  const handleClick = () => {
    dispatch(push("/account/notifications"));
  };

  return (
    <div css={classes.root}>
      <Badge
        invisible={!hasUnRead}
        overlap="circle"
        variant="dot"
        color="secondary"
        id="badge"
      >
        <button id="bell" css={classes.button} onClick={handleClick}>
          <NotificationsIcon aria-label="Notifications" />
        </button>
      </Badge>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      .MuiBadge-badge {
        border: 1px solid #fff;
      }
    `,
    button: css`
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
    `,
  };
};
