import React from "react";
import { RedirectState } from "modules/router";
import { Redirect } from "react-router";

export const RedirectToInternal: React.FC<{
  path: string;
  state: RedirectState;
}> = (props) => {
  return <Redirect to={{ pathname: props.path, state: props.state }} />;
};
