import { UserCartDTO } from "@spwn/types/functions";
import { useMemo } from "react";
import { KeyedMutator } from "swr";
// FIXME カートコンポーネント改修まわりで型整理 https://github.com/balus-co-ltd/spwn/issues/1983
import { Cart, OrderCart, useCart } from "../hooks/cart/Cart";
import { getShippingAtPreviewText } from "./goodsModel";
import { useGetUserCart, UserCart } from "./useGetUserCart";

/**
 * 既存カートとテナントカートからカートモーダル用のカートモデルを生成する
 * - useCart
 * 既存カート。
 * - useGetUserCart
 * テナントグッズを含むカート。
 *
 * 将来的にはuseGetUserCartに処理を倒していきたいが、既存機能に差し込むためこのような実装にしている
 */

export const useUserCart = (): {
  data: Cart | undefined;
  error: unknown;
  isValidating: boolean;
  mutate: KeyedMutator<UserCartDTO>;
} => {
  const {
    cart: origCart,
    loading: isLoadingOrigCart,
    error: origError,
  } = useCart();
  const { data: userCart, error, isValidating, mutate } = useGetUserCart();

  return {
    data: useMemo(() => {
      const isLoading = isLoadingOrigCart || userCart === undefined;
      if (isLoading) return undefined;
      return buildCart(origCart, userCart);
    }, [isLoadingOrigCart, origCart, userCart]),
    error: useMemo(() => {
      // 既存カートのエラーを優先的に返す。現状OrigCartDialogではエラーハンドリングやってないが。
      return origError ?? error;
    }, [origError, error]),
    isValidating,
    mutate,
  };
};

/**
 * 既存チケット、グッズのカートと、テナントグッズのカートからカートコンポーネント用のモデルを生成する
 */
export const buildCart = (oldCart: Cart | null, cart: UserCart): Cart => {
  if (!cart) {
    return {
      orderCarts: [],
      cartItemCount: 0,
      removedCartItems: [],
    };
  }
  // テナントリソースのorderCartsを生成
  // @ts-expect-error TS2322
  const tenantOrderCarts: OrderCart[] = cart.tenantGroups.map((group) => {
    return {
      groupId: group.tenantId,
      // 既存カートと合わせた後につける
      heading: "",
      // 発送日でグルーピングしないので必ずnull
      shippingAtTitle: null,
      subTotalPrice: group.totalAmount,
      shippingFee: group.totalShippingFee,
      products: group.items.map((item) => {
        return {
          name: item.name,
          thumbnail: item.thumbnail,
          price: item.price,
          count: item.quantity,
          // FIXME コンポーネントの詳細実装で、ドメインが変わらない場合、外部ドメインの場合を考慮して生成方法をあらためて検討
          links: [
            {
              name: "関連グッズをみる",
              // ショップへのURL。
              url: `https://${item.brandSlug}.spwn.jp/shop`,
            },
          ],
          // ユニークなid想定だがテナントグッズでは使用されない
          id: item.inventoryProductId,
          // Firestore Document Pathは必要ないのでnull
          refPath: null,
          // 必要ないので空文字
          cartProductId: "",
          // 保持期限がないのでnull
          retentionSeconds: null,
          // subClassNameは既存グッズのバリエーション名
          subClassName: item.variantName,
          // 商品の発送関連の文言。
          // TODO cmsと合わせる
          shippingAtTitle: getShippingAtPreviewText(
            item.deliveryMethod,
            item.deliveryAtEpochMillis
          ),
          status: item.status,
          //cartItemsのドキュメントId
          cartItemId: item.cartItemId,
          isTenantCart: true,
          /**
           * CMSグッズに会場支払いは現状ない
           */
          isVenuePay: false,
        };
      }),
      // 購入URL
      purchaseLink: `/purchase?tenantId=${group.tenantId}`,
    };
  });

  // 既存カートとテナントカートを繋げて、カート名を付与
  const newOrderCarts = tenantOrderCarts
    .concat(oldCart ? oldCart.orderCarts : [])
    .map((el, i) => ({
      ...el,
      heading: `カート${i + 1}`,
    }));

  /**
   * newOrderCartsのproductsから販売可能なアイテムだけをfilterする
   */
  const orderCarts = newOrderCarts
    .map((cart) => {
      return {
        ...cart,
        products: cart.products.filter(
          (product) =>
            product.status === "available" || product.status === undefined
        ),
      };
    })
    .filter((cart) => cart.products.length > 0);

  /**
   * newOrderCartsのproductsから販売できないアイテムだけをfilterする
   */
  const removedCartItems = newOrderCarts
    .map((cart) => {
      return cart.products.filter(
        (item) =>
          item.status === "outsideSalesPeriod" ||
          item.status === "soldOut" ||
          item.status === "outOfStock"
      );
    })
    .flat();

  // 既存カートとテナントカートを合わせたアイテム数
  // https://github.com/balus-co-ltd/spwn/blob/ea594518898db187bc8bda9bbfbbfa239e5ca787/packages/portal/src/hooks/cart/Cart.ts#L214-L218
  const cartItemCount = orderCarts
    .map(({ products }) => products)
    .flat()
    .map((product) => product.count)
    .reduce((prev, cur) => prev + cur, 0);

  return {
    orderCarts,
    cartItemCount,
    removedCartItems,
  };
};
