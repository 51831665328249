/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { changeStyleWithHosting } from "utility/hosting";
import { useI18n } from "../../hooks/i18n/i18n";
import { LinkButton } from "../../styles";

type BreadcrumbProps = {
  paths: [["/", "ホーム"], ...[string | (() => void), string][]]; // [to, name]
  additionalClassName?: string; // use for flowerstand
  isDarkModeEnabled?: boolean;
};
export const BreadcrumbArea = (props: BreadcrumbProps) => {
  const { t } = useI18n();

  // TODO: Variadic Tuple Types が導入される TypeScript 4.1 まで ignore
  // eslint-disable-next-line prefer-destructuring
  let paths: [string | (() => void), string][] = props.paths;
  // remove home breadcrumb. first item is must to be home.
  if (changeStyleWithHosting().commonSettings.route.home !== "/") {
    paths = paths.slice(1);
  }

  const classesBreadcrumbDesign = breadcrumbDesign({
    isDarkModeEnabled: props.isDarkModeEnabled,
  });
  return (
    <div
      className={`breadcrumb ${props.additionalClassName}`}
      css={classesBreadcrumbDesign.root}
    >
      <ul>
        {paths.map((path, i, array) => {
          const [to, pathname] = path;
          const name =
            pathname === "ホーム" ? t("common.routes.home") : pathname;
          if (i < array.length - 1) {
            if (typeof to === "string") {
              return (
                <li key={i}>
                  <Link to={to}>{name}</Link>
                </li>
              );
            } else {
              return (
                <li key={i}>
                  <p onClick={to}>カート</p>
                </li>
              );
            }
          } else {
            return <li key={i}>{name}</li>;
          }
        })}
      </ul>
    </div>
  );
};

// @ts-expect-error TS7006
const breadcrumbDesign = (props) => {
  const theSpecifiedTextColor = changeStyleWithHosting().baseStyles.accentColor;
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : css`
        color: ${theSpecifiedTextColor};
      `;
  const arrowColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : css`
        color: #000;
      `;
  const backgroundColorInDarkMode = props.isDarkModeEnabled
    ? css`
        background-color: #202020;
      `
    : css`
        background-color: #fff;
      `;
  const borderColorInDarkMode = props.isDarkModeEnabled
    ? css`
        border-bottom: 1px solid #fff;
      `
    : css`
        border-bottom: 1px solid #d2d2d2;
      `;

  return {
    root: css`
      display: none;
      padding: 8px 0;
      ${backgroundColorInDarkMode}
      ${borderColorInDarkMode}
      @media screen and (min-width: 768px) {
        display: block;
      }
      .content {
        width: 90%;
        @media screen and (min-width: 768px) {
          width: 980px;
          max-width: 90%;
        }
      }
      ul {
        display: flex;
        width: 95%;
        margin: 0 auto;
      }
      li {
        display: flex;
        align-items: center;
        ${textColorInDarkMode}
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        &:not(:last-child):after {
          content: ">";
          display: block;
          margin: 0 10px;
          ${arrowColorInDarkMode}
          font-weight: normal;
        }
      }
      a {
        ${textColorInDarkMode}
      }
    `,
  };
};

/**
 * go external site component for preventing popup block
 * @param props
 */
export const GoExternalSite = (props: GoExternalSiteProps) => {
  const classes = goExternalSiteDesign();
  const { t } = useI18n();
  useEffect(() => {
    /**
     * FIXME
     * 自動でリダイレクトするようにする
     * background https://balus3d.slack.com/archives/C01M0PS29PX/p1666323553247219?thread_ts=1666323477.597909&cid=C01M0PS29PX
     */
    window.location.replace(props.externalLink);
  }, [props.externalLink]);
  return (
    <div id="contents" css={classes.root}>
      <div css={classes.content}>
        {/* 自動でリダイレクトしない場合の救済措置 FIXME i18n */}
        <LinkButton onClick={() => window.location.replace(props.externalLink)}>
          {t("common.routes.externalSite")}
        </LinkButton>
        <p
          css={classes.caution}
        >{`自動で遷移しない場合はボタンをクリックしてください。`}</p>
        {props.caution && <p css={classes.caution}>{props.caution}</p>}
      </div>
    </div>
  );
};
type GoExternalSiteProps = {
  externalLink: string;
  caution?: string;
};
const goExternalSiteDesign = () => ({
  root: css`
    position: relative;
    padding: 75px 0;
    background-color: #fff;
    min-height: 80vh;
  `,
  content: css`
    margin: 0 auto;
    width: 80%;
  `,
  caution: css`
    margin-top: 20px;
    line-height: 1.3em;
    font-weight: bold;
  `,
});
