/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { DisplayUserInputText } from "components/common/DisplayUserInputText";
import {
  EventTimetableVideoButton,
  EventTimetableDayAndStageButton,
} from "./EventTimetableVideoButton";
import { EventTimetableEndLiveButton } from "./EventTimetableEndLiveButton";
import type { DayContent, StageContent, Video } from "@spwn/types";

type DayAndStageActionStatus =
  | StageContent["actionStatus"]
  | DayContent["actionStatus"];
interface Props {
  dayName: string;
  stageName: string;
  renderEvent: Video;
  dayActionStatus: DayAndStageActionStatus;
  stageActionStatus: DayAndStageActionStatus;
  initializeState: () => void;
  doDayButtonAction: () => void;
  doStageButtonAction: () => void;
  doVideoButtonAction: () => void;
}

const convertEventTimeString = (time: number): string => {
  return new Date(time * 1000).toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
  });
};

export const EventTimetableContentsItemDetail: React.FC<Props> = (props) => {
  const { startAt, endAt, artists } = props.renderEvent;
  const eventTimeFromStartToEnd = `${convertEventTimeString(
    startAt
  )}-${convertEventTimeString(endAt)}`;
  const classes = styles();

  return (
    <section css={classes.root}>
      <div css={classes.layout}>
        <div css={classes.close} onClick={props.initializeState}></div>

        <div css={classes.detail}>
          <div className="thumbnail">
            <img
              src={props.renderEvent.thumbnail}
              alt={props.renderEvent.name}
            />
          </div>

          <div className="info">
            <div className={"event_time"}>
              <ScheduleIcon />
              <p>{eventTimeFromStartToEnd}</p>
            </div>

            <div className={"event_detail"}>
              <h4>{props.renderEvent.name}</h4>
              <DisplayUserInputText>
                {props.renderEvent.description}
              </DisplayUserInputText>
            </div>

            {artists.length > 0 && (
              <div className={"artist_list"}>
                <h4>出演アーティスト</h4>
                <ul>
                  {artists.map((artist, index) => {
                    return (
                      <li key={index}>
                        <img src={artist.imgPath} alt={artist.name} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>

        <TimetableModalButton
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dayActionStatus={props.dayActionStatus as any}
          stageActionStatus={props.stageActionStatus}
          videoActionStatus={props.renderEvent.actionStatus}
          doDayButtonAction={props.doDayButtonAction}
          doStageButtonAction={props.doStageButtonAction}
          doVideoButtonAction={props.doVideoButtonAction}
        />
      </div>
    </section>
  );
};

const TimetableModalButton = ({
  dayActionStatus,
  stageActionStatus,
  videoActionStatus,
  doDayButtonAction,
  doStageButtonAction,
  doVideoButtonAction,
}: {
  dayActionStatus: DayContent["actionStatus"];
  stageActionStatus: StageContent["actionStatus"];
  videoActionStatus: Video["actionStatus"];
  doDayButtonAction: () => void;
  doStageButtonAction: () => void;
  doVideoButtonAction: () => void;
}) => {
  if (videoActionStatus === "EndLive") {
    return (
      <EventTimetableEndLiveButton>
        当日のこの配信は終了しました
      </EventTimetableEndLiveButton>
    );
  }

  const classes = styles();
  return (
    <div css={classes.buttonList}>
      <div className="button">
        <EventTimetableDayAndStageButton
          name={"DAY"}
          actionStatus={dayActionStatus}
          doButtonAction={doDayButtonAction}
        />
      </div>

      <div className="button">
        <EventTimetableDayAndStageButton
          name={"STAGE"}
          actionStatus={stageActionStatus}
          doButtonAction={doStageButtonAction}
        />
      </div>

      <div className="button">
        <EventTimetableVideoButton
          actionStatus={videoActionStatus}
          doVideoButtonAction={doVideoButtonAction}
        />
      </div>
    </div>
  );
};

const styles = () => {
  return {
    root: css`
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      overflow-y: scroll;
      z-index: 900;
    `,
    layout: css`
      position: relative;
      width: 100%;
      min-height: 100vh;
      padding: 16px;
      background-color: #fff;
      z-index: 910;
      @media screen and (min-width: 768px) {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 980px;
        height: auto;
        min-height: auto;
        overflow: scroll;
        max-height: 90%;
        padding: 32px;
        transform: translate(-50%, -50%);
      }
    `,
    close: css`
      position: relative;
      width: 32px;
      height: 32px;
      margin-left: auto;
      margin-bottom: 16px;
      cursor: pointer;
      @media screen and (min-width: 768px) {
        margin-bottom: 32px;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #0f0f0f;
        transform: translateY(-50%);
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    `,
    detail: css`
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 24px;
      @media screen and (min-width: 768px) {
        grid-template-columns: calc(50% - 12px) calc(50% - 12px);
        grid-gap: 24px;
      }
      .thumbnail {
        img {
          border: 1px solid #dedede;
          width: 100%;
        }
      }
      .info {
        .event_time {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          svg {
            margin-right: 8px;
          }
          p {
            font-family: "din-condensed", sans-serif;
            color: #00c2ad;
            font-size: 20px;
            letter-spacing: 1px;
          }
        }
        .event_detail {
          margin-bottom: 16px;
          h4 {
            margin-bottom: 8px;
          }
          div {
            font-size: 14px;
            line-height: 1.5em;
          }
          a {
            color: #00c5cb;
          }
        }
        .artist_list {
          h4 {
            margin-bottom: 8px;
            font-size: 14px;
          }
          ul {
            overflow-x: scroll;
            white-space: nowrap;
          }
          li {
            display: inline-block;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            border: 1px solid #dedede;
            overflow: hidden;
            transition: width 0.2s;
            outline: none;
            user-select: none;
            &:not(:last-of-type) {
              margin-right: 8px;
            }
          }
          img {
            width: 64px;
            height: 64px;
            object-fit: cover;
          }
        }
      }
    `,
    buttonList: css`
      font-size: 14px;
      margin-top: 32px;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .button {
        width: 100%;
        &:not(:last-of-type) {
          margin-bottom: 16px;
          @media screen and (min-width: 768px) {
            margin-bottom: 0;
            margin-right: 16px;
          }
        }
      }
    `,
  };
};
