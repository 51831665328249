/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { ComponentProps } from "react";
import { EventCapsuleItemInfo } from "./EventCapsuleItemInfo";

type Props = ComponentProps<typeof EventCapsuleItemInfo>;

export const EventCapsuleItem: React.FC<Props> = (props) => {
  const { classGoodsList } = props;
  const classesPageLayout = pageLayout();
  return (
    <React.Fragment>
      {/* purchase area */}
      <EventCapsuleItemInfo {...props} />

      {/* variant image list */}
      <div css={classesPageLayout.capsuleImages}>
        {classGoodsList.map((goods, index) => {
          return (
            <div key={index}>
              <img src={goods.variantThumbnail} alt={`goods-img-0${index}`} />
              <p>{goods.subClassName}</p>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

const pageLayout = () => {
  return {
    capsuleImages: css`
      display: flex;
      flex-wrap: wrap;
      width: 90%;
      margin: 16px auto 0;
      gap: 20px 18px;
      @media screen and (min-width: 768px) {
        width: 100%;
        margin: 64px auto 0;
        gap: 30px 24px;
      }
      div {
        display: flex;
        flex-direction: column;
        width: calc(100% / 2 - 9px);
        @media screen and (min-width: 768px) {
          width: calc(100% / 4 - 18px);
        }
        img {
          width: 100%;
          height: auto;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        }
        p {
          margin: 15px 8px 5px;
          line-height: 24px;
          font-weight: 700;
        }
      }
    `,
  };
};
