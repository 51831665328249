/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { SHIPPING_FEE_LABEL } from "constants/fulfillment";
import React, { FC } from "react";

type CapsuleTotalFeeDetailsProps = {
  numOfRolls: number;
  price: number;
  shippingFee: number;
};

export const CapsuleTotalFeeDetails: FC<CapsuleTotalFeeDetailsProps> = ({
  numOfRolls,
  price,
  shippingFee,
}) => {
  const subtotal = price * numOfRolls;
  const total = subtotal + shippingFee;
  return (
    <div css={rootStyle}>
      <dl css={feeItemStyle}>
        <dt css={totalAmountDtStyle}>合計金額：</dt>
        <dd css={totalAmountDdStyle}>{`¥${total.toLocaleString()}`}</dd>
      </dl>
      <div css={breakdownWrapperStyle}>
        <dl css={feeItemStyle}>
          <dt>小計：</dt>
          <dd>{`¥${subtotal.toLocaleString()}`}</dd>
        </dl>
        <dl css={feeItemStyle}>
          <dt>{SHIPPING_FEE_LABEL}：</dt>
          <dd>{`¥${shippingFee.toLocaleString()}`}</dd>
        </dl>
      </div>
    </div>
  );
};

const rootStyle = css`
  margin-bottom: 20px;
  padding: 24px 0 20px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
`;

const feeItemStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  font-size: 14px;
`;

const totalAmountDtStyle = css`
  font-size: 16px;
  font-weight: bold;
`;

const totalAmountDdStyle = css`
  font-size: 20px;
  font-weight: bold;
`;

const breakdownWrapperStyle = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`;
