import { css } from "@emotion/core";

export const myPageHistoryMixin = css`
  min-height: calc(100vh - 436px);
  padding-bottom: 80px;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    min-height: calc(100vh - 280px);
  }
`;
