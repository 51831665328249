import appConfig from "constants/appConfig";
import {
  fetchFbToken,
  fetchFirestoreCollectionBySnapshot,
} from "utility/firebase";
import { errorReport } from "./logger";
import type { ResGetSendingFee } from "@spwn/types/functions";
import type {
  HostingType,
  DomainGroup,
  ActiveTransaction,
} from "@spwn/types/firebase/firestore";
import { isOrderTransaction } from "features/orderModel";
import firebase from "firebase/app";
import "firebase/auth";

/**
 * return shipping fee
 */
export const checkSendingFee = async (
  user: firebase.User
): Promise<ResGetSendingFee | null> => {
  try {
    if ("uid" in user === false) return null;

    const fbToken = await fetchFbToken();
    // @ts-expect-error TS2769
    const response = await fetch(appConfig.CloudFunctions.getSendingFee, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify({}),
    });
    if (response.status === 200) {
      const json: ResGetSendingFee = await response.json();
      return {
        ...json,
      };
    } else {
      const json = await response.json();
      console.error(json.msg);
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

const DEFAULT_SHIPPING_FEE = {
  regular: 1000,
  far: 2000,
};
/**
 * calc shipping fee every domain group and return sum
 */
export const calcDomainShippingFee = async (
  domains: HostingType[],
  destinationType: keyof DomainGroup["shippingFee"]
): Promise<number | null> => {
  try {
    // get domain settings
    const domainPromises = [];
    for (const domain of domains) {
      const snapshot = firebase
        .firestore()
        .collection(`hosting/shipping/domainGroups`)
        .where("ownedDomains", "array-contains", domain)
        .get();
      domainPromises.push(
        fetchFirestoreCollectionBySnapshot<DomainGroup>(snapshot)
      );
    }
    const allPromises = await Promise.all<DomainGroup[]>(domainPromises);
    if (allPromises.length === 0) {
      errorReport(
        new Error(`${domains.join()} first fail safe default shipping fee`)
      );
      return DEFAULT_SHIPPING_FEE[destinationType];
    }
    const domainSettings = [];
    for (const settings of allPromises) {
      if (settings && settings.length === 0) {
        // fail safe
        errorReport(
          new Error(`${domains.join()} second fail safe default shipping fee`)
        );
        domainSettings.push({
          shippingFee: DEFAULT_SHIPPING_FEE,
        });
        continue;
      }
      // 1要素目のみ使う
      domainSettings.push(settings[0]);
    }
    if (domainSettings.length === 0) {
      // fail safe
      errorReport(
        new Error(`${domains.join()} third fail safe default shipping fee`)
      );
      return DEFAULT_SHIPPING_FEE[destinationType];
    }
    const shippingFee = domainSettings
      // @ts-expect-error TS18048
      .map((el) => el.shippingFee[destinationType])
      .reduce((a, b) => a + b, 0);

    return shippingFee;
  } catch (error) {
    return null;
  }
};

export const DISABLED_PURCHASE_BY_CVS = (eventId: string) => {
  switch (eventId) {
    case "200719-vills":
      return true;
    default:
      return false;
  }
};

/**
 * マイチケット用：決済済・未決済の注文データを取得する
 * @param uid
 * @returns
 */
export const fetchMyOrders = async (uid: string) => {
  const ref = `settlement/${uid}/activeTransactions`;
  const snapshot = firebase
    .firestore()
    .collection(ref)
    // 決済処理が完了している
    .where("isPurchased", "==", true)
    .get();
  const unProcessedStates = ["UNPROCESS", "AUTHPROCESS"];
  const paidOrders: ActiveTransaction[] = [];
  const unPaidOrders: ActiveTransaction[] = [];
  const settlements =
    await fetchFirestoreCollectionBySnapshot<ActiveTransaction>(snapshot);
  settlements.forEach((settlement) => {
    // isCancelled: 存在しないレコードがある
    if (settlement?.isCancelled) return;
    // 社員の場合、productsがない、productsにarrayではないデータが入っている可能性があるので対象外とする
    if (!settlement?.products || !Array.isArray(settlement.products)) return;
    // テナントのオーダーの場合、productsが空なので省く
    if (isOrderTransaction(settlement)) return;
    if (settlement?.status === "PAYSUCCESS") {
      paidOrders.push(settlement);
      // @ts-expect-error TS2345
    } else if (unProcessedStates.includes(settlement?.status)) {
      unPaidOrders.push(settlement);
    }
  });
  return {
    paidOrders,
    unPaidOrders,
  };
};
