/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { EventDate } from "../../../components/event/detail/header/EventDate";
import { changeStyleWithHosting } from "../../../utility/hosting";
import { EventInfo } from "../../../utility";
import { useI18n } from "../../../hooks/i18n/i18n";
import { TicketDataJson } from "../../../modules/event";

interface Props {
  eventInfo: EventInfo;
  ticketsData: TicketDataJson;
}
export const EventDetailHeading: React.FC<Props> = (props) => {
  const { t } = useI18n();
  const classes = styles();
  const hostingCommonSettingsMap = changeStyleWithHosting().commonSettings;
  const hostingEventPageStyleMap =
    changeStyleWithHosting().pageSettings.eventPage;

  const setEventDate = props.eventInfo.isLongEvent
    ? props.eventInfo.eventOpenDateFull +
      " ~ " +
      props.eventInfo.eventEndDateFull
    : props.eventInfo.eventOpenDateFull;

  let eventStatusText = "";
  if (props.eventInfo.isClosed && !props.eventInfo.hasVOD) {
    eventStatusText = t("event.livestatus.endLive");
  } else if (props.eventInfo.isOpenStreamingPage) {
    eventStatusText = t("event.livestatus.nowLive");
  } else if (
    props.ticketsData.display_sale_datetime &&
    props.eventInfo.isTicketSellingPageOpen
  ) {
    eventStatusText = `${t("event.livestatus.startBuyTicket")} ${
      props.eventInfo.ticketOpenDateInfo.dateFullDayTime
    }`;
  }

  return (
    <React.Fragment>
      <EventDate
        isDisplayEventDate={hostingCommonSettingsMap.isDisplayEventDate}
        isDisplayEventTime={hostingCommonSettingsMap.isDisplayEventTime}
        datetimeStr={setEventDate}
        parts={props.eventInfo.parts}
      />

      {hostingEventPageStyleMap.isDisplayEventState && eventStatusText && (
        <p css={classes.eventState}>{eventStatusText}</p>
      )}

      {hostingEventPageStyleMap.isDisplayEventTitle && (
        <h2 css={classes.eventTitle}>{props.eventInfo.eventTitle}</h2>
      )}

      {hostingEventPageStyleMap.isDisplayArtist && (
        <p css={classes.eventArtist}>{props.eventInfo.artists}</p>
      )}
    </React.Fragment>
  );
};

const styles = () => {
  return {
    eventState: css`
      margin-bottom: 15px;
      color: #ff0076;
      font-size: 14px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        margin-bottom: 18px;
        font-size: 20px;
      }
    `,
    eventTitle: css`
      margin-bottom: 10px;
      font-size: 22px;
      line-height: 1.3em;
      white-space: pre-line;
      @media screen and (min-width: 768px) {
        margin-bottom: 25px;
        font-size: 30px;
      }
    `,
    eventArtist: css`
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.3em;
      white-space: pre-line;
      @media screen and (min-width: 768px) {
        margin-bottom: 45px;
        font-size: 20px;
      }
    `,
  };
};
