/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Store } from "../store";
import firebase from "firebase/app";
import "firebase/auth";
import { FullScreenDialog } from "components/common/Modal";
import { authActions } from "../modules/auth";
import { getSlashSeparatedDateTimeString } from "utility/index";
import { push } from "connected-react-router";
import { WithTranslation, withTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { ButtonList, LinkButton } from "../styles";
import styled from "@emotion/styled";

const EmailButtonList = styled(ButtonList)`
  > * {
    margin-bottom: 16px;
  }
`;

const modalStyle = {
  position: "absolute",
  top: 65,
  margin: "0 auto",
  left: 0,
  right: 0,
  maxWidth: "calc(100% - 40px)",
  width: "100%",
  height: `calc(100% - ${65}px)`,
  backgroundColor: "#efefef",
  borderRadius: 0,
};

interface Props extends WithTranslation {
  t: TFunction;
  user: { email: string };
  pathname: string;
  sentTime: Date | null;
  // @ts-expect-error TS7051
  sendVerificationEmail: (any) => void;
  clearVerificationEmailSentTime: () => void;
  changeRoute: (path: string) => void;
}

interface States {
  open: boolean;
  isSend: boolean;
}

class Email extends Component<Props, States> {
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      isSend: false,
    };
  }

  // @ts-expect-error TS7006
  componentDidUpdate(prevProps) {
    if (this.props.pathname !== prevProps.pathname) {
      this.setState({ open: true });
    }
  }

  closeModal = () => {
    this.setState({ open: false });
  };

  resendEmail = () => {
    this.setState({ isSend: true });
    this.props.clearVerificationEmailSentTime();
    this.props.sendVerificationEmail({});
  };

  // @ts-expect-error TS7006
  switchSendDatetime = (classesSendMailLoader) => {
    const { t } = this.props;
    if (!this.state.isSend && !this.props.sentTime) {
      return null;
    } else if (this.state.isSend && !this.props.sentTime) {
      return (
        <div css={classesSendMailLoader.root}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    } else {
      return (
        <p>
          {t("email.sendTime")}
          {/*
           // @ts-expect-error TS2345 */}
          {getSlashSeparatedDateTimeString(this.props.sentTime)}
        </p>
      );
    }
  };

  render() {
    if (firebase.auth().currentUser === null)
      return <React.Fragment></React.Fragment>;

    // @ts-expect-error TS2531
    if (firebase.auth().currentUser.emailVerified)
      return <React.Fragment></React.Fragment>;

    if (
      ["/account/edit", "/account/edit/delete", "/contact"].includes(
        this.props.pathname
      )
    ) {
      return null;
    }

    const { user } = this.props;
    const classesVerificationEmailStyles = verificationEmailStyles();
    const classesSendMailLoader = sendMailLoader();
    const { t } = this.props;

    if (user.email) {
      return (
        <FullScreenDialog open={this.state.open} style={modalStyle}>
          <div css={classesVerificationEmailStyles.root}>
            <h2>{t("email.header")}</h2>

            <div css={classesVerificationEmailStyles.thxText}>
              <p>{t("email.para1")}</p>
              <p>{t("email.para2")}</p>
            </div>

            <div css={classesVerificationEmailStyles.userInfo}>
              <h3>{this.props.user.email}</h3>
              {this.switchSendDatetime(classesSendMailLoader)}
            </div>

            <div css={classesVerificationEmailStyles.caution}>
              <p>{t("email.annotation1")}</p>
              <p>{t("email.annotation2")}</p>
              <p>{t("email.annotation3")}</p>
              <p>{t("email.annotation4")}</p>
              <p>{t("email.annotation5")}</p>
            </div>

            <ButtonList>
              <LinkButton onClick={this.resendEmail}>
                {t("email.button1")}
              </LinkButton>
              <LinkButton
                onClick={() => {
                  this.props.changeRoute("/account/edit");
                }}
              >
                {t("email.button2")}
              </LinkButton>
              <LinkButton className="cancel" onClick={this.closeModal}>
                {t("email.button3")}
              </LinkButton>
            </ButtonList>
          </div>
        </FullScreenDialog>
      );
    } else {
      return (
        <FullScreenDialog open={this.state.open} style={modalStyle}>
          <div css={classesVerificationEmailStyles.root}>
            <h2>{t("email.header")}</h2>

            <div css={classesVerificationEmailStyles.thxText}>
              <p>{t("email.para1")}</p>
              <p>{t("email.annotation6")}</p>
            </div>

            <div css={classesVerificationEmailStyles.userInfo}>
              <h3>{this.props.user.email}</h3>
              {this.switchSendDatetime(classesSendMailLoader)}
            </div>

            <EmailButtonList>
              <LinkButton
                onClick={() => {
                  this.props.changeRoute("/account/edit");
                }}
              >
                {t("email.button4")}
              </LinkButton>
              {/* `user.email` が無い状態でサービスを操作させたく無いので、モーダルを閉じるボタンは表示しない */}
              {/* 関連slack: https://balus3d.slack.com/archives/C01C7KHJ2J0/p1698729273908159?thread_ts=1698719252.697579&cid=C01C7KHJ2J0 */}
            </EmailButtonList>
          </div>
        </FullScreenDialog>
      );
    }
  }
}

const mapStateToProps = (state: Store) => {
  return {
    user: state.auth.user,
    pathname: state.router.location.pathname,
    sentTime: state.auth.verificationEmailSentTime,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    // @ts-expect-error TS7006
    sendVerificationEmail: (payload) => {
      dispatch(authActions.sendVerificationEmail.started(payload));
    },
    clearVerificationEmailSentTime: () => {
      dispatch(authActions.clearStateByKey("verificationEmailSentTime"));
    },
    // @ts-expect-error TS7006
    changeRoute: (link) => {
      dispatch(push(link));
    },
  };
};
const Account = withTranslation()(Email);
// @ts-expect-error TS2345
export default connect(mapStateToProps, mapDispatchToProps)(Account);

const sendMailLoader = () => {
  return {
    root: css`
      margin-top: 16px;
      text-align: center;
      div {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin: 2px;
        background-color: #2d2d2d;
        border-radius: 100%;
        animation-fill-mode: both;
      }
      & > div:nth-child(1) {
        animation: ${sendAnimation} 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
        animation: ${sendAnimation} 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
      }
      & > div:nth-child(2) {
        animation: ${sendAnimation} 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
        animation: ${sendAnimation} 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
      }
      & > div:nth-child(3) {
        animation: ${sendAnimation} 0.75s 0s infinite
          cubic-bezier(0.2, 0.68, 0.18, 1.08);
        animation: ${sendAnimation} 0.75s 0s infinite
          cubic-bezier(0.2, 0.68, 0.18, 1.08);
      }
    `,
  };
};

const sendAnimation = keyframes`
  30% {
    -webkit-transform: scale(.3);
    transform: scale(.3);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

const verificationEmailStyles = () => {
  const root = css`
    width: 90%;
    margin: 60px auto 0;
    padding: 30px 5% 50px;
    background-color: #fff;
    @media screen and (min-width: 768px) {
      width: 700px;
      padding: 50px 2.5% 50px;
    }
    h2 {
      margin-bottom: 30px;
      font-size: 20px;
      text-align: center;
      line-height: 1.3em;
      @media screen and (min-width: 768px) {
        padding-bottom: 40px;
        margin-bottom: 32px;
        font-size: 25px;
        border-bottom: 1px solid #000;
      }
    }
  `;
  const thxText = css`
    margin-bottom: 40px;
    p {
      text-align: center;
      line-height: 1.5em;
    }
  `;
  const userInfo = css`
    margin-bottom: 40px;
    h3 {
      text-align: center;
      @media screen and (min-width: 768px) {
        font-size: 25px;
      }
    }
    p {
      margin-top: 16px;
      text-align: center;
    }
  `;
  const caution = css`
    margin-bottom: 24px;
    p {
      font-size: 80%;
      text-align: left;
      line-height: 1.5em;
      opacity: 0.5;
    }
  `;
  return {
    root,
    thxText,
    userInfo,
    caution,
  };
};
