import {
  GetCapsuleBoxRequest,
  GetCapsuleBoxResponse,
} from "@spwn/types/functions";
import { CapsuleBoxDTO } from "@spwn/types/functions/capsuleBox";
import firebase from "firebase/app";
import useSWRImmutable from "swr/immutable";

export const useGetCapsuleBox = ({
  eventId,
  capsuleId,
}: {
  eventId: string;
  capsuleId: number;
}): {
  capsuleBox: CapsuleBoxDTO;
  isLoading: boolean;
  error: unknown;
} => {
  const { data, error, isLoading } = useSWRImmutable(
    ["getCapsuleBox", eventId, capsuleId],
    async () => {
      const { data } = await callGetCapsuleBox({
        eventId,
        capsuleId,
      });
      return data;
    }
  );

  return {
    // @ts-expect-error TS2322
    capsuleBox: data,
    isLoading,
    error,
  };
};

const callGetCapsuleBox = async (
  request: GetCapsuleBoxRequest
): Promise<{ data: GetCapsuleBoxResponse }> => {
  return await firebase.functions().httpsCallable("getCapsuleBox")(request);
};
