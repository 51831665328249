/** @jsxRuntime classic /
 /** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useI18n } from "hooks/i18n/i18n";
import arrow from "../../designs/images/streaming/arrow_down.png";
import { CardInfo } from "modules/purchase";
import CreditCardPlusOutline from "mdi-material-ui/CreditCardPlusOutline";
import { Button } from "../../ui/Button";

type EventGoodsItemInfoProps = {
  registeredCardList: CardInfo[];
  setSecurityCode: React.Dispatch<React.SetStateAction<string>>;
  openRegisterCard: () => void;
  setCreditCardSeq: React.Dispatch<unknown>;
};

export const GoodsSelectCard: React.FC<EventGoodsItemInfoProps> = (props) => {
  const { t } = useI18n();
  const classes = styles();

  return (
    <React.Fragment>
      {props.registeredCardList && props.registeredCardList.length > 0 ? (
        <React.Fragment>
          <div css={classes.selectCredit}>
            <p>{t("streaming.commerceCreditPayment.cardCheckout")}</p>
            <div css={classes.selectCard}>
              <p>{t("streaming.commerceCreditPayment.card4string")}</p>
              <select onChange={(e) => props.setCreditCardSeq(e.target.value)}>
                <option value={-1}>----</option>
                {props.registeredCardList.map((card, index) => {
                  return (
                    <option value={card.cardSeq} key={index}>
                      {card.cardNumber.slice(-4)}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div css={classes.securityCode}>
            <p>{t("streaming.commerceCreditPayment.securityCode")}</p>
            <input
              type="password"
              maxLength={4}
              onChange={(e) => props.setSecurityCode(e.target.value)}
            />
          </div>
        </React.Fragment>
      ) : (
        <p css={classes.message}>
          登録されているクレジットカードはありません。
        </p>
      )}

      <Button
        margin={css`
          margin: 0 0 0 auto;
        `}
        type={"button"}
        variant={"ghost"}
        size={"sm"}
        prefix={<CreditCardPlusOutline />}
        onClick={props.openRegisterCard}
      >
        {t("streaming.commerceCreditPayment.otherCard")}
      </Button>
    </React.Fragment>
  );
};

const styles = () => {
  return {
    selectCredit: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      p {
        font-size: 14px;
      }
    `,
    selectCard: css`
      display: flex;
      align-items: center;
      p {
        margin-right: 8px;
      }
      select {
        width: 70px;
        padding: 8px;
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 1px;
        background-color: #f4f4f4;
        background-image: url(${arrow});
        background-size: 8px auto;
        background-repeat: no-repeat;
        background-position: 90% center;
        border: 1px solid #dedede;
        border-radius: 5px;
        appearance: none;
      }
    `,
    securityCode: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      p {
        font-size: 14px;
      }
      input[type="password"] {
        width: 50px;
        padding: 8px;
        font-size: 16px;
        text-align: center;
        border: 1px solid #b1b1b1;
        border-radius: 5px;
        letter-spacing: 1px;
      }
    `,
    message: css`
      color: #ff0000;
      margin-bottom: 12px;
      font-size: 14px;
      text-align: right;
    `,
    registerCard: css`
      margin: 0 0 0 auto;
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
      color: #00c2ae;
      background-color: transparent;
      font-size: 14px;
      text-align: right;
      border: none;
      cursor: pointer;
      > svg {
        width: 20px;
        height: 20px;
      }
    `,
  };
};
