import firebase from "firebase";
import useSWR from "swr";

export const useGetStatusChangedEventIds = () => {
  return useSWR<string[]>("news/topInfo", async () => {
    const snapshot = await firebase
      .firestore()
      .collection("news")
      .doc("topInfo")
      .get();
    const data = snapshot.data();
    return data?.statusChangeEventIds ?? [];
  });
};
