import firebase from "firebase";
import useSWR from "swr";

import { Place } from "@spwn/types/firebase/firestore";

export type UseGetPlaceListProps = {
  ids?: string[];
};

export const useGetPlaceList = (props?: UseGetPlaceListProps | string[]) => {
  const ids = Array.isArray(props) ? props : props?.ids;

  return useSWR(
    // 空配列が渡された場合は取得しない
    !!ids && ids.length === 0 ? null : ["placeList", ids],
    async ([_, ids]) => fetchPlaceList(ids),
    {
      revalidateOnFocus: false,
    }
  );
};

const fetchPlaceList = async (ids?: string[]) => {
  const collection = firebase.firestore().collection("places");

  if (ids) {
    // 指定されたIDのみ取得
    // INには最大10個までしか指定できないので、10個ずつに分割する
    const queryList: string[][] = [];
    const perPage = 10;
    for (let i = 0; i < ids?.length; i += perPage) {
      queryList.push(ids.slice(i, i + perPage));
    }

    const queryPromises = queryList.map(async (query) => {
      return collection
        .where(firebase.firestore.FieldPath.documentId(), "in", query)
        .get();
    });

    return await Promise.all(queryPromises).then((querySnapshotList) => {
      return querySnapshotList.flatMap((querySnapshot) => {
        return querySnapshot.docs.map((doc) => {
          return doc.data() as Place;
        });
      });
    });
  } else {
    // 全件取得
    return await collection.get().then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => {
        return doc.data() as Place;
      });
    });
  }
};
