import { createRef, useEffect, useRef, useState } from "react";
import { Cart } from "./Cart";

const FIXED_TOP_OFFSET_PX = -50;

export const useStickyCartSummary = (cart: Cart) => {
  const headerRefs = useRef(
    [...Array(cart.orderCarts.length)].map((_) => createRef<HTMLDivElement>())
  );
  // @ts-expect-error TS2345
  const [stickyCartIndex, setStickyCartIndex] = useState<number>(null);

  useEffect(() => {
    if (!cart) {
      return;
    }
    const scrollAction = () => {
      const fixedTargetRects = Object.entries(headerRefs.current)
        .map(([index, ref]) => ({
          index, // keep cart index.
          // @ts-expect-error TS18047
          rect: ref.current.getBoundingClientRect(),
        }))
        // rect top + offset が閾値を超えるCartをfilter
        .filter((el) => el.rect.top + FIXED_TOP_OFFSET_PX <= 0)
        // order by rect top desc.
        .sort((a, b) => b.rect.top - a.rect.top);
      if (fixedTargetRects.length > 0) {
        const [fixedTargetRect] = fixedTargetRects;
        // @ts-expect-error TS18048
        setStickyCartIndex(Number(fixedTargetRect.index));
      } else {
        // @ts-expect-error TS2345
        setStickyCartIndex(null);
      }
    };

    window.addEventListener("scroll", scrollAction, true);

    return () => {
      window.removeEventListener("scroll", scrollAction, true);
    };
  }, [cart, stickyCartIndex]);

  return {
    headerRefs,
    stickyCartIndex,
  };
};
