/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, ComponentProps } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "store";

import { purchaseActions } from "modules/purchase";
import { SETTLEMENT_FEE_DATA } from "constants/purchase";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { cartActions, ReqAddToCartAndPurchase } from "modules/cart";
import { useLiveCommerceShippingFee } from "hooks/Settlement";

import BorderLoader from "components/atoms/loader/BorderLoader";
import { scrollTop } from "utility/index";
import { getCardTokenGMO } from "utility/gmo";
import { modalActions } from "modules/modal";

import StreamingLiveCommerceChagePaymentMethod from "./StreamingLiveCommerceChagePaymentMethod";
import { StreamingLiveCommerceCreditPayment } from "components/streaming/liveCommerce/StreamingLiveCommerceCreditPayment";
import { StreamingLiveCommerceConveniencePayment } from "components/streaming/liveCommerce/StreamingLiveCommerceConveniencePayment";
import { StreamingLiveCommerceCareerPayment } from "components/streaming/liveCommerce/StreamingLiveCommerceCareerPayment";
import { StreamingLiveCommerceRegisterCredit } from "components/streaming/liveCommerce/StreamingLiveCommerceRegisterCredit";
import { useI18n } from "hooks/i18n/i18n";
import type { PayType } from "@spwn/types";
import type { CVSCode, PhoneCode } from "@spwn/types/gmo";
import type { ProductData } from "@spwn/types/firebase/firestore";
import { TextLink } from "ui/TextLink";

interface Props {
  currentVideoId: string;
  THEOPlayerElementHeight?: number;
  pseudoCart: (ProductData & { count: number })[];
  incrementCartItem: (itemData: ProductData) => void;
  decrementCartItem: (itemData: ProductData) => void;
  removeCartItem: (itemData: ProductData) => void;
  closeLiveCommerceCart: () => void;
}

type ReqRegisterCardInfo = ComponentProps<
  typeof StreamingLiveCommerceRegisterCredit
>["registerCardInfo"];

const StreamingLiveCommerceCart: React.FC<Props> = (props) => {
  const [isRegisterCard, setIsRegisterCard] = React.useState<boolean>(false);
  const [registerCardInfo, setRegisterCardInfo] =
    // @ts-expect-error TS2345
    React.useState<ReqRegisterCardInfo>(null);
  const [isChangePaymentMethod, setIsChangePaymentMethod] =
    React.useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = React.useState<PayType>("Card");
  const [totalFee, setTotalFee] = React.useState<number>(0);
  const [settlementFee, setSettlementFee] = React.useState<number>(0);
  const [selectCreditCardSeq, setCreditCardSeq] = React.useState(null);
  // @ts-expect-error TS2345
  const [securityCode, setSecurityCode] = React.useState<string>(null);
  // @ts-expect-error TS2345
  const [cvesCode, setCvesCode] = React.useState<CVSCode>(null);
  // @ts-expect-error TS2345
  const [customerName, setCustomerName] = React.useState<string>(null);
  // @ts-expect-error TS2345
  const [customerKana, setCustomerKana] = React.useState<string>(null);
  // @ts-expect-error TS2345
  const [telNo, setTelNo] = React.useState<string>(null);
  // @ts-expect-error TS2345
  const [phoneCode, setPhoneCode] = React.useState<PhoneCode>(null);

  // PawCharge component supposed to call fetchCardInfo to fetch cardInfoList
  const registeredCardList = useSelector(
    (state: Store) => state.purchase.cardInfoList
  );
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );
  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const isAddressRegistered = useSelector(
    (state: Store) => state.auth.isAddressRegistered
  );

  // didMountとにwatchPseudoCartに移動する
  // それに伴ってStateに値を格納するように変更する
  const { isCalculatingShippingFee, shippingFee, isError } =
    useLiveCommerceShippingFee(displayEvent, props.pseudoCart);

  const dispatch = useDispatch();
  const { t } = useI18n();
  const maxDisplayCount = 99;
  const classesLiveCommerceCartDesign = liveCommerceCartDesign({
    isDarkModeEnabled,
    THEOPlayerElementHeight: props.THEOPlayerElementHeight,
  });

  const didMount = () => {
    scrollTop();
  };
  useEffect(didMount, []);

  const watchPseudoCart = () => {
    if (props.pseudoCart.length === 0) {
      setTotalFee(0);
    } else {
      let total = props.pseudoCart
        .map((el) => el.price_jpy * el.count)
        .reduce((sum, item) => sum + item);
      total += shippingFee;
      total += settlementFee;
      setTotalFee(total);
    }
  };
  useEffect(watchPseudoCart, [props.pseudoCart, shippingFee, settlementFee]);

  const watchSettlementFee = () => {
    setSettlementFee(SETTLEMENT_FEE_DATA[paymentMethod]);
  };
  useEffect(watchSettlementFee, [paymentMethod]);

  const toggleRegisterCard = () => {
    dispatch(purchaseActions.toggleRegisterCard());
  };

  const incrementNum = (item: ProductData & { count: number }) => {
    const purchaseLimit =
      // @ts-expect-error TS18048
      maxDisplayCount < item.purchaseLimit
        ? maxDisplayCount
        : item.purchaseLimit;
    // @ts-expect-error TS18048
    if (purchaseLimit <= item.count) {
      return;
    }
    props.incrementCartItem(item);
  };

  const decrementNum = (item: ProductData & { count: number }) => {
    if (item.count <= 1) {
      return;
    }
    props.decrementCartItem(item);
  };

  const removeCartItem = (itemData: ProductData) => {
    props.removeCartItem(itemData);
  };

  const canPurchase = () => {
    return (
      selectCreditCardSeq &&
      Number(selectCreditCardSeq) !== -1 &&
      securityCode &&
      3 <= String(securityCode).length &&
      !isCalculatingShippingFee &&
      !isError
    );
  };

  const canCvsPurchase = () => {
    return (
      cvesCode !== null &&
      customerName !== null &&
      customerKana !== null &&
      telNo !== null
    );
  };

  const canCareerPurchase = () => {
    return phoneCode !== null;
  };

  const startPurchaseProcess = () => {
    const purchaseItem = props.pseudoCart.map((item) => ({
      id: item._id,
      productType: item.productType,
      count: item.count,
    }));

    switch (paymentMethod) {
      case "Card":
        if (!canPurchase()) return;

        dispatch(
          // @ts-expect-error TS2352
          cartActions.addToCartAndPurchase.started({
            eventId: displayEvent.eventId,
            currentVideoId: props.currentVideoId,
            products: purchaseItem,
            displayPrice: totalFee,
            cardSeq: selectCreditCardSeq,
            securityCode,
            purchaseMethod: "Card",
            source: "liveCommerce",
          } as ReqAddToCartAndPurchase<"Card">)
        );
        break;

      case "CVS":
        if (!canCvsPurchase()) return;

        dispatch(
          cartActions.addToCartAndPurchase.started({
            eventId: displayEvent.eventId,
            currentVideoId: props.currentVideoId,
            products: purchaseItem,
            displayPrice: totalFee,
            cvesCode,
            customerName,
            customerKana,
            telNo,
            purchaseMethod: "CVS",
            source: "liveCommerce",
          } as ReqAddToCartAndPurchase<"CVS">)
        );
        break;

      case "Phone":
        if (!canCareerPurchase()) return;

        dispatch(
          cartActions.addToCartAndPurchase.started({
            eventId: displayEvent.eventId,
            currentVideoId: props.currentVideoId,
            products: purchaseItem,
            displayPrice: totalFee,
            phoneCode,
            purchaseMethod: "Phone",
            source: "liveCommerce",
          } as ReqAddToCartAndPurchase<"Phone">)
        );
        break;
    }
  };

  const openChangePaymentMethod = () => {
    setIsChangePaymentMethod(true);
  };

  const closeChangePaymentMethod = () => {
    setIsChangePaymentMethod(false);
  };

  const openRegisterCard = () => {
    setIsRegisterCard(true);
  };

  const closeRegisterCard = () => {
    // @ts-expect-error TS2345
    setRegisterCardInfo(null);
    setIsRegisterCard(false);
  };

  const checkInputValue = () => {
    return Boolean(
      registerCardInfo &&
        registerCardInfo.creditNumber &&
        registerCardInfo.creditExpirationMonth &&
        registerCardInfo.creditExpirationYear &&
        registerCardInfo.creditName &&
        registerCardInfo.creditSecurityCode
    );
  };

  const registerNewCard = () => {
    getCardTokenGMO({
      cardNo: registerCardInfo.creditNumber,
      expire:
        registerCardInfo.creditExpirationYear.toString() +
        registerCardInfo.creditExpirationMonth.toString(),
      securityCode: registerCardInfo.creditSecurityCode,
      holderName: registerCardInfo.creditName,
      tokenNumber: 1,
      // @ts-expect-error TS7008
    }).then((response: { isError: boolean; tokens }) => {
      if (
        response.tokens === undefined ||
        response.tokens === null ||
        response.tokens.length === 0
      ) {
        dispatch(modalActions.toggleError({ msg: "カード登録に失敗しました" }));
        return;
      }
      dispatch(
        purchaseActions.registerCardInfo.started({ token: response.tokens[0] })
      );
      closeRegisterCard();
    });
  };

  const ticketsToBePurchasedList = props.pseudoCart.filter(
    (item) => item.productType === "ticket"
  );
  const goodsToBePurchasedList = props.pseudoCart.filter(
    (item) => item.productType === "goods"
  );

  return (
    <div css={classesLiveCommerceCartDesign.root}>
      <div css={classesLiveCommerceCartDesign.header}>
        <ArrowBackIosIcon onClick={props.closeLiveCommerceCart} />
        <p>{t("streaming.commerceCart.order")}</p>
      </div>

      <div css={classesLiveCommerceCartDesign.cart}>
        {props.pseudoCart.length === 0 ? (
          <div css={classesLiveCommerceCartDesign.yourCartIsEmpty}>
            <p>{t("streaming.commerceCart.emptyCart")}</p>
          </div>
        ) : (
          <React.Fragment>
            {ticketsToBePurchasedList.length > 0 && (
              <React.Fragment>
                {/* <div css={classesLiveCommerceCartDesign.categoryHeader}>
                            <p>TICKET</p>
                          </div> */}
                <div css={classesLiveCommerceCartDesign.item}>
                  <div css={classesLiveCommerceCartDesign.headerImage}>
                    <img
                      src={displayEvent.thumbnail}
                      alt={displayEvent.title}
                    />
                  </div>
                  <div css={classesLiveCommerceCartDesign.headerText}>
                    <h3>{displayEvent.title}</h3>
                  </div>
                </div>
                {ticketsToBePurchasedList.map((ticket, index) => {
                  return (
                    <div css={classesLiveCommerceCartDesign.item} key={index}>
                      <div css={classesLiveCommerceCartDesign.ticketText}>
                        <p css={classesLiveCommerceCartDesign.goodsName}>
                          {ticket.name}
                        </p>
                        <p css={classesLiveCommerceCartDesign.price}>
                          ¥{ticket.price_jpy.toLocaleString()}
                        </p>
                      </div>
                      <div css={classesLiveCommerceCartDesign.operation}>
                        {/* <div css={classesLiveCommerceCartDesign.selectNumButton}>
                                      <div onClick={ () => decrementNum(ticket) }><RemoveIcon/></div>
                                      <p>{ticket.count}</p>
                                      <div onClick={ () => incrementNum(ticket) }><AddIcon/></div>
                                    </div> */}
                        <p
                          css={classesLiveCommerceCartDesign.remove}
                          onClick={() => removeCartItem(ticket)}
                        >
                          {t("streaming.commerceCart.remove")}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            )}

            {goodsToBePurchasedList.length > 0 &&
              goodsToBePurchasedList.map((cartItem, index) => {
                return (
                  <div css={classesLiveCommerceCartDesign.item} key={index}>
                    <div css={classesLiveCommerceCartDesign.image}>
                      <img src={cartItem.thumbnail} alt={cartItem.name} />
                    </div>
                    <div css={classesLiveCommerceCartDesign.text}>
                      <p css={classesLiveCommerceCartDesign.goodsName}>
                        {cartItem.name}
                      </p>
                      <p css={classesLiveCommerceCartDesign.size}>
                        {cartItem.subClassName}
                      </p>
                      <p css={classesLiveCommerceCartDesign.price}>
                        ¥{cartItem.price_jpy.toLocaleString()}
                      </p>
                    </div>
                    <div css={classesLiveCommerceCartDesign.operation}>
                      <div css={classesLiveCommerceCartDesign.selectNumButton}>
                        <div onClick={() => decrementNum(cartItem)}>
                          <RemoveIcon />
                        </div>
                        <p>{cartItem.count}</p>
                        <div onClick={() => incrementNum(cartItem)}>
                          <AddIcon />
                        </div>
                      </div>
                      <p
                        css={classesLiveCommerceCartDesign.remove}
                        onClick={() => removeCartItem(cartItem)}
                      >
                        {t("streaming.commerceCart.remove")}
                      </p>
                    </div>
                  </div>
                );
              })}

            <div css={classesLiveCommerceCartDesign.paymentAmount}>
              <div css={classesLiveCommerceCartDesign.shippingFee}>
                <p>{t("streaming.commerceCart.shippingFee")}</p>
                {isCalculatingShippingFee ? (
                  <BorderLoader height="20px" width="40px" />
                ) : (
                  <p>¥{shippingFee.toLocaleString()}</p>
                )}
              </div>

              <div css={classesLiveCommerceCartDesign.settlementFee}>
                <p>{t("streaming.commerceCart.settlementFee")}</p>
                <p>¥{settlementFee.toLocaleString()}</p>
              </div>

              <div css={classesLiveCommerceCartDesign.total}>
                <p>{t("streaming.commerceCart.total")}</p>
                <p>¥{totalFee.toLocaleString()}</p>
              </div>
            </div>

            {!isAddressRegistered && (
              <div css={classesLiveCommerceCartDesign.button}>
                <p>{t("streaming.commerceCart.noticeAddress")}</p>
                <TextLink
                  href={`https://${window.location.hostname}/account/address`}
                >
                  住所を登録する
                </TextLink>
              </div>
            )}

            {isError && (
              <div css={classesLiveCommerceCartDesign.button}>
                <p>{t("streaming.commerceCart.noticeInvalidAddress")}</p>
                <TextLink
                  href={`https://${window.location.hostname}/account/address`}
                >
                  配送先住所を編集する
                </TextLink>
              </div>
            )}

            {isRegisterCard && (
              <StreamingLiveCommerceRegisterCredit
                isDarkModeEnabled={isDarkModeEnabled}
                registerCardInfo={registerCardInfo}
                registerNewCard={registerNewCard}
                setRegisterCardInfo={setRegisterCardInfo}
                closeRegisterCard={closeRegisterCard}
                checkInputValue={checkInputValue}
              />
            )}

            {!isRegisterCard && isAddressRegistered && isChangePaymentMethod && (
              <StreamingLiveCommerceChagePaymentMethod
                paymentMethod={paymentMethod}
                // @ts-expect-error TS2322
                THEOPlayerElementHeight={props.THEOPlayerElementHeight}
                closeChangePaymentMethod={closeChangePaymentMethod}
                setPaymentMethod={setPaymentMethod}
              />
            )}

            {!isRegisterCard &&
              isAddressRegistered &&
              !isChangePaymentMethod &&
              paymentMethod === "Card" && (
                <StreamingLiveCommerceCreditPayment
                  isError={isError}
                  isDarkModeEnabled={isDarkModeEnabled}
                  // @ts-expect-error TS2322
                  registeredCardList={registeredCardList}
                  // @ts-expect-error TS2322
                  setCreditCardSeq={setCreditCardSeq}
                  setSecurityCode={setSecurityCode}
                  openChangePaymentMethod={openChangePaymentMethod}
                  // @ts-expect-error TS2322
                  canPurchase={canPurchase}
                  toggleRegisterCard={toggleRegisterCard}
                  startPurchaseProcess={startPurchaseProcess}
                  openRegisterCard={openRegisterCard}
                />
              )}

            {!isRegisterCard &&
              isAddressRegistered &&
              !isChangePaymentMethod &&
              paymentMethod === "CVS" && (
                <StreamingLiveCommerceConveniencePayment
                  isDarkModeEnabled={isDarkModeEnabled}
                  setCvesCode={setCvesCode}
                  setCustomerName={setCustomerName}
                  setCustomerKana={setCustomerKana}
                  setTelNo={setTelNo}
                  openChangePaymentMethod={openChangePaymentMethod}
                  canCvsPurchase={canCvsPurchase}
                  startPurchaseProcess={startPurchaseProcess}
                />
              )}

            {!isRegisterCard &&
              isAddressRegistered &&
              !isChangePaymentMethod &&
              paymentMethod === "Phone" && (
                <StreamingLiveCommerceCareerPayment
                  isDarkModeEnabled={isDarkModeEnabled}
                  setPhoneCode={setPhoneCode}
                  openChangePaymentMethod={openChangePaymentMethod}
                  canCareerPurchase={canCareerPurchase}
                  startPurchaseProcess={startPurchaseProcess}
                />
              )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

interface LiveCommerceCartDesignProps {
  THEOPlayerElementHeight?: number;
  isDarkModeEnabled: boolean;
}
const liveCommerceCartDesign = (props?: LiveCommerceCartDesignProps) => {
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const svgColorInDarkMode = props.isDarkModeEnabled
    ? css`
        fill: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const actionAreaHeight = props.THEOPlayerElementHeight
    ? css`
        height: calc(
          ${
              // @ts-expect-error TS18048
              props.THEOPlayerElementHeight
            }px - 90px
        );
      `
    : null;

  const root = css`
    border: 1px solid #e0e0e0;
  `;
  const header = css`
    position: relative;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
    svg {
      position: absolute;
      top: 50%;
      left: 15px;
      width: 15px;
      height: 15px;
      margin-top: calc(-15px / 2);
      ${svgColorInDarkMode}
      cursor: pointer;
    }
    p {
      ${textColorInDarkMode}
      font-size: 14px;
      text-align: center;
    }
  `;
  const cart = css`
    padding: 0 10px 10px;
    overflow-y: scroll;
    @media screen and (min-width: 768px) {
      ${actionAreaHeight}
      min-height: 509px;
    }
  `;
  // const categoryHeader = css`
  //   padding: 8px 0;
  //   border-bottom: 1px solid #e0e0e0;
  //   background-color: #f4f4f4;
  //   p{
  //     padding: 0 12px;
  //     font-size: 15px;
  //     font-weight: bold;
  //   }
  // `
  const headerImage = css`
    width: 150px;
    margin-right: 10px;
    img {
      width: 100%;
    }
  `;
  const headerText = css`
    width: calc(100% - 160px);
    h3 {
      margin-bottom: 16px;
      ${textColorInDarkMode}
      font-size: 16px;
      font-weight: normal;
      line-height: 1.3em;
    }
  `;
  const item = css`
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
  `;
  const image = css`
    width: 90px;
    height: 90px;
    margin-right: 10px;
    img {
      width: 100%;
    }
  `;
  const text = css`
    width: calc(100% - 180px);
  `;
  const ticketText = css`
    width: calc(100% - 80px);
  `;
  const goodsName = css`
    display: box;
    height: 2.4em;
    margin-bottom: 10px;
    ${textColorInDarkMode}
    font-size: 14px;
    line-height: 1.3em;
    /* stylelint-disable-next-line property-no-unknown */
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
  `;
  const size = css`
    margin-bottom: 10px;
    color: #b1b1b1;
    font-size: 14px;
  `;
  const price = css`
    ${textColorInDarkMode}
  `;
  const operation = css`
    width: 70px;
    margin-left: 10px;
  `;
  const selectNumButton = css`
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    input,
    div,
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% / 3);
    }
    input,
    div {
      padding: 4px 7px;
    }
    input,
    p {
      font-size: 14px;
      text-align: center;
      background-color: #fff;
    }
    div {
      border-left: 1px solid #e5e5e5;
      background-color: #f4f4f4;
      cursor: pointer;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  `;
  const remove = css`
    color: #b1b1b1;
    font-size: 12px;
    text-align: right;
    cursor: pointer;
  `;
  const paymentAmount = css`
    padding: 16px 0;
    margin-bottom: 16px;
    border-bottom: 1px solid #e0e0e0;
  `;
  const shippingFee = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    ${textColorInDarkMode}
    font-size: 15px;
  `;
  const settlementFee = css`
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-bottom: 16px;
    ${textColorInDarkMode}
    font-size: 15px;
    border-bottom: 1px solid #e0e0e0;
  `;
  const total = css`
    display: flex;
    justify-content: space-between;
    ${textColorInDarkMode}
    font-size: 15px;
  `;
  const yourCartIsEmpty = css`
    padding: 20px 0;
    p {
      ${textColorInDarkMode}
      font-size: 14px;
      text-align: center;
    }
  `;
  const button = css`
    padding: 12px 0;
    p {
      font-size: 14px;
      line-height: 1.5em;
      margin-bottom: 8px;
    }
    a {
      display: block;
      width: 100%;
      padding: 12px 0;
      color: #fff;
      font-size: 14px;
      text-align: center;
      text-decoration: none;
      background-color: #00c2ae;
      border: none;
      outline: none;
      appearance: none;
      cursor: pointer;
      &.disabled {
        background-color: #b1b1b1;
      }
    }
  `;
  return {
    root,
    header,
    cart,
    // categoryHeader,
    headerImage,
    headerText,
    item,
    image,
    text,
    ticketText,
    goodsName,
    size,
    price,
    operation,
    selectNumButton,
    remove,
    paymentAmount,
    shippingFee,
    settlementFee,
    total,
    yourCartIsEmpty,
    button,
  };
};

export default StreamingLiveCommerceCart;
