/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { useSelector } from "react-redux";
import { Store } from "store";

import { sanitizer } from "../../../utility/sanitize";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import type { ProductData } from "@spwn/types/firebase/firestore";

interface Props {
  openInfo: boolean;
  goodsInfo: ProductData;
  THEOPlayerElementHeight: number;
  closeGoodsInfo: () => void;
}
const StreamingLiveCommerceDisplayInfo: React.FC<Props> = (props) => {
  const isDarkModeEnabled = useSelector(
    (state: Store) => state.streaming.streamingSettings.isDarkModeEnabled
  );
  const classesLiveCommerceCartDesign = liveCommerceCartDesign({
    isDarkModeEnabled,
    THEOPlayerElementHeight: props.THEOPlayerElementHeight,
  });
  console.log(props.goodsInfo);

  return (
    <div css={classesLiveCommerceCartDesign.root}>
      <div css={classesLiveCommerceCartDesign.header}>
        <ArrowBackIosIcon onClick={props.closeGoodsInfo} />
      </div>

      <div css={classesLiveCommerceCartDesign.infoLayout}>
        <img src={props.goodsInfo.thumbnail} alt={props.goodsInfo.name} />
        <div
          css={classesLiveCommerceCartDesign.info}
          dangerouslySetInnerHTML={{
            // @ts-expect-error TS2345
            __html: sanitizer(props.goodsInfo.specification),
          }}
        />
      </div>
    </div>
  );
};
export default StreamingLiveCommerceDisplayInfo;

interface LiveCommerceCartDesignProps {
  THEOPlayerElementHeight?: number;
  isDarkModeEnabled: boolean;
}
const liveCommerceCartDesign = (props?: LiveCommerceCartDesignProps) => {
  // @ts-expect-error TS18048
  const textColorInDarkMode = props.isDarkModeEnabled
    ? css`
        color: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const svgColorInDarkMode = props.isDarkModeEnabled
    ? css`
        fill: #fff;
      `
    : null;
  // @ts-expect-error TS18048
  const actionAreaHeight = props.THEOPlayerElementHeight
    ? css`
        height: calc(
          ${
              // @ts-expect-error TS18048
              props.THEOPlayerElementHeight
            }px - 90px
        );
      `
    : null;

  const root = css`
    border: 1px solid #e0e0e0;
    img {
      width: 100%;
    }
  `;
  const header = css`
    position: relative;
    padding: 20px 0;
    border-bottom: 1px solid #e0e0e0;
    svg {
      position: absolute;
      top: 50%;
      left: 15px;
      width: 15px;
      height: 15px;
      margin-top: calc(-15px / 2);
      ${svgColorInDarkMode}
      cursor: pointer;
    }
    p {
      ${textColorInDarkMode}
      font-size: 14px;
      text-align: center;
    }
  `;
  const infoLayout = css`
    ${actionAreaHeight}
    min-height: 415px;
    padding: 10px;
    overflow-y: scroll;
    img {
      display: block;
      width: 100%;
      margin: 0 auto 10px;
    }
  `;
  const info = css`
    h3 {
      margin: 16px 0 8px;
      ${textColorInDarkMode}
      font-size: 14px;
    }
    p,
    li {
      ${textColorInDarkMode}
      font-size: 14px;
      line-height: 1.5em;
    }
    li {
      padding-left: 1em;
      margin-bottom: 4px;
      text-indent: -1em;
    }
  `;
  return {
    root,
    header,
    infoLayout,
    info,
  };
};
