import { useI18n } from "hooks/i18n/i18n";
import { eventState } from "modules/event";
import React from "react";
import { convertDatetimeToHHII } from "utility";
import styled from "@emotion/styled";
import { fontDinMixin } from "../../../styles";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
  .date {
    ${fontDinMixin};
    font-size: 22px;
    @media screen and (min-width: 768px) {
      font-size: 35px;
    }
  }
`;

export const EventDateTimeInfo: React.FC<{
  isLongEvent: boolean;
  eventOpenDateFull: string;
  eventEndDateFull: string;
  parts: eventState["displayEvent"]["parts"];
}> = (props) => {
  return (
    <Container>
      <p className="date">
        {props.isLongEvent
          ? props.eventOpenDateFull + " ~ " + props.eventEndDateFull
          : props.eventOpenDateFull}
      </p>
      {props.isLongEvent ? <></> : <EventStartTime parts={props.parts} />}
    </Container>
  );
};

const EventStartTimeContainer = styled.div`
  ${fontDinMixin};
  .heading {
    margin-right: 10px;
    color: #666c6d;
    font-size: 15px;
    @media screen and (min-width: 768px) {
      font-size: 20px;
    }
  }
  .multipleHold {
    li {
      font-size: 20px;
      line-height: 1em;
      @media screen and (min-width: 768px) {
        font-size: 25px;
      }
      span {
        margin-right: 10px;
        font-size: 12px;
        font-weight: bold;
        @media screen and (min-width: 768px) {
          font-size: 15px;
        }
      }
    }
  }
  .openAndEndTime {
    font-size: 22px;
    line-height: 1em;
    @media screen and (min-width: 768px) {
      font-size: 35px;
    }
  }
`;

export const EventStartTime: React.FC<{
  parts: eventState["displayEvent"]["parts"];
}> = (props) => {
  const { t } = useI18n();
  // @ts-expect-error TS18048
  const openTime = convertDatetimeToHHII(props.parts[0].openTime);
  // @ts-expect-error TS18048
  const startTime = convertDatetimeToHHII(props.parts[0].startTime);
  return (
    <EventStartTimeContainer>
      <p className="heading">{t("ticketDetail.heading")}</p>

      {props.parts.length >= 2 ? (
        <ul className="multipleHold">
          {props.parts.map((eventTime, index) => {
            const openTime = convertDatetimeToHHII(eventTime.openTime);
            const startTime = convertDatetimeToHHII(eventTime.startTime);
            return (
              <li key={index}>
                <span>{eventTime.name}</span>
                {`${openTime} / ${startTime}`}
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="openAndEndTime">{`${openTime} / ${startTime}`}</p>
      )}
    </EventStartTimeContainer>
  );
};
