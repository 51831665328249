import React from "react";
import { sanitizer } from "utility/sanitize";

/**
 * htmlタグが入ったテキストをsanitizer経由でhtml表示を行う。
 */
export const SanitizedText: React.VFC<{
  className?: string;
  description: string;
}> = ({ className, description }) => {
  return (
    <p
      className={className}
      dangerouslySetInnerHTML={{
        __html: sanitizer(description),
      }}
    />
  );
};
