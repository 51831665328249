/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { changeStyleWithHosting } from "utility/hosting";
import { PurchaseTicketSkeleton } from "components/loading/SkeletonLoader";
import { EventVideoTicketList } from "./EventVideoTicketList";
import { useI18n } from "hooks/i18n/i18n";
import { LeftBorderHeadline } from "components/atoms/headline/LeftBorderHeadline";

type Props = React.ComponentProps<typeof EventVideoTicketList>;
export const EventDetailPurchaseTicket: React.FC<Props> = (props) => {
  const { t } = useI18n();
  const { eventVideoMap, streamingKey } = props;
  const classesHeadingDesign = headingDesign();
  if (!streamingKey || !eventVideoMap) {
    return <PurchaseTicketSkeleton />;
  }
  return (
    <div id="ticket" css={classesHeadingDesign.root}>
      <div css={classesHeadingDesign.heading}>
        <LeftBorderHeadline
          title={t("event.headline.streamingTicket.text")}
          subTitle={t("event.headline.streamingTicket.subText")}
          fontColor={"secondary"}
        />
      </div>
      <EventVideoTicketList {...props} />
    </div>
  );
};

const headingDesign = () => {
  const _hostingBaseStyleMap = changeStyleWithHosting().baseStyles;
  return {
    root: css`
      margin-top: 50px;
    `,
    heading: css`
      margin-bottom: 40px;
    `,
    charityText: css`
      margin-bottom: 20px;
      p {
        line-height: 1.5em;
      }
    `,
  };
};
