/** @jsxRuntime classic /
 /** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { OrderCart } from "../../hooks/cart/Cart";
import { useI18n } from "../../hooks/i18n/i18n";

interface Props {
  isDisplay: boolean;
  orderCart: OrderCart;
  goSettlement: () => void;
}

export const CartStickyOrder: React.FC<Props> = (props) => {
  const classes = styles();
  const { t } = useI18n();

  if (!props.orderCart) {
    return <div css={classes.root}></div>;
  }

  const { heading, subTotalPrice } = props.orderCart;

  return (
    // @ts-expect-error TS2322
    <div css={classes.root} className={props.isDisplay && "display"}>
      <div className="cart-order-purchase-price">
        <p className={"cart"}>{heading}</p>
        <p className={"price"}>
          <span>{t("cart.box.subtotal")}</span> {subTotalPrice.toLocaleString()}
          {t("cart.box.yen")}
          <span>{t("cart.box.tax")}</span>
        </p>
      </div>

      <button
        className="cart-order-purchase-button"
        onClick={props.goSettlement}
      >
        <ShoppingCartIcon />
        <p>{t("cart.button.checkoutButton")}</p>
      </button>
    </div>
  );
};

const styles = () => ({
  root: css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 5%;
    background-color: #fff;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.5);
    transform: translateY(-120px);
    transition: 0.5s;
    z-index: 100;
    &.display {
      transform: initial;
    }
    .cart-order-purchase-price {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .cart {
        font-size: 18px;
        font-weight: bold;
      }
      .price {
        font-size: 16px;
        font-weight: bold;
        span {
          font-size: 12px;
        }
      }
    }
    .cart-order-purchase-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 8px;
      color: #fff;
      background-color: #00c2ad;
      border: none;
      svg {
        margin-right: 8px;
      }
      p {
        font-size: 14px;
        letter-spacing: 1px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
  `,
});
